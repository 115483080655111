import React, { useState } from 'react';
import { message, Alert, Icon, Modal, Row, Col, Typography, Button, Empty } from 'antd';
import Card from '../../components/Card';
import { Link } from 'react-router-dom';
import ZeroState from '../../components/ZeroState';
import CircularLoader from '../../components/CircularLoader';
import SectionHeader from '../../components/SectionHeader';
import SectionContent from '../../components/SectionContent';
import SectionWrapper from '../../components/SectionWrapper';
import SectionFooter from '../../components/SectionFooter';
import NotifyPlayersModal from './NotifyPlayersModal';
import AddPlayerModal from './AddPlayerModal';
import ImportPlayersModal from './ImportPlayersModal';
import InvitePlayerModal from './InvitePlayerModal';
import PlayerTable from './PlayerTable';
import { GET_LEAGUE_PLAYERS_QUERY, GET_LEAGUE_QUERY } from './data/queries';
import { Query, Mutation } from '@apollo/client/react/components';
import { DELETE_LEAGUE_PLAYER_MUTATION } from './data/mutations';

const { Text, Title } = Typography;
const { confirm } = Modal;

const styles = {
    row: {
        display: 'flex',
        flexGrow: 1,
    },
    tab: {
        paddingTop: 5,
        paddingLeft: 24,
        paddingRight: 24,
        paddingBottom: 24,
    },
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        padding: 20,
    },
};

export default function LMPlayers(props) {
    const { league, authState } = props;
    const [loading, setLoading] = useState(false);
    const [selectedKeys, setSelectedKeys] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectedPlayerId, setSelectedPlayerId] = useState();
    const [playerImportModalVisible, setPlayerImportModalVisible] = useState(false);
    const [playerAddModalVisible, setPlayerAddModalVisible] = useState(false);
    const [playerNotifyModalVisible, setPlayerNotifyModalVisible] = useState(false);
    const [playerInviteModalVisible, setPlayerInviteModalVisible] = useState(false);
    const hasSelected = selectedKeys && selectedKeys.length > 0;

    function handleRemoveSelected() {
        showConfirmDelete(selectedRows);
    }

    function showConfirmDelete(selectedRows) {
        confirm({
            title: `Are you sure you want to remove ${selectedRows.length} players from this league?`,
            content: 'Note: This action will not remove real user accounts.',
            centered: true,
            transitionName: 'fade',
            maskTransitionName: 'none',
            // onOk() {
            // 	return new Promise((resolve, reject) => {
            // 		setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
            // 	}).catch(() => console.log('Oops errors!'));
            // },
            onOk() {
                const key = 'deleted';
                message.loading({ content: 'Removing players...', key, duration: 0 });

                selectedRows.forEach((row) => {
                    props.client
                        .mutate({
                            mutation: DELETE_LEAGUE_PLAYER_MUTATION,
                            variables: {
                                league_id: league.id,
                                id: row.id,
                            },
                            notifyOnNetworkStatusChange: true,
                            awaitRefetchQueries: true,
                            refetchQueries: [
                                {
                                    query: GET_LEAGUE_QUERY,
                                    variables: { id: league.id },
                                },
                                {
                                    query: GET_LEAGUE_PLAYERS_QUERY,
                                    variables: { league_id: league.id },
                                },
                            ],
                        })
                        .then((res) => {
                            console.log(res);
                            setSelectedKeys();
                            // setLoading(false);
                            message.success({
                                content: 'Player removed',
                                key,
                                duration: 2,
                            });
                        })
                        .catch((error) => {
                            console.log(error);
                            // setLoading(false);
                            message.error({ content: JSON.stringify(error), key, duration: 1 });
                        });
                });
            },
            onCancel() {},
        });
    }

    function handlePlayerInvite() {
        setPlayerInviteModalVisible(true);
    }

    function handleInvitePlayerOk() {
        setPlayerInviteModalVisible(false);
    }

    function handleInvitePlayerCancel() {
        setPlayerInviteModalVisible(false);
    }

    function handlePlayerImportModalCancel(e) {
        setPlayerImportModalVisible(false);
    }

    function handlePlayerImportModalOk(e) {
        console.log(e);
        setPlayerImportModalVisible(false);
    }

    function handlePlayerAddModalOk(e) {
        // console.log(e);
        setPlayerAddModalVisible(false);
    }

    function handlePlayerAddModalCancel(e) {
        setPlayerAddModalVisible(false);
    }

    function handlePlayerSelected(selected, rows) {
        setSelectedKeys(selected);
        setSelectedRows(rows);
    }

    function handlePlayerEditSelected(selected) {
        // console.log(selected);
        setSelectedPlayerId(selected);
        setPlayerAddModalVisible(true);
    }

    function handlePlayerAdd() {
        setPlayerAddModalVisible(true);
        setSelectedPlayerId(null);
    }

    function handlePlayerNotifyOk(e) {
        setPlayerNotifyModalVisible(false);
    }

    function handleNotifyPlayerModalCancel() {
        setPlayerNotifyModalVisible(false);
    }

    function handlePlayerNotify() {
        setPlayerNotifyModalVisible(true);
    }

    function handlePlayerImport() {
        setPlayerImportModalVisible(true);
    }

    return (
        <SectionWrapper>
            <SectionHeader
                title={props.title}
                titleStyle={{ color: '#fff', margin: 0, textTransform: 'uppercase' }}
                avatar={league.avatar}
                subtitle={league.name}
                subtitlePrefix={true}
                subtitleStyle={{
                    color: 'rgba(255,255,255,0.8)',
                    textTransform: 'uppercase',
                    fontWeight: 600,
                    fontSize: 12,
                    letterSpacing: 3,
                }}
                theme="leagueManager"
            >
                <Link to={`/leagues/${props.match.params.slug}/players`}>
                    <Button type="ghost" ghost>
                        Public Profile
                        {/* <Icon type="arrow-right" /> */}
                    </Button>
                </Link>
            </SectionHeader>

            <SectionContent padding="20px 40px">
                <Row gutter={24} justify="center" type="flex">
                    <Col xs={24}>
                        <Alert
                            message="Helpful tips for adding players"
                            description="You can add players individually or many at once by using the Player Import feature. If you include phone numbers, you will be able to send SMS notifications to those players during league play."
                            type="info"
                            closable
                            showIcon
                        />
                    </Col>
                    <Col xs={24}>
                        <Card
                            title={`All Players (${league.league_players_aggregate.aggregate.count})`}
                            extra={
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <Button type="primary" onClick={handlePlayerAdd}>
                                        <Icon type="user-add" /> Add Player
                                    </Button>
                                    <Button type="primary" onClick={handlePlayerImport}>
                                        <Icon type="usergroup-add" /> Import Players
                                    </Button>
                                    <Button type="default" onClick={handlePlayerNotify}>
                                        <Icon type="bell" /> Notify
                                    </Button>
                                    <Button type="default" onClick={handlePlayerInvite}>
                                        <Icon type="mail" /> Invite
                                    </Button>
                                    <div style={{ marginLeft: 10 }}>
                                        <Button type="danger" onClick={handleRemoveSelected} disabled={!hasSelected} loading={loading}>
                                            Remove
                                        </Button>
                                        <span style={{ marginLeft: 8 }}>{hasSelected ? `Selected ${selectedKeys.length} items` : ''}</span>
                                    </div>
                                </div>
                            }
                            bodyStyle={{ padding: 0 }}
                        >
                            <Query
                                query={GET_LEAGUE_PLAYERS_QUERY}
                                fetchPolicy="cache-and-network"
                                notifyOnNetworkStatusChange
                                awaitRefetchQueries
                                variables={{ league_id: league.id }}
                                onCompleted={(data) => {
                                    // setPlayers(data.league_players);
                                    console.log(data);
                                    console.log(league);
                                }}
                            >
                                {({ loading, error, data }) => {
                                    if (loading)
                                        return (
                                            <div style={styles.container}>
                                                <CircularLoader />
                                            </div>
                                        );
                                    if (error) return <div style={styles.container}>Error: {error.message}</div>;
                                    if ((data && !data.league_players) || (data && data.league_players.length === 0)) {
                                        return <ZeroState showImage message="No players have been added yet." style={{ margin: 30 }} {...props} />;
                                    }

                                    return (
                                        <Mutation
                                            mutation={DELETE_LEAGUE_PLAYER_MUTATION}
                                            // variables={venue ? { venue_id: venue.id } : null}
                                            onCompleted={(data) => {
                                                console.log(data);
                                            }}
                                            awaitRefetchQueries
                                        >
                                            {(deletePlayer, { loading, error }) => (
                                                <div className="table_nowrap">
                                                    <PlayerTable
                                                        {...props}
                                                        league={league}
                                                        onDelete={(record) => {
                                                            deletePlayer({
                                                                variables: {
                                                                    league_id: league.id,
                                                                    id: record.id,
                                                                },
                                                                notifyOnNetworkStatusChange: true,
                                                                awaitRefetchQueries: true,
                                                                refetchQueries: [
                                                                    {
                                                                        query: GET_LEAGUE_QUERY,
                                                                        variables: { id: league.id },
                                                                    },
                                                                    {
                                                                        query: GET_LEAGUE_PLAYERS_QUERY,
                                                                        variables: { league_id: league.id },
                                                                    },
                                                                ],
                                                            })
                                                                .then((data) => {
                                                                    console.log(data);
                                                                    message.success('Player deleted from league');
                                                                })
                                                                .catch((error) => {
                                                                    console.log(error);
                                                                    message.info('There was an error', error);
                                                                });
                                                        }}
                                                        onSelected={handlePlayerSelected}
                                                        onEditSelected={handlePlayerEditSelected}
                                                        selectedRowKeys={selectedKeys}
                                                        players={data.league_players
                                                            // .sort((a, b) => {
                                                            //     return (
                                                            //         a.name &&
                                                            //         a.name.localeCompare(b.name, undefined, {
                                                            //             numeric: true,
                                                            //             sensitivity: 'base',
                                                            //         })
                                                            //     );
                                                            // })
                                                            .map((item, index) => {
                                                                return {
                                                                    ...item,
                                                                    divisions: item.league_player_divisions
                                                                        .map((item) => {
                                                                            return item.division.name;
                                                                        })
                                                                        .join(', '),
                                                                    teams: item.league_team_players
                                                                        .map((item) => {
                                                                            return item.league_team.name;
                                                                        })
                                                                        .join(', '),
                                                                    index: index + 1,
                                                                    key: index,
                                                                };
                                                            })}
                                                    />
                                                </div>
                                            )}
                                        </Mutation>
                                    );
                                }}
                            </Query>
                        </Card>
                    </Col>
                </Row>
            </SectionContent>
            <SectionFooter {...props} />

            <ImportPlayersModal
                userID={authState.user.id}
                leagueID={league.id}
                onModalOk={handlePlayerImportModalOk}
                onModalCancel={handlePlayerImportModalCancel}
                modalVisible={playerImportModalVisible}
                {...props}
            />
            <AddPlayerModal
                selectedPlayerId={selectedPlayerId}
                userID={authState.user.id}
                leagueID={league.id}
                onModalOk={handlePlayerAddModalOk}
                onModalCancel={handlePlayerAddModalCancel}
                modalVisible={playerAddModalVisible}
                {...props}
            />
            <NotifyPlayersModal
                leagueID={league.id}
                onModalOk={handlePlayerNotifyOk}
                onModalCancel={handleNotifyPlayerModalCancel}
                modalVisible={playerNotifyModalVisible}
                {...props}
            />
            <InvitePlayerModal onModalOk={handleInvitePlayerOk} onModalCancel={handleInvitePlayerCancel} modalVisible={playerInviteModalVisible} {...props} />
        </SectionWrapper>
    );
}
