import React, { useState, useEffect } from 'react';
// import { BrowserRouter as Router, Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { Spin, Icon } from 'antd';
// import HelmetMetaData from './components/HelmetMetaData';
import { ApolloClient, InMemoryCache, ApolloProvider, persistCache, gql } from '@apollo/client';
import apolloClient from '../src/apollo/client';
import AppRoutes from './AppRoutes';
import './App.css';
import './assets/css/video-react.css';
// import 'antd/dist/antd.css';

Spin.setDefaultIndicator(<Icon type="loading" />);

// message.config({
// 	top: 100,
// 	duration: 2,
// 	maxCount: 3,
// 	getContainer: () => document.querySelector('#auth')
// });

export default function App(props, context) {
    console.log('app props', props);
    const { authState } = props;
    const client = apolloClient({ authState });

    return (
        <ApolloProvider client={client}>
            <AppRoutes client={client} {...props} />
        </ApolloProvider>
    );
}
