import React from 'react';
import Flag from 'react-flags';
import { CircleFlag } from 'react-circle-flags';
import {
    Alabama,
    Arizona,
    AmericanSamoa,
    Colorado,
    Maine,
    Michigan,
    Minnesota,
    Ohio,
    Oregon,
    Kansas,
    Guam,
    Tennessee,
    Texas,
    WestVirginia,
    Wyoming,
} from './flags';

export default function CircleStateFlag(props) {
    // const { state } = props;
    let size = props.size || 32;
    let flagSize = size;
    let containerSize = 22;

    if (size === 'xlarge') {
        flagSize = 84;
        containerSize = 70;
    } else if (size === 'large') {
        flagSize = 64;
        containerSize = 40;
    } else if (size === 'medium') {
        flagSize = 48;
        containerSize = 32;
    } else if (size === 'small') {
        flagSize = 32;
        containerSize = 22;
    }
    // else {
    //     containerSize = size;
    // }
    const left = props.left === 0 ? 0 : 5;
    const states = [
        {
            name: 'Alabama',
            code: 'AL',
            icon: Alabama,
        },
        {
            name: 'Alaska',
            code: 'AK',
            icon: null,
        },
        {
            name: 'American Samoa',
            code: 'AS',
            icon: AmericanSamoa,
        },
        {
            name: 'Arizona',
            code: 'AZ',
            icon: Arizona,
        },
        {
            name: 'Arkansas',
            code: 'AR',
            icon: null,
        },
        {
            name: 'California',
            code: 'CA',
            icon: null,
        },
        {
            name: 'Colorado',
            code: 'CO',
            icon: Colorado,
        },
        {
            name: 'Connecticut',
            code: 'CT',
            icon: null,
        },
        {
            name: 'Delaware',
            code: 'District of Columbia',
            icon: null,
        },
        {
            name: 'Micronesia',
            code: 'FM',
            icon: null,
        },
        {
            name: 'Florida',
            code: 'FL',
            icon: null,
        },
        {
            name: 'Georgia',
            code: 'GA',
            icon: null,
        },
        {
            name: 'Guam',
            code: 'GU',
            icon: Guam,
        },
        {
            name: 'Hawaii',
            code: 'HI',
            icon: null,
        },
        {
            name: 'Idaho',
            code: 'ID',
            icon: null,
        },
        {
            name: 'Illinois',
            code: 'IL',
            icon: null,
        },
        {
            name: 'Indiana',
            code: 'IN',
            icon: null,
        },
        {
            name: 'Iowa',
            code: 'IA',
            icon: null,
        },
        {
            name: 'Kansas',
            code: 'KS',
            icon: null,
        },
        {
            name: 'Kentucky',
            code: 'KY',
            icon: null,
        },
        {
            name: 'Louisiana',
            code: 'LA',
            icon: null,
        },
        {
            name: 'Maine',
            code: 'ME',
            icon: Maine,
        },
        {
            name: 'Marshall Islands',
            code: 'MH',
            icon: null,
        },
        {
            name: 'Maryland',
            code: 'MD',
            icon: null,
        },
        {
            name: 'Massachusetts',
            code: 'MA',
            icon: null,
        },
        {
            name: 'Michigan',
            code: 'MI',
            icon: Michigan,
        },
        {
            name: 'Minnesota',
            code: 'MN',
            icon: Minnesota,
        },
        {
            name: 'Missouri',
            code: 'MO',
            icon: null,
        },
        {
            name: 'Montana',
            code: 'MT',
            icon: null,
        },
        {
            name: 'Nebraska',
            code: 'NE',
            icon: null,
        },
        {
            name: 'Nevada',
            code: 'NV',
            icon: null,
        },
        {
            name: 'New Hampshire',
            code: 'NH',
            icon: null,
        },
        {
            name: 'New Jersey',
            code: 'NJ',
            icon: null,
        },
        {
            name: 'New Mexico',
            code: 'NM',
            icon: null,
        },
        {
            name: 'New York',
            code: 'NY',
            icon: null,
        },
        {
            name: 'North Carolina',
            code: 'NC',
            icon: null,
        },
        {
            name: 'North Dakota',
            code: 'ND',
            icon: null,
        },
        {
            name: 'Northern Mariana Islands',
            code: 'MP',
            icon: null,
        },
        {
            name: 'Ohio',
            code: 'OH',
            icon: Ohio,
        },
        {
            name: 'Oklahoma',
            code: 'OK',
            icon: null,
        },
        {
            name: 'Oregon',
            code: 'OR',
            icon: Oregon,
        },
        {
            name: 'Palau',
            code: 'PW',
            icon: null,
        },
        {
            name: 'Pennsylvania',
            code: 'PA',
            icon: null,
        },
        {
            name: 'Puerto Rico',
            code: 'PR',
            icon: null,
        },
        {
            name: 'Rhode Island',
            code: 'RH',
            icon: null,
        },
        {
            name: 'South Carolina',
            code: 'SC',
            icon: null,
        },
        {
            name: 'South Dakota',
            code: 'SD',
            icon: null,
        },
        {
            name: 'Tennessee',
            code: 'TN',
            icon: Tennessee,
        },
        {
            name: 'Texas',
            code: 'TX',
            icon: Texas,
        },
        {
            name: 'Utah',
            code: 'UT',
            icon: null,
        },
        {
            name: 'Vermont',
            code: 'VT',
            icon: null,
        },
        {
            name: 'Virgin Islands',
            code: 'VI',
            icon: null,
        },
        {
            name: 'Virginia',
            code: 'VA',
            icon: null,
        },
        {
            name: 'Washington',
            code: 'WA',
            icon: null,
        },
        {
            name: 'West Virginia',
            code: 'WV',
            icon: WestVirginia,
        },
        {
            name: 'Wisconsin',
            code: 'WI',
            icon: null,
        },
        {
            name: 'Wyoming',
            code: 'WY',
            icon: Wyoming,
        },
        {
            name: 'Armed Forces Americas',
            code: 'AA',
            icon: null,
        },
        {
            name: 'Armed Forces Europe, Canada, Africa and Middle East',
            code: 'AE',
            icon: null,
        },
        {
            name: 'Armed Forces Pacific',
            code: 'AP',
            icon: null,
        },
    ];

    function StateFlag(props) {
        const component = states.filter((item) => {
            return props.state === item.code;
        });

        const State = component && component[0] && component[0].icon;
        if (State) {
            return <State state={props.state} {...props} />;
        } else {
            return null;
        }
    }

    return (
        <span
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'relative',
                width: containerSize,
                minWidth: containerSize,
                height: containerSize,
                overflow: 'hidden',
                borderRadius: '50%',
                marginRight: 10,
                border: '1px solid rgba(0,0,0,0.2)',
                ...props.style,
            }}
        >
            <span style={{ position: 'relative' }}>
                {/* {states.map((state, index) => {
                    return <StateFlag state={props.state} width={containerSize} height={containerSize} key={index} />;
                })}{' '} */}
                {props.state === 'Alabama' || props.state === 'AL' ? (
                    <Alabama width={containerSize} height={containerSize} />
                ) : props.state === 'AmericanSamoa' || props.state === 'AS' ? (
                    <AmericanSamoa width={containerSize} height={containerSize} />
                ) : props.state === 'Arizona' || props.state === 'AZ' ? (
                    <Arizona width={containerSize} height={containerSize} />
                ) : props.state === 'Colorado' || props.state === 'CO' ? (
                    <Colorado width={containerSize} height={containerSize} />
                ) : props.state === 'Michigan' || props.state === 'MI' ? (
                    <Michigan width={containerSize} height={containerSize} />
                ) : props.state === 'Ohio' || props.state === 'OH' ? (
                    <Ohio width={containerSize} height={containerSize} />
                ) : props.state === 'Maine' || props.state === 'ME' ? (
                    <Maine width={containerSize} height={containerSize} />
                ) : props.state === 'Kansas' || props.state === 'KS' ? (
                    <Kansas width={containerSize} height={containerSize} />
                ) : props.state === 'Guam' || props.state === 'GU' ? (
                    <Guam width={containerSize} height={containerSize} />
                ) : props.state === 'Minnesota' || props.state === 'MN' ? (
                    <Minnesota width={containerSize} height={containerSize} />
                ) : props.state === 'Oregon' || props.state === 'OR' ? (
                    <Oregon width={containerSize} height={containerSize} />
                ) : props.state === 'Tennessee' || props.state === 'TN' ? (
                    <Tennessee width={containerSize} height={containerSize} />
                ) : props.state === 'Texas' || props.state === 'TX' ? (
                    <Texas width={containerSize} height={containerSize} />
                ) : props.state === 'West Virginia' || props.state === 'WV' ? (
                    <WestVirginia width={containerSize} height={containerSize} />
                ) : props.state === 'Wyoming' || props.state === 'WY' ? (
                    <Wyoming width={containerSize} height={containerSize} />
                ) : null}{' '}
            </span>
        </span>
    );
}
