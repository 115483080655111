import React from 'react';
import { Comment, Tooltip, List, Input, Button, Avatar } from 'antd';
import moment from 'moment';
import avatar1 from '../../../assets/img/kolage-128.jpg';

const data = [
	{
		actions: [ <span key="comment-list-reply-to-0">Reply to</span> ],
		author: 'John Doe',
		avatar: avatar1,
		content: (
			<p>
				We supply a series of design principles, practical patterns and high quality design resources (Sketch
				and Axure), to help people create their product prototypes beautifully and efficiently.
			</p>
		),
		datetime: (
			<Tooltip title={moment().subtract(1, 'days').format('YYYY-MM-DD HH:mm:ss')}>
				<span>{moment().subtract(1, 'days').fromNow()}</span>
			</Tooltip>
		)
	},
	{
		actions: [ <span key="comment-list-reply-to-0">Reply to</span> ],
		author: 'John Doe',
		avatar: avatar1,
		content: (
			<p>
				We supply a series of design principles, practical patterns and high quality design resources (Sketch
				and Axure), to help people create their product prototypes beautifully and efficiently.
			</p>
		),
		datetime: (
			<Tooltip title={moment().subtract(2, 'days').format('YYYY-MM-DD HH:mm:ss')}>
				<span>{moment().subtract(2, 'days').fromNow()}</span>
			</Tooltip>
		)
	}
];

export default function DPLeagueComments(props) {
	return (
		<React.Fragment>
			<List
				className="comment-list"
				header={`${data.length} replies`}
				itemLayout="horizontal"
				dataSource={data}
				renderItem={(item) => (
					<li>
						<Comment
							actions={item.actions}
							author={item.author}
							avatar={item.avatar}
							content={item.content}
							datetime={item.datetime}
						/>
					</li>
				)}
			/>
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					paddingTop: 16,
					borderTop: '1px solid rgb(238, 238, 238)'
				}}
			>
				<Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" size={34} />
				<Input placeholder="Leave a comment..." size="large" style={{ marginLeft: 16 }} />
				<Button type="link" size="large" style={{ marginLeft: 10, textTransform: 'uppercase' }}>
					Submit
				</Button>
			</div>
		</React.Fragment>
	);
}
