import React from 'react';
import { Button, Typography } from 'antd';
import { Form, SubmitButton } from 'formik-antd';
import { Formik } from 'formik';
import RoundTimeEntry from './RoundTimeEntry';

const { Title } = Typography;

export default function MatchTimeOverlay(props) {
    const { formRef, loading, currentRound, currentMatch, scheduledTime, onSetScheduledTime, onSetShowMatchTimeOverlay, onChangeEntry } = props;

    return (
        <div
            style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(0,0,0,0.9)',
                zIndex: 10,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <div
                style={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    flexDirection: 'column',
                }}
            >
                <Title level={4} style={{ margin: 0, color: '#fff' }}>
                    Set match date and time
                </Title>
                <Formik
                    ref={formRef}
                    initialValues={{ scheduled_time: scheduledTime }}
                    // validationSchema={MatchSchema}
                    onSubmit={(values, actions) => {
                        console.log(JSON.stringify(values, null, 2));
                        console.log(values.scheduled_time);
                        // setLoading(true);

                        // const changes = {
                        //     scheduled_time: values.scheduled_time || null,
                        //     status: values.scheduled_time ? 'SCHEDULED' : 'NOT_STARTED',
                        // };

                        // console.log(changes);

                        onSetScheduledTime(values.scheduled_time ? values.scheduled_time.toString() : null);
                        onSetShowMatchTimeOverlay(false);

                        // updateMatchTime({
                        //     variables: {
                        //         tournament_id: tournament.id,
                        //         round: currentMatch.round,
                        //         id: currentMatch.id,
                        //         changes: changes,
                        //     },
                        //     notifyOnNetworkStatusChange: true,
                        //     awaitRefetchQueries: true,
                        //     refetchQueries: [
                        //         {
                        //             query: GET_TOURNAMENT_BRACKET_MATCH_QUERY,
                        //             variables: { match_id: currentMatch.id, tournament_id: tournament.id },
                        //         },
                        //     ],
                        // })
                        //     .then((data) => {
                        //         console.log(data);
                        //         setLoading(false);
                        //         setShowMatchTimeOverlay(false);
                        //         onModalScoreVisible(false);
                        //     })
                        //     .catch((error) => {
                        //         console.log(error);
                        //         setLoading(false);
                        //         message.error(JSON.stringify(error));
                        //     });

                        actions.setSubmitting(false);
                        actions.resetForm();
                    }}
                    render={({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => {
                        console.log(values);
                        return (
                            <Form onSubmit={handleSubmit} layout="vertical">
                                <RoundTimeEntry
                                    name="scheduled_time"
                                    onOk={onChangeEntry}
                                    round={currentRound}
                                    match={currentMatch}
                                    theme="dark"
                                    values={values}
                                />

                                <SubmitButton type="primary" size="large" disabled={loading === true ? true : false}>
                                    {loading === true ? 'Saving...' : 'OK'}
                                </SubmitButton>
                                <Button
                                    type="default"
                                    ghost
                                    size="large"
                                    onClick={() => {
                                        onSetShowMatchTimeOverlay(false);
                                    }}
                                >
                                    Cancel
                                </Button>
                            </Form>
                        );
                    }}
                />
            </div>
        </div>
    );
}
