import _ from 'lodash';
import Hashids from 'hashids';

function createBracketDBDataset(tournamentID, totalPlayers, maxPlayers, consolidationFinals, playersAdvancing) {
    const trueDoubleElim = consolidationFinals === true || consolidationFinals === 'true' ? 1 : 0;
    const numPlayers = totalPlayers;
    const winnerRounds = Math.ceil(Math.log2(numPlayers));
    const L2 = Math.log2(numPlayers);
    const requiredByes = maxPlayers - numPlayers;
    const loserRounds = Math.ceil(L2) + Math.ceil(Math.log2(L2));
    let totalRounds;
    if (maxPlayers > 64) {
        totalRounds = Math.ceil(winnerRounds + loserRounds + 1);
    } else {
        totalRounds = Math.ceil(winnerRounds + loserRounds);
    }

    const loserSideArr = loserRoundMatches(maxPlayers, loserRounds);
    const winnerSideArr = winnerRoundMatches(winnerRounds, 0, trueDoubleElim);
    const hashids = new Hashids(`Tournament salt`);
    // let seeds = generateSeeds(numPlayers, winnerRounds);
    // console.log(
    // 	'seeds',
    // 	seeds
    // 		.map((seed) => {
    // 			return seed[1];
    // 		})
    // 		.join(', ')
    // );

    let loserBracketMatchesArr = [];
    let winnerBracketMatchesArr = [];

    function getRandomArbitrary(min, max) {
        return Math.random() * (max - min) + min;
    }

    // add blank tournament bracket data
    function loserRoundMatches(numPlayers) {
        let loserRoundArr = [];
        let multiplier = numPlayers / 2;
        let i = 0;

        while (multiplier > 1) {
            if (i % 2 === 0) {
                multiplier = Math.ceil(multiplier / 2);
            }
            loserRoundArr.push(multiplier);
            i++;
        }

        loserRoundArr.push(1);
        return loserRoundArr.reverse();
    }

    function winnerRoundMatches(start, end, trueDoubleElim) {
        let extra = 1;
        if (trueDoubleElim === 1) {
            extra = extra + 1;
        }
        return Array(start - end + extra)
            .fill()
            .map((item, index) => {
                return Math.ceil(Math.pow(2, start - index) / 2);
            });
    }

    function changeIntoBye(seed, participantsCount) {
        //return seed <= participantsCount ?  seed : '{0} (= bye)'.format(seed);
        // return seed <= participantsCount ? seed : null;
        return seed;
    }

    function generateSeeds(participants, rounds) {
        if (participants < 2) {
            return [];
        }
        let matches = [[1, 2]];
        let newRounds = [];
        for (let i = rounds; i >= 1; i--) {
            newRounds.push(i);
        }

        for (var round = 1; round < rounds; round++) {
            var roundMatches = [];
            var sum = Math.pow(2, round + 1) + 1;

            for (var i = 0; i < matches.length; i++) {
                var home = changeIntoBye(matches[i][0], participants);
                var away = changeIntoBye(sum - matches[i][0], participants);
                roundMatches.push([home, away]);

                home = changeIntoBye(sum - matches[i][1], participants);
                away = changeIntoBye(matches[i][1], participants);
                roundMatches.push([home, away]);
            }
            matches = roundMatches;
        }

        return matches;
    }

    function createMatch(data) {
        const match = {
            tournament_id: data.tournament_id || '',
            round: data.round || '',
            hash_id: data.hash_id,
            identifier: data.identifier || '',
            is_finals: data.is_finals || false,
            is_semi_finals: data.is_semi_finals || false,
            is_consolidation_finals: data.is_consolidation_finals || false,
            players_left: data.playersLeft,
            winner_to: data.winner_to,
            winner_from: data.winner_from,
            loser_to: data.loser_to,
            loser_from: data.loser_from,
            advance_to: data.advance_to,
        };

        // if (data.winner_to) {
        //     match.winner_to = data.winner_to || '';
        // }
        // if (data.winner_from) {
        //     match.winner_from = data.winner_from || '';
        // }
        // if (data.loser_to) {
        //     match.loser_to = data.loser_to || '';
        // }
        // if (data.loser_from) {
        //     match.loser_from = data.loser_from || '';
        // }
        return match;
    }

    let matchesToAdvance = 0;
    let slotToAdvance = 1;

    // step 2 for creating batch insert
    const winnerBracket = winnerSideArr.map((roundMatches, index) => {
        let matches = [];
        let counter = 1;
        let playersLeft;

        for (var i = 0; i < roundMatches; i++) {
            console.log('round matches', roundMatches);
            console.log('match', i);

            playersLeft = roundMatches * 2;
            console.log('players left', playersLeft);

            // roundPlayers = totalPlayers / round;
            // initialPlayers = roundPlayers + roundPlayers / 2;
            // playersLeft = initialPlayers - roundPlayers / 2;
            // if (index % i === 0) {
            //     playersLeft = initialPlayers - roundPlayers / 2;
            // }

            const loserToRound = index === 0 ? 1 : index * 2;
            let loserToMatch =
                index === 0
                    ? i + (1 % 2) === 0
                        ? counter + 1
                        : counter
                    : index % 2 === 0
                    ? roundMatches / 2 - i > 0
                        ? roundMatches / 2 - i
                        : roundMatches + roundMatches / 2 - i
                    : index > 2
                    ? roundMatches / 2 - i > 0
                        ? Math.floor(roundMatches / 2 + i + 1)
                        : i + 1 - roundMatches + roundMatches / 2
                    : roundMatches - i;

            // const loserToMatch =
            // 	index === 0
            // 		? i + 1 % 2 === 0 ? counter + 1 : counter
            // 		: index % 2 === 0
            // 			? round / 2 - i > 0 ? round / 2 - i : round + round / 2 - i
            // 			: round / 2 - i > 0 ? Math.floor(round / 2 + i + 1) : i + 1 - round + round / 2;

            const identifier = `W${index + 1}-${i + 1}`;

            // Fix bug with loser side 9-12 placement for 128 player brackets only
            if (maxPlayers === 128) {
                if (identifier === 'W5-1') {
                    loserToMatch = 1;
                } else if (identifier === 'W5-2') {
                    loserToMatch = 2;
                } else if (identifier === 'W5-3') {
                    loserToMatch = 3;
                } else if (identifier === 'W5-4') {
                    loserToMatch = 4;
                }
            }

            const loserTo = `L${loserToRound}-${loserToMatch < 1 ? 1 : loserToMatch}`;

            const winnerToRound = index + 2;
            let winnerToMatch;
            if (i % 2 === 1) {
                counter++;
            }
            winnerToMatch = i === 0 ? 1 : i % 2 === 0 ? counter : counter - 1;
            // let winnerToMatchPosition = i === 0 ? 'A' : i % 2 === 0 ? 'A' : 'B';

            const winnerTo = `W${winnerToRound}-${winnerToMatch}`;
            const newRound = index + 1;
            const randomNum = getRandomArbitrary(1, 10000).toFixed(0);
            const hashId = hashids.encode(randomNum, tournamentID, index + 1, i + 1);
            let match;

            console.log({
                identifier,
                hashId,
                winnerTo,
                loserTo,
                playersLeft,
                playersAdvancing,
            });

            let winnerToStage;
            console.log('winner side rounds');
            console.log('total players', totalPlayers);
            console.log('winner rounds', winnerRounds);
            console.log('round', newRound);
            const roundsToRemove = Math.ceil(Math.log2(playersAdvancing / 2));
            const advancingRound = roundsToRemove;
            console.log('Rounds to remove', roundsToRemove);
            console.log('Advancing round', advancingRound);

            if (playersAdvancing === 2) {
                if (newRound === winnerRounds - roundsToRemove) {
                    winnerToStage = `S2-W1-${slotToAdvance}`;
                    // matchesToAdvance++;
                    slotToAdvance = slotToAdvance + 2;
                } else {
                    winnerToStage = null;
                }
            } else {
                if (newRound === winnerRounds - roundsToRemove + 1) {
                    winnerToStage = `S2-W1-${slotToAdvance}`;
                    // matchesToAdvance++;
                    slotToAdvance = slotToAdvance + 2;
                } else {
                    winnerToStage = null;
                }
            }

            console.log('winner to stage', winnerToStage);

            if (index === 0) {
                console.log('add first match');
                match = createMatch({
                    tournament_id: tournamentID,
                    round: newRound,
                    identifier: identifier,
                    hash_id: hashId,
                    winner_to: winnerTo,
                    loser_to: loserTo,
                    players_left: playersLeft,
                    advance_to: winnerToStage,
                    // seed: seeds[i]
                    // status: 1
                });
            } else if (trueDoubleElim === 1 && index === winnerSideArr.length - 3) {
                console.log('add semi finals');
                match = createMatch({
                    tournament_id: tournamentID,
                    round: newRound,
                    identifier: identifier,
                    hash_id: hashId,
                    winner_to: winnerTo,
                    loser_to: loserTo,
                    advance_to: winnerToStage,
                    players_left: playersLeft,
                    is_semi_finals: true,
                    is_finals: false,
                    is_consolidation_finals: false,
                });
            } else if (trueDoubleElim === 1 && index === winnerSideArr.length - 2) {
                console.log('add finals');
                match = createMatch({
                    tournament_id: tournamentID,
                    round: newRound,
                    identifier: identifier,
                    hash_id: hashId,
                    winner_to: winnerTo,
                    loser_to: loserTo,
                    advance_to: winnerToStage,
                    players_left: playersLeft,
                    is_semi_finals: false,
                    is_finals: true,
                    is_consolidation_finals: false,
                });
            } else if (trueDoubleElim === 1 && index === winnerSideArr.length - 1) {
                console.log('add consolidation finals');
                match = createMatch({
                    tournament_id: tournamentID,
                    round: newRound,
                    identifier: identifier,
                    hash_id: hashId,
                    winner_to: winnerTo,
                    loser_to: loserTo,
                    advance_to: winnerToStage,
                    players_left: playersLeft,
                    is_semi_finals: false,
                    is_finals: false,
                    is_consolidation_finals: true,
                });
            } else if (trueDoubleElim !== 1 && index === winnerSideArr.length - 2) {
                console.log('add semi finals');
                match = createMatch({
                    tournament_id: tournamentID,
                    round: newRound,
                    identifier: identifier,
                    hash_id: hashId,
                    winner_to: winnerTo,
                    loser_to: loserTo,
                    advance_to: winnerToStage,
                    players_left: playersLeft,
                    is_semi_finals: true,
                    is_finals: false,
                    is_consolidation_finals: false,
                });
            } else if (trueDoubleElim !== 1 && index === winnerSideArr.length - 1) {
                console.log('add finals');
                match = createMatch({
                    tournament_id: tournamentID,
                    round: newRound,
                    identifier: identifier,
                    hash_id: hashId,
                    winner_to: winnerTo,
                    loser_to: loserTo,
                    advance_to: winnerToStage,
                    players_left: playersLeft,
                    is_semi_finals: false,
                    is_finals: true,
                    is_consolidation_finals: false,
                });
            } else {
                console.log('add other matches');
                match = createMatch({
                    tournament_id: tournamentID,
                    round: newRound,
                    identifier: identifier,
                    hash_id: hashId,
                    winner_to: winnerTo,
                    loser_to: loserTo,
                    advance_to: winnerToStage,
                    players_left: playersLeft,
                    // seed: seeds[i]
                });
            }
            // Insert players in the first round
            matches.push(match);
            winnerBracketMatchesArr.push(match);
        }
        return matches;
    });

    // step 1 for creating batch insert
    let loserBracket = loserSideArr.map((roundMatches, index) => {
        let matches = [];
        let counter = 1;
        let playersLeft;

        for (var i = 0; i < roundMatches; i++) {
            let loserFrom = null;
            const roundNum = loserSideArr.length - index;
            let winnerToRound = roundNum + 1;
            let winnerToMatch;
            let winnerTo;
            playersLeft = roundMatches * 2;
            console.log('players left', playersLeft);
            // let winnerToPosition;
            if (i % 2 === 1) {
                counter++;
            }
            // The last match of the losers side goes back to the winners side final
            if (index === 0) {
                winnerTo = `W${trueDoubleElim === 1 ? winnerSideArr.length - 1 : winnerSideArr.length}-1`;
            } else {
                if (index % 2 === 1) {
                    winnerToMatch = i + 1;
                    winnerTo = `L${winnerToRound}-${winnerToMatch}`;
                } else {
                    winnerToMatch = i === 0 ? 1 : i % 2 === 0 ? counter : counter - 1;
                    // winnerToPosition = i === 0 ? 'A' : i % 2 === 0 ? 'A' : 'B';
                    winnerTo = `L${winnerToRound}-${winnerToMatch}`;
                }
            }

            const identifier = `L${roundNum}-${i + 1}`;
            const newRound = parseInt(`-${loserSideArr.length - index}`);
            const randomNum = getRandomArbitrary(1, 1000).toFixed(0);
            const hashId = hashids.encode(randomNum, tournamentID, roundNum, i + 1);

            winnerBracket.forEach((round) => {
                round.filter((match) => {
                    if (match.loser_to === identifier) {
                        loserFrom = match.identifier;
                    }
                });
            });

            // let winnerToStage;
            // if (playersAdvancing / 2 === playersLeft && matchesToAdvance < playersAdvancing / 2 && newRound % 2) {
            //     console.log('loser from', loserFrom);
            //     winnerToStage = `S2-M${matchesToAdvance + 1}`;
            //     matchesToAdvance++;
            // } else {
            //     winnerToStage = null;
            // }

            let winnerToStage;
            console.log('loser side rounds');
            console.log('total players', totalPlayers);
            console.log('loser rounds', loserRounds);
            console.log('round', newRound);
            console.log('players advancing', playersAdvancing);

            // const L2 = Math.log2(playersAdvancing / 2);
            // const loserNum = Math.ceil(L2) + Math.ceil(Math.log2(L2));

            // console.log('loser num', loserNum);

            // let advancingRounds = [];
            // advancingRounds[2] = null;
            // advancingRounds[4] = loserRounds;
            // advancingRounds[8] = loserRounds - 2;
            // advancingRounds[16] = loserRounds - 4;
            // advancingRounds[32] = loserRounds - 6;
            // advancingRounds[64] = loserRounds - 7;
            // advancingRounds[128] = loserRounds - 8;
            // advancingRounds[256] = loserRounds - 9;
            // const advancingRound = -advancingRounds[playersAdvancing];
            const advancingRound = playersAdvancing > 2 ? playersAdvancing / 2 : 2;
            console.log('Advancing round', advancingRound);

            // if (playersLeft / 2 === playersAdvancing / 2) {
            if (playersAdvancing && playersLeft === advancingRound && newRound % 2) {
                winnerToStage = `S2-W1`;
                // winnerToStage = `S2-W1-${slotToAdvance}`;
                // matchesToAdvance++;
                // slotToAdvance++;
            } else {
                winnerToStage = null;
            }
            console.log('winner to stage', winnerToStage);

            const match = createMatch({
                tournament_id: tournamentID,
                round: newRound,
                identifier: identifier,
                hash_id: hashId,
                loser_from: loserFrom,
                winner_to: winnerTo,
                advance_to: winnerToStage,
                players_left: playersLeft,
            });
            matches.push(match);
            loserBracketMatchesArr.push(match);
        }
        return matches;
    });

    // Re-assign match IDs to start counting from loser round 1 match 1
    // let count = matchNumber - totalLoserMatches;
    // loserBracket = loserBracket
    // 	.reverse()
    // 	.map((round, index) => {
    // 		let rounds = round.map((match, i) => {
    // 			let matchData = {
    // 				...match,
    // 				id: count
    // 			};
    // 			count++;
    // 			return matchData;
    // 		});
    // 		return rounds;
    // 	})
    // 	.reverse();

    const brackets = [...winnerBracketMatchesArr, ...loserBracketMatchesArr];

    console.log('brackets', brackets);

    // generate the correct order in which the rounds should be played
    const roundOrder = setRoundOrdering(totalRounds, maxPlayers, trueDoubleElim);
    console.log('round order:', roundOrder);
    // console.log('hard coded pattern:', [ 1, 2, -1, -2, 3, -3, -4, 4, -5, -6, 5, -7, -8, 6 ]);

    let rounds = roundOrder.map((round) => {
        const roundMatches = getMatchesByRound(round, brackets);
        return roundMatches;
    });

    console.log('rounds:', rounds);

    // update match numbers to reflect new round ordering
    const ordering = setMatchNumbers(rounds, trueDoubleElim);

    console.log('ordering w/match numbers:', ordering);

    let prevPlayersLeft;
    let orderingLosers = ordering.filter((round) => {
        return round[0] && round[0].round < 2;
    });

    console.log('ordering losers', orderingLosers);

    let loserBracketPlacement = orderingLosers
        .map((round, index) => {
            let roundPlayers = round.length;
            let initialPlayers;
            let playersLeft;
            let place;

            if (index === 0) {
                initialPlayers = maxPlayers;
                // initialPlayers = maxPlayers - requiredByes; // 128, 64, 32
                playersLeft = round.length * 2 - round.length / 2; // 96, 65, 49, 33
            } else {
                initialPlayers = prevPlayersLeft;
                playersLeft = initialPlayers - roundPlayers;
                if (index % 2 === 0) {
                    playersLeft = initialPlayers - roundPlayers / 2;
                }
            }

            if (initialPlayers > 4) {
                place = `${playersLeft + 1}-${initialPlayers}`;
            } else {
                if (initialPlayers === 2) {
                    place = 3; // tie for 3rd
                } else {
                    place = initialPlayers;
                }
                // place = initialPlayers;
            }

            console.log({
                place: place.toString(),
                roundPlayers,
                length: round.length,
                round,
            });

            prevPlayersLeft = playersLeft;
            return place.toString();
            // return {
            // 	...round.map((match) => {
            // 		return {
            // 			...match,
            // 			place: place.toString()
            // 		};
            // 	})
            // };
        })
        .slice(0, orderingLosers.length - 1);

    let orderingWinners = ordering.filter((round) => {
        return round[0] && round[0].round > 0;
    });

    console.log('ordering winners', orderingWinners);

    let winnerBracketPlacement = orderingWinners.map((round, index) => {
        let roundPlayers = round.length;
        let initialPlayers;
        let playersLeft;
        let place;

        initialPlayers = roundPlayers + roundPlayers / 2;
        playersLeft = initialPlayers - roundPlayers / 2;

        if (initialPlayers > 3) {
            place = `${playersLeft + 1}-${initialPlayers}`;
        } else {
            if (index === orderingWinners.length - 1) {
                place = 1;
            } else if (index === orderingWinners.length - 2) {
                if (trueDoubleElim === 1) {
                    place = 1;
                } else {
                    place = 2;
                }
            } else if (index === orderingWinners.length - 3 && trueDoubleElim === 1) {
                place = 2;
            } else {
                place = 3;
            }
        }

        // console.log({
        // 	place: place.toString(),
        // 	roundPlayers,
        // 	length: round.length,
        // 	round
        // });

        return place.toString();

        // return {
        // 	...round.map((match) => {
        // 		return {
        // 			...match,
        // 			place: place.toString()
        // 		};
        // 	})
        // };
    });

    const loserBracket2 = ordering
        .filter((rounds) => {
            return rounds[0] && rounds[0].round < 1;
        })
        .map((rounds, index) => {
            return rounds.map((match) => {
                return {
                    ...match,
                    place: loserBracketPlacement[index],
                };
            });
        });

    const draw2 = ordering.filter((rounds) => {
        return rounds[0] && rounds[0].round === 1;
    });

    const winnerBracket2 = ordering
        .filter((rounds) => {
            return rounds[0] && rounds[0].round > 1;
        })
        .map((rounds, index) => {
            return rounds.map((match) => {
                return {
                    ...match,
                    place: winnerBracketPlacement[index],
                };
            });
        });

    const bracketWithPlacements = [...loserBracket2, ...draw2, ...winnerBracket2];

    let rounds2 = roundOrder.map((round) => {
        const roundMatches = getMatchesByRound(round, _.flatten(bracketWithPlacements));
        return roundMatches;
    });

    // update match numbers to reflect new round ordering
    const ordering2 = setMatchNumbers(rounds2, trueDoubleElim);

    let batchInsertWithPlacement = _.flatten(ordering2);

    console.log('ordering1', _.flatten(ordering));
    console.log('ordering2', _.flatten(ordering2));

    // const loserBracket2 = ordering
    // 	.filter((rounds) => {
    // 		return rounds[0].round < 1;
    // 	})
    // 	.reverse();

    // const draw2 = ordering.filter((rounds) => {
    // 	return rounds[0].round === 1;
    // });

    // const winnerBracket2 = ordering.filter((rounds) => {
    // 	return rounds[0].round > 1;
    // });

    // let batchInsertArr = [ ..._.flatten(loserBracket2), ..._.flatten(draw2), ..._.flatten(winnerBracket2) ];
    return {
        loserPlacement: loserBracketPlacement,
        winnerPlacement: winnerBracketPlacement,
        matches: batchInsertWithPlacement,
    };
}

function setRoundOrdering(totalRounds, maxPlayers, trueDoubleElim) {
    let winnerRoundCounter = 0;
    let loserRoundCounter = 0;
    let roundOrder = [];

    Array.from({ length: totalRounds }, (v, index) => {
        if (index === 0) {
            winnerRoundCounter++;
            roundOrder.push(winnerRoundCounter);
        }

        if (index === totalRounds - 1 && maxPlayers > 64) {
            winnerRoundCounter++;
            roundOrder.push(winnerRoundCounter);
        }
        if (index === totalRounds - 1 && trueDoubleElim === 1) {
            winnerRoundCounter++;
            roundOrder.push(winnerRoundCounter);
        }

        if (index % 3 === 1) {
            loserRoundCounter--;
            roundOrder.push(loserRoundCounter);
            loserRoundCounter--;
            roundOrder.push(loserRoundCounter);
        } else if (index % 2 === 0) {
            winnerRoundCounter++;
            roundOrder.push(winnerRoundCounter);
        }
    });
    console.log('total rounds:', totalRounds);
    console.log('round ordering:', roundOrder);
    return roundOrder;
}

function getMatchByIdentifier(matches, identifier) {
    var found = [];
    matches.forEach((match, index) => {
        if (match.identifier === identifier) {
            found.push(match);
        }
    });
    return found;
}

function setMatchNumbers(rounds, trueDoubleElim) {
    let counter = 1;
    let ordering = rounds.map((round, index) => {
        return round.map((match, i) => {
            const newMatch = {
                ...match,
                match_number: counter,
            };
            counter++;
            return newMatch;
        });
    });

    const matchNumberMapping = ordering.map((round, index) => {
        return round.map((match, index2) => {
            const winnerNextMatch = getMatchByIdentifier(_.flatten(ordering), match.winner_to);
            const winnerPrevMatch = getMatchByIdentifier(_.flatten(ordering), match.winner_from);
            const loserNextMatch = getMatchByIdentifier(_.flatten(ordering), match.loser_to);
            const loserPrevMatch = getMatchByIdentifier(_.flatten(ordering), match.loser_from);

            // console.log({
            // 	identifier: match.identifier,
            // 	match,
            // 	winnerNextMatch,
            // 	loserNextMatch,
            // 	loserPrevMatch
            // });

            if (winnerNextMatch.length) {
                if (trueDoubleElim === 1) {
                    // console.log('TRUE DOUBLE ELIM');
                    // console.log(`index: ${index} rounds: ${rounds.length}`);

                    if (index === rounds.length - 5) {
                        // console.log('NEXT MATCH = FINALS', winnerNextMatch);
                        // console.log(`${winnerNextMatch[0].identifier} set winner from bottom num:`, match.match_number);
                        winnerNextMatch[0].winner_from_bottom_num = match.match_number;
                    } else if (index === rounds.length - 4) {
                        // console.log('NEXT MATCH = CONSOLIDATION FINALS', winnerNextMatch);
                        // console.log(`${winnerNextMatch[0].identifier} set winner from top num:`, match.match_number);
                        // console.log(`${winnerNextMatch[0].identifier} set winner from bottom num:`, match.match_number);
                        winnerNextMatch[0].winner_from_top_num = match.match_number;
                        winnerNextMatch[0].loser_from_top_num = match.match_number;
                    } else {
                        if (match.match_number % 2 === 1) {
                            console.log(`${winnerNextMatch[0].identifier} set winner from top num:`, match.match_number);
                            winnerNextMatch[0].winner_from_top_num = match.match_number;
                        } else if (!winnerNextMatch[0].winner_from_bottom_num) {
                            console.log(`${winnerNextMatch[0].identifier} set winner from bottom num:`, match.match_number);
                            // console.log('set bottom num', match.match_number);
                            winnerNextMatch[0].winner_from_bottom_num = match.match_number;
                        }
                    }
                } else {
                    // console.log('REGULAR ELIM');
                    // console.log(`index: ${index} rounds: ${rounds.length}`);
                    if (index === rounds.length - 4) {
                        // console.log('NEXT MATCH = FINALS', winnerNextMatch);
                        // console.log(`${winnerNextMatch[0].identifier} set winner from top num:`, match.match_number);
                        winnerNextMatch[0].winner_from_bottom_num = match.match_number;
                    } else if (index === rounds.length - 6) {
                        // console.log('NEXT MATCH = SEMI FINALS', winnerNextMatch);
                        // console.log(`${winnerNextMatch[0].identifier} set winner from bottom num:`, match.match_number);
                        winnerNextMatch[0].winner_from_top_num = match.match_number;
                    } else {
                        if (match.match_number % 2 === 1) {
                            console.log(`${winnerNextMatch[0].identifier} set winner from top num:`, match.match_number);
                            winnerNextMatch[0].winner_from_top_num = match.match_number;
                        } else if (!winnerNextMatch[0].winner_from_bottom_num) {
                            console.log(`${winnerNextMatch[0].identifier} set winner from bottom num:`, match.match_number);
                            // console.log('set bottom num', match.match_number);
                            winnerNextMatch[0].winner_from_bottom_num = match.match_number;
                        }
                    }
                }
            }

            if (loserNextMatch.length) {
                if (match.match_number % 2 === 1) {
                    loserNextMatch[0].loser_from_top_num = match.match_number;
                } else if (!loserNextMatch[0].loser_from_bottom_num) {
                    loserNextMatch[0].loser_from_bottom_num = match.match_number;
                }
            }

            return {
                ...match,
                ...(match.winner_to && {
                    winner_to_num: winnerNextMatch.length ? winnerNextMatch[0].match_number : undefined,
                }),
                ...(match.winner_from && {
                    winner_from_num: winnerPrevMatch.length ? winnerPrevMatch[0].match_number : undefined,
                }),
                ...(match.loser_to && {
                    loser_to_num: loserNextMatch.length ? loserNextMatch[0].match_number : undefined,
                }),
                ...(match.loser_from && {
                    loser_from_num: loserPrevMatch.length ? loserPrevMatch[0].match_number : undefined,
                }),
            };
        });
    });
    // console.table(_.flatten(matchNumberMapping));
    return matchNumberMapping;
}

function getMatchesByRound(round, bracket) {
    const matches = [];
    bracket.forEach((item, index) => {
        if (item.round === round) {
            matches.push(item);
        }
    });
    return matches;
}

export default createBracketDBDataset;
