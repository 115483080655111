import React, { useState, useEffect } from 'react';
import { message, Button, Row, List, Input, Radio, Switch, Col, Tag, Typography, Table, Statistic, Tabs } from 'antd';
import Card, { Meta } from '../../components/Card';
import { Link, withRouter } from 'react-router-dom';
import useBreakpoint from 'use-breakpoint';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { defaultBreakpoints } from '../../utils/defaultBreakpoints';
import SectionContent from '../../components/SectionContent';
import SectionWrapper from '../../components/SectionWrapper';
import SectionTitle from '../../components/SectionTitle';
import advertisingPreview from '../../assets/img/advertising-preview.png';
import adTemplateHomepageDesktop from '../../assets/img/advertising/templates/nbl/Homepage - Desktop 970x250.png';
import adTemplateHomepageTablet from '../../assets/img/advertising/templates/nbl/Homepage - Tablet 728x90.png';
import adTemplateHomepageMobile from '../../assets/img/advertising/templates/nbl/Homepage - Mobile 320x100.png';
import adTemplateInnerDesktop from '../../assets/img/advertising/templates/nbl/Inner Pages - Desktop 970x90.png';
import adTemplateInnerTablet from '../../assets/img/advertising/templates/nbl/Inner Pages - Tablet 728x90.png';
import adTemplateInnerMobile from '../../assets/img/advertising/templates/nbl/Inner Pages - Mobile 320x100.png';

const { Title, Text } = Typography;

const styles = {};

function Advertising(props) {
    const { authState } = props;
    const { breakpoint, maxWidth, minWidth } = useBreakpoint(defaultBreakpoints);
    const [pricing, setPricing] = useState('monthly');

    function onPricingToggle(event) {
        const pricing = event.target.value;
        setPricing(pricing);
    }

    const prices = {
        starter: {
            monthly: '49',
            annually: '44',
        },
        plus: {
            monthly: '89',
            annually: '80',
        },
        pro: {
            monthly: '149',
            annually: '134',
        },
    };

    return (
        <React.Fragment>
            <Helmet>
                <title>Advertising on Digital Pool</title>
                <meta
                    name="description"
                    content="Connect your brand to our growing community of players, tournament directors, league operators, venues, businesses and billiard enthusiasts around the world!"
                />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Advertising on Digital Pool" />
                <meta
                    name="twitter:description"
                    content="Connect your brand to our growing community of players, tournament directors, league operators, venues, businesses and billiard enthusiasts around the world!"
                />
                <meta name="twitter:image" content="https://digitalpool.com/images/advertising-promo.png" />
                <meta property="og:title" content="Advertising on Digital Pool" />
                <meta
                    property="og:description"
                    content="Connect your brand to our growing community of players, tournament directors, league operators, venues, businesses and billiard enthusiasts around the world!"
                />
                <meta property="og:image" content="https://digitalpool.com/images/advertising-promo.png" />
                <meta property="og:url" content={`https://www.digitalpool.com/advertising`} />
                <meta property="og:site_name" content="digitalpool.com" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="website" />
                <meta property="fb:app_id" content="1697957547011275" />
            </Helmet>

            <SectionWrapper>
                {/* <SectionTitle title="Advertising" height={breakpoint === 'mobile' ? 80 : 100} /> */}
                <SectionContent padding="0px">
                    <Row gutter={16} justify="center" type="flex" style={{ background: 'linear-gradient(45deg, #2a00ff, #C921FA)' }}>
                        <Col xs={22} sm={20} lg={15}>
                            <div
                                style={{
                                    marginTop: 30,
                                    marginBottom: 40,
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexDirection: breakpoint === 'mobile' ? 'column' : 'row',
                                }}
                            >
                                <div style={{ textAlign: breakpoint === 'mobile' ? 'center' : 'left' }}>
                                    <Title level={breakpoint === 'desktop' ? 1 : 2} style={{ color: '#fff', marginTop: 0 }}>
                                        Advertising on Digital Pool
                                    </Title>
                                    <Text style={{ fontSize: breakpoint === 'desktop' ? 25 : 20, color: '#fff' }}>
                                        Connect your brand to a fast growing community of pool players, tournament directors, league operators, venues,
                                        businesses and billiard enthusiasts around the world!
                                    </Text>
                                    {/* <div style={{ marginTop: 20 }}>
                                        <a href="https://digitalpool.orders.adbutler.com" target="_blank" rel="noopener noreferrer">
                                            <Button type="primary" size="large" style={{ textTransform: 'uppercase', fontWeight: 700, padding: '0px 20px' }}>
                                                Advertise With Us
                                            </Button>
                                        </a>
                                    </div> */}
                                </div>
                                <div style={{ marginLeft: breakpoint === 'desktop' ? 20 : 0, marginTop: breakpoint === 'desktop' ? 0 : 20 }}>
                                    <img
                                        src={advertisingPreview}
                                        alt="Advertising"
                                        style={{ height: breakpoint === 'desktop' ? 450 : 'auto', width: breakpoint === 'desktop' ? 'auto' : '90%' }}
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row
                        gutter={16}
                        type="flex"
                        justify="center"
                        // style={{
                        //     boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.05), 0 15px 37px 0 #dbe2eb',
                        // }}
                    >
                        <Col xs={24} sm={24} md={24} lg={20} xl={20}>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    position: 'relative',
                                    marginLeft: 24,
                                    marginRight: 24,
                                }}
                            >
                                <Row gutter={16} type="flex" justify="center" style={{ marginTop: 40, marginBottom: 40 }}>
                                    <Col xs={24} sm={20} md={16} align="center">
                                        <Title level={2}>Advertising Packages</Title>
                                    </Col>

                                    <Col xs={24} sm={20} md={16} align="center">
                                        {/* {isMonthly === true ? 'Monthly' : 'Annually'}{' '}
                                        <Switch defaultChecked={isMonthly} onChange={onMonthlyToggle} style={{ marginLeft: 5 }} /> */}
                                        <div style={{ marginBottom: 10 }}>
                                            <Radio.Group onChange={onPricingToggle} value={pricing} defaultValue="monthly" size="large" buttonStyle="solid">
                                                <Radio.Button value="monthly">Monthly</Radio.Button>
                                                <Radio.Button value="annually">Annually</Radio.Button>
                                            </Radio.Group>
                                        </div>
                                        <Text>Save up to 10% when you pay annually. Offer available for Plus and Pro plans.</Text>
                                    </Col>

                                    <Col xs={24} sm={20} md={16} align="center">
                                        <Row gutter={16} type="flex" justify="center">
                                            {/* <Col xs={24} sm={20}>
                                    <Title level={2} style={{ textAlign: 'center' }}>
                                        We help businesses of all sizes reach pool players everywhere!
                                    </Title>
                                </Col> */}
                                            <Col xs={24} sm={8}>
                                                <Card title="Starter">
                                                    <Statistic
                                                        value={pricing === 'monthly' ? prices.starter.monthly : prices.starter.annually}
                                                        prefix="$"
                                                        suffix="/mo"
                                                        valueStyle={{ fontSize: 40 }}
                                                    />
                                                    {/* <div style={{ marginTop: 20, marginBottom: 20 }}>
                                                        <Text style={{ fontSize: 16 }}>
                                                            Run banner ads on DigitalPool.com and reach an audience of over 50,000 pool players!
                                                        </Text>
                                                    </div> */}

                                                    <List
                                                        size="small"
                                                        dataSource={[
                                                            '✓ 30,000 impressions per month',
                                                            '✓ Most high-traffic pages',
                                                            '✓ Static banner ads',
                                                            '✓ Inner page placement',
                                                            '✓ Desktop, Tablet & Mobile formats included',
                                                            '✓ Basic support included',
                                                            '✓ Reporting dashboard',
                                                        ]}
                                                        renderItem={(item) => (
                                                            <List.Item style={{ display: 'flex', justifyContent: 'center' }}>
                                                                <Text>{item}</Text>
                                                            </List.Item>
                                                        )}
                                                    />
                                                    <div style={{ marginTop: 15 }}>
                                                        <a
                                                            href="mailto:contact@digitalpool.com?subject=Starter Package"
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            <Button
                                                                type="primary"
                                                                size="large"
                                                                style={{ textTransform: 'uppercase', fontWeight: 700, padding: '0px 20px' }}
                                                            >
                                                                Contact Us
                                                            </Button>
                                                        </a>
                                                    </div>
                                                </Card>
                                            </Col>
                                            <Col xs={24} sm={8}>
                                                <Card title="Plus">
                                                    <Statistic
                                                        value={pricing === 'monthly' ? prices.plus.monthly : prices.plus.annually}
                                                        prefix="$"
                                                        suffix="/mo"
                                                        valueStyle={{ fontSize: 40 }}
                                                    />

                                                    <Tag color="#f50">MOST POPULAR</Tag>

                                                    {/* <div style={{ marginTop: 20, marginBottom: 20 }}>
                                                        <Text style={{ fontSize: 16 }}>
                                                            Run social media ads on Digital Pool's growing Instagram and Facebook channels with over 37,000
                                                            followers.
                                                        </Text>
                                                    </div> */}

                                                    <List
                                                        size="small"
                                                        dataSource={[
                                                            '✓ 50,000 impressions per month',
                                                            '✓ All high-traffic pages',
                                                            '✓ Static and rich media banner ads',
                                                            '✓ Home page placement',
                                                            '✓ Inner page placement',
                                                            '✓ Desktop, Tablet & Mobile formats included',
                                                            '✓ Basic support included',
                                                            '✓ Reporting dashboard',
                                                        ]}
                                                        renderItem={(item) => (
                                                            <List.Item style={{ display: 'flex', justifyContent: 'center' }}>
                                                                <Text>{item}</Text>
                                                            </List.Item>
                                                        )}
                                                    />
                                                    <div style={{ marginTop: 15 }}>
                                                        <a href="mailto:contact@digitalpool.com?subject=Plus Package" target="_blank" rel="noopener noreferrer">
                                                            <Button
                                                                type="primary"
                                                                size="large"
                                                                style={{ textTransform: 'uppercase', fontWeight: 700, padding: '0px 20px' }}
                                                            >
                                                                Contact Us
                                                            </Button>
                                                        </a>
                                                    </div>
                                                </Card>
                                            </Col>
                                            <Col xs={24} sm={8}>
                                                <Card title="Pro">
                                                    <Statistic
                                                        value={pricing === 'monthly' ? prices.pro.monthly : prices.pro.annually}
                                                        prefix="$"
                                                        suffix="/mo"
                                                        valueStyle={{ fontSize: 40 }}
                                                    />

                                                    {/* <div style={{ marginTop: 20, marginBottom: 20 }}>
                                                        <Text style={{ fontSize: 16 }}>
                                                            Run native ads in Digital Pool's mobile player app for iOS and Android (coming soon!)
                                                        </Text>
                                                    </div> */}

                                                    <List
                                                        size="small"
                                                        dataSource={[
                                                            '✓ 100,000 impressions per month',
                                                            '✓ All high-traffic pages',
                                                            '✓ Static and rich media banner ads',
                                                            '✓ Home page placement',
                                                            '✓ Inner page placement',
                                                            '✓ Desktop, Tablet & Mobile formats included',
                                                            '✓ Run multiple ads at once',
                                                            '✓ Facebook and Instagram ad',
                                                            '✓ Priority support included',
                                                            '✓ Reporting dashboard',
                                                        ]}
                                                        renderItem={(item) => (
                                                            <List.Item style={{ display: 'flex', justifyContent: 'center' }}>
                                                                <Text>{item}</Text>
                                                            </List.Item>
                                                        )}
                                                    />
                                                    <div style={{ marginTop: 15 }}>
                                                        <a href="mailto:contact@digitalpool.com?subject=Pro Package" target="_blank" rel="noopener noreferrer">
                                                            <Button
                                                                type="primary"
                                                                size="large"
                                                                style={{ textTransform: 'uppercase', fontWeight: 700, padding: '0px 20px' }}
                                                            >
                                                                Contact Us
                                                            </Button>
                                                        </a>
                                                    </div>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </Col>

                                    <Col xs={24} sm={20} md={16} align="center">
                                        <div
                                            style={{
                                                backgroundColor: '#eaf2ff',
                                                marginTop: 20,
                                                padding: 20,
                                                borderRadius: 10,
                                                textAlign: 'center',
                                            }}
                                        >
                                            <Text style={{ fontSize: 18, color: 'rgba(0, 0, 0, 0.85)' }}>
                                                You can also reach out to us at{' '}
                                                <a href="mailto:contact@digitalpool.com" target="_blank" rel="noopener noreferrer">
                                                    contact@digitalpool.com
                                                </a>{' '}
                                                to explore custom opportunities!
                                            </Text>
                                        </div>
                                    </Col>

                                    {/* <Col xs={20} align="center">
                                    <Row gutter={16} type="flex" justify="center">
                                        <Col xs={24} sm={6}>
                                           <Statistic title="Avg. Monthly Pageviews" value={"100k"} style={{ color: '#000' }} />
                                            
                                        </Col>
                                        <Col xs={24} sm={6}>
                                            <Statistic title="Avg. Monthly Pageviews" value={"100k"} style={{ color: '#000' }} />
                                           
                                        </Col>
                                    </Row>
                                </Col> */}
                                </Row>
                            </div>
                        </Col>
                    </Row>

                    <Row gutter={16} justify="center" type="flex" style={{ backgroundColor: '#0c192b' }}>
                        <Col xs={22} sm={12} style={{ paddingTop: 50, paddingBottom: 50 }}>
                            <Row>
                                <Col xs={24} align="center">
                                    <Title level={2} style={{ color: '#fff' }}>
                                        Flexible options for any budget.
                                    </Title>
                                </Col>
                                <Col xs={24} align="center">
                                    <Text style={{ fontSize: 18, color: '#fff' }}>
                                        We also have flexible pricing options for every kind of advertiser big or small. Start advertising for as little as{' '}
                                        <b>$25</b> through our convenient self-serve advertising portal.
                                    </Text>
                                </Col>
                                <Col xs={24} align="center" style={{ marginTop: 10 }}>
                                    <a href="https://digitalpool.orders.adbutler.com" target="_blank" rel="noopener noreferrer">
                                        <Button type="primary" size="large" style={{ textTransform: 'uppercase', fontWeight: 700, padding: '0px 20px' }}>
                                            Get started
                                        </Button>
                                    </a>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row
                        gutter={16}
                        justify="center"
                        type="flex"
                        style={
                            {
                                // boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.05), 0 15px 37px 0 #dbe2eb',
                            }
                        }
                    >
                        <Col xs={22} sm={12} style={{ paddingTop: 50, paddingBottom: 50 }}>
                            <Row gutter={100} type="flex" justify="center">
                                {/* <Col xs={24} sm={20}>
                                    <Title level={2} style={{ textAlign: 'center' }}>
                                        We help businesses of all sizes reach pool players everywhere!
                                    </Title>
                                </Col> */}
                                <Col xs={24} sm={8} style={{ marginTop: 10, marginBottom: 10 }}>
                                    <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                                        <div>
                                            <Title level={4} style={{ fontWeight: 600 }}>
                                                Banner Ads
                                            </Title>
                                            <Text style={{ fontSize: 16 }}>
                                                Run banner ads on DigitalPool.com and reach an audience of over 50,000 pool players!
                                            </Text>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={24} sm={8} style={{ marginTop: 10, marginBottom: 10 }}>
                                    <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                                        <div>
                                            <Title level={4} style={{ fontWeight: 600 }}>
                                                Social Media Ads
                                            </Title>
                                            <Text style={{ fontSize: 16 }}>
                                                Run social media ads on Digital Pool's growing Instagram and Facebook channels.
                                            </Text>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={24} sm={8} style={{ marginTop: 10, marginBottom: 10 }}>
                                    <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                                        <div>
                                            <Title level={4} style={{ fontWeight: 600 }}>
                                                Mobile App Ads
                                            </Title>
                                            <Text style={{ fontSize: 16 }}>
                                                Run native ads in Digital Pool's mobile player app for iOS and Android (coming soon!)
                                            </Text>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row
                        gutter={16}
                        type="flex"
                        justify="center"
                        style={{
                            backgroundColor: '#fff',
                            boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.05), 0 15px 37px 0 #dbe2eb',
                        }}
                    >
                        <Col xs={22} sm={12}>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    position: 'relative',
                                    marginLeft: 24,
                                    marginRight: 24,
                                }}
                            >
                                <Row gutter={16} type="flex" justify="center" style={{ marginTop: 40, marginBottom: 40 }}>
                                    <Col xs={24} align="center">
                                        <Title level={2}>Advertising Templates</Title>
                                    </Col>

                                    <Col xs={24} sm={12} style={{ marginTop: 10, marginBottom: 10 }}>
                                        <ul style={{ listStyleType: 'none', padding: 0 }}>
                                            <li>
                                                <a
                                                    href="https://drive.google.com/file/d/1gdw19ujphPdTdB7RASasCv_qWj6DVz_f/view?usp=sharing"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    <img src={adTemplateHomepageDesktop} alt="Homepage Desktop" width="100%" />
                                                    <Text>Homepage - Desktop (970x250)</Text>
                                                </a>
                                            </li>
                                            <li style={{ marginTop: 20 }}>
                                                <a
                                                    href="https://drive.google.com/file/d/1YB0sFTW5ClA6YZkA0W4FcDc0L5Dl5lB7/view?usp=sharing"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    <img src={adTemplateHomepageTablet} alt="Homepage Tablet" width="100%" />
                                                    <Text>Homepage - Tablet (728x90)</Text>
                                                </a>
                                            </li>
                                            <li style={{ marginTop: 20 }}>
                                                <a
                                                    href="https://drive.google.com/file/d/1BRzTK6gTti56Fbvp33JmR6eVmRxDW6ME/view?usp=sharing"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    <img src={adTemplateHomepageMobile} alt="Homepage Mobile" width="100%" />
                                                    <Text>Homepage - Mobile (320x100)</Text>
                                                </a>
                                            </li>
                                        </ul>
                                    </Col>
                                    <Col xs={24} sm={12} style={{ marginTop: 10, marginBottom: 10 }}>
                                        <ul style={{ listStyleType: 'none', padding: 0 }}>
                                            <li>
                                                <a
                                                    href="https://drive.google.com/file/d/15vRfnrOz5WzNVJtAG3INni6Ka6Nw9exP/view?usp=sharing"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    <img src={adTemplateInnerDesktop} alt="Inner Pages Desktop" width="100%" />
                                                    <Text>Inner Pages - Desktop (970x90)</Text>
                                                </a>
                                            </li>
                                            <li style={{ marginTop: 20 }}>
                                                <a
                                                    href="https://drive.google.com/file/d/1dvJFWnuEHGBcJpu1-x34jRJmyeZj1rZZ/view?usp=sharing"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    <img src={adTemplateInnerTablet} alt="Inner Pages Tablet" width="100%" />
                                                    <Text>Inner Pages - Tablet (728x90)</Text>
                                                </a>
                                            </li>
                                            <li style={{ marginTop: 20 }}>
                                                <a
                                                    href="https://drive.google.com/file/d/1tnWLHkipGJUChKEHv-nSLEXKRxNZmb_N/view?usp=sharing"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    <img src={adTemplateInnerMobile} alt="Inner Pages Mobile" width="100%" />
                                                    <Text>Inner Pages - Mobile (320x100)</Text>
                                                </a>
                                            </li>
                                        </ul>
                                    </Col>
                                    <Col xs={24} align="center">
                                        <a
                                            href="https://drive.google.com/drive/folders/1vNq2kpb4atlmsnbqPMa8_lR9Ch1rYgUU?usp=sharing"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <Button type="primary" size="large" style={{ textTransform: 'uppercase', fontWeight: 700, padding: '0px 20px' }}>
                                                Download Source Files
                                            </Button>
                                        </a>
                                        <div style={{ marginTop: 5 }}>
                                            <Text style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                Made with{' '}
                                                <a href="https://www.sketch.com/" target="_blank" rel="noopener noreferrer" style={{ paddingLeft: 5 }}>
                                                    <img src="https://cdn.cdnlogo.com/logos/s/35/sketch.svg" alt="Sketch" width={80} />
                                                </a>
                                            </Text>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                    {/* <Row
                        gutter={16}
                        type="flex"
                        justify="center"
                        style={{
                            backgroundColor: '#fff',
                            boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.05), 0 15px 37px 0 #dbe2eb',
                        }}
                    >
                        <Col xs={24} sm={24} md={24} lg={20} xl={20}>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    position: 'relative',
                                    marginLeft: 24,
                                    marginRight: 24,
                                }}
                            >
                                <Row gutter={16} type="flex" justify="center" style={{ marginTop: 40, marginBottom: 40 }}>
                                    <Col xs={24} sm={16} align="center">
                                        <Title level={2} style={{ color: 'rgba(0, 0, 0, 0.85)' }}>
                                            Why Digital Pool?
                                        </Title>
                                        <div style={{ marginTop: 20, maxWidth: 800 }}>
                                            <Text style={{ fontSize: 20, color: 'rgba(0, 0, 0, 0.85)' }}>
                                                Since launching in 2020, Digital Pool has grown to become a household name in the billiards industry. Now we are
                                                helping businesses of all sizes reach pool players everywhere!
                                            </Text>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row> */}
                </SectionContent>
            </SectionWrapper>
        </React.Fragment>
    );
}

export default withRouter(Advertising);
