import React from 'react';
import { withRouter } from 'react-router-dom';
import { Typography } from 'antd';
import LMAvatarSettingsForm from './LMAvatarSettingsForm';
import LMLogoSettingsForm from './LMLogoSettingsForm';
const { Title, Text } = Typography;

function LMLogoAvatarSettings(props) {
	return (
		<React.Fragment>
			<div style={{ marginBottom: 10 }}>
				<Title level={4}>Upload Avatar</Title>
				<Text>Avatars must in .jpg or .png format and 2MB or less.</Text>
			</div>
			<LMAvatarSettingsForm {...props} />
			<div style={{ marginTop: 20, marginBottom: 10 }}>
				<Title level={4}>Upload Logo</Title>
				<Text>Logos must in .jpg or .png format and 2MB or less.</Text>
			</div>
			<LMLogoSettingsForm {...props} />
		</React.Fragment>
	);
}

export default withRouter(LMLogoAvatarSettings);
