import React, { useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { message, Row, Col, Table, Tooltip, Button, Modal, Icon, Typography, Statistic, Pagination } from 'antd';
import Card from '../../../components/Card';
import { Query } from '@apollo/client/react/components';
import ZeroState from '../../../components/ZeroState';
import CircularLoader from '../../../components/CircularLoader';
import SectionContent from '../../../components/SectionContent';
import CircleFlag from '../../../components/CircleFlag';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { CREATE_PLAYER, UPDATE_PLAYER, UPDATE_USER } from '../data/mutations';
import { GET_ALL_USERS_QUERY, GET_ALL_USERS_COUNT, GET_ALL_PLAYERS_COUNT, SEARCH_PLAYERS } from '../data/queries';
import moment from 'moment';
import slugify from 'slugify';

const { Text, Title } = Typography;
const { confirm } = Modal;

const styles = {
    container: {
        padding: 20,
    },
};

function AllUsers(props) {
    const { client, authState } = props;
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(50);
    const [createPlayer] = useMutation(CREATE_PLAYER);
    const [updateUser] = useMutation(UPDATE_USER);
    const userCount = useQuery(GET_ALL_USERS_COUNT);
    const totalUsers = (userCount && userCount.data && userCount.data.users_aggregate && userCount.data.users_aggregate.aggregate.count) || 0;
    const playerCount = useQuery(GET_ALL_PLAYERS_COUNT);
    const totalPlayers = (playerCount && playerCount.data && playerCount.data.players_aggregate && playerCount.data.players_aggregate.aggregate.count) || 0;

    const MyPagination = ({ total, onChange, current }) => {
        return <Pagination onChange={onChange} total={total} current={currentPage} pageSize={pageSize} />;
    };

    const addPlayer = (data) => {
        console.log('add player', data);
        setLoading(true);
        const name = `${data.first_name} ${data.last_name}`;
        const slug = slugify(name, {
            replacement: '-',
            remove: /[*+~.,()'"#!:/@]/g,
            lower: true,
        });
        createPlayer({
            variables: {
                objects: [
                    {
                        name: name,
                        name_slug: slug,
                        ...(data.aliases && { aliases: data.aliases }),
                        ...(data.properties && { properties: data.properties }),
                        ...(data.image_large && { image_large: data.image_large }),
                        ...(data.image_small && { image_small: data.image_small }),
                        ...(data.country && { country: data.country }),
                        ...(data.gender && { gender: data.gender }),
                        ...(data.id && { user_id: data.id }),
                        ...(data.fargo_id && { fargo_id: parseInt(data.fargo_id) }),
                        ...(data.fargo_robustness && { fargo_robustness: parseInt(data.fargo_robustness) }),
                        ...(data.fargo_readable_id && { fargo_readable_id: parseInt(data.fargo_readable_id) }),
                        ...(data.fargo_effective_rating && { fargo_effective_rating: data.fargo_effective_rating }),
                    },
                ],
            },
        })
            .then((res) => {
                console.log(res);
                const added = res.data.insert_players.returning[0];

                if (data.id) {
                    updateUser({
                        variables: {
                            id: data.id,
                            changes: {
                                player_id: added.id,
                            },
                        },
                        notifyOnNetworkStatusChange: true,
                        awaitRefetchQueries: true,
                        refetchQueries: [
                            {
                                query: GET_ALL_USERS_QUERY,
                                variables: { limit: pageSize, offset: (currentPage - 1) * pageSize || 0 },
                            },
                        ],
                    })
                        .then((data) => {
                            console.log(data);
                            setLoading(false);
                            message.success(`Use record successfully updated`);
                        })
                        .catch((error) => {
                            console.log(error);
                            setLoading(false);
                            message.error('There was an error', JSON.stringify(error.message));
                        });
                } else {
                    console.log(data);
                    setLoading(false);
                    message.success(`Player record for ${name} successfully added`);
                }
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
                if (error.message && error.message.includes('Uniqueness violation')) {
                    // message.error('Error: There is already a player record with the same name');
                    showConfirmLink(data);
                } else {
                    message.error((error && error.message) || JSON.stringify(error));
                }
            });
    };

    async function linkPlayer(data) {
        const name = `${data.first_name} ${data.last_name}`;
        const res = await client.query({
            query: SEARCH_PLAYERS,
            variables: {
                name: `${name}`,
            },
        });

        console.log(res);
        const player = res && res.data && res.data.players && res.data.players[0];
        console.log(player);

        if (player) {
            updateUser({
                variables: {
                    id: data.id,
                    changes: {
                        player_id: player.id,
                    },
                },
                notifyOnNetworkStatusChange: true,
                awaitRefetchQueries: true,
                refetchQueries: [
                    {
                        query: GET_ALL_USERS_QUERY,
                        variables: { limit: pageSize, offset: (currentPage - 1) * pageSize || 0 },
                    },
                ],
            })
                .then((data) => {
                    console.log(data);
                    setLoading(false);
                    message.success(`Use record linked`);
                })
                .catch((error) => {
                    console.log(error);
                    setLoading(false);
                    message.error('There was an error', JSON.stringify(error.message));
                });
        }
    }

    function showConfirmLink(data) {
        confirm({
            title: `There is already a player record with the same name. Would you like to link them?`,
            centered: true,
            transitionName: 'fade',
            maskTransitionName: 'none',
            okText: `Link player`,
            onOk() {
                linkPlayer(data);
            },
            onCancel() {},
        });
    }

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            sorter: (a, b) => {
                return a.id - b.id;
            },
            render: (text, record) => {
                return <Text>{text || '-'}</Text>;
            },
        },
        {
            title: 'Name',
            dataIndex: 'display_name',
            defaultSortOrder: 'asc',
            sorter: (a, b) =>
                a.display_name &&
                b.display_name &&
                a.display_name.localeCompare(b.display_name, undefined, {
                    numeric: true,
                    sensitivity: 'base',
                }),
            render: (text, record) => {
                return (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {record.country && <CircleFlag country={record.country} left={0} />}
                        <Text>
                            {record.first_name} {record.last_name}
                        </Text>
                    </div>
                );
            },
        },
        {
            title: 'Email',
            dataIndex: 'email',
            sorter: (a, b) =>
                b.email.localeCompare(a.email, undefined, {
                    numeric: true,
                    sensitivity: 'base',
                }),
            render: (text, record) => {
                return <Text>{text || '-'}</Text>;
            },
        },
        {
            title: 'Phone',
            dataIndex: 'phone_number',
            sorter: (a, b) =>
                b.phone_number.localeCompare(a.phone_number, undefined, {
                    numeric: true,
                    sensitivity: 'base',
                }),
            render: (text, record) => {
                return <Text>{text || '-'}</Text>;
            },
        },
        {
            title: 'Location',
            dataIndex: 'city',
            sorter: (a, b) =>
                b.city.localeCompare(a.city, undefined, {
                    numeric: true,
                    sensitivity: 'base',
                }),
            render: (text, record) => {
                return (
                    <React.Fragment>
                        {record.city && record.region ? (
                            <Text>
                                {record.city}, {record.region}
                            </Text>
                        ) : (
                            <Text>{record.city || '-'} </Text>
                        )}
                    </React.Fragment>
                );
            },
        },

        {
            title: 'Fargo',
            dataIndex: 'fargo_id',
            render: (text, record) => {
                return (
                    <React.Fragment>
                        {record.fargo_rating ? (
                            <div>
                                <div>ID: {record.fargo_id || '-'}</div>
                                {record.fargo_rating && record.fargo_robustness ? (
                                    <React.Fragment>
                                        <div>Rating: {record.fargo_rating}</div>
                                        <div>Robustness: {record.fargo_robustness}</div>
                                    </React.Fragment>
                                ) : (
                                    <div>Rating: {record.fargo_rating}</div>
                                )}
                            </div>
                        ) : (
                            <Text>{text || '-'}</Text>
                        )}
                    </React.Fragment>
                );
            },
        },

        {
            title: 'Player ID',
            dataIndex: 'players',
            render: (text, record) => {
                return <Text>{(record.player_id && record.player_id) || '-'}</Text>;
            },
        },

        {
            title: 'Last seen',
            dataIndex: 'last_seen',

            width: 100,
            sorter: (a, b) =>
                b.last_seen.localeCompare(a.last_seen, undefined, {
                    numeric: true,
                    sensitivity: 'base',
                }),

            render: (text, record) => {
                return <Text>{moment(text).format('lll')}</Text>;
            },
        },

        {
            title: 'Action',
            key: 'action',
            // fixed: 'right',
            render: (text, record) => (
                <React.Fragment>
                    <span style={{ whiteSpace: 'nowrap' }}>
                        <Tooltip title="Edit">
                            <Button
                                type="ghost"
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    // props.history.push(`/users/${record.slug}`);
                                }}
                            >
                                <Icon type="edit" />
                            </Button>
                        </Tooltip>
                        {record.player_id ? (
                            <Tooltip title="View player record">
                                <Button
                                    type="ghost"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        props.history.push(`/players/${record.player.name_slug}`);
                                    }}
                                >
                                    <Icon type="eye" />
                                </Button>
                            </Tooltip>
                        ) : (
                            <Tooltip title="Create player record">
                                <Button
                                    type="ghost"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        addPlayer(record);
                                        // props.history.push(`/users/${record.slug}`);
                                    }}
                                >
                                    <Icon type="user-add" />
                                </Button>
                            </Tooltip>
                        )}

                        {/* <Tooltip title="Link player record">
                            <Button
                                type="ghost"
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    linkPlayer(record);
                                    // props.history.push(`/users/${record.slug}`);
                                }}
                                disabled={record.player_id === true ? true : false}
                            >
                                <Icon type="link" />
                            </Button>
                        </Tooltip> */}

                        {/* <Tooltip title={record.organizer_id !== authState.user.id ? 'No access to delete' : 'Delete'}>
                            <Button
                                type="ghost"
                                color="red"
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    showConfirmDelete(record);
                                }}
                                disabled={record.organizer_id !== authState.user.id}
                            >
                                <Icon type="delete" />
                            </Button>
                        </Tooltip> */}
                    </span>
                </React.Fragment>
            ),
        },

        // {
        //     title: 'Location',
        //     dataIndex: 'venue',
        //     render: (text, record) => {
        //         return (
        //             <React.Fragment>
        //                 {record.venue ? (
        //                     <React.Fragment>
        //                         <Text style={{ fontWeight: 'bold' }}>{record.venue && record.venue.name}</Text>
        //                         <br />
        //                         <Text>
        //                             {record.venue && record.venue.city}, {record.venue && record.venue.region}
        //                         </Text>
        //                     </React.Fragment>
        //                 ) : (
        //                     '-'
        //                 )}
        //             </React.Fragment>
        //         );
        //     },
        // },
        // {
        //     title: 'Status',
        //     dataIndex: 'status',
        //     render: (text, record) => {
        //         return <Text>{text || '-'}</Text>;
        //     },
        // },
    ];

    return (
        <SectionContent padding="20px 40px">
            <Row gutter={[16, 16]} justify="start" type="flex">
                <Col xs={12} sm={12}>
                    <Card>
                        <Statistic title="User accounts" value={totalUsers} />
                    </Card>
                </Col>
                <Col xs={12} sm={12}>
                    <Card>
                        <Statistic title="Player records" value={totalPlayers} />
                    </Card>
                </Col>
                <Col xs={24}>
                    <Query
                        query={GET_ALL_USERS_QUERY}
                        fetchPolicy="cache-and-network"
                        notifyOnNetworkStatusChange
                        awaitRefetchQueries
                        variables={{ limit: pageSize, offset: (currentPage - 1) * pageSize || 0 }}
                        onCompleted={(data) => {
                            console.log(data);
                        }}
                    >
                        {({ loading, error, data }) => {
                            if (loading)
                                return (
                                    <div style={styles.container}>
                                        <CircularLoader />
                                    </div>
                                );
                            if (error) return <div style={styles.container}>Error: {error.message}</div>;
                            if ((data && !data.users) || (data && data.users.length === 0)) {
                                return <ZeroState showImage message="No users found." style={{ margin: 30 }} {...props} />;
                            }

                            const users = data.users.map((user, index) => {
                                return {
                                    ...user,
                                    key: index,
                                };
                            });

                            return (
                                <div className="table_nowrap">
                                    <Table
                                        onRow={(record, rowIndex) => {
                                            return {
                                                onClick: (event) => {
                                                    console.log(record, rowIndex);
                                                    props.history.push({
                                                        pathname: `/users/${record.slug}`,
                                                        // search: '?query=abc',
                                                        state: { data: record },
                                                    });
                                                }, // click row
                                                onDoubleClick: (event) => {}, // double click row
                                                onContextMenu: (event) => {}, // right button click row
                                                onMouseEnter: (event) => {}, // mouse enter row
                                                onMouseLeave: (event) => {}, // mouse leave row
                                            };
                                        }}
                                        columns={columns}
                                        dataSource={users}
                                        pagination={false}
                                        tableLayout="auto"
                                        scroll={{ x: 400 }}
                                    />
                                </div>
                            );
                        }}
                    </Query>
                </Col>
                <Col xs={24} align="center">
                    <MyPagination
                        total={totalUsers}
                        current={currentPage}
                        onChange={(page) => {
                            client
                                .query({
                                    query: GET_ALL_USERS_QUERY,
                                    variables: {
                                        limit: pageSize,
                                        offset: (currentPage - 1) * pageSize || 0,
                                    },
                                })
                                .then((res) => {
                                    const updatedPlayers = res && res.data && res.data.users;
                                    setCurrentPage(page);
                                })
                                .catch((error) => {
                                    console.error(error);
                                    throw error;
                                });
                        }}
                    />
                </Col>
            </Row>
        </SectionContent>
    );
}

export default withRouter(AllUsers);
