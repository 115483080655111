import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { Form, FormItem, Input, Radio, Select, DatePicker, SubmitButton, ResetButton, AutoComplete } from 'formik-antd';
import { Formik, ErrorMessage } from 'formik';
import { message, Typography, Modal, Button, Divider, Icon } from 'antd';
import * as Yup from 'yup';
import CircularLoader from '../../components/CircularLoader';
import { Query, Mutation } from '@apollo/client/react/components';
import { useMutation } from '@apollo/react-hooks';
import { CREATE_ORGANIZATION_MUTATION, UPDATE_ORGANIZATION_MUTATION } from './data/mutations';
import { SEARCH_ORGANIZATIONS_QUERY, GET_USER_ORGANIZATIONS_QUERY, GET_ORGANIZATION_QUERY } from './data/queries';
import slugify from 'slugify';
import _ from 'lodash';
import { CountryRegionData } from 'react-country-region-selector';
import moment from 'moment-timezone';
import momentDurationFormatSetup from 'moment-duration-format';
momentDurationFormatSetup(moment);

const { TextArea } = Input;
const { Text } = Typography;
const { Option } = Select;

const styles = {
    error: { color: '#f5222d' },
};

function AddOrganizationModal(props) {
    const { modalVisible, onModalOk, onModalCancel, authState, selectedOrganizationId, client } = props;
    const [loading, setLoading] = useState(false);
    const [organizationDataSource, setOrganizationDataSource] = useState('');
    const formRef = useRef();
    const schema = Yup.object().shape({
        name: Yup.string().min(2, 'Too Short!').max(100, 'Too Long!').required('Required'),
    });

    function handleFormFieldUpdates(event) {
        const caret = event.target.selectionStart;
        const element = event.target;
        window.requestAnimationFrame(() => {
            element.selectionStart = caret;
            element.selectionEnd = caret;
        });
    }

    function onSearch(name) {
        if (name && name.length > 2) {
            queryOrganizations(name);
        }
    }

    function onSelect(value, option) {
        console.log({
            value,
            option,
        });
        const organization = organizationDataSource.filter((organization) => {
            return organization.id === parseInt(value);
        })[0];
        formRef.current.setValues({ ...formRef.current.state.values, organization_id: parseInt(value), organization_name: organization.name });
    }

    const getRegions = (country) => {
        if (!country) {
            return [];
        }
        if (typeof country === 'string') {
            const regions = CountryRegionData.filter((item) => {
                return item[1] === country;
            })[0];
            country = regions;
        }
        const selectedRegion = country[2].split('|').map((regionPair) => {
            let [regionName, regionShortCode = null] = regionPair.split('~');
            return regionName;
        });
        return selectedRegion;
    };

    async function queryOrganizations(organizationName) {
        let query = await client.query({
            query: SEARCH_ORGANIZATIONS_QUERY,
            variables: {
                name: `%${organizationName}%`,
            },
        });

        let dataSource = query.data.organizations;
        setOrganizationDataSource(dataSource);
    }

    function onOk(value) {
        const tzDate = moment.tz(value, moment.tz.guess(value)).format('YYYY-MM-DD hh:mm A z');
        console.log('onOK', tzDate);
        return tzDate;
    }

    function handleSubmit(values, createOrganization, updateOrganization) {
        console.log(values);

        const { name, description, address1, address2, city, country, region, postal_code, phone_number, fax_number, email_address, website } = values;
        setLoading(true);
        const slug = slugify(name, {
            replacement: '-',
            remove: /[*+~.,()'"#!:/@]/g,
            lower: true,
        });

        if (selectedOrganizationId) {
            updateOrganization({
                variables: {
                    id: selectedOrganizationId,
                    changes: {
                        name,
                        slug,
                        description,
                        address1,
                        address2,
                        city,
                        country,
                        region,
                        postal_code,
                        phone_number,
                        fax_number,
                        email_address,
                        website,
                        owner_id: authState.user.id,
                    },
                },
                refetchQueries: [
                    {
                        query: GET_ORGANIZATION_QUERY,
                        variables: { id: selectedOrganizationId },
                    },
                ],
            });
        } else {
            createOrganization({
                variables: {
                    objects: [
                        {
                            name,
                            slug,
                            description,
                            address1,
                            address2,
                            city,
                            country,
                            region,
                            postal_code,
                            phone_number,
                            fax_number,
                            email_address,
                            website,
                            owner_id: authState.user.id,
                        },
                    ],
                },
                notifyOnNetworkStatusChange: true,
                awaitRefetchQueries: true,
                refetchQueries: [
                    {
                        query: GET_USER_ORGANIZATIONS_QUERY,
                        variables: { owner_id: authState.user.id },
                    },
                ],
            });
        }
    }

    const eventMutations = (data, loading, error) => {
        return (
            <Mutation
                mutation={CREATE_ORGANIZATION_MUTATION}
                onCompleted={(data) => {
                    console.log(data);
                    setLoading(false);
                    message.success('Organization successfully created');
                    // const result = data.insert_venues.returning[0];
                    onModalOk();
                    // props.history.push(`/tournament-builder/${props.tournament.slug}/venues/${result.slug}`);
                }}
                awaitRefetchQueries
            >
                {(createEvent, { error }) => (
                    <Mutation
                        mutation={UPDATE_ORGANIZATION_MUTATION}
                        onCompleted={(data) => {
                            console.log(data);
                            setLoading(false);
                            message.success('Changes saved');
                            // const result = data.update_events.returning[0];
                            // props.history.push(`/events/${result.slug}`);
                            onModalOk();
                        }}
                        awaitRefetchQueries
                    >
                        {(updateEvent, { error }) => {
                            if (error) {
                                alert('Something went wrong');
                            }
                            const event = data && data.events && data.events.length > 0 && data.events[0];
                            const initialValues = event
                                ? {
                                      ...event,
                                      name: event.name,
                                      venue_id: event.venue && event.venue.id,
                                      venue_name: event.venue && event.venue.name,
                                      is_public: event.is_public === true ? 'public' : 'private',
                                  }
                                : {
                                      name: '',
                                      venue_id: undefined,
                                      venue_name: undefined,
                                      event_type: 'tournament',
                                      start_date_time: undefined,
                                      end_date_time: undefined,
                                      is_public: true,
                                      owner_id: '',
                                  };

                            return (
                                <React.Fragment>
                                    {/* {loading && (
											<div style={styles.container}>
												<CircularLoader />
											</div>
										)} */}
                                    {/* {error && <div style={styles.container}>Error: {error.message}</div>} */}

                                    {!loading && !error && (
                                        <Formik
                                            ref={formRef}
                                            enableReinitialize
                                            initialValues={initialValues}
                                            validationSchema={schema}
                                            validate={(values) => {
                                                // console.log(values);
                                            }}
                                            onSubmit={(values, actions) => {
                                                // console.log(values, actions);
                                                // actions.setSubmitting(false);
                                                // actions.resetForm();
                                                handleSubmit(values, createEvent, updateEvent);
                                            }}
                                            render={({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                                                <Modal
                                                    title={selectedOrganizationId ? 'Update Organization' : 'Add Organization'}
                                                    // getContainer={() => document.querySelector('.tables')}
                                                    visible={modalVisible}
                                                    onOk={onModalOk}
                                                    onCancel={onModalCancel}
                                                    centered
                                                    transitionName="fade"
                                                    // transitionName="none"
                                                    maskTransitionName="none"
                                                    // footer={null}
                                                    footer={[
                                                        <Button key="cancel" onClick={onModalCancel}>
                                                            Cancel
                                                        </Button>,
                                                        <Button
                                                            key="submit"
                                                            disabled={Object.keys(errors).length > 0 ? true : false}
                                                            type={Object.keys(errors).length > 0 ? 'danger' : 'primary'}
                                                            loading={loading}
                                                            onClick={() => {
                                                                if (formRef.current) {
                                                                    formRef.current.handleSubmit();
                                                                }
                                                            }}
                                                        >
                                                            {selectedOrganizationId ? (loading ? 'Updating...' : 'Update') : 'Create'} Organization
                                                        </Button>,
                                                    ]}
                                                    bodyStyle={{
                                                        maxHeight: 500,
                                                        overflowY: 'auto',
                                                        padding: '10px 20px',
                                                    }}
                                                    // style={{
                                                    // 	left: -120
                                                    // }}
                                                    destroyOnClose={true}
                                                >
                                                    <Form
                                                        onSubmit={handleSubmit}
                                                        layout="vertical"
                                                        style={{ maxWidth: 600 }}
                                                        onSubmit={(e) => {
                                                            e.preventDefault();
                                                            handleSubmit(values, createEvent, updateEvent);
                                                        }}
                                                    >
                                                        <FormItem label="Organization Name" name="name" required hasFeedback showValidateSuccess>
                                                            <Input
                                                                name="name"
                                                                placeholder="Name"
                                                                size="large"
                                                                onChange={(event) => {
                                                                    handleFormFieldUpdates(event);
                                                                }}
                                                            />
                                                        </FormItem>
                                                        <FormItem label="Description" name="description">
                                                            <TextArea rows={4} name="description" placeholder="Description" size="large" />
                                                        </FormItem>
                                                        <FormItem label="Address 1" name="address1">
                                                            <Input name="address1" placeholder="Address 1" size="large" />
                                                        </FormItem>
                                                        <FormItem label="Address 2" name="address2">
                                                            <Input name="address2" placeholder="Address 2" size="large" />
                                                        </FormItem>
                                                        <FormItem label="City" name="city">
                                                            <Input name="city" placeholder="City" size="large" />
                                                        </FormItem>

                                                        <FormItem label="Country" name="country">
                                                            <Select
                                                                showSearch
                                                                name="country"
                                                                placeholder="Please select a country"
                                                                optionFilterProp="children"
                                                                size="large"
                                                                filterOption={(input, option) =>
                                                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                }
                                                            >
                                                                {CountryRegionData.map((option, index) => {
                                                                    return (
                                                                        <Option value={option[1]} key={index}>
                                                                            {option[0]}
                                                                        </Option>
                                                                    );
                                                                })}
                                                            </Select>
                                                        </FormItem>
                                                        <FormItem label="Region" name="region">
                                                            {/* <Input name="region" placeholder="Select State/Province/Region" size="large" /> */}
                                                            <Select
                                                                showSearch
                                                                name="region"
                                                                placeholder="Please select a state/province/region"
                                                                size="large"
                                                                filterOption={(input, option) =>
                                                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                }
                                                            >
                                                                {getRegions(values.country).map((option, index) => (
                                                                    <Option value={option} key={index}>
                                                                        {option}
                                                                    </Option>
                                                                ))}
                                                            </Select>
                                                        </FormItem>
                                                        <FormItem label="Postal Code" name="postal_code">
                                                            <Input name="postal_code" placeholder="Postal Code" size="large" />
                                                        </FormItem>
                                                        <FormItem label="Phone Number" name="phone_number">
                                                            <Input name="phone_number" placeholder="Phone Number" size="large" />
                                                        </FormItem>
                                                        <FormItem label="Fax Number" name="fax_number">
                                                            <Input name="fax_number" placeholder="Fax Number" size="large" />
                                                        </FormItem>
                                                        <FormItem label="Email Address" name="email_address">
                                                            <Input name="email_address" placeholder="Email Address" size="large" />
                                                        </FormItem>
                                                        <FormItem label="Website" name="website">
                                                            <Input name="website" placeholder="Website" size="large" />
                                                        </FormItem>

                                                        <div>
                                                            <Text style={styles.error}>{errors.general}</Text>
                                                        </div>
                                                        <div>
                                                            <Text style={{ color: '#f5222d', marginTop: 10 }}>{errors.general}</Text>
                                                        </div>
                                                    </Form>
                                                </Modal>
                                            )}
                                        />
                                    )}
                                </React.Fragment>
                            );
                        }}
                    </Mutation>
                )}
            </Mutation>
        );
    };

    return (
        <React.Fragment>
            {selectedOrganizationId ? (
                <Query
                    query={GET_ORGANIZATION_QUERY}
                    fetchPolicy="no-cache"
                    notifyOnNetworkStatusChange={true}
                    variables={{ id: selectedOrganizationId }}
                    onCompleted={async (data) => {
                        // console.log(data);
                    }}
                >
                    {({ loading, error, data }) => eventMutations(data, loading, error)}
                </Query>
            ) : (
                eventMutations()
            )}
        </React.Fragment>
    );
}

export default withRouter(AddOrganizationModal);
