import React, { useState } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { Form, FormItem, Input, Radio, InputNumber, Checkbox, DatePicker, TimePicker, Select, FormikDebug, SubmitButton, ResetButton } from 'formik-antd';
import { Formik, ErrorMessage } from 'formik';
import { message, Collapse, Alert, Button, Typography, Avatar, Icon, Divider } from 'antd';
import * as Yup from 'yup';
import { Query, Mutation } from '@apollo/client/react/components';
import gql from 'graphql-tag';
import slugify from 'slugify';
import _ from 'lodash';
import { useMutation } from '@apollo/react-hooks';
import { getGameType } from '../../../utils';
import {
    GET_LEAGUE_DIVISION_SCHEDULES,
    GET_LEAGUE_DIVISIONS_QUERY,
    GET_LEAGUE_BY_SLUG_QUERY,
    GET_LEAGUE_SEASONS_QUERY,
    GET_LEAGUE_DIVISION_SCHEDULE,
} from '../data/queries';
import {
    CREATE_LEAGUE_SCHEDULE_MUTATION,
    UPDATE_LEAGUE_SCHEDULE_MUTATION,
    CREATE_SCHEDULED_MATCHES_MUTATION,
    UPDATE_SCHEDULED_MATCHES_MUTATION,
    DELETE_SCHEDULED_MATCHES_MUTATION,
} from '../data/mutations';
// import moment from 'moment';
import moment from 'moment-timezone';
import momentDurationFormatSetup from 'moment-duration-format';
momentDurationFormatSetup(moment);

const { TextArea } = Input;
const { Title, Text } = Typography;
const { Option } = Select;
const { Panel } = Collapse;

const styles = {
    error: { color: '#f5222d', marginTop: 10 },
};

function LMDivisionScheduleCreateForm(props) {
    console.log(props);
    const { league, division, schedule } = props;
    const [loading, setLoading] = useState(false);
    const [newSchedule, setNewSchedule] = useState([]);
    const [createScheduledMatches] = useMutation(CREATE_SCHEDULED_MATCHES_MUTATION);
    const [updateScheduledMatches] = useMutation(UPDATE_SCHEDULED_MATCHES_MUTATION);
    const [deleteScheduledMatches] = useMutation(DELETE_SCHEDULED_MATCHES_MUTATION);

    // const [ publicPrivate, setPublicPrivate ] = useState('public');

    // const bracketDBDataset = createBracketDBDataset(1, 128);
    // console.log(bracketDBDataset);
    // console.table(bracketDBDataset.matches);

    // const winnerPlaces = bracketDBDataset.winnerPlacement;
    // const loserPlaces = bracketDBDataset.loserPlacement;
    // const matches = bracketDBDataset.matches;

    // console.log(matches);

    // const combined = _.uniq([ ...winnerPlaces.reverse(), ...loserPlaces.reverse(), '2' ])
    // 	.concat([ '3' ])
    // 	.sort((a, b) => {
    // 		return a.localeCompare(b, undefined, {
    // 			numeric: true,
    // 			sensitivity: 'base'
    // 		});
    // 	})
    // 	.map((item) => {
    // 		return {
    // 			place: item,
    // 			tournament_id: 1
    // 		};
    // 	});

    // console.log(combined);

    const showLoading = () => {
        const hide = message.loading('Action in progress..', 0);
        // Dismiss manually and asynchronously
        setTimeout(hide, 2500);
    };

    const formItemLayout = {
        // labelCol: { span: 6 },
        // wrapperCol: { span: 8 }
    };
    const buttonItemLayout = {
        // wrapperCol: { span: 14, offset: 6 }
    };

    function buildMatchSchedule(values) {
        const {
            name,
            description,
            duration,
            duration_type,
            is_active,
            // reserved_weeks,
            // weeks_off,
            start_date,
            start_time,
        } = values;
        const divisionTeams = division.league_team_divisions.map((team) => {
            return team.league_team;
        });

        let totalTeams = divisionTeams.length;
        let weeksOfPlay;
        const totalMatches = (totalTeams * (totalTeams - 1)) / 2;
        console.log('total matches', totalMatches);
        if (duration_type === 'WEEKS_OF_PLAY') {
            weeksOfPlay = duration;
        } else {
            const robin = roundRobin(
                totalTeams,
                divisionTeams.map((team) => {
                    return team.name;
                })
            );
            console.log('round robin', robin);
            // var weeksOfPlay = duration;
            weeksOfPlay = robin.length * parseInt(duration);
        }
        // var reservedWeeks = reserved_weeks;
        // var totalWeeks = weeksOfPlay + reservedWeeks;
        var startDate = moment(start_date);
        const dow = startDate.isoWeekday();
        var startTime = moment(start_time);
        var endDate = moment(start_date).add(weeksOfPlay, 'weeks').format();
        var startWeekNumber = moment(startDate, 'MM/DD/YYYY').week();
        var year = moment(startDate, 'MM/DD/YYYY').year();
        var nightOfPlay = dow;

        var dateEnum = {
            1: 'Monday',
            2: 'Tuesday',
            3: 'Wednesday',
            4: 'Thursday',
            5: 'Friday',
            6: 'Saturday',
            7: 'Sunday',
        };

        var teams = divisionTeams.map((team, index) => {
            return {
                team: team,
                index: index,
            };
        });

        // var teams = [];
        // for(var i = 1; i <= totalTeams; i++){
        //   teams.push({
        //     index: i,
        //     team: {
        //       name: 'test'
        //     }
        //   });
        // }

        // console.log(teams);

        var fullSchedule = createSchedule(teams, weeksOfPlay);
        var chunked = _.chunk(fullSchedule, 2);

        console.log('schedule', fullSchedule);
        console.log(JSON.stringify(fullSchedule, null, 4));

        console.log('-------');
        console.log('chunked', chunked);
        console.log('teams', teams);
        // console.log(teamNumbers);
        console.log('weeks of play', weeksOfPlay);
        console.log('dow', dow);
        console.log('day of play', nightOfPlay);

        function createSchedule(teams, weeks) {
            var middle;
            var list1 = [];
            var list2 = [];
            var schedule = [];

            // console.log(teams);

            if (teams.length % 2 == 1) {
                teams.push({
                    team: {
                        name: 'BYE',
                    },
                    index: teams.length + 1,
                });
            }

            for (var i = 0; i < weeks; i++) {
                var weekInYear = startWeekNumber + i;
                var date = moment().year(year).week(weekInYear).day(dateEnum[nightOfPlay]).format('YYYY-MM-DD');
                var time = moment.utc(startTime).format('h:mm A');
                middle = teams.length / 2;
                list1 = teams.slice(0, middle);
                list2 = teams.slice(middle, teams.length);
                list2.reverse();

                if (i % 2 == 1) {
                    schedule.push({
                        week: i + 1,
                        date: date,
                        time: time,
                        matches: _.zip(list1, list2),
                    });
                } else {
                    schedule.push({
                        week: i + 1,
                        date: date,
                        time: time,
                        matches: _.zip(list2, list1),
                    });
                }

                // console.log(teams);
                // console.log(schedule);

                teams.splice(1, 0, teams.pop());
                // teams = _.shuffle(teams);
            }

            return schedule;
        }

        var DUMMY = -1;
        // returns an array of round representations (array of player pairs).
        // http://en.wikipedia.org/wiki/Round-robin_tournament#Scheduling_algorithm
        function roundRobin(n, ps) {
            // n = num players
            var rs = []; // rs = round array
            if (!ps) {
                ps = [];
                for (var k = 1; k <= n; k += 1) {
                    ps.push(k);
                }
            } else {
                ps = ps.slice();
            }

            if (n % 2 === 1) {
                ps.push(DUMMY); // so we can match algorithm for even numbers
                n += 1;
            }
            for (var j = 0; j < n - 1; j += 1) {
                rs[j] = []; // create inner match array for round j
                for (var i = 0; i < n / 2; i += 1) {
                    if (ps[i] !== DUMMY && ps[n - 1 - i] !== DUMMY) {
                        rs[j].push([ps[i], ps[n - 1 - i]]); // insert pair as a match
                    }
                }
                ps.splice(1, 0, ps.pop()); // permutate for next round
            }
            return rs;
        }

        return fullSchedule;
    }

    // const handleSubmit = (values) => {
    // 	console.log(values);
    // };

    // const onPublicPrivateChange = (e) => {
    // 	setPublicPrivate(e.target.value);
    // };

    function onDateChange(value, dateString) {
        console.log('Selected Time: ', value);
        console.log('Formatted Selected Time: ', dateString);
        const tzDate = moment.tz(value, moment.tz.guess(value)).format('YYYY-MM-DD hh:mm A z');
        console.log('TZ Date', tzDate);
        return value;
    }

    function onOk(value) {
        const tzDate = moment.tz(value, moment.tz.guess(value)).format('YYYY-MM-DD hh:mm A z');
        console.log('onOK', tzDate);
        return tzDate;
    }

    const SignupSchema = Yup.object().shape({
        name: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required'),
        duration_type: Yup.string().required('Required'),
        duration: Yup.number().required('Required'),
        start_date: Yup.date().nullable().required('Required'),
        start_time: Yup.date().nullable().required('Required'),
    });

    function handleSubmit(values, createLeagueSchedule, updateLeagueSchedule, actions, scheduledMatches, totalWeeks, totalMatches) {
        // console.log(values);
        const { name, description, league_id, division_id, season_id, start_date, start_time, duration_type, duration, reserved_weeks, weeks_off, is_active } =
            values;
        setLoading(true);
        const slug = slugify(name, {
            replacement: '-',
            remove: /[*+~.,()'"#!:/@]/g,
            lower: true,
        });

        const startDate = moment(start_date).format('YYYY-MM-DD');
        const startTime = moment(start_time, 'hh').format('h:mm A');
        const startDateTime = moment(`${startDate} ${startTime}`).format('YYYY-MM-DD hh:mm A z');

        if (schedule) {
            updateLeagueSchedule({
                variables: {
                    id: schedule.id,
                    changes: {
                        name,
                        slug,
                        description,
                        league_id,
                        division_id,
                        season_id,
                        start_date: startDateTime,
                        start_time: startDateTime,
                        duration_type,
                        duration,
                        reserved_weeks,
                        weeks_off,
                        is_active,
                        total_weeks: totalWeeks,
                    },
                },
                notifyOnNetworkStatusChange: true,
                awaitRefetchQueries: true,
                refetchQueries: [
                    {
                        query: GET_LEAGUE_BY_SLUG_QUERY,
                        variables: {
                            slug: props.league.slug,
                        },
                    },
                    {
                        query: GET_LEAGUE_DIVISION_SCHEDULE,
                        variables: {
                            id: schedule.id,
                        },
                    },
                ],
            })
                .then((result) => {
                    actions.setSubmitting(false);
                    // actions.resetForm();

                    const schedule = result.data.update_league_schedules.returning[0];
                    console.log('updated schedule', schedule);
                })
                .catch((e) => {
                    actions.setSubmitting(false);
                    message.error((e && e.message) || JSON.stringify(e));
                    console.log(e);
                });

            deleteScheduledMatches({
                variables: {
                    schedule_id: schedule.id,
                },
            })
                .then((data) => {
                    console.log(data);
                    updateMatches();
                })
                .catch((error) => {
                    console.log(error);
                });

            function updateMatches() {
                createScheduledMatches({
                    variables: {
                        objects: scheduledMatches.map((match) => {
                            return {
                                ...match,
                                league_id: league.id,
                                division_id: division.id,
                                schedule_id: schedule.id,
                            };
                        }),
                    },
                    notifyOnNetworkStatusChange: true,
                    awaitRefetchQueries: true,
                    refetchQueries: [
                        // {
                        // 	query: GET_LEAGUE_DIVISION_SCHEDULES,
                        // 	variables: {
                        // 		leagueId: league.id,
                        // 		divisionId: division.id
                        // 	}
                        // },
                        {
                            query: GET_LEAGUE_DIVISION_SCHEDULE,
                            variables: {
                                id: schedule.id,
                            },
                        },
                    ],
                })
                    .then((data) => {
                        console.log(data);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        } else {
            createLeagueSchedule({
                variables: {
                    objects: [
                        {
                            name,
                            slug,
                            description,
                            league_id: props.league.id,
                            division_id: props.division.id,
                            season_id,
                            start_date: startDateTime,
                            start_time: startDateTime,
                            duration_type,
                            duration,
                            reserved_weeks,
                            weeks_off,
                            is_active,
                            total_weeks: totalWeeks,
                            league_scheduled_matches: {
                                data: scheduledMatches.map((match) => {
                                    console.log(match);
                                    return {
                                        ...match,
                                        league_id: props.league.id,
                                        division_id: props.division.id,
                                    };
                                }),
                            },
                        },
                    ],
                },
                notifyOnNetworkStatusChange: true,
                awaitRefetchQueries: true,
                refetchQueries: [
                    {
                        query: GET_LEAGUE_BY_SLUG_QUERY,
                        variables: {
                            slug: props.league.slug,
                        },
                    },
                ],
            })
                .then((result) => {
                    actions.setSubmitting(false);
                    actions.resetForm();

                    // createMatchesMutation({
                    // 	variables: {
                    // 		objects: updatedMatches
                    // 	},
                    // 	awaitRefetchQueries: true
                    // })
                    // 	.then((data) => {
                    // 		message.success({
                    // 			content: `Tournament bracket successfully created`,
                    // 			key,
                    // 			duration: 2
                    // 		});
                    // 		setCurrentStep(3);
                    // 	})
                    // 	.catch((error) => {
                    // 		console.log(error);
                    // 		message.error({
                    // 			content: `There was an error ${JSON.stringify(error)}`,
                    // 			key,
                    // 			duration: 2
                    // 		});
                    // 	});
                })
                .catch((e) => {
                    actions.setSubmitting(false);
                    message.error((e && e.message) || JSON.stringify(e));
                    console.log(e);
                });
        }
    }

    return (
        <div>
            <div style={{ marginTop: 10 }}>
                <Alert
                    message="Setup your division schedule"
                    // description="Create a schedule for your division. The duration can be based on the total weeks of play, or the total number of times each opponent will play each other."
                    description="Enter the starting date for the schedule and indicate whether to generate a schedule for an even number of round-robins or a fixed number of weeks."
                    type="info"
                    closable
                    showIcon
                />
            </div>

            {/* {newSchedule && (
				<React.Fragment>
					<table>
						<tbody>
							<tr>
								<th>Week</th>
								<th>Date</th>
								<th>Time</th>
								<th>Matchups</th>
							</tr>
							{newSchedule.map((week, index) => {
								return (
									<tr key={index}>
										<th>{week.week}</th>
										<th>{week.date}</th>
										<th>{week.time}</th>
										<th>
											<ul>
												{week.matches.map((match, index2) => {
													return (
														<li key={index2}>
															{match[0].team.name} vs. {match[1].team.name}
														</li>
													);
												})}
											</ul>
										</th>
									</tr>
								);
							})}
						</tbody>
					</table>
				</React.Fragment>
			)} */}

            <Mutation
                mutation={CREATE_LEAGUE_SCHEDULE_MUTATION}
                onCompleted={(data) => {
                    console.log(data);
                    setLoading(false);
                    const result = data.insert_league_schedules.returning[0];

                    props.history.push(`/league-manager/${league.slug}/divisions/${division.slug}/schedule/${result.slug}`);

                    // if (bracketProps.loading) return <p>Loading...</p>;
                    // if (bracketProps.error) return <p>Error :(</p>;
                }}
                awaitRefetchQueries
            >
                {(createLeagueSchedule, { error }) => (
                    <Mutation
                        mutation={UPDATE_LEAGUE_SCHEDULE_MUTATION}
                        onCompleted={(data) => {
                            console.log(data);
                            setLoading(false);
                            message.success('Changes saved');
                            const result = data.update_league_schedules.returning[0];

                            props.history.push(`/league-manager/${league.slug}/divisions/${division.slug}/schedule/${result.slug}`);
                        }}
                        awaitRefetchQueries
                    >
                        {(updateLeagueSchedule, { loading, error }) => {
                            const formItemLayout = {
                                labelCol: {
                                    xs: { span: 24 },
                                    sm: { span: 8 },
                                },
                                wrapperCol: {
                                    xs: { span: 24 },
                                    sm: { span: 16 },
                                },
                            };

                            if (error) {
                                alert(error);
                            }
                            return (
                                <Formik
                                    initialValues={{
                                        // ...(props.league && props.league),
                                        ...(props.league && {
                                            league_id: props.league.id,
                                        }),
                                        ...(props.division && { division_id: props.division.id }),
                                        name: props.schedule ? props.schedule.name : '',
                                        description: props.schedule ? props.schedule.description : '',
                                        season_id: undefined,
                                        start_date: props.schedule ? props.schedule.start_date : '',
                                        start_time: props.schedule ? props.schedule.start_time : '',
                                        duration_type: props.schedule ? props.schedule.duration_type : '',
                                        duration: props.schedule ? props.schedule.duration : 1,
                                        // reserved_weeks: props.schedule ? props.schedule.reserved_weeks : 0,
                                        // weeks_off: props.schedule ? props.schedule.weeks_off : 0,
                                        is_active: props.schedule ? props.schedule.is_active : false,
                                    }}
                                    validationSchema={SignupSchema}
                                    onSubmit={(values, actions) => {
                                        console.log(values);
                                        const matchSchedule = buildMatchSchedule(values);
                                        // setNewSchedule(matchSchedule);
                                        let totalWeeks = matchSchedule.length;

                                        const scheduledMatches = _.flatten(
                                            matchSchedule.map((week) => {
                                                const weeklyMatchups = week.matches.map((match) => {
                                                    const homeTeam = match[0].team;
                                                    const visitorTeam = match[1].team;
                                                    const startDateTime = moment(`${week.date} ${week.time}`).format('YYYY-MM-DD hh:mm A z');

                                                    return {
                                                        week: week.week,
                                                        start_date: startDateTime,
                                                        start_time: startDateTime,
                                                        home_team_id: homeTeam.id,
                                                        home_team_name: homeTeam.name,
                                                        visitor_team_id: visitorTeam.id,
                                                        visitor_team_name: visitorTeam.name,
                                                    };
                                                });
                                                return weeklyMatchups;
                                            })
                                        );

                                        let totalMatches = scheduledMatches.length;
                                        // console.log(scheduledMatches);

                                        handleSubmit(values, createLeagueSchedule, updateLeagueSchedule, actions, scheduledMatches, totalWeeks, totalMatches);
                                    }}
                                    render={({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                                        // <Form layout="horizontal" {...formItemLayout} style={{ maxWidth: 600 }}>
                                        <Form onSubmit={handleSubmit} layout="vertical" style={{ maxWidth: 600 }}>
                                            <FormItem label="Name" name="name" autoFocus required hasFeedback showValidateSuccess>
                                                <Input name="name" placeholder="Name" size="large" />
                                            </FormItem>
                                            <FormItem name="description" label="Description">
                                                <TextArea rows={4} name="description" placeholder="Description" size="large" />
                                            </FormItem>
                                            {/* <FormItem
												label="Division"
												name="division_id"
												required
												hasFeedback
												showValidateSuccess
												disabled
											>
												<Query
													query={GET_LEAGUE_DIVISIONS_QUERY}
													fetchPolicy="cache-and-network"
													notifyOnNetworkStatusChange
													awaitRefetchQueries
													variables={{ leagueId: league.id }}
													onCompleted={(data) => {
														console.log(data);
													}}
												>
													{({ loading, error, data }) => {
														if (loading) return <div>Loading...</div>;
														if (error) return <div>Error: {error.message}</div>;

														return (
															<Select
																showSearch
																// mode="multiple"
																name="division_id"
																placeholder="Select a division"
																size="large"
																optionFilterProp="children"
																// defaultValue={[ 'jack', 'lucy' ]}
																filterOption={(input, option) => {
																	return (
																		option.props.children.props.children
																			.toString()
																			.toLowerCase()
																			.indexOf(input.toLowerCase()) >= 0
																	);
																}}
															>
																{data.league_divisions.map((item, index) => (
																	<Option key={index} value={item.id}>
																		<Text>{item.name}</Text>
																	</Option>
																))}
															</Select>
														);
													}}
												</Query>
											</FormItem> */}
                                            {/* <FormItem
												label="Season"
												name="season_id"
												required
												hasFeedback
												showValidateSuccess
											>
												<Query
													query={GET_LEAGUE_SEASONS_QUERY}
													fetchPolicy="cache-and-network"
													notifyOnNetworkStatusChange={true}
													onCompleted={async (data) => {
														// console.log(data);
													}}
												>
													{({ loading, error, data }) => {
														if (loading) return <div>Loading...</div>;
														if (error) return <div>Error: {error.message}</div>;

														return (
															<Select
																showSearch
																// mode="multiple"
																name="season_id"
																placeholder="Select a season"
																size="large"
																optionFilterProp="children"
																// defaultValue={[ 'jack', 'lucy' ]}
																filterOption={(input, option) => {
																	return (
																		option.props.children.props.children
																			.toString()
																			.toLowerCase()
																			.indexOf(input.toLowerCase()) >= 0
																	);
																}}
															>
																{data.league_divisions.map((item, index) => (
																	<Option key={index} value={item.id}>
																		<Text>{item.name}</Text>
																	</Option>
																))}
															</Select>
														);
													}}
												</Query>
											</FormItem> */}
                                            <FormItem label="Start date" name="start_date" required hasFeedback showValidateSuccess>
                                                <DatePicker
                                                    name="start_date"
                                                    size="large"
                                                    format="YYYY-MM-DD"
                                                    // onChange={onDateChange}
                                                    onOk={onOk}
                                                />
                                            </FormItem>

                                            <FormItem label="Start time" name="start_time" required hasFeedback showValidateSuccess>
                                                <TimePicker
                                                    name="start_time"
                                                    size="large"
                                                    defaultValue={moment()}
                                                    use12Hours
                                                    format="h:mm A"
                                                    // onChange={onDateChange}
                                                    onOk={onOk}
                                                />
                                            </FormItem>

                                            <FormItem label="Duration Type" name="duration_type" required hasFeedback showValidateSuccess>
                                                <Select
                                                    showSearch
                                                    name="duration_type"
                                                    placeholder="Select a duration type"
                                                    size="large"
                                                    optionFilterProp="children"
                                                    defaultValue="singles"
                                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                >
                                                    {[
                                                        {
                                                            type: 'Number of times opponents play each other',
                                                            value: 'SAME_OPPONENT_PLAYS',
                                                        },
                                                        {
                                                            type: 'Total weeks of play in the division',
                                                            value: 'WEEKS_OF_PLAY',
                                                        },
                                                    ].map((item, index) => (
                                                        <Option key={index} value={item.value}>
                                                            {item.type}
                                                        </Option>
                                                    ))}
                                                </Select>
                                            </FormItem>

                                            <FormItem label="Duration Value" name="duration" required hasFeedback showValidateSuccess>
                                                <InputNumber name="duration" placeholder="1" size="large" min={1} />{' '}
                                                {values && values.duration_type === 'WEEKS_OF_PLAY' ? 'weeks' : 'times each opponent meet'}
                                            </FormItem>
                                            {/* <FormItem label="Total Reserved Weeks" name="reserved_weeks">
												<InputNumber
													name="reserved_weeks"
													placeholder="Total Reserved Weeks"
													size="large"
													min={0}
												/>
											</FormItem>
											<FormItem label="Weeks off" name="weeks_off">
												<InputNumber
													name="weeks_off"
													placeholder="Weeks Off"
													size="large"
													min={0}
												/>
											</FormItem> */}

                                            <FormItem name="is_active" label="Schedule Active?">
                                                <Radio.Group name="is_active">
                                                    <Radio value={true}>Yes</Radio>
                                                    <br />
                                                    <Radio value={false}>No</Radio>
                                                </Radio.Group>
                                            </FormItem>
                                            <br />

                                            <SubmitButton
                                                size="large"
                                                disabled={Object.keys(errors).length > 0 ? true : false}
                                                type={Object.keys(errors).length > 0 ? 'danger' : 'primary'}
                                                loading={loading}
                                            >
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <Text style={{ color: '#fff' }}>{props.schedule ? 'Save' : 'Create'}</Text>
                                                </div>
                                            </SubmitButton>
                                            <ResetButton size="large">Clear</ResetButton>
                                            <div>
                                                <Text style={styles.error}>{errors.general}</Text>
                                            </div>
                                        </Form>
                                    )}
                                />
                            );
                        }}
                    </Mutation>
                )}
            </Mutation>
        </div>
    );
}

export default withRouter(LMDivisionScheduleCreateForm);
