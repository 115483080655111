import React, { useState } from 'react';
// import objtocsv from 'objects-to-csv';
import { message, Table, Icon, Row, Col, Typography, Button } from 'antd';
import Card from '../../components/Card';
import { Link } from 'react-router-dom';
import Podium from './Podium';
import { isoCountries } from '../../utils/isoCountryCodes';
import SectionTitle from '../../components/SectionTitle';
import SectionHeader from '../../components/SectionHeader';
import SectionContent from '../../components/SectionContent';
import SectionWrapper from '../../components/SectionWrapper';
import SectionFooter from '../../components/SectionFooter';
import TournamentProgressBar from './TournamentProgressBar';
import TBManageButtons from './TBManageButtons';
import ResultsTable from './ResultsTable';
import CircularLoader from '../../components/CircularLoader';
import { Subscription } from '@apollo/client/react/components';
import { GET_TOURNAMENT_PLAYER_STANDINGS_SUBSCRIPTION } from './data/subscriptions';
import { GET_TOURNAMENT_PLAYER_STANDINGS } from './data/queries';
import moment from 'moment';
import _ from 'lodash';
import { Parser } from 'json2csv';

const { Text, Title } = Typography;

const styles = {
    row: {
        display: 'flex',
        flexGrow: 1,
    },
    tab: {
        paddingTop: 5,
        paddingLeft: 24,
        paddingRight: 24,
        paddingBottom: 24,
    },
};

export default function TBResults(props) {
    const { tournament, client } = props;
    const [tableData, setTableData] = useState([]);
    const [csvDownloadLoading, setCSVDownloadLoading] = useState(false);

    function calcDuration(challenger1_times, challenger2_times) {
        const challenger1Duration = _.compact(
            challenger1_times.map((match) => {
                let duration;
                if (match.start_time && match.end_time) {
                    var start = moment(match.start_time);
                    var end = moment(match.end_time);
                    duration = parseInt(moment.duration(end.diff(start, 'milliseconds')).format('m'));
                }
                return duration;
            })
        ).reduce((acc, minutes) => {
            return acc + minutes;
        }, 0);

        const challenger2Duration = _.compact(
            challenger2_times.map((match) => {
                let duration;
                if (match.start_time && match.end_time) {
                    var start = moment(match.start_time);
                    var end = moment(match.end_time);
                    duration = parseInt(moment.duration(end.diff(start, 'milliseconds')).format('m'));
                }
                return duration;
            })
        ).reduce((acc, minutes) => {
            return acc + minutes;
        }, 0);

        return challenger1Duration + challenger2Duration;
    }

    function orderAndFormatData(arr, csv = false) {
        let count = arr.length;
        let tmp, split, cNum1, cNum2, split2, cNum11, cNum21;
        let formattedResults = [];
        let totalTournamentGames = 0;
        let totalTournamentMatches = 0;
        let totalTournamentMatchDurationMinutes = 0;
        let totalTournamentMatchDurationHours = 0;

        for (let i = 0; i < count; i++) {
            if (arr[i].place.includes('-')) {
                split = arr[i].place.split('-');
                cNum1 = parseInt(split[0]);
                cNum2 = parseInt(split[1]);
            }

            for (let j = i + 1; j < count; j++) {
                if (arr[j].place.includes('-')) {
                    split2 = arr[j].place.split('-');
                    cNum11 = parseInt(split2[0]);
                    cNum21 = parseInt(split2)[1];

                    if (split) {
                        if (cNum2 > cNum21) {
                            tmp = arr[i];
                            arr[i] = arr[j];
                            arr[j] = tmp;
                        }
                    } else {
                        if (parseInt(arr[i].place) > parseInt(cNum21)) {
                            tmp = arr[i];
                            arr[i] = arr[j];
                            arr[j] = tmp;
                        }
                    }
                } else {
                    if (parseInt(arr[i].place) > parseInt(arr[j].place)) {
                        tmp = arr[i];
                        arr[i] = arr[j];
                        arr[j] = tmp;
                    }
                }

                if (parseInt(arr[i].place) > parseInt(arr[j].place)) {
                    tmp = arr[i];
                    arr[i] = arr[j];
                    arr[j] = tmp;
                }
            }

            split = false;

            let player = arr[i];

            let matchesWon = player.challenger1_matches_won.aggregate.count + player.challenger2_matches_won.aggregate.count;
            let matchesLost = player.challenger1_matches_lost.aggregate.count + player.challenger2_matches_lost.aggregate.count;
            let gamesWon = player.challenger1_matches_games.aggregate.sum.challenger1_score + player.challenger2_matches_games.aggregate.sum.challenger2_score;
            let gamesLost = player.challenger1_matches_games.aggregate.sum.challenger2_score + player.challenger2_matches_games.aggregate.sum.challenger1_score;
            let totalMatches = player.challenger1_matches_games.aggregate.count + player.challenger2_matches_games.aggregate.count;
            let totalGames =
                player.challenger1_matches_games.aggregate.sum.challenger1_score +
                player.challenger1_matches_games.aggregate.sum.challenger2_score +
                player.challenger2_matches_games.aggregate.sum.challenger1_score +
                player.challenger2_matches_games.aggregate.sum.challenger2_score;
            let avgMatchesWon = parseFloat((matchesWon / totalMatches).toFixed(2));
            let avgGamesWon = parseFloat((gamesWon / totalGames).toFixed(2));
            let durationMinutes = calcDuration(player.challenger1_matches_games.nodes, player.challenger2_matches_games.nodes);
            let totalMatchDurationMinutes = durationMinutes;
            let avgMatchDurationMinutes = durationMinutes / totalMatches;
            let totalMatchDurationHours = parseFloat((totalMatchDurationMinutes / 60).toFixed(2));
            let avgMatchDurationHours = parseFloat((totalMatchDurationMinutes / 60 / totalMatches).toFixed(2));

            totalTournamentMatches = totalTournamentMatches + matchesWon;
            totalTournamentGames = totalTournamentGames + gamesWon;
            totalTournamentMatchDurationMinutes = totalTournamentMatchDurationMinutes + totalMatchDurationMinutes;
            totalTournamentMatchDurationHours = totalTournamentMatchDurationHours + totalMatchDurationHours;

            if (csv) {
                formattedResults.push({
                    place: player.place,
                    name: player.name,
                    country: player.country,
                    matches: matchesWon + '-' + matchesLost,
                    matches_won: matchesWon,
                    games: gamesWon + '-' + gamesLost,
                    games_won: gamesWon,
                    skill_level: player.skill_level,
                    match_win_percentage: Math.round((matchesWon / totalMatches) * 100) || 0,
                    game_win_percentage: Math.round((gamesWon / totalGames) * 100) || 0,
                    earnings: player.payouts && player.payouts.money,
                    calcutta: player.payouts && player.payouts.calcutta,
                    points: player.player_points && player.player_points.points,
                });
            } else {
                formattedResults.push({
                    key: player.id,
                    place: player.place,
                    name: player.name,
                    country: player.country,
                    matches: matchesWon + '-' + matchesLost,
                    totalMatches: totalMatches,
                    matchesWon: matchesWon,
                    matchesLost: matchesLost,
                    avgMatchesWon: avgMatchesWon,
                    games: gamesWon + '-' + gamesLost,
                    totalGames: totalGames,
                    gamesWon: gamesWon,
                    gamesLost: gamesLost,
                    avgGamesWon: avgGamesWon,
                    skillLevel: player.skill_level,
                    matchWinPercentage: Math.round((matchesWon / totalMatches) * 100) || 0,
                    gameWinPercentage: Math.round((gamesWon / totalGames) * 100) || 0,
                    earnings: player.payouts && player.payouts.money,
                    calcutta: player.payouts && player.payouts.calcutta,
                    points: player.player_points && player.player_points.points,
                    totalMatchDurationMinutes,
                    avgMatchDurationMinutes,
                    totalMatchDurationHours,
                    avgMatchDurationHours,
                });
            }
        }

        console.log(formattedResults);

        // totalTournamentMatches = totalTournamentMatches / 2;

        console.log({
            totalTournamentMatches,
            totalTournamentGames,
            totalMatchDurationHours: totalTournamentMatchDurationHours,
            totalMatchDurationMinutes: totalTournamentMatchDurationMinutes,
            avgMatchDurationHours: parseFloat((totalTournamentMatchDurationHours / totalTournamentMatches).toFixed(2)),
            avgMatchDurationMinutes: parseFloat((totalTournamentMatchDurationMinutes / totalTournamentMatches).toFixed(2)),
            avgGamesPerMatch: parseFloat((totalTournamentGames / totalTournamentMatches).toFixed(2)),
        });

        return _.uniqBy(formattedResults, 'name');
    }

    async function downloadCSV() {
        setCSVDownloadLoading(true);

        let query = await client.query({
            query: GET_TOURNAMENT_PLAYER_STANDINGS,
            variables: {
                tournament_id: tournament.id,
            },
        });

        if (!query.data.tournament_players.length) {
            message.success('There was an error');
            setCSVDownloadLoading(false);
        }

        let filename = tournament.slug + '.csv';
        let data = orderAndFormatData(query.data.tournament_players, true);
        const fields = [
            'place',
            'name',
            'country',
            'matches',
            'matches_won',
            'games',
            'games_won',
            'skill_level',
            'match_win_percentage',
            'game_win_percentage',
            'earnings',
            'calcutta',
            'points',
        ];
        // const csv = await new objtocsv(data).toString();

        const json2csvParser = new Parser({ fields });
        const csv = json2csvParser.parse(data);

        var pom = document.createElement('a');
        pom.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv));
        pom.setAttribute('download', filename);

        if (document.createEvent) {
            var event = document.createEvent('MouseEvents');
            event.initEvent('click', true, true);
            pom.dispatchEvent(event);
        } else {
            pom.click();
        }

        message.success('Exporting completed');
        setCSVDownloadLoading(false);
    }

    async function formatResultsTableData(data) {
        if (data) {
            // console.log(data);
            let orderedData = orderAndFormatData(data);

            setTableData(orderedData);
        }
    }

    return (
        <SectionWrapper>
            <SectionHeader
                title={props.title}
                titleStyle={{ color: '#fff', margin: 0, textTransform: 'uppercase' }}
                subtitle={tournament.name}
                subtitlePrefix={true}
                subtitleStyle={{
                    color: 'rgba(255,255,255,0.8)',
                    textTransform: 'uppercase',
                    fontWeight: 600,
                    fontSize: 12,
                    letterSpacing: 3,
                }}
                height={90}
                theme="tournamentBuilder"
            >
                <TBManageButtons page="results" {...props} />
            </SectionHeader>
            {/* <TournamentProgressBar {...props} /> */}
            <SectionContent padding="20px 40px">
                <Row gutter={24}>
                    <Col xs={24}>
                        <Podium tournamentID={tournament.id} style={{ marginTop: 10, paddingLeft: 20, paddingRight: 20 }} />
                        <Card
                            // title="Players"
                            bodyStyle={{ padding: 0 }}
                            // style={{
                            // 	borderRadius: 8,
                            // 	overflow: 'hidden',
                            // 	border: 'none',
                            // 	boxShadow: '0 2px 5px 0px rgba(0, 0, 0, 0.1)'
                            // }}
                            cover={
                                <div
                                    style={{
                                        paddingTop: 16,
                                        paddingBottom: 16,
                                        paddingLeft: 24,
                                        paddingRight: 24,
                                        // background:'linear-gradient(rgb(239, 243, 245), rgb(255, 255, 255))',
                                        borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <Title level={4} style={{ margin: 0, padding: 0, fontSize: 18 }}>
                                        Results
                                    </Title>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                        }}
                                    >
                                        <Button type="primary" onClick={downloadCSV}>
                                            <Icon type={csvDownloadLoading ? 'loading' : 'download'} /> Export CSV
                                        </Button>
                                    </div>
                                </div>
                            }
                        >
                            <Subscription
                                subscription={GET_TOURNAMENT_PLAYER_STANDINGS_SUBSCRIPTION}
                                fetchPolicy="cache-and-network"
                                notifyOnNetworkStatusChange={true}
                                variables={{ tournament_id: tournament.id }}
                                onSubscriptionData={(data) => {
                                    if (
                                        data &&
                                        data.subscriptionData &&
                                        data.subscriptionData.data &&
                                        data.subscriptionData.data.tournament_players &&
                                        data.subscriptionData.data.tournament_players.length
                                    ) {
                                        formatResultsTableData(data.subscriptionData.data.tournament_players);
                                    }
                                }}
                            >
                                {({ loading, error, data }) => {
                                    if (loading) return <CircularLoader />;

                                    return <ResultsTable tournament={tournament} tournamentID={tournament.id} formattedResults={tableData} />;
                                }}
                            </Subscription>
                        </Card>
                    </Col>
                </Row>
            </SectionContent>
            <SectionFooter {...props} />
        </SectionWrapper>
    );
}
