import React, { useState, useEffect, useRef } from 'react';
import { Popconfirm, Modal, message, Tabs, Layout, Row, Col, Steps, Empty, Alert, Typography, Button, Icon, Result } from 'antd';
import CircularLoader from '../../components/CircularLoader';
import { Query } from '@apollo/client/react/components';
import { GET_TOURNAMENT_PLAYERS_QUERY, GET_TOURNAMENT_BRACKET_TEMPLATE } from './data/queries';
import { UPDATE_TOURNAMENT_PLAYER_MUTATION } from './data/mutations';
import { payoutStructures, getBracketSize, getByeChart, displayEnumType } from '../../utils';
import CircleFlag from '../../components/CircleFlag';
import { ReloadOutlined } from '@ant-design/icons';
import { seedingChart } from '../../utils';
import { useMutation } from '@apollo/react-hooks';
import createBracketDBDatasetDoubleElim from './../../lib/bracket.js';
import createBracketDBDatasetModifiedDoubleElim from './../../lib/bracket-modified-double-elim.js';
import createBracketDBDatasetSingleElim from './../../lib/bracket-single.js';
import createBracketDBDatasetCustom from './../../lib/bracket-custom.js';
import BracketStaticDoubleElim from '../digitalpool/tournaments/bracket/BracketStaticDoubleElim';
import BracketStaticSingleElim from '../digitalpool/tournaments/bracket/BracketStaticSingleElim';
import Hashids from 'hashids';
import axios from 'axios';
import _ from 'lodash';

const { Step } = Steps;
const { Text, Title } = Typography;
const { Content, Footer } = Layout;
const { confirm } = Modal;
const { TabPane } = Tabs;

const styles = {
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        padding: 20,
    },
    cardHeader: {
        paddingTop: 5,
        paddingBottom: 5,
        background: 'linear-gradient(0deg, rgb(241, 244, 246), rgb(255, 255, 255))',
        // boxShadow: 'rgba(0, 0, 0, 0.05) 0px 2px 8px 0px',
        borderRadius: '10px 10px 0px 0px',
    },
};

export default function PreviewStage(props) {
    const { client, tournament, bracketCreated, stages, stage, onSetCurrentStep, onStageUpdated, onResetBracket } = props;
    const [bracketSizeWarning, setBracketSizeWarning] = useState();
    const [places, setPlaces] = useState();
    const [payouts, setPayouts] = useState();
    const [players, setPlayers] = useState();
    const [currentTab, setCurrentTab] = useState('stage-1');
    const [roundOrdering, setRoundOrdering] = useState();
    const [finalBracketSize, setFinalBracketSize] = useState();
    const [updatedMatches, setUpdatedMatches] = useState();

    const hashids = new Hashids(`Tournament salt`);
    console.log('stage', stage);
    const tournamentType = (stage && stage.tournament_type) || (tournament && tournament.tournament_type);
    const tournamentFormat = (stage && stage.tournament_format) || (tournament && tournament.tournament_format);
    const drawType = (stage && stage.draw_type) || (tournament && tournament.draw_type);
    const maxPlayers = (stage && stage.max_players) || (tournament && tournament.max_players);
    const templateId = (stage && stage.bracket_template_id) || (tournament && tournament.bracket_template_id);

    useEffect(() => {
        console.log({
            tournamentType,
            tournamentFormat,
            drawType,
            maxPlayers,
        });
    }, [bracketCreated]);

    // let maxPlayers;
    // if (stage && stage.number === 0) {
    //     maxPlayers = values && stageFormField ? values.stages[index].max_players : values.max_players;
    // } else {
    //     maxPlayers = values && stageFormField ? getBracketSize(values.stages[0].max_players) : getBracketSize(values.max_players);
    // }

    function formatBracket(tournament_brackets) {
        let bracket = [];
        let roundNum = 0;

        tournament_brackets.forEach((round, i) => {
            if (i > 0 && round.round === tournament_brackets[i - 1].round) {
                bracket[roundNum].push(round);
            } else if (i === 0) {
                bracket.push([round]);
            } else if (i > 0 && round.round !== tournament_brackets[i - 1].round) {
                bracket.push([round]);
                roundNum++;
            }
        });

        const loserBracket =
            bracket &&
            bracket.filter((rounds) => {
                return rounds[0].round < 1;
            });

        const loserBracketReversed =
            bracket &&
            bracket
                .filter((rounds) => {
                    return rounds[0].round < 1;
                })
                .reverse();

        const draw =
            bracket &&
            bracket.filter((rounds) => {
                return rounds[0].round === 1;
            });

        const winnerBracket =
            bracket &&
            bracket.filter((rounds) => {
                return rounds[0].round > 1;
            });

        return {
            tournament_brackets,
            ...((tournamentType === 'double_elimination' || tournamentType === 'modified_double_elimination') && loserBracket),
            ...((tournamentType === 'double_elimination' || tournamentType === 'modified_double_elimination') && loserBracketReversed),
            drawRound: draw,
            winnerBracket,
        };

        // console.log(tournament_brackets);
        // console.log('bracket', bracket);
        // console.log('winnerBracket', winnerBracket);
        // console.log('drawRound', draw);
    }

    async function generateBracket(data, stage) {
        const players = data.tournament_players;
        let stagePlayers = [];
        const playersWithMatches = players.map((player) => {
            const challenger1Matches = player.tournament_matches_challenger1.filter((match) => {
                return match.challenger1_id === player.id;
            });
            const challenger2Matches = player.tournament_matches_challenger2.filter((match) => {
                return match.challenger2_id === player.id;
            });
            const playerMatches = [...challenger1Matches, ...challenger2Matches];
            let stageMatches = [];
            if (stage) {
                stageMatches = playerMatches
                    .sort((a, b) => {
                        return a.match_number - b.match_number;
                    })
                    .filter((match) => {
                        return match.stage_id === stage.id;
                    });
            }
            if (stageMatches && stageMatches.length > 0) {
                stagePlayers.push(player);
            }
            return {
                ...player,
                matches: playerMatches,
            };
        });

        const sortedStagePlayers = stagePlayers.sort((a, b) => {
            return a.seed - b.seed;
        });

        console.log('players w/matches', playersWithMatches);
        console.log('stage players', sortedStagePlayers);

        // const tournamentPlayers = stage && stage.number > 1 ? [] : playersWithMatches;
        const tournamentPlayers =
            stage && stage.number > 1 ? (sortedStagePlayers && sortedStagePlayers.length > 0 ? sortedStagePlayers : []) : playersWithMatches;
        const tournamentType = (stage && stage.tournament_type) || (tournament && tournament.tournament_type);
        const entryFee = (stage && stage.entry_fee) || (tournament && tournament.entry_fee);
        const addedMoney = (stage && stage.added_money) || (tournament && tournament.added_money);
        const payoutType = (stage && stage.payout_type) || (tournament && tournament.payout_type);
        const bracketSize = stage && stage.number > 1 ? tournament.players_advance_per_group : getBracketSize(tournamentPlayers.length);
        let tournamentBrackets;
        let updatedMatches;

        console.log('tournament', tournament);
        console.log('tournament players', tournamentPlayers);
        console.log('current stage', stage);

        if (tournamentType && tournamentType === 'custom' && templateId) {
            const customBracket = await getCustomBracket();
            updatedMatches = createBracketCustom(customBracket, tournamentPlayers, stage);
            console.log('bracket custom', updatedMatches);

            setUpdatedMatches(updatedMatches);
            const data = {
                number: (stage && stage.number) || 1,
                matches: updatedMatches,
                players: tournamentPlayers,
                places: places,
                payouts: [],
                bracketSize: bracketSize,
            };
            console.log('new stage data', data);
            if (onStageUpdated) {
                onStageUpdated(data);
            }
        } else {
            tournamentBrackets = createBracket(tournament, tournamentPlayers, stage);
            updatedMatches = tournamentBrackets && tournamentBrackets.updatedMatches;
            const places = tournamentBrackets && tournamentBrackets.places;

            console.log(tournamentBrackets);

            const payouts = payoutStructures({
                entry_fee: entryFee,
                added_money: addedMoney,
                max_players: tournamentPlayers,
                payout_type: payoutType,
            });

            console.log(payouts);

            const chosenPayouts = payouts.chosenPayouts && payouts.chosenPayouts.payouts;

            console.log('chosen payouts', payouts);

            const combinedPayouts = places.map((item, index) => {
                const place = item.place.split('-');
                const placeMinPlayers = parseInt(place[0]);
                const placeMaxPlayers = parseInt(place[1]);

                // console.log(placeMinPlayers);
                // console.log(placeMaxPlayers);
                // console.log(chosenPayouts);

                let matchingPayout =
                    chosenPayouts &&
                    chosenPayouts.filter((item) => {
                        if (placeMinPlayers && placeMaxPlayers) {
                            return item.place >= placeMinPlayers && item.place <= placeMaxPlayers;
                        } else if (placeMinPlayers && !placeMaxPlayers) {
                            return item.place === placeMinPlayers;
                        }
                    });

                return {
                    ...item,
                    ...(matchingPayout &&
                        matchingPayout[0] && {
                            money: matchingPayout[0].payout,
                        }),
                    ...(matchingPayout &&
                        matchingPayout[0] && {
                            percent: matchingPayout[0].percent,
                        }),
                };
            });

            console.log('combined payouts', combinedPayouts);

            setUpdatedMatches(updatedMatches);
            setPlaces(places);
            setPayouts(combinedPayouts);
            setPlayers(tournamentPlayers);

            const data = {
                number: (stage && stage.number) || 1,
                matches: updatedMatches,
                players: tournamentPlayers,
                places: places,
                payouts: combinedPayouts,
                bracketSize: bracketSize,
            };
            console.log('new stage data', data);
            if (onStageUpdated) {
                onStageUpdated(data);
            }
            return data;
        }
    }

    function createBracketCustom(bracket, players) {
        console.log(players);
        const totalPlayers = (players && players.length) || maxPlayers;
        const bracketSize = getBracketSize(totalPlayers);

        if (bracketSize !== maxPlayers) {
            setBracketSizeWarning(`Based on ${totalPlayers} players, the bracket size has been set to ${bracketSize}.`);
        }
        setFinalBracketSize(bracketSize);

        let matches = [];
        bracket.rounds.forEach((round, roundIndex) => {
            round.matches.forEach((match, matchIndex) => {
                const randomNum = getRandomArbitrary(1, 10000).toFixed(0);
                const hashId = hashids.encode(randomNum, tournament.id, roundIndex + 1, matchIndex + 1);
                matches.push({
                    hash_id: hashId,
                    identifier: match.identifier,
                    is_consolidation_finals: match.is_consolidation_finals,
                    is_finals: match.is_finals,
                    is_semi_finals: match.is_semi_finals,
                    loser_from: match.loser_from,
                    loser_from_num: match.loser_from_num,
                    loser_from_top_num: match.loser_from_top_num,
                    loser_from_bottom_num: match.loser_from_bottom_num,
                    loser_to: match.loser_to,
                    loser_to_num: match.loser_to_num,
                    match_number: match.match_number,
                    place: match.place,
                    round: match.round,
                    tournament_id: tournament.id,
                    winner_from: match.winner_from,
                    winner_from_num: match.winner_from_num,
                    winner_from_top_num: match.winner_from_top_num,
                    winner_from_bottom_num: match.winner_from_bottom_num,
                    winner_to: match.winner_to,
                    winner_to_num: match.winner_to_num,
                    stage_id: stage && stage.id,
                    stage_number: stage && stage._number,
                });
            });
        });

        let drawRound = bracket.drawRound &&
            bracket.drawRound[0] &&
            bracket.drawRound[1] && [[...bracket.drawRound[0].matches, ...bracket.drawRound[1].matches]];

        console.log({
            matches,
        });

        console.log(drawRound);

        const draw = generateDrawCustom(
            drawRound,
            bracket.winnerBracket,
            matches,
            players,
            stage && stage.draw_type === 'random' ? true : false // random draw (true for random, false for seeded)
        );

        console.log('DRAW', draw);

        let updatedMatches = [];

        matches.forEach((match, index) => {
            let updatedMatch1 = _.omit(match, 'top_seed');
            let updatedMatch2 = _.omit(updatedMatch1, 'bottom_seed');
            let updatedMatch3 = _.omit(updatedMatch2, 'round_id');
            let updatedMatch4 = _.omit(updatedMatch3, '__typename');
            const updatedMatch = updatedMatch4;

            const filtered = draw.filter((item) => {
                if (item) {
                    return item.identifier === match.identifier;
                }
            });

            if (filtered && filtered.length) {
                const merged = filtered.reduce(function (acc, x) {
                    for (var key in x) acc[key] = x[key];
                    return acc;
                }, {});
                updatedMatches.push({
                    ...updatedMatch,
                    ...merged,
                });
            } else {
                updatedMatches.push({
                    ...updatedMatch,
                });
            }
        });

        console.log('UPDATED MATCHES', updatedMatches);

        return updatedMatches;
    }

    // function getMatchesByRound(round, bracket) {
    //     const matches = [];
    //     bracket.forEach((item, index) => {
    //         if (item.round === round) {
    //             matches.push(item);
    //         }
    //     });
    //     return matches;
    // }

    // function getBracketPlacements(bracket) {
    //     const numPlayers = 48;
    //     let prevPlayersLeft;
    //     let trueDoubleElim = true;
    //     const winnerRounds = Math.ceil(Math.log2(numPlayers));
    //     const L2 = Math.log2(numPlayers);
    //     const loserRounds = Math.ceil(L2) + Math.ceil(Math.log2(L2));
    //     const totalRounds = Math.ceil(winnerRounds + loserRounds);
    //     // generate the correct order in which the rounds should be played
    //     const roundOrder = setRoundOrdering(totalRounds, 48, trueDoubleElim);
    //     console.log('round order:', roundOrder);
    //     // console.log('hard coded pattern:', [ 1, 2, -1, -2, 3, -3, -4, 4, -5, -6, 5, -7, -8, 6 ]);

    //     let rounds = roundOrder.map((round) => {
    //         const roundMatches = getMatchesByRound(round, bracket);
    //         return roundMatches;
    //     });

    //     console.log('rounds:', rounds);

    //     let orderingLosers = rounds.filter((round) => {
    //         return round[0] && round[0].round < 2;
    //     });

    //     console.log('ordering losers', orderingLosers);

    //     let loserBracketPlacement = orderingLosers
    //         .map((round, index) => {
    //             let roundPlayers = orderingLosers.length;
    //             let initialPlayers;
    //             let playersLeft;
    //             let place;

    //             if (index === 0) {
    //                 initialPlayers = 48;
    //                 // initialPlayers = maxPlayers - requiredByes; // 128, 64, 32
    //                 playersLeft = orderingLosers.length * 2 - orderingLosers.length / 2; // 96, 65, 49, 33
    //             } else {
    //                 initialPlayers = prevPlayersLeft;
    //                 playersLeft = initialPlayers - roundPlayers;
    //                 if (index % 2 === 0) {
    //                     playersLeft = initialPlayers - roundPlayers / 2;
    //                 }
    //             }

    //             if (initialPlayers > 4) {
    //                 place = `${playersLeft + 1}-${initialPlayers}`;
    //             } else {
    //                 if (initialPlayers === 2) {
    //                     place = 3; // tie for 3rd
    //                 } else {
    //                     place = initialPlayers;
    //                 }
    //                 // place = initialPlayers;
    //             }

    //             console.log({
    //                 place: place.toString(),
    //                 roundPlayers,
    //                 length: orderingLosers.length,
    //                 round,
    //             });

    //             prevPlayersLeft = playersLeft;
    //             return place.toString();
    //         })
    //         .slice(0, orderingLosers.length - 1);

    //     let orderingWinners = rounds.filter((round) => {
    //         return round[0] && round[0].round > 0;
    //     });

    //     console.log('ordering winners', orderingWinners);

    //     let winnerBracketPlacement = orderingWinners.map((round, index) => {
    //         let roundPlayers = orderingWinners.length;
    //         let initialPlayers;
    //         let playersLeft;
    //         let place;

    //         initialPlayers = roundPlayers + roundPlayers / 2;
    //         playersLeft = initialPlayers - roundPlayers / 2;

    //         if (initialPlayers > 3) {
    //             place = `${playersLeft + 1}-${initialPlayers}`;
    //         } else {
    //             if (index === orderingWinners.length - 1) {
    //                 place = 1;
    //             } else if (index === orderingWinners.length - 2) {
    //                 if (trueDoubleElim === 1) {
    //                     place = 1;
    //                 } else {
    //                     place = 2;
    //                 }
    //             } else if (index === orderingWinners.length - 3 && trueDoubleElim === 1) {
    //                 place = 2;
    //             } else {
    //                 place = 3;
    //             }
    //         }

    //         return place.toString();
    //     });

    //     const loserBracket2 = rounds
    //         .filter((rounds) => {
    //             return rounds[0] && rounds[0].round < 1;
    //         })
    //         .map((rounds, index) => {
    //             return rounds.map((match) => {
    //                 return {
    //                     ...match,
    //                     place: loserBracketPlacement[index],
    //                 };
    //             });
    //         });

    //     const draw2 = rounds.filter((round) => {
    //         return rounds[0] && rounds[0].round === 1;
    //     });

    //     const winnerBracket2 = rounds
    //         .filter((rounds) => {
    //             return rounds[0] && rounds[0].round > 1;
    //         })
    //         .map((rounds, index) => {
    //             return rounds.map((match) => {
    //                 return {
    //                     ...match,
    //                     place: winnerBracketPlacement[index],
    //                 };
    //             });
    //         });

    //     const bracketWithPlacements = [...loserBracket2, ...draw2, ...winnerBracket2];

    //     let rounds2 = roundOrder.map((round) => {
    //         const roundMatches = getMatchesByRound(round, _.flatten(bracketWithPlacements));
    //         return roundMatches;
    //     });

    //     let batchInsertWithPlacement = _.flatten(rounds2);

    //     console.log('ordering1', batchInsertWithPlacement);
    //     console.log('ordering2', rounds2);

    //     return {
    //         loserPlacement: loserBracketPlacement,
    //         winnerPlacement: winnerBracketPlacement,
    //         matches: batchInsertWithPlacement,
    //     };
    // }

    function createBracket(tournament, players, stage) {
        // const totalPlayers = 1024;
        // const bracketSize = 1024;
        console.log(players);

        const totalPlayers = (players && players.length) || maxPlayers;
        const bracketSize = getBracketSize(totalPlayers);

        console.log({ totalPlayers, bracketSize });
        const playersAdvancing = stage && stage.players_advance_per_group;
        console.log('players advancing', playersAdvancing);
        if (bracketSize !== maxPlayers) {
            setBracketSizeWarning(`Based on ${totalPlayers} players, the bracket size has been set to ${bracketSize}.`);
        }
        setFinalBracketSize(bracketSize);

        let bracketDBDataset;
        if (tournamentType && tournamentType === 'double_elimination') {
            bracketDBDataset = createBracketDBDatasetDoubleElim(tournament.id, totalPlayers, bracketSize, tournament.consolidation_finals, playersAdvancing);
        } else if (tournamentType && tournamentType === 'modified_double_elimination') {
            bracketDBDataset = createBracketDBDatasetModifiedDoubleElim(tournament.id, totalPlayers, bracketSize, playersAdvancing);
        } else if (tournamentType && tournamentType === 'custom') {
            bracketDBDataset = createBracketDBDatasetCustom(tournament.id, totalPlayers, bracketSize, playersAdvancing);
        } else {
            bracketDBDataset = createBracketDBDatasetSingleElim(tournament.id, totalPlayers, bracketSize, playersAdvancing);
        }

        console.log({
            bracketDBDataset,
        });

        const winnerPlaces = bracketDBDataset.winnerPlacement;
        const loserPlaces = bracketDBDataset.loserPlacement;
        const matches = bracketDBDataset.matches;

        const bracketFormatted = formatBracket(matches);
        console.log({
            matches,
        });

        let drawRound = bracketFormatted.drawRound;
        let winnerBracket = bracketFormatted.winnerBracket;

        console.log({ bracketFormatted });

        const draw = generateDraw(
            drawRound,
            winnerBracket,
            matches,
            players,
            drawType === 'random' ? true : false // random draw (true for random, false for seeded)
        );
        console.log('DRAW', draw);
        let updatedMatches = [];

        matches.map((match, index) => {
            const filtered = draw.filter((item) => {
                return item.identifier === match.identifier;
            });
            if (filtered && filtered.length) {
                const merged = filtered.reduce(function (acc, x) {
                    for (var key in x) acc[key] = x[key];
                    return acc;
                }, {});
                updatedMatches.push({
                    ...match,
                    ...merged,
                    stage_id: stage && stage.id,
                    stage_number: stage && stage.number,
                });
            } else {
                updatedMatches.push({
                    ...match,
                    stage_id: stage && stage.id,
                    stage_number: stage && stage.number,
                });
            }
        });

        // updatedMatches = updatedMatches.slice(-2);

        let places;
        if (tournamentType && tournamentType === 'double_elimination') {
            places = _.uniq([...winnerPlaces.reverse(), ...loserPlaces.reverse(), '2'])
                // .concat([ '3' ])
                .sort((a, b) => {
                    return a.localeCompare(b, undefined, {
                        numeric: true,
                        sensitivity: 'base',
                    });
                })
                .map((item) => {
                    return {
                        place: item,
                        tournament_id: tournament.id,
                    };
                });
        } else if (tournamentType && tournamentType === 'modified_double_elimination') {
            places = _.uniq([...winnerPlaces.reverse(), '2'])
                // .concat([ '3' ])
                .sort((a, b) => {
                    return a.localeCompare(b, undefined, {
                        numeric: true,
                        sensitivity: 'base',
                    });
                })
                .map((item) => {
                    return {
                        place: item,
                        tournament_id: tournament.id,
                    };
                });
        } else {
            places = _.uniq([...winnerPlaces.reverse(), '2'])
                // .concat([ '3' ])
                .sort((a, b) => {
                    return a.localeCompare(b, undefined, {
                        numeric: true,
                        sensitivity: 'base',
                    });
                })
                .map((item) => {
                    return {
                        place: item,
                        tournament_id: tournament.id,
                    };
                });
        }

        console.log('places', places);

        // setTournamentBrackets(updatedMatches);

        // addPoints({
        // 	variables: {
        // 		objects: places
        // 	}
        // });

        // addPayouts({
        // 	variables: {
        // 		objects: places
        // 	}
        // });

        // addBracket({
        // 	variables: {
        // 		objects: matches
        // 	}
        // });

        console.log('UPDATED MATCHES', updatedMatches);

        return {
            updatedMatches,
            places,
        };
    }

    async function getCustomBracket() {
        let q = await client.query({
            query: GET_TOURNAMENT_BRACKET_TEMPLATE,
            variables: {
                // "league_id": 5,
                // "division_id": 18
                id: templateId,
            },
        });

        const template = q && q.data && q.data.tournament_bracket_templates && q.data.tournament_bracket_templates[0];
        const segments = template && template.segments;
        let rounds = [];
        segments &&
            segments.forEach((segment) => {
                segment.rounds &&
                    segment.rounds.map((round) => {
                        rounds.push(round);
                    });
            });

        const roundsSorted = _.sortBy(rounds, ['round']);
        let matches = [];
        roundsSorted.forEach((round) => {
            round.matches &&
                round.matches.map((match) => {
                    matches.push(match);
                });
        });

        const bracket = buildBracket(roundsSorted);
        console.log('BRACKET TEMPLATE', bracket);

        return bracket;
    }

    function buildBracket(rounds) {
        let bracket = [];
        let roundNum = 0;

        rounds.forEach((round, i) => {
            if (i > 0 && round.round === rounds[i - 1].round) {
                bracket[roundNum].push(round);
            } else if (i === 0) {
                bracket.push([round]);
            } else if (i > 0 && round.round !== rounds[i - 1].round) {
                bracket.push([round]);
                roundNum++;
            }
        });

        const loserBracket =
            bracket &&
            bracket
                .filter((rounds) => {
                    return rounds[0].round < 1;
                })

                .map((rounds) => {
                    return {
                        ...rounds[0],
                        matches: rounds[0].matches,
                    };
                });

        // let draw = []

        const draw =
            bracket &&
            bracket
                .filter((rounds) => {
                    return rounds[0].round === 1 || rounds[0].round === 2;
                })
                .map((rounds) => {
                    // draw =
                    return {
                        ...rounds[0],
                        matches: rounds[0].matches,
                    };
                });

        const winnerBracket =
            bracket &&
            bracket
                .filter((rounds) => {
                    return rounds[0].round > 1;
                })
                .map((rounds) => {
                    return {
                        ...rounds[0],
                        matches: rounds[0].matches,
                    };
                });

        const output = {
            rounds,
            loserBracket,
            drawRound: draw,
            winnerBracket,
        };

        console.log('bracket', output);
        return output;
    }

    function generateDraw(draw, winnerBracket, matches, players, random = true) {
        // console.log(draw);
        const totalPlayers = (players && players.length) || maxPlayers;
        const bracketSize = getBracketSize(totalPlayers);
        // console.log(winnerBracket);
        // console.log(tournament.max_players);

        var totalRounds = Math.ceil(Math.log(totalPlayers) / Math.log(2));
        let updatedDraw = [];

        if (players.length > 0) {
            var seeds = generateSeeds(totalPlayers, draw, bracketSize);
            console.log('seeds', seeds);
            let playersToUpdate = [];

            const key = 'updatable';
            // message.loading({ content: 'Seeding bracket...', key, duration: 0 });

            if (drawType === 'random') {
                // players = shuffle(_.uniq(players, 'id'));
                const contestantsCopy = players.slice(0);
                // Shuffle contestantsCopy with Fisher-Yates Shuffle
                for (let i = players.length - 1; i > 0; --i) {
                    let j = Math.floor(Math.random() * (i + 1));
                    swap(contestantsCopy, i, j);
                }
                function swap(array, i, j) {
                    let temp = array[i];
                    array[i] = array[j];
                    array[j] = temp;
                }
                players = contestantsCopy;
                // players = contestantsCopy.map((player, index) => {
                //     return {
                //         ...player,
                //         seed: index + 1,
                //     };
                // });
                console.log('new players order', players);
            } else {
                players = _.uniq(players, 'id').sort((a, b) => {
                    if (a.seed && b.seed) {
                        return a.seed - b.seed;
                    } else {
                        return a.id - b.id;
                    }
                });
            }

            // players = players.slice(0, 28);

            var playerIndex = 1;
            console.log('players', players);
            console.log('seeds', seeds);
            const playerSeeds = players.map((player) => player.seed);
            const duplicatePlayerSeedIds = playerSeeds.filter((item, index) => playerSeeds.indexOf(item) !== index);
            const duplicateSeededPlayers = players.filter((player) => {
                return _.includes(duplicatePlayerSeedIds, player.seed) ? true : false;
            });
            const missingSeedPlayers = players.filter((player) => {
                return !player.seed;
            });
            console.log('players with duplicate seeds', duplicateSeededPlayers);
            console.log('players missing seeds', missingSeedPlayers);

            if ((drawType === 'seeded' || drawType === 'ordered') && duplicateSeededPlayers && duplicateSeededPlayers.length > 0) {
                Modal.warning({
                    title: `Warning: duplicate or missing seed values`,
                    content: (
                        <div>
                            <div>
                                <b>
                                    {duplicateSeededPlayers.length + missingSeedPlayers.length} players were found with duplicate or missing seed values. We
                                    automatically fixed these issues by randomly placing these players in the bracket preview below. No action is required, but
                                    we recommend fixing the following player seed values to ensure the players are placed in the correct order.
                                </b>
                            </div>

                            <div>
                                {duplicateSeededPlayers && duplicateSeededPlayers.length > 0 && (
                                    <React.Fragment>
                                        <div style={{ marginTop: 10, marginBottom: 5 }}>
                                            <Text>Players with duplicate seeds:</Text>
                                        </div>
                                        {duplicateSeededPlayers.map((player, index) => (
                                            <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
                                                <CircleFlag country={player.country} />{' '}
                                                <Text>
                                                    {player.name} - {player.seed}
                                                </Text>
                                            </div>
                                        ))}
                                    </React.Fragment>
                                )}

                                {missingSeedPlayers && missingSeedPlayers.length > 0 && (
                                    <React.Fragment>
                                        <div style={{ marginTop: 10, marginBottom: 5 }}>
                                            <Text>Players missing seeds:</Text>
                                        </div>
                                        {missingSeedPlayers.map((player, index) => (
                                            <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
                                                <CircleFlag country={player.country} /> <Text>{player.name} - No seed</Text>
                                            </div>
                                        ))}
                                    </React.Fragment>
                                )}
                            </div>
                        </div>
                    ),
                    transitionName: 'fade',
                    maskTransitionName: 'none',
                    centered: true,
                    destroyOnClose: true,
                    bodyStyle: { maxHeight: 500, overflowY: 'auto', padding: 0 },
                });
            }

            let unseededPlayers = players.filter((player) => {
                return !_.includes(_.flatten(seeds), player.seed);
            });

            console.log('unseeded players', unseededPlayers);

            let seededPlayers = seeds.map((match, seedIndex) => {
                // console.log(match);
                // console.log(players);
                let player1;
                let player2;

                const byeChart = getByeChart(bracketSize);
                var matchNumber = seedIndex * 2 + 1;
                const numberOfByes = bracketSize - totalPlayers;
                console.log({
                    byeChart,
                    maxPlayers: maxPlayers,
                    totalPlayers: totalPlayers,
                    numberOfByes,
                    seedIndex,
                    matchNumber,
                });

                var seed1 = -1;
                var seed2 = -1;

                if (byeChart && byeChart.indexOf(matchNumber) <= numberOfByes - 1 && byeChart.indexOf(matchNumber) !== -1) {
                    seed1 = -1;
                } else {
                    //seed1 = match[0] - 1;
                    seed1 = playerIndex - 1;
                    playerIndex++;
                }

                // console.log({
                //     matchNumber,
                //     seed1,
                // });

                matchNumber++;
                if (byeChart && byeChart.indexOf(matchNumber) <= numberOfByes - 1 && byeChart.indexOf(matchNumber) !== -1) {
                    seed2 = -1;
                } else {
                    //seed2 = match[1] - 1;
                    seed2 = playerIndex - 1;
                    playerIndex++;
                }

                // console.log({
                //     matchNumber,
                //     seed2,
                // });
                console.log(matchNumber);

                console.log({
                    match: match.toString(),
                    seed1,
                    seed2,
                });

                if (drawType === 'seeded') {
                    player1 = players.filter((player, index) => {
                        if (player.seed) {
                            return player.seed === parseInt(match[0]);
                        } else {
                            return index + 1 === match[0];
                        }
                    })[0];

                    // if (!player1) {
                    //     player1 = unseededPlayers[0];
                    // }

                    player2 = players.filter((player, index) => {
                        if (player.seed) {
                            return player.seed === parseInt(match[1]);
                        } else {
                            return index + 1 === match[1];
                        }
                    })[0];

                    console.log({
                        player1,
                        player2,
                    });

                    // if (!player2) {
                    //     player2 = unseededPlayers[0];
                    // }

                    // let nextUnassignedSeed = unassignedSeeds[unassignedCount];

                    // if (!player1) {
                    //     if (nextUnassignedSeed) {
                    //         player1 = players[nextUnassignedSeed - 1];
                    //         unassignedCount++;
                    //     }
                    // }

                    // if (!player2) {
                    //     if (nextUnassignedSeed) {
                    //         player2 = players[nextUnassignedSeed - 1];
                    //         unassignedCount++;
                    //     }
                    // }

                    // console.log({
                    //     player1NewSeed: player1,
                    //     player2NewSeed: player2,
                    // });

                    // player1 = players[match[0] - 1];
                    // player2 = players[match[1] - 1];

                    // player1 = players[seed1];
                    // player2 = players[seed2];

                    // console.log({
                    //     p1: players[match[0] - 1],
                    //     p2: players[match[1] - 1],
                    // });

                    // console.log({
                    //     pp1: players[seed1 - 1],
                    //     pp2: players[seed2 - 1],
                    // });
                } else {
                    player1 = players[seed1];
                    player2 = players[seed2];
                }

                // player1 = players[seed1];
                // player2 = players[seed2];

                console.log({
                    match: match.toString(),
                    seed1,
                    seed2,
                    player1,
                    player2,
                });

                if (player1) {
                    playersToUpdate.push({
                        id: player1.id,
                        name: player1.name,
                        country: player1.country,
                        email: player1.email,
                        phone_number: player1.phone_number,
                        skill_level: player1.skill_level,
                        status: player1.status,
                        points: player1.points,
                        seed: player1.seed || match[0],
                        race_to: player1.race_to,
                    });
                }

                if (player2) {
                    playersToUpdate.push({
                        id: player2.id,
                        name: player2.name,
                        country: player2.country,
                        email: player2.email,
                        phone_number: player2.phone_number,
                        skill_level: player2.skill_level,
                        status: player2.status,
                        points: player2.points,
                        seed: player2.seed || match[1],
                        race_to: player2.race_to,
                    });
                }

                console.log('playersToUpdate', playersToUpdate);

                const isBye = player1 && player2 ? false : true;
                // const isBye = byeChart.includes(seedIndex + 1) ? true : false;

                return {
                    match_number: seedIndex + 1,
                    challenger1_id: player1 ? player1.id : null,
                    challenger1_name: player1 ? player1.name : 'BYE',
                    challenger1_country: player1 ? player1.country : null,
                    challenger1_race_to: player1 ? player1.race_to : null,
                    challenger1_skill_level: player1 ? player1.skill_level : null,
                    challenger1_sms_sent: player1 ? player1.sms_sent : null,
                    challenger1_score: 0,
                    challenger1_seed: match[0],
                    challenger2_id: player2 ? player2.id : null,
                    challenger2_name: player2 ? player2.name : 'BYE',
                    challenger2_country: player2 ? player2.country : null,
                    challenger2_race_to: player2 ? player2.race_to : null,
                    challenger2_skill_level: player2 ? player2.skill_level : null,
                    challenger2_sms_sent: player2 ? player2.sms_sent : null,
                    challenger2_score: 0,
                    challenger2_seed: match[1],
                    is_bye: isBye,
                    status: player1 && player2 ? 'NOT_STARTED' : 'COMPLETED',
                    progress: player1 && player2 ? 0 : 100,
                };
            });

            // const playerIds = _.uniq(players.map((player) => player.id));
            // const seededPlayerIds = _.compact(_.uniq(_.flatten(seededPlayers.map((player) => [player.challenger1_id, player.challenger2_id]))));
            // const unassignedPlayers = _.difference(playerIds, seededPlayerIds);
            // console.log('player ids', playerIds);
            // console.log('seeded players', seededPlayers);
            // console.log('seeded player ids', seededPlayerIds);
            // console.log('unassigned players', unassignedPlayers);

            // const byeChart = getByeChart(bracketSize);
            // console.log('bye chart', byeChart);
            // let seededByeMatches = seededPlayers
            //     .filter((item) => {
            //         return item.is_bye === true;
            //     })
            //     .sort((a, b) => byeChart.indexOf(a.match_number) - byeChart.indexOf(b.match_number));

            // console.log('seeded bye matches', seededByeMatches);

            // unassignedPlayers.forEach((id, index) => {
            //     const playerData = players.filter((player, index) => {
            //         return player.id === id;
            //     })[0];
            //     console.log(playerData);

            //     // const nextByeMatch = byeChart[index];
            //     // const numberOfByes = bracketSize - players.length;

            //     // if (byeChart && byeChart.indexOf(matchNumber) <= numberOfByes - 1 && byeChart.indexOf(matchNumber) !== -1) {
            //     //     seed1 = -1;
            //     // } else {
            //     //     //seed1 = match[0] - 1;
            //     //     seed1 = playerIndex - 1;
            //     //     playerIndex++;
            //     // }

            //     if (playerData) {
            //         // const currentByeMatch = seededByeMatches.filter((item) => item.match_number === nextByeMatch)[0];
            //         const currentByeMatch = seededByeMatches[index];
            //         // const currentByeMatch = seededPlayers.filter((item) => item.match_number === nextByeMatch);
            //         seededByeMatches[index] = {
            //             ...currentByeMatch,
            //             challenger1_id: currentByeMatch.challenger1_name !== 'BYE' ? currentByeMatch.challenger1_id : playerData.id,
            //             challenger1_name: currentByeMatch.challenger1_name !== 'BYE' ? currentByeMatch.challenger1_name : playerData.name,
            //             challenger1_country: currentByeMatch.challenger1_name !== 'BYE' ? currentByeMatch.challenger1_country : playerData.country,
            //             challenger1_race_to: currentByeMatch.challenger1_name !== 'BYE' ? currentByeMatch.challenger1_race_to : playerData.race_to,
            //             challenger1_skill_level: currentByeMatch.challenger1_name !== 'BYE' ? currentByeMatch.challenger1_skill_level : playerData.skill_level,
            //             challenger1_sms_sent: currentByeMatch.challenger1_name !== 'BYE' ? currentByeMatch.challenger1_sms_sent : playerData.sms_sent,
            //             challenger1_seed: currentByeMatch.challenger1_name !== 'BYE' ? currentByeMatch.challenger1_seed : playerData.seed,
            //             challenger1_score: 0,
            //             challenger2_id: currentByeMatch.challenger2_name !== 'BYE' ? currentByeMatch.challenger2_id : playerData.id,
            //             challenger2_name: currentByeMatch.challenger2_name !== 'BYE' ? currentByeMatch.challenger2_name : playerData.name,
            //             challenger2_country: currentByeMatch.challenger2_name !== 'BYE' ? currentByeMatch.challenger2_country : playerData.country,
            //             challenger2_race_to: currentByeMatch.challenger2_name !== 'BYE' ? currentByeMatch.challenger2_race_to : playerData.race_to,
            //             challenger2_skill_level: currentByeMatch.challenger2_name !== 'BYE' ? currentByeMatch.challenger2_skill_level : playerData.skill_level,
            //             challenger2_sms_sent: currentByeMatch.challenger2_name !== 'BYE' ? currentByeMatch.challenger2_sms_sent : playerData.sms_sent,
            //             challenger2_seed: currentByeMatch.challenger2_name !== 'BYE' ? currentByeMatch.challenger2_seed : playerData.seed,
            //             challenger2_score: 0,
            //             is_bye: false,
            //             status: currentByeMatch.status,
            //             progress: currentByeMatch.progress,
            //         };

            //         playersToUpdate.push({
            //             id: playerData.id,
            //             name: playerData.name,
            //             country: playerData.country,
            //             email: playerData.email,
            //             phone_number: playerData.phone_number,
            //             skill_level: playerData.skill_level,
            //             status: playerData.status,
            //             points: playerData.points,
            //             seed: playerData.seed,
            //             race_to: playerData.race_to,
            //         });
            //     }
            // });

            // // seededByeMatches = [...seededByeMatches, ...updatedByeMatches];

            // console.log('updated bye matches', seededByeMatches);
            // // seededPlayers = Object.assign(seededPlayers, seededByeMatches);

            // seededPlayers = [
            //     ...seededPlayers.filter((item) => {
            //         return item.is_bye !== true;
            //     }),
            //     ...seededByeMatches,
            // ].sort((a, b) => {
            //     return a.match_number - b.match_number;
            // });

            // const duplicatePlayerIds = _.compact(
            //     seededPlayers
            //         .map((player) => player && player.challenger1_id)
            //         .map((player) => player && player.challenger2_id)
            //         .filter((item, index) => seededPlayers.indexOf(item) !== index)
            // );
            // const duplicatePlayers = seededPlayers.filter((item) => {
            //     return _.includes(duplicatePlayerSeedIds, item.challenger1_id) || _.includes(duplicatePlayerSeedIds, item.challenger2_id);
            // });
            // console.log('duplicate player ids', duplicatePlayerIds);
            // if (duplicatePlayerIds.length > 0) {
            //     console.log('duplicate players in bracket', duplicatePlayers);
            //     message.error(`${duplicatePlayerIds.length} duplicate players found in bracket`);
            // }
            console.log('playes to update', playersToUpdate);

            function updatePlayer(player) {
                return props.client
                    .mutate({
                        mutation: UPDATE_TOURNAMENT_PLAYER_MUTATION,
                        variables: {
                            id: player.id,
                            tournament_id: tournament.id,
                            changes: player,
                        },
                        // awaitRefetchQueries: true,
                        // refetchQueries: [
                        // 	{
                        // 		query: GET_TOURNAMENT_BRACKET_QUERY,
                        // 		variables: { tournamentId: tournament.id }
                        // 	}
                        // ]
                    })
                    .then((data) => {
                        // console.log(data);
                        return data;
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            }

            let result = playersToUpdate.reduce((accumulatorPromise, nextPlayer) => {
                return accumulatorPromise.then(() => {
                    return updatePlayer(nextPlayer);
                });
            }, Promise.resolve());

            result.then((e) => {
                console.log('PLAYER UPDATE COMPLETE', playersToUpdate);
            });

            console.log('SEEDED PLAYERS', seededPlayers);
            console.log('PLAYERS TO UPDATE', playersToUpdate);
            console.log(draw[0]);

            console.log({
                content: 'Generating first-round matches...',
                key,
                duration: 0,
            });

            const firstRoundDraw =
                draw &&
                draw[0] &&
                draw[0].map((match, i) => {
                    console.log(match);
                    const player1Race = match.challenger1_race_to ? match.challenger1_race_to : stage ? stage.winners_race_to : tournament.winners_race_to;
                    const player2Race = match.challenger2_race_to ? match.challenger2_race_to : stage ? stage.winners_race_to : tournament.winners_race_to;

                    return {
                        // id: match.id,
                        ...(match.round && { round: match.round }),
                        ...(match.identifier && { identifier: match.identifier }),
                        challenger1_score: match.challenger1_score,
                        challenger1_race_to: player1Race,
                        challenger1_skill_level: match.challenger1_skill_level,
                        challenger1_seed: match.challenger1_seed,
                        challenger1_sms_sent: match.challenger1_sms_sent,
                        challenger2_score: match.challenger2_score,
                        challenger2_race_to: player2Race,
                        challenger2_skill_level: match.challenger2_skill_level,
                        challenger2_seed: match.challenger2_seed,
                        challenger2_sms_sent: match.challenger2_sms_sent,
                        match_number: match.match_number,
                        match_id: match.match_id,
                        team_id: match.team_id,
                        scheduled_time: match.scheduled_time,
                        start_time: match.start_time,
                        end_time: match.end_time,
                        duration: match.duration,
                        table_id: match.table_id,
                        table_number: match.table_number,
                        status: match.status,
                        progress: match.progress,
                        ...(match.winner_from && { winner_from: match.winner_from }),
                        ...(match.winner_from_num && { winner_from_num: match.winner_from_num }),
                        ...(match.winner_from_top_num && { winner_from_top_num: match.winner_from_top_num }),
                        ...(match.winner_from_bottom_num && { winner_from_bottom_num: match.winner_from_bottom_num }),
                        ...(match.winner_to && { winner_to: match.winner_to }),
                        ...(match.winner_to_num && { winner_to_num: match.winner_to_num }),
                        ...(match.loser_from && { loser_from: match.loser_from }),
                        ...(match.loser_from_num && { loser_from_num: match.loser_from_num }),
                        ...(match.loser_from_top_num && { loser_from_top_num: match.loser_from_top_num }),
                        ...(match.loser_from_bottom_num && { loser_from_bottom_num: match.loser_from_bottom_num }),
                        ...(match.loser_to && { loser_to: match.loser_to }),
                        ...(match.loser_to_num && { loser_to_num: match.loser_to_num }),
                        ...(match.advance_to && { advance_to: match.advance_to }),
                        ...(match.players_left && { players_left: match.players_left }),
                        ...seededPlayers[i],
                    };
                });

            console.log('First round draw', firstRoundDraw);

            // let byeMatchesCurrent = [];
            // let byeMatchesWinners = [];
            // let byeMatchesLosers = [];

            const byeMatches =
                firstRoundDraw &&
                firstRoundDraw
                    .filter((match) => {
                        return match.is_bye === true;
                    })
                    .map((match) => {
                        const matchesCopy = matches;

                        const winnerMatches = matchesCopy
                            .filter((item) => {
                                return item.match_number === match.winner_to_num;
                            })
                            .map((item) => {
                                const player1Race =
                                    match.challenger1_name !== 'BYE'
                                        ? match.challenger1_race_to
                                        : tournament.enable_stages === true && stage
                                        ? stage.winners_race_to
                                        : tournament.winners_race_to;
                                const player2Race =
                                    match.challenger2_name !== 'BYE'
                                        ? match.challenger2_race_to
                                        : tournament.enable_stages === true && stage
                                        ? stage.winners_race_to
                                        : tournament.winners_race_to;

                                return {
                                    round: item.round,
                                    identifier: item.identifier,
                                    ...(match.match_number % 2 === 0 && {
                                        challenger2_id: match.challenger1_name !== 'BYE' ? match.challenger1_id : match.challenger2_id,
                                        challenger2_name: match.challenger1_name !== 'BYE' ? match.challenger1_name : match.challenger2_name,
                                        challenger2_seed: match.challenger1_name !== 'BYE' ? match.challenger1_seed : match.challenger2_seed,
                                        challenger2_country: match.challenger1_name !== 'BYE' ? match.challenger1_country : match.challenger2_country,
                                        challenger2_race_to: player1Race,
                                        challenger2_skill_level:
                                            match.challenger1_name !== 'BYE' ? match.challenger1_skill_level : match.challenger2_skill_level,
                                    }),
                                    ...(match.match_number % 2 !== 0 && {
                                        challenger1_id: match.challenger1_name !== 'BYE' ? match.challenger1_id : match.challenger2_id,
                                        challenger1_name: match.challenger1_name !== 'BYE' ? match.challenger1_name : match.challenger2_name,
                                        challenger1_seed: match.challenger1_name !== 'BYE' ? match.challenger1_seed : match.challenger2_seed,
                                        challenger1_country: match.challenger1_name !== 'BYE' ? match.challenger1_country : match.challenger2_country,
                                        challenger1_race_to: player1Race,
                                        challenger1_skill_level:
                                            match.challenger1_name !== 'BYE' ? match.challenger1_skill_level : match.challenger2_skill_level,
                                    }),
                                    // ...(match.match_number % 2 === 0 && {
                                    //     challenger2_id: match.challenger1_id,
                                    //     challenger2_name: match.challenger1_name,
                                    //     challenger2_seed: match.challenger1_seed,
                                    //     challenger2_country: match.challenger1_country,
                                    //     challenger2_score: 0,
                                    //     challenger2_race_to: match.challenger1_race_to || tournament.winners_race_to,
                                    //     challenger2_skill_level: match.challenger1_skill_level,
                                    // }),
                                    // ...(match.match_number % 2 !== 0 && {
                                    //     challenger1_id: match.challenger1_id,
                                    //     challenger1_name: match.challenger1_name,
                                    //     challenger1_seed: match.challenger1_seed,
                                    //     challenger1_country: match.challenger1_country,
                                    //     challenger1_score: 0,
                                    //     challenger1_race_to: match.challenger1_race_to || tournament.winners_race_to,
                                    //     challenger1_skill_level: match.challenger1_skill_level,
                                    // }),
                                };
                            });
                        console.log('winner matches', winnerMatches);

                        const loserMatches = matchesCopy
                            .filter((item) => {
                                return item.match_number === match.loser_to_num;
                            })
                            .map((item) => {
                                return {
                                    ...item,
                                    round: item.round,
                                    identifier: item.identifier,
                                    challenger1_score: 0,
                                    challenger2_score: 0,
                                    status: 'COMPLETED',
                                    progress: 100,
                                    is_bye: true,
                                    ...(match.challenger2_name === 'BYE' &&
                                        match.match_number % 2 === 0 && {
                                            challenger2_name: 'BYE',
                                            challenger2_seed: null,
                                        }),
                                    ...(match.challenger2_name === 'BYE' &&
                                        match.match_number % 2 !== 0 && {
                                            challenger1_name: 'BYE',
                                            challenger1_seed: null,
                                        }),
                                };
                            });

                        const current = {
                            ...match,
                            challenger1_score: 0,
                            challenger2_score: 0,
                            status: 'COMPLETED',
                            progress: 100,
                            is_bye: true,
                        };

                        // byeMatchesCurrent.concat(current);
                        // byeMatchesWinners.concat(winnerMatches[0]);
                        // byeMatchesLosers.concat(loserMatches[0]);

                        if (tournamentType === 'double_elimination' || tournamentType === 'modified_double_elimination') {
                            return [current, winnerMatches[0], loserMatches[0]];
                        } else {
                            return [current, winnerMatches[0]];
                        }
                    });

            // console.log({
            // 	byeMatchesCurrent,
            // 	byeMatchesWinners,
            // 	byeMatchesLosers
            // });

            const flattened = _.flatten(byeMatches);

            let byeMatches2 = flattened.filter((item) => {
                return item.challenger1_name === 'BYE' || item.challenger2_name === 'BYE';
            });
            // .reduce((a, e) => {
            // 	a[e.identifier] = ++a[e.identifier] || 0;
            // 	return a;
            // }, {});

            let byeMatches3 = _.filter(
                _.uniq(
                    _.map(byeMatches2, function (item) {
                        if (_.filter(byeMatches2, { identifier: item.identifier }).length > 1) {
                            return item.identifier;
                        }

                        return false;
                    })
                ),
                function (value) {
                    return value;
                }
            );

            function getByVsByeMatches(arr1, arr2) {
                let byeVsByeMatches = [];
                let byeVsByeMatches2 = [];

                _.forEach(arr1, function (obj, index) {
                    if (
                        _.find(arr2, (o) => {
                            return o === obj.identifier;
                        })
                    ) {
                        byeVsByeMatches[obj.identifier] = {
                            ...byeVsByeMatches[obj.identifier],
                            ...obj,
                        };
                    }
                });

                for (const [key, value] of Object.entries(byeVsByeMatches)) {
                    byeVsByeMatches2.push(value);
                }

                return byeVsByeMatches2;
            }

            const byeVsByeMatches = getByVsByeMatches(flattened, byeMatches3);
            const byeMatchWinners = byeVsByeMatches.map((item) => {
                return item.winner_to_num;
            });
            const matchesCopy = matches;
            const byeMatchesFinal = matchesCopy
                .filter((item) => {
                    return byeMatchWinners.includes(item.match_number);
                })
                .map((item) => {
                    return {
                        ...item,
                        round: item.round,
                        identifier: item.identifier,
                        challenger1_score: 0,
                        challenger2_score: 0,
                        status: 'COMPLETED',
                        progress: 100,
                        is_bye: true,
                        challenger1_name: 'BYE',
                        challenger1_seed: null,
                    };
                });

            console.log(byeMatches2);
            console.log(byeVsByeMatches);

            console.log('Bye vs. Bye matches', byeMatchesFinal);
            console.log('Bye Matches not flat: ', byeMatches);
            console.log('Bye matches:', flattened);

            const matchesToUpdate = firstRoundDraw && firstRoundDraw.concat(flattened, byeMatchesFinal);
            console.log('To update:', matchesToUpdate);

            updatedDraw = matchesToUpdate;
        }

        return updatedDraw;
    }

    function generateDrawCustom(draw, winnerBracket, matches, players, random = true) {
        // console.log(draw);
        const totalPlayers = (players && players.length) || (stage && stage.max_players) || (tournament && tournament.max_players);
        const bracketSize = getBracketSize(totalPlayers);
        // console.log(winnerBracket);
        // console.log(tournament.max_players);

        var totalRounds = Math.ceil(Math.log(totalPlayers) / Math.log(2));
        let updatedDraw = [];

        if (players.length > 0) {
            var seeds = generateSeeds(totalPlayers, draw, bracketSize);
            console.log('seeds', seeds);
            let playersToUpdate = [];

            const key = 'updatable';
            // message.loading({ content: 'Seeding bracket...', key, duration: 0 });

            if (random === true) {
                // players = shuffle(_.uniq(players, 'id'));
                const contestantsCopy = players.slice(0);
                // Shuffle contestantsCopy with Fisher-Yates Shuffle
                for (let i = players.length - 1; i > 0; --i) {
                    let j = Math.floor(Math.random() * (i + 1));
                    swap(contestantsCopy, i, j);
                }
                function swap(array, i, j) {
                    let temp = array[i];
                    array[i] = array[j];
                    array[j] = temp;
                }
                players = contestantsCopy;
                // players = contestantsCopy.map((player, index) => {
                //     return {
                //         ...player,
                //         seed: index + 1,
                //     };
                // });
                console.log('new players order', players);
            } else {
                players = _.uniq(players, 'id').sort((a, b) => {
                    if (a.seed && b.seed) {
                        return a.seed - b.seed;
                    } else {
                        return a.id - b.id;
                    }
                });
            }

            // players = players.slice(0, 28);

            var playerIndex = 1;
            console.log(players);

            const seededPlayers = seeds.map((seed, seedIndex) => {
                const matchNumber = draw[0][seedIndex].match_number;
                const roundNumber = draw[0][seedIndex].round;
                const byeChart = getByeChart(bracketSize);
                const numberOfByes = bracketSize - players.length;
                console.log({
                    byeChart,
                    maxPlayers: maxPlayers,
                    totalPlayers: players.length,
                    numberOfByes,
                    seedIndex,
                    matchNumber,
                    roundNumber,
                });

                const topSeed = seed[0];
                const bottomSeed = seed[1];
                const player = players[topSeed - 1];
                const opponent = players[bottomSeed - 1];

                if (player) {
                    playersToUpdate.push({
                        id: player.id,
                        name: player.name,
                        country: player.country,
                        email: player.email,
                        phone_number: player.phone_number,
                        skill_level: player.skill_level,
                        status: player.status,
                        points: player.points,
                        seed: topSeed || player.seed,
                        race_to: player.race_to,
                    });
                }

                if (opponent) {
                    playersToUpdate.push({
                        id: opponent.id,
                        name: opponent.name,
                        country: opponent.country,
                        email: opponent.email,
                        phone_number: opponent.phone_number,
                        skill_level: opponent.skill_level,
                        status: opponent.status,
                        points: opponent.points,
                        seed: bottomSeed || opponent.seed,
                        race_to: opponent.race_to,
                    });
                }

                return {
                    challenger1_id: player ? player.id : null,
                    challenger1_name: player ? player.name : roundNumber === 1 ? 'BYE' : null,
                    challenger1_country: player ? player.country : null,
                    challenger1_race_to: player ? player.race_to : null,
                    challenger1_skill_level: player ? player.skill_level : null,
                    challenger1_sms_sent: player ? player.sms_sent : null,
                    challenger1_score: 0,
                    challenger1_seed: player ? topSeed || player.seed : null,
                    challenger2_id: opponent ? opponent.id : null,
                    challenger2_name: opponent ? opponent.name : roundNumber === 1 ? 'BYE' : null,
                    challenger2_country: opponent ? opponent.country : null,
                    challenger2_race_to: opponent ? opponent.race_to : null,
                    challenger2_skill_level: opponent ? opponent.skill_level : null,
                    challenger2_sms_sent: opponent ? opponent.sms_sent : null,
                    challenger2_score: 0,
                    challenger2_seed: opponent ? bottomSeed || opponent.seed : null,
                    is_bye: player && opponent ? false : byeChart.indexOf(matchNumber) !== -1 ? true : false,
                    status: player && opponent ? 'NOT_STARTED' : byeChart.indexOf(matchNumber) !== -1 ? 'COMPLETED' : 'NOT_STARTED',
                    progress: player && opponent ? 0 : byeChart.indexOf(matchNumber) !== -1 ? 100 : 0,
                };
            });

            console.log(seededPlayers);

            function updatePlayer(player) {
                return props.client
                    .mutate({
                        mutation: UPDATE_TOURNAMENT_PLAYER_MUTATION,
                        variables: {
                            id: player.id,
                            tournament_id: tournament.id,
                            changes: player,
                        },
                        // awaitRefetchQueries: true,
                        // refetchQueries: [
                        // 	{
                        // 		query: GET_TOURNAMENT_BRACKET_QUERY,
                        // 		variables: { tournamentId: tournament.id }
                        // 	}
                        // ]
                    })
                    .then((data) => {
                        // console.log(data);
                        return data;
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            }

            let result = playersToUpdate.reduce((accumulatorPromise, nextPlayer) => {
                return accumulatorPromise.then(() => {
                    return updatePlayer(nextPlayer);
                });
            }, Promise.resolve());

            result.then((e) => {
                console.log('PLAYER UPDATE COMPLETE', playersToUpdate);
            });

            console.log('SEEDED PLAYERS', seededPlayers);
            console.log('PLAYERS TO UPDATE', playersToUpdate);
            console.log(draw[0]);

            console.log({
                content: 'Generating first-round matches...',
                key,
                duration: 0,
            });

            const firstRoundDraw =
                draw &&
                draw[0] &&
                draw[0].map((match, i) => {
                    const player1Race = match.challenger1_race_to ? match.challenger1_race_to : stage ? stage.winners_race_to : tournament.winners_race_to;
                    const player2Race = match.challenger2_race_to ? match.challenger2_race_to : stage ? stage.winners_race_to : tournament.winners_race_to;

                    return {
                        // id: match.id,
                        ...(match.round && { round: match.round }),
                        ...(match.identifier && { identifier: match.identifier }),
                        challenger1_score: match.challenger1_score,
                        challenger1_race_to: player1Race,
                        challenger1_skill_level: match.challenger1_skill_level,
                        challenger1_seed: match.challenger1_seed,
                        challenger1_sms_sent: match.challenger1_sms_sent,
                        challenger2_score: match.challenger2_score,
                        challenger2_race_to: player2Race,
                        challenger2_skill_level: match.challenger2_skill_level,
                        challenger2_seed: match.challenger2_seed,
                        challenger2_sms_sent: match.challenger2_sms_sent,
                        match_number: match.match_number,
                        place: match.place,
                        match_id: match.match_id,
                        team_id: match.team_id,
                        scheduled_time: match.scheduled_time,
                        start_time: match.start_time,
                        end_time: match.end_time,
                        duration: match.duration,
                        table_id: match.table_id,
                        table_number: match.table_number,
                        status: match.status,
                        progress: match.progress,
                        ...(match.winner_from && { winner_from: match.winner_from }),
                        ...(match.winner_from_num && { winner_from_num: match.winner_from_num }),
                        ...(match.winner_from_top_num && { winner_from_top_num: match.winner_from_top_num }),
                        ...(match.winner_from_bottom_num && { winner_from_bottom_num: match.winner_from_bottom_num }),
                        ...(match.winner_to && { winner_to: match.winner_to }),
                        ...(match.winner_to_num && { winner_to_num: match.winner_to_num }),
                        ...(match.loser_from && { loser_from: match.loser_from }),
                        ...(match.loser_from_num && { loser_from_num: match.loser_from_num }),
                        ...(match.loser_from_top_num && { loser_from_top_num: match.loser_from_top_num }),
                        ...(match.loser_from_bottom_num && { loser_from_bottom_num: match.loser_from_bottom_num }),
                        ...(match.loser_to && { loser_to: match.loser_to }),
                        ...(match.loser_to_num && { loser_to_num: match.loser_to_num }),
                        ...(match.advance_to && { advance_to: match.advance_to }),
                        ...(match.players_left && { players_left: match.players_left }),
                        ...seededPlayers[i],
                    };
                });

            console.log('After draw', firstRoundDraw);

            // let byeMatchesCurrent = [];
            // let byeMatchesWinners = [];
            // let byeMatchesLosers = [];

            const byeMatches =
                firstRoundDraw &&
                firstRoundDraw
                    .filter((match) => {
                        return match.is_bye === true;
                    })
                    .map((match) => {
                        const matchesCopy = matches;
                        // console.log(matchesCopy);
                        const winnerMatches = matchesCopy
                            .filter((item) => {
                                return item.match_number === match.winner_to_num;
                            })
                            .map((item) => {
                                const player1Race =
                                    match.challenger1_name !== 'BYE' ? match.challenger1_race_to : stage ? stage.winners_race_to : tournament.winners_race_to;
                                const player2Race =
                                    match.challenger2_name !== 'BYE' ? match.challenger2_race_to : stage ? stage.winners_race_to : tournament.winners_race_to;

                                return {
                                    round: item.round,
                                    identifier: item.identifier,
                                    challenger2_id: match.challenger1_id,
                                    challenger2_name: match.challenger1_name,
                                    challenger2_seed: match.challenger1_seed,
                                    challenger2_country: match.challenger1_country,
                                    challenger2_score: 0,
                                    challenger2_race_to: player1Race,
                                    challenger2_skill_level: match.challenger1_skill_level,
                                };
                            });

                        const loserMatches = matchesCopy
                            .filter((item) => {
                                return item.match_number === match.loser_to_num;
                            })
                            .map((item) => {
                                return {
                                    ...item,
                                    round: item.round,
                                    identifier: item.identifier,
                                    challenger1_score: 0,
                                    challenger2_score: 0,
                                    status: 'COMPLETED',
                                    progress: 100,
                                    is_bye: true,
                                    challenger2_name: 'BYE',
                                    challenger2_seed: null,
                                };
                            });

                        const current = {
                            ...match,
                            challenger1_score: 0,
                            challenger2_score: 0,
                            status: 'COMPLETED',
                            progress: 100,
                            is_bye: true,
                        };

                        // byeMatchesCurrent.concat(current);
                        // byeMatchesWinners.concat(winnerMatches[0]);
                        // byeMatchesLosers.concat(loserMatches[0]);

                        if (tournamentType === 'double_elimination' || tournamentType === 'modified_double_elimination' || tournamentType === 'custom') {
                            return [current, winnerMatches[0], loserMatches[0]];
                        } else {
                            return [current, winnerMatches[0]];
                        }
                    });

            // console.log({
            // 	byeMatchesCurrent,
            // 	byeMatchesWinners,
            // 	byeMatchesLosers
            // });

            const flattened = _.flatten(byeMatches);

            let byeMatches2 = flattened.filter((item) => {
                if (item) {
                    return item.challenger1_name === 'BYE' || item.challenger2_name === 'BYE';
                }
            });
            // .reduce((a, e) => {
            // 	a[e.identifier] = ++a[e.identifier] || 0;
            // 	return a;
            // }, {});

            let byeMatches3 = _.filter(
                _.uniq(
                    _.map(byeMatches2, function (item) {
                        if (_.filter(byeMatches2, { identifier: item.identifier }).length > 1) {
                            return item.identifier;
                        }

                        return false;
                    })
                ),
                function (value) {
                    return value;
                }
            );

            function getByVsByeMatches(arr1, arr2) {
                let byeVsByeMatches = [];
                let byeVsByeMatches2 = [];

                _.forEach(arr1, function (obj, index) {
                    if (
                        _.find(arr2, (o) => {
                            return o === obj.identifier;
                        })
                    ) {
                        byeVsByeMatches[obj.identifier] = {
                            ...byeVsByeMatches[obj.identifier],
                            ...obj,
                        };
                    }
                });

                for (const [key, value] of Object.entries(byeVsByeMatches)) {
                    byeVsByeMatches2.push(value);
                }

                return byeVsByeMatches2;
            }

            const byeVsByeMatches = getByVsByeMatches(flattened, byeMatches3);
            const byeMatchWinners = byeVsByeMatches.map((item) => {
                return item.winner_to_num;
            });
            const matchesCopy = matches;
            const byeMatchesFinal = matchesCopy
                .filter((item) => {
                    return byeMatchWinners.includes(item.match_number);
                })
                .map((item) => {
                    return {
                        ...item,
                        round: item.round,
                        identifier: item.identifier,
                        challenger1_score: 0,
                        challenger2_score: 0,
                        status: 'COMPLETED',
                        progress: 100,
                        is_bye: true,
                        challenger2_name: 'BYE',
                        challenger2_seed: null,
                    };
                });

            console.log(byeMatches2);
            console.log(byeVsByeMatches);

            console.log('Bye vs. Bye matches', byeMatchesFinal);
            console.log('Bye Matches not flat: ', byeMatches);
            console.log('Bye matches:', flattened);

            const matchesToUpdate = firstRoundDraw && firstRoundDraw.concat(flattened, byeMatchesFinal);

            console.log('To update:', matchesToUpdate);

            updatedDraw = matchesToUpdate;
        }

        return updatedDraw;
    }

    function shuffle(array) {
        return array.sort(() => Math.random() - 0.5);
    }

    function generateSeeds(participants, draw, bracketSize) {
        if (participants < 2) {
            return [];
        }

        let matches = [[1, 2]];
        var roundMatches = [];
        var seed1 = 0;
        var seed2 = 0;

        // const seedings128 = [
        //     1, 128, 64, 65, 32, 97, 33, 96, 16, 113, 49, 80, 17, 112, 48, 81, 41, 88, 24, 105, 56, 73, 9, 120, 40, 89, 25, 104, 57, 72, 8, 121, 5, 124, 60, 69,
        //     28, 101, 37, 92, 12, 117, 53, 76, 21, 108, 44, 85, 45, 84, 20, 109, 52, 77, 13, 116, 36, 93, 29, 100, 61, 68, 4, 125, 3, 126, 62, 67, 30, 99, 35,
        //     94, 14, 115, 51, 78, 19, 110, 46, 83, 43, 86, 22, 107, 54, 75, 11, 118, 38, 91, 27, 102, 59, 70, 6, 123, 7, 122, 58, 71, 26, 103, 39, 90, 10, 119,
        //     55, 74, 23, 106, 42, 87, 47, 82, 18, 111, 50, 79, 15, 114, 34, 95, 31, 98, 63, 66, 2, 127,
        // ];

        console.log(drawType);

        if (drawType === 'seeded') {
            const seeds = _.compact(
                draw &&
                    draw[0].map((match) => {
                        if (match.top_seed && match.bottom_seed) {
                            return [match.top_seed, match.bottom_seed];
                        }
                    })
            );

            const seedChart = seedingChart(bracketSize);
            console.log('seed chart', seedChart);

            if (seeds && seeds.length > 0) {
                matches = seeds;
            } else {
                for (var playerSeedIndex = 0; playerSeedIndex <= seedChart.length - 1; playerSeedIndex++) {
                    seed1 = seedChart[playerSeedIndex];
                    playerSeedIndex++;
                    seed2 = seedChart[playerSeedIndex];
                    roundMatches.push([seed1, seed2]);
                }

                matches = roundMatches;
            }
        } else {
            for (var seedIndex = 1; seedIndex <= bracketSize; seedIndex++) {
                seed1 = seedIndex;
                seedIndex++;
                seed2 = seedIndex;
                roundMatches.push([seed1, seed2]);
            }
            matches = roundMatches;
        }

        console.log(roundMatches);
        console.log(matches);

        // for (var playerSeedIndex = 1; playerSeedIndex <= numberOfPlayersMax; playerSeedIndex++) {
        //     seed1 = playerSeedIndex;
        //     playerSeedIndex++;
        //     seed2 = playerSeedIndex;
        //     roundMatches.push([seed1, seed2]);
        // }
        // matches = roundMatches;

        // let newRounds = [];

        // for (let i = rounds; i >= 1; i--) {
        // 	newRounds.push(i);
        // }

        // for (var round = 1; round < rounds; round++) {
        // 	var roundMatches = [];
        // 	var sum = Math.pow(2, round + 1) + 1;

        // 	for (var i = 0; i < matches.length; i++) {
        // 		var home = changeIntoBye(matches[i][0], participants);
        // 		var away = changeIntoBye(sum - matches[i][0], participants);
        // 		roundMatches.push([ home, away ]);
        // 		home = changeIntoBye(sum - matches[i][1], participants);
        // 		away = changeIntoBye(matches[i][1], participants);
        // 		roundMatches.push([ home, away ]);
        // 	}
        // 	matches = roundMatches;
        // }
        return matches;
    }

    async function getFargoCharts(type, size, player1Fargo, player2Fargo, match) {
        const typesEnum = {
            hot: 2,
            medium: 1,
            mild: 0,
        };
        const url = `https://lms.fargorate.com/api/ratingcalc/racesbylengthandtype?length=${size}&type=${typesEnum[type]}&ratingOne=${player1Fargo}&ratingTwo=${player2Fargo}`;

        return await axios
            .get(url)
            .then((response) => {
                const results = response.data;
                console.log(results);
                const closestChart = results.filter((item) => item.closest === true)[0];
                const player1RaceTo =
                    player1Fargo > player2Fargo
                        ? closestChart.highPlayerRaceTo
                        : player1Fargo < player2Fargo
                        ? closestChart.lowPlayerRaceTo
                        : closestChart.highPlayerRaceTo;
                const player2RaceTo =
                    player2Fargo > player1Fargo
                        ? closestChart.highPlayerRaceTo
                        : player2Fargo < player1Fargo
                        ? closestChart.lowPlayerRaceTo
                        : closestChart.highPlayerRaceTo;

                const updatedMatch = {
                    ...match,
                    challenger1_race_to: player1RaceTo,
                    challenger2_race_to: player2RaceTo,
                };

                return updatedMatch;
            })
            .catch((error) => {
                console.log(error);
                return error;
            });
    }

    function getFargoRaces() {
        let promises = [];

        updatedMatches.map(async (match) => {
            const player1Fargo = match.challenger1_skill_level;
            const player2Fargo = match.challenger2_skill_level;

            if (player1Fargo && player2Fargo) {
                if (stage.handicap_format === 'fargo_hot_column') {
                    promises.push(getFargoCharts('hot', stage.winners_race_to, player1Fargo, player2Fargo, match));
                } else if (stage.handicap_format === 'fargo_medium_column') {
                    promises.push(getFargoCharts('medium', stage.winners_race_to, player1Fargo, player2Fargo, match));
                } else if (stage.handicap_format === 'fargo_mild_column') {
                    promises.push(getFargoCharts('mild', stage.winners_race_to, player1Fargo, player2Fargo, match));
                }
            }
        });

        message.loading('Loading Fargo Player Races...');
        Promise.all(promises)
            .then((matches) => {
                const updated = updatedMatches.map((match, index) => {
                    const updatedMatch = matches.filter((match2) => {
                        return match2.identifier === match.identifier;
                    })[0];

                    if (updatedMatch) {
                        return updatedMatch;
                    } else {
                        return match;
                    }
                });

                setUpdatedMatches(updated);
                message.success('Fargo Player Races added!');
            })
            .catch((e) => {
                console.log(e);
                message.error(JSON.stringify(e));
                message.error('There was an error', e);
            });
    }

    function getRandomArbitrary(min, max) {
        return Math.random() * (max - min) + min;
    }

    const removeEmpty = (obj) => {
        Object.keys(obj).forEach((key) => {
            if (obj[key] && typeof obj[key] === 'object') removeEmpty(obj[key]);
            else if (obj[key] === undefined) delete obj[key];
        });
        return obj;
    };

    return (
        <Query
            query={GET_TOURNAMENT_PLAYERS_QUERY}
            fetchPolicy="cache-and-network"
            notifyOnNetworkStatusChange={true}
            variables={{
                tournament_id: tournament.id,
            }}
            onCompleted={async (data) => {
                console.log('player data', data);
                generateBracket(data, stage);
            }}
        >
            {({ loading, error, data }) => {
                if (loading) {
                    return (
                        <div style={styles.container}>
                            <CircularLoader />
                        </div>
                    );
                }
                if (error) {
                    return <div style={styles.container}>Error: {error.message}</div>;
                }

                if (!data || !data.tournament_players) {
                    return (
                        <div style={styles.container}>
                            <CircularLoader />
                        </div>
                    );
                }

                console.log('bracket created preview', bracketCreated);

                return (
                    <div>
                        {bracketSizeWarning && (
                            <div
                                style={{
                                    marginTop: 20,
                                }}
                            >
                                <Alert
                                    message={bracketSizeWarning}
                                    description="We recommend this bracket size to ensure the tournament has the least amount of BYE rounds to run more efficiently."
                                    type="warning"
                                    closable
                                    showIcon
                                />
                            </div>
                        )}

                        <div
                            style={{
                                marginTop: 20,
                                marginBottom: 20,
                            }}
                        >
                            {updatedMatches && (
                                <React.Fragment>
                                    <div
                                        style={{
                                            marginBottom: 20,
                                        }}
                                    >
                                        {bracketCreated === true && (
                                            <div
                                                style={{
                                                    marginBottom: 20,
                                                }}
                                            >
                                                <Alert
                                                    message={`${stage ? stage.name : 'Tournament'} bracket already exists!`}
                                                    description={
                                                        <div>
                                                            <Text>
                                                                A bracket already exists for this {stage ? 'stage' : 'tournament'}. Reset the bracket below to
                                                                generate a new one or skip this step without making changes.
                                                            </Text>
                                                            <br />
                                                            <Button
                                                                type="danger"
                                                                ghost
                                                                style={{
                                                                    marginTop: 10,
                                                                }}
                                                                onClick={() => {
                                                                    confirm({
                                                                        title: `Are you sure you want to reset the ${
                                                                            stage ? stage.name : 'tournament'
                                                                        } bracket?`,
                                                                        content:
                                                                            'This action will destroy all existing matches and generate a new bracket from the bracket preview.',
                                                                        centered: true,
                                                                        transitionName: 'fade',
                                                                        maskTransitionName: 'none',
                                                                        okText: 'Reset bracket',
                                                                        cancelText: `Cancel`,
                                                                        onOk() {
                                                                            onResetBracket(stage);
                                                                        },
                                                                        onCancel() {
                                                                            console.log('NO RESET');
                                                                        },
                                                                    });
                                                                }}
                                                            >
                                                                Reset bracket
                                                            </Button>
                                                        </div>
                                                    }
                                                    type="error"
                                                    closable={false}
                                                    showIcon
                                                />
                                            </div>
                                        )}

                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                            }}
                                        >
                                            <div>
                                                {stage && (
                                                    <Title
                                                        level={4}
                                                        style={{
                                                            marginBottom: 5,
                                                        }}
                                                    >
                                                        Preview {stage.name}
                                                    </Title>
                                                )}

                                                {tournament && tournament.tournament_stage_format === 'multi' ? (
                                                    <Text>{`${(players && players.length) || maxPlayers} players - ${displayEnumType(tournamentType)} - ${
                                                        tournament.tournament_stage_format && displayEnumType(tournament.tournament_stage_format)
                                                    } Stage - ${displayEnumType(drawType)} draw - ${
                                                        (stage && stage.players_advance_per_group) || 0
                                                    } advancing`}</Text>
                                                ) : (
                                                    <Text>{`${(players && players.length) || maxPlayers} players - ${displayEnumType(
                                                        tournamentType
                                                    )} - ${displayEnumType(drawType)} draw`}</Text>
                                                )}
                                            </div>
                                            <div>
                                                <Button
                                                    type="default"
                                                    onClick={() => {
                                                        generateBracket(data, stage);
                                                        message.success('Player shuffle complete');
                                                    }}
                                                >
                                                    <ReloadOutlined /> Shuffle Players
                                                </Button>

                                                {tournamentFormat === 'fargo_rated' && (
                                                    <Button
                                                        type="primary"
                                                        onClick={async () => {
                                                            getFargoRaces();
                                                        }}
                                                    >
                                                        Get Fargo Races
                                                    </Button>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    {tournamentType === 'double_elimination' ||
                                    tournamentType === 'modified_double_elimination' ||
                                    tournamentType === 'custom' ? (
                                        <BracketStaticDoubleElim
                                            compact={false}
                                            setRoundTimes={false}
                                            tournament_brackets={updatedMatches}
                                            tournament={tournament}
                                            stage={stage}
                                            isFullScreen={false}
                                            theme="dark"
                                            // compact={true}
                                            {...props}
                                        />
                                    ) : (
                                        <BracketStaticSingleElim
                                            compact={false}
                                            setRoundTimes={false}
                                            tournament_brackets={updatedMatches}
                                            tournament={tournament}
                                            stage={stage}
                                            isFullScreen={false}
                                            theme="dark"
                                            // compact={true}
                                            {...props}
                                        />
                                    )}
                                </React.Fragment>
                            )}
                        </div>
                    </div>
                );
            }}
        </Query>
    );
}
