import React from 'react';
import { Button } from 'antd';
import { SubmitButton } from 'formik-antd';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { inviteModalButtonDisabled, inviteModalButtonLoading } from '../../data/atoms';

export default function InviteButton(props) {
    const { onClick } = props;
    const disabled = useRecoilValue(inviteModalButtonDisabled);
    const loading = useRecoilValue(inviteModalButtonLoading);

    return (
        <Button
            key="submit"
            disabled={disabled}
            type="primary"
            onClick={onClick}
            loading={loading}
        >
            Invite
        </Button>
    );
}