import React, { useState } from 'react';
import { Link, withRouter, Route } from 'react-router-dom';
import { Query } from '@apollo/client/react/components';
import { Row, Col, Button, Avatar, Empty, Typography } from 'antd';
import Card, { Meta } from '../../../components/Card';
import CircularLoader from '../../../components/CircularLoader';
import SectionContent from '../../../components/SectionContent';
import ZeroState from '../../../components/ZeroState';
import Masonry from '../../../components/Masonry';
import SearchFilterBar from '../leagues/SearchFilterBar';
import { GET_USER_CREATED_EVENTS_QUERY } from '../data/queries';
import defaultImg from '../../../assets/img/blue-bg.png';
import poolTableImg from '../../../assets/img/rasson-table.png';
import moment from 'moment';
import useBreakpoint from 'use-breakpoint';
import { defaultBreakpoints } from '../../../utils/defaultBreakpoints';
import { CalendarOutlined, EnvironmentOutlined } from '@ant-design/icons';
import AdZoneTemplate from '../../../components/advertising/AdZoneTemplate';

const { Text, Title } = Typography;
const masonryBreakPoints = [600, 960, 1280, 1920];

const styles = {
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
    },
};

function createMarkup(markup) {
    return { __html: markup };
}

export default function MyEvents(props) {
    const { authState, onCreateEvent, theme } = props;
    const { breakpoint, maxWidth, minWidth } = useBreakpoint(defaultBreakpoints);

    return (
        <React.Fragment>
            {/* <div
				style={{
					background: 'linear-gradient( #fafafa, #fff)',
					boxShadow: 'rgba(0, 0, 0, 0.05) 0px 4px 6px 0px',
					padding: '10px 30px'
				}}
			>
				<SearchFilterBar {...props} />
			</div> */}

            <SectionContent>
                <Query
                    query={GET_USER_CREATED_EVENTS_QUERY}
                    fetchPolicy="cache-and-network"
                    variables={{ ownerId: authState.user.id }}
                    notifyOnNetworkStatusChange
                    awaitRefetchQueries
                    onCompleted={(data, loading, error, subscribeToMore) => {
                        if (!loading && !error) {
                            console.log(data);
                        }
                    }}
                >
                    {({ loading, error, data, subscribeToMore, networkStatus }) => {
                        if (networkStatus === 1 || loading) {
                            return (
                                <Card>
                                    <div style={styles.container}>
                                        <CircularLoader />
                                    </div>
                                </Card>
                            );
                        }
                        if (loading)
                            return (
                                <Card>
                                    <div style={styles.container}>
                                        <CircularLoader />
                                    </div>
                                </Card>
                            );
                        if (error) return <div style={styles.container}>Error: {error.message}</div>;
                        if ((data && !data.events) || (data && data.events.length === 0)) {
                            return (
                                <Card>
                                    <ZeroState message="You have not created any events yet." {...props} />
                                </Card>
                            );
                        }

                        return (
                            <Row gutter={16} justify="center" type="flex">
                                <Col xs={24}>
                                    <AdZoneTemplate device={breakpoint || 'desktop'} {...props} />
                                </Col>
                                <Col xs={24}>
                                    <div className="masonry-container">
                                        <Masonry breakPoints={masonryBreakPoints}>
                                            {data.events.map((event, index) => {
                                                console.log(event);
                                                return (
                                                    <React.Fragment key={index}>
                                                        <Card
                                                            hoverable
                                                            loading={loading}
                                                            cover={
                                                                <React.Fragment>
                                                                    {event.flyer ? (
                                                                        <div
                                                                            style={{
                                                                                display: 'flex',
                                                                                alignItems: 'center',
                                                                            }}
                                                                        >
                                                                            <img
                                                                                alt="Flyer"
                                                                                src={event.flyer}
                                                                                style={{
                                                                                    width: '100%',
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    ) : (
                                                                        <div
                                                                            style={{
                                                                                height: 140,
                                                                                overflow: 'hidden',
                                                                                background: `linear-gradient(rgba(61, 185, 248, 0.8), rgba(24, 144, 255, 0.1)), url(${defaultImg})`,
                                                                                backgroundRepeat: 'no-repeat',
                                                                                backgroundSize: 'cover',
                                                                            }}
                                                                        />
                                                                    )}
                                                                </React.Fragment>
                                                            }
                                                            onClick={() => {
                                                                props.history.push(`/events/${event.slug}`);
                                                            }}
                                                        >
                                                            <Meta
                                                                // avatar={<Avatar src={event.avatar} />}
                                                                title={<Title level={4}>{event.name}</Title>}
                                                                description={
                                                                    <React.Fragment>
                                                                        <CalendarOutlined /> <Text>{moment(event.start_date_time).format('MMMM Do YYYY')}</Text>
                                                                        {event.end_date_time && (
                                                                            <Text> - {moment(event.end_date_time).format('MMMM Do YYYY')}</Text>
                                                                        )}
                                                                        <br />
                                                                        <br />
                                                                        <div
                                                                            style={{
                                                                                color: theme.name === 'dark' ? '#fff' : 'rgba(0, 0, 0, 0.85)',
                                                                            }}
                                                                            dangerouslySetInnerHTML={createMarkup(event.description)}
                                                                        />
                                                                    </React.Fragment>
                                                                }
                                                            />
                                                        </Card>
                                                    </React.Fragment>
                                                );
                                            })}
                                        </Masonry>
                                    </div>
                                </Col>
                            </Row>
                        );
                    }}
                </Query>
            </SectionContent>
        </React.Fragment>
    );
}
