import gql from 'graphql-tag';

export const GET_MATCH_QUERY = gql`
    query matches($id: Int!) {
        matches(where: { id: { _eq: $id } }) {
            id
            name
            slug
            description
            match_number
            match_type
            game_type
            rule_format
            handicap_format
            race_to
            player_id
            player_name
            player_score
            player_points
            player_tiebreaker_points
            player_race_to
            player_country
            player_state
            player_fargo
            player_is_winner
            opponent_id
            opponent_name
            opponent_score
            opponent_points
            opponent_tiebreaker_points
            opponent_race_to
            opponent_country
            opponent_state
            opponent_fargo
            opponent_is_winner
            winner_id
            winner_score
            loser_id
            loser_score
            status
            progress
            duration
            livestream_url
            score_verified
            scheduled_time
            start_date_time
            end_date_time
            is_public
            event_id
            event {
                id
                name
                slug
                description
            }
            venue_id
            venue {
                id
                name
                slug
                address1
                address2
                city
                region
                country
                postal_code
            }
            pool_table_id
            pool_table {
                id
                slug
                label
                kiosk_ip_address
                kiosk_password
            }
            match_players {
                id
                match_id
                user_id
                name
                country
                skill_level
                race_to
                score
                is_winner
            }
            match_stats(order_by: { time: desc }) {
                id
                metric
                value
                user_id
                match_id
                match_game_id
                player_id
                opponent_id
                game_number
                time
            }
            managers {
                id
                user_id
                match_id
                admin
                creator
            }
            table_size
            scoreboard_balls
            creator_id
            created_at
            updated_at
        }
    }
`;

export const GET_MATCH_BY_SLUG_QUERY = gql`
    query matches($slug: String) {
        matches(where: { slug: { _eq: $slug } }) {
            id
            name
            slug
            description
            match_number
            match_type
            game_type
            rule_format
            handicap_format
            race_to
            player_id
            player_name
            player_score
            player_points
            player_tiebreaker_points
            player_race_to
            player_country
            player_state
            player_fargo
            player_is_winner
            opponent_id
            opponent_name
            opponent_score
            opponent_points
            opponent_tiebreaker_points
            opponent_race_to
            opponent_country
            opponent_state
            opponent_fargo
            opponent_is_winner
            winner_id
            winner_score
            loser_id
            loser_score
            status
            progress
            duration
            livestream_url
            score_verified
            scheduled_time
            start_date_time
            end_date_time
            is_public
            event_id
            event {
                id
                name
                slug
                description
            }
            venue_id
            venue {
                id
                name
                slug
                address1
                address2
                city
                region
                country
                postal_code
            }
            pool_table_id
            pool_table {
                id
                slug
                label
                kiosk_ip_address
                kiosk_password
            }
            match_players {
                id
                match_id
                user_id
                name
                country
                skill_level
                race_to
                score
                is_winner
            }
            match_stats(order_by: { time: desc }) {
                id
                metric
                value
                user_id
                match_game_id
                player_id
                time
            }
            managers {
                id
                user_id
            }
            table_size
            scoreboard_balls
            creator_id
            created_at
            updated_at
        }
    }
`;

export const GET_MATCH_PLAYER_SCORES_QUERY = gql`
    query matches($id: Int!) {
        matches(where: { id: { _eq: $id } }) {
            id
            slug
            player_id
            player_name
            player_score
            player_points
            player_fargo
            player_tiebreaker_points
            player_race_to
            opponent_id
            opponent_name
            opponent_score
            opponent_points
            opponent_fargo
            opponent_tiebreaker_points
            opponent_race_to
            winner_score
            loser_score
            scoreboard_balls
        }
    }
`;

export const GET_MATCH_STATS_QUERY = gql`
    query match_stats($match_id: Int!) {
        match_stats(where: { match_id: { _eq: $match_id } }, order_by: { time: desc }) {
            id
            metric
            value
            user_id
            match_id
            match_game_id
            player_id
            opponent_id
            game_number
            time
        }
    }
`;

export const GET_GAME_TYPES_QUERY = gql`
    query game_types {
        game_types {
            value
            comment
        }
    }
`;

export const GET_VENUES_QUERY = gql`
    query venues {
        venues(order_by: { name: asc }, limit: 25) {
            id
            name
            slug
            address1
            city
            region
            country
            venue_type
            is_public
            pool_tables_aggregate {
                aggregate {
                    count
                }
            }
            owner {
                id
            }
        }
    }
`;

export const SEARCH_VENUES_QUERY = gql`
    query venues($name: String!) {
        venues(
            distinct_on: name
            where: {
                is_public: { _eq: true }
                _or: [
                    { name: { _ilike: $name } }
                    { address1: { _ilike: $name } }
                    { city: { _ilike: $name } }
                    { region: { _ilike: $name } }
                    { postal_code: { _ilike: $name } }
                ]
            }
        ) {
            id
            name
            slug
            address1
            city
            region
            country
            postal_code
            venue_type
            lat
            lng
        }
    }
`;

export const SEARCH_USERS = gql`
    query users($first_name: String, $last_name: String, $nickname: String) {
        users(
            distinct_on: [first_name, last_name]
            where: {
                _or: [
                    { first_name: { _ilike: $first_name } }
                    { last_name: { _ilike: $last_name } }
                    { display_name: { _ilike: $first_name } }
                    { nickname: { _ilike: $nickname } }
                ]
            }
        ) {
            id
            first_name
            last_name
            email
            country
            display_name
            nickname
            skill_level
            phone_number
            status
        }
    }
`;

export const GET_VENUE_TABLES_QUERY = gql`
    query pool_tables($venueId: Int) {
        pool_tables(where: { venue_id: { _eq: $venueId }, status: { _eq: OPEN } }, order_by: { label: asc }) {
            id
            label
            type
            size
            year_built
            make
            model
            livestream_url
            is_streaming_table
            is_featured_table
            created_at
            updated_at
            status
            kiosk_ip_address
            kiosk_password
        }
    }
`;

export const FILTER_VENUES = gql`
    query venues($name: String!) {
        venues(distinct_on: name, where: { name: { _ilike: $name } }) {
            id
            name
            slug
            description
            venue_type
            league_venues {
                league {
                    id
                    name
                    slug
                }
            }
            avatar
            address1
            address2
            city
            country
            region
            postal_code
            phone_number
            email_address
            website
            operating_hours
            social_facebook
            social_instagram
            social_twitter
            is_public
            is_claimed
            claimed_by_id
            owner_id
        }
    }
`;

export const GET_VENUE_BY_ID_QUERY = gql`
    query venues($id: Int!) {
        venues(where: { id: { _eq: $id } }) {
            id
            name
            slug
            description
            venue_type
            avatar
            address1
            address2
            city
            country
            region
            postal_code
            phone_number
            email_address
            website
            operating_hours
            social_facebook
            social_instagram
            social_twitter
            is_public
            is_claimed
            claimed_by_id
            owner_id
            pool_tables(order_by: { label: asc }) {
                id
                label
                type
                size
                year_built
                make
                model
                livestream_url
                is_streaming_table
                is_featured_table
                kiosk_ip_address
                kiosk_password
                created_at
                updated_at
            }
        }
    }
`;

export const GET_VENUE_TYPES_QUERY = gql`
    query venue_types {
        venue_types {
            value
            comment
        }
    }
`;

export const GET_MATCH_TYPES_QUERY = gql`
    query match_types {
        match_types(order_by: { value: desc }) {
            value
            comment
        }
    }
`;

export const GET_MATCHES_QUERY = gql`
    query matches($creator_id: Int!) {
        matches(where: { creator_id: { _eq: $creator_id } }, order_by: { updated_at: desc }) {
            id
            name
            slug
            description
            match_number
            match_type
            game_type
            rule_format
            handicap_format
            race_to
            player_id
            player_name
            player_score
            player_points
            player_tiebreaker_points
            player_race_to
            player_country
            player_state
            player_fargo
            player_is_winner
            opponent_id
            opponent_name
            opponent_score
            opponent_points
            opponent_tiebreaker_points
            opponent_race_to
            opponent_country
            opponent_state
            opponent_fargo
            opponent_is_winner
            winner_id
            winner_score
            loser_id
            loser_score
            status
            progress
            duration
            livestream_url
            score_verified
            scheduled_time
            start_date_time
            end_date_time
            is_public
            venue_id
            venue {
                id
                name
                slug
                address1
                address2
                city
                region
                country
                postal_code
            }
            pool_table_id
            pool_table {
                id
                label
            }
            match_players {
                id
                match_id
                user_id
                name
                country
                skill_level
                race_to
                score
                is_winner
            }
            managers {
                id
                user_id
            }
            table_size
            scoreboard_balls
            creator_id
            created_at
            updated_at
        }
    }
`;

export const GET_MATCHES_MANAGERS_QUERY = gql`
    query match_managers($user_id: Int!) {
        match_managers(where: { user_id: { _eq: $user_id } }) {
            id
            matches {
                id
                name
                slug
                description
                match_number
                match_type
                game_type
                rule_format
                handicap_format
                race_to
                player_id
                player_name
                player_score
                player_points
                player_tiebreaker_points
                player_race_to
                player_country
                player_state
                player_fargo
                player_is_winner
                opponent_id
                opponent_name
                opponent_score
                opponent_points
                opponent_tiebreaker_points
                opponent_race_to
                opponent_country
                opponent_state
                opponent_fargo
                opponent_is_winner
                winner_id
                winner_score
                loser_id
                loser_score
                status
                progress
                duration
                livestream_url
                score_verified
                scheduled_time
                start_date_time
                end_date_time
                is_public
                venue_id
                venue {
                    id
                    name
                    slug
                    address1
                    address2
                    city
                    region
                    country
                    postal_code
                }
                pool_table_id
                pool_table {
                    id
                    label
                }
                match_players {
                    id
                    match_id
                    user_id
                    name
                    country
                    skill_level
                    race_to
                    score
                    is_winner
                }
                managers {
                    id
                    user_id
                }
                table_size
                scoreboard_balls
                creator_id
                created_at
                updated_at
            }
            match {
                id
                name
                slug
                description
                match_number
                match_type
                game_type
                rule_format
                handicap_format
                race_to
                player_id
                player_name
                player_score
                player_points
                player_tiebreaker_points
                player_race_to
                player_country
                player_state
                player_fargo
                player_is_winner
                opponent_id
                opponent_name
                opponent_score
                opponent_points
                opponent_tiebreaker_points
                opponent_race_to
                opponent_country
                opponent_state
                opponent_fargo
                opponent_is_winner
                winner_id
                winner_score
                loser_id
                loser_score
                status
                progress
                duration
                livestream_url
                score_verified
                scheduled_time
                start_date_time
                end_date_time
                is_public
                venue_id
                venue {
                    id
                    name
                    slug
                    address1
                    address2
                    city
                    region
                    country
                    postal_code
                }
                pool_table_id
                pool_table {
                    id
                    label
                }
                match_players {
                    id
                    match_id
                    user_id
                    name
                    country
                    skill_level
                    race_to
                    score
                    is_winner
                }
                managers {
                    id
                    user_id
                }
                table_size
                scoreboard_balls
                creator_id
                created_at
                updated_at
            }
        }
    }
`;

export const GET_PUBLIC_MATCHES_QUERY = gql`
    query matches {
        matches(where: { is_public: { _eq: true } }, order_by: { updated_at: desc }) {
            id
            name
            slug
            description
            match_number
            match_type
            game_type
            rule_format
            handicap_format
            race_to
            player_id
            player_name
            player_score
            player_points
            player_tiebreaker_points
            player_race_to
            player_country
            player_state
            player_fargo
            player_is_winner
            opponent_id
            opponent_name
            opponent_score
            opponent_points
            opponent_tiebreaker_points
            opponent_race_to
            opponent_country
            opponent_state
            opponent_fargo
            opponent_is_winner
            winner_id
            winner_score
            loser_id
            loser_score
            status
            progress
            duration
            livestream_url
            score_verified
            scheduled_time
            start_date_time
            end_date_time
            is_public
            venue_id
            venue {
                id
                name
                slug
                address1
                address2
                city
                region
                country
                postal_code
            }
            pool_table_id
            pool_table {
                id
                label
            }
            match_players {
                id
                match_id
                user_id
                name
                country
                skill_level
                race_to
                score
                is_winner
            }
            table_size
            scoreboard_balls
            creator_id
            created_at
            updated_at
        }
    }
`;

export const GET_MATCH_LONGEST_WINNING_STREAK = gql`
    query match_stats($match_id: Int!) {
        vw_match_longest_winning_streaks(where: { match_id: { _eq: $match_id } }) {
            longest_opponent_winning_streak
            longest_player_winning_streak
            match_id
            opponent_name
            player_name
        }
    }
`;

export const GET_MATCH_BREAK_AND_RUN_STREAKS = gql`
    query match_stats($match_id: Int!) {
        vw_match_break_and_run_streaks(where: { match_id: { _eq: $match_id } }) {
            longest_opponent_break_and_run_streak
            longest_player_break_and_run_streak
            match_id
            opponent_name
            player_name
        }
    }
`;

export const GET_MATCH_BREAK_AND_RUN_PACKAGES = gql`
    query vw_break_and_run_streaks($match_id: Int!) {
        vw_break_and_run_streaks(where: { match_id: { _eq: $match_id } }) {
            first_game
            last_game
            match_id
            name
            player_streak_length
        }
    }
`;

export const GET_MATCH_CONSECUTIVE_BREAK_AND_RUNS = gql`
    query vw_break_and_run_streaks($match_id: Int!) {
        vw_break_and_run_streaks(where: { match_id: { _eq: $match_id } }, order_by: { player_streak_length: desc }, limit: 2) {
            first_game
            last_game
            match_id
            name
            player_streak_length
        }
    }
`;

export const GET_MATCH_ADMINS = gql`
    query match_managers($match_id: Int!) {
        match_managers(where: { match_id: { _eq: $match_id } }) {
            id
            email
            invite_accepted
            creator
            admin
            match {
                id
                creator_id
            }
            user {
                id
                display_name
                first_name
                last_name
            }
        }
    }
`;

export const GET_MATCH_MANAGERS_BY_USER_ID = gql`
    query match_managers($match_id: Int!, $user_id: Int!) {
        match_managers(where: { _and: { match_id: { _eq: $match_id }, _and: { user_id: { _eq: $user_id } } } }) {
            id
        }
    }
`;

export const GET_MATCH_MANAGERS_BY_EMAIL = gql`
    query match_managers($match_id: Int!, $email: String!) {
        match_managers(where: { _and: { match_id: { _eq: $match_id }, _and: { email: { _eq: $email } } } }) {
            id
        }
    }
`;

export const GET_USER_EMAIL_BY_ID = gql`
    query users($id: Int!) {
        users(where: { id: { _eq: $id } }) {
            id
            email
        }
    }
`;

export const GET_USER_EMAIL_BY_EMAIL = gql`
    query users($email: String!) {
        users(where: { email: { _eq: $email } }) {
            id
            email
        }
    }
`;

export const GET_ADMIN_INVITE_KEY = gql`
    query match_managers_invite_keys($email: String!, $match_id: Int!) {
        match_managers_invite_keys(where: { email: { _eq: $email }, _and: { match_id: { _eq: $match_id } } }) {
            id
        }
    }
`;

export const GET_EVENT_QUERY = gql`
    query events($eventSlug: String) {
        events(where: { slug: { _eq: $eventSlug } }) {
            id
            name
            slug
            event_type
            description
            logo
            flyer
            location
            start_date_time
            end_date_time
            deleted_at
            is_public
            venue_id
            owner {
                id
                email
            }
            matches {
                id
                name
                slug
                description
                match_number
                match_type
                game_type
                rule_format
                handicap_format
                race_to
                player_id
                player_name
                player_score
                player_points
                player_tiebreaker_points
                player_race_to
                player_country
                player_state
                player_fargo
                player_is_winner
                opponent_id
                opponent_name
                opponent_score
                opponent_points
                opponent_tiebreaker_points
                opponent_race_to
                opponent_country
                opponent_state
                opponent_fargo
                opponent_is_winner
                winner_id
                winner_score
                loser_id
                loser_score
                status
                progress
                duration
                livestream_url
                score_verified
                scheduled_time
                start_date_time
                end_date_time
                is_public
                venue_id
                venue {
                    id
                    name
                    slug
                    address1
                    address2
                    city
                    region
                    country
                    postal_code
                }
                pool_table_id
                pool_table {
                    id
                    label
                }
                match_players {
                    id
                    match_id
                    user_id
                    name
                    country
                    skill_level
                    race_to
                    score
                    is_winner
                }
                match_stats(order_by: { time: desc }) {
                    id
                    metric
                    value
                    user_id
                    match_id
                    match_game_id
                    player_id
                    opponent_id
                    game_number
                    time
                }
                managers {
                    id
                    user_id
                    match_id
                    admin
                    creator
                }
                table_size
                scoreboard_balls
                creator_id
                created_at
                updated_at
            }
            tournaments {
                name
                id
                slug
                status
                progress
                start_date_time
                end_date_time
                flyer
                game_type
                tournament_type
                tournament_format
                handicap_format
                max_players
                description
                created_at
                updated_at
                winners_race_to
                losers_race_to
                finals_race_to
                handicapped

                tournament_players(
                    where: { _or: [{ place: { _eq: "1" } }, { place: { _eq: "2" } }, { place: { _eq: "3" } }] }
                    order_by: { place: asc }
                    limit: 4
                ) {
                    name
                    place
                }
                tournament_players_aggregate {
                    aggregate {
                        count
                    }
                }

                director {
                    id
                    slug
                    first_name
                    last_name
                }
                venue {
                    id
                    name
                    slug
                    city
                    country
                    region
                }
            }
            venue {
                id
                name
                slug
                city
                country
                region
                avatar
            }
        }
    }
`;

export const GET_EVENTS_QUERY = gql`
    query events {
        events(where: { deleted_at: { _is_null: true }, is_public: { _eq: true } }, order_by: { updated_at: desc }) {
            id
            name
            slug
            event_type
            description
            logo
            flyer
            location
            start_date_time
            end_date_time
            deleted_at
            is_public
            venue_id
            owner {
                id
                email
            }
            venue {
                id
                name
                slug
                city
                country
                region
                avatar
            }
            # is_public
            # owner {
            # 	id
            # 	first_name
            # 	last_name
            # 	email
            # }
            # operator {
            # 	id
            # 	first_name
            # 	last_name
            # 	email
            # }
            # address1
            # address2
            # city
            # county
            # country
            # region
            # postal_code
            # phone_number
            # fax_number
            # email_address
            # website
            # social_facebook
            # social_instagram
            # social_twitter
            # created_at
            # updated_at
            # match_notifications_in_app
            # match_notifications_sms
        }
    }
`;

export const GET_USER_EVENTS_QUERY = gql`
    query events($user_id: Int) {
        events(where: { owner_id: { _eq: $user_id } }, order_by: { name: asc }) {
            id
            name
            slug
            event_type
            description
            logo
            flyer
            location
            start_date_time
            end_date_time
            deleted_at
            owner {
                id
                email
            }
            venue {
                id
                name
                slug
                city
                country
                region
                avatar
            }
        }
    }
`;

export const GET_MATCH_GAMES_QUERY = gql`
    query match_games($match_id: Int!) {
        match_games(where: { match_id: { _eq: $match_id } }) {
            id
            match_id
            match_number
            game_number
            player_id
            player_score
            player_points
            player_is_winner
            player_break_run
            player_runout
            player_tpa
            player_unforced_errors
            player_balls_pocketed
            opponent_id
            opponent_score
            opponent_points
            opponent_is_winner
            opponent_break_run
            opponent_runout
            opponent_tpa
            opponent_unforced_errors
            opponent_balls_pocketed
            status
            progress
            duration
            table_id
            start_date_time
            end_date_time
            created_at
            updated_at
        }
    }
`;

export const GET_EVENT_LIVE_TABLE_QUERY = gql`
    query events($event_slug: String!, $table_slug: String!) {
        events(where: { slug: { _eq: $event_slug } }) {
            id
            slug
            name
            matches(where: { pool_table: { slug: { _eq: $table_slug } } }, limit: 1) {
                id
                name
                slug
                pool_table {
                    id
                    label
                    slug
                    kiosk_ip_address
                    kiosk_password
                    matches {
                        id
                        name
                        slug
                        description
                        match_number
                        match_type
                        game_type
                        rule_format
                        handicap_format
                        race_to
                        player_id
                        player_name
                        player_score
                        player_points
                        player_tiebreaker_points
                        player_race_to
                        player_country
                        player_state
                        player_fargo
                        player_is_winner
                        opponent_id
                        opponent_name
                        opponent_score
                        opponent_points
                        opponent_tiebreaker_points
                        opponent_race_to
                        opponent_country
                        opponent_state
                        opponent_fargo
                        opponent_is_winner
                        winner_id
                        winner_score
                        loser_id
                        loser_score
                        status
                        progress
                        duration
                        livestream_url
                        score_verified
                        scheduled_time
                        start_date_time
                        end_date_time
                        is_public
                        event_id
                        event {
                            id
                            name
                            slug
                            description
                        }
                        venue_id
                        venue {
                            id
                            name
                            slug
                            address1
                            address2
                            city
                            region
                            country
                            postal_code
                        }

                        match_players {
                            id
                            match_id
                            user_id
                            name
                            country
                            skill_level
                            race_to
                            score
                            is_winner
                        }
                        match_stats(order_by: { time: desc }) {
                            id
                            metric
                            value
                            user_id
                            match_id
                            match_game_id
                            player_id
                            opponent_id
                            game_number
                            time
                        }
                        managers {
                            id
                            user_id
                            match_id
                            admin
                            creator
                        }
                        table_size
                        scoreboard_balls
                        creator_id
                        created_at
                        updated_at
                    }
                }
            }
            tournaments {
                id
                name
                slug
                pool_tables(where: { slug: { _eq: $table_slug } }, limit: 1) {
                    id
                    label
                    slug
                    kiosk_ip_address
                    kiosk_password
                    tournament_match_table {
                        id
                        hash_id
                        round
                        identifier
                        place
                        challenger1 {
                            id
                            name
                            country
                            skill_level
                            place
                            race_to
                            seed
                        }
                        challenger2 {
                            id
                            name
                            country
                            skill_level
                            place
                            race_to
                            seed
                        }
                        challenger1_id
                        challenger1_name
                        challenger1_country
                        challenger1_score
                        challenger1_points
                        challenger1_race_to
                        challenger1_skill_level
                        challenger1_seed
                        challenger1_is_winner
                        challenger1_is_forfeit
                        challenger1_is_withdraw
                        challenger2_id
                        challenger2_name
                        challenger2_country
                        challenger2_score
                        challenger2_points
                        challenger2_race_to
                        challenger2_skill_level
                        challenger2_seed
                        challenger2_is_winner
                        challenger2_is_forfeit
                        challenger2_is_withdraw
                        challenger1_sms_sent
                        challenger2_sms_sent
                        tournament_player_id
                        match_number
                        match_id
                        team_id
                        scheduled_time
                        start_time
                        end_time
                        duration
                        table_id
                        table_name
                        seed
                        status
                        progress
                        loser_from
                        loser_from_num
                        loser_to
                        loser_to_num
                        winner_from
                        winner_to
                        winner_to_num
                        winner_from_top_num
                        winner_from_bottom_num
                        loser_from_top_num
                        loser_from_bottom_num
                        is_bye
                        is_split
                        is_semi_finals
                        is_finals
                        is_consolidation_finals
                        is_scoring_started
                    }
                }
            }
        }
    }
`;

export const GET_EVENT_LIVE_TABLE_QUERY2 = gql`
    query events($event_slug: String!, $table_slug: String!) {
        events(where: { slug: { _eq: $event_slug } }) {
            id
            slug
            name
            venue_id
            venue {
                id
                name
                slug
                pool_tables(where: { slug: { _eq: $table_slug } }, limit: 1) {
                    id
                    slug
                    label
                    type
                    size
                    year_built
                    make
                    model
                    kiosk_ip_address
                    kiosk_password
                }
            }

            matches(where: { pool_table: { slug: { _eq: $table_slug } } }, limit: 1) {
                id
                name
                slug
                pool_table {
                    id
                    label
                    slug
                    kiosk_ip_address
                    kiosk_password
                    matches(where: { status: { _neq: COMPLETED } }) {
                        id
                        name
                        slug
                        description
                        match_number
                        match_type
                        game_type
                        rule_format
                        handicap_format
                        race_to
                        player_id
                        player_name
                        player_score
                        player_points
                        player_tiebreaker_points
                        player_race_to
                        player_country
                        player_state
                        player_fargo
                        player_is_winner
                        opponent_id
                        opponent_name
                        opponent_score
                        opponent_points
                        opponent_tiebreaker_points
                        opponent_race_to
                        opponent_country
                        opponent_state
                        opponent_fargo
                        opponent_is_winner
                        winner_id
                        winner_score
                        loser_id
                        loser_score
                        status
                        progress
                        duration
                        livestream_url
                        score_verified
                        scheduled_time
                        start_date_time
                        end_date_time
                        is_public
                        is_scoring_started
                        event_id
                        event {
                            id
                            name
                            slug
                            description
                        }
                        venue_id
                        venue {
                            id
                            name
                            slug
                            address1
                            address2
                            city
                            region
                            country
                            postal_code
                        }

                        match_players {
                            id
                            match_id
                            user_id
                            name
                            country
                            skill_level
                            race_to
                            score
                            is_winner
                        }
                        match_stats(order_by: { time: desc }) {
                            id
                            metric
                            value
                            user_id
                            match_id
                            match_game_id
                            player_id
                            opponent_id
                            game_number
                            time
                        }
                        managers {
                            id
                            user_id
                            match_id
                            admin
                            creator
                        }
                        table_size
                        scoreboard_balls
                        creator_id
                        created_at
                        updated_at
                    }
                }
            }
            tournaments {
                id
                name
                slug
                description
                avatar
                flyer
                logo
                tournament_type
                tournament_format
                handicap_format
                player_type
                game_type
                winners_race_to
                losers_race_to
                race_to
                consolidation_finals
                max_players
                max_tables
                rule_format
                break_format
                status
                progress
                start_date_time
                end_date_time
                entry_fee
                added_money
                payout_type
                draw_type
                rating_system
                use_text_messaging
                rsvp_allowed
                show_player_skill_levels
                show_player_races
                is_public
                signup_cutoff_time
                pool_tables(where: { slug: { _eq: $table_slug } }, limit: 1) {
                    id
                    slug
                    label
                    type
                    size
                    year_built
                    make
                    model
                    created_at
                    updated_at
                    status
                    is_streaming_table
                    is_featured_table
                    livestream_url
                    kiosk_ip_address
                    kiosk_password
                    manufacturer {
                        id
                        name
                    }
                    venue {
                        id
                        name
                    }
                    user {
                        id
                        email
                        country
                    }
                    tournament_match_table(where: { status: { _neq: COMPLETED } }, order_by: { updated_at: desc }) {
                        id
                        hash_id
                        round
                        identifier
                        place
                        challenger1 {
                            id
                            name
                            country
                            skill_level
                            place
                            race_to
                            seed
                        }
                        challenger2 {
                            id
                            name
                            country
                            skill_level
                            place
                            race_to
                            seed
                        }
                        challenger1_id
                        challenger1_name
                        challenger1_country
                        challenger1_score
                        challenger1_points
                        challenger1_race_to
                        challenger1_skill_level
                        challenger1_seed
                        challenger1_is_winner
                        challenger1_is_forfeit
                        challenger1_is_withdraw
                        challenger2_id
                        challenger2_name
                        challenger2_country
                        challenger2_score
                        challenger2_points
                        challenger2_race_to
                        challenger2_skill_level
                        challenger2_seed
                        challenger2_is_winner
                        challenger2_is_forfeit
                        challenger2_is_withdraw
                        challenger1_sms_sent
                        challenger2_sms_sent
                        tournament_player_id
                        match_number
                        match_id
                        team_id
                        scheduled_time
                        start_time
                        end_time
                        duration
                        table_id
                        table_name
                        seed
                        status
                        progress
                        loser_from
                        loser_from_num
                        loser_to
                        loser_to_num
                        winner_from
                        winner_to
                        winner_to_num
                        winner_from_top_num
                        winner_from_bottom_num
                        loser_from_top_num
                        loser_from_bottom_num
                        is_bye
                        is_split
                        is_semi_finals
                        is_finals
                        is_consolidation_finals
                        is_scoring_started
                    }
                }
            }
        }
    }
`;

export const GET_TABLE_BY_ID_QUERY = gql`
    query pool_tables($id: Int) {
        pool_tables(where: { id: { _eq: $id } }) {
            id
            slug
            label
            type
            size
            year_built
            make
            model
            livestream_url
            manufacturer_id
            venue_id
            user_id
            match_id
            is_streaming_table
            is_featured_table
            created_at
            updated_at
            status
            kiosk_ip_address
            kiosk_password
        }
    }
`;

export const GET_TABLE_MANUFACTURERS_QUERY = gql`
    query pool_table_manufacturers {
        pool_table_manufacturers(order_by: { name: asc }) {
            id
            name
        }
    }
`;

export const GET_SHOTCAST_EVENTS_QUERY = gql`
    query shotcast_events($match_id: Int) {
        shotcast_events(where: { match_id: { _eq: $match_id } }, limit: 10, order_by: { event_timestamp: desc }) {
            id
            tournament_bracket_id
            match_id
            event_timestamp
            player_name
            description
            shot_data
            created_at
            updated_at
        }
    }
`;

export const GET_ARTISTIC_SHOTS_QUERY = gql`
    query artistic_pool_shots($venueId: Int) {
        artistic_pool_shots(order_by: { discipline: asc, shot: asc }) {
            id
            discipline
            shot
            name
            slug
            points
            image_url
            description
            created_at
            updated_at
        }
    }
`;
