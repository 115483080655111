import gql from 'graphql-tag';

export const CREATE_TABLE_MUTATION = gql`
	mutation insert_pool_tables($objects: [pool_tables_insert_input!]!) {
		insert_pool_tables(objects: $objects) {
			affected_rows
			returning {
				id
				label
				type
				size
				year_built
				make
				model
				livestream_url
				venue_id
				match_id
				manufacturer_id
				is_streaming_table
				is_featured_table
				created_at
				updated_at
			}
		}
	}
`;

export const UPDATE_TABLE_MUTATION = gql`
	mutation update_pool_tables($id: Int, $changes: pool_tables_set_input) {
		update_pool_tables(where: { id: { _eq: $id } }, _set: $changes) {
			affected_rows
			returning {
				id
				label
				type
				size
				year_built
				make
				model
				livestream_url
				venue_id
				match_id
				manufacturer_id
				is_streaming_table
				is_featured_table
				created_at
				updated_at
			}
		}
	}
`;

export const DELETE_TABLE_MUTATION = gql`
	mutation delete_pool_tables($id: Int) {
		delete_pool_tables(where: { id: { _eq: $id } }) {
			affected_rows
			returning {
				id
			}
		}
	}
`;

export const DUPLICATE_POOL_TABLE = gql`
	mutation insert_pool_tables($objects: [pool_tables_insert_input!]!) {
		__typename
		insert_pool_tables(objects: $objects) {
			affected_rows
			returning {
				id
			}
		}
	}
`;

export const UPDATE_TABLE_STATUS_MUTATION = gql`
	mutation update_pool_tables($id: Int, $changes: pool_tables_set_input) {
		update_pool_tables(where: { id: { _eq: $id } }, _set: $changes) {
			affected_rows
			returning {
				id
				label
				type
				size
				year_built
				make
				model
				livestream_url
				venue_id
				match_id
				manufacturer_id
				created_at
				updated_at
			}
		}
	}
`;

export const CREATE_VENUE_TABLE_MUTATION = gql`
	mutation insert_venue_pool_tables($objects: [venue_pool_tables_insert_input!]!) {
		insert_venue_pool_tables(objects: $objects) {
			affected_rows
			returning {
				id
				label
				type
				size
				year_built
				make
				model
				livestream_url
				is_streaming_table
				is_featured_table
				created_at
				updated_at
			}
		}
	}
`;

export const UPDATE_VENUE_TABLE_MUTATION = gql`
	mutation update_venue_pool_tables($id: Int, $changes: venue_pool_tables_set_input) {
		update_venue_pool_tables(where: { id: { _eq: $id } }, _set: $changes) {
			affected_rows
			returning {
				id
				label
				type
				size
				year_built
				make
				model
				livestream_url
				is_streaming_table
				is_featured_table
				created_at
				updated_at
			}
		}
	}
`;

export const CREATE_VENUE_MUTATION = gql`
	mutation insert_venues($objects: [venues_insert_input!]!) {
		insert_venues(objects: $objects) {
			affected_rows
			returning {
				id
				name
				slug
				description
				venue_type
				avatar
				address1
				address2
				city
				country
				region
				postal_code
				phone_number
				email_address
				website
				operating_hours
				social_facebook
				social_instagram
				social_twitter
				is_public
				is_claimed
				claimed_by_id
				owner_id
			}
		}
	}
`;

export const UPDATE_VENUE_MUTATION = gql`
	mutation update_venues($id: Int, $changes: venues_set_input) {
		update_venues(where: { id: { _eq: $id } }, _set: $changes) {
			affected_rows
			returning {
				id
				name
				slug
				description
				venue_type
				avatar
				address1
				address2
				city
				country
				region
				postal_code
				phone_number
				email_address
				website
				operating_hours
				social_facebook
				social_instagram
				social_twitter
				is_public
				is_claimed
				claimed_by_id
				owner_id
			}
		}
	}
`;

export const DELETE_VENUE_MUTATION = gql`
	mutation delete_venues($venue_id: Int) {
		delete_venues(where: { id: { _eq: $venue_id } }) {
			affected_rows
			returning {
				id
			}
		}
	}
`;
