import React, { useState } from 'react';
import { Table, Icon, Button, Row, Empty, Col, Typography, Modal } from 'antd';
import { Link, Route, withRouter } from 'react-router-dom';
import SectionWrapper from '../../components/SectionWrapper';
import SectionHeader from '../../components/SectionHeader';
import SectionContent from '../../components/SectionContent';
import Card from '../../components/Card';
import useBreakpoint from 'use-breakpoint';
import { defaultBreakpoints } from '../../utils/defaultBreakpoints';

function Terms(props) {
    const { breakpoint, maxWidth, minWidth } = useBreakpoint(defaultBreakpoints);

    return (
        <SectionWrapper>
            <SectionHeader
                title="Terms of Service"
                titleStyle={{
                    color: '#fff',
                    textTransform: 'uppercase',
                    margin: 0,
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
                height={breakpoint === 'mobile' ? 80 : 120}
                theme="digitalpool"
            />

            <SectionContent>
                <Row gutter={16} justify="center" type="flex">
                    <Col xs={22}>
                        <div
                            style={{
                                display: 'flex',
                                margin: 24,
                                justifyContent: 'center',
                            }}
                        >
                            <Card>
                                <div className="content">
                                    <div className="privacy">
                                        {/* <h2>Web Site Terms and Conditions of Use</h2> */}
                                        <p className="lead">
                                            <strong>
                                                DigitalPool's products and services are provided by DigitalPoolLeague, LLC ("DigitalPool"). These Terms of
                                                Service ("Terms") govern your access to and use of DigitalPool's website ("Website"), all mobile apps ("App"),
                                                products, and services ("Services"). Please read these Terms carefully, and{' '}
                                                <a href="mailto:contact@digitalpool.com" target="_blank" rel="noopener noreferrer">
                                                    contact us
                                                </a>{' '}
                                                if you have any questions. By accessing or using our Services, you agree to be bound by these Terms and by our{' '}
                                                <Link to="/privacy">Privacy Policy</Link>.
                                            </strong>
                                        </p>
                                        <h4>1. Using DigitalPool</h4>
                                        <h5>a. Who can use DigitalPool</h5>
                                        <p>
                                            You may use our Services only if you can form a binding contract with DigitalPool, and only in compliance with these
                                            Terms and all applicable laws. When you create your DigitalPool account, you must provide us with accurate and
                                            complete information. Any administrative use or access by anyone under the age of 13 is prohibited. If you open an
                                            account on behalf of a company, organization, or other entity, then (a) "you" includes you and that entity, and (b)
                                            you represent and warrant that you are authorized to grant all permissions and licenses provided in these Terms and
                                            bind the entity to these Terms, and that you agree to these Terms on the entity's behalf. Some of our Services may
                                            be software that is downloaded to your phone, tablet, or other device. You agree that we may automatically upgrade
                                            those Services, and these Terms will apply to such upgrades.
                                        </p>
                                        <h5>b. Our license to you</h5>
                                        <p>
                                            Subject to these Terms and our policies (including our <Link to="/use">Acceptable Usage Policy</Link>), we grant you
                                            a limited, non-exclusive, non-transferable, and revocable license to use our Services.
                                        </p>
                                        <h4>2. Your Content</h4>
                                        <h5>a. Posting content</h5>
                                        <p>
                                            DigitalPool allows you to post content, including files, comments, and other materials. Anything that you post or
                                            otherwise make available on our Services is referred to as "User Content." You retain all rights in, and are solely
                                            responsible for, the User Content you post to DigitalPool.
                                        </p>
                                        <h5>b. How DigitalPool and other users can use your content</h5>
                                        <p>
                                            You grant DigitalPool and its users a non-exclusive, royalty-free, transferable, sublicensable, worldwide license to
                                            use, store, display, reproduce, modify, perform, and distribute your User Content on DigitalPool solely for the
                                            purposes of operating, developing, providing, and using the DigitalPool Services. Nothing in these Terms shall
                                            restrict other legal rights DigitalPool may have to User Content, for example under other licenses. We reserve the
                                            right to remove or modify User Content for any reason, including User Content that we believe violates these Terms
                                            or our policies.
                                        </p>
                                        <h5>c. How long we keep your content</h5>
                                        <p>
                                            Following termination or deactivation of your account, or if you remove any User Content from DigitalPool, we may
                                            retain your User Content for a commercially reasonable period of time for backup, archival, or audit purposes.
                                        </p>
                                        <h5>d. Feedback you provide</h5>
                                        <p>
                                            We value hearing from our users, and are always interested in learning about ways we can make DigitalPool better. If
                                            you choose to submit comments, ideas or feedback, you agree that we are free to use them without any restriction or
                                            compensation to you. By accepting your submission, DigitalPool does not waive any rights to use similar or related
                                            Feedback previously known to DigitalPool, or developed by its employees, or obtained from sources other than you
                                        </p>
                                        <h4>3. Security</h4>
                                        <p>
                                            We care about the security of our users. While we work very hard to protect the security of your content and
                                            account, DigitalPool cannot guarantee that unauthorized third parties will not be able to defeat our security
                                            measures. Please notify us immediately of any compromise or unauthorized use of your account.
                                        </p>
                                        <h4>4. Third-Party Links, Sites, and Services</h4>
                                        <p>
                                            Our Services may contain links to third-party websites, advertisers, services, special offers, or other events or
                                            activities that are not owned or controlled by DigitalPool. We do not endorse or assume any responsibility for any
                                            such third-party sites, information, materials, products, or services. If you access any third party website,
                                            service, or content from DigitalPool, you do so at your own risk and you agree that DigitalPool will have no
                                            liability arising from your use of or access to any third-party website, service, or content.
                                        </p>
                                        <h4>5. Termination</h4>
                                        <p>
                                            DigitalPool may terminate or suspend this license at any time, with or without cause or notice to you. Upon
                                            termination, you continue to be bound by Sections 2 and 5-10 of these Terms.
                                        </p>
                                        <h4>6. Disclaimers</h4>
                                        <p>
                                            The Services and all included content are provided on an "as is" basis without warranty of any kind, whether express
                                            or implied.
                                        </p>
                                        <p>
                                            DigitalPool SPECIFICALLY DISCLAIMS ANY AND ALL WARRANTIES AND CONDITIONS OF MERCHANTABILITY, FITNESS FOR A
                                            PARTICULAR PURPOSE, AND NON-INFRINGEMENT, AND ANY WARRANTIES ARISING OUT OF COURSE OF DEALING OR USAGE OF TRADE.
                                        </p>
                                        <p>
                                            DigitalPool takes no responsibility and assumes no liability for any User Content that you or any other user or
                                            third party posts or transmits using our Services. You understand and agree that you may be exposed to User Content
                                            that is inaccurate, objectionable, inappropriate for children, or otherwise unsuited to your purpose.
                                        </p>
                                        <h4>7. Limitation of Liability</h4>
                                        <p>
                                            TO THE MAXIMUM EXTENT PERMITTED BY LAW, DigitalPool SHALL NOT BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL,
                                            CONSEQUENTIAL OR PUNITIVE DAMAGES, OR ANY LOSS OF PROFITS OR REVENUES, WHETHER INCURRED DIRECTLY OR INDIRECTLY, OR
                                            ANY LOSS OF DATA, USE, GOOD-WILL, OR OTHER INTANGIBLE LOSSES, RESULTING FROM (A) YOUR ACCESS TO OR USE OF OR
                                            INABILITY TO ACCESS OR USE THE SERVICES; (B) ANY CONDUCT OR CONTENT OF ANY THIRD PARTY ON THE SERVICES, INCLUDING
                                            WITHOUT LIMITATION, ANY DEFAMATORY, OFFENSIVE OR ILLEGAL CONDUCT OF OTHER USERS OR THIRD PARTIES; OR (C)
                                            UNAUTHORIZED ACCESS, USE OR ALTERATION OF YOUR TRANSMISSIONS OR CONTENT. IN NO EVENT SHALL DigitalPool'S AGGREGATE
                                            LIABILITY FOR ALL CLAIMS RELATING TO THE SERVICES EXCEED ONE HUNDRED U.S. DOLLARS (U.S. $100.00).
                                        </p>
                                        <h4>8. Arbitration</h4>
                                        <p>
                                            For any dispute you have with DigitalPool, you agree to first contact us and attempt to resolve the dispute with us
                                            informally. If DigitalPool has not been able to resolve the dispute with you informally, we each agree to resolve
                                            any claim, dispute, or controversy (excluding claims for injunctive or other equitable relief) arising out of or in
                                            connection with or relating to these Terms by binding arbitration by the American Arbitration Association ("AAA")
                                            under the Commercial Arbitration Rules and Supplementary Procedures for Consumer Related Disputes then in effect for
                                            the AAA, except as provided herein. Unless you and DigitalPool agree otherwise, the arbitration will be conducted in
                                            the county where you reside. Each party will be responsible for paying any AAA filing, administrative and arbitrator
                                            fees in accordance with AAA rules, except that DigitalPool will pay for your reasonable filing, administrative, and
                                            arbitrator fees if your claim for damages does not exceed $75,000 and is non-frivolous (as measured by the standards
                                            set forth in Federal Rule of Civil Procedure 11(b)). The award rendered by the arbitrator shall include costs of
                                            arbitration, reasonable attorneys' fees and reasonable costs for expert and other witnesses, and any judgment on the
                                            award rendered by the arbitrator may be entered in any court of competent jurisdiction. Nothing in this Section
                                            shall prevent either party from seeking injunctive or other equitable relief from the courts for matters related to
                                            data security, intellectual property or unauthorized access to the Service. ALL CLAIMS MUST BE BROUGHT IN THE
                                            PARTIES' INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE
                                            PROCEEDING, AND, UNLESS WE AGREE OTHERWISE, THE ARBITRATOR MAY NOT CONSOLIDATE MORE THAN ONE PERSON'S CLAIMS. YOU
                                            AGREE THAT, BY ENTERING INTO THESE TERMS, YOU AND DigitalPool ARE EACH WAIVING THE RIGHT TO A TRIAL BY JURY OR TO
                                            PARTICIPATE IN A CLASS ACTION.
                                        </p>
                                        <h4>9. Governing Law and Jurisdiction</h4>
                                        <p>
                                            These Terms shall be governed by the laws of the State of Wisconsin, without respect to its conflict of laws
                                            principles. We each agree to submit to the personal jurisdiction of a state court located in Outagamie County,
                                            Wisconsin or the United States District Court for the Eastern District of Wisconsin, for any actions not subject to
                                            Section 8 (Arbitration). Our Services are controlled and operated from the United States, and we make no
                                            representations that they are appropriate or available for use in other locations.
                                        </p>
                                        <h4>10. General Terms</h4>
                                        <p>
                                            <strong>Notification Procedures and changes to these Terms.</strong> DigitalPool reserves the right to determine the
                                            form and means of providing notifications to you, and you agree to receive legal notices electronically if we so
                                            choose. We may revise these Terms from time to time and the most current version will always be posted on our
                                            website. If a revision, in our sole discretion, is material we will notify you. By continuing to access or use the
                                            Services after revisions become effective, you agree to be bound by the revised Terms. If you do not agree to the
                                            new terms, please stop using the Services.
                                        </p>
                                        <p>
                                            <strong>Assignment.</strong> These Terms, and any rights and licenses granted hereunder, may not be transferred or
                                            assigned by you, but may be assigned by DigitalPool without restriction. Any attempted transfer or assignment in
                                            violation hereof shall be null and void.
                                        </p>
                                        <p>
                                            <strong>Entire Agreement/Severability.</strong> These Terms, together with the Privacy Policy and any amendments and
                                            any additional agreements you may enter into with DigitalPool in connection with the Services, shall constitute the
                                            entire agreement between you and DigitalPool concerning the Services. If any provision of these Terms is deemed
                                            invalid, then that provision will be limited or eliminated to the minimum extent necessary, and the remaining
                                            provisions of these Terms will remain in full force and effect.
                                        </p>
                                        <p>
                                            <strong>No Waiver.</strong> No waiver of any term of these Terms shall be deemed a further or continuing waiver of
                                            such term or any other term, and DigitalPool's failure to assert any right or provision under these Terms shall not
                                            constitute a waiver of such right or provision.
                                        </p>
                                        <h4>11. YouTube Terms of Service</h4>
                                        <p>
                                            <a href="https://www.youtube.com/t/terms">https://www.youtube.com/t/terms</a>
                                        </p>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <a name="addenduma" />
                                        <h2>Addendum A - Messaging System</h2>
                                        <p>We, DigitalPool, the operators of this App and Website, provide it as a public service to our users.</p>
                                        <p>
                                            Please carefully review the following basic rules that govern your use of the messaging system within the App and
                                            Website. Please note that your use of these messaging components constitutes your unconditional agreement to follow
                                            and be bound by these Terms and Conditions of Use. If you (the "User") do not agree to them, do not use the
                                            messaging components within the App or Website, provide any materials to the App or Website or download any
                                            materials from them.
                                        </p>
                                        <p>
                                            DigitalPool reserves the right to update or modify these Terms and Conditions at any time without prior notice to
                                            User. Your use of the App or Website following any such change constitutes your unconditional agreement to follow
                                            and be bound by these Terms and Conditions as changed. For this reason, we encourage you to review these Terms and
                                            Conditions of Use whenever you use the messaging components of the App or Website.
                                        </p>
                                        <p>
                                            These Terms and Conditions of Use apply to the use of the messaging components within the App and Website and do not
                                            extend to any linked third party sites. These Terms and Conditions are an Addendum to the App and Website Terms of
                                            Conditions set forth within, which are hereby incorporated by reference, contain the entire agreement (the
                                            “Agreement”) between you and DigitalPool with respect to the App and Website. Any rights not expressly granted
                                            herein are reserved.
                                        </p>
                                        <p>
                                            You are strictly prohibited from communicating on or through the App and Website any unlawful, harmful, offensive,
                                            threatening, abusive, libelous, harassing, defamatory, vulgar, obscene, profane, hateful, fraudulent, sexually
                                            explicit, racially, ethnically, or otherwise objectionable material of any sort, including, but not limited to, any
                                            material that encourages conduct that would constitute a criminal offense, give rise to civil liability, or
                                            otherwise violate any applicable local, state, national, or international law.
                                        </p>
                                        <p>
                                            You are expressly prohibited from compiling and using other Users' personal information, including addresses,
                                            telephone numbers, fax numbers, email addresses or other contact information that may appear on the App and Website,
                                            for the purpose of creating or compiling marketing and/or mailing lists and from sending other Users unsolicited
                                            marketing materials, whether by facsimile, email, or other technological means.
                                        </p>
                                        <p>
                                            DigitalPool may, but are not obligated to, monitor or review any areas on the App or Website where users transmit or
                                            post communications or communicate solely with each other, including but not limited to device to device messaging,
                                            tournament administrator push notifications, user message boards and email lists, and the content of any such
                                            communications. DigitalPool, however, will have no liability related to the content of any such communications,
                                            whether or not arising under the laws of copyright, libel, privacy, obscenity, or otherwise. DigitalPool may edit or
                                            remove content on the the App or Website at their discretion at any time.
                                        </p>
                                    </div>
                                </div>
                            </Card>
                        </div>
                    </Col>
                </Row>
            </SectionContent>
        </SectionWrapper>
    );
}

export default withRouter(Terms);
