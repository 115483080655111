import React, { useState, useEffect, useRef } from 'react';
import { message, Modal, Button, Typography, Upload, Divider, Icon, Avatar } from 'antd';
import { Form, FormItem, Input, Select, InputNumber } from 'formik-antd';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { displayEnumType } from '../../utils';
import { GET_LEAGUE_MATCH_QUERY, GET_LEAGUE_MATCHES_QUERY } from './data/queries';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { UPDATE_LEAGUE_MATCH, UPDATE_LEAGUE_MATCH_PLAYER } from './data/mutations';
import moment from 'moment-timezone';

const { Option } = Select;
const { Text } = Typography;

function MatchScoringModal(props) {
    const { client, selectedMatchId, match, userID, leagueID, divisionID, modalVisible, onModalOk, onModalCancel } = props;
    const [loading, setLoading] = useState(false);
    const [updateLeagueMatch, { updateLoading, updateError }] = useMutation(UPDATE_LEAGUE_MATCH);
    const [updateLeagueMatchPlayer] = useMutation(UPDATE_LEAGUE_MATCH_PLAYER);
    const formRef = useRef();

    function handleFocus(event) {
        event.target.select();
    }

    function onChange1(value) {
        console.log('changed', value);
    }

    function onChange2(value) {
        console.log('changed', value);
    }

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    };

    const submitUpdate = (data, match) => {
        const { player_score, opponent_score } = data;

        let playerWinner = false;
        let opponentWinner = false;

        if (parseInt(player_score) > parseInt(opponent_score)) {
            playerWinner = true;
        } else {
            opponentWinner = true;
        }

        setLoading(true);
        updateLeagueMatch({
            variables: {
                id: selectedMatchId,
                league_id: leagueID,
                changes: {
                    player_score: parseInt(player_score),
                    opponent_score: parseInt(opponent_score),
                    end_date_time: moment.utc(),
                    status: 'COMPLETED',
                    progress: '100',
                    player_is_winner: playerWinner,
                    opponent_is_winner: opponentWinner,
                },
            },
            notifyOnNetworkStatusChange: true,
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: GET_LEAGUE_MATCH_QUERY,
                    variables: { league_id: leagueID, match_id: match.id },
                },
            ],
        })
            .then((res) => {
                console.log(data);
                setLoading(false);
                message.success('Changes saved');
                onModalOk();
                // updateLeagueMatchPlayer({
                // 	variables: {
                // 		league_id: leagueID,
                // 		league_match_id: match.id,
                // 		objects: match.league_match_players.map((player, index) => {
                // 			return {
                // 				league_id: leagueID,
                // 				league_match_id: match.id,
                // 				league_player_id: player.id,
                // 				division_id: divisionID,
                // 				score: index === 0 ? parseInt(player_score) : parseInt(opponent_score)
                // 			};
                // 		})
                // 	},
                // 	awaitRefetchQueries: true,
                // 	refetchQueries: [
                // 		{
                // 			query: GET_LEAGUE_MATCHES_QUERY,
                // 			variables: { league_id: leagueID }
                // 		}
                // 	]
                // })
                // 	.then((data) => {
                // 		console.log(data);
                // 		setLoading(false);
                // 		message.success('Changes saved');
                // 		onModalOk();
                // 	})
                // 	.catch((error) => {
                // 		console.log(error);
                // 		setLoading(false);
                // 		message.info('There was an error', error);
                // 		onModalOk();
                // 	});
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
                message.error('There was an error', error);
                onModalOk();
            });
    };

    const schema = Yup.object().shape({
        player_score: Yup.number().required('Required'),
        opponent_score: Yup.number().required('Required'),
    });

    console.log(match);
    let initialValues;

    if (match) {
        initialValues = {
            ...match,
            player_score: match.player_score || 0,
            opponent_score: match.opponent_score || 0,
            match_players:
                match.match_players &&
                match.match_players.map((player) => {
                    return player.league_player_id;
                }),
        };
    }

    return (
        <Formik
            ref={formRef}
            onSubmit={(data, actions) => {
                submitUpdate(data, match);
            }}
            validationSchema={schema}
            enableReinitialize
            initialValues={initialValues}
            render={({ errors, touched, values }) => (
                <Modal
                    title="Enter Final Match Scores"
                    // getContainer={() => document.querySelector('.tables')}
                    visible={modalVisible}
                    onOk={onModalOk}
                    onCancel={onModalCancel}
                    centered
                    transitionName="fade"
                    // transitionName="none"
                    maskTransitionName="none"
                    footer={[
                        <Button key="cancel" onClick={onModalCancel}>
                            Cancel
                        </Button>,
                        <Button
                            key="submit"
                            disabled={Object.keys(errors).length > 0 ? true : false}
                            type={Object.keys(errors).length > 0 ? 'danger' : 'primary'}
                            onClick={() => {
                                formRef.current.handleSubmit();
                            }}
                        >
                            {loading ? 'Saving...' : 'Save Final Scores'}
                        </Button>,
                    ]}
                    bodyStyle={{ maxHeight: 500, overflowY: 'auto', padding: '10px 20px' }}
                    destroyOnClose={true}
                >
                    <Form
                        {...formItemLayout}
                        layout="vertical"
                        style={{ maxWidth: 600 }}
                        // onSubmit={(e) => {
                        // 	e.preventDefault();
                        // 	console.log(values);
                        // 	// handleSubmit(values);
                        // }}
                    >
                        <FormItem label={`${match && match.player_name} (${match && match.player_race_to})`} name="player_score">
                            <InputNumber
                                name="player_score"
                                placeholder="0"
                                min={0}
                                max={match && match.player_race_to}
                                // value={score}
                                // onChange={onChange1}
                                onFocus={handleFocus}
                                // onBlur={handleBlur}
                                // style={styles.inputSmall}
                                // onPressEnter={onEnter}
                                size="large"
                                // disabled
                                style={{ maxWidth: 200 }}
                            />
                            <Text style={{ marginLeft: 10 }}>{match && displayEnumType(match.winner_determined_by)}</Text>
                        </FormItem>

                        <FormItem label={`${match && match.opponent_name} (${match && match.opponent_race_to})`} name="opponent_score">
                            <InputNumber
                                name="opponent_score"
                                placeholder="0"
                                min={0}
                                max={match && match.opponent_race_to}
                                // value={score}
                                // onChange={onChange2}
                                onFocus={handleFocus}
                                // onBlur={handleBlur}
                                // style={styles.inputSmall}
                                // onPressEnter={onEnter}
                                size="large"
                                // disabled
                                style={{ maxWidth: 200 }}
                            />
                            <Text style={{ marginLeft: 10 }}>{match && displayEnumType(match.winner_determined_by)}</Text>
                        </FormItem>
                    </Form>
                </Modal>
            )}
        />
    );
}

export default MatchScoringModal;
