import React, { useState, useEffect, useLayoutEffect, useRef, createRef } from 'react';
import Round from './Round';
// import MatchGroup from './MatchGroup';
import Match from './Match';
import { withRouter, Link } from 'react-router-dom';
import Card from '../../../../components/Card';
// import scrollToComponent from 'react-scroll-to-component';
// import { scrollTo } from '../../utils/scrollTo';
// import DragScroll from './DragScroll';
import ScrollContainer from 'react-indiana-drag-scroll';
import CircularLoader from '../../../../components/CircularLoader';
import { isoCountries } from '../../../../utils/isoCountryCodes';
import ZeroState from '../../../../components/ZeroState';
import Sticky from 'react-stickynode';
import {
    GET_TOURNAMENT_QUERY,
    GET_TOURNAMENT_BRACKET_QUERY,
    GET_TOURNAMENT_PLAYERS_QUERY,
    GET_TOURNAMENT_TABLES_QUERY,
    GET_TOURNAMENT_POINTS,
    GET_TOURNAMENT_PAYOUTS,
    GET_TOURNAMENT_STATUS,
    GET_TOURNAMENT_BRACKET_PROJECTIONS,
    GET_ALL_TOURNAMENT_TABLES_QUERY,
} from '../data/queries';
import {
    GET_TOURNAMENT_BRACKET_SUBSCRIPTION,
    GET_ALL_TOURNAMENT_TABLES_SUBSCRIPTION,
    GET_TOURNAMENT_BRACKET_UPDATES_SUBSCRIPTION,
} from '../data/subscriptions';
import {
    UPDATE_TOURNAMENT_BRACKET_MUTATION,
    UPDATE_TOURNAMENT_BRACKET_MATCH_MUTATION,
    CREATE_TOURNAMENT_BRACKET_MUTATION,
    DELETE_TOURNAMENT_BRACKET_MUTATION,
    UPDATE_TOURNAMENT_PLAYER_MUTATION,
    UPDATE_TABLE_STATUS_MUTATION,
    RESET_TOURNAMENT_PLAYER_SEED_ORDER,
    RESET_TOURNAMENT_BRACKET_PLAYERS,
    UPDATE_TOURNAMENT_STATUS,
    UPDATE_TOURNAMENT_TABLE_STATUS_MUTATION,
    DELETE_TOURNAMENT_POINTS_MUTATION,
    DELETE_TOURNAMENT_PAYOUTS_MUTATION,
    RESET_TOURNAMENT_PLAYER_PLACEMENTS,
    GENERATE_TOURNAMENT_MATCH_TIME_PROJECTIONS,
} from '../data/mutations';
import Draggable from 'react-draggable';
import { DigitalPoolAPI } from '../../../../lib/api';
// import DoubleElimBracketGenerator from './doubleElimBracketGenerator';
import { message, Menu, Dropdown, Button, Icon, Typography, Empty, Row, Col, Tooltip, Modal, Input, Layout } from 'antd';
// import _ from 'lodash';
import { DownOutlined } from '@ant-design/icons';
import gql from 'graphql-tag';
import { Query, Subscription } from '@apollo/client/react/components';
import { useMutation, useQuery } from '@apollo/react-hooks';
import TournamentStageDropdown from './TournamentStageDropdown';
import MatchInfoModal from './MatchInfoModal';
import useBreakpoint from 'use-breakpoint';
import { defaultBreakpoints } from '../../../../utils/defaultBreakpoints';
import MatchOverlay from './MatchOverlay3';
import SetRoundTimeModal from './SetRoundTimeModal';
import { MapInteractionCSS } from 'react-map-interaction';
import SidePanel from './SidePanel';
import { timeoutPromise, getByeChart } from '../../../../utils';
import _ from 'lodash';
import BracketControls from './BracketControls';
// import scrollToComponent from 'react-scroll-to-component';
import qs from 'query-string';
import Autopilot from './Autopilot3';
import PendingMatches from './PendingMatches';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
// import * as htmlToImage from 'html-to-image';

// import clearRender from 'clear-render';
import moment from 'moment';
import './bracket.css';
import './bracket-compact.css';

const { Text, Title } = Typography;
const { Sider, Content, Header } = Layout;
// const { SubMenu } = Menu;
const { confirm } = Modal;

// http://udaikapila165.blogspot.com/2010/12/double-elimination-how-many-rounds.html
function Bracket(props) {
    const params = qs.parse(props.location.search, { ignoreQueryPrefix: true });
    const {
        isPublic,
        authState,
        tournament,
        isFullScreen,
        onFullScreen,
        tablesPanel,
        statusPanel,
        // size,
        height,
        navType,
        playerSearchCoordinates,
        onUpdateCoordinates,
        theme,
    } = props;

    const { breakpoint, maxWidth, minWidth } = useBreakpoint(defaultBreakpoints);
    const viewToggle = localStorage.getItem('digitalpool__viewType');
    const bracketLayoutToggle = localStorage.getItem('digitalpool__bracketLayout');
    const showNavigation = params && params.navigation === 'false' ? false : true;
    const showTabNavigation = params && params.subnavigation === 'false' ? false : true;
    const isPrint = params && params.print === 'true' ? true : false;
    const tournamentPath = props.match.params.slug;
    const stageSlug = props.match.params.stage;
    console.log('stage slug', stageSlug);
    // const [ bracketContent, setBracketContent ] = useState(null);
    const [bracketLayout, setBracketLayout] = useState(bracketLayoutToggle || 'up-down');
    const [originalMatches, setOriginalMatches] = useState([]);
    // const [ drawComplete, setDrawComplete ] = useState(false);
    const [tables, setTables] = useState([]);
    const [selectedStage, setSelectedStage] = useState();

    // useEffect(() => {
    //     if (stages && stages.length > 0) {
    //         let stage;
    //         if (!currentStage) {
    //             stage = stages && stages[0];
    //             setCurrentStage(stage);
    //         }
    //     }
    // }, [stages, currentStage]);

    const [bracketDisplayMode, setBracketDisplayMode] = useState(tournament.filter_by_round ? tournament.filter_by_round : 'all');
    const [compact, setCompact] = useState(props.compact || isPrint === true ? true : false);
    const [viewType, setViewType] = useState(viewToggle ? viewToggle : 'scroll');
    const [roundTimeModalVisible, setRoundTimeModalVisible] = useState(false);
    // const [ players, setPlayers ] = useState([]);
    // const [ drawRound, setDrawRound ] = useState([]);
    // const [ winnerBracket, setWinnerBracket ] = useState([]);
    // const [ loserBracket, setLoserBracket ] = useState([]);
    const [rendered, setRendered] = useState(false);
    // const [ loserBracketReversed, setLoserBracketReversed ] = useState([]);
    // const [tournamentComplete, setTournamentComplete] = useState(tournament.status === 'COMPLETED' ? true : false);
    const [zoom, setZoom] = useState(breakpoint === 'mobile' || isPrint === true ? 0.5 : 1.0);
    const [translation, setTranslation] = useState({ x: 0, y: 0 });
    // const [ drawerVisible, setDrawerVisible ] = useState(false);
    const [modalScoreVisible, setModalScoreVisible] = useState(false);
    const [currentMatch, setCurrentMatch] = useState();
    const [currentRound, setCurrentRound] = useState();
    const [sidePanelOpen, setSidePanelOpen] = useState(breakpoint === 'mobile' ? false : true);
    const [showStatusPanel, setShowStatusPanel] = useState(breakpoint === 'mobile' ? false : true);
    const [generateTournamentMatchTimeProjections] = useMutation(GENERATE_TOURNAMENT_MATCH_TIME_PROJECTIONS);
    const [matchModalVisible, setMatchModalVisible] = useState(false);
    const [autoScrollEnabled, setAutoScrollEnabled] = useState(false);

    const maxPlayers = (selectedStage && selectedStage.max_players) || tournament.max_players;
    const tournamentType = (selectedStage && selectedStage.tournament_type) || tournament.tournament_type;

    // const [ updateTableStatus, { updateTableLoading, updateTableError } ] = useMutation(
    // 	UPDATE_TOURNAMENT_TABLE_STATUS_MUTATION
    // );
    // const [ updateBracketMatch, { updateBracketLoading, updateBracketError } ] = useMutation(
    // 	UPDATE_TOURNAMENT_BRACKET_MATCH_MUTATION
    // );

    // const elementsRef = useRef(Array.from(Array(32)).map(() => createRef()));
    // const [ bracketDimensions, setBracketDimensions ] = useState();
    const bracketEl = useRef();
    let minScale = 0.1;
    let maxScale = 3;

    const windowHeight = window.innerHeight;
    const windowWidth = window.innerWidth;
    const headerEl = document.querySelector('.header');
    const headerNarrowEl = document.querySelector('.header-narrow');
    const footerEl = document.querySelector('.footer');
    const sectionHeaderEl = document.querySelector('.section-header');
    const bracketWrapperEl = document.querySelector('.bracket-surface');
    const progressBarEl = document.querySelector('.tournament-progress-bar');

    let bracketHeight;
    let headerHeight = isFullScreen === true || showNavigation === false ? 0 : sectionHeaderEl && sectionHeaderEl.offsetHeight;
    let headerNarrowHeight = headerNarrowEl && headerNarrowEl.offsetHeight;
    let progressBarHeight = progressBarEl && progressBarEl.offsetHeight;
    // let footerHeight = footerEl && footerEl.offsetHeight;
    let footerHeight = 53;
    let bracketWidth = bracketWrapperEl && bracketWrapperEl.offsetWidth;
    // const onMapChange = _.debounce(handleMapZoom, 100); // wait  to set the zoom and translation state
    const ForwardRound = React.forwardRef((props, ref) => (
        <Round
            {...props}
            theme={theme}
            stage={selectedStage}
            stages={tournament && tournament.stages}
            compact={compact}
            innerRef={ref}
            bracketDisplayMode={bracketDisplayMode}
            onUpdateCoordinates={onUpdateCoordinates}
            onSelectedStage={(stage) => {
                console.log('stage', stage);
                setSelectedStage(stage);
            }}
            onSetRoundTime={(round, matches) => {
                console.log(round);
                setCurrentRound({
                    ...round,
                    matches: matches,
                });
                setRoundTimeModalVisible(true);
            }}
        />
    ));

    const API = DigitalPoolAPI(props);

    if (isPublic === true) {
        if (isFullScreen === true) {
            bracketHeight = '100%';
        } else {
            const spacer = breakpoint === 'mobile' ? 170 : 95;
            bracketHeight = windowHeight - (headerHeight + footerHeight + spacer);
        }
    } else if (isFullScreen === false && headerNarrowEl) {
        bracketHeight = windowHeight - (headerHeight + progressBarHeight + footerHeight + headerNarrowHeight);
    } else if (isFullScreen === false && headerEl) {
        bracketHeight = windowHeight - (headerHeight + progressBarHeight + footerHeight + headerEl.offsetHeight);
    } else if (isFullScreen === true) {
        bracketHeight = '100%';
    } else {
        bracketHeight = windowHeight - (headerHeight + progressBarHeight + footerHeight);
    }

    if (breakpoint === 'mobile') {
        bracketHeight = bracketHeight + 80;
    }

    const styles = {
        container: {
            // position: 'fixed',
            // top: 0,
            // left: navType === 'desktop' ? 125 : 0,
            // width: '100%',
            // height: '100%',
            // // padding: '20px 40px',
            // display: 'flex',
            // alignItems: 'center',
            // justifyContent: 'center',
            // textAlign: 'center',
            // zIndex: 1
            padding: 10,
            backgroundColor: theme.bracket.backgroundColor,
        },
        headerContainer: {
            display: 'flex',
            borderBottom: '1px solid #EDEDED',
        },
        header: {
            // display: 'flex',
            textAlign: 'center',
            padding: 10,
            backgroundColor: '#fff',
            minWidth: 300,
            borderBottom: '1px solid #EDEDED',
            marginBottom: 40,
        },
        spacer: {},
    };

    useLayoutEffect(() => {
        if (rendered === true) {
            function getOffset(el) {
                var _x = 0;
                var _y = 0;
                while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
                    _x += el.offsetLeft - el.scrollLeft;
                    _y += el.offsetTop - el.scrollTop;
                    el = el.offsetParent;
                }
                return { top: _y, left: _x };
            }

            const identifierElement = document.getElementById('W1-1');
            const bracketSurfaceElement = document.querySelector('.bracket-surface');
            let absoluteCoordinates = getOffset(identifierElement);
            const identifierRect = identifierElement && identifierElement.getBoundingClientRect();
            const bracketRect = bracketSurfaceElement && bracketSurfaceElement.getBoundingClientRect();

            // console.log(bracketRect);
            // console.log('bracketLayout:', bracketLayout);
            // console.log('viewType:', viewType);

            let newX;
            if (viewType === 'zoom') {
                if (bracketLayout === 'up-down') {
                    if (bracketRect) {
                        newX = -absoluteCoordinates.left + document.body.offsetWidth - bracketRect.width / 2 - 40;
                    } else {
                        newX = -absoluteCoordinates.left + document.body.offsetWidth - 40;
                    }
                } else if (bracketLayout === 'left-right') {
                    if (bracketDisplayMode === 'top16') {
                        newX = -absoluteCoordinates.left + document.body.offsetWidth / 2;
                    } else {
                        newX = -absoluteCoordinates.left + document.body.offsetWidth / 2 - identifierRect.width / 2;
                    }
                }
            } else if (viewType === 'scroll') {
                handleSetZoom(breakpoint === 'mobile' || isPrint === true ? 0.7 : 0.8);
                // onUpdateZoom(1.0);
                if (bracketLayout === 'up-down') {
                    if (bracketRect) {
                        newX = -absoluteCoordinates.left + document.body.offsetWidth - bracketRect.width / 2 - 40;
                    } else {
                        newX = -absoluteCoordinates.left + document.body.offsetWidth - 40;
                    }
                    const scrollingElement = document.querySelector('.scroll-container');
                    if (scrollingElement) {
                        scrollingElement.scroll({
                            top: 0,
                            left: 0,
                            behavior: 'smooth',
                        });
                    }
                } else if (bracketLayout === 'left-right') {
                    if (bracketDisplayMode === 'top16') {
                        newX = -absoluteCoordinates.left + document.body.offsetWidth / 2;
                    } else {
                        if (identifierRect) {
                            newX = -absoluteCoordinates.left + document.body.offsetWidth / 2 - identifierRect.width / 2;
                        } else {
                            newX = -absoluteCoordinates.left + document.body.offsetWidth / 2;
                        }
                    }

                    const scrollingElement = document.querySelector('.scroll-container');
                    if (scrollingElement) {
                        // console.log('left', identifierElement.offsetLeft);
                        if (bracketDisplayMode === 'top16') {
                            scrollingElement.scroll({
                                top: 0,
                                left: 0,
                                behavior: 'smooth',
                            });
                        } else {
                            if (identifierRect) {
                                scrollingElement.scroll({
                                    top: 0,
                                    left: document.body.offsetWidth / 2 + identifierElement.offsetLeft / 2 - identifierRect.width / 2,
                                    behavior: 'smooth',
                                });
                            } else if (identifierElement) {
                                scrollingElement.scroll({
                                    top: 0,
                                    left: document.body.offsetWidth / 2 + identifierElement.offsetLeft / 2,
                                    behavior: 'smooth',
                                });
                            } else {
                                scrollingElement.scroll({
                                    top: 0,
                                    left: document.body.offsetWidth / 2,
                                    behavior: 'smooth',
                                });
                            }
                        }
                    }
                }
            }
            const newY = 0;
            const newCoords = { x: newX, y: newY };
            // console.log(newCoords);
            if (onUpdateCoordinates) {
                onUpdateCoordinates(newCoords);
            }
        }
    }, [rendered, viewType, bracketLayout]);

    function handleCompactToggle(value) {
        setCompact(value);
    }

    function showScoreModal(match, round) {
        setCurrentMatch(match);
        setCurrentRound(round);
        setModalScoreVisible(true);
    }

    function toggleFullScreen() {
        onFullScreen(!isFullScreen);
    }

    function handleRoundTimeModalOk(e) {
        setRoundTimeModalVisible(false);
    }

    function handleRoundTimeModalCancel(e) {
        setRoundTimeModalVisible(false);
    }

    function generateMatchTimeProjections(projectionConfig) {
        console.log('time projection config', projectionConfig);
        const key = 'projections';
        message.loading({ content: 'Calculating match time projections...', key, duration: 0 });

        const generateProjectionsWithTimeout = () => {
            return new Promise((resolve, reject) => {
                generateTournamentMatchTimeProjections({
                    variables: {
                        tournament_id: tournament.id,
                        ...projectionConfig,
                    },
                    awaitRefetchQueries: true,
                    notifyOnNetworkStatusChange: true,
                    refetchQueries: [
                        {
                            query: GET_TOURNAMENT_BRACKET_QUERY,
                            variables: { tournamentId: tournament.id },
                            // awaitRefetchQueries: true,
                            // onCompleted: (data) => {
                            // 	console.log(data);
                            // },
                            // notifyOnNetworkStatusChange: true,
                            // fetchPolicy: 'cache-and-network'
                        },
                        // {
                        // 	query: GET_TOURNAMENT_BRACKET_PROJECTIONS,
                        // 	variables: { tournament_id: tournament.id }
                        // }
                    ],
                })
                    .then((data) => {
                        resolve(data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        };

        // Apply a timeout of 10 seconds to doSomething
        timeoutPromise(10000, generateProjectionsWithTimeout())
            .then((res) => {
                console.log('time projections', res);
                message.success({
                    content: `Match time projections created`,
                    key,
                    duration: 2,
                });
            })
            .catch((error) => {
                console.error(error);
                message.info('There was an error', error);
            });
    }

    function startTournament(callback) {
        API.updateTournament({
            start_date_time: moment.utc(),
            status: 'IN_PROGRESS',
        }).then((res) => {
            // await API.updateTournamentStatus('IN_PROGRESS');
            message.success({
                content: `Tournament clock started.`,
                duration: 2,
            });

            return res;
        });

        // const projectionConfig = {
        //     from_round: null,
        //     number_of_tables: tables.length || 1,
        //     use_tournament_actuals: false,
        //     winner_side_race_to: tournament.winner_side_race_to || 7,
        //     loser_side_race_to: tournament.loser_side_race_to || 7,
        //     average_time_per_game: 5,
        // };
        // generateMatchTimeProjections(projectionConfig);
    }

    async function resetBracket() {
        const key = 'updatable';
        // message.loading({ content: 'Resetting tournament bracket...', key, duration: 0 });
        const status = await API.updateTournamentStatus('NOT_STARTED');

        console.log('status', status);

        props.client
            .mutate({
                mutation: UPDATE_TOURNAMENT_STATUS,
                variables: {
                    id: tournament.id,
                    changes: {
                        status: 'NOT_STARTED',
                        progress: '0',
                    },
                },
                notifyOnNetworkStatusChange: true,
                awaitRefetchQueries: true,
                refetchQueries: [
                    {
                        query: GET_TOURNAMENT_STATUS,
                        variables: { id: tournament.id },
                    },
                ],
            })
            .then((data) => {
                console.log(data);
            })
            .catch((e) => {
                console.error(e);
                message.error({ content: JSON.stringify(e), key, duration: 2 });
            });

        props.client
            .mutate({
                mutation: RESET_TOURNAMENT_PLAYER_PLACEMENTS,
                variables: {
                    tournament_id: tournament.id,
                },
                notifyOnNetworkStatusChange: true,
                awaitRefetchQueries: true,
                refetchQueries: [
                    {
                        query: GET_TOURNAMENT_PLAYERS_QUERY,
                        variables: { tournament_id: tournament.id },
                    },
                ],
            })
            .then((data) => {
                console.log(data);
            })
            .catch((e) => {
                console.error(e);
                message.error({ content: JSON.stringify(e), key, duration: 2 });
            });

        props.client
            .mutate({
                mutation: DELETE_TOURNAMENT_POINTS_MUTATION,
                variables: {
                    tournament_id: tournament.id,
                },
                notifyOnNetworkStatusChange: true,
                awaitRefetchQueries: true,
                refetchQueries: [
                    {
                        query: GET_TOURNAMENT_POINTS,
                        variables: { tournament_id: tournament.id },
                    },
                ],
            })
            .then(async (data) => {
                console.log(data);
            })
            .catch((error) => {
                console.log(error);
                return error;
            });

        props.client
            .mutate({
                mutation: DELETE_TOURNAMENT_PAYOUTS_MUTATION,
                variables: {
                    tournament_id: tournament.id,
                },
                notifyOnNetworkStatusChange: true,
                awaitRefetchQueries: true,
                refetchQueries: [
                    {
                        query: GET_TOURNAMENT_PAYOUTS,
                        variables: { tournament_id: tournament.id },
                    },
                ],
            })
            .then(async (data) => {
                console.log(data);
            })
            .catch((error) => {
                console.log(error);
                return error;
            });

        return props.client
            .mutate({
                mutation: DELETE_TOURNAMENT_BRACKET_MUTATION,
                variables: {
                    tournament_id: tournament.id,
                },
                notifyOnNetworkStatusChange: true,
                awaitRefetchQueries: true,
                refetchQueries: [
                    {
                        query: GET_TOURNAMENT_QUERY,
                        variables: { id: tournament.id },
                    },
                    {
                        query: GET_TOURNAMENT_BRACKET_QUERY,
                        variables: { tournamentId: tournament.id },
                    },
                ],
            })
            .then(async (data) => {
                props.history.push(`/tournament-builder/${tournament.slug}/edit?step=3`);
                // console.log(data);
                // message.loading({ content: 'Creating new tournament bracket...', key, duration: 0 });
                // const totalPlayers = tournament.tournament_players_aggregate.aggregate.count;
                // const bracketSize = determineBracketSize(totalPlayers);

                // const bracketDBDataset = createBracketDBDataset(
                // 	tournament.id,
                // 	totalPlayers,
                // 	bracketSize,
                // 	tournament.consolidation_finals
                // );
                // console.log(bracketDBDataset);

                // return new Promise(async (resolve, reject) => {
                // 	return await props.client
                // 		.mutate({
                // 			mutation: CREATE_TOURNAMENT_BRACKET_MUTATION,
                // 			variables: {
                // 				objects: bracketDBDataset.matches
                // 			},
                // 			awaitRefetchQueries: true,
                // 			refetchQueries: [
                // 				{
                // 					query: GET_TOURNAMENT_BRACKET_QUERY,
                // 					variables: { tournamentId: tournament.id }
                // 				}
                // 			]
                // 		})
                // 		.then((res) => {
                // 			console.log(res);
                // 			message.success({ content: 'Tournament bracket reset complete!', key, duration: 1 });
                // 			resolve(res.data.insert_tournament_brackets.affected_rows);
                // 		})
                // 		.catch((error) => {
                // 			console.log(error);
                // 			message.error({
                // 				content: `Error resetting the tournament ${JSON.stringify(error)}`,
                // 				key,
                // 				duration: 1
                // 			});
                // 			reject(error);
                // 		});
                // });
            })
            .catch((error) => {
                console.log(error);
                return error;
            });
    }

    function showConfirmStart(drawRound, winnerBracket, matches) {
        confirm({
            title: 'Are you ready to start the tournament?',
            content: 'This action will start the tournament clock.',
            centered: true,
            transitionName: 'fade',
            maskTransitionName: 'none',
            okText: 'Start Tournament',
            cancelText: "I'll do this later",
            onOk() {
                // return new Promise((resolve, reject) => {
                //   setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                // }).catch(() => console.log('Oops errors!'));
                // if (tables.length > 0 && drawRound[0] && drawRound[0][0] && !drawRound[0][0].challenger1_seed) {
                // 	showConfirmDraw(drawRound, winnerBracket);
                // } else if (tables.length > 0) {
                // 	startTournament(drawRound, winnerBracket);
                // } else {
                // 	message.error('You must add at least 1 table before you can start the tournament.', 10);
                // }
                if (tables.length > 0) {
                    startTournament();
                    // startTournament().then((data) => {
                    // 	generateDraw(drawRound, winnerBracket, matches, true);
                    // });

                    // showConfirmDraw(drawRound, winnerBracket);
                } else {
                    message.error('You must add at least 1 table before you can start the tournament.', 10);
                }
            },
            onCancel() {},
        });
    }

    function showConfirmReset() {
        confirm({
            title: 'Are you sure you want to reset the bracket?',
            content: 'This action will destroy all existing matches.',
            centered: true,
            transitionName: 'fade',
            maskTransitionName: 'none',
            okText: `Reset`,
            onOk() {
                // return new Promise((resolve, reject) => {
                //   setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                // }).catch(() => console.log('Oops errors!'));
                resetBracket();
            },
            onCancel() {},
        });
    }

    function showCompleteDialog(winner) {
        console.log(winner);
        let content;
        if (winner.is_split === true) {
            content = `${winner.challenger1_name} and ${winner.challenger2_name} split 1st place!.`;
        } else {
            content = `${winner.challenger2_name || winner.challenger1_name} won the tournament!`;
        }
        Modal.success({
            content: content,
            centered: true,
            transitionName: 'fade',
            maskTransitionName: 'none',
        });
    }

    function completeTournament(winner) {
        showCompleteDialog(winner);
        // setTournamentComplete(true);
    }

    function shuffle(array) {
        return array.sort(() => Math.random() - 0.5);
    }

    async function generateDraw(draw, winnerBracket, matches, random = true) {
        const stage = selectedStage;
        return new Promise(async (resolve, reject) => {
            props.client
                .query({
                    query: GET_TOURNAMENT_PLAYERS_QUERY,
                    variables: {
                        tournament_id: tournament.id,
                    },
                })
                .then((res) => {
                    props.client
                        .mutate({
                            mutation: RESET_TOURNAMENT_PLAYER_SEED_ORDER,
                            variables: {
                                tournament_id: tournament.id,
                            },
                        })
                        .then((data1) => {
                            props.client
                                .mutate({
                                    mutation: RESET_TOURNAMENT_BRACKET_PLAYERS,
                                    variables: {
                                        tournament_id: tournament.id,
                                    },
                                })
                                .then((data2) => {
                                    //  RESET_TOURNAMENT_PLAYER_SEED_ORDER

                                    let players = res && res.data && res.data.tournament_players;

                                    console.log(draw);
                                    // console.log(winnerBracket);
                                    // console.log(tournament.max_players);

                                    var totalRounds = Math.ceil(Math.log(tournament.max_players) / Math.log(2));

                                    if (players.length > 0) {
                                        var seeds = generateSeeds(tournament.max_players, totalRounds, tournament.max_players);
                                        console.log('seeds', seeds);
                                        let playersToUpdate = [];

                                        const key = 'updatable';
                                        message.loading({ content: 'Seeding bracket...', key, duration: 0 });

                                        if (random === true) {
                                            players = shuffle(_.uniq(players, 'id'));
                                        } else {
                                            players = _.uniq(players, 'id');
                                        }

                                        // players = players.slice(0, 28);

                                        var playerIndex = 1;
                                        console.log(players);

                                        const seededPlayers = seeds.map((match, seedIndex) => {
                                            // console.log(match);
                                            // console.log(players);

                                            const byeChart = getByeChart(tournament.max_players);
                                            var matchNumber = seedIndex * 2 + 1;
                                            const numberOfByes = tournament.max_players - players.length;
                                            //console.log(
                                            //	'number of byes: ',
                                            //	tournament.max_players,
                                            //	players.length,
                                            //	numberOfByes,
                                            //	seedIndex,
                                            //	matchNumber
                                            //);

                                            var seed1 = -1;
                                            var seed2 = -1;

                                            if (byeChart.indexOf(matchNumber) <= numberOfByes - 1 && byeChart.indexOf(matchNumber) !== -1) seed1 = -1;
                                            else {
                                                //seed1 = match[0] - 1;
                                                seed1 = playerIndex - 1;
                                                playerIndex++;
                                            }

                                            //console.log('matchNumber, seed1', matchNumber, seed1);

                                            matchNumber++;
                                            if (byeChart.indexOf(matchNumber) <= numberOfByes - 1 && byeChart.indexOf(matchNumber) !== -1) seed2 = -1;
                                            else {
                                                //seed2 = match[1] - 1;
                                                seed2 = playerIndex - 1;
                                                playerIndex++;
                                            }

                                            //console.log('matchNumber, seed2', matchNumber, seed2);

                                            const player1 = players[seed1];
                                            const player2 = players[seed2];

                                            // console.log({
                                            // 	match,
                                            // 	seed1,
                                            // 	seed2,
                                            // 	player1,
                                            // 	player2
                                            // });

                                            if (player1) {
                                                playersToUpdate.push({
                                                    id: player1.id,
                                                    name: player1.name,
                                                    country: player1.country,
                                                    email: player1.email,
                                                    phone_number: player1.phone_number,
                                                    skill_level: player1.skill_level,
                                                    status: player1.status,
                                                    points: player1.points,
                                                    seed: match[0],
                                                    race_to: player1.race_to,
                                                    user_id: player1.user_id,
                                                });
                                            }

                                            if (player2) {
                                                playersToUpdate.push({
                                                    id: player2.id,
                                                    name: player2.name,
                                                    country: player2.country,
                                                    email: player2.email,
                                                    phone_number: player2.phone_number,
                                                    skill_level: player2.skill_level,
                                                    status: player2.status,
                                                    points: player2.points,
                                                    seed: match[1],
                                                    race_to: player2.race_to,
                                                    user_id: player2.user_id,
                                                });
                                            }

                                            return {
                                                challenger1_id: player1 ? player1.id : null,
                                                challenger1_name: player1 ? player1.name : 'BYE',
                                                challenger1_country: player1 ? player1.country : null,
                                                challenger1_race_to: player1 ? player1.race_to : null,
                                                challenger1_skill_level: player1 ? player1.skill_level : null,
                                                challenger1_sms_sent: player1 ? player1.sms_sent : null,
                                                challenger1_score: 0,
                                                challenger1_seed: match[0],
                                                challenger2_id: player2 ? player2.id : null,
                                                challenger2_name: player2 ? player2.name : 'BYE',
                                                challenger2_country: player2 ? player2.country : null,
                                                challenger2_race_to: player2 ? player2.race_to : null,
                                                challenger2_skill_level: player2 ? player2.skill_level : null,
                                                challenger2_sms_sent: player2 ? player2.sms_sent : null,
                                                challenger2_score: 0,
                                                challenger2_seed: match[1],
                                            };
                                        });

                                        function updatePlayer(player) {
                                            return props.client
                                                .mutate({
                                                    mutation: UPDATE_TOURNAMENT_PLAYER_MUTATION,
                                                    variables: {
                                                        id: player.id,
                                                        tournament_id: tournament.id,
                                                        changes: player,
                                                    },
                                                    // awaitRefetchQueries: true,
                                                    // refetchQueries: [
                                                    // 	{
                                                    // 		query: GET_TOURNAMENT_BRACKET_QUERY,
                                                    // 		variables: { tournamentId: tournament.id }
                                                    // 	}
                                                    // ]
                                                })
                                                .then((data) => {
                                                    // console.log(data);
                                                    return data;
                                                })
                                                .catch((error) => {
                                                    console.error(error);
                                                    reject(error);
                                                });
                                        }

                                        let result = playersToUpdate.reduce((accumulatorPromise, nextPlayer) => {
                                            return accumulatorPromise.then(() => {
                                                return updatePlayer(nextPlayer);
                                            });
                                        }, Promise.resolve());

                                        result.then((e) => {
                                            console.log(seededPlayers);
                                            console.log(playersToUpdate);
                                            console.log(draw[0]);

                                            message.loading({
                                                content: 'Generating first-round matches...',
                                                key,
                                                duration: 0,
                                            });

                                            let promises = [];

                                            const firstRoundDraw =
                                                draw &&
                                                draw[0] &&
                                                draw[0].map((match, i) => {
                                                    const player1Race = match.challenger1_race_to
                                                        ? match.challenger1_race_to
                                                        : stage
                                                        ? stage.winners_race_to
                                                        : tournament.winners_race_to;
                                                    const player2Race = match.challenger2_race_to
                                                        ? match.challenger2_race_to
                                                        : stage
                                                        ? stage.winners_race_to
                                                        : tournament.winners_race_to;

                                                    return {
                                                        id: match.id,
                                                        ...(match.round && { round: match.round }),
                                                        ...(match.identifier && { identifier: match.identifier }),
                                                        challenger1_score: match.challenger1_score,
                                                        challenger1_race_to: player1Race,
                                                        challenger1_skill_level: match.challenger1_skill_level,
                                                        challenger1_seed: match.challenger1_seed,
                                                        challenger1_sms_sent: match.challenger1_sms_sent,
                                                        challenger2_score: match.challenger2_score,
                                                        challenger2_race_to: player2Race,
                                                        challenger2_skill_level: match.challenger2_skill_level,
                                                        challenger2_seed: match.challenger2_seed,
                                                        challenger2_sms_sent: match.challenger2_sms_sent,
                                                        match_number: match.match_number,
                                                        match_id: match.match_id,
                                                        team_id: match.team_id,
                                                        scheduled_time: match.scheduled_time,
                                                        start_time: match.start_time,
                                                        end_time: match.end_time,
                                                        duration: match.duration,
                                                        table_id: match.table_id,
                                                        table_number: match.table_number,
                                                        seed: match.seed,
                                                        status: match.status,
                                                        progress: match.progress,
                                                        winner_to: match.winner_to,
                                                        winner_to_num: match.winner_to_num,
                                                        loser_from: match.loser_from,
                                                        loser_from_num: match.loser_from_num,
                                                        loser_to: match.loser_to,
                                                        loser_to_num: match.loser_to_num,
                                                        ...seededPlayers[i],
                                                    };
                                                });
                                            console.log('After draw', firstRoundDraw);

                                            const byeMatches = firstRoundDraw
                                                .filter((match) => {
                                                    return match.challenger1_name === 'BYE' || match.challenger2_name === 'BYE';
                                                })
                                                .map((match) => {
                                                    const matchesCopy = matches;
                                                    const winnerMatches = matchesCopy
                                                        .filter((item) => {
                                                            return item.match_number === match.winner_to_num;
                                                        })
                                                        .map((item) => {
                                                            const player1Race =
                                                                match.challenger1_name !== 'BYE'
                                                                    ? match.challenger1_race_to
                                                                    : tournament.enable_stages === true && stage
                                                                    ? stage.winners_race_to
                                                                    : tournament.winners_race_to;
                                                            const player2Race =
                                                                match.challenger2_name !== 'BYE'
                                                                    ? match.challenger2_race_to
                                                                    : tournament.enable_stages === true && stage
                                                                    ? stage.winners_race_to
                                                                    : tournament.winners_race_to;

                                                            return {
                                                                id: item.id,
                                                                round: item.round,
                                                                identifier: item.identifier,
                                                                ...(match.match_number % 2 === 0 && {
                                                                    challenger2_id: match.challenger1_id,
                                                                    challenger2_name: match.challenger1_name,
                                                                    challenger2_seed: match.challenger1_seed,
                                                                    challenger2_country: match.challenger1_country,
                                                                    challenger2_score: 0,
                                                                    challenger2_race_to: player1Race,
                                                                    challenger2_skill_level: match.challenger1_skill_level,
                                                                }),
                                                                ...(match.match_number % 2 !== 0 && {
                                                                    challenger1_id: match.challenger1_id,
                                                                    challenger1_name: match.challenger1_name,
                                                                    challenger1_seed: match.challenger1_seed,
                                                                    challenger1_country: match.challenger1_country,
                                                                    challenger1_score: 0,
                                                                    challenger1_race_to: player1Race,
                                                                    challenger1_skill_level: match.challenger1_skill_level,
                                                                }),
                                                            };
                                                        });

                                                    const loserMatches = matchesCopy
                                                        .filter((item) => {
                                                            return item.match_number === match.loser_to_num;
                                                        })
                                                        .map((item) => {
                                                            return {
                                                                id: item.id,
                                                                round: item.round,
                                                                identifier: item.identifier,
                                                                ...(match.challenger2_name === 'BYE' &&
                                                                    match.match_number % 2 !== 0 && {
                                                                        challenger1_name: 'BYE',
                                                                        challenger1_seed: match.challenger2_seed,
                                                                    }),
                                                                ...(match.challenger2_name === 'BYE' &&
                                                                    match.match_number % 2 === 0 && {
                                                                        challenger2_name: 'BYE',
                                                                        challenger2_seed: match.challenger2_seed,
                                                                    }),
                                                            };
                                                        });

                                                    const current = {
                                                        ...match,
                                                        challenger1_score: 0,
                                                        challenger2_score: 0,
                                                        status: 'COMPLETED',
                                                        progress: 100,
                                                        is_bye: true,
                                                    };

                                                    return [current, winnerMatches[0], loserMatches[0]];
                                                });

                                            console.log('Bye Matches not flat: ', byeMatches);
                                            console.log('Bye matches:', _.flatten(byeMatches));

                                            const matchesToUpdate = firstRoundDraw.concat(_.flatten(byeMatches));

                                            console.log('To update:', matchesToUpdate);

                                            matchesToUpdate.map(async (match, index) => {
                                                let updatedMatch = match;

                                                // if (
                                                // 	match.challenger1_name === 'BYE' ||
                                                // 	match.challenger2_name === 'BYE'
                                                // ) {
                                                // 	updatedMatch = {
                                                // 		...match,
                                                // 		challenger1_score: 0,
                                                // 		challenger2_score: 0,
                                                // 		status: 'COMPLETED',
                                                // 		progress: 100,
                                                // 		is_bye: true
                                                // 	};
                                                // } else {
                                                // 	updatedMatch = match;
                                                // }

                                                promises.push(
                                                    props.client.mutate({
                                                        mutation: UPDATE_TOURNAMENT_BRACKET_MATCH_MUTATION,
                                                        variables: {
                                                            id: updatedMatch.id,
                                                            tournament_id: tournament.id,
                                                            changes: updatedMatch,
                                                        },
                                                        // awaitRefetchQueries: true,
                                                        // refetchQueries: [
                                                        // 	{
                                                        // 		query: GET_TOURNAMENT_BRACKET_QUERY,
                                                        // 		variables: { tournamentId: tournament.id }
                                                        // 	}
                                                        // ]
                                                    })
                                                );
                                                // console.log(res2);
                                            });

                                            Promise.all(promises)
                                                .then((values) => {
                                                    props.client
                                                        .query({
                                                            query: GET_TOURNAMENT_BRACKET_QUERY,
                                                            variables: {
                                                                tournamentId: tournament.id,
                                                            },
                                                        })
                                                        .then((res) => {
                                                            message.success({
                                                                content: 'First-round draw complete!',
                                                                key,
                                                                duration: 2,
                                                            });
                                                            // setDrawComplete(true);
                                                            // showConfirmStart(drawRound, winnerBracket);
                                                            resolve(res);
                                                        })
                                                        .catch((error) => {
                                                            // setDrawComplete(false);
                                                            console.error(error);
                                                            reject(error);
                                                        });
                                                })
                                                .catch((e) => {
                                                    console.log(e);
                                                    // setDrawComplete(false);
                                                    message.error({ content: JSON.stringify(e), key, duration: 1 });
                                                    reject(e);
                                                    // showConfirmDraw(drawRound, winnerBracket);
                                                });
                                        });
                                    }
                                });
                        });
                });
        }).catch((error) => {
            console.log(error);
            return error;
        });
    }

    function changeIntoBye(seed, participantsCount) {
        //return seed <= participantsCount ?  seed : '{0} (= bye)'.format(seed);
        return seed <= participantsCount ? seed : null;
    }

    function generateSeeds(participants, rounds, numberOfPlayersMax) {
        if (participants < 2) {
            return [];
        }

        let matches = [[1, 2]];
        var roundMatches = [];
        var seed1 = 0;
        var seed2 = 0;
        for (var playerSeedIndex = 1; playerSeedIndex <= numberOfPlayersMax; playerSeedIndex++) {
            seed1 = playerSeedIndex;
            playerSeedIndex++;
            seed2 = playerSeedIndex;
            roundMatches.push([seed1, seed2]);
        }
        matches = roundMatches;

        // let newRounds = [];

        // for (let i = rounds; i >= 1; i--) {
        // 	newRounds.push(i);
        // }

        // for (var round = 1; round < rounds; round++) {
        // 	var roundMatches = [];
        // 	var sum = Math.pow(2, round + 1) + 1;

        // 	for (var i = 0; i < matches.length; i++) {
        // 		var home = changeIntoBye(matches[i][0], participants);
        // 		var away = changeIntoBye(sum - matches[i][0], participants);
        // 		roundMatches.push([ home, away ]);
        // 		home = changeIntoBye(sum - matches[i][1], participants);
        // 		away = changeIntoBye(matches[i][1], participants);
        // 		roundMatches.push([ home, away ]);
        // 	}
        // 	matches = roundMatches;
        // }
        return matches;
    }

    function handleSetZoom(scale) {
        setZoom(scale);
    }

    function handleSetTranslation(translation) {
        setTranslation(translation);
    }

    // update bracket zoom level and position only after the movement has finished
    function handleMapZoom({ scale, translation }) {
        if (zoom !== scale) {
            handleSetZoom(scale);
        }

        handleSetTranslation(translation);
    }

    function discreteScaleStepSize() {
        const delta = Math.abs(maxScale - minScale);
        return delta / 30;
    }

    function canAccess(user) {
        if (user && user.role && (user.role === 'user' || user.role === 'admin')) {
            return true;
        } else {
            return false;
        }
    }

    async function subscribeToNewMatches(subscribeToMore) {
        console.log('subscribe to new matches');
        const unsubscribe = subscribeToMore({
            document: GET_TOURNAMENT_BRACKET_UPDATES_SUBSCRIPTION,
            variables: {
                tournamentId: tournament.id,
            },
            onSubscriptionData: (data) => {
                console.log('subscription data', data);
            },
            updateQuery: (prev, { subscriptionData }) => {
                if (!subscriptionData.data) return prev;
                // console.log(prev);
                // console.log(subscriptionData);
                const match_updates = subscriptionData.data.tournament_brackets;
                if (!match_updates || match_updates.length === 0) return prev;
                // match_updates.forEach((match) => {
                // 	const exists = [ prev.tournament_brackets ].find(({ id }) => id === match.id);
                // 	if (exists) return prev;
                // });

                const matchIndex = _.findIndex(prev.tournament_brackets, {
                    id: match_updates[0].id,
                });

                var newMatches = [...prev.tournament_brackets.slice(0, matchIndex), match_updates[0], ...prev.tournament_brackets.slice(matchIndex + 1)];

                console.log({
                    matchIndex,
                    newMatches,
                    match_updates,
                });

                const updates = Object.assign({}, prev, {
                    tournament_brackets: [...newMatches],
                });
                // console.log({
                // 	prev,
                // 	updates
                // });

                // setMatches(newMatches);

                // const brackets = buildBracket(newMatches);

                // setMatches(brackets.tournament_brackets);
                // setWinnerBracket(brackets.winnerBracket);
                // setLoserBracket(brackets.loserBracket);
                // setLoserBracketReversed(brackets.loserBracket.reverse());
                // setDrawRound(brackets.draw);

                return updates;
            },
        });

        return () => unsubscribe();
    }

    // function printDocument() {
    //     const input = document.querySelector('#bracket-container');
    //     var w = input.offsetWidth;
    //     var h = input.offsetHeight;
    //     htmlToImage.toPng(input, { quality: 0.95 }).then(function (dataUrl) {
    //         console.log('width', w);
    //         console.log('height', w);
    //         // var link = document.createElement('a');
    //         // link.download = 'my-image-name.jpeg';
    //         const pdf = new jsPDF('L', 'px', [w, h]);
    //         const imgProps = pdf.getImageProperties(dataUrl);
    //         const pdfWidth = pdf.internal.pageSize.getWidth();
    //         const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
    //         pdf.addImage(dataUrl, 'PNG', 0, 0, pdfWidth, pdfHeight);
    //         pdf.save('download.pdf');
    //     });
    // }

    function printDocument() {
        // const input = document.querySelector('#bracket-container');
        const input = document.querySelector('.bracket-surface');
        var w = input.offsetWidth;
        var h = input.offsetHeight;
        html2canvas(input, {
            dpi: 300, // Set to 300 DPI
            // scale: 3, // Adjusts your resolution
        }).then((canvas) => {
            console.log('width', w);
            console.log('height', h);
            // var link = document.createElement('a');
            // link.download = 'my-image-name.jpeg';
            // document.body.appendChild(canvas);
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF('p', 'px', [w, h]);
            pdf.addImage(imgData, 'PNG', 0, 0, w, h, undefined, 'FAST');
            // pdf.output('dataurlnewwindow');
            pdf.save('download.pdf');

            // var a = document.createElement('a');
            // // toDataURL defaults to png, so we need to request a jpeg, then convert for file download.
            // a.href = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream');
            // a.download = 'somefilename.png';
            // a.click();
        });

        // var w = document.querySelector('.bracket-surface').offsetWidth;
        // var h = document.querySelector('.bracket-surface').offsetHeight;
        // html2canvas(input, {
        //     dpi: 300, // Set to 300 DPI
        //     scale: 3, // Adjusts your resolution
        // }).then((canvas) => {
        //     document.body.appendChild(canvas);
        //     const imgData = canvas.toDataURL('image/jpeg', 1);
        //     const pdf = new jsPDF('L', 'px', [w, h]);
        //     pdf.addImage(imgData, 'JPEG', 0, 0, [w, h]);
        //     // pdf.output('dataurlnewwindow');
        //     pdf.save('download.pdf');
        // });
    }

    function renderAllRounds(brackets) {
        if (bracketLayout === 'left-right') {
            return (
                <div className="rounds">
                    <div className="b-side">
                        {brackets.loserBracketReversed.map((rounds, i) => {
                            return (
                                <React.Fragment key={i}>
                                    <ForwardRound
                                        {...props}
                                        matches={brackets.tournament_brackets}
                                        bracket={brackets.loserBracketReversed}
                                        rounds={rounds}
                                        tables={tables}
                                        i={i}
                                        onShowScoreModal={showScoreModal}
                                        onMatchSelected={handleMatchSelected}
                                    />
                                </React.Fragment>
                            );
                        })}
                    </div>
                    <div className="draw">
                        {brackets.drawRound.map((rounds, i) => {
                            return (
                                <React.Fragment key={i}>
                                    <ForwardRound
                                        {...props}
                                        matches={brackets.tournament_brackets}
                                        bracket={brackets.drawRound}
                                        rounds={rounds}
                                        tables={tables}
                                        i={i}
                                        onShowScoreModal={showScoreModal}
                                        onMatchSelected={handleMatchSelected}
                                        // ref={elementsRef.current[i]}
                                    />
                                </React.Fragment>
                            );
                        })}
                    </div>
                    <div className="a-side">
                        {brackets.winnerBracket.map((rounds, i) => {
                            return (
                                <React.Fragment key={i}>
                                    <ForwardRound
                                        {...props}
                                        matches={brackets.tournament_brackets}
                                        bracket={brackets.winnerBracket}
                                        rounds={rounds}
                                        tables={tables}
                                        i={i}
                                        onShowScoreModal={showScoreModal}
                                        onMatchSelected={handleMatchSelected}
                                    />
                                </React.Fragment>
                            );
                        })}
                    </div>
                </div>
            );
        } else {
            return (
                <div className="rounds" style={{ display: 'inline-block' }}>
                    <div style={{ display: 'flex' }}>
                        <div className="draw">
                            {brackets.drawRound.map((rounds, i) => {
                                return (
                                    <React.Fragment key={i}>
                                        <ForwardRound
                                            {...props}
                                            matches={brackets.tournament_brackets}
                                            bracket={brackets.drawRound}
                                            rounds={rounds}
                                            tables={tables}
                                            i={i}
                                            onShowScoreModal={showScoreModal}
                                            onMatchSelected={handleMatchSelected}
                                            // ref={elementsRef.current[i]}
                                        />
                                    </React.Fragment>
                                );
                            })}
                        </div>
                        <div className="a-side">
                            {brackets.winnerBracket.map((rounds, i) => {
                                return (
                                    <React.Fragment key={i}>
                                        <ForwardRound
                                            {...props}
                                            matches={brackets.tournament_brackets}
                                            bracket={brackets.winnerBracket}
                                            rounds={rounds}
                                            tables={tables}
                                            i={i}
                                            onShowScoreModal={showScoreModal}
                                            onMatchSelected={handleMatchSelected}
                                        />
                                    </React.Fragment>
                                );
                            })}
                            <Round />
                        </div>
                    </div>
                    {brackets.loserBracket && brackets.loserBracket.length > 0 && (
                        <div className={tournamentType === 'custom' ? 'a-side' : 'b-side'}>
                            {brackets.loserBracket.map((rounds, i) => {
                                return (
                                    <React.Fragment key={i}>
                                        <ForwardRound
                                            {...props}
                                            matches={brackets.tournament_brackets}
                                            bracket={brackets.loserBracket}
                                            rounds={rounds}
                                            tables={tables}
                                            i={i}
                                            onShowScoreModal={showScoreModal}
                                            onMatchSelected={handleMatchSelected}
                                        />
                                    </React.Fragment>
                                );
                            })}
                            <Round />
                        </div>
                    )}
                </div>
            );
        }
    }

    function renderWinnerSide(brackets) {
        if (bracketLayout === 'left-right') {
            return (
                <div className="rounds">
                    <div className="draw">
                        {brackets.drawRound.map((rounds, i) => {
                            return (
                                <React.Fragment key={i}>
                                    <ForwardRound
                                        {...props}
                                        matches={brackets.tournament_brackets}
                                        bracket={brackets.drawRound}
                                        rounds={rounds}
                                        tables={tables}
                                        i={i}
                                        onShowScoreModal={showScoreModal}
                                        onMatchSelected={handleMatchSelected}
                                        // ref={elementsRef.current[i]}
                                    />
                                </React.Fragment>
                            );
                        })}
                    </div>
                    <div className="a-side">
                        {brackets.winnerBracket.map((rounds, i) => {
                            return (
                                <React.Fragment key={i}>
                                    <ForwardRound
                                        {...props}
                                        matches={brackets.tournament_brackets}
                                        bracket={brackets.winnerBracket}
                                        rounds={rounds}
                                        tables={tables}
                                        i={i}
                                        onShowScoreModal={showScoreModal}
                                        onMatchSelected={handleMatchSelected}
                                    />
                                </React.Fragment>
                            );
                        })}
                    </div>
                </div>
            );
        } else {
            return (
                <div className="rounds" style={{ display: 'inline-block' }}>
                    <div style={{ display: 'flex' }}>
                        <div className="draw">
                            {brackets.drawRound.map((rounds, i) => {
                                return (
                                    <React.Fragment key={i}>
                                        <ForwardRound
                                            {...props}
                                            matches={brackets.tournament_brackets}
                                            bracket={brackets.drawRound}
                                            rounds={rounds}
                                            tables={tables}
                                            i={i}
                                            onShowScoreModal={showScoreModal}
                                            onMatchSelected={handleMatchSelected}
                                            // ref={elementsRef.current[i]}
                                        />
                                    </React.Fragment>
                                );
                            })}
                        </div>
                        <div className="a-side">
                            {brackets.winnerBracket.map((rounds, i) => {
                                return (
                                    <React.Fragment key={i}>
                                        <ForwardRound
                                            {...props}
                                            matches={brackets.tournament_brackets}
                                            bracket={brackets.winnerBracket}
                                            rounds={rounds}
                                            tables={tables}
                                            i={i}
                                            onShowScoreModal={showScoreModal}
                                            onMatchSelected={handleMatchSelected}
                                        />
                                    </React.Fragment>
                                );
                            })}
                            <Round />
                        </div>
                    </div>
                </div>
            );
        }
    }

    function renderLoserSide(brackets) {
        if (bracketLayout === 'left-right') {
            return (
                <div className="rounds">
                    <div className="b-side">
                        {brackets.loserBracketReversed.map((rounds, i) => {
                            return (
                                <React.Fragment key={i}>
                                    <ForwardRound
                                        {...props}
                                        matches={brackets.tournament_brackets}
                                        bracket={brackets.loserBracketReversed}
                                        rounds={rounds}
                                        tables={tables}
                                        i={i}
                                        onShowScoreModal={showScoreModal}
                                        onMatchSelected={handleMatchSelected}
                                    />
                                </React.Fragment>
                            );
                        })}
                    </div>
                </div>
            );
        } else {
            return (
                <div className="rounds" style={{ display: 'inline-block' }}>
                    <div className="b-side">
                        {brackets.loserBracket.map((rounds, i) => {
                            return (
                                <React.Fragment key={i}>
                                    <ForwardRound
                                        {...props}
                                        matches={brackets.tournament_brackets}
                                        bracket={brackets.loserBracket}
                                        rounds={rounds}
                                        tables={tables}
                                        i={i}
                                        onShowScoreModal={showScoreModal}
                                        onMatchSelected={handleMatchSelected}
                                    />
                                </React.Fragment>
                            );
                        })}
                        <Round />
                    </div>
                </div>
            );
        }
    }

    function renderDrawRound(brackets) {
        if (bracketLayout === 'left-right') {
            return (
                <div className="rounds">
                    <div className="draw">
                        {brackets.drawRound.map((rounds, i) => {
                            return (
                                <React.Fragment key={i}>
                                    <ForwardRound
                                        {...props}
                                        matches={brackets.tournament_brackets}
                                        bracket={brackets.drawRound}
                                        rounds={rounds}
                                        tables={tables}
                                        i={i}
                                        onShowScoreModal={showScoreModal}
                                        onMatchSelected={handleMatchSelected}
                                        // ref={elementsRef.current[i]}
                                    />
                                </React.Fragment>
                            );
                        })}
                    </div>
                    {/* <div className="a-side">
                        {brackets.winnerBracket.slice(0, 1).map((rounds, i) => {
                            return (
                                <React.Fragment key={i}>
                                    <ForwardRound
                                        {...props}
                                        matches={brackets.tournament_brackets}
                                        bracket={brackets.winnerBracket}
                                        rounds={rounds}
                                        tables={tables}
                                        i={i}
                                        onShowScoreModal={showScoreModal}
                                        onMatchSelected={handleMatchSelected}
                                    />
                                </React.Fragment>
                            );
                        })}
                    </div> */}
                </div>
            );
        } else {
            return (
                <div className="rounds" style={{ display: 'inline-block' }}>
                    <div style={{ display: 'flex' }}>
                        <div className="draw">
                            {brackets.drawRound.map((rounds, i) => {
                                return (
                                    <React.Fragment key={i}>
                                        <ForwardRound
                                            {...props}
                                            matches={brackets.tournament_brackets}
                                            bracket={brackets.drawRound}
                                            rounds={rounds}
                                            tables={tables}
                                            i={i}
                                            onShowScoreModal={showScoreModal}
                                            onMatchSelected={handleMatchSelected}
                                            // ref={elementsRef.current[i]}
                                        />
                                    </React.Fragment>
                                );
                            })}
                        </div>
                        {/* <div className="a-side">
                            {brackets.winnerBracket.slice(0, 1).map((rounds, i) => {
                                return (
                                    <React.Fragment key={i}>
                                        <ForwardRound
                                            {...props}
                                            matches={brackets.tournament_brackets}
                                            bracket={brackets.winnerBracket}
                                            rounds={rounds}
                                            tables={tables}
                                            i={i}
                                            onShowScoreModal={showScoreModal}
                                            onMatchSelected={handleMatchSelected}
                                        />
                                    </React.Fragment>
                                );
                            })}
                            <Round />
                        </div> */}
                    </div>
                </div>
            );
        }
    }

    function renderTop128(brackets) {
        if (bracketLayout === 'left-right') {
            return (
                <div className="rounds">
                    <div className="b-side">
                        {brackets.loserBracketReversed.slice(0, 11).map((rounds, i) => {
                            return (
                                <React.Fragment key={i}>
                                    <ForwardRound
                                        {...props}
                                        matches={brackets.tournament_brackets}
                                        bracket={brackets.loserBracketReversed}
                                        rounds={rounds}
                                        tables={tables}
                                        i={i}
                                        onShowScoreModal={showScoreModal}
                                        onMatchSelected={handleMatchSelected}
                                    />
                                </React.Fragment>
                            );
                        })}
                    </div>
                    {/* <div className="draw" style={{ marginLeft: 50, marginRight: 50 }}></div> */}
                    <div className="a-side">
                        {brackets.winnerBracket.slice(tournament.max_players >= 64 ? -7 : -8).map((rounds, i) => {
                            return (
                                <React.Fragment key={i}>
                                    <ForwardRound
                                        {...props}
                                        matches={brackets.tournament_brackets}
                                        bracket={brackets.winnerBracket}
                                        rounds={rounds}
                                        tables={tables}
                                        i={i}
                                        onShowScoreModal={showScoreModal}
                                        onMatchSelected={handleMatchSelected}
                                    />
                                </React.Fragment>
                            );
                        })}
                    </div>
                </div>
            );
        } else {
            return (
                <div className="rounds" style={{ display: 'inline-block' }}>
                    <div style={{ display: 'flex' }}>
                        {/* <div className="draw"></div> */}
                        <div className="a-side">
                            {brackets.winnerBracket.slice(tournament.max_players >= 64 ? -7 : -8).map((rounds, i) => {
                                return (
                                    <React.Fragment key={i}>
                                        <ForwardRound
                                            {...props}
                                            matches={brackets.tournament_brackets}
                                            bracket={brackets.winnerBracket}
                                            rounds={rounds}
                                            tables={tables}
                                            i={i}
                                            onShowScoreModal={showScoreModal}
                                            onMatchSelected={handleMatchSelected}
                                        />
                                    </React.Fragment>
                                );
                            })}
                            <Round />
                        </div>
                    </div>
                    <div className="b-side">
                        {brackets.loserBracket.slice(-11).map((rounds, i) => {
                            return (
                                <React.Fragment key={i}>
                                    <ForwardRound
                                        {...props}
                                        matches={brackets.tournament_brackets}
                                        bracket={brackets.loserBracket}
                                        rounds={rounds}
                                        tables={tables}
                                        i={i}
                                        onShowScoreModal={showScoreModal}
                                        onMatchSelected={handleMatchSelected}
                                    />
                                </React.Fragment>
                            );
                        })}
                        <Round />
                    </div>
                </div>
            );
        }
    }

    function renderTop64(brackets) {
        if (bracketLayout === 'left-right') {
            return (
                <div className="rounds">
                    <div className="b-side">
                        {brackets.loserBracketReversed.slice(0, 9).map((rounds, i) => {
                            return (
                                <React.Fragment key={i}>
                                    <ForwardRound
                                        {...props}
                                        matches={brackets.tournament_brackets}
                                        bracket={brackets.loserBracketReversed}
                                        rounds={rounds}
                                        tables={tables}
                                        i={i}
                                        onShowScoreModal={showScoreModal}
                                        onMatchSelected={handleMatchSelected}
                                    />
                                </React.Fragment>
                            );
                        })}
                    </div>
                    {/* <div className="draw" style={{ marginLeft: 50, marginRight: 50 }}></div> */}
                    <div className="a-side">
                        {brackets.winnerBracket.slice(tournament.max_players >= 64 ? -6 : -7).map((rounds, i) => {
                            return (
                                <React.Fragment key={i}>
                                    <ForwardRound
                                        {...props}
                                        matches={brackets.tournament_brackets}
                                        bracket={brackets.winnerBracket}
                                        rounds={rounds}
                                        tables={tables}
                                        i={i}
                                        onShowScoreModal={showScoreModal}
                                        onMatchSelected={handleMatchSelected}
                                    />
                                </React.Fragment>
                            );
                        })}
                    </div>
                </div>
            );
        } else {
            return (
                <div className="rounds" style={{ display: 'inline-block' }}>
                    <div style={{ display: 'flex' }}>
                        {/* <div className="draw"></div> */}
                        <div className="a-side">
                            {brackets.winnerBracket.slice(tournament.max_players >= 64 ? -6 : -7).map((rounds, i) => {
                                return (
                                    <React.Fragment key={i}>
                                        <ForwardRound
                                            {...props}
                                            matches={brackets.tournament_brackets}
                                            bracket={brackets.winnerBracket}
                                            rounds={rounds}
                                            tables={tables}
                                            i={i}
                                            onShowScoreModal={showScoreModal}
                                            onMatchSelected={handleMatchSelected}
                                        />
                                    </React.Fragment>
                                );
                            })}
                            <Round />
                        </div>
                    </div>
                    <div className="b-side">
                        {brackets.loserBracket.slice(-9).map((rounds, i) => {
                            return (
                                <React.Fragment key={i}>
                                    <ForwardRound
                                        {...props}
                                        matches={brackets.tournament_brackets}
                                        bracket={brackets.loserBracket}
                                        rounds={rounds}
                                        tables={tables}
                                        i={i}
                                        onShowScoreModal={showScoreModal}
                                        onMatchSelected={handleMatchSelected}
                                    />
                                </React.Fragment>
                            );
                        })}
                        <Round />
                    </div>
                </div>
            );
        }
    }

    function renderTop32(brackets) {
        if (bracketLayout === 'left-right') {
            return (
                <div className="rounds">
                    <div className="b-side">
                        {brackets.loserBracketReversed.slice(0, 7).map((rounds, i) => {
                            return (
                                <React.Fragment key={i}>
                                    <ForwardRound
                                        {...props}
                                        matches={brackets.tournament_brackets}
                                        bracket={brackets.loserBracketReversed}
                                        rounds={rounds}
                                        tables={tables}
                                        i={i}
                                        onShowScoreModal={showScoreModal}
                                        onMatchSelected={handleMatchSelected}
                                    />
                                </React.Fragment>
                            );
                        })}
                    </div>
                    {/* <div className="draw" style={{ marginLeft: 50, marginRight: 50 }}></div> */}
                    <div className="a-side">
                        {brackets.winnerBracket.slice(tournament.max_players >= 64 ? -5 : -6).map((rounds, i) => {
                            return (
                                <React.Fragment key={i}>
                                    <ForwardRound
                                        {...props}
                                        matches={brackets.tournament_brackets}
                                        bracket={brackets.winnerBracket}
                                        rounds={rounds}
                                        tables={tables}
                                        i={i}
                                        onShowScoreModal={showScoreModal}
                                        onMatchSelected={handleMatchSelected}
                                    />
                                </React.Fragment>
                            );
                        })}
                    </div>
                </div>
            );
        } else {
            return (
                <div className="rounds" style={{ display: 'inline-block' }}>
                    <div style={{ display: 'flex' }}>
                        {/* <div className="draw"></div> */}
                        <div className="a-side">
                            {brackets.winnerBracket.slice(tournament.max_players >= 64 ? -5 : -6).map((rounds, i) => {
                                return (
                                    <React.Fragment key={i}>
                                        <ForwardRound
                                            {...props}
                                            matches={brackets.tournament_brackets}
                                            bracket={brackets.winnerBracket}
                                            rounds={rounds}
                                            tables={tables}
                                            i={i}
                                            onShowScoreModal={showScoreModal}
                                            onMatchSelected={handleMatchSelected}
                                        />
                                    </React.Fragment>
                                );
                            })}
                            <Round />
                        </div>
                    </div>
                    <div className="b-side">
                        {brackets.loserBracket.slice(-7).map((rounds, i) => {
                            return (
                                <React.Fragment key={i}>
                                    <ForwardRound
                                        {...props}
                                        matches={brackets.tournament_brackets}
                                        bracket={brackets.loserBracket}
                                        rounds={rounds}
                                        tables={tables}
                                        i={i}
                                        onShowScoreModal={showScoreModal}
                                        onMatchSelected={handleMatchSelected}
                                    />
                                </React.Fragment>
                            );
                        })}
                        <Round />
                    </div>
                </div>
            );
        }
    }

    function renderTop16(brackets) {
        if (bracketLayout === 'left-right') {
            return (
                <div className="rounds">
                    <div className="b-side">
                        {brackets.loserBracketReversed.slice(0, 5).map((rounds, i) => {
                            return (
                                <React.Fragment key={i}>
                                    <ForwardRound
                                        {...props}
                                        matches={brackets.tournament_brackets}
                                        bracket={brackets.loserBracketReversed}
                                        rounds={rounds}
                                        tables={tables}
                                        i={i}
                                        onShowScoreModal={showScoreModal}
                                        onMatchSelected={handleMatchSelected}
                                    />
                                </React.Fragment>
                            );
                        })}
                    </div>
                    {/* <div className="draw" style={{ marginLeft: 50, marginRight: 50 }}></div> */}
                    <div className="a-side">
                        {brackets.winnerBracket.slice(tournament.max_players >= 64 ? -4 : -5).map((rounds, i) => {
                            return (
                                <React.Fragment key={i}>
                                    <ForwardRound
                                        {...props}
                                        matches={brackets.tournament_brackets}
                                        bracket={brackets.winnerBracket}
                                        rounds={rounds}
                                        tables={tables}
                                        i={i}
                                        onShowScoreModal={showScoreModal}
                                        onMatchSelected={handleMatchSelected}
                                    />
                                </React.Fragment>
                            );
                        })}
                    </div>
                </div>
            );
        } else {
            return (
                <div className="rounds" style={{ display: 'inline-block' }}>
                    <div style={{ display: 'flex' }}>
                        {/* <div className="draw"></div> */}
                        <div className="a-side">
                            {brackets.winnerBracket.slice(tournament.max_players >= 64 ? -4 : -5).map((rounds, i) => {
                                return (
                                    <React.Fragment key={i}>
                                        <ForwardRound
                                            {...props}
                                            matches={brackets.tournament_brackets}
                                            bracket={brackets.winnerBracket}
                                            rounds={rounds}
                                            tables={tables}
                                            i={i}
                                            onShowScoreModal={showScoreModal}
                                            onMatchSelected={handleMatchSelected}
                                        />
                                    </React.Fragment>
                                );
                            })}
                            <Round />
                        </div>
                    </div>
                    <div className="b-side">
                        {brackets.loserBracket.slice(-5).map((rounds, i) => {
                            return (
                                <React.Fragment key={i}>
                                    <ForwardRound
                                        {...props}
                                        matches={brackets.tournament_brackets}
                                        bracket={brackets.loserBracket}
                                        rounds={rounds}
                                        tables={tables}
                                        i={i}
                                        onShowScoreModal={showScoreModal}
                                        onMatchSelected={handleMatchSelected}
                                    />
                                </React.Fragment>
                            );
                        })}
                        <Round />
                    </div>
                </div>
            );
        }
    }

    function renderTop8(brackets) {
        if (bracketLayout === 'left-right') {
            return (
                <div className="rounds">
                    <div className="b-side">
                        {brackets.loserBracketReversed.slice(0, tournament.max_players >= 64 ? 3 : 4).map((rounds, i) => {
                            return (
                                <React.Fragment key={i}>
                                    <ForwardRound
                                        {...props}
                                        matches={brackets.tournament_brackets}
                                        bracket={brackets.loserBracketReversed}
                                        rounds={rounds}
                                        tables={tables}
                                        i={i}
                                        onShowScoreModal={showScoreModal}
                                        onMatchSelected={handleMatchSelected}
                                    />
                                </React.Fragment>
                            );
                        })}
                    </div>
                    {/* <div className="draw" style={{ marginLeft: 50, marginRight: 50 }}></div> */}
                    <div className="a-side">
                        {brackets.winnerBracket.slice(tournament.max_players >= 64 ? -3 : -4).map((rounds, i) => {
                            return (
                                <React.Fragment key={i}>
                                    <ForwardRound
                                        {...props}
                                        matches={brackets.tournament_brackets}
                                        bracket={brackets.winnerBracket}
                                        rounds={rounds}
                                        tables={tables}
                                        i={i}
                                        onShowScoreModal={showScoreModal}
                                        onMatchSelected={handleMatchSelected}
                                    />
                                </React.Fragment>
                            );
                        })}
                    </div>
                </div>
            );
        } else {
            return (
                <div className="rounds" style={{ display: 'inline-block' }}>
                    <div style={{ display: 'flex' }}>
                        {/* <div className="draw"></div> */}
                        <div className="a-side">
                            {brackets.winnerBracket.slice(tournament.max_players >= 64 ? -3 : -4).map((rounds, i) => {
                                return (
                                    <React.Fragment key={i}>
                                        <ForwardRound
                                            {...props}
                                            matches={brackets.tournament_brackets}
                                            bracket={brackets.winnerBracket}
                                            rounds={rounds}
                                            tables={tables}
                                            i={i}
                                            onShowScoreModal={showScoreModal}
                                            onMatchSelected={handleMatchSelected}
                                        />
                                    </React.Fragment>
                                );
                            })}
                            <Round />
                        </div>
                    </div>
                    <div className="b-side">
                        {brackets.loserBracket.slice(tournament.max_players >= 64 ? -3 : -4).map((rounds, i) => {
                            return (
                                <React.Fragment key={i}>
                                    <ForwardRound
                                        {...props}
                                        matches={brackets.tournament_brackets}
                                        bracket={brackets.loserBracket}
                                        rounds={rounds}
                                        tables={tables}
                                        i={i}
                                        onShowScoreModal={showScoreModal}
                                        onMatchSelected={handleMatchSelected}
                                    />
                                </React.Fragment>
                            );
                        })}
                        <Round />
                    </div>
                </div>
            );
        }
    }

    function renderBracket(brackets, matches) {
        // console.log(tables);
        // console.log(bracketLayout);
        return (
            <React.Fragment>
                {autoScrollEnabled === true && (
                    <div
                        style={{
                            marginTop: 10,
                            textAlign: 'center',
                        }}
                    >
                        <Text>&#8673; Auto scroll enabled &#8673;</Text>
                    </div>
                )}
                <div
                    style={{
                        position: viewType === 'zoom' && breakpoint === 'mobile' ? 'relative' : 'absolute',
                        // left: viewType === 'scroll' && bracketLayout === 'left-right' ? '50%' : 0,
                        // left: bracketDimensions
                        // 	? `${-bracketDimensions.x + bracketDimensions.width / 2}px`
                        // 	: 0,
                        // width: '100%',
                        // height: '100%',
                        // zoom: zoom,
                        cursor: 'move',
                        // width: `calc(100% - ${windowWidth / zoom}px)`,
                        // height: `calc(100% - ${bracketHeight / zoom}px)`,
                        // transform: `scale(${zoom})`,
                        // transform: `scale(${zoom}) translate(0,0)`,
                        // transform: `translate3d(${translation.x}px, ${translation.y}px, 0) scale(${zoom})`,
                        // transform: `translate3d(scale(${zoom})`,
                        // transform: `scale(${zoom}) translateX(calc(-50% - 300px))`,
                        // transform:
                        // 	viewType === 'zoom'
                        // 		? `translateX(calc(-50% - ${isWidthDown('xs', width)
                        // 				? 450
                        // 				: sidePanelOpen === true ? 300 : 450}px))`
                        // 		: `scale(${zoom})`,
                        // transform:
                        // 	viewType === 'scroll' && bracketLayout === 'left-right'
                        // 		? `translateX(calc(-50% - 450px)) scale(${zoom})`
                        // 		: `scale(${zoom})`,
                        transform: viewType === 'scroll' && `scale(${zoom})`,
                        transformOrigin: 'top left',
                        transition: 'all 150ms cubic-bezier(0, 0, 0.58, 1)',
                    }}
                    className={`bracket-surface noselect mobilegpu ${autoScrollEnabled === true ? 'marquee' : ''}`}
                >
                    {bracketDisplayMode === 'winners' ? (
                        <React.Fragment>{renderWinnerSide(brackets)}</React.Fragment>
                    ) : bracketDisplayMode === 'losers' ? (
                        <React.Fragment>{renderLoserSide(brackets)}</React.Fragment>
                    ) : bracketDisplayMode === 'top128' ? (
                        <React.Fragment>{renderTop128(brackets)}</React.Fragment>
                    ) : bracketDisplayMode === 'top64' ? (
                        <React.Fragment>{renderTop64(brackets)}</React.Fragment>
                    ) : bracketDisplayMode === 'top32' ? (
                        <React.Fragment>{renderTop32(brackets)}</React.Fragment>
                    ) : bracketDisplayMode === 'top16' ? (
                        <React.Fragment>{renderTop16(brackets)}</React.Fragment>
                    ) : bracketDisplayMode === 'top8' ? (
                        <React.Fragment>{renderTop8(brackets)}</React.Fragment>
                    ) : bracketDisplayMode === 'draw' ? (
                        <React.Fragment>{renderDrawRound(brackets)}</React.Fragment>
                    ) : (
                        <React.Fragment>{renderAllRounds(brackets)}</React.Fragment>
                    )}
                </div>
            </React.Fragment>
        );
    }

    function buildBracket(tournament_brackets) {
        let bracket = [];
        let roundNum = 0;

        tournament_brackets.forEach((round, i) => {
            if (i > 0 && round.round === tournament_brackets[i - 1].round) {
                bracket[roundNum].push(round);
            } else if (i === 0) {
                bracket.push([round]);
            } else if (i > 0 && round.round !== tournament_brackets[i - 1].round) {
                bracket.push([round]);
                roundNum++;
            }
        });

        const loserBracket =
            bracket &&
            bracket.filter((rounds) => {
                return rounds[0].round < 1;
            });

        const loserBracketReversed =
            bracket &&
            bracket
                .filter((rounds) => {
                    return rounds[0].round < 1;
                })
                .reverse();

        const draw =
            bracket &&
            bracket.filter((rounds) => {
                return rounds[0].round === 1;
            });

        const winnerBracket =
            bracket &&
            bracket.filter((rounds) => {
                return rounds[0].round > 1;
            });

        const output = {
            tournament_brackets,
            loserBracket,
            loserBracketReversed,
            drawRound: draw,
            winnerBracket,
        };
        console.log('output', output);
        return output;
        // console.log(tournament_brackets);
        // console.log('bracket', bracket);
        // console.log('winnerBracket', winnerBracket);
        // console.log('drawRound', draw);
    }

    function handleMatchModalOk(e) {
        // console.log(e);
        setMatchModalVisible(false);
    }

    function handleMatchModalCancel(e) {
        setMatchModalVisible(false);
    }

    function handleMatchSelected(match) {
        setCurrentMatch(match);
        setMatchModalVisible(true);
    }

    const bracketDisplayMenu = () => {
        console.log({
            selectedStage,
            tournament,
            maxPlayers,
            tournamentType,
        });

        return (
            <Menu selectedKeys={[bracketDisplayMode]} onClick={handleBracketDisplayMenuClick} theme={theme && theme.name}>
                <Menu.Item key="draw">Draw round</Menu.Item>
                {tournamentType === 'double_elimination' && <Menu.Item key="winners">Winner side</Menu.Item>}
                {tournamentType === 'double_elimination' && <Menu.Item key="losers">Loser side</Menu.Item>}
                {maxPlayers > 128 && <Menu.Item key="top128">Top 128</Menu.Item>}
                {maxPlayers > 64 && <Menu.Item key="top64">Top 64</Menu.Item>}
                {maxPlayers > 32 && <Menu.Item key="top32">Top 32</Menu.Item>}
                {maxPlayers > 16 && <Menu.Item key="top16">Top 16</Menu.Item>}
                {maxPlayers > 8 && <Menu.Item key="top8">Top 8</Menu.Item>}
                <Menu.Item key="all">Show all</Menu.Item>
            </Menu>
        );
    };

    function handleBracketDisplayMenuClick(e) {
        console.log('click', e);
        setBracketDisplayMode(e.key);
    }

    function toggleNavigation() {
        const paramsFormatted = new URLSearchParams(params);
        let url;
        if (showNavigation === false) {
            paramsFormatted.delete('navigation');
        } else {
            paramsFormatted.append('navigation', false);
        }
        url = `/tournaments/${tournamentPath}/bracket?${paramsFormatted}`;
        props.history.push(url);
    }

    function toggleTabNavigation() {
        const paramsFormatted = new URLSearchParams(params);
        let url;
        if (showTabNavigation === false) {
            paramsFormatted.delete('subnavigation');
        } else {
            paramsFormatted.append('subnavigation', false);
        }
        url = `/tournaments/${tournamentPath}/bracket?${paramsFormatted}`;
        props.history.push(url);
    }

    return (
        <Query
            query={GET_TOURNAMENT_BRACKET_QUERY}
            fetchPolicy="cache-and-network"
            notifyOnNetworkStatusChange
            awaitRefetchQueries
            variables={{ tournamentId: tournament.id }}
            onCompleted={(data, loading, error, subscribeToMore) => {
                if (!loading && !error) {
                    // const { tournament_brackets } = data;
                    // setMatches(tournament_brackets);
                    // const brackets = buildBracket(tournament_brackets);
                    // setMatches(brackets.tournament_brackets);
                    // setWinnerBracket(brackets.winnerBracket);
                    // setLoserBracket(brackets.loserBracket);
                    // setLoserBracketReversed(brackets.loserBracket.reverse());
                    // setDrawRound(brackets.draw);

                    if (data && data.tournament_brackets && data.tournament_brackets.length > 0) {
                        setRendered(true);
                        setOriginalMatches(data.tournament_brackets);
                    }

                    // console.log('data', data);

                    const stages = tournament.stages;
                    console.log('stages', stages);
                    if (stages && stages.length > 0) {
                        let defaultStage;
                        if (stageSlug) {
                            defaultStage = stages.filter((stage) => {
                                return stage.slug === stageSlug;
                            })[0];
                        } else {
                            defaultStage = stages[0];
                        }
                        console.log(defaultStage);
                        setSelectedStage(defaultStage);
                    }
                }
            }}
        >
            {({ loading, error, data, subscribeToMore, networkStatus }) => {
                if (networkStatus === 1 || loading) {
                    return (
                        <div style={styles.container}>
                            <CircularLoader />
                        </div>
                    );
                }

                if (error) return <div style={styles.container}>Error: {error.message}</div>;
                if ((data && !data.tournament_brackets) || (data && data.tournament_brackets.length === 0)) {
                    return (
                        <Row gutter={24} justify="center" type="flex" style={{ padding: '20px 40px' }}>
                            <Col xs={24}>
                                <Card>
                                    <ZeroState showImage message="No tournament bracket exists yet." style={{ margin: 30 }} {...props}>
                                        <Button
                                            type="primary"
                                            size="large"
                                            onClick={() => {
                                                props.history.push(`/tournament-builder/${tournament.slug}/edit?step=4`);
                                            }}
                                        >
                                            Set up bracket
                                        </Button>
                                    </ZeroState>
                                </Card>
                            </Col>
                        </Row>
                    );
                }

                const step = discreteScaleStepSize();
                if (rendered !== true) {
                    subscribeToNewMatches(subscribeToMore);
                }

                let multiStage = tournament && tournament.tournament_stage_format === 'multi';
                let tournamentBrackets;
                if (multiStage === true) {
                    const advanceRounds =
                        data.tournament_brackets &&
                        data.tournament_brackets
                            .filter((match) => {
                                const stageNum = 1;
                                return match.stage_number === stageNum && match.advance_to !== null;
                            })
                            .map((match) => {
                                return match.round;
                            });

                    tournamentBrackets =
                        data.tournament_brackets &&
                        data.tournament_brackets
                            .filter((match) => {
                                const stageNum = selectedStage ? selectedStage.number : 1;
                                return match.stage_number === stageNum;
                            })
                            .filter((match, index) => {
                                const stageNum = 1;
                                if (match.stage_number === stageNum) {
                                    return advanceRounds.some((value) => {
                                        if (match.identifier.includes('L')) {
                                            return value <= match.round;
                                        } else {
                                            return value >= match.round;
                                        }
                                    });
                                } else {
                                    return true;
                                }
                            });
                } else {
                    tournamentBrackets = data.tournament_brackets;
                }
                const brackets = buildBracket(tournamentBrackets);
                const matches = tournamentBrackets;
                const stages = tournament.stages;

                return (
                    <Query
                        query={GET_ALL_TOURNAMENT_TABLES_QUERY}
                        fetchPolicy="cache-and-network"
                        notifyOnNetworkStatusChange={true}
                        variables={{ tournament_id: tournament.id }}
                        onCompleted={(data, loading, error) => {
                            if (!loading && !error) {
                                const { pool_tables } = data;
                                setTables(pool_tables);

                                // if (
                                // 	pool_tables.length > 0
                                // ) {
                                // 	showConfirmDraw(drawRound, winnerBracket, matches);
                                // }
                            }
                        }}
                    >
                        {({ loading, error, data }) => {
                            return (
                                <Layout style={{ height: bracketHeight }}>
                                    {isPrint === true ? (
                                        <Content
                                            style={{ position: 'relative', backgroundColor: theme.bracket.backgroundColor }}
                                            className={
                                                bracketLayout === 'left-right'
                                                    ? `bracket-default ${theme && theme.name}`
                                                    : `bracket-compact ${theme && theme.name}`
                                            }
                                        >
                                            {renderBracket(brackets, matches, stages)}
                                        </Content>
                                    ) : (
                                        <Layout style={{ height: bracketHeight }}>
                                            {showStatusPanel === true && statusPanel !== false && tournament.status !== 'COMPLETED' && (
                                                <Sider
                                                    theme={theme.name}
                                                    width={300}
                                                    style={{
                                                        backgroundColor: theme.bracket.panels.backgroundColor,
                                                        // borderLeft: '1px solid #eee',
                                                        position: 'relative',
                                                        zIndex: 1,
                                                        boxShadow: 'rgba(0, 21, 41, 0.25) 0px 0px 10px 0px',
                                                    }}
                                                >
                                                    <Autopilot
                                                        theme={theme}
                                                        tournament={tournament}
                                                        stages={stages}
                                                        selectedStage={selectedStage}
                                                        tournamentMatches={matches}
                                                        tables={tables}
                                                        // players={players}

                                                        onShowScoreModal={showScoreModal}
                                                        showPanel={showStatusPanel}
                                                        bracketHeight={isFullScreen === true ? windowHeight : bracketHeight}
                                                        onStartTournament={() => {
                                                            if (tables.length > 0) {
                                                                showConfirmStart(brackets.drawRound, brackets.winnerBracket, matches);
                                                            } else {
                                                                message.error('You must first add tables and players before you can start the tournament');
                                                            }
                                                        }}
                                                        setShowStatusPanel={setShowStatusPanel}
                                                        subscribeToMore={subscribeToMore}
                                                        subscribeToNewData={() => {
                                                            subscribeToMore({
                                                                document: GET_TOURNAMENT_BRACKET_SUBSCRIPTION,
                                                                variables: { tournamentId: tournament.id },
                                                                updateQuery: (prev, { subscriptionData }) => {
                                                                    if (!subscriptionData.data) return prev;
                                                                    const newMatches = subscriptionData.data.tournament_brackets;
                                                                    let updated;
                                                                    if (prev) {
                                                                        updated = Object.assign({}, prev, {
                                                                            tournament_brackets: [...newMatches],
                                                                        });
                                                                    } else {
                                                                        updated = Object.assign({}, prev, {
                                                                            tournament_brackets: [...newMatches],
                                                                        });
                                                                    }
                                                                    return updated;
                                                                },
                                                            });
                                                        }}
                                                    />
                                                    <PendingMatches theme={theme} tournament={tournament} tables={tables} />
                                                </Sider>
                                            )}

                                            <Content
                                                style={{ position: 'relative', backgroundColor: theme.bracket.backgroundColor }}
                                                className={
                                                    bracketLayout === 'left-right'
                                                        ? `bracket-default ${theme && theme.name}`
                                                        : `bracket-compact ${theme && theme.name}`
                                                }
                                            >
                                                {tournament.logo && (
                                                    <div
                                                        style={{
                                                            position: 'absolute',
                                                            top: '50%',
                                                            left: '50%',
                                                            transform: 'translate(-50%, -50%)',
                                                        }}
                                                    >
                                                        <img src={tournament.logo} alt="Logo" style={{ opacity: 0.05, maxWidth: 1000, maxHeight: 700 }} />
                                                    </div>
                                                )}

                                                <div
                                                    style={{
                                                        width: 'auto',
                                                        display: 'flex',
                                                        top: 10,
                                                        right: 10,
                                                        position: 'absolute',
                                                        zIndex: 1,
                                                    }}
                                                >
                                                    <Dropdown
                                                        getPopupContainer={() => document.querySelectorAll('#bracket-container .ant-layout-content')[0]}
                                                        overlay={bracketDisplayMenu}
                                                        trigger={['click']}
                                                    >
                                                        <Button type="primary">
                                                            {bracketDisplayMode === 'winners'
                                                                ? 'Winner side'
                                                                : bracketDisplayMode === 'losers'
                                                                ? 'Loser side'
                                                                : bracketDisplayMode === 'top8'
                                                                ? 'Top 8'
                                                                : bracketDisplayMode === 'top16'
                                                                ? 'Top 16'
                                                                : bracketDisplayMode === 'top32'
                                                                ? 'Top 32'
                                                                : bracketDisplayMode === 'top64'
                                                                ? 'Top 64'
                                                                : bracketDisplayMode === 'top128'
                                                                ? 'Top 128'
                                                                : bracketDisplayMode === 'draw'
                                                                ? 'Draw round'
                                                                : 'Filter rounds'}{' '}
                                                            <DownOutlined />
                                                        </Button>
                                                    </Dropdown>
                                                </div>

                                                <TournamentStageDropdown
                                                    isPublic={isPublic}
                                                    stages={stages}
                                                    selectedStage={selectedStage}
                                                    tournament={tournament}
                                                    onSelected={(stage) => {
                                                        console.log('stage', stage);
                                                        setSelectedStage(stage);
                                                    }}
                                                    {...props}
                                                />

                                                <BracketControls
                                                    {...props}
                                                    tournament={tournament}
                                                    tournamentComplete={tournament.status === 'COMPLETED'}
                                                    drawRound={brackets.drawRound}
                                                    isFullScreen={isFullScreen}
                                                    isPublic={isPublic}
                                                    zoom={zoom}
                                                    compact={compact}
                                                    toggleCompact={handleCompactToggle}
                                                    translation={translation}
                                                    toggleFullScreen={toggleFullScreen}
                                                    bracketEl={bracketEl}
                                                    sidePanelOpen={sidePanelOpen}
                                                    showStatusPanel={showStatusPanel}
                                                    onSidePanelOpen={setSidePanelOpen}
                                                    autoScrollEnabled={autoScrollEnabled}
                                                    navigationEnabled={showNavigation}
                                                    tabNavigationEnabled={showTabNavigation}
                                                    step={step}
                                                    bracketLayout={bracketLayout}
                                                    onNavigationToggle={toggleNavigation}
                                                    onTabNavigationToggle={toggleTabNavigation}
                                                    onAutoScrollToggle={() => {
                                                        if (autoScrollEnabled === true) {
                                                            setAutoScrollEnabled(false);
                                                        } else {
                                                            setAutoScrollEnabled(true);
                                                        }
                                                    }}
                                                    onBracketLayout={(layout) => {
                                                        localStorage.setItem('digitalpool__bracketLayout', layout);
                                                        setBracketLayout(layout);
                                                    }}
                                                    viewType={viewType}
                                                    onViewType={(type) => {
                                                        localStorage.setItem('digitalpool__viewType', type);
                                                        setViewType(type);
                                                    }}
                                                    onStart={() => {
                                                        showConfirmStart(brackets.drawRound, brackets.winnerBracket, matches);
                                                    }}
                                                    onUpdateMatchTimeProjections={() => {
                                                        let completedMatches = [];
                                                        let incompleteMatches = [];
                                                        matches.forEach((match) => {
                                                            if (match.status === 'COMPLETED') {
                                                                completedMatches.push(match);
                                                            } else {
                                                                incompleteMatches.push(match);
                                                            }
                                                        });

                                                        const incompleteSorted = incompleteMatches.sort((a, b) => {
                                                            return a.round > b.round;
                                                        });

                                                        const currentRoundNum = incompleteSorted && incompleteSorted[0] && incompleteSorted[0].round;

                                                        // console.log('completed matches', completedMatches);
                                                        // console.log('incomplete matches', incompleteSorted);
                                                        // console.log('current round', currentRoundNum);

                                                        const currentRound = currentRoundNum
                                                            ? Math.sign(currentRoundNum) === 1
                                                                ? currentRoundNum + 1
                                                                : currentRoundNum - 1
                                                            : 1;

                                                        const projectionConfig = {
                                                            from_round: currentRound || 1,
                                                            number_of_tables: tables.length || 1,
                                                            use_tournament_actuals: true,
                                                            winner_side_race_to: tournament.winner_side_race_to || 7,
                                                            loser_side_race_to: tournament.loser_side_race_to || 7,
                                                            average_time_per_game: 5,
                                                        };

                                                        console.log(projectionConfig);

                                                        generateMatchTimeProjections(projectionConfig);
                                                    }}
                                                    onReset={showConfirmReset}
                                                    onShowStatusPanel={setShowStatusPanel}
                                                    onGenerateDraw={(random) => {
                                                        generateDraw(brackets.drawRound, brackets.winnerBracket, matches, random);
                                                    }}
                                                    onZoom={handleSetZoom}
                                                    onMove={handleSetTranslation}
                                                />

                                                {breakpoint === 'mobile' && viewType === 'scroll' ? (
                                                    <ScrollContainer
                                                        id="bracket"
                                                        className="scroll-container"
                                                        vertical={true}
                                                        horizontal={true}
                                                        hideScrollbars={false}
                                                        activationDistance={1}
                                                        ignoreElements=".prevent-drag-scroll"
                                                        nativeMobileScroll={true}
                                                        style={{
                                                            height: bracketHeight,
                                                            width: '100%',
                                                            position: 'absolute',
                                                            zIndex: 0,
                                                            // position: 'relative'
                                                            // background: 'linear-gradient( #fafafa, #f0f2f5)'
                                                        }}
                                                        ref={bracketEl}
                                                    >
                                                        {renderBracket(brackets, matches)}
                                                    </ScrollContainer>
                                                ) : breakpoint === 'mobile' && viewType === 'zoom' ? (
                                                    <MapInteractionCSS
                                                        scale={zoom}
                                                        // translation={translation}
                                                        // onChange={({ scale, translation }) => {
                                                        // 	setZoom(scale);
                                                        // 	setTranslation(translation);
                                                        // }}
                                                        defaultScale={1}
                                                        // defaultTranslation={{ x: 0, y: 0 }}
                                                        // minScale={0.05}
                                                        // maxScale={5}
                                                        // showControls
                                                        minScale={minScale}
                                                        maxScale={maxScale}
                                                        // scale={zoom}
                                                        translation={playerSearchCoordinates}
                                                        showControls
                                                        // onChange={onMapChange}
                                                        controlsClass="zoom-buttons-container"
                                                        btnClass="zoom-buttons"
                                                        plusBtnClass="zoom-plus"
                                                        minusBtnClass="zoom-minus"
                                                        plusBtnContents={<Icon type="zoom-in" />}
                                                        minusBtnContents={<Icon type="zoom-out" />}
                                                    >
                                                        {renderBracket(brackets, matches)}
                                                    </MapInteractionCSS>
                                                ) : viewType === 'zoom' ? (
                                                    <MapInteractionCSS
                                                        scale={zoom}
                                                        // translation={translation}
                                                        // onChange={({ scale, translation }) => {
                                                        // 	setZoom(scale);
                                                        // 	setTranslation(translation);
                                                        // }}
                                                        defaultScale={1}
                                                        // defaultTranslation={{ x: 0, y: 0 }}
                                                        // minScale={0.05}
                                                        // maxScale={5}
                                                        // showControls
                                                        minScale={minScale}
                                                        maxScale={maxScale}
                                                        // scale={zoom}
                                                        translation={playerSearchCoordinates}
                                                        showControls
                                                        // onChange={onMapChange}
                                                        controlsClass="zoom-buttons-container"
                                                        btnClass="zoom-buttons"
                                                        plusBtnClass="zoom-plus"
                                                        minusBtnClass="zoom-minus"
                                                        plusBtnContents={<Icon type="zoom-in" />}
                                                        minusBtnContents={<Icon type="zoom-out" />}
                                                    >
                                                        {renderBracket(brackets, matches)}
                                                    </MapInteractionCSS>
                                                ) : (
                                                    <ScrollContainer
                                                        id="bracket"
                                                        className="scroll-container"
                                                        vertical={true}
                                                        horizontal={true}
                                                        hideScrollbars={false}
                                                        activationDistance={1}
                                                        ignoreElements=".prevent-drag-scroll"
                                                        nativeMobileScroll={true}
                                                        style={{
                                                            height: '100%',
                                                            width: '100%',
                                                            position: 'absolute',
                                                            zIndex: 0,
                                                            // position: 'relative'
                                                            // background: 'linear-gradient( #fafafa, #f0f2f5)'
                                                        }}
                                                        ref={bracketEl}
                                                    >
                                                        {renderBracket(brackets, matches)}
                                                    </ScrollContainer>
                                                )}
                                            </Content>
                                            {authState && tournament.status !== 'COMPLETED' && (
                                                <React.Fragment>
                                                    <MatchOverlay
                                                        {...props}
                                                        isFullScreen={isFullScreen}
                                                        // players={players}
                                                        matches={matches}
                                                        stage={selectedStage}
                                                        stages={stages}
                                                        originalMatches={originalMatches}
                                                        currentRound={currentRound}
                                                        currentMatch={currentMatch}
                                                        modalScoreVisible={modalScoreVisible}
                                                        onModalScoreVisible={setModalScoreVisible}
                                                        onTournamentComplete={(winner) => {
                                                            completeTournament(winner);
                                                        }}
                                                    />
                                                    <SetRoundTimeModal
                                                        tournament={tournament}
                                                        currentRound={currentRound}
                                                        stage={selectedStage}
                                                        matches={matches}
                                                        tables={tables}
                                                        onModalOk={handleRoundTimeModalOk}
                                                        onModalCancel={handleRoundTimeModalCancel}
                                                        modalVisible={roundTimeModalVisible}
                                                        {...props}
                                                    />
                                                </React.Fragment>
                                            )}

                                            {currentMatch && (
                                                <MatchInfoModal
                                                    {...props}
                                                    tournament={tournament}
                                                    tournamentID={tournament.id}
                                                    currentMatch={currentMatch}
                                                    modalVisible={matchModalVisible}
                                                    onMatchModalOk={handleMatchModalOk}
                                                    onMatchModalCancel={handleMatchModalCancel}
                                                />
                                            )}

                                            {sidePanelOpen === true && tablesPanel !== false && tournament.status !== 'COMPLETED' && canAccess(authState.user) && (
                                                <Sider
                                                    theme={theme.name}
                                                    width={300}
                                                    style={{
                                                        backgroundColor: theme.bracket.panels.backgroundColor,
                                                        // borderLeft: '1px solid #eee',
                                                        boxShadow: 'rgba(0, 21, 41, 0.25) 0px 0px 10px 0px',
                                                    }}
                                                >
                                                    <SidePanel
                                                        theme={theme}
                                                        tournament={tournament}
                                                        matches={matches}
                                                        onSidePanelOpen={() => {
                                                            setSidePanelOpen(false);
                                                        }}
                                                        onShowScoreModal={showScoreModal}
                                                        scrollToTable={() => {
                                                            // const nextHeights = elementsRef.current.map(
                                                            // 	(ref) => ref.current.getBoundingClientRect().height
                                                            // );
                                                            // console.log(nextHeights);
                                                            // scrollToComponent(this[`winnerRound1_ref`], {
                                                            // 	offset: 1000,
                                                            // 	align: 'top',
                                                            // 	duration: 1500
                                                            // });
                                                        }}
                                                        bracketHeight={isFullScreen === true ? windowHeight : bracketHeight}
                                                    />
                                                </Sider>
                                            )}
                                            {/* {isPublic === true && (
                                                <React.Fragment>
                                                    {breakpoint === 'desktop' ? (
                                                        <div style={{ position: 'absolute', right: 10, bottom: 10, width: 728, height: 90 }}>
                                                            <BracketSponsorAds device="desktop" section="bracket" {...props} />
                                                        </div>
                                                    ) : breakpoint === 'tablet' ? (
                                                        <div
                                                            style={{
                                                                position: 'absolute',
                                                                left: '50%',
                                                                bottom: 0,
                                                                width: '100%',
                                                                height: 90,
                                                                transform: 'translateX(-50%)',
                                                            }}
                                                        >
                                                            <BracketSponsorAds device="tablet" section="bracket" {...props} />
                                                        </div>
                                                    ) : (
                                                        <div
                                                            style={{
                                                                position: 'absolute',
                                                                left: '50%',
                                                                bottom: 0,
                                                                width: '100%',
                                                                height: 100,
                                                                transform: 'translateX(-50%)',
                                                            }}
                                                        >
                                                            <BracketSponsorAds device="mobile" section="bracket" {...props} />
                                                        </div>
                                                    )}
                                                </React.Fragment>
                                            )} */}
                                        </Layout>
                                    )}
                                </Layout>
                            );
                        }}
                    </Query>
                );
            }}
        </Query>
    );
}

export default React.memo(Bracket);
