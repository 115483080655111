import { PageHeader, Tag, Icon, Tabs, Button, Typography, Row, Col } from 'antd';
import React, { useState, useEffect } from 'react';
import TBPayouts from './TBPayouts';
import TBPoints from './TBPoints';
import TournamentProgressBar from './TournamentProgressBar';
import TBManageButtons from './TBManageButtons';
import SectionHeader from '../../components/SectionHeader';
import SectionContent from '../../components/SectionContent';
import SectionWrapper from '../../components/SectionWrapper';
import SectionFooter from '../../components/SectionFooter';

const { TabPane } = Tabs;

const styles = {
    row: {
        display: 'flex',
        flexGrow: 1,
    },
    tab: {
        padding: '10px 30px',
    },
};

export default function PointsPayoutsContainer(props) {
    const { tournament } = props;

    return (
        <SectionWrapper className="tables">
            <SectionHeader
                title={props.title}
                titleStyle={{ color: '#fff', margin: 0, textTransform: 'uppercase' }}
                subtitle={tournament.name}
                subtitlePrefix={true}
                subtitleStyle={{
                    color: 'rgba(255,255,255,0.8)',
                    textTransform: 'uppercase',
                    fontWeight: 600,
                    fontSize: 12,
                    letterSpacing: 3,
                }}
                height={90}
                theme="tournamentBuilder"
            >
                <TBManageButtons page="overview" {...props} />
            </SectionHeader>
            {/* <TournamentProgressBar {...props} /> */}
            <SectionContent padding="0px">
                <Tabs
                    animated={{ inkBar: true, tabPane: false }}
                    defaultActiveKey="all"
                    tabBarStyle={{
                        textTransform: 'uppercase',
                        paddingLeft: 40,
                        paddingRight: 40,
                    }}
                >
                    <TabPane tab="Payouts" key="payouts" style={styles.tab}>
                        <Row gutter={24} justify="center" type="flex">
                            <Col xs={24}>
                                <TBPayouts {...props} />
                            </Col>
                        </Row>
                    </TabPane>
                    <TabPane tab="Points" key="points" style={styles.tab}>
                        <Row gutter={24} justify="center" type="flex">
                            <Col xs={24}>
                                <TBPoints {...props} />
                            </Col>
                        </Row>
                    </TabPane>
                </Tabs>
            </SectionContent>
            <SectionFooter {...props} />
        </SectionWrapper>
    );
}
