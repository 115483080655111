import React, { useState, useEffect, useLayoutEffect, useRef, createRef } from 'react';
import { withRouter } from 'react-router-dom';
import Round from './Round';
import ScrollContainer from 'react-indiana-drag-scroll';
import { Button, Icon, Typography, Layout } from 'antd';
import useBreakpoint from 'use-breakpoint';
import { defaultBreakpoints } from '../../../../utils/defaultBreakpoints';
import { MapInteractionCSS } from 'react-map-interaction';
import _ from 'lodash';
import './bracket.css';
import './bracket-compact.css';
import dpLogoHeader from '../../../../assets/img/dp-logo-header.png';

const { Title } = Typography;
const { Content, Header } = Layout;

// http://udaikapila165.blogspot.com/2010/12/double-elimination-how-many-rounds.html
function BracketStatic(props) {
    const {
        tournament_brackets,
        isPublic,
        authState,
        tournament,
        stage,
        isFullScreen,
        onFullScreen,
        tablesPanel,
        statusPanel,
        compact,
        // size,
        height,
        navType,
        playerSearchCoordinates,
        onUpdateCoordinates,
        theme,
    } = props;

    const { breakpoint, maxWidth, minWidth } = useBreakpoint(defaultBreakpoints);
    const viewToggle = localStorage.getItem('digitalpool__viewType');
    const bracketLayoutToggle = localStorage.getItem('digitalpool__bracketLayout');
    const [bracketLayout, setBracketLayout] = useState(bracketLayoutToggle || 'left-right');

    const [viewType, setViewType] = useState(breakpoint === 'mobile' ? 'scroll' : viewToggle || 'zoom');
    const [rendered, setRendered] = useState(false);
    const [zoom, setZoom] = useState(breakpoint === 'mobile' ? 0.7 : 0.7);
    const [translation, setTranslation] = useState({ x: 0, y: 0 });
    const [modalScoreVisible, setModalScoreVisible] = useState(false);
    const [currentMatch, setCurrentMatch] = useState();
    const showNavigation = true;
    const bracketEl = useRef(null);
    let minScale = 0.1;
    let maxScale = 3;

    const windowHeight = window.innerHeight;
    const headerEl = document.querySelector('.header');
    const headerNarrowEl = document.querySelector('.header-narrow');
    const footerEl = document.querySelector('.footer');
    const sectionHeaderEl = document.querySelector('.section-header');
    const progressBarEl = document.querySelector('.tournament-progress-bar');

    let bracketHeight;
    let headerHeight = isFullScreen === true || showNavigation === false ? 0 : sectionHeaderEl && sectionHeaderEl.offsetHeight;
    let headerNarrowHeight = headerNarrowEl && headerNarrowEl.offsetHeight;
    let progressBarHeight = progressBarEl && progressBarEl.offsetHeight;
    let footerHeight = footerEl && footerEl.offsetHeight;
    const ForwardRound = React.forwardRef((props, ref) => (
        <Round {...props} theme={theme} compact={compact} innerRef={ref} onUpdateCoordinates={onUpdateCoordinates} />
    ));

    if (isPublic === true) {
        const spacer = navType === 'mobile' ? 100 : 80;
        bracketHeight = windowHeight - (headerHeight + footerHeight + spacer);
    } else if (isFullScreen === false && headerNarrowEl) {
        bracketHeight = windowHeight - (headerHeight + progressBarHeight + footerHeight + headerNarrowHeight);
    } else if (isFullScreen === false && headerEl) {
        bracketHeight = windowHeight - (headerHeight + progressBarHeight + footerHeight + headerEl.offsetHeight);
    } else if (isFullScreen === true) {
        bracketHeight = windowHeight - headerNarrowHeight;
    } else {
        bracketHeight = windowHeight - (headerHeight + progressBarHeight + footerHeight);
    }

    if (breakpoint === 'mobile') {
        bracketHeight = bracketHeight + 80;
    }

    useLayoutEffect(() => {
        if (rendered === true) {
            function getOffset(el) {
                var _x = 0;
                var _y = 0;
                while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
                    _x += el.offsetLeft - el.scrollLeft;
                    _y += el.offsetTop - el.scrollTop;
                    el = el.offsetParent;
                }
                return { top: _y, left: _x };
            }

            const identifierElement = document.getElementById('W1-1');
            const bracketSurfaceElement = document.querySelector('.bracket-surface');
            let absoluteCoordinates = getOffset(identifierElement);
            const identifierRect = identifierElement && identifierElement.getBoundingClientRect();
            const bracketRect = bracketSurfaceElement && bracketSurfaceElement.getBoundingClientRect();

            // console.log(bracketRect);
            // console.log('bracketLayout:', bracketLayout);
            // console.log('viewType:', viewType);

            let newX;
            if (viewType === 'zoom') {
                if (bracketLayout === 'up-down') {
                    if (bracketRect) {
                        newX = -absoluteCoordinates.left + document.body.offsetWidth - bracketRect.width / 2 - 40;
                    } else {
                        newX = -absoluteCoordinates.left + document.body.offsetWidth - 40;
                    }
                } else if (bracketLayout === 'left-right') {
                    newX = -absoluteCoordinates.left + document.body.offsetWidth / 2 - identifierRect.width / 2;
                }
            } else if (viewType === 'scroll') {
                handleSetZoom(1.0);
                // onUpdateZoom(1.0);
                if (bracketLayout === 'up-down') {
                    if (bracketRect) {
                        newX = -absoluteCoordinates.left + document.body.offsetWidth - bracketRect.width / 2 - 40;
                    } else {
                        newX = -absoluteCoordinates.left + document.body.offsetWidth - 40;
                    }
                    const scrollingElement = document.querySelector('.scroll-container');
                    if (scrollingElement) {
                        scrollingElement.scroll({
                            top: 0,
                            left: 0,
                            behavior: 'smooth',
                        });
                    }
                } else if (bracketLayout === 'left-right') {
                    newX = -absoluteCoordinates.left + document.body.offsetWidth / 2 - identifierRect.width / 2;

                    const scrollingElement = document.querySelector('.scroll-container');
                    if (scrollingElement) {
                        // console.log('left', identifierElement.offsetLeft);
                        scrollingElement.scroll({
                            top: 0,
                            left: document.body.offsetWidth / 2 + identifierElement.offsetLeft / 2 - identifierRect.width / 2,
                            behavior: 'smooth',
                        });
                    }
                }
            }
            const newY = 0;
            const newCoords = { x: newX, y: newY };
            // console.log(newCoords);
            if (onUpdateCoordinates) {
                onUpdateCoordinates(newCoords);
            }
        }
    }, [rendered, viewType, bracketLayout]);

    function showScoreModal(match) {
        // console.log(match);
        setCurrentMatch(match);
        setModalScoreVisible(true);
    }

    function toggleFullScreen() {
        onFullScreen(!isFullScreen);
    }

    function handleSetZoom(scale) {
        setZoom(scale);
    }

    function handleSetTranslation(translation) {
        setTranslation(translation);
    }

    // update bracket zoom level and position only after the movement has finished
    function handleMapZoom({ scale, translation }) {
        if (zoom !== scale) {
            handleSetZoom(scale);
        }

        handleSetTranslation(translation);
    }

    function discreteScaleStepSize() {
        const delta = Math.abs(maxScale - minScale);
        return delta / 30;
    }

    function renderBracket(brackets) {
        // console.log(bracketLayout);
        return (
            <div
                style={{
                    position: viewType === 'zoom' && breakpoint === 'mobile' ? 'relative' : 'absolute',
                    cursor: 'move',
                    transform: viewType === 'scroll' && `scale(${zoom})`,
                    transformOrigin: 'top left',
                    // transition: 'all 250ms ease-in-out'
                }}
                className="bracket-surface noselect mobilegpu"
            >
                {bracketLayout === 'left-right' ? (
                    <div className="rounds">
                        <div className="draw">
                            {brackets.drawRound.map((rounds, i) => {
                                return (
                                    <React.Fragment key={i}>
                                        <ForwardRound
                                            {...props}
                                            bracket={brackets.drawRound}
                                            rounds={rounds}
                                            i={i}
                                            onShowScoreModal={showScoreModal}
                                            // ref={elementsRef.current[i]}
                                        />
                                    </React.Fragment>
                                );
                            })}
                        </div>
                        <div className="a-side">
                            {brackets.winnerBracket.map((rounds, i) => {
                                return (
                                    <React.Fragment key={i}>
                                        <ForwardRound {...props} bracket={brackets.winnerBracket} rounds={rounds} i={i} onShowScoreModal={showScoreModal} />
                                    </React.Fragment>
                                );
                            })}
                        </div>
                    </div>
                ) : (
                    <div className="rounds" style={{ display: 'inline-block' }}>
                        <div style={{ display: 'flex' }}>
                            <div className="draw">
                                {brackets.drawRound.map((rounds, i) => {
                                    return (
                                        <React.Fragment key={i}>
                                            <ForwardRound
                                                {...props}
                                                bracket={brackets.drawRound}
                                                rounds={rounds}
                                                i={i}
                                                onShowScoreModal={showScoreModal}
                                                // ref={elementsRef.current[i]}
                                            />
                                        </React.Fragment>
                                    );
                                })}
                            </div>
                            <div className="a-side">
                                {brackets.winnerBracket.map((rounds, i) => {
                                    return (
                                        <React.Fragment key={i}>
                                            <ForwardRound {...props} bracket={brackets.winnerBracket} rounds={rounds} i={i} onShowScoreModal={showScoreModal} />
                                        </React.Fragment>
                                    );
                                })}
                                <Round />
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }

    function buildBracket(tournament_brackets) {
        let bracket = [];
        let roundNum = 0;

        tournament_brackets.forEach((round, i) => {
            if (i > 0 && round.round === tournament_brackets[i - 1].round) {
                bracket[roundNum].push(round);
            } else if (i === 0) {
                bracket.push([round]);
            } else if (i > 0 && round.round !== tournament_brackets[i - 1].round) {
                bracket.push([round]);
                roundNum++;
            }
        });

        const draw =
            bracket &&
            bracket.filter((rounds) => {
                return rounds[0].round === 1;
            });

        const winnerBracket =
            bracket &&
            bracket.filter((rounds) => {
                return rounds[0].round > 1;
            });

        return {
            tournament_brackets,

            drawRound: draw,
            winnerBracket,
        };

        // console.log(tournament_brackets);
        // console.log('bracket', bracket);
        // console.log('winnerBracket', winnerBracket);
        // console.log('drawRound', draw);
    }

    const step = discreteScaleStepSize();

    const brackets = buildBracket(tournament_brackets);
    const matches = tournament_brackets;

    return (
        <Layout>
            {(isFullScreen === true || showNavigation === false) && (
                <Header
                    className="header-narrow"
                    style={{
                        width: '100%',
                        paddingTop: 0,
                        paddingBottom: 0,
                        paddingLeft: 20,
                        paddingRight: 20,
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        {showNavigation !== false ? (
                            <Button
                                type="primary"
                                shape="circle"
                                onClick={toggleFullScreen}
                                style={{
                                    width: 30,
                                    height: 30,
                                    textAlign: 'center',
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                <Icon type="close" />
                            </Button>
                        ) : (
                            <Button
                                type="primary"
                                shape="circle"
                                onClick={() => {
                                    props.history.push(`/tournament-builder/${tournament.slug}/bracket`);
                                }}
                                style={{
                                    width: 30,
                                    height: 30,
                                    textAlign: 'center',
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                <Icon type="arrow-left" />
                            </Button>
                        )}

                        <Title level={4} style={{ margin: 0, color: '#fff', marginLeft: 10 }}>
                            {_.startCase(tournament.name)}
                        </Title>
                    </div>
                    <div className="logo" style={{ position: 'fixed', left: '50%', transform: 'translateX(-50%)' }}>
                        <img src={dpLogoHeader} alt="Logo" width="100%" />
                    </div>
                </Header>
            )}

            <Layout style={{ height: bracketHeight }}>
                <Content
                    style={{ position: 'relative' }}
                    className={bracketLayout === 'left-right' ? `bracket-default ${theme && theme.name}` : `bracket-compact ${theme && theme.name}`}
                >
                    {breakpoint === 'mobile' && viewType === 'scroll' ? (
                        <ScrollContainer
                            id="bracket"
                            className="scroll-container"
                            vertical={true}
                            horizontal={true}
                            hideScrollbars={false}
                            activationDistance={1}
                            ignoreElements=".prevent-drag-scroll"
                            nativeMobileScroll={true}
                            style={{
                                height: bracketHeight,
                                width: '100%',
                                position: 'relative',
                                // background: 'linear-gradient( #fafafa, #f0f2f5)'
                            }}
                            ref={bracketEl}
                        >
                            {renderBracket(brackets)}
                        </ScrollContainer>
                    ) : breakpoint === 'mobile' && viewType === 'zoom' ? (
                        <MapInteractionCSS
                            scale={zoom}
                            // translation={translation}
                            // onChange={({ scale, translation }) => {
                            // 	setZoom(scale);
                            // 	setTranslation(translation);
                            // }}
                            defaultScale={1}
                            // defaultTranslation={{ x: 0, y: 0 }}
                            // minScale={0.05}
                            // maxScale={5}
                            // showControls
                            minScale={minScale}
                            maxScale={maxScale}
                            // scale={zoom}
                            translation={playerSearchCoordinates}
                            showControls
                            // onChange={onMapChange}
                            controlsClass="zoom-buttons-container"
                            btnClass="zoom-buttons"
                            plusBtnClass="zoom-plus"
                            minusBtnClass="zoom-minus"
                            plusBtnContents={<Icon type="zoom-in" />}
                            minusBtnContents={<Icon type="zoom-out" />}
                        >
                            {renderBracket(brackets)}
                        </MapInteractionCSS>
                    ) : viewType === 'zoom' ? (
                        <MapInteractionCSS
                            scale={zoom}
                            // translation={translation}
                            // onChange={({ scale, translation }) => {
                            // 	setZoom(scale);
                            // 	setTranslation(translation);
                            // }}
                            defaultScale={1}
                            // defaultTranslation={{ x: 0, y: 0 }}
                            // minScale={0.05}
                            // maxScale={5}
                            // showControls
                            minScale={minScale}
                            maxScale={maxScale}
                            // scale={zoom}
                            translation={playerSearchCoordinates}
                            showControls
                            // onChange={onMapChange}
                            controlsClass="zoom-buttons-container"
                            btnClass="zoom-buttons"
                            plusBtnClass="zoom-plus"
                            minusBtnClass="zoom-minus"
                            plusBtnContents={<Icon type="zoom-in" />}
                            minusBtnContents={<Icon type="zoom-out" />}
                        >
                            {renderBracket(brackets)}
                        </MapInteractionCSS>
                    ) : (
                        <ScrollContainer
                            id="bracket"
                            className="scroll-container"
                            vertical={true}
                            horizontal={true}
                            hideScrollbars={false}
                            activationDistance={1}
                            ignoreElements=".prevent-drag-scroll"
                            nativeMobileScroll={true}
                            style={{
                                height: '100%',
                                width: '100%',
                                position: 'relative',
                                // background: 'linear-gradient( #fafafa, #f0f2f5)'
                            }}
                            ref={bracketEl}
                        >
                            {renderBracket(brackets)}
                        </ScrollContainer>
                    )}
                </Content>
            </Layout>
        </Layout>
    );
}

export default withRouter(BracketStatic);
