import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { Form, FormItem, Input, Radio, Select, AutoComplete, SubmitButton, ResetButton } from 'formik-antd';
import { Formik, ErrorMessage } from 'formik';
import { message, Typography, Modal, Button, Divider, Avatar, Icon } from 'antd';
import * as Yup from 'yup';
import CircularLoader from '../../components/CircularLoader';
import { Query, Mutation } from '@apollo/client/react/components';
import { CountryDropdown, CountryRegionData } from 'react-country-region-selector';
import { CREATE_VENUE_MUTATION, UPDATE_VENUE_MUTATION, LINK_LEAGUE_VENUE_MUTATION } from './data/mutations';
import { GET_LEAGUE_VENUES_QUERY, GET_VENUE_TYPES_QUERY, GET_VENUE_BY_ID_QUERY, FILTER_VENUES } from './data/queries';
import apiEndPoint from '../../utils/apiEndpoint';
import CircleFlag from '../../components/CircleFlag';
import { useMutation, useQuery } from '@apollo/react-hooks';
import slugify from 'slugify';
import _ from 'lodash';
import axios from 'axios';

const { TextArea } = Input;
const { Text } = Typography;
const { Option } = Select;

const styles = {
    error: { color: '#f5222d' },
};

function AddVenueModal(props) {
    const { client, league, modalVisible, onModalOk, onModalCancel, authState, selectedVenueId } = props;
    const [loading, setLoading] = useState(false);
    const [country, setCountry] = useState();
    const [acDataSource, setacDataSource] = useState([]);
    const [selected, setSelected] = useState();
    const [createVenue, { createLoading, createError }] = useMutation(CREATE_VENUE_MUTATION);
    const [updateVenue, { updateLoading, updateError }] = useMutation(UPDATE_VENUE_MUTATION);
    const [linkLeagueVenue, { linkLoading, linkError }] = useMutation(LINK_LEAGUE_VENUE_MUTATION);
    const formRef = useRef();

    const phoneRegExp = /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,3})|(\(?\d{2,3}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/;
    const schema = Yup.object().shape({
        name: Yup.string().max(50, 'Too Long!').required('Name is required'),
        address1: Yup.string().required('Address is required'),
        city: Yup.string().required('City is required'),
        country: Yup.string().required('Country is required'),
        region: Yup.string().required('Region is required'),
        postal_code: Yup.string().required('Postal Code is required'),
        email_address: Yup.string().email('Email is invalid'),
        website: Yup.string().url('Website must be a valid url e.g. http://www.example.com)').nullable(),
        phone_number: Yup.string().matches(phoneRegExp, 'Phone number is not valid').nullable(),
        social_facebook: Yup.string().nullable(),
        social_instagram: Yup.string().nullable(),
        social_twitter: Yup.string().nullable(),
    });

    function handleKeyPress(event) {
        console.log(event.key);
        if (event.key === 'Enter') {
            console.log('enter press here! ');
            if (formRef.current) {
                formRef.current.handleSubmit();
            }
        }
    }

    const linkVenue = (id, actions) => {
        linkLeagueVenue({
            variables: {
                objects: [
                    {
                        venue_id: id,
                        league_id: league.id,
                    },
                ],
            },
            notifyOnNetworkStatusChange: true,
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: GET_LEAGUE_VENUES_QUERY,
                    variables: { leagueId: league.id },
                },
            ],
        })
            .then((data) => {
                console.log(data);
                setLoading(false);
                setSelected();
                if (actions) {
                    actions.setSubmitting(false);
                    actions.resetForm();
                }
                message.success('Venue successfully linked');
                onModalOk();
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
                setSelected();
                if (actions) {
                    actions.setSubmitting(false);
                    actions.resetForm();
                }
                message.info('There was an error', error);
                onModalOk();
            });
    };

    function geocodeAddress(values) {
        return axios
            .post(`${apiEndPoint}mapquestGeocodeAddress`, {
                street: encodeURI(values.address1),
                city: encodeURI(values.city),
                state: encodeURI(values.region),
                postalCode: encodeURI(values.postal_code),
            })
            .then((res) => {
                const result = res && res.data && res.data.results && res.data.results[0] && res.data.results[0].locations && res.data.results[0].locations[0];
                const latLng = result.latLng;
                console.log(latLng);
                return latLng;
            })
            .catch((error) => {
                console.log(error);
                message.error(`There was an error: ${JSON.stringify(error)}`);
                return error;
            });
    }

    const submitUpdate = async (values, venue) => {
        const {
            name,
            description,
            venue_type,
            address1,
            address2,
            city,
            country,
            region,
            postal_code,
            phone_number,
            email_address,
            website,
            social_facebook,
            social_twitter,
            social_instagram,
            operating_hours,
            is_public,
            is_claimed,
            claimed_by_id,
        } = values;
        setLoading(true);
        const slug = slugify(`${name} ${city}`, {
            replacement: '-',
            remove: /[*+~.,()'"#!:/@]/g,
            lower: true,
        });

        const location = await geocodeAddress(values);
        console.log('location', location);

        updateVenue({
            variables: {
                id: selectedVenueId,
                changes: {
                    name,
                    slug,
                    description,
                    venue_type,
                    address1,
                    address2,
                    city,
                    country,
                    region,
                    postal_code,
                    phone_number,
                    email_address,
                    website,
                    social_facebook,
                    social_twitter,
                    social_instagram,
                    operating_hours,
                    lat: location && location.lat && location.lat.toString(),
                    lng: location && location.lng && location.lng.toString(),
                    ...(location && {
                        location: {
                            type: 'Point',
                            coordinates: [location.lat, location.lng],
                        },
                    }),
                    owner_id: authState.user.id,
                    is_public,
                    is_claimed,
                    claimed_by_id,
                },
            },
            notifyOnNetworkStatusChange: true,
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: GET_LEAGUE_VENUES_QUERY,
                    variables: { leagueId: league.id },
                },
            ],
        })
            .then((data) => {
                console.log(data);
                setLoading(false);
                message.success('Changes saved');
                setSelected();
                onModalOk();
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
                message.info('There was an error', error);
                setSelected();
                onModalOk();
            });
    };

    const submitAdd = async (values, actions) => {
        const {
            name,
            description,
            venue_type,
            address1,
            address2,
            city,
            country,
            region,
            postal_code,
            phone_number,
            email_address,
            website,
            social_facebook,
            social_twitter,
            social_instagram,
            operating_hours,
            is_public,
            is_claimed,
            claimed_by_id,
        } = values;
        setLoading(true);
        let slug;
        if (name && city) {
            slug = slugify(`${name} ${city}`, {
                replacement: '-',
                remove: /[*+~.,()'"#!:/@]/g,
                lower: true,
            });
        } else {
            slug = slugify(name, {
                replacement: '-',
                remove: /[*+~.,()'"#!:/@]/g,
                lower: true,
            });
        }

        const location = await geocodeAddress(values);
        console.log('location', location);

        createVenue({
            variables: {
                objects: [
                    {
                        name,
                        description,
                        slug: slug,
                        venue_type,
                        address1,
                        address2,
                        city,
                        country,
                        region,
                        postal_code,
                        phone_number,
                        email_address,
                        website,
                        social_facebook,
                        social_twitter,
                        social_instagram,
                        operating_hours,
                        lat: location && location.lat && location.lat.toString(),
                        lng: location && location.lng && location.lng.toString(),
                        ...(location && {
                            location: {
                                type: 'Point',
                                coordinates: [location.lat, location.lng],
                            },
                        }),
                        owner_id: authState.user.id,
                        is_public,
                        league_venues: {
                            data: {
                                league_id: league.id,
                            },
                        },
                        // is_claimed: is_claimed,
                        // claimed_by_id: claimed_by_id
                    },
                ],
            },
            notifyOnNetworkStatusChange: true,
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: GET_LEAGUE_VENUES_QUERY,
                    variables: { leagueId: league.id },
                },
            ],
        })
            .then((data) => {
                console.log(data);
                setLoading(false);
                if (actions) {
                    actions.setSubmitting(false);
                    actions.resetForm();
                }
                setSelected();
                message.success('Venue successfully added');
                onModalOk();
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
                if (actions) {
                    actions.setSubmitting(false);
                    actions.resetForm();
                }
                setSelected();
                message.info('There was an error', error);
                onModalOk();
            });
    };

    async function queryDB(name) {
        setacDataSource([]);
        var dataSet = [];
        const res = await client.query({
            query: FILTER_VENUES,
            variables: {
                name: `%${name}%`,
            },
        });

        for (let i = 0; i < res.data.venues.length; i++) {
            let venue = res.data.venues[i];
            dataSet.push(venue);
        }
        setacDataSource(dataSet);
    }

    function isDisabled() {
        return selected && selected.id ? true : false;
    }

    const getRegions = (country) => {
        if (!country) {
            return [];
        }
        if (typeof country === 'string') {
            const regions = CountryRegionData.filter((item) => {
                return item[1] === country;
            })[0];
            country = regions;
        }
        const selectedRegion = country[2].split('|').map((regionPair) => {
            let [regionName, regionShortCode = null] = regionPair.split('~');
            return regionName;
        });
        return selectedRegion;
    };

    function selectCountry(val) {
        setCountry(val);
    }

    function getPriorityList() {
        const priorities = ['US', 'GB', 'CA'];
        let priorityList = [];
        CountryRegionData.forEach((option, index) => {
            if (_.includes(priorities, option[1])) {
                priorityList.push(option);
            }
        });
        return [...priorityList];
    }

    function getCountryData() {
        const priorities = ['US', 'GB', 'CA'];
        let priorityList = [];
        let newList = [];
        CountryRegionData.forEach((option, index) => {
            if (_.includes(priorities, option[1])) {
                priorityList.push(option);
            }
        });

        newList = [...priorityList, ...CountryRegionData];
        return newList;
    }

    function onSearch(name) {
        queryDB(name);
    }

    function onSelect(value, option) {
        console.log(value, option);
        const venue = acDataSource.filter((item) => item.name === value);
        if (venue) {
            setSelected(venue[0]);
            console.log(venue[0]);
            formRef.current.setValues(venue[0]);
        } else {
            setSelected();
        }
    }

    function onAutoCompleteChange(value) {
        console.log(value);
        // if (!value) {
        // 	setSelected();
        // }
    }

    const venueMutations = (data, error) => {
        const venue = data && data.venues && data.venues.length > 0 && data.venues[0];
        const initialValues = venue
            ? {
                  ...venue,
              }
            : {
                  name: '',
                  description: '',
                  venue_type: undefined,
                  address1: '',
                  address2: '',
                  city: '',
                  country: undefined,
                  region: undefined,
                  postal_code: '',
                  phone_number: '',
                  email_address: '',
                  website: '',
                  operating_hours: '',
                  social_facebook: '',
                  social_twitter: '',
                  social_instagram: '',
                  is_public: true,
                  is_claimed: false,
                  owner_id: '',
                  claimed_by_id: '',
              };

        return (
            <React.Fragment>
                {loading && (
                    <div style={styles.container}>
                        <CircularLoader />
                    </div>
                )}
                {error && <div style={styles.container}>Error: {error.message}</div>}

                {!error && (
                    <Formik
                        ref={formRef}
                        enableReinitialize
                        initialValues={initialValues}
                        validationSchema={schema}
                        validate={(values) => {
                            // console.log(values);
                        }}
                        onSubmit={(values, actions) => {
                            const merged = {
                                ...values,
                                ...selected,
                            };
                            if (selectedVenueId) {
                                submitUpdate(merged, venue);
                            } else if (selected && selected.id) {
                                linkVenue(selected.id, actions);
                            } else {
                                submitAdd(merged, actions);
                            }
                        }}
                        render={({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                            <Modal
                                title={selectedVenueId ? 'Update Venue' : 'Add Venue'}
                                // getContainer={() => document.querySelector('.tables')}
                                visible={modalVisible}
                                onOk={onModalOk}
                                onCancel={onModalCancel}
                                centered
                                transitionName="fade"
                                // transitionName="none"
                                maskTransitionName="none"
                                // footer={null}
                                footer={[
                                    <Button key="cancel" onClick={onModalCancel}>
                                        Cancel
                                    </Button>,
                                    <Button
                                        key="submit"
                                        disabled={Object.keys(errors).length > 0 ? true : false}
                                        type={Object.keys(errors).length > 0 ? 'danger' : 'primary'}
                                        loading={loading}
                                        onClick={() => {
                                            if (formRef.current) {
                                                formRef.current.handleSubmit();
                                            }
                                        }}
                                    >
                                        {selectedVenueId
                                            ? loading
                                                ? 'Updating...'
                                                : 'Update'
                                            : selected && selected.id
                                            ? 'Link Existing'
                                            : loading
                                            ? 'Creating...'
                                            : 'Create'}{' '}
                                        Venue
                                    </Button>,
                                ]}
                                bodyStyle={{
                                    maxHeight: 500,
                                    overflowY: 'auto',
                                    padding: '10px 20px',
                                }}
                                // style={{
                                // 	left: -120
                                // }}
                                destroyOnClose={true}
                            >
                                <Form
                                    onSubmit={handleSubmit}
                                    layout="vertical"
                                    style={{ maxWidth: 600 }}
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        const merged = {
                                            ...values,
                                            ...selected,
                                        };
                                        if (selectedVenueId) {
                                            submitUpdate(merged, venue);
                                        } else if (selected && selected.id) {
                                            linkVenue(selected.id);
                                        } else {
                                            submitAdd(merged);
                                        }
                                    }}
                                >
                                    <FormItem label="Venue Name" name="name" autoFocus required hasFeedback showValidateSuccess>
                                        <AutoComplete
                                            dataSource={acDataSource}
                                            // value={autocompleteValue}
                                            // validate={validator}
                                            style={{ width: '100%' }}
                                            onSearch={onSearch}
                                            onSelect={onSelect}
                                            // onChange={onAutoCompleteChange}
                                            size="large"
                                            name="name"
                                            placeholder="Search for a venue name"
                                            optionFilterProp="children"
                                            optionLabelProp="value"
                                            filterOption={(inputValue, option) => {
                                                return (
                                                    option.props &&
                                                    option.props.children &&
                                                    option.props.children.toString().toLowerCase().indexOf(inputValue.toString().toLowerCase()) >= 0
                                                );
                                            }}
                                        >
                                            {acDataSource &&
                                                acDataSource.map((item, index) => (
                                                    <Option key={index} value={item.name}>
                                                        {item.user_id && (
                                                            <Avatar
                                                                size={24}
                                                                style={{
                                                                    display: 'inline-block',
                                                                    marginRight: 8,
                                                                }}
                                                            >
                                                                <Icon type="user" />
                                                            </Avatar>
                                                        )}
                                                        {
                                                            /* {(item.name.match(/\"[a-zA-Z\ \-\']+\"/g)) ? item.name.replace(/\"[a-zA-Z\ \-\']+\"/g, "") : item.name} */ item.name
                                                        }
                                                    </Option>
                                                ))}
                                        </AutoComplete>
                                    </FormItem>

                                    <FormItem label="Venue Type" name="venue_type" required hasFeedback showValidateSuccess>
                                        <Query query={GET_VENUE_TYPES_QUERY} fetchPolicy="cache-and-network" notifyOnNetworkStatusChange>
                                            {({ loading, error, data }) => {
                                                if (loading) return <div>Loading...</div>;
                                                if (error) return <div>Error: {error.message}</div>;

                                                return (
                                                    <Select
                                                        showSearch
                                                        name="venue_type"
                                                        placeholder="Select a venue type"
                                                        size="large"
                                                        disabled={isDisabled()}
                                                        optionFilterProp="children"
                                                        // defaultValue="lucy"
                                                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                    >
                                                        {data.venue_types.map((item, index) => (
                                                            <Option key={index} value={item.value}>
                                                                {_.startCase(_.toLower(item.value.replace('_', ' ')))}
                                                            </Option>
                                                        ))}
                                                    </Select>
                                                );
                                            }}
                                        </Query>
                                    </FormItem>

                                    <FormItem label="Description" name="description">
                                        <TextArea name="description" rows={4} placeholder="Description" size="large" disabled={isDisabled()} />
                                    </FormItem>

                                    <FormItem label="Address 1" name="address1" required hasFeedback showValidateSuccess>
                                        <Input name="address1" placeholder="Address 1" size="large" disabled={isDisabled()} />
                                    </FormItem>
                                    <FormItem label="Address 2" name="address2">
                                        <Input name="address2" placeholder="Address 2" size="large" />
                                    </FormItem>
                                    <FormItem label="City" name="city" required hasFeedback showValidateSuccess>
                                        <Input name="city" placeholder="City" size="large" disabled={isDisabled()} />
                                    </FormItem>

                                    {/* <FormItem
																label="Country"
																name="country"
																required
																hasFeedback
																showValidateSuccess
															>
																<CountryDropdown
																	value={country}
																	onChange={(val) => selectCountry(val)}
																	style={{
																		backgroundColor: 'blue',
																		color: 'white',
																		fontSize: 20
																	}}
																	priorityOptions={[ 'US', 'GB', 'CA' ]}
																/>
															</FormItem> */}

                                    <FormItem label="Country" name="country" required hasFeedback showValidateSuccess>
                                        <Select
                                            showSearch
                                            name="country"
                                            placeholder="Please select a country"
                                            optionFilterProp="children"
                                            size="large"
                                            disabled={isDisabled()}
                                            filterOption={(input, option) => {
                                                return (
                                                    option &&
                                                    option.props &&
                                                    option.props.children &&
                                                    option.props.children.props &&
                                                    option.props.children.props.children &&
                                                    option.props.children.props.children[1] &&
                                                    option.props.children.props.children[1].toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                );
                                            }}
                                            dropdownRender={(menu) => (
                                                <React.Fragment>
                                                    <div>{menu}</div>
                                                </React.Fragment>
                                            )}
                                        >
                                            {CountryRegionData.map((option, index) => {
                                                return (
                                                    <Option value={option[1]} key={index}>
                                                        <span style={{ display: 'flex', alignItems: 'center' }}>
                                                            <span role="img" aria-label={option[0]}>
                                                                <CircleFlag country={option[1]} left={0} />
                                                            </span>

                                                            {option[0]}
                                                        </span>
                                                    </Option>
                                                );
                                            })}
                                        </Select>
                                    </FormItem>
                                    <FormItem label="Region" name="region" required hasFeedback showValidateSuccess>
                                        <Select
                                            showSearch
                                            name="region"
                                            size="large"
                                            placeholder="Please select a state/province/region"
                                            disabled={isDisabled()}
                                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                        >
                                            {getRegions(values.country).map((option, index) => (
                                                <Option value={option} key={index}>
                                                    {option}
                                                </Option>
                                            ))}
                                        </Select>
                                    </FormItem>
                                    <FormItem label="Postal Code" name="postal_code" required hasFeedback showValidateSuccess disabled={isDisabled()}>
                                        <Input name="postal_code" placeholder="Postal Code" size="large" />
                                    </FormItem>
                                    <FormItem label="Operating Hours" name="operating_hours">
                                        <Input name="operating_hours" placeholder="Monday-Friday: 10am to 5pm" size="large" disabled={isDisabled()} />
                                    </FormItem>

                                    <FormItem label="Phone Number" name="phone_number">
                                        <Input name="phone_number" placeholder="Phone Number" size="large" disabled={isDisabled()} />
                                    </FormItem>

                                    <FormItem
                                        label="Email Address"
                                        name="email_address"
                                        // required
                                        hasFeedback
                                        showValidateSuccess
                                    >
                                        <Input name="email_address" placeholder="Email Address" size="large" disabled={isDisabled()} />
                                    </FormItem>
                                    <FormItem label="Website" name="website">
                                        <Input name="website" placeholder="https://www.example.com" size="large" disabled={isDisabled()} />
                                    </FormItem>
                                    <FormItem label="Facebook Link" name="social_facebook">
                                        <Input name="social_facebook" placeholder="Facebook Link" size="large" disabled={isDisabled()} />
                                    </FormItem>
                                    <FormItem label="Instagram Link" name="social_instagram">
                                        <Input name="social_instagram" placeholder="Instagram Link" size="large" disabled={isDisabled()} />
                                    </FormItem>
                                    <FormItem label="Twitter Link" name="social_twitter">
                                        <Input name="social_twitter" placeholder="Twitter Link" size="large" disabled={isDisabled()} />
                                    </FormItem>
                                    <FormItem label="Access" name="is_public">
                                        <Radio.Group name="is_public" disabled={isDisabled()}>
                                            <Radio value={true}>Public - Anyone can view this venue.</Radio>
                                            <br />
                                            <Radio value={false}>Private - Only users who have been given access can view this venue.</Radio>
                                        </Radio.Group>
                                    </FormItem>

                                    <button type="submit" hidden />

                                    {Object.keys(errors).length > 0 && (
                                        <div>
                                            <Text style={styles.error}>Validation errors: ({Object.keys(errors).length})</Text>
                                            <ul style={{ margin: 0 }}>
                                                {Object.entries(errors).map(([key, value]) => {
                                                    return (
                                                        <li key={key} style={styles.error}>
                                                            <Text style={styles.error}>{value}</Text>
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        </div>
                                    )}

                                    {/* <FormItem>
												<SubmitButton
													size="large"
													disabled={Object.keys(errors).length > 0 ? true : false}
													type={Object.keys(errors).length > 0 ? 'danger' : 'primary'}
													loading={loading}
												>
													{props.venue ? loading ? 'Updating...' : 'Update' : 'Create'} Venue
												</SubmitButton>
												<ResetButton size="large">Clear</ResetButton>
												<div>
													<Text style={{ color: '#f5222d', marginTop: 10 }}>
														{errors.general}
													</Text>
												</div>
											</FormItem> */}
                                </Form>
                            </Modal>
                        )}
                    />
                )}
            </React.Fragment>
        );
    };

    return (
        <React.Fragment>
            {selectedVenueId ? (
                <Query
                    query={GET_VENUE_BY_ID_QUERY}
                    fetchPolicy="no-cache"
                    notifyOnNetworkStatusChange={true}
                    variables={{ id: selectedVenueId }}
                    onCompleted={async (data) => {
                        // console.log(data);
                    }}
                >
                    {({ loading, error, data }) => venueMutations(data, loading, error)}
                </Query>
            ) : (
                venueMutations()
            )}
        </React.Fragment>
    );
}

export default withRouter(AddVenueModal);
