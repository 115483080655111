import React from 'react';
import { Typography, Tag } from 'antd';
import { getGameType, displayEnumType } from '../../utils';
import useBreakpoint from 'use-breakpoint';
import { defaultBreakpoints } from '../../utils/defaultBreakpoints';
import moment from 'moment-timezone';
import momentDurationFormatSetup from 'moment-duration-format';
momentDurationFormatSetup(moment);

const { Text } = Typography;

function CenterDisplay(props) {
	const { matchData, currentFrame } = props;
	const { breakpoint, maxWidth, minWidth } = useBreakpoint(defaultBreakpoints);

	const styles = {
		cell: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			flexDirection: 'column',
			alignSelf: 'flex-start',
			margin: 0,
			padding: breakpoint === 'mobile' ? 10 : '10px 0px',
			textAlign: 'center'
		}
	};

	let duration;

	if (matchData.start_time && matchData.end_time) {
		var start = moment(matchData.start_time);
		var end = moment(matchData.end_time);
		duration = moment.duration(end.diff(start, 'milliseconds')).format('h [hrs] m [min]');
		// duration = moment.duration(end.diff(start, 'milliseconds')).format('hh:mm');
	} else {
		duration = moment
			.duration(moment.utc().diff(matchData.start_date_time, 'milliseconds'))
			.format('h [hours] m [minutes]');
		// duration = moment.duration(moment.utc().diff(matchData.start_time, 'milliseconds')).format('hh:mm');
	}

	var zone_name = moment.tz.guess();
	var timezone = moment.tz(zone_name).zoneAbbr();

	return (
		<div style={styles.cell}>
			<div style={{ marginBottom: 35 }}>
				<Tag size="large">MATCH {matchData.status}</Tag>
			</div>
			{matchData && (
				<div style={{ marginBottom: 20, display: 'flex', flexDirection: 'column' }}>
					<img alt="example" src={getGameType(matchData.game_type)} width={50} height={50} />
					<Text>{displayEnumType(matchData.game_type)}</Text>
				</div>
			)}

			<div>
				<Text style={{ fontSize: 20 }}>{matchData.table_name}</Text>
			</div>

			<div style={{ marginTop: 10 }}>
				<Text style={{ fontSize: 20 }}>{duration || '00:00'}</Text>
			</div>
			<div style={{ marginTop: 20 }}>
				<Text style={{ fontSize: 14 }}>
					{matchData.scoring_format === 'BEST_OF' ? (
						`Best of ${matchData.best_of_num} ${matchData.winner_determined_by &&
							displayEnumType(matchData.winner_determined_by.split('_')[0]).toLowerCase()}`
					) : matchData.scoring_format === 'RACE_TO' ? (
						`Race to ${matchData.race_to_num} ${matchData.winner_determined_by &&
							displayEnumType(matchData.winner_determined_by.split('_')[0]).toLowerCase()}`
					) : (
						''
					)}
				</Text>
			</div>

			<div>
				<Text style={{ fontSize: 14 }}>Current frame</Text>
			</div>
			<div>
				<Text style={{ fontSize: 40 }}>{currentFrame && currentFrame.frame_number}</Text>
			</div>
		</div>
	);
}

export default CenterDisplay;
