import React, { useState } from 'react';
import { Modal, Result, Alert, Avatar, Statistic, Empty, Icon, List, Button, Row, Col, Typography, Tabs } from 'antd';
import { withRouter, Link } from 'react-router-dom';
import Card from '../../components/Card';
import SectionTitle from '../../components/SectionTitle';
import SectionContent from '../../components/SectionContent';
import SmallStat from '../../components/SmallStat';
const { TabPane } = Tabs;
const { Text, Title } = Typography;

const styles = {
	cardContent: {
		padding: '16px 24px'
	},
	row: {
		display: 'flex',
		flexGrow: 1
	},
	tab: {
		paddingTop: 5,
		paddingLeft: 24,
		paddingRight: 24,
		paddingBottom: 24
	}
};

const notifications = [
	{
		title: 'Finish setting up your profile'
	},
	{
		title: '9-Ball match vs John Doe is starting now on table 8.'
	},
	{
		title: 'You have improved 25% this week vs. last week.'
	}
];

const schedule = [
	{
		title: '3 Amigos vs Gert & Wild Bunch',
		description: 'Bronze Division - 9-Ball Teams - Snookered Billiards - Sept 2, 2019'
	},
	{
		title: '3 Amigos vs Gert & Wild Bunch',
		description: 'Bronze Division - 9-Ball Teams - Snookered Billiards - Sept 2, 2019'
	},
	{
		title: '3 Amigos vs Gert & Wild Bunch',
		description: 'Bronze Division - 9-Ball Teams - Snookered Billiards - Sept 2, 2019'
	}
];

const divisions = [
	{
		title: 'Gold Division',
		description: '9-Ball Singles'
	},
	{
		title: 'Bronze Division',
		description: '9-Ball Teams'
	},
	{
		title: 'Silver Division',
		description: '8-Ball Singles'
	}
];

const matches = [
	{
		title: 'You vs John Doe (Upcoming)',
		description: 'Gold Division - 9-Ball Singles - Snookered Billiards - Sept 2, 2019'
	},
	{
		title: 'You vs John Doe (9-7 Win)',
		description: 'Gold Division - 9-Ball Singles - Snookered Billiards - Aug 21, 2019'
	},
	{
		title: 'You vs John Doe (4-7 Loss)',
		description: 'Gold Division - 9-Ball Singles - Snookered Billiards - Aug 15, 2019'
	}
];

function DPLDashboard(props) {
	const { authState } = props;
	const [ activeKey, setActiveKey ] = useState(null);

	const callback = (key) => {
		setActiveKey(key);
	};

	return (
		<div style={{ background: 'linear-gradient(#eaedef, #fafafa)' }}>
			<SectionTitle
				title={props.title}
				titleStyle={{ color: '#fff', margin: 0, textTransform: 'uppercase' }}
				height={120}
			/>
			<SectionContent padding="20px 40px">
				<Row gutter={16} justify="center" type="flex">
					<Col xs={24}>
						<Card bodyStyle={{ padding: 0, margin: 0 }}>
							<Alert
								message="Annual Membership Expiring Soon"
								description="Your annual membership fee of $35.00 is due in 3 days."
								banner
								closable
								closeText={
									<Button type="primary" size="large">
										Renew Now
									</Button>
								}
								style={{ borderRadius: 8, overflow: 'hidden' }}
							/>
							{/* <Row gutter={16}>
					<Col xs={24}>
						<Card>
							<div>
								{authState.user &&
								authState.user.displayName && (
									<Title level={4} style={{ margin: 0 }}>
										Welcome, {authState.user.displayName}!
									</Title>
								)}
							</div>
						</Card>
					</Col>
				</Row> */}
						</Card>
					</Col>
					<Col xs={8}>
						<Card
							title={`Notifications (${notifications.length})`}
							bodyStyle={{ padding: 0 }}
							extra={<Button type="default">View All</Button>}
						>
							<List
								className="hover-list"
								size="small"
								itemLayout="horizontal"
								dataSource={notifications}
								renderItem={(item) => (
									<List.Item>
										<List.Item.Meta
											avatar={
												<Avatar style={{ backgroundColor: '#1890ff' }} icon="bell" size={35} />
											}
											title={item.title}
											// description="Ant Design, a design language for background applications, is refined by Ant UED Team"
										/>
									</List.Item>
								)}
							/>
						</Card>
					</Col>
					<Col xs={8}>
						<Card
							title="Stats"
							bodyStyle={styles.cardContent}
							extra={<Button type="default">View All</Button>}
						>
							<Row gutter={16}>
								<Col span={12}>
									<SmallStat
										title="League Matches"
										winPercentage={65}
										trailColor="rgba(26, 135, 255, 0.2)"
										strokeColor="#2196f3"
										centered
									/>
									{/* <Statistic title="Matches won/lost" value={50} suffix="/100 (50%)" /> */}
								</Col>
								<Col span={12}>
									<SmallStat
										title="League Games"
										winPercentage={72}
										trailColor="#fff6cf"
										strokeColor="#ffcd00"
										centered
									/>
									{/* <Statistic title="Games won/lost" value={100} suffix="/200 (50%)" /> */}
								</Col>
							</Row>
						</Card>
					</Col>
					<Col xs={8}>
						<Card
							title="Current Balance"
							bodyStyle={styles.cardContent}
							extra={<Button type="default">View Billing</Button>}
						>
							<Row gutter={16}>
								<Col span={12}>
									<Statistic title="Outstanding Balance" value={35} precision={2} prefix="$" />
								</Col>
								<Col span={12}>
									<Statistic title="Available Credits" value={21} suffix="credits" />
								</Col>
							</Row>
						</Card>
					</Col>
				</Row>
				<Row>
					<Col>
						<Card bodyStyle={{ padding: 0 }}>
							<Tabs
								animated={{ inkBar: true, tabPane: false }}
								activeKey={activeKey ? activeKey : '1'}
								defaultActiveKey="1"
								onChange={callback}
								tabBarStyle={{ textTransform: 'uppercase' }}
								tabBarGutter={5}
							>
								<TabPane tab="Schedule" key="1" style={{ padding: 0, margin: 0 }}>
									<List
										size="small"
										itemLayout="horizontal"
										dataSource={schedule}
										renderItem={(item) => (
											<List.Item
												actions={[ <a key="list-loadmore-edit">View</a> ]}
												style={{ padding: '10px 16px' }}
											>
												<List.Item.Meta
													avatar={
														<Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />
													}
													title={
														<React.Fragment>
															<Link to="/notifications/1">{item.title}</Link>
														</React.Fragment>
													}
													description={item.description}
												/>
											</List.Item>
										)}
									/>
								</TabPane>
								<TabPane tab="My Divisions" key="2" style={{ padding: 0, margin: 0 }}>
									<List
										size="small"
										itemLayout="horizontal"
										dataSource={divisions}
										renderItem={(item) => (
											<List.Item
												actions={[ <a key="list-loadmore-edit">View</a> ]}
												style={{ padding: '10px 16px' }}
											>
												<List.Item.Meta
													avatar={
														<Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />
													}
													title={
														<React.Fragment>
															<Link to="/notifications/1">{item.title}</Link>
														</React.Fragment>
													}
													description={item.description}
												/>
											</List.Item>
										)}
									/>
								</TabPane>
								<TabPane tab="My Matches" key="3" style={{ padding: 0, margin: 0 }}>
									<List
										size="small"
										itemLayout="horizontal"
										dataSource={matches}
										renderItem={(item) => (
											<List.Item
												actions={[ <a key="list-loadmore-edit">View</a> ]}
												style={{ padding: '10px 16px' }}
											>
												<List.Item.Meta
													avatar={
														<Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />
													}
													title={
														<React.Fragment>
															<Link to="/notifications/1">{item.title}</Link>
														</React.Fragment>
													}
													description={item.description}
												/>
											</List.Item>
										)}
									/>
								</TabPane>
							</Tabs>
						</Card>
					</Col>
				</Row>
			</SectionContent>
		</div>
	);
}

export default withRouter(DPLDashboard);
