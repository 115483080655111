import React, { useState, useEffect, useRef } from 'react';
import { List, Tabs, Row, Col, Button, Icon, Typography, Empty, Input } from 'antd';
import TableScoreCard from '../TableScoreCard';
import TableAssignment from '../../../tournament-builder/TableAssignment2';

export default function SidePanelTables(props) {
    const { tournament, tables, onScoreModal, theme, subscribeToMore, subscribeToNewData } = props;

    useEffect(() => {
        let unsubscribe;
        if (subscribeToNewData) {
            unsubscribe = subscribeToNewData(subscribeToMore);
        }
        return () => {
            if (unsubscribe) {
                unsubscribe();
            }
        };
    }, []);

    return (
        <React.Fragment>
            {tables &&
                tables.map((table, index) => {
                    const match = table.tournament_match_table && table.tournament_match_table[0];

                    return (
                        <React.Fragment key={index}>
                            <Col
                                xs={24}
                                style={{
                                    margin: 0,
                                    paddingLeft: 8,
                                    paddingRight: 8,
                                    paddingTop: 4,
                                    paddingBottom: 4,
                                }}
                            >
                                {match && table.status === 'IN_USE' ? (
                                    <TableScoreCard
                                        {...props}
                                        key={index}
                                        label={table.label}
                                        matchData={match}
                                        table={table}
                                        tournament={tournament}
                                        status={table.status}
                                        gameType={tournament.game_type}
                                        title={match.match_number && `Match ${match.match_number}`}
                                        tableSize="small"
                                        onClick={() => onScoreModal(match)}
                                        style={{
                                            backgroundColor: 'transparent',
                                            border: theme && theme.name === 'dark' ? '1px solid rgba(255, 255, 255, 0.2)' : '1px solid rgb(238, 238, 238)',
                                            borderRadius: 4,
                                        }}
                                    />
                                ) : (
                                    <TableAssignment
                                        {...props}
                                        title={`Match ${index + 1}`}
                                        label={table.label}
                                        status={table.status}
                                        displayText={table.status}
                                        matchData={match}
                                        table={table}
                                        tableSize="small"
                                        controls={false}
                                        style={{
                                            backgroundColor: 'transparent',
                                            border: theme && theme.name === 'dark' ? '1px solid rgba(255, 255, 255, 0.2)' : '1px solid rgb(238, 238, 238)',
                                            borderRadius: 4,
                                        }}
                                        // onEdit={() => {
                                        // 	setEditModalVisible(true);
                                        // }}
                                    />
                                )}
                            </Col>
                        </React.Fragment>
                    );
                })}
        </React.Fragment>
    );
}
