import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Upload, Avatar, message } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { functions } from '../../../firebase';
import { connectFunctionsEmulator, httpsCallable } from 'firebase/functions';
import CircularLoader from '../../../components/CircularLoader';
import axios from 'axios';
import gql from 'graphql-tag';
import { UserOutlined } from '@ant-design/icons';

function PostImageUpload(props) {
    const { post } = props;
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState(false);
    const fileReader = new FileReader();

    useEffect(() => {
        if (imageUrl) {
            props.client
                .mutate({
                    mutation: gql`
                        mutation update_posts($id: Int, $imageUrl: String) {
                            update_posts(where: { id: { _eq: $id } }, _set: { image_url: $imageUrl }) {
                                affected_rows
                                returning {
                                    id
                                    slug
                                    image_url
                                }
                            }
                        }
                    `,
                    variables: {
                        id: props.post.id,
                        imageUrl: imageUrl,
                    },
                })
                .then(async (res) => {
                    const results = res.data.update_posts.returning[0];
                    console.log(results);
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    }, [imageUrl, props.post, props.client]);

    function getBase64(img, callback) {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }

    function beforeUpload(file) {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('Image must smaller than 2MB!');
        }
        return isJpgOrPng && isLt2M;
    }

    function handleChange(info) {
        if (info.file.status === 'uploading') {
            setLoading(true);
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            getBase64(info.file.originFileObj, (image) => {
                setImageUrl(image);
                setLoading(false);
            });
        }
    }

    const onChange = (info) => {
        if (!fileReader.onloadend) {
            // fileReader.onloadend = (obj) => {
            // 	setImage(obj.srcElement.result);
            // };
            // can be any other read function ( any reading function from
            // previously created instance can be used )
            const { status } = info.file;
            if (status === 'uploading') {
                setLoading(true);
                // console.log(info.file, info.fileList);
            }
            if (status === 'done') {
                setLoading(false);
                console.log(info.file, info.fileList);
                message.success(`${info.file.name} file uploaded successfully.`);
            } else if (status === 'error') {
                setLoading(false);
                message.error(`${info.file.name} file upload failed.`);
            }
            // fileReader.readAsArrayBuffer(info.file.originFileObj);
        }
    };

    const customRequest = async (option) => {
        const { onSuccess, onError, file, action, onProgress } = option;
        // const url = action;

        const data = {
            S3BucketName: 'digitalpool',
            key: `post-images/${file.name}`,
            contentType: file.type,
        };

        if (window.location.hostname.indexOf('localhost') > -1) {
            connectFunctionsEmulator(functions, 'localhost', 5001);
        }

        // Retrieve the Cloud Function
        var getPost = await httpsCallable(functions, 'uploadImageS3');
        // await new Promise((resolve) => waitUntilImageLoaded(resolve));
        // Call the cloud function
        return getPost(data)
            .then((result) => {
                // S3 pre-signed URL
                const options = {
                    onUploadProgress: (e) => {
                        onProgress({ percent: (e.loaded / e.total) * 100 });
                    },
                    headers: {
                        'Content-Type': file.type,
                        'x-amz-acl': 'public-read',
                        'x-amz-server-side-encryption': 'AES256',
                    },
                };

                const signedRequest = result.data.signedRequest;
                const imageUrl = result.data.url;
                // Run a HTTP PUT request to the pre-signed URL
                return axios
                    .put(signedRequest, file, options)
                    .then((response) => {
                        setImageUrl(imageUrl);
                        onSuccess(response.body);
                    })
                    .catch((err) => {
                        onError(err);
                    });
            })
            .catch((e) => {
                console.log(e);
                return e;
            });
    };

    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );
    return (
        <Upload name="avatar" className="avatar-uploader" customRequest={customRequest} showUploadList={false} beforeUpload={beforeUpload} onChange={onChange}>
            {loading ? (
                <CircularLoader />
            ) : (
                <React.Fragment>
                    {imageUrl || (post && post.imageUrl) ? <img src={imageUrl || (post && post.imageUrl)} alt="Post" /> : props.children}
                </React.Fragment>
            )}
        </Upload>
    );
}

export default withRouter(PostImageUpload);
