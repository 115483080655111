import React, { useState, useEffect } from 'react';
import { Row, Col, Typography, Button, Avatar, Input, Tabs, Empty, Statistic, Pagination } from 'antd';
import Card, { Meta } from '../../../components/Card';
import CircularLoader from '../../../components/CircularLoader';
import ZeroState from '../../../components/ZeroState';
import { getCountryName, displayEnumType } from '../../../utils';
import { GET_PLAYERS_QUERY, GET_PLAYER_COUNT, SEARCH_PLAYERS_QUERY } from './data/queries';
import { Query } from '@apollo/client/react/components';
import useBreakpoint from 'use-breakpoint';
import { defaultBreakpoints } from '../../../utils/defaultBreakpoints';
import CircleFlag from '../../../components/CircleFlag';
import SectionContent from '../../../components/SectionContent';
import AdZoneTemplate from '../../../components/advertising/AdZoneTemplate';
import SearchFilterPlayers from './SearchFilterPlayers';
import { useMutation, useQuery } from '@apollo/react-hooks';
import numeral from 'numeral';
import pluralize from 'pluralize';

const { Title, Text } = Typography;

const styles = {
    headerImg: {
        background: `linear-gradient(-90deg, #002eaf, #008dd0) 100% 100% no-repeat`,
        // background: `linear-gradient(0deg, rgba(0,0,0,0.2), rgba(0,0,0, 0.2)), url(${blueBg})`,
        // backgroundAttachment: 'fixed',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100%',
        // backgroundPosition: '0px -400px'

        // backgroundPosition: '0px -40px'
    },
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        // height: '100%'
    },
};

export default function AllPlayers(props) {
    const { client, authState } = props;
    const { breakpoint, maxWidth, minWidth } = useBreakpoint(defaultBreakpoints);
    const [filters, setFilters] = useState({
        venueType: 'all',
        tableSize: 'all',
        tableManufacturer: 'all',
    });
    const [searchValue, setSearchValue] = useState();
    const [searching, setSearching] = useState(false);
    const [searchResults, setSearchResults] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(100);
    const [players, setPlayers] = useState([]);
    const allPlayers = useQuery(GET_PLAYER_COUNT);
    const allPlayersCount = (allPlayers && allPlayers.data && allPlayers.data.players_aggregate && allPlayers.data.players_aggregate.aggregate.count) || 0;

    const MyPagination = ({ total, onChange, current }) => {
        return <Pagination onChange={onChange} total={total} current={currentPage} pageSize={pageSize} />;
    };

    function renderPlayerData(players) {
        if (!players && players.length === 0) {
            return <ZeroState showImage message="No players found." style={{ margin: 30 }} {...props} />;
        } else {
            return (
                <React.Fragment>
                    {players &&
                        players.map((player, index) => {
                            const properties = player.properties;
                            const fargoData = properties && properties.fargo_data;
                            let fargoParsed = {};
                            if (fargoData) {
                                fargoParsed = {
                                    city: fargoData.city,
                                    country: fargoData.country,
                                    effectiveRating: fargoData.effectiveRating,
                                    firstName: fargoData.firstName,
                                    id: fargoData.id,
                                    lastName: fargoData.lastName,
                                    location: fargoData.location,
                                    membershipId: fargoData.membershipId,
                                    readableId: fargoData.readableId,
                                    robustness: fargoData.robustness,
                                    state: fargoData.state,
                                    suffix: fargoData.suffix,
                                };
                            }

                            const playerMetadata = properties && properties['player-meta'];
                            let playerMetaParsed = {};
                            if (playerMetadata) {
                                playerMetaParsed = {
                                    birthdate: playerMetadata.Birthdate,
                                    country: playerMetadata.Country,
                                    nickname: playerMetadata.Nickname,
                                };
                            }

                            const worldRankings = player.pro_world_rankings;
                            const tournamentsPlayed =
                                player.tournament_players_aggregate &&
                                player.tournament_players_aggregate.aggregate &&
                                player.tournament_players_aggregate.aggregate.count;

                            return (
                                <Col xs={24} sm={12} md={8} lg={8} xl={6} xxl={4} key={index}>
                                    <Card
                                        hoverable
                                        cover={
                                            <React.Fragment>
                                                {/* {player.image_large && player.image_large !== '<Unknown>' ? (
                                                                    <div
                                                                        style={{
                                                                            // height: 300,
                                                                            display: 'flex',
                                                                            alignItems: 'center',
                                                                            overflow: 'hidden',
                                                                        }}
                                                                    >
                                                                        <img alt="Logo" src={player.image_large} style={{ width: '100%' }} />
                                                                    </div>
                                                                ) : (
                                                                    <div
                                                                        style={{
                                                                            overflow: 'hidden',
                                                                            backgroundRepeat: 'no-repeat',
                                                                            backgroundSize: 'cover',
                                                                        }}
                                                                    />
                                                                )} */}

                                                {player.image_small && player.image_small !== '<Unknown>' ? (
                                                    <div
                                                        style={{
                                                            // height: 300,
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            overflow: 'hidden',
                                                        }}
                                                    >
                                                        <img alt="Logo" src={player.image_small} style={{ width: '100%' }} />
                                                    </div>
                                                ) : (
                                                    <div
                                                        style={{
                                                            overflow: 'hidden',
                                                            backgroundRepeat: 'no-repeat',
                                                            backgroundSize: 'cover',
                                                        }}
                                                    />
                                                )}
                                            </React.Fragment>
                                        }
                                        onClick={() => {
                                            console.log(player);
                                            props.history.push(`/players/${player.name_slug}`);
                                        }}
                                    >
                                        <Meta
                                            title={
                                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <Title level={3} style={{ margin: 0 }}>
                                                            {player.name}
                                                        </Title>
                                                    </div>
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        {fargoParsed.country && (
                                                            <CircleFlag country={getCountryName(fargoParsed.country)} size="small" left={0} />
                                                        )}
                                                        <Text>{fargoParsed.country}</Text>
                                                    </div>
                                                </div>
                                            }
                                            description={
                                                <React.Fragment>
                                                    <div>
                                                        <Text>
                                                            {fargoParsed.city && fargoParsed.state
                                                                ? `${fargoParsed.city}, ${fargoParsed.state}`
                                                                : fargoParsed.city
                                                                ? fargoParsed.city
                                                                : fargoParsed.state
                                                                ? fargoParsed.state
                                                                : null}
                                                        </Text>
                                                    </div>
                                                    <div>
                                                        <Text>
                                                            <b>{fargoParsed.effectiveRating}</b> Fargo
                                                        </Text>{' '}
                                                        <br />
                                                        <Text>
                                                            <b>{numeral(fargoParsed.robustness).format('0,0')}</b> Robustness
                                                        </Text>
                                                        {tournamentsPlayed !== 0 && (
                                                            <React.Fragment>
                                                                <br />
                                                                <Text>
                                                                    <b>{tournamentsPlayed}</b> {pluralize('tournament', tournamentsPlayed)}
                                                                </Text>
                                                            </React.Fragment>
                                                        )}
                                                    </div>
                                                    {worldRankings && (
                                                        <table width="100%" className="info-table" style={{ marginTop: 10 }}>
                                                            <tbody>
                                                                {worldRankings.map((item, index) => (
                                                                    <tr key={index}>
                                                                        <td>{item.ranking_system.display_name}</td>
                                                                        <td>{item.ranking}</td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    )}

                                                    {/* <Text>{JSON.stringify(fargoParsed)}</Text> */}
                                                </React.Fragment>
                                            }
                                        />
                                    </Card>
                                </Col>
                            );
                        })}
                </React.Fragment>
            );
        }
    }

    async function handlePlayerSearch(value) {
        setSearchValue(value);
        if (value && value.length > 1) {
            setSearching(true);
            const res = await client.query({
                query: SEARCH_PLAYERS_QUERY,
                variables: {
                    name: `%${value}%`,
                },
            });
            setSearching(false);
            const players = res.data.players;
            console.log(players);
            setSearchResults(players);
        }
    }

    return (
        <SectionContent padding="20px 40px">
            <Row gutter={24} justify="center" type="flex">
                <Col xs={24}>
                    <AdZoneTemplate device={breakpoint || 'desktop'} {...props} />
                </Col>
                <Col xs={24}>
                    <Query
                        query={GET_PLAYERS_QUERY}
                        fetchPolicy="cache-and-network"
                        notifyOnNetworkStatusChange
                        awaitRefetchQueries
                        variables={{ limit: pageSize, offset: (currentPage - 1) * pageSize || 0 }}
                        onCompleted={(data, loading, error, subscribeToMore) => {
                            if (!loading && !error) {
                                console.log(data);
                                const players = data.players;
                                let filteredPlayers;
                                if (searchResults && searchValue) {
                                    filteredPlayers = searchResults;
                                } else {
                                    filteredPlayers = players;
                                }
                                setPlayers(filteredPlayers);
                            }
                        }}
                    >
                        {({ loading, error, data, subscribeToMore, networkStatus }) => {
                            if (loading)
                                return (
                                    <div style={styles.container}>
                                        <CircularLoader />
                                        <Text style={{ marginLeft: 10 }}>Loading...</Text>
                                    </div>
                                );
                            if (error) return <div style={styles.container}>Error: {error.message}</div>;
                            if ((data && !data.players) || (data && data.players.length === 0)) {
                                return (
                                    <Card>
                                        <ZeroState message="No players found." />
                                    </Card>
                                );
                            }

                            console.log('players', players);
                            console.log({
                                searchValue,
                                searchResults,
                            });

                            return (
                                <React.Fragment>
                                    <div
                                        style={{
                                            // background: 'linear-gradient( #fff, #fafafa)',
                                            // boxShadow: 'rgba(0, 0, 0, 0.05) 0px 4px 6px 0px',

                                            // borderRadius: 10,
                                            // overflow: 'hidden',
                                            marginTop: 15,
                                        }}
                                    >
                                        <SearchFilterPlayers
                                            placeholder="Search all players"
                                            players={searchResults && searchValue ? searchResults : data.players}
                                            searching={searching}
                                            onSearchPlayer={handlePlayerSearch}
                                            {...props}
                                        />
                                    </div>

                                    <Row gutter={16} justify="center" type="flex">
                                        {renderPlayerData(searchResults && searchValue ? searchResults : players)}
                                    </Row>
                                </React.Fragment>
                            );
                        }}
                    </Query>
                </Col>
                <Col xs={24} align="center">
                    <MyPagination
                        total={allPlayersCount}
                        current={currentPage}
                        onChange={(page) => {
                            client
                                .query({
                                    query: GET_PLAYERS_QUERY,
                                    variables: {
                                        limit: pageSize,
                                        offset: (currentPage - 1) * pageSize || 0,
                                    },
                                })
                                .then((res) => {
                                    const updatedPlayers = res && res.data && res.data.players;
                                    if (updatedPlayers) {
                                        let filteredPlayers;
                                        if (searchResults && searchValue) {
                                            filteredPlayers = searchResults;
                                        } else {
                                            filteredPlayers = updatedPlayers;
                                        }
                                        setPlayers(filteredPlayers);
                                        setCurrentPage(page);
                                    }
                                })
                                .catch((error) => {
                                    console.error(error);
                                    throw error;
                                });
                        }}
                    />
                </Col>
            </Row>
        </SectionContent>
    );
}
