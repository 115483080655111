import React, { useState, useEffect } from 'react';
import { Link, withRouter, Route } from 'react-router-dom';
import { Popconfirm, Modal, message, Layout, Row, Col, Steps, Empty, Alert, Typography, Button, Icon, Result } from 'antd';
import Card from '../../components/Card';
import SectionHeader from '../../components/SectionHeader';
import SectionContent from '../../components/SectionContent';
import SectionWrapper from '../../components/SectionWrapper';
import SectionFooter from '../../components/SectionFooter';
import TBCreateAdvancedTournamentForm from './TBCreateTournamentForm';
import qs from 'query-string';

const { Step } = Steps;

const styles = {};

function TBCreateAdvancedTournament(props) {
    const params = qs.parse(props.location.search, { ignoreQueryPrefix: true });
    const step = params && params.step && params.step - 1;
    const [currentStep, setCurrentStep] = useState(step || 0);

    function onChange(current) {
        setCurrentStep(current);
    }

    return (
        <SectionWrapper>
            <SectionHeader
                title="Advanced Tournament Setup"
                titleStyle={{
                    color: '#fff',
                    textTransform: 'uppercase',
                    margin: 0,
                    display: 'flex',
                }}
                height={120}
                backButton={
                    <Button
                        onClick={() =>
                            props.match.params.slug
                                ? props.history.push(`/tournament-builder/${props.match.params.slug}/dashboard`)
                                : props.history.push(`/tournament-builder`)
                        }
                        type="ghost"
                        shape="circle"
                        icon="arrow-left"
                        size="large"
                        ghost
                        style={{ border: 'none' }}
                    />
                }
                subtitle="Configure your tournament in a few simple steps."
                subtitleStyle={{ color: '#fff' }}
                theme="tournamentBuilder"
            />
            <SectionContent padding="20px 40px">
                <Row gutter={24} justify="center" type="flex">
                    <Col xs={24}>
                        <Card headStyle={styles.cardHeader} bodyStyle={{ padding: 0 }}>
                            <Row gutter={0} justify="start" type="flex">
                                <Col xs={24} sm={6} md={4}>
                                    <Steps
                                        direction="vertical"
                                        current={currentStep}
                                        onChange={onChange}
                                        style={{
                                            marginBottom: 20,
                                            paddingTop: 20,
                                            paddingLeft: 20,
                                            paddingRight: 20,
                                        }}
                                    >
                                        <Step title="Multi-Stage Settings" />
                                        <Step title="Settings" />
                                        <Step title={props.match.params.slug ? 'Edit Players' : 'Add Players'} />
                                        <Step title={props.match.params.slug ? 'Edit Tables' : 'Add Tables'} />
                                        <Step title="Upload Flyer" />
                                        <Step title="Review" />
                                    </Steps>
                                </Col>
                                <Col xs={24} sm={18} md={20}>
                                    <div
                                        style={{
                                            padding: 20,
                                        }}
                                    >
                                        <TBCreateAdvancedTournamentForm
                                            {...props}
                                            onCreated={(tournament) => {
                                                console.log(tournament);
                                            }}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </SectionContent>
            <SectionFooter {...props} />
        </SectionWrapper>
    );
}

export default withRouter(TBCreateAdvancedTournament);
