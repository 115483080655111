import gql from 'graphql-tag';
export const LEAGUE_FIELDS = gql`
    fragment leagueFields on leagues {
        id
        name
        slug
        short_description
        long_description
        avatar
        logo
        is_public
        organization_id
        organization {
            id
            name
            slug
            description
            address1
            address2
            city
            country
            region
            postal_code
            phone_number
            fax_number
            email_address
            website
            owner_id
            created_at
            updated_at
        }
        owner_id
        owner {
            id
            first_name
            last_name
            email
        }
        operator {
            id
            first_name
            last_name
            email
        }
        address1
        address2
        city
        county
        country
        region
        postal_code
        phone_number
        fax_number
        email_address
        website
        social_facebook
        social_instagram
        social_twitter
        match_notifications_in_app
        match_notifications_sms
        league_divisions_aggregate {
            aggregate {
                count
            }
        }
        league_players_aggregate {
            aggregate {
                count
            }
        }
        league_matches_aggregate {
            aggregate {
                count
            }
        }
        created_at
        updated_at
        deleted_at
    }
`;
