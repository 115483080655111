import React, { useState, useEffect, useLayoutEffect, useRef, createRef } from 'react';
import { message, Row, Col, Checkbox, List, Modal, Avatar, Badge, Input, Typography, Progress, Button, Icon, Tabs, Empty } from 'antd';
import { Query, Subscription } from '@apollo/client/react/components';
import CircleFlag from '../../components/CircleFlag';
import { functions } from '../../firebase';
import { connectFunctionsEmulator, httpsCallable } from 'firebase/functions';
import { displayEnumType } from '../../utils';
import { useMutation, useQuery } from '@apollo/react-hooks';
import ZeroState from '../../components/ZeroState';
import { GET_TOURNAMENT_BRACKET_QUERY } from './data/queries';
import {
    UPDATE_TOURNAMENT_MATCH_MUTATION,
    LOG_PLAYER_SMS_NOTIFICATION,
    UPDATE_TABLE_STATUS_MUTATION,
    CREATE_TOURNAMENT_BRACKET_MUTATION,
    DELETE_TOURNAMENT_MATCH,
} from './data/mutations';
import PlayerModal from './PlayerModal';
import Fade from 'react-reveal/Fade';
import Hashids from 'hashids';
import moment from 'moment-timezone';
import momentDurationFormatSetup from 'moment-duration-format';
momentDurationFormatSetup(moment);

const { TabPane } = Tabs;
const { Search } = Input;
const { Text, Title } = Typography;
const { confirm } = Modal;

const styles = {
    blueText: {
        color: '#0084ff',
    },
    redText: {
        color: '#f5222d',
    },
    greenText: {
        // color: '#1890ff',
        color: '#1e9c00',
    },
    orangeText: {
        color: '#ff5d1e',
    },
    cell: {
        fontWeight: 700,
    },
    container: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
};

const tabHeight = 45;

function ViewerMatches(props) {
    const {
        tournament,
        players,
        isPublic,
        matches,
        tables,
        recommendedTables,
        bracketHeight,
        theme,
        setShowStatusPanel,
        onShowScoreModal,
        onStartTournament,
        onSetMatches,
    } = props;
    const [bracketState, setBracketState] = useState();
    const [loading, setLoading] = useState(false);
    const [inProgressMatches, setInProgressMatches] = useState();
    const [completedMatches, setCompletedMatches] = useState();
    const [pendingMatches, setPendingMatches] = useState();
    const [onDeckMatches, setOnDeckMatches] = useState();
    const [openTables, setOpenTables] = useState();
    const [playerModalVisible, setPlayerModalVisible] = useState(false);
    const [selectedPlayer, setSelectedPlayer] = useState({});
    const [logSMSNotification] = useMutation(LOG_PLAYER_SMS_NOTIFICATION);
    const [updateTournamentMatch] = useMutation(UPDATE_TOURNAMENT_MATCH_MUTATION);
    const [createMatchesMutation] = useMutation(CREATE_TOURNAMENT_BRACKET_MUTATION);
    const [updateTableStatus, { mutationLoading, mutationError }] = useMutation(UPDATE_TABLE_STATUS_MUTATION);
    const hashids = new Hashids(`Tournament salt`);

    var zone_name = moment.tz.guess();
    var timezone = moment.tz(zone_name).zoneAbbr();

    useEffect(() => {
        function getCurrentState() {
            let matchState = {
                inProgress: 0,
                notStarted: 0,
                scheduled: 0,
                completed: 0,
                pending: 0,
                winnerSide: 0,
                loserSide: 0,
            };

            let tableState = {
                inUse: 0,
                open: 0,
                closed: 0,
                assigned: 0,
                winnerSide: 0,
                loserSide: 0,
            };

            let progress = Math.round(tournament.progress) || 0;

            matches
                .filter((match) => {
                    return match.is_bye !== true;
                })
                .forEach((match, i) => {
                    switch (match.status) {
                        case 'IN_PROGRESS':
                            matchState.inProgress++;
                            break;
                        case 'NOT_STARTED':
                            matchState.notStarted++;
                            break;
                        case 'COMPLETED':
                            matchState.completed++;
                            break;
                        case 'PENDING':
                            matchState.pending++;
                            break;
                        case 'SCHEDULED':
                            matchState.scheduled++;
                            break;
                        default:
                            break;
                    }
                });

            tables &&
                tables.forEach((table, index) => {
                    switch (table.status) {
                        case 'OPEN':
                            tableState.open++;
                            break;
                        case 'CLOSED':
                            tableState.closed++;
                            break;
                        case 'ASSIGNED':
                            tableState.assigned++;
                            break;
                        case 'IN_USE':
                            tableState.inUse++;
                            break;
                        default:
                            break;
                    }

                    // Check to see if a table is either in USE or has a match ID assigned to it
                    // if (
                    // 	table.status === 'IN_USE' ||
                    // 	(table.tournament_match_table && table.tournament_match_table.length > 0)
                    // ) {
                    // 	tableState.inUse++;
                    // }

                    if (table && table.tournament_match_table && table.tournament_match_table.length > 0) {
                        if (table.tournament_match_table[0].round >= 1) {
                            tableState.winnerSide++;
                        } else {
                            tableState.loserSide++;
                        }
                    }
                });

            matches &&
                matches
                    .filter((match) => {
                        return match.challenger1_id && match.challenger2_id;
                    })
                    .filter((match) => {
                        return match.is_bye !== true;
                    })
                    .forEach((match, index) => {
                        if (match.round >= 1) {
                            matchState.winnerSide++;
                        } else {
                            matchState.loserSide++;
                        }
                    });

            return {
                matchState,
                tableState,
                // playerState,
                progress,
            };
        }
        const bracketState = getCurrentState();
        setBracketState(bracketState);
        const inProgress = getMatchesInProgress(bracketState, matches);
        setInProgressMatches(inProgress);
        const onDeckCurrent = getMatchesOnDeck(bracketState, matches);
        setOnDeckMatches(onDeckCurrent);
        const pending = getPendingMatches(bracketState, matches);
        setPendingMatches(pending);
        const completed = getCompletedMatches(bracketState, matches);
        setCompletedMatches(completed);
        const openTables = getOpenTables();
        setOpenTables(openTables);
    }, [tournament, tables, matches]);

    function getMatchesOnDeck(bracketState, matches) {
        console.log('open tables', bracketState.tableState.open);

        const onDeck =
            matches &&
            matches
                .filter((match) => {
                    return match.status !== 'COMPLETED' && (match.status === 'NOT_STARTED' || match.status === 'SCHEDULED');
                })
                .filter((match) => {
                    return match.is_bye !== true;
                });
        // .slice(0, bracketState.tableState.open)
        return onDeck;
    }

    function getMatchesInProgress(bracketState, matches) {
        const inProgress =
            matches &&
            matches
                .filter((match) => {
                    return match.status === 'IN_PROGRESS';
                })
                .filter((match) => {
                    return match.is_bye !== true;
                })
                .slice(0, bracketState.tableState.in_progress);

        return inProgress;
    }

    function getCompletedMatches(bracketState, matches) {
        const completed =
            matches &&
            matches
                .filter((match) => {
                    return match.status === 'COMPLETED';
                })
                .filter((match) => {
                    return match.is_bye !== true;
                })
                .slice(0, bracketState.tableState.completed);

        return completed;
    }

    function getPendingMatches(bracketState, matches) {
        const pending =
            matches &&
            matches
                .filter((match) => {
                    return match.status === 'PENDING';
                })
                .filter((match) => {
                    return match.is_bye !== true;
                })
                .slice(0, bracketState.tableState.pending);

        return pending;
    }

    function getOpenTables() {
        return tables
            .filter((table) => {
                return table.status === 'OPEN';
            })
            .sort((a, b) => {
                return a.label.localeCompare(b.label, undefined, {
                    numeric: true,
                    sensitivity: 'base',
                });
            });
    }

    function handleShowPlayerModal(player) {
        console.log(player);
        setSelectedPlayer(player);
        setPlayerModalVisible(true);
    }

    function handlePlayerModalVisible(visibility) {
        setPlayerModalVisible(visibility);
    }

    function hasBothPlayers(match) {
        return match.challenger1_name && match.challenger2_name ? true : false;
    }

    function getMatchByNumber(matchNum) {
        return matches.filter((item) => {
            return item.match_number === matchNum;
        })[0];
    }

    function formatMatchDisplay(match) {
        return (
            <React.Fragment>
                {match.pool_table &&
                    `${match.pool_table.label} ${match.pool_table && match.pool_table.is_streaming_table ? 'Streaming' : ''} ${
                        match.pool_table && match.pool_table.is_featured_table ? 'Featured' : ''
                    }`}{' '}
                ({match.challenger1_score ? `${match.challenger1_score} ` : `0 `} -{match.challenger2_score ? ` ${match.challenger2_score}` : ` 0`}) -{' '}
                {displayEnumType(match.status)}
                {match.status !== 'NOT_STARTED' && (
                    <React.Fragment>
                        <br />
                        <Progress
                            percent={match.progress || 0}
                            status={match.status === 'PENDING' ? 'success' : match.progress < 100 ? 'active' : match.progress === 100 ? 'normal' : 'normal'}
                            size="small"
                            strokeWidth={4}
                            strokeLinecap="square"
                            // strokeColor={match.status === 'PENDING' && '#ff5d1e'}
                            className={theme && theme.name}
                            style={{
                                top: -5,
                                padding: 0,
                                height: 5,
                                position: 'relative',
                            }}
                        />
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }

    function getPlayer(id) {
        return players.filter((player) => {
            return player && player.id === id;
        })[0];
    }

    function showConfirmAssignTable(match, table, promises) {
        confirm({
            title: `Are you sure you want to assign ${table.label} to the same player back-to-back?`,
            centered: true,
            transitionName: 'fade',
            maskTransitionName: 'none',
            // onOk() {
            // 	return new Promise((resolve, reject) => {
            // 		setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
            // 	}).catch(() => console.log('Oops errors!'));
            // },
            onOk() {
                console.log('ok');
                assignMatchToTable(match, table, promises);
            },
            onCancel() {},
            cancelText: 'Cancel',
        });
    }

    function isTableAllowedToAssign(winnerPrevMatch, loserPrevMatch, table) {
        let allowedToAssign;
        console.log('loser prev matches', {
            winnerPrevMatch,
            loserPrevMatch,
        });

        if (winnerPrevMatch && loserPrevMatch) {
            if (winnerPrevMatch.table_id !== table.id && loserPrevMatch.table_id !== table.id) {
                allowedToAssign = true;
            } else {
                allowedToAssign = false;
            }
        } else {
            allowedToAssign = true;
        }
        return allowedToAssign;
    }

    function logSMSMessage(options) {
        const { match, player, tournament } = options;
        console.log('LOG SMS MESSAGE', options);

        return logSMSNotification({
            variables: {
                objects: [
                    {
                        tournament_id: tournament.id,
                        tournament_bracket_id: match.id,
                        type: 'SMS',
                        round_number: match.round,
                        match_number: match.match_number,
                        player_name: player.name,
                        player_id: player.id,
                        player_phone_number: `${player.country_phone || '+1'}${player.phone_number}`,
                        message: options.message.text,
                        message_id: options.message.id,
                        message_sent: options.message.sent,
                        message_delivered: options.message.delivered,
                        message_error: options.message.error,
                        message_undeliverable: message.undeliverable,
                        message_sent_at: options.message.sent_at,
                        message_delivered_at: options.message.delivered_at,
                    },
                ],
            },
            // notifyOnNetworkStatusChange: true,
            // awaitRefetchQueries: true,
            // refetchQueries: [
            // 	{
            // 		query: GET_MATCH_STATS_QUERY,
            // 		variables: { match_id: matchId }
            // 	}
            // ]
        })
            .then((values) => {
                console.log(values);
                return values;
            })
            .catch((e) => {
                const err = (e && e.message) || JSON.stringify(e);
                message.error(err);
                console.log(err);
                return err;
            });
    }

    async function notifyPlayer(player1, player2, table, currentMatch) {
        if (tournament.use_text_messaging !== false) {
            let player1NameSplit;
            let player2NameSplit;
            let player1FirstName;
            let player2FirstName;
            let text;
            let scoreText;
            let player1Race;
            let player2Race;

            console.log({ player1, player2, table });
            const tableName =
                table && table.is_streaming_table === true
                    ? `${table.label} (Live Stream Table)`
                    : table && table.is_featured_table === true
                    ? `${table.label} (Featured Table)`
                    : table.label;

            if (player1) {
                player1NameSplit = player1.name && player1.name.split(' ');
                player1FirstName = player1NameSplit && player1NameSplit.length > 0 ? player1NameSplit[0] : player1.name;
            }

            if (player2) {
                player2NameSplit = player2.name && player2.name.split(' ');
                player2FirstName = player2NameSplit && player2NameSplit.length > 0 ? player2NameSplit[0] : player2.name;
            }

            const liveScoringLink = `Live score your match #${currentMatch.match_number}: https://digitalpool.com/m/${currentMatch.hash_id}`;

            if (player1 && player2) {
                if (currentMatch.identifier.includes('L')) {
                    player1Race = player1 && player1.race_to ? player1.race_to : tournament.losers_race_to;
                    player2Race = player2 && player2.race_to ? player2.race_to : tournament.losers_race_to;
                } else {
                    player1Race = player1 && player1.race_to ? player1.race_to : tournament.winners_race_to;
                    player2Race = player2 && player2.race_to ? player2.race_to : tournament.winners_race_to;
                }
                if (currentMatch.scheduled_time) {
                    text = `Your scheduled match #${currentMatch.match_number} against ${player2 && player2.name} will start ${moment(
                        currentMatch.scheduled_time
                    ).calendar()} on ${tableName}. Please arrive early to start on time.`;
                    scoreText = liveScoringLink;
                } else {
                    text = `Your match #${currentMatch.match_number} against ${
                        player2 && player2.name
                    } has been assigned to ${tableName}. ${player1FirstName} goes to ${player1Race} and ${player2FirstName} goes to ${player2Race}.`;
                    scoreText = liveScoringLink;
                }
            } else if (player1 && !player2) {
                if (currentMatch.scheduled_time) {
                    text = `Your scheduled match #${currentMatch.match_number} will start ${moment(
                        currentMatch.scheduled_time
                    ).calendar()} on ${tableName}. Please arrive early to start on time.`;
                    scoreText = liveScoringLink;
                } else {
                    text = `Your match #${currentMatch.match_number} has been assigned to ${tableName}. ${
                        player1 && player1.race_to ? `${player1FirstName} goes to ${player1.race_to}.` : ''
                    }`;
                    scoreText = player1 && player1.race_to && liveScoringLink;
                }
            } else if (!player1 && player2) {
                if (currentMatch.scheduled_time) {
                    text = `Your scheduled match #${currentMatch.match_number} will start ${moment(
                        currentMatch.scheduled_time
                    ).calendar()} on ${tableName}. Please arrive early to start on time.`;
                    scoreText = liveScoringLink;
                } else {
                    text = `Your match #${currentMatch.match_number} has been assigned to ${tableName}. ${
                        player2 && player2.race_to ? `${player2FirstName} goes to ${player2.race_to}.` : ''
                    }`;
                    scoreText = player2 && player2.race_to && liveScoringLink;
                }
            } else {
                throw new Error('Text message could not be sent. No players found!');
            }

            if (window.location.hostname.indexOf('localhost') > -1) {
                connectFunctionsEmulator(functions, 'localhost', 5001);
            }

            const sendSMS = httpsCallable(functions, 'sms');
            const messageToSend = `DigitalPool.com\n${text}`;
            // const messageToSend = `DigitalPool.com\n${text}\n${moment().format('MM/DD/YYYY h:mm a')}`;
            if (player1) {
                try {
                    const resultPlayer1 = await sendSMS({
                        phone_number: `${player1.country_phone || '+1'}${player1.phone_number}`,
                        message: messageToSend,
                        user_id: player1.id,
                        tournament_id: tournament.id,
                        // token: ''
                    })
                        .then(async (values) => {
                            console.log(values);
                            await logSMSMessage({
                                tournament,
                                match: currentMatch,
                                player: player1,
                                message: {
                                    id: values && values.data && values.data['message-id'],
                                    text: messageToSend,
                                    sent: true,
                                    error: null,
                                    sent_at: moment.utc(),
                                    delivered: null,
                                    delivered_at: null,
                                    undeliverable: null,
                                },
                            });
                            message.success(`SMS Notification sent to ${player1.name}`);
                            setLoading(false);
                            return values;
                        })
                        .catch(async (e) => {
                            const err = (e && e.message) || JSON.stringify(e);
                            console.log(err);
                            await logSMSMessage({
                                tournament,
                                match: currentMatch,
                                player: player1,
                                message: {
                                    id: err && err.data && err.data['message-id'],
                                    text: messageToSend,
                                    sent: true,
                                    error: err,
                                    sent_at: moment.utc(),
                                    delivered: false,
                                    delivered_at: null,
                                    undeliverable: true,
                                },
                            });
                            setLoading(false);
                            message.error(`SMS Notification error: ${err}`);
                            return err;
                        });
                    console.log(player1);
                    console.log(resultPlayer1);

                    if (scoreText) {
                        const scoringLinkToSend = `DigitalPool.com\n${scoreText}`;
                        // const scoringLinkToSend = `DigitalPool.com\n${scoreText}\n${moment().format('MM/DD/YYYY h:mm a')}`;
                        await sendSMS({
                            phone_number: `${player1.country_phone || '+1'}${player1.phone_number}`,
                            message: scoringLinkToSend,
                            user_id: player1.id,
                            tournament_id: tournament.id,
                            // token: ''
                        })
                            .then(async (values) => {
                                console.log(values);
                                await logSMSMessage({
                                    tournament,
                                    match: currentMatch,
                                    player: player1,
                                    message: {
                                        id: values && values.data && values.data['message-id'],
                                        text: scoreText,
                                        sent: true,
                                        error: null,
                                        sent_at: moment.utc(),
                                        delivered: null,
                                        delivered_at: null,
                                        undeliverable: null,
                                    },
                                });

                                return values;
                            })
                            .catch(async (e) => {
                                const err = (e && e.message) || JSON.stringify(e);
                                console.log(err);
                                await logSMSMessage({
                                    tournament,
                                    match: currentMatch,
                                    player: player1,
                                    message: {
                                        id: err && err.data && err.data['message-id'],
                                        text: scoreText,
                                        sent: true,
                                        error: err,
                                        sent_at: moment.utc(),
                                        delivered: false,
                                        delivered_at: null,
                                        undeliverable: true,
                                    },
                                });

                                return err;
                            });
                    }
                } catch (e) {
                    const err = (e && e.message) || JSON.stringify(e);
                    console.log(err);
                    await logSMSMessage({
                        tournament,
                        match: currentMatch,
                        player: player1,
                        message: {
                            id: err && err.data && err.data['message-id'],
                            text: messageToSend,
                            sent: true,
                            error: err,
                            sent_at: moment.utc(),
                            delivered: false,
                            delivered_at: null,
                            undeliverable: true,
                        },
                    });
                    setLoading(false);
                    message.error(`SMS Notification error: ${err}`);
                }
            }
        } else {
            console.log('SMS notifications are currently disabled');
        }
    }

    function assignMatchToTable(match, table, promises) {
        const changes = {
            challenger1_id: match.challenger1_id,
            challenger1_name: match.challenger1_name,
            challenger1_country: match.challenger1_country,
            challenger1_score: match.challenger1_score,
            challenger1_race_to: match.challenger1_race_to,
            challenger1_skill_level: match.challenger1_skill_level,
            challenger1_seed: match.challenger1_seed,
            challenger2_id: match.challenger2_id,
            challenger2_name: match.challenger2_name,
            challenger2_country: match.challenger2_country,
            challenger2_score: match.challenger2_score,
            challenger2_race_to: match.challenger2_race_to,
            challenger2_skill_level: match.challenger2_skill_level,
            challenger2_seed: match.challenger2_seed,
            start_time: moment.utc(),
            table_name: table.label,
            table_id: table.id,
            status: 'IN_PROGRESS',
            progress: 0,
        };

        const player1 = getPlayer(match.challenger1_id);
        const player2 = getPlayer(match.challenger2_id);

        if (player1 && player1.phone_number) {
            notifyPlayer(player1, player2, table, match);
        }
        if (player2 && player2.phone_number) {
            notifyPlayer(player2, player1, table, match);
        }

        promises.push(
            updateTournamentMatch({
                variables: {
                    id: match.id,
                    tournament_id: tournament.id,
                    changes: changes,
                },
            }).then((data) => {
                console.log(data);
                // message.success(`Match ${match.match_number} assigned to ${table.label}`);
                updateTableStatus({
                    variables: {
                        id: table.id,
                        changes: {
                            status: 'IN_USE',
                        },
                    },
                })
                    .then((values) => {
                        console.log(values);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            })
        );
    }

    function assignOnDeckMatches(onDeckMatches, openTables) {
        let promises = [];
        setLoading(true);
        return new Promise(async (resolve, reject) => {
            if (onDeckMatches && onDeckMatches.length > 0 && openTables && openTables.length > 0) {
                openTables.slice(0, onDeckMatches.length).forEach((table, index) => {
                    const match = onDeckMatches[index];
                    console.log(match);
                    let allowedToAssign = true;

                    // const winnerPrevMatchTop = getMatchByNumber(match.winner_from_top_num);
                    // const winnerPrevMatchBottom = getMatchByNumber(match.winner_from_bottom_num);
                    // allowedToAssign = isTableAllowedToAssign(winnerPrevMatchTop, winnerPrevMatchBottom, table);

                    if (allowedToAssign === true) {
                        assignMatchToTable(match, table, promises);
                    } else {
                        showConfirmAssignTable(match, table, promises);
                        // message.error(`Cannot auto-assign ${table.label} to the same player back-to-back`);
                        return;
                    }

                    Promise.all(promises)
                        .then((values) => {
                            message.success(`Matches have been assigned to ${openTables.length} open tables.`);
                            setLoading(false);
                            resolve(values);
                        })
                        .catch((error) => {
                            console.log(error);
                            message.error('There was an error', error);
                            setLoading(false);
                            reject(error);
                        });
                });
            } else {
                message.error(`No tables available`);
            }
        }).catch((error) => {
            console.log(error);
            return error;
        });
    }

    function assignTables() {
        assignOnDeckMatches(onDeckMatches, openTables);
    }

    function getRandomArbitrary(min, max) {
        return Math.random() * (max - min) + min;
    }

    function isPlayerInMatch(player) {
        const matchPlayer = matches
            .filter((match) => {
                return match.status === 'NOT_STARTED';
            })
            .filter((match) => {
                return match.challenger1_id === (player && player.id) || match.challenger2_id === (player && player.id);
            })[0];
        if (matchPlayer) {
            return true;
        } else {
            return false;
        }
    }

    function checkForPlayersWaitingToPlay(matches, waiting) {
        const currentMatch = matches && matches[matches.length - 1];

        const matchesNeedingPlayers =
            matches &&
            matches.filter((match) => {
                return !match.challenger1_name || !match.challenger2_name;
            });

        console.log('players waiting', waiting);
        console.log('matches needing players', matchesNeedingPlayers);

        if (waiting && waiting.length > 1) {
            const totalGroups = Math.round(waiting.length / (tournament.players_per_table || 2));
            console.log('total groups', totalGroups);

            const perGroup = tournament.players_per_table || 2;
            let playerGroups = waiting.reduce((resultArray, item, index) => {
                const groupIndex = Math.floor(index / perGroup);
                if (!resultArray[groupIndex]) {
                    resultArray[groupIndex] = []; // start a new chunk
                }
                resultArray[groupIndex].push(item);
                return resultArray;
            }, []);
            console.log('player groups', playerGroups);

            playerGroups.forEach((group) => {
                const player1 = group[0];
                const player2 = group[1];

                const player1InMatch = isPlayerInMatch(player1);
                const player2InMatch = isPlayerInMatch(player2);

                console.log({
                    player1InMatch,
                    player2InMatch,
                });

                if (player1InMatch !== true && player2InMatch !== true) {
                    if (player1 && player2) {
                        // const counter = matches.length + 1;
                        // const identifier = `R${currentMatch.round}-M${counter}`;
                        // const randomNum = getRandomArbitrary(1, 10000).toFixed(0);
                        // const hashId = hashids.encode(randomNum, tournament.id, counter + 1);
                        // const newMatch = {
                        //     // ...match,
                        //     round: currentMatch.round,
                        //     tournament_id: tournament.id,
                        //     match_number: counter,
                        //     identifier: identifier,
                        //     hash_id: hashId,
                        //     table_id: currentMatch.table_id,
                        //     table_name: currentMatch.table_name,
                        //     // ...(tournament.autopilot_mode === true && { table_id: currentMatch.table_id }),
                        //     // ...(tournament.autopilot_mode === true && { table_name: currentMatch.table_name }),
                        //     challenger1_id: player1.id,
                        //     challenger1_name: player1.name,
                        //     challenger1_seed: player1.seed,
                        //     challenger1_country: player1.country,
                        //     challenger1_score: 0,
                        //     challenger1_race_to: player1.race_to || tournament.winners_race_to,
                        //     challenger1_skill_level: player1.skill_level,
                        //     challenger2_id: player2.id,
                        //     challenger2_name: player2.name,
                        //     challenger2_seed: player2.seed,
                        //     challenger2_country: player1.country,
                        //     challenger2_score: 0,
                        //     challenger2_race_to: player1.race_to || tournament.winners_race_to,
                        //     challenger2_skill_level: player1.skill_level,
                        //     status: tournament.autopilot_mode === true ? 'IN_PROGRESS' : 'NOT_STARTED',
                        // };
                        // console.log('new match', newMatch);
                        // createMatchesMutation({
                        //     variables: {
                        //         objects: newMatch,
                        //     },
                        //     notifyOnNetworkStatusChange: true,
                        //     awaitRefetchQueries: true,
                        //     refetchQueries: [
                        //         {
                        //             query: GET_TOURNAMENT_BRACKET_QUERY,
                        //             variables: {
                        //                 tournamentId: tournament.id,
                        //             },
                        //         },
                        //     ],
                        // })
                        //     .then((data) => {
                        //         console.log('res', data);
                        //     })
                        //     .catch((error) => {
                        //         console.log(error);
                        //         message.info('There was an error', error);
                        //     });
                    }
                }
            });
        } else {
            console.log('assign player to open match');

            // if (matchesNeedingPlayers.length > 0) {
            //     const nextMatch = matchesNeedingPlayers[0];
            //     const changes = {
            //         challenger1_id: nextMatch.challenger1_id ? nextMatch.challenger1_id : waiting.id,
            //         challenger1_name: nextMatch.challenger1_name ? nextMatch.challenger1_name : waiting.name,
            //         challenger1_seed: nextMatch.challenger1_seed ? nextMatch.challenger1_seed : waiting.seed,
            //         challenger1_country: nextMatch.challenger1_country ? nextMatch.challenger1_country : waiting.country,
            //         challenger1_race_to: nextMatch.challenger1_race_to ? nextMatch.challenger1_race_to : waiting.race_to,
            //         challenger1_skill_level: nextMatch.challenger1_race_to ? nextMatch.challenger1_race_to : waiting.race_to,
            //         challenger1_score: 0,
            //         challenger2_id: nextMatch.challenger2_id ? nextMatch.challenger2_id : waiting.id,
            //         challenger2_name: nextMatch.challenger2_name ? nextMatch.challenger2_name : waiting.name,
            //         challenger2_seed: nextMatch.challenger2_seed ? nextMatch.challenger2_seed : waiting.seed,
            //         challenger2_country: nextMatch.challenger2_country ? nextMatch.challenger2_country : waiting.country,
            //         challenger2_race_to: nextMatch.challenger2_race_to ? nextMatch.challenger2_race_to : waiting.race_to,
            //         challenger2_skill_level: nextMatch.challenger2_race_to ? nextMatch.challenger2_race_to : waiting.race_to,
            //         challenger2_score: 0,
            //         start_time: moment.utc(),
            //         progress: 0,
            //         status: 'IN_PROGRESS',
            //     };
            //     console.log('filling empty match', changes);
            //     updateTournamentMatch({
            //         variables: {
            //             id: nextMatch.id,
            //             tournament_id: tournament.id,
            //             changes: changes,
            //         },
            //         // optimisticResponse: {
            //         // 	__typename: 'Mutation',
            //         // 	update_tournament_brackets: {
            //         // 		id: currentMatch.id,
            //         // 		tournament_id: tournament.id,
            //         // 		__typename: 'tournament_brackets',
            //         // 		changes: changes,
            //         // 		affected_rows: []
            //         // 	}
            //         // },
            //         notifyOnNetworkStatusChange: true,
            //         awaitRefetchQueries: true,
            //         refetchQueries: [
            //             // {
            //             // 	query: GET_TOURNAMENT_BRACKET_MATCH_QUERY,
            //             // 	variables: { match_id: currentMatch.id, tournament_id: tournament.id },
            //             // 	awaitRefetchQueries: true,
            //             // 	notifyOnNetworkStatusChange: true
            //             // }
            //             // {
            //             // 	query: GET_TOURNAMENT_TABLES_QUERY,
            //             // 	variables: { tournament_id: tournament.id }
            //             // },
            //             // {
            //             // 	query: GET_TOURNAMENT_BRACKET_QUERY,
            //             // 	variables: { tournamentId: tournament.id }
            //             // }
            //         ],
            //     })
            //         .then((data) => {
            //             console.log(data);
            //         })
            //         .catch((error) => {
            //             console.log(error);
            //             message.info('There was an error', error);
            //         });
            // }
        }
    }

    function showConfirmDeleteMatch(match) {
        confirm({
            title: `Are you sure you want to delete this match?`,
            centered: true,
            transitionName: 'fade',
            maskTransitionName: 'none',
            onOk() {
                const key = 'delete';
                props.client
                    .mutate({
                        mutation: DELETE_TOURNAMENT_MATCH,
                        variables: {
                            id: match.id,
                            tournament_id: tournament.id,
                        },
                        // awaitRefetchQueries: true,
                        // refetchQueries: [
                        //     {
                        //         query: GET_TOURNAMENT_PAYOUTS,
                        //         variables: { tournament_id: tournament.id },
                        //     },
                        // ],
                    })
                    .then(async (data) => {
                        console.log(data);

                        // props.client
                        //     .mutate({
                        //         mutation: RESET_TABLE_STATUS_MUTATION,
                        //         variables: {
                        //             tournament_id: tournament.id,
                        //         },
                        //         awaitRefetchQueries: true,
                        //         refetchQueries: [
                        //             {
                        //                 query: GET_TOURNAMENT_TABLES_QUERY,
                        //                 variables: { tournament_id: tournament.id, status: 'OPEN' },
                        //             },
                        //         ],
                        //     })
                        //     .then((data) => {
                        //         console.log(data);
                        //     })
                        //     .catch((e) => {
                        //         console.error(e);
                        //         message.error({ content: JSON.stringify(e), key, duration: 2 });
                        //     });
                    })
                    .catch((error) => {
                        console.log(error);
                        message.error({
                            content: `There was an error ${JSON.stringify(error)}`,
                            key,
                            duration: 2,
                        });
                    });
            },
            onCancel() {},
            cancelText: 'Cancel',
        });
    }

    // const playersWaiting = players.filter((player) => {
    //     if (player.is_eliminated === true || (player.matches && player.matches.length > 0)) {
    //         return false;
    //     } else {
    //         return !player.table;
    //     }
    // });

    // console.log('players waiting', playersWaiting);

    // if (playersWaiting && playersWaiting.length > 0) {
    //     checkForPlayersWaitingToPlay(matches, playersWaiting);
    // }

    const playersLeft = players.filter((player) => {
        return player.is_eliminated === false;
    });

    return (
        <div>
            <Row
                gutter={16}
                justify="center"
                type="flex"
                style={{
                    margin: 0,
                    padding: 0,
                    height: bracketHeight - tabHeight - 59,
                    width: '100%',
                }}
            >
                {tournament.status === 'IN_PROGRESS' ? (
                    <React.Fragment>
                        <Col xs={24} style={{ borderBottom: `1px solid ${theme.bracket.panels.borderColor}` }}>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    // cursor: 'grab',
                                }}
                                className="tournament-status-title"
                            >
                                <Title
                                    level={4}
                                    style={{
                                        paddingLeft: 10,
                                        // paddingRight: 10,
                                        fontSize: 18,
                                        color: theme.bracket.panels.textColor,
                                    }}
                                >
                                    Tournament Status
                                </Title>
                            </div>
                            <div
                                style={{
                                    paddingLeft: 10,
                                    // paddingRight: 10,
                                    width: '100%',
                                }}
                            >
                                <Progress
                                    percent={bracketState && bracketState.progress}
                                    status={
                                        bracketState && bracketState.progress < 100
                                            ? 'active'
                                            : bracketState && bracketState.progress === 100
                                            ? 'success'
                                            : 'normal'
                                    }
                                    strokeWidth={6}
                                    strokeLinecap="square"
                                    className={theme && theme.name}
                                    style={{
                                        top: -4,
                                        padding: 0,
                                        position: 'relative',
                                    }}
                                />
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        paddingRight: 10,
                                    }}
                                >
                                    <Text style={{ color: theme.bracket.panels.textColor }}>{displayEnumType(tournament.status)}</Text>
                                    <Text style={{ color: theme.bracket.panels.textColor }}>
                                        <Icon type="clock-circle" style={{ marginRight: 3 }} />{' '}
                                        {moment.duration(moment.utc().diff(tournament.start_date_time, 'milliseconds')).format('h [hours] m [minutes]')}
                                    </Text>
                                </div>
                                {/* {matches &&
                                            matches.filter((match) => {
                                                return match.is_bye !== true;
                                            }).length === completedMatches.length &&
                                            tournament.status === 'IN_PROGRESS' && (
                                                <div style={{ marginTop: 10 }}>
                                                    <Button
                                                        type="primary"
                                                        style={{ width: '100%' }}
                                                        onClick={() => {
                                                            showCompleteDialog();
                                                        }}
                                                    >
                                                        Complete Tournament
                                                    </Button>
                                                </div>
                                            )} */}
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'flex-start',
                                    justifyContent: 'space-between',
                                    marginTop: 5,
                                    paddingLeft: 10,
                                    color: theme.bracket.panels.textColor,
                                }}
                            >
                                <div style={{ width: '100%' }}>
                                    {/* <Title level={4} style={{ fontSize: 14, color: theme.bracket.panels.textColor }}>
                                        Stats
                                    </Title> */}
                                    <table style={{ width: '100%', flex: 1 }}>
                                        <tbody>
                                            <tr>
                                                <td>Players left</td>
                                                <td style={styles.cell}>{playersLeft && playersLeft.length}</td>
                                            </tr>
                                            <tr>
                                                <td>Recommended tables</td>
                                                <td style={styles.cell}>{recommendedTables}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </Col>

                        <Col
                            xs={24}
                            style={{
                                margin: 0,
                                padding: 0,
                                overflowY: 'auto',
                                height: isPublic === true ? 'calc(100% - 30px)' : 'calc(100% - 100px)',
                                width: '100%',
                                alignItems: 'center',
                            }}
                        >
                            <Tabs
                                animated={{ inkBar: true, tabPane: false }}
                                defaultActiveKey="up-next"
                                // onChange={callback}
                                // type="card"
                                tabBarStyle={{
                                    textTransform: 'uppercase',
                                    padding: 0,
                                    margin: 0,
                                    color: theme.bracket.panels.textColor,
                                    borderBottom: `1px solid ${theme.bracket.panels.borderColor}`,
                                }}
                                tabBarGutter={0}
                                className="no-scroll"
                            >
                                <TabPane
                                    tab={`On Deck (${onDeckMatches && onDeckMatches.length})`}
                                    key="up-next"
                                    // style={{ padding: 20 }}
                                >
                                    {/* <div
                                            style={{
                                                paddingTop: 15,
                                                color: theme.bracket.panels.textColor,
                                            }}
                                        >
                                            <Title level={4} style={{ paddingLeft: 20, paddingRight: 20, fontSize: 14, color: theme.bracket.panels.textColor }}>
                                                Players Waiting ({playersWaiting && playersWaiting.length})
                                            </Title>
                                            {playersWaiting && playersWaiting.length > 0 ? (
                                                <ul
                                                    style={{
                                                        margin: 0,
                                                        padding: 0,
                                                        listStyle: 'none',
                                                        color: theme.bracket.panels.textColor,
                                                    }}
                                                >
                                                    <List
                                                        className={`hover-list ${theme && theme.name}`}
                                                        size="small"
                                                        itemLayout="horizontal"
                                                        dataSource={playersWaiting}
                                                        renderItem={(player, index) => {
                                                            return (
                                                                <React.Fragment>
                                                                    <List.Item
                                                                        style={{
                                                                            padding: '10px 10px',

                                                                            color: theme.bracket.panels.textColor,
                                                                        }}
                                                                        onClick={(event) => {
                                                                            handleShowPlayerModal(player);
                                                                        }}
                                                                    >
                                                                        <div
                                                                            style={{
                                                                                display: 'flex',
                                                                                alignItems: 'center',
                                                                                width: '100%',
                                                                            }}
                                                                        >
                                                                            <div>
                                                                                <Text style={{ color: theme.bracket.panels.textColor }}>{index + 1}</Text>
                                                                            </div>
                                                                            <div
                                                                                style={{
                                                                                    display: 'flex',
                                                                                    alignItems: 'center',
                                                                                    flex: 1,
                                                                                    marginLeft: 15,
                                                                                }}
                                                                            >
                                                                                <div>{player.country && <CircleFlag country={player.country} left={0} />}</div>

                                                                                <Text
                                                                                    style={{
                                                                                        marginLeft: 0,
                                                                                        width: '100%',
                                                                                        whiteSpace: 'nowrap',
                                                                                        overflow: 'hidden',
                                                                                        textOverflow: 'ellipsis',
                                                                                        color: theme.bracket.panels.textColor,
                                                                                    }}
                                                                                >
                                                                                    <div className="ellipsis">
                                                                                        <b>
                                                                                            {player.name} {player.skill_level && `(${player.skill_level})`}
                                                                                        </b>
                                                                                    </div>

                                                                                    <div style={{ color: '#a7e063' }}>Up Next</div>
                                                                                </Text>
                                                                            </div>

                                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                                <Text style={styles.error}>{player.chips_left} Chips left</Text>
                                                                            </div>
                                                                        </div>
                                                                    </List.Item>
                                                                </React.Fragment>
                                                            );
                                                        }}
                                                    />

                                                   
                                                </ul>
                                            ) : (
                                                <div
                                                    style={{
                                                        paddingLeft: 20,
                                                        paddingRight: 20,
                                                        paddingBottom: 15,
                                                        color: theme.bracket.panels.textColor,
                                                    }}
                                                >
                                                    No players are waiting to play.
                                                </div>
                                            )}
                                        </div> */}
                                    <div
                                        style={{
                                            paddingTop: 15,
                                            paddingLeft: 20,
                                            paddingRight: 20,
                                            color: theme.bracket.panels.textColor,
                                            // borderTop: `1px solid ${theme.bracket.panels.borderColor}`,
                                        }}
                                    >
                                        {onDeckMatches && onDeckMatches.length > 0 && (
                                            <Button
                                                type="primary"
                                                style={{
                                                    marginBottom: 10,
                                                    width: '100%',
                                                }}
                                                onClick={() => {
                                                    assignTables();
                                                }}
                                            >
                                                Assign {onDeckMatches.length} Match
                                                {onDeckMatches.length > 1 ? 'es' : ''} to Open Tables
                                            </Button>
                                        )}

                                        <Title level={4} style={{ fontSize: 14, color: theme.bracket.panels.textColor }}>
                                            Matches Ready To Assign ({onDeckMatches && onDeckMatches.length})
                                        </Title>
                                    </div>
                                    <ul
                                        style={{
                                            margin: 0,
                                            padding: 0,
                                            listStyle: 'none',
                                            color: theme.bracket.panels.textColor,
                                        }}
                                    >
                                        {onDeckMatches && onDeckMatches.length > 0 ? (
                                            <List
                                                className={`hover-list ${theme && theme.name}`}
                                                size="small"
                                                itemLayout="horizontal"
                                                dataSource={onDeckMatches}
                                                renderItem={(match, index) => {
                                                    // const updatedOpenTables = openTables;
                                                    // const updatedOpenTables = openTables && _.shuffle(openTables);
                                                    return (
                                                        <React.Fragment>
                                                            <List.Item
                                                                onClick={() => {
                                                                    console.log('m', match);
                                                                    onShowScoreModal(match);
                                                                }}
                                                                style={{
                                                                    borderBottom: `1px solid ${theme.bracket.panels.borderColor}`,
                                                                }}
                                                                actions={[
                                                                    <Button
                                                                        type="danger"
                                                                        ghost
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            e.stopPropagation();
                                                                            showConfirmDeleteMatch(match);
                                                                        }}
                                                                    >
                                                                        <Icon type="delete" />
                                                                    </Button>,
                                                                ]}
                                                            >
                                                                <List.Item.Meta
                                                                    title={
                                                                        <React.Fragment>
                                                                            <Text
                                                                                style={{
                                                                                    color: theme.bracket.panels.textColor,
                                                                                }}
                                                                            >
                                                                                Match {match.match_number} ({match.identifier}) -{' '}
                                                                            </Text>

                                                                            <Text
                                                                                style={{
                                                                                    fontSize: 12,
                                                                                    backgroundColor: theme.bracket.panels.tagBackgroundColor,
                                                                                    borderRadius: 4,
                                                                                    padding: '2px 5px',
                                                                                    color: theme.bracket.panels.tagTextColor,
                                                                                }}
                                                                            >
                                                                                {openTables && openTables[index] ? openTables[index].label : 'No Table'}
                                                                            </Text>
                                                                        </React.Fragment>
                                                                    }
                                                                    description={
                                                                        <React.Fragment>
                                                                            <b>
                                                                                <Text
                                                                                    style={{
                                                                                        color: theme.bracket.panels.textColor,
                                                                                    }}
                                                                                >
                                                                                    {match.challenger1_name}
                                                                                    {match.challenger1_name &&
                                                                                        match.is_bye !== true &&
                                                                                        match.challenger1_race_to &&
                                                                                        `(${match.challenger1_race_to})`}{' '}
                                                                                    vs. {match.challenger2_name}
                                                                                    {match.challenger1_name &&
                                                                                        match.is_bye !== true &&
                                                                                        match.challenger2_race_to &&
                                                                                        `(${match.challenger2_race_to})`}
                                                                                </Text>
                                                                            </b>
                                                                            <br />

                                                                            <Text>
                                                                                {match &&
                                                                                    match.tournament_bracket_projections &&
                                                                                    match.tournament_bracket_projections[0] && (
                                                                                        <React.Fragment>
                                                                                            <Text
                                                                                                style={{
                                                                                                    color: '#888',
                                                                                                }}
                                                                                            >
                                                                                                Projected:{' '}
                                                                                                {`${moment
                                                                                                    .utc(match.tournament_bracket_projections[0].start_time)
                                                                                                    .local()
                                                                                                    .format('LT')} ${timezone} (${moment
                                                                                                    .utc(match.tournament_bracket_projections[0].start_time)
                                                                                                    .local()
                                                                                                    .fromNow()})`}
                                                                                            </Text>
                                                                                            <br />
                                                                                        </React.Fragment>
                                                                                    )}
                                                                            </Text>

                                                                            <span style={styles.redText}>
                                                                                {openTables[index] && !match.pool_table && `${openTables[index].label}`}{' '}
                                                                                {formatMatchDisplay(match)}
                                                                            </span>
                                                                        </React.Fragment>
                                                                    }
                                                                />
                                                            </List.Item>
                                                        </React.Fragment>
                                                    );
                                                }}
                                            />
                                        ) : (
                                            <div
                                                style={{
                                                    paddingLeft: 20,
                                                    paddingRight: 20,
                                                    color: theme.bracket.panels.textColor,
                                                }}
                                            >
                                                No matches are ready to assign yet.
                                            </div>
                                        )}
                                    </ul>
                                </TabPane>
                                <TabPane
                                    tab={`Pending (${bracketState && bracketState.matchState.pending + bracketState.matchState.inProgress})`}
                                    key="pending"
                                    style={{ padding: 0 }}
                                >
                                    <div
                                        style={{
                                            paddingTop: 15,
                                            paddingLeft: 20,
                                            paddingRight: 20,
                                            color: theme.bracket.panels.textColor,
                                        }}
                                    >
                                        <Title
                                            level={4}
                                            style={{
                                                fontSize: 14,
                                                color: theme.bracket.panels.textColor,
                                            }}
                                        >
                                            Pending Score Approval ({pendingMatches && pendingMatches.length})
                                        </Title>
                                    </div>
                                    <ul
                                        style={{
                                            margin: 0,
                                            padding: 0,
                                            listStyle: 'none',
                                            color: theme.bracket.panels.textColor,
                                        }}
                                    >
                                        {pendingMatches && pendingMatches.length > 0 ? (
                                            <List
                                                className={`hover-list ${theme && theme.name}`}
                                                size="small"
                                                itemLayout="horizontal"
                                                dataSource={pendingMatches}
                                                renderItem={(match, index) => (
                                                    <List.Item
                                                        onClick={() => {
                                                            console.log('m', match);
                                                            onShowScoreModal(match);
                                                        }}
                                                        style={{
                                                            borderBottom: `1px solid ${theme.bracket.panels.borderColor}`,
                                                        }}
                                                    >
                                                        <List.Item.Meta
                                                            title={
                                                                <Text style={{ color: theme.bracket.panels.textColor }}>
                                                                    Match {match.match_number} ({match.identifier})
                                                                </Text>
                                                            }
                                                            description={
                                                                <React.Fragment>
                                                                    <b>
                                                                        <Text
                                                                            style={{
                                                                                color: theme.bracket.panels.textColor,
                                                                            }}
                                                                        >
                                                                            {match.challenger1_name}
                                                                            {match.challenger1_name &&
                                                                                match.is_bye !== true &&
                                                                                match.challenger1_race_to &&
                                                                                `(${match.challenger1_race_to})`}{' '}
                                                                            vs. {match.challenger2_name}
                                                                            {match.challenger1_name &&
                                                                                match.is_bye !== true &&
                                                                                match.challenger2_race_to &&
                                                                                `(${match.challenger2_race_to})`}
                                                                        </Text>
                                                                    </b>

                                                                    <br />
                                                                    <span style={styles.greenText}>{formatMatchDisplay(match)}</span>
                                                                </React.Fragment>
                                                            }
                                                        />
                                                    </List.Item>
                                                )}
                                            />
                                        ) : (
                                            <div
                                                style={{
                                                    paddingLeft: 20,
                                                    paddingRight: 20,
                                                    paddingBottom: 15,
                                                    color: theme.bracket.panels.textColor,
                                                }}
                                            >
                                                No pending matches.
                                            </div>
                                        )}
                                    </ul>
                                    <div
                                        style={{
                                            paddingLeft: 20,
                                            paddingRight: 20,
                                            paddingTop: 15,
                                            borderTop: `1px solid ${theme.bracket.panels.borderColor}`,
                                        }}
                                    >
                                        <Title
                                            level={4}
                                            style={{
                                                fontSize: 14,

                                                color: theme.bracket.panels.textColor,
                                            }}
                                        >
                                            In Progress ({inProgressMatches && inProgressMatches.length})
                                        </Title>
                                    </div>
                                    <ul
                                        style={{
                                            margin: 0,
                                            padding: 0,
                                            listStyle: 'none',
                                        }}
                                    >
                                        {inProgressMatches && inProgressMatches.length > 0 ? (
                                            <List
                                                className={`hover-list ${theme && theme.name}`}
                                                size="small"
                                                itemLayout="horizontal"
                                                dataSource={inProgressMatches}
                                                renderItem={(match, index) => (
                                                    <List.Item
                                                        onClick={() => {
                                                            console.log('m', match);
                                                            onShowScoreModal(match);
                                                        }}
                                                        style={{
                                                            borderBottom: `1px solid ${theme.bracket.panels.borderColor}`,
                                                        }}
                                                        actions={[
                                                            <Button
                                                                type="danger"
                                                                ghost
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    e.stopPropagation();
                                                                    showConfirmDeleteMatch(match);
                                                                }}
                                                            >
                                                                <Icon type="delete" />
                                                            </Button>,
                                                        ]}
                                                    >
                                                        <List.Item.Meta
                                                            title={
                                                                <Text style={{ color: theme.bracket.panels.textColor }}>
                                                                    Match {match.match_number} ({match.identifier})
                                                                </Text>
                                                            }
                                                            description={
                                                                <React.Fragment>
                                                                    <b>
                                                                        <Text
                                                                            style={{
                                                                                color: theme.bracket.panels.textColor,
                                                                            }}
                                                                        >
                                                                            {match.challenger1_name}
                                                                            {match.challenger1_name &&
                                                                                match.is_bye !== true &&
                                                                                match.challenger1_race_to &&
                                                                                `(${match.challenger1_race_to})`}{' '}
                                                                            vs. {match.challenger2_name}
                                                                            {match.challenger1_name &&
                                                                                match.is_bye !== true &&
                                                                                match.challenger2_race_to &&
                                                                                `(${match.challenger2_race_to})`}
                                                                        </Text>
                                                                    </b>

                                                                    <br />
                                                                    <span style={styles.blueText}>{formatMatchDisplay(match)}</span>
                                                                </React.Fragment>
                                                            }
                                                        />
                                                    </List.Item>
                                                )}
                                            />
                                        ) : (
                                            <div style={{ paddingLeft: 20, paddingRight: 20, color: theme.bracket.panels.textColor }}>
                                                No matches in progress yet.
                                            </div>
                                        )}
                                    </ul>
                                </TabPane>

                                <TabPane
                                    tab={`Done (${completedMatches && completedMatches.length})`}
                                    key="completed"
                                    // style={{ padding: 20 }}
                                >
                                    <ul
                                        style={{
                                            margin: 0,
                                            padding: 0,
                                            listStyle: 'none',
                                        }}
                                    >
                                        {completedMatches && completedMatches.length > 0 ? (
                                            <List
                                                className={`hover-list ${theme && theme.name}`}
                                                size="small"
                                                itemLayout="horizontal"
                                                dataSource={completedMatches.sort((a, b) => {
                                                    return b.match_number - a.match_number;
                                                })}
                                                renderItem={(match, index) => {
                                                    // const updatedOpenTables = openTables;
                                                    // const updatedOpenTables = openTables && _.shuffle(openTables);
                                                    return (
                                                        <List.Item
                                                            key={index}
                                                            onClick={() => {
                                                                console.log('m', match);
                                                                onShowScoreModal(match);
                                                                // setSideBarPlayerModalPlayerData({
                                                                //     visible: true,
                                                                //     selectedMatch: match,
                                                                //     openTables,
                                                                //     players,
                                                                //     onDeckMatches,
                                                                //     tournament,
                                                                //     tournamentId: tournament.id,
                                                                // });
                                                            }}
                                                            style={{
                                                                paddingTop: 15,
                                                                borderBottom: `1px solid ${theme.bracket.panels.borderColor}`,
                                                            }}
                                                        >
                                                            <List.Item.Meta
                                                                title={
                                                                    <React.Fragment>
                                                                        <Text style={{ color: theme.bracket.panels.textColor }}>
                                                                            Match {match.match_number} ({match.identifier})
                                                                        </Text>
                                                                    </React.Fragment>
                                                                }
                                                                description={
                                                                    <React.Fragment>
                                                                        <b>
                                                                            <Text style={{ color: theme.bracket.panels.textColor }}>
                                                                                {match.challenger1_name}
                                                                                {match.challenger1_name &&
                                                                                    match.is_bye !== true &&
                                                                                    match.challenger1_race_to &&
                                                                                    ` (${match.challenger1_race_to})`}{' '}
                                                                                vs. {match.challenger2_name}
                                                                                {match.challenger1_name &&
                                                                                    match.is_bye !== true &&
                                                                                    match.challenger2_race_to &&
                                                                                    ` (${match.challenger2_race_to})`}
                                                                            </Text>
                                                                        </b>
                                                                        <br />

                                                                        <span style={styles.blueText}>{formatMatchDisplay(match)}</span>
                                                                    </React.Fragment>
                                                                }
                                                            />
                                                        </List.Item>
                                                    );
                                                }}
                                            />
                                        ) : (
                                            <div
                                                style={{
                                                    paddingTop: 15,
                                                    paddingLeft: 20,
                                                    paddingRight: 20,
                                                    color: theme.bracket.panels.textColor,
                                                }}
                                            >
                                                No matches have finished yet.
                                            </div>
                                        )}
                                    </ul>
                                </TabPane>
                            </Tabs>
                        </Col>
                    </React.Fragment>
                ) : tournament.status === 'COMPLETED' ? (
                    <div
                        style={{
                            width: '100%',
                            height: '100%',
                            paddingTop: 10,
                            paddingLeft: 20,
                            paddingRight: 10,
                            paddingBottom: 10,
                            borderBottom: '1px solid rgba(0, 0, 0, 0.07)',
                        }}
                    >
                        <div
                            style={{
                                height: '100%',
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <ZeroState showImage message="Tournament complete" style={{ margin: 30 }} {...props} />
                        </div>
                    </div>
                ) : (
                    <div
                        style={{
                            width: '100%',
                            height: '100%',
                            paddingTop: 10,
                            paddingLeft: 20,
                            paddingRight: 10,
                            paddingBottom: 10,
                            borderBottom: '1px solid rgba(0, 0, 0, 0.07)',
                        }}
                    >
                        <div
                            style={{
                                height: '100%',
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <ZeroState showImage message="Tournament not started." style={{ margin: 30 }} {...props}>
                                <Button type="primary" onClick={onStartTournament}>
                                    Start Tournament
                                </Button>
                            </ZeroState>
                        </div>
                    </div>
                )}
            </Row>
            {isPublic !== true && tournament.status !== 'COMPLETED' && (
                <Row>
                    <Col
                        xs={24}
                        style={{
                            margin: 0,
                            paddingLeft: 8,
                            paddingRight: 8,
                            borderTop: `1px solid ${theme.bracket.panels.borderColor}`,
                            backgroundColor: theme.bracket.panels.backgroundColor,
                        }}
                    >
                        <Button
                            onClick={() => {
                                onShowScoreModal();
                            }}
                            size="large"
                            type="ghost"
                            style={{ width: '100%' }}
                        >
                            Create new match
                        </Button>
                        {/* <Link to={`/tournament-builder/${tournament.slug}/players`}>
                                        <Button size="large" type="ghost" style={{ width: '100%', color: theme.bracket.panels.textColor }}>
                                            Manage Players
                                        </Button>
                                    </Link> */}
                    </Col>
                </Row>
            )}

            <PlayerModal
                tournament={tournament}
                tournamentID={tournament.id}
                playerId={selectedPlayer.id}
                modalVisible={playerModalVisible}
                onPlayerModalVisible={handlePlayerModalVisible}
            />
        </div>
    );
}

export default ViewerMatches;
