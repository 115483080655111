import React, { useState, useEffect } from 'react';
import { Link, withRouter, Switch, Route } from 'react-router-dom';
import { PageHeader, Icon, Row, Col, Typography, Tabs, Button, Descriptions, Empty } from 'antd';
import Card from '../../../components/Card';
import SectionContent from '../../../components/SectionContent';
import SectionHeader from '../../../components/SectionHeader';
import CircularLoader from '../../../components/CircularLoader';
import LMDivisionCreate from './LMDivisionCreate';
import LMDivisionOverview from './LMDivisionOverview';
import LMDivisionStats from './LMDivisionStats';
import LMDivisionVenues from './LMDivisionVenues';
import LMDivisionStandings from './LMDivisionStandings';
import LMDivisionScores from './LMDivisionScores';
import LMDivisionPlayers from './LMDivisionPlayers';
import LMDivisionPlayerDetail from './LMDivisionPlayerDetail';
import LMDivisionTeams from './LMDivisionTeams';
import LMDivisionTeamDetail from './LMDivisionTeamDetail';
import LMDivisionSchedule from './LMDivisionSchedule2';
import LMDivisionScheduleDetail from './LMDivisionScheduleDetail';
import LMDivisionScheduledMatchesEdit from './LMDivisionScheduledMatchesEdit';
import LMDivisionMatches from './LMDivisionMatches';
import LMDivisionMatchDetail from './LMDivisionMatchDetail';
import LMDivisionScheduleCreate from './LMDivisionScheduleCreate';
import BreadcrumbContainer from '../../../components/BreadcrumbContainer';
import { Query } from '@apollo/client/react/components';
import { GET_LEAGUE_DIVISION_BY_SLUG_QUERY } from '../data/queries';
import useBreakpoint from 'use-breakpoint';
import { defaultBreakpoints } from '../../../utils/defaultBreakpoints';
import * as routes from '../../../config/routes';
import moment from 'moment';
import _ from 'lodash';

const { Text } = Typography;
const { TabPane } = Tabs;

const styles = {
    row: {
        display: 'flex',
        flexGrow: 1,
    },
    tab: {
        paddingTop: 5,
        paddingLeft: 24,
        paddingRight: 24,
        paddingBottom: 24,
    },
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
};

function LMDivisionDetail(props) {
    const { league, history } = props;
    const urlPath = window.location.pathname;
    const params = props.match.params;
    const slug = params.slug;
    const division = params.division;
    const splitPath = urlPath.split('/');
    const dpIncludes = ['overview', 'schedule', 'matches', 'teams', 'players', 'scores', 'standings', 'stats', 'venues'];
    const { breakpoint, maxWidth, minWidth } = useBreakpoint(defaultBreakpoints);

    let pathMatch =
        splitPath[splitPath.length] === slug
            ? splitPath[splitPath.length - 1]
            : _.includes(dpIncludes, splitPath[splitPath.length - 1])
            ? splitPath[splitPath.length - 1]
            : _.includes(dpIncludes, splitPath[splitPath.length - 2])
            ? splitPath[splitPath.length - 2]
            : _.includes(dpIncludes, splitPath[splitPath.length - 3])
            ? splitPath[splitPath.length - 3]
            : _.includes(dpIncludes, splitPath[splitPath.length - 4])
            ? splitPath[splitPath.length - 4]
            : 'overview';

    console.log(splitPath);

    const handleTabClick = (tab) => {
        setCurrent(tab);
        props.history.push(`/league-manager/${slug}/divisions/${division}/${tab}`);
    };

    const [current, setCurrent] = useState(pathMatch);

    useEffect(() => {
        setCurrent(pathMatch);
    }, [pathMatch]);

    return (
        <React.Fragment>
            {/* <SectionHeader
				title={props.title}
				backButton={
					<Button
						onClick={() => props.history.push(`/league-manager/${props.data.slug}/divisions`)}
						type="ghost"
						shape="circle"
						icon="arrow-left"
						size="large"
					/>
				}
			/> */}

            <Query
                query={GET_LEAGUE_DIVISION_BY_SLUG_QUERY}
                fetchPolicy="cache-and-network"
                notifyOnNetworkStatusChange={true}
                variables={{ leagueId: league.id, divisionSlug: props.match.params.division }}
                onCompleted={(data) => {
                    console.log(data);
                }}
            >
                {({ loading, error, data }) => {
                    if (loading) return <CircularLoader />;
                    if (error) return <div style={styles.container}>Error: {error.message}</div>;
                    if (data && !data.league_divisions) {
                        return <div>Division not found.</div>;
                    }
                    if (data && data.league_divisions.length === 0) {
                        return <div>Division not found.</div>;
                    }

                    const divisions = data.league_divisions
                        .map((item, index) => {
                            return {
                                ...item,
                                key: index,
                                is_public: item.is_public === true ? 'Public' : 'Private',
                                is_active: item.is_active === true ? 'Active' : 'Inactive',
                                is_handicapped: item.is_handicapped === true ? 'Yes' : 'No',
                                division_type: _.startCase(_.toLower(item.division_type.replace('_', ' '))),
                                game_types: item.game_types.map((item) => _.startCase(_.toLower(item.game_type.replace('_', ' ')))).join(', '),
                            };
                        })
                        .map((division, index) => {
                            return division;
                        });

                    const division = divisions[0];

                    return (
                        <Switch>
                            <Route
                                exact
                                path={routes.LEAGUE_MANAGER.DETAIL_DIVISION_EDIT}
                                render={(matchProps) => <LMDivisionCreate title="Edit Division" league={league} division={division} {...props} />}
                            />
                            <Route
                                exact
                                path={routes.LEAGUE_MANAGER.DETAIL_DIVISION_SCHEDULED_MATCHES_EDIT}
                                render={(matchProps) => (
                                    <LMDivisionScheduledMatchesEdit {...props} title="Edit Scheduled Matches" league={league} division={division} {...props} />
                                )}
                            />
                            <Route
                                exact
                                path={routes.LEAGUE_MANAGER.DETAIL_DIVISION_SCHEDULE_CREATE}
                                render={(matchProps) => (
                                    <LMDivisionScheduleCreate {...props} title="Create New Division Schedule" league={league} division={division} {...props} />
                                )}
                            />
                            <Route
                                exact
                                path={routes.LEAGUE_MANAGER.DETAIL_DIVISION_SCHEDULE_DETAIL}
                                render={(matchProps) => (
                                    <LMDivisionScheduleDetail {...props} title="Division Schedule" league={league} division={division} {...props} />
                                )}
                            />

                            <Route
                                exact
                                path={routes.LEAGUE_MANAGER.DETAIL_DIVISION_SCHEDULE_EDIT}
                                render={(matchProps) => (
                                    <LMDivisionScheduleCreate {...props} title="Edit Division Schedule" league={league} division={division} {...props} />
                                )}
                            />

                            <Route
                                exact
                                path={routes.LEAGUE_MANAGER.DETAIL_TEAM_DETAIL}
                                render={(matchProps) => <LMDivisionTeamDetail title="Division Team" league={league} division={division} {...props} />}
                            />

                            <Route
                                exact
                                path={routes.LEAGUE_MANAGER.DETAIL_PLAYER_DETAIL}
                                render={(matchProps) => <LMDivisionPlayerDetail title="Division Player" league={league} division={division} {...props} />}
                            />

                            <Route
                                exact
                                path={routes.LEAGUE_MANAGER.DETAIL_PLAYER_DETAIL}
                                render={(matchProps) => <LMDivisionPlayerDetail title="Division Player" league={league} division={division} {...props} />}
                            />

                            <Route
                                exact
                                path={routes.LEAGUE_MANAGER.DETAIL_DIVISION_MATCH}
                                render={(matchProps) => <LMDivisionMatchDetail title="Division Match" league={league} division={division} {...props} />}
                            />

                            <Route
                                path={`/league-manager/${league.slug}/divisions/${division.slug}`}
                                render={(matchProps) => (
                                    <React.Fragment>
                                        <SectionHeader
                                            title={division.name.toUpperCase()}
                                            titleStyle={{ color: '#fff', margin: 0, textTransform: 'uppercase' }}
                                            avatar={league.avatar}
                                            // subtitle={<BreadcrumbContainer theme="dark" />}
                                            subtitle={league.name}
                                            subtitlePrefix={true}
                                            height={116}
                                            subtitleStyle={{
                                                color: 'rgba(255,255,255,0.8)',
                                                textTransform: 'uppercase',
                                                fontSize: 12,
                                                letterSpacing: 3,
                                            }}
                                            // breadCrumb={<BreadcrumbContainer theme="dark" />}
                                            backButton={
                                                <Button
                                                    onClick={() => history.push(`/league-manager/${league.slug}/divisions`)}
                                                    type="ghost"
                                                    shape="circle"
                                                    icon="arrow-left"
                                                    size="large"
                                                    ghost
                                                    style={{ border: 'none' }}
                                                />
                                            }
                                            // height={130}
                                            theme="leagueManager"
                                        >
                                            <Link
                                                to={`/league-manager/${league.slug}/divisions/${props.match.params.division}/edit`}
                                                style={{ marginBottom: '0.5em' }}
                                                key="edit"
                                            >
                                                <Button type="ghost" ghost>
                                                    <Icon type="edit" /> Edit
                                                </Button>
                                            </Link>
                                            <Link to={`/leagues/${league.slug}/divisions/${props.match.params.division}`} style={{ marginLeft: 10 }}>
                                                <Button type="ghost" ghost>
                                                    Public Profile
                                                    <Icon type="arrow-right" />
                                                </Button>
                                            </Link>
                                        </SectionHeader>

                                        <Tabs
                                            defaultActiveKey={current}
                                            activeKey={current}
                                            animated={{ inkBar: true, tabPane: false }}
                                            // renderTabBar={() => <ScrollableInkTabBar />}
                                            // renderTabContent={() => <TabContent />}
                                            onTabClick={handleTabClick}
                                            className="tabs-bg-orange"
                                            tabPosition="top"
                                            tabBarStyle={{
                                                textTransform: 'uppercase',
                                                paddingLeft: breakpoint === 'mobile' ? 0 : 24,
                                                paddingRight: breakpoint === 'mobile' ? 0 : 24,
                                                paddingTop: 0,
                                                background: '#0b1a2d',
                                                // background: 'linear-gradient(#0b1a2d, #13253c)',
                                                boxShadow: 'rgba(0, 0, 0, 0.05) 0px 2px 8px 0px',
                                                color: '#fff',
                                                zIndex: 1,
                                                position: 'relative',
                                                borderBottom: 'none',
                                                borderTop: '1px solid rgba(255,255,255,0.1)',
                                            }}
                                        >
                                            <TabPane tab="Overview" key="overview">
                                                <LMDivisionOverview title="Division Overview" league={league} division={division} {...props} />
                                            </TabPane>
                                            <TabPane tab={`Schedule (${division.league_schedules_aggregate.aggregate.count})`} key="schedule">
                                                <LMDivisionSchedule title="Division Schedule" league={league} division={division} {...props} />
                                            </TabPane>
                                            <TabPane tab={`Matches (${division.league_matches_aggregate.aggregate.count})`} key="matches">
                                                <LMDivisionMatches title="Division Matches" league={league} division={division} {...props} />
                                            </TabPane>
                                            <TabPane tab={`Teams (${division.league_team_divisions_aggregate.aggregate.count})`} key="teams">
                                                <LMDivisionTeams title="Division Teams" league={league} division={division} {...props} />
                                            </TabPane>
                                            <TabPane tab={`Players (${division.league_player_divisions_aggregate.aggregate.count})`} key="players">
                                                <LMDivisionPlayers title="Division Players" league={league} division={division} {...props} />
                                            </TabPane>
                                            <TabPane tab="Live Scores" key="scores">
                                                <LMDivisionScores title="Division Scores" league={league} division={division} {...props} />
                                            </TabPane>
                                            <TabPane tab="Standings" key="standings">
                                                <LMDivisionStandings title="Division Standings" league={league} division={division} {...props} />
                                            </TabPane>
                                            <TabPane tab="Stats" key="stats">
                                                <LMDivisionStats title="Division Stats" league={league} division={division} {...props} />
                                            </TabPane>
                                            <TabPane tab="Venues" key="venues">
                                                <LMDivisionVenues title="Division Venues" league={league} division={division} {...props} />
                                            </TabPane>
                                        </Tabs>
                                    </React.Fragment>
                                )}
                            />
                        </Switch>
                    );
                }}
            </Query>
        </React.Fragment>
    );
}

export default withRouter(LMDivisionDetail);
