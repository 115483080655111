import React, { useState, useEffect } from 'react';
import { Avatar, Typography, Row, Col, Button, Empty, Icon } from 'antd';
import { withRouter, Link } from 'react-router-dom';
import Card, { Meta } from '../../../components/Card';
import PoolTable from '../../../components/PoolTable';
import intlOpenLogo from '../../../assets/img/intlopen-logo.png';
import poweredByLogo from '../../../assets/img/powered-by-logo.png';
import CircleFlag from '../../../components/CircleFlag';
import CircularLoader from '../../../components/CircularLoader';
import CountdownTimerProgress from '../../../components/CountdownTimerProgress';
import ZeroState from '../../../components/ZeroState';
import Fade from 'react-reveal/Fade';

import { Query, Subscription } from '@apollo/client/react/components';
import { GET_EVENT_BY_SLUG_QUERY } from '../data/queries';
import { GET_EVENT_LIVE_TABLE_SCORES_SUBSCRIPTION } from '../data/subscriptions';
import _ from 'lodash';
import SwitchComponents from './SwitchComponents';
import moment from 'moment-timezone';
import momentDurationFormatSetup from 'moment-duration-format';
momentDurationFormatSetup(moment);

const { Text, Title } = Typography;
const styles = {
    container: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 20,
    },
};

function EventTableLiveScores2(props) {
    const { match, authState } = props;
    const params = match.params;
    const eventSlug = params.slug;
    let defaultGroupCount = 6;
    const [matches, setMatches] = useState([]);
    const [activeComponent, setActiveComponent] = useState(1);
    const [isPlaying, setIsPlaying] = useState(false);
    const [totalSlides, setTotalSlides] = useState(1);
    const [slides, setSlides] = useState([]);
    const toggleIntervalSecs = 7;

    useEffect(() => {
        const interval = setInterval(() => {
            setIsPlaying(false);
            if (activeComponent === totalSlides) {
                setActiveComponent(1);
            } else {
                setActiveComponent(activeComponent + 1);
            }
        }, toggleIntervalSecs * 1000); // rotate tables every 10 seconds
        setIsPlaying(true);
        return () => {
            clearInterval(interval);
        };
    });

    function renderTable(table, match, eventData) {
        let duration;
        if (match.start_time && match.end_time) {
            var start = moment(match.start_time);
            var end = moment(match.end_time);
            duration = moment.duration(end.diff(start, 'milliseconds')).format('h [hrs] m [min]');
        } else {
            // duration = moment.duration(moment.utc().diff(match.start_time, 'milliseconds')).format('h [hrs] m [min]');
            // duration = moment.duration(moment.utc().diff(match.start_time, 'milliseconds')).format('h [hours] m [minutes]');
            duration = match.start_time ? moment(match.start_time).fromNow() : '0 mins';
        }
        var zone_name = moment.tz.guess();
        var timezone = moment.tz(zone_name).zoneAbbr();

        return (
            <div
                style={{
                    display: 'flex',
                    flex: 1,
                    flexDirection: 'column',
                    width: '100%',
                    opacity: (table.status === 'OPEN' && !match) || (match && match.status !== 'IN_PROGRESS') ? 0.5 : 1.0,
                }}
            >
                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        padding: 5,
                        flex: 1,
                    }}
                >
                    <div>
                        <Text style={{ color: '#fff', fontSize: 24, fontWeight: 700 }}>{table.label}</Text>
                    </div>
                    <div style={{ marginTop: 8, textAlign: 'right', display: 'flex', alignItems: 'center' }}>
                        {match && (
                            <div>
                                <Text
                                    style={{
                                        color: 'rgba(255,255,255,0.7)',
                                        fontSize: 16,
                                        fontWeight: 700,
                                    }}
                                >
                                    Match {match.match_number}
                                </Text>
                            </div>
                        )}
                        <span style={{ marginLeft: 5, marginRight: 5 }}>-</span>

                        <Text style={{ color: '#ccc', fontSize: 16, fontWeight: 700 }}>
                            {match.scheduled_time
                                ? `Scheduled: ${moment.utc(match.scheduled_time).local().format('h:mm A z')} ${timezone}`
                                : match.status === 'COMPLETED'
                                ? `Ended: ${moment.utc(match.end_time).local().format('h:mm A z')} ${timezone}`
                                : `Started: ${moment.utc(match.start_time).local().format('h:mm A z')} ${timezone}`}
                        </Text>
                    </div>
                </div>
                <PoolTable
                    displayText={!match && table.status}
                    table={{
                        label: table.label,
                        is_streaming_table: table.is_streaming_table,
                        status: table.status,
                    }}
                    hoverable
                    onClick={() => {
                        props.history.push(`/events/${eventData.slug}/tables/${table.slug}/scoring`);
                    }}
                    matchData={
                        match
                            ? {
                                  game_type: match.game_type,
                                  match_number: match.match_number,
                                  challenger1_name: match.challenger1_name,
                                  challenger1_country: match.challenger1_country,
                                  // challenger1_skill_level: '7',
                                  challenger1_race_to: match.challenger1_race_to,
                                  challenger1_score: match.challenger1_score,
                                  challenger1_points: match.challenger1_points,
                                  challenger2_name: match.challenger2_name,
                                  challenger2_country: match.challenger2_country,
                                  // challenger2_skill_level: '5',
                                  challenger2_race_to: match.challenger2_race_to,
                                  challenger2_score: match.challenger2_score,
                                  challenger2_points: match.challenger2_points,
                              }
                            : {}
                    }
                    tournament={{
                        show_player_races: false,
                        show_player_skill_levels: false,
                    }}
                    status={match && match.status}
                    gameType={eventData.game_type}
                    tableSize="large"
                    style={{ cursor: 'pointer' }}
                    playerStyle={{ marginLeft: 10, fontSize: 35, minWidth: 60, textShadow: '0 2px 2px rgba(0,0,0,0.50)' }}
                    {...props}
                />
            </div>
        );
    }

    return (
        <div
            style={{
                // display: 'flex',
                // flexDirection: 'column',
                // flex: 1,
                minHeight: '100%',
                // height: '100%',
                justifyContent: 'center',
                background: 'linear-gradient(180deg, #3E5267 0%, #1F3349 21%, #121A27 100%)',
                color: '#fff',
                display: 'flex',
                height: 'auto',
            }}
        >
            <div
                style={{
                    position: 'absolute',
                    top: 0,
                    paddingTop: 10,
                    paddingBottom: 10,
                    paddingLeft: 10,
                    paddingRight: 20,
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    zIndex: 1,
                    // boxShadow: 'rgb(0 0 0 / 20%) 0px 1px 15px 0px'
                }}
            >
                <div>
                    <Button
                        type="link"
                        icon="arrow-left"
                        size="large"
                        ghost
                        style={{ border: 'none' }}
                        onClick={() => {
                            props.history.push(`/events/${eventSlug}/scores`);
                        }}
                    >
                        <Text
                            style={{
                                color: '#fff',
                                textTransform: 'uppercase',
                                fontSize: 16,
                                fontWeight: 500,
                                letterSpacing: 1,
                            }}
                        >
                            Live table scores
                        </Text>
                    </Button>
                </div>
                <div>{isPlaying && <CountdownTimerProgress isPlaying={isPlaying} duration={toggleIntervalSecs} />}</div>
            </div>

            <Query query={GET_EVENT_BY_SLUG_QUERY} fetchPolicy="cache-and-network" notifyOnNetworkStatusChange={true} variables={{ eventSlug: eventSlug }}>
                {({ loading, error, data, refetch }) => {
                    if (loading) return <CircularLoader />;
                    if (error) return <div style={styles.container}>Error: {error.message}</div>;

                    const eventData = data.events && data.events[0];
                    if (!eventData) {
                        return (
                            <Row gutter={24} justify="center" type="flex">
                                <Col xs={24}>
                                    <Card style={{ margin: 10 }}>
                                        <ZeroState showImage message="Event not found" style={{ margin: 30 }} {...props} />
                                    </Card>
                                </Col>
                            </Row>
                        );
                    } else {
                        return (
                            <div
                                style={{
                                    // flex: 1,
                                    alignItems: 'center',
                                    // padding: 20,
                                    // display: 'flex',
                                    // justifyContent: 'center',
                                    // flexDirection: 'column',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between',
                                    transform: 'scale(0.95)',
                                    transformOrigin: '50% 0px',
                                    position: 'relative',
                                }}
                            >
                                {eventData.logo ? (
                                    <div
                                        style={{
                                            paddingTop: 30,
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            width: '100%',
                                            marginBottom: 10,
                                        }}
                                    >
                                        <img src={eventData.logo} alt="Logo" height={130} />
                                    </div>
                                ) : (
                                    <div />
                                )}

                                <Subscription
                                    subscription={GET_EVENT_LIVE_TABLE_SCORES_SUBSCRIPTION}
                                    fetchPolicy="cache-and-network"
                                    notifyOnNetworkStatusChange={true}
                                    variables={{ event_id: eventData.id }}
                                    onSubscriptionData={(res, error, loading) => {
                                        let matches = [];
                                        const events = res && res.subscriptionData && res.subscriptionData.data && res.subscriptionData.data.events;
                                        events &&
                                            events.forEach((event) => {
                                                const eventMatches = event.matches;
                                                const eventTournaments = event.tournaments;

                                                eventMatches.forEach((match) => {
                                                    if (match.pool_table && match.pool_table.matches) {
                                                        match.pool_table.matches.forEach((item) => {
                                                            matches.push({
                                                                table: match.pool_table,
                                                                ...item,
                                                            });
                                                        });
                                                    }
                                                });

                                                eventTournaments.forEach((tournament) => {
                                                    if (tournament.pool_tables) {
                                                        tournament.pool_tables.forEach((table) => {
                                                            if (table.tournament_match_table && table.tournament_match_table[0]) {
                                                                matches.push({
                                                                    table,
                                                                    ...table.tournament_match_table[0],
                                                                });
                                                            }
                                                        });
                                                    }
                                                });
                                            });

                                        const uniqueMatches = _.uniqBy(matches, 'id').sort((a, b) => {
                                            if (a.table_name && b.table_name) {
                                                return a.table_name.localeCompare(b.table_name, undefined, {
                                                    numeric: true,
                                                    sensitivity: 'base',
                                                });
                                            } else if (a.table.label && b.table.label) {
                                                return a.table.label.localeCompare(b.table.label, undefined, {
                                                    numeric: true,
                                                    sensitivity: 'base',
                                                });
                                            }
                                        });

                                        const perGroup = defaultGroupCount;
                                        const totalGroups = Math.round(uniqueMatches.length / perGroup) || 1;
                                        setTotalSlides(totalGroups);
                                        setMatches(uniqueMatches);
                                        let matchGroups = [];
                                        console.log(matches);
                                        console.log(totalGroups);
                                        for (var i = 0; i < totalGroups; i++) {
                                            let matches;
                                            if (i === 0) {
                                                matches = uniqueMatches.slice(0, perGroup);
                                            } else {
                                                matches = uniqueMatches.slice(i + perGroup - 1, perGroup * 2);
                                            }
                                            matchGroups.push(matches);
                                        }
                                        console.log(matchGroups);
                                        setSlides(matchGroups);
                                    }}
                                >
                                    {({ loading, error, data }) => {
                                        if (loading)
                                            return (
                                                <div style={{ padding: 50 }}>
                                                    <CircularLoader />
                                                </div>
                                            );
                                        if (error) return <div style={styles.container}>Error: {error.message}</div>;
                                        if ((data && !matches) || (data && matches && matches.length === 0)) {
                                            return (
                                                <Row gutter={24} justify="center" type="flex">
                                                    <Col xs={24}>
                                                        <div style={styles.container}>
                                                            <Title
                                                                level={1}
                                                                style={{
                                                                    padding: 20,
                                                                    margin: 0,
                                                                    color: '#fff',
                                                                    background: 'rgba(255,255,255,0.05)',
                                                                    borderRadius: 20,
                                                                }}
                                                            >
                                                                Waiting for live matches
                                                            </Title>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            );
                                        }

                                        // let matchGroups = uniqueMatches.reduce((resultArray, item, index) => {
                                        //    const groupIndex = Math.floor(index / perGroup);
                                        //    if (!resultArray[groupIndex]) {
                                        //        resultArray[groupIndex] = []; // start a new chunk
                                        //    }
                                        //    resultArray[groupIndex].push(item);
                                        //    return resultArray;
                                        //}, []);

                                        return (
                                            <SwitchComponents active={activeComponent}>
                                                {slides &&
                                                    slides.map((group, index) => {
                                                        return (
                                                            <Row
                                                                gutter={24}
                                                                type="flex"
                                                                justify="center"
                                                                style={{ width: '100%' }}
                                                                name={index + 1}
                                                                key={index}
                                                            >
                                                                {group.map((match, index) => {
                                                                    const table = match.table;

                                                                    if (match) {
                                                                        let formattedMatch;
                                                                        if (match.player_name || match.opponent_name) {
                                                                            formattedMatch = {
                                                                                ...match,
                                                                                challenger1_id: match.player_id,
                                                                                challenger1_name: match.player_name,
                                                                                challenger1_country: match.player_country,
                                                                                challenger1_score: match.player_score,
                                                                                challenger2_id: match.opponent_id,
                                                                                challenger2_name: match.opponent_name,
                                                                                challenger2_country: match.opponent_country,
                                                                                challenger2_score: match.opponent_score,
                                                                            };
                                                                        } else {
                                                                            formattedMatch = { ...match };
                                                                        }

                                                                        if (group.length === 1) {
                                                                            return (
                                                                                <Col key={index} xs={16}>
                                                                                    <Fade>{renderTable(table, formattedMatch, eventData)}</Fade>
                                                                                </Col>
                                                                            );
                                                                        } else {
                                                                            return (
                                                                                <Col key={index} xs={24} sm={12} md={12} lg={8} xl={8}>
                                                                                    <Fade>{renderTable(table, formattedMatch, eventData)}</Fade>
                                                                                </Col>
                                                                            );
                                                                        }
                                                                    }
                                                                })}
                                                            </Row>
                                                        );
                                                    })}
                                            </SwitchComponents>
                                        );
                                    }}
                                </Subscription>

                                <Row gutter={16} type="flex" justify="space-between" style={{ marginTop: 15 }}>
                                    <Col xs={24} align="center">
                                        <div>
                                            <img src={poweredByLogo} alt="Powered By" width={300} />
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        );
                    }
                }}
            </Query>
        </div>
    );
}

export default withRouter(EventTableLiveScores2);

// if (uniqueMatches && uniqueMatches.length === 1) {
//     return (
//         <Row gutter={24} type="flex" justify="center" style={{ width: '100%' }}>
//             {uniqueMatches
//                 .sort((a, b) => {
//                     if (a.table_name && b.table_name) {
//                         return a.table_name.localeCompare(b.table_name, undefined, {
//                             numeric: true,
//                             sensitivity: 'base',
//                         });
//                     } else if (a.table.label && b.table.label) {
//                         return a.table.label.localeCompare(b.table.label, undefined, {
//                             numeric: true,
//                             sensitivity: 'base',
//                         });
//                     }
//                 })
//                 .map((match, index) => {
//                     const table = match.table;

//                     if (match) {
//                         let formattedMatch;
//                         if (match.player_name || match.opponent_name) {
//                             formattedMatch = {
//                                 ...match,
//                                 challenger1_id: match.player_id,
//                                 challenger1_name: match.player_name,
//                                 challenger1_country: match.player_country,
//                                 challenger1_score: match.player_score,
//                                 challenger2_id: match.opponent_id,
//                                 challenger2_name: match.opponent_name,
//                                 challenger2_country: match.opponent_country,
//                                 challenger2_score: match.opponent_score,
//                             };
//                         } else {
//                             formattedMatch = { ...match };
//                         }

//                         return (
//                             <Col key={index} xs={20}>
//                                 {renderTable(table, formattedMatch, eventData)}
//                             </Col>
//                         );
//                     }
//                 })}
//         </Row>
//     );
// } else {
//     return (
//         <Row gutter={24} type="flex" justify="center" style={{ width: '100%' }}>
//             {uniqueMatches.map((match, index) => {
//                 const table = match.table;

//                 if (match) {
//                     let formattedMatch;
//                     if (match.player_name || match.opponent_name) {
//                         formattedMatch = {
//                             ...match,
//                             challenger1_id: match.player_id,
//                             challenger1_name: match.player_name,
//                             challenger1_country: match.player_country,
//                             challenger1_score: match.player_score,
//                             challenger2_id: match.opponent_id,
//                             challenger2_name: match.opponent_name,
//                             challenger2_country: match.opponent_country,
//                             challenger2_score: match.opponent_score,
//                         };
//                     } else {
//                         formattedMatch = { ...match };
//                     }

//                     return (
//                         <Col key={index} xs={24} sm={12} md={12} lg={8} xl={8} xxl={6}>
//                             {renderTable(table, formattedMatch, eventData)}
//                         </Col>
//                     );
//                 }
//             })}
//         </Row>
//     );
// }
