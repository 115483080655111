import React, { useState, useEffect, useRef } from 'react';
import { Link, withRouter, Route } from 'react-router-dom';
import { Empty, Table, Typography, Button } from 'antd';
import Card from '../../../components/Card';
import { Query } from '@apollo/client/react/components';
import { displayEnumType } from '../../../utils';
import ZeroState from '../../../components/ZeroState';
import CircularLoader from '../../../components/CircularLoader';
import { GET_MATCHES_QUERY, GET_MATCHES_MANAGERS_QUERY } from './data/queries';
import moment from 'moment';

const { Text, Title } = Typography;

const styles = {};

export default function MatchTable(props) {
    const { authState } = props;

    const columns = [
        {
            title: 'Date',
            dataIndex: 'updated_at',
            defaultSortOrder: 'desc',
            width: 100,
            sorter: (a, b) =>
                b.updated_at.localeCompare(a.updated_at, undefined, {
                    numeric: true,
                    sensitivity: 'base',
                }),

            render: (text, record) => {
                console.log(record);
                return <Text>{moment(text).format('lll')}</Text>;
            },
        },
        {
            title: 'Name',
            dataIndex: 'name',
            sorter: (a, b) =>
                b.name.localeCompare(a.name, undefined, {
                    numeric: true,
                    sensitivity: 'base',
                }),
            render: (text, record) => {
                return <Text>{text || '-'}</Text>;
            },
        },

        {
            title: 'Players',
            dataIndex: 'players',
            render: (text, record) => {
                return <Text>{text || '-'}</Text>;
            },
        },
        {
            title: 'Race to',
            dataIndex: 'race_to',
            render: (text, record) => {
                return <Text>{text || '-'}</Text>;
            },
        },
        {
            title: 'Score',
            dataIndex: 'score',
            render: (text, record) => {
                return <Text>{text || '-'}</Text>;
            },
        },

        {
            title: 'Match type',
            dataIndex: 'match_type',
            render: (text, record) => {
                return <Text>{record.match_type ? displayEnumType(record.match_type) : '-'}</Text>;
            },
        },
        {
            title: 'Game type',
            dataIndex: 'game_type',
            render: (text, record) => {
                return <Text>{record.game_type ? displayEnumType(record.game_type) : '-'}</Text>;
            },
        },
        {
            title: 'Table size',
            dataIndex: 'table_size',
            render: (text, record) => {
                return <Text>{text || '-'}</Text>;
            },
        },
        {
            title: 'Location',
            dataIndex: 'venue',
            render: (text, record) => {
                return (
                    <React.Fragment>
                        {record.venue ? (
                            <React.Fragment>
                                <Text style={{ fontWeight: 'bold' }}>{record.venue && record.venue.name}</Text>
                                <br />
                                <Text>
                                    {record.venue && record.venue.city}, {record.venue && record.venue.region}
                                </Text>
                            </React.Fragment>
                        ) : (
                            '-'
                        )}
                    </React.Fragment>
                );
            },
        },
        {
            title: 'Status',
            dataIndex: 'status',
            render: (text, record) => {
                return <Text>{text || '-'}</Text>;
            },
        },
    ];

    return (
        <React.Fragment>
            {authState && authState.user && authState.user.id ? (
                <Query
                    query={GET_MATCHES_MANAGERS_QUERY}
                    fetchPolicy="cache-and-network"
                    notifyOnNetworkStatusChange
                    awaitRefetchQueries
                    variables={{
                        user_id: authState && authState.user && authState.user.id,
                    }}
                    onCompleted={(data) => {
                        // console.log(data);
                    }}
                >
                    {({ loading, error, data }) => {
                        if (loading) return <CircularLoader />;
                        if (error) return <div style={styles.container}>Error: {error.message}</div>;
                        if ((data && !data.match_managers) || (data && data.match_managers.length === 0)) {
                            return <ZeroState showImage message="No Matches found." style={{ margin: 30 }} {...props} />;
                        }

                        let creatorMatches = [];
                        let combined = [];

                        if (data.match_managers[0].matches.length) {
                            creatorMatches = data.match_managers[0].matches.map((match, index) => {
                                return {
                                    ...match,
                                    key: index,
                                    name: match.name,
                                    slug: match.slug,
                                    players: `${match.player_name} vs. ${match.opponent_name}`,
                                    race_to: match.race_to,
                                    score: `${match.player_score || 0}-${match.opponent_score || 0}`,
                                    match_type: match.match_type,
                                    venue: match.venue,
                                    table_size: match.table_size,
                                    scheduled_time: match.scheduled_time,
                                    status: match.status,
                                };
                            });
                        }

                        const matches = data.match_managers.map((manager, index) => {
                            const match = manager.match;

                            if (match) {
                                return {
                                    ...match,
                                    key: index,
                                    name: match.name,
                                    slug: match.slug,
                                    players: `${match.player_name} vs. ${match.opponent_name}`,
                                    race_to: match.race_to,
                                    score: `${match.player_score || 0}-${match.opponent_score || 0}`,
                                    match_type: match.match_type,
                                    venue: match.venue,
                                    table_size: match.table_size,
                                    scheduled_time: match.scheduled_time,
                                    status: match.status,
                                };
                            }
                        });

                        let filter = matches.filter((ele) => {
                            for (let i = 0; i < creatorMatches; i++) {
                                let creatorMatch = creatorMatches[i];

                                if (ele.creator_id === creatorMatch.user_id) {
                                    return true;
                                }
                            }
                        });

                        combined = creatorMatches.concat(filter);

                        return (
                            <div className="table_nowrap">
                                <Table
                                    onRow={(record, rowIndex) => {
                                        return {
                                            onClick: (event) => {
                                                console.log(record, rowIndex);
                                                props.history.push({
                                                    pathname: `/matches/${record.slug}`,
                                                    // search: '?query=abc',
                                                    state: { data: record },
                                                });
                                            }, // click row
                                            onDoubleClick: (event) => {}, // double click row
                                            onContextMenu: (event) => {}, // right button click row
                                            onMouseEnter: (event) => {}, // mouse enter row
                                            onMouseLeave: (event) => {}, // mouse leave row
                                        };
                                    }}
                                    columns={columns}
                                    dataSource={combined}
                                    pagination={false}
                                    tableLayout="auto"
                                    scroll={{ x: 400 }}
                                />
                            </div>
                        );
                    }}
                </Query>
            ) : (
                <Card>
                    <ZeroState message="You must be logged in to create a match." {...props}>
                        <Link to="/login">
                            <Button type="primary" size="large">
                                Log In
                            </Button>
                        </Link>
                        <Link to="/register" style={{ marginLeft: 10 }}>
                            <Button size="large">Create Free Account</Button>
                        </Link>
                    </ZeroState>
                </Card>
            )}
        </React.Fragment>
    );
}
