import { store } from 'react-easy-state';
// import * as api from "./api";

// use 'appStore' instead of 'this' in the store methods to make them passable as callbacks
const appStore = store({
	notificationCount: 0,
	notifications: [],
	activePlayer: null,
	activePlayerMatchIdentifier: null,
	searchedPlayer: null
	// async fetchBeers(filter) {
	//   appStore.isLoading = true;
	//   appStore.beers = await api.fetchBeers(filter);
	//   appStore.isLoading = false;
	// }
});

export default appStore;
