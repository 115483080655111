import React from 'react';
import PropTypes from 'prop-types';
import { Circle } from 'rc-progress';
import CountUp from 'react-countup';
import { Row, Col, Typography, Statistic } from 'antd';

const { Text } = Typography;

const styles = {};

export default function SmallStat(props) {
	const { centered, title, winPercentage, strokeWidth, strokeColor, trailWidth, trailColor } = props;

	return (
		<div style={centered && { display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
			<Text>{title}</Text>
			<div
				style={{
					marginTop: 10,
					marginBottom: 10,
					width: 110,
					position: 'relative',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center'
				}}
			>
				<div style={{ position: 'absolute', textAlign: 'center' }}>
					<h2 style={{ margin: 0 }}>
						<CountUp end={winPercentage} suffix="%" duration={2.75} />
					</h2>
					<Text>Won</Text>
				</div>
				<Circle
					percent={winPercentage}
					strokeWidth={strokeWidth || 4}
					trailWidth={trailWidth || 4}
					trailColor={trailColor || '#fff6cf'}
					strokeColor={strokeColor || '#ffcd00'}
					width={110}
				/>
			</div>

			<Row gutter={14} type="flex" justify="center">
				<Col xs={12} sm={12} md={12} lg={12}>
					<Statistic title="Wins" value={120} />
					{/* <Statistic title="Wins" value={120} suffix={<CountUp end={65} suffix="%" duration={2.75} />} /> */}
				</Col>
				<Col xs={12} sm={12} md={12} lg={12}>
					<Statistic title="Losses" value={82} />
					{/* <Statistic title="Losses" value={82} suffix={<CountUp end={35} suffix="%" duration={2.75} />} /> */}
				</Col>
			</Row>
		</div>
	);
}
