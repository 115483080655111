import React from 'react';
import { withRouter } from 'react-router-dom';
import { message, Tabs, Descriptions, Row, Avatar, List, Skeleton, Button, Empty, Icon, Col, Typography } from 'antd';
import { Link } from 'react-router-dom';
import SectionTitle from '../../../components/SectionTitle';
import SectionContent from '../../../components/SectionContent';
import SectionWrapper from '../../../components/SectionWrapper';
import SectionFooter from '../../../components/SectionFooter';
import ZeroState from '../../../components/ZeroState';
import { getGameType, displayEnumType, getCountryName, formatPlayerName, getOrdinalSuffix } from '../../../utils';
import CircularLoader from '../../../components/CircularLoader';
import CircleFlag from '../../../components/CircleFlag';
import Card, { Meta } from '../../../components/Card';
// import DPDivisionCard from './DPDivisionCard';
// import DPTournamentSchedule from '../tournaments/DPTournamentSchedule';
import { Query } from '@apollo/client/react/components';
import {
    GET_LEAGUE_MATCHES_QUERY,
    GET_UPCOMING_LEAGUE_MATCHES_QUERY,
    GET_LEAGUE_DIVISION_TEAMS_QUERY,
    GET_LEAGUE_DIVISION_PLAYERS_QUERY,
    GET_LEAGUE_DIVISION_VENUES_QUERY,
} from '../data/queries';
import blueBg from '../../../assets/img/blue-bg.png';
import defaultImg from '../../../assets/img/ball/default.png';
import _ from 'lodash';
import moment from 'moment-timezone';
import momentDurationFormatSetup from 'moment-duration-format';
momentDurationFormatSetup(moment);

const { Title, Text } = Typography;
const { TabPane } = Tabs;

const styles = {};

function LMDivisionOverview(props) {
    const { league, division, history } = props;
    console.log(props);

    var zone_name = moment.tz.guess();
    var timezone = moment.tz(zone_name).zoneAbbr();

    return (
        <SectionWrapper>
            <SectionContent padding="20px 40px">
                <Row gutter={16}>
                    <Col xs={24}>
                        <Row gutter={24} justify="end" type="flex">
                            <Col xs={24} lg={8}>
                                <Card
                                    title="Overview"
                                    bodyStyle={{ padding: 0 }}
                                    style={{
                                        borderRadius: 8,
                                        overflow: 'hidden',
                                        border: 'none',
                                        boxShadow: '0 2px 5px 0px rgba(0, 0, 0, 0.1)',
                                    }}
                                >
                                    <Row gutter={8} type="flex" justify="start">
                                        <div
                                            style={{
                                                padding: '12px 24px',
                                            }}
                                        >
                                            <Row gutter={24}>
                                                <Col xs={24}>
                                                    <Tabs
                                                        defaultActiveKey="details"
                                                        animated={{ inkBar: true, tabPane: false }}
                                                        className="no-scroll tabs-bg2"
                                                        tabBarStyle={{
                                                            textTransform: 'uppercase',
                                                        }}
                                                    >
                                                        <TabPane tab="Details" key="details">
                                                            <Row gutters={[16, 16]}>
                                                                <Col xs={24}>
                                                                    <table width="100%" className="info-table">
                                                                        <tbody>
                                                                            <tr>
                                                                                <td>Name</td>
                                                                                <td>{division.name}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Description</td>
                                                                                <td className="display-linebreak">{division.description || '-'}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Games played</td>
                                                                                <td>{division.game_types}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Start date</td>
                                                                                <td>
                                                                                    {division.start_date
                                                                                        ? `${moment(division.start_date).format('llll')} (${timezone})`
                                                                                        : '-'}
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>End date</td>
                                                                                <td>
                                                                                    {division.end_date
                                                                                        ? `${moment(division.end_date).format('llll')} (${timezone})`
                                                                                        : '-'}
                                                                                </td>
                                                                            </tr>

                                                                            <tr>
                                                                                <td>Scoring format</td>
                                                                                <td>
                                                                                    {division && division.scoring_format
                                                                                        ? displayEnumType(division.scoring_format)
                                                                                        : ''}
                                                                                </td>
                                                                            </tr>
                                                                            {division.scoring_format === 'RACE_TO' ? (
                                                                                <tr>
                                                                                    <td>Race to</td>
                                                                                    <td>{division.race_to_num || '-'}</td>
                                                                                </tr>
                                                                            ) : (
                                                                                <tr>
                                                                                    <td>Best of</td>
                                                                                    <td>{division.best_of || '-'}</td>
                                                                                </tr>
                                                                            )}
                                                                            <tr>
                                                                                <td>Winner determiend by</td>
                                                                                <td>
                                                                                    {division.winner_determined_by
                                                                                        ? displayEnumType(division.winner_determined_by)
                                                                                        : ''}
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Season</td>
                                                                                <td>{division.season || '-'}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Season length</td>
                                                                                <td>{division.num_weeks || '-'}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Current week</td>
                                                                                <td>{division.week || '-'}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Session ID</td>
                                                                                <td>{division.session_id || '-'}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Handicapped</td>
                                                                                <td>{division.is_handicapped || '-'}</td>
                                                                            </tr>

                                                                            <tr>
                                                                                <td>Access</td>
                                                                                <td>{division.is_public ? 'Public' : 'Private'}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Active</td>
                                                                                <td>{division.is_active ? 'Yes' : 'No'}</td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </Col>
                                                            </Row>
                                                        </TabPane>

                                                        <TabPane tab={`Teams`} key="teams">
                                                            <Query
                                                                query={GET_LEAGUE_DIVISION_TEAMS_QUERY}
                                                                fetchPolicy="cache-and-network"
                                                                notifyOnNetworkStatusChange={true}
                                                                variables={{ league_id: league.id, division_id: division.id }}
                                                                onCompleted={(data) => {
                                                                    console.log(data);
                                                                }}
                                                            >
                                                                {({ loading, error, data }) => {
                                                                    if (loading)
                                                                        return (
                                                                            <div style={styles.container}>
                                                                                <CircularLoader />
                                                                            </div>
                                                                        );
                                                                    if (error) return <div style={styles.container}>Error: {error.message}</div>;
                                                                    if ((data && !data.league_teams) || (data && data.league_teams.length === 0)) {
                                                                        return (
                                                                            <ZeroState
                                                                                showImage
                                                                                message="No teams have been added yet."
                                                                                style={{ margin: 30 }}
                                                                                {...props}
                                                                            />
                                                                        );
                                                                    }

                                                                    const teams = data.league_teams;

                                                                    return (
                                                                        <div>
                                                                            <List
                                                                                className="hover-list"
                                                                                size="small"
                                                                                loading={loading}
                                                                                dataSource={teams}
                                                                                renderItem={(item) => {
                                                                                    const challengerCountry = getCountryName(item.country);

                                                                                    return (
                                                                                        <List.Item
                                                                                            onClick={() =>
                                                                                                props.history.push(
                                                                                                    `/league-manager/${league.slug}/divisions/${division.slug}/teams/${item.slug}`
                                                                                                )
                                                                                            }
                                                                                            style={{
                                                                                                justifyContent: 'flex-start',
                                                                                                padding: item.country ? '10px 10px' : '10px 10px',
                                                                                            }}
                                                                                        >
                                                                                            {challengerCountry && (
                                                                                                <CircleFlag country={challengerCountry} left={0} />
                                                                                            )}

                                                                                            <span>{formatPlayerName(item)} </span>
                                                                                        </List.Item>
                                                                                    );
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    );
                                                                }}
                                                            </Query>
                                                        </TabPane>

                                                        <TabPane tab={`Players`} key="players">
                                                            <Query
                                                                query={GET_LEAGUE_DIVISION_PLAYERS_QUERY}
                                                                fetchPolicy="cache-and-network"
                                                                notifyOnNetworkStatusChange={true}
                                                                variables={{ league_id: league.id, division_id: division.id }}
                                                                onCompleted={(data) => {
                                                                    console.log(data);
                                                                }}
                                                            >
                                                                {({ loading, error, data }) => {
                                                                    if (loading)
                                                                        return (
                                                                            <div style={styles.container}>
                                                                                <CircularLoader />
                                                                            </div>
                                                                        );
                                                                    if (error) return <div style={styles.container}>Error: {error.message}</div>;
                                                                    if ((data && !data.league_players) || (data && data.league_players.length === 0)) {
                                                                        return (
                                                                            <ZeroState
                                                                                showImage
                                                                                message="No players have been added yet."
                                                                                style={{ margin: 30 }}
                                                                                {...props}
                                                                            />
                                                                        );
                                                                    }

                                                                    const players = data.league_players;

                                                                    return (
                                                                        <div>
                                                                            <List
                                                                                size="small"
                                                                                dataSource={players}
                                                                                renderItem={(item) => {
                                                                                    const challengerCountry = getCountryName(item.country);

                                                                                    return (
                                                                                        <List.Item
                                                                                            style={{
                                                                                                justifyContent: 'flex-start',
                                                                                                padding: item.country ? '10px 10px' : '10px 10px',
                                                                                            }}
                                                                                        >
                                                                                            {challengerCountry && (
                                                                                                <CircleFlag country={challengerCountry} left={0} />
                                                                                            )}

                                                                                            <span>{formatPlayerName(item)} </span>
                                                                                        </List.Item>
                                                                                    );
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    );
                                                                }}
                                                            </Query>
                                                        </TabPane>
                                                    </Tabs>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Row>
                                </Card>
                                <br />
                                <Card
                                    title="Venues"
                                    bodyStyle={{ padding: 0 }}
                                    style={{
                                        borderRadius: 8,
                                        overflow: 'hidden',
                                        border: 'none',
                                        boxShadow: '0 2px 5px 0px rgba(0, 0, 0, 0.1)',
                                    }}
                                >
                                    <Query
                                        query={GET_LEAGUE_DIVISION_VENUES_QUERY}
                                        fetchPolicy="cache-and-network"
                                        notifyOnNetworkStatusChange={true}
                                        variables={{ league_id: league.id }}
                                        onCompleted={(data) => {
                                            console.log(data);
                                        }}
                                    >
                                        {({ loading, error, data }) => {
                                            if (loading)
                                                return (
                                                    <div style={styles.container}>
                                                        <CircularLoader />
                                                    </div>
                                                );
                                            if (error) return <div style={styles.container}>Error: {error.message}</div>;
                                            if ((data && !data.venues) || (data && data.venues.length === 0)) {
                                                return <ZeroState showImage message="No venues have been added yet." style={{ margin: 30 }} {...props} />;
                                            }

                                            const venues = data.venues;

                                            return (
                                                <div>
                                                    <List
                                                        className="hover-list"
                                                        size="small"
                                                        itemLayout="horizontal"
                                                        dataSource={venues}
                                                        renderItem={(item) => (
                                                            <List.Item
                                                                // actions={[ <a key="list-loadmore-edit">View</a> ]}
                                                                style={{ padding: '10px 16px' }}
                                                                onClick={() => history.push(`/venues/${item.slug}`)}
                                                            >
                                                                <List.Item.Meta
                                                                    avatar={
                                                                        <div>
                                                                            <Icon
                                                                                type="environment"
                                                                                theme="filled"
                                                                                style={{
                                                                                    color: '#1890ff',
                                                                                    fontSize: 30,
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    }
                                                                    title={
                                                                        <Text
                                                                            style={{
                                                                                fontSize: 16,
                                                                            }}
                                                                        >
                                                                            {item.name}
                                                                        </Text>
                                                                    }
                                                                    description={
                                                                        <div>
                                                                            {item.address1}, {item.city}, {item.region} {item.postal_code} {item.country}
                                                                        </div>
                                                                    }
                                                                />
                                                            </List.Item>
                                                        )}
                                                    />
                                                </div>
                                            );
                                        }}
                                    </Query>
                                </Card>
                            </Col>
                            <Col xs={24} lg={16}>
                                <Row gutter={[16, 16]}>
                                    <Col
                                        xs={24}
                                        style={{
                                            display: 'flex',
                                        }}
                                    >
                                        <Card title="Upcoming matches" bodyStyle={{ padding: 0 }}>
                                            <Query
                                                query={GET_UPCOMING_LEAGUE_MATCHES_QUERY}
                                                fetchPolicy="cache-and-network"
                                                notifyOnNetworkStatusChange={true}
                                                variables={{
                                                    league_id: league.id,
                                                    division_id: division.id,
                                                    current_date: moment.utc(),
                                                }}
                                                onCompleted={(data) => {
                                                    console.log(data);
                                                }}
                                            >
                                                {({ loading, error, data }) => {
                                                    if (loading) return <CircularLoader />;
                                                    if (error) return <div style={styles.container}>Error: {error.message}</div>;

                                                    const matches =
                                                        data.league_matches &&
                                                        data.league_matches.map((item, index) => {
                                                            return {
                                                                ...item,
                                                                key: index,
                                                                player1: item.league_match_players && item.league_match_players[0],
                                                                player2: item.league_match_players && item.league_match_players[1],
                                                                venue: item.venue && item.venue.name,
                                                            };
                                                        });
                                                    return (
                                                        <List
                                                            className="hover-list"
                                                            size="small"
                                                            loading={loading}
                                                            itemLayout="horizontal"
                                                            dataSource={matches}
                                                            renderItem={(item) => (
                                                                <List.Item
                                                                    actions={[<Link to={`/league-manager/${league.slug}/matches/${item.slug}`}>View</Link>]}
                                                                    style={{ padding: '10px 16px' }}
                                                                    onClick={() => props.history.push(`/league-manager/${league.slug}/matches/${item.slug}`)}
                                                                >
                                                                    <Skeleton avatar title={false} loading={loading} active>
                                                                        <List.Item.Meta
                                                                            avatar={
                                                                                <React.Fragment>
                                                                                    {item.game_type ? (
                                                                                        <Avatar size={30} src={getGameType(item.game_type)} />
                                                                                    ) : (
                                                                                        <Avatar size={30} src={defaultImg} />
                                                                                    )}
                                                                                </React.Fragment>
                                                                            }
                                                                            title={
                                                                                <div>
                                                                                    <Text>
                                                                                        {`${moment(item.scheduled_time).format('llll')} (${moment(
                                                                                            item.scheduled_time
                                                                                        ).fromNow()})`}
                                                                                    </Text>
                                                                                    <br />
                                                                                    <Text
                                                                                        style={{
                                                                                            fontSize: 16,
                                                                                        }}
                                                                                    >
                                                                                        {item.name}
                                                                                    </Text>
                                                                                    <Text>{item.table}</Text>
                                                                                </div>
                                                                            }
                                                                            description={item.short_description}
                                                                        />
                                                                    </Skeleton>
                                                                </List.Item>
                                                            )}
                                                        />
                                                    );
                                                }}
                                            </Query>
                                        </Card>
                                    </Col>
                                    <Col
                                        xs={24}
                                        style={{
                                            display: 'flex',
                                        }}
                                    >
                                        <Card title="Standings">Standings will go here</Card>
                                    </Col>
                                    <Col xs={24}>
                                        <Card title="News & Updates">News will go here...</Card>
                                    </Col>

                                    {/* <Col
                                        xs={12}
                                        style={{
                                            display: 'flex',
                                        }}
                                    >
                                        <Card
                                            cover={
                                                <div
                                                    style={{
                                                        paddingTop: 16,
                                                        paddingBottom: 16,
                                                        paddingLeft: 24,
                                                        paddingRight: 24,
                                                        // background:'linear-gradient(rgb(239, 243, 245), rgb(255, 255, 255))',
                                                        borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
                                                    }}
                                                >
                                                    <Title level={4} style={{ margin: 0, padding: 0 }}>
                                                        Players
                                                    </Title>
                                                </div>
                                            }
                                        >
                                            Players will go here
                                        </Card>
                                    </Col>
                                    <Col
                                        xs={12}
                                        style={{
                                            display: 'flex',
                                        }}
                                    >
                                        <Card
                                            cover={
                                                <div
                                                    style={{
                                                        paddingTop: 16,
                                                        paddingBottom: 16,
                                                        paddingLeft: 24,
                                                        paddingRight: 24,
                                                        // background:'linear-gradient(rgb(239, 243, 245), rgb(255, 255, 255))',
                                                        borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
                                                    }}
                                                >
                                                    <Title level={4} style={{ margin: 0, padding: 0 }}>
                                                        Teams
                                                    </Title>
                                                </div>
                                            }
                                        >
                                            Teams will go here
                                        </Card>
                                    </Col> */}
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </SectionContent>
            <SectionFooter {...props} />
        </SectionWrapper>
    );
}

export default withRouter(LMDivisionOverview);
