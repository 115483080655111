import React from 'react';
import { Card, Avatar, Typography, Tag } from 'antd';
import { withRouter, Link } from 'react-router-dom';
import { Query } from '@apollo/client/react/components';
import { GET_PLAYER_TOURNAMENTS_QUERY } from './data/queries';
import CircularLoader from '../../../components/CircularLoader';
import ZeroState from '../../../components/ZeroState';
import { GlobalOutlined } from '@ant-design/icons';
import { getGameType, getOrdinalSuffix } from '../../../utils';
import { TrophyFilled } from '@ant-design/icons';
import moment from 'moment';
import _ from 'lodash';

const styles = {
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 20,
    },
};

const { Title, Text } = Typography;

function PlayerTournaments(props) {
    const { authState, player, theme } = props;

    return (
        <Query
            query={GET_PLAYER_TOURNAMENTS_QUERY}
            fetchPolicy="no-cache"
            variables={{ player_id: player.id }}
            notifyOnNetworkStatusChange
            awaitRefetchQueries
            onCompleted={(data, loading, error, subscribeToMore) => {}}
        >
            {({ loading, error, data }) => {
                if (loading)
                    return (
                        <div style={styles.container}>
                            <CircularLoader />
                        </div>
                    );
                if (error) return <div style={styles.container}>Error: {error.message}</div>;
                if (data && (!data.tournament_players || data.tournament_players.length === 0) && (!data.tournaments || data.tournaments.length === 0)) {
                    return (
                        <div style={styles.container}>
                            <ZeroState
                                message="No tournaments created or played in yet."
                                messageStyle={{ fontSize: 16 }}
                                showImage={false}
                                style={{ textAlign: 'left' }}
                            />
                        </div>
                    );
                }

                const tournamentPlayer =
                    data && data.tournament_players
                        ? data.tournament_players.map((player) => {
                              return {
                                  ...player.tournament,
                                  place: player.place,
                                  is_member: true,
                              };
                          })
                        : [];

                const tournaments = tournamentPlayer.sort((a, b) => {
                    const createdAtA = moment(a.created_at).valueOf();
                    const createdAtB = moment(b.created_at).valueOf();
                    return createdAtB - createdAtA;
                });

                const isSplit = tournaments.filter((player) => {
                    return player.place === '1';
                });

                return (
                    <React.Fragment>
                        {tournaments.map((tournament, index) => {
                            return (
                                <Card
                                    // title={league.name}
                                    bordered={false}
                                    hoverable
                                    style={{ marginBottom: 10 }}
                                    // extra={<a href="#">More</a>}
                                    key={index}
                                >
                                    <Link to={`/tournaments/${tournament.slug}`}>
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                            }}
                                        >
                                            <div style={{ display: 'flex' }}>
                                                <div
                                                    style={{
                                                        width: 50,
                                                        height: 50,
                                                    }}
                                                >
                                                    <Avatar
                                                        size={50}
                                                        style={{
                                                            cursor: 'pointer',
                                                        }}
                                                        src={
                                                            tournament.avatar
                                                                ? tournament.avatar
                                                                : tournament.game_type
                                                                ? getGameType(tournament.game_type, theme)
                                                                : null
                                                        }
                                                    />
                                                </div>
                                                <div style={{ marginLeft: 15 }}>
                                                    <Title level={4} style={{ margin: 0 }}>
                                                        {tournament.name}
                                                    </Title>
                                                    {/* <Text type="primary">{tournament.description}</Text> */}

                                                    <Text type="secondary" style={{ marginTop: 5, display: 'block' }}>
                                                        {moment(tournament.created_at).format('MMMM Do, YYYY')}
                                                    </Text>
                                                </div>
                                            </div>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <div>
                                                    {tournament.place === '1' ? (
                                                        <TrophyFilled
                                                            style={{
                                                                color: '#eab700',
                                                                marginRight: 5,
                                                            }}
                                                        />
                                                    ) : tournament.place === '2' ? (
                                                        <TrophyFilled
                                                            style={{
                                                                color: '#9baab1',
                                                                marginRight: 5,
                                                            }}
                                                        />
                                                    ) : (
                                                        tournament.place === '3' && (
                                                            <TrophyFilled
                                                                style={{
                                                                    color: '#f19921',
                                                                    marginRight: 5,
                                                                }}
                                                            />
                                                        )
                                                    )}
                                                </div>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <Text
                                                        style={{
                                                            marginLeft: 0,
                                                            width: '100%',
                                                            whiteSpace: 'nowrap',
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                        }}
                                                    >
                                                        {tournament.place
                                                            ? `${getOrdinalSuffix(tournament.place)} ${
                                                                  tournament.place === '1' && isSplit.length > 1 ? '(split)' : ''
                                                              }`
                                                            : ''}
                                                    </Text>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </Card>
                            );
                        })}
                    </React.Fragment>
                );
            }}
        </Query>
    );
}

export default withRouter(PlayerTournaments);
