import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { Popconfirm, Modal, message, Layout, Row, Col, Steps, Empty, Alert, Typography, Button, Icon, Result } from 'antd';
import Card from '../../components/Card';
import SectionHeader from '../../components/SectionHeader';
import SectionContent from '../../components/SectionContent';
import SectionWrapper from '../../components/SectionWrapper';
import TBCreateTournamentForm from './TBCreateTournamentFormStages';
import SectionFooter from '../../components/SectionFooter';
import TBPlayers from './TBPlayers';
import { getByeChart } from '../../utils';
import TBCreateAddPlayers from './TBCreateAddPlayers';
import TBCreateAddTables from './TBCreateAddTables';
import TBUploadFlyer from './TBUploadFlyer';
import CircularLoader from '../../components/CircularLoader';
import { Query } from '@apollo/client/react/components';
import TBPreviewRoundRobin from './TBPreviewRoundRobin';
import TBPreviewChip from './TBPreviewChip';
import TBPreviewBracket from './TBPreviewBracketStages';
import { GET_TOURNAMENT_BY_SLUG_QUERY } from './data/queries';
import _ from 'lodash';
import TBCreateReviewTournament from './TBCreateReviewTournament';
import qs from 'query-string';

const { Step } = Steps;
const { Text, Title } = Typography;

const styles = {
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        padding: 20,
    },
    cardHeader: {
        paddingTop: 5,
        paddingBottom: 5,
        background: 'linear-gradient(0deg, rgb(241, 244, 246), rgb(255, 255, 255))',
        // boxShadow: 'rgba(0, 0, 0, 0.05) 0px 2px 8px 0px',
        borderRadius: '10px 10px 0px 0px',
    },
};

function TBTournamentSetup(props) {
    const { client } = props;
    const params = qs.parse(props.location.search, { ignoreQueryPrefix: true });
    console.log(params);
    const tournamentSlug = props.match && props.match.params && props.match.params.slug;
    const step = (params && params.step && parseInt(params.step - 1)) || 0;
    console.log('step', step);
    const [currentStep, setCurrentStep] = useState(step);
    console.log('current step', currentStep);
    const [tournament, setTournament] = useState();
    const [bracketCreated, setBracketCreated] = useState(false);

    useEffect(() => {
        if (step) {
            setCurrentStep(step);
            console.log('step', step);
        }
    }, [step]);

    function onChange(current) {
        setCurrentStep(current);
    }

    function setRoundOrdering(totalRounds, maxPlayers, trueDoubleElim) {
        let winnerRoundCounter = 0;
        let loserRoundCounter = 0;
        let roundOrder = [];

        Array.from({ length: totalRounds }, (v, index) => {
            if (index === 0) {
                winnerRoundCounter++;
                roundOrder.push(winnerRoundCounter);
            }

            if (index === totalRounds - 1 && maxPlayers > 64) {
                winnerRoundCounter++;
                roundOrder.push(winnerRoundCounter);
            }
            if (index === totalRounds - 1 && trueDoubleElim === 1) {
                winnerRoundCounter++;
                roundOrder.push(winnerRoundCounter);
            }

            if (index % 3 === 1) {
                loserRoundCounter--;
                roundOrder.push(loserRoundCounter);
                loserRoundCounter--;
                roundOrder.push(loserRoundCounter);
            } else if (index % 2 === 0) {
                winnerRoundCounter++;
                roundOrder.push(winnerRoundCounter);
            }
        });
        console.log('total rounds:', totalRounds);
        console.log('round ordering:', roundOrder);
        return roundOrder;
    }

    return (
        <React.Fragment>
            <SectionWrapper>
                <SectionHeader
                    title="Tournament Setup"
                    titleStyle={{
                        color: '#fff',
                        textTransform: 'uppercase',
                        margin: 0,
                        display: 'flex',
                    }}
                    height={100}
                    backButton={
                        <Button
                            onClick={() =>
                                props.match.params.slug
                                    ? props.history.push(`/tournament-builder/${props.match.params.slug}/dashboard`)
                                    : props.history.push(`/tournament-builder`)
                            }
                            type="ghost"
                            shape="circle"
                            icon="arrow-left"
                            size="large"
                            ghost
                            style={{ border: 'none' }}
                        />
                    }
                    subtitle="Configure your tournament in a few simple steps."
                    subtitleStyle={{ color: '#fff' }}
                    theme="tournamentBuilder"
                >
                    <Button
                        ghost
                        disabled={
                            tournament &&
                            tournament.tournament_brackets_aggregate &&
                            tournament.tournament_brackets_aggregate.aggregate &&
                            tournament.tournament_brackets_aggregate.aggregate.count > 0
                                ? false
                                : true
                        }
                        onClick={() => {
                            props.history.push(`/tournament-builder/${tournament.slug}/bracket`);
                        }}
                    >
                        <Icon type="apartment" /> View Bracket
                    </Button>
                </SectionHeader>
                <SectionContent padding="20px 40px">
                    <Row gutter={24} justify="center" type="flex">
                        <Col xs={24}>
                            <Card bodyStyle={{ padding: 0 }}>
                                <Row gutter={0} justify="start" type="flex">
                                    <Col xs={24} sm={6} md={4}>
                                        <Steps
                                            direction="vertical"
                                            current={currentStep}
                                            onChange={onChange}
                                            style={{
                                                marginBottom: 20,
                                                paddingTop: 20,
                                                paddingLeft: 20,
                                                paddingRight: 20,
                                            }}
                                        >
                                            <Step title="Settings" />
                                            <Step title={props.match.params.slug ? 'Edit Players' : 'Add Players'} />
                                            <Step title={props.match.params.slug ? 'Edit Tables' : 'Add Tables'} />
                                            <Step title="Preview Draw" />
                                            <Step title="Upload Flyer" />
                                            <Step title="Review" />
                                        </Steps>
                                    </Col>
                                    <Col xs={24} sm={18} md={20}>
                                        {tournamentSlug || tournament ? (
                                            <Query
                                                query={GET_TOURNAMENT_BY_SLUG_QUERY}
                                                // fetchPolicy="network-only"
                                                notifyOnNetworkStatusChange={true}
                                                variables={{ slug: tournamentSlug || tournament.slug }}
                                                onCompleted={(data) => {
                                                    console.log(data);
                                                    const tournament = data && data.tournaments && data.tournaments[0];
                                                    setTournament(tournament);
                                                    setBracketCreated(
                                                        tournament &&
                                                            tournament.tournament_brackets_aggregate &&
                                                            tournament.tournament_brackets_aggregate.aggregate &&
                                                            tournament.tournament_brackets_aggregate.aggregate.count > 0
                                                            ? true
                                                            : false
                                                    );
                                                }}
                                            >
                                                {({ loading, error, data }) => {
                                                    if (loading) {
                                                        return (
                                                            <div style={styles.container}>
                                                                <CircularLoader />
                                                            </div>
                                                        );
                                                    }
                                                    if (error) {
                                                        return <div style={styles.container}>Error: {error.message}</div>;
                                                    }
                                                    if (!loading && data && !data.tournaments) {
                                                        return (
                                                            <Empty
                                                                image="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original"
                                                                imageStyle={{
                                                                    height: 100,
                                                                }}
                                                                description={<Text style={{ fontSize: 18 }}>You have not created any tournaments yet.</Text>}
                                                                style={{ margin: 10 }}
                                                            >
                                                                <Button>Create New Tournament</Button>
                                                            </Empty>
                                                        );
                                                    }

                                                    // const tournament = data && data.tournaments && data.tournaments[0];

                                                    return (
                                                        <React.Fragment>
                                                            {tournament ? (
                                                                <React.Fragment>
                                                                    {currentStep === 0 && (
                                                                        <div style={{ padding: 20 }}>
                                                                            <TBCreateTournamentForm
                                                                                {...props}
                                                                                onCreated={(tournament) => {
                                                                                    console.log('tournament created', tournament);
                                                                                    setTournament(tournament);
                                                                                    setCurrentStep(1);
                                                                                }}
                                                                                tournament={tournament}
                                                                            />
                                                                        </div>
                                                                    )}

                                                                    {currentStep === 1 && (
                                                                        <div
                                                                            style={{
                                                                                padding: 20,
                                                                            }}
                                                                        >
                                                                            <TBCreateAddPlayers
                                                                                {...props}
                                                                                tournament={tournament}
                                                                                goBack={() => {
                                                                                    setCurrentStep(0);
                                                                                }}
                                                                                onContinue={(players) => {
                                                                                    setCurrentStep(2);
                                                                                    window.scrollTo(0, 0);
                                                                                    // createBracket(tournament, players);
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    )}

                                                                    {currentStep === 2 && (
                                                                        <div
                                                                            style={{
                                                                                padding: 20,
                                                                            }}
                                                                        >
                                                                            <TBCreateAddTables
                                                                                {...props}
                                                                                tournament={tournament}
                                                                                venue={tournament && tournament.venue}
                                                                                goBack={() => {
                                                                                    setCurrentStep(1);
                                                                                }}
                                                                                onContinue={() => {
                                                                                    setCurrentStep(3);
                                                                                    // props.history.push(
                                                                                    // 	`/tournament-builder/${tournament.slug}/bracket`
                                                                                    // );
                                                                                    // const playerCount =
                                                                                    // 	tournament &&
                                                                                    // 	tournament.tournament_players_aggregate.aggregate.count;
                                                                                    // const tableCount =
                                                                                    // 	tournament && tournament.pool_tables_aggregate.aggregate.count;

                                                                                    // if (playerCount > 0 && tableCount > 0) {
                                                                                    // 	props.history.push(
                                                                                    // 		`/tournament-builder/${tournament.slug}/bracket`
                                                                                    // 	);
                                                                                    // } else {
                                                                                    // 	message.error(
                                                                                    // 		`You must have at least ${playerCount > 0
                                                                                    // 			? ''
                                                                                    // 			: '1 player and '} ${tableCount > 0
                                                                                    // 			? ''
                                                                                    // 			: '1 table'} before you can start the tournament.`
                                                                                    // 	);
                                                                                    // }
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    )}

                                                                    {/* {currentStep === 3 && tournament.tournament_type === 'round_robin' && (
                                                                        <TBPreviewRoundRobin
                                                                            {...props}
                                                                            tournament={tournament}
                                                                            goBack={() => {
                                                                                setCurrentStep(2);
                                                                            }}
                                                                            onContinue={() => {
                                                                                setCurrentStep(4);
                                                                            }}
                                                                        />
                                                                    )} */}

                                                                    {currentStep === 3 && tournament.tournament_type === 'chip' && (
                                                                        <TBPreviewChip
                                                                            {...props}
                                                                            tournament={tournament}
                                                                            goBack={() => {
                                                                                setCurrentStep(2);
                                                                            }}
                                                                            onContinue={() => {
                                                                                setCurrentStep(4);
                                                                            }}
                                                                        />
                                                                    )}

                                                                    {currentStep === 3 && tournament.tournament_type !== 'chip' && (
                                                                        <TBPreviewBracket
                                                                            {...props}
                                                                            tournament={tournament}
                                                                            bracketCreated={bracketCreated}
                                                                            stages={tournament && tournament.stages}
                                                                            currentStep={currentStep}
                                                                            goBack={() => {
                                                                                setCurrentStep(1);
                                                                            }}
                                                                            onContinue={() => {
                                                                                setCurrentStep(3);
                                                                            }}
                                                                            onSetCurrentStep={(step) => {
                                                                                setCurrentStep(step);
                                                                            }}
                                                                            onSetBracketCreated={(value) => {
                                                                                setBracketCreated(value);
                                                                            }}
                                                                        />
                                                                    )}

                                                                    {currentStep === 4 && (
                                                                        <div
                                                                            style={{
                                                                                padding: 20,
                                                                            }}
                                                                        >
                                                                            <TBUploadFlyer
                                                                                {...props}
                                                                                tournament={tournament}
                                                                                goBack={() => {
                                                                                    setCurrentStep(3);
                                                                                }}
                                                                                onContinue={() => {
                                                                                    setCurrentStep(5);
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    )}

                                                                    {currentStep === 5 && (
                                                                        <div
                                                                            style={{
                                                                                padding: 20,
                                                                            }}
                                                                        >
                                                                            <TBCreateReviewTournament {...props} tournament={tournament} />
                                                                            {tournament.tournament_type === 'chip' ||
                                                                            tournament.tournament_type === 'round_robin' ? (
                                                                                <Button
                                                                                    type="primary"
                                                                                    size="large"
                                                                                    onClick={() => {
                                                                                        props.history.push(`/tournament-builder/${tournament.slug}/viewer`);
                                                                                    }}
                                                                                    style={{ marginTop: 20 }}
                                                                                >
                                                                                    <div
                                                                                        style={{
                                                                                            display: 'flex',
                                                                                            alignItems: 'center',
                                                                                        }}
                                                                                    >
                                                                                        <Text style={{ color: '#fff' }}>Go to Viewer</Text>
                                                                                        {/* <Icon type="arrow-right" style={{ marginLeft: 3 }} /> */}
                                                                                    </div>
                                                                                </Button>
                                                                            ) : (
                                                                                <Button
                                                                                    type="primary"
                                                                                    size="large"
                                                                                    onClick={() => {
                                                                                        props.history.push(`/tournament-builder/${tournament.slug}/bracket`);
                                                                                    }}
                                                                                    style={{ marginTop: 20 }}
                                                                                >
                                                                                    <div
                                                                                        style={{
                                                                                            display: 'flex',
                                                                                            alignItems: 'center',
                                                                                        }}
                                                                                    >
                                                                                        <Text style={{ color: '#fff' }}>Go to bracket</Text>
                                                                                        {/* <Icon type="arrow-right" style={{ marginLeft: 3 }} /> */}
                                                                                    </div>
                                                                                </Button>
                                                                            )}

                                                                            <Button
                                                                                type="secondary"
                                                                                size="large"
                                                                                onClick={() => {
                                                                                    setCurrentStep(4);
                                                                                }}
                                                                                style={{ marginTop: 20 }}
                                                                            >
                                                                                <div
                                                                                    style={{
                                                                                        display: 'flex',
                                                                                        alignItems: 'center',
                                                                                    }}
                                                                                >
                                                                                    <Text>Go back</Text>
                                                                                </div>
                                                                            </Button>
                                                                        </div>
                                                                    )}
                                                                </React.Fragment>
                                                            ) : (
                                                                <div>No data found</div>
                                                            )}
                                                        </React.Fragment>
                                                    );
                                                }}
                                            </Query>
                                        ) : (
                                            <React.Fragment>
                                                {currentStep === 0 && (
                                                    <div style={{ padding: 20 }}>
                                                        <TBCreateTournamentForm {...props} />
                                                    </div>
                                                )}

                                                {currentStep === 1 && (
                                                    <Result
                                                        status="info"
                                                        title="You must finish configuring the tournament before you can add players."
                                                        extra={
                                                            <Button type="primary" key="console" size="large" onClick={() => setCurrentStep(0)}>
                                                                Go Back
                                                            </Button>
                                                        }
                                                    />
                                                )}

                                                {currentStep === 2 && (
                                                    <Result
                                                        status="info"
                                                        title="You must finish adding players before you can add tables."
                                                        extra={
                                                            <Button type="primary" key="console" size="large" onClick={() => setCurrentStep(1)}>
                                                                Go Back
                                                            </Button>
                                                        }
                                                    />
                                                )}

                                                {currentStep === 3 && (
                                                    <Result
                                                        status="info"
                                                        title="You must finish adding players before you can preview the bracket."
                                                        extra={
                                                            <Button type="primary" key="console" size="large" onClick={() => setCurrentStep(2)}>
                                                                Go Back
                                                            </Button>
                                                        }
                                                    />
                                                )}

                                                {currentStep === 4 && (
                                                    <Result
                                                        status="info"
                                                        title="You must create the tournament before you can upload a flyer."
                                                        extra={
                                                            <Button type="primary" key="console" size="large" onClick={() => setCurrentStep(3)}>
                                                                Go Back
                                                            </Button>
                                                        }
                                                    />
                                                )}

                                                {currentStep === 5 && (
                                                    <Result
                                                        status="info"
                                                        title="You must create the tournament before you can review it."
                                                        extra={
                                                            <Button type="primary" key="console" size="large" onClick={() => setCurrentStep(0)}>
                                                                Go to step 1
                                                            </Button>
                                                        }
                                                    />
                                                )}
                                            </React.Fragment>
                                        )}
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                </SectionContent>
                <SectionFooter {...props} />
            </SectionWrapper>
        </React.Fragment>
    );
}

export default withRouter(TBTournamentSetup);
