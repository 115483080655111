import React, { useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { message, PageHeader, Icon, Row, Col, Typography, Button, Descriptions, Empty } from 'antd';
import Card from '../../../components/Card';
import SectionContent from '../../../components/SectionContent';
import SectionHeader from '../../../components/SectionHeader';
import SectionWrapper from '../../../components/SectionWrapper';
import { displayEnumType } from '../../../utils';
import CircularLoader from '../../../components/CircularLoader';
import { Query } from '@apollo/client/react/components';
import { GET_LEAGUE_DIVISION_SCHEDULE_BY_SLUG } from '../data/queries';
import _ from 'lodash';
import AddMatchModal from '../AddMatchModal';
import ScheduledMatchesList from './ScheduledMatchesList';
import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';
momentDurationFormatSetup(moment);

const { Text, Title } = Typography;

const styles = {
    row: {
        display: 'flex',
        flexGrow: 1,
    },
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
};

function LMDivisionScheduledMatchesEdit(props) {
    const { league, division } = props;
    console.log(props);

    const [selectedMatchId, setSelectedMatchId] = useState();
    const [matchModalVisible, setMatchModalVisible] = useState(false);
    const [scheduledMatches, setScheduledMatches] = useState([]);

    function handleMatchModalOk(e) {
        // console.log(e);
        setMatchModalVisible(false);
        setSelectedMatchId();
    }

    function handleMatchModalCancel(e) {
        setMatchModalVisible(false);
    }

    function handleTableSelected(selected) {
        console.log(selected);
        setSelectedMatchId(selected);
    }

    function handleEditTableSelected(selected) {
        console.log(selected);
        setSelectedMatchId(selected);
        setMatchModalVisible(true);
    }

    return (
        <SectionWrapper>
            <SectionHeader
                title={displayEnumType(props.match.params.schedule)}
                titleStyle={{ color: '#fff', margin: 0, textTransform: 'uppercase' }}
                subtitle={props.title}
                subtitlePrefix={true}
                subtitleStyle={{
                    color: 'rgba(255,255,255,0.8)',
                    textTransform: 'uppercase',
                    fontSize: 12,
                    letterSpacing: 3,
                }}
                height={120}
                backButton={
                    <Button
                        onClick={() => props.history.push(`/league-manager/${league.slug}/divisions/${division.slug}/schedule/${props.match.params.schedule}`)}
                        type="ghost"
                        shape="circle"
                        icon="arrow-left"
                        size="large"
                        ghost
                        style={{ border: 'none' }}
                    />
                }
                theme="leagueManager"
            >
                <Button size="large" type="ghost" ghost onClick={() => setMatchModalVisible(true)}>
                    <Icon type="plus" /> Add Week
                </Button>
                {/* <Button size="large" type="danger" ghost>
					<Icon type="delete" /> Delete
				</Button> */}
            </SectionHeader>
            {/* <PageHeader
				onBack={() => props.history.push(`/tournament-builder/${props.tournament.slug}/venues`)}
				backIcon={<Icon type="arrow-left" style={{ color: props.theme === 'dark' ? '#fff' : '#000' }} />}
				title={
					<Text style={{ color: props.theme === 'dark' ? '#fff' : '#000' }}>
						{_.startCase(_.toLower(props.match.params.venue.replace('-', ' '))).toUpperCase()}
					</Text>
				}
				style={{
					background:
						props.theme === 'dark'
							? 'linear-gradient(180deg, #07101f, #14243b)'
							: 'linear-gradient(#fff, #fafafa)',
					paddingTop: 24,
					paddingLeft: 40,
					paddingRight: 40,
					paddingBottom: 24,
					display: 'flex',
					alignItems: 'center'
				}}
			/> */}
            <SectionContent padding="20px 40px">
                <Query
                    query={GET_LEAGUE_DIVISION_SCHEDULE_BY_SLUG}
                    fetchPolicy="cache-and-network"
                    notifyOnNetworkStatusChange={true}
                    variables={{ slug: props.match.params.schedule }}
                    onCompleted={(data) => {
                        console.log(data);
                    }}
                >
                    {({ loading, error, data }) => {
                        if (loading) return <CircularLoader />;
                        if (error) return <div style={styles.container}>Error: {error.message}</div>;

                        const schedule =
                            data.league_schedules &&
                            data.league_schedules.map((item, index) => {
                                return {
                                    ...item,
                                    key: index,
                                };
                            })[0];

                        let weeks = [];
                        let weekNum = 0;

                        schedule.league_scheduled_matches &&
                            schedule.league_scheduled_matches.forEach((match, i) => {
                                if (i > 0 && match.week === schedule.league_scheduled_matches[i - 1].week) {
                                    weeks[weekNum].push(match);
                                } else if (i === 0) {
                                    weeks.push([match]);
                                    // if (!round.challenger1_seed) {
                                    // 	unseeded = true;
                                    // }
                                } else if (i > 0 && match.week !== schedule.league_scheduled_matches[i - 1].week) {
                                    weeks.push([match]);
                                    weekNum++;
                                }
                            });

                        console.log(weeks);

                        return (
                            <React.Fragment>
                                <React.Fragment>
                                    {/* <pre>{JSON.stringify(data.venues, null, 4)}</pre> */}
                                    {weeks.map((week, index) => {
                                        return (
                                            <Row gutter={24} key={index}>
                                                <Col>
                                                    <Card
                                                        // title={
                                                        // 	<div
                                                        // 		style={{
                                                        // 			display: 'flex',
                                                        // 			alignItems: 'center',
                                                        // 			justifyContent: 'space-between'
                                                        // 		}}
                                                        // 	>
                                                        // 		<Text>
                                                        // 			{venue_pool_tables &&
                                                        // 			venue_pool_tables.length > 0 ? (
                                                        // 				`Tables (${venue_pool_tables.length})`
                                                        // 			) : (
                                                        // 				'Tables'
                                                        // 			)}
                                                        // 		</Text>
                                                        // 		<div>
                                                        // 			<Button
                                                        // 				type="default"
                                                        // 				onClick={() => {
                                                        // 					setTableModalVisible(true);
                                                        // 				}}
                                                        // 			>
                                                        // 				<Icon type="plus-circle" /> Add Tables
                                                        // 			</Button>
                                                        // 		</div>
                                                        // 	</div>
                                                        // }
                                                        cover={
                                                            <div
                                                                style={{
                                                                    paddingTop: 16,
                                                                    paddingBottom: 16,
                                                                    paddingLeft: 24,
                                                                    paddingRight: 24,
                                                                    // background:'linear-gradient(rgb(239, 243, 245), rgb(255, 255, 255))',
                                                                    borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'space-between',
                                                                }}
                                                            >
                                                                <Title level={4} style={{ margin: 0, padding: 0, fontSize: 18 }}>
                                                                    Week {index + 1} - {moment(week.start_date).format('ll')}
                                                                </Title>
                                                                <div
                                                                    style={{
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                        justifyContent: 'space-between',
                                                                    }}
                                                                >
                                                                    <Button
                                                                        type="secondary"
                                                                        onClick={() => {
                                                                            setMatchModalVisible(true);
                                                                        }}
                                                                    >
                                                                        <Icon type="plus-circle" /> Add Match
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        }
                                                        bodyStyle={{ padding: 0 }}
                                                    >
                                                        <ScheduledMatchesList
                                                            {...props}
                                                            onSelected={handleTableSelected}
                                                            onEditSelected={handleEditTableSelected}
                                                            matches={week.map((item, index) => {
                                                                console.log(item);
                                                                return {
                                                                    ...item,
                                                                    key: index,
                                                                    index: index + 1,
                                                                };
                                                            })}
                                                        />
                                                    </Card>
                                                </Col>
                                            </Row>
                                        );
                                    })}
                                </React.Fragment>
                                <AddMatchModal
                                    {...props}
                                    tournament={props.tournament}
                                    // match={match}
                                    selectedTableId={selectedMatchId}
                                    tableModalVisible={matchModalVisible}
                                    onTableModalOk={handleMatchModalOk}
                                    onTableModalCancel={handleMatchModalCancel}
                                />
                            </React.Fragment>
                        );
                    }}
                </Query>
            </SectionContent>
        </SectionWrapper>
    );

    // return (
    // 	<Form name="dynamic_form_nest_item" onFinish={onFinish} autoComplete="off">
    // 		<Form.List name="users">
    // 			{(fields, { add, remove }) => (
    // 				<React.Fragment>
    // 					{fields.map((field, index) => (
    // 						<React.Fragment key={index}>
    // 							<Form.Item
    // 								{...field}
    // 								name={[ field.name, 'first' ]}
    // 								fieldKey={[ field.fieldKey, 'first' ]}
    // 								rules={[ { required: true, message: 'Missing first name' } ]}
    // 							>
    // 								<Input placeholder="First Name" />
    // 							</Form.Item>
    // 							<Form.Item
    // 								{...field}
    // 								name={[ field.name, 'last' ]}
    // 								fieldKey={[ field.fieldKey, 'last' ]}
    // 								rules={[ { required: true, message: 'Missing last name' } ]}
    // 							>
    // 								<Input placeholder="Last Name" />
    // 							</Form.Item>
    // 							<MinusCircleOutlined onClick={() => remove(field.name)} />
    // 						</React.Fragment>
    // 					))}
    // 					<Form.Item>
    // 						<Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
    // 							Add field
    // 						</Button>
    // 					</Form.Item>
    // 				</React.Fragment>
    // 			)}
    // 		</Form.List>
    // 		<Form.Item>
    // 			<Button type="primary" htmlType="submit">
    // 				Submit
    // 			</Button>
    // 		</Form.Item>
    // 	</Form>
    // );
}

export default withRouter(LMDivisionScheduledMatchesEdit);
