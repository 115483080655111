import React, { useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Menu, Icon, Typography, Tooltip } from 'antd';
import { Query } from '@apollo/client/react/components';
import _ from 'lodash';
import CircularLoader from '../../components/CircularLoader';
import { GET_USER_TOURNAMENTS_QUERY, GET_TOURNAMENT_QUERY } from './data/queries';

const { Text } = Typography;

// const USER_tournamentS_SUBSCRIPTION = gql`
// 	subscription tournaments {
// 		tournaments(order_by: { id: desc }) {
// 			id
// 			name
// 			slug
// 		}
// 	}
// `;

const styles = {
    menuItemGroup: {
        marginTop: 10,
        marginLeft: 10,
        textTransform: 'uppercase',
        fontSize: 12,
        fontWeight: 600,
        color: 'rgba(255,255,255,0.4)',
    },
    titleText: {
        color: '#fff',
        padding: '15px 20px',
    },
};

function TBNav(props) {
    const { match, authState } = props;
    console.log(props);
    const slug = match.params.slug;

    const [theme, setTheme] = useState('dark');
    const [current, setCurrent] = useState(slug);

    // useEffect(
    // 	() => {
    // 		setCurrent(pathMatch);
    // 	},
    // 	[ pathMatch ]
    // );

    const handleClick = (e) => {
        // console.log(e.key);
        if (e.key === 'back') {
            setCurrent('tournament-builder');
            props.history.push('/tournament-builder');
        } else if (e.key === 'all') {
            props.history.push('/tournament-builder');
            props.onClicked();
        } else {
            props.onClicked();
            // setTimeout(() => {
            // 	props.onClicked();
            // }, 500);
            const url =
                e.key === 'tournament-builder' ? '/tournament-builder' : e.key === 'new' ? `/tournament-builder/new` : `/tournament-builder/${e.key}/dashboard`;
            // if (_.includes(tbIncludes, e.key)) {
            // 	setTimeout(() => {
            // 		props.onClicked();
            // 	}, 300);
            // }
            props.history.push(url);
        }
    };

    useEffect(() => {
        setCurrent(slug);
    }, [slug]);

    return (
        <div>
            <Menu
                theme={theme}
                onClick={handleClick}
                // style={{ width: 256 }}
                // defaultOpenKeys={[ 'sub1' ]}
                selectedKeys={[current]}
                mode="inline"
            >
                <Menu.Item key="tournament-builder">
                    <Icon type="home" /> Home
                </Menu.Item>
                <Menu.Item key="new">
                    <Icon type="plus" /> Create tournament
                </Menu.Item>
                <Menu.Divider style={{ backgroundColor: '#324454' }} />
            </Menu>
            <Query
                query={GET_USER_TOURNAMENTS_QUERY}
                fetchPolicy="cache-and-network"
                notifyOnNetworkStatusChange={true}
                variables={{
                    manager_id: props.authState.user.id,
                    limit: 5,
                }}
                onCompleted={async (data) => {
                    console.log(data);
                }}
            >
                {({ loading, error, data }) => {
                    if (loading) return <div style={styles.titleText}>Loading...</div>;
                    if (error) return <div style={styles.titleText}>Error: {error.message}</div>;
                    if (data && !data.tournaments) {
                        return <div style={styles.titleText}>No tournaments found.</div>;
                    }

                    return (
                        <Menu
                            theme={theme}
                            onClick={handleClick}
                            // style={{ width: 256 }}
                            // defaultOpenKeys={[ 'sub1' ]}
                            selectedKeys={[current]}
                            mode="inline"
                        >
                            <Menu.ItemGroup key="tournaments" title={<div style={styles.menuItemGroup}>My recent tournaments</div>}>
                                {data.tournaments.length > 0 ? (
                                    data.tournaments.map((tournament, index) => (
                                        <Menu.Item key={tournament.slug}>
                                            <Tooltip title={tournament.name} placement="right" autoAdjustOverflow>
                                                <Icon type="trophy" /> {tournament.name}
                                            </Tooltip>
                                        </Menu.Item>
                                    ))
                                ) : (
                                    <div style={{ marginTop: 16, marginLeft: 24 }}>None yet</div>
                                )}

                                {data.tournaments.length > 0 && (
                                    <Menu.Item key="all">
                                        <Icon type="arrow-right" /> View all
                                    </Menu.Item>
                                )}
                            </Menu.ItemGroup>
                        </Menu>
                    );
                }}
            </Query>
        </div>
    );
}

export default withRouter(TBNav);
