import React, { useState, useRef, useEffect } from 'react';
import { message, Icon, Table, Row, Col, Modal, Avatar, Typography, Button, Tooltip, Input, Tabs, Empty } from 'antd';
import Card from '../../../components/Card';
import { Link } from 'react-router-dom';
import CircularLoader from '../../../components/CircularLoader';
import SectionHeader from '../../../components/SectionHeader';
import SectionContent from '../../../components/SectionContent';
import SectionWrapper from '../../../components/SectionWrapper';
import SectionFooter from '../../../components/SectionFooter';
import { displayEnumType, getGameType } from '../../../utils';
import CircleFlag from '../../../components/CircleFlag';
import Highlighter from 'react-highlight-words';
import { GET_LEAGUE_CHALLENGES_QUERY } from '../data/queries';
import { DELETE_LEAGUE_CHALLENGE, DELETE_MATCH_MUTATION } from '../data/mutations';
import { Query } from '@apollo/client/react/components';
import AddChallengeModal from '../../league-manager/AddChallengeModal';
import ZeroState from '../../../components/ZeroState';
import _ from 'lodash';
import moment from 'moment';

const { Text } = Typography;
const { confirm } = Modal;

const styles = {};

export default function DPLeagueChallenges(props) {
    const { authState, league, challenges, isLeagueMember, onSelected, onEditSelected, selectedRowKeys, onDelete } = props;
    const [selectedKeys, setSelectedKeys] = useState(selectedRowKeys || []);
    const [selectedChallengeId, setSelectedChallengeId] = useState();
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [modalVisible, setModalVisible] = useState(false);
    const searchInput = useRef(null);
    function handleModalOk(e) {
        console.log(e);
        setModalVisible(false);
    }

    function handleModalCancel(e) {
        setModalVisible(false);
    }

    function handleAddChallenge() {
        setModalVisible(true);
        setSelectedChallengeId(null);
    }

    function handleSelected(selected) {
        console.log(selected);
        setSelectedKeys(selected);
    }

    function handleEditSelected(selected) {
        // console.log(selected);
        setSelectedChallengeId(selected);
        setModalVisible(true);
    }

    function getColumnSearchProps(dataIndex) {
        return {
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={searchInput}
                        placeholder={`Search ${dataIndex}`}
                        value={selectedKeys[0]}
                        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        style={{ width: 188, marginBottom: 8, display: 'block' }}
                    />
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon="search"
                        size="small"
                        style={{ width: 90, marginRight: 8 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                </div>
            ),
            filterIcon: (filtered) => <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
            onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
            onFilterDropdownVisibleChange: (visible) => {
                if (visible) {
                    setTimeout(() => searchInput.current.select());
                }
            },
            render: (text) =>
                searchedColumn === dataIndex ? (
                    <Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={[searchText]}
                        autoEscape
                        textToHighlight={text.toString()}
                    />
                ) : (
                    text
                ),
        };
    }

    const columns = [
        {
            title: '#',
            dataIndex: 'index',
            width: 60,

            sorter: (a, b) => {
                return a.index - b.index;
            },
        },
        {
            title: 'Name',
            dataIndex: 'name',
            defaultSortOrder: 'ascend',
            ...getColumnSearchProps('name'),
            sorter: (a, b) => {
                return (
                    a.name &&
                    a.name.localeCompare(b.name, undefined, {
                        numeric: true,
                        sensitivity: 'base',
                    })
                );
            },
            render: (text, record) => <Text>{text || '-'}</Text>,
        },
        {
            title: 'Players',
            dataIndex: 'players',
            key: 'players',
            width: 300,
            render: (text, record) => {
                console.log(record);
                const country1 = record.player_country;
                const country2 = record.opponent_country;

                return (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                        {country1 && <CircleFlag country={country1} left={0} />}

                        <div
                            style={{
                                fontWeight:
                                    record.player_is_winner === true || record.challenger1_score === getChallengerRaceTo(record, 'player') ? 'bold' : 'normal',
                            }}
                        >
                            {`${record.player_name || '-'} ${
                                record.player_name && record.player_name !== 'BYE' && record.player_race_to ? `(${record.player_race_to})` : ''
                            }` || '-'}
                        </div>

                        <div style={{ marginLeft: 5, marginRight: 5 }}>vs.</div>

                        {country2 && <CircleFlag country={country2} left={0} />}

                        <span
                            style={{
                                marginRight: 10,
                                fontWeight:
                                    record.opponent_is_winner === true || record.opponent_score === getChallengerRaceTo(record, 'opponent') ? 'bold' : 'normal',
                            }}
                        >
                            {`${record.opponent_name || '-'} ${
                                record.opponent_name && record.opponent_name !== 'BYE' && record.opponent_race_to ? `(${record.opponent_race_to})` : ''
                            }` || '-'}
                        </span>
                    </div>
                );
            },
        },
        {
            title: 'Scheduled time',
            dataIndex: 'scheduled_time',
            defaultSortOrder: 'descend',
            sorter: (a, b) => {
                return a.scheduled_time && a.scheduled_time.localeCompare(b.scheduled_time);
            },
            render: (text, record) => {
                return <Text>{moment(text).format('llll')}</Text>;
            },
        },
        {
            title: 'Table',
            dataIndex: 'table_name',
            sorter: (a, b) => {
                return a.table_name && b.table_name.localeCompare(b.table_name);
            },
            render: (text, record) => {
                return <Text>{text}</Text>;
            },
        },

        {
            title: 'Match type',
            dataIndex: 'match_type',
            sorter: (a, b) => {
                return a.match_type && a.match_type.localeCompare(b.match_type);
            },
            render: (text, record) => {
                return <Text>{displayEnumType(text) || '-'}</Text>;
            },
        },
        {
            title: 'Game type',
            dataIndex: 'game_type',
            sorter: (a, b) => {
                return a.game_type && a.game_type.localeCompare(b.game_type);
            },
            render: (text, record) => {
                return (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {record && record.game_type && (
                            <Avatar size={24} src={getGameType(record.game_type)} style={{ display: 'inline-block', marginRight: 8 }} />
                        )}

                        <Text>{displayEnumType(text) || '-'}</Text>
                    </div>
                );
            },
        },
        {
            title: 'Division',
            dataIndex: 'division',
            sorter: (a, b) => {
                return a.division.localeCompare(b.division, undefined, {
                    numeric: true,
                    sensitivity: 'base',
                });
            },
            render: (text, record) => (
                <div className="ellipsis" style={{ maxWidth: 150 }}>
                    <Text>{text || '-'}</Text>
                </div>
            ),
        },
        {
            title: 'Venue',
            dataIndex: 'venue',

            sorter: (a, b) => {
                return a.venue.name && b.venue.name.localeCompare(b.venue.name);
            },
            render: (text, record) => {
                return (
                    <div className="ellipsis" style={{ width: 150 }}>
                        <Text>{record.venue.name}</Text>
                    </div>
                );
            },
        },

        {
            title: 'Status',
            dataIndex: 'Status',
            sorter: (a, b) => {
                return a.status && a.status.localeCompare(b.status);
            },
            render: (text, record) => {
                return record.status || '-';
            },
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <span style={{ whiteSpace: 'nowrap' }}>
                    <Tooltip title="Edit">
                        <Button
                            type="ghost"
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                handleEditSelected(record.id);
                                // props.history.push(
                                // 	`/tournament-builder/${props.tournament.slug}/venues/${record.slug}/edit`
                                // );
                            }}
                        >
                            <Icon type="edit" />
                        </Button>
                    </Tooltip>
                    {/* <Divider type="vertical" /> */}
                    <Tooltip title="Delete">
                        <Button
                            type="danger"
                            ghost
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                showConfirm(record);
                            }}
                        >
                            <Icon type="delete" />
                        </Button>
                    </Tooltip>
                </span>
            ),
        },
    ];

    function onSelectChange(selectedRowKeys, selectedRows) {
        console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        setSelectedKeys(selectedRowKeys);
        onSelected(selectedRowKeys, selectedRows);
    }

    function handleSearch(selectedKeys, confirm, dataIndex) {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    }

    function handleReset(clearFilters) {
        clearFilters();
        setSearchText('');
    }

    function onChange(pagination, filters, sorter) {
        console.log('params', pagination, filters, sorter);
    }

    function showConfirm(record) {
        confirm({
            title: `Are you sure you want to remove ${record.name}?`,
            centered: true,
            transitionName: 'fade',
            maskTransitionName: 'none',
            // onOk() {
            // 	return new Promise((resolve, reject) => {
            // 		setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
            // 	}).catch(() => console.log('Oops errors!'));
            // },
            onOk() {
                onDelete(record);
            },
            onCancel() {},
        });
    }

    function getChallengerRaceTo(currentMatch, challenger) {
        const challenger_race_to = challenger === 'player' ? currentMatch.player_race_to : challenger === 'opponent' ? currentMatch.opponent_race_to : null;
        if (challenger_race_to) {
            return challenger_race_to;
        } else {
            return null;
        }
    }

    return (
        <SectionWrapper>
            <SectionContent padding="20px 40px">
                <Row gutter={24} justify="center" type="flex">
                    <Col xs={24} sm={props.navType === 'desktop' ? 24 : 22}>
                        <Card
                            title="All Challenges"
                            extra={
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    {isLeagueMember && (
                                        <Button type="primary" onClick={handleAddChallenge}>
                                            + Create Challenge
                                        </Button>
                                    )}

                                    {/* <div style={{ marginLeft: 10 }}>
                                        <Button type="danger" disabled={!hasSelected}>
                                            Remove
                                        </Button>
                                        <span style={{ marginLeft: 8 }}>{hasSelected ? `${selectedKeys.length} items` : ''}</span>
                                    </div> */}
                                </div>
                            }
                            bodyStyle={{ padding: 0 }}
                        >
                            <Query
                                query={GET_LEAGUE_CHALLENGES_QUERY}
                                fetchPolicy="cache-and-network"
                                notifyOnNetworkStatusChange
                                awaitRefetchQueries
                                variables={{
                                    league_id: league.id,
                                }}
                                onCompleted={(data) => {
                                    console.log(data);
                                }}
                            >
                                {({ loading, error, data }) => {
                                    if (loading) return <CircularLoader />;
                                    if (error) return <div style={styles.container}>Error: {error.message}</div>;
                                    if ((data && !data.challenges) || (data && data.challenges.length === 0)) {
                                        return <ZeroState showImage message="No challenges have been created yet." style={{ margin: 30 }} {...props} />;
                                    }

                                    const challenges =
                                        data &&
                                        data.challenges &&
                                        data.challenges.map((item, index) => {
                                            return {
                                                ...item,
                                                ...item.match,
                                                id: item.id,
                                                index: index + 1,
                                                division: item.division && item.division.name,
                                                key: index,
                                            };
                                        });

                                    return (
                                        <div className="table_nowrap">
                                            <Table
                                                // rowSelection={rowSelection}
                                                onRow={(record, rowIndex) => {
                                                    return {
                                                        onClick: (event) => {
                                                            console.log(record, rowIndex);
                                                            props.history.push({
                                                                pathname: `/league-manager/${props.league.slug}/challenges/${record.slug}`,
                                                                // search: '?query=abc',
                                                                state: {
                                                                    data: record,
                                                                    backPath: `/league-manager/${props.league.slug}/challenges`,
                                                                },
                                                            });
                                                        },
                                                        onDoubleClick: (event) => {}, // double click row
                                                        onContextMenu: (event) => {}, // right button click row
                                                        onMouseEnter: (event) => {}, // mouse enter row
                                                        onMouseLeave: (event) => {}, // mouse leave row
                                                    };
                                                }}
                                                columns={columns.filter((column) => {
                                                    if (league.has_division_support === false && column.dataIndex === 'division') {
                                                        return null;
                                                    } else {
                                                        return column;
                                                    }
                                                })}
                                                dataSource={challenges}
                                                onChange={onChange}
                                                pagination={false}
                                                // pagination={false}
                                                tableLayout="auto"
                                                scroll={{ x: 400 }}
                                                // pagination={{ pageSize: 30 }}
                                                size="middle"
                                                // bordered
                                            />
                                        </div>
                                    );
                                }}
                            </Query>
                        </Card>
                    </Col>
                </Row>
            </SectionContent>

            <AddChallengeModal
                {...props}
                challenger={authState && authState.user}
                selectedChallengeId={selectedChallengeId}
                userID={authState && authState.user && authState.user.id}
                leagueID={league.id}
                modalVisible={modalVisible}
                onModalOk={handleModalOk}
                onModalCancel={handleModalCancel}
            />
        </SectionWrapper>
    );
}
