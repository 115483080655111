export const DIGITALPOOL = {
    INDEX: '/',
    FEEDS: '/feeds',
    LIVE: '/live',
    LIVE_DETAIL_TWITCH: '/live/twitch/:channel',
    LIVE_DETAIL_YOUTUBE: '/live/youtube/:channel',
    MATCHES: '/matches',
    MATCH_DETAIL: '/matches/:slug',
    MATCH_DETAIL_OVERVIEW: '/matches/:slug/overview',
    MATCH_DETAIL_SCOREBOARD: '/matches/:slug/scoreboard',
    MATCH_DETAIL_STREAM_OVERLAY: '/matches/:slug/overlay',
    MATCH_DETAIL_STREAM_TABLE_OVERLAY: '/matches/:slug/table-overlay',
    MATCH_DETAIL_STATS: '/matches/:slug/stats',
    MATCH_DETAIL_STATS_ADVANCED: '/matches/:slug/stats-advanced',
    MATCH_DETAIL_STATS_OVERLAY: '/matches/:slug/stats-overlay',
    MATCH_DETAIL_DAY_STATS_OVERLAY: '/matches/:slug/day-stats-overlay',
    MATCH_DETAIL_LARGE_SCORE_OVERLAY: '/matches/:slug/score-overlay',
    MATCHES_CREATE: '/matches/new',
    MATCHES_EDIT: '/matches/:slug/edit',
    LEAGUES: '/leagues',
    LEAGUE_DETAIL: '/leagues/:slug',
    LEAGUE_DIVISION_DETAIL: '/leagues/:slug/divisions/:division',
    LEAGUE_DETAIL_DIVISION_MATCHES: '/leagues/:slug/divisions/:division/matches',
    LEAGUE_DETAIL_DIVISION_MATCH_DETAIL: '/leagues/:slug/divisions/:division/matches/:match',
    LEAGUE_DETAIL_DIVISION_MATCH_SCORING: '/leagues/:slug/divisions/:division/matches/:match/scoring',
    LEAGUE_DETAIL_SUB: '/leagues/:slug/:sub',
    ALL_LEAGUES: '/leagues/all-leagues',
    MY_LEAGUES: '/leagues/my-leagues',
    EVENTS: '/events',
    EVENT_DETAIL: '/events/:slug',
    EVENT_DETAIL_OVERVIEW: '/events/:slug/overview',
    EVENT_DETAIL_SCORES: '/events/:slug/scores',
    EVENT_DETAIL_STANDINGS: '/events/:slug/standings',
    EVENT_DETAIL_TABLES: '/events/:slug/tables',
    EVENT_CREATE: '/events/new',
    EVENT_EDIT: '/events/:slug/edit',
    // EVENT_SCORING: '/events/:slug/scoring',
    // EVENT_TABLES: '/events/:slug/tables',
    EVENT_TABLE_DETAIL: '/events/:slug/tables/:table',
    EVENT_TABLE_SCORING: '/events/:slug/tables/:table/scoring',
    EVENT_TABLE_LIVE_SCORES: '/events/:slug/tables/overlay',
    EVENT_TABLE_TV_SCORE_DISPLAY: '/events/:slug/tables/:table/tvdisplay',
    EVENT_TABLE_STREAM_OVERLAY_SMALL: '/events/:slug/tables/:table/overlay-small',
    EVENT_TABLE_STREAM_OVERLAY_LARGE: '/events/:slug/tables/:table/overlay-large',
    EVENT_TABLE_STREAM_OVERLAY_RACK_TRACKER: '/events/:slug/tables/:table/racktracker',
    ALL_EVENTS: '/events/all-events',
    MY_EVENTS: '/events/my-events',
    USER_DETAILS: '/users/:slug',
    TOURS: '/tours',
    TOUR_DETAIL: '/tours/:slug',
    TOURNAMENTS: '/tournaments',
    TOURNAMENT_DETAIL: '/tournaments/:slug',
    TOURNAMENT_DETAIL_BRACKET_STAGE: '/tournaments/:slug/bracket/:stage',
    TOURNAMENT_DETAIL_SUB: '/tournaments/:slug/:sub',
    TOURNAMENT_REGISTRATION: '/tournaments/:slug/register',
    TOURNAMENT_STREAM_OVERLAY: '/tournaments/:slug/overlay',
    TOURNAMENT_MATCH_SCORING: '/tournaments/:slug/scoring/:hash_id',
    TOURNAMENT_MATCH_DETAIL: '/tournaments/:slug/matches/:hash_id',
    TOURNAMENT_MATCH_DETAIL_SCORESHEET: '/tournaments/:slug/matches/:hash_id/scoresheet',
    TOURNAMENT_MATCH_SCORING_SHORT_URL: '/m/:hash_id',
    // TOURNAMENT_TABLES: '/tournaments/:slug/tables',
    TOURNAMENT_TABLE_DETAIL: '/tournaments/:slug/tables/:table',
    TOURNAMENT_TABLE_SCOREBOARD: '/tournaments/:slug/tables/:table/scoring',
    TOURNAMENT_TABLE_SCORING_MOBILE: '/tournaments/:slug/tables/:table/scoring-mobile',
    TOURNAMENT_TABLE_LIVE_SCORES: '/tournaments/:slug/tables',
    TOURNAMENT_TABLE_TV_SCORE_DISPLAY: '/tournaments/:slug/tables/:table/tvdisplay',
    TOURNAMENT_TABLE_TV_SCORE_OVERLAY_SMALL: '/tournaments/:slug/tables/:table/overlay-small',
    TOURNAMENT_TABLE_TV_SCORE_OVERLAY_LARGE: '/tournaments/:slug/tables/:table/overlay-large',
    TOURNAMENT_TABLE_TV_RACK_TRACKER: '/tournaments/:slug/tables/:table/racktracker',
    TOURNAMENT_MAP: '/tournaments/map',
    TOURNAMENT_DETAIL_SCHEDULE: '/tournaments/:slug/schedule',
    TOURNAMENT_DETAIL_SCHEDULE_TABLES: '/tournaments/:slug/schedule/tables',
    TOURNAMENT_DETAIL_SCHEDULE_MATCHES: '/tournaments/:slug/schedule/matches',
    FANTASY_LINES: '/fantasy-lines',
    VENUES: '/venues',
    VENUE_MAP: '/venues/map',
    VENUE_DETAIL: '/venues/:slug',
    GHOST_GAMES: '/ghost-games',
    GHOST_GAMES_DETAIL: '/ghost-games/:slug',
    PEOPLE: '/people',
    PLAYERS: '/players',
    ALL_PLAYERS: '/players/all',
    PLAYER_DETAIL: '/players/:slug',
    PRO_RANKINGS: '/players/pro-rankings',
    ORGANIZATIONS: '/organizations',
    DASHBOARD: '/dashboard',
    CHAT: '/chat',
    STATS: '/stats',
    ADVERTISING: '/advertising',
    NOTIFICATIONS: '/notifications',
    FRIENDS: '/friends',
    BILLING: '/billing',
    PROFILE: '/profile',
    ADMIN: '/admin',
    ADMIN_USERS: '/admin/users',
    ADMIN_USER_DETAIL: '/admin/users/:slug',
    ADMIN_PLAYERS: '/admin/players',
    ADMIN_PLAYER_DETAIL: '/admin/players/:slug',
    PROFILE_PRO: '/:slug',
    PROFILE_PRO_ABOUT: '/:slug/about',
    PROFILE_PRO_VIDEOS: '/:slug/videos',
    PROFILE_PRO_VIDEO_DETAIL: '/:slug/videos/:id',
    PROFILE_PRO_FEED: '/:slug/feed',
    PROFILE_PRO_FEED_DETAIL: '/:slug/feed/:post',
    PROFILE_PRO_LESSONS: '/:slug/lessons',
    PROFILE_PRO_SHOP: '/:slug/shop',
    PROFILE_PRO_SPONSORS: '/:slug/sponsors',
    PROFILE_PRO_ADMIN: '/:slug/admin',
    PROFILE_PRO_ADMIN_DETAIL: {
        FRIEND_REQUESTS: '/:slug/admin/friend-requests',
        FRIENDS: '/:slug/admin/friends',
    },
    SCOREBOARD: '/scoreboard',
    STREAM_OVERLAY: '/stream-overlay',
    LOGIN: '/login',
    REGISTER: '/register',
    FORGOT_PASSWORD: '/forgot-password',
    CHANGE_PASSWORD: '/change-password',
    VERIFY_EMAIL: '/verify-email/:email',
    ACCOUNT_SETTINGS: '/settings',
    ACCOUNT_PROFILE_SETTINGS: '/settings/profile',
    ACCOUNT_NOTIFICATION_SETTINGS: '/settings/notifications',
};

export const LEAGUE_MANAGER = {
    INDEX: '/league-manager',
    LEAGUES: '/league-manager/leagues',
    CREATE: '/league-manager/new',
    EDIT: '/league-manager/:slug/edit',
    DETAIL: '/league-manager/:slug',
    DETAIL_DASHBOARD: '/league-manager/:slug/dashboard',
    DETAIL_SETTINGS: '/league-manager/:slug/settings',
    DETAIL_SETTINGS_BASIC: '/league-manager/:slug/settings/basic',
    DETAIL_SETTINGS_CONTACT: '/league-manager/:slug/settings/contact',
    DETAIL_SETTINGS_ORGANIZATION: '/league-manager/:slug/settings/organization',
    DETAIL_SETTINGS_NOTIFICATIONS: '/league-manager/:slug/settings/notifications',
    DETAIL_SETTINGS_AVATAR: '/league-manager/:slug/settings/avatar',
    DETAIL_DIVISIONS: '/league-manager/:slug/divisions',
    DETAIL_DIVISION: '/league-manager/:slug/divisions/:division',
    DETAIL_DIVISION_CREATE: '/league-manager/:slug/divisions/new',
    DETAIL_DIVISION_SCHEDULE_CREATE: '/league-manager/:slug/divisions/:division/schedule/new',
    DETAIL_DIVISION_SCHEDULE_EDIT: '/league-manager/:slug/divisions/:division/schedule/:schedule/edit',
    DETAIL_DIVISION_SCHEDULE_DETAIL: '/league-manager/:slug/divisions/:division/schedule/:schedule',
    DETAIL_DIVISION_SCHEDULED_MATCHES_EDIT: '/league-manager/:slug/divisions/:division/schedule/:schedule/matches/edit',
    DETAIL_DIVISION_EDIT: '/league-manager/:slug/divisions/:division/edit',
    DETAIL_DIVISION_MATCHES: '/league-manager/:slug/divisions/:division/matches',
    DETAIL_DIVISION_MATCH: '/league-manager/:slug/divisions/:division/matches/:match',
    DETAIL_DIVISION_MATCH_SCORING: '/league-manager/:slug/divisions/:division/matches/:match/scoring',
    DETAIL_TEAMS: '/league-manager/:slug/teams',
    DETAIL_TEAM_INFO: '/league-manager/:slug/teams/:team',
    DETAIL_TEAM_DETAIL: '/league-manager/:slug/divisions/:division/teams/:team',
    DETAIL_ROSTERS: '/league-manager/:slug/rosters',
    DETAIL_PLAYERS: '/league-manager/:slug/players',
    DETAIL_PLAYER_INFO: '/league-manager/:slug/players/:player',
    DETAIL_PLAYER_DETAIL: '/league-manager/:slug/divisions/:division/players/:player',
    DETAIL_NOTIFICATIONS: '/league-manager/:slug/notifications',
    DETAIL_PAYMENTS: '/league-manager/:slug/payments',
    DETAIL_SCHEDULE: '/league-manager/:slug/schedule',
    DETAIL_CHALLENGES: '/league-manager/:slug/challenges',
    DETAIL_CHALLENGE_INFO: '/league-manager/:slug/challenges/:challenge',
    DETAIL_MATCHES: '/league-manager/:slug/matches',
    DETAIL_MATCH_INFO: '/league-manager/:slug/matches/:match',
    DETAIL_MATCH_SCORING: '/league-manager/:slug/matches/:match/scoring',
    DETAIL_STANDINGS: '/league-manager/:slug/standings',
    DETAIL_STATS: '/league-manager/:slug/stats',
    DETAIL_VENUES: '/league-manager/:slug/venues',
    DETAIL_VENUE_INFO: '/league-manager/:slug/venues/:venue',
    DETAIL_VENUE_CREATE: '/league-manager/:slug/venues/new',
    DETAIL_VENUE_EDIT: '/league-manager/:slug/venues/:venue/edit',
    DIVISIONS: '/league-manager/divisions',
    MY_DIVISIONS: '/league-manager/divisions/my-divisions',
    ALL_DIVISIONS: '/league-manager/divisions/all-divisions',
    MATCHES: '/league-manager/matches',
    TEAMS: '/league-manager/teams',
    PLAYERS: '/league-manager/players',
    VENUES: '/league-manager/venues',
};
export const TOURNAMENT_BUILDER = {
    INDEX: '/tournament-builder',
    TOURNAMENTS: '/tournament-builder/tournaments',
    CREATE: '/tournament-builder/new',
    CREATE_SIMPLE_TOURNAMENT: '/tournament-builder/new/simple',
    CREATE_ADVANCED_TOURNAMENT: '/tournament-builder/new/advanced',
    CREATE_TOUR_SERIES_TOURNAMENT: '/tournament-builder/new/tour-series',
    BRACKET_TEMPLATE_CREATOR: '/tournament-builder/bracket-creator',
    BRACKET_TEMPLATE_CREATOR_DETAIL: '/tournament-builder/bracket-creator/:slug',
    CREATE_TOURNAMENT_BRACKET_TEMPLATE: '/tournament-builder/bracket-creator/new',
    EDIT: '/tournament-builder/:slug/edit',
    DETAIL: '/tournament-builder/:slug',
    DETAIL_DASHBOARD: '/tournament-builder/:slug/dashboard',
    DETAIL_SETTINGS: '/tournament-builder/:slug/settings',
    DETAIL_SETTINGS_BASIC: '/tournament-builder/:slug/settings/basic',
    DETAIL_SETTINGS_CONTACT: '/tournament-builder/:slug/settings/contact',
    DETAIL_SETTINGS_NOTIFICATIONS: '/tournament-builder/:slug/settings/notifications',
    DETAIL_SETTINGS_AVATAR: '/tournament-builder/:slug/settings/avatar',
    DETAIL_SCHEDULE: '/tournament-builder/:slug/schedule',
    DETAIL_SCHEDULE_TABLES: '/tournament-builder/:slug/schedule/tables',
    DETAIL_SCHEDULE_MATCHES: '/tournament-builder/:slug/schedule/matches',
    DETAIL_BRACKET: '/tournament-builder/:slug/bracket',
    DETAIL_BRACKET_STAGE: '/tournament-builder/:slug/bracket/:stage',
    DETAIL_VIEWER: '/tournament-builder/:slug/viewer',
    DETAIL_SCORES: '/tournament-builder/:slug/scores',
    DETAIL_RESULTS: '/tournament-builder/:slug/results',
    DETAIL_MATCHES: '/tournament-builder/:slug/matches',
    DETAIL_STATS: '/tournament-builder/:slug/stats',
    DETAIL_PLAYERS: '/tournament-builder/:slug/players',
    DETAIL_NOTIFICATIONS: '/tournament-builder/:slug/notifications',
    NOTIFY_PLAYERS: '/tournament-builder/:slug/players/notify',
    DETAIL_PLAYER_INFO: '/tournament-builder/:slug/players/:player',
    DETAIL_POINTS_PAYOUTS: '/tournament-builder/:slug/payouts_points',
    DETAIL_TABLES: '/tournament-builder/:slug/tables',
    DETAIL_TABLE_DETAIL: '/tournament-builder/:slug/tables/:table',
    DETAIL_TABLE_SCORING: '/tournament-builder/:slug/tables/:table/scoring',
    DETAIL_VENUES: '/tournament-builder/:slug/venues',
    DETAIL_VENUE_INFO: '/tournament-builder/:slug/venues/:venue',
};

export const TOUR_BUILDER = {
    INDEX: '/tour-builder',
    CREATE: '/tour-builder/new',
    EDIT: '/tour-builder/:id/edit',
    DETAIL: '/tour-builder/:id',
    DETAIL_OVERVIEW: '/tour-builder/:id/overview',
    DETAIL_STOPS: '/tour-builder/:id/stops',
    DETAIL_VENUES: '/tour-builder/:id/venues',
    VENUES: '/tour-builder/venues',
};

export const POOL_LIVE = {
    INDEX: '/pool-live',
    MATCHES: '/pool-live/matches',
    TOURNAMENTS: '/pool-live/tournaments',
    STREAM_DETAIL: '/pool-live/:slug',
    CREATE: '/pool-live/new',
    STREAMS: '/pool-live/streams',
    STREAMS_NEW: '/pool-live/streams/new',
    DETAIL: '/pool-live/:slug',
};

export const TOURNAMENTS = {
    INDEX: '/tournaments',
    CREATE: '/tournaments/new',
    DETAIL: '/tournaments/:slug',
    OVERVIEW: '/tournaments/:slug/overview',
    STANDINGS: '/tournaments/:slug/standings',
    BRACKETS: '/tournaments/:slug/brackets',
    MATCHES: '/tournaments/:slug/matches',
    STATS: '/tournaments/:slug/stats',
    PLAYERS: '/tournaments/:slug/players',
};
export const FEED = {
    INDEX: '/feed',
    DETAIL: '/feed/:id',
    ACTIVITY: '/feed/activity',
    NEWS: '/feed/news',
    SOCIAL: '/feed/social',
};

export const LIVE = {
    INDEX: '/live',
    DETAIL: '/live/:slug',
};

export const EVENTS = {
    INDEX: '/events',
    DETAIL: '/events/:slug',
    CREATE: '/events/new',
};

export const ORGANIZATIONS = {
    INDEX: '/organizations',
    DETAIL: '/organizations/:slug',
    CREATE: '/organizations/new',
    EDIT: '/organizations/:slug/edit',
};

export const TEAMS = {
    INDEX: '/teams',
    DETAIL: '/teams/:id',
    MY_TEAMS: '/teams/my-teams',
    ALL_TEAMS: '/teams/all-teams',
};

export const DIVISIONS = {
    INDEX: '/divisions',
    DETAIL: '/divisions/:slug',
    CREATE: '/divisions/new',
    MY_DIVISIONS: '/divisions/my-divisions',
    ALL_DIVISIONS: '/divisions/all-divisions',
};

export const DPL = {
    INDEX: '/dpl',
    DETAIL: '/dpl/:id',
    DASHBOARD: '/dpl/dashboard',
    FEED: '/dpl/feed',
    FEED_ACTIVITY: '/dpl/feed/activity',
    FEED_NEWS: '/dpl/feed/news',
    FEED_SOCIAL: '/dpl/feed/social',
    MESSAGES: '/dpl/messages',
    STATS: '/dpl/stats',
    MY_STATS: '/dpl/stats/my-stats',
    TEAM_STATS: '/dpl/stats/team-stats',
    NOTIFICATIONS: '/dpl/notifications',
    FRIENDS: '/dpl/friends',
    MATCHES: '/dpl/matches',
    SETTINGS: '/dpl/settings',
    TEAMS: '/dpl/teams',
    MY_TEAMS: '/dpl/teams/my-teams',
    ALL_TEAMS: '/dpl/teams/all-teams',
    PLAYERS: '/dpl/players',
    VENUES: '/dpl/venues',
    RESULTS: '/dpl/results',
    SCHEDULE: '/dpl/schedule',
    RULES: '/dpl/rules',
    FAQ: '/dpl/faq',
    ABOUT: '/dpl/about',
    SUPPORT: '/dpl/support',
    APP: '/dpl/app',
    DIVISIONS: '/dpl/divisions',
    DIVISION_DETAIL: '/dpl/divisions/:division',
    MY_DIVISIONS: '/dpl/divisions/my-divisions',
    ALL_DIVISIONS: '/dpl/divisions/all-divisions',
};

export const STATS = {
    INDEX: '/stats',
    MY_STATS: '/stats/my-stats',
    TEAM_STATS: '/stats/team-stats',
};

export const GHOST_EVENTS_MANAGER = {
    INDEX: '/ghost-event-manager',
    CREATE: '/ghost-event-manager/new',
    DETAIL: '/ghost-event-manager/:slug',
    DETAIL_DASHBOARD: '/ghost-event-manager/:slug/dashboard',
    DETAIL_PLAYERS: '/ghost-event-manager/:slug/players',
    DETAIL_RESULTS: '/ghost-event-manager/:slug/results',
    DETAIL_STATS: '/ghost-event-manager/:slug/stats',
    DETAIL_PAYOUTS: '/ghost-event-manager/:slug/payouts',
    DETAIL_SETTINGS: './ghost-event-manager/:slug/settings',
};

export const LANDING = '/landing';
export const ACCOUNT = '/account';
export const PROFILE = '/profile';
export const MATCHES = '/matches';
export const MESSAGES = '/messages';
export const FRIENDS = '/friends';
export const PLAYERS = '/players';
export const TERMS = '/terms';
export const PRIVACY = '/privacy';
export const USE = '/use';
export const NOT_FOUND = '/not-found';
// export const DIVISIONS = '/divisions';
// export const DIVISION_CREATE = '/divisions/new';
// export const TEAMS = '/teams';
export const CHALLENGES = '/challenges';
export const VENUES = '/venues';
export const NOTIFICATIONS = '/notifications';
export const USER_PROFILE = '/profile';

// Admin routes
export const ADMIN = '/admin';
export const ADMIN_USERS = '/admin/users';
