import { auth, firebase } from './index';
import { signInWithEmailAndPassword, createUserWithEmailAndPassword, sendEmailVerification } from 'firebase/auth';
import axios from 'axios';
import apiEndPoint from '../utils/apiEndpoint';
// import { saveUser } from "./db";

// Sign Up
export const createUserWithEmail = (email, password) => {
    return createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            return userCredential;
        })
        .catch((error) => {
            console.log(error);
            throw error;
        });
};

export const verifyUserPassword = async (email, password) => {
    try {
        const credential = firebase.auth.EmailAuthProvider.credential(email, password);
        return auth
            .signInWithCredential(credential)
            .then((res) => {
                return res;
            })
            .catch((error) => {
                console.log('error', error);
                throw error;
            });
    } catch (e) {
        throw e;
    }
};

export const getUserByEmail = (email) => {
    return axios
        .post(`${apiEndPoint}checkIfUserExists`, {
            email: email,
        })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            throw error;
        });
};

export const sendEmail = async (user, email, client) => {
    // check for invites, then append it to the url
    // const check = await client.query({
    // 	query: '',
    // 	variables: {

    // 	}
    // })

    return await sendEmailVerification(user, {
        url: `https://digitalpool.com/verify-email/${email}`,
        // iOS: {
        // 	bundleId: 'com.example.ios'
        // },
        // android: {
        // 	packageName: 'com.example.android',
        // 	installApp: true,
        // 	minimumVersion: '12'
        // },
        handleCodeInApp: true,
    }).catch((error) => {
        console.log(error);
        // throw error;
        throw error;
    });
};
// See https://firebase.google.com/docs/auth/admin/create-custom-tokens
// This may not work on the client side because it requires using a service account
// export const createCustomToken = user => {
//   let additionalClaims = {
//     premiumAccount: true,
//     role: "admin"
//   };
//   auth
//     .createCustomToken(user.uid, additionalClaims)
//     .then(function(customToken) {
//       return customToken;
//       // Send token back to client
//     })
//     .catch(function(error) {
//       console.log("Error creating custom token:", error);
//     });
// };

// export const signInWithCustomToken = token => {
//   auth.signInWithCustomToken(token).catch(function(error) {
//     // Handle Errors here.
//     let errorCode = error.code;
//     let errorMessage = error.message;
//     // ...
//   });
// };

// Sign In
export const signInWithEmail = (email, password) => {
    // auth.signInWithEmailAndPassword(email, password).catch((error) => {
    // 	console.log(error);
    // 	throw error;
    // });

    return signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            return userCredential;
        })
        .catch((error) => {
            return error;
        });
};
// auth.signInWithEmailAndPassword(email, password).then(signInWithCustomToken);

// Sign out
export const signOut = () => auth.signOut();

// Password Reset
export const resetPassword = (email) => auth.sendPasswordResetEmail(email);

// Password Change
export const changePassword = (password) => auth.currentUser.updatePassword(password);
