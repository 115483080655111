import React, { useState, useEffect, useRef } from 'react';
import { Drawer, Layout, Typography, Button } from 'antd';
import { withRouter } from 'react-router-dom';
import { Form, FormItem, Input, Radio, Select, DatePicker, SubmitButton, ResetButton, AutoComplete } from 'formik-antd';
import { Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Slide from 'react-reveal/Slide';

const { Sider, Content, Header } = Layout;
const { Title, Text } = Typography;
const { TextArea } = Input;
const { Option } = Select;

const styles = {
    error: { color: '#f5222d', marginTop: 10 },
};

export default function SidePanelMatches(props) {
    const { authState, theme, selectedMatch, matches, sidePanelOpen, onSidePanelClose, onSubmit } = props;
    const formRef = useRef();

    const CreateEventSchema = Yup.object().shape({
        round: Yup.string().required('Required'),
        identifier: Yup.string().required('Required'),
        match_number: Yup.string().required('Required'),
    });

    function handleSubmit(values) {
        onSubmit(values);
    }

    function handleFormFieldUpdates(event) {
        const caret = event.target.selectionStart;
        const element = event.target;
        window.requestAnimationFrame(() => {
            element.selectionStart = caret;
            element.selectionEnd = caret;
        });
    }

    return (
        <Drawer
            title="Match Configuration"
            placement="right"
            onClose={onSidePanelClose}
            visible={sidePanelOpen}
            getContainer={false}
            width={300}
            mask={false}
            maskClosable={false}
            drawerStyle={{ maxHeight: 800, overflowY: 'auto' }}
            // style={{ position: 'absolute' }}
            // headerStyle={{
            //     backgroundColor: theme.bracket.panels.backgroundColor,
            // }}
            // drawerStyle={{
            //     backgroundColor: theme.bracket.panels.backgroundColor,
            // }}
        >
            <Formik
                ref={formRef}
                enableReinitialize
                initialValues={
                    selectedMatch
                        ? {
                              ...selectedMatch,
                              winner_to_slot: null,
                              loser_to_slot: null,
                          }
                        : {
                              round: '',
                              identifier: '',
                              match_number: '',
                              place: '',
                              top_seed: '',
                              bottom_seed: '',
                              winner_from: '',
                              winner_to: '',
                              loser_from: '',
                              loser_to: '',
                              is_finals: false,
                              is_semi_finals: false,
                              is_consolidation_finals: false,
                              winner_to_slot: 'top',
                              loser_to_slot: 'top',
                          }
                }
                validationSchema={CreateEventSchema}
                onSubmit={(values, actions) => {
                    handleSubmit(values, actions);
                }}
                render={({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => {
                    let winnerToSlot;
                    let loserToSlot;
                    if (selectedMatch) {
                        const winnerToMatch =
                            values.winner_to &&
                            matches.filter((match) => {
                                return match.identifier === values.winner_to;
                            })[0];
                        console.log(winnerToMatch);
                        winnerToSlot =
                            winnerToMatch && winnerToMatch.winner_from_bottom_num === selectedMatch.match_number
                                ? 'bottom'
                                : winnerToMatch && winnerToMatch.winner_from_top_num === selectedMatch.match_number
                                ? 'top'
                                : null;
                        console.log('winner to slot', winnerToSlot);
                        const loserToMatch =
                            values.loser_to &&
                            matches.filter((match) => {
                                return match.identifier === values.loser_to;
                            })[0];
                        console.log(loserToMatch);
                        loserToSlot =
                            loserToMatch && loserToMatch.loser_from_bottom_num === selectedMatch.match_number
                                ? 'bottom'
                                : loserToMatch && loserToMatch.loser_from_top_num === selectedMatch.match_number
                                ? 'top'
                                : null;
                        console.log('loser to slot', loserToSlot);
                    }

                    return (
                        <React.Fragment>
                            <Form
                                onSubmit={handleSubmit}
                                layout="vertical"
                                // onSubmit={(e) => {
                                //     e.preventDefault();
                                //     // handleSubmit(values);
                                //     formRef.current.handleSubmit();
                                //     formRef.current.setSubmitting(false);
                                //     formRef.current.resetForm();
                                // }}
                                style={{ maxWidth: 600 }}
                            >
                                <FormItem label="Round" name="round" required>
                                    <Input
                                        name="round"
                                        placeholder="Round"
                                        size="large"
                                        onChange={(event) => {
                                            handleFormFieldUpdates(event);
                                        }}
                                    />
                                </FormItem>
                                <FormItem label="Identifier" name="identifier" required>
                                    <Input
                                        name="identifier"
                                        placeholder="Identifier"
                                        size="large"
                                        onChange={(event) => {
                                            handleFormFieldUpdates(event);
                                        }}
                                    />
                                </FormItem>
                                <FormItem label="Match Number" name="match_number" required>
                                    <Input
                                        name="match_number"
                                        placeholder="Match Number"
                                        size="large"
                                        onChange={(event) => {
                                            handleFormFieldUpdates(event);
                                        }}
                                    />
                                </FormItem>
                                <FormItem label="Place" name="place">
                                    <Input
                                        name="place"
                                        placeholder="Place"
                                        size="large"
                                        onChange={(event) => {
                                            handleFormFieldUpdates(event);
                                        }}
                                    />
                                </FormItem>
                                <FormItem label="Top Seed" name="top_seed">
                                    <Input
                                        name="top_seed"
                                        placeholder="Top Seed"
                                        size="large"
                                        onChange={(event) => {
                                            handleFormFieldUpdates(event);
                                        }}
                                    />
                                </FormItem>
                                <FormItem label="Bottom Seed" name="bottom_seed">
                                    <Input
                                        name="bottom_seed"
                                        placeholder="Bottom Seed"
                                        size="large"
                                        onChange={(event) => {
                                            handleFormFieldUpdates(event);
                                        }}
                                    />
                                </FormItem>
                                {/* <FormItem label="Winner From" name="winner_from">
                                <Select
                                    showSearch
                                    name="winner_from"
                                    placeholder="Select match number"
                                    size="large"
                                    optionFilterProp="children"
                                    filterOption={(input, option) => {
                                        return (
                                            option &&
                                            option.props &&
                                            option.props.children &&
                                            option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        );
                                    }}
                                >
                                    <Option value="">None</Option>
                                    {matches &&
                                        matches
                                            .map((match) => {
                                                return {
                                                    match_number: match.match_number,
                                                    type: match.identifier,
                                                    value: match.identifier,
                                                };
                                            })
                                            .sort((a, b) => {
                                                return a.match_number - b.match_number;
                                            })
                                            .map((item, index) => (
                                                <Option key={`w-${index}`} value={item.value}>
                                                    Match {item.match_number} ({item.type})
                                                </Option>
                                            ))}
                                </Select>
                            </FormItem> */}

                                <FormItem label="Winner To" name="winner_to">
                                    <Select
                                        showSearch
                                        name="winner_to"
                                        placeholder="Select match number"
                                        size="large"
                                        optionFilterProp="children"
                                        filterOption={(input, option) => {
                                            return (
                                                option &&
                                                option.props &&
                                                option.props.children &&
                                                option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            );
                                        }}
                                    >
                                        <Option value="">None</Option>
                                        {matches &&
                                            matches
                                                .map((match) => {
                                                    return {
                                                        match_number: match.match_number,
                                                        type: match.identifier,
                                                        value: match.identifier,
                                                    };
                                                })
                                                .sort((a, b) => {
                                                    return a.match_number - b.match_number;
                                                })
                                                .map((item, index) => (
                                                    <Option key={`w-${index}`} value={item.value}>
                                                        Match {item.match_number} ({item.type})
                                                    </Option>
                                                ))}
                                    </Select>
                                </FormItem>

                                <FormItem name="winner_to_slot">
                                    <Radio.Group name="winner_to_slot" value={winnerToSlot || values.winner_to_slot}>
                                        <Radio value="top">Top slot</Radio>
                                        <Radio value="bottom">Bottom slot</Radio>
                                    </Radio.Group>
                                </FormItem>

                                {/* <FormItem label="Loser From" name="loser_from">
                                <Select
                                    showSearch
                                    name="loser_from"
                                    placeholder="Select match number"
                                    size="large"
                                    optionFilterProp="children"
                                    defaultValue="tournament"
                                    filterOption={(input, option) => {
                                        return (
                                            option &&
                                            option.props &&
                                            option.props.children &&
                                            option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        );
                                    }}
                                >
                                    <Option value="">None</Option>
                                   
                                    {matches &&
                                        matches
                                            .map((match) => {
                                                return {
                                                    match_number: match.match_number,
                                                    type: match.identifier,
                                                    value: match.identifier,
                                                };
                                            })
                                            .sort((a, b) => {
                                                return a.match_number - b.match_number;
                                            })

                                            .map((item, index) => (
                                                <Option key={`l-${index}`} value={item.value}>
                                                    Match {item.match_number} ({item.type})
                                                </Option>
                                            ))}
                                </Select>
                            </FormItem> */}

                                <FormItem label="Loser To" name="loser_to">
                                    <Select
                                        showSearch
                                        name="loser_to"
                                        placeholder="Select match number"
                                        size="large"
                                        optionFilterProp="children"
                                        defaultValue="tournament"
                                        filterOption={(input, option) => {
                                            return (
                                                option &&
                                                option.props &&
                                                option.props.children &&
                                                option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            );
                                        }}
                                    >
                                        <Option value="">None</Option>
                                        {/* .sort((a, b) => {
                                                return a.match_number.localeCompare(b.match_number, undefined, {
                                                    numeric: true,
                                                    sensitivity: 'base',
                                                });
                                            }) */}
                                        {matches &&
                                            matches
                                                .map((match) => {
                                                    return {
                                                        match_number: match.match_number,
                                                        type: match.identifier,
                                                        value: match.identifier,
                                                    };
                                                })
                                                .sort((a, b) => {
                                                    return a.match_number - b.match_number;
                                                })

                                                .map((item, index) => (
                                                    <Option key={`l-${index}`} value={item.value}>
                                                        Match {item.match_number} ({item.type})
                                                    </Option>
                                                ))}
                                    </Select>
                                </FormItem>

                                <FormItem name="loser_to_slot">
                                    <Radio.Group name="loser_to_slot" value={loserToSlot || values.loser_to_slot}>
                                        <Radio value="top">Top slot</Radio>
                                        <Radio value="bottom">Bottom slot</Radio>
                                    </Radio.Group>
                                </FormItem>

                                <FormItem name="is_finals" label="Final Match">
                                    <Radio.Group name="is_finals">
                                        <Radio value={true}>Yes</Radio>
                                        <br />
                                        <Radio value={false}>No</Radio>
                                    </Radio.Group>
                                </FormItem>

                                <FormItem name="is_semi_finals" label="Semi-Final Match">
                                    <Radio.Group name="is_semi_finals">
                                        <Radio value={true}>Yes</Radio>
                                        <br />
                                        <Radio value={false}>No</Radio>
                                    </Radio.Group>
                                </FormItem>

                                <FormItem name="is_consolidation_finals" label="Consolidation Final Match">
                                    <Radio.Group name="is_consolidation_finals">
                                        <Radio value={true}>Yes</Radio>
                                        <br />
                                        <Radio value={false}>No</Radio>
                                    </Radio.Group>
                                </FormItem>

                                <SubmitButton
                                    size="large"
                                    disabled={Object.keys(errors).length > 0 ? true : false}
                                    type={Object.keys(errors).length > 0 ? 'danger' : 'primary'}
                                >
                                    {selectedMatch ? 'Update' : 'Create'}
                                </SubmitButton>
                                <Button type="secondary" size="large" onClick={onSidePanelClose}>
                                    Dismiss
                                </Button>

                                <div>
                                    <Text style={styles.error}>{errors.general}</Text>
                                </div>
                                <div>
                                    <Text style={{ color: '#f5222d', marginTop: 10 }}>{errors.general}</Text>
                                </div>
                            </Form>

                            {/* <Text>{JSON.stringify(selectedMatch, null, 4)}</Text> */}
                        </React.Fragment>
                    );
                }}
            />
        </Drawer>
    );
}
