import React, { useEffect, useState } from 'react';
import { Query } from '@apollo/client/react/components';
import { useSetRecoilState, useRecoilValue } from 'recoil';
import { Formik, insert, setIn } from 'formik';
import { Typography, Button, Alert, message } from 'antd';
import { DatePicker, Form, FormItem, Input, Radio, Select, SubmitButton, Checkbox } from 'formik-antd';
import InvitePlayerInputList from './InvitePlayerInputList';
import { createStep, createGhostGameInviteInputList, ghostEventId, invitePlayersAtom, completedStepAtom } from '../../data/atoms';
import { createGhostGameInviteInputListSelector } from '../../data/selectors';
import {
    GET_USER_BY_EMAIL_QUERY,
    GET_GHOST_EVENT_ID_QUERY,
    GET_INVITE_EMAILS_QUERY,
    GET_GHOST_GAMES_PLAYERS_LIST,
    GET_USER_IF_EXISTS,
} from '../../data/queries';
import {
    INSERT_GHOST_EVENT_PLAYER_MUTATION,
    INSERT_GHOST_GAMES_PLAYER_MUTATION,
    UPDATE_GHOST_GAMES_PLAYERS_MUTATION,
    DELETE_GHOST_GAMES_PLAYER_BY_EMAIL,
} from '../../data/mutations';
import { split } from 'lodash';
import { chart } from 'highcharts';
import { parse } from 'query-string';

const { Text, Title } = Typography;
const { TextArea } = Input;

export default function AddPlayerForm(props) {
    const { client } = props;

    const [inviteTextArea, setInviteTextArea] = useState('');
    const setActiveStep = useSetRecoilState(createStep);
    const setInput = useSetRecoilState(createGhostGameInviteInputList);
    const inputser = useRecoilValue(createGhostGameInviteInputListSelector);
    const ghostEventIdValue = useRecoilValue(ghostEventId);
    const setInvitePlayersValue = useSetRecoilState(invitePlayersAtom);
    const invitePlayersValue = useRecoilValue(invitePlayersAtom);
    const [optionFormat, setOptionFormat] = useState('comma');
    const [textareaPlaceholder, setTextareaPlaceholder] = useState('doc@digitalpool.com,isaac@levid.com,scott@levid.com');

    useEffect(() => {
        client
            .query({
                query: GET_GHOST_GAMES_PLAYERS_LIST,
                variables: {
                    ghost_games_id: ghostEventIdValue,
                },
            })
            .then(function (res) {
                const playerList = res && res.data && res.data.ghost_games_players && res.data.ghost_games_players;
                let textAreaText = '';
                let player;

                if (playerList.length) {
                    for (let i = 0; i < playerList.length; i++) {
                        player = playerList[i];
                        textAreaText += `${player.email_address}${player.separator}`;
                    }
                    setInviteTextArea(textAreaText);
                }
            });
    }, []);

    function onChangeInviteBox(e) {
        setInviteTextArea(e.target.value);
    }

    return (
        <div
            style={{
                marginTop: 20,
                marginBottom: 20,
            }}
        >
            {' '}
            <div style={{ marginBottom: 20, marginRight: 20 }}>
                <Alert
                    message="How inviting works"
                    description="After all the steps are completed, an invite link will sent to the invited players, and the same link will be provided for you to send to your friends."
                    type="info"
                    closable
                    showIcon
                />
            </div>
            <Title level={4} style={{ marginBottom: 10 }}>
                Invite Players
            </Title>
            <Formik
                initialValues={{
                    players: '',
                }}
                onSubmit={async (values) => {
                    if (!values) return;

                    const playerListQuery = await client.query({
                        query: GET_GHOST_GAMES_PLAYERS_LIST,
                        variables: {
                            ghost_games_id: ghostEventIdValue,
                        },
                    });

                    const playerList = playerListQuery && playerListQuery.data && playerListQuery.data.ghost_games_players;

                    let emailStr = '';
                    let parsedEmails = [];
                    let parsedEmail = '';
                    let insertedEmails = [];
                    var userArrayInsert = [];
                    let userId;
                    let query;

                    for (let i = 0; i < values.players.length; i++) {
                        let char = values.players[i];

                        emailStr += char;

                        if (char === '\n' || char === ',' || i === values.players.length - 1) {
                            parsedEmail = char === '\n' || char === ',' ? emailStr.substring(0, emailStr.length - 1) : emailStr;
                            if (parsedEmail === '') continue;

                            parsedEmails.push({
                                email: parsedEmail,
                                separator: char === '\n' || char === ',' ? char : '',
                            });

                            emailStr = '';
                        }
                    }

                    if (playerList.length) {
                        let emailFound;
                        let playerListEmailFound;

                        playerList.forEach(async function (player) {
                            playerListEmailFound = parsedEmails.find(function (parsedEmail) {
                                return player.email_address === parsedEmail.email;
                            });

                            if (!playerListEmailFound) {
                                await client.mutate({
                                    mutation: DELETE_GHOST_GAMES_PLAYER_BY_EMAIL,
                                    variables: {
                                        ghost_games_id: ghostEventIdValue,
                                        email_address: player.email_address,
                                    },
                                });
                            }
                        });

                        parsedEmails.forEach((parsedEmail) => {
                            emailFound = playerList.find(function (player) {
                                return parsedEmail.email === player.email_address;
                            });

                            if (!emailFound) {
                                insertedEmails.push(parsedEmail);
                            }
                        });

                        for (let i = 0; i < insertedEmails.length; i++) {
                            let insertedEmail = insertedEmails[i];

                            query = await client.query({
                                query: GET_USER_IF_EXISTS,
                                variables: {
                                    email: insertedEmail.email,
                                },
                            });

                            userId = query && query.data && query.data.users && query.data.users[0] && query.data.users[0].id ? query.data.users[0].id : false;

                            if (userId) {
                                userArrayInsert.push({
                                    ghost_games_id: ghostEventIdValue,
                                    user_id: userId,
                                    email_address: insertedEmail.address,
                                    is_invited: true,
                                    separator: insertedEmail.separator,
                                });
                            } else {
                                userArrayInsert.push({
                                    ghost_games_id: ghostEventIdValue,
                                    email_address: insertedEmail.address,
                                    is_invited: true,
                                    separator: insertedEmail.separator,
                                });
                            }
                        }
                    } else {
                        for (let i = 0; i < parsedEmails.length; i++) {
                            let parsedEmail = parsedEmails[i];

                            query = await client.query({
                                query: GET_USER_IF_EXISTS,
                                variables: {
                                    email: parsedEmail.email,
                                },
                            });

                            userId = query && query.data && query.data.users && query.data.users[0] && query.data.users[0].id ? query.data.users[0].id : null;

                            if (userId) {
                                userArrayInsert.push({
                                    ghost_games_id: ghostEventIdValue,
                                    user_id: userId,
                                    email_address: parsedEmail.email,
                                    is_invited: true,
                                    separator: parsedEmail.separator,
                                });
                            } else {
                                userArrayInsert.push({
                                    ghost_games_id: ghostEventIdValue,
                                    email_address: parsedEmail.email,
                                    is_invited: true,
                                    separator: parsedEmail.separator,
                                });
                            }
                        }
                    }

                    if (userArrayInsert) {
                        await client.mutate({
                            mutation: INSERT_GHOST_GAMES_PLAYER_MUTATION,
                            variables: {
                                objects: userArrayInsert,
                            },
                        });
                    }
                }}
                render={(values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting) => {
                    return (
                        <Form onSubmit={handleSubmit} layout="vertical" style={{ maxWidth: 600 }}>
                            <FormItem label="Email Addresses" name="players">
                                <TextArea
                                    label="players"
                                    name="players"
                                    rows={7}
                                    placeholder="Comma separated or one for each line"
                                    value={inviteTextArea}
                                    onChange={onChangeInviteBox}
                                />
                            </FormItem>
                            <SubmitButton size="large" type="primary" disabled={false} loading={false}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Text style={{ color: '#fff' }}>To Step 3</Text>
                                </div>
                            </SubmitButton>
                        </Form>
                    );
                }}
            />
        </div>
    );
}
