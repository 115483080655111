import React, { useState, useEffect } from 'react';
import { message, List, Tabs, Row, Col, Radio, Modal, Layout, Button, Tag, Icon, Tooltip, Typography, Empty, Input } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import { DigitalPoolAPI } from '../../lib/api';
import Fade from 'react-reveal/Fade';
import CircularLoader from '../../components/CircularLoader';
import Match from '../../screens/digitalpool/tournaments/bracket/Match';
import Card from '../../components/Card';
import ViewerMatches from './ViewerMatches';
import ViewerPlayers from './ViewerPlayers';
import useBreakpoint from 'use-breakpoint';
import { Query, Subscription } from '@apollo/client/react/components';
import { GET_TOURNAMENT_BRACKET_STAGES_QUERY } from './data/queries';
import { GET_TOURNAMENT_BRACKET_STAGES_SUBSCRIPTION, GET_TOURNAMENT_PLAYERS_SUBSCRIPTION_W_STATS } from './data/subscriptions';
import { defaultBreakpoints } from '../../utils/defaultBreakpoints';
import ZeroState from '../../components/ZeroState';
import RoundRobinGroup from './RoundRobinGroup';
import _ from 'lodash';
import moment from 'moment-timezone';
import momentDurationFormatSetup from 'moment-duration-format';
momentDurationFormatSetup(moment);

const { TabPane } = Tabs;
const { Search } = Input;
const { Text, Title } = Typography;
const { Sider, Content, Header } = Layout;
const { confirm } = Modal;

const styles = {
    container: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
};

function RoundRobinMatches(props) {
    const { history, tournament, stage, tables, isPublic, theme, bracketHeight, sidePanelOpen, onSidePanelOpen, onShowScoreModal, onSetMatches } = props;
    const [matches, setMatches] = useState([]);
    const [players, setPlayers] = useState([]);
    const [groups, setGroups] = useState([]);

    // let payout = rounds && tournament.tournament_payouts && tournament.tournament_payouts.filter((payout) => rounds[0].place === payout.place)[0];
    // console.log(payout);
    var zone_name = moment.tz.guess();
    var timezone = moment.tz(zone_name).zoneAbbr();

    function handleScoreModal(match, round) {
        onShowScoreModal(match, round);
    }

    return (
        <Query
            query={GET_TOURNAMENT_BRACKET_STAGES_QUERY}
            fetchPolicy="cache-and-network"
            notifyOnNetworkStatusChange={true}
            variables={{ tournament_id: tournament.id, stage_id: stage.id }}
            onCompleted={(data, loading, error) => {
                if (!loading && !error) {
                    const { tournament_brackets } = data;
                    const matches = tournament_brackets;
                    console.log(matches);
                    const groupedMatches = _.groupBy(matches, 'group');
                    const groups = Object.values(groupedMatches);
                    console.log(groups);
                    const groupsWithRounds =
                        groups &&
                        groups.map((group, index) => {
                            const groupNumber = index + 1;
                            const groupedRounds = _.groupBy(group, 'round');
                            const rounds = Object.values(groupedRounds);
                            console.log(rounds);
                            return {
                                group: groupNumber,
                                rounds: rounds,
                            };
                        });
                    console.log(groupsWithRounds);
                    setGroups(groupsWithRounds);
                    setMatches(matches);
                    if (onSetMatches) {
                        onSetMatches(matches);
                    }
                }
            }}
        >
            {({ subscribeToMore, ...result }) => (
                <Subscription
                    subscription={GET_TOURNAMENT_PLAYERS_SUBSCRIPTION_W_STATS}
                    fetchPolicy="cache-and-network"
                    notifyOnNetworkStatusChange={true}
                    variables={{ tournament_id: tournament.id }}
                    onSubscriptionData={(data, loading, error) => {
                        if (!loading && !error) {
                            const { tournament_players } = data.subscriptionData.data;
                            let players =
                                tournament_players &&
                                tournament_players
                                    // .filter((player) => {
                                    //     console.log(player);
                                    //     return player.is_eliminated === false;
                                    // })
                                    .map((player) => {
                                        const matchesChallenger1 = player.tournament_matches_challenger1;
                                        const matchesChallenger2 = player.tournament_matches_challenger2;
                                        const playerMatches = [...matchesChallenger1, ...matchesChallenger2];
                                        const player1MatchesWon =
                                            player.challenger1_matches_won &&
                                            player.challenger1_matches_won.aggregate &&
                                            player.challenger1_matches_won.aggregate.count;
                                        const player2MatchesWon =
                                            player.challenger2_matches_won &&
                                            player.challenger2_matches_won.aggregate &&
                                            player.challenger2_matches_won.aggregate.count;
                                        const matchesWon = player1MatchesWon + player2MatchesWon || 0;
                                        const player1MatchesLost =
                                            player.challenger1_matches_lost &&
                                            player.challenger1_matches_lost.aggregate &&
                                            player.challenger1_matches_lost.aggregate.count;
                                        const player2MatchesLost =
                                            player.challenger2_matches_lost &&
                                            player.challenger2_matches_lost.aggregate &&
                                            player.challenger2_matches_lost.aggregate.count;
                                        const matchesLost = player1MatchesLost + player2MatchesLost || 0;
                                        const playerMatchesSorted = playerMatches.sort((a, b) => {
                                            return b.match_number - a.match_number;
                                        });
                                        const playerTable =
                                            (playerMatches &&
                                                playerMatchesSorted[0] &&
                                                playerMatchesSorted[0].table_name &&
                                                playerMatchesSorted[0].table_name) ||
                                            null;
                                        // console.log({
                                        //     player,
                                        //     playerMatchesSorted,
                                        //     playerTable,
                                        // });
                                        return {
                                            ...player,
                                            matches: playerMatchesSorted,
                                            matches_won: matchesWon,
                                            matches_lost: matchesLost,
                                            table: playerTable,
                                            is_waiting: playerTable ? false : true,
                                        };
                                    });

                            // .sort((player) => {
                            //     return player.is_waiting ? -1 : 1;
                            // });

                            console.log(players);
                            setPlayers(players);
                        }
                    }}
                >
                    {({ loading, error, data }) => {
                        return (
                            <React.Fragment>
                                {(loading || result.loading) && (
                                    <div style={styles.container}>
                                        <CircularLoader />
                                    </div>
                                )}
                                {!result.loading && !loading && !error && (
                                    <div
                                        style={{
                                            paddingLeft: 20,
                                            paddingRight: 20,
                                            paddingBottom: 20,
                                        }}
                                    >
                                        {groups &&
                                            groups.map((group, groupIndex) => {
                                                const groupPlayers =
                                                    players &&
                                                    players
                                                        .filter((player) => {
                                                            return player.group === group.group;
                                                        })
                                                        .sort((a, b) => {
                                                            return (b.points = a.points);
                                                        })
                                                        .map((player, index) => {
                                                            return {
                                                                ...player,
                                                                rank: index + 1,
                                                            };
                                                        });
                                                return (
                                                    <RoundRobinGroup
                                                        key={groupIndex}
                                                        group={group}
                                                        players={groupPlayers}
                                                        matches={matches}
                                                        index={groupIndex}
                                                        onShowScoreModal={onShowScoreModal}
                                                        {...props}
                                                    />
                                                );
                                            })}

                                        <div style={{ marginTop: 20 }}>
                                            <Card title="standings">
                                                <table width="100%">
                                                    <thead>
                                                        <tr>
                                                            <th>Rank</th>
                                                            <th>Player</th>
                                                            <th>Played</th>
                                                            <th>Remaining</th>
                                                            <th>W-L-T</th>
                                                            <th>Tiebreak</th>
                                                            <th>Set wins</th>
                                                            <th>Set ties</th>
                                                            <th>Points</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {players.map((row, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td>{index + 1}</td>
                                                                    <td>{row.name}</td>
                                                                    <td>{row.matches_won + row.matches_lost}</td>
                                                                    <td>0</td>
                                                                    <td>
                                                                        {row.matches_won}-{row.matches_lost}-0
                                                                    </td>
                                                                    <td>0</td>
                                                                    <td>0</td>
                                                                    <td>0</td>
                                                                    <td>0</td>
                                                                </tr>
                                                            );
                                                        })}
                                                    </tbody>
                                                </table>

                                                <table width="100%">
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <strong>
                                                                    <em>Player’s Name</em>
                                                                </strong>
                                                            </td>
                                                            <td>
                                                                <strong>
                                                                    <em>HC</em>
                                                                </strong>
                                                            </td>
                                                            <td>
                                                                <strong>
                                                                    <em>Player</em>
                                                                </strong>
                                                                <strong>
                                                                    <em>#</em>
                                                                </strong>
                                                            </td>
                                                            <td>
                                                                <strong>
                                                                    <em>1</em>
                                                                </strong>
                                                            </td>
                                                            <td>
                                                                <strong>
                                                                    <em>2</em>
                                                                </strong>
                                                            </td>
                                                            <td>
                                                                <strong>
                                                                    <em>3</em>
                                                                </strong>
                                                            </td>
                                                            <td>
                                                                <strong>
                                                                    <em>4</em>
                                                                </strong>
                                                            </td>
                                                            <td>
                                                                <strong>
                                                                    <em>5</em>
                                                                </strong>
                                                            </td>
                                                            <td>
                                                                <strong>
                                                                    <em>6</em>
                                                                </strong>
                                                            </td>
                                                            <td>
                                                                <strong>
                                                                    <em>7</em>
                                                                </strong>
                                                            </td>
                                                            <td>
                                                                <em>Total</em>
                                                                <strong>
                                                                    <em>Wins</em>
                                                                </strong>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td></td>
                                                            <td>&nbsp;</td>
                                                            <td>1 &nbsp; &nbsp;</td>
                                                            <td>xxx </td>
                                                            <td>&nbsp;</td>
                                                            <td>&nbsp;</td>
                                                            <td>&nbsp;</td>
                                                            <td>&nbsp;</td>
                                                            <td>&nbsp;</td>
                                                            <td>&nbsp;</td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td></td>
                                                            <td>&nbsp;</td>
                                                            <td>2 &nbsp; &nbsp;</td>
                                                            <td>&nbsp;</td>
                                                            <td>xxx </td>
                                                            <td>&nbsp;</td>
                                                            <td>&nbsp;</td>
                                                            <td>&nbsp;</td>
                                                            <td>&nbsp;</td>
                                                            <td>&nbsp;</td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td></td>
                                                            <td>&nbsp;</td>
                                                            <td>3 &nbsp; &nbsp;</td>
                                                            <td>&nbsp;</td>
                                                            <td>&nbsp;</td>
                                                            <td>xxx </td>
                                                            <td>&nbsp;</td>
                                                            <td>&nbsp;</td>
                                                            <td>&nbsp;</td>
                                                            <td>&nbsp;</td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td></td>
                                                            <td>&nbsp;</td>
                                                            <td>4 &nbsp; &nbsp;</td>
                                                            <td>&nbsp;</td>
                                                            <td>&nbsp;</td>
                                                            <td>&nbsp;</td>
                                                            <td>xxx </td>
                                                            <td>&nbsp;</td>
                                                            <td>&nbsp;</td>
                                                            <td>&nbsp;</td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td></td>
                                                            <td>&nbsp;</td>
                                                            <td>5 &nbsp; &nbsp;</td>
                                                            <td>&nbsp;</td>
                                                            <td>&nbsp;</td>
                                                            <td>&nbsp;</td>
                                                            <td>&nbsp;</td>
                                                            <td>xxx </td>
                                                            <td>&nbsp;</td>
                                                            <td>&nbsp;</td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td></td>
                                                            <td>&nbsp;</td>
                                                            <td>6 &nbsp; &nbsp;</td>
                                                            <td>&nbsp;</td>
                                                            <td>&nbsp;</td>
                                                            <td>&nbsp;</td>
                                                            <td>&nbsp;</td>
                                                            <td>&nbsp;</td>
                                                            <td>xxx </td>
                                                            <td>&nbsp;</td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td></td>
                                                            <td>&nbsp;</td>
                                                            <td>7 &nbsp; &nbsp;</td>
                                                            <td>&nbsp;</td>
                                                            <td>&nbsp;</td>
                                                            <td>&nbsp;</td>
                                                            <td>&nbsp;</td>
                                                            <td>&nbsp;</td>
                                                            <td>&nbsp;</td>
                                                            <td>xxx </td>
                                                            <td></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </Card>
                                        </div>
                                    </div>
                                )}
                            </React.Fragment>
                        );
                    }}
                </Subscription>
            )}
        </Query>
    );
}

export default withRouter(RoundRobinMatches);
