import React, { useState } from 'react';
import { Table, Icon, Button, Row, Empty, Col, Typography, Modal } from 'antd';
import { Link, Route, withRouter } from 'react-router-dom';
import SectionWrapper from '../../components/SectionWrapper';
import SectionHeader from '../../components/SectionHeader';
import SectionContent from '../../components/SectionContent';
import Card from '../../components/Card';
import useBreakpoint from 'use-breakpoint';
import { defaultBreakpoints } from '../../utils/defaultBreakpoints';

function Use(props) {
    const { breakpoint, maxWidth, minWidth } = useBreakpoint(defaultBreakpoints);

    return (
        <SectionWrapper>
            <SectionHeader
                title="Acceptable Use Policy"
                titleStyle={{
                    color: '#fff',
                    textTransform: 'uppercase',
                    margin: 0,
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
                height={breakpoint === 'mobile' ? 80 : 120}
                theme="digitalpool"
            />

            <SectionContent>
                <Row gutter={16} justify="center" type="flex">
                    <Col xs={22}>
                        <div
                            style={{
                                display: 'flex',
                                margin: 24,
                                justifyContent: 'center',
                            }}
                        >
                            <Card>
                                <div className="content">
                                    <div className="privacy">
                                        <p className="lead">
                                            DigitalPool provides a platform to share your tournament information and results. To keep DigitalPool running
                                            smoothly for all of our Users, you agree that you will use the Services only in a manner consistent with the
                                            following Acceptable Use Policy.
                                        </p>
                                        <p>
                                            <strong>You agree not to post any User Content that:</strong>
                                        </p>
                                        <ul>
                                            <li>
                                                contains any information or content that is illegal (including, without limitation, the disclosure of insider
                                                information under securities law or of another party's trade secrets);
                                            </li>
                                            <li>
                                                creates a risk of harm, loss, physical or mental injury, emotional distress, death, disability, disfigurement,
                                                or physical or mental illness to yourself, to any other person, or to any animal;
                                            </li>
                                            <li>may create a risk of any other loss or damage to any person or property;</li>
                                            <li>is sexually explicit or pornographic;</li>
                                            <li>is fraudulent, false, misleading, or deceptive.</li>
                                            <li>
                                                infringes any third party's Intellectual Property Rights, privacy rights, publicity rights, or other personal or
                                                proprietary rights;
                                            </li>
                                            <li>
                                                contains any information or content we deem to be hateful, violent, harmful, abusive, racially or ethnically
                                                offensive, defamatory, infringing, invasive of personal privacy or publicity rights, harassing, humiliating to
                                                other people (publicly or otherwise), libelous, threatening, profane, or otherwise objectionable;
                                            </li>
                                            <li>
                                                seeks to harm or exploit children by exposing them to inappropriate content, asking for personally identifiable
                                                details or otherwise;
                                            </li>
                                            <li>
                                                contains any information or content that you do not have a right to make available under any law or under
                                                contractual or fiduciary relationships; or
                                            </li>
                                            <li>violates, or encourages any conduct that violates laws or regulations;</li>
                                        </ul>
                                        <p>
                                            <strong>You agree not to engage in any of the following prohibited activities:</strong>
                                        </p>
                                        <ul>
                                            <li>
                                                send any unsolicited or unauthorized spam and spam comments on posts, advertising messages, promotional
                                                materials, email, junk mail, chain letters or other form of solicitation;
                                            </li>
                                            <li>
                                                use the Service for any commercial purpose or the benefit of any third party, except as otherwise explicitly
                                                permitted for you by DigitalPool or in any manner not permitted by the Terms;
                                            </li>
                                            <li>
                                                attempt to access or search the Services, User Content or DigitalPool Content or scrape or download User Content
                                                or DigitalPool Content from the Services, or otherwise use, upload content to, or create new links, reposts, or
                                                referrals in the Service through the use of any engine, software, tool, agent, device or mechanism (including
                                                automated scripts, spiders, robots, crawlers, data mining tools or the like) other than the software and/or
                                                search agents provided by DigitalPool or other generally available third party web browsers;
                                            </li>
                                            <li>
                                                collect or store any personally identifiable information from the Services from other users of the Services
                                                without their express permission;
                                            </li>
                                            <li>
                                                attempt to decipher, decompile, disassemble or reverse engineer any of the software used to provide the
                                                Services;
                                            </li>
                                            <li>
                                                use, display, mirror or frame the Service, any individual element within the Service, the DigitalPool name,
                                                trademark, logo or other proprietary information, or the layout and design of any page, without our express
                                                written consent;
                                            </li>
                                            <li>
                                                access, tamper with, or use non-public areas of the Service, our computer systems, or the technical delivery
                                                systems of our providers;
                                            </li>
                                            <li>
                                                use DigitalPool user information to forge any TCP/IP packet header or any part of the header information in any
                                                email or newsgroup posting, or in any way use the Services to send altered, deceptive or false
                                                source-identifying information;
                                            </li>
                                            <li>
                                                interfere with, or attempt to interfere with, the access of any user, host or network, including, without
                                                limitation, sending a virus, overloading, flooding, spamming, or mail-bombing the Services;
                                            </li>
                                            <li>impersonate or misrepresent your affiliation with any person or entity;</li>
                                            <li>violate any applicable law or regulation;</li>
                                            <li>
                                                request, collect or store username, password, token or any other account authentication information from other
                                                users; or
                                            </li>
                                            <li>
                                                encourage or enable any other individual to do any of the activities prohibited in this Acceptable Use Policy.
                                            </li>
                                        </ul>
                                        <p>
                                            DigitalPool reserves the right, but is not obligated, to remove any User Content for any reason or for no reason,
                                            including User Content that DigitalPool believes violates this Acceptable Use Policy or its{' '}
                                            <Link to="/terms">Terms of Service</Link>. DigitalPool may also permanently or temporarily terminate or suspend a
                                            User account without notice and liability for any reason, including if, in DigitalPool's sole determination, a User
                                            violates any provision of this Acceptable Use Policy, our <Link to="/terms">Terms of Service</Link>, or for no
                                            reason.
                                        </p>
                                    </div>
                                </div>
                            </Card>
                        </div>
                    </Col>
                </Row>
            </SectionContent>
        </SectionWrapper>
    );
}

export default withRouter(Use);
