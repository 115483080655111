import React from 'react';
import { Typography } from 'antd';
import blueBg from '../assets/img/blue-bg.png';
import poolHall from '../assets/img/pool-hall9.jpg';
import poolHall2 from '../assets/img/pool-hall10.jpg';
import poolHall3 from '../assets/img/pool-hall15.jpg';
const { Title } = Typography;
const defaultHeight = 130;

export default function SectionTitle(props) {
	const styles = {
		tab: {
			// backgroundColor: '#fff',
			padding: '10px 30px'
		},
		header: {
			height: props.height || defaultHeight,
			backgroundColor: '#000',
			// paddingTop: 20,
			// paddingBottom: 20,
			// overflow: 'hidden',
			borderRadius: 0,
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between',
			paddingLeft: 40,
			paddingRight: 40
			// backgroundPosition: '0px -40px'
		},
		headerImg: {
			height: props.height || defaultHeight,
			// overflow: 'hidden',
			borderRadius: 0,
			background: `linear-gradient(-90deg, #002eaf, #008dd0) 100% 100% no-repeat`,
			// background: `linear-gradient(-45deg, rgb(80, 215, 255), rgb(0, 30, 175)) 0% 0% / 100% no-repeat fixed`,
			// background: `linear-gradient(rgba(61, 185, 248, 0.8), rgba(24, 144, 255, 0.1)), url(${blueBg})`,
			// backgroundAttachment: 'fixed',
			backgroundRepeat: 'no-repeat',
			backgroundSize: '100%',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between',
			paddingLeft: 40,
			paddingRight: 40
			// backgroundPosition: '0px -40px'
		},
		// headerImg: {
		// 	height: props.height || defaultHeight,
		// 	overflow: 'hidden',
		// 	borderRadius: 0,
		// 	background: `linear-gradient(180deg, rgba(0,0,0, 0.1), rgba(0,0,0, 0.7)), url(${poolHall3})`,
		// 	backgroundAttachment: 'fixed',
		// 	backgroundRepeat: 'no-repeat',
		// 	backgroundSize: 'cover',
		// 	display: 'flex',
		// 	alignItems: 'center',
		// 	justifyContent: 'space-between',
		// 	paddingLeft: 40,
		// 	paddingRight: 40,
		// 	backgroundPosition: '0px -400px'
		// },
		headerImgLeagueManager: {
			height: props.height || defaultHeight,
			// overflow: 'hidden',
			borderRadius: 0,
			background: `linear-gradient(180deg, rgba(0,0,0, 0.4), rgba(0,0,0, 0.1)), url(${poolHall}) `,
			// backgroundAttachment: 'fixed',
			backgroundRepeat: 'no-repeat',
			// backgroundSize: '100% 100%',
			backgroundSize: 'auto',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between',
			paddingLeft: 40,
			paddingRight: 40,
			backgroundPosition: '0px -20px'
		},
		headerImgTournamentBuilder: {
			height: props.height || defaultHeight,
			// overflow: 'hidden',
			borderRadius: 0,
			background: `linear-gradient(90deg, rgba(0,0,0, 0.6), rgba(0,0,0, 0.6)), url(${poolHall2})`,
			// backgroundAttachment: 'fixed',
			backgroundRepeat: 'no-repeat',
			// backgroundSize: '100% 100%',
			backgroundSize: 'cover',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between',
			paddingLeft: 40,
			paddingRight: 40,
			backgroundPosition: 'top center'
		}
	};

	return (
		<div
			className="section-header"
			style={
				props.background !== false ? props.theme === 'tournamentBuilder' ? (
					styles.headerImgTournamentBuilder
				) : props.theme === 'leagueManager' ? (
					styles.headerImgLeagueManager
				) : (
					styles.headerImg
				) : (
					styles.header
				)
			}
		>
			{props.titleExtra ? (
				<div style={{ display: 'flex', alignItems: 'center', paddingBottom: 30, marginBottom: '0.5em' }}>
					{props.avatar && props.avatar}
					<Title
						level={2}
						style={
							props.titleStyle ? (
								props.titleStyle
							) : (
								{ color: '#fff', textTransform: 'uppercase', margin: 0 }
							)
						}
					>
						{props.title}
					</Title>
					<div style={{ marginLeft: 10 }}>{props.titleExtra}</div>
				</div>
			) : (
				<React.Fragment>
					{props.avatar && props.avatar}
					<Title
						level={2}
						style={
							props.titleStyle ? (
								props.titleStyle
							) : (
								{ color: '#fff', textTransform: 'uppercase', margin: 0 }
							)
						}
					>
						{props.title}
					</Title>
				</React.Fragment>
			)}

			{props.children}
		</div>
	);
}
