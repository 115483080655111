import React, { useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Icon, Row, Col, Typography, Button } from 'antd';
import TBBasicSettingsForm from './TBBasicSettingsForm';
const { Text, Title } = Typography;

function TBBasicSettings(props) {
	return (
		<React.Fragment>
			<Title level={4}>{props.title}</Title>
			<TBBasicSettingsForm {...props} />
		</React.Fragment>
	);
}

export default withRouter(TBBasicSettings);
