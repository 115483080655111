import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

const ScrollToTop = ({ children, location: { pathname } }) => {
	// console.log(pathname);
	useEffect(
		() => {
			const container = document.querySelector('.content-container');
			if (container) {
				container.scrollTop = 0;
			}
		},
		[ pathname ]
	);

	return children || null;
};

export default withRouter(ScrollToTop);
