import React, { useState, useEffect } from 'react';
import { Row, Col, Typography, Button, Avatar, Tag, Table, Input, Tabs, Empty } from 'antd';
import Card, { Meta } from '../../../components/Card';
import CircularLoader from '../../../components/CircularLoader';
import ZeroState from '../../../components/ZeroState';
import { getCountryName, displayEnumType } from '../../../utils';
import { GET_CURRENT_PRO_RANKINGS_QUERY } from './data/queries';
import { Query } from '@apollo/client/react/components';
import RankingSystemPicker from './RankingSystemPicker';
import ProWorldRankings from './ProWorldRankings';
import CurrentProRankings from './CurrentProRankings';
import useBreakpoint from 'use-breakpoint';
import { defaultBreakpoints } from '../../../utils/defaultBreakpoints';
import CircleFlag from '../../../components/CircleFlag';
import SectionContent from '../../../components/SectionContent';
import moment from 'moment';
import AdZoneTemplate from '../../../components/advertising/AdZoneTemplate';

const { Title, Text } = Typography;

const styles = {
    headerImg: {
        background: `linear-gradient(-90deg, #002eaf, #008dd0) 100% 100% no-repeat`,
        // background: `linear-gradient(0deg, rgba(0,0,0,0.2), rgba(0,0,0, 0.2)), url(${blueBg})`,
        // backgroundAttachment: 'fixed',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100%',
        // backgroundPosition: '0px -400px'

        // backgroundPosition: '0px -40px'
    },
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        // height: '100%'
    },
};

export default function ProRankings2(props) {
    const { theme } = props;
    const { breakpoint, maxWidth, minWidth } = useBreakpoint(defaultBreakpoints);

    return (
        <SectionContent padding="20px 40px">
            <Row gutter={24} justify="center" type="flex">
                <Col xs={24}>
                    <AdZoneTemplate device={breakpoint || 'desktop'} {...props} />
                </Col>
                <Col xs={24}>
                    <Query
                        query={GET_CURRENT_PRO_RANKINGS_QUERY}
                        fetchPolicy="cache-and-network"
                        notifyOnNetworkStatusChange
                        onCompleted={(data, loading, error, subscribeToMore) => {
                            if (!loading && !error) {
                                console.log(data);
                            }
                        }}
                    >
                        {({ loading, error, data, subscribeToMore, networkStatus }) => {
                            if (networkStatus === 1 || loading) {
                                return (
                                    <div style={styles.container}>
                                        <CircularLoader />
                                    </div>
                                );
                            }
                            if (loading)
                                return (
                                    <div style={styles.container}>
                                        <CircularLoader />
                                    </div>
                                );
                            if (error) return <div style={styles.container}>Error: {error.message}</div>;
                            if ((data && !data.vw_current_pro_rankings2) || (data && data.vw_current_pro_rankings2.length === 0)) {
                                return <ZeroState message="No players found." theme={theme} />;
                            }

                            const rankings =
                                data.vw_current_pro_rankings2 &&
                                data.vw_current_pro_rankings2.map((player, index) => {
                                    return {
                                        index,
                                        ...player,
                                        rank_change: null,
                                    };
                                });

                            return <CurrentProRankings rankings={rankings} {...props} />;
                        }}
                    </Query>

                    {/* {selectedRankingSystem === 'ALL_RANKINGS' ? (
                        <CurrentProRankings selected={selectedRankingSystem} onSelected={handleSelectedRankingSystem} {...props} />
                    ) : (
                        <ProWorldRankings selected={selectedRankingSystem} onSelected={handleSelectedRankingSystem} {...props} />
                    )} */}
                </Col>
            </Row>
        </SectionContent>
    );
}
