import React from 'react';
// import PropTypes from 'prop-types';
import { Avatar, Typography } from 'antd';
import { GET_TOURNAMENT_PLAYER_PODIUM_SUBSCRIPTION } from './data/subscriptions';
import { Subscription } from '@apollo/client/react/components';
import CircularLoader from '../../components/CircularLoader';
import firstPlace from '../../assets/img/podium-1st.png';
import secondPlace from '../../assets/img/podium-2nd.png';
import thirdPlace from '../../assets/img/podium-3rd.png';
import numeral from 'numeral';
import './podium.css';

const { Title, Text } = Typography;

const styles = {
    avatar: {
        margin: 0,
    },
    bigAvatar: {
        width: 40,
        height: 40,
    },
};

export default function Podium(props) {
    const { tournamentID } = props;

    function getPlace(place, data) {
        let results = [];
        if (data) {
            for (let i = 0; i < data.length; i++) {
                if (data[i].place === place) {
                    results.push(data[i]);
                }
            }
        }
        return results;
    }

    function getThirdPlaces(data) {
        let arr = [];

        if (data) {
            for (let i = 0; i < data.length; i++) {
                if (data[i].place == '3') arr.push(data[i]);
            }
        }

        return arr;
    }

    return (
        <div className="podium" style={props.style}>
            <Subscription
                subscription={GET_TOURNAMENT_PLAYER_PODIUM_SUBSCRIPTION}
                fetchPolicy="cache-and-network"
                notifyOnNetworkStatusChange={true}
                variables={{ tournament_id: tournamentID }}
                onSubscriptionData={(data) => {}}
            >
                {({ loading, error, data }) => {
                    if (loading) return <CircularLoader />;

                    console.log('subdata', tournamentID);

                    let firstPlaceData = getPlace('1', data && data.tournament_players);
                    let secondPlaceData = getPlace('2', data && data.tournament_players);
                    let getThirdPlacesData = getThirdPlaces(data && data.tournament_players);
                    let firstPlaceNameStr;
                    let firstPlaceMoneyStr;
                    let secondPlaceNameStr;
                    let secondPlaceMoneyStr;
                    let firstThirdPlace = getThirdPlacesData.length ? getThirdPlacesData[0] : null;
                    let secondThirdPlace = getThirdPlacesData.length ? getThirdPlacesData[1] : null;

                    if (firstPlaceData.length <= 1) {
                        firstPlaceNameStr = firstPlaceData[0] ? firstPlaceData[0].name : '';
                        firstPlaceMoneyStr =
                            firstPlaceData && firstPlaceData[0] && firstPlaceData[0].payouts && firstPlaceData[0].payouts.money
                                ? `1st (${numeral(firstPlaceData[0].payouts.money).format('$0,0')})`
                                : `1st`;
                    }

                    if (secondPlaceData.length <= 1) {
                        secondPlaceNameStr = secondPlaceData[0] ? secondPlaceData[0].name : '';
                        secondPlaceMoneyStr =
                            secondPlaceData && secondPlaceData[0] && secondPlaceData[0].payouts && secondPlaceData[0].payouts.money
                                ? `2nd (${numeral(secondPlaceData[0].payouts.money).format('$0,0')})`
                                : `2nd`;
                    }

                    let thirdPlaceName1Str = firstThirdPlace ? firstThirdPlace.name : '';
                    let thirdPlaceMoney1Str =
                        firstThirdPlace && firstThirdPlace.payouts && firstThirdPlace.payouts.money
                            ? `3rd (${numeral(firstThirdPlace.payouts.money).format('$0,0')})`
                            : `3rd`;

                    let thirdPlaceName2Str = secondThirdPlace ? secondThirdPlace.name : '';
                    let thirdPlaceMoney2Str =
                        secondThirdPlace && secondThirdPlace.payouts && secondThirdPlace.payouts.money
                            ? `3rd (${numeral(secondThirdPlace.payouts.money).format('$0,0')})`
                            : `3rd`;

                    return (
                        <React.Fragment>
                            {firstPlaceData.length > 1 ? (
                                <React.Fragment>
                                    <div className="place position1">
                                        <div className="participant">
                                            <div className="info">
                                                <Avatar src={firstPlace} size="large" />
                                                <h3 className="name">{firstPlaceData[0].name}</h3>
                                            </div>
                                        </div>
                                        <div className="base" style={{ borderRadius: '6px 0px 0px 0px' }} />
                                        <div className="number">{firstPlaceData[0].payouts.money / 2 || '1st (split)'}</div>
                                    </div>
                                    <div className="place position1">
                                        <div className="participant">
                                            <div className="info">
                                                <Avatar src={firstPlace} size="large" />
                                                <h3 className="name">{firstPlaceData[1].name}</h3>
                                            </div>
                                        </div>
                                        <div className="base" />
                                        <div className="number">{firstPlaceData[1].payouts.money / 2 || '1st (split)'}</div>
                                    </div>
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    <div className="place position2">
                                        <div className="participant">
                                            <div className="info">
                                                <Avatar src={secondPlace} size="large" />
                                                <h3 className="name">{secondPlaceNameStr}</h3>
                                            </div>
                                        </div>
                                        <div className="base" style={{ borderRadius: '6px 0px 0px 0px' }} />
                                        <div className="number">{secondPlaceMoneyStr}</div>
                                    </div>
                                    <div className="place position1">
                                        <div className="participant">
                                            <div className="info">
                                                <Avatar src={firstPlace} size="large" />
                                                <h3 className="name">{firstPlaceNameStr}</h3>
                                            </div>
                                        </div>
                                        <div className="base" />
                                        <div className="number">
                                            <b>{firstPlaceMoneyStr}</b>
                                        </div>
                                    </div>
                                </React.Fragment>
                            )}

                            <div className="place position3">
                                <div className="participant">
                                    <div className="info">
                                        <Avatar src={thirdPlace} size="large" />
                                        <h3 className="name">{thirdPlaceName1Str}</h3>
                                    </div>
                                </div>
                                <div className="base" />
                                <div className="number">{thirdPlaceMoney1Str}</div>
                            </div>
                            {/* <div className="place position3">
								<div className="participant">
									<div className="info">
										<Avatar src={thirdPlace} />
										<h3 className="name">{thirdPlaceName2Str}</h3>
									</div>
								</div>
								<div className="base" style={{ borderRadius: '0px 6px 0px 0px' }} />
								<div className="number">{thirdPlaceMoney2Str}</div>
							</div> */}
                        </React.Fragment>
                    );
                }}
            </Subscription>
        </div>
    );
}
