import React, { useState } from 'react';
import { Table, Typography, Button, Modal, Icon, Tooltip } from 'antd';
import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';
momentDurationFormatSetup(moment);

const { Text } = Typography;
const { confirm } = Modal;

export default function ScheduledMatchesList(props) {
    const { onSelected, onEditSelected, onDelete, size } = props;

    console.log(props);

    const columns = [
        {
            title: 'Match',
            dataIndex: 'id',
            // width: 100,
            defaultSortOrder: 'ascend',
            sorter: (a, b) => {
                return a.id - b.id;
            },
        },
        {
            title: 'Home Team',
            dataIndex: 'home_team_id',
            sorter: (a, b) => {
                return a.home_team.name.localeCompare(b.home_team.name, undefined, {
                    numeric: true,
                    sensitivity: 'base',
                });
            },
            render: (text, record) => <React.Fragment>{(record.home_team && record.home_team.name) || '-'}</React.Fragment>,
        },

        {
            title: 'Visitor Team',
            dataIndex: 'visitor_team_id',
            sorter: (a, b) => {
                return a.visitor_team.name.localeCompare(b.visitor_team.name, undefined, {
                    numeric: true,
                    sensitivity: 'base',
                });
            },
            render: (text, record) => <React.Fragment>{(record.visitor_team && record.visitor_team.name) || '-'}</React.Fragment>,
        },
        {
            title: 'Start Date',
            dataIndex: 'start_date',
            sorter: (a, b) => a.start_date - b.start_date,
            render: (text, record) => <React.Fragment>{moment(record.start_date).format('l') || '-'}</React.Fragment>,
        },
        {
            title: 'Start Time',
            dataIndex: 'start_time',
            sorter: (a, b) => a.start_time - b.start_time,
            render: (text, record) => <React.Fragment>{moment(record.start_time).format('h:mm A') || '-'}</React.Fragment>,
        },

        {
            title: 'Status',
            dataIndex: 'status',
            sorter: (a, b) => a.status - b.status,
            render: (text, record) => <React.Fragment>{text || '-'}</React.Fragment>,
        },
        {
            title: 'Action',
            key: 'action',
            width: 200,
            render: (text, record) => (
                <span style={{ whiteSpace: 'nowrap' }}>
                    <Tooltip title="Edit">
                        <Button
                            type="ghost"
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                onEditSelected(record.id);
                                // props.history.push(
                                // 	`/tournament-builder/${props.tournament.slug}/venues/${record.slug}/edit`
                                // );
                            }}
                        >
                            <Icon type="edit" />
                        </Button>
                    </Tooltip>
                    {/* <Divider type="vertical" /> */}
                    <Tooltip title="Delete">
                        <Button
                            type="ghost"
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                showConfirm(record);
                            }}
                        >
                            <Icon type="delete" />
                        </Button>
                    </Tooltip>
                </span>
            ),
        },
    ];

    function onChange(pagination, filters, sorter) {
        console.log('params', pagination, filters, sorter);
    }

    function showConfirm(record) {
        confirm({
            title: `Are you sure you want to remove ${record.label} from this venue?`,
            centered: true,
            transitionName: 'fade',
            maskTransitionName: 'none',
            // onOk() {
            // 	return new Promise((resolve, reject) => {
            // 		setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
            // 	}).catch(() => console.log('Oops errors!'));
            // },
            onOk() {
                onDelete(record);
            },
            onCancel() {},
        });
    }

    return (
        <Table
            onRow={(record, rowIndex) => {
                return {
                    // onClick: (event) => {
                    // 	console.log(record, rowIndex);
                    // 	props.history.push({
                    // 		pathname: `/tournament-builder/${props.tournament.slug}/venues/${record.slug}`,
                    // 		// search: '?query=abc',
                    // 		state: { data: record }
                    // 	});
                    // },
                    onDoubleClick: (event) => {}, // double click row
                    onContextMenu: (event) => {}, // right button click row
                    onMouseEnter: (event) => {}, // mouse enter row
                    onMouseLeave: (event) => {}, // mouse leave row
                };
            }}
            className="noselect"
            columns={columns}
            dataSource={props.matches}
            onChange={onChange}
            pagination={false}
            tableLayout="auto"
            scroll={{ x: 400 }}
            size={size ? size : 'default'}
            bordered={false}
        />
    );
}
