import React, { useState, useEffect, useLayoutEffect, useRef, createRef } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { message, Menu, Dropdown, Button, Icon, Typography, Empty, Row, Col, Tooltip, Modal, Input, Layout } from 'antd';
import Card from '../../components/Card';
import ScrollContainer from 'react-indiana-drag-scroll';
import CircularLoader from '../../components/CircularLoader';
import ZeroState from '../../components/ZeroState';
import { GET_ALL_TOURNAMENT_TABLES_QUERY } from './data/queries';
import { DownOutlined } from '@ant-design/icons';
import gql from 'graphql-tag';
import { Query, Subscription } from '@apollo/client/react/components';
import { useMutation, useQuery } from '@apollo/react-hooks';
import PendingMatches from '../../screens/digitalpool/tournaments/bracket/PendingMatches';
import useBreakpoint from 'use-breakpoint';
import ViewerMatchOverlay from './ViewerMatchOverlay';
import { defaultBreakpoints } from '../../utils/defaultBreakpoints';
import { MapInteractionCSS } from 'react-map-interaction';
import ViewerSidePanel from './ViewerSidePanel';
import ViewerTables from './ViewerTables';
import _ from 'lodash';
import qs from 'query-string';
import moment from 'moment';

const { Text, Title } = Typography;
const { Sider, Content, Header } = Layout;
// const { SubMenu } = Menu;
const { confirm } = Modal;

const tabHeight = 45;

const styles = {
    container: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
};

export function Viewer(props) {
    const { authState, tournament, theme, isPublic, isFullScreen } = props;
    const params = qs.parse(props.location.search, { ignoreQueryPrefix: true });
    const [modalScoreVisible, setModalScoreVisible] = useState(false);
    const [currentMatch, setCurrentMatch] = useState();
    const [matches, setMatches] = useState();
    const [playerWaiting, setPlayerWaiting] = useState();
    const [currentRound, setCurrentRound] = useState();
    const { breakpoint, maxWidth, minWidth } = useBreakpoint(defaultBreakpoints);
    const [sidePanelOpen, setSidePanelOpen] = useState(breakpoint === 'mobile' ? false : true);
    const showNavigation = params && params.navigation === 'false' ? false : true;
    const tournamentPath = props.match.params.slug;
    const [tables, setTables] = useState([]);
    const viewerEl = useRef();

    const windowHeight = window.innerHeight;
    const headerEl = document.querySelector('.header');
    const headerNarrowEl = document.querySelector('.header-narrow');
    const sectionHeaderEl = document.querySelector('.section-header');
    const progressBarEl = document.querySelector('.tournament-progress-bar');

    let bracketHeight;
    let headerHeight = isFullScreen === true || showNavigation === false ? 0 : sectionHeaderEl && sectionHeaderEl.offsetHeight;
    let headerNarrowHeight = headerNarrowEl && headerNarrowEl.offsetHeight;
    let progressBarHeight = progressBarEl && progressBarEl.offsetHeight;
    let footerHeight = 53;

    if (isPublic === true) {
        if (isFullScreen === true) {
            bracketHeight = '100%';
        } else {
            const spacer = breakpoint === 'mobile' ? 170 : 95;
            bracketHeight = windowHeight - (headerHeight + footerHeight + spacer);
        }
    } else if (isFullScreen === false && headerNarrowEl) {
        bracketHeight = windowHeight - (headerHeight + progressBarHeight + footerHeight + headerNarrowHeight);
    } else if (isFullScreen === false && headerEl) {
        bracketHeight = windowHeight - (headerHeight + progressBarHeight + footerHeight + headerEl.offsetHeight);
    } else if (isFullScreen === true) {
        bracketHeight = '100%';
    } else {
        bracketHeight = windowHeight - (headerHeight + progressBarHeight + footerHeight);
    }
    if (breakpoint === 'mobile') {
        bracketHeight = bracketHeight + 80;
    }

    function showCompleteDialog(winner) {
        console.log(winner);
        let content;
        if (winner.is_split === true) {
            content = `${winner.challenger1_name} and ${winner.challenger2_name} split 1st place!.`;
        } else {
            content = `${winner.challenger2_name || winner.challenger1_name} won the tournament!`;
        }
        Modal.success({
            content: content,
            centered: true,
            transitionName: 'fade',
            maskTransitionName: 'none',
        });
    }

    function completeTournament(winner) {
        showCompleteDialog(winner);
        // setTournamentComplete(true);
    }

    function showScoreModal(match, round) {
        setCurrentMatch(match);
        setCurrentRound(round);
        setModalScoreVisible(true);
    }

    function handleSetMatches(data) {
        setMatches(data);
    }

    function handlePlayerWaiting(data) {
        setPlayerWaiting(data);
    }

    return (
        <Query
            query={GET_ALL_TOURNAMENT_TABLES_QUERY}
            fetchPolicy="cache-and-network"
            notifyOnNetworkStatusChange={true}
            variables={{ tournament_id: tournament.id }}
            onCompleted={(data, loading, error) => {
                if (!loading && !error) {
                    // const { pool_tables } = data.subscriptionData.data;
                    // setTables(pool_tables);
                    // if (
                    // 	pool_tables.length > 0
                    // ) {
                    // 	showConfirmDraw(drawRound, winnerBracket, matches);
                    // }
                }
            }}
        >
            {({ loading, error, data }) => {
                if (loading)
                    return (
                        <div style={styles.container}>
                            <CircularLoader />
                        </div>
                    );
                if (error) return <div style={styles.container}>Error: {error.message}</div>;
                if ((data && !data.pool_tables) || (data && data.pool_tables.length === 0)) {
                    return (
                        <Row
                            gutter={16}
                            justify="center"
                            type="flex"
                            style={{
                                margin: 0,
                                padding: 0,
                                overflowY: 'auto',
                                height: bracketHeight - tabHeight,
                                width: '100%',
                                alignItems: 'center',
                            }}
                        >
                            <ZeroState showImage message="No tables found." style={{ margin: 30 }} {...props}>
                                <Link to={`/tournament-builder/${tournament.slug}/tables`}>
                                    <Button>Manage Tables</Button>
                                </Link>
                            </ZeroState>
                        </Row>
                    );
                }

                const tables = data && data.pool_tables;

                return (
                    <Layout style={{ height: bracketHeight }}>
                        {sidePanelOpen === true ? (
                            <Sider
                                theme={theme.name}
                                width={300}
                                style={{
                                    backgroundColor: theme.bracket.panels.backgroundColor,
                                    // borderLeft: '1px solid #eee',
                                    position: 'relative',
                                    zIndex: 1,
                                    boxShadow: 'rgba(0, 21, 41, 0.25) 0px 0px 10px 0px',
                                }}
                            >
                                <React.Fragment>
                                    <ViewerSidePanel
                                        isPublic={isPublic}
                                        tables={tables}
                                        bracketHeight={bracketHeight}
                                        sidePanelOpen={sidePanelOpen}
                                        onShowScoreModal={showScoreModal}
                                        onSetMatches={handleSetMatches}
                                        onSetPlayerWaiting={handlePlayerWaiting}
                                        onSidePanelOpen={() => {
                                            setSidePanelOpen(false);
                                        }}
                                        {...props}
                                    />
                                    <PendingMatches theme={theme} tournament={tournament} tables={tables} />
                                </React.Fragment>
                            </Sider>
                        ) : (
                            <div
                                onClick={() => {
                                    setSidePanelOpen(true);
                                }}
                                style={{ marginLeft: 20, marginTop: 20 }}
                            >
                                <Button>Open Players Panel</Button>
                            </div>
                        )}
                        <Content style={{ position: 'relative' }}>
                            <ScrollContainer
                                id="bracket"
                                className="scroll-container"
                                vertical={true}
                                horizontal={true}
                                hideScrollbars={false}
                                activationDistance={1}
                                ignoreElements=".prevent-drag-scroll"
                                nativeMobileScroll={true}
                                style={{
                                    height: '100%',
                                    width: '100%',
                                    position: 'absolute',
                                    zIndex: 0,
                                    // position: 'relative'
                                    // background: 'linear-gradient( #fafafa, #f0f2f5)'
                                }}
                                ref={viewerEl}
                            >
                                <ViewerTables
                                    isPublic={isPublic}
                                    matches={matches}
                                    bracketHeight={bracketHeight}
                                    onShowScoreModal={showScoreModal}
                                    {...props}
                                />
                            </ScrollContainer>

                            {authState && tournament.status !== 'COMPLETED' && (
                                <React.Fragment>
                                    <ViewerMatchOverlay
                                        {...props}
                                        isFullScreen={isFullScreen}
                                        // players={players}
                                        matches={matches}
                                        currentRound={currentRound}
                                        currentMatch={currentMatch}
                                        nextPlayerWaiting={playerWaiting}
                                        modalScoreVisible={modalScoreVisible}
                                        onModalScoreVisible={setModalScoreVisible}
                                        onTournamentComplete={(winner) => {
                                            completeTournament(winner);
                                        }}
                                    />
                                </React.Fragment>
                            )}
                        </Content>
                    </Layout>
                );
            }}
        </Query>
    );
}

export default React.memo(Viewer);
