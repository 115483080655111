import React, { useState, useEffect, useRef } from 'react';
import { message, Modal, Button, Typography, Upload, Divider, Icon, Avatar } from 'antd';
import { Form, Table, AddRowButton, RemoveRowButton, FormItem, Input, Select, InputNumber } from 'formik-antd';
import * as Yup from 'yup';
import { Formik } from 'formik';
import CircularLoader from '../../components/CircularLoader';
import { Query, Mutation } from '@apollo/client/react/components';
import { GET_TOURNAMENT_QUERY, GET_TOURNAMENT_CHIP_AMOUNTS } from './data/queries';
import { DELETE_TOURNAMENT_CHIP_AMOUNTS, CREATE_TOURNAMENT_CHIP_AMOUNTS } from './data/mutations';
import { useMutation } from '@apollo/react-hooks';
import moment from 'moment-timezone';
import _ from 'lodash';

const { Option } = Select;
const { Text, Title } = Typography;

function ChipAmountModal(props) {
    const { client, tournament, tournamentID, modalVisible, onModalOk, onModalCancel, onSave } = props;
    const [loading, setLoading] = useState(false);
    const [deleteChipAmounts] = useMutation(DELETE_TOURNAMENT_CHIP_AMOUNTS);
    const [createChipAmounts] = useMutation(CREATE_TOURNAMENT_CHIP_AMOUNTS);
    const formRef = useRef();

    function submitAdd(data, actions) {
        const { custom_amounts } = data;

        const toUpdate = custom_amounts
            .filter((item) => {
                return !_.isEmpty(item.amoun) || !_.isEmpty(item.skill_level);
            })
            .map((item) => {
                return {
                    skill_level: item.skill_level,
                    amount: item.amount,
                };
            });

        console.log(toUpdate);
        onSave(toUpdate);
        actions.setSubmitting(false);
        setLoading(false);
        onModalOk();
    }

    function submitUpdate(data, actions) {
        const { custom_amounts } = data;

        const toUpdate = custom_amounts
            .filter((item) => {
                return !_.isEmpty(item.amoun) || !_.isEmpty(item.skill_level);
            })
            .map((item) => {
                return {
                    tournament_id: tournament.id,
                    skill_level: item.skill_level,
                    amount: item.amount,
                };
            });
        console.log('to update', toUpdate);

        setLoading(true);
        props.client
            .mutate({
                mutation: DELETE_TOURNAMENT_CHIP_AMOUNTS,
                variables: {
                    tournament_id: tournamentID,
                },
                // awaitRefetchQueries: true,
                // refetchQueries: [
                //     {
                //         query: GET_TOURNAMENT_PAYOUTS,
                //         variables: { tournament_id: tournament.id },
                //     },
                // ],
            })
            .then(async (data) => {
                createChipAmounts({
                    variables: {
                        objects: toUpdate,
                    },
                    notifyOnNetworkStatusChange: true,
                    awaitRefetchQueries: true,
                    refetchQueries: [
                        {
                            query: GET_TOURNAMENT_CHIP_AMOUNTS,
                            variables: { tournament_id: tournamentID },
                        },
                        {
                            query: GET_TOURNAMENT_QUERY,
                            variables: {
                                id: tournamentID,
                            },
                        },
                    ],
                });
                actions.setSubmitting(false);
                setLoading(false);
                message.success('Changes saved');
                onModalOk();
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
                message.error('There was an error', error);
                onModalOk();
            });
    }

    const schema = Yup.object().shape({
        player_score: Yup.number().required('Required'),
        opponent_score: Yup.number().required('Required'),
    });

    const renderForm = (chips) => {
        const initialValues =
            tournamentID && chips
                ? {
                      custom_amounts: chips,
                  }
                : {
                      custom_amounts: [
                          {
                              skill_level: '400-',
                              amount: '7',
                          },
                          {
                              skill_level: '400-500',
                              amount: '6',
                          },
                          {
                              skill_level: '501-600',
                              amount: '5',
                          },
                          {
                              skill_level: '601-650',
                              amount: '4',
                          },
                          {
                              skill_level: '651-699',
                              amount: '3',
                          },
                          {
                              skill_level: '700+',
                              amount: '2',
                          },
                      ],
                  };

        return (
            <Formik
                ref={formRef}
                onSubmit={(data, actions) => {
                    if (tournamentID) {
                        submitUpdate(data, actions);
                    } else {
                        submitAdd(data, actions);
                    }
                }}
                enableReinitialize={false}
                initialValues={initialValues}
                render={({ errors, touched, values }) => (
                    <Modal
                        title="Set Chip Amounts By Skill Level"
                        // getContainer={() => document.querySelector('.tables')}
                        visible={modalVisible}
                        onOk={onModalOk}
                        onCancel={onModalCancel}
                        centered
                        transitionName="fade"
                        // transitionName="none"
                        maskTransitionName="none"
                        footer={[
                            <Button key="cancel" onClick={onModalCancel}>
                                Cancel
                            </Button>,
                            <Button
                                key="submit"
                                disabled={Object.keys(errors).length > 0 ? true : false}
                                type={Object.keys(errors).length > 0 ? 'danger' : 'primary'}
                                onClick={() => {
                                    formRef.current.handleSubmit();
                                }}
                            >
                                {loading ? 'Saving...' : 'Save Amounts'}
                            </Button>,
                        ]}
                        bodyStyle={{ maxHeight: 500, overflowY: 'auto', padding: '10px 20px' }}
                        destroyOnClose={true}
                    >
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <Form>
                                <Table
                                    name="custom_amounts"
                                    rowKey={(row, index) => index}
                                    size="small"
                                    pagination={false}
                                    columns={[
                                        {
                                            width: 100,
                                            title: 'Skill Level',
                                            key: 'skill_level',
                                            render: (text, record, i) => (
                                                <Input placeholder="100" style={{ border: 'none' }} name={`custom_amounts.${i}.skill_level`} />
                                            ),
                                        },
                                        {
                                            title: 'Chip amount',
                                            key: 'amount',
                                            render: (text, record, i) => (
                                                <Input placeholder="1" style={{ border: 'none' }} name={`custom_amounts.${i}.amount`} />
                                            ),
                                        },
                                        {
                                            width: 32,
                                            key: 'actions',
                                            align: 'right',
                                            render: (text, record, index) => (
                                                <RemoveRowButton style={{ border: 'none' }} icon="delete" name="custom_amounts" index={index} />
                                            ),
                                        },
                                    ]}
                                />
                                <AddRowButton
                                    name="custom_amounts"
                                    style={{ marginTop: 10 }}
                                    createNewRow={() => ({
                                        id: '' + Math.random(),
                                        skill_level: '',
                                        amount: '',
                                    })}
                                >
                                    Add
                                </AddRowButton>
                            </Form>
                        </div>
                    </Modal>
                )}
            />
        );
    };

    return (
        <React.Fragment>
            {tournamentID ? (
                <Query
                    query={GET_TOURNAMENT_CHIP_AMOUNTS}
                    fetchPolicy="cache-and-network"
                    notifyOnNetworkStatusChange={true}
                    variables={{ tournament_id: tournament.id }}
                    onCompleted={async (data) => {
                        console.log(data);
                    }}
                >
                    {({ loading, error, data }) => {
                        if (loading) return <CircularLoader />;
                        if (error) return <div>Error: {error.message}</div>;

                        const chips =
                            data && data.tournament_chips && data.tournament_chips.length > 0
                                ? data.tournament_chips.map((item) => {
                                      return {
                                          skill_level: item.skill_level,
                                          amount: item.amount,
                                      };
                                  })
                                : null;

                        return <React.Fragment>{renderForm(chips)}</React.Fragment>;
                    }}
                </Query>
            ) : (
                <React.Fragment>{renderForm()}</React.Fragment>
            )}
        </React.Fragment>
    );
}

export default ChipAmountModal;
