import React, { useState } from 'react';
import { Link, withRouter, Route } from 'react-router-dom';
import { Query } from '@apollo/client/react/components';
import { Row, Col, Button, Avatar, Empty, Typography } from 'antd';
import Card, { Meta } from '../../../components/Card';
import CircularLoader from '../../../components/CircularLoader';
import SectionContent from '../../../components/SectionContent';
import SearchFilterBar from '../leagues/SearchFilterBar';
import ZeroState from '../../../components/ZeroState';
import Masonry from '../../../components/Masonry';
import { CalendarOutlined, EnvironmentOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import moment from 'moment';
import useBreakpoint from 'use-breakpoint';
import { defaultBreakpoints } from '../../../utils/defaultBreakpoints';
import { GET_EVENTS_QUERY } from '../data/queries';
import defaultImg from '../../../assets/img/blue-bg.png';
import tournamentThumb from '../../../assets/img/tournament-thumb.png';
import AdZoneTemplate from '../../../components/advertising/AdZoneTemplate';

const { Text, Title } = Typography;
const masonryBreakPoints = [600, 960, 1280, 1920];

const styles = {
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
    },
};

function createMarkup(markup) {
    return { __html: markup };
}

export default function AllEvents(props) {
    const { onCreateEvent, theme } = props;
    const [searchValue, setSearchValue] = useState();
    const { breakpoint, maxWidth, minWidth } = useBreakpoint(defaultBreakpoints);

    return (
        <React.Fragment>
            {/* <div
				style={{
					background: 'linear-gradient( #fafafa, #fff)',
					boxShadow: 'rgba(0, 0, 0, 0.05) 0px 4px 6px 0px',
					padding: '10px 30px'
				}}
			>
				<SearchFilterBar {...props} />
			</div> */}

            <SectionContent>
                <Query
                    query={GET_EVENTS_QUERY}
                    fetchPolicy="cache-and-network"
                    notifyOnNetworkStatusChange
                    awaitRefetchQueries
                    onCompleted={(data, loading, error, subscribeToMore) => {
                        if (!loading && !error) {
                            console.log(data);
                        }
                    }}
                >
                    {({ loading, error, data, subscribeToMore, networkStatus }) => {
                        if (networkStatus === 1 || loading) {
                            return (
                                <div style={styles.container}>
                                    <CircularLoader />
                                </div>
                            );
                        }
                        if (loading)
                            return (
                                <div style={styles.container}>
                                    <CircularLoader />
                                </div>
                            );
                        if (error) return <div style={styles.container}>Error: {error.message}</div>;
                        if ((data && !data.events) || (data && data.events.length === 0)) {
                            return (
                                <Card>
                                    <ZeroState message="No events have been created yet.">
                                        <Link to="/events/new">
                                            <Button size="large">Create Event</Button>
                                        </Link>
                                    </ZeroState>
                                </Card>
                            );
                        }

                        return (
                            <Row gutter={16} justify="center" type="flex">
                                <Col xs={24}>
                                    <AdZoneTemplate device={breakpoint || 'desktop'} {...props} />
                                </Col>
                                <Col xs={24}>
                                    <div className="masonry-container">
                                        <Masonry breakPoints={masonryBreakPoints}>
                                            {data.events.map((event, index) => {
                                                return (
                                                    <Card
                                                        key={index}
                                                        hoverable
                                                        loading={loading}
                                                        cover={
                                                            <div
                                                                style={{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    // borderBottom: '1px solid #e6e7ea',
                                                                }}
                                                            >
                                                                <img alt="Flyer" src={event.flyer || tournamentThumb} style={{ width: '100%' }} />
                                                            </div>
                                                        }
                                                        onClick={() => {
                                                            props.history.push(`/events/${event.slug}`);
                                                        }}
                                                    >
                                                        <Meta
                                                            title={<Title level={4}>{event.name}</Title>}
                                                            description={
                                                                <React.Fragment>
                                                                    <CalendarOutlined /> <Text>{moment(event.start_date_time).format('MMMM Do YYYY')}</Text>
                                                                    {event.end_date_time && (
                                                                        <Text> - {moment(event.end_date_time).format('MMMM Do YYYY')}</Text>
                                                                    )}
                                                                    {event.venue && (
                                                                        <React.Fragment>
                                                                            <br />
                                                                            <EnvironmentOutlined />{' '}
                                                                            <Text>
                                                                                <Highlighter
                                                                                    highlightClassName="highlighted-text"
                                                                                    searchWords={[searchValue]}
                                                                                    autoEscape={true}
                                                                                    textToHighlight={`${event.venue.name} -
                                                                            ${event.venue.city}, 
                                                                            ${event.venue.region}`}
                                                                                />
                                                                            </Text>
                                                                        </React.Fragment>
                                                                    )}
                                                                    <br />
                                                                    <br />
                                                                    <div
                                                                        style={{
                                                                            color: theme.name === 'dark' ? '#fff' : 'rgba(0, 0, 0, 0.85)',
                                                                        }}
                                                                        dangerouslySetInnerHTML={createMarkup(event.description)}
                                                                    />
                                                                </React.Fragment>
                                                            }
                                                        />
                                                    </Card>
                                                );
                                            })}
                                        </Masonry>
                                    </div>
                                </Col>
                            </Row>
                        );
                    }}
                </Query>
            </SectionContent>
        </React.Fragment>
    );
}
