import React, { useState, useEffect, useRef } from 'react';
import { message, Comment, Icon, Card, Divider, Tooltip, Modal, Avatar, Typography } from 'antd';
import moment from 'moment';
import FeedCommentForm from './FeedCommentForm';
import { GET_POST_COMMENTS, GET_LEAGUE_POSTS } from '../data/queries';
import { DELETE_POST_COMMENT_MUTATION } from '../data/mutations';
import { useMutation, useQuery } from '@apollo/react-hooks';
import LikeCommentButton from './LikeCommentButton';
import pluralize from 'pluralize';
import AddCommentModal from './AddCommentModal';

const { confirm } = Modal;

export default function NestedComment(props) {
    const { authState, user, post, comment, league } = props;
    const [reply, setReply] = useState(false);
    const [currentId, setCurrentId] = useState();
    const [deleteComment] = useMutation(DELETE_POST_COMMENT_MUTATION);
    const likes = (comment.likes_aggregate && comment.likes_aggregate.aggregate && comment.likes_aggregate.aggregate.count) || 0;
    const [commentModalVisible, setCommentModalVisible] = useState(false);

    const nestedComments = (comment.children || []).map((comment, index) => {
        return <NestedComment key={comment.id} authState={authState} user={user} post={post} comment={comment} type="child" />;
    });

    function handleReply() {
        setReply(true);
    }

    function handleEdit(id) {
        setCurrentId(id);
        setCommentModalVisible(true);
    }

    function handleCommentModalOk(data) {
        console.log(data);
        if (data) {
            // const updatedPlayers = players.concat(data);
            // setPlayers(updatedPlayers);
        }
        setCommentModalVisible(false);
    }

    function handleCommentModalCancel(e) {
        setCurrentId();
        setCommentModalVisible(false);
    }

    function handleDelete(commentId) {
        showConfirmDelete(commentId);
    }

    function showConfirmDelete(commentId) {
        confirm({
            title: 'Are you sure you want to delete this comment?',
            content: 'This action wil delete the comment and any sub comments.',
            centered: true,
            transitionName: 'fade',
            maskTransitionName: 'none',
            okText: `Delete`,
            onOk() {
                handleDeleteComment(commentId);
            },
            onCancel() {},
        });
    }

    async function handleDeleteComment(commentId) {
        message.info('Deleting comment...');

        deleteComment({
            variables: {
                id: commentId,
            },
            notifyOnNetworkStatusChange: true,
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: GET_LEAGUE_POSTS,
                    variables: { id: league.id },
                },
                {
                    query: GET_POST_COMMENTS,
                    variables: { post_id: post.id },
                },
            ],
        })
            .then((data) => {
                console.log(data);
                message.success('Comment deleted');
                // props.history.push(`/venues`);
            })
            .catch((error) => {
                console.log(error);
                message.info('There was an error', error);
                return;
            });
    }

    const actions = [
        <span key="comment-basic-like">
            {authState && authState.user && authState.user.id ? (
                <LikeCommentButton {...props} />
            ) : (
                <span style={{ paddingLeft: 4 }}>
                    {likes} {pluralize('Like', likes)}
                </span>
            )}
        </span>,

        <span key="comment-reply">
            {comment.children && authState && authState.user && authState.user.id && (
                <span
                    style={{ paddingLeft: 4 }}
                    onClick={() => {
                        handleReply();
                        setCurrentId(comment.id);
                    }}
                >
                    Reply
                </span>
            )}
        </span>,

        <span key="comment-edit">
            {comment.children && authState && authState.user && authState.user.id && (
                <span
                    style={{ paddingLeft: 4 }}
                    onClick={() => {
                        handleEdit(comment.id);
                    }}
                >
                    Edit
                </span>
            )}
        </span>,

        <span key="comment-delete">
            {authState && authState.user && (authState.user.id === post.user_id || authState.user.id === comment.user_id) && (
                <span
                    style={{ paddingLeft: 4 }}
                    // style={{ paddingLeft: 4, color: '#ff4d4e' }}
                    onClick={() => {
                        handleDelete(comment.id);
                    }}
                >
                    Delete
                </span>
            )}
        </span>,
        // <span key="comment-basic-reply-to">
        //     <Tooltip title="Comment" placement="bottom">
        //         <Icon type="message" theme={action === 'comment' ? 'filled' : 'outlined'} />
        //     </Tooltip>
        //     <span style={{ paddingLeft: 8, cursor: 'auto' }}>{comments}</span> Comments
        // </span>,
    ];

    return (
        <div>
            <Comment
                actions={actions}
                author={`${comment.user.first_name} ${comment.user.last_name}`}
                avatar={<Avatar src={comment.user.avatar} alt="User" />}
                content={<p>{comment.message}</p>}
                datetime={
                    <Tooltip title={moment(comment.created_at).format('YYYY-MM-DD HH:mm:ss')}>
                        <span>{moment(comment.created_at).fromNow()}</span>
                    </Tooltip>
                }
            >
                {reply === true && currentId === comment.id && authState && authState.user && authState.user.id && (
                    <FeedCommentForm user={user} post={post} comment={comment} parentId={comment.id} {...props} />
                )}
                {nestedComments}
            </Comment>

            <AddCommentModal
                selectedCommentId={currentId}
                onModalOk={handleCommentModalOk}
                onModalCancel={handleCommentModalCancel}
                modalVisible={commentModalVisible}
                {...props}
            />
        </div>
    );
}
