import React, { useState, useRef } from 'react';
import appStore from '../../../../lib/appStore';
import { view } from 'react-easy-state';
import { message, Input, Layout, Icon, Avatar, AutoComplete, Select } from 'antd';
import { playerSearch, coordinatesByIdentifier } from '../../../../lib/playerBracketCoords';
import { SEARCH_TOURNAMENT_PLAYERS, GET_FILTERED_PLAYER_BRACKET } from '../data/queries';

const { Footer } = Layout;
const { Option } = Select;

function PlayerSearch(props) {
    const { tournament, client, className, onPlayerSearch, style } = props;
    const [playerSearchDataSource, setPlayerSearchDataSource] = useState([]);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    function queryDB(name) {
        // setPlayerSearchDataSource([]);
        return client
            .query({
                query: SEARCH_TOURNAMENT_PLAYERS,
                variables: {
                    name: `%${name}%`,
                    nickname: `%${name}%`,
                    tournament_id: tournament.id,
                },
            })
            .then((res) => {
                // console.log(res);
                setPlayerSearchDataSource(res.data.tournament_players);
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                // console.log(error);
                message.info('There was an error', error);
            });
    }

    function onSearch(name) {
        // queryDB(name);

        if (name) {
            setLoading(true);
            setOpen(true);
            queryDB(name);
        }
    }

    function onBlur() {
        setOpen(false);
    }

    function onChange(val) {
        if (!val || val === '') {
            setOpen(false);
            appStore.activePlayer = null;
            appStore.searchedPlayer = null;
            appStore.activePlayerMatchIdentifier = null;

            const scrollingElement = document.querySelector('.scroll-container');
            if (scrollingElement) {
                // const identifierRect = identifierElement.getBoundingClientRect();
                scrollingElement.scroll({
                    top: 0,
                    left: 0,
                });
            }
        }
    }

    async function onSelect(id) {
        setOpen(false);

        const filteredPlayer = await client.query({
            query: GET_FILTERED_PLAYER_BRACKET,
            variables: {
                challenger_id: parseInt(id),
                tournament_id: tournament.id,
            },
        });

        let identifier = filteredPlayer.data && filteredPlayer.data.tournament_brackets[0] && filteredPlayer.data.tournament_brackets[0].identifier;
        if (!identifier) {
            message.error('Player not found in bracket');
            return;
        }

        appStore.activePlayer = parseInt(id);
        appStore.searchedPlayer = parseInt(id);
        appStore.activePlayerMatchIdentifier = identifier;

        let newCoords = coordinatesByIdentifier(identifier);

        onPlayerSearch(newCoords);
    }

    return (
        <React.Fragment>
            {/* <Search
								placeholder="Find player"
								onSearch={handleSearch}
								onChange={onChange}
								maxLength={100}
								className="player-search"
							/> */}
            <AutoComplete
                dataSource={playerSearchDataSource}
                allowClear
                // value={autocompleteValue}
                // validate={validator}

                onSearch={onSearch}
                onSelect={onSelect}
                onChange={onChange}
                onBlur={onBlur}
                open={open}
                loading={loading}
                // onChange={onAutoCompleteChange}
                // size="large"
                // input={<Input style={{ backgroundColor: 'none !important' }} />}
                name="name"
                className={className || 'player-search'}
                placeholder="Search for a player name"
                optionLabelProp="display"
                filterOption={(inputValue, option) => {
                    return (
                        option.props &&
                        option.props.children &&
                        option.props.children.toString().toLowerCase().indexOf(inputValue.toString().toLowerCase()) >= 0
                    );
                }}
                style={style ? style : {}}
            >
                {playerSearchDataSource &&
                    playerSearchDataSource.map((item, index) => (
                        <Option key={index} value={item.id.toString()} display={item.name}>
                            {item.user_id && (
                                <Avatar
                                    size={24}
                                    style={{
                                        display: 'inline-block',
                                        marginRight: 8,
                                    }}
                                >
                                    <Icon type="user" />
                                </Avatar>
                            )}
                            {item.name}
                        </Option>
                    ))}
            </AutoComplete>
        </React.Fragment>
    );
}

export default view(PlayerSearch);
