import React, { useState, useEffect, useRef } from 'react';
import { message, Row, Col, Icon, Table, Typography, Slider, Result, Button } from 'antd';
import { functions } from '../../../firebase';
import { connectFunctionsEmulator, httpsCallable } from 'firebase/functions';
import SectionContent from '../../../components/SectionContent';
import SectionWrapper from '../../../components/SectionWrapper';
import CircularLoader from '../../../components/CircularLoader';

const styles = {};

export default function MatchStatsAdvanced(props) {
    const { authState, match, theme, user } = props;
    const [loading, setLoading] = useState(false);
    const [sheetData, setSheetData] = useState([]);
    const [columns, setColumns] = useState([]);

    useEffect(() => {
        getData();
    }, []);

    async function getData() {
        if (window.location.hostname.indexOf('localhost') > -1) {
            connectFunctionsEmulator(functions, 'localhost', 5001);
        }
        try {
            setLoading(true);
            const getSpreadsheetData = httpsCallable(functions, 'googSheets');
            const results = await getSpreadsheetData();
            const data = results && results.data;
            const columnData = data && data[0];
            const rows = data.slice(1, data.length - 1);
            // const data = columns.map()
            console.log('columns', columnData);

            const columnsFormatted = columnData.map((column) => {
                return {
                    title: column,
                    dataIndex: column.toLowerCase(),
                    render: (text, record) => {
                        return <div>{record[column] || '-'}</div>;
                    },
                };
            });
            // console.log('rows', rows);
            const formatted = rows.map((row, index) => {
                let rowData = {};
                columnData.forEach((column, index2) => {
                    rowData[column] = row[index2];
                });
                return {
                    id: index,
                    ...rowData,
                };
            });
            console.log(formatted);
            setLoading(false);
            setSheetData(formatted);
            setColumns(columnsFormatted);
            message.success(`Spreadsheet data loaded.`);
        } catch (e) {
            console.log(e);
            setLoading(false);
            message.error(`Error: ${JSON.stringify(e)}`);
        }
    }

    if (loading) {
        return <CircularLoader />;
    } else if (!sheetData) {
        return <div>Match stats not found.</div>;
    } else if (sheetData && sheetData.length === 0) {
        return <div>Match stats not found.</div>;
    } else {
        return (
            <SectionWrapper>
                <SectionContent padding="20px 40px">
                    <div className="table_nowrap" style={{ width: '100%' }}>
                        <Table
                            rowKey="id"
                            onRow={(record, rowIndex) => {
                                return {
                                    onClick: (event) => {
                                        console.log(record, rowIndex);
                                    }, // click row
                                    onDoubleClick: (event) => {}, // double click row
                                    onContextMenu: (event) => {}, // right button click row
                                    onMouseEnter: (event) => {}, // mouse enter row
                                    onMouseLeave: (event) => {}, // mouse leave row
                                };
                            }}
                            columns={columns}
                            dataSource={sheetData}
                            pagination={false}
                            tableLayout="auto"
                            scroll={{ x: 400 }}
                        />
                    </div>
                </SectionContent>
            </SectionWrapper>
        );
    }
}
