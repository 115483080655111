import React from 'react';
import { Avatar, Typography } from 'antd';

import ball1 from '../../assets/img/table/balls/1-ball.png';
import ball2 from '../../assets/img/table/balls/2-ball.png';
import ball3 from '../../assets/img/table/balls/3-ball.png';
import ball4 from '../../assets/img/table/balls/4-ball.png';
import ball5 from '../../assets/img/table/balls/5-ball.png';
import ball6 from '../../assets/img/table/balls/6-ball.png';
import ball7 from '../../assets/img/table/balls/7-ball.png';
import ball8 from '../../assets/img/table/balls/8-ball.png';
import ball9 from '../../assets/img/table/balls/9-ball.png';
import ball10 from '../../assets/img/table/balls/10-ball.png';
import ball11 from '../../assets/img/table/balls/11-ball.png';
import ball12 from '../../assets/img/table/balls/12-ball.png';
import ball13 from '../../assets/img/table/balls/13-ball.png';
import ball14 from '../../assets/img/table/balls/14-ball.png';
import ball15 from '../../assets/img/table/balls/15-ball.png';

const { Text } = Typography;

export default function BallsMade(props) {
    const { ball_size, balls_made, game_type } = props;

    return (
        <React.Fragment>
            <div
                style={{
                    backgroundColor: '#000',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: 3,
                }}
            >
                <div
                    style={{
                        maxWidth: 800,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    {game_type === 'EIGHT_BALL' || game_type === 'ONE_POCKET' ? (
                        <React.Fragment>
                            <span>
                                <Avatar size={ball_size} src={ball1} className={balls_made[1] === true && 'ball-scored'} />
                            </span>
                            <span>
                                <Avatar size={ball_size} src={ball2} className={balls_made[2] === true && 'ball-scored'} />
                            </span>
                            <span>
                                <Avatar size={ball_size} src={ball3} className={balls_made[3] === true && 'ball-scored'} />
                            </span>
                            <span>
                                <Avatar size={ball_size} src={ball4} className={balls_made[4] === true && 'ball-scored'} />
                            </span>
                            <span>
                                <Avatar size={ball_size} src={ball5} className={balls_made[5] === true && 'ball-scored'} />
                            </span>
                            <span>
                                <Avatar size={ball_size} src={ball6} className={balls_made[6] === true && 'ball-scored'} />
                            </span>
                            <span>
                                <Avatar size={ball_size} src={ball7} className={balls_made[7] === true && 'ball-scored'} />
                            </span>
                            <span>
                                <Avatar size={ball_size} src={ball8} className={balls_made[8] === true && 'ball-scored'} />
                            </span>
                            <span>
                                <Avatar size={ball_size} src={ball9} className={balls_made[9] === true && 'ball-scored'} />
                            </span>
                            <span>
                                <Avatar size={ball_size} src={ball10} className={balls_made[10] === true && 'ball-scored'} />
                            </span>
                            <span>
                                <Avatar size={ball_size} src={ball11} className={balls_made[11] === true && 'ball-scored'} />
                            </span>
                            <span>
                                <Avatar size={ball_size} src={ball12} className={balls_made[12] === true && 'ball-scored'} />
                            </span>
                            <span>
                                <Avatar size={ball_size} src={ball13} className={balls_made[13] === true && 'ball-scored'} />
                            </span>
                            <span>
                                <Avatar size={ball_size} src={ball14} className={balls_made[14] === true && 'ball-scored'} />
                            </span>
                            <span>
                                <Avatar size={ball_size} src={ball15} className={balls_made[15] === true && 'ball-scored'} />
                            </span>
                        </React.Fragment>
                    ) : game_type === 'NINE_BALL' ? (
                        <React.Fragment>
                            <span>
                                <Avatar size={ball_size} src={ball1} className={balls_made[1] === true && 'ball-scored'} />
                            </span>
                            <span>
                                <Avatar size={ball_size} src={ball2} className={balls_made[2] === true && 'ball-scored'} />
                            </span>
                            <span>
                                <Avatar size={ball_size} src={ball3} className={balls_made[3] === true && 'ball-scored'} />
                            </span>
                            <span>
                                <Avatar size={ball_size} src={ball4} className={balls_made[4] === true && 'ball-scored'} />
                            </span>
                            <span>
                                <Avatar size={ball_size} src={ball5} className={balls_made[5] === true && 'ball-scored'} />
                            </span>
                            <span>
                                <Avatar size={ball_size} src={ball6} className={balls_made[6] === true && 'ball-scored'} />
                            </span>
                            <span>
                                <Avatar size={ball_size} src={ball7} className={balls_made[7] === true && 'ball-scored'} />
                            </span>
                            <span>
                                <Avatar size={ball_size} src={ball8} className={balls_made[8] === true && 'ball-scored'} />
                            </span>
                            <span>
                                <Avatar size={ball_size} src={ball9} className={balls_made[9] === true && 'ball-scored'} />
                            </span>
                        </React.Fragment>
                    ) : game_type === 'TEN_BALL' ? (
                        <React.Fragment>
                            <span>
                                <Avatar size={ball_size} src={ball1} className={balls_made[1] === true && 'ball-scored'} />
                            </span>
                            <span>
                                <Avatar size={ball_size} src={ball2} className={balls_made[2] === true && 'ball-scored'} />
                            </span>
                            <span>
                                <Avatar size={ball_size} src={ball3} className={balls_made[3] === true && 'ball-scored'} />
                            </span>
                            <span>
                                <Avatar size={ball_size} src={ball4} className={balls_made[4] === true && 'ball-scored'} />
                            </span>
                            <span>
                                <Avatar size={ball_size} src={ball5} className={balls_made[5] === true && 'ball-scored'} />
                            </span>
                            <span>
                                <Avatar size={ball_size} src={ball6} className={balls_made[6] === true && 'ball-scored'} />
                            </span>
                            <span>
                                <Avatar size={ball_size} src={ball7} className={balls_made[7] === true && 'ball-scored'} />
                            </span>
                            <span>
                                <Avatar size={ball_size} src={ball8} className={balls_made[8] === true && 'ball-scored'} />
                            </span>
                            <span>
                                <Avatar size={ball_size} src={ball9} className={balls_made[9] === true && 'ball-scored'} />
                            </span>
                            <span>
                                <Avatar size={24} src={ball10} className={balls_made[10] === true && 'ball-scored'} />
                            </span>
                        </React.Fragment>
                    ) : (
                        <React.Fragment></React.Fragment>
                    )}
                </div>
            </div>
        </React.Fragment>
    );
}
