import React, { useState, useEffect, useRef } from 'react';
import { message, Avatar, Collapse, Radio, Typography, Layout, Modal, Tag, Tooltip, Row, Col, Button, Icon } from 'antd';
import { withRouter, Link } from 'react-router-dom';
import { Form, FormItem, InputNumber } from 'formik-antd';
import { Formik, ErrorMessage } from 'formik';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { UPDATE_MATCH_MUTATION, UPDATE_MATCH_GAME, CREATE_MATCH_GAME, DELETE_MATCH_GAME, RESET_MATCH_GAMES } from './data/mutations';
import { GET_MATCH_QUERY, GET_MATCH_GAMES_QUERY, GET_EVENT_LIVE_TABLE_QUERY2 } from './data/queries';
import { Query, Subscription } from '@apollo/client/react/components';
import Card, { Meta } from '../../../components/Card';
import CircleFlag from '../../../components/CircleFlag';
import CircleStateFlag from '../../../components/CircleStateFlag';
import CircularLoader from '../../../components/CircularLoader';
import { getGameType, getCountryName, displayEnumType } from '../../../utils';
import axios from 'axios';
import apiEndPoint from '../../../utils/apiEndpoint';
import ArtisticShotModal from './ArtisticShotModal';
import tableBlue from '../../../assets/img/table/table-blue.png';
import slugify from 'slugify';
// import RackTracker from './RackTracker';
import { QuestionCircleFilled } from '@ant-design/icons';
import numeral from 'numeral';
import Ripples from 'react-ripples';
import _ from 'lodash';
import moment from 'moment-timezone';
import momentDurationFormatSetup from 'moment-duration-format';
momentDurationFormatSetup(moment);

const { Text, Title } = Typography;
const { confirm } = Modal;
const { Sider, Content, Header } = Layout;
const { Panel } = Collapse;

const styles = {
    container: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#fff',
    },
    btnPoints: {
        fontSize: 30,
        fontWeight: 700,
        width: 60,
        height: 55,
        lineHeight: '50px',
    },
};

function EventScoreboardArtistic(props) {
    const { client, backUrl, matchData, tableData, subscribeToMore, subscribeToNewData, theme } = props;
    const [endMatchDisabled, setEndMatchDisabled] = useState(true);
    const [updateBracketMatch, updateBracketMatchRes] = useMutation(UPDATE_MATCH_MUTATION);
    // const [scoringStarted, setScoringStarted] = useState(false);
    const [scoringStarted, setScoringStarted] = useState(matchData.is_scoring_started === true ? true : false);
    const [userCheckMatchEnding, setUserCheckMatchEnding] = useState(false);
    const [shotModalVisible, setShotModalVisible] = useState(false);
    const [selectedShot, setSelectedShot] = useState();
    const [thumb, setThumb] = useState();
    const [matchEnded, setMatchEnded] = useState(false);
    const formRef = useRef();

    const stats = ['Balls pocketed', 'Unforced errors'];
    const table = tableData;
    let playerRaceTo;
    let opponentRaceTo;
    playerRaceTo = matchData.player_race_to || matchData.race_to || 0;
    opponentRaceTo = matchData.opponent_race_to || matchData.race_to || 0;

    let duration;
    if (matchData.start_date_time && matchData.end_date_time) {
        var start = moment(matchData.start_date_time);
        var end = moment(matchData.end_date_time);
        duration = moment.duration(end.diff(start, 'milliseconds')).format('h [hrs] m [mins]');
        // duration = moment.duration(end.diff(start, 'milliseconds')).format('hh:mm');
    } else {
        duration = moment.duration(moment.utc().diff(matchData.start_date_time, 'milliseconds')).format('h [hrs] m [mins]');
        // duration = moment.duration(moment.utc().diff(match.start_date_time, 'milliseconds')).format('hh:mm');
    }

    useEffect(() => {
        let unsubscribe;
        if (subscribeToNewData) {
            unsubscribe = subscribeToNewData(subscribeToMore);
        }
        return () => {
            if (unsubscribe) {
                unsubscribe();
            }
        };
    }, []);

    useEffect(() => {
        if (matchData) {
            if (matchData.is_scoring_started && matchData.is_scoring_started !== scoringStarted) {
                setScoringStarted(matchData.is_scoring_started);
            }
            if (matchData.status === 'COMPLETED') {
                setEndMatchDisabled(true);
            }
            // else if (matchData.status !== 'COMPLETED' && checkForWinner(matchData) === true && matchData.status !== 'COMPLETED') {
            //     setEndMatchDisabled(false);
            //     showConfirmEndMatch(matchData);
            // }
            else {
                setEndMatchDisabled(false);
            }
        }
    }, [matchData]);

    async function handleShotModalOk(player) {
        console.log(player);
        setShotModalVisible(false);
    }

    function handleShotModalCancel(e) {
        setShotModalVisible(false);
    }

    function getVideoThumbnail() {
        const payload = {
            match_id: matchData.id,
            table_id: matchData.table_id,
            table_name: matchData.table_name,
            table_slug: matchData.tableSlug,
            game_type: matchData.game_type,
            player: matchData.player_name,
            opponent: matchData.opponent_name,
            event: 'thumb',
            webhook_url: 'https://us-central1-digital-pool.cloudfunctions.net/matchStatusUpdate',
            callback_url: 'https://www.onsitepool.com/nblusa/digitalpool/',
        };

        // const url = 'https://www.onsitepool.com/nblusa/digitalpool/';
        // const options = {
        //     method: 'POST',
        //     headers: {
        //         Accept: 'application/json',
        //         'Content-Type': 'application/json;charset=UTF-8',
        //     },
        //     body: JSON.stringify(payload),
        // };

        // fetch(url, options).then((response) => {
        //     console.log(response.status);
        // });

        return axios
            .post(`${apiEndPoint}matchStatusUpdate`, payload)
            .then((res) => {
                console.log(res.data);

                if (res.data && res.data.success === true) {
                    // message.success(JSON.stringify(res.data));

                    if (res.data.data) {
                        setThumb(res.data.data);
                    }
                } else {
                    message.warning(JSON.stringify(res.data));
                }

                return res.data;
            })
            .catch((error) => {
                console.log(error);
                message.error(`There was an error: ${JSON.stringify(error)}`);
                return error;
            });
    }

    async function startMatch(matchData) {
        console.log(matchData);

        await resetGames(matchData);

        const startTime = moment.utc();
        const changes = {
            start_date_time: startTime,
            status: 'IN_PROGRESS',
            progress: 0,
            is_scoring_started: true,
        };

        const payload = {
            match_id: matchData.id,
            table_id: matchData.table_id,
            table_name: matchData.table_name,
            table_slug: matchData.tableSlug,
            game_type: matchData.game_type,
            player: matchData.player_name,
            opponent: matchData.opponent_name,
            event: 'start',
            webhook_url: 'https://us-central1-digital-pool.cloudfunctions.net/matchStatusUpdate',
            callback_url: 'https://www.onsitepool.com/nblusa/digitalpool',
        };

        return client
            .mutate({
                mutation: UPDATE_MATCH_MUTATION,
                variables: {
                    id: matchData.id,
                    changes: changes,
                },
                notifyOnNetworkStatusChange: true,
                awaitRefetchQueries: true,
            })
            .then((data) => {
                console.log(data);
                message.success(`Match started at ${moment.utc(startTime).local().format('LT')}`);

                createFirstGame(matchData);

                // return axios
                //     .post(`${apiEndPoint}matchStatusUpdate`, payload)
                //     .then((res) => {
                //         console.log(res.data);

                //         // if (res.data && res.data.success === true) {
                //         //     message.success(JSON.stringify(res.data));
                //         // } else {
                //         //     message.warning(JSON.stringify(res.data));
                //         // }

                //         return res.data;
                //     })
                //     .catch((error) => {
                //         console.log(error);
                //         // message.error(`There was an error: ${JSON.stringify(error)}`);
                //         return error;
                //     });
            })
            .catch((error) => {
                console.error(error);
                message.error(`There was an error: ${JSON.stringify(error)}`);
                throw error;
            });
    }

    function endMatch(match) {
        const endTime = moment.utc();
        const changes = {
            end_date_time: endTime,
            status: 'COMPLETED',
            progress: 100,
            // pool_table_id: match.table_id && match.table_id,
            pool_table_id: null,
        };

        const payload = {
            match_id: matchData.id,
            table_id: matchData.table_id,
            table_name: matchData.table_name,
            table_slug: matchData.tableSlug,
            game_type: matchData.game_type,
            player: matchData.player_name,
            opponent: matchData.opponent_name,
            event: 'split',
            webhook_url: 'https://us-central1-digital-pool.cloudfunctions.net/matchStatusUpdate',
            callback_url: 'https://www.onsitepool.com/nblusa/digitalpool',
        };

        if (tableData && tableData.kiosk_ip_address) {
            axios
                .post(`${apiEndPoint}fullyKiosk`, {
                    endpoint: `${tableData.kiosk_ip_address}&cmd=loadStartUrl`,
                })
                .then((res) => {
                    console.log(res.data);
                    return res.data;
                })
                .catch((error) => {
                    console.log(error);
                    message.error(`There was an error: ${JSON.stringify(error)}`);
                    return error;
                });
        }

        return client
            .mutate({
                mutation: UPDATE_MATCH_MUTATION,
                variables: {
                    id: match.id,
                    changes: changes,
                },
                notifyOnNetworkStatusChange: true,
                awaitRefetchQueries: true,
                refetchQueries: [
                    {
                        query: GET_EVENT_LIVE_TABLE_QUERY2,
                        variables: {
                            event_slug: matchData.eventSlug,
                            table_slug: matchData.tableSlug,
                        },
                    },
                ],
            })
            .then((data) => {
                console.log(data);
                message.success(`Match scores submitted at ${moment.utc(endTime).local().format('LT')}`);

                axios
                    .post(
                        'https://elicit.waypointsoftware.io/capture_post.php?xAuthentication=09e648487cf9156231d88645459d5ef0',
                        {
                            match_id: matchData.id,
                        },
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data',
                            },
                        }
                    )
                    .then((response) => console.log(response))
                    .catch((error) => {
                        console.error('There was an error!', error);
                    });

                // return axios
                //     .post(`${apiEndPoint}matchStatusUpdate`, payload)
                //     .then((res) => {
                //         console.log(res.data);
                //         // message.success(JSON.stringify(res.data));
                //         return res.data;
                //     })
                //     .catch((error) => {
                //         console.log(error);
                //         // message.error(`There was an error: ${JSON.stringify(error)}`);
                //         return error;
                //     });
            })
            .catch((error) => {
                console.error(error);
                message.error(`There was an error: ${JSON.stringify(error)}`);
                throw error;
            });
    }

    function showConfirmEndMatch(matchData) {
        const { winner, loser } = determineMatchWinner(matchData);
        setUserCheckMatchEnding(true);
    }

    function checkForWinner(matchData) {
        let playerRaceTo;
        let opponentRaceTo;
        playerRaceTo = matchData.player_race_to || matchData.race_to || 0;
        opponentRaceTo = matchData.opponent_race_to || matchData.race_to || 0;

        if (matchData.player_score >= playerRaceTo || matchData.opponent_score >= opponentRaceTo) {
            return true;
        } else {
            return false;
        }
    }

    function determineMatchWinner(matchData) {
        let playerRaceTo;
        let opponentRaceTo;
        playerRaceTo = matchData.player_race_to || matchData.race_to || 0;
        opponentRaceTo = matchData.opponent_race_to || matchData.race_to || 0;
        let winner;
        let loser;

        if (matchData.player_score >= playerRaceTo) {
            winner = {
                id: matchData.player_id,
                name: matchData.player_name,
                country: matchData.player_country,
                score: matchData.player_score,
                is_winner: true,
            };
            loser = {
                id: matchData.opponent_id,
                name: matchData.opponent_name,
                country: matchData.opponent_country,
                score: matchData.opponent_score,
                is_winner: false,
            };
        } else if (matchData.opponent_score >= opponentRaceTo) {
            winner = {
                id: matchData.opponent_id,
                name: matchData.opponent_name,
                country: matchData.opponent_country,
                score: matchData.opponent_score,
                is_winner: true,
            };
            loser = {
                id: matchData.player_id,
                name: matchData.player_name,
                country: matchData.player_country,
                score: matchData.player_score,
                is_winner: false,
            };
        }

        console.log({
            winner,
            loser,
        });

        return {
            winner,
            loser,
        };
    }

    function createFirstGame(matchData) {
        console.log(matchData);

        const player =
            matchData.match_players &&
            matchData.match_players.filter((player) => {
                return player.name === matchData.player_name;
            })[0];

        const opponent =
            matchData.match_players &&
            matchData.match_players.filter((opponent) => {
                return opponent.name === matchData.opponent_name;
            })[0];

        return client
            .mutate({
                mutation: CREATE_MATCH_GAME,
                variables: {
                    objects: [
                        {
                            match_id: matchData.id,
                            match_number: matchData.match_number,
                            game_number: 0,
                            ...(player && player.id && { player_id: player.id }),
                            player_score: 0,
                            player_points: 0,
                            player_is_winner: false,
                            player_break_run: false,
                            player_runout: false,
                            ...(opponent && opponent.id && { opponent_id: opponent.id }),
                            opponent_score: 0,
                            opponent_points: 0,
                            opponent_is_winner: false,
                            opponent_break_run: false,
                            opponent_runout: false,
                            status: 'IN_PROGRESS',
                            progress: 0,
                            duration: null,
                            start_date_time: moment.utc(),
                            end_date_time: moment.utc(),
                            table_id: matchData.table_id,
                        },
                    ],
                },
                notifyOnNetworkStatusChange: true,
                awaitRefetchQueries: true,
                refetchQueries: [
                    {
                        query: GET_MATCH_GAMES_QUERY,
                        variables: { match_id: matchData.id },
                    },
                ],
            })
            .then((data) => {
                console.log(data);
                return data;

                // message.success({
                // 	content: `${text} updated`,
                // 	key,
                // 	duration: 1
                // });
            })
            .catch((error) => {
                console.error(error);
                message.info('There was an error', error);
                return error;
            });
    }

    function resetGames(matchData) {
        return client
            .mutate({
                mutation: RESET_MATCH_GAMES,
                variables: {
                    match_id: matchData.id,
                },
                notifyOnNetworkStatusChange: true,
                awaitRefetchQueries: true,
                refetchQueries: [
                    {
                        query: GET_MATCH_GAMES_QUERY,
                        variables: { match_id: matchData.id },
                    },
                ],
            })
            .then((data) => {
                console.log(data);
                return data;
            })
            .catch((error) => {
                console.error(error);
                message.info('There was an error', error);
                return error;
            });
    }

    function addGame(challenger, newScore, matchData, gameData, stats) {
        console.log({
            challenger,
            newScore,
            matchData,
            gameData,
        });

        const currentGame = gameData && gameData[gameData.length - 1];

        console.log('CURRENT GAME', currentGame);

        const player =
            matchData.match_players &&
            matchData.match_players.filter((player) => {
                return player.name === matchData.player_name;
            })[0];

        const opponent =
            matchData.match_players &&
            matchData.match_players.filter((opponent) => {
                return opponent.name === matchData.opponent_name;
            })[0];

        return client
            .mutate({
                mutation: CREATE_MATCH_GAME,
                variables: {
                    objects: [
                        {
                            match_id: matchData.id,
                            match_number: matchData.match_number,
                            game_number: matchData.player_score + matchData.opponent_score + 1,
                            ...(player && player.id && { player_id: player.id }),
                            player_score: challenger === 'player' ? newScore : matchData.player_score,
                            player_points: matchData.player_points,
                            player_is_winner: challenger === 'player' ? true : false,
                            player_break_run: stats ? stats.playerBreakRun : false,
                            player_runout: stats ? stats.playerRunout : false,
                            player_tpa: currentGame && currentGame.player_tpa,
                            player_balls_pocketed: currentGame && currentGame.player_balls_pocketed,
                            player_unforced_errors: currentGame && currentGame.player_unforced_errors,
                            ...(opponent && opponent.id && { opponent_id: opponent.id }),
                            opponent_score: challenger === '.opponent' ? newScore : matchData.opponent_score,
                            opponent_points: matchData.opponent_points,
                            opponent_is_winner: challenger === '.opponent' ? true : false,
                            opponent_break_run: stats ? stats.opponentBreakRun : false,
                            opponent_runout: stats ? stats.opponentRunout : false,
                            opponent_tpa: currentGame && currentGame.opponent_tpa,
                            opponent_balls_pocketed: currentGame && currentGame.opponent_balls_pocketed,
                            opponent_unforced_errors: currentGame && currentGame.opponent_unforced_errors,
                            status: 'COMPLETED',
                            progress: 100,
                            duration: null,
                            start_date_time: moment.utc(),
                            end_date_time: moment.utc(),
                            table_id: matchData.table_id,
                        },
                    ],
                },
                notifyOnNetworkStatusChange: true,
                awaitRefetchQueries: true,
                refetchQueries: [
                    {
                        query: GET_MATCH_GAMES_QUERY,
                        variables: { match_id: matchData.id },
                    },
                ],
            })
            .then((data) => {
                console.log(data);
                return data;

                // message.success({
                // 	content: `${text} updated`,
                // 	key,
                // 	duration: 1
                // });
            })
            .catch((error) => {
                console.error(error);
                message.info('There was an error', error);
                return error;
            });
    }

    function removeGame(challenger, matchData, gameData) {
        console.log({
            challenger,
            matchData,
            gameData,
        });

        const currentGame = gameData && gameData[gameData.length - 1];

        return client
            .mutate({
                mutation: DELETE_MATCH_GAME,
                variables: {
                    id: currentGame.id,
                    match_id: matchData.id,
                },
                notifyOnNetworkStatusChange: true,
                awaitRefetchQueries: true,
                refetchQueries: [
                    {
                        query: GET_MATCH_GAMES_QUERY,
                        variables: { match_id: matchData.id },
                    },
                ],
            })
            .then((data) => {
                console.log(data);
                return data;

                // message.success({
                // 	content: `${text} updated`,
                // 	key,
                // 	duration: 1
                // });
            })
            .catch((error) => {
                console.error(error);
                message.info('There was an error', error);
                return error;
            });
    }

    function getMatchProgress(matchData) {
        const totalRace = parseInt(matchData.player_race_to + matchData.opponent_race_to);
        const matchProgress =
            matchData.status === 'COMPLETED'
                ? 100
                : parseInt(matchData.player_score) >= totalRace || parseInt(matchData.opponent_score) >= totalRace
                ? 100
                : (((matchData.player_score + matchData.opponent_score) / totalRace) * 100).toFixed(0);
        return parseInt(matchProgress);
    }

    function incrementPlayerScore(challenger, newScore, matchData, gameData, stats) {
        const matchProgress = getMatchProgress(matchData);
        const totalScore = newScore;
        const changes = {
            ...(challenger === 'player' ? { player_score: newScore } : { opponent_score: newScore }),
            progress: matchProgress,
        };
        console.log('total score', totalScore);
        updateMatch(changes, matchData)
            .then((result) => {
                console.log(result);
                // addGame(challenger, newScore, matchData, gameData, stats);
            })
            .catch((error) => {
                console.log(error);
                message.error('There was an error', error);
            });
    }

    function decrementPlayerScore(challenger, newScore, matchData, gameData) {
        const matchProgress = getMatchProgress(matchData);
        const totalScore = newScore;
        const changes = {
            ...(challenger === 'player' ? { player_score: newScore } : { opponent_score: newScore }),
            ...(totalScore === 0 && { is_scoring_started: false }),
            progress: matchProgress,
        };
        const currentGame = gameData && gameData[gameData.length - 1];
        console.log('total score', totalScore);
        updateMatch(changes, matchData)
            .then((result) => {
                console.log(result);
                // if (totalScore > 0) {
                //     removeGame(challenger, matchData, gameData);
                // } else {
                //     resetGames(matchData);
                // }
            })
            .catch((error) => {
                console.log(error);
                message.error('There was an error', error);
            });
    }

    function updateMatch(changes, matchData) {
        console.log(matchData);
        console.log('changes', changes);

        return updateBracketMatch({
            variables: {
                id: matchData.id,
                changes: changes,
            },

            optimisticResponse: {
                update_matches: {
                    __typename: 'matches_mutation_response',
                    returning: {
                        ...changes,
                    },
                },
            },
            update: (cache, { data }) => {
                console.log({
                    cache,
                    data,
                });
                // Get the current cached data.
                const existingMatchData = client.readQuery({
                    // The cached query key is the same as the name of the GQL schema
                    query: GET_EVENT_LIVE_TABLE_QUERY2,
                    variables: { event_slug: matchData.eventSlug, table_slug: matchData.tableSlug },
                });

                // Now we combine the optimisticResponse we passed in earlier and the existing data
                const newMatches = [data.update_matches, existingMatchData.matches];

                console.log('new matches', newMatches);
                // Finally we overwrite the cache
                cache.writeQuery({
                    query: GET_EVENT_LIVE_TABLE_QUERY2,
                    variables: { event_slug: matchData.eventSlug, table_slug: matchData.tableSlug },
                    data: newMatches,
                });
            },
        })
            .then((data) => {
                console.log(data);
                // handleMatchUpdate(matchData);
                return data;
                // message.success({
                // 	content: `${text} updated`,
                // 	key,
                // 	duration: 1
                // });
            })
            .catch((error) => {
                console.error(error);
                message.info('There was an error', error);
                return error;
            });
    }

    function showConfirmStats(challenger, score, matchData, gameData) {
        // const newScore = score + 1;
        const currentPlayer = challenger === 'player' ? matchData.player_name : matchData.opponent_name;
        const radioStyle = {
            display: 'block',
            height: '30px',
            lineHeight: '30px',
        };

        let playerBreakRun = false;
        let playerRunout = false;
        let opponentBreakRun = false;
        let opponentRunout = false;
        let stats = {};

        function onChangeStats(e) {
            console.log(e.target.value);
            const breakRun = e.target.value === 'break_run';
            const runout = e.target.value === 'run_out';
            const none = e.target.value === 'none';

            console.log(breakRun);
            console.log(runout);
            console.log(challenger);

            if (none) {
                playerBreakRun = true;
                opponentBreakRun = false;
                playerRunout = false;
                opponentRunout = false;
            }

            if (challenger === 'player') {
                if (breakRun === true) {
                    console.log('player break and run');
                    playerBreakRun = true;
                    opponentBreakRun = false;
                } else if (runout === true) {
                    console.log('player runout');
                    playerRunout = true;
                    opponentRunout = false;
                } else {
                    playerBreakRun = false;
                    opponentBreakRun = false;
                }
            } else if (challenger === '.opponent') {
                if (breakRun === true) {
                    console.log('opponent break and run');
                    playerBreakRun = false;
                    opponentBreakRun = true;
                } else if (runout === true) {
                    console.log('opponent runout');
                    playerRunout = false;
                    opponentRunout = true;
                } else {
                    playerRunout = false;
                    opponentRunout = false;
                }
            }

            stats = {
                playerBreakRun,
                playerRunout,
                opponentBreakRun,
                opponentRunout,
            };
            console.log(stats);
        }

        confirm({
            title: `Did ${currentPlayer} get a Break and Run or Table Run this game?`,
            centered: true,

            transitionName: 'fade',
            maskTransitionName: 'none',
            content: (
                <div>
                    <Radio.Group onChange={onChangeStats} defaultValue="none">
                        <Radio style={radioStyle} value="break_run">
                            Break and Run (BR)
                        </Radio>
                        <Radio style={radioStyle} value="run_out">
                            Table Run (RO)
                        </Radio>
                        <Radio style={radioStyle} value="none">
                            None
                        </Radio>
                    </Radio.Group>
                </div>
            ),
            onOk() {
                console.log('ok');
                const newScore = score + 1;
                incrementPlayerScore(challenger, newScore, matchData, gameData, stats);
            },
            onCancel() {
                console.log('cancel');
            },
            okText: 'Save',
            cancelText: 'Cancel',
        });
    }

    function startScoring() {
        setScoringStarted(true);
    }

    function handleMatchUpdate(matchData) {
        console.log('match', matchData);

        if (checkForWinner(matchData) === true) {
            setEndMatchDisabled(false);
            showConfirmEndMatch(matchData);
        } else {
            setEndMatchDisabled(true);
        }
    }

    function calculateTPA(ballsPocketed, errors) {
        return numeral(ballsPocketed / (ballsPocketed + errors)).format('0.000');
    }

    function handleSubmit(player, playerData, stat, value, values, gameData) {
        console.log({
            player,
            playerData,
            stat,
            value,
            values,
            gameData,
        });

        const gameId = gameData && gameData[gameData.length - 1] && gameData[gameData.length - 1].id;
        if (gameId) {
            const playerBallsPocketed = values.player_balls_pocketed;
            const playerUnforcedErrors = values.player_unforced_errors;
            const opponentBallsPocketed = values.opponent_balls_pocketed;
            const opponentUnforcedErrors = values.opponent_unforced_errors;
            const changes = {
                player_tpa: calculateTPA(playerBallsPocketed, playerUnforcedErrors),
                player_balls_pocketed: playerBallsPocketed,
                player_unforced_errors: playerUnforcedErrors,
                opponent_tpa: calculateTPA(opponentBallsPocketed, opponentUnforcedErrors),
                opponent_balls_pocketed: opponentBallsPocketed,
                opponent_unforced_errors: opponentUnforcedErrors,
            };

            updateGame(gameId, changes);
        }
    }

    function updateGame(gameId, changes) {
        console.log({
            gameId,
            changes,
        });
        return client
            .mutate({
                mutation: UPDATE_MATCH_GAME,
                variables: {
                    id: gameId,
                    match_id: matchData.id,
                    changes: changes,
                },
                notifyOnNetworkStatusChange: true,
                awaitRefetchQueries: true,
                refetchQueries: [
                    {
                        query: GET_MATCH_GAMES_QUERY,
                        variables: { match_id: matchData.id },
                    },
                ],
            })
            .then((data) => {
                console.log(data);
                // message.success('Game updated');
                return data;
            })
            .catch((error) => {
                console.error(error);
                message.info('There was an error', error);
                return error;
            });
    }

    function isDisabled(matchData) {
        return matchData.status === 'COMPLETED' || matchData.status === 'COMPLETED';
    }

    function renderStat(player, playerData, stat, key, values, index, gameData) {
        return (
            <div style={index === stats.length - 1 ? { borderBottom: 'none' } : { borderBottom: '1px solid rgba(255,255,255,0.1)' }}>
                <Row
                    justify="space-between"
                    type="flex"
                    style={{
                        // backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        alignItems: 'center',
                        borderRadius: 6,
                        color: '#fff',
                    }}
                >
                    <Col xs={24} sm={12} align="start">
                        <div style={{ fontSize: 16, paddingLeft: 5 }}>
                            <Title level={4} style={{ margin: 0, color: '#fff' }}>
                                {stat}
                            </Title>
                        </div>
                    </Col>
                    <Col xs={24} sm={12} align="center" style={{ paddingTop: 0, paddingBottom: 0 }}>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-end',
                            }}
                        >
                            {/* <Tooltip placement="top" title={stat}>
                                <QuestionCircleFilled style={{ color: '#999', marginRight: 10 }} />
                            </Tooltip> */}
                            <div>
                                <FormItem name={`${key}`}>
                                    <InputNumber
                                        name={`${key}`}
                                        size="large"
                                        defaultValue={0}
                                        placeholder={0}
                                        onPressEnter={() => {
                                            formRef.current.handleSubmit();
                                            // handleSubmit(formRef.current.state.values);
                                        }}
                                        // onBlur={() => {
                                        // 	formRef.current.handleSubmit();
                                        // 	// handleSubmit(formRef.current.state.values);
                                        // }}
                                        onChange={(value) => {
                                            formRef.current.handleSubmit();
                                            if (value) {
                                                const updatedValues = {
                                                    ...formRef.current.state.values,
                                                    [key]: value,
                                                };
                                                handleSubmit(player, playerData, key, value, updatedValues, gameData);
                                            }
                                        }}
                                        style={{
                                            flex: 1,
                                            fontSize: 18,
                                            height: '100%',
                                            backgroundColor: 'transparent',
                                            paddingLeft: 0,
                                            paddingRight: 0,
                                            marginRight: 8,
                                            border: '1px solid #5e6977',
                                            borderRadius: 4,
                                            maxWidth: 80,
                                            color: '#fff',
                                        }}
                                    />
                                </FormItem>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <Ripples color="#ccc">
                                    <Button
                                        shape="circle"
                                        type="primary"
                                        ghost
                                        onClick={() => {
                                            const currentValue = formRef.current.state.values[key];
                                            const newValue = currentValue + 1;
                                            const updatedValues = {
                                                ...formRef.current.state.values,
                                                [key]: newValue,
                                            };
                                            formRef.current.setValues(updatedValues);
                                            formRef.current.handleSubmit();
                                            handleSubmit(player, playerData, key, newValue, updatedValues, gameData);
                                        }}
                                        style={{ width: 40, height: 40, borderWidth: 2 }}
                                    >
                                        <Icon type="plus" />
                                    </Button>
                                </Ripples>
                                <Ripples color="#ccc">
                                    <Button
                                        shape="circle"
                                        type="danger"
                                        ghost
                                        onClick={() => {
                                            const currentValue = formRef.current.state.values[key];
                                            const newValue = currentValue - 1;
                                            const updatedValues = {
                                                ...formRef.current.state.values,
                                                [key]: newValue,
                                            };
                                            formRef.current.setValues(updatedValues);
                                            formRef.current.handleSubmit();
                                            handleSubmit(player, playerData, key, newValue, updatedValues, gameData);
                                        }}
                                        style={{ marginLeft: 10, width: 40, height: 40, borderWidth: 2 }}
                                    >
                                        <Icon type="minus" />
                                    </Button>
                                </Ripples>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }

    return (
        <Query
            query={GET_MATCH_GAMES_QUERY}
            fetchPolicy="cache-and-network"
            notifyOnNetworkStatusChange={true}
            variables={{ match_id: matchData.id }}
            onCompleted={(data, error, loading) => {
                console.log(data);
            }}
        >
            {({ loading, error, data, refetch }) => {
                if (loading) return <CircularLoader />;
                if (error) return <div>Error: {error.message}</div>;

                const gameData = data && data.match_games;
                const currentGame = gameData && gameData[gameData.length - 1];
                console.log(currentGame);

                const initialValues = {};
                stats.forEach((stat, index) => {
                    const statUnderscored = stat.split(' ').join('_').toLowerCase();
                    const keyPlayer = `player_${statUnderscored}`;
                    const keyOpponent = `opponent_${statUnderscored}`;
                    initialValues[keyPlayer] = (currentGame && currentGame[keyPlayer]) || 0;
                    initialValues[keyOpponent] = (currentGame && currentGame[keyOpponent]) || 0;
                });

                return (
                    <Layout style={{ height: '100%', backgroundColor: 'transparent' }}>
                        <Content style={{ position: 'relative', display: 'flex', alignItems: 'center', backgroundColor: 'transparent', padding: 10 }}>
                            {thumb && (
                                <div style={{ position: 'absolute', top: 0, left: 0, zIndex: 10, width: 800 }}>
                                    <img src={`data:image/png;base64, ${thumb}`} alt="Table Thumbnail" width="100%" />
                                </div>
                            )}

                            <div
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    paddingTop: 10,
                                    paddingBottom: 10,
                                    paddingLeft: 10,
                                    paddingRight: 20,
                                    width: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    zIndex: 11,
                                    // boxShadow: 'rgb(0 0 0 / 20%) 0px 1px 15px 0px'
                                }}
                            >
                                <div />
                                <div>
                                    {matchEnded === true && (
                                        <div>
                                            <div
                                                style={{
                                                    position: 'fixed',
                                                    width: '100%',
                                                    height: '100%',
                                                    top: 0,
                                                    left: 0,
                                                    right: 0,
                                                    bottom: 0,
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                }}
                                            >
                                                <Title
                                                    level={1}
                                                    style={{
                                                        padding: 20,
                                                        margin: 0,
                                                        color: '#fff',
                                                        background: 'rgba(255,255,255,0.05)',
                                                        borderRadius: 20,
                                                        textTransform: 'uppercase',
                                                        letterSpacing: 2,
                                                        fontSize: 60,
                                                    }}
                                                >
                                                    Match finished
                                                </Title>
                                            </div>
                                        </div>
                                    )}

                                    {userCheckMatchEnding === true && matchEnded === false && (
                                        <div>
                                            <div
                                                style={{
                                                    position: 'fixed',
                                                    top: '50%',
                                                    left: '50%',
                                                    transform: 'translate(-50%, -50%)',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <Ripples color="#000">
                                                    <Button
                                                        size="large"
                                                        type="primary"
                                                        onClick={() => {
                                                            setMatchEnded(true);
                                                            endMatch(matchData);
                                                        }}
                                                        style={{
                                                            width: '100%',
                                                            paddingLeft: 40,
                                                            paddingRight: 40,
                                                            height: 160,
                                                            fontSize: 100,
                                                            fontWeight: 700,
                                                            borderRadius: 20,
                                                            // textShadow: '0 2px 5px rgba(0,0,0,0.25)',
                                                        }}
                                                    >
                                                        SUBMIT SCORES
                                                    </Button>
                                                </Ripples>
                                                <Ripples color="#000">
                                                    <Button
                                                        size="large"
                                                        type="ghost"
                                                        onClick={() => {
                                                            setUserCheckMatchEnding(false);
                                                        }}
                                                        style={{
                                                            marginTop: 20,
                                                            paddingLeft: 40,
                                                            paddingRight: 40,
                                                            height: 140,
                                                            fontSize: 80,
                                                            fontWeight: 700,
                                                            borderRadius: 20,
                                                            color: '#fff',
                                                            // textShadow: '0 2px 5px rgba(0,0,0,0.25)',
                                                        }}
                                                    >
                                                        GO BACK
                                                    </Button>
                                                </Ripples>
                                            </div>
                                        </div>
                                    )}
                                    {matchData.status === 'IN_PROGRESS' &&
                                    (matchData.player_score > 0 || matchData.opponent_score > 0) &&
                                    userCheckMatchEnding === false ? (
                                        <Tooltip
                                            placement="left"
                                            title={
                                                endMatchDisabled === true
                                                    ? matchData.status === 'COMPLETED'
                                                        ? 'Match scores have already been submitted and are awaiting approval.'
                                                        : 'Cannot submit scores until a winner has been determined'
                                                    : 'Submit scores for approval'
                                            }
                                        >
                                            <Button
                                                size="large"
                                                type="ghost"
                                                ghost
                                                onClick={() => {
                                                    showConfirmEndMatch(matchData);
                                                }}
                                                disabled={endMatchDisabled || false}
                                                className={endMatchDisabled === true ? 'disabled' : ''}
                                            >
                                                Submit scores
                                            </Button>
                                        </Tooltip>
                                    ) : matchData.status === 'COMPLETED' ? (
                                        <Text style={{ color: '#fff' }}>Scores have been submitted</Text>
                                    ) : (
                                        matchData.status !== 'COMPLETED' &&
                                        (matchData.player_score === 0 || matchData.player_score === null) &&
                                        (matchData.opponent_score === 0 || matchData.player_score === null) &&
                                        scoringStarted !== true && (
                                            <div
                                                style={{
                                                    position: 'fixed',
                                                    top: '50%',
                                                    left: '50%',
                                                    transform: 'translate(-50%, -50%)',
                                                }}
                                            >
                                                <Ripples color="#ccc">
                                                    <Button
                                                        size="large"
                                                        type="primary"
                                                        onClick={() => {
                                                            if (scoringStarted !== true) {
                                                                startScoring();
                                                                startMatch(matchData);
                                                            }
                                                        }}
                                                        disabled={scoringStarted === true}
                                                        className={scoringStarted === true ? 'disabled' : ''}
                                                        style={{
                                                            width: '100%',
                                                            paddingLeft: 40,
                                                            paddingRight: 40,
                                                            height: 160,
                                                            fontSize: 100,
                                                            fontWeight: 700,
                                                            borderRadius: 20,
                                                            // textShadow: '0 2px 5px rgba(0,0,0,0.25)',
                                                        }}
                                                    >
                                                        START SCORING
                                                    </Button>
                                                </Ripples>

                                                {/* <Button
                                                onClick={() => {
                                                    getVideoThumbnail();
                                                }}
                                            >
                                                Get thumbnail
                                            </Button> */}
                                            </div>
                                        )
                                    )}
                                </div>
                            </div>

                            {scoringStarted === false &&
                                (matchData.player_score === 0 || matchData.player_score === null) &&
                                (matchData.opponent_score === 0 || matchData.opponent_score === null) && (
                                    <div
                                        style={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            right: 0,
                                            bottom: 0,
                                            zIndex: 10,
                                            background: 'rgba(0,0,0,0.6)',
                                        }}
                                    />
                                )}

                            {userCheckMatchEnding === true && (
                                <div
                                    style={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        right: 0,
                                        bottom: 0,
                                        zIndex: 10,
                                        background: 'rgba(0,0,0,0.6)',
                                    }}
                                />
                            )}

                            <div
                                style={{
                                    flex: 1,
                                    alignItems: 'center',
                                    // padding: 20,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    flexDirection: 'column',
                                }}
                            >
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', marginBottom: 0, minHeight: 20 }}>
                                    {matchData.logo && <img src={matchData.logo} alt="Logo" height={120} />}
                                </div>

                                <Formik
                                    ref={formRef}
                                    onSubmit={(data, actions) => {
                                        console.log(data);
                                    }}
                                    // validationSchema={schema}
                                    enableReinitialize
                                    initialValues={initialValues}
                                    render={({ errors, touched, values }) => (
                                        <Form
                                            layout="vertical"
                                            onSubmit={(e) => {
                                                e.preventDefault();
                                                console.log(values);
                                                // handleSubmit(values);
                                            }}
                                            style={{ display: 'flex', alignItems: 'center', width: '100%', flexDirection: 'column' }}
                                        >
                                            <Row gutter={16} type="flex" justify="space-between" style={{ width: '100%', position: 'relative' }}>
                                                <Col xs={12} sm={12}>
                                                    <Title level={2} style={{ marginLeft: 20, marginTop: 0, marginBottom: 0, color: '#fff' }}>
                                                        {table.label} - Match {matchData.match_number}
                                                    </Title>
                                                </Col>
                                                <div style={{ top: 10, position: 'absolute', left: '50%', transform: 'translateX(-50%)' }}>
                                                    <Title level={3} style={{ marginLeft: 20, marginTop: 0, marginBottom: 0, color: '#fff', fontWeight: 400 }}>
                                                        {matchData.name}
                                                    </Title>
                                                </div>
                                                <Col xs={12} sm={12} align="right">
                                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                                        {/* <Avatar
                                                            size={28}
                                                            src={getGameType(matchData.game_type, 'dark')}
                                                            style={{ width: 30, marginRight: 10 }}
                                                        /> */}
                                                        <Title level={2} style={{ marginRight: 20, marginTop: 0, marginBottom: 0, color: '#fff' }}>
                                                            {selectedShot && (
                                                                <React.Fragment>
                                                                    {selectedShot.name}
                                                                    {/* D{selectedShot.discipline}-{selectedShot.shot && `${selectedShot.shot.toUpperCase()}`} (
                                                                    {selectedShot.points} points) - {selectedShot.name} */}
                                                                </React.Fragment>
                                                            )}
                                                        </Title>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row gutter={24} type="flex" justify="space-between" style={{ width: '100%' }}>
                                                <Col xs={12} sm={12}>
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            flex: 1,
                                                            background:
                                                                matchData.player_score > matchData.opponent_score
                                                                    ? 'linear-gradient(45deg, #0089cd, #0045b5)'
                                                                    : 'linear-gradient(-45deg, black, transparent)',
                                                            // background: 'linear-gradient(206deg, #0045B4 0%, #0284CB 95%)',
                                                            borderRadius: 30,
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            flexDirection: 'column',
                                                            padding: 20,
                                                            filter: 'drop-shadow(0px 20px 10px rgba(0,0,0,0.2))',
                                                        }}
                                                    >
                                                        <div style={{ marginTop: 40, marginBottom: 10, paddingLeft: 20 }}>
                                                            {matchData.player_country && (
                                                                <React.Fragment>
                                                                    {matchData.player_state ? (
                                                                        <CircleStateFlag
                                                                            state={matchData.player_state}
                                                                            size="large"
                                                                            style={{ transform: 'scale(1.8)' }}
                                                                        />
                                                                    ) : (
                                                                        <CircleFlag
                                                                            country={getCountryName(matchData.player_country) || 'Unknown'}
                                                                            size="large"
                                                                            style={{ transform: 'scale(1.8)' }}
                                                                        />
                                                                    )}
                                                                </React.Fragment>
                                                            )}
                                                        </div>
                                                        <div style={{ marginTop: 10, textAlign: 'center' }}>
                                                            <Text style={{ fontSize: 40, fontWeight: 700, color: '#fff', textTransform: 'uppercase' }}>
                                                                {matchData.player_name}{' '}
                                                                {matchData.player_name &&
                                                                    matchData.player_name !== 'BYE' &&
                                                                    matchData.player_skill_level &&
                                                                    `(${matchData.player_skill_level})`}
                                                            </Text>
                                                        </div>
                                                        <div>
                                                            <Text style={{ margin: 0, fontSize: 200, lineHeight: '210px', fontWeight: 700, color: '#fff' }}>
                                                                {matchData.player_score || 0}
                                                            </Text>
                                                        </div>

                                                        {/* <div
                                                        style={{
                                                            backgroundColor: 'rgba(255,255,255, 0.05)',
                                                            paddingLeft: 10,
                                                            paddingRight: 10,
                                                            paddingTop: 5,
                                                            paddingBottom: 5,
                                                            borderRadius: 10,
                                                            fontSize: 20,
                                                        }}
                                                    >
                                                        <Text style={{ color: '#fff', fontWeight: 700, fontSize: 25 }}>
                                                            TPA: {(currentGame && currentGame.player_tpa) || '0.000'}
                                                        </Text>
                                                    </div> */}
                                                    </div>
                                                </Col>
                                                <Col xs={12} sm={12}>
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            flex: 1,
                                                            background:
                                                                matchData.opponent_score > matchData.player_score
                                                                    ? 'linear-gradient(45deg, #0089cd, #0045b5)'
                                                                    : 'linear-gradient(45deg, black, transparent)',
                                                            // background: 'linear-gradient(180deg, #0F141A 0%, #495C75 100%)',
                                                            borderRadius: 30,
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            flexDirection: 'column',
                                                            padding: 20,
                                                            filter: 'drop-shadow(0px 20px 10px rgba(0,0,0,0.2))',
                                                        }}
                                                    >
                                                        <div style={{ marginTop: 40, marginBottom: 10, paddingLeft: 20 }}>
                                                            {matchData.opponent_country && (
                                                                <React.Fragment>
                                                                    {matchData.opponent_state ? (
                                                                        <CircleStateFlag
                                                                            state={matchData.opponent_state}
                                                                            size="large"
                                                                            style={{ transform: 'scale(1.8)' }}
                                                                        />
                                                                    ) : (
                                                                        <CircleFlag
                                                                            country={getCountryName(matchData.opponent_country) || 'Unknown'}
                                                                            size="large"
                                                                            style={{ transform: 'scale(1.8)' }}
                                                                        />
                                                                    )}
                                                                </React.Fragment>
                                                            )}
                                                        </div>
                                                        <div style={{ marginTop: 10, textAlign: 'center' }}>
                                                            <Text style={{ fontSize: 40, fontWeight: 700, color: '#fff', textTransform: 'uppercase' }}>
                                                                {matchData.opponent_name}{' '}
                                                                {matchData.opponent_name &&
                                                                    matchData.opponent_name !== 'BYE' &&
                                                                    matchData.opponent_skill_level &&
                                                                    `(${matchData.opponent_skill_level})`}
                                                            </Text>
                                                        </div>
                                                        <div>
                                                            <Text style={{ margin: 0, fontSize: 200, lineHeight: '210px', fontWeight: 700, color: '#fff' }}>
                                                                {matchData.opponent_score || 0}
                                                            </Text>
                                                        </div>
                                                        {/* <div
                                                        style={{
                                                            backgroundColor: 'rgba(255,255,255, 0.05)',
                                                            paddingLeft: 10,
                                                            paddingRight: 10,
                                                            paddingTop: 5,
                                                            paddingBottom: 5,
                                                            borderRadius: 10,
                                                            fontSize: 20,
                                                        }}
                                                    >
                                                        <Text style={{ color: '#fff', fontWeight: 700, fontSize: 25 }}>
                                                            TPA: {(currentGame && currentGame.opponent_tpa) || '0.000'}
                                                        </Text>
                                                    </div> */}
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row gutter={16} type="flex" justify="space-between" style={{ position: 'relative', zIndex: 1, width: '100%' }}>
                                                <Col xs={12} sm={12}>
                                                    <div style={{ margin: 20, display: 'flex', justifyContent: 'center' }}>
                                                        <Ripples color="#ccc">
                                                            <Button
                                                                type="primary"
                                                                ghost
                                                                shape="circle"
                                                                size="large"
                                                                style={{ width: 80, height: 80, borderWidth: 3 }}
                                                                disabled={
                                                                    isDisabled(matchData) === true || matchData.player_score === matchData.player_race_to
                                                                        ? true
                                                                        : false
                                                                }
                                                                onClick={() => {
                                                                    if (matchData.player_score >= 0) {
                                                                        // showConfirmStats('player', matchData.player_score, matchData, gameData);
                                                                        const newScore = matchData.player_score + 1;
                                                                        incrementPlayerScore('player', newScore, matchData, gameData);
                                                                    }
                                                                }}
                                                                className={
                                                                    isDisabled(matchData) === true || matchData.player_score === matchData.player_race_to
                                                                        ? 'disabled'
                                                                        : ''
                                                                }
                                                            >
                                                                <Icon type="plus" style={{ fontSize: 50, lineHeight: '40px' }} />
                                                            </Button>
                                                        </Ripples>
                                                        <div style={{ width: 20 }} />
                                                        <Ripples color="#ccc">
                                                            <Button
                                                                type="danger"
                                                                ghost
                                                                shape="circle"
                                                                size="large"
                                                                style={{ width: 80, height: 80, borderWidth: 3 }}
                                                                disabled={isDisabled(matchData) === true || matchData.player_score === 0 ? true : false}
                                                                onClick={() => {
                                                                    if (matchData.player_score > 0) {
                                                                        const newScore = matchData.player_score - 1;
                                                                        decrementPlayerScore('player', newScore, matchData, gameData);
                                                                    }
                                                                }}
                                                                className={isDisabled(matchData) === true || matchData.player_score === 0 ? 'disabled' : ''}
                                                            >
                                                                <Icon type="minus" style={{ fontSize: 50, lineHeight: '40px' }} />
                                                            </Button>
                                                        </Ripples>
                                                    </div>
                                                </Col>
                                                <Col xs={12} sm={12}>
                                                    <div style={{ margin: 20, display: 'flex', justifyContent: 'center' }}>
                                                        <Ripples color="#ccc">
                                                            <Button
                                                                type="primary"
                                                                ghost
                                                                shape="circle"
                                                                size="large"
                                                                style={{ width: 80, height: 80, borderWidth: 3 }}
                                                                disabled={
                                                                    isDisabled(matchData) === true || matchData.opponent_score === matchData.opponent_race_to
                                                                        ? true
                                                                        : false
                                                                }
                                                                onClick={() => {
                                                                    if (matchData.opponent_score >= 0) {
                                                                        // showConfirmStats('.opponent', matchData.opponent_score, matchData, gameData);
                                                                        const newScore = matchData.opponent_score + 1;
                                                                        incrementPlayerScore('.opponent', newScore, matchData, gameData);
                                                                    }
                                                                }}
                                                                className={
                                                                    isDisabled(matchData) === true || matchData.opponent_score === matchData.opponent_race_to
                                                                        ? 'disabled'
                                                                        : ''
                                                                }
                                                            >
                                                                <Icon type="plus" style={{ fontSize: 50, lineHeight: '40px' }} />
                                                            </Button>
                                                        </Ripples>
                                                        <div style={{ width: 20 }} />
                                                        <Ripples color="#ccc">
                                                            <Button
                                                                type="danger"
                                                                ghost
                                                                shape="circle"
                                                                size="large"
                                                                style={{ width: 80, height: 80, borderWidth: 3 }}
                                                                disabled={isDisabled(matchData) === true || matchData.opponent_score === 0 ? true : false}
                                                                onClick={() => {
                                                                    if (matchData.opponent_score > 0) {
                                                                        const newScore = matchData.opponent_score - 1;
                                                                        decrementPlayerScore('.opponent', newScore, matchData, gameData);
                                                                    }
                                                                }}
                                                                className={isDisabled(matchData) === true || matchData.opponent_score === 0 ? 'disabled' : ''}
                                                            >
                                                                <Icon type="minus" style={{ fontSize: 50, lineHeight: '40px' }} />
                                                            </Button>
                                                        </Ripples>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row gutter={16} type="flex" justify="space-between" style={{ marginTop: -120 }}>
                                                <Col xs={24} align="center">
                                                    <div style={{ marginBottom: 10 }}>
                                                        <Tag
                                                            size="large"
                                                            color={
                                                                matchData.status === 'NOT_STARTED'
                                                                    ? '#80848c'
                                                                    : matchData.status === 'IN_PROGRESS'
                                                                    ? '#108ee9'
                                                                    : matchData.status === 'COMPLETED'
                                                                    ? '#009ba2'
                                                                    : '#cd201f'
                                                            }
                                                        >
                                                            {matchData.status}
                                                        </Tag>
                                                    </div>
                                                    {selectedShot && (
                                                        <div>
                                                            <Text style={{ fontSize: 40, color: '#fff', fontWeight: 700 }}>
                                                                D{selectedShot.discipline}-{selectedShot.shot && `${selectedShot.shot.toUpperCase()}`} (
                                                                {selectedShot.points} points)
                                                            </Text>
                                                        </div>
                                                    )}

                                                    <div>
                                                        <Text style={{ fontSize: 30, color: '#fff' }}>{duration || '0 minutes'}</Text>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row gutter={16} type="flex" justify="center" style={{ width: '100%', marginTop: 20 }}>
                                                <Col align="center">
                                                    <div style={{ marginTop: 5, marginBottom: 15 }}>
                                                        <Title level={4} style={{ fontSize: 18 }}>
                                                            Add points for p1
                                                        </Title>
                                                    </div>
                                                    <Radio.Group value="large" size="large" disabled={selectedShot ? false : true} style={{ marginRight: 20 }}>
                                                        <Radio.Button
                                                            value={4}
                                                            onClick={() => {
                                                                const newScore = matchData.player_score + 4;
                                                                incrementPlayerScore('player', newScore, matchData, gameData);
                                                            }}
                                                            disabled={selectedShot && selectedShot.points - 2 > 4 ? true : false}
                                                            style={styles.btnPoints}
                                                        >
                                                            4
                                                        </Radio.Button>
                                                        <Radio.Button
                                                            value={5}
                                                            onClick={() => {
                                                                const newScore = matchData.player_score + 5;
                                                                incrementPlayerScore('player', newScore, matchData, gameData);
                                                            }}
                                                            disabled={selectedShot && selectedShot.points - 2 > 5 ? true : false}
                                                            style={styles.btnPoints}
                                                        >
                                                            5
                                                        </Radio.Button>
                                                        <Radio.Button
                                                            value={6}
                                                            onClick={() => {
                                                                const newScore = matchData.player_score + 6;
                                                                incrementPlayerScore('player', newScore, matchData, gameData);
                                                            }}
                                                            disabled={selectedShot && selectedShot.points < 6 ? true : false}
                                                            style={styles.btnPoints}
                                                        >
                                                            6
                                                        </Radio.Button>
                                                        <Radio.Button
                                                            value={7}
                                                            onClick={() => {
                                                                const newScore = matchData.player_score + 7;
                                                                incrementPlayerScore('player', newScore, matchData, gameData);
                                                            }}
                                                            disabled={selectedShot && selectedShot.points < 7 ? true : false}
                                                            style={styles.btnPoints}
                                                        >
                                                            7
                                                        </Radio.Button>
                                                        <Radio.Button
                                                            value={8}
                                                            onClick={() => {
                                                                const newScore = matchData.player_score + 8;
                                                                incrementPlayerScore('player', newScore, matchData, gameData);
                                                            }}
                                                            disabled={selectedShot && selectedShot.points < 8 ? true : false}
                                                            style={styles.btnPoints}
                                                        >
                                                            8
                                                        </Radio.Button>
                                                        <Radio.Button
                                                            value={9}
                                                            onClick={() => {
                                                                const newScore = matchData.player_score + 9;
                                                                incrementPlayerScore('player', newScore, matchData, gameData);
                                                            }}
                                                            disabled={selectedShot && selectedShot.points < 9 ? true : false}
                                                            style={styles.btnPoints}
                                                        >
                                                            9
                                                        </Radio.Button>
                                                        <Radio.Button
                                                            value={10}
                                                            onClick={() => {
                                                                const newScore = matchData.player_score + 10;
                                                                incrementPlayerScore('player', newScore, matchData, gameData);
                                                            }}
                                                            disabled={selectedShot && selectedShot.points < 10 ? true : false}
                                                            style={styles.btnPoints}
                                                        >
                                                            10
                                                        </Radio.Button>
                                                    </Radio.Group>
                                                </Col>
                                                <Col align="center">
                                                    {selectedShot ? (
                                                        <div
                                                            style={{
                                                                backgroundColor: '#fff',
                                                                padding: 6,
                                                                borderRadius: 5,
                                                                overflow: 'hidden',
                                                                cursor: 'pointer',
                                                            }}
                                                            onClick={() => {
                                                                setShotModalVisible(true);
                                                            }}
                                                        >
                                                            <img src={selectedShot.image_url} alt={selectedShot.name} style={{ width: 250 }} />
                                                        </div>
                                                    ) : (
                                                        <div>
                                                            <img src={tableBlue} width="100%" alt="Pool Table" style={{ width: 250 }} />
                                                            <Title level={4} style={{ position: 'relative', marginTop: -80 }}>
                                                                No shot selected
                                                            </Title>
                                                        </div>
                                                    )}
                                                </Col>
                                                <Col align="center">
                                                    <div style={{ marginTop: 5, marginBottom: 15 }}>
                                                        <Title level={4} style={{ fontSize: 18 }}>
                                                            Add points for p2
                                                        </Title>
                                                    </div>
                                                    <Radio.Group value="large" size="large" disabled={selectedShot ? false : true} style={{ marginLeft: 20 }}>
                                                        <Radio.Button
                                                            value={4}
                                                            onClick={() => {
                                                                const newScore = matchData.opponent_score + 4;
                                                                incrementPlayerScore('opponent', newScore, matchData, gameData);
                                                            }}
                                                            disabled={selectedShot && selectedShot.points - 2 > 4 ? true : false}
                                                            style={styles.btnPoints}
                                                        >
                                                            4
                                                        </Radio.Button>
                                                        <Radio.Button
                                                            value={5}
                                                            onClick={() => {
                                                                const newScore = matchData.opponent_score + 5;
                                                                incrementPlayerScore('opponent', newScore, matchData, gameData);
                                                            }}
                                                            disabled={selectedShot && selectedShot.points - 2 > 5 ? true : false}
                                                            style={styles.btnPoints}
                                                        >
                                                            5
                                                        </Radio.Button>
                                                        <Radio.Button
                                                            value={6}
                                                            onClick={() => {
                                                                const newScore = matchData.opponent_score + 6;
                                                                incrementPlayerScore('opponent', newScore, matchData, gameData);
                                                            }}
                                                            disabled={selectedShot && selectedShot.points < 6 ? true : false}
                                                            style={styles.btnPoints}
                                                        >
                                                            6
                                                        </Radio.Button>
                                                        <Radio.Button
                                                            value={7}
                                                            onClick={() => {
                                                                const newScore = matchData.opponent_score + 7;
                                                                incrementPlayerScore('opponent', newScore, matchData, gameData);
                                                            }}
                                                            disabled={selectedShot && selectedShot.points < 7 ? true : false}
                                                            style={styles.btnPoints}
                                                        >
                                                            7
                                                        </Radio.Button>
                                                        <Radio.Button
                                                            value={8}
                                                            onClick={() => {
                                                                const newScore = matchData.opponent_score + 8;
                                                                incrementPlayerScore('opponent', newScore, matchData, gameData);
                                                            }}
                                                            disabled={selectedShot && selectedShot.points < 8 ? true : false}
                                                            style={styles.btnPoints}
                                                        >
                                                            8
                                                        </Radio.Button>
                                                        <Radio.Button
                                                            value={9}
                                                            onClick={() => {
                                                                const newScore = matchData.opponent_score + 9;
                                                                incrementPlayerScore('opponent', newScore, matchData, gameData);
                                                            }}
                                                            disabled={selectedShot && selectedShot.points < 9 ? true : false}
                                                            style={styles.btnPoints}
                                                        >
                                                            9
                                                        </Radio.Button>
                                                        <Radio.Button
                                                            value={10}
                                                            onClick={() => {
                                                                const newScore = matchData.opponent_score + 10;
                                                                incrementPlayerScore('opponent', newScore, matchData, gameData);
                                                            }}
                                                            disabled={selectedShot && selectedShot.points < 10 ? true : false}
                                                            style={styles.btnPoints}
                                                        >
                                                            10
                                                        </Radio.Button>
                                                    </Radio.Group>
                                                </Col>
                                            </Row>
                                            <Row gutter={16} type="flex" justify="center" style={{ width: '100%' }}>
                                                <Col align="center">
                                                    {selectedShot ? (
                                                        <div>
                                                            {/* <div>
                                                                <Title level={4} style={{ fontSize: 16, margin: 0 }}>
                                                                    {selectedShot.name}
                                                                </Title>
                                                            </div> */}
                                                            <div style={{ marginTop: 10 }}>
                                                                <Button
                                                                    size="large"
                                                                    onClick={() => {
                                                                        setShotModalVisible(true);
                                                                    }}
                                                                >
                                                                    View Shot Catalog
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div>
                                                            <div style={{ marginTop: 50 }}>
                                                                <Button
                                                                    size="large"
                                                                    onClick={() => {
                                                                        setShotModalVisible(true);
                                                                    }}
                                                                >
                                                                    View Shot Catalog
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Col>
                                            </Row>
                                        </Form>
                                    )}
                                />
                            </div>
                        </Content>
                        <ArtisticShotModal
                            currentShot={selectedShot}
                            modalVisible={shotModalVisible}
                            onModalOk={handleShotModalOk}
                            onModalCancel={handleShotModalCancel}
                            onShotSelected={(value) => {
                                console.log('shot data', value);
                                setSelectedShot(value);
                            }}
                        />
                    </Layout>
                );
            }}
        </Query>
    );
}

export default withRouter(EventScoreboardArtistic);
