import React, { useState, useEffect, useRef } from 'react';
import { Table, Tabs, Icon, Button, Input, Tooltip, Avatar, Progress, Row, Pagination, Empty, Col, Typography, Modal } from 'antd';
import CircularLoader from '../../../components/CircularLoader';
import CircleFlag from '../../../components/CircleFlag';
import Highlighter from 'react-highlight-words';
import { displayEnumType, formatPlayerName, getOrdinalSuffix, getGameType } from '../../../utils';
import tournamentThumb from '../../../assets/img/tournament-thumb.png';
import tournamentThumb8Ball from '../../../assets/img/tournament-thumb-8ball.png';
import tournamentThumb9Ball from '../../../assets/img/tournament-thumb-9ball.png';
import tournamentThumb10Ball from '../../../assets/img/tournament-thumb-10ball.png';
import tournamentThumbOnePocket from '../../../assets/img/tournament-thumb-one-pocket.png';
import { TrophyFilled } from '@ant-design/icons';
import { UserOutlined } from '@ant-design/icons';
import _ from 'lodash';
import moment from 'moment';

const { Text, Title } = Typography;

export default function TournamentsTable(props) {
    const { authState, tournaments, totalTournaments, limit, defaultLimit, onShowMore, theme } = props;
    const [searchValue, setSearchValue] = useState();
    const [searching, setSearching] = useState(false);
    const [selectedKeys, setSelectedKeys] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);

    function onSelectChange(selectedKeys) {
        // console.log('selectedRowKeys changed: ', selectedKeys);
        setSelectedKeys(selectedKeys);
    }

    function handleSearch(selectedKeys, confirm, dataIndex) {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    }

    function handleReset(clearFilters) {
        clearFilters();
        setSearchText('');
    }

    function getColumnSearchProps(dataIndex) {
        return {
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={searchInput}
                        placeholder={`Search ${dataIndex}`}
                        value={selectedKeys[0]}
                        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        style={{ width: 188, marginBottom: 8, display: 'block' }}
                    />
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon="search"
                        size="small"
                        style={{ width: 90, marginRight: 8 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                </div>
            ),
            filterIcon: (filtered) => <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
            onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
            onFilterDropdownVisibleChange: (visible) => {
                if (visible) {
                    setTimeout(() => searchInput.current.select());
                }
            },
            render: (text) =>
                searchedColumn === dataIndex ? (
                    <Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={[searchText]}
                        autoEscape
                        textToHighlight={text.toString()}
                    />
                ) : (
                    text
                ),
        };
    }

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            ...getColumnSearchProps('name'),
            sorter: (a, b) => {
                return a.name && a.name.localeCompare(b.name);
            },
            render: (text, record) => {
                return (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div>
                            <img
                                alt="example"
                                src={
                                    record.flyer
                                        ? record.flyer
                                        : record.game_type === 'NINE_BALL'
                                        ? tournamentThumb9Ball
                                        : record.game_type === 'EIGHT_BALL'
                                        ? tournamentThumb8Ball
                                        : record.game_type === 'TEN_BALL'
                                        ? tournamentThumb10Ball
                                        : record.game_type === 'ONE_POCKET'
                                        ? tournamentThumbOnePocket
                                        : tournamentThumb
                                }
                                style={{ width: 80, borderRadius: 6 }}
                            />
                        </div>
                        {/* {record.game_type && (
                            <Avatar size={24} src={getGameType(record.game_type, theme)} style={{ display: 'inline-block', marginRight: 8 }} />
                        )} */}
                        <div style={{ marginLeft: 15 }}>
                            <Title level={4} style={{ fontSize: 16 }}>
                                {text}
                            </Title>
                            {record.status === 'COMPLETED' && (
                                <div>
                                    {record.tournament_players &&
                                        record.tournament_players.map((player, index) => {
                                            const isSplit = record.tournament_players.filter((player) => {
                                                return player.place === '1';
                                            });
                                            return (
                                                <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
                                                    <div>
                                                        {player.place === '1' ? (
                                                            <TrophyFilled
                                                                style={{
                                                                    color: '#eab700',
                                                                    marginRight: 5,
                                                                }}
                                                            />
                                                        ) : player.place === '2' ? (
                                                            <TrophyFilled
                                                                style={{
                                                                    color: '#9baab1',
                                                                    marginRight: 5,
                                                                }}
                                                            />
                                                        ) : (
                                                            player.place === '3' && (
                                                                <TrophyFilled
                                                                    style={{
                                                                        color: '#f19921',
                                                                        marginRight: 5,
                                                                    }}
                                                                />
                                                            )
                                                        )}
                                                    </div>
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <Text
                                                            style={{
                                                                marginLeft: 0,
                                                                width: '100%',
                                                                whiteSpace: 'nowrap',
                                                                overflow: 'hidden',
                                                                textOverflow: 'ellipsis',
                                                            }}
                                                        >
                                                            {formatPlayerName(player)} {player.race_to ? `(${player.race_to})` : null} -{' '}
                                                            {player.place
                                                                ? `${getOrdinalSuffix(player.place)} ${
                                                                      player.place === '1' && isSplit.length > 1 ? '(split)' : ''
                                                                  }`
                                                                : '-'}
                                                        </Text>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                </div>
                            )}
                        </div>
                    </div>
                );
            },
        },
        {
            title: 'Updated',
            dataIndex: 'updated_at',
            // defaultSortOrder: 'descend',
            sorter: (a, b) => a.updated_at && a.updated_at.localeCompare(b.updated_at),
            render: (text, record) => <span>{moment(text).format('ll')}</span>,
        },

        {
            title: 'Size',
            dataIndex: 'max_players',
            width: 100,
            sorter: (a, b) => a.max_players - b.max_players,
            render: (text, record) => {
                return <Text style={{ whiteSpace: 'nowrap' }}>{text} Players</Text>;
            },
        },
        {
            title: 'Type',
            dataIndex: 'tournament_type',
            sorter: (a, b) => a.tournament_type - b.tournament_type,
            render: (text, record) => {
                return <Text>{displayEnumType(text)}</Text>;
            },
        },
        {
            title: 'Progress',
            dataIndex: 'progress',
            sorter: (a, b) => a.progress - b.progress,
            render: (text, record) => {
                const progress = parseInt(text);
                return (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Progress
                            percent={progress || 0}
                            status={progress < 100 ? 'active' : progress === 100 ? 'success' : 'normal'}
                            size="small"
                            // showInfo={false}
                            strokeWidth={6}
                            strokeLinecap="square"
                        />
                    </div>
                );
            },
        },
        {
            title: 'Status',
            dataIndex: 'status',
            sorter: (a, b) => a.status && a.status.localeCompare(b.status),
            render: (text, record) => {
                return <Text>{text}</Text>;
            },
        },
        {
            title: 'Game Type',
            dataIndex: 'game_type',
            sorter: (a, b) => a.game_type - b.game_type,
            render: (text, record) => {
                return <Text>{displayEnumType(text)}</Text>;
            },
        },
        {
            title: 'Director',
            dataIndex: 'director',

            sorter: (a, b) => {
                const aDirector = a.director && `${a.director.first_name} ${a.director.last_name}`;
                const bDirector = b.director && `${b.director.first_name} ${b.director.last_name}`;
                return aDirector.localeCompare(bDirector, undefined, {
                    numeric: true,
                    sensitivity: 'base',
                });
            },
            render: (text, record) => {
                return (
                    <Text>
                        {record.director && record.director.first_name} {record.director && record.director.last_name}
                    </Text>
                );
            },
        },
        {
            title: 'Location',
            dataIndex: 'location',
            render: (text, record) => {
                return (
                    <div>
                        <Text>{record.venue && record.venue.name}</Text>
                        <br />
                        <Text>
                            {record.venue && record.venue.city}, {record.venue && record.venue.region}
                        </Text>
                    </div>
                );
            },
        },

        {
            title: 'Action',
            key: 'action',
            // fixed: 'right',
            render: (text, record) => (
                <React.Fragment>
                    <span style={{ whiteSpace: 'nowrap' }}>
                        <Tooltip title="View Bracket">
                            <Button
                                type="ghost"
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    props.history.push(`/tournaments/${record.slug}/bracket`);
                                }}
                            >
                                <Icon type="apartment" />
                            </Button>
                        </Tooltip>
                    </span>
                </React.Fragment>
            ),
        },
    ];

    function handleShowMore() {
        onShowMore(limit);
    }

    return (
        <React.Fragment>
            <Table
                rowKey="name"
                // className="table-striped-rows"
                columns={columns}
                dataSource={tournaments}
                pagination={false}
                tableLayout="auto"
                scroll={{ x: 400 }}
                onRow={(record, rowIndex) => {
                    return {
                        onClick: (event) => {
                            console.log(record, rowIndex);
                            props.history.push({
                                pathname: `/tournaments/${record.slug}`,
                                // search: '?query=abc',
                                state: { data: record },
                            });
                        },
                        onDoubleClick: (event) => {}, // double click row
                        onContextMenu: (event) => {}, // right button click row
                        onMouseEnter: (event) => {}, // mouse enter row
                        onMouseLeave: (event) => {}, // mouse leave row
                    };
                }}
            />
            {limit && totalTournaments > limit && (
                <div
                    style={{
                        marginTop: 20,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Button size="large" type="primary" onClick={handleShowMore}>
                        Show more
                    </Button>
                </div>
            )}
        </React.Fragment>
    );
}
