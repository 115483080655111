export const isoCountries = {
    afghanistan: 'AF',
    'aland islands': 'AX',
    albania: 'AL',
    algeria: 'DZ',
    'american samoa': 'AS',
    andorra: 'AD',
    angola: 'AO',
    anguilla: 'AI',
    antarctica: 'AQ',
    'antigua and barbuda': 'AG',
    argentina: 'AR',
    armenia: 'AM',
    aruba: 'AW',
    australia: 'AU',
    austria: 'AT',
    azerbaijan: 'AZ',
    bahamas: 'BS',
    bahrain: 'BH',
    bangladesh: 'BD',
    barbados: 'BB',
    belarus: 'BY',
    belgium: 'BE',
    belize: 'BZ',
    benin: 'BJ',
    bermuda: 'BM',
    bhutan: 'BT',
    bolivia: 'BO',
    'bosnia and herzegovina': 'BA',
    botswana: 'BW',
    'bouvet island': 'BV',
    brazil: 'BR',
    'british indian ocean territory': 'IO',
    'brunei darussalam': 'BN',
    bulgaria: 'BG',
    'burkina faso': 'BF',
    burundi: 'BI',
    cambodia: 'KH',
    cameroon: 'CM',
    canada: 'CA',
    'cape verde': 'CV',
    'cayman islands': 'KY',
    'central african republic': 'CF',
    chad: 'TD',
    chile: 'CL',
    china: 'CN',
    'christmas island': 'CX',
    'cocos (keeling) islands': 'CC',
    colombia: 'CO',
    comoros: 'KM',
    congo: 'CG',
    'congo, democratic republic': 'CD',
    'cook islands': 'CK',
    'costa rica': 'CR',
    "cote d'ivoire": 'CI',
    croatia: 'HR',
    cuba: 'CU',
    cyprus: 'CY',
    'czech republic': 'CZ',
    denmark: 'DK',
    djibouti: 'DJ',
    dominica: 'DM',
    'dominican republic': 'DO',
    ecuador: 'EC',
    egypt: 'EG',
    'el salvador': 'SV',
    'equatorial guinea': 'GQ',
    eritrea: 'ER',
    estonia: 'EE',
    ethiopia: 'ET',
    'falkland islands': 'FK',
    'faroe islands': 'FO',
    fiji: 'FJ',
    finland: 'FI',
    france: 'FR',
    'french guiana': 'GF',
    'french polynesia': 'PF',
    'french southern territories': 'TF',
    gabon: 'GA',
    gambia: 'GM',
    georgia: 'GE',
    germany: 'DE',
    ghana: 'GH',
    gibraltar: 'GI',
    greece: 'GR',
    greenland: 'GL',
    grenada: 'GD',
    guadeloupe: 'GP',
    guam: 'GU',
    guatemala: 'GT',
    guernsey: 'GG',
    guinea: 'GN',
    'guinea-bissau': 'GW',
    guyana: 'GY',
    haiti: 'HT',
    'heard island & mcdonald islands': 'HM',
    'holy see (vatican city state)': 'VA',
    honduras: 'HN',
    'hong kong': 'HK',
    hungary: 'HU',
    iceland: 'IS',
    india: 'IN',
    indonesia: 'ID',
    'iran, islamic republic of': 'IR',
    iraq: 'IQ',
    ireland: 'IE',
    'isle of man': 'IM',
    israel: 'IL',
    italy: 'IT',
    jamaica: 'JM',
    japan: 'JP',
    jersey: 'JE',
    jordan: 'JO',
    kazakhstan: 'KZ',
    kenya: 'KE',
    kiribati: 'KI',
    'republic of korea': 'KR',
    'south korea': 'KR',
    "democratic people's republic of korea": 'KP',
    'north korea': 'KP',
    kuwait: 'KW',
    kyrgyzstan: 'KG',
    "lao people's democratic republic": 'LA',
    latvia: 'LV',
    lebanon: 'LB',
    lesotho: 'LS',
    liberia: 'LR',
    'libyan arab jamahiriya': 'LY',
    liechtenstein: 'LI',
    lithuania: 'LT',
    luxembourg: 'LU',
    macao: 'MO',
    macedonia: 'MK',
    madagascar: 'MG',
    malawi: 'MW',
    malaysia: 'MY',
    maldives: 'MV',
    mali: 'ML',
    malta: 'MT',
    'marshall islands': 'MH',
    martinique: 'MQ',
    mauritania: 'MR',
    mauritius: 'MU',
    mayotte: 'YT',
    mexico: 'MX',
    'micronesia, federated states of': 'FM',
    moldova: 'MD',
    monaco: 'MC',
    mongolia: 'MN',
    montenegro: 'ME',
    montserrat: 'MS',
    morocco: 'MA',
    mozambique: 'MZ',
    myanmar: 'MM',
    namibia: 'NA',
    nauru: 'NR',
    nepal: 'NP',
    netherlands: 'NL',
    'netherlands antilles': 'AN',
    'new caledonia': 'NC',
    'new zealand': 'NZ',
    nicaragua: 'NI',
    niger: 'NE',
    nigeria: 'NG',
    niue: 'NU',
    'norfolk island': 'NF',
    'northern mariana islands': 'MP',
    norway: 'NO',
    oman: 'OM',
    pakistan: 'PK',
    palau: 'PW',
    'palestinian territory, occupied': 'PS',
    panama: 'PA',
    'papua new guinea': 'PG',
    paraguay: 'PY',
    peru: 'PE',
    philippines: 'PH',
    pitcairn: 'PN',
    poland: 'PL',
    portugal: 'PT',
    'puerto rico': 'PR',
    qatar: 'QA',
    reunion: 'RE',
    romania: 'RO',
    'russian federation': 'RU',
    rwanda: 'RW',
    'saint barthelemy': 'BL',
    'saint helena': 'SH',
    'saint kitts and nevis': 'KN',
    'saint lucia': 'LC',
    'saint martin': 'MF',
    'saint pierre and miquelon': 'PM',
    'saint vincent and grenadines': 'VC',
    samoa: 'WS',
    'san marino': 'SM',
    'sao tome and principe': 'ST',
    'saudi arabia': 'SA',
    scotland: 'SCB',
    senegal: 'SN',
    serbia: 'RS',
    seychelles: 'SC',
    'sierra leone': 'SL',
    singapore: 'SG',
    slovakia: 'SK',
    slovenia: 'SI',
    'solomon islands': 'SB',
    somalia: 'SO',
    'south africa': 'ZA',
    'south georgia and sandwich isl.': 'GS',
    spain: 'ES',
    'sri lanka': 'LK',
    sudan: 'SD',
    suriname: 'SR',
    'svalbard and jan mayen': 'SJ',
    swaziland: 'SZ',
    sweden: 'SE',
    switzerland: 'CH',
    'syrian arab republic': 'SY',
    taiwan: 'TW',
    tajikistan: 'TJ',
    tanzania: 'TZ',
    thailand: 'TH',
    'timor-leste': 'TL',
    togo: 'TG',
    tokelau: 'TK',
    tonga: 'TO',
    'trinidad and tobago': 'TT',
    tunisia: 'TN',
    turkey: 'TR',
    turkmenistan: 'TM',
    'turks and caicos islands': 'TC',
    tuvalu: 'TV',
    uganda: 'UG',
    ukraine: 'UA',
    'united arab emirates': 'AE',
    'united kingdom': 'GB',
    'united states': 'US',
    'united states outlying islands': 'UM',
    uruguay: 'UY',
    uzbekistan: 'UZ',
    vanuatu: 'VU',
    venezuela: 'VE',
    vietnam: 'VN',
    'virgin islands, british': 'VG',
    'virgin islands, u.s.': 'VI',
    'wallis and futuna': 'WF',
    'western sahara': 'EH',
    yemen: 'YE',
    zambia: 'ZM',
    zimbabwe: 'ZW',
};

export const isoCountries3 = {
    afghanistan: 'AFG',
    'aland islands': 'ALA',
    albania: 'ALB',
    algeria: 'DZA',
    'american samoa': 'ASM',
    andorra: 'AND',
    angola: 'AGO',
    anguilla: 'AIA',
    antarctica: 'ATA',
    'antigua and barbuda': 'ATG',
    argentina: 'ARG',
    armenia: 'ARM',
    aruba: 'ABW',
    australia: 'AUS',
    austria: 'AUT',
    azerbaijan: 'AZE',
    bahamas: 'BHS',
    bahrain: 'BHR',
    bangladesh: 'BGD',
    barbados: 'BRB',
    belarus: 'BLR',
    belgium: 'BEL',
    belize: 'BLZ',
    benin: 'BEN',
    bermuda: 'BMU',
    bhutan: 'BTN',
    bolivia: 'BOL',
    'bosnia and herzegovina': 'BIH',
    botswana: 'BWA',
    'bouvet island': 'BVT',
    brazil: 'BRA',
    'british indian ocean territory': 'IOT',
    'brunei darussalam': 'BRN',
    bulgaria: 'BGR',
    'burkina faso': 'BFA',
    burundi: 'BDI',
    cambodia: 'KHM',
    cameroon: 'CMR',
    canada: 'CAN',
    'cape verde': 'CPV',
    'cayman islands': 'CYM',
    'central african republic': 'CAF',
    chad: 'TCD',
    chile: 'CHI',
    china: 'CHN',
    'christmas island': 'CXR',
    'cocos (keeling) islands': 'CCX',
    colombia: 'COL',
    comoros: 'COM',
    congo: 'COG',
    'congo, democratic republic': 'COD',
    'cook islands': 'COK',
    'costa rica': 'CRI',
    "cote d'ivoire": 'CIV',
    croatia: 'CRO',
    cuba: 'CUB',
    cyprus: 'CYP',
    'czech republic': 'CZE',
    denmark: 'DEN',
    djibouti: 'DJI',
    dominica: 'DMA',
    'dominican republic': 'DOM',
    ecuador: 'ECU',
    egypt: 'EGY',
    'el salvador': 'SLV',
    'equatorial guinea': 'GNQ',
    eritrea: 'ERI',
    estonia: 'EST',
    ethiopia: 'ETH',
    'falkland islands': 'FLK',
    'faroe islands': 'FOR',
    fiji: 'FJI',
    finland: 'FIN',
    france: 'FRA',
    'french guiana': 'GUF',
    'french polynesia': 'PYF',
    'french southern territories': 'ATF',
    gabon: 'GAB',
    gambia: 'GMB',
    georgia: 'GEO',
    germany: 'GER',
    ghana: 'GHA',
    gibraltar: 'GIB',
    greece: 'GRE',
    greenland: 'GRL',
    grenada: 'GRD',
    guadeloupe: 'GLP',
    guam: 'GUM',
    guatemala: 'GTM',
    guernsey: 'GGY',
    guinea: 'GIN',
    'guinea-bissau': 'GNB',
    guyana: 'GUY',
    haiti: 'HTI',
    'heard island & mcdonald islands': 'HMD',
    'holy see (vatican city state)': 'VAT',
    honduras: 'HND',
    'hong kong': 'HKG',
    hungary: 'HUN',
    iceland: 'ISL',
    india: 'IND',
    indonesia: 'IDN',
    'iran, islamic republic of': 'IRN',
    iraq: 'IRQ',
    ireland: 'IRL',
    'isle of man': 'IMN',
    israel: 'ISR',
    italy: 'ITA',
    jamaica: 'JAM',
    japan: 'JPN',
    jersey: 'JEY',
    jordan: 'JOR',
    kazakhstan: 'KAZ',
    kenya: 'KEN',
    kiribati: 'KIR',
    'republic of korea': 'KOR',
    'south korea': 'KR',
    "democratic people's republic of korea": 'PRK',
    'north korea': 'KP',
    kuwait: 'KUW',
    kyrgyzstan: 'KGZ',
    "lao people's democratic republic": 'LAO',
    latvia: 'LVA',
    lebanon: 'LBN',
    lesotho: 'LSO',
    liberia: 'LBR',
    'libyan arab jamahiriya': 'LY',
    liechtenstein: 'LIE',
    lithuania: 'LIT',
    luxembourg: 'LUZ',
    macao: 'MAC',
    macedonia: 'MK',
    madagascar: 'MDG',
    malawi: 'MWI',
    malaysia: 'MAS',
    maldives: 'MDV',
    mali: 'MLI',
    malta: 'MLT',
    'marshall islands': 'MHL',
    martinique: 'MTQ',
    mauritania: 'MRT',
    mauritius: 'MUS',
    mayotte: 'MYT',
    mexico: 'MEX',
    'micronesia, federated states of': 'FSM',
    moldova: 'MDA',
    monaco: 'MCO',
    mongolia: 'MNG',
    montenegro: 'MNE',
    montserrat: 'MSR',
    morocco: 'MAR',
    mozambique: 'MOZ',
    myanmar: 'MMR',
    namibia: 'NAM',
    nauru: 'NRU',
    nepal: 'NPL',
    netherlands: 'NED',
    'netherlands antilles': 'AN',
    'new caledonia': 'NCL',
    'new zealand': 'NZL',
    nicaragua: 'NIC',
    niger: 'NER',
    nigeria: 'NGA',
    niue: 'NIU',
    'norfolk island': 'NFK',
    'northern mariana islands': 'MNP',
    norway: 'NOR',
    oman: 'OMN',
    pakistan: 'PAK',
    palau: 'PLW',
    'palestinian territory, occupied': 'PSE',
    panama: 'PAN',
    'papua new guinea': 'HKN',
    paraguay: 'PRY',
    peru: 'PER',
    philippines: 'PHI',
    pitcairn: 'PCN',
    poland: 'POL',
    portugal: 'POR',
    'puerto rico': 'PRI',
    qatar: 'QAT',
    reunion: 'REU',
    romania: 'ROU',
    'russian federation': 'RUS',
    rwanda: 'RWA',
    'saint barthelemy': 'BLM',
    'saint helena': 'SHN',
    'saint kitts and nevis': 'KNA',
    'saint lucia': 'LCA',
    'saint martin': 'MAF',
    'saint pierre and miquelon': 'SPM',
    'saint vincent and grenadines': 'VCT',
    samoa: 'WSM',
    'san marino': 'SMR',
    'sao tome and principe': 'STP',
    'saudi arabia': 'KSA',
    scotland: 'SCB',
    senegal: 'SEN',
    serbia: 'SRB',
    seychelles: 'SCY',
    'sierra leone': 'SLE',
    singapore: 'SGP',
    slovakia: 'SVK',
    slovenia: 'SVN',
    'solomon islands': 'SLB',
    somalia: 'SOM',
    'south africa': 'RSA',
    'south georgia and sandwich isl.': 'SGS',
    spain: 'ESP',
    'sri lanka': 'LKA',
    sudan: 'SSD',
    suriname: 'SUR',
    'svalbard and jan mayen': 'SJM',
    swaziland: 'SZ',
    sweden: 'SWE',
    switzerland: 'SUI',
    'syrian arab republic': 'SYR',
    taiwan: 'TPE',
    tajikistan: 'TJK',
    tanzania: 'TZZ',
    thailand: 'THA',
    'timor-leste': 'TLS',
    togo: 'TGO',
    tokelau: 'TKL',
    tonga: 'TON',
    'trinidad and tobago': 'TTO',
    tunisia: 'TUN',
    turkey: 'TUR',
    turkmenistan: 'TKM',
    'turks and caicos islands': 'TCA',
    tuvalu: 'TUV',
    uganda: 'UGA',
    ukraine: 'UKR',
    'united arab emirates': 'UAE',
    'united kingdom': 'GBR',
    'united states': 'USA',
    'united states outlying islands': 'UMI',
    uruguay: 'URY',
    uzbekistan: 'UZB',
    vanuatu: 'VUT',
    venezuela: 'VEN',
    vietnam: 'VIE',
    'virgin islands, british': 'VGB',
    'virgin islands, u.s.': 'VIR',
    'wallis and futuna': 'WLF',
    'western sahara': 'ESH',
    yemen: 'YEM',
    zambia: 'ZMB',
    zimbabwe: 'ZWE',
};
