import gql from 'graphql-tag';

export const GET_GHOST_EVENT_QUERY = gql`
    query ghost_games($slug: String!) {
        ghost_games(where: {slug: {_eq: $slug}}) {
            id
            manager_id
            name
            description
            slug
            start_date
            end_date
            type
            payment_link
            cost
            type
            status
            max_players
            progress
            is_public
            invite_only
            updated_at
            created_at
            total_players: ghost_games_players_aggregate {
                aggregate {
                    count(columns: id)
                }
            }
            invited: ghost_games_players_aggregate(where: {is_invited: {_eq: true}}) {
                aggregate {
                    count(columns: is_invited)
                }
            }
            accepted_invitation: ghost_games_players_aggregate(where: {accepted_invitation: {_eq: true}}) {
                aggregate {
                    count(columns: accepted_invitation)
                }
            }
        }
    }
`;

export const GET_GHOST_EVENT_BY_ID_QUERY = gql`
    query ghost_games($id: Int) {
        ghost_games(where: {id: {_eq: $id}}) {
            id
            name
            description
            type
            max_players
            number_of_attempts
            number_of_racks
            start_date
            end_date
            cost
            payment_link
            number_of_rebuys
            rebuy_cost
            is_public
        }
    }
`

export const GET_GHOST_EVENT_BY_SLUG_QUERY = gql`
    query ghost_games($slug: String!) {
        ghost_games(where: {slug: {_eq: $slug}}) {
            id
            name
            description
            type
            max_players
            number_of_attempts
            number_of_racks
            start_date
            end_date
            cost
            payment_link
            number_of_rebuys
            rebuy_cost
            is_public
            slug
        }
    }
`

export const GET_GHOST_EVENT_REVIEW_PAGE_QUERY = gql`
    query ghost_games($id: Int!) {
        ghost_games(where: {id: {_eq: $id}}) {
            id
            manager_id
            name
            description
            slug
            start_date
            end_date
            type
            payment_link
            cost
            type
            status
            max_players
            progress
            is_public
            invite_only
            updated_at
            created_at
            number_of_rebuys
            number_of_attempts
            number_of_games
            rebuy_cost
            ghost_games_players {
                email_address
                user_id
                user {
                    first_name
                    last_name
                }
            }
        }
    }
`;

export const GET_GHOST_EVENT_ID_QUERY = gql`
    query ghost_games($slug: String!) {
        ghost_games(where: {slug: {eq: $slug}}) {
            id
        }
    }
`;

export const GET_USER_GHOST_EVENTS_QUERY = gql`
    query ghost_games($manager_id: Int!, $limit: Int) {
        ghost_games(limit: $limit, order_by: {created_at: desc}, where: {manager_id: {_eq: $manager_id}}) {
            id
            slug
            name
        }
    }
`;

export const GET_PUBLIC_GHOST_EVENTS = gql`
    query ghost_games {
        ghost_games(order_by: {created_at: desc}, where: {is_public: {_eq: true}}) {
            id
            slugs
        }
    }
`;

export const GET_GHOST_EVENTS_TYPE = gql`
    query ghost_games_type {
        ghost_games_type {
            value
            comment
        }
    }
`;

export const GET_GHOST_EVENT_PLAYERS_QUERY = gql`
    query ghost_games_players($ghost_games_id: Int!) {
        ghost_games_players(where: {ghost_games_id: {_eq: $ghost_games_id}}) {
            user {
                id
                display_name
                first_name
                last_name
                email
                country
            }
            id
            ghost_games_id
            paid
            is_invited
            accepted_invitation
            email_address
        }
    }
`;

export const GET_USER_BY_EMAIL_QUERY = gql`
    query users($email: String!) {
        users(where: {email: {_eq: $email}}) {
            id
            email
            first_name
            display_name
        }
    }
`;

export const GET_CHECK_INVITE_CODE_QUERY = gql`
    query ghost_games($invite_link_id: String!) {
        ghost_games(where: {invite_link_id: {_eq: $invite_link_id}}) {
            invite_link_id
        }
    }
`;

export const GET_INVITE_EMAILS_QUERY = gql`
    query ghost_games_players($ghost_games_id: Int!) {
        ghost_games_players(where: { ghost_games_id: {_eq: $ghost_games_id}}) {
            email_address
        }
    }
`;

export const GET_GHOST_GAMES_INVITE_KEY = gql`
    query ghost_games($ghost_games_id: Int!) {
        ghost_games(where: { id: {_eq: $ghost_games_id}}) {
            invite_link_id
        }
    }
`;

export const GET_GHOST_GAMES_PLAYER_QUERY = gql`
    query ghost_games_players($ghost_games_id: Int!, $email_address: String!) {
        ghost_games_players(where: {ghost_games_id: {_eq: $ghost_games_id}, _and: {email_address: {_eq: $email_address}}}) {
            id
        }
    }
`;

export const GET_GHOST_GAMES_RESULTS = gql`
    query ghost_games($id: Int!) {
        ghost_games(where: {id: {_eq: $id}}) {
            ghost_games_players {
                user {
                    first_name
                    last_name
                    display_name
                    country
                }
                ghost_games_rounds {
                    player_id
                    points
                    rack
                    attempt
                }
                ghost_games_rounds_aggregate(order_by: {points: desc}) {
                    aggregate {
                        sum {
                            points
                        }
                    }
                }
            }
            ghost_games_payouts(order_by: {place: asc}) {
                place
                amount
            }
            number_of_attempts
            status
        }
    }
`;

export const GET_GHOST_GAMES_PAYOUTS = gql`
    query ghost_games_payouts($ghost_games_id: Int!) {
        ghost_games_payouts(where: {ghost_games_id: {_eq: $ghost_games_id}}) {
            id
            place
            amount
        }
    }
`;

export const GET_GHOST_GAMES_PAYOUTS_RECORD = gql`
    query ghost_games_payouts($id: Int!) {
        ghost_games_payouts(where: {id: {_eq: $id}}) {
            place
            amount
        }
    }
`;

export const GET_GHOST_GAMES = gql`
    query ghost_games($manager_id: Int!) {
        ghost_games(where: {manager_id: {_eq: $manager_id}}, order_by: {id: desc}) {
            id
            name
            slug
        }
    }
`;

export const GET_GHOST_GAMES_STATS_QUERY = gql`
    query ghost_games($id: Int!) {
        ghost_games(where: {id: {_eq: $id}}) {
            id
            ghost_games_players {
                id
                ghost_games_rounds(order_by: {attempt: asc}) {
                    id
                    attempt
                    rack
                    points
                }
                user {
                    id
                    first_name
                    last_name
                    display_name
                }
                ghost_games_rounds_aggregate {
                    aggregate {
                        avg {
                            points
                        }
                        sum {
                            points
                        }
                    }
                }
            }
        }
    }
`;

export const CHECK_GHOST_GAMES_PAYOUTS_DUPLICATE_PLACE = gql`
    query ghost_games_payouts($ghost_games_id: Int!, $place: String!) {
        ghost_games_payouts(where: {ghost_games_id: {_eq: $ghost_games_id}, place: {_eq: $place}}) {
            id
        }
    }
`;

export const GET_GHOST_GAMES_PLAYERS_LIST = gql`
    query ghost_games_players($ghost_games_id: Int!) {
        ghost_games_players(where: {ghost_games_id: {_eq: $ghost_games_id}}) {
            id
            user_id
            email_address
            separator
        }
    }
`;

export const GET_USER_IF_EXISTS = gql`
    query users($email: String!) {
        users(where: {email: {_eq: $email}}) {
            id
        }
    }
`;