import React from 'react';
import { Query } from '@apollo/client/react/components';
import { GET_GHOST_GAMES } from '../../data/queries';
import { List, Table } from 'antd';

export default function CreatedGhostEventsTable(props) {
    const { userId, history } = props;

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            sorter: (a, b) => {
                return a.name && a.name.localeCompare(b.name);
            },
        },
    ];

    return (
        <Query
            query={GET_GHOST_GAMES}
            fetchPolicy="cache-and-network"
            notifyOnNetworkStatusChange={true}
            variables={{
                manager_id: userId,
            }}
        >
            {({ loading, error, data }) => {
                if (loading) return null;
                if (error) return null;

                const ghostGames = data && data.ghost_games;

                return (
                    <Table
                        columns={columns}
                        dataSource={ghostGames}
                        pagination={{
                            defaultPageSize: 10,
                        }}
                        onRow={(record, recordIndex) => ({
                            onClick: (event) => {
                                history.push(`/ghost-event-manager/${record.slug}/dashboard`);
                            },
                        })}
                    />
                );
            }}
        </Query>
    );
}
