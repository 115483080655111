import React, { useState, useEffect } from 'react';
import { Table, Icon, Row, Col, Typography, Tooltip, Progress, Button } from 'antd';
import Card from '../../../components/Card';
import CircleFlag from '../../../components/CircleFlag';
import { isoCountries } from '../../../utils/isoCountryCodes';
import { getCountryName } from '../../../utils/getCountryName';
import { Link } from 'react-router-dom';
import Podium from './Podium';
import { TrophyFilled } from '@ant-design/icons';
import _ from 'lodash';
import PlayerModal from './bracket/PlayerModal';
import numeral from 'numeral';

const { Text, Title } = Typography;

const styles = {
    row: {
        display: 'flex',
        flexGrow: 1,
    },
    tab: {
        paddingTop: 5,
        paddingLeft: 24,
        paddingRight: 24,
        paddingBottom: 24,
    },
};

export default function ResultsTable(props) {
    const { tournament, tournamentID, formattedResults } = props;
    const [columnData, setColumnData] = useState([]);
    const [playerModalVisible, setPlayerModalVisible] = useState(false);
    const [selectedPlayerId, setSelectedPlayerId] = useState();
    // console.log('results', props.formattedResults);

    const columns = [
        {
            title: 'Place',
            dataIndex: 'place',
            key: 'place',
            width: 80,
            defaultSortOrder: 'ascend',
            sorter: (a, b) => {
                return (
                    a.place &&
                    a.place.localeCompare(b.place, undefined, {
                        numeric: true,
                        sensitivity: 'base',
                    })
                );
            },
            render: (text, record) => (
                <div style={{ textAlign: 'right' }}>
                    {record.place === '1' ? (
                        <TrophyFilled style={{ color: '#eab700', marginRight: 5 }} />
                    ) : record.place === '2' ? (
                        <TrophyFilled style={{ color: '#9baab1', marginRight: 5 }} />
                    ) : (
                        record.place === '3' && <TrophyFilled style={{ color: '#f19921', marginRight: 5 }} />
                    )}
                    <Text>{text || '-'}</Text>
                </div>
            ),
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => {
                return a.name && a.name.localeCompare(b.name);
            },
            render: (text, record) => {
                const countryName = getCountryName(record && record.country);

                return (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        {countryName && <CircleFlag country={countryName} left={0} />}

                        <span style={{ marginLeft: countryName ? 10 : 0 }}>{text}</span>
                    </div>
                );
            },
        },

        {
            title: 'SL',
            dataIndex: 'skillLevel',
            key: 'skillLevel',
            disabled: true,
            sorter: (a, b) => {
                return a.skillLevel - b.skillLevel;
            },
            render: (text, record) => <Text>{tournament.show_player_skill_levels === true ? text : '-'}</Text>,
        },
        {
            title: 'Match History',
            dataIndex: 'matches',
            key: 'matches',
            disabled: true,
            render: (text, record) => {
                // console.log(record);
                return <Text>{renderMatchHistory(record.matches, record.id)}</Text>;
            },
        },

        {
            title: 'Earnings',
            dataIndex: 'earnings',
            key: 'earnings',
            sorter: (a, b) => {
                return a.earnings - b.earnings;
            },
            render: (text, record) => <Text>{record.earnings ? numeral(record.earnings).format('$0,0') : '-'}</Text>,
        },
        {
            title: 'Auction',
            dataIndex: 'calcutta',
            key: 'calcutta',
            sorter: (a, b) => {
                return a.calcutta - b.calcutta;
            },
            render: (text, record) => <Text>{record.calcutta ? numeral(record.calcutta).format('$0,0') : '-'}</Text>,
        },
        {
            title: 'Points',
            dataIndex: 'points',
            key: 'points',
            sorter: (a, b) => {
                return a.points - b.points;
            },
            render: (text, record) => <Text>{text || '-'}</Text>,
        },
        // {
        // 	title: 'Match History',
        // 	dataIndex: 'matchHistory',
        // 	key: 'matchHistory',
        // 	render: (text, record) => {
        // 		return <Button type="link">View</Button>;
        // 		// return record.matchHistory.map((result, index) => {
        // 		// 	return (
        // 		// 		<span key={index} className={result === 'W' ? 'match-badge win' : 'match-badge loss'}>
        // 		// 			{result}
        // 		// 		</span>
        // 		// 	);
        // 		// });
        // 	}
        // }
    ];

    // useEffect(
    // 	() => {
    // 		let checkedColumns = [];

    // 		if (props.formattedResults) {
    // 			console.log(props.formattedResults[0]);
    // 			if (_.has(props.formattedResults[0], 'name') !== false) {
    // 				checkedColumns.push('name');
    // 			}
    // 			if (_.has(props.formattedResults[0], 'place') !== false) {
    // 				checkedColumns.push('place');
    // 			}
    // 			if (_.has(props.formattedResults[0], 'earnings') !== false) {
    // 				checkedColumns.push('earnings');
    // 			}
    // 			if (_.has(props.formattedResults[0], 'skillLevel') !== false) {
    // 				checkedColumns.push('skillLevel');
    // 			}
    // 			if (_.has(props.formattedResults[0], 'points') !== false) {
    // 				checkedColumns.push('points');
    // 			}
    // 			if (_.has(props.formattedResults[0], 'calcutta') !== false) {
    // 				checkedColumns.push('calcutta');
    // 			}

    // 			var filtered;
    // 			for (var i = 0; i <= checkedColumns.length; i++) {
    // 				filtered = columns.filter((el) => {
    // 					console.log(el);
    // 					return el.dataIndex !== checkedColumns[i];
    // 				});
    // 			}

    // 			console.log(checkedColumns);
    // 			console.log(filtered);
    // 			setColumnData(filtered);
    // 		}
    // 	},
    // 	[ props.formattedResults ]
    // );

    function handleShowPlayerModal(playerId) {
        console.log(playerId);
        setSelectedPlayerId(playerId);
        setPlayerModalVisible(true);
    }

    function handlePlayerModalVisible(visibility) {
        setPlayerModalVisible(visibility);
    }

    function renderMatchHistory(matchesWithoutByes, playerId) {
        return (
            <Text>
                {matchesWithoutByes
                    .sort((a, b) => {
                        return a.match_number - b.match_number;
                    })
                    .filter((match) => {
                        return match.challenger1_is_winner === true || match.challenger2_is_winner === true;
                    })
                    .map((match, index) => {
                        const result =
                            match.challenger1_id === playerId && match.challenger1_is_winner === true
                                ? 'W'
                                : match.challenger2_id === playerId && match.challenger2_is_winner === true
                                ? 'W'
                                : match.challenger1_is_winner !== true && match.challenger2_is_winner !== true
                                ? '-'
                                : match.challenger1_is_forfeit === true || match.challenger2_is_forfeit === true
                                ? 'FF'
                                : 'L';

                        const opponentName =
                            match.challenger1_id === playerId ? match.challenger2_name : match.challenger2_id === playerId ? match.challenger1_name : null;
                        const playerScore =
                            match.challenger1_id === playerId ? match.challenger1_score : match.challenger2_id === playerId ? match.challenger2_score : 0;
                        const opponentScore =
                            match.challenger1_id !== playerId ? match.challenger1_score : match.challenger2_id !== playerId ? match.challenger2_score : 0;

                        return (
                            <React.Fragment key={index}>
                                <Tooltip placement="top" title={`${result} vs. ${opponentName} (${playerScore}-${opponentScore})`}>
                                    <span
                                        className={
                                            (match.challenger1_id === playerId && match.challenger1_is_winner === true) ||
                                            (match.challenger2_id === playerId && match.challenger2_is_winner === true)
                                                ? 'match-badge win'
                                                : 'match-badge loss'
                                        }
                                    >
                                        {result}
                                    </span>
                                </Tooltip>
                            </React.Fragment>
                        );
                    })}
            </Text>
        );
    }

    const keys = ['id', 'place', 'name', 'matches', 'skillLevel', 'earnings', 'calcutta', 'points'];
    let visibleColumns = [];

    keys.forEach((key) => {
        var hasData = _.some(formattedResults, (row) => {
            return row[key] !== undefined;
        });
        if (key === 'earnings') {
            visibleColumns.push(key);
        } else if (hasData !== false) {
            visibleColumns.push(key);
        }
    });

    // console.log(formattedResults);

    return (
        <React.Fragment>
            <Podium tournamentID={tournamentID} style={{ marginTop: 10 }} />
            <Card
                // title="Results"
                bodyStyle={{ padding: 0 }}
                // style={{
                // 	borderRadius: 8,
                // 	overflow: 'hidden',
                // 	border: 'none',
                // 	boxShadow: '0 2px 5px 0px rgba(0, 0, 0, 0.1)'
                // }}
            >
                <div className="table_nowrap">
                    <Table
                        pagination={false}
                        columns={columns.filter((column) => {
                            return visibleColumns.includes(column.dataIndex);
                        })}
                        dataSource={formattedResults}
                        onRow={(record, rowIndex) => {
                            return {
                                onClick: (event) => {
                                    console.log(record);
                                    // console.log(record, rowIndex);
                                    handleShowPlayerModal(record.key);
                                    // props.history.push({
                                    // 	pathname: `/tournament-builder/${props.tournament
                                    // 		.slug}/players/${record.name}`,
                                    // 	// search: '?query=abc',
                                    // 	state: {
                                    // 		data: record,
                                    // 		backPath: `/tournament-builder/${props.tournament
                                    // 			.slug}/stats`
                                    // 	}
                                    // });
                                },
                                onDoubleClick: (event) => {}, // double click row
                                onContextMenu: (event) => {}, // right button click row
                                onMouseEnter: (event) => {}, // mouse enter row
                                onMouseLeave: (event) => {}, // mouse leave row
                            };
                        }}
                        size="default"
                        tableLayout="auto"
                        scroll={{ x: 400 }}
                        // pagination={{ pageSize: 25 }}
                    />
                </div>
            </Card>
            <PlayerModal
                tournamentID={tournamentID}
                playerId={selectedPlayerId}
                modalVisible={playerModalVisible}
                onPlayerModalVisible={handlePlayerModalVisible}
                {...props}
            />
        </React.Fragment>
    );
}
