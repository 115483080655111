import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { Popconfirm, message, Menu, Dropdown, Modal, Progress, List, Button, Upload, Icon, Typography, Avatar } from 'antd';
import axios from 'axios';
import _ from 'lodash';
import apiEndPoint from '../utils/apiEndpoint';
import { DownOutlined } from '@ant-design/icons';
import numeral from 'numeral';

const { Text } = Typography;

function GetFargoModal(props) {
    const { player, modalVisible, onModalOk, onModalCancel } = props;
    const [fargoResults, setFargoResults] = useState([]);
    const [playerList, setPlayerList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalPlayers, setTotalPlayers] = useState(0);
    const [confirmPopupVisible, setConfirmPopupVisible] = useState(false);

    useEffect(() => {
        let promises = [];
        async function fetchData() {
            setLoading(true);
            console.log(player);
            const name = player.display_name || player.name;
            // const fargoId = player.fargo_id && player.fargo_id.toString().replace(/^\s+|\s+$/gm, ''); // remove white spaces
            // const results = await getFargoRatingByName(name, fargoId);
            const results2 = await getFargoRatingByName2(name);

            // console.log('results', _.compact(results));
            console.log('new results', results2);

            setFargoResults(results2);
        }

        if (player && modalVisible === true) {
            fetchData();
        }
    }, [player, modalVisible]);

    async function getFargoRatingByName(name, fargoId) {
        try {
            const nameSplit = name.split(' ');
            let url;
            if (nameSplit.length > 1) {
                const firstName = nameSplit[0];
                const lastName = nameSplit[1];
                url = 'https://dashboard.fargorate.com/api/indexsearch?q=' + firstName.toLowerCase() + '%20' + lastName.toLowerCase();
            } else {
                url = 'https://dashboard.fargorate.com/api/indexsearch?q=' + name.toLowerCase();
            }

            var response = await axios(url);
            const results = response.data.value;
            console.log(results);
            console.log(fargoId);

            setLoading(false);

            if (results && results.length) {
                if (fargoId) {
                    return results
                        .map((row) => {
                            return {
                                id: player.id,
                                firstName: row.firstName,
                                lastName: row.lastName,
                                effectiveRating: row.effectiveRating,
                                rating: row.rating,
                                robustness: row.robustness,
                                location: row.location,
                                city: row.city,
                                state: row.state,
                                country: row.country,
                                fargoId: row.membershipId,
                                fargoUuid: row.id,
                                fargoReadableId: row.readableId,
                            };
                        })
                        .filter((row) => {
                            if (fargoId === row.fargoId) {
                                return row;
                            }
                        });
                } else {
                    return results.map((row) => {
                        return {
                            id: player.id,
                            firstName: row.firstName,
                            lastName: row.lastName,
                            effectiveRating: row.effectiveRating,
                            rating: row.rating,
                            robustness: row.robustness,
                            location: row.location,
                            city: row.city,
                            state: row.state,
                            country: row.country,
                            fargoId: row.membershipId,
                            fargoUuid: row.id,
                            fargoReadableId: row.readableId,
                        };
                    });
                }
            }
        } catch (error) {
            const text = error && error.response && error.response.data && error.response.data.Message;
            console.log(text);
            if (text) {
                message.error(`Error: ${text}`);
            } else {
                message.error(`Error: Player not found.`);
            }
            setLoading(false);
            return text;
        }
    }

    function getFargoRatingByName2(name) {
        if (name) {
            setLoading(true);
            // const formattedName = name.toLowerCase().trim().replace('-', '').replace(' ', '');
            const formattedName = name.toLowerCase();
            let url = `https://api.fargorate.com/search?search=${formattedName}`;

            const nameSplit = name.split(' ');
            const firstName = nameSplit[0];
            const lastName = nameSplit[1];

            if (nameSplit && nameSplit.length > 0) {
                url = `https://api.fargorate.com/search?search=firstName:${firstName} AND lastName:${lastName}`;
            } else {
                url = `https://api.fargorate.com/search?search=${formattedName}`;
            }

            return axios
                .post(`${apiEndPoint}getFargoRating`, {
                    url: url,
                    player: formattedName,
                })
                .then((res) => {
                    console.log(res.data);
                    setLoading(false);
                    const fargoData = res.data;
                    if (fargoData) {
                        return [
                            {
                                id: player.id,
                                firstName: fargoData.firstName,
                                lastName: fargoData.lastName,
                                effectiveRating: fargoData.effectiveRating,
                                rating: fargoData.rating,
                                robustness: fargoData.robustness,
                                location: fargoData.location,
                                city: fargoData.city,
                                state: fargoData.state,
                                country: fargoData.country,
                                fargoId: fargoData.membershipId,
                                fargoUuid: fargoData.id,
                                fargoReadableId: fargoData.readableId,
                                properties: {
                                    fargo_data: fargoData,
                                },
                            },
                        ];
                    } else {
                        return [];
                    }
                })
                .catch((error) => {
                    console.log(error);
                    message.error(`There was an error: ${JSON.stringify(error)}`);
                    setLoading(false);
                    return error;
                });
        }
    }

    return (
        <Modal
            title="Get Fargo Rating"
            centered
            transitionName="fade"
            wrapClassName="fargo-modal"
            // transitionName="none"
            maskTransitionName="none"
            bodyStyle={{ maxHeight: 500, overflowY: 'auto', padding: 0 }}
            destroyOnClose={false}
            footer={null}
            visible={modalVisible}
            // onOk={() => {
            //     setConfirmPopupVisible(true);
            // }}
            onCancel={onModalCancel}
            // okText="Apply Ratings"
            // cancelText="Dismiss"
        >
            <React.Fragment>
                {loading ? (
                    <div style={{ padding: '10px 20px' }}>Finding player...</div>
                ) : (
                    <List className="hover-list" size="small" loading={loading}>
                        <div>
                            {fargoResults && fargoResults.length > 0 ? (
                                fargoResults.map((item, index) => {
                                    let selectedItem = item;

                                    return (
                                        <React.Fragment key={index}>
                                            <List.Item
                                                onClick={() => {
                                                    let selectedPlayer = item;
                                                    selectedPlayer[index] = selectedPlayer[0];
                                                    console.log(selectedPlayer);
                                                    onModalOk(selectedPlayer);
                                                }}
                                                style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                                            >
                                                <List.Item.Meta
                                                    avatar={
                                                        <Avatar size={30}>
                                                            <Icon type="user" />
                                                        </Avatar>
                                                    }
                                                    title={`${selectedItem.firstName} ${selectedItem.lastName} - ${selectedItem.effectiveRating}`}
                                                    description={
                                                        <Text type="secondary">
                                                            {selectedItem.location && selectedItem.location !== ' ' && `${selectedItem.location}`}
                                                            {selectedItem.location && selectedItem.location !== ' ' && <br />}
                                                            Fargo ID: {selectedItem.fargoId}
                                                            <br />
                                                            Robustness: {numeral(selectedItem.robustness).format('0,0')}
                                                        </Text>
                                                    }
                                                />
                                            </List.Item>
                                        </React.Fragment>
                                    );
                                })
                            ) : (
                                <List.Item style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                    No player could be found.
                                </List.Item>
                            )}
                        </div>
                    </List>
                )}
            </React.Fragment>
        </Modal>
    );
}

export default withRouter(GetFargoModal);
