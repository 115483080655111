export function getByeChart(totalPlayers) {
    let byeCharts = [];
    byeCharts[2] = [2];
    byeCharts[4] = [4, 2];
    byeCharts[8] = [8, 4, 6, 2];
    byeCharts[16] = [16, 8, 12, 4, 14, 6, 10, 2];
    byeCharts[32] = [32, 16, 24, 8, 28, 12, 20, 4, 30, 14, 22, 6, 26, 10, 18, 2];
    byeCharts[48] = [16, 8, 12, 4, 14, 6, 10, 2, 15, 7, 11, 3, 13, 5, 9, 1];
    byeCharts[64] = [64, 32, 48, 16, 56, 24, 40, 8, 60, 28, 44, 12, 52, 20, 36, 4, 62, 30, 46, 14, 54, 22, 38, 6, 58, 26, 42, 10, 50, 18, 34, 2];
    byeCharts[128] = [
        128, 64, 96, 32, 112, 48, 80, 16, 120, 56, 88, 24, 104, 40, 72, 8, 124, 60, 92, 28, 108, 44, 76, 12, 116, 52, 84, 20, 100, 36, 68, 4, 126, 62, 94, 30,
        110, 46, 78, 14, 118, 54, 86, 22, 102, 38, 70, 6, 122, 58, 90, 26, 106, 42, 74, 10, 114, 50, 82, 18, 98, 34, 66, 2,
    ];
    byeCharts[256] = [
        256, 128, 192, 64, 224, 96, 160, 32, 240, 112, 176, 48, 208, 80, 144, 16, 248, 120, 184, 56, 216, 88, 152, 24, 232, 104, 168, 40, 200, 72, 136, 8, 252,
        124, 188, 60, 220, 92, 156, 28, 236, 108, 172, 44, 204, 76, 140, 12, 244, 116, 180, 52, 212, 84, 148, 20, 228, 100, 164, 36, 196, 68, 132, 4, 254, 126,
        190, 62, 222, 94, 158, 30, 238, 110, 174, 46, 206, 78, 142, 14, 246, 118, 182, 54, 214, 86, 150, 22, 230, 102, 166, 38, 198, 70, 134, 6, 250, 122, 186,
        58, 218, 90, 154, 26, 234, 106, 170, 42, 202, 74, 138, 10, 242, 114, 178, 50, 210, 82, 146, 18, 226, 98, 162, 34, 194, 66, 130, 2,
    ];
    byeCharts[512] = [
        512, 256, 384, 128, 448, 192, 320, 64, 480, 224, 352, 96, 416, 160, 288, 32, 496, 240, 368, 112, 432, 176, 304, 48, 464, 208, 336, 80, 400, 144, 272,
        16, 504, 248, 376, 120, 440, 184, 312, 56, 472, 216, 344, 88, 408, 152, 280, 24, 488, 232, 360, 104, 424, 168, 296, 40, 456, 200, 328, 72, 392, 136,
        264, 8, 508, 252, 380, 124, 444, 188, 316, 60, 476, 220, 348, 92, 412, 156, 284, 28, 492, 236, 364, 108, 428, 172, 300, 44, 460, 204, 332, 76, 396, 140,
        268, 12, 500, 244, 372, 116, 436, 180, 308, 52, 468, 212, 340, 84, 404, 148, 276, 20, 484, 228, 356, 100, 420, 164, 292, 36, 452, 196, 324, 68, 388,
        132, 260, 4, 510, 254, 382, 126, 446, 190, 318, 62, 478, 222, 350, 94, 414, 158, 286, 30, 494, 238, 366, 110, 430, 174, 302, 46, 462, 206, 334, 78, 398,
        142, 270, 14, 502, 246, 374, 118, 438, 182, 310, 54, 470, 214, 342, 86, 406, 150, 278, 22, 486, 230, 358, 102, 422, 166, 294, 38, 454, 198, 326, 70,
        390, 134, 262, 6, 506, 250, 378, 122, 442, 186, 314, 58, 474, 218, 346, 90, 410, 154, 282, 26, 490, 234, 362, 106, 426, 170, 298, 42, 458, 202, 330, 74,
        394, 138, 266, 10, 498, 242, 370, 114, 434, 178, 306, 50, 466, 210, 338, 82, 402, 146, 274, 18, 482, 226, 354, 98, 418, 162, 290, 34, 450, 194, 322, 66,
        386, 130, 258, 2,
    ];
    byeCharts[1024] = [
        1024, 512, 768, 256, 896, 384, 640, 128, 960, 448, 704, 192, 832, 320, 576, 64, 992, 480, 736, 224, 864, 352, 608, 96, 928, 416, 672, 160, 800, 288,
        544, 32, 1008, 496, 752, 240, 880, 368, 624, 112, 944, 432, 688, 176, 816, 304, 560, 48, 976, 464, 720, 208, 848, 336, 592, 80, 912, 400, 656, 144, 784,
        272, 528, 16, 1016, 504, 760, 248, 888, 376, 632, 120, 952, 440, 696, 184, 824, 312, 568, 56, 984, 472, 728, 216, 856, 344, 600, 88, 920, 408, 664, 152,
        792, 280, 536, 24, 1000, 488, 744, 232, 872, 360, 616, 104, 936, 424, 680, 168, 808, 296, 552, 40, 968, 456, 712, 200, 840, 328, 584, 72, 904, 392, 648,
        136, 776, 264, 520, 8, 1020, 508, 764, 252, 892, 380, 636, 124, 956, 444, 700, 188, 828, 316, 572, 60, 988, 476, 732, 220, 860, 348, 604, 92, 924, 412,
        668, 156, 796, 284, 540, 28, 1004, 492, 748, 236, 876, 364, 620, 108, 940, 428, 684, 172, 812, 300, 556, 44, 972, 460, 716, 204, 844, 332, 588, 76, 908,
        396, 652, 140, 780, 268, 524, 12, 1012, 500, 756, 244, 884, 372, 628, 116, 948, 436, 692, 180, 820, 308, 564, 52, 980, 468, 724, 212, 852, 340, 596, 84,
        916, 404, 660, 148, 788, 276, 532, 20, 996, 484, 740, 228, 868, 356, 612, 100, 932, 420, 676, 164, 804, 292, 548, 36, 964, 452, 708, 196, 836, 324, 580,
        68, 900, 388, 644, 132, 772, 260, 516, 4, 1022, 510, 766, 254, 894, 382, 638, 126, 958, 446, 702, 190, 830, 318, 574, 62, 990, 478, 734, 222, 862, 350,
        606, 94, 926, 414, 670, 158, 798, 286, 542, 30, 1006, 494, 750, 238, 878, 366, 622, 110, 942, 430, 686, 174, 814, 302, 558, 46, 974, 462, 718, 206, 846,
        334, 590, 78, 910, 398, 654, 142, 782, 270, 526, 14, 1014, 502, 758, 246, 886, 374, 630, 118, 950, 438, 694, 182, 822, 310, 566, 54, 982, 470, 726, 214,
        854, 342, 598, 86, 918, 406, 662, 150, 790, 278, 534, 22, 998, 486, 742, 230, 870, 358, 614, 102, 934, 422, 678, 166, 806, 294, 550, 38, 966, 454, 710,
        198, 838, 326, 582, 70, 902, 390, 646, 134, 774, 262, 518, 6, 1018, 506, 762, 250, 890, 378, 634, 122, 954, 442, 698, 186, 826, 314, 570, 58, 986, 474,
        730, 218, 858, 346, 602, 90, 922, 410, 666, 154, 794, 282, 538, 26, 1002, 490, 746, 234, 874, 362, 618, 106, 938, 426, 682, 170, 810, 298, 554, 42, 970,
        458, 714, 202, 842, 330, 586, 74, 906, 394, 650, 138, 778, 266, 522, 10, 1010, 498, 754, 242, 882, 370, 626, 114, 946, 434, 690, 178, 818, 306, 562, 50,
        978, 466, 722, 210, 850, 338, 594, 82, 914, 402, 658, 146, 786, 274, 530, 18, 994, 482, 738, 226, 866, 354, 610, 98, 930, 418, 674, 162, 802, 290, 546,
        34, 962, 450, 706, 194, 834, 322, 578, 66, 898, 386, 642, 130, 770, 258, 514, 2,
    ];

    if (totalPlayers) {
        return byeCharts[totalPlayers];
    } else {
        return;
    }
}
