import React, { useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Dropdown, Button, Menu } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import CircularLoader from '../../../../components/CircularLoader';
import ZeroState from '../../../../components/ZeroState';
import { GET_TOURNAMENT_STAGES } from '../data/queries';
import { Query } from '@apollo/client/react/components';

const styles = {};

function TournamentStageDropdown(props) {
    const { theme, tournament, stages, match, isPublic, selectedStage, onSelected } = props;
    const tournamentSlug = match && match.params && match.params.slug;
    const currentStage = selectedStage || (stages && stages[0]);

    return (
        <React.Fragment>
            {stages && stages.length > 0 && (
                <div
                    style={{
                        width: 'auto',
                        display: 'flex',
                        top: 55,
                        right: 10,
                        position: 'absolute',
                        zIndex: 1,
                    }}
                >
                    <Dropdown
                        getPopupContainer={() => document.querySelectorAll('#bracket-container .ant-layout-content')[0]}
                        overlay={
                            <Menu
                                selectedKeys={currentStage && currentStage.slug}
                                onClick={(e) => {
                                    if (isPublic === true) {
                                        props.history.push(`/tournaments/${tournamentSlug}/bracket/${e.key}`);
                                    } else {
                                        props.history.push(`/tournament-builder/${tournamentSlug}/bracket/${e.key}`);
                                    }
                                    const selectedStage =
                                        stages &&
                                        stages.filter((stage) => {
                                            return stage.slug === e.key;
                                        })[0];
                                    onSelected(selectedStage);
                                }}
                                theme={theme && theme.name}
                            >
                                {stages &&
                                    stages.map((stage, index) => {
                                        return <Menu.Item key={stage.slug}>{stage.name}</Menu.Item>;
                                    })}
                            </Menu>
                        }
                        trigger={['click']}
                    >
                        <Button type="danger">
                            {(currentStage && currentStage.name) || '-'}
                            <DownOutlined />
                        </Button>
                    </Dropdown>
                </div>
            )}

            {/* <Query
                query={GET_TOURNAMENT_STAGES}
                fetchPolicy="cache-and-network"
                notifyOnNetworkStatusChange={true}
                variables={{ tournament_id: tournament.id }}
                onCompleted={(data) => {
                    console.log(data);
                }}
            >
                {({ loading, error, data }) => {
                    if (loading) return <CircularLoader />;
                    if (error) return <div style={styles.container}>Error: {error.message}</div>;
                    if ((data && !data.tournament_stages) || (data && data.tournament_stages.length === 0)) {
                        return null;
                    }

                    const stages = data && data.tournament_stages;
                    const currentStage = stages.filter((item) => {
                        if (item.stage.slug === tournamentSlug) {
                            return item;
                        }
                    })[0];

                    return (
                        <div
                            style={{
                                width: 'auto',
                                display: 'flex',
                                top: 55,
                                right: 10,
                                position: 'absolute',
                                zIndex: 1,
                            }}
                        >
                            <Dropdown
                                getPopupContainer={() => document.querySelectorAll('#bracket-container .ant-layout-content')[0]}
                                overlay={
                                    <Menu
                                        selectedKeys={tournamentSlug}
                                        onClick={(e) => {
                                            if (isPublic === true) {
                                                props.history.push(`/tournaments/${e.key}/bracket`);
                                            } else {
                                                props.history.push(`/tournament-builder/${e.key}/bracket`);
                                            }
                                        }}
                                        theme={theme && theme.name}
                                    >
                                        {stages.map((item) => {
                                            return <Menu.Item key={item.stage.slug}>{item.stage.name}</Menu.Item>;
                                        })}
                                    </Menu>
                                }
                                trigger={['click']}
                            >
                                <Button type="danger">
                                    {currentStage && currentStage.stage && currentStage.stage.name}
                                    <DownOutlined />
                                </Button>
                            </Dropdown>
                        </div>
                    );
                }}
            </Query> */}
        </React.Fragment>
    );
}

export default withRouter(TournamentStageDropdown);
