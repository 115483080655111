import React, { useState, useEffect } from 'react';
import { Avatar, Typography, Row, Col, Button, Icon } from 'antd';
import { withRouter, Link } from 'react-router-dom';
import CircleFlag from '../../../components/CircleFlag';
import dpLogoWatermarkColor from '../../../assets/img/dp-logo-watermark-color.png';
import { getGameType, getCountryName, displayEnumType } from '../../../utils';
import RackTracker from './RackTracker';
import qs from 'query-string';

const { Text, Title } = Typography;

const styles = {
    container: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 20,
    },
};

function TVRackTracker(props) {
    const { tournamentData, matchData, tableData, subscribeToMore, subscribeToNewData } = props;

    let challenger1RaceTo;
    let challenger2RaceTo;
    if (matchData.identifier.includes('L')) {
        challenger1RaceTo = matchData.challenger1_race_to || matchData.tournament.losers_race_to || 0;
        challenger2RaceTo = matchData.challenger2_race_to || matchData.tournament.losers_race_to || 0;
    } else {
        challenger1RaceTo = matchData.challenger1_race_to || matchData.tournament.winners_race_to || 0;
        challenger2RaceTo = matchData.challenger2_race_to || matchData.tournament.winners_race_to || 0;
    }
    const currentGame = matchData && matchData.tournament_match_games && matchData.tournament_match_games[matchData.tournament_match_games.length - 1];

    useEffect(() => {
        let unsubscribe;
        if (subscribeToNewData) {
            unsubscribe = subscribeToNewData(subscribeToMore);
        }
        return () => {
            if (unsubscribe) {
                unsubscribe();
            }
        };
    }, []);

    return (
        <div style={{ display: 'flex', height: '100%' }}>
            <div
                style={{
                    paddingTop: 20,
                    paddingLeft: 20,
                    paddingRight: 10,
                    paddingBottom: 20,
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 1,
                    height: '100%',
                    justifyContent: 'space-between',
                }}
            >
                <Row gutter={16} type="flex" justify="space-between" style={{ width: '100%' }}>
                    <Col xs={12} sm={12}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            {tournamentData.logo && <img src={tournamentData.logo} alt="Logo" height={120} />}

                            <div style={{ marginLeft: 20, display: 'flex', flexDirection: 'column' }}>
                                <div
                                    style={{
                                        flex: 1,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        minWidth: 250,
                                    }}
                                >
                                    <div style={{ display: 'flex', alignItems: 'center', paddingRight: 50 }}>
                                        {matchData.challenger1_country && (
                                            <CircleFlag country={getCountryName(matchData.challenger1_country) || 'Unknown'} size="medium" />
                                        )}
                                        <Title
                                            level={3}
                                            style={{
                                                margin: 0,
                                                color: '#fff',
                                                letterSpacing: 0,
                                                textShadow: '0 2px 5px rgba(0,0,0,0.50)',
                                                textTransform: 'uppercase',
                                            }}
                                        >
                                            {matchData.challenger1_name}{' '}
                                            {matchData.challenger1_name &&
                                                matchData.challenger1_name !== 'BYE' &&
                                                matchData.challenger1_skill_level &&
                                                `(${matchData.challenger1_skill_level})`}
                                        </Title>
                                    </div>

                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        {tournamentData && tournamentData.game_type === 'ONE_POCKET' && (
                                            <Title
                                                level={2}
                                                style={{
                                                    margin: 0,
                                                    color: 'rgba(255,255,255,0.75)',
                                                    letterSpacing: 0,
                                                    textShadow: '0 2px 5px rgba(0,0,0,0.50)',
                                                    marginRight: 20,
                                                    minWidth: 20,
                                                    textAlign: 'center',
                                                }}
                                            >
                                                {matchData.challenger1_points || 0} B
                                            </Title>
                                        )}

                                        <Title
                                            level={2}
                                            style={{
                                                margin: 0,
                                                color: '#fff',
                                                letterSpacing: 0,
                                                textShadow: '0 2px 5px rgba(0,0,0,0.50)',
                                                minWidth: 20,
                                                textAlign: 'center',
                                            }}
                                        >
                                            {matchData.challenger1_score || 0}
                                        </Title>
                                    </div>
                                </div>
                                <div style={{ marginTop: 5, marginBottom: 5, borderTop: '1px solid rgba(255,255,255,0.2)' }} />
                                <div
                                    style={{
                                        flex: 1,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        minWidth: 250,
                                    }}
                                >
                                    <div style={{ display: 'flex', alignItems: 'center', paddingRight: 50 }}>
                                        {matchData.challenger2_country && (
                                            <CircleFlag country={getCountryName(matchData.challenger2_country) || 'Unknown'} size="medium" />
                                        )}
                                        <Title
                                            level={3}
                                            style={{
                                                margin: 0,
                                                color: '#fff',
                                                letterSpacing: 0,
                                                textShadow: '0 2px 5px rgba(0,0,0,0.50)',
                                                textTransform: 'uppercase',
                                            }}
                                        >
                                            {matchData.challenger2_name}{' '}
                                            {matchData.challenger2_name &&
                                                matchData.challenger2_name !== 'BYE' &&
                                                matchData.challenger2_skill_level &&
                                                `(${matchData.challenger2_skill_level})`}
                                        </Title>
                                    </div>

                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        {tournamentData && tournamentData.game_type === 'ONE_POCKET' && (
                                            <Title
                                                level={2}
                                                style={{
                                                    margin: 0,
                                                    color: 'rgba(255,255,255,0.75)',
                                                    letterSpacing: 0,
                                                    textShadow: '0 2px 5px rgba(0,0,0,0.50)',
                                                    marginRight: 20,
                                                    minWidth: 20,
                                                    textAlign: 'center',
                                                }}
                                            >
                                                {matchData.challenger2_points || 0} B
                                            </Title>
                                        )}

                                        <Title
                                            level={2}
                                            style={{
                                                margin: 0,
                                                color: '#fff',
                                                letterSpacing: 0,
                                                textShadow: '0 2px 5px rgba(0,0,0,0.50)',
                                                minWidth: 20,
                                                textAlign: 'center',
                                            }}
                                        >
                                            {matchData.challenger2_score || 0}
                                        </Title>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} align="right">
                        <img src={dpLogoWatermarkColor} alt="Digital Pool Logo" width={80} />
                    </Col>
                </Row>
                <Row gutter={16} type="flex" justify="center" style={{ marginBottom: 100 }}>
                    <Col xs={24}>
                        <Row gutter={4} type="flex" justify="center">
                            <Col>
                                <div style={{ transform: 'scale(1.2)' }}>
                                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
                                        <Avatar
                                            size={35}
                                            src={getGameType(tournamentData.game_type)}
                                            style={{
                                                marginLeft: 20,
                                                marginRight: 10,
                                                boxShadow: '0 2px 5px rgba(0,0,0,0.50)',
                                                backgroundColor: '#fff',
                                            }}
                                        />
                                        <Title
                                            level={2}
                                            style={{
                                                margin: 0,
                                                color: '#fff',
                                                letterSpacing: 0,
                                                textShadow: '0 2px 5px rgba(0,0,0,0.50)',
                                            }}
                                        >
                                            Rack Tracker
                                        </Title>
                                    </div>
                                    <div>
                                        <div
                                            style={{
                                                // display: 'flex',

                                                background: 'linear-gradient(180deg, #0F141A 0%, #495C75 100%)',
                                                borderRadius: 20,
                                                // alignItems: 'center',
                                                // justifyContent: 'center',
                                                // flexDirection: 'column',
                                                paddingTop: 20,
                                                paddingLeft: 20,
                                                paddingRight: 20,
                                                paddingBottom: 10,
                                                filter: 'drop-shadow(0px 20px 10px rgba(0,0,0,0.2))',
                                            }}
                                        >
                                            <RackTracker matchData={matchData} tournamentData={tournamentData} />
                                        </div>
                                    </div>
                                    <Row gutter={4} type="flex" justify="center">
                                        <Col xs={12} sm={12}>
                                            <Title
                                                level={3}
                                                style={{
                                                    marginLeft: 20,
                                                    marginTop: 0,
                                                    marginBottom: 0,
                                                    color: '#fff',
                                                    letterSpacing: 0,
                                                    textShadow: '0 2px 5px rgba(0,0,0,0.50)',
                                                }}
                                            >
                                                Match {matchData.match_number}
                                            </Title>
                                        </Col>
                                        <div style={{ position: 'absolute' }}>
                                            <Title
                                                level={4}
                                                style={{
                                                    marginTop: 10,
                                                    marginBottom: 0,
                                                    color: '#fff',
                                                    letterSpacing: 0,
                                                    opacity: 0.8,
                                                    textShadow: '0 2px 5px rgba(0,0,0,0.50)',
                                                }}
                                            >
                                                {tournamentData.name}
                                            </Title>
                                        </div>
                                        <Col xs={12} sm={12} align="right">
                                            <Title
                                                level={3}
                                                style={{
                                                    marginRight: 20,
                                                    marginTop: 0,
                                                    marginBottom: 0,
                                                    color: '#fff',
                                                    letterSpacing: 0,
                                                    textShadow: '0 2px 5px rgba(0,0,0,0.50)',
                                                }}
                                            >
                                                Race to{' '}
                                                {matchData.challenger1_race_to && matchData.challenger2_race_to
                                                    ? `${challenger1RaceTo}/${challenger2RaceTo}`
                                                    : matchData.tournament && matchData.tournament.winners_race_to}
                                            </Title>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default withRouter(TVRackTracker);
