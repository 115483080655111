import React, { useState, useRef } from 'react';
import { Row, Col, Typography, Statistic } from 'antd';
import Card from '../../../components/Card';
import { Line, Circle } from 'rc-progress';
import SmallStat from '../../../components/SmallStat';
import CountUp from 'react-countup';
// import moment from 'moment';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import SectionContent from '../../../components/SectionContent';
import SectionTitle from '../../../components/SectionTitle';
// import borderRadius from 'highcharts-border-radius';
// borderRadius(Highcharts);

const { Text } = Typography;

const styles = {
	row: {
		display: 'flex',
		flexGrow: 1
	}
};

Highcharts.setOptions({
	global: {
		useUTC: false
	}
});
const defaultWidth = 3;
export default function Stats(props) {
	const chartRef = useRef();
	const optionsDivision = {
		colors: [ '#2196f3', 'rgba(255, 204, 0, 1)', '#44475b' ],
		chart: {
			height: 250,
			type: 'bar',
			animation: false
		},
		title: {
			text: null
		},
		xAxis: {
			categories: [ 'Singles Open 8-Ball', 'Singles Open 9-Ball', 'Singles Open 10-ball' ],
			crosshair: true,
			lineColor: '#C0D0E0',
			lineWidth: 1,
			gridLineColor: '#D8D8D8',
			gridLineDashedStyle: 'solid',
			gridLineWidth: 0
		},
		yAxis: {
			min: 0,
			title: {
				text: null
			},
			tickInterval: 5,
			lineColor: '#C0D0E0',
			lineWidth: 0,
			gridLineColor: '#efefef',
			gridLineDashedStyle: 'solid',
			gridLineWidth: 1
		},
		credits: { enabled: false },
		tooltip: {
			shared: true
		},
		legend: {
			reversed: true
		},
		plotOptions: {
			series: {
				animation: false
				// stacking: 'normal'
			}
		},
		series: [
			{
				name: 'Wins',
				data: [ 5, 3, 4 ],
				borderRadiusTopLeft: 3,
				borderRadiusTopRight: 3
			},
			{
				name: 'Losses',
				data: [ 2, 2, 3 ]
			}
		]
	};

	const optionsDonut = {
		colors: [ '#2196f3', 'rgba(255, 204, 0, 1)', '#44475b' ],
		title: {
			text: null
		},
		chart: {
			height: 250,
			type: 'pie',
			animation: false
		},
		yAxis: {
			title: {
				text: null
			},
			crosshair: true
		},
		credits: { enabled: false },
		tooltip: {
			shared: true
		},
		plotOptions: {
			pie: {
				animation: false,
				shadow: false,
				dataLabels: {
					enabled: true,
					format: '{point.y}',
					style: {
						color: 'black'
					}
				}
			}
		},

		// tooltip: {
		// 	formatter: function() {
		// 		return '<b>' + this.point.name + '</b>: ' + this.y + ' %';
		// 	}
		// },
		series: [
			{
				name: 'Wins',
				// data: [['10-Ball', 120], ['9-Ball', 86], ['8-Ball', 35]],

				data: [
					{
						name: '10-ball',
						y: 120
					},
					{
						name: '9-ball',
						y: 86
					},
					{
						name: '8-ball',
						y: 35
					}
				],
				size: '100%',
				innerSize: '50%',
				showInLegend: true
				// dataLabels: {
				// 	enabled: false
				// }
			}
		]
	};

	const options1 = {
		colors: [ '#2196f3', 'rgba(26, 135, 255, 0.4)', 'rgba(255, 204, 0, 1)', 'rgba(255, 204, 0, 0.4)', '#44475b' ],
		title: {
			text: null
		},
		chart: {
			height: 250,
			panning: false,
			zoomType: 'x',
			animation: false
		},
		legend: {
			align: 'left'
			// symbolWidth: 12,
			// symbolRadius: 6
		},
		credits: { enabled: false },
		tooltip: {
			shared: true
		},
		plotOptions: {
			column: {
				// stacking: 'normal',
				pointPadding: 0.1,
				borderWidth: 0,
				animation: false
				// dataLabels: {
				// 	enabled: true,
				// 	formatter: function() {
				// 		if (this.y > 0) {
				// 			return this.y;
				// 		}
				// 		return null;
				// 	}
				// }
				// borderRadiusTopLeft: 5,
				// borderRadiusTopRight: 5
				// dataLabels: {
				// 	enabled: true,
				// 	color: (Highcharts.theme && Highcharts.theme.dataLabelsColor) || 'white'
				// }
			}
		},
		yAxis: [
			{
				title: {
					text: null
				},
				lineColor: '#C0D0E0',
				lineWidth: 0,
				gridLineColor: '#efefef',
				gridLineDashedStyle: 'solid',
				gridLineWidth: 1
			}
		],
		xAxis: {
			categories: [ 'League', 'Tournament' ],
			crosshair: true,
			lineColor: '#C0D0E0',
			lineWidth: 1,
			gridLineColor: '#D8D8D8',
			gridLineDashedStyle: 'solid',
			gridLineWidth: 0
		},
		series: [
			{
				name: 'Matches won',
				type: 'column',
				data: [ 120, 120 ],
				stack: 'league',
				borderRadiusTopLeft: 3,
				borderRadiusTopRight: 3
			},
			{
				name: 'Matches lost',
				type: 'column',
				data: [ 82, 82 ],
				stack: 'league'
				// borderRadiusTopLeft: 3,
				// borderRadiusTopRight: 3
			},
			{
				name: 'Games won',
				type: 'column',
				data: [ 120, 120 ],
				stack: 'tournament',
				borderRadiusTopLeft: 3,
				borderRadiusTopRight: 3
			},
			{
				name: 'Games lost',
				type: 'column',
				data: [ 82, 82 ],
				stack: 'tournament'
				// borderRadiusTopLeft: 3,
				// borderRadiusTopRight: 3
			}
		]
	};

	const options2 = {
		colors: [ '#2196f3', 'rgba(26, 135, 255, 0.4)', 'rgba(255, 204, 0, 1)', 'rgba(255, 204, 0, 0.4)', '#44475b' ],
		title: {
			text: null
		},
		chart: {
			height: 300,
			panning: false,
			zoomType: 'x',
			animation: false
		},
		legend: {
			align: 'left'
		},
		credits: { enabled: false },
		tooltip: {
			shared: true
		},
		plotOptions: {
			column: {
				// stacking: 'normal',
				pointPadding: 0.1,
				borderWidth: 0,
				animation: false
				// borderRadiusTopLeft: 5,
				// borderRadiusTopRight: 5
				// dataLabels: {
				// 	enabled: true,
				// 	color: (Highcharts.theme && Highcharts.theme.dataLabelsColor) || 'white'
				// }
			}
		},
		yAxis: [
			{
				title: {
					// text: 'Matches and Games played'
					text: null
				},
				lineColor: '#C0D0E0',
				lineWidth: 0,
				gridLineColor: '#efefef',
				gridLineDashedStyle: 'solid',
				gridLineWidth: 1
			}
			// {
			// 	title: {
			// 		text: 'Game wins'
			// 	},
			// 	opposite: true
			// }
		],
		xAxis: {
			categories: [ 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec' ],
			crosshair: true,
			// type: 'datetime',
			// labels: {
			// 	formatter: function() {
			// 		return moment(this.value).format('MMM');
			// 	}
			// },
			// minTickInterval: moment.duration(1, 'month').asMilliseconds(),
			// crosshair: true,
			lineColor: '#C0D0E0',
			lineWidth: 1,
			gridLineColor: '#D8D8D8',
			gridLineDashedStyle: 'solid',
			gridLineWidth: 0
		},
		series: [
			{
				name: 'Matches won',
				type: 'column',
				data: [ 10, 5, 8, 2, 10, 3, 6, 8, 4, 3, 7, 4 ],
				// data: [
				// 	[moment('2014-01-01').valueOf(), 10],
				// 	[moment('2014-02-01').valueOf(), 5],
				// 	[moment('2014-03-01').valueOf(), 8],
				// 	[moment('2014-04-01').valueOf(), 2],
				// 	[moment('2014-05-01').valueOf(), 10],
				// 	[moment('2014-06-01').valueOf(), 3],
				// 	[moment('2014-07-01').valueOf(), 6],
				// 	[moment('2014-08-01').valueOf(), 8],
				// 	[moment('2014-09-01').valueOf(), 4],
				// 	[moment('2014-10-01').valueOf(), 3],
				// 	[moment('2014-11-01').valueOf(), 7],
				// 	[moment('2014-12-01').valueOf(), 4]
				// ],
				stack: 'matches',
				borderRadiusTopLeft: 3,
				borderRadiusTopRight: 3
			},
			{
				name: 'Matches lost',
				type: 'column',
				data: [ 0, 5, 2, 8, 0, 8, 4, 2, 6, 7, 3, 6 ],
				// data: [
				// 	[moment('2014-01-01').valueOf(), 0],
				// 	[moment('2014-02-01').valueOf(), 5],
				// 	[moment('2014-03-01').valueOf(), 2],
				// 	[moment('2014-04-01').valueOf(), 8],
				// 	[moment('2014-05-01').valueOf(), 0],
				// 	[moment('2014-06-01').valueOf(), 8],
				// 	[moment('2014-07-01').valueOf(), 4],
				// 	[moment('2014-08-01').valueOf(), 2],
				// 	[moment('2014-09-01').valueOf(), 6],
				// 	[moment('2014-10-01').valueOf(), 7],
				// 	[moment('2014-11-01').valueOf(), 3],
				// 	[moment('2014-12-01').valueOf(), 6]
				// ],
				stack: 'matches'
			},
			{
				name: 'Games won',
				type: 'column',
				data: [ 25, 18, 10, 7, 30, 18, 21, 13, 17, 8, 14, 23 ],
				// data: [
				// 	[moment('2014-01-01').valueOf(), 25],
				// 	[moment('2014-02-01').valueOf(), 18],
				// 	[moment('2014-03-01').valueOf(), 10],
				// 	[moment('2014-04-01').valueOf(), 7],
				// 	[moment('2014-05-01').valueOf(), 30],
				// 	[moment('2014-06-01').valueOf(), 18],
				// 	[moment('2014-07-01').valueOf(), 21],
				// 	[moment('2014-08-01').valueOf(), 13],
				// 	[moment('2014-09-01').valueOf(), 17],
				// 	[moment('2014-10-01').valueOf(), 8],
				// 	[moment('2014-11-01').valueOf(), 14],
				// 	[moment('2014-12-01').valueOf(), 23]
				// ],
				stack: 'games',
				borderRadiusTopLeft: 3,
				borderRadiusTopRight: 3
			},
			{
				name: 'Games lost',
				type: 'column',
				data: [ 7, 10, 7, 12, 9, 12, 8, 15, 10, 19, 11, 9 ],
				// data: [
				// 	[moment('2014-01-01').valueOf(), 7],
				// 	[moment('2014-02-01').valueOf(), 10],
				// 	[moment('2014-03-01').valueOf(), 7],
				// 	[moment('2014-04-01').valueOf(), 12],
				// 	[moment('2014-05-01').valueOf(), 9],
				// 	[moment('2014-06-01').valueOf(), 12],
				// 	[moment('2014-07-01').valueOf(), 8],
				// 	[moment('2014-08-01').valueOf(), 15],
				// 	[moment('2014-09-01').valueOf(), 10],
				// 	[moment('2014-10-01').valueOf(), 19],
				// 	[moment('2014-11-01').valueOf(), 11],
				// 	[moment('2014-12-01').valueOf(), 9]
				// ],
				stack: 'games'
			}
		]
	};

	return (
		<div style={{ background: 'linear-gradient(#eaedef, #fafafa)' }}>
			<SectionTitle
				title="Statistics"
				titleStyle={{ color: '#fff', margin: 0, textTransform: 'uppercase' }}
				height={120}
			/>
			<SectionContent padding="20px 40px">
				<Row gutter={18} justify="center" type="flex">
					<Col xs={24}>
						<Card title="Wins and losses by month for 2018">
							<HighchartsReact
								highcharts={Highcharts}
								options={options2}
								containerProps={{ className: 'winsLossesContainer' }}
								ref={chartRef}
							/>
						</Card>
					</Col>

					<Col xs={24} sm={8}>
						<Card title="Totals for 2018">
							<HighchartsReact
								highcharts={Highcharts}
								options={options1}
								containerProps={{ className: 'totalStatsContainer' }}
								ref={chartRef}
							/>
						</Card>
					</Col>

					<Col xs={24} sm={16}>
						<Card title="Win percentages for 2018">
							<Row gutter={24}>
								<Col xs={12} sm={12} md={6}>
									<SmallStat
										title="League Matches"
										winPercentage={65}
										trailColor="rgba(26, 135, 255, 0.2)"
										strokeColor="#2196f3"
										centered
									/>
								</Col>
								<Col xs={12} sm={12} md={6}>
									<SmallStat
										title="League Games"
										winPercentage={72}
										trailColor="#fff6cf"
										strokeColor="#ffcd00"
										centered
									/>
								</Col>
								<Col xs={12} sm={12} md={6}>
									<SmallStat
										title="Tournament Matches"
										winPercentage={65}
										trailColor="rgba(26, 135, 255, 0.2)"
										strokeColor="#2196f3"
										centered
									/>
								</Col>
								<Col xs={12} sm={12} md={6}>
									<SmallStat
										title="Tournament Games"
										winPercentage={72}
										trailColor="#fff6cf"
										strokeColor="#ffcd00"
										centered
									/>
								</Col>
							</Row>
						</Card>
					</Col>

					<Col xs={24} sm={8}>
						<Card title="Totals by division type">
							<HighchartsReact
								highcharts={Highcharts}
								options={optionsDivision}
								containerProps={{ className: 'divisionStatsContainer' }}
								ref={chartRef}
							/>
						</Card>
					</Col>

					<Col xs={24} sm={8}>
						<Card title="Total wins by game type">
							<HighchartsReact
								highcharts={Highcharts}
								options={optionsDonut}
								containerProps={{ className: 'gameStatsContainer' }}
								ref={chartRef}
							/>
						</Card>
					</Col>

					<Col xs={24} sm={8}>
						<Card title="Performance stats">
							<Row gutter={14} type="flex" justify="center">
								<Col xs={12} sm={12} md={12} lg={12}>
									<div>
										<Statistic title="Break and runs" value={12} />
										<Line
											percent="10"
											strokeWidth={defaultWidth}
											trailWidth={defaultWidth}
											trailColor="rgba(26, 135, 255, 0.2)"
											strokeColor="#2196f3"
										/>
									</div>
									<div style={{ marginTop: 10 }}>
										<Statistic title="On the snaps" value={24} />
										<Line
											percent="18"
											strokeWidth={defaultWidth}
											trailWidth={defaultWidth}
											trailColor="rgba(26, 135, 255, 0.2)"
											strokeColor="#2196f3"
										/>
									</div>
								</Col>
								<Col xs={12} sm={12} md={12} lg={12}>
									<div>
										<Statistic title="Clean sweeps" value={7} />
										<Line
											percent="7"
											strokeWidth={defaultWidth}
											trailWidth={defaultWidth}
											trailColor="rgba(26, 135, 255, 0.2)"
											strokeColor="#2196f3"
										/>
									</div>
									<div style={{ marginTop: 10 }}>
										<Statistic title="Consecutive wins" value={5} />
										<Line
											percent="2"
											strokeWidth={defaultWidth}
											trailWidth={defaultWidth}
											trailColor="rgba(26, 135, 255, 0.2)"
											strokeColor="#2196f3"
										/>
									</div>
								</Col>
							</Row>
						</Card>
					</Col>
				</Row>
			</SectionContent>
		</div>
	);
}
