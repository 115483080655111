import React, { useState, useRef } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { Modal, Button, message } from 'antd';
import { Formik } from 'formik';
import { Form, FormItem, Input, Radio, Select, SubmitButton, ResetButton, AutoComplete } from 'formik-antd';
import * as Yup from 'yup';
import { payoutsModalVisible, payoutsRowSelectedId, payoutsInitialFormValues, payoutsSubmitButtonText, isNewPayoutsEntry } from '../../data/atoms';
import { INSERT_GHOST_GAMES_PAYOUTS_ENTRY, UPDATE_GHOST_GAMES_PAYOUTS_RECORD } from '../../data/mutations';
import { GET_GHOST_GAMES_PAYOUTS, CHECK_GHOST_GAMES_PAYOUTS_DUPLICATE_PLACE } from '../../data/queries';

export default function PayoutsModal(props) {
    const { client, ghostEventId } = props;

    const modalVisibleValue = useRecoilValue(payoutsModalVisible);
    const payoutsInitialFormValuesValue = useRecoilValue(payoutsInitialFormValues);
    const payoutsRowSelectedIdValue = useRecoilValue(payoutsRowSelectedId);
    const setModalVisibleValue = useSetRecoilState(payoutsModalVisible);
    const payoutsSubmitButtonTextValue = useRecoilValue(payoutsSubmitButtonText);
    const isNewPayoutsEntryValue = useRecoilValue(isNewPayoutsEntry);

    const initialFormValues = payoutsInitialFormValuesValue;

    const formRef = useRef();
    var initialValues = {};

    const schema = Yup.object().shape({
        place: Yup.string()
            .required('Required')
            .matches(/[0-9|\-]+/, 'The only characters allowed are numbers and dashes'),
        amount: Yup.string()
            .required('Required')
            .matches(/[0-9]+/, 'Only numbers are allowed'),
    });

    function onOk() {
        formRef.current.handleSubmit();
    }

    function onCancel() {
        setModalVisibleValue(false);
    }

    return (
        <Modal
            onOk={onOk}
            onCancel={onCancel}
            visible={modalVisibleValue}
            title="Add Payout"
            destroyOnClose={true}
            transitionName="fade"
            maskTransitionName="none"
            footer={[
                <Button key="cancel" onClick={onCancel}>
                    Cancel
                </Button>,
                <Button key="submit" onClick={onOk} type="primary">
                    {payoutsSubmitButtonTextValue}
                </Button>,
            ]}
        >
            <Formik
                ref={formRef}
                initialValues={initialFormValues}
                validationSchema={schema}
                onSubmit={async (values) => {
                    if (isNewPayoutsEntryValue) {
                        if (values.place.includes('-')) {
                            const placeSplit = values.place.split('-');
                            const place1 = placeSplit[0];
                            const place2 = placeSplit[1];
                        }
                        const check = await client.mutate({
                            mutation: CHECK_GHOST_GAMES_PAYOUTS_DUPLICATE_PLACE,
                            variables: {
                                ghost_games_id: ghostEventId,
                                place: values.place,
                            },
                        });

                        console.log('check', check);

                        if (check && check.data && check.data.ghost_games_payouts && check.data.ghost_games_payouts.length) {
                            message.error('Duplicate place found');
                            return;
                        }

                        const res = await client.mutate({
                            mutation: INSERT_GHOST_GAMES_PAYOUTS_ENTRY,
                            variables: {
                                objects: {
                                    ghost_games_id: ghostEventId,
                                    place: values.place,
                                    amount: values.amount,
                                },
                            },
                            notifyOnNetworkStatusChange: true,
                            awaitRefetchQueries: true,
                            refetchQueries: [
                                {
                                    query: GET_GHOST_GAMES_PAYOUTS,
                                    variables: { ghost_games_id: ghostEventId },
                                },
                            ],
                        });
                    } else {
                        await client.mutate({
                            mutation: UPDATE_GHOST_GAMES_PAYOUTS_RECORD,
                            variables: {
                                id: values.id,
                                changes: {
                                    place: values.place,
                                    amount: values.amount,
                                },
                            },
                            notifyOnNetworkStatusChange: true,
                            awaitRefetchQueries: true,
                            refetchQueries: [
                                {
                                    query: GET_GHOST_GAMES_PAYOUTS,
                                    variables: { ghost_games_id: ghostEventId },
                                },
                            ],
                        });
                    }

                    setModalVisibleValue(false);
                }}
                render={({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => {
                    return (
                        <Form onSubmit={handleSubmit} layout="vertical" style={{ maxWidth: 6000 }}>
                            <FormItem label="Place" name="place" required hasFeedback showValidateSuccess>
                                <Input name="place" placeholder="Place" />
                            </FormItem>
                            <FormItem label="Amount" name="amount" required hasFeedback showValidateSuccess>
                                <Input name="amount" placeholder="Amount" />
                            </FormItem>
                        </Form>
                    );
                }}
            />
        </Modal>
    );
}
