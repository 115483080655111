import React, { useState } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { Breadcrumb, Typography } from 'antd';
import dpIcon from '../assets/img/dp-icon.png';
import _ from 'lodash';
import moment from 'moment';

const { Text } = Typography;

function BreadcrumbContainer(props) {
	const { location } = props;
	const pathSnippets = location.pathname.split('/').filter((i) => i);
	const extraBreadcrumbItems = pathSnippets.map((path, index) => {
		const name = path === 'dpl' ? 'DPL' : _.startCase(_.toLower(path.replace('-', ' ')));
		const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;

		return (
			<Breadcrumb.Item key={url}>
				<Link to={url}>{name}</Link>
			</Breadcrumb.Item>
		);
	});
	const breadcrumbItems = [
		<Breadcrumb.Item key="home">
			<Link to="/">DigitalPool</Link>
		</Breadcrumb.Item>
	].concat(extraBreadcrumbItems);

	return (
		<div
			style={{
				// marginLeft: 20,
				// padding: '12px 40px',
				width: '100%',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'space-between'
			}}
		>
			<Breadcrumb
				className={
					props.theme === 'dark' ? (
						'dark-breadcrumb'
					) : props.theme === 'light' ? (
						'light-breadcrumb'
					) : props.theme === 'orange' ? (
						'orange-breadcrumb'
					) : null
				}
			>
				{breadcrumbItems}
			</Breadcrumb>
			{/* <Text style={{ color: '#fff' }}>{moment().format('ll')}</Text> */}
			{props.children}
		</div>
	);
}

export default withRouter(BreadcrumbContainer);
