import React, { useEffect, useState } from 'react';
import { Skeleton, Table } from 'antd';
import CircularLoader from '../../../../components/CircularLoader';
import CircleFlag from '../../../../components/CircleFlag';
import { Query } from '@apollo/react-components';
import { GET_GHOST_GAMES_RESULTS, GET_GHOST_EVENT_PLAYES_QUERY } from '../../data/queries';
import { ghostEventId } from '../../data/atoms';

export default function ResultsTable(props) {
    const { ghostEvent, client } = props;
    const [tableDataSource, setTableDataSource] = useState([]);
    const [tableColumns, setTableColumns] = useState([]);

    useEffect(() => {
        client
            .query({
                query: GET_GHOST_GAMES_RESULTS,
                variables: {
                    id: ghostEvent.id,
                },
            })
            .then((res) => {
                let ordered = [];
                let arr, tmp, num1, num2;

                const columns = [
                    {
                        title: 'Place',
                        dataIndex: 'place',
                        key: 'place',
                    },
                    {
                        title: 'Name',
                        dataIndex: 'name',
                        key: 'name',
                        sorter: (a, b) => {
                            return a.name && a.name.localeCompare(b.name);
                        },
                        render: (text, record) => {
                            return (
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    {record.country && <CircleFlag country={record.country} left={0} />}

                                    <span style={{ marginLeft: record.country ? 10 : 0 }}>{text}</span>
                                </div>
                            );
                        },
                    },
                    {
                        title: 'Total Points',
                        dataIndex: 'total_points',
                        key: 'total_points',
                    },
                ];

                var data = res && res.data && res.data.ghost_games && res.data.ghost_games[0] && res.data.ghost_games[0].ghost_games_players;
                const attempts = res && res.data && res.data.ghost_games && res.data.ghost_games[0] && res.data.ghost_games[0].number_of_attempts;

                if (!data) {
                    return [];
                }

                for (let i = 0; i < data.length; i++) {
                    num1 = data[i].ghost_games_rounds_aggregate.aggregate.sum.points;

                    for (let j = 0; j < data.length; j++) {
                        num2 = data[j].ghost_games_rounds_aggregate.aggregate.sum.points;

                        if (num1 >= num2) {
                            tmp = data[i];
                            data[i] = data[j];
                            data[j] = tmp;
                        }
                    }
                }

                let newCol, attemptNum;

                for (let i = 0; i < attempts; i++) {
                    attemptNum = i + 1;

                    newCol = {
                        title: `Attempt ${attemptNum}`,
                        dataIndex: `attempt_${attemptNum}`,
                        key: `attempt_${attemptNum}`,
                    };

                    columns.splice(2 + i, 0, newCol);
                }

                var rackScore = 0,
                    forwardAttempt = 0,
                    place,
                    oldPoints = 0,
                    totalPoints,
                    oldPlace = 1;

                console.log('data', data);

                const tableData = data.map((val, key) => {
                    totalPoints = val.ghost_games_rounds_aggregate.aggregate.sum.points;
                    place = key + 1;

                    var tableObj = {
                        key: key,
                        place: oldPlace,
                        name: val.user.first_name ? `${val.user.first_name} ${val.user.last_name}` : val.user.display_name,
                        total_points: val.ghost_games_rounds_aggregate.aggregate.sum.points,
                        country: val.user.country,
                    };

                    if (data[key + 1] !== undefined) {
                        if (data[key + 1].ghost_games_rounds_aggregate.aggregate.sum.points === data[key].ghost_games_rounds_aggregate.aggregate.sum.points) {
                            oldPlace = place;
                        } else {
                            oldPlace = oldPlace + 1;
                        }
                    }

                    let roundsLength = val.ghost_games_rounds.length;

                    for (let i = 0; i < roundsLength; i++) {
                        let round = val.ghost_games_rounds[i];

                        forwardAttempt = val.ghost_games_rounds[i + 1] !== undefined ? val.ghost_games_rounds[i + 1] : 0;
                        rackScore = rackScore + round.points;

                        if (round.attempt !== forwardAttempt.attempt) {
                            tableObj[`attempt_${round.attempt}`] = rackScore;
                            rackScore = 0;
                        }
                    }

                    return tableObj;
                });

                setTableDataSource(tableData);
                setTableColumns(columns);
            });
    }, []);

    return <Table columns={tableColumns} dataSource={tableDataSource} pagination={false} />;
}
