import { isoCountries, isoCountries3 } from './isoCountryCodes';

export function getCountryName(str) {
    const countryStr = str && str.toLowerCase().trim();
    const countryNameISO2 =
        countryStr &&
        Object.entries(isoCountries)
            .map(([key, value]) => {
                return {
                    name: key,
                    value: value.toLowerCase(),
                };
            })
            .filter((item, index) => {
                return item.value === countryStr || item.name === countryStr;
            })[0];

    const countryNameISO3 =
        countryStr &&
        Object.entries(isoCountries3)
            .map(([key, value]) => {
                return {
                    name: key,
                    value: value.toLowerCase(),
                };
            })
            .filter((item, index) => {
                return item.value === countryStr || item.name === countryStr;
            })[0];

    let country;
    if (countryNameISO2 && countryNameISO2.value) {
        // console.log('countryNameISO2', countryNameISO2);
        country = countryNameISO2.name;
        // console.log('country', country);
    } else if (countryNameISO3 && countryNameISO3.value) {
        // console.log('countryNameISO3', countryNameISO3);
        country = countryNameISO3.name;
        // console.log('country', country);
    }
    return country;
}
