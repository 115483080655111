import React, { useState, useEffect } from 'react';
import { Link, withRouter, Route, Switch } from 'react-router-dom';
import { Layout, Tabs, Icon, Button } from 'antd';
import SectionContent from '../../components/SectionContent';
import SectionTitle from '../../components/SectionTitle';
import MyLeagues from './leagues/MyLeagues';
import AllLeagues from './leagues/AllLeagues';
import SearchFilterBar from './leagues/SearchFilterBar';
import useBreakpoint from 'use-breakpoint';
import { defaultBreakpoints } from '../../utils/defaultBreakpoints';
import * as routes from '../../config/routes';
import _ from 'lodash';

const { TabPane } = Tabs;

const styles = {
    headerImg: {
        background: `linear-gradient(-90deg, #002eaf, #008dd0) 100% 100% no-repeat`,
        // background: `linear-gradient(0deg, rgba(0,0,0,0.2), rgba(0,0,0, 0.2)), url(${blueBg})`,
        // backgroundAttachment: 'fixed',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100%',
        // backgroundPosition: '0px -400px'

        // backgroundPosition: '0px -40px'
    },
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        // height: '100%'
    },
};

function Leagues(props) {
    const { history, authState } = props;
    const urlPath = window.location.pathname;
    const params = props.match.params;
    const slug = params.slug;
    const splitPath = urlPath.split('/');
    const dpIncludes = ['my-leagues', 'all-leagues'];
    const [current, setCurrent] = useState();
    const { breakpoint, maxWidth, minWidth } = useBreakpoint(defaultBreakpoints);

    const handleTabClick = (tab) => {
        setCurrent(tab);
        props.history.push(`/leagues/${tab}`);
    };

    useEffect(() => {
        let pathMatch = _.includes(dpIncludes, splitPath[splitPath.length - 1])
            ? splitPath[splitPath.length - 1]
            : _.includes(dpIncludes, splitPath[splitPath.length - 2])
            ? splitPath[splitPath.length - 2]
            : _.includes(dpIncludes, splitPath[splitPath.length - 3])
            ? splitPath[splitPath.length - 3]
            : 'all-leagues';

        setCurrent(pathMatch);
    }, [urlPath, dpIncludes, splitPath, params]);

    return (
        <Layout className="leagues-page">
            <div style={styles.headerImg}>
                <SectionTitle title={props.title} height={breakpoint === 'mobile' ? 80 : 100}>
                    <Link to={authState && authState.user && authState.user.id ? '/league-manager/new' : 'login'}>
                        {breakpoint === 'mobile' ? (
                            <Button size="large" type="ghost" ghost>
                                <Icon type="plus" />
                            </Button>
                        ) : (
                            <Button size="large" type="ghost" ghost>
                                Create League
                                <Icon type="arrow-right" />
                            </Button>
                        )}
                    </Link>
                </SectionTitle>

                {authState && authState.user && authState.user.id ? (
                    <Tabs
                        defaultActiveKey={current}
                        activeKey={current}
                        animated={{ inkBar: true, tabPane: false }}
                        // renderTabBar={() => <ScrollableInkTabBar />}
                        // renderTabContent={() => <TabContent />}
                        onTabClick={handleTabClick}
                        className="tabs-bg"
                        tabPosition="top"
                        tabBarStyle={{
                            textTransform: 'uppercase',
                            paddingLeft: 24,
                            paddingRight: 24,
                            paddingTop: 0,
                            // backgroundColor: '#132339',
                            boxShadow: 'rgba(0, 0, 0, 0.2) 0px 1px 15px 0px',
                            color: '#fff',
                            zIndex: 1,
                            position: 'relative',
                            borderBottom: 'none',
                            borderTop: '1px solid rgba(255,255,255,0.1)',
                        }}
                    >
                        <TabPane tab="All Leagues" key="all-leagues" style={styles.tab}>
                            <Switch>
                                <Route path={routes.DIGITALPOOL.ALL_LEAGUES} render={(matchProps) => <AllLeagues {...matchProps} {...props} />} />
                                <Route path={routes.DIGITALPOOL.LEAGUES} render={(matchProps) => <AllLeagues {...matchProps} {...props} />} />
                            </Switch>
                        </TabPane>
                        <TabPane tab="My Leagues" key="my-leagues" style={styles.tab}>
                            <Switch>
                                <Route path={routes.DIGITALPOOL.MY_LEAGUES} render={(matchProps) => <MyLeagues {...matchProps} {...props} />} />
                            </Switch>
                        </TabPane>
                    </Tabs>
                ) : (
                    <AllLeagues {...props} />
                )}
            </div>
        </Layout>
    );
}

export default withRouter(Leagues);
