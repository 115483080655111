import React, { useState } from 'react';
import { Table, Typography, Button, Modal, Icon, Tooltip } from 'antd';
import poolTableImg from '../../../assets/img/table/table-blue.png';
import poolTableStreamingImg from '../../../assets/img/table/table-red.png';

const { Text } = Typography;
const { confirm } = Modal;

export default function TableList(props) {
    const { onSelected, onEditSelected, onDelete, size } = props;

    const columns = [
        // {
        // 	title: '#',
        // 	dataIndex: 'index',
        // 	width: 60,

        // 	sorter: (a, b) => {
        // 		return a.index - b.index;
        // 	}
        // },
        {
            title: 'Label',
            dataIndex: 'label',
            defaultSortOrder: 'ascend',
            sorter: (a, b) => {
                return a.label.localeCompare(b.label, undefined, {
                    numeric: true,
                    sensitivity: 'base',
                });
            },
            render: (text, record) => {
                return (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img src={record.is_streaming_table === true ? poolTableStreamingImg : poolTableImg} alt="" width={30} />
                        <Text style={{ marginLeft: 10, fontWeight: 600 }}>{text || '-'}</Text>
                    </div>
                );
            },
        },

        {
            title: 'Size',
            dataIndex: 'size',
            sorter: (a, b) => {
                return a.size.localeCompare(b.size, undefined, {
                    numeric: true,
                    sensitivity: 'base',
                });
            },
            render: (text, record) => <Text>{text || '-'}</Text>,
        },
        {
            title: 'Make',
            dataIndex: 'make',
            sorter: (a, b) => {
                return a.make.localeCompare(b.make, undefined, {
                    numeric: true,
                    sensitivity: 'base',
                });
            },
            render: (text, record) => <Text>{text || '-'}</Text>,
        },
        {
            title: 'Streaming Table',
            dataIndex: 'is_streaming_table',
            sorter: (a, b) => a.is_streaming_table - b.is_streaming_table,
            render: (text, record) => <React.Fragment>{record.is_streaming_table === true ? 'Yes' : 'No'}</React.Fragment>,
        },
        // {
        // 	title: 'Featured Table',
        // 	dataIndex: 'is_featured_table',
        // 	sorter: (a, b) => a.is_featured_table - b.is_featured_table,
        // 	render: (text, record) => (
        // 		<React.Fragment>{record.is_featured_table === true ? 'Yes' : 'No'}</React.Fragment>
        // 	)
        // },
        // {
        // 	title: 'Status',
        // 	dataIndex: 'status',
        // 	sorter: (a, b) => a.status - b.status,
        // 	render: (text, record) => <Text>{text || '-'}</Text>
        // },
        {
            title: 'Action',
            key: 'action',
            width: 200,
            render: (text, record) => (
                <span style={{ whiteSpace: 'nowrap' }}>
                    <Tooltip title="Edit">
                        <Button
                            type="ghost"
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                onEditSelected(record.id);
                                // props.history.push(
                                // 	`/tournament-builder/${props.tournament.slug}/venues/${record.slug}/edit`
                                // );
                            }}
                        >
                            <Icon type="edit" /> Edit
                        </Button>
                    </Tooltip>
                    {/* <Divider type="vertical" /> */}
                    <Tooltip title="Delete">
                        <Button
                            type="danger"
                            ghost
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                showConfirm(record);
                            }}
                        >
                            <Icon type="delete" /> Delete
                        </Button>
                    </Tooltip>
                </span>
            ),
        },
    ];

    function onChange(pagination, filters, sorter) {
        console.log('params', pagination, filters, sorter);
    }

    function showConfirm(record) {
        confirm({
            title: `Are you sure you want to remove ${record.label} from this venue?`,
            centered: true,
            transitionName: 'fade',
            maskTransitionName: 'none',
            // onOk() {
            // 	return new Promise((resolve, reject) => {
            // 		setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
            // 	}).catch(() => console.log('Oops errors!'));
            // },
            onOk() {
                onDelete(record);
            },
            onCancel() {},
        });
    }

    return (
        <Table
            onRow={(record, rowIndex) => {
                return {
                    // onClick: (event) => {
                    // 	console.log(record, rowIndex);
                    // 	props.history.push({
                    // 		pathname: `/tournament-builder/${props.tournament.slug}/venues/${record.slug}`,
                    // 		// search: '?query=abc',
                    // 		state: { data: record }
                    // 	});
                    // },
                    onDoubleClick: (event) => {}, // double click row
                    onContextMenu: (event) => {}, // right button click row
                    onMouseEnter: (event) => {}, // mouse enter row
                    onMouseLeave: (event) => {}, // mouse leave row
                };
            }}
            className="noselect"
            columns={columns}
            dataSource={props.tables}
            onChange={onChange}
            pagination={false}
            tableLayout="auto"
            scroll={{ x: 400 }}
            size={size ? size : 'default'}
            bordered={false}
        />
    );
}
