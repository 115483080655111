import React, { useState } from 'react';
import { Icon, Row, Col, Collapse, Typography, Button, Input, Tabs, Empty } from 'antd';
import Card from '../../components/Card';
import { Link } from 'react-router-dom';
import SectionTitle from '../../components/SectionTitle';
import SectionContent from '../../components/SectionContent';

const { Text } = Typography;
const { Panel } = Collapse;

const styles = {
	row: {
		display: 'flex',
		flexGrow: 1
	},
	tab: {
		paddingTop: 5,
		paddingLeft: 24,
		paddingRight: 24,
		paddingBottom: 24
	}
};

const text = (
	<p style={{ paddingLeft: 24 }}>
		A dog is a type of domesticated animal. Known for its loyalty and faithfulness, it can be found as a welcome
		guest in many households across the world.
	</p>
);

export default function DPLFaq(props) {
	const [ activeKey, setActiveKey ] = useState('1');

	const handleExpandAll = () => {
		if (activeKey && activeKey.length >= 3) {
			setActiveKey([]);
		} else {
			setActiveKey([ '1', '2', '3' ]);
		}
	};

	const handleClicked = (clicked) => {
		setActiveKey(clicked);
	};

	return (
		<div style={{ background: 'linear-gradient(#eaedef, #fafafa)' }}>
			<SectionTitle
				title={props.title}
				titleStyle={{ color: '#fff', margin: 0, textTransform: 'uppercase' }}
				height={120}
			/>
			<SectionContent padding="20px 40px">
				<Row gutter={16} justify="center" type="flex">
					<Col xs={24}>
						<Card title="All Questions" bodyStyle={{ padding: 0 }}>
							<Collapse
								bordered={false}
								activeKey={activeKey}
								defaultActiveKey={[ '1' ]}
								onChange={handleClicked}
							>
								<Panel header="This is panel header 1" key="1">
									{text}
								</Panel>
								<Panel header="This is panel header 2" key="2">
									{text}
								</Panel>
								<Panel header="This is panel header 3" key="3">
									{text}
								</Panel>
							</Collapse>
							<div style={{ marginLeft: 10, marginRight: 10, marginTop: 10, marginBottom: 10 }}>
								<Button type="link" onClick={handleExpandAll}>
									{activeKey && activeKey.length >= 3 ? 'Collapse all' : 'Expand all'}
								</Button>
							</div>
						</Card>
					</Col>
				</Row>
			</SectionContent>
		</div>
	);
}
