export function seedingChart(totalPlayers) {
    let seedCharts = [];
    seedCharts[2] = [1, 2];
    seedCharts[4] = [1, 4, 24, 3];
    seedCharts[8] = [8, 4, 6, 2];
    seedCharts[16] = [1, 16, 8, 9, 4, 13, 5, 12, 2, 15, 7, 10, 3, 14, 6, 11];
    seedCharts[32] = [1, 32, 16, 17, 9, 24, 8, 25, 4, 29, 13, 20, 12, 21, 5, 28, 2, 31, 15, 18, 10, 23, 7, 26, 3, 30, 14, 19, 11, 22, 6, 27];
    seedCharts[48] = [
        32, 33, 17, 48, 24, 41, 25, 40, 29, 36, 20, 45, 21, 44, 28, 37, 31, 34, 18, 47, 23, 42, 26, 39, 30, 35, 19, 46, 22, 43, 27, 38, 1, 0, 0, 16, 9, 0, 0, 8,
        4, 0, 0, 13, 12, 0, 0, 5, 2, 0, 0, 15, 10, 0, 0, 7, 3, 0, 0, 14, 11, 0, 0, 6,
    ];
    seedCharts[64] = [
        1, 64, 32, 33, 17, 48, 16, 49, 9, 56, 24, 41, 25, 40, 8, 57, 4, 61, 29, 36, 20, 45, 13, 52, 12, 53, 21, 44, 28, 37, 5, 60, 2, 63, 31, 34, 18, 47, 15,
        50, 10, 55, 23, 42, 26, 39, 7, 58, 3, 62, 30, 35, 19, 46, 14, 51, 11, 54, 22, 43, 27, 38, 6, 59,
    ];
    seedCharts[128] = [
        1, 128, 65, 64, 33, 96, 97, 32, 17, 112, 81, 48, 49, 80, 113, 16, 9, 120, 73, 56, 41, 88, 105, 24, 25, 104, 89, 40, 57, 72, 121, 8, 5, 124, 69, 60, 37,
        92, 101, 28, 21, 108, 85, 44, 53, 76, 117, 12, 13, 116, 77, 52, 45, 84, 109, 20, 29, 100, 93, 36, 61, 68, 125, 4, 3, 126, 67, 62, 35, 94, 99, 30, 19,
        110, 83, 46, 51, 78, 115, 14, 11, 118, 75, 54, 43, 86, 107, 22, 27, 102, 91, 38, 59, 70, 123, 6, 7, 122, 71, 58, 39, 90, 103, 26, 23, 106, 87, 42, 55,
        74, 119, 10, 15, 114, 79, 50, 47, 82, 111, 18, 31, 98, 95, 34, 63, 66, 127, 2,
    ];
    seedCharts[256] = [
        1, 256, 128, 129, 65, 192, 64, 193, 33, 224, 96, 161, 97, 160, 32, 225, 17, 240, 112, 145, 81, 176, 48, 209, 49, 208, 80, 177, 113, 144, 16, 241, 9,
        248, 120, 137, 73, 184, 56, 201, 41, 216, 88, 169, 105, 152, 24, 233, 25, 232, 104, 153, 89, 168, 40, 217, 57, 200, 72, 185, 121, 136, 8, 249, 5, 252,
        124, 133, 69, 188, 60, 197, 37, 220, 92, 165, 101, 156, 28, 229, 21, 236, 108, 149, 85, 172, 44, 213, 53, 204, 76, 181, 117, 140, 12, 245, 13, 244, 116,
        141, 77, 180, 52, 205, 45, 212, 84, 173, 109, 148, 20, 237, 29, 228, 100, 157, 93, 164, 36, 221, 61, 196, 68, 189, 125, 132, 4, 253, 3, 254, 126, 131,
        67, 190, 62, 195, 35, 222, 94, 163, 99, 158, 30, 227, 19, 238, 110, 147, 83, 174, 46, 211, 51, 206, 78, 79, 115, 142, 14, 243, 11, 246, 118, 139, 75,
        182, 54, 203, 43, 214, 86, 171, 107, 150, 22, 235, 27, 230, 102, 155, 91, 166, 38, 219, 59, 198, 70, 187, 123, 134, 6, 251, 7, 250, 122, 135, 71, 186,
        58, 199, 39, 218, 90, 167, 103, 154, 26, 231, 23, 234, 106, 151, 87, 170, 42, 215, 55, 202, 74, 183, 119, 138, 10, 247, 15, 242, 114, 143, 79, 178, 50,
        207, 47, 210, 82, 175, 111, 146, 18, 239, 31, 226, 98, 159, 95, 162, 34, 223, 63, 194, 66, 191, 127, 130, 2, 255,
    ];
    seedCharts[512] = [
        1, 512, 256, 257, 129, 384, 128, 385, 65, 448, 192, 321, 193, 320, 64, 449, 33, 480, 224, 289, 161, 352, 96, 417, 97, 416, 160, 353, 225, 288, 32, 481,
        17, 496, 240, 273, 145, 368, 112, 401, 81, 432, 176, 337, 209, 304, 48, 465, 49, 464, 208, 305, 177, 336, 80, 433, 113, 400, 144, 369, 241, 272, 16,
        497, 9, 504, 248, 265, 137, 376, 120, 393, 73, 440, 184, 329, 201, 312, 56, 457, 41, 472, 216, 297, 169, 344, 88, 425, 105, 408, 152, 361, 233, 280, 24,
        489, 25, 488, 232, 281, 153, 360, 104, 409, 89, 424, 168, 345, 217, 296, 40, 473, 57, 456, 200, 313, 185, 328, 72, 441, 121, 392, 136, 377, 249, 264, 8,
        505, 5, 508, 252, 261, 133, 380, 124, 389, 69, 444, 188, 325, 197, 316, 60, 453, 37, 476, 220, 293, 165, 348, 92, 421, 101, 412, 156, 357, 229, 284, 28,
        485, 21, 492, 236, 277, 149, 364, 108, 405, 85, 428, 172, 341, 213, 300, 44, 469, 53, 460, 204, 309, 181, 332, 76, 437, 117, 396, 140, 373, 245, 268,
        12, 501, 13, 500, 244, 269, 141, 372, 116, 397, 77, 436, 180, 333, 205, 308, 52, 461, 45, 468, 212, 301, 173, 340, 84, 429, 109, 404, 148, 365, 237,
        276, 20, 493, 29, 484, 228, 285, 157, 356, 100, 413, 93, 420, 164, 349, 221, 292, 36, 477, 61, 452, 196, 317, 189, 324, 68, 445, 125, 388, 132, 381,
        253, 260, 3, 510, 4, 509, 254, 259, 131, 382, 126, 387, 67, 446, 190, 323, 195, 318, 62, 451, 35, 478, 222, 291, 163, 350, 94, 419, 99, 414, 158, 355,
        227, 286, 30, 483, 19, 494, 238, 275, 147, 366, 110, 403, 83, 430, 174, 339, 211, 302, 46, 467, 51, 462, 206, 307, 179, 334, 78, 435, 115, 398, 142,
        371, 243, 270, 14, 499, 11, 502, 246, 267, 139, 374, 118, 395, 75, 438, 182, 331, 203, 310, 54, 459, 43, 470, 214, 299, 171, 342, 86, 427, 107, 406,
        150, 363, 235, 278, 22, 491, 27, 486, 230, 283, 155, 358, 102, 411, 91, 422, 166, 347, 219, 294, 38, 475, 59, 454, 198, 315, 187, 326, 70, 443, 123,
        390, 134, 379, 251, 262, 6, 507, 7, 506, 250, 263, 135, 378, 122, 391, 71, 442, 186, 327, 199, 314, 58, 455, 39, 474, 218, 295, 167, 346, 90, 423, 103,
        410, 154, 359, 231, 282, 26, 487, 23, 490, 234, 279, 151, 362, 106, 407, 87, 426, 170, 343, 215, 298, 42, 471, 55, 458, 202, 311, 183, 330, 74, 439,
        119, 394, 138, 375, 247, 266, 10, 503, 15, 498, 242, 271, 143, 370, 114, 399, 79, 434, 178, 335, 207, 306, 50, 463, 47, 466, 210, 303, 175, 338, 82,
        431, 111, 402, 146, 367, 239, 274, 18, 495, 31, 482, 226, 287, 159, 354, 98, 415, 95, 418, 162, 351, 223, 290, 34, 479, 63, 450, 194, 319, 191, 322, 66,
        447, 127, 386, 130, 383, 255, 258, 2, 511,
    ];
    seedCharts[1024] = [];

    if (totalPlayers) {
        return seedCharts[totalPlayers];
    } else {
        return;
    }
}
