import React, { useState, useEffect } from 'react';
import { message, Avatar, Radio, Typography, Modal, Empty, Tag, Tooltip, Row, Col, Button, Icon } from 'antd';
import { withRouter, Link } from 'react-router-dom';
import CircularLoader from '../../../components/CircularLoader';
import Slider from 'react-slick';
import Card from '../../../components/Card';
import EventMatchScoreboard from '../matches/EventMatchScoreboard';
import EventScoreboardArtistic from '../matches/EventScoreboardArtistic';
import EventScoreboard from '../tournaments/EventScoreboard';
import EventScoreboard1P from '../tournaments/EventScoreboard1P';
import dpLogoWatermarkColor from '../../../assets/img/dp-logo-watermark-color.png';
import chalkysLogo from '../../../assets/img/chalkys-logo.png';
import twentyOnePoolRoomLogo from '../../../assets/img/21poolroom-logo.png';
import Ripples from 'react-ripples';
import slugify from 'slugify';
import Hashids from 'hashids';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { CREATE_MATCH_MUTATION } from '../data/mutations';
import { GET_EVENT_LIVE_TABLE_QUERY2, GET_EVENT_BY_SLUG_QUERY } from '../data/queries';
import { GET_EVENT_LIVE_TABLE_SUBSCRIPTION, GET_EVENT_LIVE_TABLE_SUBSCRIPTION2 } from '../data/subscriptions';
import { Subscription, Query } from '@apollo/client/react/components';
import _ from 'lodash';
import { displayEnumType } from '../../../utils';
import EventLiveScoringSetupModal from './EventLiveScoringSetupModal';
import moment from 'moment-timezone';
import momentDurationFormatSetup from 'moment-duration-format';
momentDurationFormatSetup(moment);

const { Text, Title } = Typography;
const { confirm } = Modal;

const styles = {
    container: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 20,
    },
};

// const settings = {
//     dots: true,
//     infinite: true,
//     speed: 500,
//     // slidesToShow: 1,
//     // slidesToScroll: 1,
//     autoplay: true,
//     autoplaySpeed: 2000,
//     adaptiveHeight: false,
//     // fade: true,
// };

const settings = {
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
    easing: 'ease-in-out',
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    draggable: false,
};

function EventLiveScoring3(props) {
    const { client } = props;
    const { match, authState } = props;
    const [setupModalVisible, setSetupModalVisible] = useState(false);
    const [activeTable, setActiveTable] = useState();
    const [gameType, setGameType] = useState();
    const [currentEvent, setCurrentEvent] = useState();

    const params = match.params;
    const eventSlug = params.slug;
    const tableSlug = params.table;
    const hashids = new Hashids(`Match salt`);
    const [createMatch] = useMutation(CREATE_MATCH_MUTATION);

    function handleScoringSetupModalOk(e) {
        setSetupModalVisible(false);
    }

    function handleScoringSetupModalCancel(e) {
        setSetupModalVisible(false);
    }

    function handleScoringSetup(table, gameType, event) {
        setSetupModalVisible(true);
        setActiveTable(table);
        setGameType(gameType);
        setCurrentEvent(event);
    }

    function getRandomArbitrary(min, max) {
        return Math.random() * (max - min) + min;
    }

    function startScoringMatch(values) {
        console.log(values);
        const {
            player_id,
            player_user_id,
            player_country,
            player_name,
            player_fargo,
            player_race_to,
            opponent_id,
            opponent_user_id,
            opponent_name,
            opponent_country,
            opponent_fargo,
            opponent_race_to,
        } = values;
        if (currentEvent) {
            const eventId = currentEvent.id;
            const venueId = currentEvent.venue_id;
            const tableId = activeTable.id;
            const randomNum = getRandomArbitrary(1, 10000).toFixed(0);
            const hashId = hashids.encode(randomNum, venueId, tableId);
            const name = `${displayEnumType(gameType)} - ${player_name || 'Home Team'} vs. ${opponent_name || 'Away Team'}`;

            const slug = slugify(`${name} ${hashId}`, {
                replacement: '-',
                remove: /[*+~.,()'"#!:/@]/g,
                lower: true,
            });

            createMatch({
                variables: {
                    objects: [
                        {
                            name,
                            slug,
                            match_type: 'singles',
                            game_type: gameType,
                            player_id: player_id,
                            player_country: player_country,
                            player_name: player_name || 'Home Team',
                            player_score: 0,
                            player_fargo: player_fargo,
                            player_race_to: player_race_to,
                            opponent_id: opponent_id,
                            opponent_country: opponent_country,
                            opponent_name: opponent_name || 'Away Team',
                            opponent_score: 0,
                            opponent_fargo: opponent_fargo,
                            opponent_race_to: opponent_race_to,
                            venue_id: venueId,
                            pool_table_id: tableId,
                            event_id: eventId,
                            is_public: false,
                            is_scoring_started: false,
                            scheduled_time: moment.utc(),
                            status: 'NOT_STARTED',
                            // match_players: {
                            //     data: [
                            //         {
                            //             name: player_name,
                            //             country: player_country,
                            //             user_id: player_user_id,
                            //         },
                            //         {
                            //             name: opponent_name,
                            //             country: opponent_country,
                            //             user_id: opponent_user_id,
                            //         },
                            //     ],
                            // },
                        },
                    ],
                },
                notifyOnNetworkStatusChange: true,
                awaitRefetchQueries: true,
                refetchQueries: [
                    {
                        query: GET_EVENT_LIVE_TABLE_QUERY2,
                        variables: {
                            event_slug: eventSlug,
                            table_slug: tableSlug,
                        },
                    },
                ],
            })
                .then((values) => {
                    console.log(values);
                    setSetupModalVisible(false);
                })
                .catch((e) => {
                    if (e.message && e.message.includes('Uniqueness violation')) {
                        message.error('Error: There is already a match with that name. Please choose a different name.');
                    } else {
                        message.error((e && e.message) || JSON.stringify(e));
                    }
                    console.log(e);
                    setSetupModalVisible(false);
                });
        } else {
            message.error('No event found');
            setSetupModalVisible(false);
        }
    }

    function renderDummyScoring(event, table) {
        return (
            <div style={styles.container}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                    <img src={dpLogoWatermarkColor} alt="Digital Pool Logo" width={250} style={{ marginBottom: 20 }} />
                    <Title
                        level={1}
                        style={{
                            padding: 20,
                            margin: 0,
                            color: '#fff',
                            // background: 'rgba(255,255,255,0.05)',
                            textTransform: 'uppercase',
                            letterSpacing: 2,
                            fontSize: 40,
                            marginBottom: 20,
                        }}
                    >
                        Start Scoring on {tableSlug}
                    </Title>
                    <Row gutter={24} justify="center" type="flex">
                        <Col>
                            <Ripples color="#ccc">
                                <Button
                                    size="large"
                                    type="secondary"
                                    ghost
                                    onClick={() => {
                                        if (table) {
                                            handleScoringSetup(table, 'EIGHT_BALL', event);
                                        }
                                    }}
                                    style={{
                                        width: '100%',
                                        paddingLeft: 40,
                                        paddingRight: 40,
                                        height: 80,
                                        fontSize: 40,
                                        fontWeight: 700,
                                        borderRadius: 20,
                                        borderWidth: 3,
                                        // textShadow: '0 2px 5px rgba(0,0,0,0.25)',
                                    }}
                                >
                                    8-Ball
                                </Button>
                            </Ripples>
                        </Col>
                        <Col>
                            <Ripples color="#ccc">
                                <Button
                                    size="large"
                                    type="secondary"
                                    ghost
                                    onClick={() => {
                                        if (table) {
                                            handleScoringSetup(table, 'NINE_BALL', event);
                                        }
                                    }}
                                    style={{
                                        width: '100%',
                                        paddingLeft: 40,
                                        paddingRight: 40,
                                        height: 80,
                                        fontSize: 40,
                                        fontWeight: 700,
                                        borderRadius: 20,
                                        borderWidth: 3,
                                        // textShadow: '0 2px 5px rgba(0,0,0,0.25)',
                                    }}
                                >
                                    9-Ball
                                </Button>
                            </Ripples>
                        </Col>
                        <Col>
                            <Ripples color="#ccc">
                                <Button
                                    size="large"
                                    type="secondary"
                                    ghost
                                    onClick={() => {
                                        if (table) {
                                            handleScoringSetup(table, 'TEN_BALL', event);
                                        }
                                    }}
                                    style={{
                                        width: '100%',
                                        paddingLeft: 40,
                                        paddingRight: 40,
                                        height: 80,
                                        fontSize: 40,
                                        fontWeight: 700,
                                        borderRadius: 20,
                                        borderWidth: 3,
                                        // textShadow: '0 2px 5px rgba(0,0,0,0.25)',
                                    }}
                                >
                                    10-Ball
                                </Button>
                            </Ripples>
                        </Col>
                    </Row>
                    <Row gutter={24} justify="center" type="flex" style={{ marginTop: 20 }}>
                        <Col>
                            <Ripples color="#ccc">
                                <Button
                                    size="large"
                                    type="secondary"
                                    ghost
                                    onClick={() => {
                                        if (table) {
                                            handleScoringSetup(table, 'BANK_POOL', event);
                                        }
                                    }}
                                    style={{
                                        width: '100%',
                                        paddingLeft: 40,
                                        paddingRight: 40,
                                        height: 80,
                                        fontSize: 40,
                                        fontWeight: 700,
                                        borderRadius: 20,
                                        borderWidth: 3,
                                        // textShadow: '0 2px 5px rgba(0,0,0,0.25)',
                                    }}
                                >
                                    Banks
                                </Button>
                            </Ripples>
                        </Col>
                        <Col>
                            <Ripples color="#ccc">
                                <Button
                                    size="large"
                                    type="secondary"
                                    ghost
                                    onClick={() => {
                                        if (table) {
                                            handleScoringSetup(table, 'ONE_POCKET', event);
                                        }
                                    }}
                                    style={{
                                        width: '100%',
                                        paddingLeft: 40,
                                        paddingRight: 40,
                                        height: 80,
                                        fontSize: 40,
                                        fontWeight: 700,
                                        borderRadius: 20,
                                        borderWidth: 3,
                                        // textShadow: '0 2px 5px rgba(0,0,0,0.25)',
                                    }}
                                >
                                    One Pocket
                                </Button>
                            </Ripples>
                        </Col>
                        <Col>
                            <Ripples color="#ccc">
                                <Button
                                    size="large"
                                    type="secondary"
                                    ghost
                                    onClick={() => {
                                        if (table) {
                                            handleScoringSetup(table, 'STRAIGHT_POOL', event);
                                        }
                                    }}
                                    style={{
                                        width: '100%',
                                        paddingLeft: 40,
                                        paddingRight: 40,
                                        height: 80,
                                        fontSize: 40,
                                        fontWeight: 700,
                                        borderRadius: 20,
                                        borderWidth: 3,
                                        // textShadow: '0 2px 5px rgba(0,0,0,0.25)',
                                    }}
                                >
                                    Straight Pool
                                </Button>
                            </Ripples>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
                // height: '100%',
                justifyContent: 'center',

                background: 'linear-gradient(180deg, #3E5267 0%, #1F3349 21%, #121A27 100%)',
                color: '#fff',
                height: 'auto',
                minHeight: '100%',
            }}
        >
            <Query
                query={GET_EVENT_LIVE_TABLE_QUERY2}
                fetchPolicy="cache-and-network"
                notifyOnNetworkStatusChange={true}
                variables={{ event_slug: eventSlug, table_slug: tableSlug }}
                onCompleted={(data, error, loading) => {
                    console.log(data);
                }}
            >
                {({ loading, error, data, refetch, fetchMore, subscribeToMore, networkStatus }) => {
                    if (loading)
                        return (
                            <div style={{ padding: 50 }}>
                                <CircularLoader />
                            </div>
                        );
                    if (error) return <div style={styles.container}>Error: {error.message}</div>;

                    let tableData;
                    let matchData;
                    let tournamentData;
                    let tables = [];

                    data &&
                        data.events &&
                        data.events.forEach((event) => {
                            const eventMatches = event.matches;
                            const eventTournaments = event.tournaments;
                            const eventVenueTables = event.venue && event.venue.pool_tables;

                            eventMatches.forEach((match) => {
                                if (match.pool_table && match.pool_table.matches) {
                                    match.pool_table.matches.forEach((item) => {
                                        matchData = item;
                                        tableData = match.pool_table;
                                    });
                                }
                            });

                            eventTournaments.forEach((tournament) => {
                                if (tournament.pool_tables) {
                                    tournament.pool_tables.forEach((table) => {
                                        tables.push(table);
                                        if (table.tournament_match_table && table.tournament_match_table[0]) {
                                            matchData = table.tournament_match_table[0];
                                            tableData = table;
                                            tournamentData = tournament;
                                        }
                                    });
                                }
                            });

                            eventVenueTables.forEach((table) => {
                                if (table) {
                                    tables.push(table);
                                }
                            });
                        });

                    console.log({
                        tableData,
                        tables,
                        matchData,
                        tournamentData,
                    });

                    const table = tables && tables[0];
                    const event = data.events && data.events[0];

                    if (data && !tableData && !tables.length) {
                        return (
                            <Row gutter={24} justify="center" type="flex">
                                <Col xs={24}>
                                    <div style={styles.container}>
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                                            <img src={dpLogoWatermarkColor} alt="Digital Pool Logo" width={250} style={{ marginBottom: 20 }} />
                                            <Title
                                                level={1}
                                                style={{
                                                    padding: 20,
                                                    margin: 0,
                                                    color: '#fff',
                                                    // background: 'rgba(255,255,255,0.05)',
                                                    textTransform: 'uppercase',
                                                    letterSpacing: 2,
                                                    fontSize: 40,
                                                    marginBottom: 20,
                                                }}
                                            >
                                                {tableSlug} does not exist
                                            </Title>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        );
                    } else if (data && !tableData) {
                        if (event && event.id === 91) {
                            return (
                                <Slider {...settings} style={{ background: 'transparent' }}>
                                    <Row gutter={24} justify="center" type="flex">
                                        <Col xs={24}>{renderDummyScoring(event, table)}</Col>
                                    </Row>
                                    <Row gutter={24} justify="center" type="flex">
                                        <Col xs={24} align="center">
                                            <img src={chalkysLogo} alt="Chalkys Logo" width="80%" />
                                        </Col>
                                    </Row>
                                </Slider>
                            );
                        } else if (event && event.id === 143) {
                            return (
                                <Slider {...settings} style={{ background: 'transparent' }}>
                                    <Row gutter={24} justify="center" type="flex">
                                        <Col xs={24}>{renderDummyScoring(event, table)}</Col>
                                    </Row>
                                    <Row gutter={24} justify="center" type="flex">
                                        <Col xs={24} align="center">
                                            <img src={twentyOnePoolRoomLogo} alt="21 Pool Room Logo" width="80%" />
                                        </Col>
                                    </Row>
                                </Slider>
                            );
                        } else {
                            return (
                                <Row gutter={24} justify="center" type="flex">
                                    <Col xs={24}>{renderDummyScoring(event, table)}</Col>
                                </Row>
                            );
                        }
                    }

                    if (tournamentData) {
                        if (tournamentData.game_type === 'ONE_POCKET') {
                            return (
                                <EventScoreboard1P
                                    backUrl={`/events/${eventSlug}/scores`}
                                    tournamentData={tournamentData}
                                    tableData={tableData}
                                    matchData={{
                                        ...matchData,
                                        game_type: match.game_type || (tournamentData && tournamentData.game_type),
                                        tournament: tournamentData,
                                        eventSlug,
                                        tableSlug,
                                    }}
                                    subscribeToMore={subscribeToMore}
                                    subscribeToNewData={() => {
                                        subscribeToMore({
                                            document: GET_EVENT_LIVE_TABLE_SUBSCRIPTION,
                                            variables: { event_slug: eventSlug, table_slug: tableSlug },
                                            updateQuery: (prev, { subscriptionData }) => {
                                                if (!subscriptionData.data) return prev;
                                                const newTournament = subscriptionData.data.tournaments;
                                                let updated;
                                                if (prev) {
                                                    updated = Object.assign({}, prev, {
                                                        tournaments: [...newTournament],
                                                    });
                                                } else {
                                                    updated = Object.assign({}, prev, {
                                                        tournaments: [...newTournament],
                                                    });
                                                }
                                                return updated;
                                            },
                                        });
                                    }}
                                    {...props}
                                />
                            );
                        } else {
                            return (
                                <EventScoreboard
                                    backUrl={`/events/${eventSlug}/scores`}
                                    tournamentData={tournamentData}
                                    tableData={tableData}
                                    matchData={{
                                        ...matchData,
                                        game_type: match.game_type || (tournamentData && tournamentData.game_type),
                                        tournament: tournamentData,
                                        eventSlug,
                                        tableSlug,
                                    }}
                                    subscribeToMore={subscribeToMore}
                                    subscribeToNewData={() => {
                                        subscribeToMore({
                                            document: GET_EVENT_LIVE_TABLE_SUBSCRIPTION,
                                            variables: { event_slug: eventSlug, table_slug: tableSlug },
                                            updateQuery: (prev, { subscriptionData }) => {
                                                if (!subscriptionData.data) return prev;
                                                const newTournament = subscriptionData.data.tournaments;
                                                let updated;
                                                if (prev) {
                                                    updated = Object.assign({}, prev, {
                                                        tournaments: [...newTournament],
                                                    });
                                                } else {
                                                    updated = Object.assign({}, prev, {
                                                        tournaments: [...newTournament],
                                                    });
                                                }
                                                return updated;
                                            },
                                        });
                                    }}
                                    {...props}
                                />
                            );
                        }
                    } else {
                        if (matchData.game_type === 'ARTISTIC_POOL') {
                            return (
                                <EventScoreboardArtistic
                                    backUrl={`/events/${eventSlug}/scores`}
                                    tableData={tableData}
                                    matchData={{
                                        ...matchData,
                                        eventSlug,
                                        tableSlug,
                                    }}
                                    subscribeToMore={subscribeToMore}
                                    subscribeToNewData={() => {
                                        subscribeToMore({
                                            document: GET_EVENT_LIVE_TABLE_SUBSCRIPTION2,
                                            variables: { event_slug: eventSlug, table_slug: tableSlug },
                                            updateQuery: (prev, { subscriptionData }) => {
                                                if (!subscriptionData.data) return prev;
                                                const newMatch = subscriptionData.data.matches;
                                                let updated;
                                                if (prev) {
                                                    updated = Object.assign({}, prev, {
                                                        matches: [newMatch],
                                                    });
                                                } else {
                                                    updated = Object.assign({}, prev, {
                                                        matches: [newMatch],
                                                    });
                                                }
                                                return updated;
                                            },
                                        });
                                    }}
                                    {...props}
                                />
                            );
                        } else {
                            return (
                                <EventMatchScoreboard
                                    backUrl={`/events/${eventSlug}/scores`}
                                    tableData={tableData}
                                    matchData={{
                                        ...matchData,
                                        eventSlug,
                                        tableSlug,
                                    }}
                                    subscribeToMore={subscribeToMore}
                                    subscribeToNewData={() => {
                                        subscribeToMore({
                                            document: GET_EVENT_LIVE_TABLE_SUBSCRIPTION2,
                                            variables: { event_slug: eventSlug, table_slug: tableSlug },
                                            updateQuery: (prev, { subscriptionData }) => {
                                                if (!subscriptionData.data) return prev;
                                                const newMatch = subscriptionData.data.matches;
                                                let updated;
                                                if (prev) {
                                                    updated = Object.assign({}, prev, {
                                                        matches: [newMatch],
                                                    });
                                                } else {
                                                    updated = Object.assign({}, prev, {
                                                        matches: [newMatch],
                                                    });
                                                }
                                                return updated;
                                            },
                                        });
                                    }}
                                    {...props}
                                />
                            );
                        }
                    }
                }}
            </Query>

            <EventLiveScoringSetupModal
                {...props}
                gameType={gameType}
                modalVisible={setupModalVisible}
                onModalOk={handleScoringSetupModalOk}
                onModalCancel={handleScoringSetupModalCancel}
                onStartScoringMatch={startScoringMatch}
            />
        </div>
    );
}

export default withRouter(EventLiveScoring3);
