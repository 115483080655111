import React, { useState, useEffect, useRef } from 'react';
import { message, Modal, Typography, Button, Icon, Result, Tabs } from 'antd';
import PreviewStage from './PreviewStage';
import { payoutStructures, getByeChart, displayEnumType } from '../../utils';
import { useMutation } from '@apollo/react-hooks';
import {
    GET_TOURNAMENT_BY_SLUG_QUERY,
    GET_TOURNAMENT_PLAYERS_QUERY,
    GET_TOURNAMENT_BRACKET_TEMPLATE,
    GET_TOURNAMENT_QUERY,
    GET_TOURNAMENT_BRACKET_QUERY,
    GET_TOURNAMENT_POINTS,
    GET_TOURNAMENT_PAYOUTS,
    GET_TOURNAMENT_STATUS,
    GET_TOURNAMENT_TABLES_QUERY,
} from './data/queries';
import {
    CREATE_TOURNAMENT_BRACKET_MUTATION,
    CREATE_TOURNAMENT_POINTS_MUTATION,
    CREATE_TOURNAMENT_PAYOUTS_MUTATION,
    UPDATE_TOURNAMENT_MUTATION,
    UPDATE_TOURNAMENT_PLAYER_MUTATION,
    DELETE_TOURNAMENT_BRACKET_MUTATION,
    DELETE_TOURNAMENT_STAGE_BRACKET_MUTATION,
    UPDATE_TOURNAMENT_STATUS,
    DELETE_TOURNAMENT_POINTS_MUTATION,
    DELETE_TOURNAMENT_PAYOUTS_MUTATION,
    RESET_TOURNAMENT_PLAYER_PLACEMENTS,
    RESET_TABLE_STATUS_MUTATION,
    RESET_TOURNAMENT_STAGES,
} from './data/mutations';
import { DigitalPoolAPI } from '../../lib/api';
import PreviewStageRoundRobin from './PreviewStageRoundRobin';
import _ from 'lodash';
const { Text, Title } = Typography;
const { confirm } = Modal;
const { TabPane } = Tabs;

const styles = {};

export default function TBPreviewBracketStages(props) {
    const { client, tournament, bracketCreated, onSetBracketCreated, stages, onSetCurrentStep } = props;
    // const [currentStage, setCurrentStage] = useState();
    const [stageData, setStageData] = useState([]);
    const [activeTab, setActiveTab] = useState('stage-1');
    const [createBracketMutation, bracketProps] = useMutation(CREATE_TOURNAMENT_BRACKET_MUTATION);
    const [createPointsMutation, pointsProps] = useMutation(CREATE_TOURNAMENT_POINTS_MUTATION);
    const [createPayoutsMutation, payoutsProps] = useMutation(CREATE_TOURNAMENT_PAYOUTS_MUTATION);
    const [updateTournament, tournamentProps] = useMutation(UPDATE_TOURNAMENT_MUTATION);

    // useEffect(() => {
    //     setStageData([]);
    // }, [tournament]);

    const API = DigitalPoolAPI({
        ...props,
        tournament,
    });

    // useEffect(() => {
    //     if (stages && stages.length > 0) {
    //         let stage;
    //         if (!currentStage) {
    //             stage = stages && stages[0];
    //             setCurrentStage(stage);
    //         }
    //     }
    // }, [stages, currentStage]);

    function handleTabClick(tab) {
        setActiveTab(tab);
    }

    console.log('bracket  created', bracketCreated);

    function createTournamentBracket(data) {
        const updatedMatches = data.matches;
        const players = data.players;
        const places = data.places;
        const finalBracketSize = data.bracketSize;

        console.log('stage data', data);
        console.log('matches to save', updatedMatches);
        const key = 'bracket';
        message.loading({
            content: `Creating new bracket...`,
            key,
            duration: 0,
        });

        const tournamentPlayers = players;

        updateTournament({
            variables: {
                id: tournament.id,
                changes: {
                    max_players: finalBracketSize,
                },
            },
            notifyOnNetworkStatusChange: true,
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: GET_TOURNAMENT_QUERY,
                    variables: { id: tournament.id },
                },
            ],
        })
            .then((data) => {
                if (places && places.length > 0) {
                    createPointsMutation({
                        variables: {
                            objects: places,
                        },
                        notifyOnNetworkStatusChange: true,
                        awaitRefetchQueries: true,
                    });
                }

                const payouts = payoutStructures({
                    entry_fee: tournament.entry_fee,
                    added_money: tournament.added_money,
                    max_players: tournamentPlayers && tournamentPlayers.length,
                    payout_type: tournament.payout_type,
                });

                const chosenPayouts = payouts && payouts.chosenPayouts && payouts.chosenPayouts.payouts;
                const combinedPayouts =
                    places &&
                    places.map((item, index) => {
                        const place = item.place.split('-');
                        const placeMinPlayers = parseInt(place[0]);
                        const placeMaxPlayers = parseInt(place[1]);

                        // console.log(placeMinPlayers);
                        // console.log(placeMaxPlayers);
                        // console.log(chosenPayouts);

                        let matchingPayout =
                            chosenPayouts &&
                            chosenPayouts.filter((item) => {
                                if (placeMinPlayers && placeMaxPlayers) {
                                    return item.place >= placeMinPlayers && item.place <= placeMaxPlayers;
                                } else if (placeMinPlayers && !placeMaxPlayers) {
                                    return item.place === placeMinPlayers;
                                }
                            });

                        return {
                            ...item,
                            ...(matchingPayout &&
                                matchingPayout[0] && {
                                    money: matchingPayout[0].payout,
                                }),
                            ...(matchingPayout &&
                                matchingPayout[0] && {
                                    percent: matchingPayout[0].percent,
                                }),
                        };
                    });

                if (combinedPayouts && combinedPayouts.length > 0) {
                    console.log('chosen payouts', combinedPayouts);
                    createPayoutsMutation({
                        variables: {
                            objects: combinedPayouts,
                        },
                        notifyOnNetworkStatusChange: true,
                        awaitRefetchQueries: true,
                        refetchQueries: [
                            {
                                query: GET_TOURNAMENT_PAYOUTS,
                                variables: { tournament_id: tournament.id },
                            },
                        ],
                    });
                }

                console.log({
                    entry_fee: tournament.entry_fee,
                    added_money: tournament.added_money,
                    payout_type: tournament.payout_type,
                    tournamentPlayers,
                    chosenPayouts,
                });
                console.log(updatedMatches);

                createBracketMutation({
                    variables: {
                        objects: updatedMatches,
                    },
                    notifyOnNetworkStatusChange: true,
                    awaitRefetchQueries: true,
                    refetchQueries: [
                        {
                            query: GET_TOURNAMENT_QUERY,
                            variables: { id: tournament.id },
                        },

                        // {
                        //     query: GET_TOURNAMENT_BRACKET_QUERY,
                        //     variables: { tournamentId: tournament.id },
                        // },

                        {
                            query: GET_TOURNAMENT_PLAYERS_QUERY,
                            variables: {
                                tournament_id: tournament.id,
                            },
                        },
                    ],
                })
                    .then((data) => {
                        message.success({
                            content: `Tournament bracket successfully created`,
                            key,
                            duration: 2,
                        });
                        // setBracketCreated(true);
                        onSetBracketCreated(true);
                        onSetCurrentStep(4);
                    })
                    .catch((error) => {
                        console.log(error);
                        message.error({
                            content: `There was an error ${JSON.stringify(error)}`,
                            key,
                            duration: 2,
                        });
                    });
            })
            .catch((error) => {
                console.log(error);
                message.error({
                    content: `There was an error ${JSON.stringify(error)}`,
                    key,
                    duration: 2,
                });
            });
    }

    function resetTableStatus() {
        const key = 'resetTables';
        props.client
            .mutate({
                mutation: RESET_TABLE_STATUS_MUTATION,
                variables: {
                    tournament_id: tournament.id,
                },
                notifyOnNetworkStatusChange: true,
                awaitRefetchQueries: true,
                refetchQueries: [
                    {
                        query: GET_TOURNAMENT_TABLES_QUERY,
                        variables: { tournament_id: tournament.id, status: 'OPEN' },
                    },
                ],
            })
            .then((data) => {
                console.log(data);
            })
            .catch((e) => {
                console.error(e);
                message.error({ content: JSON.stringify(e), key, duration: 2 });
            });
    }

    function updateTournamentStatus(status, progress) {
        const key = 'updateStatus';
        props.client
            .mutate({
                mutation: UPDATE_TOURNAMENT_STATUS,
                variables: {
                    id: tournament.id,
                    changes: {
                        status: status,
                        progress: progress.toString(),
                    },
                },
                notifyOnNetworkStatusChange: true,
                awaitRefetchQueries: true,
                refetchQueries: [
                    {
                        query: GET_TOURNAMENT_STATUS,
                        variables: { id: tournament.id },
                    },
                ],
            })
            .then((data) => {
                console.log(data);
            })
            .catch((e) => {
                console.error(e);
                message.error({ content: JSON.stringify(e), key, duration: 2 });
            });
    }

    function resetTournamentStages() {
        const key = 'resetStages';
        props.client
            .mutate({
                mutation: RESET_TOURNAMENT_STAGES,
                variables: {
                    tournament_id: tournament.id,
                },
                notifyOnNetworkStatusChange: true,
                awaitRefetchQueries: true,
                refetchQueries: [
                    {
                        query: GET_TOURNAMENT_BY_SLUG_QUERY,
                        variables: {
                            slug: props.match.params.slug || tournament.slug,
                        },
                    },
                ],
            })
            .then((data) => {
                console.log(data);
            })
            .catch((e) => {
                console.error(e);
                message.error({ content: JSON.stringify(e), key, duration: 2 });
            });
    }

    function resetPlayerStatus() {
        const key = 'resetStatus';
        props.client
            .mutate({
                mutation: RESET_TOURNAMENT_PLAYER_PLACEMENTS,
                variables: {
                    tournament_id: tournament.id,
                },
                notifyOnNetworkStatusChange: true,
                awaitRefetchQueries: true,
                refetchQueries: [
                    {
                        query: GET_TOURNAMENT_PLAYERS_QUERY,
                        variables: { tournament_id: tournament.id },
                    },
                ],
            })
            .then((data) => {
                console.log(data);
            })
            .catch((e) => {
                console.error(e);
                message.error({ content: JSON.stringify(e), key, duration: 2 });
            });
    }

    function resetTournamentPoints() {
        props.client
            .mutate({
                mutation: DELETE_TOURNAMENT_POINTS_MUTATION,
                variables: {
                    tournament_id: tournament.id,
                },
                notifyOnNetworkStatusChange: true,
                awaitRefetchQueries: true,
                refetchQueries: [
                    {
                        query: GET_TOURNAMENT_POINTS,
                        variables: { tournament_id: tournament.id },
                    },
                ],
            })
            .then(async (data) => {
                console.log(data);
            })
            .catch((error) => {
                console.log(error);
                return error;
            });
    }

    function resetTournamentPayouts() {
        props.client
            .mutate({
                mutation: DELETE_TOURNAMENT_PAYOUTS_MUTATION,
                variables: {
                    tournament_id: tournament.id,
                },
                notifyOnNetworkStatusChange: true,
                awaitRefetchQueries: true,
                refetchQueries: [
                    {
                        query: GET_TOURNAMENT_PAYOUTS,
                        variables: { tournament_id: tournament.id },
                    },
                ],
            })
            .then(async (data) => {
                console.log(data);
            })
            .catch((error) => {
                console.log(error);
                return error;
            });
    }

    function resetTournamentBracket() {
        const key = 'updatable';
        return props.client
            .mutate({
                mutation: DELETE_TOURNAMENT_BRACKET_MUTATION,
                variables: {
                    tournament_id: tournament.id,
                },
                notifyOnNetworkStatusChange: true,
                awaitRefetchQueries: true,
                refetchQueries: [
                    {
                        query: GET_TOURNAMENT_QUERY,
                        variables: { id: tournament.id },
                    },
                    {
                        query: GET_TOURNAMENT_PLAYERS_QUERY,
                        variables: { tournament_id: tournament.id },
                    },
                    {
                        query: GET_TOURNAMENT_BRACKET_QUERY,
                        variables: { tournamentId: tournament.id },
                    },
                ],
            })
            .then((data) => {
                message.success({
                    content: `Tournament bracket reset complete`,
                    key,
                    duration: 2,
                });
                if (stages && stages.length > 0) {
                    stageData &&
                        stageData.forEach((data, index) => {
                            createTournamentBracket(data);
                        });
                } else {
                    if (stageData && stageData[0]) {
                        stageData &&
                            stageData.forEach((data, index) => {
                                createTournamentBracket(data);
                            });
                    } else {
                        createTournamentBracket();
                    }
                    // createTournamentBracket(stageData[0]);
                }
            })
            .catch((error) => {
                console.log(error);
                return error;
            });
    }

    function resetStageBracket(stage) {
        const key = 'updatable';

        if (stage) {
            return props.client
                .mutate({
                    mutation: DELETE_TOURNAMENT_STAGE_BRACKET_MUTATION,
                    variables: {
                        tournament_id: tournament.id,
                        stage_id: stage.id,
                    },
                    notifyOnNetworkStatusChange: true,
                    awaitRefetchQueries: true,
                    refetchQueries: [
                        {
                            query: GET_TOURNAMENT_QUERY,
                            variables: { id: tournament.id },
                        },
                        {
                            query: GET_TOURNAMENT_PLAYERS_QUERY,
                            variables: { tournament_id: tournament.id },
                        },
                        {
                            query: GET_TOURNAMENT_BRACKET_QUERY,
                            variables: { tournamentId: tournament.id },
                        },
                    ],
                })
                .then((data) => {
                    console.log(data);
                    message.success({
                        content: `${stage.name} bracket reset complete`,
                        key,
                        duration: 2,
                    });

                    // console.log(stage);
                    // console.log(stageData);
                    // const currentStage =
                    //     stageData &&
                    //     stageData.filter((data) => {
                    //         return data.number === stage.number;
                    //     })[0];

                    // console.log(currentStage);
                    const currentStage =
                        stageData &&
                        stageData.filter((item) => {
                            return item.number === stage.number;
                        })[0];
                    createTournamentBracket(currentStage);
                })
                .catch((error) => {
                    console.log(error);
                    return error;
                });
        }
    }

    async function resetBracket(stage) {
        console.log('reset stage', stage);
        console.log('stage data', stageData);
        const key = 'updatable';
        if (stage) {
            message.loading({ content: `Resetting ${stage.name} bracket...`, key, duration: 0 });
            // const status = await API.updateTournamentStatus('NOT_STARTED');
            // console.log('status', status);
            resetStageBracket(stage);
        } else {
            message.loading({ content: 'Resetting tournament bracket...', key, duration: 0 });
            const status = await API.updateTournamentStatus('NOT_STARTED');
            console.log('status', status);
            resetTableStatus();
            updateTournamentStatus('NOT_STARTED', 0);
            resetPlayerStatus();
            resetTournamentPoints();
            resetTournamentPayouts();
            resetTournamentBracket();
            if (tournament.tournament_stage_format === 'single') {
                resetTournamentStages();
            }
        }
    }

    return (
        <div
            style={{
                paddingLeft: 20,
                paddingRight: 20,
                paddingBottom: 20,
            }}
        >
            <div
                style={{
                    marginTop: 20,
                    marginBottom: 20,
                }}
            >
                <Title
                    level={4}
                    style={{
                        marginBottom: 5,
                    }}
                >
                    Preview Draw
                </Title>
                {tournament.tournament_stage_format === 'multi' && stages && stages.length > 0 ? (
                    <React.Fragment>
                        {stages.map((stage, index) => {
                            console.log('stage data', stage);
                            console.log('stageData', stageData);
                            return (
                                <React.Fragment key={index}>
                                    {stage.tournament_type === 'round_robin' ? (
                                        <PreviewStageRoundRobin
                                            tournament={tournament}
                                            stage={stage}
                                            bracketCreated={bracketCreated}
                                            onStageUpdated={(data) => {
                                                let updated = _.uniqBy([data, ...stageData], 'number');
                                                setStageData(updated);
                                            }}
                                            onResetBracket={resetBracket}
                                            {...props}
                                        />
                                    ) : (
                                        <PreviewStage
                                            tournament={tournament}
                                            stage={stage}
                                            bracketCreated={bracketCreated}
                                            onStageUpdated={(data) => {
                                                let updated = _.uniqBy([data, ...stageData], 'number');
                                                setStageData(updated);
                                            }}
                                            onResetBracket={resetBracket}
                                            {...props}
                                        />
                                    )}
                                </React.Fragment>
                            );
                        })}
                    </React.Fragment>
                ) : (
                    <PreviewStage
                        tournament={tournament}
                        bracketCreated={bracketCreated}
                        onResetBracket={resetBracket}
                        onStageUpdated={(data) => {
                            console.log({
                                stageData,
                                data,
                            });
                            let updated = _.uniqBy([data, ...stageData], 'number');
                            console.log(updated);
                            setStageData(updated);
                        }}
                        {...props}
                    />
                )}
                {/* {tournament.tournament_stage_format === 'multi' && stages && stages.length > 0 ? (
                    <Tabs
                        defaultActiveKey="iframe"
                        animated={{ inkBar: true, tabPane: false }}
                        className="no-scroll tabs-bg2"
                        onChange={handleTabClick}
                        tabBarStyle={{
                            textTransform: 'uppercase',
                        }}
                    >
                        {stages.map((stage, index) => {
                            console.log('stage data', stage);
                            console.log('stageData', stageData);
                            return (
                                <TabPane tab={stage.name} key={stage.slug}>
                                    <PreviewStage
                                        key={index}
                                        tournament={tournament}
                                        stage={stage}
                                        bracketCreated={bracketCreated}
                                        onStageUpdated={(data) => {
                                            let updated = _.uniqBy([...stageData, data], 'number');
                                            setStageData(updated);
                                        }}
                                        onResetBracket={resetBracket}
                                        {...props}
                                    />
                                </TabPane>
                            );
                        })}
                    </Tabs>
                ) : (
                    <PreviewStage
                        tournament={tournament}
                        bracketCreated={bracketCreated}
                        onResetBracket={resetBracket}
                        onStageUpdated={(data) => {
                            let updated = _.uniqBy([...stageData, data], 'number');
                            setStageData(updated);
                        }}
                        {...props}
                    />
                )} */}

                <Button
                    type="primary"
                    size="large"
                    onClick={() => {
                        const key = 'bracket';
                        if (bracketCreated === false) {
                            console.log({
                                stages,
                                stageData,
                            });
                            if (stages && stages.length > 0) {
                                stageData &&
                                    stageData.forEach((data, index) => {
                                        createTournamentBracket(data);
                                    });
                            } else {
                                if (stageData && stageData[0]) {
                                    createTournamentBracket(stageData[0]);
                                } else {
                                    createTournamentBracket();
                                }
                            }
                        } else {
                            confirm({
                                title: `Tournament bracket already exists. Do you want to reset it?`,
                                content: 'This action will destroy all existing matches and generate a new bracket from the bracket preview.',
                                centered: true,
                                transitionName: 'fade',
                                maskTransitionName: 'none',
                                okText: `Keep bracket`,
                                onOk() {
                                    onSetCurrentStep(4);
                                },
                                onCancel() {
                                    resetBracket();
                                },
                                cancelText: 'Reset bracket',
                            });
                            // message.error({
                            //     content: `Tournament bracket already exists, skipping...`,
                            //     key,
                            //     duration: 2,
                            // });
                            // setCurrentStep(3);
                        }
                    }}
                    style={{
                        marginTop: 20,
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <Text
                                style={{
                                    color: '#fff',
                                }}
                            >
                                {bracketCreated === true ? 'Save' : 'Create'} and go to step 5
                            </Text>
                            {/* <Icon type="arrow-right" style={{ marginLeft: 3 }} /> */}
                        </div>
                    </div>
                </Button>

                <Button
                    type="secondary"
                    size="large"
                    onClick={() => {
                        onSetCurrentStep(2);
                    }}
                    style={{
                        marginTop: 20,
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <Text>Go back</Text>
                    </div>
                </Button>
            </div>
        </div>
    );
}
