import React from 'react';
import { Icon, Row, Col, Typography, Button, Input, Tabs, Empty } from 'antd';
import Card from '../../components/Card';
import { Link } from 'react-router-dom';
import SectionHeader from '../../components/SectionHeader';
import SectionContent from '../../components/SectionContent';
import StreamTable from './StreamTable';

const { TabPane } = Tabs;
const { Text, Title } = Typography;
const { Search } = Input;

function callback(key) {
	console.log(key);
}

const styles = {
	row: {
		display: 'flex',
		flexGrow: 1
	},
	tab: {
		paddingTop: 5,
		paddingLeft: 24,
		paddingRight: 24,
		paddingBottom: 24
	}
};

export default function PLMain(props) {
	return (
		<React.Fragment>
			<SectionHeader
				title={props.title}
				titleStyle={{
					color: '#fff',
					textTransform: 'uppercase',
					margin: 0,
					display: 'flex'
				}}
				height={120}
				subtitle="Stream live pool matches easily from your own device."
				subtitleSuffix={true}
				subtitleStyle={{ color: '#fff' }}
			>
				<Link to="/pool-live/new">
					<Button size="large" type="ghost" ghost>
						Create Stream
						<Icon type="arrow-right" />
					</Button>
				</Link>
			</SectionHeader>

			<SectionContent padding="20px 40px">
				<Row gutter={16} style={styles.row}>
					<Col xs={24} sm={24} md={24} lg={12}>
						<Card title="Create a new stream">
							<div style={{ marginBottom: 16 }}>
								<Text>
									Want to easily stream your pool match with your own device? Get started below!
								</Text>
							</div>
							<Button type="primary">Get Started</Button>
						</Card>
					</Col>
					<Col xs={24} sm={24} md={24} lg={12}>
						<Card title="Everything you need to know">
							<div style={{ marginBottom: 16 }}>
								<Text>
									Have questions? We have answers! Check out our Streaming How-to Guide and FAQs.
								</Text>
							</div>
							<Button>How-To Guide</Button>
							<Button>FAQ</Button>
						</Card>
					</Col>
				</Row>
				<Row>
					<Col>
						<Card style={styles.card}>
							<Empty
								image="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original"
								imageStyle={{
									height: 100
								}}
								description={
									<Text style={{ fontSize: 18 }}>You have not created any live streams yet.</Text>
								}
								style={{ margin: 10 }}
							>
								<Button>Create Stream</Button>
								<Button>View All Streams</Button>
							</Empty>
						</Card>
					</Col>
				</Row>
			</SectionContent>
		</React.Fragment>
	);
}
