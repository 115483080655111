import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { List, PageHeader, Icon, Row, Col, Typography, Button, Descriptions, Empty } from 'antd';
import Card from '../../components/Card';
import { Link } from 'react-router-dom';
import SectionContent from '../../components/SectionContent';
import SectionHeader from '../../components/SectionHeader';
import { displayEnumType } from '../../utils';
import CircleFlag from '../../components/CircleFlag';
import { getCountryName } from '../../utils/getCountryName';
import CircularLoader from '../../components/CircularLoader';
import { Query } from '@apollo/client/react/components';
import { GET_LEAGUE_TEAM_BY_SLUG_QUERY, GET_LEAGUE_TEAM_STATS } from './data/queries';

const { Text, Title } = Typography;

const styles = {
    row: {
        display: 'flex',
        flexGrow: 1,
    },
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
};

function LMTeamDetail(props) {
    const { league, division, history } = props;
    // const [ team, setTeam ] = useState();

    return (
        <React.Fragment>
            <SectionHeader
                title={displayEnumType(props.match.params.team)}
                titleStyle={{ color: '#fff', margin: 0, textTransform: 'uppercase' }}
                subtitle="Division Team"
                subtitlePrefix={true}
                subtitleStyle={{
                    color: 'rgba(255,255,255,0.8)',
                    textTransform: 'uppercase',
                    fontSize: 12,
                    letterSpacing: 3,
                }}
                backButton={
                    <Button
                        onClick={() => props.history.goBack()}
                        // onClick={() =>
                        // 	props.history.push(
                        // 		history && history.location && history.location.state && history.location.state.backPath
                        // 			? history.location.state.backPath
                        // 			: `/league-builder/${props.league.slug}/teams`
                        // 	)}
                        type="ghost"
                        shape="circle"
                        icon="arrow-left"
                        size="large"
                        ghost
                        style={{ border: 'none' }}
                    />
                }
                theme="leagueManager"
            >
                <Button
                    size="large"
                    type="ghost"
                    ghost
                    onClick={() => {
                        props.history.push(`/league-manager/${league.slug}/divisions/${division.slug}/teams/${props.match.params.team}/edit`);
                    }}
                >
                    <Icon type="edit" /> Edit
                </Button>

                <Link to={`/leagues/${league.slug}/divisions/${props.match.params.division}/teams`} style={{ marginLeft: 10 }}>
                    <Button size="large" type="ghost" ghost>
                        View Public Profile
                        <Icon type="arrow-right" />
                    </Button>
                </Link>
            </SectionHeader>
            {/* <PageHeader
				onBack={() => props.history.push(`/league-builder/${props.league.slug}/teams`)}
				backIcon={<Icon type="arrow-left" style={{ color: props.theme === 'dark' ? '#fff' : '#000' }} />}
				title={
					<Text style={{ color: props.theme === 'dark' ? '#fff' : '#000' }}>
						{_.startCase(_.toLower(props.match.params.team.replace('-', ' '))).toUpperCase()}
					</Text>
				}
				style={{
					background:
						props.theme === 'dark'
							? 'linear-gradient(180deg, #07101f, #14243b)'
							: 'linear-gradient(#fff, #fafafa)',
					paddingTop: 24,
					paddingLeft: 40,
					paddingRight: 40,
					paddingBottom: 24,
					display: 'flex',
					alignItems: 'center'
				}}
			/> */}
            <SectionContent padding="20px 40px">
                <Query
                    query={GET_LEAGUE_TEAM_BY_SLUG_QUERY}
                    fetchPolicy="cache-and-network"
                    notifyOnNetworkStatusChange={true}
                    variables={{ league_id: league.id, slug: props.match.params.team }}
                    onCompleted={(data) => {
                        console.log(data);
                        // const team = data.league_teams && data.league_teams[0];
                        // setTeam(team);
                    }}
                >
                    {({ loading, error, data }) => {
                        if (loading) return <CircularLoader />;
                        if (error) return <div style={styles.container}>Error: {error.message}</div>;

                        const team = data && data.league_teams && data.league_teams[0];

                        return (
                            <React.Fragment>
                                {loading && (
                                    <div style={styles.container}>
                                        <CircularLoader />
                                    </div>
                                )}
                                {error && <div style={styles.container}>Error: {error.message}</div>}
                                {!loading && !team && (
                                    <Card>
                                        <Empty
                                            image="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original"
                                            imageStyle={{
                                                height: 100,
                                            }}
                                            description={<Text style={{ fontSize: 18 }}>team could not be found</Text>}
                                            style={{ margin: 10 }}
                                        >
                                            <Button>Create New team</Button>
                                        </Empty>
                                    </Card>
                                )}
                                {team && (
                                    <Row gutter={24}>
                                        <Col xs={24}>
                                            <Card title="Team Information">
                                                <Descriptions
                                                    // title="League Details"
                                                    size="small"
                                                    column={2}
                                                    bordered={false}
                                                    layout="horizontal"
                                                >
                                                    <Descriptions.Item label="Name">
                                                        <Text>{team.name}</Text>
                                                    </Descriptions.Item>

                                                    <Descriptions.Item label="Team Number">{team.team_number || 'N/A'}</Descriptions.Item>

                                                    <Descriptions.Item label="League Dues Paid">{team.league_dues_paid ? 'Yes' : 'No'}</Descriptions.Item>

                                                    <Descriptions.Item label="Last Payment Date">{team.last_payment_date || 'N/A'}</Descriptions.Item>

                                                    <Descriptions.Item label="Status">
                                                        <Text>{team.status === 'ACTIVE' ? 'Active' : 'Not Active'}</Text>
                                                    </Descriptions.Item>
                                                </Descriptions>
                                            </Card>
                                        </Col>
                                        <Col xs={24}>
                                            <Card title="Team Players" bodyStyle={{ padding: 0 }}>
                                                <List
                                                    className="hover-list"
                                                    size="small"
                                                    loading={false}
                                                    itemLayout="horizontal"
                                                    dataSource={team.league_team_players.map((team) => {
                                                        return team.league_player;
                                                    })}
                                                    renderItem={(player) => {
                                                        return (
                                                            <React.Fragment>
                                                                {player && player.name && (
                                                                    <List.Item>
                                                                        {player.country && <CircleFlag country={player.country} left={0} />}

                                                                        <Text
                                                                            style={{
                                                                                marginLeft: 10,
                                                                                width: '100%',
                                                                                whiteSpace: 'nowrap',
                                                                                overflow: 'hidden',
                                                                                textOverflow: 'ellipsis',
                                                                            }}
                                                                        >
                                                                            {player.name} ({player.skill_level})
                                                                            {team.captain_id === player.id
                                                                                ? ' - Captain'
                                                                                : team.co_captain_id === player.id
                                                                                ? ' - Co-Captain'
                                                                                : null}
                                                                        </Text>
                                                                    </List.Item>
                                                                )}
                                                            </React.Fragment>
                                                        );
                                                    }}
                                                />
                                            </Card>
                                        </Col>
                                        <Query
                                            query={GET_LEAGUE_TEAM_STATS}
                                            fetchPolicy="cache-and-network"
                                            notifyOnNetworkStatusChange={true}
                                            variables={{
                                                league_id: league.id,
                                                league_team_id: team.id,
                                            }}
                                            onCompleted={(data) => {
                                                console.log(data);
                                            }}
                                        >
                                            {({ loading, error, data }) => {
                                                return (
                                                    <React.Fragment>
                                                        {loading && <div>Loading stats...</div>}
                                                        <Col>
                                                            <Card title="team Stats">
                                                                <pre>{JSON.stringify(data, null, 4)}</pre>
                                                            </Card>
                                                        </Col>
                                                    </React.Fragment>
                                                );
                                            }}
                                        </Query>
                                    </Row>
                                )}
                            </React.Fragment>
                        );
                    }}
                </Query>
            </SectionContent>
        </React.Fragment>
    );
}

export default withRouter(LMTeamDetail);
