import React, { useState } from 'react';
import { Table, Icon, Row, Col, Typography, Progress, Button } from 'antd';
import Card from '../../components/Card';
import CircleFlag from '../../components/CircleFlag';

import numeral from 'numeral';

const { Text, Title } = Typography;

const styles = {
    row: {
        display: 'flex',
        flexGrow: 1,
    },
    tab: {
        paddingTop: 5,
        paddingLeft: 24,
        paddingRight: 24,
        paddingBottom: 24,
    },
};

export default function TBResults(props) {
    const { tournament, tournamentID } = props;

    const columns = [
        {
            title: 'Place',
            dataIndex: 'place',
            key: 'place',
            width: 80,
            defaultSortOrder: 'ascend',
            sorter: (a, b) => {
                return (
                    a.place &&
                    a.place.localeCompare(b.place, undefined, {
                        numeric: true,
                        sensitivity: 'base',
                    })
                );
            },
            render: (text, record) => (
                <div>
                    {/* {record.place === '1' ? (
						<TrophyOutlined style={{ color: '#eab700', marginRight: 5 }} />
					) : record.place === '2' ? (
						<TrophyOutlined style={{ color: '#9baab1', marginRight: 5 }} />
					) : record.place === '3' ? (
						<TrophyOutlined style={{ color: '#f19921', marginRight: 5 }} />
					) : (
						''
					)} */}
                    <Text>{text}</Text>
                </div>
            ),
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => {
                return a.name && a.name.localeCompare(b.name);
            },
            render: (text, record) => (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <div>{record.country && <CircleFlag country={record.country} left={0} />}</div>
                    <Text
                        style={{
                            width: '100%',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}
                    >
                        {text ? text : '-'}
                    </Text>
                </div>
            ),
        },
        {
            title: 'Skill Level',
            dataIndex: 'skillLevel',
            key: 'skillLevel',
            sorter: (a, b) => {
                return a.skillLevel - b.skillLevel;
            },
            render: (text, record) => {
                return <Text>{tournament.show_player_skill_levels === true ? text : '-'}</Text>;
            },
        },
        {
            title: 'Matches',
            dataIndex: 'matches',
            key: 'matches',
            sorter: (a, b) => {
                return a.matchesWon - b.matchesWon;
            },
        },
        {
            title: 'Match win %',
            dataIndex: 'matchWinPercentage',
            key: 'matchWinPercentage',
            sorter: (a, b) => {
                return a.matchWinPercentage - b.matchWinPercentage;
            },
            render: (text, record) => (
                <React.Fragment>
                    <Progress
                        percent={parseInt(record.matchWinPercentage.toFixed(0)) || 0}
                        status="normal"
                        size="small"
                        // showInfo={false}
                        strokeWidth={6}
                        strokeLinecap="square"
                    />
                </React.Fragment>
            ),
        },

        {
            title: 'Games',
            dataIndex: 'games',
            key: 'games',
            sorter: (a, b) => {
                return a.gamesWon - b.gamesWon;
            },
        },
        {
            title: 'Game win %',
            dataIndex: 'gameWinPercentage',
            key: 'gameWinPercentage',
            sorter: (a, b) => {
                return a.gameWinPercentage - b.gameWinPercentage;
            },
            render: (text, record) => (
                <React.Fragment>
                    <Progress
                        percent={parseInt(record.gameWinPercentage.toFixed(0)) || 0}
                        status="normal"
                        size="small"
                        // showInfo={false}
                        strokeWidth={6}
                        strokeLinecap="square"
                    />
                </React.Fragment>
            ),
        },

        {
            title: 'Earnings',
            dataIndex: 'earnings',
            key: 'earnings',
            sorter: (a, b) => {
                return a.earnings - b.earnings;
            },
            render: (text, record) => {
                return (
                    <div>
                        <Text>{text !== undefined ? numeral(text).format('$0,0') : '-'}</Text>
                    </div>
                );
            },
        },
        {
            title: 'Points',
            dataIndex: 'points',
            key: 'points',
            sorter: (a, b) => {
                return a.points - b.points;
            },
        },
        // {
        // 	title: 'Match History',
        // 	dataIndex: 'matchHistory',
        // 	key: 'matchHistory',
        // 	render: (text, record) => {
        // 		return <Button type="link">View</Button>;
        // 		// return record.matchHistory.map((result, index) => {
        // 		// 	return (
        // 		// 		<span key={index} className={result === 'W' ? 'match-badge win' : 'match-badge loss'}>
        // 		// 			{result}
        // 		// 		</span>
        // 		// 	);
        // 		// });
        // 	}
        // }
    ];

    return (
        <React.Fragment>
            <Card
                // title="Results"
                bodyStyle={{ padding: 0 }}
                // style={{
                // 	borderRadius: 8,
                // 	overflow: 'hidden',
                // 	border: 'none',
                // 	boxShadow: '0 2px 5px 0px rgba(0, 0, 0, 0.1)'
                // }}
            >
                <div className="table_nowrap">
                    <Table
                        pagination={false}
                        columns={columns}
                        dataSource={props.formattedResults}
                        size="default"
                        tableLayout="auto"
                        scroll={{ x: 400 }}
                        // pagination={{ pageSize: 25 }}
                    />
                </div>
            </Card>
        </React.Fragment>
    );
}
