import React, { useState, useEffect } from 'react';
import { Avatar, Typography, Row, Col, Button, Empty, Icon } from 'antd';
import { withRouter, Link } from 'react-router-dom';
import Card, { Meta } from '../../../components/Card';
import PoolTable from '../../../components/PoolTable';
import intlOpenLogo from '../../../assets/img/intlopen-logo.png';
import poweredByLogo from '../../../assets/img/powered-by-logo.png';
import CircleFlag from '../../../components/CircleFlag';
import CircularLoader from '../../../components/CircularLoader';
import { Query, Subscription } from '@apollo/client/react/components';
import { GET_TOURNAMENT_BY_SLUG } from './data/queries';
import { GET_ALL_TOURNAMENT_TABLES_TV_DISPLAY_SUBSCRIPTION } from './data/subscriptions';

const { Text, Title } = Typography;
const styles = {};

function DPTournamentTableLiveScores(props) {
    const { match, authState } = props;
    const params = match.params;
    const tournamentSlug = params.slug;

    return (
        <div
            style={{
                // display: 'flex',
                // flexDirection: 'column',
                // flex: 1,
                minHeight: '100%',
                // height: '100%',
                justifyContent: 'center',
                background: 'linear-gradient(180deg, #3E5267 0%, #1F3349 21%, #121A27 100%)',
                color: '#fff',
            }}
        >
            <div
                style={{
                    position: 'absolute',
                    top: 0,
                    paddingTop: 10,
                    paddingBottom: 10,
                    paddingLeft: 10,
                    paddingRight: 20,
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    zIndex: 1,
                    // boxShadow: 'rgb(0 0 0 / 20%) 0px 1px 15px 0px'
                }}
            >
                <div>
                    <Button
                        type="link"
                        icon="arrow-left"
                        size="large"
                        ghost
                        style={{ border: 'none' }}
                        onClick={() => {
                            props.history.push(`/tournaments/${tournamentSlug}/scores`);
                        }}
                    >
                        <Text
                            style={{
                                color: '#fff',
                                textTransform: 'uppercase',
                                fontSize: 16,
                                fontWeight: 500,
                                letterSpacing: 1,
                            }}
                        >
                            Live table scores
                        </Text>
                    </Button>
                </div>
            </div>

            <Query query={GET_TOURNAMENT_BY_SLUG} fetchPolicy="cache-and-network" notifyOnNetworkStatusChange={true} variables={{ slug: tournamentSlug }}>
                {({ loading, error, data, refetch }) => {
                    if (loading) return <CircularLoader />;
                    if (error) return <div style={styles.container}>Error: {error.message}</div>;

                    const tournamentData = data.tournaments && data.tournaments[0];
                    if (!tournamentData) {
                        return (
                            <Row gutter={24} justify="center" type="flex">
                                <Col xs={24}>
                                    <Card style={{ margin: 10 }}>
                                        <Empty
                                            image="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original"
                                            imageStyle={{
                                                height: 100,
                                            }}
                                            description={<Text style={{ fontSize: 18 }}>Tournament not found</Text>}
                                            style={{ margin: 10 }}
                                        />
                                    </Card>
                                </Col>
                            </Row>
                        );
                    } else if (tournamentData.is_public === false && !authState.user) {
                        return (
                            <Row gutter={24} justify="center" type="flex">
                                <Col xs={24}>
                                    <Card style={{ margin: 10 }}>
                                        <Empty
                                            image="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original"
                                            imageStyle={{
                                                height: 100,
                                            }}
                                            description={<Text style={{ fontSize: 18 }}>Tournament not found</Text>}
                                            style={{ margin: 10 }}
                                        />
                                    </Card>
                                </Col>
                            </Row>
                        );
                    } else if (
                        tournamentData.is_public === false &&
                        authState &&
                        authState.user &&
                        (tournamentData.organizer_id !== authState.user.id || tournamentData.owner_id !== authState.user.id) &&
                        authState.user.role !== 'admin'
                    ) {
                        return (
                            <Row gutter={24} justify="center" type="flex">
                                <Col xs={24}>
                                    <Card style={{ margin: 10 }}>
                                        <Empty
                                            image="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original"
                                            imageStyle={{
                                                height: 100,
                                            }}
                                            description={<Text style={{ fontSize: 18 }}>Tournament not found</Text>}
                                            style={{ margin: 10 }}
                                        />
                                    </Card>
                                </Col>
                            </Row>
                        );
                    } else {
                        return (
                            <div
                                style={{
                                    // flex: 1,
                                    alignItems: 'center',
                                    // padding: 20,
                                    // display: 'flex',
                                    // justifyContent: 'center',
                                    // flexDirection: 'column',

                                    transform: 'scale(0.9)',
                                    transformOrigin: 'top center',
                                    position: 'relative',
                                }}
                            >
                                <div
                                    style={{
                                        paddingTop: 30,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        width: '100%',
                                        marginBottom: 10,
                                    }}
                                >
                                    {tournamentData.logo && <img src={tournamentData.logo} alt="Logo" height={130} />}
                                </div>

                                <Subscription
                                    subscription={GET_ALL_TOURNAMENT_TABLES_TV_DISPLAY_SUBSCRIPTION}
                                    fetchPolicy="cache-and-network"
                                    notifyOnNetworkStatusChange={true}
                                    variables={{ tournament_id: tournamentData.id }}
                                    onSubscriptionData={(data, error, loading) => {
                                        console.log(data);
                                    }}
                                >
                                    {({ loading, error, data }) => {
                                        if (loading)
                                            return (
                                                <div style={{ padding: 50 }}>
                                                    <CircularLoader />
                                                </div>
                                            );
                                        if (error) return <div style={styles.container}>Error: {error.message}</div>;
                                        if ((data && !data.pool_tables) || (data && data.pool_tables.length === 0)) {
                                            return (
                                                <Row gutter={24} justify="center" type="flex">
                                                    <Col xs={24}>
                                                        <Card>
                                                            <Empty
                                                                image="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original"
                                                                imageStyle={{
                                                                    height: 100,
                                                                }}
                                                                description={<Text style={{ fontSize: 18 }}>No tables available.</Text>}
                                                                style={{ margin: 10 }}
                                                            />
                                                        </Card>
                                                    </Col>
                                                </Row>
                                            );
                                        }

                                        return (
                                            <Row gutter={24} type="flex" justify="center" style={{ width: '100%' }}>
                                                {/* <Col xs={24}>
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'flex-end',
                                                        }}
                                                    >
                                                        <Button
                                                            type="primary"
                                                            onClick={() => {
                                                                window.open(`/tournaments/${tournamentData.slug}/`);
                                                            }}
                                                        >
                                                            <Icon type="download" /> Export CSV
                                                        </Button>
                                                    </div>
                                                </Col> */}
                                                {data.pool_tables
                                                    .sort((a, b) => {
                                                        return a.label.localeCompare(b.label, undefined, {
                                                            numeric: true,
                                                            sensitivity: 'base',
                                                        });
                                                    })
                                                    .map((item, index) => {
                                                        console.log(item);
                                                        const match = item.tournament_match_table && item.tournament_match_table[0];

                                                        return (
                                                            <Col key={index} xs={24} sm={12} md={12} lg={8} xl={8} xxl={6}>
                                                                <div
                                                                    style={{
                                                                        opacity:
                                                                            (item.status === 'OPEN' && !match) || (match && match.status !== 'IN_PROGRESS')
                                                                                ? 0.5
                                                                                : 1.0,
                                                                    }}
                                                                >
                                                                    <div
                                                                        style={{
                                                                            display: 'flex',
                                                                            alignItems: 'center',
                                                                            justifyContent: 'space-between',
                                                                            padding: 5,
                                                                        }}
                                                                    >
                                                                        <Text style={{ color: '#fff', fontSize: 16, fontWeight: 700 }}>{item.label}</Text>
                                                                        {match && (
                                                                            <div>
                                                                                <Text
                                                                                    style={{
                                                                                        color: 'rgba(255,255,255,0.7)',
                                                                                        fontSize: 16,
                                                                                        fontWeight: 700,
                                                                                        marginRight: 20,
                                                                                    }}
                                                                                >
                                                                                    Match {match.match_number}
                                                                                </Text>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                    <PoolTable
                                                                        displayText={!match && item.status}
                                                                        table={{
                                                                            label: item.label,
                                                                            is_streaming_table: item.is_streaming_table,
                                                                            status: item.status,
                                                                        }}
                                                                        hoverable
                                                                        onClick={() => {
                                                                            if (
                                                                                authState &&
                                                                                authState.user &&
                                                                                (tournamentData.organizer_id === authState.user.id ||
                                                                                    tournamentData.owner_id === authState.user.id ||
                                                                                    authState.user.role === 'admin')
                                                                            ) {
                                                                                props.history.push(
                                                                                    `/tournaments/${tournamentData.slug}/tables/${item.slug}/scoring`
                                                                                );
                                                                            } else {
                                                                                props.history.push(
                                                                                    `/tournaments/${tournamentData.slug}/tables/${item.slug}/tvdisplay`
                                                                                );
                                                                            }
                                                                        }}
                                                                        tournament={tournamentData}
                                                                        matchData={
                                                                            match
                                                                                ? {
                                                                                      game_type: tournamentData.game_type,
                                                                                      match_number: match.match_number,
                                                                                      challenger1_name: match.challenger1_name,
                                                                                      challenger1_country: match.challenger1_country,
                                                                                      challenger1_skill_level: match.challenger1_skill_level,
                                                                                      challenger1_race_to: match.challenger1_race_to,
                                                                                      challenger1_score: match.challenger1_score,
                                                                                      challenger1_points: match.challenger1_points,
                                                                                      challenger2_name: match.challenger2_name,
                                                                                      challenger2_country: match.challenger2_country,
                                                                                      challenger2_skill_level: match.challenger2_skill_level,
                                                                                      challenger2_race_to: match.challenger2_race_to,
                                                                                      challenger2_score: match.challenger2_score,
                                                                                      challenger2_points: match.challenger2_points,
                                                                                  }
                                                                                : {}
                                                                        }
                                                                        status={match && match.status}
                                                                        gameType={tournamentData.game_type}
                                                                        tableSize="large"
                                                                        style={{ cursor: 'pointer' }}
                                                                        {...props}
                                                                    />
                                                                </div>
                                                            </Col>
                                                        );
                                                    })}
                                            </Row>
                                        );
                                    }}
                                </Subscription>

                                <Row gutter={16} type="flex" justify="space-between" style={{ marginTop: 15 }}>
                                    <Col xs={24} align="center">
                                        <div>
                                            <img src={poweredByLogo} alt="Powered By" width={300} />
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        );
                    }
                }}
            </Query>
        </div>
    );
}

export default withRouter(DPTournamentTableLiveScores);
