import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Form, FormItem, Select, Input, Radio, SubmitButton, ResetButton } from 'formik-antd';
import { Formik, ErrorMessage } from 'formik';
import { message, Collapse, Col, Row, Alert, Button, Tooltip, Typography, Avatar, Icon, Divider, InputNumber } from 'antd';
import * as Yup from 'yup';
import { Query, Mutation } from '@apollo/client/react/components';
import { useMutation } from '@apollo/react-hooks';
import { CREATE_LEAGUE_MUTATION, UPDATE_LEAGUE_MUTATION } from './data/mutations';
import { GET_ORGANIZATIONS_QUERY } from './data/queries';
import AddOrganizationModal from './AddOrganizationModal';
import { QuestionCircleOutlined, QuestionCircleFilled, InfoCircleFilled } from '@ant-design/icons';
import gql from 'graphql-tag';
import slugify from 'slugify';

const { TextArea } = Input;
const { Text } = Typography;
const { Option } = Select;

const styles = {
    error: { color: '#f5222d', marginTop: 10 },
};

function CreateLeagueForm(props) {
    const { organization, league, onCreated, onSkipStep } = props;
    const [loading, setLoading] = useState(false);
    const [organizationModalVisible, setOrganizationModalVisible] = useState(false);
    const [createLeague, { createLoading, createError }] = useMutation(CREATE_LEAGUE_MUTATION);
    const [updateLeague, { updateLoading, updateError }] = useMutation(UPDATE_LEAGUE_MUTATION);

    const showLoading = () => {
        const hide = message.loading('Action in progress..', 0);
        // Dismiss manually and asynchronously
        setTimeout(hide, 2500);
    };

    const formItemLayout = {
        // labelCol: { span: 6 },
        // wrapperCol: { span: 8 }
    };
    const buttonItemLayout = {
        // wrapperCol: { span: 14, offset: 6 }
    };

    // const handleSubmit = (values) => {
    // 	console.log(values);
    // };

    // const onPublicPrivateChange = (e) => {
    // 	setPublicPrivate(e.target.value);
    // };

    const SignupSchema = Yup.object().shape({
        name: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Name required'),
        short_description: Yup.string().required('Short description required'),
        league_type: Yup.string().required('Required'),
    });

    function handleSubmit(values, actions) {
        const {
            name,
            league_type,
            short_description,
            long_description,
            is_public,
            owner_id,
            operator_id,
            organization_id,
            has_team_support,
            has_division_support,
            has_challenge_support,
        } = values;
        setLoading(true);
        const slug = slugify(name, {
            replacement: '-',
            remove: /[*+~.,()'"#!:/@]/g,
            lower: true,
        });

        if (league) {
            updateLeague({
                variables: {
                    id: league.id,
                    changes: {
                        name,
                        league_type,
                        slug,
                        owner_id,
                        operator_id,
                        organization_id,
                        short_description,
                        long_description,
                        is_public,
                        has_team_support,
                        has_division_support,
                        has_challenge_support,
                    },
                },
                // refetchQueries: () => [
                // 	{
                // 		query: GET_LEAGUE_DIVISION_QUERY,
                // 		variables: {
                // 			leagueId: props.data.id,
                // 			divisionSlug: slug
                // 		},
                // 		awaitRefetchQueries: true
                // 	}
                // ]
            })
                .then((res) => {
                    actions.setSubmitting(false);
                    actions.resetForm();
                    setLoading(false);
                    message.success('Changes saved');
                    // const result = res.data.update_leagues.returning[0];
                    // props.history.push(`/league-manager/${result.slug}/dashboard`);
                })
                .catch((e) => {
                    actions.setSubmitting(false);
                    message.error((e && e.message) || JSON.stringify(e));
                    console.log(e);
                });
        } else {
            createLeague({
                variables: {
                    objects: [
                        {
                            name,
                            league_type,
                            slug,
                            owner_id: props.authState.user.id,
                            operator_id: props.authState.user.id,
                            organization_id,
                            short_description,
                            long_description,
                            is_public,
                            has_team_support,
                            has_division_support,
                            has_challenge_support,
                        },
                    ],
                },
            })
                .then((res) => {
                    actions.setSubmitting(false);
                    actions.resetForm();
                    console.log(res);
                    setLoading(false);
                    const result = res.data.insert_leagues.returning[0];
                    message.success('League created');
                    if (onCreated) {
                        onCreated(result);
                    }
                })
                .catch((e) => {
                    actions.setSubmitting(false);
                    if (e.message && e.message.includes('Uniqueness violation')) {
                        message.error('Error: There is already a league with that name. Please choose a different name.');
                    } else {
                        message.error((e && e.message) || JSON.stringify(e));
                    }

                    console.log(e);
                });
        }
    }

    function handleOrganizationModalOk(e) {
        setOrganizationModalVisible(false);
    }

    function handleOrganizationModalCancel(e) {
        setOrganizationModalVisible(false);
    }

    function handleCreateOrganization() {
        setOrganizationModalVisible(true);
    }

    return (
        <Formik
            initialValues={
                league
                    ? {
                          ...league,
                          organization_id: league.organization_id,
                      }
                    : {
                          name: '',
                          league_type: 'teams',
                          short_description: '',
                          long_description: '',
                          is_public: true,
                          has_team_support: true,
                          has_division_support: true,
                          has_challenge_support: false,
                          organization_id: undefined,
                      }
            }
            validationSchema={SignupSchema}
            onSubmit={(values, actions) => {
                console.log(values);
                handleSubmit(values, actions);
            }}
            render={({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                <Form onSubmit={handleSubmit} layout="vertical" style={{ maxWidth: 400 }}>
                    <FormItem name="name" label="League Name" required hasFeedback showValidateSuccess>
                        <Input name="name" placeholder="Name" size="large" />
                    </FormItem>
                    <FormItem label="League Type" name="league_type" required hasFeedback showValidateSuccess>
                        <Select
                            showSearch
                            name="league_type"
                            placeholder="Select a league type"
                            size="large"
                            optionFilterProp="children"
                            defaultValue="teams"
                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        >
                            {[
                                {
                                    type: 'Teams',
                                    value: 'teams',
                                },
                                {
                                    type: 'Singles',
                                    value: 'singles',
                                },
                                {
                                    type: 'Ladder',
                                    value: 'ladder',
                                },

                                {
                                    type: 'Other',
                                    value: 'other',
                                },
                            ].map((item, index) => (
                                <Option key={index} value={item.value}>
                                    {item.type}
                                </Option>
                            ))}
                        </Select>
                    </FormItem>
                    <FormItem label="Short Description" name="short_description" required hasFeedback showValidateSuccess>
                        <Input name="short_description" placeholder="Short Description" size="large" />
                    </FormItem>
                    <FormItem label="Long Description (optional)" name="long_description">
                        <TextArea rows={4} name="long_description" placeholder="Long Description" size="large" />
                    </FormItem>

                    <FormItem
                        label={
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                Associate with an organization (optional){' '}
                                <Tooltip
                                    placement="right"
                                    title={<div>Organizations are used to group multiple leagues and tournaments together under one entity.</div>}
                                >
                                    <QuestionCircleFilled style={{ marginLeft: 5 }} />
                                </Tooltip>
                            </div>
                        }
                        name="organization_id"
                    >
                        <Query query={GET_ORGANIZATIONS_QUERY} fetchPolicy="cache-and-network" notifyOnNetworkStatusChange={true}>
                            {({ loading, error, data }) => {
                                if (error) return <div>Error: {error.message}</div>;

                                return (
                                    <Select
                                        showSearch
                                        name="organization_id"
                                        placeholder="Select an organization"
                                        size="large"
                                        loading={loading}
                                        // disabled={organization && organization.id ? true : false}
                                        // defaultValue={[ 'jack', 'lucy' ]}
                                        optionFilterProp="children"
                                        optionLabelProp="children"
                                        filterOption={(input, option) => {
                                            return option.props.children.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                        }}
                                        dropdownRender={(menu) => (
                                            <div>
                                                {menu}
                                                <Divider style={{ margin: '4px 0' }} />
                                                <div
                                                    style={{
                                                        padding: '4px 8px',
                                                        cursor: 'pointer',
                                                    }}
                                                    onMouseDown={(e) => e.preventDefault()}
                                                    onClick={handleCreateOrganization}
                                                >
                                                    <Icon type="plus" /> Create Organization
                                                </div>
                                            </div>
                                        )}
                                    >
                                        {data &&
                                            data.organizations &&
                                            data.organizations
                                                .sort((a, b) => {
                                                    return a.name.localeCompare(b.name, undefined, {
                                                        numeric: true,
                                                        sensitivity: 'base',
                                                    });
                                                })
                                                .map((item, index) => (
                                                    <Option key={index} name={item.name} value={item.id}>
                                                        <Text>{item.name}</Text>
                                                    </Option>
                                                ))}
                                    </Select>
                                );
                            }}
                        </Query>
                    </FormItem>
                    <FormItem
                        name="has_team_support"
                        label={
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                Includes support for teams{' '}
                                <Tooltip placement="right" title={<div>This feature is recommended if your league includes one or more teams.</div>}>
                                    <QuestionCircleFilled style={{ marginLeft: 5 }} />
                                </Tooltip>
                            </div>
                        }
                    >
                        <Radio.Group name="has_team_support">
                            <Radio value={true}>Yes</Radio>
                            <br />
                            <Radio value={false}>No</Radio>
                        </Radio.Group>
                    </FormItem>
                    <FormItem
                        name="has_division_support"
                        label={
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                Includes support for divisions{' '}
                                <Tooltip placement="right" title={<div>This feature is recommended if your league includes one or more divisions.</div>}>
                                    <QuestionCircleFilled style={{ marginLeft: 5 }} />
                                </Tooltip>
                            </div>
                        }
                    >
                        <Radio.Group name="has_division_support">
                            <Radio value={true}>Yes</Radio>
                            <br />
                            <Radio value={false}>No</Radio>
                        </Radio.Group>
                    </FormItem>
                    <FormItem
                        name="has_challenge_support"
                        label={
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                Includes support for challenge matches{' '}
                                <Tooltip
                                    placement="right"
                                    title={
                                        <div>
                                            This feature is recommended if your league allows players or teams to challenge one another outside of a set
                                            schedule.
                                        </div>
                                    }
                                >
                                    <QuestionCircleFilled style={{ marginLeft: 5 }} />
                                </Tooltip>
                            </div>
                        }
                    >
                        <Radio.Group name="has_challenge_support">
                            <Radio value={true}>Yes</Radio>
                            <br />
                            <Radio value={false}>No</Radio>
                        </Radio.Group>
                    </FormItem>
                    <FormItem label="Access" name="is_public">
                        <Radio.Group name="is_public">
                            <Radio value={true}>Public - Anyone can view this league and request to join.</Radio>
                            <br />
                            <Radio value={false}>Private - Only users who have been sent an invite can view or join this league.</Radio>
                        </Radio.Group>
                    </FormItem>
                    <SubmitButton
                        size="large"
                        disabled={Object.keys(errors).length > 0 ? true : false}
                        type={Object.keys(errors).length > 0 ? 'danger' : 'primary'}
                        loading={loading}
                    >
                        {league ? (loading ? 'Updating...' : 'Update') : 'Create'} League
                    </SubmitButton>
                    <ResetButton size="large">Reset</ResetButton>
                    <div>
                        <Text style={styles.error}>{errors.general}</Text>
                    </div>

                    <AddOrganizationModal
                        {...props}
                        modalVisible={organizationModalVisible}
                        onModalOk={handleOrganizationModalOk}
                        onModalCancel={handleOrganizationModalCancel}
                    />
                </Form>
            )}
        />
    );
}

export default withRouter(CreateLeagueForm);

// import React from 'react';
// import { Form, Input, Button, Radio } from 'antd';

// export default class FormLayoutDemo extends React.Component {
// 	constructor() {
// 		super();
// 		this.state = {
// 			formLayout: 'horizontal'
// 		};
// 	}

// 	handleFormLayoutChange = (e) => {
// 		this.setState({ formLayout: e.target.value });
// 	};

// 	render() {
// 		const { formLayout } = this.state;
// 		const formItemLayout =
// 			formLayout === 'horizontal'
// 				? {
// 						labelCol: { span: 4 },
// 						wrapperCol: { span: 14 }
// 					}
// 				: null;
// 		const buttonItemLayout =
// 			formLayout === 'horizontal'
// 				? {
// 						wrapperCol: { span: 14, offset: 4 }
// 					}
// 				: null;
// 		return (
// 			<div>
// 				<Form layout={formLayout}>
// 					<Form.Item label="Form Layout" {...formItemLayout}>
// 						<Radio.Group defaultValue="horizontal" onChange={this.handleFormLayoutChange}>
// 							<Radio.Button value="horizontal">Horizontal</Radio.Button>
// 							<Radio.Button value="vertical">Vertical</Radio.Button>
// 							<Radio.Button value="inline">Inline</Radio.Button>
// 						</Radio.Group>
// 					</Form.Item>
// 					<Form.Item label="Field A" {...formItemLayout}>
// 						<Input placeholder="input placeholder" />
// 					</Form.Item>
// 					<Form.Item label="Field B" {...formItemLayout}>
// 						<Input placeholder="input placeholder" />
// 					</Form.Item>
// 					<Form.Item {...buttonItemLayout}>
// 						<Button type="primary">Submit</Button>
// 					</Form.Item>
// 				</Form>
// 			</div>
// 		);
// 	}
// }
