import React, { useState, useEffect, useRef } from 'react';
import { message, Avatar, Button } from 'antd';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { UPDATE_TOURNAMENT_BRACKET_MATCH_MUTATION } from '../data/mutations';
import { GET_TOURNAMENT_BRACKET_MATCH_QUERY } from '../data/queries';

import moment from 'moment-timezone';
import momentDurationFormatSetup from 'moment-duration-format';
momentDurationFormatSetup(moment);

const gameTypes = {
    ONE_POCKET: 15,
    NINE_BALL: 9,
    TEN_BALL: 10,
    STRAIGHT_POOL: 15,
    EIGHT_BALL: 15,
};

export default function BallTray(props) {
    const { matchData, tournamentData } = props;
    const [updateBracketMatch] = useMutation(UPDATE_TOURNAMENT_BRACKET_MATCH_MUTATION);
    const ballsMade = (matchData && matchData.scoreboard_balls) || {};
    const gameType = tournamentData && tournamentData.game_type;

    function markBallMade(ball, ballsMade, matchData) {
        console.log(ball);
        console.log(ballsMade);
        const isScored = ballsMade[ball] === true;
        const markingMessage = isScored === true ? `Removed ${ball} ball as scored` : `Marked ${ball} ball as scored`;

        const key = 'ballsMade';
        // message.loading({ content: markingMessage, key, duration: 1 });

        return updateBracketMatch({
            variables: {
                id: matchData.id,
                tournament_id: tournamentData.id,
                changes: {
                    scoreboard_balls: {
                        ...ballsMade,
                        [ball]: isScored ? false : true,
                    },
                },
            },
            notifyOnNetworkStatusChange: true,
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: GET_TOURNAMENT_BRACKET_MATCH_QUERY,
                    variables: { match_id: matchData.id, tournament_id: tournamentData.id },
                },
            ],
        })
            .then((data) => {
                console.log(data);
                message.success({
                    content: markingMessage,
                    key,
                    duration: 1,
                });
                return data;
            })
            .catch((error) => {
                console.error(error);
                message.info('There was an error', error);
                return error;
            });
    }

    function resetBallsMade() {
        const key = 'ballsMade';
        message.loading({ content: 'Clearing scored balls...', key, duration: 1 });
        return updateBracketMatch({
            variables: {
                id: matchData.id,
                tournament_id: tournamentData.id,
                changes: {
                    scoreboard_balls: {},
                },
            },
            notifyOnNetworkStatusChange: true,
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: GET_TOURNAMENT_BRACKET_MATCH_QUERY,
                    variables: { match_id: matchData.id, tournament_id: tournamentData.id },
                },
            ],
        })
            .then((data) => {
                console.log(data);
                message.success({
                    content: `All scored balls have been cleared`,
                    key,
                    duration: 1,
                });
                return data;
            })
            .catch((error) => {
                console.error(error);
                message.info('There was an error', error);
                return error;
            });
    }

    return (
        <React.Fragment>
            {Array.from(
                {
                    length: gameTypes[gameType] ? gameTypes[gameType] : 15,
                },
                (v, index) => {
                    const ballNum = index + 1;
                    return (
                        <Avatar
                            size={30}
                            src={require(`../../../../assets/img/table/balls/${ballNum}-ball.png`)}
                            className={ballsMade[ballNum] === true ? 'ball-scored' : ''}
                            key={index}
                        />
                    );
                }
            )}
        </React.Fragment>
    );
}
