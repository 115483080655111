import React from 'react';
import PropTypes from 'prop-types';
import { auth, db } from '../../firebase';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import { getAuthStatus } from '../../getAuthStatus';
import * as routes from '../../config/routes';
import gql from 'graphql-tag';

const withAuthorization = (condition, extraProps) => (Component) => {
    class WithAuthorization extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                credentials: false,
            };
        }

        componentDidMount() {
            const isLogin = !!localStorage.getItem('digitalpool-auth');
            if (isLogin) {
                const authUser = JSON.parse(localStorage.getItem('digitalpool-auth'));
                console.log('checking authorization', authUser);
                if (!condition(authUser)) {
                    this.props.history.push(routes.DIGITALPOOL.LOGIN);
                } else {
                    this.setState({
                        credentials: true,
                    });
                }
            } else {
                getAuthStatus()
                    .then(async (authUser) => {
                        if (authUser && authUser.uid) {
                            this.props.client
                                .query({
                                    query: gql`
                                        query get_user($uid: String!) {
                                            users(where: { uid: { _eq: $uid } }) {
                                                id
                                                first_name
                                                last_name
                                                email
                                                role
                                                uid
                                                last_seen
                                            }
                                        }
                                    `,
                                    variables: {
                                        uid: authUser.uid,
                                    },
                                })
                                .then((res) => {
                                    const foundUser = res && res.data && res.data.users && res.data.users[0];
                                    let mergedUser = _.assign({}, authUser, foundUser);

                                    if (!condition(mergedUser)) {
                                        this.props.history.push(routes.DIGITALPOOL.LOGIN);
                                    } else {
                                        this.setState({
                                            credentials: true,
                                        });
                                    }
                                })
                                .catch((error) => {
                                    console.error(error);
                                    this.props.history.push(routes.DIGITALPOOL.LOGIN);
                                });
                        } else {
                            console.log('No user authenticated');
                            // this.props.alert.show('You are not authorized to view that page.');
                            this.props.history.push(routes.DIGITALPOOL.LOGIN);
                        }
                    })
                    .catch((err, context) => {
                        console.log(err);
                        this.props.history.push(routes.DIGITALPOOL.LOGIN);
                    });
            }
        }

        render() {
            return this.state.credentials === true ? <Component {...this.props} {...extraProps} /> : null;
        }
    }

    WithAuthorization.contextTypes = {
        authUser: PropTypes.object,
    };

    return withRouter(WithAuthorization);
};

export default withAuthorization;
