import React from 'react';
import NewsFeed from './feed/NewsFeed';
import SectionHeader from '../../components/SectionHeader';
import SectionContent from '../../components/SectionContent';
import SectionTitle from '../../components/SectionTitle';
import { Tabs } from 'antd';
import useBreakpoint from 'use-breakpoint';
import { defaultBreakpoints } from '../../utils/defaultBreakpoints';

const { TabPane } = Tabs;

function Feeds(props) {
	const { authState } = props;
	const { breakpoint, maxWidth, minWidth } = useBreakpoint(defaultBreakpoints);

	const styles = {
		tab: {
			// backgroundColor: '#fff',
			padding: breakpoint === 'mobile' ? 0 : '10px 30px'
		}
	};

	function callback(key) {
		// console.log(key);
	}

	return (
		<React.Fragment>
			{/* <SectionHeader title={props.title} subtitle={props.subtitle} /> */}
			<SectionTitle title={props.title} height={breakpoint === 'mobile' ? 80 : 120} />
			<SectionContent padding="0px">
				<Tabs
					animated={{ inkBar: true, tabPane: false }}
					onChange={callback}
					defaultActiveKey="1"
					// type="card"
					tabBarStyle={
						breakpoint === 'mobile' ? (
							{ textTransform: 'uppercase', textAlign: 'center' }
						) : (
							{ paddingLeft: 40, paddingRight: 40, textTransform: 'uppercase' }
						)
					}
					// tabBarGutter={5}
				>
					<TabPane tab="News" key="1" style={styles.tab}>
						<NewsFeed />
					</TabPane>
					<TabPane tab="Social" key="2" style={styles.tab}>
						<NewsFeed />
					</TabPane>
					<TabPane tab="Activity" key="3" style={styles.tab}>
						<NewsFeed />
					</TabPane>
				</Tabs>
			</SectionContent>
		</React.Fragment>
	);
}

export default Feeds;
