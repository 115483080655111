import React, { useState, useEffect, useRef } from 'react';
import { message, Avatar, Radio, Typography, Modal, Tag, Tooltip, Row, Col, Button, Icon } from 'antd';
import { withRouter, Link } from 'react-router-dom';
import { Form, FormItem, InputNumber, Checkbox } from 'formik-antd';
import { Formik, ErrorMessage } from 'formik';
import { useMutation, useQuery } from '@apollo/react-hooks';
import {
    UPDATE_TOURNAMENT_BRACKET_MATCH_MUTATION,
    UPDATE_TOURNAMENT_MATCH_GAME,
    CREATE_TOURNAMENT_MATCH_GAME,
    DELETE_TOURNAMENT_MATCH_GAME,
    RESET_TOURNAMENT_MATCH_GAMES,
} from './data/mutations';
import { GET_TOURNAMENT_BRACKET_MATCH_QUERY, GET_TOURNAMENT_MATCH_GAMES_QUERY, GET_EVENT_LIVE_TABLE_QUERY } from './data/queries';
import { Query, Subscription } from '@apollo/client/react/components';
import CircleFlag from '../../../components/CircleFlag';
import CircleStateFlag from '../../../components/CircleStateFlag';
import CircularLoader from '../../../components/CircularLoader';
import { getGameType, getCountryName, displayEnumType } from '../../../utils';
import axios from 'axios';
import apiEndPoint from '../../../utils/apiEndpoint';
import slugify from 'slugify';
import RackTracker from './RackTracker';
import { QuestionCircleFilled, CheckOutlined } from '@ant-design/icons';
import numeral from 'numeral';
import Ripples from 'react-ripples';
import dpLogoWatermarkColor from '../../../assets/img/dp-logo-watermark-color.png';
import moment from 'moment-timezone';
import momentDurationFormatSetup from 'moment-duration-format';
momentDurationFormatSetup(moment);

const { Text, Title } = Typography;
const { confirm } = Modal;

const styles = {
    container: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#fff',
    },
};

const gameTypes = {
    ONE_POCKET: 15,
    NINE_BALL: 9,
    TEN_BALL: 10,
    STRAIGHT_POOL: 15,
    EIGHT_BALL: 15,
};

export default function EventScoreboard(props) {
    const { client, backUrl, matchData, tournamentData, tableData, subscribeToMore, subscribeToNewData } = props;
    const [endMatchDisabled, setEndMatchDisabled] = useState(true);
    const [updateBracketMatch, updateBracketMatchRes] = useMutation(UPDATE_TOURNAMENT_BRACKET_MATCH_MUTATION);
    // const [scoringStarted, setScoringStarted] = useState(false);
    const [scoringStarted, setScoringStarted] = useState(matchData.is_scoring_started || false);
    const [userCheckMatchEnding, setUserCheckMatchEnding] = useState(false);
    const [thumb, setThumb] = useState();
    const [matchEnded, setMatchEnded] = useState(false);
    const [playerOneScore, setPlayerOneScore] = useState(matchData.challenger1_score || 0);
    const [playerTwoScore, setPlayerTwoScore] = useState(matchData.challenger2_score || 0);
    const formRef = useRef();

    console.log('match data', matchData);
    console.log('is scoring started', scoringStarted);

    const stats = ['Break and run', 'Table run'];
    const table = tableData;
    let challenger1RaceTo;
    let challenger2RaceTo;
    if (matchData.identifier && matchData.identifier.includes('L')) {
        challenger1RaceTo = matchData.challenger1_race_to || (matchData.tournament && matchData.tournament.losers_race_to) || 0;
        challenger2RaceTo = matchData.challenger2_race_to || (matchData.tournament && matchData.tournament.losers_race_to) || 0;
    } else {
        challenger1RaceTo = matchData.challenger1_race_to || (matchData.tournament && matchData.tournament.winners_race_to) || 0;
        challenger2RaceTo = matchData.challenger2_race_to || (matchData.tournament && matchData.tournament.winners_race_to) || 0;
    }

    let duration;
    if (matchData.start_time && matchData.end_time) {
        var start = moment(matchData.start_time);
        var end = moment(matchData.end_time);
        duration = moment.duration(end.diff(start, 'milliseconds')).format('h [hrs] m [mins]');
        // duration = moment.duration(end.diff(start, 'milliseconds')).format('hh:mm');
    } else {
        duration = moment.duration(moment.utc().diff(matchData.start_time, 'milliseconds')).format('h [hrs] m [mins]');
        // duration = moment.duration(moment.utc().diff(match.start_time, 'milliseconds')).format('hh:mm');
    }
    const ballsMade = matchData.scoreboard_balls || {};

    useEffect(() => {
        let unsubscribe;
        if (subscribeToNewData) {
            unsubscribe = subscribeToNewData(subscribeToMore);
        }
        return () => {
            if (unsubscribe) {
                unsubscribe();
            }
        };
    }, []);

    useEffect(() => {
        if (matchData) {
            console.log('match data effect', matchData);
            if (matchData.is_scoring_started !== scoringStarted) {
                setMatchEnded(false);
                setUserCheckMatchEnding(false);
                setScoringStarted(matchData.is_scoring_started || false);
                setPlayerOneScore(0);
                setPlayerTwoScore(0);
                setScoringStarted(false);
                console.log('SCORING NOT STARTED');
            } else if (matchData.status === 'PENDING') {
                setEndMatchDisabled(true);
            } else if (matchData.status !== 'PENDING' && checkForWinner(matchData) === true && matchData.status !== 'COMPLETED') {
                setEndMatchDisabled(false);
                showConfirmEndMatch(matchData, tournamentData.id);
            } else {
                setEndMatchDisabled(true);
            }

            if (matchData.challenger1_score || matchData.challenger2_score) {
                setPlayerOneScore(matchData.challenger1_score || 0);
                setPlayerTwoScore(matchData.challenger2_score || 0);
            }
        }
    }, [matchData]);

    function getVideoThumbnail() {
        const tableSlug = slugify(matchData.table_name, {
            replacement: '-',
            remove: /[*+~.,()'"#!:/@]/g,
            lower: true,
        });

        const payload = {
            tournament_id: tournamentData.id,
            match_id: matchData.id,
            table_id: matchData.table_id,
            table_name: matchData.table_name,
            table_slug: tableSlug,
            game_type: tournamentData.game_type,
            player: matchData.challenger1_name,
            opponent: matchData.challenger2_name,
            event: 'thumb',
            webhook_url: 'https://us-central1-digital-pool.cloudfunctions.net/matchStatusUpdate',
            callback_url: 'https://www.onsitepool.com/nblusa/digitalpool',
        };

        // const url = 'https://www.onsitepool.com/nblusa/digitalpool/';
        // const options = {
        //     method: 'POST',
        //     headers: {
        //         Accept: 'application/json',
        //         'Content-Type': 'application/json;charset=UTF-8',
        //     },
        //     body: JSON.stringify(payload),
        // };

        // fetch(url, options).then((response) => {
        //     console.log(response.status);
        // });

        return axios
            .post(`${apiEndPoint}matchStatusUpdate`, payload)
            .then((res) => {
                console.log(res.data);

                if (res.data && res.data.success === true) {
                    // message.success(JSON.stringify(res.data));

                    if (res.data.data) {
                        setThumb(res.data.data);
                    }
                } else {
                    message.warning(JSON.stringify(res.data));
                }

                return res.data;
            })
            .catch((error) => {
                console.log(error);
                message.error(`There was an error: ${JSON.stringify(error)}`);
                return error;
            });
    }

    async function startMatch(matchData, tournamentId) {
        console.log(matchData);

        await resetGames(matchData);

        const startTime = moment.utc();
        const changes = {
            start_time: startTime,
            status: 'IN_PROGRESS',
            progress: 0,
            is_scoring_started: true,
        };

        const tableSlug = slugify(matchData.table_name, {
            replacement: '-',
            remove: /[*+~.,()'"#!:/@]/g,
            lower: true,
        });

        const payload = {
            tournament_id: tournamentId,
            tournament_bracket_id: matchData.id,
            table_id: matchData.table_id,
            table_name: matchData.table_name,
            table_slug: tableSlug,
            game_type: tournamentData.game_type,
            player: matchData.challenger1_name,
            opponent: matchData.challenger2_name,
            event: 'start',
            webhook_url: 'https://us-central1-digital-pool.cloudfunctions.net/matchStatusUpdate',
            callback_url: 'https://www.onsitepool.com/nblusa/digitalpool/',
        };

        return client
            .mutate({
                mutation: UPDATE_TOURNAMENT_BRACKET_MATCH_MUTATION,
                variables: {
                    id: matchData.id,
                    tournament_id: tournamentId,
                    changes: changes,
                },
                notifyOnNetworkStatusChange: true,
                awaitRefetchQueries: true,
                // refetchQueries: [
                //     {
                //         query: GET_TOURNAMENT_BRACKET_MATCH_BY_HASH_ID,
                //         variables: { hash_id: match.hash_id, tournament_id: tournamentID },
                //     },
                // ],
            })
            .then((data) => {
                console.log(data);
                message.success(`Match started at ${moment.utc(startTime).local().format('LT')}`);

                createFirstGame(matchData);

                return axios
                    .post(`${apiEndPoint}matchStatusUpdate`, payload)
                    .then((res) => {
                        console.log(res.data);

                        // if (res.data && res.data.success === true) {
                        //     message.success(JSON.stringify(res.data));
                        // } else {
                        //     message.warning(JSON.stringify(res.data));
                        // }

                        return res.data;
                    })
                    .catch((error) => {
                        console.log(error);
                        // message.error(`There was an error: ${JSON.stringify(error)}`);
                        return error;
                    });
            })
            .catch((error) => {
                console.error(error);
                message.error(`There was an error: ${JSON.stringify(error)}`);
                throw error;
            });
    }

    function endMatch(match, tournamentId) {
        const endTime = moment.utc();
        const changes = {
            end_time: endTime,
            status: 'PENDING',
            progress: 100,
            table_name: match.table_name && match.table_name,
            table_id: match.table_id && match.table_id,
        };

        const tableSlug = slugify(matchData.table_name, {
            replacement: '-',
            remove: /[*+~.,()'"#!:/@]/g,
            lower: true,
        });

        const payload = {
            tournament_id: tournamentId,
            match_id: matchData.id,
            table_id: matchData.table_id,
            table_name: matchData.table_name,
            table_slug: tableSlug,
            game_type: tournamentData.game_type,
            player: matchData.challenger1_name,
            opponent: matchData.challenger2_name,
            event: 'split',
            webhook_url: 'https://us-central1-digital-pool.cloudfunctions.net/matchStatusUpdate',
            callback_url: 'https://www.onsitepool.com/nblusa/digitalpool',
        };

        return client
            .mutate({
                mutation: UPDATE_TOURNAMENT_BRACKET_MATCH_MUTATION,
                variables: {
                    id: match.id,
                    tournament_id: tournamentId,
                    changes: changes,
                },
                notifyOnNetworkStatusChange: true,
                awaitRefetchQueries: true,
                // refetchQueries: [
                //     {
                //         query: GET_TOURNAMENT_BRACKET_MATCH_BY_HASH_ID,
                //         variables: { hash_id: match.hash_id, tournament_id: tournamentID },
                //     },
                // ],
            })
            .then((data) => {
                console.log(data);
                message.success(`Match scores submitted for TD approval at ${moment.utc(endTime).local().format('LT')}`);

                axios
                    .post(
                        'https://elicit.waypointsoftware.io/capture_post.php?xAuthentication=09e648487cf9156231d88645459d5ef0',
                        {
                            match_id: matchData.id,
                        },
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data',
                            },
                        }
                    )
                    .then((response) => console.log(response))
                    .catch((error) => {
                        console.error('There was an error!', error);
                    });

                return axios
                    .post(`${apiEndPoint}matchStatusUpdate`, payload)
                    .then((res) => {
                        console.log(res.data);
                        // message.success(JSON.stringify(res.data));
                        return res.data;
                    })
                    .catch((error) => {
                        console.log(error);
                        // message.error(`There was an error: ${JSON.stringify(error)}`);
                        return error;
                    });
            })
            .catch((error) => {
                console.error(error);
                message.error(`There was an error: ${JSON.stringify(error)}`);
                throw error;
            });
    }

    function showConfirmEndMatch(matchData, tournamentId) {
        const { winner, loser } = determineMatchWinner(matchData);

        setUserCheckMatchEnding(true);

        // confirm({
        //     title: (
        //         <React.Fragment>
        //             Did {winner.name} win the match {winner.score}-{loser.score}? Please verify the scores are correct and submit for Tournament Director
        //             approval.
        //         </React.Fragment>
        //     ),
        //     centered: true,
        //     transitionName: 'fade',
        //     maskTransitionName: 'none',
        //     // onOk() {
        //     // 	return new Promise((resolve, reject) => {
        //     // 		setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
        //     // 	}).catch(() => console.log('Oops errors!'));
        //     // },
        //     onOk() {
        //         endMatch(matchData, tournamentId);
        //     },
        //     onCancel() {},
        //     okText: 'Submit Scores',
        //     cancelText: 'Cancel',
        // });
    }

    function checkForWinner(matchData) {
        let challenger1RaceTo;
        let challenger2RaceTo;
        console.log('match data', matchData);
        console.log('p1 score', playerOneScore);
        console.log('p2 score', playerTwoScore);
        if (matchData.identifier && matchData.identifier.includes('L')) {
            challenger1RaceTo = matchData.challenger1_race_to || (matchData.tournament && matchData.tournament.losers_race_to) || 0;
            challenger2RaceTo = matchData.challenger2_race_to || (matchData.tournament && matchData.tournament.losers_race_to) || 0;
        } else {
            challenger1RaceTo = matchData.challenger1_race_to || (matchData.tournament && matchData.tournament.winners_race_to) || 0;
            challenger2RaceTo = matchData.challenger2_race_to || (matchData.tournament && matchData.tournament.winners_race_to) || 0;
        }

        if (matchData.challenger1_score >= challenger1RaceTo || matchData.challenger2_score >= challenger2RaceTo) {
            return true;
        } else {
            return false;
        }
    }

    function determineMatchWinner(matchData) {
        let challenger1RaceTo;
        let challenger2RaceTo;
        if (matchData.identifier && matchData.identifier.includes('L')) {
            challenger1RaceTo = matchData.challenger1_race_to || (matchData.tournament && matchData.tournament.losers_race_to) || 0;
            challenger2RaceTo = matchData.challenger2_race_to || (matchData.tournament && matchData.tournament.losers_race_to) || 0;
        } else {
            challenger1RaceTo = matchData.challenger1_race_to || (matchData.tournament && matchData.tournament.winners_race_to) || 0;
            challenger2RaceTo = matchData.challenger2_race_to || (matchData.tournament && matchData.tournament.winners_race_to) || 0;
        }
        let winner;
        let loser;

        if (playerOneScore >= challenger1RaceTo) {
            winner = {
                id: matchData.challenger1_id,
                name: matchData.challenger1_name,
                country: matchData.challenger1_country,
                score: playerOneScore,
                is_winner: true,
            };
            loser = {
                id: matchData.challenger2_id,
                name: matchData.challenger2_name,
                country: matchData.challenger2_country,
                score: playerTwoScore,
                is_winner: false,
            };
        } else if (playerTwoScore >= challenger2RaceTo) {
            winner = {
                id: matchData.challenger2_id,
                name: matchData.challenger2_name,
                country: matchData.challenger2_country,
                score: playerTwoScore,
                is_winner: true,
            };
            loser = {
                id: matchData.challenger1_id,
                name: matchData.challenger1_name,
                country: matchData.challenger1_country,
                score: playerOneScore,
                is_winner: false,
            };
        }

        console.log({
            winner,
            loser,
        });

        return {
            winner,
            loser,
        };
    }

    function createFirstGame(matchData) {
        console.log(matchData);
        return client
            .mutate({
                mutation: CREATE_TOURNAMENT_MATCH_GAME,
                variables: {
                    objects: [
                        {
                            tournament_id: tournamentData.id,
                            tournament_bracket_id: matchData.id,
                            match_number: matchData.match_number,
                            game_number: 0,
                            player_id: matchData.challenger1_id,
                            player_score: 0,
                            player_points: 0,
                            player_is_winner: false,
                            player_break_run: false,
                            player_runout: false,
                            opponent_id: matchData.challenger2_id,
                            opponent_score: 0,
                            opponent_points: 0,
                            opponent_is_winner: false,
                            opponent_break_run: false,
                            opponent_runout: false,
                            status: 'IN_PROGRESS',
                            progress: 0,
                            duration: null,
                            start_time: moment.utc(),
                            end_time: moment.utc(),
                            table_id: matchData.table_id,
                        },
                    ],
                },
                notifyOnNetworkStatusChange: true,
                awaitRefetchQueries: true,
                refetchQueries: [
                    {
                        query: GET_TOURNAMENT_MATCH_GAMES_QUERY,
                        variables: { tournament_id: tournamentData.id, tournament_bracket_id: matchData.id },
                    },
                ],
            })
            .then((data) => {
                console.log(data);
                return data;

                // message.success({
                // 	content: `${text} updated`,
                // 	key,
                // 	duration: 1
                // });
            })
            .catch((error) => {
                console.error(error);
                message.info('There was an error', error);
                return error;
            });
    }

    function resetGames(matchData) {
        return client
            .mutate({
                mutation: RESET_TOURNAMENT_MATCH_GAMES,
                variables: {
                    tournament_id: tournamentData.id,
                    tournament_bracket_id: matchData.id,
                },
                notifyOnNetworkStatusChange: true,
                awaitRefetchQueries: true,
                refetchQueries: [
                    {
                        query: GET_TOURNAMENT_MATCH_GAMES_QUERY,
                        variables: { tournament_id: tournamentData.id, tournament_bracket_id: matchData.id },
                    },
                ],
            })
            .then((data) => {
                console.log(data);
                return data;
            })
            .catch((error) => {
                console.error(error);
                message.info('There was an error', error);
                return error;
            });
    }

    function updateStats(currentGame, stats) {
        if (currentGame.id) {
            const changes = {
                player_break_run: stats ? stats.playerBreakRun : false,
                player_runout: stats ? stats.playerRunout : false,
                opponent_break_run: stats ? stats.opponentBreakRun : false,
                opponent_runout: stats ? stats.opponentRunout : false,
            };

            updateGame(currentGame.id, changes);
        }
    }

    function addGame(challenger, newScore, matchData, tournamentData, gameData, stats) {
        console.log({
            challenger,
            newScore,
            matchData,
            tournamentData,
            gameData,
        });

        const currentGame = gameData && gameData[gameData.length - 1];

        console.log('CURRENT GAME', currentGame);

        return client
            .mutate({
                mutation: CREATE_TOURNAMENT_MATCH_GAME,
                variables: {
                    objects: [
                        {
                            tournament_id: tournamentData.id,
                            tournament_bracket_id: matchData.id,
                            match_number: matchData.match_number,
                            game_number: playerOneScore + playerTwoScore + 1,
                            player_id: matchData.challenger1_id,
                            player_score: challenger === 'challenger1' ? newScore : playerOneScore,
                            player_points: matchData.challenger1_points,
                            player_is_winner: challenger === 'challenger1' ? true : false,
                            player_break_run: stats ? stats.playerBreakRun : false,
                            player_runout: stats ? stats.playerRunout : false,
                            player_tpa: currentGame && currentGame.player_tpa,
                            player_balls_pocketed: currentGame && currentGame.player_balls_pocketed,
                            player_unforced_errors: currentGame && currentGame.player_unforced_errors,
                            opponent_id: matchData.challenger2_id,
                            opponent_score: challenger === 'challenger2' ? newScore : playerTwoScore,
                            opponent_points: matchData.challenger2_points,
                            opponent_is_winner: challenger === 'challenger2' ? true : false,
                            opponent_break_run: stats ? stats.opponentBreakRun : false,
                            opponent_runout: stats ? stats.opponentRunout : false,
                            opponent_tpa: currentGame && currentGame.opponent_tpa,
                            opponent_balls_pocketed: currentGame && currentGame.opponent_balls_pocketed,
                            opponent_unforced_errors: currentGame && currentGame.opponent_unforced_errors,
                            status: 'COMPLETED',
                            progress: 100,
                            duration: null,
                            start_time: moment.utc(),
                            end_time: moment.utc(),
                            table_id: matchData.table_id,
                        },
                    ],
                },
                notifyOnNetworkStatusChange: true,
                awaitRefetchQueries: true,
                refetchQueries: [
                    {
                        query: GET_TOURNAMENT_MATCH_GAMES_QUERY,
                        variables: { tournament_id: tournamentData.id, tournament_bracket_id: matchData.id },
                    },
                ],
            })
            .then((data) => {
                console.log(data);

                if (challenger === 'challenger1') {
                    setPlayerOneScore(newScore);
                } else {
                    setPlayerTwoScore(newScore);
                }
                return data;

                // message.success({
                // 	content: `${text} updated`,
                // 	key,
                // 	duration: 1
                // });
            })
            .catch((error) => {
                console.error(error);
                message.info('There was an error', error);
                return error;
            });
    }

    function removeGame(challenger, matchData, tournamentData, gameData) {
        console.log({
            challenger,
            matchData,
            tournamentData,
            gameData,
        });

        const currentGame = gameData && gameData[gameData.length - 1];

        console.log('remove game', currentGame);

        return client
            .mutate({
                mutation: DELETE_TOURNAMENT_MATCH_GAME,
                variables: {
                    id: currentGame.id,
                    tournament_id: tournamentData.id,
                    tournament_bracket_id: matchData.id,
                },
                notifyOnNetworkStatusChange: true,
                awaitRefetchQueries: true,
                refetchQueries: [
                    {
                        query: GET_TOURNAMENT_MATCH_GAMES_QUERY,
                        variables: { tournament_id: tournamentData.id, tournament_bracket_id: matchData.id },
                    },
                ],
            })
            .then((data) => {
                console.log(data);
                if (challenger === 'challenger1') {
                    setPlayerOneScore(playerOneScore - 1);
                } else {
                    setPlayerTwoScore(playerTwoScore - 1);
                }
                return data;

                // message.success({
                // 	content: `${text} updated`,
                // 	key,
                // 	duration: 1
                // });
            })
            .catch((error) => {
                console.error(error);
                message.info('There was an error', error);
                return error;
            });
    }

    function getMatchProgress(matchData) {
        const totalRace = parseInt(challenger1RaceTo + challenger2RaceTo);
        const matchProgress =
            matchData.status === 'COMPLETED'
                ? 100
                : parseInt(playerOneScore) >= totalRace || parseInt(playerTwoScore) >= totalRace
                ? 100
                : (((playerOneScore + playerTwoScore) / totalRace) * 100).toFixed(0);
        return parseInt(matchProgress);
    }

    function incrementPlayerScore(challenger, score, matchData, gameData, stats) {
        const matchProgress = getMatchProgress(matchData);
        const newScore = score + 1;
        const totalScore = newScore;
        const changes = {
            ...(challenger === 'challenger1' ? { challenger1_score: newScore } : { challenger2_score: newScore }),
            progress: matchProgress,
        };
        console.log('total score', totalScore);
        updateMatch(changes, matchData)
            .then((result) => {
                console.log(result);
                addGame(challenger, newScore, matchData, tournamentData, gameData, stats);
            })
            .catch((error) => {
                console.log(error);
                message.error('There was an error', error);
            });
    }

    function decrementPlayerScore(challenger, score, matchData, gameData) {
        const matchProgress = getMatchProgress(matchData);
        const newScore = score - 1;
        const totalScore = playerOneScore + playerTwoScore - 1;
        const changes = {
            ...(challenger === 'challenger1' ? { challenger1_score: newScore } : { challenger2_score: newScore }),
            ...(totalScore === 0 && { is_scoring_started: false }),
            progress: matchProgress,
        };

        console.log('total score', totalScore);
        updateMatch(changes, matchData)
            .then((result) => {
                console.log(result);
                if (totalScore > 0) {
                    removeGame(challenger, matchData, tournamentData, gameData);
                } else {
                    console.log('reset games');
                    resetGames(matchData);
                }
            })
            .catch((error) => {
                console.log(error);
                message.error('There was an error', error);
            });
    }

    function setPlayerTurn(player) {
        let currentPlayer;
        if (player === 'challenger1') {
            currentPlayer = matchData.challenger1_name;
        } else {
            currentPlayer = matchData.challenger2_name;
        }

        return updateBracketMatch({
            variables: {
                id: matchData.id,
                tournament_id: tournamentData.id,
                changes: {
                    ...(player === 'challenger1'
                        ? { challenger1_is_playing: true, challenger2_is_playing: false }
                        : { challenger2_is_playing: true, challenger1_is_playing: false }),
                },
            },
            notifyOnNetworkStatusChange: true,
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: GET_TOURNAMENT_BRACKET_MATCH_QUERY,
                    variables: { match_id: matchData.id, tournament_id: tournamentData.id },
                },
            ],
        })
            .then((data) => {
                const key = 'playerTurn';

                console.log(data);
                message.success({
                    content: `${currentPlayer} is at the table`,
                    key,
                    duration: 1,
                });
                return data;
            })
            .catch((error) => {
                console.error(error);
                message.info('There was an error', error);
                return error;
            });
    }

    function updateMatch(changes, matchData) {
        console.log(matchData);

        if (matchData.eventSlug) {
            return updateBracketMatch({
                variables: {
                    id: matchData.id,
                    tournament_id: tournamentData.id,
                    changes: changes,
                },
                // awaitRefetchQueries: true,
                // refetchQueries: [
                //     {
                //         query: GET_TOURNAMENT_BRACKET_MATCH_QUERY,
                //         variables: { match_id: matchData.id, tournament_id: tournamentData.id },
                //     },
                // ],
                optimisticResponse: {
                    updateMatch: {
                        __typename: 'tournament_brackets_mutation_response',
                        returning: {
                            ...changes,
                        },
                    },
                },
                update: (cache, { data }) => {
                    console.log({
                        cache,
                        data,
                    });
                    // Get the current cached data.
                    const existingMatchData = client.readQuery({
                        // The cached query key is the same as the name of the GQL schema
                        query: GET_EVENT_LIVE_TABLE_QUERY,
                        variables: { event_slug: matchData.eventSlug, table_slug: matchData.tableSlug },
                    });

                    // Now we combine the optimisticResponse we passed in earlier and the existing data
                    const newMatches = [data.update_tournament_brackets, existingMatchData.touraments];

                    console.log('new matches', newMatches);
                    // Finally we overwrite the cache
                    cache.writeQuery({
                        query: GET_EVENT_LIVE_TABLE_QUERY,
                        variables: { event_slug: matchData.eventSlug, table_slug: matchData.tableSlug },
                        data: newMatches,
                    });
                },
            })
                .then((data) => {
                    console.log(data);
                    // handleMatchUpdate(matchData);
                    return data;
                    // message.success({
                    // 	content: `${text} updated`,
                    // 	key,
                    // 	duration: 1
                    // });
                })
                .catch((error) => {
                    console.error(error);
                    message.info('There was an error', error);
                    return error;
                });
        } else {
            return updateBracketMatch({
                variables: {
                    id: matchData.id,
                    tournament_id: tournamentData.id,
                    changes: changes,
                },
                notifyOnNetworkStatusChange: true,
                awaitRefetchQueries: true,
                refetchQueries: [
                    {
                        query: GET_TOURNAMENT_BRACKET_MATCH_QUERY,
                        variables: { match_id: matchData.id, tournament_id: tournamentData.id },
                    },
                ],
            })
                .then((data) => {
                    console.log(data);
                    // handleMatchUpdate(matchData);
                    return data;
                    // message.success({
                    // 	content: `${text} updated`,
                    // 	key,
                    // 	duration: 1
                    // });
                })
                .catch((error) => {
                    console.error(error);
                    message.info('There was an error', error);
                    return error;
                });
        }

        // const key = 'scores';
        // message.loading({ content: `Updating ${text}...`, key, duration: 1 });
        // return client
        //     .mutate({
        //         mutation: UPDATE_TOURNAMENT_BRACKET_MATCH_MUTATION,
        //         variables: {
        //             id: matchData.id,
        //             tournament_id: tournamentData.id,
        //             changes: changes,
        //         },
        //         notifyOnNetworkStatusChange: true,
        //         awaitRefetchQueries: true,
        //         refetchQueries: [
        //             {
        //                 query: GET_TOURNAMENT_BRACKET_MATCH_QUERY,
        //                 variables: { match_id: matchData.id, tournament_id: tournamentData.id },
        //             },
        //         ],
        //     })
        //     .then((data) => {
        //         console.log(data);
        //         // handleMatchUpdate(matchData);
        //         return data;
        //         // message.success({
        //         // 	content: `${text} updated`,
        //         // 	key,
        //         // 	duration: 1
        //         // });
        //     })
        //     .catch((error) => {
        //         console.error(error);
        //         message.info('There was an error', error);
        //         return error;
        //     });
    }

    function showConfirmStats(challenger, score, matchData, gameData) {
        const newScore = score + 1;
        const currentPlayer = challenger === 'challenger1' ? matchData.challenger1_name : matchData.challenger2_name;
        const radioStyle = {
            display: 'block',
            height: '30px',
            lineHeight: '30px',
        };

        let playerBreakRun = false;
        let playerRunout = false;
        let opponentBreakRun = false;
        let opponentRunout = false;
        let stats = {};

        function onChangeStats(e) {
            console.log(e.target.value);
            const breakRun = e.target.value === 'break_run';
            const runout = e.target.value === 'run_out';
            const none = e.target.value === 'none';

            console.log(breakRun);
            console.log(runout);
            console.log(challenger);

            if (none) {
                playerBreakRun = true;
                opponentBreakRun = false;
                playerRunout = false;
                opponentRunout = false;
            }

            if (challenger === 'challenger1') {
                if (breakRun === true) {
                    console.log('player break and run');
                    playerBreakRun = true;
                    opponentBreakRun = false;
                } else if (runout === true) {
                    console.log('player runout');
                    playerRunout = true;
                    opponentRunout = false;
                } else {
                    playerBreakRun = false;
                    opponentBreakRun = false;
                }
            } else if (challenger === 'challenger2') {
                if (breakRun === true) {
                    console.log('opponent break and run');
                    playerBreakRun = false;
                    opponentBreakRun = true;
                } else if (runout === true) {
                    console.log('opponent runout');
                    playerRunout = false;
                    opponentRunout = true;
                } else {
                    playerRunout = false;
                    opponentRunout = false;
                }
            }

            stats = {
                playerBreakRun,
                playerRunout,
                opponentBreakRun,
                opponentRunout,
            };
            console.log(stats);
        }

        confirm({
            title: `Did ${currentPlayer} get a Break and Run or Table Run this game?`,
            centered: true,

            transitionName: 'fade',
            maskTransitionName: 'none',
            content: (
                <div>
                    <Radio.Group onChange={onChangeStats} defaultValue="none">
                        <Radio style={radioStyle} value="break_run">
                            Break and Run (BR)
                        </Radio>
                        <Radio style={radioStyle} value="run_out">
                            Table Run (RO)
                        </Radio>
                        <Radio style={radioStyle} value="none">
                            None
                        </Radio>
                    </Radio.Group>
                </div>
            ),
            onOk() {
                console.log('ok');
                // incrementPlayerScore(challenger, score, matchData, gameData, stats);
            },
            onCancel() {
                console.log('cancel');
            },
            okText: 'Save',
            cancelText: 'Go back',
        });
    }

    function startScoring() {
        setScoringStarted(true);
    }

    function handleMatchUpdate(matchData, tournamentId) {
        console.log('match', matchData);

        if (checkForWinner(matchData) === true) {
            setEndMatchDisabled(false);
            showConfirmEndMatch(matchData, tournamentId);
        } else {
            setEndMatchDisabled(true);
        }
    }

    function calculateTPA(ballsPocketed, errors) {
        return numeral(ballsPocketed / (ballsPocketed + errors)).format('0.000');
    }

    function handleSubmit(player, playerData, stat, value, values, gameData) {
        console.log({
            player,
            playerData,
            stat,
            value,
            values,
            gameData,
        });

        const gameId = gameData && gameData[gameData.length - 1] && gameData[gameData.length - 1].id;
        if (gameId) {
            const playerBallsPocketed = values.player_balls_pocketed;
            const playerUnforcedErrors = values.player_unforced_errors;
            const opponentBallsPocketed = values.opponent_balls_pocketed;
            const opponentUnforcedErrors = values.opponent_unforced_errors;
            const changes = {
                player_tpa: calculateTPA(playerBallsPocketed, playerUnforcedErrors),
                player_balls_pocketed: playerBallsPocketed,
                player_unforced_errors: playerUnforcedErrors,
                opponent_tpa: calculateTPA(opponentBallsPocketed, opponentUnforcedErrors),
                opponent_balls_pocketed: opponentBallsPocketed,
                opponent_unforced_errors: opponentUnforcedErrors,
            };

            updateGame(gameId, changes);
        }
    }

    function updateGame(gameId, changes) {
        console.log({
            gameId,
            changes,
        });
        return client
            .mutate({
                mutation: UPDATE_TOURNAMENT_MATCH_GAME,
                variables: {
                    id: gameId,
                    tournament_id: tournamentData.id,
                    changes: changes,
                },
                notifyOnNetworkStatusChange: true,
                awaitRefetchQueries: true,
                refetchQueries: [
                    {
                        query: GET_TOURNAMENT_MATCH_GAMES_QUERY,
                        variables: { tournament_id: tournamentData.id, tournament_bracket_id: matchData.id },
                    },
                ],
            })
            .then((data) => {
                console.log(data);
                // message.success('Game updated');
                return data;
            })
            .catch((error) => {
                console.error(error);
                message.info('There was an error', error);
                return error;
            });
    }

    function isDisabled(matchData) {
        return matchData.status === 'PENDING' || matchData.status === 'COMPLETED';
    }

    function renderStat(player, playerData, stat, key, values, index, gameData) {
        return (
            <div style={index === stats.length - 1 ? { borderBottom: 'none' } : { borderBottom: '1px solid rgba(255,255,255,0.1)' }}>
                <Row
                    justify="space-between"
                    type="flex"
                    style={{
                        // backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        alignItems: 'center',
                        borderRadius: 6,
                        color: '#fff',
                    }}
                >
                    <Col xs={24} sm={12} align="start">
                        <div style={{ fontSize: 16, paddingLeft: 5 }}>
                            <Title level={4} style={{ margin: 0, color: '#fff' }}>
                                {stat}
                            </Title>
                        </div>
                    </Col>
                    <Col xs={24} sm={12} align="center" style={{ paddingTop: 0, paddingBottom: 0 }}>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-end',
                            }}
                        >
                            {/* <Tooltip placement="top" title={stat}>
                                <QuestionCircleFilled style={{ color: '#999', marginRight: 10 }} />
                            </Tooltip> */}
                            <div>
                                <FormItem name={`${key}`}>
                                    <InputNumber
                                        name={`${key}`}
                                        size="large"
                                        defaultValue={0}
                                        placeholder={0}
                                        onPressEnter={() => {
                                            formRef.current.handleSubmit();
                                            // handleSubmit(formRef.current.state.values);
                                        }}
                                        // onBlur={() => {
                                        // 	formRef.current.handleSubmit();
                                        // 	// handleSubmit(formRef.current.state.values);
                                        // }}
                                        onChange={(value) => {
                                            formRef.current.handleSubmit();
                                            if (value) {
                                                const updatedValues = {
                                                    ...formRef.current.state.values,
                                                    [key]: value,
                                                };
                                                handleSubmit(player, playerData, key, value, updatedValues, gameData);
                                            }
                                        }}
                                        style={{
                                            flex: 1,
                                            fontSize: 18,
                                            height: '100%',
                                            backgroundColor: 'transparent',
                                            paddingLeft: 0,
                                            paddingRight: 0,
                                            marginRight: 8,
                                            border: '1px solid #5e6977',
                                            borderRadius: 4,
                                            maxWidth: 80,
                                            color: '#fff',
                                        }}
                                    />
                                </FormItem>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <Ripples color="#ccc">
                                    <Button
                                        shape="circle"
                                        type="primary"
                                        ghost
                                        onClick={() => {
                                            const currentValue = formRef.current.state.values[key];
                                            const newValue = currentValue + 1;
                                            const updatedValues = {
                                                ...formRef.current.state.values,
                                                [key]: newValue,
                                            };
                                            formRef.current.setValues(updatedValues);
                                            formRef.current.handleSubmit();
                                            handleSubmit(player, playerData, key, newValue, updatedValues, gameData);
                                        }}
                                        style={{ width: 40, height: 40, borderWidth: 2 }}
                                    >
                                        <Icon type="plus" />
                                    </Button>
                                </Ripples>
                                <Ripples color="#ccc">
                                    <Button
                                        shape="circle"
                                        type="danger"
                                        ghost
                                        onClick={() => {
                                            const currentValue = formRef.current.state.values[key];
                                            const newValue = currentValue - 1;
                                            const updatedValues = {
                                                ...formRef.current.state.values,
                                                [key]: newValue,
                                            };
                                            formRef.current.setValues(updatedValues);
                                            formRef.current.handleSubmit();
                                            handleSubmit(player, playerData, key, newValue, updatedValues, gameData);
                                        }}
                                        style={{ marginLeft: 10, width: 40, height: 40, borderWidth: 2 }}
                                    >
                                        <Icon type="minus" />
                                    </Button>
                                </Ripples>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }

    function markBallMade(ball, ballsMade, matchData) {
        console.log(ball);
        console.log(ballsMade);
        const isScored = ballsMade[ball] === true;
        const markingMessage = isScored === true ? `Removed ${ball} ball as scored` : `Marked ${ball} ball as scored`;

        const key = 'ballsMade';
        // message.loading({ content: markingMessage, key, duration: 1 });

        return updateBracketMatch({
            variables: {
                id: matchData.id,
                tournament_id: tournamentData.id,
                changes: {
                    scoreboard_balls: {
                        ...ballsMade,
                        [ball]: isScored ? false : true,
                    },
                },
            },
            notifyOnNetworkStatusChange: true,
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: GET_TOURNAMENT_BRACKET_MATCH_QUERY,
                    variables: { match_id: matchData.id, tournament_id: tournamentData.id },
                },
            ],
        })
            .then((data) => {
                console.log(data);
                message.success({
                    content: markingMessage,
                    key,
                    duration: 1,
                });
                return data;
            })
            .catch((error) => {
                console.error(error);
                message.info('There was an error', error);
                return error;
            });
    }

    function resetBallsMade() {
        const key = 'ballsMade';
        message.loading({ content: 'Clearing scored balls...', key, duration: 1 });
        return updateBracketMatch({
            variables: {
                id: matchData.id,
                tournament_id: tournamentData.id,
                changes: {
                    scoreboard_balls: {},
                },
            },
            notifyOnNetworkStatusChange: true,
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: GET_TOURNAMENT_BRACKET_MATCH_QUERY,
                    variables: { match_id: matchData.id, tournament_id: tournamentData.id },
                },
            ],
        })
            .then((data) => {
                console.log(data);
                message.success({
                    content: `All scored balls have been cleared`,
                    key,
                    duration: 1,
                });
                return data;
            })
            .catch((error) => {
                console.error(error);
                message.info('There was an error', error);
                return error;
            });
    }

    return (
        <Query
            query={GET_TOURNAMENT_MATCH_GAMES_QUERY}
            fetchPolicy="cache-and-network"
            notifyOnNetworkStatusChange={true}
            variables={{ tournament_id: tournamentData.id, tournament_bracket_id: matchData.id }}
            onCompleted={(data, error, loading) => {
                console.log('match games', data);
            }}
        >
            {({ loading, error, data, refetch }) => {
                if (loading) return <CircularLoader />;
                if (error) return <div>Error: {error.message}</div>;

                const gameData =
                    data &&
                    data.tournament_match_games &&
                    data.tournament_match_games.sort((a, b) => {
                        return a.game_number - b.game_number;
                    });
                const currentGame = gameData && gameData[gameData.length - 1];
                console.log('current game', currentGame);
                console.log('match data', matchData);

                const initialValues = {};
                stats.forEach((stat, index) => {
                    const statUnderscored = stat.split(' ').join('_').toLowerCase();
                    const keyPlayer = `player_${statUnderscored}`;
                    const keyOpponent = `opponent_${statUnderscored}`;
                    initialValues[keyPlayer] = (currentGame && currentGame[keyPlayer]) || 0;
                    initialValues[keyOpponent] = (currentGame && currentGame[keyOpponent]) || 0;
                });

                return (
                    <React.Fragment>
                        {thumb && (
                            <div style={{ position: 'absolute', top: 0, left: 0, zIndex: 10, width: 800 }}>
                                <img src={`data:image/png;base64, ${thumb}`} alt="Table Thumbnail" width="100%" />
                            </div>
                        )}

                        <div
                            style={{
                                position: 'absolute',
                                top: 0,
                                paddingTop: 10,
                                paddingBottom: 10,
                                paddingLeft: 10,
                                paddingRight: 20,
                                width: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                zIndex: 11,
                                // boxShadow: 'rgb(0 0 0 / 20%) 0px 1px 15px 0px'
                            }}
                        >
                            <div />
                            {/* <div>
                    <Button
                        type="default"
                        icon="arrow-left"
                        size="large"
                        ghost
                        style={{ border: 'none' }}
                        onClick={() => {
                            if (backUrl) {
                                props.history.push(backUrl);
                            } else {
                                props.history.push(`/tournaments/${tournamentData.slug}/scores`);
                            }
                        }}
                    >
                        <Text
                            style={{
                                color: '#fff',
                                textTransform: 'uppercase',
                                fontSize: 16,
                                fontWeight: 500,
                                letterSpacing: 1,
                            }}
                        >
                            Live table scoring
                        </Text>
                    </Button>
                </div> */}
                            <div>
                                {matchEnded === true && (
                                    <div>
                                        <div
                                            style={{
                                                position: 'fixed',
                                                width: '100%',
                                                height: '100%',
                                                top: 0,
                                                left: 0,
                                                right: 0,
                                                bottom: 0,
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <Title
                                                level={1}
                                                style={{
                                                    paddingTop: 20,
                                                    paddingBottom: 20,
                                                    paddingLeft: 25,
                                                    paddingRight: 25,
                                                    margin: 0,
                                                    color: '#fff',
                                                    background: 'rgba(255,255,255,0.05)',
                                                    borderRadius: 20,
                                                    textTransform: 'uppercase',
                                                    letterSpacing: 2,
                                                    fontSize: 60,
                                                }}
                                            >
                                                Match finished
                                            </Title>
                                        </div>
                                    </div>
                                )}
                                {userCheckMatchEnding === true && matchEnded === false && (
                                    <div>
                                        <div
                                            style={{
                                                position: 'fixed',
                                                top: '50%',
                                                left: '50%',
                                                transform: 'translate(-50%, -50%)',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Ripples color="#000">
                                                <Button
                                                    size="large"
                                                    type="primary"
                                                    onClick={() => {
                                                        setMatchEnded(true);
                                                        endMatch(matchData, tournamentData.id);
                                                    }}
                                                    style={{
                                                        width: '100%',
                                                        paddingLeft: 40,
                                                        paddingRight: 40,
                                                        height: 160,
                                                        fontSize: 100,
                                                        fontWeight: 700,
                                                        borderRadius: 20,
                                                        // textShadow: '0 2px 5px rgba(0,0,0,0.25)',
                                                    }}
                                                >
                                                    SUBMIT SCORES
                                                </Button>
                                            </Ripples>
                                            <Ripples color="#000">
                                                <Button
                                                    size="large"
                                                    type="ghost"
                                                    onClick={() => {
                                                        setUserCheckMatchEnding(false);
                                                    }}
                                                    style={{
                                                        marginTop: 20,
                                                        paddingLeft: 40,
                                                        paddingRight: 40,
                                                        height: 140,
                                                        fontSize: 80,
                                                        fontWeight: 700,
                                                        borderRadius: 20,
                                                        color: '#fff',
                                                        // textShadow: '0 2px 5px rgba(0,0,0,0.25)',
                                                    }}
                                                >
                                                    GO BACK
                                                </Button>
                                            </Ripples>
                                        </div>
                                    </div>
                                )}
                                {matchData.status === 'IN_PROGRESS' && (playerOneScore > 0 || playerTwoScore > 0) && userCheckMatchEnding === false ? (
                                    <Tooltip
                                        placement="left"
                                        title={
                                            endMatchDisabled === true
                                                ? matchData.status === 'PENDING'
                                                    ? 'Match scores have already been submitted and are awaiting approval.'
                                                    : 'Cannot submit scores until a winner has been determined'
                                                : 'Submit scores for approval'
                                        }
                                    >
                                        <Button
                                            size="large"
                                            type="ghost"
                                            ghost
                                            onClick={() => {
                                                showConfirmEndMatch(matchData, tournamentData.id);
                                            }}
                                            disabled={endMatchDisabled || false}
                                            className={endMatchDisabled === true ? 'disabled' : ''}
                                        >
                                            Submit scores
                                        </Button>
                                    </Tooltip>
                                ) : matchData.status === 'PENDING' ? (
                                    <Text style={{ color: '#fff' }}>Scores pending TD approval</Text>
                                ) : (
                                    matchData.status !== 'COMPLETED' &&
                                    playerOneScore === 0 &&
                                    playerTwoScore === 0 &&
                                    scoringStarted !== true && (
                                        <div
                                            style={{
                                                position: 'fixed',
                                                top: '50%',
                                                left: '50%',
                                                transform: 'translate(-50%, -50%)',
                                            }}
                                        >
                                            <Ripples color="#ccc">
                                                <Button
                                                    size="large"
                                                    type="primary"
                                                    onClick={() => {
                                                        if (scoringStarted !== true) {
                                                            startScoring();
                                                            startMatch(matchData, tournamentData.id);
                                                        }
                                                    }}
                                                    disabled={scoringStarted === true}
                                                    className={scoringStarted === true ? 'disabled' : ''}
                                                    style={{
                                                        width: '100%',
                                                        paddingLeft: 40,
                                                        paddingRight: 40,
                                                        height: 160,
                                                        fontSize: 100,
                                                        fontWeight: 700,
                                                        borderRadius: 20,
                                                        // textShadow: '0 2px 5px rgba(0,0,0,0.25)',
                                                    }}
                                                >
                                                    START SCORING
                                                </Button>
                                            </Ripples>
                                            {/* <Button
                                                onClick={() => {
                                                    getVideoThumbnail();
                                                }}
                                            >
                                                Get thumbnail
                                            </Button> */}
                                        </div>
                                    )
                                )}
                            </div>
                        </div>

                        {scoringStarted === false && playerOneScore === 0 && playerTwoScore === 0 && (
                            <div
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                    bottom: 0,
                                    zIndex: 10,
                                    background: 'rgba(0,0,0,0.6)',
                                }}
                            />
                        )}

                        {userCheckMatchEnding === true && (
                            <div
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                    bottom: 0,
                                    zIndex: 10,
                                    background: 'rgba(0,0,0,0.6)',
                                }}
                            />
                        )}

                        <div
                            style={{
                                flex: 1,
                                alignItems: 'center',
                                // padding: 20,
                                display: 'flex',
                                justifyContent: 'center',
                                flexDirection: 'column',
                            }}
                        >
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', marginBottom: 0, minHeight: 20 }}>
                                {tournamentData.logo && <img src={tournamentData.logo} alt="Logo" height={120} />}
                            </div>

                            <Formik
                                ref={formRef}
                                onSubmit={(data, actions) => {
                                    console.log(data);
                                }}
                                // validationSchema={schema}
                                enableReinitialize
                                initialValues={initialValues}
                                render={({ errors, touched, values }) => (
                                    <Form
                                        layout="vertical"
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            console.log(values);
                                            // handleSubmit(values);
                                        }}
                                        style={{ display: 'flex', alignItems: 'center', width: '100%', flexDirection: 'column' }}
                                    >
                                        <Row gutter={16} type="flex" justify="space-between" style={{ width: '100%', position: 'relative' }}>
                                            <Col xs={12} sm={12}>
                                                <Title level={2} style={{ marginLeft: 20, marginTop: 0, marginBottom: 0, color: '#fff' }}>
                                                    {table.label} - Match {matchData.match_number} ({matchData.identifier})
                                                </Title>
                                            </Col>
                                            <div style={{ top: 8, position: 'absolute', left: '50%', transform: 'translateX(-50%)' }}>
                                                <Title level={2} style={{ marginLeft: 20, marginTop: 0, marginBottom: 0, color: '#fff', fontWeight: 400 }}>
                                                    {tournamentData.name}
                                                </Title>
                                            </div>
                                            <Col xs={12} sm={12} align="right">
                                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                                    <Avatar size={28} src={getGameType(tournamentData.game_type)} style={{ width: 30, marginRight: 10 }} />
                                                    <Title level={2} style={{ marginRight: 20, marginTop: 0, marginBottom: 0, color: '#fff' }}>
                                                        Race to {challenger1RaceTo}/{challenger2RaceTo}
                                                    </Title>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row gutter={24} type="flex" justify="space-between" style={{ width: '100%' }}>
                                            <Col xs={12} sm={12}>
                                                <div
                                                    style={{
                                                        display: 'inline-flex',
                                                        flex: 1,
                                                        background:
                                                            playerOneScore > playerTwoScore
                                                                ? 'linear-gradient(45deg, #0089cd, #0045b5)'
                                                                : 'linear-gradient(-45deg, black, transparent)',
                                                        // background: 'linear-gradient(206deg, #0045B4 0%, #0284CB 95%)',
                                                        borderRadius: 30,
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        flexDirection: 'column',
                                                        padding: 20,
                                                        filter: 'drop-shadow(0px 20px 10px rgba(0,0,0,0.2))',
                                                        cursor: 'pointer',
                                                        width: '100%',
                                                        height: '100%',
                                                        position: 'relative',
                                                    }}
                                                    onClick={() => {
                                                        setPlayerTurn('challenger1');
                                                    }}
                                                    className="ripple"
                                                >
                                                    <div style={{ marginTop: 40, marginBottom: 10, paddingLeft: 20 }}>
                                                        {matchData.challenger1_country && (
                                                            <React.Fragment>
                                                                {matchData.challenger1_state ? (
                                                                    <CircleStateFlag
                                                                        state={matchData.challenger1_state}
                                                                        size="large"
                                                                        style={{ transform: 'scale(1.8)' }}
                                                                    />
                                                                ) : (
                                                                    <CircleFlag
                                                                        country={getCountryName(matchData.challenger1_country) || 'Unknown'}
                                                                        size="large"
                                                                        style={{ transform: 'scale(1.8)' }}
                                                                    />
                                                                )}
                                                            </React.Fragment>
                                                        )}
                                                    </div>
                                                    <div style={{ marginTop: 10, textAlign: 'center' }}>
                                                        <Text style={{ fontSize: 40, fontWeight: 700, color: '#fff', textTransform: 'uppercase' }}>
                                                            {matchData.challenger1_name}{' '}
                                                            {matchData.challenger1_name &&
                                                                matchData.challenger1_name !== 'BYE' &&
                                                                matchData.challenger1_skill_level &&
                                                                `(${matchData.challenger1_skill_level})`}
                                                        </Text>
                                                    </div>
                                                    <div>
                                                        <Text style={{ margin: 0, fontSize: 200, lineHeight: '210px', fontWeight: 700, color: '#fff' }}>
                                                            {playerOneScore || 0}
                                                        </Text>
                                                    </div>
                                                    {matchData.challenger1_is_playing === true && <div className="arrow-right"></div>}

                                                    {/* <div
                                                        style={{
                                                            backgroundColor: 'rgba(255,255,255, 0.05)',
                                                            paddingLeft: 10,
                                                            paddingRight: 10,
                                                            paddingTop: 5,
                                                            paddingBottom: 5,
                                                            borderRadius: 10,
                                                            fontSize: 20,
                                                        }}
                                                    >
                                                        <Text style={{ color: '#fff', fontWeight: 700, fontSize: 25 }}>
                                                            TPA: {(currentGame && currentGame.player_tpa) || '0.000'}
                                                        </Text>
                                                    </div> */}
                                                </div>
                                            </Col>

                                            <Col xs={12} sm={12}>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        flex: 1,
                                                        background:
                                                            playerTwoScore > playerOneScore
                                                                ? 'linear-gradient(45deg, #0089cd, #0045b5)'
                                                                : 'linear-gradient(45deg, black, transparent)',
                                                        // background: 'linear-gradient(180deg, #0F141A 0%, #495C75 100%)',
                                                        borderRadius: 30,
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        flexDirection: 'column',
                                                        padding: 20,
                                                        filter: 'drop-shadow(0px 20px 10px rgba(0,0,0,0.2))',
                                                        cursor: 'pointer',
                                                        height: '100%',
                                                    }}
                                                    onClick={() => {
                                                        setPlayerTurn('challenger2');
                                                    }}
                                                    className="ripple"
                                                >
                                                    <div style={{ marginTop: 40, marginBottom: 10, paddingLeft: 20 }}>
                                                        {matchData.challenger2_country && (
                                                            <React.Fragment>
                                                                {matchData.challenger2_state ? (
                                                                    <CircleStateFlag state={playerTwoScore} size="large" style={{ transform: 'scale(1.8)' }} />
                                                                ) : (
                                                                    <CircleFlag
                                                                        country={getCountryName(matchData.challenger2_country) || 'Unknown'}
                                                                        size="large"
                                                                        style={{ transform: 'scale(1.8)' }}
                                                                    />
                                                                )}
                                                            </React.Fragment>
                                                        )}
                                                    </div>
                                                    <div style={{ marginTop: 10, textAlign: 'center' }}>
                                                        <Text style={{ fontSize: 40, fontWeight: 700, color: '#fff', textTransform: 'uppercase' }}>
                                                            {matchData.challenger2_name}{' '}
                                                            {matchData.challenger2_name &&
                                                                matchData.challenger2_name !== 'BYE' &&
                                                                matchData.challenger2_skill_level &&
                                                                `(${matchData.challenger2_skill_level})`}
                                                        </Text>
                                                    </div>
                                                    <div>
                                                        <Text style={{ margin: 0, fontSize: 200, lineHeight: '210px', fontWeight: 700, color: '#fff' }}>
                                                            {playerTwoScore || 0}
                                                        </Text>
                                                    </div>
                                                    {matchData.challenger2_is_playing === true && <div className="arrow-left"></div>}

                                                    {/* <div
                                                        style={{
                                                            backgroundColor: 'rgba(255,255,255, 0.05)',
                                                            paddingLeft: 10,
                                                            paddingRight: 10,
                                                            paddingTop: 5,
                                                            paddingBottom: 5,
                                                            borderRadius: 10,
                                                            fontSize: 20,
                                                        }}
                                                    >
                                                        <Text style={{ color: '#fff', fontWeight: 700, fontSize: 25 }}>
                                                            TPA: {(currentGame && currentGame.opponent_tpa) || '0.000'}
                                                        </Text>
                                                    </div> */}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row gutter={16} type="flex" justify="space-between" style={{ position: 'relative', zIndex: 1, width: '100%' }}>
                                            <Col xs={12} sm={12}>
                                                <div style={{ margin: 20, display: 'flex', justifyContent: 'center' }}>
                                                    <Ripples color="#ccc">
                                                        <Button
                                                            type="primary"
                                                            ghost
                                                            shape="circle"
                                                            size="large"
                                                            style={{ width: 80, height: 80, borderWidth: 3 }}
                                                            disabled={isDisabled(matchData) === true || playerOneScore === challenger1RaceTo ? true : false}
                                                            onClick={() => {
                                                                console.log('increment player score', matchData);
                                                                if (playerOneScore >= 0 && playerOneScore <= challenger1RaceTo) {
                                                                    // showConfirmStats('challenger1', matchData.challenger1_score, matchData, gameData);
                                                                    incrementPlayerScore('challenger1', playerOneScore, matchData, gameData);
                                                                }
                                                            }}
                                                            className={isDisabled(matchData) === true || playerOneScore === challenger1RaceTo ? 'disabled' : ''}
                                                        >
                                                            <Icon type="plus" style={{ fontSize: 50, lineHeight: '40px' }} />
                                                        </Button>
                                                    </Ripples>
                                                    <div style={{ width: 20 }} />
                                                    <Ripples color="#ccc">
                                                        <Button
                                                            type="danger"
                                                            ghost
                                                            shape="circle"
                                                            size="large"
                                                            style={{ width: 80, height: 80, borderWidth: 3 }}
                                                            disabled={isDisabled(matchData) === true || playerOneScore === 0 ? true : false}
                                                            onClick={() => {
                                                                console.log('decrement player score', matchData);
                                                                if (playerOneScore > 0) {
                                                                    decrementPlayerScore('challenger1', playerOneScore, matchData, gameData);
                                                                }
                                                            }}
                                                            className={isDisabled(matchData) === true || playerOneScore === 0 ? 'disabled' : ''}
                                                        >
                                                            <Icon type="minus" style={{ fontSize: 50, lineHeight: '40px' }} />
                                                        </Button>
                                                    </Ripples>
                                                </div>
                                            </Col>
                                            <Col xs={12} sm={12}>
                                                <div style={{ margin: 20, display: 'flex', justifyContent: 'center' }}>
                                                    <Ripples color="#ccc">
                                                        <Button
                                                            type="primary"
                                                            ghost
                                                            shape="circle"
                                                            size="large"
                                                            style={{ width: 80, height: 80, borderWidth: 3 }}
                                                            disabled={isDisabled(matchData) === true || playerTwoScore === challenger2RaceTo ? true : false}
                                                            onClick={() => {
                                                                console.log('increment player score', matchData);
                                                                if (playerTwoScore >= 0 && playerTwoScore <= challenger2RaceTo) {
                                                                    // showConfirmStats('challenger2', matchData.challenger2_score, matchData, gameData);
                                                                    incrementPlayerScore('challenger2', playerTwoScore, matchData, gameData);
                                                                }
                                                            }}
                                                            className={isDisabled(matchData) === true || playerTwoScore === challenger2RaceTo ? 'disabled' : ''}
                                                        >
                                                            <Icon type="plus" style={{ fontSize: 50, lineHeight: '40px' }} />
                                                        </Button>
                                                    </Ripples>
                                                    <div style={{ width: 20 }} />
                                                    <Ripples color="#ccc">
                                                        <Button
                                                            type="danger"
                                                            ghost
                                                            shape="circle"
                                                            size="large"
                                                            style={{ width: 80, height: 80, borderWidth: 3 }}
                                                            disabled={isDisabled(matchData) === true || playerTwoScore === 0 ? true : false}
                                                            onClick={() => {
                                                                console.log('decrement player score', matchData);
                                                                if (playerTwoScore > 0) {
                                                                    decrementPlayerScore('challenger2', playerTwoScore, matchData, gameData);
                                                                }
                                                            }}
                                                            className={isDisabled(matchData) === true || playerTwoScore === 0 ? 'disabled' : ''}
                                                        >
                                                            <Icon type="minus" style={{ fontSize: 50, lineHeight: '40px' }} />
                                                        </Button>
                                                    </Ripples>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row gutter={16} type="flex" justify="space-between" style={{ marginTop: -120 }}>
                                            <Col xs={24} align="center">
                                                <div style={{ marginBottom: 10 }}>
                                                    <Tag
                                                        size="large"
                                                        color={
                                                            matchData.status === 'NOT_STARTED'
                                                                ? '#80848c'
                                                                : matchData.status === 'IN_PROGRESS'
                                                                ? '#108ee9'
                                                                : matchData.status === 'PENDING'
                                                                ? '#009ba2'
                                                                : '#cd201f'
                                                        }
                                                    >
                                                        {matchData.status}
                                                    </Tag>
                                                </div>
                                                <div>
                                                    <Text style={{ fontSize: 40, color: '#fff', fontWeight: 700 }}>
                                                        Rack{' '}
                                                        {playerOneScore + playerTwoScore === 0
                                                            ? 1
                                                            : playerOneScore + playerTwoScore === challenger1RaceTo + challenger2RaceTo
                                                            ? playerOneScore + playerTwoScore
                                                            : playerOneScore + playerTwoScore + 1}
                                                    </Text>
                                                </div>
                                                <div>
                                                    <Text style={{ fontSize: 30, color: '#fff' }}>{duration || '0 minutes'}</Text>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row gutter={16} type="flex" justify="center" style={{ width: '100%' }}>
                                            <Col xs={12} align="center">
                                                <Ripples color="#ccc">
                                                    <Button
                                                        size="large"
                                                        type={currentGame && currentGame.player_break_run === true ? 'primary' : 'secondary'}
                                                        ghost
                                                        onClick={() => {
                                                            updateStats(currentGame, {
                                                                playerBreakRun: currentGame.player_break_run === true ? false : true,
                                                                playerRunout: false,
                                                            });
                                                        }}
                                                        disabled={currentGame && currentGame.player_is_winner === true ? false : true}
                                                        style={{
                                                            marginRight: 10,
                                                            height: 60,
                                                            paddingLeft: 20,
                                                            paddingRight: 20,
                                                            fontSize: 30,
                                                            fontWeight: 700,
                                                            borderRadius: 20,
                                                            // textShadow: '0 2px 5px rgba(0,0,0,0.25)',
                                                        }}
                                                    >
                                                        {currentGame && currentGame.player_break_run === true && <CheckOutlined />} Break & Run
                                                    </Button>
                                                </Ripples>

                                                <Ripples color="#ccc">
                                                    <Button
                                                        size="large"
                                                        type={currentGame && currentGame.player_runout === true ? 'primary' : 'secondary'}
                                                        ghost
                                                        onClick={() => {
                                                            updateStats(currentGame, {
                                                                playerBreakRun: false,
                                                                playerRunout: currentGame.player_runout === true ? false : true,
                                                            });
                                                        }}
                                                        disabled={currentGame && currentGame.player_is_winner === true ? false : true}
                                                        style={{
                                                            marginLeft: 10,
                                                            height: 60,
                                                            paddingLeft: 20,
                                                            paddingRight: 20,
                                                            fontSize: 30,
                                                            fontWeight: 700,
                                                            borderRadius: 20,
                                                            // textShadow: '0 2px 5px rgba(0,0,0,0.25)',
                                                        }}
                                                    >
                                                        {currentGame && currentGame.player_runout === true && <CheckOutlined />} Runout
                                                    </Button>
                                                </Ripples>
                                            </Col>
                                            <Col xs={12} align="center">
                                                <Ripples color="#ccc">
                                                    <Button
                                                        size="large"
                                                        type={currentGame && currentGame.opponent_break_run === true ? 'primary' : 'secondary'}
                                                        ghost
                                                        onClick={() => {
                                                            updateStats(currentGame, {
                                                                opponentBreakRun: currentGame.opponent_break_run === true ? false : true,
                                                                opponentRunout: false,
                                                            });
                                                        }}
                                                        disabled={currentGame && currentGame.opponent_is_winner === true ? false : true}
                                                        style={{
                                                            marginRight: 10,
                                                            height: 60,
                                                            paddingLeft: 20,
                                                            paddingRight: 20,
                                                            fontSize: 30,
                                                            fontWeight: 700,
                                                            borderRadius: 20,
                                                            // textShadow: '0 2px 5px rgba(0,0,0,0.25)',
                                                        }}
                                                    >
                                                        {currentGame && currentGame.opponent_break_run === true && <CheckOutlined />} Break & Run
                                                    </Button>
                                                </Ripples>

                                                <Ripples color="#ccc">
                                                    <Button
                                                        size="large"
                                                        type={currentGame && currentGame.opponent_runout === true ? 'primary' : 'secondary'}
                                                        ghost
                                                        onClick={() => {
                                                            updateStats(currentGame, {
                                                                opponentBreakRun: false,
                                                                opponentRunout: currentGame.opponent_runout === true ? false : true,
                                                            });
                                                        }}
                                                        disabled={currentGame && currentGame.opponent_is_winner === true ? false : true}
                                                        style={{
                                                            marginLeft: 10,
                                                            height: 60,
                                                            paddingLeft: 20,
                                                            paddingRight: 20,
                                                            fontSize: 30,
                                                            fontWeight: 700,
                                                            borderRadius: 20,
                                                            // textShadow: '0 2px 5px rgba(0,0,0,0.25)',
                                                        }}
                                                    >
                                                        {currentGame && currentGame.opponent_runout === true && <CheckOutlined />} Runout
                                                    </Button>
                                                </Ripples>
                                            </Col>
                                        </Row>
                                        <Row gutter={8} type="flex" justify="center" style={{ position: 'absolute', bottom: 0 }}>
                                            <Col xs={24} align="center" style={{ padding: 0 }}>
                                                <div style={{ backgroundColor: 'rgba(0,0,0,0.4)', padding: 10, borderRadius: 20 }}>
                                                    {Array.from(
                                                        {
                                                            length: gameTypes[matchData.game_type] ? gameTypes[matchData.game_type] : 15,
                                                        },
                                                        (v, index) => {
                                                            const ballNum = index + 1;
                                                            return (
                                                                <Button
                                                                    type="link"
                                                                    size="large"
                                                                    onClick={() => {
                                                                        markBallMade(ballNum, ballsMade, matchData);
                                                                    }}
                                                                    key={index}
                                                                >
                                                                    <Avatar
                                                                        size={40}
                                                                        src={require(`../../../assets/img/table/balls/${ballNum}-ball.png`)}
                                                                        className={ballsMade[ballNum] === true ? 'ball-scored' : ''}
                                                                    />
                                                                </Button>
                                                            );
                                                        }
                                                    )}
                                                    <Button size="large" ghost onClick={resetBallsMade} style={{ borderRadius: 10 }}>
                                                        Clear
                                                    </Button>
                                                </div>
                                            </Col>
                                        </Row>
                                        {/* <Row gutter={16} justify="center" type="flex" style={{ marginTop: -50, width: '90%' }}>
                                            <Col xs={12} sm={9} align="center">
                                                <Row
                                                    gutter={24}
                                                    justify="end"
                                                    type="flex"
                                                    style={{
                                                        backgroundColor: 'rgba(0,0,0,0.5)',
                                                        borderRadius: 15,
                                                    }}
                                                >
                                                    <Col xs={24}>
                                                        <React.Fragment>
                                                            {stats.map((stat, index) => {
                                                                const prefix = 'player';
                                                                const player = matchData.challenger1;
                                                                const statUnderscored = stat.split(' ').join('_').toLowerCase();
                                                                const key = `${prefix}_${statUnderscored}`;
                                                                return (
                                                                    <React.Fragment key={index}>
                                                                        {renderStat('player', player, stat, key, values, index, gameData)}
                                                                    </React.Fragment>
                                                                );
                                                            })}
                                                        </React.Fragment>
                                                    </Col>
                                                </Row>
                                            </Col>

                                            <Col xs={6} align="center">
                                                <React.Fragment>&nbsp;</React.Fragment>
                                            </Col>

                                            <Col xs={12} sm={9} align="center">
                                                <Row
                                                    gutter={24}
                                                    justify="end"
                                                    type="flex"
                                                    style={{
                                                        backgroundColor: 'rgba(0,0,0,0.5)',
                                                        borderRadius: 15,
                                                    }}
                                                >
                                                    <Col xs={24}>
                                                        <React.Fragment>
                                                            {stats.map((stat, index) => {
                                                                const prefix = 'opponent';
                                                                const opponent = matchData.challenger2;
                                                                const statUnderscored = stat.split(' ').join('_').toLowerCase();
                                                                const key = `${prefix}_${statUnderscored}`;
                                                                return (
                                                                    <React.Fragment key={index}>
                                                                        {renderStat('opponent', opponent, stat, key, values, index, gameData)}
                                                                    </React.Fragment>
                                                                );
                                                            })}
                                                        </React.Fragment>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row> */}
                                        {/* <Row gutter={16} justify="center" type="flex">
                                            <Col xs={24}>
                                                <div style={{ marginTop: 20 }}>
                                                    <RackTracker gameData={gameData} matchData={matchData} tournamentData={tournamentData} />
                                                </div>
                                            </Col>
                                        </Row> */}
                                    </Form>
                                )}
                            />
                        </div>
                    </React.Fragment>
                );
            }}
        </Query>
    );
}
