import React, { useState } from 'react';
import { message, Icon, Row, Col, Typography, Table, Progress, Button, Input, Tabs, Empty, Radio } from 'antd';
import Card from '../../components/Card';
import { Link } from 'react-router-dom';
import CircularLoader from '../../components/CircularLoader';
import SectionTitle from '../../components/SectionTitle';
import SectionHeader from '../../components/SectionHeader';
import SectionContent from '../../components/SectionContent';
import SectionWrapper from '../../components/SectionWrapper';
import SectionFooter from '../../components/SectionFooter';
import { Query, Subscription } from '@apollo/client/react/components';
// import { GET_TOURNAMENT_PLAYER_STATS_QUERY } from './data/queries';
import { GET_LEAGUE_PLAYER_STATS_SUBSCRIPTION } from './data/subscriptions';
import _ from 'lodash';

const { Text, Title } = Typography;
const { TabPane } = Tabs;

const styles = {};

export default function TBStats(props) {
    const { league } = props;
    const [rendered, setRendered] = useState();
    // const [ current, setCurrent ] = useState('teams');

    const columns = [
        {
            title: 'Place',
            dataIndex: 'place',
            // width: 80
            // sorter: (a, b) => {
            // 	return a.position.localeCompare(b.position);
            // },
            sorter: (a, b) => {
                return a.place.localeCompare(b.place, undefined, {
                    numeric: true,
                    sensitivity: 'base',
                });
            },
            render: (text, record) => {
                return <Text>{text ? text : '-'}</Text>;
            },
        },
        {
            title: 'Name',
            dataIndex: 'name',
            sorter: (a, b) => {
                return a.name.localeCompare(b.name);
            },
            render: (text, record) => {
                return <Text>{text}</Text>;
            },
        },
        {
            title: 'Match record',
            dataIndex: 'match_record',
            defaultSortOrder: 'descend',
            sorter: (a, b) => {
                return a.matches.stats.matches_won - b.matches.stats.matches_won;
            },
            render: (text, record) => {
                return (
                    <Text>
                        {record.matches.stats.matches_won}-{record.matches.stats.matches_lost}
                    </Text>
                );
            },
        },
        {
            title: 'Matches won %',
            dataIndex: 'matches_won_pct',
            sorter: (a, b) => {
                return a.matches.stats.matches_won_pct - b.matches.stats.matches_won_pct;
            },
            render: (text, record) => {
                return (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                        <div style={{ width: 40 }}>
                            <Text>{record.matches.stats.matches_won_pct}%</Text>
                        </div>

                        <Progress
                            percent={parseInt(record.matches.stats.matches_won_pct.toFixed(0)) || 0}
                            status="normal"
                            size="small"
                            showInfo={false}
                            strokeWidth={6}
                            strokeLinecap="square"
                            style={{ maxWidth: 100 }}
                        />
                    </div>
                );
            },
        },
        {
            title: 'Game record',
            dataIndex: 'game_record',
            sorter: (a, b) => {
                return a.matches.stats.games_won - b.matches.stats.games_won;
            },
            render: (text, record) => {
                return (
                    <Text>
                        {record.matches.stats.games_won}-{record.matches.stats.games_lost}
                    </Text>
                );
            },
        },
        {
            title: 'Games won %',
            dataIndex: 'games_won_pct',
            sorter: (a, b) => {
                return a.matches.stats.games_won_pct - b.matches.stats.games_won_pct;
            },
            render: (text, record) => {
                return (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                        <div style={{ width: 40 }}>
                            <Text>{record.matches.stats.games_won_pct}%</Text>
                        </div>

                        <Progress
                            percent={parseInt(record.matches.stats.games_won_pct.toFixed(0)) || 0}
                            status="normal"
                            size="small"
                            showInfo={false}
                            strokeWidth={6}
                            strokeLinecap="square"
                            style={{ maxWidth: 100 }}
                        />
                    </div>
                );
            },
        },

        // {
        // 	title: 'Rounds',
        // 	dataIndex: 'rounds'
        // 	// sorter: (a, b) => {
        // 	// 	return a.rounds.localeCompare(b.rounds);
        // 	// }
        // },

        {
            title: 'Points',
            dataIndex: 'points',
            // sorter: (a, b) => {
            // 	return a.points.localeCompare(b.points);
            // },
            sorter: (a, b) => {
                return a.points - b.points;
            },
            render: (text, record) => {
                return <Text>{(record.points && record.points) || '-'}</Text>;
            },
        },
        // {
        // 	title: 'Weeks',
        // 	dataIndex: 'weeks',
        // 	defaultSortOrder: 'descend'
        // 	// sorter: (a, b) => {
        // 	// 	return a.weeks.localeCompare(b.weeks);
        // 	// }
        // }
    ];

    return (
        <SectionWrapper>
            <SectionHeader
                title={props.title}
                titleStyle={{ color: '#fff', margin: 0, textTransform: 'uppercase' }}
                avatar={league.avatar}
                subtitle={league.name}
                subtitlePrefix={true}
                subtitleStyle={{
                    color: 'rgba(255,255,255,0.8)',
                    textTransform: 'uppercase',
                    fontWeight: 600,
                    fontSize: 12,
                    letterSpacing: 3,
                }}
                theme="leagueManager"
            >
                <Link to={`/leagues/${props.match.params.slug}`}>
                    <Button type="ghost" ghost>
                        Public Profile
                    </Button>
                </Link>
            </SectionHeader>
            <SectionContent padding="20px 40px">
                <Row gutter={24} justify="center" type="flex">
                    <Subscription
                        subscription={GET_LEAGUE_PLAYER_STATS_SUBSCRIPTION}
                        fetchPolicy="cache-and-network"
                        notifyOnNetworkStatusChange={true}
                        variables={{ league_id: league.id }}
                        onSubscriptionData={(data) => {
                            console.log(data);
                            if (rendered === true) {
                                message.info('Stats updated just now');
                            }
                            setRendered(true);
                        }}
                    >
                        {({ loading, error, data }) => {
                            if (loading) return <CircularLoader />;
                            if (error) return <div style={styles.container}>Error: {error.message}</div>;

                            return (
                                <Col xs={24}>
                                    <Card title="Stats" bodyStyle={{ padding: 0 }}>
                                        <Table
                                            columns={columns}
                                            dataSource={data.league_players.map((item, index) => {
                                                const playerMatchesWon = item && item.player && item.player.player_matches_won.aggregate.count;
                                                const opponentMatchesWon = item && item.player && item.player.opponent_matches_won.aggregate.count;
                                                const matchesWon = playerMatchesWon + opponentMatchesWon;
                                                const playerMatchesLost = item && item.player && item.player.player_matches_lost.aggregate.count;
                                                const opponentMatchesLost = item && item.player && item.player.opponent_matches_lost.aggregate.count;
                                                const matchesLost = playerMatchesLost + opponentMatchesLost;
                                                const playerGamesWon = item && item.player && item.player.player_matches_games.aggregate.sum.player_score;
                                                const opponentGamesWon = item && item.player && item.player.opponent_matches_games.aggregate.sum.opponent_score;
                                                const gamesWon = playerGamesWon + opponentGamesWon;
                                                const playerGamesLost = item && item.player && item.player.player_matches_games.aggregate.sum.opponent_score;
                                                const opponentGamesLost = item && item.player && item.player.opponent_matches_games.aggregate.sum.player_score;
                                                const gamesLost = playerGamesLost + opponentGamesLost;
                                                const totalMatches = matchesWon + matchesLost;
                                                const totalGames = gamesWon + gamesLost;

                                                const games = {
                                                    games_won: gamesWon,
                                                    games_won_pct: parseInt((gamesWon / totalGames) * 100 || 0),
                                                    games_lost: gamesLost,
                                                    games_lost_pct: parseInt((gamesLost / totalGames) * 100 || 0),
                                                };

                                                return {
                                                    id: item.id,
                                                    name: item.name,
                                                    place: item.place,
                                                    points: item.points,
                                                    key: index,
                                                    matches: {
                                                        stats: {
                                                            matches_won: matchesWon,
                                                            matches_won_pct: parseInt((matchesWon / totalMatches) * 100 || 0),
                                                            matches_lost: matchesLost,
                                                            matches_lost_pct: parseInt((matchesLost / totalMatches) * 100 || 0),
                                                            ...games,
                                                        },
                                                    },
                                                };
                                            })}
                                            onRow={(record, rowIndex) => {
                                                return {
                                                    onClick: (event) => {
                                                        console.log(record, rowIndex);
                                                        props.history.push({
                                                            pathname: `/league-manager/${props.league.slug}/players/${record.name}`,
                                                            // search: '?query=abc',
                                                            state: {
                                                                data: record,
                                                                backPath: `/league-manager/${props.league.slug}/stats`,
                                                            },
                                                        });
                                                    },
                                                    onDoubleClick: (event) => {}, // double click row
                                                    onContextMenu: (event) => {}, // right button click row
                                                    onMouseEnter: (event) => {}, // mouse enter row
                                                    onMouseLeave: (event) => {}, // mouse leave row
                                                };
                                            }}
                                            pagination={false}
                                            tableLayout="auto"
                                            scroll={{ x: 400 }}
                                        />
                                        {/* <Tabs
								animated={{ inkBar: true, tabPane: false }}
								defaultActiveKey="tables"
								onChange={callback}
								tabBarStyle={{ paddingLeft: 10, paddingRight: 10, textTransform: 'uppercase' }}
							>
								<TabPane tab="Player Stats" key="tables">
									<Table
										columns={columns}
										dataSource={data}
										pagination={false}
										tableLayout="auto"
										scroll={{ x: 400 }}
									/>
								</TabPane>
								<TabPane tab="Top Gun Stats" key="players">
									<Table
										columns={columns}
										dataSource={data2}
										pagination={false}
										tableLayout="auto"
										scroll={{ x: 400 }}
									/>
								</TabPane>
							</Tabs> */}
                                    </Card>
                                </Col>
                            );
                        }}
                    </Subscription>
                </Row>
            </SectionContent>
            <SectionFooter {...props} />
        </SectionWrapper>
    );
}
