import React, { useState, useEffect, useRef } from 'react';
import { Popover, Button } from 'antd';
import data from 'emoji-mart/data/apple.json';
import { NimblePicker } from 'emoji-mart';
import 'emoji-mart/css/emoji-mart.css';

export default function EmojiPicker(props) {
    const { text, onSetText, buttonType, buttonSize } = props;
    const [visible, setVisible] = useState(false);

    function hide() {
        setVisible(false);
    }

    function handleVisibleChange(visible) {
        setVisible(visible);
    }

    function addEmoji(e) {
        let sym = e.unified.split('-');
        let codesArray = [];
        sym.forEach((el) => codesArray.push('0x' + el));
        let emoji = String.fromCodePoint(...codesArray);
        onSetText(text + emoji);
        hide();
    }

    return (
        <Popover
            content={
                <span>
                    <NimblePicker
                        onSelect={addEmoji}
                        theme="dark"
                        set="apple"
                        emoji="point_up"
                        defaultSkin={3}
                        showPreview={false}
                        sheetSize={32}
                        perLine={8}
                        color="#72f8ff"
                        title="Pick an emoji"
                        data={data}
                    />
                </span>
            }
            placement="right"
            title="Title"
            trigger="click"
            visible={visible}
            onVisibleChange={handleVisibleChange}
        >
            <Button type={buttonType || 'secondary'} ghost size={buttonSize || 'large'} style={{ padding: '0px 14px' }}>
                <span role="img" aria-label="Smiley face" style={{ fontSize: 20 }}>
                    😎
                </span>
            </Button>
        </Popover>
    );
}
