import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { message, Modal, Button, Upload, Icon, Typography, Divider } from 'antd';
import { Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Form, FormItem, Input, Radio, Select, SubmitButton, AutoComplete } from 'formik-antd';
import { FILTER_TOURNAMENT_PLAYERS } from './data/queries';
import { getOperationName } from 'apollo-link';
import { functions } from '../../firebase';
import { connectFunctionsEmulator, httpsCallable } from 'firebase/functions';
import moment from 'moment';

const { TextArea } = Input;
const { Option } = Select;

// function onSelect(value) {
//     console.log('onSelect', value);
//   }

function NotifyPlayerModal(props) {
    const { tournamentID, modalVisible, onModalOk, onModalCancel } = props;
    const [loading, setLoading] = useState(false);
    const [dataSource, setDataSource] = useState();
    const [value, setValue] = useState(false);
    const [selected, setSelected] = useState();
    const formRef = useRef();

    async function queryDB(name) {
        var dataSet = [];
        const res = await props.client.query({
            query: FILTER_TOURNAMENT_PLAYERS,
            variables: {
                name: `%${name}%`,
                tournament_id: props.tournamentID,
            },
        });

        for (let i = 0; i < res.data.tournament_players.length; i++) {
            let player = res.data.tournament_players[i];
            // dataSet.push(tp.name);
            const { id, name, email, phone_number, country_phone } = player;

            dataSet.push({
                id,
                name,
                email,
                phone_number,
                country_phone,
            });
        }
        setDataSource(dataSet);
    }

    const onSearch = (searchText) => {
        queryDB(searchText);
    };

    async function handleSubmit(values) {
        const player = selected;
        setLoading(true);
        if (window.location.hostname.indexOf('localhost') > -1) {
            connectFunctionsEmulator(functions, 'localhost', 5001);
        }
        // functions.useFunctionsEmulator('http://localhost:5001');

        try {
            const sendSMS = httpsCallable(functions, 'sms');
            const messageToSend = `DigitalPool.com\n${values.message}\n${moment().format('llll')}`;
            const phoneNumber = `${player.country_phone || '+1'}${player.phone_number}`;
            const result = await sendSMS({
                phone_number: phoneNumber,
                message: messageToSend,
                user_id: player.id,
                tournament_id: tournamentID,
                // token: ''
            });
            console.log(result);
            setLoading(false);
            message.success(`Notification sent to ${player.name}`);
            onModalOk();
        } catch (e) {
            setLoading(false);
            console.log(e);
            message.error(`Notification error: ${JSON.stringify(e)}`);
        }
    }

    const onMessageChange = (e) => {
        setValue(e.target.value);
        // this.setState({ value });
        // setValue(value);
    };

    const onSelect = async (value) => {
        // console.log(value);
        const id = parseInt(value);
        const player = dataSource.filter((item) => {
            return item.id === id;
        })[0];
        setSelected(player);
    };

    const schema = Yup.object().shape({
        // name: Yup.string().required('Name is required')
    });

    const initialValues = {
        player_name: '',
        message: '',
    };

    return (
        <React.Fragment>
            <Formik
                ref={formRef}
                onSubmit={(values, actions) => {
                    console.log(values);
                    handleSubmit(values);
                }}
                validationSchema={schema}
                initialValues={initialValues}
                render={({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                    <Modal
                        title="Notify Player via SMS"
                        visible={modalVisible}
                        onOk={onModalOk}
                        onCancel={onModalCancel}
                        centered
                        transitionName="fade"
                        maskTransitionName="none"
                        footer={[
                            <Button key="cancel" onClick={onModalCancel}>
                                Cancel
                            </Button>,
                            <Button
                                key="submit"
                                type="primary"
                                loading={loading}
                                onClick={() => {
                                    // onModalOk();
                                    formRef.current.handleSubmit();
                                }}
                            >
                                {loading ? 'Sending...' : 'Send notification'}
                            </Button>,
                        ]}
                        bodyStyle={{ maxHeight: 500, overflowY: 'auto', padding: '10px 20px' }}
                    >
                        <Form onSubmit={handleSubmit} layout="vertical">
                            <FormItem name="player_name" label="Who to notify" style={{ width: '100%' }}>
                                <AutoComplete
                                    dataSource={dataSource}
                                    style={{ width: '100%' }}
                                    onSelect={onSelect}
                                    onSearch={onSearch}
                                    size="large"
                                    name="player_name"
                                    placeholder="Search for a player name"
                                    autoFocus
                                    optionFilterProp="children"
                                    filterOption={(inputValue, option) => {
                                        return option.props.children && option.props.children.toString().toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
                                    }}
                                >
                                    {/* <Option value="all" style={{ borderBottom: '1px solid #ccc' }}>
									All tournament players
								</Option> */}

                                    {dataSource &&
                                        dataSource.map((item, index) => (
                                            <Option key={index} value={item.id.toString()}>
                                                {item.phone_number
                                                    ? `${item.name} (${item.country_phone || '+1'} ${
                                                          item.phone_number && item.phone_number.replace(/[^\w\s]/gi, '')
                                                      })`
                                                    : `${item.name}`}
                                            </Option>
                                        ))}
                                </AutoComplete>
                            </FormItem>
                            <FormItem name="message">
                                <TextArea
                                    name="message"
                                    placeholder="Message"
                                    // value={value}
                                    size="large"
                                    allowClear
                                    // onChange={onMessageChange}
                                    maxLength={200}
                                    autoSize={{ minRows: 4, maxRows: 6 }}
                                />
                            </FormItem>

                            <div>Characters remaining: {200 - (values.message.length || 0)} </div>
                        </Form>
                    </Modal>
                )}
            />
        </React.Fragment>
    );
}

export default withRouter(NotifyPlayerModal);
