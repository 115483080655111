import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Icon, Button, Menu, Dropdown } from 'antd';
import { Query, Subscription } from '@apollo/client/react/components';
import CircularLoader from '../../components/CircularLoader';
import { GET_STREAMING_TOURNAMENT_TABLES_SUBSCRIPTION } from './data/subscriptions';
import { DownOutlined } from '@ant-design/icons';

const { SubMenu } = Menu;

const styles = {
    container: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
};

export default function TBManageButtons(props) {
    const { page, manageDropdown, navType, theme, tournament } = props;

    function handleMenuClick(e) {
        const key = e.key;
        if (key === 'manage-tables') {
            props.history.push(`/tournament-builder/${props.match.params.slug}/tables`);
        } else if (key === 'live-table-scores') {
            window.open(`/tournaments/${props.match.params.slug}/tables`, '_blank');
        } else {
            window.open(`/tournaments/${props.match.params.slug}/overlay?table_id=${key}`, '_blank');
        }
    }

    const streamOverlayDropdown = (
        <Subscription
            subscription={GET_STREAMING_TOURNAMENT_TABLES_SUBSCRIPTION}
            fetchPolicy="cache-and-network"
            notifyOnNetworkStatusChange={true}
            variables={{ tournament_id: tournament.id }}
            onSubscriptionData={(data, loading, error) => {
                if (!loading && !error) {
                    // const { pool_tables } = data.subscriptionData.data;
                }
            }}
        >
            {({ loading, error, data }) => {
                if (loading) return null;
                if (error) return null;
                if ((data && !data.pool_tables) || (data && data.pool_tables.length === 0)) {
                    return (
                        <Dropdown
                            overlay={
                                <Menu theme={theme.name} onClick={handleMenuClick}>
                                    <Menu.Item key="live-table-scores">All Table Scores</Menu.Item>
                                </Menu>
                            }
                            className="stream-overlay-button"
                        >
                            <Button type="ghost" ghost style={{ marginLeft: 10 }}>
                                Overlays <DownOutlined />
                            </Button>
                        </Dropdown>
                    );
                }

                const streamingTables = data.pool_tables;

                return (
                    <Dropdown
                        // icon={<Icon type="down" />}
                        overlay={
                            <Menu theme={theme.name} onClick={handleMenuClick}>
                                {streamingTables &&
                                    streamingTables.length > 0 &&
                                    streamingTables.map((table) => {
                                        return <Menu.Item key={table.id}>Stream Overlay - {table.label}</Menu.Item>;
                                    })}
                                <Menu.Divider style={{ backgroundColor: theme.bracket.panels.borderColor }} />
                                <Menu.Item key="live-table-scores">All Table Scores</Menu.Item>
                            </Menu>
                        }
                        className="stream-overlay-button"
                    >
                        <Button type="ghost" ghost style={{ marginLeft: 10 }}>
                            Overlays <DownOutlined />
                        </Button>
                    </Dropdown>
                );
            }}
        </Subscription>
    );

    return (
        <React.Fragment>
            <Link to={`/tournament-builder/${props.match.params.slug}/edit`}>
                <Button size="default" type="ghost" ghost>
                    <Icon type="setting" /> Setup
                </Button>
            </Link>

            {streamOverlayDropdown}

            <Link to={`/tournaments/${props.match.params.slug}/${page}`} style={{ marginLeft: 10 }}>
                <Button type="ghost" ghost size={navType === 'mobile' ? 'default' : 'default'}>
                    Public Page
                    {/* <Icon type="arrow-right" /> */}
                </Button>
            </Link>
        </React.Fragment>
    );
}
