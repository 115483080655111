import React from 'react';
import { Typography } from 'antd';

const { Text } = Typography;

export default function RaceTo(props) {
	const { race_to } = props;
	
	return (
		<React.Fragment>
			<div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            paddingLeft: 15,
                            paddingRight: 15,
                            justifyContent: 'center',
                        }}
                    >
                        <Text style={{ color: 'rgba(255,255,255,0.9)', fontSize: 14 }}>Race to {race_to}</Text>
                    </div>
		</React.Fragment>
	);
}
