import React, { useState } from 'react';
import { message, Icon, Row, Col, Typography, Table, Progress, Button, Input, Tabs, Empty, Radio } from 'antd';
import Card from '../../components/Card';
import { Link } from 'react-router-dom';
import CircularLoader from '../../components/CircularLoader';
import SectionTitle from '../../components/SectionTitle';
import SectionHeader from '../../components/SectionHeader';
import SectionContent from '../../components/SectionContent';
import SectionWrapper from '../../components/SectionWrapper';
import SectionFooter from '../../components/SectionFooter';
import TournamentProgressBar from './TournamentProgressBar';
import TBManageButtons from './TBManageButtons';
import CircleFlag from '../../components/CircleFlag';
import { getCountryName } from '../../utils/getCountryName';
import { Query, Subscription } from '@apollo/client/react/components';
import { GET_TOURNAMENT_PLAYER_STATS_QUERY } from './data/queries';
import { GET_TOURNAMENT_PLAYER_STATS_SUBSCRIPTION2 } from './data/subscriptions';
import _ from 'lodash';
import PlayerModal from '../digitalpool/tournaments/bracket/PlayerModal';

const { Text, Title } = Typography;
const { TabPane } = Tabs;

export default function TBStats(props) {
    const { tournament } = props;
    const [rendered, setRendered] = useState();
    const [playerModalVisible, setPlayerModalVisible] = useState(false);
    const [selectedPlayerId, setSelectedPlayerId] = useState();
    // const [ current, setCurrent ] = useState('teams');

    const styles = {};

    function callback(key) {
        // console.log(key);
    }

    const columns = [
        {
            title: 'Place',
            dataIndex: 'place',
            key: 'place',

            // width: 80
            // sorter: (a, b) => {
            // 	return a.position.localeCompare(b.position);
            // },
            sorter: (a, b) => {
                return (
                    a.place &&
                    a.place.localeCompare(b.place, undefined, {
                        numeric: true,
                        sensitivity: 'base',
                    })
                );
            },
            render: (text, record) => {
                return <Text>{text ? text : '-'}</Text>;
            },
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => {
                return a.name.localeCompare(b.name);
            },
            render: (text, record) => {
                return (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        {record.country && <CircleFlag country={record.country} left={0} />}

                        <Text
                            style={{
                                width: '100%',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}
                        >
                            {text ? text : '-'}
                        </Text>
                    </div>
                );
            },
        },
        {
            title: 'Match record',
            dataIndex: 'match_record',
            key: 'matchRecord',
            sorter: (a, b) => {
                const matchesWonA = a.matches_won.split('-')[0];
                const matchesWonB = b.matches_won.split('-')[0];
                return matchesWonA - matchesWonB;
            },
            render: (text, record) => {
                return <Text>{record.matches_won}</Text>;
            },
        },
        {
            title: 'Match win %',
            dataIndex: 'matches_won_pct',
            key: 'matchWinPercentage',
            sorter: (a, b) => {
                return a.matches_won_pct - b.matches_won_pct;
            },
            render: (text, record) => {
                return (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                        <div style={{ width: 40 }}>
                            <Text>{record.matches_won_pct}%</Text>
                        </div>

                        <Progress
                            percent={parseInt(record.matches_won_pct.toFixed(0)) || 0}
                            status="normal"
                            size="small"
                            showInfo={false}
                            strokeWidth={6}
                            strokeLinecap="square"
                            style={{ maxWidth: 100 }}
                        />
                    </div>
                );
            },
        },
        {
            title: 'Game record',
            dataIndex: 'game_record',
            key: 'gamesRecord',
            sorter: (a, b) => {
                const gamesWonA = a.games_won.split('-')[0];
                const gamesWonB = b.games_won.split('-')[0];
                return gamesWonA - gamesWonB;
            },
            render: (text, record) => {
                return <Text>{record.games_won}</Text>;
            },
        },
        {
            title: 'Game win %',
            dataIndex: 'games_won_pct',
            key: 'gameWinPercentage',
            sorter: (a, b) => {
                return a.games_won_pct - b.games_won_pct;
            },
            render: (text, record) => {
                return (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                        <div style={{ width: 40 }}>
                            <Text>{record.games_won_pct}%</Text>
                        </div>

                        <Progress
                            percent={parseInt(record.games_won_pct.toFixed(0)) || 0}
                            status="normal"
                            size="small"
                            showInfo={false}
                            strokeWidth={6}
                            strokeLinecap="square"
                            style={{ maxWidth: 100 }}
                        />
                    </div>
                );
            },
        },
        // {
        // 	title: 'Rounds',
        // 	dataIndex: 'rounds'
        // 	// sorter: (a, b) => {
        // 	// 	return a.rounds.localeCompare(b.rounds);
        // 	// }
        // },

        {
            title: 'Points',
            dataIndex: 'points',
            // sorter: (a, b) => {
            // 	return a.points.localeCompare(b.points);
            // },
            sorter: (a, b) => {
                return a.points - b.points;
            },
            render: (text, record) => {
                return <Text>{(record.points && record.points) || '-'}</Text>;
            },
        },
        // {
        // 	title: 'Weeks',
        // 	dataIndex: 'weeks',
        // 	defaultSortOrder: 'descend'
        // 	// sorter: (a, b) => {
        // 	// 	return a.weeks.localeCompare(b.weeks);
        // 	// }
        // }
    ];

    function handleShowPlayerModal(playerId) {
        console.log(playerId);
        setSelectedPlayerId(playerId);
        setPlayerModalVisible(true);
    }

    function handlePlayerModalVisible(visibility) {
        setPlayerModalVisible(visibility);
    }

    return (
        <SectionWrapper>
            <SectionHeader
                title={props.title}
                titleStyle={{ color: '#fff', margin: 0, textTransform: 'uppercase' }}
                subtitle={tournament.name}
                subtitlePrefix={true}
                subtitleStyle={{
                    color: 'rgba(255,255,255,0.8)',
                    textTransform: 'uppercase',
                    fontWeight: 600,
                    fontSize: 12,
                    letterSpacing: 3,
                }}
                height={90}
                theme="tournamentBuilder"
            >
                {/* <Radio.Group
					value={current}
					size="large"
					onChange={(e) => {
						setCurrent(e.target.value);
					}}
				>
					<Radio.Button
						value="teams"
						style={current === 'teams' ? styles.selectedToggle : styles.defaultToggle}
					>
						Team
					</Radio.Button>
					<Radio.Button
						value="players"
						style={current === 'players' ? styles.selectedToggle : styles.defaultToggle}
					>
						Player
					</Radio.Button>
				</Radio.Group> */}
                <TBManageButtons manageDropdown={false} page="stats" {...props} />
            </SectionHeader>
            {/* <TournamentProgressBar {...props} /> */}
            <SectionContent padding="20px 40px">
                <Row gutter={24} justify="center" type="flex">
                    <Subscription
                        subscription={GET_TOURNAMENT_PLAYER_STATS_SUBSCRIPTION2}
                        fetchPolicy="cache-and-network"
                        notifyOnNetworkStatusChange={true}
                        variables={{ tournament_id: tournament.id }}
                        onSubscriptionData={(data) => {
                            console.log(data);
                            if (rendered === true) {
                                message.info('Stats updated just now');
                            }
                            setRendered(true);
                        }}
                    >
                        {({ loading, error, data }) => {
                            if (loading) return <CircularLoader />;
                            if (error) return <div style={styles.container}>Error: {error.message}</div>;

                            const stats = data && data.vw_tournament_statistics;

                            return (
                                <Col xs={24}>
                                    <Card
                                        cover={
                                            <div
                                                style={{
                                                    paddingTop: 16,
                                                    paddingBottom: 16,
                                                    paddingLeft: 24,
                                                    paddingRight: 24,
                                                    // background:'linear-gradient(rgb(239, 243, 245), rgb(255, 255, 255))',
                                                    borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
                                                }}
                                            >
                                                <Title level={4} style={{ margin: 0, padding: 0 }}>
                                                    Player stats
                                                </Title>
                                            </div>
                                        }
                                        bodyStyle={{ padding: 0 }}
                                    >
                                        <Table
                                            columns={columns}
                                            dataSource={_.uniqBy(
                                                stats.map((player, index) => {
                                                    return {
                                                        id: player.id,
                                                        name: player.player_name,
                                                        player_id: player.player_id,
                                                        place: player.place,
                                                        country: player.player_country,
                                                        points: player.points,
                                                        matches_won: player.match_record,
                                                        matches_won_pct: player.match_win_percentage,
                                                        games_won: player.game_record,
                                                        games_won_pct: player.game_win_percentage,
                                                        key: index,
                                                    };
                                                }),
                                                'name'
                                            )}
                                            onRow={(record, rowIndex) => {
                                                return {
                                                    onClick: (event) => {
                                                        console.log(record, rowIndex);
                                                        handleShowPlayerModal(record.player_id);
                                                        // props.history.push({
                                                        // 	pathname: `/tournament-builder/${props.tournament
                                                        // 		.slug}/players/${record.name}`,
                                                        // 	// search: '?query=abc',
                                                        // 	state: {
                                                        // 		data: record,
                                                        // 		backPath: `/tournament-builder/${props.tournament
                                                        // 			.slug}/stats`
                                                        // 	}
                                                        // });
                                                    },
                                                    onDoubleClick: (event) => {}, // double click row
                                                    onContextMenu: (event) => {}, // right button click row
                                                    onMouseEnter: (event) => {}, // mouse enter row
                                                    onMouseLeave: (event) => {}, // mouse leave row
                                                };
                                            }}
                                            pagination={false}
                                            tableLayout="auto"
                                            scroll={{ x: 400 }}
                                        />
                                    </Card>
                                </Col>
                            );
                        }}
                    </Subscription>
                </Row>
                <PlayerModal
                    tournamentID={tournament.id}
                    playerId={selectedPlayerId}
                    modalVisible={playerModalVisible}
                    onPlayerModalVisible={handlePlayerModalVisible}
                    {...props}
                />
            </SectionContent>
            <SectionFooter {...props} />
        </SectionWrapper>
    );
}
