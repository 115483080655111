import React, { useState } from 'react';
import { Icon, Row, Col, Typography, Button, Input, Tabs, Empty } from 'antd';
import Card from '../../components/Card';
import { Link } from 'react-router-dom';
import SectionTitle from '../../components/SectionTitle';
import SectionContent from '../../components/SectionContent';
import DivisionTable from './divisions/DivisionTable';
import GeoLocate from '../../components/GeoLocate';

const { TabPane } = Tabs;
const { Text, Title } = Typography;
const { Search } = Input;

const styles = {
	row: {
		display: 'flex',
		flexGrow: 1
	},
	tab: {
		padding: 0
		// backgroundColor: '#fff'
	}
};

export default function DPLMain(props) {
	const [ enableCurrentLocation, setEnableCurrentLocation ] = useState(null);
	const [ currentLocation, setCurrentLocation ] = useState(null);
	const [ currentAddress, setCurrentAddress ] = useState(null);
	const [ activeKey, setActiveKey ] = useState(null);

	console.log(currentLocation);
	console.log(currentAddress);

	const callback = (key) => {
		setActiveKey(key);
	};

	return (
		<div style={{ background: 'linear-gradient(#eaedef, #fafafa)' }}>
			<SectionTitle
				title={props.title}
				titleStyle={{ color: '#fff', margin: 0, textTransform: 'uppercase' }}
				height={120}
			>
				<Link to={`/league-manager/digital-pool-league/divisions/new`}>
					<Button size="large" type="ghost" ghost>
						Create Division
						<Icon type="arrow-right" />
					</Button>
				</Link>
			</SectionTitle>

			<SectionContent padding="20px 40px">
				<Row gutter={16} justify="center" type="flex">
					<Col xs={24} sm={24} md={24} lg={8}>
						<Card title="Find nearby divisions">
							<div style={{ marginBottom: 16 }}>
								<Input
									size="large"
									placeholder="Enter your ZIP/Postal code"
									style={{ maxWidth: 400 }}
									onPressEnter={(e) => {
										setCurrentAddress(e.target.value);
									}}
									onChange={(e) => {
										setCurrentAddress(e.target.value);
									}}
									value={currentAddress ? currentAddress : null}
								/>
							</div>
							<Button type="primary" size="large">
								Search Nearby
							</Button>
							<Button
								disabled={enableCurrentLocation === true && currentAddress}
								loading={enableCurrentLocation}
								onClick={() => setEnableCurrentLocation(true)}
								size="large"
							>
								{enableCurrentLocation === true ? 'Locating...' : 'Current Location'}
							</Button>
							{enableCurrentLocation === true && (
								<GeoLocate
									renderOutput={false}
									onFromLatLng={(address) => {
										if (address) {
											setCurrentAddress(address.postalCode);
											setEnableCurrentLocation(false);
										}
									}}
									// onFromAddress={(location) => {
									// 	console.log(location);
									// }}
									// onSuccess={(location) => {
									// 	if (location && location.coords) {
									// 		setCurrentLocation(location.coords);
									// 	}
									// }}
									onError={(error) => {
										console.log(error);
									}}
								/>
							)}
						</Card>
					</Col>
					<Col xs={24} sm={24} md={24} lg={8}>
						<Card title="Start a new division">
							<div style={{ marginBottom: 16 }}>
								<Text>Interested in starting a new DPL division in your area? Get started below!</Text>
							</div>
							<Link to="/dpl/divisions/new">
								<Button type="primary" size="large">
									Create Division
								</Button>
							</Link>
							<Link to="/dpl/faq" style={{ marginLeft: 6 }}>
								<Button size="large">Learn More</Button>
							</Link>
						</Card>
					</Col>
					<Col xs={24} sm={24} md={24} lg={8}>
						<Card title="Everything you need to know">
							<div style={{ marginBottom: 16 }}>
								<Text>
									Have questions? We have answers! Check out our League Overview, Rules and FAQs.
								</Text>
							</div>
							<Link to="/dpl/about">
								<Button type="primary" size="large">
									About DPL
								</Button>
							</Link>
							<Link to="/dpl/rules" style={{ marginLeft: 6 }}>
								<Button size="large">Rules</Button>
							</Link>
							<Link to="/dpl/faq" style={{ marginLeft: 6 }}>
								<Button size="large">FAQ</Button>
							</Link>
						</Card>
					</Col>
				</Row>
				<Row>
					<Col>
						<Card bodyStyle={{ padding: 0 }}>
							<Tabs
								animated={{ inkBar: true, tabPane: false }}
								// type="card"
								activeKey={activeKey ? activeKey : currentAddress ? '2' : '1'}
								defaultActiveKey="1"
								onChange={callback}
								tabBarStyle={{ textTransform: 'uppercase' }}
								tabBarGutter={5}
							>
								<TabPane tab="My Divisions" key="1" style={styles.tab}>
									<Empty
										image="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original"
										imageStyle={{
											height: 100
										}}
										description={
											<React.Fragment>
												<Text style={{ fontSize: 18, fontWeight: 500 }}>
													You are not part of any divisions yet.
												</Text>
												<br />
												<Text>Start or join a division to play matches.</Text>
											</React.Fragment>
										}
										style={{ padding: 40 }}
									>
										<Button size="large">Create New Division</Button>
										<Button size="large">Join Division</Button>
									</Empty>
								</TabPane>
								<TabPane tab="All Divisions" key="2" style={styles.tab}>
									<Search
										placeholder="Search divisions"
										// value={currentAddress}
										onSearch={(value) => console.log(value)}
										size="large"
										style={{ maxWidth: 300, margin: 16 }}
									/>
									<DivisionTable />
								</TabPane>
							</Tabs>
						</Card>
					</Col>
				</Row>
			</SectionContent>
		</div>
	);
}
