import React, { useState, useEffect, useRef } from 'react';
import { Form, FormItem, Input, Radio, Checkbox, DatePicker, Select, FormikDebug, SubmitButton, ResetButton, AutoComplete, InputNumber } from 'formik-antd';
import { message, Collapse, Col, Row, Alert, Button, Tooltip, Typography, Avatar, Icon, Divider } from 'antd';
import { Query } from '@apollo/client/react/components';
import { GET_TOURNAMENT_BRACKET_TEMPLATES } from './data/queries';
import { QuestionCircleFilled } from '@ant-design/icons';
import { getGameType, getBracketSize, payoutStructures, calculateUSAPLRace, displayEnumType } from '../../utils';
import Card from '../../components/Card';
import ChipAmountModal from './ChipAmountModal';
import CustomHandicapChartModal from './CustomHandicapChartModal';
import axios from 'axios';

const { TextArea } = Input;
const { Title, Text } = Typography;
const { Option } = Select;
const { Panel } = Collapse;

const styles = {
    error: { color: '#f5222d', marginTop: 10 },
};

export default function TournamentStageForm(props) {
    const { values, index, formRef, tournament, theme, stage, onRemoveStage } = props;
    const [chipModalVisible, setChipModalVisible] = useState(false);
    const [handicapModalVisible, setHandicapModalVisible] = useState(false);
    const [chipAmounts, setChipAmounts] = useState();
    const [handicapAmounts, setHandicapAmounts] = useState();
    const [fargoLoading, setFargoLoading] = useState(false);
    const [fargoCharts, setFargoCharts] = useState();
    const [fargoRaceSize, setFargoRaceSize] = useState(5);
    const [player1Fargo, setPlayer1Fargo] = useState(500);
    const [player1RaceTo, setPlayer1RaceTo] = useState(0);
    const [player2Fargo, setPlayer2Fargo] = useState(600);
    const [player2RaceTo, setPlayer2RaceTo] = useState(0);

    // console.log('tournament data', tournament);
    // console.log('stage data', stage);
    // console.log('values', values);

    const stageFormField = values && values.tournament_stage_format === 'multi' && `stages.${index}`;
    const tournamentType = values && stageFormField ? values.stages[index].tournament_type : values.tournament_type;
    const tournamentFormat = values && stageFormField ? values.stages[index].tournament_format : values.tournament_format;
    const drawType = values && stageFormField ? values.stages[index].draw_type : values.draw_type;
    const maxPlayers = values && stageFormField ? values.stages[index].max_players : values.max_players;
    const enableGroups = values && stageFormField ? values.stages[index].enable_groups : values.enable_groups;
    const stages = values && values.stages;

    useEffect(() => {
        async function fargoRatedCharts(size) {
            size = size || 0;
            setFargoLoading(true);
            const hotCharts = await getFargoCharts('hot', size);
            const mediumCharts = await getFargoCharts('medium', size);
            const mildCharts = await getFargoCharts('mild', size);
            setFargoLoading(false);
            // console.log(charts);
            const output = {
                hot: hotCharts,
                medium: mediumCharts,
                mild: mildCharts,
            };
            return output;
            // setFargoCharts(output);
        }

        const getData = async () => {
            const charts = await fargoRatedCharts(fargoRaceSize);
            console.log(charts);
            setFargoCharts(charts);
        };
        if (player1Fargo || player2Fargo) {
            getData();

            if (tournament && tournament.tournament_format === 'usapl_fargo_rated') {
                if (tournament.usapl_race_format === 'longer_races') {
                    calculateRace(1.0, player1Fargo, player2Fargo);
                } else if (tournament.usapl_race_format === 'shorter_races') {
                    calculateRace(0.75, player1Fargo, player2Fargo);
                }
            }
        }
    }, [fargoRaceSize, player1Fargo, player2Fargo]);

    const arrayOfMultiples = (a, b) => {
        let arr = [];
        let x = 1;
        for (let i = 1; i <= b / 2; i++) {
            arr.push(a * x);
            x = x * 2;
        }
        return arr;
    };

    function getFargoCharts(type, size) {
        return new Promise((resolve, reject) => {
            const typesEnum = {
                hot: 2,
                medium: 1,
                mild: 0,
            };
            const url = `https://lms.fargorate.com/api/ratingcalc/racesbylengthandtype?length=${size}&type=${typesEnum[type]}&ratingOne=${player1Fargo}&ratingTwo=${player2Fargo}`;

            axios
                .get(url)
                .then((response) => {
                    const results = response.data;

                    resolve(results);
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                });
        });
    }

    function handleFocus(event) {
        event.target.select();
    }

    function calculateRace(dampFactor, ratingOne, ratingTwo) {
        const { p1RaceTo, p2RaceTo } = calculateUSAPLRace(dampFactor, ratingOne, ratingTwo);
        setPlayer1RaceTo(p1RaceTo);
        setPlayer2RaceTo(p2RaceTo);
    }

    function handleSetChipAmounts() {
        setChipModalVisible(true);
    }

    function handleChipModalOk(e) {
        setChipModalVisible(false);
    }

    function handleChipModalCancel(e) {
        setChipModalVisible(false);
    }

    function handleSaveChips(data) {
        console.log(data);
        setChipAmounts(data);
    }

    function handleSetHandicaps() {
        setHandicapModalVisible(true);
    }

    function handleHandicapModalOk(e) {
        setHandicapModalVisible(false);
    }

    function handleHandicapModalCancel(e) {
        setHandicapModalVisible(false);
    }

    function handleSaveHandicaps(data) {
        console.log(data);
        setHandicapAmounts(data);
    }

    function handleFormFieldUpdates(event) {
        const caret = event.target.selectionStart;
        const element = event.target;
        window.requestAnimationFrame(() => {
            element.selectionStart = caret;
            element.selectionEnd = caret;
        });
    }

    const renderForm = (
        <React.Fragment>
            {values && values.tournament_stage_format === 'multi' && (
                <FormItem label="Stage Name" name={stageFormField ? `${stageFormField}.name` : 'name'} autoFocus required hasFeedback showValidateSuccess>
                    <Input
                        name={stageFormField ? `${stageFormField}.name` : 'name'}
                        placeholder="Name"
                        size="large"
                        onChange={(event) => {
                            handleFormFieldUpdates(event);
                        }}
                    />
                </FormItem>
            )}

            <FormItem
                label={values && values.tournament_stage_format === 'multi' ? 'Stage Type' : 'Tournament Type'}
                name={stageFormField ? `${stageFormField}.tournament_type` : 'tournament_type'}
                required
                hasFeedback
                showValidateSuccess
            >
                <Select
                    showSearch
                    allowClear
                    // name="tournament_type"
                    name={stageFormField ? `${stageFormField}.tournament_type` : 'tournament_type'}
                    placeholder="Select a tournament type"
                    size="large"
                    optionFilterProp="children"
                    defaultValue="double_elimination"
                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    dropdownRender={(menu) => <div>{menu}</div>}
                >
                    {[
                        {
                            type: 'Double Elimination',
                            value: 'double_elimination',
                        },
                        {
                            type: 'Modified Double Elimination',
                            value: 'modified_double_elimination',
                        },
                        {
                            type: 'Single Elimination',
                            value: 'single_elimination',
                        },
                        {
                            type: 'Custom Template',
                            value: 'custom',
                        },
                        {
                            type: 'Chip Tournament',
                            value: 'chip',
                        },
                        {
                            type: 'Round Robin (Coming soon)',
                            value: 'round_robin',
                        },
                        {
                            type: 'Single Modified (Not yet supported)',
                            value: 'single_modified',
                        },
                        {
                            type: 'Knockout FFA (Not yet supported)',
                            value: 'knockout',
                        },
                    ].map((item, index) => (
                        <Option
                            key={index}
                            value={item.value}
                            disabled={
                                item.value !== 'double_elimination' &&
                                item.value !== 'modified_double_elimination' &&
                                item.value !== 'single_elimination' &&
                                item.value !== 'custom' &&
                                item.value !== 'round_robin' &&
                                item.value !== 'chip'
                            }
                            style={{ display: 'flex', alignItems: 'center' }}
                            label={
                                <React.Fragment>
                                    <Icon type="search" />
                                    &nbsp;
                                    {item}
                                </React.Fragment>
                            }
                        >
                            {item.type}
                        </Option>
                    ))}
                </Select>
            </FormItem>

            {tournamentType === 'custom' && (
                <FormItem
                    label="Custom Template"
                    name={stageFormField ? `${stageFormField}.bracket_template_id` : 'bracket_template_id'}
                    hasFeedback
                    showValidateSuccess
                >
                    <Query
                        query={GET_TOURNAMENT_BRACKET_TEMPLATES}
                        fetchPolicy="cache-and-network"
                        notifyOnNetworkStatusChange={true}
                        variables={{
                            creator_id: props.authState.user.id,
                        }}
                    >
                        {({ loading, error, data }) => {
                            if (loading) return <div>Loading...</div>;
                            if (error) return <div>Error: {error.message}</div>;

                            return (
                                <Select
                                    showSearch
                                    // mode="multiple"
                                    // name="bracket_template_id"
                                    name={stageFormField ? `${stageFormField}.bracket_template_id` : 'bracket_template_id'}
                                    placeholder="Select custom tournament bracket template"
                                    size="large"
                                    optionFilterProp="children"
                                    // defaultValue={[ 'jack', 'lucy' ]}
                                    filterOption={(input, option) => {
                                        // console.log(option);
                                        return option && option.props && option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                    }}
                                >
                                    {data.tournament_bracket_templates
                                        .sort((a, b) => a.name.localeCompare(b.name))
                                        .map((item, index) => (
                                            <Option key={index} value={item.id}>
                                                <Text>{item.name}</Text>
                                            </Option>
                                        ))}
                                </Select>
                            );
                        }}
                    </Query>
                </FormItem>
            )}

            {tournamentType === 'round_robin' && (
                <React.Fragment>
                    <FormItem
                        label="Participants play each other"
                        name={stageFormField ? `${stageFormField}.participant_times_played` : 'participant_times_played'}
                    >
                        <Radio.Group name={stageFormField ? `${stageFormField}.participant_times_played` : 'participant_times_played'}>
                            <Radio value={1}>Once</Radio>
                            <Radio value={2}>Twice</Radio>
                            <Radio value={3}>Three times</Radio>
                        </Radio.Group>
                    </FormItem>
                    <FormItem
                        label="Estimated number of players"
                        name={stageFormField ? `${stageFormField}.max_players` : 'max_players'}
                        required
                        hasFeedback
                        showValidateSuccess
                    >
                        <InputNumber
                            name={stageFormField ? `${stageFormField}.max_players` : 'max_players'}
                            min={0}
                            placeholder="1"
                            // value={score}
                            // onChange={onChange1}
                            onFocus={handleFocus}
                            // onBlur={handleBlur}
                            // style={styles.inputSmall}
                            // onPressEnter={onEnter}
                            size="large"
                            // disabled
                            style={{ maxWidth: 200 }}
                        />
                    </FormItem>
                    <FormItem
                        label="Players in each group"
                        name={stageFormField ? `${stageFormField}.players_per_group` : 'players_per_group'}
                        hasFeedback
                        showValidateSuccess
                    >
                        <InputNumber
                            name={stageFormField ? `${stageFormField}.players_per_group` : 'players_per_group'}
                            min={0}
                            placeholder="1"
                            // value={score}
                            // onChange={onChange1}
                            onFocus={handleFocus}
                            // onBlur={handleBlur}
                            // style={styles.inputSmall}
                            // onPressEnter={onEnter}
                            size="large"
                            // disabled
                            style={{ maxWidth: 200 }}
                        />
                    </FormItem>
                    <FormItem
                        label="Players advancing from each group"
                        name={stageFormField ? `${stageFormField}.players_advance_per_group` : 'players_advance_per_group'}
                        hasFeedback
                        showValidateSuccess
                        extra="Must be a power of 2 for single & double elim (1,2,4,8,16,...)"
                    >
                        <Select
                            showSearch
                            name={stageFormField ? `${stageFormField}.players_advance_per_group` : 'players_advance_per_group'}
                            placeholder="Select number of players"
                            size="large"
                            optionFilterProp="children"
                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        >
                            {arrayOfMultiples(2, maxPlayers).map((item, index) => (
                                <Option key={index} value={item}>
                                    {item}
                                </Option>
                            ))}
                        </Select>
                    </FormItem>
                    <FormItem
                        label="Players ranked by"
                        name={stageFormField ? `${stageFormField}.players_ranked_by` : 'players_ranked_by'}
                        hasFeedback
                        showValidateSuccess
                    >
                        <Select
                            showSearch
                            name={stageFormField ? `${stageFormField}.players_ranked_by` : 'players_ranked_by'}
                            placeholder="Select how players are ranked"
                            size="large"
                            optionFilterProp="children"
                            defaultValue="match_wins"
                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        >
                            {[
                                {
                                    type: 'Match Wins',
                                    value: 'match_wins',
                                },
                                {
                                    type: 'Game / Set Wins',
                                    value: 'game_set_wins',
                                },
                                {
                                    type: 'Game / Set Win %',
                                    value: 'game_set_win_percentage',
                                },
                                {
                                    type: 'Game / Set Win Loss Difference',
                                    value: 'game_set_win_loss',
                                },
                                {
                                    type: 'Points Won',
                                    value: 'points_won',
                                },
                                {
                                    type: 'Point Difference',
                                    value: 'point_difference',
                                },
                            ].map((item, index) => (
                                <Option key={index} value={item.value}>
                                    {item.type}
                                </Option>
                            ))}
                        </Select>
                    </FormItem>
                </React.Fragment>
            )}

            {tournamentFormat === 'multi' ? (
                <React.Fragment>
                    {tournamentType === 'double_elimination' && (
                        <FormItem
                            label="True double elimination - Hot seat winner must be defeated twice to win the tournament."
                            name={stageFormField ? `${stageFormField}.consolidation_finals` : 'consolidation_finals'}
                        >
                            <Radio.Group name={stageFormField ? `${stageFormField}.consolidation_finals` : 'consolidation_finals'}>
                                <Radio value={true}>Yes</Radio>
                                <Radio value={false}>No</Radio>
                            </Radio.Group>
                        </FormItem>
                    )}

                    {tournamentType === 'double_elimination' && (
                        <FormItem
                            label="Split players - Half of the players will start in the losers bracket (coming soon)"
                            name={stageFormField ? `${stageFormField}.split_bracket` : 'split_bracket'}
                        >
                            <Radio.Group name={stageFormField ? `${stageFormField}.split_bracket` : 'split_bracket'} disabled>
                                <Radio value={true}>Yes</Radio>
                                <Radio value={false}>No</Radio>
                            </Radio.Group>
                        </FormItem>
                    )}
                </React.Fragment>
            ) : (
                <React.Fragment>
                    {tournamentType === 'double_elimination' && (
                        <FormItem
                            label="True double elimination - Hot seat winner must be defeated twice to win the tournament."
                            name={stageFormField ? `${stageFormField}.consolidation_finals` : 'consolidation_finals'}
                        >
                            <Radio.Group name={stageFormField ? `${stageFormField}.consolidation_finals` : 'consolidation_finals'}>
                                <Radio value={true}>Yes</Radio>
                                <Radio value={false}>No</Radio>
                            </Radio.Group>
                        </FormItem>
                    )}
                </React.Fragment>
            )}

            <FormItem
                label={values && values.tournament_stage_format === 'multi' ? 'Stage Format' : 'Tournament Format'}
                name={stageFormField ? `${stageFormField}.tournament_format` : 'tournament_format'}
            >
                <Radio.Group name={stageFormField ? `${stageFormField}.tournament_format` : 'tournament_format'} defaultValue="standard" size="medium">
                    <Radio.Button value="standard">Standard</Radio.Button>
                    <Radio.Button value="fargo_rated">Fargo Rated</Radio.Button>
                    <Radio.Button value="usapl_fargo_rated">USAPL Fargo Rated</Radio.Button>
                    {/* <Radio.Button value="custom">Custom</Radio.Button> */}
                </Radio.Group>
                <Tooltip
                    placement="right"
                    title={
                        <React.Fragment>
                            {tournamentFormat === 'fargo_rated' ? (
                                <div>
                                    Learn more about Fargo Hot, Medium and Mild tournament formats here -{' '}
                                    <a
                                        href="https://fargorate.com/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                        }}
                                    >
                                        FargoRate.com
                                    </a>
                                </div>
                            ) : tournamentFormat === 'usapl_fargo_rated' ? (
                                <div>
                                    The USAPL Fargo Rated format uses points for races instead of games. The built in USAPL Fargo Rate calculator will suggest a
                                    short race or long race based on two Fargo Ratings.
                                </div>
                            ) : (
                                <div>
                                    The standard tournament format allows you to set default winner and loser side races (loser races only apply to double
                                    elimination tournaments).
                                </div>
                            )}
                        </React.Fragment>
                    }
                >
                    <QuestionCircleFilled style={{ marginLeft: 10, fontSize: 15 }} />
                </Tooltip>
            </FormItem>

            {tournamentFormat === 'standard' && (
                <React.Fragment>
                    <FormItem
                        label="Winners Race To"
                        name={stageFormField ? `${stageFormField}.winners_race_to` : 'winners_race_to'}
                        extra="Override this value using the 'race_to' field when adding players."
                        required
                        hasFeedback
                        showValidateSuccess
                    >
                        <Input
                            name={stageFormField ? `${stageFormField}.winners_race_to` : 'winners_race_to'}
                            placeholder="Winner side match length"
                            size="large"
                            onChange={(e) => {
                                const size = e.target.value;
                                setFargoRaceSize(parseInt(size));
                            }}
                            suffix={
                                <Tooltip title="The default number of games on the winner side required to win a match.">
                                    <QuestionCircleFilled />
                                </Tooltip>
                            }
                        />
                    </FormItem>
                    {/* <div style={{ display: 'flex', alignItems: 'center' }}>
                        <FormItem
                            label="Winners Race To"
                            name={stageFormField ? `${stageFormField}.winners_race_to` : 'winners_race_to'}
                            extra="Override this value using the 'race_to' field when adding players."
                            required
                            hasFeedback
                            showValidateSuccess
                        >
                            <Input
                                name={stageFormField ? `${stageFormField}.winners_race_to` : 'winners_race_to'}
                                placeholder="Winner side match length"
                                size="large"
                                onChange={(e) => {
                                    const size = e.target.value;
                                    setFargoRaceSize(parseInt(size));
                                }}
                                suffix={
                                    <Tooltip title="The default number of games on the winner side required to win a match.">
                                        <QuestionCircleFilled />
                                    </Tooltip>
                                }
                            />
                        </FormItem>
                        <Button
                            type="primary"
                            ghost
                            size="large"
                            onClick={() => {
                                handleSetHandicaps();
                            }}
                            style={{ marginTop: 5, marginLeft: 10 }}
                        >
                            Custom handicap chart
                        </Button>
                    </div> */}
                    {values && (tournamentType === 'double_elimination' || tournamentType === 'modified_double_elimination') && (
                        <FormItem
                            label="Losers Race To"
                            name={stageFormField ? `${stageFormField}.losers_race_to` : 'losers_race_to'}
                            extra="Override this value using the 'race_to' field when adding players."
                            hasFeedback
                            showValidateSuccess
                        >
                            <Input
                                name={stageFormField ? `${stageFormField}.losers_race_to` : 'losers_race_to'}
                                placeholder="Losers side match length"
                                size="large"
                                suffix={
                                    <Tooltip title="The default number of games on the loser side required to win a match.">
                                        <QuestionCircleFilled />
                                    </Tooltip>
                                }
                            />
                        </FormItem>
                    )}
                    {((stages && stages.length > 1 && index > 0) || (stages && stages.length === 1)) && (
                        <FormItem
                            label="Finals Race To"
                            name={stageFormField ? `${stageFormField}.finals_race_to` : 'finals_race_to'}
                            hasFeedback
                            showValidateSuccess
                        >
                            <Input
                                name={stageFormField ? `${stageFormField}.finals_race_to` : 'finals_race_to'}
                                placeholder="Final match length"
                                size="large"
                                suffix={
                                    <Tooltip title="The number of games needed to win the final match if different than the winners race to number.">
                                        <QuestionCircleFilled />
                                    </Tooltip>
                                }
                            />
                        </FormItem>
                    )}
                </React.Fragment>
            )}

            {tournamentFormat === 'usapl_fargo_rated' && (
                <React.Fragment>
                    <div style={{ marginTop: 10, paddingBottom: 10 }}>
                        <div style={{ marginBottom: 10 }}>
                            <Text style={{ fontSize: 15, fontWeight: 600 }}>USAPL Fargo Rate Handicaps</Text>
                            <br />
                            <Text>
                                Enter the Fargo Ratings for two players and select "Shorter Races" or "Longer Races" under Race Format to preview the handicap
                                match length used by USAPL scoring. The selected race format will be used to calculate the race for each match in the bracket.
                            </Text>
                        </div>

                        <div>
                            <Row gutter={8}>
                                <Col xs={12} sm={4}>
                                    <Text style={{ fontSize: 13, fontWeight: 600 }}>Player 1 Fargo</Text>
                                    <FormItem name={stageFormField ? `${stageFormField}.player_fargo` : 'player_fargo'}>
                                        <InputNumber
                                            name={stageFormField ? `${stageFormField}.player_fargo` : 'player_fargo'}
                                            min={1}
                                            max={1000}
                                            value={player1Fargo}
                                            onChange={(value) => {
                                                if (value > 0) {
                                                    setPlayer1Fargo(value);
                                                }
                                            }}
                                        />
                                    </FormItem>
                                </Col>
                                <Col xs={12} sm={4}>
                                    <Text style={{ fontSize: 13, fontWeight: 600 }}>Player 2 Fargo</Text>
                                    <FormItem name={stageFormField ? `${stageFormField}.opponent_fargo` : 'opponent_fargo'}>
                                        <InputNumber
                                            name={stageFormField ? `${stageFormField}.opponent_fargo` : 'opponent_fargo'}
                                            min={1}
                                            max={1000}
                                            value={player2Fargo}
                                            onChange={(value) => {
                                                if (value > 0) {
                                                    setPlayer2Fargo(value);
                                                }
                                            }}
                                        />
                                    </FormItem>
                                </Col>
                                <Col xs={12} sm={4}>
                                    <Text style={{ fontSize: 13, fontWeight: 600 }}>Rating difference</Text>
                                    <div style={{ marginTop: 10 }}>
                                        <Text>
                                            {player1Fargo > player2Fargo
                                                ? player1Fargo - player2Fargo
                                                : player2Fargo > player1Fargo
                                                ? player2Fargo - player1Fargo
                                                : 'N/A'}{' '}
                                            points
                                        </Text>
                                    </div>
                                </Col>
                            </Row>
                            <Row gutter={8}>
                                <Col xs={24}>
                                    <FormItem name={stageFormField ? `${stageFormField}.usapl_race_format` : 'usapl_race_format'} label="Race Format">
                                        <Radio.Group
                                            name={stageFormField ? `${stageFormField}.usapl_race_format` : 'usapl_race_format'}
                                            defaultValue="longer_races"
                                            size="medium"
                                        >
                                            <Radio.Button
                                                value="longer_races"
                                                onClick={() => {
                                                    calculateRace(1.0, player1Fargo, player2Fargo);
                                                }}
                                            >
                                                Longer Races
                                            </Radio.Button>
                                            <Radio.Button
                                                value="shorter_races"
                                                onClick={() => {
                                                    calculateRace(0.75, player1Fargo, player2Fargo);
                                                }}
                                            >
                                                Shorter Races
                                            </Radio.Button>
                                        </Radio.Group>
                                    </FormItem>
                                </Col>
                            </Row>
                            <Row gutter={8}>
                                <Col xs={24}>
                                    <Text>
                                        Player One Race To: <b>{player1RaceTo}</b>
                                    </Text>
                                    <br />
                                    <Text>
                                        Player Two Race To: <b>{player2RaceTo}</b>
                                    </Text>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </React.Fragment>
            )}

            {tournamentFormat === 'fargo_rated' && (
                <React.Fragment>
                    <div style={{ marginTop: 10, paddingBottom: 10 }}>
                        <div style={{ marginBottom: 10 }}>
                            <Text style={{ fontSize: 15, fontWeight: 600 }}>Fargo Rate Match Configuration</Text>
                            <br />
                            <Text>
                                Determine the preferred race length (R2-R11) and handicap level (Hot, Medium, or Mild) for your Fargo Rated tournament. Keep in
                                mind when choosing a race length that the maximum amount of games in the race will be roughly double the R number (e.g. R4 = 8
                                max games, R5 = 10 max games etc).
                            </Text>
                            <br />
                            <br />
                            <Text>
                                Hot means the handicaps will be as close to even as possible and Medium and Mild get progressively less. For more information on
                                handicapped Fargo Rated Tournaments, please watch this{' '}
                                <a href="https://www.facebook.com/931961723522836/videos/1775349585850708" target="_blank" rel="noopener noreferrer">
                                    Facebook video
                                </a>
                                .
                            </Text>
                        </div>

                        <div>
                            <Row gutter={8}>
                                <Col xs={12} sm={4}>
                                    <Text style={{ fontSize: 13, fontWeight: 600 }}>Player 1 Fargo</Text>
                                    <FormItem name={stageFormField ? `${stageFormField}.player_fargo` : 'player_fargo'}>
                                        <InputNumber
                                            name={stageFormField ? `${stageFormField}.player_fargo` : 'player_fargo'}
                                            min={1}
                                            max={1000}
                                            value={player1Fargo}
                                            onChange={(value) => {
                                                if (value > 0) {
                                                    setPlayer1Fargo(value);
                                                }
                                            }}
                                        />
                                    </FormItem>
                                </Col>
                                <Col xs={12} sm={4}>
                                    <Text style={{ fontSize: 13, fontWeight: 600 }}>Player 2 Fargo</Text>
                                    <FormItem name={stageFormField ? `${stageFormField}.opponent_fargo` : 'opponent_fargo'}>
                                        <InputNumber
                                            name={stageFormField ? `${stageFormField}.opponent_fargo` : 'opponent_fargo'}
                                            min={1}
                                            max={1000}
                                            value={player2Fargo}
                                            onChange={(value) => {
                                                if (value > 0) {
                                                    setPlayer2Fargo(value);
                                                }
                                            }}
                                        />
                                    </FormItem>
                                </Col>
                                <Col xs={12} sm={4}>
                                    <Text style={{ fontSize: 13, fontWeight: 600 }}>Rating difference</Text>
                                    <div style={{ marginTop: 10 }}>
                                        <Text>
                                            {player1Fargo > player2Fargo
                                                ? player1Fargo - player2Fargo
                                                : player2Fargo > player1Fargo
                                                ? player2Fargo - player1Fargo
                                                : 'N/A'}{' '}
                                            points
                                        </Text>
                                    </div>
                                </Col>
                            </Row>
                        </div>

                        <div>
                            <FormItem
                                label={tournamentType === 'double_elimination' ? 'Winners Race Length' : 'Race Length'}
                                name={stageFormField ? `${stageFormField}.winners_race_to` : 'winners_race_to'}
                            >
                                <Radio.Group
                                    name={stageFormField ? `${stageFormField}.winners_race_to` : 'winners_race_to'}
                                    size="large"
                                    onChange={(e) => {
                                        const size = e.target.value;
                                        setFargoRaceSize(size);
                                    }}
                                >
                                    <Radio.Button value={2}>R2</Radio.Button>
                                    <Radio.Button value={3}>R3</Radio.Button>
                                    <Radio.Button value={4}>R4</Radio.Button>
                                    <Radio.Button value={5}>R5</Radio.Button>
                                    <Radio.Button value={6}>R6</Radio.Button>
                                    <Radio.Button value={7}>R7</Radio.Button>
                                    <Radio.Button value={9}>R9</Radio.Button>
                                    <Radio.Button value={11}>R11</Radio.Button>
                                </Radio.Group>
                            </FormItem>

                            <FormItem
                                label={
                                    <React.Fragment>
                                        Winners Max Race{' '}
                                        <Tooltip placement="right" title={<div>The maximum number of games a player can go to (optional).</div>}>
                                            <QuestionCircleFilled style={{ marginLeft: 5 }} />
                                        </Tooltip>
                                    </React.Fragment>
                                }
                                name={stageFormField ? `${stageFormField}.winners_max_race` : 'winners_max_race'}
                            >
                                <Input name={stageFormField ? `${stageFormField}.winners_max_race` : 'winners_max_race'} placeholder="0" size="large" />
                            </FormItem>
                        </div>

                        {tournamentType === 'double_elimination' && (
                            <div>
                                <FormItem label="Losers Race Length" name={stageFormField ? `${stageFormField}.losers_race_to` : 'losers_race_to'}>
                                    <Radio.Group
                                        name={stageFormField ? `${stageFormField}.losers_race_to` : 'losers_race_to'}
                                        size="large"
                                        onChange={(e) => {
                                            const size = e.target.value;
                                            setFargoRaceSize(size);
                                        }}
                                    >
                                        <Radio.Button value={2}>R2</Radio.Button>
                                        <Radio.Button value={3}>R3</Radio.Button>
                                        <Radio.Button value={4}>R4</Radio.Button>
                                        <Radio.Button value={5}>R5</Radio.Button>
                                        <Radio.Button value={6}>R6</Radio.Button>
                                        <Radio.Button value={7}>R7</Radio.Button>
                                        <Radio.Button value={9}>R9</Radio.Button>
                                        <Radio.Button value={11}>R11</Radio.Button>
                                    </Radio.Group>
                                </FormItem>

                                <FormItem
                                    label={
                                        <React.Fragment>
                                            Losers Max Race{' '}
                                            <Tooltip placement="right" title={<div>The maximum number of games a player can go to (optional).</div>}>
                                                <QuestionCircleFilled style={{ marginLeft: 5 }} />
                                            </Tooltip>
                                        </React.Fragment>
                                    }
                                    name={stageFormField ? `${stageFormField}.losers_max_race` : 'losers_max_race'}
                                >
                                    <Input name={stageFormField ? `${stageFormField}.losers_max_race` : 'losers_max_race'} placeholder="0" size="large" />
                                </FormItem>
                            </div>
                        )}

                        <div>
                            <FormItem label="Fargo Handicap Format" name={stageFormField ? `${stageFormField}.handicap_format` : 'handicap_format'}>
                                <Radio.Group
                                    name={stageFormField ? `${stageFormField}.handicap_format` : 'handicap_format'}
                                    defaultValue="fargo_hot_column"
                                    size="medium"
                                >
                                    <Radio.Button value="fargo_hot_column">Hot</Radio.Button>
                                    <Radio.Button value="fargo_medium_column">Medium</Radio.Button>
                                    <Radio.Button value="fargo_mild_column">Mild</Radio.Button>
                                </Radio.Group>
                            </FormItem>
                        </div>

                        <table
                            width="100%"
                            style={{
                                marginTop: 10,
                                marginBottom: 0,
                                marginLeft: 0,
                                marginRight: 0,
                            }}
                        >
                            <tbody>
                                <tr style={{ display: 'flex' }}>
                                    <td style={{ flex: 1, fontSize: 13, fontWeight: 600 }}>Hot</td>
                                    <td style={{ flex: 1, fontSize: 13, fontWeight: 600 }}>Medium</td>
                                    <td style={{ flex: 1, fontSize: 13, fontWeight: 600 }}>Mild</td>
                                </tr>

                                {fargoLoading === true ? (
                                    <tr style={{ display: 'flex' }}>
                                        <td style={{ flex: 1 }}>Loading...</td>
                                        <td style={{ flex: 1 }}>Loading...</td>
                                        <td style={{ flex: 1 }}>Loading...</td>
                                    </tr>
                                ) : (
                                    <tr style={{ display: 'flex' }}>
                                        <td style={{ flex: 1 }}>
                                            {fargoCharts &&
                                                fargoCharts.hot &&
                                                fargoCharts.hot.map((item, index) => {
                                                    return (
                                                        <div
                                                            key={`hot-${index}`}
                                                            style={
                                                                item.closest === true && values.handicap_format === 'fargo_hot_column'
                                                                    ? {
                                                                          backgroundColor: theme && theme.name === 'dark' ? '#083865' : '#e6f7ff',
                                                                          fontWeight: 'bold',
                                                                      }
                                                                    : item.closest === true
                                                                    ? { fontWeight: 'bold' }
                                                                    : {}
                                                            }
                                                        >
                                                            {parseInt(player1Fargo) > parseInt(player2Fargo) ? (
                                                                <Text>
                                                                    {item.highPlayerRaceTo} - {item.lowPlayerRaceTo}
                                                                </Text>
                                                            ) : (
                                                                <Text>
                                                                    {item.lowPlayerRaceTo} - {item.highPlayerRaceTo}
                                                                </Text>
                                                            )}
                                                            {item.closest === true && ' (closest)'}
                                                        </div>
                                                    );
                                                })}
                                        </td>

                                        <td style={{ flex: 1 }}>
                                            {fargoCharts &&
                                                fargoCharts.medium &&
                                                fargoCharts.medium.map((item, index) => {
                                                    return (
                                                        <div
                                                            key={`medium-${index}`}
                                                            style={
                                                                item.closest === true && values.handicap_format === 'fargo_medium_column'
                                                                    ? {
                                                                          backgroundColor: theme && theme.name === 'dark' ? '#083865' : '#e6f7ff',
                                                                          fontWeight: 'bold',
                                                                      }
                                                                    : item.closest === true
                                                                    ? { fontWeight: 'bold' }
                                                                    : {}
                                                            }
                                                        >
                                                            {parseInt(player1Fargo) > parseInt(player2Fargo) ? (
                                                                <Text>
                                                                    {item.highPlayerRaceTo} - {item.lowPlayerRaceTo}
                                                                </Text>
                                                            ) : (
                                                                <Text>
                                                                    {item.lowPlayerRaceTo} - {item.highPlayerRaceTo}
                                                                </Text>
                                                            )}
                                                            {item.closest === true && ' (closest)'}
                                                        </div>
                                                    );
                                                })}
                                        </td>
                                        <td style={{ flex: 1 }}>
                                            {fargoCharts &&
                                                fargoCharts.mild &&
                                                fargoCharts.mild.map((item, index) => {
                                                    return (
                                                        <div
                                                            key={`mild-${index}`}
                                                            style={
                                                                item.closest === true && values.handicap_format === 'fargo_mild_column'
                                                                    ? {
                                                                          backgroundColor: theme && theme.name === 'dark' ? '#083865' : '#e6f7ff',
                                                                          fontWeight: 'bold',
                                                                      }
                                                                    : item.closest === true
                                                                    ? { fontWeight: 'bold' }
                                                                    : {}
                                                            }
                                                        >
                                                            {parseInt(player1Fargo) > parseInt(player2Fargo) ? (
                                                                <Text>
                                                                    {item.highPlayerRaceTo} - {item.lowPlayerRaceTo}
                                                                </Text>
                                                            ) : (
                                                                <Text>
                                                                    {item.lowPlayerRaceTo} - {item.highPlayerRaceTo}
                                                                </Text>
                                                            )}
                                                            {item.closest === true && ' (closest)'}
                                                        </div>
                                                    );
                                                })}
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </React.Fragment>
            )}

            {tournamentType !== 'chip' && tournamentType !== 'round_robin' && (
                <FormItem
                    label="Estimated Bracket Size"
                    name={stageFormField ? `${stageFormField}.max_players` : 'max_players'}
                    extra="The final bracket size will be determined based on how many players have been added."
                    hasFeedback
                    showValidateSuccess
                >
                    <Select
                        showSearch
                        name={stageFormField ? `${stageFormField}.max_players` : 'max_players'}
                        placeholder="Select the bracket size"
                        size="large"
                        optionFilterProp="children"
                        // defaultValue="lucy"
                        filterOption={(input, option) => {
                            return option.props.children.toString().indexOf(input) >= 0;
                        }}
                    >
                        {[2, 4, 8, 16, 32, 64, 128, 256, 512].map((item, index) => (
                            <Option key={index} value={item}>
                                {item}
                            </Option>
                        ))}
                    </Select>
                </FormItem>
            )}

            {tournamentType !== 'chip' && (
                <FormItem
                    name={stageFormField ? `${stageFormField}.draw_type` : 'draw_type'}
                    label="Bracket ordering"
                    extra={
                        drawType === 'random'
                            ? 'The player order will be randomized using the Fisher Yates shuffle algorithm'
                            : drawType === 'seeded'
                            ? 'The highest seeded player plays the lowest seeded player, the second highest seed plays the second lowest seed etc.'
                            : drawType === 'ordered'
                            ? 'You can specify the exact order the players will appear in the bracket using the seed value. Useful for manually determining the player order or digitizing a paper bracket.'
                            : ''
                    }
                >
                    <Radio.Group name={stageFormField ? `${stageFormField}.draw_type` : 'draw_type'} defaultValue="random" size="medium">
                        <Radio.Button value="random">Random draw </Radio.Button>
                        <Radio.Button value="seeded">Seeded draw</Radio.Button>
                        <Radio.Button value="ordered">Set order</Radio.Button>
                        {/* <Radio.Button value="custom">Custom</Radio.Button> */}
                    </Radio.Group>
                    <Tooltip
                        title={
                            <div>
                                Select from a random draw, seeded draw (matching the highest seeds against the lowest seeds), or a set order which allows you to
                                specify the exact order the players will appear in the bracket.
                            </div>
                        }
                    >
                        <QuestionCircleFilled style={{ marginLeft: 10 }} />
                    </Tooltip>
                </FormItem>
            )}

            {tournamentType === 'chip' && (
                <React.Fragment>
                    <FormItem
                        label="Estimated Number of Players"
                        name={stageFormField ? `${stageFormField}.max_players` : 'max_players'}
                        required
                        hasFeedback
                        showValidateSuccess
                    >
                        <InputNumber
                            name={stageFormField ? `${stageFormField}.max_players` : 'max_players'}
                            min={0}
                            placeholder="1"
                            // value={score}
                            // onChange={onChange1}
                            onFocus={handleFocus}
                            // onBlur={handleBlur}
                            // style={styles.inputSmall}
                            // onPressEnter={onEnter}
                            size="large"
                            // disabled
                            style={{ maxWidth: 200 }}
                        />
                    </FormItem>
                    <FormItem
                        label="Players Per Table"
                        name={stageFormField ? `${stageFormField}.players_per_table` : 'players_per_table'}
                        hasFeedback
                        showValidateSuccess
                    >
                        <Input
                            name={stageFormField ? `${stageFormField}.players_per_table` : 'players_per_table'}
                            placeholder="2"
                            // value={score}
                            // onChange={onChange1}
                            onFocus={handleFocus}
                            // onBlur={handleBlur}
                            // style={styles.inputSmall}
                            // onPressEnter={onEnter}
                            size="large"
                            // disabled
                            style={{ maxWidth: 200 }}
                        />
                    </FormItem>
                    <FormItem
                        label="Default Number of Chips Per Player"
                        name={stageFormField ? `${stageFormField}.default_chip_amount` : 'default_chip_amount'}
                        hasFeedback
                        showValidateSuccess
                    >
                        <Input
                            name={stageFormField ? `${stageFormField}.default_chip_amount` : 'default_chip_amount'}
                            placeholder="1"
                            // value={score}
                            // onChange={onChange1}
                            onFocus={handleFocus}
                            // onBlur={handleBlur}
                            // style={styles.inputSmall}
                            // onPressEnter={onEnter}
                            size="large"
                            // disabled
                            style={{ maxWidth: 200 }}
                        />
                        <Button
                            type="primary"
                            ghost
                            size="large"
                            onClick={() => {
                                handleSetChipAmounts();
                            }}
                            style={{ marginLeft: 10 }}
                        >
                            Set custom chip amounts
                        </Button>
                    </FormItem>
                    {/* <FormItem
                                                            name="redraw_every_round"
                                                            label={
                                                                <React.Fragment>
                                                                    Re-draw players every round{' '}
                                                                    <Tooltip
                                                                        title={<div>This option will re-draw the player order every round.</div>}
                                                                        placement="right"
                                                                    >
                                                                        <QuestionCircleFilled style={{ marginLeft: 5 }} />
                                                                    </Tooltip>
                                                                </React.Fragment>
                                                            }
                                                        >
                                                            <Radio.Group name="redraw_every_round">
                                                                <Radio value={true}>Yes</Radio>
                                                                <br />
                                                                <Radio value={false}>No</Radio>
                                                            </Radio.Group>
                                                        </FormItem> */}

                    <FormItem
                        name={stageFormField ? `${stageFormField}.autopilot_mode` : 'autopilot_mode'}
                        label={
                            <React.Fragment>
                                Autopilot mode{' '}
                                <Tooltip
                                    title={
                                        <div>
                                            Auto-pilot mode will automatically assign the next player waiting to play in the queue to a table waiting for an
                                            opponent.
                                        </div>
                                    }
                                    placement="right"
                                >
                                    <QuestionCircleFilled style={{ marginLeft: 5 }} />
                                </Tooltip>
                            </React.Fragment>
                        }
                    >
                        <Radio.Group name={stageFormField ? `${stageFormField}.autopilot_mode` : 'autopilot_mode'}>
                            <Radio value={true}>Yes</Radio>
                            <Radio value={false}>No</Radio>
                        </Radio.Group>
                    </FormItem>

                    {/* <FormItem name="type">
                        <Input name="type" type="hidden" />
                    </FormItem> */}
                </React.Fragment>
            )}

            {tournamentType !== 'custom_template' && index === 0 && (
                <React.Fragment>
                    {values && values.tournament_stage_format === 'multi' && enableGroups === true ? (
                        <React.Fragment>
                            <FormItem
                                label="Bracket groups - The bracket is divided into multiple groups of players (coming soon)"
                                name={stageFormField ? `${stageFormField}.enable_groups` : 'enable_groups'}
                            >
                                <Radio.Group name={stageFormField ? `${stageFormField}.enable_groups` : 'enable_groups'} disabled>
                                    <Radio value={true}>Yes</Radio>
                                    <Radio value={false}>No</Radio>
                                </Radio.Group>
                            </FormItem>
                            <FormItem
                                label="Players in each group"
                                name={stageFormField ? `${stageFormField}.players_per_group` : 'players_per_group'}
                                hasFeedback
                                showValidateSuccess
                            >
                                <InputNumber
                                    name={stageFormField ? `${stageFormField}.players_per_group` : 'players_per_group'}
                                    min={0}
                                    placeholder="4"
                                    // value={score}
                                    // onChange={onChange1}
                                    onFocus={handleFocus}
                                    // onBlur={handleBlur}
                                    // style={styles.inputSmall}
                                    // onPressEnter={onEnter}
                                    size="large"
                                    // disabled
                                    style={{ maxWidth: 200 }}
                                />
                            </FormItem>

                            <FormItem
                                label="Players advancing from each group"
                                name={stageFormField ? `${stageFormField}.players_advance_per_group` : 'players_advance_per_group'}
                                hasFeedback
                                showValidateSuccess
                                extra="Must be a power of 2 for single & double elim (1,2,4,8,16,...)"
                            >
                                <Select
                                    showSearch
                                    name={stageFormField ? `${stageFormField}.players_advance_per_group` : 'players_advance_per_group'}
                                    placeholder="Select number of players"
                                    size="large"
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                >
                                    {arrayOfMultiples(2, maxPlayers)
                                        .filter((players) => {
                                            return players >= maxPlayers ? false : true;
                                        })
                                        .map((item, index) => (
                                            <Option key={index} value={item}>
                                                {item}
                                            </Option>
                                        ))}
                                </Select>
                            </FormItem>
                        </React.Fragment>
                    ) : values && values.tournament_stage_format === 'multi' && enableGroups === false ? (
                        <React.Fragment>
                            <FormItem
                                label="Bracket groups - The bracket is divided into multiple groups of players (coming soon)"
                                name={stageFormField ? `${stageFormField}.enable_groups` : 'enable_groups'}
                            >
                                <Radio.Group name={stageFormField ? `${stageFormField}.enable_groups` : 'enable_groups'} disabled>
                                    <Radio value={true}>Yes</Radio>
                                    <Radio value={false}>No</Radio>
                                </Radio.Group>
                            </FormItem>

                            <FormItem
                                label="Players advancing to next stage"
                                name={stageFormField ? `${stageFormField}.players_advance_per_group` : 'players_advance_per_group'}
                                hasFeedback
                                showValidateSuccess
                                extra="Must be a power of 2 for single & double elim (1,2,4,8,16,...)"
                            >
                                <Select
                                    showSearch
                                    name={stageFormField ? `${stageFormField}.players_advance_per_group` : 'players_advance_per_group'}
                                    placeholder="Select number of players"
                                    size="large"
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    onChange={(players) => {
                                        console.log(players);
                                        if (values && values.stages.length > 1) {
                                            formRef.current.setValues({
                                                ...formRef.current.state.values,
                                                stages: values.stages.map((stage) => {
                                                    return {
                                                        ...stage,
                                                        ...(stage && stage.number === 1 && { players_advance_per_group: players }),
                                                        ...(stage && stage.number === 2 && { players_advance_per_group: undefined, max_players: players }),
                                                    };
                                                }),
                                            });
                                        }
                                    }}
                                >
                                    {arrayOfMultiples(2, maxPlayers)
                                        .filter((players) => {
                                            return players >= maxPlayers ? false : true;
                                        })
                                        .map((item, index) => (
                                            <Option key={index} value={item}>
                                                {item}
                                            </Option>
                                        ))}
                                </Select>
                            </FormItem>
                        </React.Fragment>
                    ) : null}
                </React.Fragment>
            )}
        </React.Fragment>
    );

    return (
        <React.Fragment>
            {values && values.tournament_stage_format === 'multi' ? (
                <Card
                    title={`Stage ${stage.number}`}
                    style={{ marginBottom: 20, borderRadius: 8 }}
                    bodyStyle={{ paddingTop: 10, paddingBottom: 20, paddingLeft: 20, paddingRight: 20 }}
                    extra={
                        <Tooltip title={`Remove stage ${stage.number}`}>
                            <Button
                                type="danger"
                                ghost
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    console.log('delete');
                                    onRemoveStage(stage);
                                    // showConfirmDelete(record);
                                }}
                                disabled={index + 1 === 1}
                            >
                                <Icon type="delete" />
                            </Button>
                        </Tooltip>
                    }
                >
                    {renderForm}
                </Card>
            ) : (
                <React.Fragment>{renderForm}</React.Fragment>
            )}

            <CustomHandicapChartModal
                {...props}
                tournament={tournament}
                tournamentID={tournament && tournament.id}
                modalVisible={handicapModalVisible}
                onModalOk={handleHandicapModalOk}
                onModalCancel={handleHandicapModalCancel}
                onSave={handleSaveHandicaps}
            />

            <ChipAmountModal
                {...props}
                tournament={tournament}
                tournamentID={tournament && tournament.id}
                modalVisible={chipModalVisible}
                onModalOk={handleChipModalOk}
                onModalCancel={handleChipModalCancel}
                onSave={handleSaveChips}
            />
        </React.Fragment>
    );
}
