import React, { useState, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { message, Modal, Button, Upload, Icon, Typography } from 'antd';
// import parse from 'csv-parse';
import parse from '../../lib/csv-parse';
import { useMutation, useLazyQuery } from '@apollo/react-hooks';
import { ApolloConsumer } from '@apollo/client/react/components';
import promiseTimeout from '../../utils/promiseTimeout';
import { GET_USER, GET_LEAGUE_PLAYERS_QUERY, GET_LEAGUE_QUERY, GET_LEAGUE_DIVISION_BY_SLUG_QUERY, GET_LEAGUE_DIVISION_PLAYERS_QUERY } from './data/queries';
import { CREATE_LEAGUE_PLAYER } from './data/mutations';
import { FIND_LEAGUE_PLAYER_BY_ID, FIND_LEAGUE_PLAYER_BY_EMAIL } from './data/queries';
import _ from 'lodash';

const { Text } = Typography;
const { confirm } = Modal;

function ImportPlayersModal(props) {
    const { leagueID, divisionID, onModalOk, onModalCancel, modalVisible } = props;
    const [fileList, setFileList] = useState();
    const [importing, setImporting] = useState(false);
    const [players, setPlayers] = useState();
    const [file, setFile] = useState();
    const [createLeaguePlayer, { mutationLoading, mutationError }] = useMutation(CREATE_LEAGUE_PLAYER);

    const uploadProps = {
        onChange: handleFile,
        beforeUpload: (file) => {
            return false;
        },
    };

    function handleFile(info) {
        let localFileList = info.fileList;
        localFileList = localFileList.slice(-1);
        setFileList(localFileList);
        setFile(info.file);
    }

    async function checkIfUserExistsByEmail(email) {
        // Check if user exists in users table
        const res = await props.client.query({
            query: GET_USER,
            variables: {
                email: email,
            },
        });
        if (res.data && res.data.users && res.data.users.length > 0) {
            return res.data.users[0];
        }
    }

    async function checkIfPlayerExistsById(userId, leagueId) {
        // Check if user exists in league players table
        const res = await props.client.query({
            query: FIND_LEAGUE_PLAYER_BY_ID,
            variables: {
                user_id: userId,
                league_id: leagueId,
            },
        });
        if (res.data && res.data.league_players && res.data.league_players.length > 0) {
            return res.data.league_players[0];
        }
    }

    async function checkIfPlayerExistsByEmail(email, leagueId) {
        // Check if user exists in league players table
        const res = await props.client.query({
            query: FIND_LEAGUE_PLAYER_BY_EMAIL,
            variables: {
                email: email.toString(),
                league_id: leagueId,
            },
        });
        if (res.data && res.data.league_players && res.data.league_players.length > 0) {
            return res.data.league_players[0];
        }
    }

    async function submit(e) {
        let reader = new FileReader();
        reader.onload = (e) => {
            parse(e.target.result, {}, async (err, output) => {
                output = output.slice(1, output.length);
                const totalPlayers = output.length;

                setImporting(true);
                setPlayers(output);

                const promises = [];
                const playersToInsert = [];

                for (let i = 0; i < output.length; i++) {
                    let name = output[i][0];
                    let email = output[i][1];
                    let phone_number = output[i][2];
                    let skill_level = output[i][3];
                    let country = output[i][4];

                    const user = await checkIfUserExistsByEmail(email);

                    // If user exists in users table
                    if (user) {
                        console.log('user found in users table, checking for existing player record...');
                        console.log('user found:', user);
                        var existinPlayer = await checkIfPlayerExistsById(user.id, leagueID);
                        // if player exists in league_players table don't insert
                        if (!existinPlayer) {
                            // If league player doesnt exist then insert it using the user data
                            const existingUser = {
                                league_id: leagueID,
                                user_id: user.id,
                                name: `${user.first_name} ${user.last_name}`,
                                country: user.country,
                                email: user.email,
                                phone_number: user.phone_number,
                                skill_level: parseInt(user.skill_level),
                                ...(divisionID && {
                                    league_player_divisions: {
                                        data: [
                                            {
                                                division_id: divisionID,
                                            },
                                        ],
                                    },
                                }),
                            };
                            // console.log('user not found in league_players table, adding...');
                            // let insertPromise = promiseTimeout(1000, insertLeaguePlayerMutation(existingUser));
                            // promises.push(insertPromise);
                            playersToInsert.push(existingUser);
                        } else {
                            // console.log('player found in league_players table, skipping...');
                            // console.log('player found:', existinPlayer);
                        }
                    } else {
                        // console.log(`user not found with email ${email}, checking for existing player record...`);
                        var existingPlayer = await checkIfPlayerExistsByEmail(email, leagueID);
                        // If no user or league players exist, insert into league players db
                        if (!existingPlayer) {
                            const newLeaguePlayer = {
                                league_id: leagueID,
                                name: name,
                                country: country,
                                email: email,
                                phone_number: phone_number,
                                skill_level: parseInt(skill_level),
                                ...(divisionID && {
                                    league_player_divisions: {
                                        data: [
                                            {
                                                division_id: divisionID,
                                            },
                                        ],
                                    },
                                }),
                            };
                            // console.log(`player record not found for ${email} in tournament_players table, adding...`);
                            // let insertPromise = promiseTimeout(1000, insertTournamentPlayerMutation(newTourneyPlayer));
                            // promises.push(insertPromise);
                            playersToInsert.push(newLeaguePlayer);
                        } else {
                            // console.log(
                            // 	`player record found in tournament_players table with email ${email}, skipping...`
                            // );
                            // console.log(`player found:`, existingPlayer);
                        }
                    }
                    // console.log('---------------------------');
                }
                // console.log('----- end of player list ----');

                console.log(playersToInsert);

                createLeaguePlayer({
                    variables: {
                        objects: playersToInsert,
                    },
                    awaitRefetchQueries: true,
                    notifyOnNetworkStatusChange: true,
                    refetchQueries: divisionID
                        ? [
                              {
                                  query: GET_LEAGUE_QUERY,
                                  variables: { id: leagueID },
                              },
                              {
                                  query: GET_LEAGUE_PLAYERS_QUERY,
                                  variables: { league_id: leagueID },
                              },
                              {
                                  query: GET_LEAGUE_DIVISION_PLAYERS_QUERY,
                                  variables: { league_id: leagueID, division_id: divisionID },
                              },
                              {
                                  query: GET_LEAGUE_DIVISION_BY_SLUG_QUERY,
                                  variables: { leagueId: leagueID, divisionSlug: props.match.params.division },
                              },
                          ]
                        : [
                              {
                                  query: GET_LEAGUE_QUERY,
                                  variables: { id: leagueID },
                              },
                              {
                                  query: GET_LEAGUE_PLAYERS_QUERY,
                                  variables: { league_id: leagueID },
                              },
                          ],
                })
                    .then((data) => {
                        console.log(data);
                        setImporting(false);
                        message.success(totalPlayers + ' Players imported successfully');
                        onModalOk();
                    })
                    .catch((error) => {
                        console.error(error);
                        setImporting(false);
                        message.success('There was an error');
                        onModalOk();
                    });
            });
        };
        if (file) {
            reader.readAsText(file);
        } else {
            message.error('Error: No file selected to import.');
        }

        // props.onModalOk();
    }

    return (
        <Modal
            title="Import Players"
            // getContainer={() => document.querySelector('.tables')}
            visible={modalVisible}
            onOk={onModalOk}
            onCancel={onModalCancel}
            centered
            transitionName="fade"
            // transitionName="none"
            maskTransitionName="none"
            footer={[
                <Button key="cancel" onClick={onModalCancel}>
                    Cancel
                </Button>,
                <Button key="submit" type="primary" onClick={submit} loading={importing}>
                    {importing === true
                        ? `Importing ${players ? players.length : ''} players...`
                        : `Import ${players ? `${players.length} Players` : 'Players'}`}
                </Button>,
            ]}
            bodyStyle={{ maxHeight: 500, overflowY: 'auto' }}
        >
            <Upload {...uploadProps} fileList={fileList}>
                <Button size="large">
                    <Icon type="upload" /> Upload players spreadsheet (.csv only)
                </Button>
            </Upload>
            <div style={{ marginTop: 10 }}>
                <Text>Download sample players spreadsheet:</Text>
                <ul style={{ marginTop: 10 }}>
                    <li>
                        <a
                            href="https://docs.google.com/spreadsheets/d/e/2PACX-1vTJS5alqUSm1vAKzTtGrrXFT-VVnSUQvvfJqvm1pmhNA-zcHCPPUuNIteewZPZUhyO5LshmDkPcM7yk/pub?gid=0&single=true&output=csv"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            8 players
                        </a>
                    </li>
                    <li>
                        <a
                            href="https://docs.google.com/spreadsheets/d/e/2PACX-1vTJS5alqUSm1vAKzTtGrrXFT-VVnSUQvvfJqvm1pmhNA-zcHCPPUuNIteewZPZUhyO5LshmDkPcM7yk/pub?gid=226496687&single=true&output=csv"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            16 players
                        </a>
                    </li>
                    <li>
                        <a
                            href="https://docs.google.com/spreadsheets/d/e/2PACX-1vTJS5alqUSm1vAKzTtGrrXFT-VVnSUQvvfJqvm1pmhNA-zcHCPPUuNIteewZPZUhyO5LshmDkPcM7yk/pub?gid=1488275380&single=true&output=csv"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            32 players
                        </a>
                    </li>
                    <li>
                        <a
                            href="https://docs.google.com/spreadsheets/d/e/2PACX-1vTJS5alqUSm1vAKzTtGrrXFT-VVnSUQvvfJqvm1pmhNA-zcHCPPUuNIteewZPZUhyO5LshmDkPcM7yk/pub?gid=635549493&single=true&output=csv"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            64 players
                        </a>
                    </li>
                    <li>
                        <a
                            href="https://docs.google.com/spreadsheets/d/e/2PACX-1vTJS5alqUSm1vAKzTtGrrXFT-VVnSUQvvfJqvm1pmhNA-zcHCPPUuNIteewZPZUhyO5LshmDkPcM7yk/pub?gid=1250317935&single=true&output=csv"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            128 players
                        </a>
                    </li>
                </ul>
            </div>
        </Modal>
    );
}

export default withRouter(ImportPlayersModal);
