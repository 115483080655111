import gql from 'graphql-tag';

export const GET_TOURNAMENT_BY_SLUG_QUERY = gql`
    query tournaments($slug: String) {
        tournaments(where: { slug: { _eq: $slug } }) {
            id
            name
            slug
            description
            avatar
            flyer
            logo
            tournament_type
            tournament_format
            bracket_template_id
            participant_times_played
            enable_groups
            enable_sets
            sets_per_match
            set_tiebreaker_format
            split_bracket
            players_per_group
            players_advance_per_group
            players_ranked_by
            handicap_format
            player_type
            game_type
            winners_race_to
            winners_max_race
            losers_race_to
            losers_max_race
            finals_race_to
            race_to
            consolidation_finals
            max_players
            max_tables
            rebuys_allowed
            max_rebuys
            rule_format
            break_format
            status
            progress
            start_date_time
            end_date_time
            entry_fee
            added_money
            payout_type
            draw_type
            rating_system
            use_text_messaging
            use_in_app_messaging
            rsvp_allowed
            show_player_skill_levels
            show_player_races
            show_unconfirmed_players
            is_public
            is_featured
            tournament_stage_format
            signup_cutoff_time
            usapl_race_format
            filter_by_round
            players_per_table
            redraw_every_round
            default_chip_amount
            autopilot_mode
            event_id
            event {
                id
                name
                slug
                description
            }
            director_id
            director {
                id
                first_name
                last_name
                slug
                email
            }
            organizer_id
            organizer {
                id
                first_name
                last_name
                slug
                email
            }
            venue {
                id
                name
                slug
                description
                venue_type
                avatar
                address1
                address2
                city
                country
                region
                postal_code
                phone_number
                email_address
                website
                operating_hours
                social_facebook
                social_instagram
                social_twitter
            }
            stages {
                id
                name
                slug
                number
                type
                status
                tournament_id
                draw_type
                tournament_type
                tournament_format
                usapl_race_format
                handicap_format
                autopilot_mode
                bracket_template_id
                consolidation_finals
                max_players
                winners_race_to
                losers_race_to
                split_bracket
                participant_times_played
                players_advance_per_group
                players_per_group
                players_per_table
                players_ranked_by
                default_chip_amount
                enable_groups
                finals_race_to
            }
            tournament_templates {
                id
                name
                tournament_id
                user_id
            }
            tournament_payouts(where: { money: { _is_null: false } }) {
                money
                place
                calcutta
            }
            tournament_brackets_aggregate {
                aggregate {
                    count
                }
            }
            tournament_managers {
                manager {
                    id
                    first_name
                    last_name
                    display_name
                }
            }
            bye_matches: tournament_brackets_aggregate(where: { is_bye: { _eq: true } }) {
                aggregate {
                    count
                }
            }
            in_progress_matches: tournament_brackets_aggregate(where: { status: { _eq: IN_PROGRESS } }) {
                aggregate {
                    count
                }
            }
            completed_matches: tournament_brackets_aggregate(where: { _and: [{ status: { _eq: COMPLETED } }, { is_bye: { _eq: false } }] }) {
                aggregate {
                    count
                }
            }
            not_started_matches: tournament_brackets_aggregate(where: { status: { _eq: NOT_STARTED } }) {
                aggregate {
                    count
                }
            }
            tournament_players_aggregate {
                aggregate {
                    count
                }
            }
            unconfirmed_players: tournament_players_aggregate(where: { status: { _eq: UNCONFIRMED } }) {
                aggregate {
                    count
                }
            }
            confirmed_players: tournament_players_aggregate(where: { status: { _eq: CONFIRMED } }) {
                aggregate {
                    count
                }
            }
            waiting_list_players: tournament_players_aggregate(where: { status: { _eq: WAITING_LIST } }) {
                aggregate {
                    count
                }
            }
            pool_tables_aggregate {
                aggregate {
                    count
                }
            }

            available_tables: pool_tables_aggregate(where: { status: { _neq: CLOSED } }) {
                aggregate {
                    count
                }
            }
            in_use_tables: pool_tables_aggregate(where: { status: { _eq: IN_USE } }) {
                aggregate {
                    count
                }
            }
            open_tables: pool_tables_aggregate(where: { status: { _eq: OPEN } }) {
                aggregate {
                    count
                }
            }
            closed_tables: pool_tables_aggregate(where: { status: { _eq: CLOSED } }) {
                aggregate {
                    count
                }
            }
            created_at
            updated_at
        }
    }
`;

export const GET_TOURNAMENT_QUERY = gql`
    query tournaments($id: Int) {
        tournaments(where: { id: { _eq: $id } }) {
            id
            name
            slug
            description
            avatar
            flyer
            logo
            tournament_type
            tournament_format
            bracket_template_id
            participant_times_played
            enable_groups
            enable_sets
            sets_per_match
            set_tiebreaker_format
            split_bracket
            players_per_group
            players_advance_per_group
            players_ranked_by
            handicap_format
            player_type
            game_type
            winners_race_to
            winners_max_race
            losers_race_to
            losers_max_race
            finals_race_to
            race_to
            consolidation_finals
            max_players
            max_tables
            max_tables
            rebuys_allowed
            rule_format
            break_format
            status
            progress
            start_date_time
            end_date_time
            entry_fee
            added_money
            payout_type
            draw_type
            rating_system
            use_text_messaging
            use_in_app_messaging
            rsvp_allowed
            show_player_skill_levels
            show_player_races
            show_unconfirmed_players
            is_public
            is_featured
            tournament_stage_format
            signup_cutoff_time
            usapl_race_format
            filter_by_round
            players_per_table
            redraw_every_round
            default_chip_amount
            autopilot_mode
            event_id
            event {
                id
                name
                slug
                description
            }
            director_id
            director {
                id
                first_name
                last_name
                slug
                email
            }
            organizer_id
            organizer {
                id
                first_name
                last_name
                slug
                email
            }
            venue {
                id
                name
                slug
                description
                venue_type
                avatar
                address1
                address2
                city
                country
                region
                postal_code
                phone_number
                email_address
                website
                operating_hours
                social_facebook
                social_instagram
                social_twitter
            }
            stages {
                id
                name
                slug
                number
                type
                status
                tournament_id
                draw_type
                tournament_type
                tournament_format
                usapl_race_format
                handicap_format
                autopilot_mode
                bracket_template_id
                consolidation_finals
                max_players
                winners_race_to
                losers_race_to
                split_bracket
                participant_times_played
                players_advance_per_group
                players_per_group
                players_per_table
                players_ranked_by
                default_chip_amount
                enable_groups
                finals_race_to
            }
            tournament_payouts(where: { money: { _is_null: false } }) {
                money
                place
                calcutta
            }
            tournament_brackets_aggregate {
                aggregate {
                    count
                }
            }
            bye_matches: tournament_brackets_aggregate(where: { is_bye: { _eq: true } }) {
                aggregate {
                    count
                }
            }
            in_progress_matches: tournament_brackets_aggregate(where: { status: { _eq: IN_PROGRESS } }) {
                aggregate {
                    count
                }
            }
            completed_matches: tournament_brackets_aggregate(where: { _and: [{ status: { _eq: COMPLETED } }, { is_bye: { _eq: false } }] }) {
                aggregate {
                    count
                }
            }
            not_started_matches: tournament_brackets_aggregate(where: { status: { _eq: NOT_STARTED } }) {
                aggregate {
                    count
                }
            }
            tournament_players_aggregate {
                aggregate {
                    count
                }
            }
            unconfirmed_players: tournament_players_aggregate(where: { status: { _eq: UNCONFIRMED } }) {
                aggregate {
                    count
                }
            }
            confirmed_players: tournament_players_aggregate(where: { status: { _eq: CONFIRMED } }) {
                aggregate {
                    count
                }
            }
            waiting_list_players: tournament_players_aggregate(where: { status: { _eq: WAITING_LIST } }) {
                aggregate {
                    count
                }
            }
            pool_tables_aggregate {
                aggregate {
                    count
                }
            }
            available_tables: pool_tables_aggregate(where: { status: { _neq: CLOSED } }) {
                aggregate {
                    count
                }
            }
            in_use_tables: pool_tables_aggregate(where: { status: { _eq: IN_USE } }) {
                aggregate {
                    count
                }
            }
            open_tables: pool_tables_aggregate(where: { status: { _eq: OPEN } }) {
                aggregate {
                    count
                }
            }
            closed_tables: pool_tables_aggregate(where: { status: { _eq: CLOSED } }) {
                aggregate {
                    count
                }
            }
            created_at
            updated_at
        }
    }
`;

export const GET_TOURNAMENT_TEMPLATES = gql`
    query tournament_templates($user_id: Int) {
        tournament_templates(where: { user_id: { _eq: $user_id } }) {
            id
            name
            tournament_id
            tournament {
                id
                name
                slug
                description
                avatar
                flyer
                logo
                tournament_type
                tournament_format
                bracket_template_id
                participant_times_played
                enable_groups
                enable_sets
                sets_per_match
                set_tiebreaker_format
                split_bracket
                players_per_group
                players_advance_per_group
                players_ranked_by
                handicap_format
                player_type
                game_type
                winners_race_to
                winners_max_race
                losers_race_to
                losers_max_race
                finals_race_to
                race_to
                consolidation_finals
                max_players
                max_tables
                max_tables
                rebuys_allowed
                rule_format
                break_format
                status
                progress
                start_date_time
                end_date_time
                entry_fee
                added_money
                payout_type
                draw_type
                rating_system
                use_text_messaging
                use_in_app_messaging
                rsvp_allowed
                show_player_skill_levels
                show_player_races
                show_unconfirmed_players
                is_public
                is_featured
                tournament_stage_format
                signup_cutoff_time
                usapl_race_format
                filter_by_round
                players_per_table
                redraw_every_round
                default_chip_amount
                autopilot_mode
                event_id
                event {
                    id
                    name
                    slug
                    description
                }
                director_id
                director {
                    id
                    first_name
                    last_name
                    slug
                    email
                }
                organizer_id
                organizer {
                    id
                    first_name
                    last_name
                    slug
                    email
                }
                venue {
                    id
                    name
                    slug
                    description
                    venue_type
                    avatar
                    address1
                    address2
                    city
                    country
                    region
                    postal_code
                    phone_number
                    email_address
                    website
                    operating_hours
                    social_facebook
                    social_instagram
                    social_twitter
                }
                tournament_payouts(where: { money: { _is_null: false } }) {
                    money
                    place
                    calcutta
                }
            }
            user_id
            created_at
            updated_at
        }
    }
`;

export const GET_USER_TOURNAMENTS_QUERY = gql`
    query tournaments($manager_id: Int, $limit: Int) {
        tournaments(
            where: {
                _or: [
                    { tournament_managers: { manager_id: { _eq: $manager_id } } }
                    { director_id: { _eq: $manager_id } }
                    { organizer_id: { _eq: $manager_id } }
                ]
            }
            order_by: { updated_at: desc }
            limit: $limit
        ) {
            id
            name
            slug
            tournament_type
            tournament_format
            bracket_template_id
            handicap_format
            player_type
            game_type
            max_players
            progress
            is_public
            status
            director_id
            organizer_id
            organizer {
                id
                first_name
                last_name
                slug
                email
            }
            venue {
                id
                name
                slug
                avatar
                address1
                address2
                city
                country
                region
            }
            created_at
            updated_at
        }
    }
`;

export const GET_TOURNAMENT_BRACKET_QUERY = gql`
    query tournament_brackets($tournamentId: Int!) {
        tournament_brackets(where: { tournament_id: { _eq: $tournamentId } }, order_by: { id: asc }) {
            id
            hash_id
            round
            group
            identifier
            place
            challenger1 {
                id
                name
                country
                email
                country_phone
                phone_number
                skill_level
                status
                points
                seed
                race_to
                user {
                    id
                    first_name
                    last_name
                    country
                    email
                    country_phone
                    phone_number
                    skill_level
                }
            }
            challenger2 {
                id
                name
                country
                email
                country_phone
                phone_number
                skill_level
                status
                points
                seed
                race_to
                user {
                    id
                    first_name
                    last_name
                    country
                    email
                    country_phone
                    phone_number
                    skill_level
                }
            }
            challenger1_id
            challenger1_name
            challenger1_country
            challenger1_score
            challenger1_race_to
            challenger1_seed
            challenger1_is_winner
            challenger1_is_forfeit
            challenger2_id
            challenger2_name
            challenger2_country
            challenger2_score
            challenger2_race_to
            challenger2_seed
            challenger2_is_winner
            challenger2_is_forfeit
            tournament_player_id
            match_number
            match_id
            team_id
            scheduled_time
            start_time
            end_time
            duration
            table_id
            table_name
            scheduled_table_id
            priority
            scheduled_table {
                slug
                label
                type
                size
                year_built
                make
                model
                created_at
                updated_at
                status
            }
            seed
            status
            progress
            loser_from
            loser_from_num
            loser_to
            loser_to_num
            winner_from
            winner_to
            winner_to_num
            winner_from_top_num
            winner_from_bottom_num
            loser_from_top_num
            loser_from_bottom_num
            advance_to
            stage_id
            stage_number
            is_bye
            is_semi_finals
            is_finals
            is_consolidation_finals
            tournament {
                start_date_time
            }
            pool_table {
                id
                slug
                label
                type
                size
                year_built
                make
                model
                created_at
                updated_at
                status
                is_streaming_table
                is_featured_table
                livestream_url
            }
        }
    }
`;

export const GET_TOURNAMENT_BRACKET_BY_STATUS_QUERY = gql`
    query tournament_brackets($tournament_id: Int!, $status: tournament_status_enum) {
        tournament_brackets(where: { tournament_id: { _eq: $tournament_id }, status: { _eq: $status } }, order_by: { match_number: asc }) {
            id
            hash_id
            round
            group
            identifier
            place
            challenger1 {
                id
                name
                country
                email
                phone_number
                skill_level
                status
                points
                seed
                race_to
                user {
                    id
                    first_name
                    last_name
                    country
                    email
                    country_phone
                    phone_number
                    skill_level
                }
            }
            challenger2 {
                id
                name
                country
                email
                phone_number
                skill_level
                status
                points
                seed
                race_to
                user {
                    id
                    first_name
                    last_name
                    country
                    email
                    country_phone
                    phone_number
                    skill_level
                }
            }
            challenger1_id
            challenger1_name
            challenger1_country
            challenger1_score
            challenger1_race_to
            challenger1_seed
            challenger1_is_winner
            challenger1_is_forfeit
            challenger2_id
            challenger2_name
            challenger2_country
            challenger2_score
            challenger2_race_to
            challenger2_seed
            challenger2_is_winner
            challenger2_is_forfeit
            tournament_player_id
            match_number
            match_id
            team_id
            scheduled_time
            start_time
            end_time
            duration
            table_id
            table_name
            seed
            status
            progress
            loser_from
            loser_from_num
            loser_to
            loser_to_num
            winner_from
            winner_to
            winner_to_num
            winner_from_top_num
            winner_from_bottom_num
            loser_from_top_num
            loser_from_bottom_num
            advance_to
            stage_id
            stage_number
            is_bye
            is_semi_finals
            is_finals
            is_consolidation_finals
        }
    }
`;

export const GET_TABLES_QUERY = gql`
    query pool_tables {
        pool_tables {
            id
            slug
            label
            type
            size
            year_built
            make
            model
            is_streaming_table
            is_featured_table
            created_at
            updated_at
            status
            manufacturer {
                id
                name
            }
            venue {
                id
                name
            }
            user {
                id
                email
            }
        }
    }
`;

export const GET_SCHEDULED_TABLES_QUERY = gql`
    query pool_tables($tournament_id: Int) {
        pool_tables(where: { tournament_id: { _eq: $tournament_id } }, order_by: { label: asc }) {
            slug
            label
            type
            size
            year_built
            make
            model
            created_at
            updated_at
            is_streaming_table
            is_featured_table
            status
            scheduled_matches(where: { status: { _neq: COMPLETED } }) {
                id
                hash_id
                round
                identifier
                place
                challenger1 {
                    id
                    name
                    country
                    email
                    country_phone
                    phone_number
                    skill_level
                    status
                    points
                    seed
                    race_to
                    user {
                        id
                        first_name
                        last_name
                        country
                        email
                        country_phone
                        phone_number
                        skill_level
                    }
                }
                challenger2 {
                    id
                    name
                    country
                    email
                    country_phone
                    phone_number
                    skill_level
                    status
                    points
                    seed
                    race_to
                    user {
                        id
                        first_name
                        last_name
                        country
                        email
                        country_phone
                        phone_number
                        skill_level
                    }
                }
                challenger1_id
                challenger1_name
                challenger1_country
                challenger1_score
                challenger1_race_to
                challenger1_seed
                challenger1_is_winner
                challenger1_is_forfeit
                challenger1_seed
                challenger2_id
                challenger2_name
                challenger2_country
                challenger2_score
                challenger2_race_to
                challenger2_seed
                challenger2_is_winner
                challenger2_is_forfeit
                tournament_player_id
                match_number
                match_id
                team_id
                scheduled_time
                start_time
                end_time
                duration
                table_id
                table_name
                scheduled_table_id
                priority
                scheduled_table {
                    slug
                    label
                    type
                    size
                    year_built
                    make
                    model
                    created_at
                    updated_at
                    status
                }
                seed
                status
                progress
                loser_from
                loser_from_num
                loser_to
                loser_to_num
                winner_from
                winner_to
                winner_to_num
                winner_from_top_num
                winner_from_bottom_num
                loser_from_top_num
                loser_from_bottom_num
                advance_to
                stage_id
                stage_number
                is_bye
                is_semi_finals
                is_finals
                is_consolidation_finals
            }
        }
    }
`;

export const GET_ALL_TOURNAMENT_TABLES_QUERY = gql`
    query pool_tables($tournament_id: Int) {
        pool_tables(where: { tournament_id: { _eq: $tournament_id } }, order_by: { label: asc }) {
            id
            slug
            label
            type
            size
            year_built
            make
            model
            created_at
            updated_at
            status
            is_streaming_table
            is_featured_table
            tournament {
                id
                slug
                game_type
            }
            manufacturer {
                id
                name
            }
            venue {
                id
                name
            }
            user {
                id
                email
            }
            tournament_match_table {
                id
                hash_id
                round
                identifier
                challenger1_id
                challenger1_name
                challenger1_country
                challenger1_score
                challenger1_race_to
                challenger1_seed
                challenger1_is_winner
                challenger1_is_forfeit
                challenger1_seed
                challenger2_id
                challenger2_name
                challenger2_country
                challenger2_score
                challenger2_race_to
                challenger2_seed
                challenger2_is_winner
                challenger2_is_forfeit
                tournament_player_id
                match_number
                match_id
                team_id
                scheduled_time
                start_time
                end_time
                duration
                table_id
                table_name
                seed
                status
                progress
                loser_from
                loser_from_num
                loser_to
                loser_to_num
                winner_from
                winner_to
                winner_to_num
                winner_from_top_num
                winner_from_bottom_num
                loser_from_top_num
                loser_from_bottom_num
                advance_to
                stage_id
                stage_number
                is_bye
                is_semi_finals
                is_finals
                is_consolidation_finals
            }
        }
    }
`;

export const GET_TOURNAMENT_TABLES_QUERY = gql`
    query pool_tables($tournament_id: Int, $status: pool_table_status_enum) {
        pool_tables(where: { tournament_id: { _eq: $tournament_id }, _and: { status: { _eq: $status } } }, order_by: { label: asc }) {
            id
            slug
            label
            type
            size
            year_built
            make
            model
            created_at
            updated_at
            status
            is_streaming_table
            is_featured_table
            tournament {
                id
                slug
                game_type
            }
            manufacturer {
                id
                name
            }
            venue {
                id
                name
                slug
            }
            user {
                id
                email
            }

            tournament_match_table {
                id
                hash_id
                round
                identifier
                challenger1_id
                challenger1_name
                challenger1_country
                challenger1_score
                challenger1_race_to
                challenger1_seed
                challenger1_is_winner
                challenger1_is_forfeit
                challenger1_seed
                challenger2_id
                challenger2_name
                challenger2_country
                challenger2_score
                challenger2_race_to
                challenger2_seed
                challenger2_is_winner
                challenger2_is_forfeit
                tournament_player_id
                match_number
                match_id
                team_id
                scheduled_time
                start_time
                end_time
                duration
                table_id
                table_name
                seed
                status
                progress
                loser_from
                loser_from_num
                loser_to
                loser_to_num
                winner_from
                winner_to
                winner_to_num
                winner_from_top_num
                winner_from_bottom_num
                loser_from_top_num
                loser_from_bottom_num
                advance_to
                stage_id
                stage_number
                is_bye
                is_semi_finals
                is_finals
                is_consolidation_finals
            }
        }
    }
`;

export const GET_STAGE_QUERY = gql`
    query stages($id: Int) {
        stages(where: { id: { _eq: $id } }) {
            id
            name
            slug
            number
            type
            status
            tournament_id
            draw_type
            tournament_type
            tournament_format
            usapl_race_format
            handicap_format
            autopilot_mode
            bracket_template_id
            consolidation_finals
            max_players
            winners_race_to
            losers_race_to
            split_bracket
            participant_times_played
            players_advance_per_group
            players_per_group
            players_per_table
            players_ranked_by
            default_chip_amount
            enable_groups
            finals_race_to
        }
    }
`;

export const GET_TABLE_QUERY = gql`
    query pool_tables($id: Int) {
        pool_tables(where: { id: { _eq: $id } }) {
            id
            slug
            label
            type
            size
            year_built
            make
            model
            created_at
            updated_at
            is_streaming_table
            is_featured_table
            status
            manufacturer {
                id
                name
            }
            venue {
                id
                name
                slug
            }
            user {
                id
                email
            }
        }
    }
`;

export const GET_TABLE_BY_SLUG_QUERY = gql`
    query pool_tables($tournament_slug: String!, $table_slug: String!) {
        pool_tables(where: { tournament: { slug: { _eq: $tournament_slug } }, slug: { _eq: $table_slug } }) {
            id
            slug
            label
            type
            size
            year_built
            make
            model
            created_at
            updated_at
            is_streaming_table
            is_featured_table
            livestream_url
            status
            manufacturer {
                id
                name
            }
            venue {
                id
                name
                slug
            }
            user {
                id
                email
            }
            tournament_match_table(where: { status: { _eq: IN_PROGRESS } }) {
                id
                hash_id
                round
                identifier
                challenger1_id
                challenger1_name
                challenger1_country
                challenger1_score
                challenger1_race_to
                challenger1_seed
                challenger1_is_winner
                challenger1_is_forfeit
                challenger1_seed
                challenger2_id
                challenger2_name
                challenger2_country
                challenger2_score
                challenger2_race_to
                challenger2_seed
                challenger2_is_winner
                challenger2_is_forfeit
                tournament_player_id
                match_number
                match_id
                team_id
                scheduled_time
                start_time
                end_time
                duration
                table_id
                table_name
                seed
                status
                progress
                loser_from
                loser_from_num
                loser_to
                loser_to_num
                winner_from
                winner_to
                winner_to_num
                winner_from_top_num
                winner_from_bottom_num
                loser_from_top_num
                loser_from_bottom_num
                advance_to
                stage_id
                stage_number
                is_bye
                is_semi_finals
                is_finals
                is_consolidation_finals
            }
        }
    }
`;

export const GET_TABLE_MANUFACTURERS_QUERY = gql`
    query pool_table_manufacturers {
        pool_table_manufacturers(order_by: { name: asc }) {
            id
            name
        }
    }
`;

export const GET_VENUE_TYPES_QUERY = gql`
    query venue_types {
        venue_types {
            value
            comment
        }
    }
`;

export const GET_GAME_TYPES_QUERY = gql`
    query game_types {
        game_types {
            value
            comment
        }
    }
`;

export const GET_RECENT_VENUES_QUERY = gql`
    query venues {
        venues(
            distinct_on: name
            where: {
                is_public: { _eq: true }
                _or: [
                    { name: { _ilike: $name } }
                    { address1: { _ilike: $name } }
                    { city: { _ilike: $name } }
                    { region: { _ilike: $name } }
                    { postal_code: { _ilike: $name } }
                ]
            }
            order_by: { created_at: desc }
            limit: 10
        ) {
            id
            name
            slug
            address1
            city
            region
            country
            postal_code
            venue_type
            lat
            lng
            pool_tables_aggregate {
                aggregate {
                    count
                }
            }
            pool_tables(order_by: { label: asc }) {
                id
                slug
                label
                type
                size
                year_built
                make
                model
            }
            owner {
                id
            }
        }
    }
`;

export const GET_EVENT_QUERY = gql`
    query events($eventSlug: String) {
        events(where: { slug: { _eq: $eventSlug } }) {
            id
            name
            slug
            event_type
            description
            logo
            flyer
            location
            start_date_time
            end_date_time
            deleted_at
            is_public
            venue_id
            owner {
                id
                email
            }
            tournaments {
                name
                id
                slug
                status
                progress
                start_date_time
                end_date_time
                flyer
                game_type
                tournament_type
                tournament_format
                handicap_format
                bracket_template_id
                max_players
                description
                created_at
                updated_at
                winners_race_to
                winners_max_race
                losers_race_to
                losers_max_race
                finals_race_to
                handicapped
                tournament_players(
                    where: { _or: [{ place: { _eq: "1" } }, { place: { _eq: "2" } }, { place: { _eq: "3" } }] }
                    order_by: { place: asc }
                    limit: 4
                ) {
                    name
                    place
                }
                tournament_players_aggregate {
                    aggregate {
                        count
                    }
                }

                director {
                    id
                    slug
                    first_name
                    last_name
                }
                venue {
                    id
                    name
                    slug
                    city
                    country
                    region
                }
            }
            venue {
                id
                name
                slug
                city
                country
                region
                avatar
            }
        }
    }
`;

export const GET_EVENTS_QUERY = gql`
    query events {
        events(where: { deleted_at: { _is_null: true }, is_public: { _eq: true } }, order_by: { updated_at: desc }) {
            id
            name
            slug
            event_type
            description
            logo
            flyer
            location
            start_date_time
            end_date_time
            deleted_at
            is_public
            venue_id
            owner {
                id
                email
            }
            venue {
                id
                name
                slug
                city
                country
                region
                avatar
            }
            # is_public
            # owner {
            # 	id
            # 	first_name
            # 	last_name
            # 	email
            # }
            # operator {
            # 	id
            # 	first_name
            # 	last_name
            # 	email
            # }
            # address1
            # address2
            # city
            # county
            # country
            # region
            # postal_code
            # phone_number
            # fax_number
            # email_address
            # website
            # social_facebook
            # social_instagram
            # social_twitter
            # created_at
            # updated_at
            # match_notifications_in_app
            # match_notifications_sms
        }
    }
`;

export const GET_USER_EVENTS_QUERY = gql`
    query events($user_id: Int) {
        events(where: { owner_id: { _eq: $user_id } }, order_by: { name: asc }) {
            id
            name
            slug
            event_type
            description
            logo
            flyer
            location
            start_date_time
            end_date_time
            deleted_at
            owner {
                id
                email
            }
            venue {
                id
                name
                slug
                city
                country
                region
                avatar
            }
        }
    }
`;

export const GET_VENUE_TABLES_QUERY = gql`
    query venue_pool_tables($venueId: Int) {
        venue_pool_tables(where: { venue_id: { _eq: $venueId } }, order_by: { label: asc }) {
            id
            label
            type
            size
            year_built
            make
            model
            livestream_url
            is_streaming_table
            is_featured_table
            created_at
            updated_at
            status
        }
    }
`;

export const GET_LEAGUE_VENUES_QUERY = gql`
    query venues($leagueId: Int) {
        venues(where: { league_venues: { league_id: { _eq: $leagueId } } }, order_by: { name: asc }) {
            id
            name
            slug
            description
            venue_type
            league_venues {
                league {
                    id
                    name
                    slug
                }
            }
            avatar
            address1
            address2
            city
            country
            region
            postal_code
            phone_number
            email_address
            website
            operating_hours
            social_facebook
            social_instagram
            social_twitter
            is_public
            is_claimed
            claimed_by_id
            organizer_id
        }
    }
`;

export const GET_VENUE_QUERY = gql`
    query venues($slug: String) {
        venues(where: { slug: { _eq: $slug } }) {
            id
            name
            slug
            description
            venue_type
            avatar
            address1
            address2
            city
            country
            region
            postal_code
            phone_number
            email_address
            website
            operating_hours
            social_facebook
            social_instagram
            social_twitter
            is_public
            is_claimed
            claimed_by_id
            owner_id
            pool_tables(order_by: { label: asc }) {
                id
                slug
                label
                type
                size
                year_built
                make
                model
                livestream_url
                is_streaming_table
                is_featured_table
                created_at
                updated_at
            }
        }
    }
`;

export const GET_VENUE_BY_ID_QUERY = gql`
    query venues($id: Int!) {
        venues(where: { id: { _eq: $id } }) {
            id
            name
            slug
            description
            venue_type
            avatar
            address1
            address2
            city
            country
            region
            postal_code
            phone_number
            email_address
            website
            operating_hours
            social_facebook
            social_instagram
            social_twitter
            is_public
            is_claimed
            claimed_by_id
            owner_id
            pool_tables(order_by: { label: asc }) {
                id
                slug
                label
                type
                size
                year_built
                make
                model
                livestream_url
                is_streaming_table
                is_featured_table
                created_at
                updated_at
            }
        }
    }
`;

export const GET_TABLE_BY_ID_QUERY = gql`
    query pool_tables($id: Int) {
        pool_tables(where: { id: { _eq: $id } }) {
            id
            slug
            label
            type
            size
            year_built
            make
            model
            livestream_url
            manufacturer_id
            venue_id
            user_id
            match_id
            is_streaming_table
            is_featured_table
            created_at
            updated_at
            status
        }
    }
`;

export const GET_USER = gql`
    query users($email: String, $phone_number: String) {
        users(where: { _or: [{ email: { _eq: $email } }, { phone_number: { _eq: $phone_number } }] }) {
            id
            first_name
            last_name
            slug
            country
            email
            nickname
            country_phone
            phone_number
            skill_level
            fargo_id
        }
    }
`;

export const GET_TOURNAMENT_PLAYERS_QUERY = gql`
    query tournament_players($tournament_id: Int) {
        tournament_players(where: { tournament_id: { _eq: $tournament_id } }, order_by: { name: asc }) {
            id
            name
            nickname
            country
            email
            country_phone
            phone_number
            skill_level
            status
            points
            seed
            race_to
            special_group
            chips_left
            lives_left
            is_eliminated
            last_played_at
            group
            player_id
            fargo_id
            user_id
            user {
                id
                first_name
                last_name
                nickname
                display_name
                avatar
                country
                email
                country_phone
                phone_number
                skill_level
                fargo_id
            }
            tournament_matches_challenger1 {
                id
                hash_id
                round
                identifier
                place
                challenger1_id
                challenger1_name
                challenger1_country
                challenger1_score
                challenger1_race_to
                challenger1_seed
                challenger1_is_winner
                challenger1_is_forfeit
                challenger2_id
                challenger2_name
                challenger2_country
                challenger2_score
                challenger2_race_to
                challenger2_seed
                challenger2_is_winner
                challenger2_is_forfeit
                tournament_player_id
                match_number
                match_id
                team_id
                scheduled_time
                start_time
                end_time
                duration
                table_id
                table_name
                seed
                status
                progress
                stage_id
            }
            tournament_matches_challenger2 {
                id
                hash_id
                round
                identifier
                place
                challenger1_id
                challenger1_name
                challenger1_country
                challenger1_score
                challenger1_race_to
                challenger1_seed
                challenger1_is_winner
                challenger1_is_forfeit
                challenger2_id
                challenger2_name
                challenger2_country
                challenger2_score
                challenger2_race_to
                challenger2_seed
                challenger2_is_winner
                challenger2_is_forfeit
                tournament_player_id
                match_number
                match_id
                team_id
                scheduled_time
                start_time
                end_time
                duration
                table_id
                table_name
                seed
                status
                progress
                stage_id
            }
        }
    }
`;

export const GET_TOURNAMENT_PLAYERS_W_STATS = gql`
    query tournament_players($tournament_id: Int) {
        tournament_players(where: { tournament_id: { _eq: $tournament_id } }, order_by: { seed: asc }) {
            id
            name
            nickname
            country
            skill_level
            fargo_id
            status
            points
            seed
            place
            race_to
            country_phone
            phone_number
            chips_left
            lives_left
            is_eliminated
            last_played_at
            group
            player_id
            user_id
            user {
                id
                first_name
                last_name
                nickname
                display_name
                avatar
                country
                skill_level
                country_phone
                phone_number
                fargo_id
            }
            payouts {
                money
                calcutta
            }
            player_points {
                points
            }
            tournament_matches_challenger1 {
                id
                hash_id
                round
                identifier
                place
                challenger1_id
                challenger1_name
                challenger1_country
                challenger1_score
                challenger1_race_to
                challenger1_seed
                challenger1_is_winner
                challenger1_is_forfeit
                challenger2_id
                challenger2_name
                challenger2_country
                challenger2_score
                challenger2_race_to
                challenger2_seed
                challenger2_is_winner
                challenger2_is_forfeit
                tournament_player_id
                match_number
                match_id
                team_id
                scheduled_time
                start_time
                end_time
                duration
                table_id
                table_name
                seed
                status
                progress
            }
            tournament_matches_challenger2 {
                id
                hash_id
                round
                identifier
                place
                challenger1_id
                challenger1_name
                challenger1_country
                challenger1_score
                challenger1_race_to
                challenger1_seed
                challenger1_is_winner
                challenger1_is_forfeit
                challenger2_id
                challenger2_name
                challenger2_country
                challenger2_score
                challenger2_race_to
                challenger2_seed
                challenger2_is_winner
                challenger2_is_forfeit
                tournament_player_id
                match_number
                match_id
                team_id
                scheduled_time
                start_time
                end_time
                duration
                table_id
                table_name
                seed
                status
                progress
            }
            challenger1_matches_won: tournament_matches_challenger1_aggregate(
                where: { challenger1_is_winner: { _eq: true }, status: { _eq: COMPLETED }, is_bye: { _eq: false } }
                order_by: { match_number: asc }
            ) {
                aggregate {
                    count
                }
            }
            challenger1_matches_lost: tournament_matches_challenger1_aggregate(
                where: { challenger1_is_winner: { _eq: false }, status: { _eq: COMPLETED }, is_bye: { _eq: false } }
                order_by: { match_number: asc }
            ) {
                aggregate {
                    count
                }
            }
            challenger2_matches_won: tournament_matches_challenger2_aggregate(
                where: { challenger2_is_winner: { _eq: true }, status: { _eq: COMPLETED }, is_bye: { _eq: false } }
                order_by: { match_number: asc }
            ) {
                aggregate {
                    count
                }
            }
            challenger2_matches_lost: tournament_matches_challenger2_aggregate(
                where: { challenger2_is_winner: { _eq: false }, status: { _eq: COMPLETED }, is_bye: { _eq: false } }
                order_by: { match_number: asc }
            ) {
                aggregate {
                    count
                }
            }
        }
    }
`;

export const FIND_TOURNAMENT_PLAYER_IN_BRACKET = gql`
    query tournament_players($id: Int, $tournament_id: Int) {
        tournament_players(where: { id: { _eq: $id }, _and: { tournament_id: { _eq: $tournament_id } } }) {
            tournament_matches_challenger1(limit: 1, order_by: { match_number: asc }) {
                id
                hash_id
                round
                identifier
                place
                challenger1_id
                challenger1_name
                challenger1_country
                challenger1_score
                challenger1_race_to
                challenger1_seed
                challenger1_is_winner
                challenger1_is_forfeit
                challenger2_id
                challenger2_name
                challenger2_country
                challenger2_score
                challenger2_race_to
                challenger2_seed
                challenger2_is_winner
                challenger2_is_forfeit
                tournament_player_id
                match_number
                match_id
                team_id
                scheduled_time
                start_time
                end_time
                duration
                table_id
                table_name
                seed
                status
                progress
            }
            tournament_matches_challenger2(limit: 1, order_by: { match_number: asc }) {
                id
                hash_id
                round
                identifier
                place
                challenger1_id
                challenger1_name
                challenger1_country
                challenger1_score
                challenger1_race_to
                challenger1_seed
                challenger1_is_winner
                challenger1_is_forfeit
                challenger2_id
                challenger2_name
                challenger2_country
                challenger2_score
                challenger2_race_to
                challenger2_seed
                challenger2_is_winner
                challenger2_is_forfeit
                tournament_player_id
                match_number
                match_id
                team_id
                scheduled_time
                start_time
                end_time
                duration
                table_id
                table_name
                seed
                status
                progress
            }
        }
    }
`;

export const FIND_TOURNAMENT_PLAYER_BY_ID = gql`
    query tournament_players($user_id: Int, $tournament_id: Int) {
        tournament_players(where: { user_id: { _eq: $user_id }, _and: { tournament_id: { _eq: $tournament_id } } }) {
            id
            tournament_id
            name
            nickname
            country
            email
            country_phone
            phone_number
            skill_level
            status
            points
            seed
            race_to
            special_group
            fargo_id
            user_id
            user {
                id
                first_name
                last_name
                display_name
                avatar
                country
                email
                country_phone
                phone_number
                skill_level
                fargo_id
            }
        }
    }
`;

// export const FIND_TOURNAMENT_PLAYER_BY_EMAIL = gql`
// 	query tournament_players($email: String!, $tournament_id: Int!) {
// 		tournament_players(where: { email: { _eq: $email }, _and: { tournament_id: { _eq: $tournament_id } } }) {
// 			id
// 			name
// 			country
// 			email
// 			phone_number
// 			skill_level
// 		}
// 	}
// `;

// export const FIND_TOURNAMENT_PLAYER_BY_ID = gql`
// 	query tournament_players($user_id: Int, $tournament_id: Int) {
// 		tournament_players(
// 			where: { user_id: { _eq: $user_id }, _and: { tournament: { id: { _eq: $tournament_id } } } }
// 		) {
// 			id
// 			tournament_id
// 			user {
// 				id
// 				first_name
// 				last_name
// 				country
// 				email
// 				phone_number
// 				skill_level
// 			}
// 		}
// 	}
// `;

export const FIND_TOURNAMENT_PLAYER_BY_EMAIL = gql`
    query tournament_players($email: String!, $tournament_id: Int!) {
        tournament_players(where: { email: { _eq: $email }, _and: { tournament: { id: { _eq: $tournament_id } } } }) {
            id
            name
            nickname
            country
            email
            country_phone
            phone_number
            skill_level
            fargo_id
            status
            points
            seed
            race_to
        }
    }
`;

export const FILTER_TOURNAMENT_PLAYERS = gql`
    query tournament_players($name: String!, $tournament_id: Int!) {
        tournament_players(where: { name: { _ilike: $name }, _and: { tournament_id: { _eq: $tournament_id } } }, order_by: { name: asc }) {
            id
            name
            nickname
            email
            country_phone
            phone_number
        }
    }
`;

export const SEARCH_TOURNAMENT_PLAYERS = gql`
    query tournament_players($name: String!, $nickname: String!, $tournament_id: Int) {
        tournament_players(
            distinct_on: name
            where: { tournament_id: { _eq: $tournament_id }, _or: [{ name: { _ilike: $name } }, { nickname: { _ilike: $nickname } }] }
        ) {
            id
            user_id
            name
            nickname
            email
            country
            nickname
            country_phone
            phone_number
            skill_level
            fargo_id
            seed
            status
        }
    }
`;

export const SEARCH_VENUES_QUERY = gql`
    query venues($name: String!) {
        venues(
            distinct_on: name
            where: {
                is_public: { _eq: true }
                _or: [
                    { name: { _ilike: $name } }
                    { address1: { _ilike: $name } }
                    { city: { _ilike: $name } }
                    { region: { _ilike: $name } }
                    { postal_code: { _ilike: $name } }
                ]
            }
        ) {
            id
            name
            slug
            address1
            city
            region
            country
            postal_code
            venue_type
            lat
            lng
            pool_tables_aggregate {
                aggregate {
                    count
                }
            }
            pool_tables(order_by: { label: asc }) {
                id
                slug
                label
                type
                size
                year_built
                make
                model
            }
            owner {
                id
            }
        }
    }
`;

// distinct_on: [first_name, last_name]
export const SEARCH_USERS = gql`
    query users($first_name: String, $last_name: String, $nickname: String) {
        users(
            where: {
                _or: [
                    { first_name: { _ilike: $first_name } }
                    { last_name: { _ilike: $last_name } }
                    { display_name: { _ilike: $first_name } }
                    { nickname: { _ilike: $nickname } }
                ]
            }
        ) {
            id
            first_name
            last_name
            email
            avatar
            city
            region
            country
            display_name
            nickname
            skill_level
            country_phone
            fargo_id
            phone_number
            status
        }
    }
`;

export const SEARCH_USERS_BY_EMAIL = gql`
    query users($email: String!) {
        users(where: { email: { _eq: $email } }) {
            id
            first_name
            last_name
            email
            country
            display_name
            nickname
            skill_level
            country_phone
            phone_number
            status
        }
    }
`;

export const SEARCH_PLAYERS = gql`
    query players($name: String!) {
        players(distinct_on: [name], where: { name: { _ilike: $name } }) {
            id
            name
            name_slug
            aliases
            country
            country_phone
            phone_number
            gender
            properties
            image_large
            image_small
            user_id
            user {
                id
                first_name
                last_name
                email
                country
                city
                region
                display_name
                nickname
                skill_level
                country_phone
                phone_number
                fargo_id
                fargo_rating
                status
            }
        }
    }
`;

export const GET_TOURNAMENT_PLAYER_QUERY = gql`
    query tournament_players($id: Int, $tournament_id: Int!) {
        tournament_players(where: { tournament_id: { _eq: $tournament_id }, id: { _eq: $id } }) {
            id
            name
            nickname
            country
            email
            country_phone
            phone_number
            skill_level
            status
            points
            place
            seed
            race_to
            special_group
            chips_left
            lives_left
            is_eliminated
            last_played_at
            group
            player_id
            fargo_id
            user_id
            user {
                id
                first_name
                last_name
                nickname
                display_name
                avatar
                country
                email
                country_phone
                phone_number
                skill_level
                fargo_id
            }
            tournament_matches_challenger1(where: { is_bye: { _eq: false } }) {
                id
                hash_id
                round
                identifier
                place
                challenger1_id
                challenger1_name
                challenger1_country
                challenger1_score
                challenger1_race_to
                challenger1_seed
                challenger1_is_winner
                challenger1_is_forfeit
                challenger2_id
                challenger2_name
                challenger2_country
                challenger2_score
                challenger2_race_to
                challenger2_seed
                challenger2_is_winner
                challenger2_is_forfeit
                tournament_player_id
                match_number
                match_id
                team_id
                scheduled_time
                start_time
                end_time
                duration
                table_id
                table_name
                seed
                status
                progress
            }
            tournament_matches_challenger2(where: { is_bye: { _eq: false } }) {
                id
                hash_id
                round
                identifier
                place
                challenger1_id
                challenger1_name
                challenger1_country
                challenger1_score
                challenger1_race_to
                challenger1_seed
                challenger1_is_winner
                challenger1_is_forfeit
                challenger2_id
                challenger2_name
                challenger2_country
                challenger2_score
                challenger2_race_to
                challenger2_seed
                challenger2_is_winner
                challenger2_is_forfeit
                tournament_player_id
                match_number
                match_id
                team_id
                scheduled_time
                start_time
                end_time
                duration
                table_id
                table_name
                seed
                status
                progress
            }
        }
    }
`;

export const GET_TOURNAMENT_PLAYER_BY_NAME_QUERY = gql`
    query tournament_players($name: String, $tournament_id: Int!) {
        tournament_players(where: { tournament_id: { _eq: $tournament_id }, name: { _eq: $name } }) {
            id
            name
            nickname
            country
            email
            country_phone
            phone_number
            skill_level
            status
            points
            seed
            race_to
            special_group
            fargo_id
            user_id
            user {
                id
                first_name
                last_name
                display_name
                avatar
                country
                email
                country_phone
                phone_number
                skill_level
                fargo_id
            }
        }
    }
`;

export const GET_TOURNAMENT_PLAYER_STATS_QUERY = gql`
    query tournament_player_stats($tournament_id: Int!) {
        tournament_players(where: { tournament_id: { _eq: $tournament_id } }, order_by: { name: asc }) {
            id
            name
            nickname
            place
            country
            player_points {
                points
            }
            challenger1_matches_won: tournament_matches_challenger1_aggregate(
                where: { challenger1_is_winner: { _eq: true }, status: { _eq: COMPLETED }, is_bye: { _eq: false } }
                order_by: { match_number: asc }
            ) {
                aggregate {
                    count
                }
            }
            challenger1_matches_lost: tournament_matches_challenger1_aggregate(
                where: { challenger1_is_winner: { _eq: false }, status: { _eq: COMPLETED }, is_bye: { _eq: false } }
                order_by: { match_number: asc }
            ) {
                aggregate {
                    count
                }
            }
            challenger2_matches_won: tournament_matches_challenger2_aggregate(
                where: { challenger2_is_winner: { _eq: true }, status: { _eq: COMPLETED }, is_bye: { _eq: false } }
                order_by: { match_number: asc }
            ) {
                aggregate {
                    count
                }
            }
            challenger2_matches_lost: tournament_matches_challenger2_aggregate(
                where: { challenger2_is_winner: { _eq: false }, status: { _eq: COMPLETED }, is_bye: { _eq: false } }
                order_by: { match_number: asc }
            ) {
                aggregate {
                    count
                }
            }
            challenger1_matches_games: tournament_matches_challenger1_aggregate(
                where: { status: { _eq: COMPLETED }, is_bye: { _eq: false } }
                order_by: { match_number: asc }
            ) {
                aggregate {
                    count
                    sum {
                        challenger1_score
                        challenger2_score
                    }
                }
                nodes {
                    start_time
                    end_time
                }
            }
            challenger2_matches_games: tournament_matches_challenger2_aggregate(
                where: { status: { _eq: COMPLETED }, is_bye: { _eq: false } }
                order_by: { match_number: asc }
            ) {
                aggregate {
                    count
                    sum {
                        challenger1_score
                        challenger2_score
                    }
                }
                nodes {
                    start_time
                    end_time
                }
            }
        }
    }
`;

export const GET_TOURNAMENT_PLAYER_STATS_BY_ID_QUERY = gql`
    query tournament_player_stats($player_id: Int!, $tournament_id: Int!) {
        matches_won: tournament_brackets_aggregate(
            where: {
                tournament_id: { _eq: $tournament_id }
                _or: [
                    { challenger1_id: { _eq: $player_id }, challenger1_is_winner: { _eq: true } }
                    { challenger2_id: { _eq: $player_id }, challenger2_is_winner: { _eq: true } }
                ]
                _and: { status: { _eq: COMPLETED } }
            }
            order_by: { match_number: asc }
        ) {
            aggregate {
                count
            }
            nodes {
                identifier
                round
                match_number
                challenger1_id
                challenger1_name
                challenger1_score
                challenger1_is_winner
                challenger2_id
                challenger2_name
                challenger2_score
                challenger2_is_winner
            }
        }
        matches_lost: tournament_brackets_aggregate(
            where: {
                tournament_id: { _eq: $tournament_id }
                _or: [
                    { challenger1_id: { _eq: $player_id }, challenger1_is_winner: { _eq: false } }
                    { challenger2_id: { _eq: $player_id }, challenger2_is_winner: { _eq: false } }
                ]
                _and: { status: { _eq: COMPLETED } }
            }
            order_by: { match_number: asc }
        ) {
            aggregate {
                count
            }
            nodes {
                identifier
                round
                match_number
                challenger1_id
                challenger1_name
                challenger1_score
                challenger1_is_winner
                challenger2_id
                challenger2_name
                challenger2_score
                challenger2_is_winner
            }
        }
    }
`;

export const GET_PLAYER_STATUS = gql`
    query player_status {
        player_status(order_by: { value: asc }) {
            value
        }
    }
`;

export const GET_TOURNAMENT_PLAYER_STATUS = gql`
    query tournament_player_status {
        tournament_player_status(order_by: { value: asc }) {
            value
        }
    }
`;

export const GET_TOURNAMENT_CHIP_AMOUNTS = gql`
    query tournament_chips($tournament_id: Int!) {
        tournament_chips(where: { tournament_id: { _eq: $tournament_id } }) {
            id
            skill_level
            amount
        }
    }
`;

export const GET_PAYOUTS = gql`
    query tournament_payouts($tournament_id: Int!) {
        tournament_payouts(where: { tournament_id: { _eq: $tournament_id } }) {
            id
            place
            money
            calcutta
            percent
            player {
                id
                name
            }
        }
    }
`;

export const GET_PAYOUTS_ENTRY = gql`
    query tournament_payouts($id: Int!) {
        tournament_payouts(where: { id: { _eq: $id } }) {
            id
            money
            calcutta
            place
            percent
            tournament_player
            player {
                name
            }
        }
    }
`;

export const GET_TOURNAMENT_STATUS = gql`
    query tournament_status($id: Int!) {
        tournaments(where: { id: { _eq: $id } }) {
            id
            status
        }
    }
`;

export const GET_TOURNAMENT_POINTS = gql`
    query tournament_points($tournament_id: Int!) {
        tournament_points(where: { tournament_id: { _eq: $tournament_id } }) {
            id
            place
            points
            tournament_id
        }
    }
`;

export const GET_TOURNAMENT_POINTS_BY_ID = gql`
    query tournament_points($id: Int!) {
        tournament_points(where: { id: { _eq: $id } }) {
            id
            place
            points
            tournament_id
        }
    }
`;

export const GET_TOURNAMENT_PAYOUTS = gql`
    query tournament_points($tournament_id: Int!) {
        tournament_payouts(where: { tournament_id: { _eq: $tournament_id } }) {
            id
            place
            money
            calcutta
            percent
            player {
                id
                name
            }
        }
    }
`;

export const GET_TOURNAMENT_PLAYER_PODIUM = gql`
    query tournament_players($tournament_id: Int!) {
        tournament_players(where: { place: { _lte: "3" }, tournament_id: { _eq: $tournament_id } }, distinct_on: id) {
            id
            name
            place
            payouts {
                money
            }
        }
    }
`;

export const GET_TOURNAMENT_PLAYER_STANDINGS = gql`
    query tournament_player_stats($tournament_id: Int!) {
        tournament_players(where: { tournament_id: { _eq: $tournament_id }, place: { _neq: "null" } }, distinct_on: id) {
            id
            name
            nickname
            place
            country
            skill_level
            payouts {
                money
            }
            player_points {
                points
            }
            challenger1_matches_won: tournament_matches_challenger1_aggregate(
                where: { challenger1_is_winner: { _eq: true }, status: { _eq: COMPLETED }, is_bye: { _eq: false } }
                order_by: { match_number: asc }
            ) {
                aggregate {
                    count
                }
            }
            challenger1_matches_lost: tournament_matches_challenger1_aggregate(
                where: { challenger1_is_winner: { _eq: false }, status: { _eq: COMPLETED }, is_bye: { _eq: false } }
                order_by: { match_number: asc }
            ) {
                aggregate {
                    count
                }
            }
            challenger2_matches_won: tournament_matches_challenger2_aggregate(
                where: { challenger2_is_winner: { _eq: true }, status: { _eq: COMPLETED }, is_bye: { _eq: false } }
                order_by: { match_number: asc }
            ) {
                aggregate {
                    count
                }
            }
            challenger2_matches_lost: tournament_matches_challenger2_aggregate(
                where: { challenger2_is_winner: { _eq: false }, status: { _eq: COMPLETED }, is_bye: { _eq: false } }
                order_by: { match_number: asc }
            ) {
                aggregate {
                    count
                }
            }
            challenger1_matches_games: tournament_matches_challenger1_aggregate(
                where: { status: { _eq: COMPLETED }, is_bye: { _eq: false } }
                order_by: { match_number: asc }
            ) {
                aggregate {
                    count
                    sum {
                        challenger1_score
                        challenger2_score
                    }
                }
                nodes {
                    start_time
                    end_time
                }
            }
            challenger2_matches_games: tournament_matches_challenger2_aggregate(
                where: { status: { _eq: COMPLETED }, is_bye: { _eq: false } }
                order_by: { match_number: asc }
            ) {
                aggregate {
                    count
                    sum {
                        challenger1_score
                        challenger2_score
                    }
                }
                nodes {
                    start_time
                    end_time
                }
            }
        }
    }
`;

export const SEARCH_POOL_VENUES = gql`
    query venues($name: String!) {
        venues(where: { name: { _ilike: $name } }) {
            id
            name
            pool_tables_aggregate {
                aggregate {
                    count
                }
            }
        }
    }
`;

export const GET_POOL_TABLES = gql`
    query pool_tables($venue_id: Int!) {
        pool_tables(where: { venue_id: { _eq: $venue_id } }) {
            id
            slug
            is_featured_table
            is_streaming_table
            label
            league_id
            livestream_url
            make
            manufacturer_id
            match_id
            model
            size
            status
            tournament_id
            type
            updated_at
            user_id
            venue_id
            year_built
            created_at
        }
    }
`;

export const GET_TOURNAMENT_TIMING_STATS = gql`
    query timings($tournament_id: Int!) {
        vw_average_match_duration(where: { tournament_id: { _eq: $tournament_id } }) {
            average_skill_level
            average_match_duration
            average_game_duration
        }
        vw_time_between_matches_by_table(where: { tournament_id: { _eq: $tournament_id } }) {
            table_name
            time_between_matches
        }
    }
`;

export const GET_TOURNAMENT_MANAGERS = gql`
    query tournament_managers($tournament_id: Int!) {
        tournament_managers(where: { tournament_id: { _eq: $tournament_id } }) {
            id
            manager_id
            email
            tournament_slug
            invite_accepted
            manager {
                id
                first_name
                last_name
                display_name
            }
        }
    }
`;

export const GET_TOURNAMENT_MANAGERS_INVITE_KEY = gql`
    query tournament_managers_invite_keys($tournament_id: Int!, $email: String!, $key: String!) {
        tournament_managers_invite_keys(where: { tournament_id: { _eq: $tournament_id }, _and: { email: { _eq: $email }, _and: { key: { _eq: $key } } } }) {
            id
            tournament_id
        }
    }
`;

export const GET_TOURNAMENT_MANAGERS_INVITE_KEY_BY_SLUG = gql`
    query tournament_managers_invite_keys($tournament_slug: String!, $email: String!, $key: String!) {
        tournament_managers_invite_keys(where: { tournament_slug: { _eq: $tournament_slug }, _and: { email: { _eq: $email }, _and: { key: { _eq: $key } } } }) {
            id
            tournament_id
        }
    }
`;

export const CHECK_TOURNAMENT_MANAGERS_INVITE_USER = gql`
    query tournament_managers_invite_keys($tournament_id: Int!, $email: String!) {
        tournament_managers_invite_keys(where: { tournament_id: { _eq: $tournament_id }, _and: { email: { _eq: $email } } }) {
            id
        }
    }
`;

export const GET_USER_BY_EMAIL_QUERY = gql`
    query users($email: String!) {
        users(where: { email: { _eq: $email } }) {
            id
            email
            first_name
            display_name
        }
    }
`;

export const GET_TOURNAMENT_MANAGERS_USER_BY_MANAGER_ID = gql`
    query tournament_managers($tournament_id: Int!, $manager_id: Int!) {
        tournament_managers(where: { tournament_id: { _eq: $tournament_id }, _and: { manager_id: { _eq: $manager_id } } }) {
            id
        }
    }
`;

export const GET_TOURNAMENT_MANAGERS_INVITED_USER_BY_MANAGER_EMAIL = gql`
    query tournament_managers($tournament_id: Int!, $email: String!) {
        tournament_managers(where: { tournament_id: { _eq: $tournament_id }, _and: { email: { _eq: $email }, _and: { invite_accepted: { _eq: false } } } }) {
            id
        }
    }
`;

export const GET_TOURNAMENT_NOTIFICATIONS_QUERY = gql`
    query tournament_notifications($tournament_id: Int) {
        tournament_notifications(where: { tournament_id: { _eq: $tournament_id } }) {
            id
            tournament_id
            tournament_bracket_id
            round_number
            match_number
            player_name
            player_id
            player_phone_number
            type
            message_delivered
            message
            message_error
            message_error_code
            message_sent_at
            message_delivered_at
            message_undeliverable
            message_sent
            message_id
            message_status
            delivery_receipt
            created_at
            updated_at
        }
    }
`;

export const GET_TOURNAMENT_BRACKET_TEMPLATES = gql`
    query tournament_bracket_templates($creator_id: Int!) {
        tournament_bracket_templates(where: { _or: [{ creator_id: { _eq: $creator_id } }, { is_standard: { _eq: true } }] }, order_by: { id: asc }) {
            id
            name
            slug
            type
            creator_id
            created_at
            updated_at
        }
    }
`;

export const GET_TOURNAMENT_BRACKET_TEMPLATE_MATCH = gql`
    query tournament_bracket_template_matches($id: Int!) {
        tournament_bracket_template_matches(where: { id: { _eq: $id } }, order_by: { match_number: asc }) {
            id
            round
            round_id
            identifier
            match_number
            place
            top_seed
            bottom_seed
            winner_to
            winner_to_num
            winner_from
            winner_from_num
            winner_from_top_num
            winner_from_bottom_num
            loser_to
            loser_to_num
            loser_from
            loser_from_num
            loser_from_top_num
            loser_from_bottom_num
            advance_to
            stage_id
            stage_number
            is_finals
            is_semi_finals
            is_consolidation_finals
            created_at
            updated_at
        }
    }
`;

export const GET_TOURNAMENT_BRACKET_TEMPLATE = gql`
    query tournament_bracket_templates($id: Int!) {
        tournament_bracket_templates(where: { id: { _eq: $id } }) {
            id
            name
            slug
            type
            creator_id
            segments {
                id
                name
                slug
                rounds(order_by: { round: asc }) {
                    id
                    round
                    segment_id
                    identifier
                    matches(order_by: { match_number: asc }) {
                        id
                        round
                        round_id
                        identifier
                        match_number
                        place
                        top_seed
                        bottom_seed
                        winner_to
                        winner_to_num
                        winner_from
                        winner_from_num
                        winner_from_top_num
                        winner_from_bottom_num
                        loser_to
                        loser_to_num
                        loser_from
                        loser_from_num
                        loser_from_top_num
                        loser_from_bottom_num
                        advance_to
                        stage_id
                        stage_number
                        is_finals
                        is_semi_finals
                        is_consolidation_finals
                    }
                }
            }
        }
    }
`;

export const GET_TOURNAMENT_BRACKET_TEMPLATE_BY_SLUG = gql`
    query tournament_bracket_templates($slug: String) {
        tournament_bracket_templates(where: { slug: { _eq: $slug } }) {
            id
            name
            slug
            type
            creator_id
            segments {
                id
                name
                slug
                rounds(order_by: { round: asc }) {
                    id
                    round
                    segment_id
                    identifier
                    matches(order_by: { match_number: asc }) {
                        id
                        round
                        round_id
                        identifier
                        match_number
                        place
                        top_seed
                        bottom_seed
                        winner_to
                        winner_to_num
                        winner_from
                        winner_from_num
                        winner_from_top_num
                        winner_from_bottom_num
                        loser_to
                        loser_to_num
                        loser_from
                        loser_from_num
                        loser_from_top_num
                        loser_from_bottom_num
                        advance_to
                        stage_id
                        stage_number
                        is_finals
                        is_semi_finals
                        is_consolidation_finals
                    }
                }
            }
        }
    }
`;

export const GET_ALL_TOURNAMENT_TABLES_OPTIMIZED_QUERY = gql`
    query pool_tables($tournament_id: Int) {
        pool_tables(where: { tournament_id: { _eq: $tournament_id } }, order_by: { updated_at: desc }) {
            id
            slug
            label
            status
            livestream_url
            is_streaming_table
            is_featured_table
        }
    }
`;

export const GET_TOURNAMENT_BRACKET_MATCH_QUERY = gql`
    query tournament_brackets($match_id: Int, $tournament_id: Int!) {
        tournament_brackets(where: { id: { _eq: $match_id }, tournament_id: { _eq: $tournament_id } }, order_by: { id: asc }, limit: 1) {
            id
            hash_id
            round
            identifier
            challenger1 {
                id
                name
                country
                skill_level
                race_to
                place
                seed
                user_id
            }
            challenger2 {
                id
                name
                country
                skill_level
                race_to
                place
                seed
                user_id
            }
            challenger1_id
            challenger1_name
            challenger1_country
            challenger1_score
            challenger1_points
            challenger1_race_to
            challenger1_seed
            challenger1_is_winner
            challenger1_is_forfeit
            challenger1_is_withdraw
            challenger2_id
            challenger2_name
            challenger2_country
            challenger2_score
            challenger2_points
            challenger2_race_to
            challenger2_seed
            challenger2_is_winner
            challenger2_is_forfeit
            challenger2_is_withdraw
            challenger1_sms_sent
            challenger2_sms_sent
            tournament_player_id
            match_number
            match_id
            match_color
            team_id
            scheduled_table_id
            priority
            scheduled_time
            start_time
            end_time
            duration
            table_id
            table_name
            seed
            status
            progress
            loser_from
            loser_from_num
            loser_to
            loser_to_num
            winner_to
            winner_to_num
            winner_from
            winner_from_top_num
            winner_from_bottom_num
            loser_from_top_num
            loser_from_bottom_num
            advance_to
            stage_id
            stage_number
            is_bye
            is_split
            is_semi_finals
            is_finals
            is_consolidation_finals
            livestream_url
            livestream_thumbnail
            pool_table {
                id
                slug
                label
                is_streaming_table
                is_featured_table
                livestream_url
                status
            }
        }
    }
`;

export const GET_TOURNAMENT_PLAYER_MATCHES_QUERY = gql`
    query vw_player_tournament_matches($tournament_id: Int!, $player_name: String) {
        vw_player_tournament_matches(where: { tournament_id: { _eq: $tournament_id }, player_name: { _eq: $player_name } }) {
            match_duration
            match_number
            match_rating
            opponent_name
            opponents_score
            player_name
            players_score
            tournament_id
            identifier
            opponent_skill_level
            opponent_won
            player_skill_level
            player_won
        }
    }
`;

export const GET_TOURNAMENT_PLAYER_STATS_QUERY2 = gql`
    query vw_tournament_statistics($tournament_id: Int!, $player_id: Int!) {
        vw_tournament_statistics(where: { tournament_id: { _eq: $tournament_id }, player_id: { _eq: $player_id } }) {
            game_record
            game_win_percentage
            match_record
            match_win_percentage
            place
            player_name
            player_country
            points
            tournament_id
        }
    }
`;

export const GET_TOURNAMENT_MATCH_GAMES_QUERY = gql`
    query tournament_match_games($tournament_id: Int!, $tournament_bracket_id: Int!) {
        tournament_match_games(where: { tournament_id: { _eq: $tournament_id }, tournament_bracket_id: { _eq: $tournament_bracket_id } }) {
            id
            tournament_id
            tournament_bracket_id
            match_number
            game_number
            player_id
            player_score
            player_points
            player_is_winner
            player_break_run
            player_runout
            player_tpa
            player_unforced_errors
            player_balls_pocketed
            opponent_id
            opponent_score
            opponent_points
            opponent_is_winner
            opponent_break_run
            opponent_runout
            opponent_tpa
            opponent_unforced_errors
            opponent_balls_pocketed
            status
            progress
            duration
            table_id
            start_time
            end_time
            created_at
            updated_at
        }
    }
`;

export const GET_TOURNAMENT_BRACKET_STAGES_QUERY = gql`
    query tournament_brackets($tournament_id: Int!) {
        tournament_brackets(where: { tournament_id: { _eq: $tournament_id } }, order_by: { id: asc }) {
            id
            hash_id
            round
            group
            identifier
            place
            challenger1 {
                id
                name
                country
                email
                country_phone
                phone_number
                skill_level
                status
                points
                seed
                race_to
                user {
                    id
                    first_name
                    last_name
                    country
                    email
                    country_phone
                    phone_number
                    skill_level
                }
            }
            challenger2 {
                id
                name
                country
                email
                country_phone
                phone_number
                skill_level
                status
                points
                seed
                race_to
                user {
                    id
                    first_name
                    last_name
                    country
                    email
                    country_phone
                    phone_number
                    skill_level
                }
            }
            challenger1_id
            challenger1_name
            challenger1_country
            challenger1_score
            challenger1_race_to
            challenger1_seed
            challenger1_is_winner
            challenger1_is_forfeit
            challenger2_id
            challenger2_name
            challenger2_country
            challenger2_score
            challenger2_race_to
            challenger2_seed
            challenger2_is_winner
            challenger2_is_forfeit
            tournament_player_id
            match_number
            match_id
            team_id
            scheduled_time
            start_time
            end_time
            duration
            table_id
            table_name
            scheduled_table_id
            priority
            scheduled_table {
                slug
                label
                type
                size
                year_built
                make
                model
                created_at
                updated_at
                status
            }
            seed
            status
            progress
            loser_from
            loser_from_num
            loser_to
            loser_to_num
            winner_from
            winner_to
            winner_to_num
            winner_from_top_num
            winner_from_bottom_num
            loser_from_top_num
            loser_from_bottom_num
            advance_to
            stage_id
            stage_number
            is_bye
            is_semi_finals
            is_finals
            is_consolidation_finals
            tournament {
                start_date_time
            }
            pool_table {
                id
                slug
                label
                type
                size
                year_built
                make
                model
                created_at
                updated_at
                status
                is_streaming_table
                is_featured_table
                livestream_url
            }
        }
    }
`;
