import React, { useState } from 'react';
import { message, Icon, Row, Col, Typography, Button, Input, Tabs, Empty } from 'antd';
import Card from '../../components/Card';
import { Link, withRouter } from 'react-router-dom';
import CircularLoader from '../../components/CircularLoader';
import SectionTitle from '../../components/SectionTitle';
import SectionHeader from '../../components/SectionHeader';
import SectionContent from '../../components/SectionContent';
import SectionWrapper from '../../components/SectionWrapper';
import SectionFooter from '../../components/SectionFooter';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { GET_RECENT_VENUES_QUERY } from './data/queries';
import { DELETE_VENUE_MUTATION } from './data/mutations';
import { Query } from '@apollo/client/react/components';
import VenueTable from './VenueTable';
import _ from 'lodash';
import ZeroState from '../../components/ZeroState';
import TournamentProgressBar from './TournamentProgressBar';
import TBManageButtons from './TBManageButtons';
import AddVenueModal from './AddVenueModal';

const { Text, Title } = Typography;

const styles = {
    row: {
        display: 'flex',
        flexGrow: 1,
    },
    tab: {
        paddingTop: 5,
        paddingLeft: 24,
        paddingRight: 24,
        paddingBottom: 24,
    },
};

function TBVenues(props) {
    const { tournament, history } = props;
    const [selectedKeys, setSelectedKeys] = useState([]);
    const [selectedVenueId, setSelectedVenueId] = useState();
    const [venues, setVenues] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);
    const [deleteVenue, { loading, error }] = useMutation(DELETE_VENUE_MUTATION);

    function handleSelected(selected) {
        console.log(selected);
        setSelectedKeys(selected);
    }

    function handleEditSelected(selected) {
        // console.log(selected);
        setSelectedVenueId(selected);
        setModalVisible(true);
    }

    const hasSelected = selectedKeys && selectedKeys.length > 0;

    function handleModalOk(e) {
        console.log(e);
        setModalVisible(false);
    }

    function handleModalCancel(e) {
        setModalVisible(false);
    }

    function handleAddVenue() {
        setModalVisible(true);
        setSelectedVenueId(null);
    }

    return (
        <SectionWrapper>
            <SectionHeader
                title={props.title}
                titleStyle={{ color: '#fff', margin: 0, textTransform: 'uppercase' }}
                subtitle={tournament.name}
                subtitlePrefix={true}
                subtitleStyle={{
                    color: 'rgba(255,255,255,0.8)',
                    textTransform: 'uppercase',
                    fontSize: 12,
                    letterSpacing: 3,
                }}
                backButton={
                    <Button
                        onClick={() => history.push(`/tournament-builder/${tournament.slug}`)}
                        type="ghost"
                        shape="circle"
                        icon="arrow-left"
                        size="large"
                        ghost
                        style={{ border: 'none' }}
                    />
                }
                theme="tournamentBuilder"
            >
                <TBManageButtons page="players" {...props} />
            </SectionHeader>
            <TournamentProgressBar {...props} />
            <SectionContent padding="20px 40px">
                <Row gutter={24} justify="center" type="flex">
                    <Col xs={24}>
                        <Card
                            // title="Venues"
                            // bodyStyle={{ padding: 0 }}
                            // style={{
                            // 	borderRadius: 8,
                            // 	overflow: 'hidden',
                            // 	border: 'none',
                            // 	boxShadow: '0 2px 5px 0px rgba(0, 0, 0, 0.1)'
                            // }}
                            cover={
                                <div
                                    style={{
                                        paddingTop: 16,
                                        paddingBottom: 16,
                                        paddingLeft: 24,
                                        paddingRight: 24,
                                        // background:'linear-gradient(rgb(239, 243, 245), rgb(255, 255, 255))',
                                        borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <Title level={4} style={{ margin: 0, padding: 0, fontSize: 18 }}>
                                        Venues
                                    </Title>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                        }}
                                    >
                                        <Button type="primary" onClick={handleAddVenue}>
                                            + Add Venue
                                        </Button>
                                        <div style={{ marginLeft: 10 }}>
                                            <Button type="danger" disabled={!hasSelected}>
                                                Remove
                                            </Button>
                                            <span style={{ marginLeft: 8 }}>{hasSelected ? `Selected ${selectedKeys.length} items` : ''}</span>
                                        </div>
                                    </div>
                                </div>
                            }
                            bodyStyle={{ padding: 0 }}
                        >
                            <Query
                                query={GET_RECENT_VENUES_QUERY}
                                fetchPolicy="cache-and-network"
                                notifyOnNetworkStatusChange={true}
                                onCompleted={async (data) => {
                                    setVenues(data.venues);
                                    // console.log(data);
                                }}
                            >
                                {({ loading, error, data }) => {
                                    if (loading) return <CircularLoader />;
                                    if (error) return <div style={styles.container}>Error: {error.message}</div>;
                                    if ((data && !data.venues) || (data && data.venues.length === 0)) {
                                        return (
                                            <ZeroState showImage message="No Venues found." style={{ margin: 30 }} {...props}>
                                                <Button onClick={handleAddVenue}>Create Venue</Button>
                                            </ZeroState>
                                        );
                                    }

                                    return (
                                        <VenueTable
                                            {...props}
                                            onDelete={(record) => {
                                                deleteVenue({
                                                    variables: {
                                                        venue_id: record.id,
                                                    },
                                                    notifyOnNetworkStatusChange: true,
                                                    awaitRefetchQueries: true,
                                                    refetchQueries: [
                                                        {
                                                            query: GET_RECENT_VENUES_QUERY,
                                                        },
                                                    ],
                                                })
                                                    .then((data) => {
                                                        console.log(data);
                                                        message.success('Venue deleted');
                                                    })
                                                    .catch((error) => {
                                                        console.log(error);
                                                        message.info('There was an error', error);
                                                    });
                                            }}
                                            onSelected={handleSelected}
                                            onEditSelected={handleEditSelected}
                                            tournaments={
                                                data.venues &&
                                                data.venues.map((item, index) => {
                                                    return {
                                                        ...item,
                                                        index: index + 1,
                                                        key: index,
                                                        venue_type: item.venue_type && _.startCase(_.toLower(item.venue_type.replace('_', ' '))),
                                                        owner_id: item.owner && item.owner.id,
                                                        pool_tables: item.pool_tables_aggregate.aggregate.count,
                                                    };
                                                })
                                            }
                                        />
                                    );
                                }}
                            </Query>
                        </Card>
                    </Col>
                </Row>
                <AddVenueModal
                    {...props}
                    selectedVenueId={selectedVenueId}
                    // venue={
                    // 	venues.filter((item, index) => {
                    // 		return item.id === selectedVenueId;
                    // 	})[0]
                    // }
                    modalVisible={modalVisible}
                    onModalOk={handleModalOk}
                    onModalCancel={handleModalCancel}
                />
            </SectionContent>
            <SectionFooter {...props} />
        </SectionWrapper>
    );
}

export default withRouter(TBVenues);
