import gql from 'graphql-tag';

export const GET_PLAYER_COUNT = gql`
    query players_aggregate {
        players_aggregate {
            aggregate {
                count
            }
        }
    }
`;

export const GET_PLAYERS_QUERY = gql`
    query players($limit: Int!, $offset: Int!) {
        players(order_by: { name: asc }, limit: $limit, offset: $offset) {
            id
            name
            name_slug
            aliases
            properties
            image_large
            image_small
            country
            gender
            pro_world_rankings(distinct_on: ranking_system_id) {
                id
                ranking
                rank_date
                ranking_system {
                    display_name
                }
            }
            player_matches_aggregate {
                aggregate {
                    count
                }
            }
            opponent_matches_aggregate {
                aggregate {
                    count
                }
            }
            tournament_players_aggregate {
                aggregate {
                    count
                }
            }
            user_id
            user {
                id
                first_name
                last_name
                display_name
                avatar
                photo_url
                country
                region
                city
                slug
            }
            created_date
            updated_date
        }
    }
`;

export const GET_PLAYER_BY_SLUG = gql`
    query players($slug: String!) {
        players(where: { name_slug: { _eq: $slug } }) {
            id
            name
            name_slug
            email
            aliases
            country
            country_phone
            city
            region
            phone_number
            gender
            properties
            image_large
            image_small
            fargo_id
            fargo_readable_id
            fargo_robustness
            fargo_effective_rating
            fargo_rating
            fargo_uuid
            user_id
            user {
                id
                first_name
                last_name
                slug
                photo_url
                avatar
            }
            tournament_players_aggregate {
                aggregate {
                    count
                }
            }

            league_players_aggregate {
                aggregate {
                    count
                }
            }
        }
    }
`;

export const GET_PLAYER_TOURNAMENTS_QUERY = gql`
    query tournament_players($player_id: Int, $limit: Int) {
        tournament_players(where: { player_id: { _eq: $player_id } }, order_by: { updated_at: asc }, limit: $limit) {
            id
            place
            tournament {
                name
                slug
                description
                game_type
                avatar
                logo
                organizer_id
                director_id
                created_at
                updated_at
            }
        }
    }
`;

export const GET_PLAYER_LEAGUES_QUERY = gql`
    query league_players($player_id: Int, $limit: Int) {
        league_players(where: { player_id: { _eq: $player_id } }, order_by: { updated_at: desc }, limit: $limit) {
            id
            league {
                name
                slug
                short_description
                avatar
                logo
                deleted_at
            }
        }
    }
`;

export const GET_PRO_WORLD_RANKINGS_QUERY = gql`
    query pro_world_rankings($code: String!) {
        players(where: { pro_world_rankings: { id: { _is_null: false }, ranking_system: { code: { _eq: $code } } } }, order_by: { name: asc }) {
            id
            name
            name_slug
            aliases
            properties
            image_large
            image_small
            country
            gender
            user_id
            user {
                id
                first_name
                last_name
                display_name
                slug
            }
            pro_world_rankings(where: { ranking_system: { code: { _eq: $code } } }, order_by: { ranking: asc, rank_date: desc }, limit: 2) {
                id
                player_name
                ranking
                rank_date
                created_at
                ranking_system_id
                ranking_system {
                    id
                    code
                    display_name
                }
            }
            created_date
            updated_date
        }
    }
`;

export const GET_PRO_WORLD_RANKINGS_QUERY2 = gql`
    query pro_world_rankings {
        pro_world_rankings(where: { ranking_system_id: { _eq: 6 } }, distinct_on: [player_name, rank_date], order_by: { player_name: asc, rank_date: desc }) {
            id
            player_name
            ranking
            rank_date
            created_at
            player_id
            player {
                id
                name
                name_slug
                country
            }
            ranking_system_id
            ranking_system {
                id
                code
                display_name
            }
        }
    }
`;

export const GET_RANKING_SYSTEMS_QUERY = gql`
    query ranking_systems {
        ranking_systems(order_by: { code: asc }) {
            id
            code
            display_name
            display_logo
            digital_pool_ranking_weight
        }
    }
`;

export const GET_CURRENT_PRO_RANKINGS_QUERY = gql`
    query vw_current_pro_rankings {
        vw_current_pro_rankings2(order_by: { name: asc }) {
            name
            country
            ranked_player_id
            player {
                id
                name
                name_slug
                country
            }
            rank_date_rank
            fargo_ranking
            matchroom_ranking
            fargo_mens_ranking
            fargo_us_ranking
            fargo_womens_ranking
            us_proseries_ranking
            wpa_mens_ranking
            wpa_womens_ranking
            weighted_fargo_ranking
            weighted_matchroom_ranking
            weighted_us_proseries_ranking
            weighted_wpa_mens_ranking
            weighted_wpa_womens_ranking
            weighted_fargo_womens_ranking
            weighted_fargo_mens_ranking
            digital_pool_ranking
            digital_pool_womens_ranking
            digital_pool_mens_ranking
        }
    }
`;

export const GET_CURRENT_PRO_RANKINGS_ALL_FIELDS_QUERY = gql`
    query vw_current_pro_rankings {
        vw_current_pro_rankings2(order_by: { name: asc }) {
            name
            country
            ranked_player_id
            player {
                id
                name
                name_slug
                country
            }
            pro_world_rankings(order_by: { ranking: asc, rank_date: desc }, limit: 2) {
                id
                player_name
                ranking
                rank_date
            }
            rank_date_rank
            fargo_ranking
            matchroom_ranking
            fargo_mens_ranking
            fargo_us_ranking
            fargo_womens_ranking
            us_proseries_ranking
            wpa_mens_ranking
            wpa_womens_ranking
            weighted_fargo_ranking
            weighted_matchroom_ranking
            weighted_us_proseries_ranking
            weighted_wpa_mens_ranking
            weighted_wpa_womens_ranking
            weighted_fargo_womens_ranking
            weighted_fargo_mens_ranking
            digital_pool_aggregate
            digital_pool_ranking
            digital_pool_womens_aggregate
            digital_pool_womens_ranking
            digital_pool_mens_aggregate
            digital_pool_mens_ranking
        }
    }
`;

export const SEARCH_PLAYERS_QUERY = gql`
    query players($name: String!) {
        players(distinct_on: name, where: { _or: [{ name: { _ilike: $name } }, { name_slug: { _ilike: $name } }] }) {
            id
            name
            name_slug
            aliases
            properties
            image_large
            image_small
            country
            gender
            pro_world_rankings(distinct_on: ranking_system_id) {
                id
                ranking
                rank_date
                ranking_system {
                    display_name
                }
            }
            player_matches_aggregate {
                aggregate {
                    count
                }
            }
            opponent_matches_aggregate {
                aggregate {
                    count
                }
            }
            tournament_players_aggregate {
                aggregate {
                    count
                }
            }
            user_id
            user {
                id
                first_name
                last_name
                display_name
                avatar
                photo_url
                country
                region
                city
                slug
            }
            created_date
            updated_date
        }
    }
`;

// export const GET_PLAYER_COUNT = gql`
//     query players_aggregate {
//         players_aggregate(where: { image_small: { _neq: "" }, _and: { image_small: { _neq: "<Unknown>" } } }) {
//             aggregate {
//                 count
//             }
//         }
//     }
// `;

// export const GET_PLAYERS_QUERY = gql`
//     query players($limit: Int!, $offset: Int!) {
//         players(where: { image_small: { _neq: "" }, _and: { image_small: { _neq: "<Unknown>" } } }, order_by: { name: asc }, limit: $limit, offset: $offset) {
//             id
//             name
//             name_slug
//             aliases
//             properties
//             image_large
//             image_small
//             country
//             gender
//             pro_world_rankings(distinct_on: ranking_system_id) {
//                 id
//                 ranking
//                 rank_date
//                 ranking_system {
//                     display_name
//                 }
//             }
//             player_matches_aggregate {
//                 aggregate {
//                     count
//                 }
//             }
//             opponent_matches_aggregate {
//                 aggregate {
//                     count
//                 }
//             }
//             tournament_players_aggregate {
//                 aggregate {
//                     count
//                 }
//             }
//             user_id
//             user {
//                 id
//                 first_name
//                 last_name
//                 display_name
//                 avatar
//                 photo_url
//                 country
//                 region
//                 city
//                 slug
//             }
//             created_date
//             updated_date
//         }
//     }
// `;
