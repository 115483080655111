import React, { useState } from 'react';
import { Icon, Row, Col, Typography, Button, Input, Tabs, Empty } from 'antd';
import Card from '../../components/Card';
import { Link } from 'react-router-dom';
import SectionTitle from '../../components/SectionTitle';
import SectionContent from '../../components/SectionContent';
import SectionWrapper from '../../components/SectionWrapper';

const { Text } = Typography;

const styles = {
	row: {
		display: 'flex',
		flexGrow: 1
	},
	tab: {
		paddingTop: 5,
		paddingLeft: 24,
		paddingRight: 24,
		paddingBottom: 24
	}
};

export default function TBScores(props) {
	return (
		<SectionWrapper>
			<SectionTitle
				title={props.title}
				titleStyle={{ color: '#fff', margin: 0, textTransform: 'uppercase' }}
				height={120}
				theme="tournamentBuilder"
			>
				<Link to={`/tournaments/${props.match.params.slug}/scores`}>
					<Button size="large" type="ghost" ghost>
						View Public Page
						<Icon type="arrow-right" />
					</Button>
				</Link>
			</SectionTitle>
			<SectionContent padding="20px 40px">
				<Row gutter={24} justify="center" type="flex">
					<Col xs={24}>
						<Card
							title="Scores"
							bodyStyle={{ padding: 0 }}
							style={{
								borderRadius: 8,
								overflow: 'hidden',
								border: 'none',
								boxShadow: '0 2px 5px 0px rgba(0, 0, 0, 0.1)'
							}}
						>
							<Empty
								image="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original"
								imageStyle={{
									height: 100
								}}
								description={<Text style={{ fontSize: 18 }}>Scores will appear here.</Text>}
								style={{ margin: 10 }}
							/>
						</Card>
					</Col>
				</Row>
			</SectionContent>
		</SectionWrapper>
	);
}
