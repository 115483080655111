import React, { useState } from 'react';
import { Table, Typography, Button, Modal, Tag, Icon, Tooltip } from 'antd';
import CircleFlag from '../../../components/CircleFlag';
import { getCountryName } from '../../../utils/getCountryName';

const { Text } = Typography;
const { confirm } = Modal;

export default function FramesList(props) {
    const { onSelected, onEditSelected, onDelete } = props;

    const columns = [
        {
            title: '#',
            dataIndex: 'frame_number',
            width: 80,
            defaultSortOrder: 'ascend',
            sorter: (a, b) => {
                return a.frame_number - b.frame_number;
            },
        },
        {
            title: 'Challenger 1 Stats',
            dataIndex: 'challenger1_stats',

            render: (text, record) => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ marginRight: 10 }}>
                        {record.challenger1_is_winner === true ? (
                            <Tag
                                color="#52c41a"
                                size="large"
                                style={{
                                    fontSize: 16,
                                    padding: '5px 10px',
                                    width: 30,
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                W
                            </Tag>
                        ) : (
                            <Tag
                                color="#f5222d"
                                size="large"
                                style={{
                                    fontSize: 16,
                                    padding: '5px 10px',
                                    width: 30,
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                L
                            </Tag>
                        )}
                    </div>
                    <div>
                        <div>
                            {record.challenger1.country && <CircleFlag country={record.challenger1.country} left={0} />}

                            <Text style={{ fontWeight: 600, marginLeft: record.challenger1.country ? 10 : 0 }}>{record.challenger1.name} </Text>
                        </div>
                        <div>
                            <Text type="secondary">
                                <b>{record.challenger1_points}</b> points - <b>{record.challenger1_balls_pocketed}</b> balls pocketed -{' '}
                                <b>{record.challenger1_high_break}</b> high break - <b>{record.challenger1_errors}</b> errors - <b>{record.challenger1_tpa}</b>{' '}
                                TPA
                            </Text>
                        </div>
                    </div>
                </div>
            ),
        },

        {
            title: 'Challenger 2 Stats',
            dataIndex: 'challenger2_stats',
            render: (text, record) => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ marginRight: 10 }}>
                        {record.challenger2_is_winner === true ? (
                            <Tag
                                color="#52c41a"
                                size="large"
                                style={{
                                    fontSize: 16,
                                    padding: '5px 10px',
                                    width: 30,
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                W
                            </Tag>
                        ) : (
                            <Tag
                                color="#f5222d"
                                size="large"
                                style={{
                                    fontSize: 16,
                                    padding: '5px 10px',
                                    width: 30,
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                L
                            </Tag>
                        )}
                    </div>
                    <div>
                        <div>
                            {record.challenger2.country && <CircleFlag country={record.challenger2.country} left={0} />}

                            <Text style={{ fontWeight: 600, marginLeft: record.challenger2.country ? 10 : 0 }}>{record.challenger2.name} </Text>
                        </div>
                        <div>
                            <Text type="secondary">
                                <b>{record.challenger2_points}</b> points - <b>{record.challenger2_balls_pocketed}</b> balls pocketed -{' '}
                                <b>{record.challenger2_high_break}</b> high break - <b>{record.challenger2_errors}</b> errors - <b>{record.challenger2_tpa}</b>{' '}
                                TPA
                            </Text>
                        </div>
                    </div>
                </div>
            ),
        },

        // {
        // 	title: 'Action',
        // 	key: 'action',
        // 	width: 200,
        // 	render: (text, record) => (
        // 		<span style={{ whiteSpace: 'nowrap' }}>
        // 			<Tooltip title="Edit">
        // 				<Button
        // 					type="ghost"
        // 					onClick={(e) => {
        // 						e.preventDefault();
        // 						e.stopPropagation();
        // 						onEditSelected(record.id);
        // 						// props.history.push(
        // 						// 	`/tournament-builder/${props.tournament.slug}/venues/${record.slug}/edit`
        // 						// );
        // 					}}
        // 				>
        // 					<Icon type="edit" />
        // 				</Button>
        // 			</Tooltip>
        // 			{/* <Divider type="vertical" /> */}
        // 			<Tooltip title="Delete">
        // 				<Button
        // 					type="ghost"
        // 					onClick={(e) => {
        // 						e.preventDefault();
        // 						e.stopPropagation();
        // 						showConfirm(record);
        // 					}}
        // 				>
        // 					<Icon type="delete" />
        // 				</Button>
        // 			</Tooltip>
        // 		</span>
        // 	)
        // }
    ];

    function onChange(pagination, filters, sorter) {
        console.log('params', pagination, filters, sorter);
    }

    function showConfirm(record) {
        confirm({
            title: `Are you sure you want to remove ${record.label} from this venue?`,
            centered: true,
            transitionName: 'fade',
            maskTransitionName: 'none',
            // onOk() {
            // 	return new Promise((resolve, reject) => {
            // 		setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
            // 	}).catch(() => console.log('Oops errors!'));
            // },
            onOk() {
                onDelete(record);
            },
            onCancel() {},
        });
    }

    return (
        <Table
            onRow={(record, rowIndex) => {
                return {
                    // onClick: (event) => {
                    // 	console.log(record, rowIndex);
                    // 	props.history.push({
                    // 		pathname: `/tournament-builder/${props.tournament.slug}/venues/${record.slug}`,
                    // 		// search: '?query=abc',
                    // 		state: { data: record }
                    // 	});
                    // },
                    onDoubleClick: (event) => {}, // double click row
                    onContextMenu: (event) => {}, // right button click row
                    onMouseEnter: (event) => {}, // mouse enter row
                    onMouseLeave: (event) => {}, // mouse leave row
                };
            }}
            columns={columns}
            dataSource={props.frames}
            onChange={onChange}
            pagination={false}
            tableLayout="auto"
            scroll={{ x: 400 }}
            // size="small"
            bordered={false}
        />
    );
}
