import React, { useState, useRef, useEffect } from 'react';
import { message, Icon, Row, Col, Typography, Popconfirm, Layout, Progress, Button, Input, Tabs, Empty, Table, Modal } from 'antd';
import CircularLoader from '../../components/CircularLoader';
import Card from '../../components/Card';
import { Query, Subscription } from '@apollo/client/react/components';
import { GET_SCHEDULED_TABLES_QUERY, GET_TOURNAMENT_BRACKET_QUERY } from './data/queries';
import { GET_SCHEDULED_TABLES_SUBSCRIPTION } from './data/subscriptions';
import useBreakpoint from 'use-breakpoint';
import { defaultBreakpoints } from '../../utils/defaultBreakpoints';
import MatchOverlay from '../digitalpool/tournaments/bracket/MatchOverlay2';
import PendingMatches from '../digitalpool/tournaments/bracket/PendingMatches';
import SidePanel from '../../screens/digitalpool/tournaments/bracket/SidePanel';
import ScheduledTables from './ScheduledTables';
import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';
momentDurationFormatSetup(moment);

const { Text, Title } = Typography;
const { Sider, Content } = Layout;
const { confirm } = Modal;

const styles = {
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
};

export default function TBScheduleTables(props) {
    const { tournament, client, theme } = props;

    const [modalVisible, setModalVisible] = useState(false);
    const [currentMatch, setCurrentMatch] = useState();
    const [currentRound, setCurrentRound] = useState();
    const [tables, setTables] = useState([]);
    const [tournamentComplete, setTournamentComplete] = useState(tournament.status === 'COMPLETED' ? true : false);
    const { breakpoint, maxWidth, minWidth } = useBreakpoint(defaultBreakpoints);
    const [sidePanelOpen, setSidePanelOpen] = useState(breakpoint === 'mobile' ? false : true);
    const defaultSize = 'medium';
    var zone_name = moment.tz.guess();
    var timezone = moment.tz(zone_name).zoneAbbr();
    const windowHeight = window.innerHeight;

    function showCompleteDialog(winner) {
        Modal.success({
            content: `${winner.challenger2_name || winner.challenger1_name} won the tournament!`,
            centered: true,
            transitionName: 'fade',
            maskTransitionName: 'none',
        });
    }

    function completeTournament(winner) {
        showCompleteDialog(winner);
        setTournamentComplete(true);
    }

    function showScoreModal(match, round) {
        setCurrentMatch(match);
        setCurrentRound(round);
        setModalVisible(true);
    }

    function handleApproval(match) {
        console.log('approved', match);
        // assignNextMatchConfirmation(match);
    }

    function assignNextMatchConfirmation(matches, match) {
        confirm({
            title: `Assign next scheduled match ${match.match_number} on ${match.table_name} between ${match.challenger1_name} vs. ${match.challenger2_name}?`,
            centered: true,
            transitionName: 'fade',
            maskTransitionName: 'none',
            // onOk() {
            // 	return new Promise((resolve, reject) => {
            // 		setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
            // 	}).catch(() => console.log('Oops errors!'));
            // },
            onOk() {
                const nextMatchOnTable = matches
                    .filter((item) => {
                        return item.scheduled_table_id === match.scheduled_table_id;
                    })
                    .filter((item) => {
                        return item.id !== match.id;
                    });

                console.log({
                    match,
                    nextMatchOnTable: nextMatchOnTable,
                    matches,
                    tables,
                });
            },
            onCancel() {},
            cancelText: 'Dismiss',
        });
    }

    return (
        <Query
            query={GET_TOURNAMENT_BRACKET_QUERY}
            fetchPolicy="cache-and-network"
            notifyOnNetworkStatusChange
            awaitRefetchQueries
            variables={{ tournamentId: tournament.id }}
            onCompleted={(data, loading, error, subscribeToMore) => {
                if (!loading && !error) {
                    // const { tournament_brackets } = data;
                    // setMatches(tournament_brackets);
                    // const brackets = buildBracket(tournament_brackets);
                    // setMatches(brackets.tournament_brackets);
                    // setWinnerBracket(brackets.winnerBracket);
                    // setLoserBracket(brackets.loserBracket);
                    // setLoserBracketReversed(brackets.loserBracket.reverse());
                    // setDrawRound(brackets.draw);
                }
            }}
        >
            {({ loading, error, data, networkStatus }) => {
                if (networkStatus === 1 || loading) {
                    return (
                        <div style={styles.container}>
                            <CircularLoader />
                        </div>
                    );
                }
                if (loading)
                    return (
                        <div style={styles.container}>
                            <CircularLoader />
                        </div>
                    );
                if (error) return <div style={styles.container}>Error: {error.message}</div>;
                if ((data && !data.tournament_brackets) || (data && data.tournament_brackets.length === 0)) {
                    if ((data && !data.tournament_brackets) || (data && data.tournament_brackets.length === 0)) {
                        return (
                            <Col xs={24}>
                                <Card>
                                    <Empty
                                        image="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original"
                                        imageStyle={{
                                            height: 100,
                                        }}
                                        description={<Text style={{ fontSize: 18 }}>No tournament bracket exists yet.</Text>}
                                        style={{ margin: 10 }}
                                    />
                                </Card>
                            </Col>
                        );
                    }
                }

                // subscribeToNewMatches(subscribeToMore);
                const matches = data.tournament_brackets;

                return (
                    <React.Fragment>
                        <Layout>
                            <Content
                                style={{
                                    maxHeight: windowHeight - 250,
                                    overflowY: 'auto',
                                    padding: 20,
                                    backgroundColor: theme && theme.name === 'dark' ? '#06101b' : 'transparent',
                                }}
                            >
                                <Row gutter={24} justify="center" type="flex">
                                    <Col xs={24}>
                                        <Query
                                            query={GET_SCHEDULED_TABLES_QUERY}
                                            fetchPolicy="cache-and-network"
                                            notifyOnNetworkStatusChange={true}
                                            variables={{ tournament_id: tournament.id }}
                                            onCompleted={(data) => {
                                                if (!loading && !error) {
                                                    const { pool_tables } = data;
                                                    if (pool_tables) {
                                                        setTables(pool_tables);
                                                    }
                                                }
                                            }}
                                        >
                                            {({ loading, error, data, subscribeToMore }) => {
                                                if (loading) return <CircularLoader />;
                                                if (error) return <div style={styles.container}>Error: {error.message}</div>;
                                                if (data && !data.pool_tables) {
                                                    return <div>Tables not found.</div>;
                                                }
                                                if (data && data.pool_tables.length === 0) {
                                                    return <div>Tables not found.</div>;
                                                }

                                                let scheduledMatches = [];

                                                data.pool_tables &&
                                                    data.pool_tables.forEach((table) => {
                                                        if (table.scheduled_matches && table.scheduled_matches.length > 0) {
                                                            table.scheduled_matches.forEach((match) => {
                                                                scheduledMatches.push({
                                                                    ...match,
                                                                    pool_table: table,
                                                                    table_id: match.table_id,
                                                                    table_name: match.table_id ? match.table_name : table.label,
                                                                    names: `${match.challenger1_name} ${match.challenger2_name}`,
                                                                });
                                                            });
                                                        }
                                                    });

                                                let sortedMatches = scheduledMatches.sort((a, b) => {
                                                    return (
                                                        a.table_name.localeCompare(b.table_name, undefined, {
                                                            numeric: true,
                                                            sensitivity: 'base',
                                                        }) || a.priority - b.priority
                                                    );
                                                });

                                                return (
                                                    <Row gutter={24} justify="center" type="flex">
                                                        <Col xs={24}>
                                                            <Card
                                                                bodyStyle={{ padding: 0 }}
                                                                cover={
                                                                    <div
                                                                        style={{
                                                                            paddingTop: 16,
                                                                            paddingBottom: 16,
                                                                            paddingLeft: 24,
                                                                            paddingRight: 24,
                                                                            // background:'linear-gradient(rgb(239, 243, 245), rgb(255, 255, 255))',
                                                                            borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
                                                                        }}
                                                                    >
                                                                        <Title
                                                                            level={4}
                                                                            style={{
                                                                                margin: 0,
                                                                                padding: 0,
                                                                                fontSize: 18,
                                                                            }}
                                                                        >
                                                                            Scheduled matches by table
                                                                        </Title>
                                                                    </div>
                                                                }
                                                            >
                                                                <ScheduledTables
                                                                    matches={sortedMatches.map((item, index) => {
                                                                        return {
                                                                            ...item,
                                                                            table_name:
                                                                                item.table_name || (item && item.scheduled_table && item.scheduled_table.label),
                                                                            matches: matches,
                                                                            index: index + 1,
                                                                            key: index,
                                                                        };
                                                                    })}
                                                                    onModalVisible={setModalVisible}
                                                                    onCurrentRound={setCurrentRound}
                                                                    onCurrentMatch={(match) => {
                                                                        // assignNextMatchConfirmation(sortedMatches, match);
                                                                        setCurrentMatch(match);
                                                                    }}
                                                                    subscribeToMore={subscribeToMore}
                                                                    subscribeToNewData={() => {
                                                                        subscribeToMore({
                                                                            document: GET_SCHEDULED_TABLES_SUBSCRIPTION,
                                                                            variables: { tournament_id: tournament.id },
                                                                            updateQuery: (prev, { subscriptionData }) => {
                                                                                if (!subscriptionData.data) return prev;
                                                                                const newTables = subscriptionData.data.pool_tables;

                                                                                let updated;
                                                                                if (prev) {
                                                                                    updated = Object.assign({}, prev, {
                                                                                        pool_tables: [...newTables],
                                                                                    });
                                                                                } else {
                                                                                    updated = Object.assign({}, prev, {
                                                                                        pool_tables: [...newTables],
                                                                                    });
                                                                                }
                                                                                return updated;
                                                                            },
                                                                        });
                                                                    }}
                                                                    {...props}
                                                                />
                                                            </Card>
                                                        </Col>
                                                    </Row>
                                                );
                                            }}
                                        </Query>
                                    </Col>
                                </Row>
                            </Content>

                            {sidePanelOpen === true && (
                                <Sider
                                    width={300}
                                    theme={theme.name}
                                    // style={{
                                    //     backgroundColor: '#fff',
                                    //     boxShadow: 'rgba(0, 21, 41, 0.25) 0px 0px 10px 0px',
                                    // }}
                                >
                                    <SidePanel
                                        {...props}
                                        tournament={tournament}
                                        matches={matches}
                                        onSidePanelOpen={() => {
                                            setSidePanelOpen(false);
                                        }}
                                        onShowScoreModal={showScoreModal}
                                        scrollToTable={() => {
                                            // const nextHeights = elementsRef.current.map(
                                            // 	(ref) => ref.current.getBoundingClientRect().height
                                            // );
                                            // console.log(nextHeights);
                                            // scrollToComponent(this[`winnerRound1_ref`], {
                                            // 	offset: 1000,
                                            // 	align: 'top',
                                            // 	duration: 1500
                                            // });
                                        }}
                                        bracketHeight={windowHeight - 230}
                                    />
                                </Sider>
                            )}
                        </Layout>
                        <MatchOverlay
                            {...props}
                            finals={matches && matches[matches.length - 1]}
                            matches={matches}
                            currentRound={currentRound}
                            currentMatch={currentMatch}
                            onSave={handleApproval}
                            modalScoreVisible={modalVisible}
                            onModalScoreVisible={setModalVisible}
                            tournament={tournament}
                            onTournamentComplete={(winner) => {
                                completeTournament(winner);
                            }}
                        />
                        <PendingMatches theme={theme} tournament={tournament} tables={tables} />
                    </React.Fragment>
                );
            }}
        </Query>
    );
}
