import React, { useState } from 'react';
import { message, Form, Select, Icon, Row, Col, Typography, Button, Radio, Avatar, Input, List, Tabs, Empty, Skeleton } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { Query, Mutation } from '@apollo/client/react/components';
import { GET_GAME_TYPES_QUERY } from './data/queries';
import _ from 'lodash';
import useBreakpoint from 'use-breakpoint';
import { defaultBreakpoints } from '../../../utils/defaultBreakpoints';
import { getGameType } from '../../../utils';
import numeral from 'numeral';

const { Search } = Input;
const { Option } = Select;
const { Title, Text } = Typography;

export default function SearchFilterTournaments(props) {
    const { tournaments, counts, viewToggle, theme, searching, onSearchTournament, onGameTypeChange, onStatusChange, onViewToggle } = props;
    const { breakpoint, maxWidth, minWidth } = useBreakpoint(defaultBreakpoints);
    const [current, setCurrent] = useState(viewToggle || 'grid');
    const statusTypes = tournaments && _.countBy(tournaments, 'status');

    function handleSubmit(values, actions) {
        actions.setSubmitting(false);
        actions.resetForm();
        console.log('Received values of form: ', values);
    }

    return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Form layout="inline" onSubmit={handleSubmit}>
                <Form.Item>
                    <span style={{ display: 'flex', alignItems: 'center' }}>
                        {/* <SearchOutlined size="large" style={{ marginRight: 10 }} /> */}
                        <Search
                            allowClear
                            placeholder="Search all tournaments"
                            size="large"
                            style={{ width: 350 }}
                            enterButton
                            onSearch={onSearchTournament}
                            loading={searching}
                        />
                    </span>
                </Form.Item>

                <Form.Item name="status">
                    <Select
                        showSearch
                        name="status"
                        defaultValue="all"
                        size="large"
                        style={{ width: 200 }}
                        optionFilterProp="children"
                        onChange={onStatusChange}
                        // defaultValue={[ 'jack', 'lucy' ]}
                        filterOption={(input, option) => {
                            // console.log(option);
                            return option && option.props && option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                        }}
                    >
                        <Option value="all">All Tournaments ({tournaments && numeral(tournaments.length).format('0,0')})</Option>
                        <Option value="NOT_STARTED">Upcoming ({statusTypes && numeral(statusTypes['NOT_STARTED']).format('0,0')})</Option>
                        <Option value="IN_PROGRESS">In Progress ({statusTypes && numeral(statusTypes['IN_PROGRESS']).format('0,0')})</Option>
                        <Option value="COMPLETED">Completed ({statusTypes && numeral(statusTypes['COMPLETED']).format('0,0')})</Option>
                        {/* <Option value="all">All Tournaments ({counts && numeral(counts.all).format('0,0')})</Option>
                        <Option value="NOT_STARTED">Upcoming ({counts && numeral(counts.upcoming).format('0,0')})</Option>
                        <Option value="IN_PROGRESS">In Progress ({counts && numeral(counts.in_progress).format('0,0')})</Option>
                        <Option value="COMPLETED">Completed ({counts && numeral(counts.completed).format('0,0')})</Option> */}
                    </Select>
                </Form.Item>

                <Form.Item name="game_type">
                    <Query query={GET_GAME_TYPES_QUERY} fetchPolicy="cache-and-network" notifyOnNetworkStatusChange={true}>
                        {({ loading, error, data }) => {
                            if (loading) return <div>Loading...</div>;
                            if (error) return <div>Error: {error.message}</div>;

                            const types = tournaments && _.countBy(tournaments, 'game_type');

                            return (
                                <Select
                                    showSearch
                                    name="game_type"
                                    defaultValue="all"
                                    size="large"
                                    style={{ width: 200 }}
                                    optionFilterProp="children"
                                    onChange={onGameTypeChange}
                                    // defaultValue={[ 'jack', 'lucy' ]}
                                    filterOption={(input, option) => {
                                        // console.log(option);
                                        return option && option.props && option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                    }}
                                >
                                    <Option value="all">All Games</Option>
                                    {/* {data &&
                                        data.game_types
                                            .sort((a, b) => a.value.localeCompare(b.value))
                                            .map((item, index) => {
                                                return (
                                                    <Option key={index} value={item.value}>
                                                        {item.value && (
                                                            <Avatar
                                                                size={24}
                                                                src={getGameType(item.value, theme)}
                                                                style={{
                                                                    display: 'inline-block',
                                                                    marginRight: 8,
                                                                }}
                                                            />
                                                        )}

                                                        <Text>
                                                            {_.startCase(_.toLower(item.value.replace('_', ' ')))} (
                                                            {(types && numeral(types[item.value]).format('0,0')) || 0})
                                                        </Text>
                                                    </Option>
                                                );
                                            })} */}
                                </Select>
                            );
                        }}
                    </Query>
                </Form.Item>
                {breakpoint === 'mobile' && (
                    <div>
                        <Radio.Group
                            value={current}
                            type="ghost"
                            onChange={(e) => {
                                setCurrent(e.target.value);
                                onViewToggle(e.target.value);
                            }}
                            style={{ marginRight: 10, marginTop: 8 }}
                        >
                            <Radio.Button value="grid">
                                <Icon type="appstore" />
                            </Radio.Button>
                            <Radio.Button value="list">
                                <Icon type="unordered-list" />
                            </Radio.Button>
                        </Radio.Group>
                    </div>
                )}
            </Form>
            {breakpoint !== 'mobile' && (
                <Radio.Group
                    value={current}
                    type="ghost"
                    onChange={(e) => {
                        setCurrent(e.target.value);
                        onViewToggle(e.target.value);
                    }}
                    style={{ marginRight: 10 }}
                >
                    <Radio.Button value="grid">
                        <Icon type="appstore" />
                    </Radio.Button>
                    <Radio.Button value="list">
                        <Icon type="unordered-list" />
                    </Radio.Button>
                </Radio.Group>
            )}
        </div>
    );
}
