export const payoutStructures = (values) => {
	let payoutStructures = [
		[ 100 ],
		[ 70, 30 ],
		[ 50, 30, 20 ],
		[ 40, 30, 20, 10 ],
		[ 43, 23, 14, 9.5, 5.25, 5.25 ],
		[ 40, 21, 12.5, 9, 5.25, 5.25, 3.5, 3.5 ],
		[ 36, 18, 11, 8.5, 5, 5, 3, 3, 2.5, 2.5, 2.5, 2.5 ],
		[ 33, 17.5, 11, 8, 4.75, 4.75, 3.5, 3.5, 2.5, 2.5, 2.5, 2.5, 1, 1, 1, 1 ],
		[ 31, 15.5, 10.5, 7.5, 4.25, 4.25, 2.5, 2.5, 2, 2, 2, 2, 1.5, 1.5, 1.5, 1.5, 1, 1, 1, 1, 1, 1, 1, 1 ]
	];

	let playerPlacements = [
		[ 1, 4 ],
		[ 5, 8 ],
		[ 9, 16 ],
		[ 17, 24 ],
		[ 25, 31 ],
		[ 32, 63 ],
		[ 64, 80 ],
		[ 81, 95 ],
		[ 96, 128 ]
	];

	const payoutType = values.payout_type;
	const totalPlayers = parseInt(values.max_players) || 32;
	// const totalPlayers = 32;
	const entryFee = parseFloat(values.entry_fee) || 0;
	const addedMoney = parseFloat(values.added_money) || 0;
	let recommendedPayouts = [];
	let chosenPayouts = [];

	const totalPurse = entryFee * totalPlayers + addedMoney;
	console.log('total purse', totalPurse);

	const payoutOptions = payoutStructures.map((percentages, index) => {
		const minPlayers = playerPlacements[index][0];
		const maxPlayers = playerPlacements[index][1];

		const payouts = percentages.map((percentage, index2) => {
			return {
				place: index2 + 1,
				payout: parseFloat((totalPurse * (percentage / 100)).toFixed(2)),
				percent: percentage
			};
		});
		const payoutPlaces = `1-${percentages.length}`;

		const output = {
			range: `${minPlayers}-${maxPlayers}`,
			payoutPlaces: payoutPlaces,
			minPlayers: minPlayers,
			maxPlayers: maxPlayers,
			payouts: payouts
		};

		if (payoutType && payoutType === payoutPlaces) {
			chosenPayouts = output;
		}

		if (totalPlayers >= minPlayers && totalPlayers <= maxPlayers) {
			recommendedPayouts = output;
		}

		return output;
	});

	return {
		totalPurse,
		entryFee,
		addedMoney,
		totalPlayers,
		payoutOptions: payoutOptions,
		recommendedPayouts,
		chosenPayouts
	};
};
