import React, { useState } from 'react';
import { Form, FormItem, Input, Radio, Checkbox, DatePicker, Select, FormikDebug, SubmitButton, ResetButton, AutoComplete } from 'formik-antd';
import { message, Icon, Row, Col, Typography, Button, Empty, Alert } from 'antd';
import { Formik, ErrorMessage } from 'formik';
import Card from '../../components/Card';
import { GET_PAYOUTS, GET_TOURNAMENT_PAYOUTS } from './data/queries';
import { CREATE_TOURNAMENT_PAYOUTS_MUTATION, DELETE_PAYOUTS_ENTRY, DELETE_TOURNAMENT_PAYOUTS_MUTATION } from './data/mutations';
import { useMutation } from '@apollo/react-hooks';
import { payoutStructures } from '../../utils';
import { Query } from '@apollo/client/react/components';
import ZeroState from '../../components/ZeroState';
import AddPlayerToPayoutModal from './AddPlayerToPayoutModal';
import PayoutsTable from './PayoutsTable';
import numeral from 'numeral';
import _ from 'lodash';

const { Text, Title } = Typography;
const { Option } = Select;

export default function TBPayouts(props) {
    const { tournament, client } = props;
    const [addPlayerModalVisible, setAddPlayerModalVisible] = useState(false);
    // const [ orderedData, setOrderedData ] = useState(false);
    const [selectedPayoutsId, setSelectedPayoutsId] = useState(false);
    // const [ tableData, setTableData ] = useState([]);
    const [deletePayouts] = useMutation(DELETE_PAYOUTS_ENTRY);
    const [createPayoutsMutation] = useMutation(CREATE_TOURNAMENT_PAYOUTS_MUTATION);

    function handlePlayerPayoutsOk() {
        setAddPlayerModalVisible(false);
    }

    function handlePlayerPayoutsCancel() {
        setAddPlayerModalVisible(false);
    }

    function handleEditSelected(data) {
        setAddPlayerModalVisible(true);
        setSelectedPayoutsId(data.id);
    }

    // async function handleDeleteSelected(data) {
    // 	await client.mutate({
    // 		mutation: DELETE_PAYOUTS_ENTRY,
    // 		variables: {
    // 			payouts_id: data.id
    // 		},
    // 		awaitRefetchQueries: true,
    // 		refetchQueries: [
    // 			{
    // 				query: GET_PAYOUTS,
    // 				variables: {
    // 					tournament_id: tournament.id
    // 				}
    // 			}
    // 		]
    // 	});
    // }

    function orderAndFormatData(arr) {
        let count = arr.length;
        let tmp, split, cNum1, cNum2, split2, cNum11, cNum21;
        let formatArr = [];

        for (let i = 0; i < count; i++) {
            if (arr[i].place.includes('-')) {
                split = arr[i].place.split('-');
                cNum1 = parseInt(split[0]);
                cNum2 = parseInt(split[1]);
            }

            for (let j = i + 1; j < count; j++) {
                if (arr[j].place.includes('-')) {
                    split2 = arr[j].place.split('-');
                    cNum11 = parseInt(split2[0]);
                    cNum21 = parseInt(split2)[1];

                    if (split) {
                        if (cNum2 > cNum21) {
                            tmp = arr[i];
                            arr[i] = arr[j];
                            arr[j] = tmp;
                        }
                    } else {
                        if (parseInt(arr[i].place) > parseInt(cNum21)) {
                            tmp = arr[i];
                            arr[i] = arr[j];
                            arr[j] = tmp;
                        }
                    }
                } else {
                    if (parseInt(arr[i].place) > parseInt(arr[j].place)) {
                        tmp = arr[i];
                        arr[i] = arr[j];
                        arr[j] = tmp;
                    }
                }

                if (parseInt(arr[i].place) > parseInt(arr[j].place)) {
                    tmp = arr[i];
                    arr[i] = arr[j];
                    arr[j] = tmp;
                }
            }

            split = false;

            formatArr.push({
                id: arr[i].id,
                place: arr[i].place,
                name: arr[i].player[0] ? arr[i].player[0].name : '',
                payout: arr[i].money,
                calcutta: arr[i].calcutta,
                percent: arr[i].percent,
                key: i + 1,
            });
        }

        return _.uniqBy(formatArr, 'place');
    }

    function handleSubmit(values, actions) {
        const payouts = payoutStructures({
            ...tournament,
            max_players:
                tournament &&
                tournament.tournament_players_aggregate &&
                tournament.tournament_players_aggregate.aggregate &&
                tournament.tournament_players_aggregate.aggregate.count,
            ...values,
        });

        let updatedPayouts = [];

        const chosenPayouts = payouts && payouts.chosenPayouts && payouts.chosenPayouts.payouts;
        const combinedPayouts = chosenPayouts;
        const payoutsUpdated = _.groupBy(combinedPayouts, 'payout');
        const entries = Object.entries(payoutsUpdated);
        entries.forEach((entry) => {
            const payout = entry[0];
            const places1 = entry[1][0].place;
            const places2 = entry[1][entry[1].length - 1].place;
            const percent = entry[1][0].percent;

            if (places1 !== places2) {
                updatedPayouts.push({
                    tournament_id: tournament.id,
                    money: payout,
                    percent,
                    place: `${places1}-${places2}`,
                });
            } else {
                updatedPayouts.push({
                    tournament_id: tournament.id,
                    money: payout,
                    percent,
                    place: `${places1}`,
                });
            }
        });
        console.log('payouts', updatedPayouts);

        props.client
            .mutate({
                mutation: DELETE_TOURNAMENT_PAYOUTS_MUTATION,
                variables: {
                    tournament_id: tournament.id,
                },
                // awaitRefetchQueries: true,
                // refetchQueries: [
                //     {
                //         query: GET_TOURNAMENT_PAYOUTS,
                //         variables: { tournament_id: tournament.id },
                //     },
                // ],
            })
            .then(async (data) => {
                console.log(data);
                createPayoutsMutation({
                    variables: {
                        objects: updatedPayouts,
                    },
                    notifyOnNetworkStatusChange: true,
                    awaitRefetchQueries: true,
                    refetchQueries: [
                        {
                            query: GET_TOURNAMENT_PAYOUTS,
                            variables: { tournament_id: tournament.id },
                        },
                    ],
                });
                actions.setSubmitting(false);
            })
            .catch((error) => {
                console.log(error);
                return error;
            });
    }

    return (
        <Row gutter={24} justify="center" type="flex">
            <Col xs={24}>
                <Alert
                    message="Helpful tips for adding placements"
                    description="When adding a placement range, put a dash inbetween two places. eg 10-20"
                    type="info"
                    closable
                    showIcon
                />
            </Col>
            <Col xs={24}>
                <Card title="Payout preview">
                    <Formik
                        enableReinitialize
                        initialValues={{
                            payout_type: tournament.payout_type || 'custom',
                        }}
                        onSubmit={(values, actions) => {
                            handleSubmit(values, actions);
                        }}
                        render={({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => {
                            const payouts = payoutStructures({
                                ...tournament,
                                max_players:
                                    tournament &&
                                    tournament.tournament_players_aggregate &&
                                    tournament.tournament_players_aggregate.aggregate &&
                                    tournament.tournament_players_aggregate.aggregate.count,
                                ...values,
                            });
                            const combinedPayouts = payouts && payouts.chosenPayouts && payouts.chosenPayouts.payouts;

                            // console.log('recommended', payouts.recommendedPayouts);

                            return (
                                <React.Fragment>
                                    <div style={{ marginBottom: 10 }}>
                                        <Text style={{ fontSize: 15, fontWeight: 600 }}>Payout Preview</Text>
                                    </div>
                                    <div style={{ marginBottom: 10 }}>
                                        <Text>
                                            Total purse: <b>{numeral(payouts.totalPurse).format('$0,0.00')}</b> based on a <b>${payouts.entryFee}</b> entry fee
                                            with <b>${payouts.addedMoney}</b> added money and <b>{payouts.totalPlayers}</b> players.
                                        </Text>
                                    </div>

                                    <Form onSubmit={handleSubmit} layout="vertical" style={{ maxWidth: 600 }}>
                                        <FormItem name="payout_type" label="Payout Structure">
                                            <Select
                                                showSearch
                                                name="payout_type"
                                                placeholder="Select how winners will be paid out"
                                                size="large"
                                                optionFilterProp="children"
                                                defaultValue="custom"
                                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                            >
                                                {[
                                                    {
                                                        type: 'Custom',
                                                        value: 'custom',
                                                    },
                                                    {
                                                        type: 'Winner Take All (1-4 players)',
                                                        value: '1-1',
                                                    },
                                                    {
                                                        type: 'Top 2 places (5-8 players)',
                                                        value: '1-2',
                                                    },
                                                    {
                                                        type: 'Top 3 places (9-16 players)',
                                                        value: '1-3',
                                                    },
                                                    {
                                                        type: 'Top 4 places (17-24 players)',
                                                        value: '1-4',
                                                    },
                                                    {
                                                        type: 'Top 6 places (25-31 players)',
                                                        value: '1-6',
                                                    },
                                                    {
                                                        type: 'Top 8 places (32-63 players)',
                                                        value: '1-8',
                                                    },
                                                    {
                                                        type: 'Top 12 places (64-80 players)',
                                                        value: '1-12',
                                                    },
                                                    {
                                                        type: 'Top 16 places (81-95 players)',
                                                        value: '1-16',
                                                    },
                                                    {
                                                        type: 'Top 24 places (96-128 players)',
                                                        value: '1-24',
                                                    },
                                                ].map((item, index) => (
                                                    <Option key={index} value={item.value}>
                                                        {item.type}
                                                    </Option>
                                                ))}
                                            </Select>
                                            {combinedPayouts && (
                                                <div style={{ marginTop: 10 }}>
                                                    <table
                                                        width="100%"
                                                        className="info-table"
                                                        style={{
                                                            marginTop: 10,
                                                            marginBottom: 0,
                                                            marginLeft: 0,
                                                            marginRight: 0,
                                                        }}
                                                    >
                                                        <tbody>
                                                            <tr>
                                                                <td>Place</td>
                                                                <td>Payout</td>
                                                                <td>Percent</td>
                                                            </tr>
                                                            {combinedPayouts.map((payouts, index) => {
                                                                return (
                                                                    <tr key={index}>
                                                                        <td>{payouts.place}</td>
                                                                        <td>
                                                                            <Text>{numeral(payouts.payout).format('$0,0')}</Text>
                                                                        </td>
                                                                        <td>
                                                                            <Text>{payouts.percent}%</Text>
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            })}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            )}
                                        </FormItem>
                                        <div style={{ marginTop: 10 }}>
                                            <SubmitButton
                                                size="large"
                                                disabled={Object.keys(errors).length > 0 ? true : false}
                                                type={Object.keys(errors).length > 0 ? 'danger' : 'primary'}
                                                // loading={loading}
                                            >
                                                Reset Payouts
                                            </SubmitButton>
                                            {/* <ResetButton size="large">Reset</ResetButton> */}
                                        </div>
                                    </Form>
                                </React.Fragment>
                            );
                        }}
                    />
                </Card>
            </Col>
            <Col xs={24}>
                <Card
                    title="Payouts"
                    extra={
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'right',
                                justifyContent: 'space-between',
                            }}
                        >
                            <Button
                                type="primary"
                                onClick={(e) => {
                                    setSelectedPayoutsId(0);
                                    setAddPlayerModalVisible(true);
                                }}
                            >
                                <Icon type="plus-circle" /> Add Payout
                            </Button>
                        </div>
                    }
                    bodyStyle={{ padding: 0 }}
                >
                    <Query
                        query={GET_TOURNAMENT_PAYOUTS}
                        fetchPolicy="cache-and-network"
                        notifyOnNetworkStatusChange={true}
                        variables={{ tournament_id: tournament.id }}
                        onCompleted={(data) => {
                            // var set = [];
                            // for (let i = 0; i < data.tournament_payouts.length; i++) {
                            // 	set.push(data.tournament_payouts[i]);
                            // }
                            // let formattedData = orderAndFormatData(set);
                            // setTableData(formattedData);
                        }}
                    >
                        {({ loading, error, data, refetch }) => {
                            // console.log(data);
                            if (loading) return <div>Loading...</div>;
                            if (error) return <div>{error}</div>;
                            if ((data && !data.tournament_payouts) || (data && data.tournament_payouts.length === 0)) {
                                return <ZeroState showImage message="No payouts found." style={{ margin: 30 }} {...props} />;
                            }

                            var set = [];
                            for (let i = 0; i < data.tournament_payouts.length; i++) {
                                set.push(data.tournament_payouts[i]);
                            }

                            let formattedData = orderAndFormatData(set);

                            console.log('fd', formattedData);

                            return (
                                <React.Fragment>
                                    <PayoutsTable
                                        formattedData={formattedData}
                                        onEditSelected={handleEditSelected}
                                        onDelete={(record) => {
                                            deletePayouts({
                                                variables: {
                                                    id: record.id,
                                                },
                                                notifyOnNetworkStatusChange: true,
                                                awaitRefetchQueries: true,
                                                refetchQueries: [
                                                    {
                                                        query: GET_PAYOUTS,
                                                        variables: {
                                                            tournament_id: tournament.id,
                                                        },
                                                    },
                                                ],
                                            })
                                                .then((data) => {
                                                    console.log(data);
                                                    message.success('Points deleted');
                                                })
                                                .catch((error) => {
                                                    console.log(error);
                                                    message.info('There was an error', error);
                                                });
                                        }}
                                    />
                                </React.Fragment>
                            );
                        }}
                    </Query>
                </Card>
            </Col>
            <AddPlayerToPayoutModal
                {...props}
                selectedPayoutsId={selectedPayoutsId}
                modalVisible={addPlayerModalVisible}
                onModalOk={handlePlayerPayoutsOk}
                onModalCancel={handlePlayerPayoutsCancel}
                client={props.client}
                tournamentID={tournament.id}
            />
        </Row>
    );
}
