import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Row, Col, Typography, List, Badge, Tag, Drawer, Empty, Modal, Button } from 'antd';
import Card, { Meta } from '../../../components/Card';
import SectionWrapper from '../../../components/SectionWrapper';
import SectionContent from '../../../components/SectionContent';
import { Query, Subscription } from '@apollo/client/react/components';
import { GET_EVENT_LIVE_SCORES_SUBSCRIPTION, GET_EVENT_LIVE_TABLE_SCORES_SUBSCRIPTION } from '../data/subscriptions';
import CircularLoader from '../../../components/CircularLoader';
import TableScoreCard from '../../digitalpool/tournaments/TableScoreCard';
import ZeroState from '../../../components/ZeroState';
import _ from 'lodash';

const { Text } = Typography;

function EventScores(props) {
    const { event, tournaments, authState } = props;

    function canAccess() {
        if ((authState && authState.user && authState.user.role === 'admin') || (authState && authState.user && event.owner_id === authState.user.id)) {
            return true;
        } else {
            return false;
        }
    }

    function renderMatch(match, table, index, tournament) {
        return (
            <Col xs={24} sm={12} md={12} lg={8} xl={8} xxl={6} key={index}>
                <TableScoreCard
                    title={table ? `Match ${index + 1}` : null}
                    table={table}
                    label={table ? table.label : `Match ${index + 1}`}
                    matchData={match}
                    tournament={tournament}
                    status={match && match.status}
                    gameType="NINE_BALL"
                    hoverable={canAccess() === true ? true : false}
                    onClick={() => {
                        if (canAccess() === true) {
                            props.history.push(`/events/${event.slug}/tables/${table.slug}/scoring`);
                        }
                    }}
                    playerStyle={{ fontSize: 20, textShadow: '0 2px 2px rgba(0,0,0,0.50)' }}
                />
            </Col>
        );
    }

    return (
        <SectionWrapper>
            <SectionContent padding="14px 40px">
                <Row gutter={24} justify="center" type="flex" id="table-scores">
                    <Col xs={24}>
                        <Subscription
                            subscription={GET_EVENT_LIVE_TABLE_SCORES_SUBSCRIPTION}
                            fetchPolicy="cache-and-network"
                            notifyOnNetworkStatusChange={true}
                            variables={{ event_id: event.id }}
                            onSubscriptionData={(data, error, loading) => {
                                console.log(data.subscriptionData && data.subscriptionData.data);
                            }}
                        >
                            {({ loading, error, data }) => {
                                let matches = [];
                                data &&
                                    data.events &&
                                    data.events.forEach((event) => {
                                        const eventMatches = event.matches;
                                        const eventTournaments = event.tournaments;

                                        eventMatches.forEach((match) => {
                                            if (match.pool_table && match.pool_table.matches) {
                                                match.pool_table.matches.forEach((item) => {
                                                    matches.push({
                                                        table: match.pool_table,
                                                        ...item,
                                                    });
                                                });
                                            }
                                        });

                                        eventTournaments.forEach((tournament) => {
                                            if (tournament.pool_tables) {
                                                tournament.pool_tables.forEach((table) => {
                                                    if (table.tournament_match_table && table.tournament_match_table[0]) {
                                                        matches.push({
                                                            table,
                                                            ...table.tournament_match_table[0],
                                                        });
                                                    }
                                                });
                                            }
                                        });
                                    });

                                if (loading) return <CircularLoader />;
                                if (error) return <div>Error: {error.message}</div>;
                                if ((data && !matches) || (data && matches && matches.length === 0)) {
                                    return (
                                        <Row gutter={24} justify="center" type="flex">
                                            <Col xs={24}>
                                                <Card
                                                    title="Event Live Scores"
                                                    extra={
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'flex-end',
                                                            }}
                                                        >
                                                            <Button
                                                                type="default"
                                                                size="large"
                                                                onClick={() => {
                                                                    props.history.push(`/events/${event.slug}/tables/overlay`);
                                                                }}
                                                            >
                                                                All Tables Overlay
                                                            </Button>
                                                        </div>
                                                    }
                                                    bodyStyle={{ padding: 0 }}
                                                >
                                                    <ZeroState showImage message="No matches currently in progress." style={{ margin: 30 }} {...props} />
                                                </Card>
                                            </Col>
                                        </Row>
                                    );
                                }

                                const uniqueMatches = _.uniqBy(matches, 'id');
                                console.log(uniqueMatches);

                                return (
                                    <Row gutter={24} justify="center" type="flex">
                                        <Col xs={24}>
                                            <Card
                                                title="Event Live Scores"
                                                extra={
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'flex-end',
                                                        }}
                                                    >
                                                        <Button
                                                            type="default"
                                                            size="large"
                                                            onClick={() => {
                                                                props.history.push(`/events/${event.slug}/tables`);
                                                            }}
                                                        >
                                                            Live Scores Overlay
                                                        </Button>
                                                    </div>
                                                }
                                                bodyStyle={{ padding: 0 }}
                                            >
                                                <Row gutter={16} justify="start" type="flex">
                                                    {uniqueMatches
                                                        .sort((a, b) => {
                                                            if (a.table_name && b.table_name) {
                                                                return a.table_name.localeCompare(b.table_name, undefined, {
                                                                    numeric: true,
                                                                    sensitivity: 'base',
                                                                });
                                                            } else if (a.table.label && b.table.label) {
                                                                return a.table.label.localeCompare(b.table.label, undefined, {
                                                                    numeric: true,
                                                                    sensitivity: 'base',
                                                                });
                                                            }
                                                        })
                                                        .map((match, index) => {
                                                            const table = match.table;
                                                            if (match) {
                                                                let formattedMatch;
                                                                if (match.player_name || match.opponent_name) {
                                                                    formattedMatch = {
                                                                        ...match,
                                                                        challenger1_id: match.player_id,
                                                                        challenger1_name: match.player_name,
                                                                        challenger1_country: match.player_country,
                                                                        challenger1_score: match.player_score,
                                                                        challenger2_id: match.opponent_id,
                                                                        challenger2_name: match.opponent_name,
                                                                        challenger2_country: match.opponent_country,
                                                                        challenger2_score: match.opponent_score,
                                                                    };
                                                                } else {
                                                                    formattedMatch = { ...match };
                                                                }

                                                                return renderMatch(formattedMatch, table, index, {
                                                                    show_player_races: false,
                                                                    show_player_skill_levels: false,
                                                                });
                                                            }
                                                        })}
                                                </Row>
                                            </Card>
                                        </Col>
                                    </Row>
                                );
                            }}
                        </Subscription>
                    </Col>
                </Row>
            </SectionContent>
        </SectionWrapper>
    );
}

export default withRouter(EventScores);
