import React from 'react';
import { Button, Typography } from 'antd';

const { Title } = Typography;

export default function MatchFargoColumnCharts(props) {
    const { tournament, selectedFargoRace, getFargoRaces, onSetSelectedFargoRace, onSetShowFargoColumnCharts } = props;

    return (
        <div
            style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(0,0,0,0.8)',
                zIndex: 10,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <div
                style={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    flexDirection: 'column',
                }}
            >
                <Title level={4} style={{ marginTop: 0, marginBottom: 10, color: '#fff' }}>
                    Select Fargo{' '}
                    {tournament.handicap_format === 'fargo_hot_column'
                        ? 'hot column'
                        : tournament.handicap_format === 'fargo_medium_column'
                        ? 'medium column'
                        : tournament.handicap_format === 'fargo_mild_column'
                        ? 'mild column'
                        : ''}{' '}
                    race
                </Title>
                <div>
                    <Button
                        type={selectedFargoRace === 2 ? 'primary' : `ghost`}
                        ghost
                        size="large"
                        onClick={() => {
                            getFargoRaces(2);
                            onSetSelectedFargoRace(2);
                            onSetShowFargoColumnCharts(false);
                        }}
                    >
                        R2
                    </Button>
                    <Button
                        type={selectedFargoRace === 3 ? 'primary' : `ghost`}
                        ghost
                        size="large"
                        onClick={() => {
                            getFargoRaces(3);
                            onSetSelectedFargoRace(3);
                            onSetShowFargoColumnCharts(false);
                        }}
                    >
                        R3
                    </Button>
                    <Button
                        type={selectedFargoRace === 4 ? 'primary' : `ghost`}
                        ghost
                        size="large"
                        onClick={() => {
                            getFargoRaces(4);
                            onSetSelectedFargoRace(4);
                            onSetShowFargoColumnCharts(false);
                        }}
                    >
                        R4
                    </Button>
                    <Button
                        type={selectedFargoRace === 5 ? 'primary' : `ghost`}
                        ghost
                        size="large"
                        onClick={() => {
                            getFargoRaces(5);
                            onSetSelectedFargoRace(5);
                            onSetShowFargoColumnCharts(false);
                        }}
                    >
                        R5
                    </Button>
                    <Button
                        type={selectedFargoRace === 6 ? 'primary' : `ghost`}
                        ghost
                        size="large"
                        onClick={() => {
                            getFargoRaces(6);
                            onSetSelectedFargoRace(6);
                            onSetShowFargoColumnCharts(false);
                        }}
                    >
                        R6
                    </Button>
                    <Button
                        type={selectedFargoRace === 7 ? 'primary' : `ghost`}
                        ghost
                        size="large"
                        onClick={() => {
                            getFargoRaces(7);
                            onSetSelectedFargoRace(7);
                            onSetShowFargoColumnCharts(false);
                        }}
                    >
                        R7
                    </Button>
                    <Button
                        type={selectedFargoRace === 9 ? 'primary' : `ghost`}
                        ghost
                        size="large"
                        onClick={() => {
                            getFargoRaces(9);
                            onSetSelectedFargoRace(9);
                            onSetShowFargoColumnCharts(false);
                        }}
                    >
                        R9
                    </Button>
                    <Button
                        type={selectedFargoRace === 11 ? 'primary' : `ghost`}
                        ghost
                        size="large"
                        onClick={() => {
                            getFargoRaces(11);
                            onSetSelectedFargoRace(11);
                            onSetShowFargoColumnCharts(false);
                        }}
                    >
                        R11
                    </Button>
                </div>
                <div style={{ marginTop: 15 }}>
                    <Button
                        type="secondary"
                        size="large"
                        onClick={() => {
                            onSetShowFargoColumnCharts(false);
                        }}
                    >
                        Cancel
                    </Button>
                </div>
            </div>
        </div>
    );
}
