import React from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { FormItem, Input } from 'formik-antd';
import { Radio } from 'antd';
import { settingsFormSelector } from '../../data/selectors';
import { createGhostGameFormRebuyRadio } from '../../data/atoms';


function FormItemRebuyInput(props) {
    const { rebuyRadio } = useRecoilValue(settingsFormSelector);
    if(!rebuyRadio) return null;

    return (
        <React.Fragment>
            <FormItem
                label="Number of Rebuys"
                name="number_of_rebuys"
                required 
                hasFeedback 
                showValidateSuccess
            >
                <Input name="number_of_rebuys" placeholder="Number of Rebuys" />
            </FormItem>
            <FormItem
                label="Rebuy Cost"
                name="rebuy_cost"
                required 
                hasFeedback 
                showValidateSuccess
            >
                <Input name="rebuy_cost" placeholder="Rebuy Cost" />
            </FormItem>
        </React.Fragment>
    );
}

export default function RadioRebuys(props) {
    const { onChange, value } = props;

    const { rebuyRadio } = useRecoilValue(settingsFormSelector);
    const setValue = useSetRecoilState(createGhostGameFormRebuyRadio);

    if(rebuyRadio === null) setValue(true);

    return (
        <React.Fragment>
            <FormItem
                label="Rebuys"
                name="allow_rebuys"
                hasFeedback 
            >
                <Radio.Group name="allow_rebuys" value={rebuyRadio} onChange={onChange}>
                    <Radio value={true}>
                        Yes
                    </Radio>
                    <br />
                    <Radio value={false}>
                        No
                    </Radio>
                </Radio.Group>
            </FormItem>
            <FormItemRebuyInput />
        </React.Fragment>
    );
}