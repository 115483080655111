import React, { useState } from 'react';
import NewsFeed from './feed/NewsFeed';
import SectionHeader from '../../components/SectionHeader';
import SectionContent from '../../components/SectionContent';
import SectionTitle from '../../components/SectionTitle';
import { Tabs, Col, Row, Avatar, Form, Icon, Input, Button, Empty, Typography, Select } from 'antd';
import Card, { Meta } from '../../components/Card';
import Masonry from '../../components/Masonry';
import { Query } from '@apollo/client/react/components';
import LiveThumbnail from '../digitalpool/live/LiveThumbnail';
import useBreakpoint from 'use-breakpoint';
import { defaultBreakpoints } from '../../utils/defaultBreakpoints';

import TwitchEmbedVideo from 'react-twitch-embed-video';
import { getTwitchUserStream } from '../../lib/live';
import { GET_ALL_LIVE_STREAMS } from './data/queries';
import CircularLoader from '../../components/CircularLoader';
// import { setAnimation } from 'highcharts';

const { TabPane } = Tabs;
const { Title, Text } = Typography;
const { Option } = Select;
const { Search } = Input;

const masonryBreakPoints = [600, 960, 1280, 1920];

const styles = {
    tab: {
        paddingLeft: 4,
        paddingRight: 4,
    },
    filters: {
        padding: '0px 40px',
        marginBottom: 10,
        background: 'linear-gradient(#fff, #fafafa)',
        boxShadow: 'rgba(0, 0, 0, 0.05) 0px 4px 6px 0px',
        borderTop: '1px solid #e8e8e8',
    },
};

function Live(props) {
    const { authState } = props;
    const { breakpoint, maxWidth, minWidth } = useBreakpoint(defaultBreakpoints);

    function handleSubmit(values, actions) {
        actions.setSubmitting(false);
        actions.resetForm();
        console.log('Received values of form: ', values);
    }

    function filterStatus(status) {}

    return (
        <React.Fragment>
            <SectionTitle title={props.title} height={breakpoint === 'mobile' ? 80 : 100} />
            {/* <SectionContent style={styles.filters}>
				<Row gutter={16}>
					<Col xs={24}>
						<Form layout="inline" onSubmit={handleSubmit}>
							<Form.Item label="Search">
								<Search
									placeholder="input search text"
									size="default"
									onSearch={(value) => console.log(value)}
								/>
							</Form.Item>
							<Form.Item label="Type">
								<Select name="type" defaultValue="All" size="default" style={{ width: 120 }}>
									<Option value="ALL">All</Option>
									<Option value="EIGHT_BALL">8-Ball</Option>
									<Option value="NINE_BALL">9-Ball</Option>
									<Option value="TEN_BALL">10-Ball</Option>
								</Select>
							</Form.Item>
							<Form.Item label="Status">
								<Select
									name="status"
									defaultValue="All"
									size="default"
									style={{ width: 120 }}
									onChange={filterStatus}
								>
									<Option value="ALL">All</Option>
									<Option value="IN_PROGRESS">In Progress</Option>
									<Option value="UPCOMING">Upcoming</Option>
									<Option value="COMPLETED">Completed</Option>
								</Select>
							</Form.Item>
						</Form>
					</Col>
				</Row>
			</SectionContent> */}
            <SectionContent>
                <Query query={GET_ALL_LIVE_STREAMS} fetchPolicy="cache-and-network" notifyOnNetworkStatusChange={true}>
                    {({ loading, error, data, networkStatus }) => {
                        if (loading) return <CircularLoader />;

                        let allStreams = data && data.live && data.live.concat(data && data.live && data.live_matches);

                        return (
                            <Row gutter={16} justify="center" type="flex">
                                <Col xs={24} style={{ paddingTop: 15, paddingBottom: 15 }}>
                                    <div className="masonry-container">
                                        <Masonry breakPoints={masonryBreakPoints}>
                                            {allStreams.map((item, index) => {
                                                let type;
                                                let channel;
                                                let typeDisplay;

                                                switch (item.__typename) {
                                                    case 'live':
                                                        channel = item.channel;
                                                        type = item.type;
                                                        typeDisplay = item.type;
                                                        break;

                                                    case 'live_matches':
                                                        channel = item.data.opponent_1.channel;
                                                        type = item.data.opponent_1.stream_type;
                                                        typeDisplay = `${item.data.opponent_1.stream_type}, ${item.data.opponent_2.stream_type}`;
                                                        break;
                                                }

                                                return (
                                                    <Card
                                                        hoverable
                                                        loading={loading}
                                                        key={index}
                                                        style={{
                                                            width: '100%',
                                                            minWidth: 300,
                                                            // maxWidth: 600,
                                                            borderRadius: '8px',

                                                            // boxShadow: '0 2px 5px 0px rgba(0, 0, 0, 0.1)',
                                                            border: 'none',
                                                            boxShadow: '0 8px 20px 0 rgba(218,224,235,.6)',
                                                            height: '100%',
                                                            transition: 'all 150ms cubic-bezier(0.4, 0.0, 0.2, 1)',
                                                        }}
                                                        cover={<LiveThumbnail type={type} channel={channel} />}
                                                        onClick={() => {
                                                            let service;
                                                            switch (type) {
                                                                case 'TWITCH':
                                                                    service = 'twitch';
                                                                    break;
                                                                case 'YOUTUBE':
                                                                    service = 'youtube';
                                                                    break;
                                                            }

                                                            props.history.push(`/live/${service}/${item.channel}`);
                                                        }}
                                                    >
                                                        <Meta
                                                            // avatar={<LiveThumbnail />}
                                                            title={
                                                                <Title level={4} style={{ margin: 0 }}>
                                                                    {item.name}
                                                                </Title>
                                                            }
                                                            description={
                                                                <div
                                                                    style={{
                                                                        color: 'rgba(0, 0, 0, 0.85)',
                                                                    }}
                                                                >
                                                                    {typeDisplay}
                                                                </div>
                                                            }
                                                        />
                                                    </Card>
                                                );
                                            })}
                                        </Masonry>
                                    </div>
                                </Col>
                            </Row>
                        );
                    }}
                </Query>
            </SectionContent>
        </React.Fragment>
    );
}

export default React.memo(Live);
