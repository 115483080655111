import React, { useState, useEffect } from 'react';
import { message, List, Tabs, Row, Col, Button, Icon, Typography, Empty, Input } from 'antd';
import TableScoreCard from '../../screens/digitalpool/tournaments/TableScoreCard';
import { Query, Subscription } from '@apollo/client/react/components';
import { Link, withRouter } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { GET_ALL_TOURNAMENT_TABLES_SUBSCRIPTION } from './data/subscriptions';
import { UPDATE_TABLE_STATUS_MUTATION, DELETE_TOURNAMENT_MATCH } from './data/mutations';
import ZeroState from '../../components/ZeroState';
import CircularLoader from '../../components/CircularLoader';
import TableAssignment from './TableAssignment2';
import _ from 'lodash';

const styles = {
    container: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
};

const tabHeight = 45;

function ViewerTables(props) {
    const { tournament, isPublic, matches, bracketHeight, theme, onSidePanelOpen, onShowScoreModal, scrollToTable } = props;
    const [totalTables, setTotalTables] = useState();
    const [updateTableStatus, tableProps] = useMutation(UPDATE_TABLE_STATUS_MUTATION);
    const defaultTableSize = isPublic === true ? 'large' : 'small';

    return (
        <Subscription
            subscription={GET_ALL_TOURNAMENT_TABLES_SUBSCRIPTION}
            fetchPolicy="cache-and-network"
            notifyOnNetworkStatusChange={true}
            variables={{ tournament_id: tournament.id }}
            onSubscriptionData={(data, loading, error) => {
                if (!loading && !error) {
                    const { pool_tables } = data.subscriptionData.data;
                    setTotalTables(pool_tables.length);
                    // onTablesUpdated(pool_tables);
                }
            }}
        >
            {({ loading, error, data, refetch, fetchMore, subscribeToMore, networkStatus }) => {
                if (loading)
                    return (
                        <div style={styles.container}>
                            <CircularLoader />
                        </div>
                    );
                if (error) return <div style={styles.container}>Error: {error.message}</div>;
                if ((data && !data.pool_tables) || (data && data.pool_tables.length === 0)) {
                    return (
                        <Row
                            gutter={16}
                            justify="center"
                            type="flex"
                            style={{
                                margin: 0,
                                padding: 0,
                                overflowY: 'auto',
                                height: bracketHeight - tabHeight,
                                width: '100%',
                                alignItems: 'center',
                            }}
                        >
                            <ZeroState showImage message="No tables found." style={{ margin: 30 }} {...props}>
                                <Link to={`/tournament-builder/${tournament.slug}/tables`}>
                                    <Button>Manage Tables</Button>
                                </Link>
                            </ZeroState>
                        </Row>
                    );
                }

                function removeMatches(matches) {
                    const key = 'delete';
                    message.loading({ content: `Deleting ${matches.length} matches...`, key, duration: 0 });

                    let result = matches.reduce((accumulatorPromise, nextMatch) => {
                        return accumulatorPromise.then(() => {
                            return deleteMatch(nextMatch);
                        });
                    }, Promise.resolve());

                    result.then((e) => {
                        message.success({
                            content: `Updated player chip counts.`,
                            key,
                            duration: 2,
                        });
                        // console.log('PLAYER UPDATE COMPLETE', playersToUpdate);
                    });
                }

                function deleteMatch(match) {
                    return props.client
                        .mutate({
                            mutation: DELETE_TOURNAMENT_MATCH,
                            variables: {
                                id: match.id,
                                tournament_id: tournament.id,
                            },
                            notifyOnNetworkStatusChange: true,
                        })
                        .then((data) => {
                            console.log(data);
                            return data;
                        })
                        .catch((error) => {
                            console.error(error);
                        });
                }

                const tables = data.pool_tables;

                const assignedTables =
                    data &&
                    data.pool_tables
                        .filter((table) => {
                            return table.status !== 'IN_USE';
                        })
                        .sort((a, b) => {
                            return a.label.localeCompare(b.label, undefined, {
                                numeric: true,
                                sensitivity: 'base',
                            });
                        });

                const inUseTables =
                    data &&
                    data.pool_tables
                        .filter((table) => {
                            return table.status === 'IN_USE';
                        })
                        .sort((a, b) => {
                            return a.label.localeCompare(b.label, undefined, {
                                numeric: true,
                                sensitivity: 'base',
                            });
                        });

                const openTables =
                    data &&
                    data.pool_tables
                        .filter((table) => {
                            return table.status === 'OPEN';
                        })
                        .sort((a, b) => {
                            return a.label.localeCompare(b.label, undefined, {
                                numeric: true,
                                sensitivity: 'base',
                            });
                        });

                const notStartedMatches =
                    matches &&
                    matches.filter((match) => {
                        return match.status === 'NOT_STARTED';
                    });

                return (
                    <div style={{ paddingTop: 10, paddingLeft: 20, paddingRight: 20, paddingBottom: 10 }}>
                        <Row gutter={16} type="flex">
                            {tables &&
                                tables
                                    .sort((a, b) => {
                                        return a.label.localeCompare(b.label, undefined, {
                                            numeric: true,
                                            sensitivity: 'base',
                                        });
                                    })
                                    .map((table, index) => {
                                        const match = table.tournament_match_table && table.tournament_match_table[0];

                                        return (
                                            <React.Fragment key={index}>
                                                <Col key={index} xs={24} sm={24} md={24} lg={12} xl={8} xxl={6}>
                                                    {match && table.status === 'IN_USE' ? (
                                                        <TableScoreCard
                                                            {...props}
                                                            key={index}
                                                            label={table.label}
                                                            matchData={match}
                                                            table={table}
                                                            tournament={tournament}
                                                            hoverable={isPublic === true ? false : true}
                                                            status={table.status}
                                                            gameType={tournament.game_type}
                                                            title={match.match_number && `Match ${match.match_number}`}
                                                            tableSize={defaultTableSize}
                                                            onClick={() => {
                                                                console.log(match);
                                                                if (match && isPublic !== true) {
                                                                    onShowScoreModal(match);
                                                                }
                                                            }}
                                                            playerStyle={{ fontSize: 20, textShadow: '0 2px 2px rgba(0,0,0,0.50)' }}
                                                        />
                                                    ) : (
                                                        <TableAssignment
                                                            {...props}
                                                            label={table.label}
                                                            status={table.status}
                                                            displayText={table.status}
                                                            tournament={tournament}
                                                            hoverable={isPublic === true ? false : true}
                                                            title={table.status === 'OPEN' ? 'Waiting for match' : 'Table inactive'}
                                                            table={table}
                                                            tableSize={defaultTableSize}
                                                            showEditButton={false}
                                                            onClick={(tableSlug) => {
                                                                // setSelectedTableId(tableSlug);
                                                                if (isPublic !== true) {
                                                                    if (table.status === 'OPEN') {
                                                                        console.log('open tables', openTables);
                                                                        console.log('matches', notStartedMatches);
                                                                        if (notStartedMatches.length >= openTables.length) {
                                                                            const matchesToRemove = notStartedMatches.slice(
                                                                                openTables.length - 1,
                                                                                notStartedMatches.length
                                                                            );
                                                                            removeMatches(matchesToRemove);
                                                                        }

                                                                        updateTableStatus({
                                                                            variables: {
                                                                                id: table.id,
                                                                                changes: {
                                                                                    status: 'CLOSED',
                                                                                },
                                                                            },
                                                                        });
                                                                    } else {
                                                                        updateTableStatus({
                                                                            variables: {
                                                                                id: table.id,
                                                                                changes: {
                                                                                    status: 'OPEN',
                                                                                },
                                                                            },
                                                                        });
                                                                    }
                                                                }

                                                                console.log(table);
                                                            }}
                                                            playerStyle={{ fontSize: 20, textShadow: '0 2px 2px rgba(0,0,0,0.50)' }}
                                                        />
                                                    )}
                                                </Col>
                                            </React.Fragment>
                                        );
                                    })}
                        </Row>
                    </div>
                );
            }}
        </Subscription>
    );
}

export default withRouter(ViewerTables);
