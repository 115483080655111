import React, { useState } from 'react';
import { message, Icon, Row, Col, Typography, Button, Input, Tabs, Empty } from 'antd';
import SectionTitle from '../../../components/SectionTitle';
import SectionContent from '../../../components/SectionContent';
import SectionWrapper from '../../../components/SectionWrapper';
import SectionFooter from '../../../components/SectionFooter';
import ZeroState from '../../../components/ZeroState';
import Card, { Meta } from '../../../components/Card';
import CircularLoader from '../../../components/CircularLoader';
import blueBg from '../../../assets/img/blue-bg.png';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { GET_LEAGUE_DIVISION_MATCHES_QUERY } from '../data/queries';
import { DELETE_LEAGUE_MATCH_MUTATION } from '../data/mutations';
import { Query } from '@apollo/client/react/components';
import MatchesTable from '../MatchesTable';
import AddMatchModal from '../AddMatchModal';
import _ from 'lodash';

const { Title, Text } = Typography;

const styles = {
    headerImg: {
        height: 120,
        overflow: 'hidden',
        borderRadius: 0,
        background: `linear-gradient(rgba(61, 185, 248, 0.8), rgba(24, 144, 255, 0.1)), url(${blueBg}) 0px 0px no-repeat`,
        // backgroundAttachment: 'fixed',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100%',
        display: 'flex',
        alignItems: 'center',
        paddingLeft: 40,
        paddingRight: 40,
        // backgroundPosition: '0px -40px'
    },
};

export default function LMDivisionMatches(props) {
    const { league, division, authState } = props;
    const [selectedKeys, setSelectedKeys] = useState([]);
    const [selectedMatchId, setSelectedMatchId] = useState();
    const [modalVisible, setModalVisible] = useState(false);
    const [deleteLeagueMatch] = useMutation(DELETE_LEAGUE_MATCH_MUTATION);

    function handleSelected(selected) {
        console.log(selected);
        setSelectedKeys(selected);
    }

    function handleEditSelected(selected) {
        // console.log(selected);
        setSelectedMatchId(selected);
        setModalVisible(true);
    }

    const hasSelected = selectedKeys && selectedKeys.length > 0;

    function handleModalOk(e) {
        console.log(e);
        setModalVisible(false);
    }

    function handleModalCancel(e) {
        setModalVisible(false);
    }

    function handleAddMatch() {
        setModalVisible(true);
        setSelectedMatchId(null);
    }
    return (
        <SectionWrapper>
            {/* <SectionTitle
				title="Matches"
				titleStyle={{
					color: '#fff',
					margin: 0,
					textTransform: 'uppercase'
				}}
				height={160}
				theme="leagueManager"
			/> */}
            <SectionContent padding="20px 40px">
                <Row gutter={16} justify="center" type="flex">
                    <Col xs={24}>
                        <Card
                            cover={
                                <div
                                    style={{
                                        paddingTop: 16,
                                        paddingBottom: 16,
                                        paddingLeft: 24,
                                        paddingRight: 24,
                                        borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <Title level={4} style={{ margin: 0, padding: 0, fontSize: 18 }}>
                                        Division Matches ({division.league_matches_aggregate.aggregate.count})
                                    </Title>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                        }}
                                    >
                                        <Button type="primary" onClick={handleAddMatch}>
                                            + Add Match
                                        </Button>
                                        <div style={{ marginLeft: 10 }}>
                                            <Button type="danger" disabled={!hasSelected}>
                                                Remove
                                            </Button>
                                            <span style={{ marginLeft: 8 }}>{hasSelected ? `Selected ${selectedKeys.length} items` : ''}</span>
                                        </div>
                                    </div>
                                </div>
                            }
                            bodyStyle={{ padding: 0 }}
                        >
                            <Query
                                query={GET_LEAGUE_DIVISION_MATCHES_QUERY}
                                fetchPolicy="cache-and-network"
                                notifyOnNetworkStatusChange
                                awaitRefetchQueries
                                variables={{
                                    league_id: league.id,
                                    division_id: division.id,
                                }}
                                onCompleted={(data) => {
                                    // console.log(data);
                                }}
                            >
                                {({ loading, error, data }) => {
                                    if (loading) return <CircularLoader />;
                                    if (error) return <div style={styles.container}>Error: {error.message}</div>;
                                    if ((data && !data.league_matches) || (data && data.league_matches.length === 0)) {
                                        return <ZeroState showImage message="No matches have been created yet." style={{ margin: 30 }} {...props} />;
                                    }

                                    return (
                                        <div className="table_nowrap">
                                            <MatchesTable
                                                {...props}
                                                onSelected={handleSelected}
                                                onEditSelected={handleEditSelected}
                                                onDelete={(record) => {
                                                    deleteLeagueMatch({
                                                        variables: {
                                                            league_id: league.id,
                                                            id: record.id,
                                                        },
                                                        notifyOnNetworkStatusChange: true,
                                                        awaitRefetchQueries: true,
                                                        refetchQueries: [
                                                            {
                                                                query: GET_LEAGUE_DIVISION_MATCHES_QUERY,
                                                                variables: {
                                                                    league_id: league.id,
                                                                    division_id: division.id,
                                                                },
                                                            },
                                                        ],
                                                    })
                                                        .then((data) => {
                                                            console.log(data);
                                                            message.success('Match deleted');
                                                        })
                                                        .catch((error) => {
                                                            console.log(error);
                                                            message.info('There was an error', error);
                                                        });
                                                }}
                                                matches={
                                                    data.league_matches &&
                                                    data.league_matches.map((item, index) => {
                                                        const players = _.compact(
                                                            item.league_match_players &&
                                                                item.league_match_players.length > 0 &&
                                                                item.league_match_players.map((player) => player.name)
                                                        );
                                                        return {
                                                            ...item,
                                                            index: index + 1,
                                                            division: item.division,
                                                            players: players && players.length > 0 ? players.join(', ') : null,
                                                            key: index,
                                                        };
                                                    })
                                                }
                                            />
                                        </div>
                                    );
                                }}
                            </Query>
                        </Card>
                    </Col>
                </Row>
            </SectionContent>
            <SectionFooter {...props} />

            <AddMatchModal
                {...props}
                selectedMatchId={selectedMatchId}
                userID={authState.user.id}
                leagueID={league.id}
                divisionID={division.id}
                modalVisible={modalVisible}
                onModalOk={handleModalOk}
                onModalCancel={handleModalCancel}
            />
        </SectionWrapper>
    );
}
