import React, { useState, useEffect, useRef } from 'react';
import { Avatar, Typography, Alert, Row, Col } from 'antd';
import { getGameType, displayEnumType, payoutStructures, calculateUSAPLRace } from '../../utils';
import { tournamentPreviewStats } from '../../utils/tournamentUtils';
import CircularLoader from '../../components/CircularLoader';
import { Query } from '@apollo/client/react/components';
import { GET_TOURNAMENT_PLAYERS_QUERY } from './data/queries';
import numeral from 'numeral';
import moment from 'moment-timezone';
import momentDurationFormatSetup from 'moment-duration-format';
momentDurationFormatSetup(moment);

const { Text, Title } = Typography;

const styles = {};

export default function TBCreateReviewTournament(props) {
    const { tournament } = props;
    var zone_name = moment.tz.guess();
    var timezone = moment.tz(zone_name).zoneAbbr();
    // const [players, setPlayers] = useState()

    return (
        <Query
            query={GET_TOURNAMENT_PLAYERS_QUERY}
            fetchPolicy="cache-and-network"
            notifyOnNetworkStatusChange={true}
            variables={{ tournament_id: tournament.id }}
            onCompleted={(data) => {
                // console.log(data);
                // const players = data.tournament_players;
                // setPlayers(players)
                // console.log(players);
                // if (
                // 	!canAccess(authState) &&
                // 	(tournament.organizer_id !== authState.user.id || tournament.director_id !== authState.user.id)
                // ) {
                // 	message.error('You do not have permission to access this tournament');
                // 	props.history.push('/tournament-builder');
                // }
            }}
        >
            {({ loading, error, data }) => {
                if (loading)
                    return (
                        <div style={styles.container}>
                            <CircularLoader />
                        </div>
                    );
                if (error) return <div style={styles.container}>Error: {error.message}</div>;

                const tournamentPlayers = data.tournament_players;

                console.log(tournament);
                console.log(tournamentPlayers);
                let tournamentStats;

                if (tournamentPlayers && tournamentPlayers.length) {
                    const poolTables =
                        (tournament.pool_tables_aggregate && tournament.pool_tables_aggregate.aggregate && tournament.pool_tables_aggregate.aggregate.count) ||
                        1;
                    tournamentStats = tournamentPreviewStats(
                        tournamentPlayers.length,
                        tournament.winners_race_to,
                        tournament.losers_race_to,
                        poolTables,
                        tournament.game_type
                    );
                    console.log(tournamentStats);
                }

                const payouts = payoutStructures({
                    ...tournament,
                    max_players: tournamentPlayers && tournamentPlayers.length,
                });

                return (
                    <div>
                        <div>
                            <Alert
                                message="Please review and verify your settings before finalizing"
                                description={`Ensure your data is correct and evaluate tournament stats and time projections. Once you are satisfied and save the tournament you will be taken to the tournament bracket.`}
                                type="info"
                                closable
                                showIcon
                            />
                        </div>

                        <div
                            style={{
                                marginTop: 20,
                                marginBottom: 20,
                            }}
                        >
                            <Title level={4} style={{ marginBottom: 20 }}>
                                Review Tournament Configuration
                            </Title>
                            <Row gutter={[16, 16]}>
                                <Col xs={24} sm={12}>
                                    <Title level={4} style={{ fontSize: 18, marginBottom: 20 }}>
                                        Settings
                                    </Title>
                                    <table width="100%" className="info-table">
                                        <tbody>
                                            <tr>
                                                <td>Name</td>
                                                <td>{tournament.name}</td>
                                            </tr>
                                            <tr>
                                                <td>Description</td>
                                                <td className="display-linebreak">{tournament.description || 'N/A'}</td>
                                            </tr>
                                            <tr>
                                                <td>Start date</td>
                                                <td>
                                                    {moment(tournament.start_date).format('llll')} ({timezone})
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>End date</td>
                                                <td>
                                                    {moment(tournament.end_date).format('llll')} ({timezone})
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Venue</td>
                                                <td>{tournament.venue.name}</td>
                                            </tr>
                                            <tr>
                                                <td>Player type</td>
                                                <td>{tournament.player_type ? displayEnumType(tournament.player_type) : 'N/A'}</td>
                                            </tr>
                                            <tr>
                                                <td>Tournament Type</td>
                                                <td>{tournament.tournament_type ? displayEnumType(tournament.tournament_type) : 'N/A'}</td>
                                            </tr>
                                            <tr>
                                                <td>Game type</td>
                                                <td>
                                                    <Avatar size={25} src={getGameType(tournament.game_type)} />
                                                    <Text style={{ marginLeft: 5 }}>
                                                        {tournament.game_type ? displayEnumType(tournament.game_type) : 'N/A'}
                                                    </Text>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Winners race to</td>
                                                <td>{tournament.winners_race_to}</td>
                                            </tr>
                                            <tr>
                                                <td>Losers race to</td>
                                                <td>{tournament.losers_race_to}</td>
                                            </tr>

                                            <tr>
                                                <td>Bracket size</td>
                                                <td>{tournament.max_players}</td>
                                            </tr>
                                            <tr>
                                                <td>Players</td>
                                                <td>{tournamentPlayers && tournamentPlayers.length} Players</td>
                                            </tr>
                                            <tr>
                                                <td>Tables</td>
                                                <td>
                                                    {tournament && tournament.pool_tables_aggregate && tournament.pool_tables_aggregate.aggregate.count} Tables
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Rating system</td>
                                                <td>{tournament.rating_system ? displayEnumType(tournament.rating_system) : 'N/A'}</td>
                                            </tr>
                                            <tr>
                                                <td>Break format</td>
                                                <td>{tournament.break_format ? displayEnumType(tournament.break_format) : 'N/A'}</td>
                                            </tr>
                                            <tr>
                                                <td>Rules</td>
                                                <td>{tournament.rule_format.toUpperCase()}</td>
                                            </tr>
                                            <tr>
                                                <td>Entry fee</td>
                                                <td>{tournament.entry_fee ? `$${tournament.entry_fee}` : 'N/A'}</td>
                                            </tr>
                                            <tr>
                                                <td>Added money</td>
                                                <td>{tournament.added_money ? `$${tournament.added_money}` : 'N/A'}</td>
                                            </tr>
                                            <tr>
                                                <td>Payout type</td>
                                                <td>{tournament.payout_type ? `${tournament.payout_type} place` : 'N/A'}</td>
                                            </tr>
                                            <tr>
                                                <td>Draw type</td>
                                                <td>{tournament.draw_type ? displayEnumType(tournament.draw_type) : 'N/A'}</td>
                                            </tr>
                                            <tr>
                                                <td>Signup cutoff time</td>
                                                <td>{tournament.signup_cutoff_time ? displayEnumType(tournament.signup_cutoff_time) : 'N/A'}</td>
                                            </tr>
                                            <tr>
                                                <td>Handicap</td>
                                                <td>{tournament.handicapFormat || 'N/A'}</td>
                                            </tr>
                                            {tournament.director && (
                                                <tr>
                                                    <td>Tournament director</td>
                                                    <td>
                                                        {tournament.director.first_name} {tournament.director.last_name} <br /> {tournament.director.email}
                                                    </td>
                                                </tr>
                                            )}

                                            <tr>
                                                <td>Allow players to RSVP</td>
                                                <td>
                                                    <Text>{tournament.rsvp_allowed ? 'Yes' : 'No'}</Text>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>Use text messaging</td>
                                                <td>
                                                    <Text>{tournament.use_text_messaging ? 'Yes' : 'No'}</Text>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>True double elimination</td>
                                                <td>
                                                    <Text>{tournament.consolidation_finals ? 'Yes' : 'No'}</Text>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>Access</td>
                                                <td>
                                                    <Text>{tournament.is_public ? 'Public' : 'Private'}</Text>
                                                </td>
                                            </tr>
                                            {tournament.flyer && (
                                                <tr>
                                                    <td>Flyer</td>
                                                    <td>
                                                        <img
                                                            src={tournament.flyer}
                                                            width="100%"
                                                            alt="Flyer"
                                                            style={{
                                                                maxWidth: 300,
                                                                borderRadius: 10,
                                                                overflow: 'hidden',
                                                            }}
                                                        />
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                    <Title level={4} style={{ fontSize: 18, marginTop: 20, marginBottom: 20 }}>
                                        Payouts
                                    </Title>
                                    {payouts && payouts.chosenPayouts && payouts.chosenPayouts.payouts ? (
                                        <div style={{ marginTop: 10 }}>
                                            <div style={{ marginBottom: 10 }}>
                                                <Text style={{ fontSize: 15, fontWeight: 600 }}>Payout Preview</Text>
                                            </div>
                                            <div style={{ marginBottom: 10 }}>
                                                <Text>
                                                    Total purse: <b>{numeral(payouts.totalPurse).format('$0,0.00')}</b> based on a <b>${payouts.entryFee}</b>{' '}
                                                    entry fee with <b>${payouts.addedMoney}</b> added money and <b>{payouts.totalPlayers}</b> players.
                                                </Text>
                                            </div>
                                            <table
                                                width="100%"
                                                className="info-table"
                                                style={{
                                                    marginTop: 10,
                                                    marginBottom: 0,
                                                    marginLeft: 0,
                                                    marginRight: 0,
                                                }}
                                            >
                                                <tbody>
                                                    <tr>
                                                        <td>Place</td>
                                                        <td>Payout</td>
                                                        <td>Percent</td>
                                                    </tr>
                                                    {payouts.chosenPayouts.payouts.map((payouts, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td>{payouts.place}</td>
                                                                <td>
                                                                    <Text>{numeral(payouts.payout).format('$0,0')}</Text>
                                                                </td>
                                                                <td>
                                                                    <Text>{payouts.percent}%</Text>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    ) : (
                                        <div style={{ marginTop: 10 }}>
                                            <table
                                                width="100%"
                                                className="info-table"
                                                style={{
                                                    marginTop: 10,
                                                    marginBottom: 0,
                                                    marginLeft: 0,
                                                    marginRight: 0,
                                                }}
                                            >
                                                <tbody>
                                                    <tr>
                                                        <td>Type</td>
                                                        <td>Custom</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    )}
                                </Col>
                                <Col xs={24} sm={12}>
                                    <Title level={4} style={{ fontSize: 18, marginBottom: 20 }}>
                                        Stats & Time Projections
                                    </Title>
                                    {tournamentStats ? (
                                        <table width="100%" className="info-table">
                                            <tbody>
                                                {Object.keys(tournamentStats).map((key, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>{displayEnumType(key)}</td>
                                                            <td>{tournamentStats[key]}</td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    ) : (
                                        <div>You must add at least 1 player to see this data.</div>
                                    )}
                                </Col>
                            </Row>
                        </div>
                    </div>
                );
            }}
        </Query>
    );
}
