import gql from 'graphql-tag';

export const GET_TOURNAMENT_BY_ID = gql`
    query tournaments($id: Int!) {
        tournaments(where: { id: { _eq: $id } }) {
            id
            name
            slug
            status
            flyer
            game_type
            consolidation_finals
            organizer_id
            director_id
            tournament_payouts(where: { money: { _is_null: false } }) {
                money
                place
                calcutta
            }
        }
    }
`;

export const GET_TOUR_LIST = gql`
    query tours {
        tours(where: { is_public: { _eq: true } }, order_by: { updated_at: desc }) {
            name
            id
            slug
            location
            description
            created_at
            updated_at
        }
    }
`;

export const GET_TOUR_STANDINGS_BY_SLUG = gql`
    query tours($slug: String!) {
        tours(where: { slug: { _eq: $slug } }) {
            id
            name
            tour_tournaments {
                tournament {
                    id
                    name
                    tournament_players(order_by: { place: asc }) {
                        id
                        name
                        skill_level
                        country
                        place
                        phone_number
                    }
                    tournament_points {
                        id
                        place
                        points
                    }
                }
            }
        }
    }
`;
