import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { message, Col } from 'antd';
import Card from '../../components/Card';
import apiEndPoint from '../../utils/apiEndpoint';
import axios from 'axios';

export default function AdZoneTemplate(props) {
    const { device, section, theme } = props;
    const [ad, setAd] = useState();

    useEffect(() => {
        let isSubscribed = true;
        axios
            .post(`${apiEndPoint}adserve`, {
                device: device,
                section: section,
            })
            .then((res) => {
                const placement = res && res.data && res.data.placements && res.data.placements.placement_1;
                // console.log(res.data);
                // console.log(placement);
                if (isSubscribed) {
                    setAd(placement);
                }
            })
            .catch((error) => {
                console.log(error);
                message.error(`There was an error: ${JSON.stringify(error)}`);
                return error;
            });

        return () => (isSubscribed = false);
    }, [device]);

    // function getAd() {
    //     return axios
    //         .get('https://servedbyadbutler.com/adserve/;ID=183009;size=728x90;setID=531733;type=json')
    //         .then((res) => {
    //             const placement = res && res.data && res.data.placements && res.data.placements.placement_1;
    //             console.log(res.data);
    //             console.log(placement);
    //             setAd(placement);
    //         })
    //         .catch((error) => {
    //             console.log(error);
    //             message.error(`There was an error: ${JSON.stringify(error)}`);
    //             return error;
    //         });
    // }

    // function getAd() {
    //     let body;
    //     if (size === '300x250') {
    //         body = {
    //             ID: 183009,
    //             size: '728x90',
    //             setID: 531733,
    //             type: 'json',
    //             // pid : "123456",
    //             // place : 0
    //         };
    //     } else if (size === '728x90') {
    //         body = {
    //             ID: 183009,
    //             size: '728x90',
    //             setID: 531733,
    //             type: 'json',
    //             // pid : "123456",
    //             // place : 0
    //         };
    //     } else if (size === '970x250') {
    //         body = {
    //             ID: 183009,
    //             size: '728x90',
    //             setID: 531733,
    //             type: 'json',
    //             // pid : "123456",
    //             // place : 0
    //         };
    //     }

    //     return axios
    //         .post('https://servedbyadbutler.com/adserve', body)
    //         .then((res) => {
    //             const placement = res && res.data && res.data.placements && res.data.placements.placement_1;
    //             console.log(res.data);
    //             console.log(placement);
    //             setAd(placement);
    //         })
    //         .catch((error) => {
    //             console.log(error);
    //             message.error(`There was an error: ${JSON.stringify(error)}`);
    //             return error;
    //         });
    // }

    return (
        <React.Fragment>
            {ad && (
                <div style={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'flex-end', justifyContent: 'center' }}>
                    <Card
                        bodyStyle={{ padding: 0, backgroundColor: theme && theme.name === 'light' ? '#fff' : '#000' }}
                        style={{ border: 'none', width: '100%', boxShadow: 'none' }}
                    >
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <a href={ad.redirect_url} target={ad.target || '_blank'} rel="noopener noreferrer">
                                <img src={ad.image_url} height={ad.height} width="100%" alt={ad.html_alt_text} style={{ maxWidth: '100%' }} />
                            </a>
                        </div>
                    </Card>
                    {section !== 'bracket' && (
                        <div>
                            <Link
                                to="/advertising"
                                style={{
                                    backgroundColor: theme && theme.name === 'light' ? '#fff' : '#000',
                                    paddingTop: 6,
                                    paddingBottom: 6,
                                    paddingLeft: 10,
                                    paddingRight: 10,
                                }}
                            >
                                Advertise here
                            </Link>
                        </div>
                    )}
                </div>
            )}
        </React.Fragment>
    );
}
