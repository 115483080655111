import React, { useState, useRef } from 'react';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import { displayEnumType } from '../../../utils';
import moment from 'moment-timezone';
import momentDurationFormatSetup from 'moment-duration-format';
momentDurationFormatSetup(moment);

Highcharts.setOptions({
    global: {
        useUTC: false,
    },
    lang: {
        printChart: 'Aaaa',
        thousandsSep: ',',
    },
});

export default function LineChart(props) {
    const { metric, metric_lowercase, data, height, xAxisGames, theme } = props;
    const chartRef = useRef();

    function afterRender(chart) {
        var series = chart.series[0],
            points = series.points,
            pLen = points.length,
            // i = 0,
            lastIndex = pLen - 1,
            minIndex = series.processedYData.indexOf(series.dataMin),
            maxIndex = series.processedYData.indexOf(series.dataMax);

        if (points[minIndex] && points[minIndex].options) {
            points[minIndex].options.showLabel = true;
        }
        if (points[maxIndex] && points[maxIndex].options) {
            points[maxIndex].options.showLabel = true;
        }
        if (points[lastIndex] && points[lastIndex].options) {
            points[lastIndex].options.showLabel = true;
        }

        series.isDirty = true;
        chart.redraw();
    }

    const options = {
        colors: ['#39F', 'rgba(255, 204, 0, 1)', '#44475b'],
        chart: {
            height: height || 250,
            type: 'line',
            animation: false,
            backgroundColor: 'transparent',
        },
        legend: {
            enabled: true,
            align: 'center',
            // symbolWidth: 12,
            // symbolRadius: 6
            itemStyle: {
                color: theme && theme.name === 'dark' ? '#fff' : '#666666',
            },
            itemHoverStyle: {
                color: theme && theme.name === 'dark' ? '#fff' : '#666666',
            },
        },
        title: {
            text: displayEnumType(metric),
            style: {
                color: theme && theme.name === 'dark' ? '#fff' : '#666666',
            },
        },

        yAxis: {
            title: {
                text: null,
            },
            tickInterval: 5,
            lineColor: theme && theme.name === 'dark' ? 'rgba(255,255,255,0.2)' : '#39F',
            lineWidth: 0,
            gridLineColor: theme && theme.name === 'dark' ? 'rgba(255,255,255,0.2)' : '#D8D8D8',
            gridLineDashedStyle: 'solid',
            gridLineWidth: 1,
            labels: {
                style: {
                    fontSize: 10,
                    color: theme && theme.name === 'dark' ? '#fff' : '#666666',
                },
            },
        },

        xAxis: {
            ordinal: true,
            type: xAxisGames ? 'number' : 'datetime',

            // dateTimeLabelFormats: {
            // 	// don't display the dummy year
            // 	month: '%e. %b',
            // 	year: '%b'
            // },
            labels: {
                fontSize: 10,
                color: theme && theme.name === 'dark' ? '#fff' : '#666666',
                distance: 1,
                formatter: function () {
                    if (xAxisGames) {
                        return this.value;
                    } else {
                        var dateObj = new Date(this.value);
                        // return moment(dateObj).format('MMM D, h:mm a');
                        return moment(dateObj).format('MMM D, h:mm a');
                    }
                },
            },
            title: {
                text: null,
            },
            crosshair: true,
            gridLineWidth: 0,
            lineColor: theme && theme.name === 'dark' ? 'rgba(255,255,255,0.2)' : '#39F',
            lineWidth: 1,
            gridLineColor: theme && theme.name === 'dark' ? 'rgba(255,255,255,0.2)' : '#D8D8D8',
            gridLineDashedStyle: 'solid',
        },

        credits: { enabled: false },
        tooltip: {
            headerFormat: '<b>{series.name}</b><br>',
            pointFormat: '{point.x:%b %e %H:%M}: <b>{point.y:1f}</b>',
            crosshairs: true,
            shared: true,
        },

        plotOptions: {
            series: {
                connectNulls: true, // by default
                lineWidth: 1,
                animation: false,

                marker: {
                    enabled: true,
                    // symbol: 'circle',
                    // radius: 3,
                    radius: 3,
                    states: {
                        select: {
                            fillColor: '#000',
                            lineWidth: 1,
                            radius: 3,
                        },
                        hover: {
                            radius: 4,
                            lineWidth: 1,
                        },
                    },
                },

                dataLabels: {
                    enabled: true,
                    formatter: function () {
                        if (this.point.options.showLabel) {
                            return this.y;
                        }
                        return null;
                    },
                    style: {
                        fontSize: 10,
                        color: theme && theme.name === 'dark' ? '#fff' : '#666666',
                    },
                },

                crosshairs: true,
                cursor: 'pointer',
            },
        },

        series: data.map((stats, index) => {
            const series = stats.series
                .filter((item) => {
                    return item.metric === `${stats.stat_prefix}_${metric_lowercase}`;
                })
                .map((item) => {
                    if (xAxisGames) {
                        return [item.game_number, item.value];
                    } else {
                        return [moment(item.time).valueOf(), item.value];
                    }
                })
                .reverse();

            return {
                name: `${stats.name}`,
                data: series,
            };
        }),

        responsive: {
            rules: [
                {
                    condition: {
                        maxWidth: 500,
                    },
                    chartOptions: {
                        legend: {
                            layout: 'horizontal',
                            align: 'center',
                            verticalAlign: 'bottom',
                        },
                    },
                },
            ],
        },
    };

    return <HighchartsReact highcharts={Highcharts} options={options} ref={chartRef} callback={afterRender} />;
}
