import React from 'react';
import { Table, Divider } from 'antd';
import { Link, Route, withRouter } from 'react-router-dom';
const slugify = require('slugify');

const columns = [
	{
		title: 'Name',
		dataIndex: 'name',
		filters: [
			{
				text: 'Platinum Division',
				value: 'platinum'
			},
			{
				text: 'Gold Division',
				value: 'gold'
			},
			{
				text: 'Silver Division',
				value: 'silver'
			},
			{
				text: 'Bronze Division',
				value: 'bronze'
			},
			{
				text: 'Trophy Division',
				value: 'trophy'
			}
		],
		// specify the condition of filtering result
		// here is that finding the name started with `value`
		onFilter: (value, record) => record.name.indexOf(value) === 0,
		sorter: (a, b) => a.name.length - b.name.length,
		sortDirections: [ 'descend' ]
	},
	{
		title: 'Description',
		dataIndex: 'description',
		defaultSortOrder: 'descend',
		sorter: (a, b) => a.description - b.description
	},
	{
		title: 'Type',
		dataIndex: 'type',
		defaultSortOrder: 'descend',
		sorter: (a, b) => a.description - b.description
	},
	{
		title: 'Location',
		dataIndex: 'location',
		sorter: (a, b) => a.location.length - b.location.length,
		sortDirections: [ 'descend', 'ascend' ]
	},
	{
		title: 'Operator',
		dataIndex: 'operator',
		defaultSortOrder: 'descend',
		sorter: (a, b) => a.operator - b.operatpr
	},
	{
		title: 'Action',
		key: 'action',
		render: (text, record) => (
			<span>
				<a href="#">Join</a>
				{/* <a href="#">Edit</a>
				<Divider type="vertical" />
				<a href="#">Delete</a> */}
			</span>
		)
	}
];

const data = [
	{
		key: '1',
		name: 'Platinum Division',
		slug: 'platinum',
		description: 'Possimus voluptate et quam aut.',
		type: '9-Ball Teams',
		location: 'New York',
		operator: 'John Doe'
	},
	{
		key: '2',
		name: 'Gold Division',
		slug: 'gold',
		description: 'Possimus voluptate et quam aut.',
		type: '9-Ball Teams',
		location: 'New York',
		operator: 'John Doe'
	},
	{
		key: '3',
		name: 'Silver Division',
		slug: 'silver',
		description: 'Possimus voluptate et quam aut.',
		type: '9-Ball Teams',
		location: 'New York',
		operator: 'John Doe'
	},
	{
		key: '4',
		name: 'Bronze Division',
		slug: 'bronze',
		description: 'Possimus voluptate et quam aut.',
		type: '9-Ball Teams',
		location: 'New York',
		operator: 'John Doe'
	},
	{
		key: '5',
		name: 'Trophy Division (no payouts)',
		slug: 'trophy',
		description: 'Possimus voluptate et quam aut.',
		type: '9-Ball Teams',
		location: 'New York',
		operator: 'John Doe'
	}
];

function onChange(pagination, filters, sorter) {
	console.log('params', pagination, filters, sorter);
}

function DivisionTable(props) {
	return (
		<Table
			onRow={(record, rowIndex) => {
				return {
					onClick: (event) => {
						console.log(record, rowIndex);
						// const slug = slugify(record.name, {
						// 	replacement: '-',
						// 	remove: /[*+~.,()'"!:/@]/g,
						// 	lower: true
						// });
						props.history.push({
							pathname: `/dpl/divisions/${record.slug}`,
							// search: '?query=abc',
							state: { data: record }
						});
					}, // click row
					onDoubleClick: (event) => {}, // double click row
					onContextMenu: (event) => {}, // right button click row
					onMouseEnter: (event) => {}, // mouse enter row
					onMouseLeave: (event) => {} // mouse leave row
				};
			}}
			columns={columns}
			dataSource={data}
			onChange={onChange}
			// size="middle"
			// bordered
		/>
	);
}

export default withRouter(DivisionTable);
