import React from 'react';
import { withRouter } from 'react-router-dom';
import { Icon, Row, Col, PageHeader, Steps, Typography, Button, Avatar, Input, List, Tabs, Empty, Skeleton } from 'antd';
import Card from '../../../components/Card';
import SectionHeader from '../../../components/SectionHeader';
import SectionContent from '../../../components/SectionContent';
import LMDivisionScheduleCreateForm from './LMDivisionScheduleCreateForm';
import CircularLoader from '../../../components/CircularLoader';
import { getGameType, displayEnumType } from '../../../utils';
import { Query } from '@apollo/client/react/components';
import { GET_LEAGUE_DIVISION_SCHEDULE_BY_SLUG_QUERY } from '../data/queries';
import _ from 'lodash';

const { Step } = Steps;
const { Text, Title } = Typography;

const styles = {
    cardHeader: {
        paddingTop: 5,
        paddingBottom: 5,
        background: 'linear-gradient(0deg, rgb(241, 244, 246), rgb(255, 255, 255))',
        // boxShadow: 'rgba(0, 0, 0, 0.05) 0px 2px 8px 0px',
        borderRadius: '10px 10px 0px 0px',
    },
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
};

function LMDivisionScheduleCreate(props) {
    console.log(props);
    return (
        <React.Fragment>
            <SectionHeader
                title={props.match.params.schedule ? displayEnumType(props.match.params.schedule) : props.title}
                titleStyle={{
                    color: '#fff',
                    textTransform: 'uppercase',
                    margin: 0,
                    display: 'flex',
                }}
                subtitle={props.title}
                subtitleStyle={{
                    color: 'rgba(255,255,255,0.8)',
                    textTransform: 'uppercase',
                    fontSize: 12,
                    letterSpacing: 3,
                }}
                subtitlePrefix={true}
                theme="leagueManager"
                backButton={
                    <Button
                        onClick={() => props.history.push(`/league-manager/${props.league.slug}/divisions/${props.division.slug}/schedule`)}
                        type="default"
                        shape="circle"
                        icon="arrow-left"
                        size="large"
                        ghost
                        style={{ border: 'none' }}
                    />
                }
            />

            {/* {props.match.params.division ? (
				<PageHeader
					onBack={() =>
						props.history.push(
							`/league-manager/${props.data.slug}/divisions/${props.match.params.division}`
						)}
					title="EDIT DIVISION"
					style={{
						background: props.theme === 'dark' ? '#000' : 'linear-gradient(180deg, #fff, #fafafa)',
						// borderBottom: '1px solid #e0e3ea',
						paddingTop: 24,
						paddingLeft: 40,
						paddingRight: 24,
						paddingBottom: 24,
						display: 'flex',
						alignItems: 'center'
					}}
				/>
			) : (
				<PageHeader
					onBack={() => props.history.push(`/league-manager/${props.data.slug}/divisions`)}
					title="CREATE DIVISION"
					style={{
						background: props.theme === 'dark' ? '#000' : 'linear-gradient(180deg, #fff, #fafafa)',
						// borderBottom: '1px solid #e0e3ea',
						paddingTop: 24,
						paddingLeft: 40,
						paddingRight: 24,
						paddingBottom: 24,
						display: 'flex',
						alignItems: 'center'
					}}
				/>
			)} */}
            <SectionContent padding="20px 40px">
                <Row>
                    <Col>
                        <Card
                            cover={
                                <div
                                    style={{
                                        paddingTop: 16,
                                        paddingBottom: 16,
                                        paddingLeft: 24,
                                        paddingRight: 24,
                                        // background:'linear-gradient(rgb(239, 243, 245), rgb(255, 255, 255))',
                                        borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <Title level={4} style={{ margin: 0, padding: 0, fontSize: 18 }}>
                                        Schedule Information
                                    </Title>
                                </div>
                            }
                            headStyle={styles.cardHeader}
                        >
                            {props.match.params.schedule ? (
                                <Query
                                    query={GET_LEAGUE_DIVISION_SCHEDULE_BY_SLUG_QUERY}
                                    fetchPolicy="cache-and-network"
                                    notifyOnNetworkStatusChange={true}
                                    variables={{
                                        leagueId: props.league.id,
                                        divisionId: props.division.id,
                                        slug: props.match.params.schedule,
                                    }}
                                    onCompleted={async (data) => {
                                        console.log(data);
                                    }}
                                >
                                    {({ loading, error, data }) => {
                                        if (loading)
                                            return (
                                                <div style={styles.container}>
                                                    <CircularLoader />
                                                </div>
                                            );
                                        if (error) return <div style={styles.container}>Error: {error.message}</div>;
                                        if (!loading && data && !data.league_schedules) {
                                            return (
                                                <Empty
                                                    image="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original"
                                                    imageStyle={{
                                                        height: 100,
                                                    }}
                                                    description={<Text style={{ fontSize: 18 }}>You have not added any schedules yet.</Text>}
                                                    style={{ margin: 10 }}
                                                >
                                                    <Button>Create New Schedule</Button>
                                                </Empty>
                                            );
                                        }

                                        return (
                                            <React.Fragment>
                                                <LMDivisionScheduleCreateForm
                                                    {...props}
                                                    league={props.league}
                                                    division={props.division}
                                                    schedule={data.league_schedules[0]}
                                                />
                                            </React.Fragment>
                                        );
                                    }}
                                </Query>
                            ) : (
                                <LMDivisionScheduleCreateForm {...props} league={props.league} division={props.division} />
                            )}
                        </Card>
                        {/* <Card
							headStyle={styles.cardHeader}
							title={
								<Steps size="small" current={0}>
									<Step title="General Information" />
									<Step title="League Operator Info" />
									<Step title="Division Setup" />
								</Steps>
							}
						>
							<CreateLeagueForm {...props} />
						</Card> */}
                    </Col>
                </Row>
            </SectionContent>
        </React.Fragment>
    );
}

export default withRouter(LMDivisionScheduleCreate);
