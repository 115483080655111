import React, { useState } from 'react';
import { message, Icon, Row, Col, Typography, Progress, Table, Button, Input, Tabs, Empty, Radio } from 'antd';
import Card from '../../../components/Card';
import { Link } from 'react-router-dom';
import Flag from '../../../components/Flag';
import { isoCountries } from '../../../utils/isoCountryCodes';
import CircularLoader from '../../../components/CircularLoader';
import SectionTitle from '../../../components/SectionTitle';
import SectionHeader from '../../../components/SectionHeader';
import SectionContent from '../../../components/SectionContent';
import SectionWrapper from '../../../components/SectionWrapper';
import { getCountryShortCode } from '../../../utils';
import { Query, Subscription } from '@apollo/client/react/components';
import { GET_TOURNAMENT_PLAYER_EFFECTIVE_RATINGS } from './data/queries';
import _ from 'lodash';
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';
import PlayerModal from '../../digitalpool/tournaments/bracket/PlayerModal';

const { Text, Title } = Typography;
const { TabPane } = Tabs;

export default function DPTournamentPerformance(props) {
    const { tournament } = props;
    const styles = {};
    const [playerModalVisible, setPlayerModalVisible] = useState(false);
    const [selectedPlayerId, setSelectedPlayerId] = useState();

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',

            // width: 80
            // sorter: (a, b) => {
            // 	return a.position.localeCompare(b.position);
            // },
            sorter: (a, b) => {
                return (
                    a.name &&
                    a.name.localeCompare(b.name, undefined, {
                        numeric: true,
                        sensitivity: 'base',
                    })
                );
            },
            render: (text, record) => {
                return (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        {record.country && (
                            <Flag
                                name={getCountryShortCode(record.country) || 'Unknown'}
                                format="png"
                                pngSize={24}
                                shiny={false}
                                alt="Canada Flag"
                                className="flag"
                                basePath="/images/flags"
                            />
                        )}

                        <span style={{ marginLeft: record.country ? 10 : 0 }}>{text}</span>
                    </div>
                );
            },
        },
        // {
        // 	title: 'Fargo Rating',
        // 	dataIndex: 'fargo_rating',
        // 	key: 'fargo_rating',
        // 	sorter: (a, b) => {
        // 		return a.fargo_rating - b.fargo_rating;
        // 	},
        // 	render: (text, record) => {
        // 		return <Text>{text ? text : '-'}</Text>;
        // 	}
        // },
        {
            title: 'Skill Level',
            dataIndex: 'skill_level',
            key: 'skill_level',
            sorter: (a, b) => {
                return a.skill_level - b.skill_level;
            },
            render: (text, record) => {
                return <Text>{text ? text : '-'}</Text>;
            },
        },
        {
            title: 'Effective Rating',
            dataIndex: 'effective_rating',
            key: 'effective_rating',
            defaultSortOrder: 'descend',
            sorter: (a, b) => {
                return a.effective_rating - b.effective_rating;
            },
            render: (text, record) => {
                return (
                    <Text>
                        {text ? (
                            <React.Fragment>
                                {parseFloat(record.effective_rating) > parseFloat(record.skill_level) ? (
                                    <div style={{ color: 'green' }}>{text}</div>
                                ) : parseFloat(record.effective_rating) < parseFloat(record.skill_level) ? (
                                    <div style={{ color: 'red' }}>{text}</div>
                                ) : (
                                    <div>{text}</div>
                                )}
                            </React.Fragment>
                        ) : (
                            '-'
                        )}
                    </Text>
                );
            },
        },
        {
            title: 'To Par',
            dataIndex: 'to_par',
            key: 'to_par',
            sorter: (a, b) => {
                return a.to_par - b.to_par;
            },
            render: (text, record) => {
                return (
                    <Text style={{ whiteSpace: 'nowrap' }}>
                        {text ? (
                            <React.Fragment>
                                {parseFloat(record.to_par) > 0 ? (
                                    <div style={{ color: 'green' }}>
                                        <ArrowUpOutlined style={{ color: 'green' }} /> {text}
                                    </div>
                                ) : parseFloat(record.to_par) < 0 ? (
                                    <div style={{ color: 'red' }}>
                                        <ArrowDownOutlined style={{ color: 'red' }} /> {text}
                                    </div>
                                ) : (
                                    <div>{text}</div>
                                )}
                            </React.Fragment>
                        ) : (
                            '-'
                        )}
                    </Text>
                );
            },
        },
    ];

    function handleShowPlayerModal(playerId) {
        console.log(playerId);
        setSelectedPlayerId(playerId);
        setPlayerModalVisible(true);
    }

    function handlePlayerModalVisible(visibility) {
        setPlayerModalVisible(visibility);
    }

    return (
        <SectionWrapper>
            <SectionContent padding="20px 40px">
                <Row gutter={24} justify="center" type="flex">
                    <Query
                        query={GET_TOURNAMENT_PLAYER_EFFECTIVE_RATINGS}
                        fetchPolicy="cache-and-network"
                        notifyOnNetworkStatusChange={true}
                        variables={{ tournament_id: tournament.id }}
                        onCompleted={(data) => {
                            console.log(data);
                        }}
                    >
                        {({ loading, error, data }) => {
                            if (loading) return <CircularLoader />;
                            if (error) return <div style={styles.container}>Error: {error.message}</div>;

                            const ratings =
                                data &&
                                data.vw_tournament_player_effective_ratings &&
                                data.vw_tournament_player_effective_ratings.map((rating, index) => {
                                    return {
                                        key: index,
                                        ...rating,
                                    };
                                });

                            console.log(ratings);

                            return (
                                <Col xs={24}>
                                    <Card
                                        cover={
                                            <div
                                                style={{
                                                    paddingTop: 16,
                                                    paddingBottom: 16,
                                                    paddingLeft: 24,
                                                    paddingRight: 24,
                                                    // background:'linear-gradient(rgb(239, 243, 245), rgb(255, 255, 255))',
                                                    borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
                                                }}
                                            >
                                                <Title level={4} style={{ margin: 0, padding: 0 }}>
                                                    Player Performance Ratings
                                                </Title>
                                            </div>
                                        }
                                        bodyStyle={{ padding: 0 }}
                                    >
                                        <Table
                                            columns={columns}
                                            dataSource={ratings}
                                            onRow={(record, rowIndex) => {
                                                return {
                                                    onClick: (event) => {
                                                        console.log(record, rowIndex);
                                                        handleShowPlayerModal(record.id);
                                                    },
                                                    onDoubleClick: (event) => {}, // double click row
                                                    onContextMenu: (event) => {}, // right button click row
                                                    onMouseEnter: (event) => {}, // mouse enter row
                                                    onMouseLeave: (event) => {}, // mouse leave row
                                                };
                                            }}
                                            pagination={false}
                                            tableLayout="auto"
                                            scroll={{ x: 400 }}
                                        />
                                    </Card>
                                </Col>
                            );
                        }}
                    </Query>
                </Row>
                <PlayerModal
                    tournamentID={tournament.id}
                    playerId={selectedPlayerId}
                    modalVisible={playerModalVisible}
                    onPlayerModalVisible={handlePlayerModalVisible}
                    {...props}
                />
            </SectionContent>
        </SectionWrapper>
    );
}
