import React, { useState, useEffect } from 'react';
import { Link, withRouter, Route } from 'react-router-dom';
import { message, Menu, Icon, Modal, Switch, Row, Col, Typography, Button, Input, Tabs, Empty } from 'antd';
import Card from '../../components/Card';
import SectionTitle from '../../components/SectionTitle';
import SectionHeader from '../../components/SectionHeader';
import SectionContent from '../../components/SectionContent';
import SectionWrapper from '../../components/SectionWrapper';
import SectionFooter from '../../components/SectionFooter';
import * as routes from '../../config/routes';
import { useMutation, useQuery } from '@apollo/react-hooks';
import TBBasicSettings from '../tournament-builder/settings/TBBasicSettings';
import TBLogoAvatarSettings from '../tournament-builder/settings/TBLogoAvatarSettings';
import TBContactSettings from '../tournament-builder/settings/TBContactSettings';
import TBNotificationSettings from '../tournament-builder/settings/TBNotificationSettings';
import TournamentProgressBar from './TournamentProgressBar';
import TBManageButtons from './TBManageButtons';
import { GET_USER_TOURNAMENTS_QUERY } from './data/queries';
import { DELETE_TOURNAMENT_MUTATION } from './data/mutations';

const { Text, Title } = Typography;
const { confirm } = Modal;

// const styles = {
// 	row: {
// 		display: 'flex',
// 		flexGrow: 1
// 	},
// 	tab: {
// 		paddingTop: 5,
// 		paddingLeft: 24,
// 		paddingRight: 24,
// 		paddingBottom: 24
// 	}
// };

function TBSettings(props) {
    const { tournament, authState, theme } = props;
    const url = props.location.pathname.split('/');
    const currentPath = url[url.length - 1];
    const [current, setCurrent] = useState(currentPath !== 'settings' ? currentPath : 'basic');
    const [deleteTournament, { loading, error }] = useMutation(DELETE_TOURNAMENT_MUTATION);

    useEffect(() => {
        setCurrent(currentPath);
    }, [currentPath]);

    const handleClick = (e) => {
        // console.log(props);
        // console.log('click ', e);
        setCurrent(e.key);
        props.history.push(`/tournament-builder/${props.match.params.slug}/settings/${e.key}`);
    };

    function showConfirmDelete(record) {
        confirm({
            title: `Are you sure you want to remove ${record.name}?`,
            content: 'This will permanently delete this tournament and cannot be undone.',
            centered: true,
            transitionName: 'fade',
            maskTransitionName: 'none',
            // onOk() {
            // 	return new Promise((resolve, reject) => {
            // 		setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
            // 	}).catch(() => console.log('Oops errors!'));
            // },
            onOk() {
                deleteTournament({
                    variables: {
                        tournament_id: record.id,
                    },
                    notifyOnNetworkStatusChange: true,
                    awaitRefetchQueries: true,
                    refetchQueries: [
                        {
                            query: GET_USER_TOURNAMENTS_QUERY,
                            variables: {
                                manager_id: props.authState.user.id,
                            },
                        },
                    ],
                })
                    .then((data) => {
                        console.log(data);
                        message.success('Tournament deleted');
                        props.history.push(`/tournament-builder`);
                    })
                    .catch((error) => {
                        console.log(error);
                        message.error('There was an error', error);
                    });
            },
            onCancel() {},
        });
    }

    return (
        <SectionWrapper>
            <SectionHeader
                title={props.title}
                titleStyle={{ color: '#fff', margin: 0, textTransform: 'uppercase' }}
                subtitle={tournament.name}
                subtitlePrefix={true}
                subtitleStyle={{
                    color: 'rgba(255,255,255,0.8)',
                    textTransform: 'uppercase',
                    fontWeight: 600,
                    fontSize: 12,
                    letterSpacing: 3,
                }}
                height={90}
                theme="tournamentBuilder"
            >
                <TBManageButtons page="settings" {...props} />
            </SectionHeader>
            {/* <TournamentProgressBar {...props} /> */}
            <SectionContent padding="20px 40px">
                <Row gutter={24} justify="center" type="flex">
                    <Col xs={24}>
                        <Card
                            cover={
                                <div
                                    style={{
                                        paddingTop: 16,
                                        paddingBottom: 16,
                                        paddingLeft: 24,
                                        paddingRight: 24,
                                        // background:'linear-gradient(rgb(239, 243, 245), rgb(255, 255, 255))',
                                        borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <Title level={4} style={{ margin: 0, padding: 0, fontSize: 18 }}>
                                        Tournament Settings
                                    </Title>
                                    <Button type="danger" ghost onClick={() => showConfirmDelete(tournament)}>
                                        <Icon type="delete" /> Delete Tournament
                                    </Button>
                                </div>
                            }
                            // title="Manage Settings"
                            bodyStyle={{ padding: 0, display: 'flex' }}
                            // style={{
                            // 	borderRadius: 8,
                            // 	overflow: 'hidden',
                            // 	border: 'none',
                            // 	// boxShadow: '0 2px 5px 0px rgba(0, 0, 0, 0.1)'
                            // 	boxShadow: '0 0px 2px 0px rgba(0, 0, 0, 0.1), 0 8px 20px 0 rgba(218,224,235,.6)'
                            // }}
                        >
                            <div style={{ display: 'flex' }}>
                                <Menu
                                    onClick={handleClick}
                                    style={{
                                        flex: 1,
                                        marginTop: 1,
                                        width: 224,
                                        borderRight: theme && theme.name === 'dark' ? '1px solid rgba(255, 255, 255, 0.2)' : '1px solid #e8e8e8',
                                    }}
                                    defaultSelectedKeys={[current]}
                                    // defaultOpenKeys={[ 'sub1' ]}
                                    mode="inline"
                                    theme="light"
                                >
                                    <Menu.Item key="basic">
                                        <Icon type="setting" />
                                        Basic Settings
                                    </Menu.Item>
                                    {/* <Menu.Item key="contact">
										<Icon type="mail" />
										Contact Information
									</Menu.Item> */}
                                    <Menu.Item key="notifications">
                                        <Icon type="bell" />
                                        Notifications
                                    </Menu.Item>
                                    <Menu.Item key="avatar">
                                        <i className="icon ion-md-image" style={{ fontSize: 18, marginRight: 10 }} />
                                        Logos & Avatars
                                    </Menu.Item>
                                    {/* <SubMenu
										key="sub1"
										title={
											<span>
												<Icon type="appstore" />
												<span>Navigation Three</span>
											</span>
										}
									>
										<Menu.Item key="3">Basic</Menu.Item>
										<Menu.Item key="4">Option 4</Menu.Item>
										<SubMenu key="sub1-2" title="Submenu">
											<Menu.Item key="5">Option 5</Menu.Item>
											<Menu.Item key="6">Option 6</Menu.Item>
										</SubMenu>
									</SubMenu>
									<SubMenu
										key="sub2"
										title={
											<span>
												<Icon type="setting" />
												<span>Navigation Four</span>
											</span>
										}
									>
										<Menu.Item key="7">Option 7</Menu.Item>
										<Menu.Item key="8">Option 8</Menu.Item>
										<Menu.Item key="9">Option 9</Menu.Item>
										<Menu.Item key="10">Option 10</Menu.Item>
									</SubMenu> */}
                                </Menu>
                            </div>
                            <div
                                style={{
                                    height: '100%',
                                    paddingTop: 16,
                                    paddingBottom: 16,
                                    paddingLeft: 24,
                                    paddingRight: 24,
                                    width: '100%',
                                }}
                            >
                                <Route
                                    exact
                                    path={routes.TOURNAMENT_BUILDER.DETAIL_SETTINGS}
                                    render={() => <TBBasicSettings {...props} title="Basic Settings" />}
                                />
                                <Route
                                    exact
                                    path={routes.TOURNAMENT_BUILDER.DETAIL_SETTINGS_BASIC}
                                    render={() => <TBBasicSettings {...props} title="Basic Settings" />}
                                />
                                <Route
                                    exact
                                    path={routes.TOURNAMENT_BUILDER.DETAIL_SETTINGS_CONTACT}
                                    render={() => <TBContactSettings {...props} title="Contact Settings" />}
                                />
                                <Route
                                    exact
                                    path={routes.TOURNAMENT_BUILDER.DETAIL_SETTINGS_NOTIFICATIONS}
                                    render={() => <TBNotificationSettings {...props} title="Notification Settings" />}
                                />
                                <Route
                                    exact
                                    path={routes.TOURNAMENT_BUILDER.DETAIL_SETTINGS_AVATAR}
                                    render={() => <TBLogoAvatarSettings {...props} title="Upload Logo & Avatar" />}
                                />
                            </div>
                        </Card>
                    </Col>
                </Row>
            </SectionContent>
            <SectionFooter {...props} />
        </SectionWrapper>
    );
}

export default withRouter(TBSettings);
