import React from 'react';
import { Row, Col, Icon, Typography, Button, Avatar, Input, List, Empty, Skeleton } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import Card from '../../components/Card';
import SectionHeader from '../../components/SectionHeader';
import SectionContent from '../../components/SectionContent';
import SectionWrapper from '../../components/SectionWrapper';
import CircularLoader from '../../components/CircularLoader';
import { Subscription, Query } from '@apollo/client/react/components';
import gql from 'graphql-tag';

import ZeroState from '../../components/ZeroState';
import { GET_USER_LEAGUES_QUERY } from './data/queries';
import Ripples from 'react-ripples';
import moment from 'moment';
import defaultImg from '../../assets/img/ball/default.png';
import defaultDarkImg from '../../assets/img/ball/default-dark.png';

const { Text, Title } = Typography;

const styles = {
    row: {
        display: 'flex',
        flexGrow: 1,
    },
};

export default function LMMain(props) {
    const { theme } = props;

    return (
        <SectionWrapper>
            <SectionHeader
                title={props.title}
                titleStyle={{
                    color: '#fff',
                    textTransform: 'uppercase',
                    margin: 0,
                    display: 'flex',
                }}
                height={116}
                subtitle="Everything you need to run a world-class pool league"
                subtitleSuffix={true}
                subtitleStyle={{ color: '#fff' }}
                theme="leagueManager"
            />

            <SectionContent padding="20px 40px">
                <Row gutter={16} style={styles.row}>
                    <Col xs={24} sm={24} md={24} lg={12}>
                        <Card title="Start a new league">
                            <div style={{ marginBottom: 16 }}>
                                <Text>
                                    Create a brand new league of your own, add divisions and start recruiting players with a few clicks! Use the exact same
                                    tools that we do to manage the Digital Pool League.
                                </Text>
                            </div>
                            <Link to="/league-manager/new">
                                <Button type="primary" size="large">
                                    Create League
                                </Button>
                            </Link>
                        </Card>
                    </Col>

                    <Col xs={24} sm={24} md={24} lg={12}>
                        <Card title="Try it out">
                            <div style={{ marginBottom: 16 }}>
                                <Text>Want to see how League Manager works? Try it out with our sample demo league.</Text>
                            </div>
                            <Ripples>
                                <Button type="primary" size="large">
                                    View Demo
                                </Button>
                            </Ripples>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Card title="My leagues" style={styles.card} bodyStyle={{ padding: 0 }} headStyle={styles.cardHeader}>
                            <Query
                                query={GET_USER_LEAGUES_QUERY}
                                fetchPolicy="cache-and-network"
                                notifyOnNetworkStatusChange={true}
                                variables={{ owner_id: props.authState.user.id }}
                                onCompleted={async (data) => {
                                    console.log(data);
                                }}
                            >
                                {({ loading, error, data }) => {
                                    return (
                                        <React.Fragment>
                                            {/* {loading && (
												<div
													style={{
														display: 'flex',
														alignItems: 'center',
														justifyContent: 'center',
														height: '100%'
													}}
												>
													<CircularLoader />
												</div>
											)} */}
                                            {error && <div>Error: {error.message}</div>}
                                            {!loading && data && data.leagues && data.leagues.length > 0 ? (
                                                <List
                                                    className="hover-list"
                                                    size="small"
                                                    loading={loading}
                                                    itemLayout="horizontal"
                                                    dataSource={data.leagues}
                                                    renderItem={(item) => (
                                                        <List.Item
                                                            // actions={[ <a key="list-loadmore-edit">View</a> ]}
                                                            style={{ padding: '10px 16px' }}
                                                            onClick={() => props.history.push(`/league-manager/${item.slug}`)}
                                                        >
                                                            <Skeleton avatar title={false} loading={loading} active>
                                                                <List.Item.Meta
                                                                    avatar={
                                                                        <React.Fragment>
                                                                            {item.avatar ? (
                                                                                <Avatar size={30} src={item.avatar} />
                                                                            ) : (
                                                                                <Avatar
                                                                                    size={30}
                                                                                    src={theme && theme.name === 'dark' ? defaultDarkImg : defaultImg}
                                                                                />
                                                                            )}
                                                                        </React.Fragment>
                                                                    }
                                                                    title={
                                                                        item.deleted_at
                                                                            ? `${item.name} (Deleted ${moment(item.deleted_at).format('ll')})`
                                                                            : item.name
                                                                    }
                                                                    description={item.short_description}
                                                                />
                                                            </Skeleton>
                                                        </List.Item>
                                                    )}
                                                />
                                            ) : (
                                                <ZeroState showImage message="You have not created any leagues yet." style={{ margin: 30 }} {...props}>
                                                    <Link to="/league-manager/new">
                                                        <Button>Create League</Button>
                                                    </Link>
                                                </ZeroState>
                                            )}
                                        </React.Fragment>
                                    );
                                }}
                            </Query>
                        </Card>
                    </Col>
                </Row>
            </SectionContent>
        </SectionWrapper>
    );
}
