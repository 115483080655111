import React, { useState, useEffect } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { message, Button, Typography, Modal, Tooltip } from 'antd';
import { Query } from '@apollo/client/react/components';
import { GET_TOURNAMENT_TABLE_QUERY, GET_TOURNAMENT_BY_SLUG, GET_TOURNAMENT_BRACKET_MATCH_BY_HASH_ID } from './data/queries';
import { UPDATE_TOURNAMENT_BRACKET_MATCH_MUTATION } from './data/mutations';
import { GET_TOURNAMENT_TABLE_SUBSCRIPTION } from './data/subscriptions';
import SectionContent from '../../../components/SectionContent';
import SectionWrapper from '../../../components/SectionWrapper';
import CircularLoader from '../../../components/CircularLoader';
import MatchScoreboard from './MatchScoreboard2';
import moment from 'moment-timezone';
import momentDurationFormatSetup from 'moment-duration-format';
momentDurationFormatSetup(moment);

const { Text } = Typography;
const { confirm } = Modal;

const styles = {
    container: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 20,
        height: '100%',
    },
};

function DPTournamentTableScoringMobile(props) {
    const { client } = props;
    const params = props.match.params;
    const tournamentSlug = params.slug;
    const tableSlug = params.table;
    const [endMatchDisabled, setEndMatchDisabled] = useState(true);

    function startMatch(match, tournamentID) {
        const startTime = moment.utc();
        const changes = {
            start_time: startTime,
            status: 'IN_PROGRESS',
            progress: 0,
        };

        return client
            .mutate({
                mutation: UPDATE_TOURNAMENT_BRACKET_MATCH_MUTATION,
                variables: {
                    id: match.id,
                    tournament_id: tournamentID,
                    changes: changes,
                },
                notifyOnNetworkStatusChange: true,
                awaitRefetchQueries: true,
                refetchQueries: [
                    {
                        query: GET_TOURNAMENT_BRACKET_MATCH_BY_HASH_ID,
                        variables: { hash_id: match.hash_id, tournament_id: tournamentID },
                    },
                ],
            })
            .then((data) => {
                console.log(data);
                message.success(`Match started at ${moment.utc(startTime).local().format('LT')}`);
            })
            .catch((error) => {
                console.error(error);
                message.info('There was an error', error);
            });
    }

    function endMatch(match, tournamentID) {
        const endTime = moment.utc();
        const changes = {
            end_time: endTime,
            status: 'PENDING',
            progress: 100,
            table_name: match.table_name && match.table_name,
            table_id: match.table_id && match.table_id,
        };

        return client
            .mutate({
                mutation: UPDATE_TOURNAMENT_BRACKET_MATCH_MUTATION,
                variables: {
                    id: match.id,
                    tournament_id: tournamentID,
                    changes: changes,
                },
                notifyOnNetworkStatusChange: true,
                awaitRefetchQueries: true,
                refetchQueries: [
                    {
                        query: GET_TOURNAMENT_BRACKET_MATCH_BY_HASH_ID,
                        variables: { hash_id: match.hash_id, tournament_id: tournamentID },
                    },
                ],
            })
            .then((data) => {
                console.log(data);
                message.success(`Match scores submitted for TD approval at ${moment.utc(endTime).local().format('LT')}`);
            })
            .catch((error) => {
                console.error(error);
                message.info('There was an error', error);
            });
    }

    function showConfirmEndMatch(matchData) {
        const { winner, loser } = determineMatchWinner(matchData);

        confirm({
            title: (
                <React.Fragment>
                    Did {winner.name} win the match {winner.score}-{loser.score}? Please verify the scores are correct and submit for Tournament Director
                    approval.
                </React.Fragment>
            ),
            centered: true,
            transitionName: 'fade',
            maskTransitionName: 'none',
            // onOk() {
            // 	return new Promise((resolve, reject) => {
            // 		setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
            // 	}).catch(() => console.log('Oops errors!'));
            // },
            onOk() {
                endMatch(matchData);
            },
            onCancel() {},
            okText: 'Submit Scores',
            cancelText: 'Cancel',
        });
    }

    function checkForWinner(matchData) {
        const challenger1RaceTo = matchData.challenger1_race_to || matchData.tournament.winners_race_to || 0;
        const challenger2RaceTo = matchData.challenger2_race_to || matchData.tournament.winners_race_to || 0;

        if (matchData.challenger1_score >= challenger1RaceTo || matchData.challenger2_score >= challenger2RaceTo) {
            return true;
        } else {
            return false;
        }
    }

    function determineMatchWinner(matchData) {
        const challenger1RaceTo = matchData.challenger1_race_to || matchData.tournament.winners_race_to || 0;
        const challenger2RaceTo = matchData.challenger2_race_to || matchData.tournament.winners_race_to || 0;
        let winner;
        let loser;

        if (matchData.challenger1_score >= challenger1RaceTo) {
            winner = {
                id: matchData.challenger1_id,
                name: matchData.challenger1_name,
                country: matchData.challenger1_country,
                score: matchData.challenger1_score,
                is_winner: true,
            };
            loser = {
                id: matchData.challenger2_id,
                name: matchData.challenger2_name,
                country: matchData.challenger2_country,
                score: matchData.challenger2_score,
                is_winner: false,
            };
        } else if (matchData.challenger2_score >= challenger2RaceTo) {
            winner = {
                id: matchData.challenger2_id,
                name: matchData.challenger2_name,
                country: matchData.challenger2_country,
                score: matchData.challenger2_score,
                is_winner: true,
            };
            loser = {
                id: matchData.challenger1_id,
                name: matchData.challenger1_name,
                country: matchData.challenger1_country,
                score: matchData.challenger1_score,
                is_winner: false,
            };
        }

        console.log({
            winner,
            loser,
        });

        return {
            winner,
            loser,
        };
    }

    function handleMatchUpdate(matchData) {
        console.log('match', matchData);

        if (checkForWinner(matchData) === true) {
            setEndMatchDisabled(false);
            showConfirmEndMatch(matchData);
        } else {
            setEndMatchDisabled(true);
        }
    }

    return (
        <SectionWrapper>
            <SectionContent
                padding="0px"
                style={{
                    width: '100%',
                    height: '100%',
                    flex: 1,
                    background: 'linear-gradient(rgb(32, 54, 76), rgb(17, 27, 39))',
                    // background: 'linear-gradient(180deg, rgb(0, 46, 175), rgb(0, 141, 208)) 100% 100% / 100% no-repeat',
                    color: '#fff',
                    position: 'relative',
                }}
            >
                <Query
                    query={GET_TOURNAMENT_BY_SLUG}
                    fetchPolicy="cache-and-network"
                    notifyOnNetworkStatusChange={true}
                    variables={{ slug: tournamentSlug }}
                    onCompleted={(data) => {
                        // var set = [];
                        // for (let i = 0; i < data.tournament_payouts.length; i++) {
                        // 	set.push(data.tournament_payouts[i]);
                        // }
                        // let formattedData = orderAndFormatData(set);
                        // setTableData(formattedData);
                    }}
                >
                    {({ loading, error, data, refetch, fetchMore, subscribeToMore, networkStatus }) => {
                        if (loading) return <CircularLoader />;
                        if (error) return <div>Error: {error.message}</div>;

                        const tournamentData = data.tournaments[0];
                        console.log(tournamentData);

                        return (
                            <Query
                                query={GET_TOURNAMENT_TABLE_QUERY}
                                fetchPolicy="cache-and-network"
                                notifyOnNetworkStatusChange={true}
                                variables={{ tournament_id: tournamentData.id, table_slug: tableSlug }}
                                onCompleted={(data, error, loading) => {
                                    console.log(data);
                                }}
                            >
                                {({ loading, error, data, refetch, fetchMore, subscribeToMore, networkStatus }) => {
                                    if (loading) return <CircularLoader />;
                                    if (error) return <div>Error: {error.message}</div>;
                                    if (data && !data.pool_tables) {
                                        return <div style={styles.container}>No tables found.</div>;
                                    }
                                    if (data && data.pool_tables.length === 0) {
                                        return <div style={styles.container}>No tables found.</div>;
                                    }

                                    const tableData = data.pool_tables && data.pool_tables[0];
                                    const matchData = tableData && tableData.tournament_match_table && tableData.tournament_match_table[0];

                                    if (!matchData) {
                                        return <div style={styles.container}>No table scores found</div>;
                                    }

                                    console.log(matchData);

                                    return (
                                        <React.Fragment>
                                            <div
                                                style={{
                                                    background: `linear-gradient(-90deg, #002eaf, #008dd0) 100% 100% no-repeat`,
                                                    // position: 'absolute',
                                                    // top: 0,
                                                    paddingTop: 10,
                                                    paddingBottom: 10,
                                                    paddingLeft: 10,
                                                    paddingRight: 20,
                                                    width: '100%',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'space-between',
                                                    // boxShadow: 'rgb(0 0 0 / 20%) 0px 1px 15px 0px'
                                                }}
                                            >
                                                <div>
                                                    <Button
                                                        type="default"
                                                        shape="circle"
                                                        icon="arrow-left"
                                                        size="large"
                                                        ghost
                                                        style={{ border: 'none' }}
                                                        onClick={() => {
                                                            props.history.push(`/tournaments/${matchData.tournament && matchData.tournament.slug}/bracket`);
                                                        }}
                                                    >
                                                        <Text
                                                            style={{
                                                                color: '#fff',
                                                                textTransform: 'uppercase',
                                                                fontSize: 16,
                                                                fontWeight: 500,
                                                                letterSpacing: 1,
                                                            }}
                                                        >
                                                            View Bracket
                                                        </Text>
                                                    </Button>
                                                </div>
                                                <div>
                                                    {matchData.status === 'IN_PROGRESS' ? (
                                                        <Tooltip
                                                            placement="left"
                                                            title={
                                                                endMatchDisabled === true
                                                                    ? matchData.status === 'PENDING'
                                                                        ? 'Match scores have already been submitted and are awaiting approval.'
                                                                        : 'Cannot submit scores until a winner has been determined'
                                                                    : 'Submit scores for approval'
                                                            }
                                                        >
                                                            <Button
                                                                size="large"
                                                                ghost
                                                                onClick={() => {
                                                                    showConfirmEndMatch(matchData);
                                                                }}
                                                                disabled={endMatchDisabled || false}
                                                                className={endMatchDisabled === true ? 'disabled' : ''}
                                                            >
                                                                Submit scores
                                                            </Button>
                                                        </Tooltip>
                                                    ) : matchData.status === 'PENDING' ? (
                                                        <Text style={{ color: '#fff' }}>Scores pending TD approval</Text>
                                                    ) : (
                                                        matchData.status !== 'COMPLETED' && (
                                                            <Button
                                                                size="large"
                                                                ghost
                                                                onClick={() => {
                                                                    startMatch(matchData);
                                                                }}
                                                            >
                                                                Start match
                                                            </Button>
                                                        )
                                                    )}
                                                </div>
                                            </div>
                                            <MatchScoreboard
                                                matchData={{
                                                    ...matchData,
                                                    game_type: matchData.game_type || (tournamentData && tournamentData.game_type),
                                                    tournament: tournamentData,
                                                    tableSlug,
                                                }}
                                                onUpdateMatch={handleMatchUpdate}
                                                showBallTray
                                                subscribeToMore={subscribeToMore}
                                                subscribeToNewData={() => {
                                                    subscribeToMore({
                                                        document: GET_TOURNAMENT_TABLE_SUBSCRIPTION,
                                                        variables: { tournament_id: tournamentData.id, table_slug: tableData.slug },
                                                        updateQuery: (prev, { subscriptionData }) => {
                                                            console.log('prev', prev);
                                                            if (!subscriptionData.data) return prev;
                                                            const newTable =
                                                                subscriptionData &&
                                                                subscriptionData.data &&
                                                                subscriptionData.data.pool_tables &&
                                                                subscriptionData.data.pool_tables[0];
                                                            const newMatch = newTable && newTable.tournament_match_table && newTable.tournament_match_table[0];
                                                            const prevTable = prev && prev.pool_tables && prev.pool_tables[0];
                                                            const prevMatch =
                                                                prevTable && prevTable.tournament_match_table && prevTable.tournament_match_table[0];

                                                            console.log('new match', newMatch);
                                                            console.log('prev match', prevMatch);
                                                            if (prevMatch) {
                                                                const output = Object.assign({}, prevMatch, {
                                                                    matchData: {
                                                                        ...newMatch,
                                                                        game_type: newMatch.game_type || (tournamentData && tournamentData.game_type),
                                                                        tournament: tournamentData,
                                                                    },
                                                                });
                                                                return output;
                                                            } else {
                                                                return {
                                                                    matchData: {
                                                                        ...prevMatch,
                                                                        game_type: prevMatch.game_type || (tournamentData && tournamentData.game_type),
                                                                        tournament: tournamentData,
                                                                    },
                                                                };
                                                            }

                                                            // if (!subscriptionData.data) return prev;
                                                            // const updatedMatch =
                                                            //     subscriptionData &&
                                                            //     subscriptionData.data &&
                                                            //     subscriptionData.data.tournament_brackets &&
                                                            //     subscriptionData.data.tournament_brackets[0];
                                                            // const prevMatch = prev && prev.tournament_brackets && prev.tournament_brackets[0];
                                                            // if (prevMatch && updatedMatch.id === prevMatch.id) {
                                                            //     const output = Object.assign({}, prev, {
                                                            //         ...prev.tournament_brackets[0],
                                                            //         updatedMatch,
                                                            //     });

                                                            //     return output;
                                                            // } else {
                                                            //     return prev;
                                                            // }
                                                        },
                                                    });
                                                }}
                                                {...props}
                                            />
                                        </React.Fragment>
                                    );
                                }}
                            </Query>
                        );
                    }}
                </Query>
            </SectionContent>
        </SectionWrapper>
    );
}

export default withRouter(DPTournamentTableScoringMobile);
