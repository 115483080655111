import React, { useState, useRef } from 'react';
import { message, Modal, Button, Icon, Upload, Progress, Typography } from 'antd';
import { Form, FormItem, Input } from 'formik-antd';
import { Formik, ErrorMessage } from 'formik';
import { GET_LEAGUE_POSTS, GET_POST_QUERY } from '../data/queries';
import { CREATE_LEAGUE_POST_MUTATION, UPDATE_POST_MUTATION } from '../data/mutations';
import { Query, Mutation } from '@apollo/client/react/components';
import CircularLoader from '../../../components/CircularLoader';
import * as Yup from 'yup';
import { functions } from '../../../firebase';
import { connectFunctionsEmulator, httpsCallable } from 'firebase/functions';
import PostImageUpload from './PostImageUpload';
import EmojiPicker from './EmojiPicker';
import axios from 'axios';
import { useMutation, useQuery } from '@apollo/react-hooks';
import slugify from 'slugify';

const { Title, Text } = Typography;

export default function AddPostModal(props) {
    const { authState, selectedPostId, modalVisible, onModalOk, onModalCancel, league } = props;
    const formRef = useRef();

    // const [loading, setLoading] = useState(false);
    const [fileList, setFileList] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [progress, setProgress] = useState();
    const [imagePreview, setImagePreview] = useState();
    const [text, setText] = useState('');
    const fileReader = new FileReader();
    const [createLeaguePost] = useMutation(CREATE_LEAGUE_POST_MUTATION);
    const [updatePost] = useMutation(UPDATE_POST_MUTATION);

    const schema = Yup.object().shape({
        message: Yup.string().required('Message is a required field'),
    });

    async function submitAdd(values, actions) {
        // handleSubmit(dataImage, actions);
        console.log(values);
        let image;

        if (fileList && fileList.length > 0) {
            setUploading(true);
            image = await handleUploadImage(values, actions);
        }

        // const slug = slugify(values.message, {
        //     replacement: '-',
        //     remove: /[*+~.,()'"#!:/@]/g,
        //     lower: true,
        // });

        createLeaguePost({
            variables: {
                objects: [
                    {
                        league_id: league.id,
                        user_id: authState && authState.user && authState.user.id,
                        post: {
                            data: {
                                message: values.message,
                                // slug: slug,
                                user_id: authState && authState.user && authState.user.id,
                                ...(image && { image_url: image.image_url }),
                            },
                        },
                    },
                ],
            },
            notifyOnNetworkStatusChange: true,
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: GET_LEAGUE_POSTS,
                    variables: { id: league.id },
                },
            ],
        })
            .then((res) => {
                actions.setSubmitting(false);
                actions.resetForm();
                console.log(res);
                setUploading(false);
                setProgress(0);
                // const result = res && res.data && res.data.insert_posts && res.data.insert_posts.returning && res.data.insert_posts.returning[0];
                message.success('Post created');
                setFileList([]);
                onModalOk();
            })
            .catch((e) => {
                setUploading(false);
                setProgress(0);
                actions.setSubmitting(false);
                message.error((e && e.message) || JSON.stringify(e));
                console.log(e);
            });
    }

    async function submitUpdate(data, actions) {
        let image;
        if (fileList && fileList.length > 0) {
            setUploading(true);
            const imageUrl = data.image_url;
            const splitPath = imageUrl.split('/');
            const fileName = splitPath[splitPath.length - 1];
            const deleteImg = await handleDeleteImage(fileName);
            console.log('deleted', deleteImg);
            image = await handleUploadImage(data, actions);
        }

        // const slug = slugify(data.message, {
        //     replacement: '-',
        //     remove: /[*+~.,()'"#!:/@]/g,
        //     lower: true,
        // });

        updatePost({
            variables: {
                id: selectedPostId,
                changes: {
                    message: data.message,
                    // slug: slug,
                    image_url: (image && image.image_url) || data.image_url,
                },
            },
            notifyOnNetworkStatusChange: true,
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: GET_POST_QUERY,
                    variables: { id: selectedPostId },
                },
            ],
        })
            .then((res) => {
                actions.setSubmitting(false);
                actions.resetForm();
                console.log(res);
                setUploading(false);
                setProgress(0);
                // const result = res && res.data && res.data.insert_posts && res.data.insert_posts.returning && res.data.insert_posts.returning[0];
                message.success('Post updated');
                setFileList([]);
                onModalOk();
            })
            .catch((e) => {
                setUploading(false);
                setProgress(0);
                actions.setSubmitting(false);
                message.error((e && e.message) || JSON.stringify(e));
                console.log(e);
            });
    }

    async function handleDeleteImage(fileName) {
        const config = {
            S3BucketName: 'digitalpool',
            key: `post-images/${fileName}`,
            action: 'delete',
        };

        if (window.location.hostname.indexOf('localhost') > -1) {
            connectFunctionsEmulator(functions, 'localhost', 5001);
        }
        try {
            var deleteImage = await httpsCallable(functions, 'uploadImageS3');
            return deleteImage(config)
                .then((result) => {
                    console.log(result);
                    return result;
                })
                .catch((e) => {
                    console.log(e);
                    return e;
                });
        } catch (e) {
            console.log(e);
            return e;
        }
    }

    async function handleUploadImage(data, actions) {
        const formData = new FormData();
        let files = [];
        fileList.forEach((file) => {
            console.log(file);
            files.push(file);
            formData.append('files[]', file);
        });

        message.info('Uploading image...');

        console.log(formData);
        console.log(files);

        const file = files[0];

        const config = {
            S3BucketName: 'digitalpool',
            key: `post-images/${file.name}`,
            contentType: file.type,
        };

        if (window.location.hostname.indexOf('localhost') > -1) {
            connectFunctionsEmulator(functions, 'localhost', 5001);
        }
        try {
            // Retrieve the Cloud Function
            var uploadImage = await httpsCallable(functions, 'uploadImageS3');
            // await new Promise((resolve) => waitUntilImageLoaded(resolve));
            // Call the cloud function
            return uploadImage(config)
                .then((result) => {
                    // S3 pre-signed URL
                    const options = {
                        onUploadProgress: (e) => {
                            setProgress((e.loaded / e.total) * 100);
                        },
                        headers: {
                            'Content-Type': file.type,
                            'x-amz-acl': 'public-read',
                            'x-amz-server-side-encryption': 'AES256',
                        },
                    };

                    const signedRequest = result.data.signedRequest;
                    const imageUrl = result.data.url;
                    // Run a HTTP PUT request to the pre-signed URL
                    return axios
                        .put(signedRequest, file, options)
                        .then((response) => {
                            if (imageUrl) {
                                let dataImage = {
                                    ...data,
                                    image_url: imageUrl,
                                };
                                message.success('Image upload successful!');
                                return dataImage;
                            } else {
                                return response.body;
                            }
                        })
                        .catch((err) => {
                            message.error('Image upload failed');
                            console.log(err);
                            return err;
                        });
                })
                .catch((e) => {
                    message.error('Image upload failed');
                    console.log(e);
                    return e;
                });
        } catch (e) {
            message.error('Image upload failed');
            console.log(e);
            return e;
        }
    }

    function handleFormFieldUpdates(event) {
        const caret = event.target.selectionStart;
        const element = event.target;
        window.requestAnimationFrame(() => {
            element.selectionStart = caret;
            element.selectionEnd = caret;
        });

        setText(event.target.value);
    }

    const onChange = (info) => {
        console.log(info);
        if (!fileReader.onloadend) {
            // fileReader.onloadend = (obj) => {
            // 	setImage(obj.srcElement.result);
            // };
            // can be any other read function ( any reading function from
            // previously created instance can be used )
            const { status } = info.file;
            console.log(info.file);
            if (status === 'removed') {
                setImagePreview();
            } else {
                let imageObj;
                // if (info.file) {
                //     imageObj = fileReader.readAsArrayBuffer(info.file);
                // } else {
                //     imageObj = URL.createObjectURL(info.file);
                // }
                imageObj = URL.createObjectURL(info.file);
                console.log(imageObj);
                setImagePreview(imageObj);
            }
            // if (status === 'uploading') {
            //     setImagePreview(true);
            //     // console.log(info.file, info.fileList);
            // }
            // if (status === 'done') {
            //     setLoading(false);
            //     console.log(info.file, info.fileList);
            //     message.success(`${info.file.name} file uploaded successfully.`);
            // } else if (status === 'error') {
            //     setLoading(false);
            //     message.error(`${info.file.name} file upload failed.`);
            // }
        }
    };

    function renderForm(post) {
        const initialValues = selectedPostId
            ? {
                  ...post,
                  message: post.message,
              }
            : {
                  message: '',
              };

        const uploadProps = {
            onRemove: (file) => {
                const index = fileList.indexOf(file);
                const newFileList = fileList.slice();
                newFileList.splice(index, 1);
                setFileList(newFileList);
            },
            beforeUpload: (file) => {
                const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
                if (!isJpgOrPng) {
                    message.error('You can only upload JPG/PNG file!');
                }
                const isLt2M = file.size / 1024 / 1024 < 2;
                if (!isLt2M) {
                    message.error('Image must smaller than 2MB!');
                }

                setFileList([...fileList, file]);
                setImagePreview(file);
                // return isJpgOrPng && isLt2M;
                return false;
            },
            fileList,
        };

        return (
            <div>
                <Formik
                    ref={formRef}
                    onSubmit={(data, actions) => {
                        console.log({ data, actions });
                        // handleUpload(data, actions);

                        // console.log(merged);
                        if (selectedPostId) {
                            submitUpdate(data, actions);
                        } else {
                            submitAdd(data, actions);
                        }
                    }}
                    validationSchema={schema}
                    enableReinitialize
                    initialValues={initialValues}
                    render={({ errors, touched, values }) => (
                        <Modal
                            title={
                                <Title level={4} style={{ marginBottom: 0 }}>
                                    {selectedPostId ? 'Edit post' : 'Create new post'}
                                </Title>
                            }
                            visible={modalVisible}
                            onOk={onModalOk}
                            onCancel={onModalCancel}
                            centered
                            transitionName="fade"
                            // transitionName="none"
                            maskTransitionName="none"
                            bodyStyle={{ maxHeight: 500, overflowY: 'auto', padding: '10px 20px' }}
                            destroyOnClose={true}
                            footer={[
                                <Button key="cancel" size="large" onClick={onModalCancel}>
                                    Cancel
                                </Button>,
                                <Button
                                    key="submit"
                                    size="large"
                                    disabled={Object.keys(errors).length > 0 ? true : false}
                                    type={Object.keys(errors).length > 0 ? 'danger' : 'primary'}
                                    loading={uploading}
                                    onClick={() => {
                                        formRef.current.handleSubmit();
                                    }}
                                >
                                    {selectedPostId ? (uploading ? 'Updating...' : 'Update Post') : uploading ? 'Creating Post' : 'Create Post'}
                                </Button>,
                            ]}
                        >
                            <Form
                                layout="vertical"
                                style={{ maxWidth: 600 }}
                                // onSubmit={(e) => {
                                // 	e.preventDefault();
                                // 	console.log(values);
                                // 	// handleSubmit(values);
                                // }}
                            >
                                <FormItem name="message" hasFeedback>
                                    <Input.TextArea
                                        name="message"
                                        placeholder="What's on your mind?"
                                        size="large"
                                        rows={4}
                                        // value={text}
                                        onChange={(event) => {
                                            handleFormFieldUpdates(event);
                                        }}
                                        style={{ fontSize: 20 }}
                                    />
                                </FormItem>

                                {uploading === true && <Progress percent={parseInt(progress)} size="small" />}

                                <div style={{ display: 'flex', alignItems: 'flex-start', marginTop: 5, marginBottom: 10 }}>
                                    <EmojiPicker
                                        text={text}
                                        onSetText={(text) => {
                                            formRef.current.setValues({
                                                message: text,
                                            });
                                            setText(text);
                                        }}
                                    />
                                    <div style={{ width: 10 }} />
                                    <Upload {...uploadProps} onChange={onChange}>
                                        <Button ghost size="large">
                                            {post && post.image_url ? (
                                                <React.Fragment>
                                                    {imagePreview ? (
                                                        <React.Fragment>
                                                            <img src={imagePreview} alt="Preview" height={30} /> Replace Image
                                                        </React.Fragment>
                                                    ) : (
                                                        <React.Fragment>
                                                            <img src={post.image_url} alt="Post" height={30} /> Replace Image
                                                        </React.Fragment>
                                                    )}
                                                </React.Fragment>
                                            ) : (
                                                <React.Fragment>
                                                    <Icon type="file-image" style={{ fontSize: 16 }} /> Add Image
                                                </React.Fragment>
                                            )}
                                        </Button>
                                    </Upload>
                                </div>
                            </Form>
                        </Modal>
                    )}
                />
            </div>
        );
    }

    return (
        <React.Fragment>
            {selectedPostId ? (
                <Query
                    query={GET_POST_QUERY}
                    fetchPolicy="cache-and-network"
                    notifyOnNetworkStatusChange
                    variables={{ id: selectedPostId }}
                    onCompleted={async (data) => {
                        console.log(data);
                    }}
                >
                    {({ loading, error, data }) => {
                        if (loading) return null;
                        if (error) return <div>Error: {error.message}</div>;

                        const post = data && data.posts && data.posts.length > 0 ? data.posts[0] : null;
                        return <React.Fragment>{renderForm(post)}</React.Fragment>;
                    }}
                </Query>
            ) : (
                <React.Fragment>{renderForm()}</React.Fragment>
            )}
        </React.Fragment>
    );
}
