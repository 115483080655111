import React, { useState, useEffect } from 'react';
import { message, Avatar, Radio, Typography, Modal, Empty, Tag, Tooltip, Row, Col, Button, Icon } from 'antd';
import { withRouter, Link } from 'react-router-dom';
import CircularLoader from '../../../components/CircularLoader';
import Card from '../../../components/Card';
import StreamOverlayLarge from '../tournaments/StreamOverlayLarge';
import { GET_EVENT_BY_SLUG_QUERY, GET_EVENT_LIVE_TABLE_QUERY } from '../data/queries';
import { GET_EVENT_LIVE_TABLE_SUBSCRIPTION } from '../data/subscriptions';
import { Query, Subscription } from '@apollo/client/react/components';
import liveStreamSS from '../../../assets/img/live-stream-bg.png';
import qs from 'query-string';
import moment from 'moment-timezone';
import momentDurationFormatSetup from 'moment-duration-format';
momentDurationFormatSetup(moment);

const { Text, Title } = Typography;
const { confirm } = Modal;

const styles = {
    container: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 20,
    },
};

function EventStreamOverlayLarge(props) {
    const { client } = props;
    const { match, authState } = props;
    const params = qs.parse(props.location.search, { ignoreQueryPrefix: true });
    const eventSlug = match.params.slug;
    const tableSlug = match.params.table;
    const showPhoto = params && params.photo === 'true' ? true : false;

    return (
        <Query
            query={GET_EVENT_LIVE_TABLE_QUERY}
            fetchPolicy="cache-and-network"
            notifyOnNetworkStatusChange={true}
            variables={{ event_slug: eventSlug, table_slug: tableSlug }}
            onCompleted={(data, error, loading) => {
                // console.log(data);
            }}
        >
            {({ loading, error, data, refetch, fetchMore, subscribeToMore, networkStatus }) => {
                if (loading)
                    return (
                        <div style={styles.container}>
                            <CircularLoader /> <span style={{ marginLeft: 10 }}>Loading...</span>
                        </div>
                    );
                if (error) return <div style={styles.container}>Error: {error.message}</div>;

                let tableData;
                let matchData;
                let tournamentData;

                data &&
                    data.tournaments &&
                    data.tournaments.forEach((tournament) => {
                        tournament.pool_tables &&
                            tournament.pool_tables.forEach((table) => {
                                if (table.tournament_match_table && table.tournament_match_table.length > 0) {
                                    tableData = table;
                                    tournamentData = tournament;

                                    if (table.tournament_match_table[0]) {
                                        matchData = table.tournament_match_table[0];
                                    }
                                }
                            });
                    });

                if (data && !matchData) {
                    return (
                        <div>
                            <Title
                                level={4}
                                style={{
                                    marginLeft: 20,
                                    marginTop: 10,
                                    color: '#fff',
                                    letterSpacing: 0,
                                    textShadow: '0 2px 5px rgba(0,0,0,0.50)',
                                }}
                            >
                                Waiting for a live match on {tableSlug}
                            </Title>
                        </div>
                    );
                }

                console.log(tableData);
                console.log(tournamentData);
                console.log(matchData);

                return (
                    <React.Fragment>
                        {showPhoto === true && (
                            <div style={{ position: 'fixed', top: 0, left: 0, width: '100%' }}>
                                <img src={liveStreamSS} alt="Live stream screenshot" width="100%" />
                            </div>
                        )}
                        <StreamOverlayLarge
                            tournamentData={tournamentData}
                            matchData={matchData}
                            tableData={tableData}
                            subscribeToMore={subscribeToMore}
                            subscribeToNewData={() => {
                                subscribeToMore({
                                    document: GET_EVENT_LIVE_TABLE_SUBSCRIPTION,
                                    variables: { event_slug: eventSlug, table_slug: tableSlug },
                                    updateQuery: (prev, { subscriptionData }) => {
                                        if (!subscriptionData.data) return prev;
                                        const newTournament = subscriptionData.data.tournaments;
                                        let updated;
                                        if (prev) {
                                            updated = Object.assign({}, prev, {
                                                tournaments: [...newTournament],
                                            });
                                        } else {
                                            updated = Object.assign({}, prev, {
                                                tournaments: [...newTournament],
                                            });
                                        }
                                        return updated;
                                    },
                                });
                            }}
                            {...props}
                        />
                        ;
                    </React.Fragment>
                );
            }}
        </Query>
    );
}

export default withRouter(EventStreamOverlayLarge);
