import React, { useState, useEffect } from 'react';
import { List, Tabs, Row, Col, Button, Icon, Typography, Empty, Input } from 'antd';
import TableScoreCard from '../TableScoreCard';
import TableAssignment from '../../../tournament-builder/TableAssignment2';
import { Query, Subscription } from '@apollo/client/react/components';
import { Link, withRouter } from 'react-router-dom';
import { isoCountries } from '../../../../utils/isoCountryCodes';
import { GET_ALL_TOURNAMENT_TABLES_SIDEPANEL_QUERY, GET_TOURNAMENT_TABLES_QUERY, GET_TOURNAMENT_BRACKET_PLAYERS_QUERY } from '../data/queries';
import {
    GET_ALL_TOURNAMENT_TABLES_SUBSCRIPTION,
    GET_TOURNAMENT_BRACKET_PLAYERS_SUBSCRIPTION,
    GET_TOURNAMENT_PLAYERS_SUBSCRIPTION,
} from '../data/subscriptions';
import ZeroState from '../../../../components/ZeroState';
import CircularLoader from '../../../../components/CircularLoader';
import Card from '../../../../components/Card';
import SidePanelPlayers from './SidePanelPlayers';
import _ from 'lodash';
import Fade from 'react-reveal/Fade';
import SidePanelTables from './SidePanelTables';

const { TabPane } = Tabs;
const { Search } = Input;
const { Text } = Typography;

const styles = {
    container: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
};

const tabHeight = 45;

function SidePanel(props) {
    const { tournament, matches, bracketHeight, theme, onSidePanelOpen, onShowScoreModal, scrollToTable } = props;
    const [totalTables, setTotalTables] = useState();
    const [totalPlayers, setTotalPlayers] = useState();
    // function callback(key) {
    // 	console.log(key);
    // }

    function getCurrentRound(match) {
        return matches.filter((item) => {
            return item.round === match.round;
        });
    }

    function handleScoreModal(match) {
        const currentRound = getCurrentRound(match);
        onShowScoreModal(match, currentRound);
    }

    function renderTable(table, match, index) {
        return (
            <React.Fragment key={index}>
                <Col
                    xs={24}
                    style={{
                        margin: 0,
                        paddingLeft: 8,
                        paddingRight: 8,
                        paddingTop: 4,
                        paddingBottom: 4,
                    }}
                >
                    {match && table.status === 'IN_USE' ? (
                        <TableScoreCard
                            {...props}
                            key={index}
                            label={table.label}
                            matchData={match}
                            table={table}
                            tournament={tournament}
                            status={table.status}
                            gameType={tournament.game_type}
                            title={match.match_number && `Match ${match.match_number}`}
                            tableSize="small"
                            onClick={() => handleScoreModal(match)}
                            style={{
                                backgroundColor: 'transparent',
                                border: theme && theme.name === 'dark' ? '1px solid rgba(255, 255, 255, 0.2)' : '1px solid rgb(238, 238, 238)',
                                borderRadius: 4,
                            }}
                        />
                    ) : (
                        <TableAssignment
                            {...props}
                            title={`Match ${index + 1}`}
                            label={table.label}
                            status={table.status}
                            displayText={table.status}
                            matchData={match}
                            table={table}
                            tableSize="small"
                            controls={false}
                            style={{
                                backgroundColor: 'transparent',
                                border: theme && theme.name === 'dark' ? '1px solid rgba(255, 255, 255, 0.2)' : '1px solid rgb(238, 238, 238)',
                                borderRadius: 4,
                            }}
                            // onEdit={() => {
                            // 	setEditModalVisible(true);
                            // }}
                        />
                    )}
                </Col>
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            <Tabs
                animated={{ inkBar: true, tabPane: false }}
                defaultActiveKey="tables"
                // onChange={callback}
                // type="card"
                tabBarStyle={{
                    textTransform: 'uppercase',
                    paddingLeft: 10,
                    color: theme.bracket.panels.textColor,
                    borderBottom: `1px solid ${theme.bracket.panels.borderColor}`,
                }}
                tabBarExtraContent={
                    <Button type="link" style={{ marginRight: 10 }} onClick={() => onSidePanelOpen(false)}>
                        <Icon type="close" style={{ color: theme.bracket.panels.iconColor }} />
                    </Button>
                }
            >
                <TabPane tab={`Tables ${totalTables ? `(${totalTables})` : ''}`} key="tables">
                    <Subscription
                        subscription={GET_ALL_TOURNAMENT_TABLES_SUBSCRIPTION}
                        fetchPolicy="cache-and-network"
                        notifyOnNetworkStatusChange={true}
                        variables={{ tournament_id: tournament.id }}
                        onSubscriptionData={(data, loading, error) => {
                            if (!loading && !error) {
                                const { pool_tables } = data.subscriptionData.data;
                                setTotalTables(pool_tables.length);
                                // onTablesUpdated(pool_tables);
                            }
                        }}
                    >
                        {({ loading, error, data, refetch, fetchMore, subscribeToMore, networkStatus }) => {
                            if (loading)
                                return (
                                    <div style={styles.container}>
                                        <CircularLoader />
                                    </div>
                                );
                            if (error) return <div style={styles.container}>Error: {error.message}</div>;
                            if ((data && !data.pool_tables) || (data && data.pool_tables.length === 0)) {
                                return (
                                    <Row
                                        gutter={16}
                                        justify="center"
                                        type="flex"
                                        style={{
                                            margin: 0,
                                            padding: 0,
                                            overflowY: 'auto',
                                            height: bracketHeight - tabHeight,
                                            width: '100%',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <ZeroState showImage message="No tables found." style={{ margin: 30 }} {...props}>
                                            <Link to={`/tournament-builder/${tournament.slug}/tables`}>
                                                <Button>Manage Tables</Button>
                                            </Link>
                                        </ZeroState>
                                    </Row>
                                );
                            }

                            const assignedTables =
                                data &&
                                data.pool_tables
                                    .filter((table) => {
                                        return table.status !== 'IN_USE';
                                    })
                                    .sort((a, b) => {
                                        return a.label.localeCompare(b.label, undefined, {
                                            numeric: true,
                                            sensitivity: 'base',
                                        });
                                    });

                            const inUseTables =
                                data &&
                                data.pool_tables
                                    .filter((table) => {
                                        return table.status === 'IN_USE';
                                    })
                                    .sort((a, b) => {
                                        return a.label.localeCompare(b.label, undefined, {
                                            numeric: true,
                                            sensitivity: 'base',
                                        });
                                    });

                            return (
                                <React.Fragment>
                                    <Row
                                        gutter={16}
                                        justify="center"
                                        type="flex"
                                        style={{
                                            margin: 0,
                                            padding: 0,
                                            overflowY: 'auto',
                                            height: bracketHeight - tabHeight - 57,
                                            width: '100%',
                                            alignItems: 'flex-start',
                                        }}
                                    >
                                        <SidePanelTables
                                            tournament={tournament}
                                            tables={inUseTables}
                                            onScoreModal={handleScoreModal}
                                            // subscribeToMore={subscribeToMore}
                                            // subscribeToNewData={() => {
                                            //     subscribeToMore({
                                            //         document: GET_ALL_TOURNAMENT_TABLES_SUBSCRIPTION,
                                            //         variables: { tournament_id: tournament.id },
                                            //         updateQuery: (prev, { subscriptionData }) => {
                                            //             if (!subscriptionData.data) return prev;
                                            //             const newTable = subscriptionData.data.pool_tables;
                                            //             console.log(newTable);
                                            //             let updated;
                                            //             if (prev) {
                                            //                 updated = Object.assign({}, prev, {
                                            //                     pool_tables: [...newTable],
                                            //                 });
                                            //             } else {
                                            //                 updated = Object.assign({}, prev, {
                                            //                     pool_tables: [...newTable],
                                            //                 });
                                            //             }
                                            //             return updated;
                                            //         },
                                            //     });
                                            // }}
                                            {...props}
                                        />
                                        {/* {inUseTables.map((table, index) => {
                                            const match = table.tournament_match_table && table.tournament_match_table[0];
                                            return renderTable(table, match, index);
                                        })} */}

                                        <SidePanelTables
                                            tournament={tournament}
                                            tables={assignedTables}
                                            onScoreModal={handleScoreModal}
                                            // subscribeToMore={subscribeToMore}
                                            // subscribeToNewData={() => {
                                            //     subscribeToMore({
                                            //         document: GET_ALL_TOURNAMENT_TABLES_SUBSCRIPTION,
                                            //         variables: { tournament_id: tournament.id },
                                            //         updateQuery: (prev, { subscriptionData }) => {
                                            //             if (!subscriptionData.data) return prev;
                                            //             const newTable = subscriptionData.data.pool_tables;
                                            //             console.log(newTable);
                                            //             let updated;
                                            //             if (prev) {
                                            //                 updated = Object.assign({}, prev, {
                                            //                     pool_tables: [...newTable],
                                            //                 });
                                            //             } else {
                                            //                 updated = Object.assign({}, prev, {
                                            //                     pool_tables: [...newTable],
                                            //                 });
                                            //             }
                                            //             return updated;
                                            //         },
                                            //     });
                                            // }}
                                            {...props}
                                        />
                                        {/* {assignedTables.map((table, index) => {
                                            const match = table.tournament_match_table && table.tournament_match_table[0];
                                            return renderTable(table, match, index);
                                        })} */}
                                    </Row>
                                    <Row>
                                        <Col
                                            xs={24}
                                            style={{
                                                margin: 0,
                                                paddingLeft: 8,
                                                paddingRight: 8,
                                                borderTop: `1px solid ${theme.bracket.panels.borderColor}`,
                                                backgroundColor: theme.bracket.panels.backgroundColor,
                                            }}
                                        >
                                            <Link to={`/tournament-builder/${tournament.slug}/tables`}>
                                                <Button size="large" type="ghost" style={{ width: '100%', color: theme.bracket.panels.textColor }}>
                                                    Manage Tables
                                                </Button>
                                            </Link>
                                        </Col>
                                    </Row>
                                </React.Fragment>
                            );
                        }}
                    </Subscription>
                </TabPane>
                <TabPane tab={`Players ${totalPlayers ? `(${totalPlayers})` : ''}`} key="players">
                    <Subscription
                        subscription={GET_TOURNAMENT_PLAYERS_SUBSCRIPTION}
                        fetchPolicy="cache-and-network"
                        // notifyOnNetworkStatusChange={true}
                        variables={{ tournament_id: tournament.id }}
                        onSubscriptionData={(data, loading, error) => {
                            // console.log(data.subscriptionData.data);
                            if (!loading && !error) {
                                const { tournament_players } = data.subscriptionData.data;
                                // const players = _.uniq(
                                // 	_.flatten(
                                // 		tournament_brackets.map((match) => {
                                // 			return [ match.challenger1, match.challenger2 ];
                                // 		})
                                // 	),
                                // 	'id'
                                // );
                                // // console.log(players);
                                setTotalPlayers(tournament_players.length);
                                // console.log(players);
                                // onPlayersUpdated(players);
                            }
                        }}
                    >
                        {({ loading, error, data }) => {
                            if (loading)
                                return (
                                    <div style={styles.container}>
                                        <CircularLoader />
                                    </div>
                                );
                            if (error) return <div style={styles.container}>Error: {error.message}</div>;
                            if ((data && !data.tournament_players) || (data && data.tournament_players.length === 0)) {
                                return (
                                    <Row
                                        gutter={16}
                                        justify="center"
                                        type="flex"
                                        style={{
                                            margin: 0,
                                            padding: 0,
                                            overflowY: 'auto',
                                            height: bracketHeight - tabHeight,
                                            width: '100%',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <ZeroState showImage message="No players found." style={{ margin: 30 }} {...props}>
                                            <Link to={`/tournament-builder/${tournament.slug}/players`}>
                                                <Button>Manage Players</Button>
                                            </Link>
                                        </ZeroState>
                                    </Row>
                                );
                            }

                            return <SidePanelPlayers data={data} tournament={tournament} bracketHeight={bracketHeight} {...props} />;
                        }}
                    </Subscription>
                </TabPane>
            </Tabs>
        </React.Fragment>
    );
}

export default withRouter(SidePanel);
