import React, { useState, useEffect } from 'react';
import { Descriptions, Row, Tag, Avatar, Button, Empty, Icon, List, Skeleton, Col, Typography } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import CircularLoader from '../../../components/CircularLoader';
import { Subscription } from '@apollo/client/react/components';
import { GET_MATCH_BY_SLUG_SUBSCRIPTION } from './data/subscriptions';
import qs from 'query-string';
import StatLongestWinningStreak from './StatLongestWinningStreak';
import StatBreakAndRunPackages from './StatBreakAndRunPackages';
import StatConsecutiveBreakAndRunStreak from './StatConsecutiveBreakAndRunStreak';
import { getGameType, displayEnumType } from '../../../utils';
import Card, { Meta } from '../../../components/Card';
import { matchStats as stats } from '../../../utils';
import Flag from '../../../components/Flag';
import _ from 'lodash';
import numeral from 'numeral';
import dpLogoHeader from '../../../assets/img/dp-logo-header.png';

const { Title, Text } = Typography;

const styles = {};

function MatchStatsOverlay(props) {
    const { client } = props;
    const params = qs.parse(props.location.search, { ignoreQueryPrefix: true });

    return (
        <Subscription
            subscription={GET_MATCH_BY_SLUG_SUBSCRIPTION}
            fetchPolicy="cache-and-network"
            notifyOnNetworkStatusChange={true}
            variables={{ slug: props.match.params.slug }}
            onSubscriptionData={async (data) => {
                console.log(data);
            }}
        >
            {({ loading, error, data }) => {
                if (loading) return <CircularLoader />;
                if (error) return <div style={styles.container}>Error: {error.message}</div>;
                if (data && !data.matches) {
                    return <div>Match not found.</div>;
                }
                if (data && data.matches.length === 0) {
                    return <div>Match not found.</div>;
                }

                console.log(data);
                const matchData = data && data.matches && data.matches[0];
                const matchStats = matchData.match_stats;

                const playerStats = matchStats.filter((stat) => {
                    return stat.metric.indexOf('player_') !== -1;
                });

                const opponentStats = matchStats.filter((stat) => {
                    return stat.metric.indexOf('opponent_') !== -1;
                });

                const uniquePlayerStats = _.uniqBy(playerStats, 'metric');
                const uniqueOpponentStats = _.uniqBy(opponentStats, 'metric');

                // TPA formula = balls pocketed / balls pocketed + errors
                function calculateTPA(ballsPocketed, errors) {
                    return numeral(ballsPocketed / (ballsPocketed + errors)).format('0.000');
                }

                function getPlayerTPA() {
                    const playerBallsPocketed = playerStats.filter((stat) => stat.metric === 'player_balls_pocketed')[0];
                    const playerBallsPocketedValue = playerBallsPocketed ? playerBallsPocketed.value : 0;
                    const playerErrors = playerStats.filter((stat) => stat.metric === 'player_unforced_errors')[0];
                    const playerErrorValue = playerErrors ? playerErrors.value : 0;
                    const tpaScore = calculateTPA(playerBallsPocketedValue, playerErrorValue);
                    return tpaScore;
                }

                function getOpponentTPA() {
                    const opponentBallsPocketed = opponentStats.filter((stat) => stat.metric === 'opponent_balls_pocketed')[0];
                    const opponentBallsPocketedValue = opponentBallsPocketed ? opponentBallsPocketed.value : 0;
                    const opponentErrors = opponentStats.filter((stat) => stat.metric === 'opponent_unforced_errors')[0];
                    const opponentErrorValue = opponentErrors ? opponentErrors.value : 0;
                    const tpaScore = calculateTPA(opponentBallsPocketedValue, opponentErrorValue);
                    return tpaScore;
                }

                console.log({
                    playerStats,
                    opponentStats,
                });

                const playerScore = playerStats.filter((stat) => stat.metric === 'player_score')[0] || [];
                const playerBallsPocketed = playerStats.filter((stat) => stat.metric === 'player_balls_pocketed')[0] || [];
                const playerBallsMissed = playerStats.filter((stat) => stat.metric === 'player_balls_missed')[0] || [];
                const playerBallsMadeOnBreak = playerStats.filter((stat) => stat.metric === 'player_balls_made_on_break')[0] || [];
                const playerBreakAndRuns = playerStats.filter((stat) => stat.metric === 'player_break_and_runs')[0] || [];
                const playerDryBreaks = playerStats.filter((stat) => stat.metric === 'player_dry_breaks')[0] || [];
                const playerScratchesOnBreak = playerStats.filter((stat) => stat.metric === 'player_scratches_on_break')[0] || [];
                const playerShotmakingRate = ((playerBallsPocketed.value / (playerBallsPocketed.value + playerBallsMissed.value)) * 100).toFixed(0);
                const playerMissRate = ((playerBallsMissed.value / (playerBallsPocketed.value + playerBallsMissed.value)) * 100).toFixed(0);
                const playerBallsMadeOnBreakRate = ((playerBallsMadeOnBreak.value / (playerScore.value * 9)) * 100).toFixed(0);
                const playerBreakAndRunRate = ((playerBreakAndRuns.value / playerScore.value) * 100).toFixed(0);
                const playerScratchesOnBreakRate = ((playerScratchesOnBreak.value / playerScore.value) * 100).toFixed(0);
                const playerDryBreakRate = ((playerDryBreaks.value / playerScore.value) * 100).toFixed(0);
                const playerUnforcedErrors = playerStats.filter((stat) => stat.metric === 'player_unforced_errors')[0] || [];
                const playerSafetyErrors = playerStats.filter((stat) => stat.metric === 'player_safety_errors')[0] || [];
                const playerErrorRate = ((playerUnforcedErrors.value / (playerBallsPocketed.value + playerUnforcedErrors.value)) * 100).toFixed(0);
                const playerSafetyErrorRate = ((playerSafetyErrors.value / (playerBallsPocketed.value + playerSafetyErrors.value)) * 100).toFixed(0);

                const opponentScore = opponentStats.filter((stat) => stat.metric === 'opponent_score')[0] || [];
                const opponentBallsPocketed = opponentStats.filter((stat) => stat.metric === 'opponent_balls_pocketed')[0] || [];
                const opponentBallsMissed = opponentStats.filter((stat) => stat.metric === 'opponent_balls_missed')[0] || [];
                const opponentBallsMadeOnBreak = opponentStats.filter((stat) => stat.metric === 'opponent_balls_made_on_break')[0] || [];
                const opponentBreakAndRuns = opponentStats.filter((stat) => stat.metric === 'opponent_break_and_runs')[0] || [];
                const opponentDryBreaks = opponentStats.filter((stat) => stat.metric === 'opponent_dry_breaks')[0] || [];
                const opponentScratchesOnBreak = opponentStats.filter((stat) => stat.metric === 'opponent_scratches_on_break')[0] || [];
                const opponentShotmakingRate = ((opponentBallsPocketed.value / (opponentBallsPocketed.value + opponentBallsMissed.value)) * 100).toFixed(0);
                const opponentMissRate = ((opponentBallsMissed.value / (opponentBallsPocketed.value + opponentBallsMissed.value)) * 100).toFixed(0);
                const opponentBallsMadeOnBreakRate = ((opponentBallsMadeOnBreak.value / (opponentScore.value * 9)) * 100).toFixed(0);
                const opponentBreakAndRunRate = ((opponentBreakAndRuns.value / opponentScore.value) * 100).toFixed(0);
                const opponentScratchesOnBreakRate = ((opponentScratchesOnBreak.value / opponentScore.value) * 100).toFixed(0);
                const opponentDryBreakRate = ((opponentDryBreaks.value / opponentScore.value) * 100).toFixed(0);
                const opponentUnforcedErrors = opponentStats.filter((stat) => stat.metric === 'opponent_unforced_errors')[0] || [];
                const opponentSafetyErrors = opponentStats.filter((stat) => stat.metric === 'opponent_safety_errors')[0] || [];
                const opponentErrorRate = ((opponentUnforcedErrors.value / (opponentBallsPocketed.value + opponentUnforcedErrors.value)) * 100).toFixed(0);
                const opponentSafetyErrorRate = ((opponentSafetyErrors.value / (opponentBallsPocketed.value + opponentSafetyErrors.value)) * 100).toFixed(0);

                console.log({
                    opponentBallsPocketed,
                    opponentBallsMissed,
                    opponentShotmakingRate,
                });

                //const stats = [
                //	'Score',
                //	'Points',
                //	'Balls pocketed',
                //	'Balls made on the break',
                //	'Balls missed',
                //	'Unforced errors',
                //	'Fouls',
                //	'Illegal breaks',
                //	'Dry breaks',
                //	'Runouts',
                //	'Break and runs',
                //	'Golden breaks',
                //	'Racks won on break',
                //	'Racks won against break'
                //];

                // const stats = [
                // 	'Score',
                // 	'Points',
                // 	'Balls made',
                // 	'Errors',
                // 	'High run',
                // 	'Balls made on the break',
                // 	'Balls missed',
                // 	'Fouls',
                // 	'Illegal breaks',
                // 	'Dry breaks',
                // 	'Runouts',
                // 	'Break and runs',
                // 	'Golden breaks',
                // 	'Racks won on break',
                // 	'Racks won against break',
                // 	'Bank shots made',
                // 	'Bank shots missed',
                // 	'Kick shots made',
                // 	'Kick shots missed',
                // 	'Cut shots made',
                // 	'Cut shots missed',
                // 	'Straight shots made',
                // 	'Straight shots missed',
                // 	'Carom shots made',
                // 	'Carom shots missed',
                // 	'Combo shots made',
                // 	'Combo shots missed',
                // 	'Safety shots made',
                // 	'Safety shots missed',
                // 	'Fluked shots',
                // 	'Kick safes made',
                // 	'Kick safes missed',
                // 	'Successful breaks',
                // 	'Unsuccessful breaks'
                // ];

                const playerStatsFormatted = stats.map((stat) => {
                    const statUnderscored = stat.split(' ').join('_').toLowerCase();
                    return `player_${statUnderscored}`;
                });

                const opponentStatsFormatted = stats.map((stat) => {
                    const statUnderscored = stat.split(' ').join('_').toLowerCase();
                    return `opponent_${statUnderscored}`;
                });

                return (
                    <div
                        style={{
                            background: 'linear-gradient(#20364c, #111b27)',
                            position: 'relative',
                            width: '100%',
                            maxWidth: 600,
                            left: '50%',
                            transform: 'translateX(-50%)',
                            color: '#fff',
                            paddingLeft: 20,
                            paddingRight: 20,
                            paddingTop: 10,
                            paddingBottom: 10,
                            overflowY: 'auto',
                        }}
                    >
                        <Row gutter={24} justify="center" type="flex">
                            <Col xs={12} align="center">
                                <div style={{ marginBottom: 10 }}>
                                    {matchData.player_country && (
                                        <Flag
                                            name={matchData.player_country || 'Unknown'}
                                            format="png"
                                            pngSize={48}
                                            shiny={false}
                                            alt="Flag"
                                            className="flag"
                                            basePath="/images/flags"
                                        />
                                    )}

                                    <div style={{ fontSize: 25 }}>{matchData.player_name}</div>
                                    {matchStats && (
                                        <div style={{ fontSize: 18 }}>
                                            <Text style={{ color: 'rgba(255,255,255,0.5)' }}>TPA:</Text> <Text style={{ color: '#fff' }}>{getPlayerTPA()}</Text>
                                        </div>
                                    )}
                                </div>

                                <div
                                    style={{
                                        // background:
                                        // 	matchData.player_score > matchData.opponent_score
                                        // 		? 'linear-gradient(-45deg, transparent, #0074ff)'
                                        // 		: 'linear-gradient(-45deg, transparent, black)',
                                        color: '#fff',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',

                                        borderRadius: 10,
                                        // border: '2px solid #3090d2'
                                    }}
                                >
                                    <div
                                        style={{
                                            fontSize: 60,
                                            fontWeight: 700,
                                            lineHeight: 1.2,
                                            // paddingTop: 20,
                                            // paddingBottom: 20
                                        }}
                                    >
                                        {matchData.player_score || 0}
                                    </div>
                                </div>
                            </Col>
                            <Col xs={12} align="center">
                                <div style={{ marginBottom: 10 }}>
                                    {matchData.opponent_country && (
                                        <Flag
                                            name={matchData.opponent_country || 'Unknown'}
                                            format="png"
                                            pngSize={48}
                                            shiny={false}
                                            alt="Flag"
                                            className="flag"
                                            basePath="/images/flags"
                                        />
                                    )}

                                    <div style={{ fontSize: 25 }}>{matchData.opponent_name}</div>
                                    {matchStats && (
                                        <div>
                                            <div style={{ fontSize: 18 }}>
                                                <Text style={{ color: 'rgba(255,255,255,0.5)' }}>TPA:</Text>{' '}
                                                <Text style={{ color: '#fff' }}>{getOpponentTPA()}</Text>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div
                                    style={{
                                        // background:
                                        // 	matchData.opponent_score > matchData.player_score
                                        // 		? 'linear-gradient(45deg, transparent, #0074ff)'
                                        // 		: 'linear-gradient(45deg, transparent, black)',
                                        color: '#fff',

                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',

                                        borderRadius: 10,
                                        // border: '2px solid #3090d2'
                                    }}
                                >
                                    <div
                                        style={{
                                            fontSize: 60,
                                            fontWeight: 700,
                                            lineHeight: 1.2,
                                            // paddingTop: 20,
                                            // paddingBottom: 20
                                        }}
                                    >
                                        {matchData.opponent_score || 0}
                                    </div>
                                </div>
                            </Col>

                            <Col xs={24}>
                                <div>
                                    <table width="100%" className="info-table-stats-dark">
                                        <tbody>
                                            {playerStats &&
                                                stats.map((stat, index) => {
                                                    const statUnderscored = stat.split(' ').join('_').toLowerCase();
                                                    const playerStat = `player_${statUnderscored}`;
                                                    const opponentStat = `opponent_${statUnderscored}`;

                                                    const playerValue = uniquePlayerStats.filter((item) => {
                                                        return item.metric === playerStat;
                                                    })[0];

                                                    const opponentValue = uniqueOpponentStats.filter((item) => {
                                                        return item.metric === opponentStat;
                                                    })[0];

                                                    console.log(playerValue);
                                                    console.log(opponentValue);

                                                    const totalValue = playerValue && opponentValue && playerValue.value + opponentValue.value;
                                                    const playerValuePct = playerValue && ((playerValue.value / totalValue) * 100).toFixed(0);
                                                    const opponentValuePct = opponentValue && ((opponentValue.value / totalValue) * 100).toFixed(0);

                                                    return (
                                                        <tr key={index}>
                                                            <td align="right" width="60px">
                                                                <div
                                                                    style={{
                                                                        fontSize: 16,
                                                                        textAlign: 'right',
                                                                        paddingLeft: 20,
                                                                        paddingRight: 20,
                                                                    }}
                                                                >
                                                                    <React.Fragment>
                                                                        {statUnderscored === 'balls_pocketed'
                                                                            ? isNaN(playerShotmakingRate) === false
                                                                                ? `${playerShotmakingRate}%`
                                                                                : '-'
                                                                            : statUnderscored === 'balls_missed'
                                                                            ? isNaN(playerMissRate) === false
                                                                                ? `${playerMissRate}%`
                                                                                : '-'
                                                                            : statUnderscored === 'unforced_errors'
                                                                            ? isNaN(playerErrorRate) === false
                                                                                ? `${playerErrorRate}%`
                                                                                : '-'
                                                                            : statUnderscored === 'safety_errors'
                                                                            ? isNaN(playerSafetyErrorRate) === false
                                                                                ? `${playerSafetyErrorRate}%`
                                                                                : '-'
                                                                            : statUnderscored === 'balls_made_on_break'
                                                                            ? isNaN(playerBallsMadeOnBreakRate) === false
                                                                                ? `${playerBallsMadeOnBreakRate}%`
                                                                                : '-'
                                                                            : statUnderscored === 'break_and_runs'
                                                                            ? isNaN(playerBreakAndRunRate) === false
                                                                                ? `${playerBreakAndRunRate}%`
                                                                                : '-'
                                                                            : statUnderscored === 'scratches_on_break'
                                                                            ? isNaN(playerScratchesOnBreakRate) === false
                                                                                ? `${playerScratchesOnBreakRate}%`
                                                                                : '-'
                                                                            : statUnderscored === 'dry_breaks'
                                                                            ? isNaN(playerDryBreakRate) === false
                                                                                ? `${playerDryBreakRate}%`
                                                                                : '-'
                                                                            : isNaN(playerValuePct) === false
                                                                            ? `${playerValuePct}%`
                                                                            : '-'}
                                                                    </React.Fragment>
                                                                </div>
                                                            </td>
                                                            <td align="right">
                                                                <div
                                                                    style={{
                                                                        fontSize: 18,
                                                                    }}
                                                                >
                                                                    <b>{playerValue ? playerValue.value : 0}</b>
                                                                </div>
                                                            </td>
                                                            <td align="center" width="350px">
                                                                {displayEnumType(statUnderscored)}
                                                            </td>
                                                            <td align="left">
                                                                <div
                                                                    style={{
                                                                        fontSize: 18,
                                                                    }}
                                                                >
                                                                    <b>{opponentValue ? opponentValue.value : 0}</b>
                                                                </div>
                                                            </td>
                                                            <td align="left" width="60px">
                                                                <div
                                                                    style={{
                                                                        fontSize: 16,
                                                                        textAlign: 'left',
                                                                        paddingLeft: 20,
                                                                        paddingRight: 20,
                                                                    }}
                                                                >
                                                                    <React.Fragment>
                                                                        {statUnderscored === 'balls_pocketed'
                                                                            ? isNaN(opponentShotmakingRate) === false
                                                                                ? `${opponentShotmakingRate}%`
                                                                                : '-'
                                                                            : statUnderscored === 'balls_missed'
                                                                            ? isNaN(opponentMissRate) === false
                                                                                ? `${opponentMissRate}%`
                                                                                : '-'
                                                                            : statUnderscored === 'unforced_errors'
                                                                            ? isNaN(opponentErrorRate) === false
                                                                                ? `${opponentErrorRate}%`
                                                                                : '-'
                                                                            : statUnderscored === 'safety_errors'
                                                                            ? isNaN(opponentSafetyErrorRate) === false
                                                                                ? `${opponentSafetyErrorRate}%`
                                                                                : '-'
                                                                            : statUnderscored === 'balls_made_on_break'
                                                                            ? isNaN(opponentBallsMadeOnBreakRate) === false
                                                                                ? `${opponentBallsMadeOnBreakRate}%`
                                                                                : '-'
                                                                            : statUnderscored === 'break_and_runs'
                                                                            ? isNaN(opponentBreakAndRunRate) === false
                                                                                ? `${opponentBreakAndRunRate}%`
                                                                                : '-'
                                                                            : statUnderscored === 'scratches_on_break'
                                                                            ? isNaN(opponentScratchesOnBreakRate) === false
                                                                                ? `${opponentScratchesOnBreakRate}%`
                                                                                : '-'
                                                                            : statUnderscored === 'dry_breaks'
                                                                            ? isNaN(opponentDryBreakRate) === false
                                                                                ? `${opponentDryBreakRate}%`
                                                                                : '-'
                                                                            : isNaN(opponentValuePct) === false
                                                                            ? `${opponentValuePct}%`
                                                                            : '-'}
                                                                    </React.Fragment>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            {/* <tr>
                                                <td align="right" width="60px"></td>
                                                <td align="right">
                                                    <b>{playerShotmakingRate}%</b>
                                                </td>
                                                <td align="center" width="350px">
                                                    Shotmaking Rate
                                                </td>
                                                <td align="left">
                                                    <b>{opponentShotmakingRate}%</b>
                                                </td>
                                                <td align="left" width="60px"></td>
                                            </tr> */}

                                            <StatLongestWinningStreak matchId={matchData.id} {...props} />
                                            <StatConsecutiveBreakAndRunStreak matchId={matchData.id} {...props} />
                                            {/* <StatBreakAndRunPackages matchId={matchData.id} {...props} /> */}
                                        </tbody>
                                    </table>
                                    <div
                                        style={{
                                            marginTop: 20,
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <Text style={{ color: 'rgba(255,255,255,0.5)', marginRight: 5 }}>Powered by:</Text>{' '}
                                        <img src={dpLogoHeader} alt="Logo" width={150} />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                );
            }}
        </Subscription>
    );
}

export default withRouter(MatchStatsOverlay);
