import React, { useState, useEffect, useLayoutEffect, useRef, createRef } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { message, Menu, Dropdown, Button, Icon, Typography, Empty, Row, Col, Tooltip, Modal, Input, Layout } from 'antd';
import Card from '../../components/Card';
import ScrollContainer from 'react-indiana-drag-scroll';
import CircularLoader from '../../components/CircularLoader';
import ZeroState from '../../components/ZeroState';
import { GET_TOURNAMENT_BRACKET_STAGES_QUERY, GET_ALL_TOURNAMENT_TABLES_QUERY } from './data/queries';
import { GET_ALL_TOURNAMENT_TABLES_SUBSCRIPTION, GET_TOURNAMENT_BRACKET_SUBSCRIPTION } from './data/subscriptions';
import { DownOutlined } from '@ant-design/icons';
import gql from 'graphql-tag';
import { Query, Subscription } from '@apollo/client/react/components';
import { useMutation, useQuery } from '@apollo/react-hooks';
import PendingMatches from '../../screens/digitalpool/tournaments/bracket/PendingMatches';
import useBreakpoint from 'use-breakpoint';
import RoundRobinViewerMatchOverlay from './RoundRobinViewerMatchOverlay';
import SetRoundTimeModal from '../../screens/digitalpool/tournaments/bracket/SetRoundTimeModal';
import { defaultBreakpoints } from '../../utils/defaultBreakpoints';
import { MapInteractionCSS } from 'react-map-interaction';
import SidePanel from '../../screens/digitalpool/tournaments/bracket/SidePanel';
import MatchInfoModal from '../../screens/digitalpool/tournaments/bracket/MatchInfoModal';
import Autopilot from '../../screens/digitalpool/tournaments/bracket/Autopilot3';
import RoundRobinMatches from './RoundRobinMatches';
import { DigitalPoolAPI } from '../../lib/api';
import _ from 'lodash';
import qs from 'query-string';
import moment from 'moment';

const { Text, Title } = Typography;
const { Sider, Content, Header } = Layout;
// const { SubMenu } = Menu;
const { confirm } = Modal;

const tabHeight = 45;

const styles = {
    container: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
};

export function RoundRobinViewer(props) {
    const { authState, tournament, theme, statusPanel, tablesPanel, isPublic, isFullScreen } = props;
    const params = qs.parse(props.location.search, { ignoreQueryPrefix: true });
    const [modalScoreVisible, setModalScoreVisible] = useState(false);
    const [currentMatch, setCurrentMatch] = useState();
    const [matches, setMatches] = useState();
    const [playerWaiting, setPlayerWaiting] = useState();
    const [currentRound, setCurrentRound] = useState();
    // const [tables, setTables] = useState([]);
    // const [selectedStage, setSelectedStage] = useState();
    const { breakpoint, maxWidth, minWidth } = useBreakpoint(defaultBreakpoints);
    const [sidePanelOpen, setSidePanelOpen] = useState(breakpoint === 'mobile' ? false : true);
    const [showStatusPanel, setShowStatusPanel] = useState(breakpoint === 'mobile' ? false : true);
    const [roundTimeModalVisible, setRoundTimeModalVisible] = useState(false);
    const [matchModalVisible, setMatchModalVisible] = useState(false);
    const showNavigation = params && params.navigation === 'false' ? false : true;
    const tournamentPath = props.match.params.slug;
    const stageSlug = props.match.params.stage;
    console.log('stage slug', stageSlug);
    const viewerEl = useRef();

    const windowHeight = window.innerHeight;
    const headerEl = document.querySelector('.header');
    const headerNarrowEl = document.querySelector('.header-narrow');
    const sectionHeaderEl = document.querySelector('.section-header');
    const progressBarEl = document.querySelector('.tournament-progress-bar');

    let bracketHeight;
    let headerHeight = isFullScreen === true || showNavigation === false ? 0 : sectionHeaderEl && sectionHeaderEl.offsetHeight;
    let headerNarrowHeight = headerNarrowEl && headerNarrowEl.offsetHeight;
    let progressBarHeight = progressBarEl && progressBarEl.offsetHeight;
    let footerHeight = 53;

    const API = DigitalPoolAPI(props);

    if (isPublic === true) {
        if (isFullScreen === true) {
            bracketHeight = '100%';
        } else {
            const spacer = breakpoint === 'mobile' ? 170 : 95;
            bracketHeight = windowHeight - (headerHeight + footerHeight + spacer);
        }
    } else if (isFullScreen === false && headerNarrowEl) {
        bracketHeight = windowHeight - (headerHeight + progressBarHeight + footerHeight + headerNarrowHeight);
    } else if (isFullScreen === false && headerEl) {
        bracketHeight = windowHeight - (headerHeight + progressBarHeight + footerHeight + headerEl.offsetHeight);
    } else if (isFullScreen === true) {
        bracketHeight = '100%';
    } else {
        bracketHeight = windowHeight - (headerHeight + progressBarHeight + footerHeight);
    }
    if (breakpoint === 'mobile') {
        bracketHeight = bracketHeight + 80;
    }

    function canAccess(user) {
        if (user && user.role && (user.role === 'user' || user.role === 'admin')) {
            return true;
        } else {
            return false;
        }
    }

    function showCompleteDialog(winner) {
        console.log(winner);
        let content;
        if (winner.is_split === true) {
            content = `${winner.challenger1_name} and ${winner.challenger2_name} split 1st place!.`;
        } else {
            content = `${winner.challenger2_name || winner.challenger1_name} won the tournament!`;
        }
        Modal.success({
            content: content,
            centered: true,
            transitionName: 'fade',
            maskTransitionName: 'none',
        });
    }

    function completeTournament(winner) {
        showCompleteDialog(winner);
        // setTournamentComplete(true);
    }

    function showScoreModal(match, round) {
        setCurrentMatch(match);
        setCurrentRound(round);
        setModalScoreVisible(true);
    }

    function handleSetMatches(data) {
        setMatches(data);
    }

    function handlePlayerWaiting(data) {
        setPlayerWaiting(data);
    }

    function handleMatchModalOk(e) {
        // console.log(e);
        setMatchModalVisible(false);
    }

    function handleMatchModalCancel(e) {
        setMatchModalVisible(false);
    }

    function handleMatchSelected(match) {
        setCurrentMatch(match);
        setMatchModalVisible(true);
    }

    function handleRoundTimeModalOk(e) {
        setRoundTimeModalVisible(false);
    }

    function handleRoundTimeModalCancel(e) {
        setRoundTimeModalVisible(false);
    }

    function showConfirmStart(tables) {
        confirm({
            title: 'Are you ready to start the tournament?',
            content: 'This action will start the tournament clock.',
            centered: true,
            transitionName: 'fade',
            maskTransitionName: 'none',
            okText: 'Start Tournament',
            cancelText: "I'll do this later",
            onOk() {
                if (tables.length > 0) {
                    startTournament();
                } else {
                    message.error('You must add at least 1 table before you can start the tournament.', 10);
                }
            },
            onCancel() {},
        });
    }

    function startTournament(callback) {
        API.updateTournament({
            start_date_time: moment.utc(),
            status: 'IN_PROGRESS',
        }).then((res) => {
            // await API.updateTournamentStatus('IN_PROGRESS');
            message.success({
                content: `Tournament clock started.`,
                duration: 2,
            });

            return res;
        });
    }

    return (
        <Query
            query={GET_TOURNAMENT_BRACKET_STAGES_QUERY}
            fetchPolicy="cache-and-network"
            notifyOnNetworkStatusChange={true}
            variables={{ tournament_id: tournament.id }}
            onCompleted={(data, loading, error) => {
                if (!loading && !error) {
                    console.log(data);
                }
            }}
        >
            {({ loading, error, data, subscribeToMore, networkStatus }) => {
                if (networkStatus === 1 || loading) {
                    return (
                        <div style={styles.container}>
                            <CircularLoader />
                        </div>
                    );
                }

                if (error) return <div style={styles.container}>Error: {error.message}</div>;
                if ((data && !data.tournament_brackets) || (data && data.tournament_brackets.length === 0)) {
                    return (
                        <Row gutter={24} justify="center" type="flex" style={{ padding: '20px 40px' }}>
                            <Col xs={24}>
                                <Card>
                                    <ZeroState showImage message="No tournament bracket exists yet." style={{ margin: 30 }} {...props}>
                                        <Button
                                            type="primary"
                                            size="large"
                                            onClick={() => {
                                                props.history.push(`/tournament-builder/${tournament.slug}/edit?step=4`);
                                            }}
                                        >
                                            Set up bracket
                                        </Button>
                                    </ZeroState>
                                </Card>
                            </Col>
                        </Row>
                    );
                }

                const tournament_brackets = data.tournament_brackets;
                const matches = tournament_brackets;
                console.log(matches);
                const groupedMatches = _.groupBy(matches, 'group');
                const groups = Object.values(groupedMatches);
                console.log(groups);
                const groupsWithRounds =
                    groups &&
                    groups.map((group, index) => {
                        const groupNumber = index + 1;
                        const groupedRounds = _.groupBy(group, 'round');
                        const rounds = Object.values(groupedRounds);
                        console.log(rounds);
                        return {
                            group: groupNumber,
                            rounds: rounds,
                        };
                    });
                const tournamentMatches = matches;

                return (
                    <Query
                        query={GET_ALL_TOURNAMENT_TABLES_QUERY}
                        fetchPolicy="cache-and-network"
                        notifyOnNetworkStatusChange={true}
                        variables={{ tournament_id: tournament.id }}
                        onCompleted={(data, loading, error) => {
                            if (!loading && !error) {
                                // const { pool_tables } = data;
                                // setTables(pool_tables);
                                // // if (
                                // // 	pool_tables.length > 0
                                // // ) {
                                // // 	showConfirmDraw(drawRound, winnerBracket, matches);
                                // // }
                                // const stages = tournament.stages;
                                // console.log('stages', stages);
                                // if (stages && stages.length > 0) {
                                //     let defaultStage;
                                //     if (stageSlug) {
                                //         defaultStage = stages.filter((stage) => {
                                //             return stage.slug === stageSlug;
                                //         })[0];
                                //     } else {
                                //         defaultStage = stages[0];
                                //     }
                                //     console.log(defaultStage);
                                //     setSelectedStage(defaultStage);
                                // }
                            }
                        }}
                    >
                        {({ loading, error, data }) => {
                            if (loading)
                                return (
                                    <div style={styles.container}>
                                        <CircularLoader />
                                    </div>
                                );
                            if (error) return <div style={styles.container}>Error: {error.message}</div>;

                            const tables = data.pool_tables;
                            const stages = tournament.stages;
                            let selectedStage;
                            console.log('stages', stages);
                            if (stages && stages.length > 0) {
                                let defaultStage;
                                if (stageSlug) {
                                    defaultStage = stages.filter((stage) => {
                                        return stage.slug === stageSlug;
                                    })[0];
                                } else {
                                    defaultStage = stages[0];
                                }
                                console.log(defaultStage);
                                selectedStage = defaultStage;
                            }

                            return (
                                <Layout style={{ height: bracketHeight }}>
                                    {showStatusPanel === true && statusPanel !== false && tournament.status !== 'COMPLETED' && (
                                        <Sider
                                            theme={theme.name}
                                            width={300}
                                            style={{
                                                backgroundColor: theme.bracket.panels.backgroundColor,
                                                // borderLeft: '1px solid #eee',
                                                position: 'relative',
                                                zIndex: 1,
                                                boxShadow: 'rgba(0, 21, 41, 0.25) 0px 0px 10px 0px',
                                            }}
                                        >
                                            <Autopilot
                                                theme={theme}
                                                tournament={tournament}
                                                tournamentMatches={tournamentMatches}
                                                tables={tables}
                                                // players={players}
                                                onShowScoreModal={showScoreModal}
                                                showPanel={showStatusPanel}
                                                bracketHeight={isFullScreen === true ? windowHeight : bracketHeight}
                                                onStartTournament={() => {
                                                    if (tables.length > 0) {
                                                        showConfirmStart(tables);
                                                    } else {
                                                        message.error('You must first add tables and players before you can start the tournament');
                                                    }
                                                }}
                                                setShowStatusPanel={setShowStatusPanel}
                                                subscribeToMore={subscribeToMore}
                                                subscribeToNewData={() => {
                                                    subscribeToMore({
                                                        document: GET_TOURNAMENT_BRACKET_SUBSCRIPTION,
                                                        variables: { tournamentId: tournament.id },
                                                        updateQuery: (prev, { subscriptionData }) => {
                                                            if (!subscriptionData.data) return prev;
                                                            const newMatches = subscriptionData.data.tournament_brackets;
                                                            let updated;
                                                            if (prev) {
                                                                updated = Object.assign({}, prev, {
                                                                    tournament_brackets: [...newMatches],
                                                                });
                                                            } else {
                                                                updated = Object.assign({}, prev, {
                                                                    tournament_brackets: [...newMatches],
                                                                });
                                                            }
                                                            return updated;
                                                        },
                                                    });
                                                }}
                                            />
                                            <PendingMatches theme={theme} tournament={tournament} tables={tables} />
                                        </Sider>
                                    )}

                                    <Content style={{ position: 'relative', backgroundColor: theme.bracket.backgroundColor }}>
                                        <ScrollContainer
                                            id="bracket"
                                            className="scroll-container"
                                            vertical={true}
                                            horizontal={true}
                                            hideScrollbars={false}
                                            activationDistance={1}
                                            ignoreElements=".prevent-drag-scroll"
                                            nativeMobileScroll={true}
                                            style={{
                                                height: '100%',
                                                width: '100%',
                                                position: 'absolute',
                                                zIndex: 0,
                                                // position: 'relative'
                                                // background: 'linear-gradient( #fafafa, #f0f2f5)'
                                            }}
                                            ref={viewerEl}
                                        >
                                            <RoundRobinMatches
                                                isPublic={isPublic}
                                                matches={matches}
                                                stage={selectedStage}
                                                tables={tables}
                                                bracketHeight={bracketHeight}
                                                onShowScoreModal={showScoreModal}
                                                {...props}
                                            />
                                        </ScrollContainer>
                                    </Content>
                                    {authState && tournament.status !== 'COMPLETED' && (
                                        <React.Fragment>
                                            <RoundRobinViewerMatchOverlay
                                                {...props}
                                                isFullScreen={isFullScreen}
                                                // players={players}
                                                stage={selectedStage}
                                                matches={matches}
                                                currentRound={currentRound}
                                                currentMatch={currentMatch}
                                                modalScoreVisible={modalScoreVisible}
                                                onModalScoreVisible={setModalScoreVisible}
                                                onTournamentComplete={(winner) => {
                                                    completeTournament(winner);
                                                }}
                                            />
                                            <SetRoundTimeModal
                                                tournament={tournament}
                                                currentRound={currentRound}
                                                matches={matches}
                                                tables={tables}
                                                onModalOk={handleRoundTimeModalOk}
                                                onModalCancel={handleRoundTimeModalCancel}
                                                modalVisible={roundTimeModalVisible}
                                                {...props}
                                            />
                                        </React.Fragment>
                                    )}

                                    {currentMatch && (
                                        <MatchInfoModal
                                            {...props}
                                            tournament={tournament}
                                            tournamentID={tournament.id}
                                            currentMatch={currentMatch}
                                            modalVisible={matchModalVisible}
                                            onMatchModalOk={handleMatchModalOk}
                                            onMatchModalCancel={handleMatchModalCancel}
                                        />
                                    )}
                                    {sidePanelOpen === true && tablesPanel !== false && tournament.status !== 'COMPLETED' && canAccess(authState.user) && (
                                        <Sider
                                            theme={theme.name}
                                            width={300}
                                            style={{
                                                backgroundColor: theme.bracket.panels.backgroundColor,
                                                // borderLeft: '1px solid #eee',
                                                boxShadow: 'rgba(0, 21, 41, 0.25) 0px 0px 10px 0px',
                                            }}
                                        >
                                            <SidePanel
                                                theme={theme}
                                                tournament={tournament}
                                                matches={matches}
                                                onSidePanelOpen={() => {
                                                    setSidePanelOpen(false);
                                                }}
                                                onShowScoreModal={showScoreModal}
                                                scrollToTable={() => {
                                                    // const nextHeights = elementsRef.current.map(
                                                    // 	(ref) => ref.current.getBoundingClientRect().height
                                                    // );
                                                    // console.log(nextHeights);
                                                    // scrollToComponent(this[`winnerRound1_ref`], {
                                                    // 	offset: 1000,
                                                    // 	align: 'top',
                                                    // 	duration: 1500
                                                    // });
                                                }}
                                                bracketHeight={isFullScreen === true ? windowHeight : bracketHeight}
                                            />
                                        </Sider>
                                    )}
                                </Layout>
                            );
                        }}
                    </Query>
                );
            }}
        </Query>
    );
}

export default React.memo(RoundRobinViewer);
