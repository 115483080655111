import React from 'react';
import { Table, Icon, Avatar, Divider, Modal, Tooltip, Button, Typography } from 'antd';
import { Link, Route, withRouter } from 'react-router-dom';
import { getGameType } from '../../../utils';
const slugify = require('slugify');

const { Text } = Typography;
const { confirm } = Modal;

function DivisionTable(props) {
    const { onSelected, onEditSelected, onDelete, league, theme } = props;

    function onChange(pagination, filters, sorter) {
        console.log('params', pagination, filters, sorter);
    }

    const columns = [
        {
            title: '#',
            dataIndex: 'index',
            width: 80,
            defaultSortOrder: 'ascend',
            sorter: (a, b) => {
                return a.index - b.index;
            },
        },
        {
            title: 'Name',
            dataIndex: 'name',
            // filters: [
            // 	{
            // 		text: 'Platinum Division',
            // 		value: 'platinum'
            // 	},
            // 	{
            // 		text: 'Gold Division',
            // 		value: 'gold'
            // 	},
            // 	{
            // 		text: 'Silver Division',
            // 		value: 'silver'
            // 	},
            // 	{
            // 		text: 'Bronze Division',
            // 		value: 'bronze'
            // 	},
            // 	{
            // 		text: 'Trophy Division',
            // 		value: 'trophy'
            // 	}
            // ],
            // // specify the condition of filtering result
            // // here is that finding the name started with `value`
            // onFilter: (value, record) => record.name.indexOf(value) === 0,
            sorter: (a, b) => {
                return a.name.localeCompare(b.name);
            },
            render: (text, record) => {
                return (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {record && record.game_types && (
                            <Avatar size={24} src={getGameType(record.game_types, theme)} style={{ display: 'inline-block', marginRight: 8 }} />
                        )}

                        <Text style={{ fontWeight: 600 }}>{text || '-'}</Text>
                    </div>
                );
            },
        },
        // {
        // 	title: 'Description',
        // 	dataIndex: 'description',
        // 	defaultSortOrder: 'descend',
        // 	sorter: (a, b) => {
        // 		return a.description.localeCompare(b.description);
        // 	}
        // },
        {
            title: 'Division Type',
            dataIndex: 'division_type',
            sorter: (a, b) => {
                return a.division_type.localeCompare(b.division_type);
            },
            render: (text, record) => <Text>{text || '-'}</Text>,
        },
        {
            title: 'Game Types',
            dataIndex: 'game_types',
            sorter: (a, b) => {
                return a.game_types.localeCompare(b.game_types);
            },
            render: (text, record) => <Text>{text || '-'}</Text>,
        },
        {
            title: 'Players',
            dataIndex: 'players',
            sorter: (a, b) => {
                return a.players - b.players;
            },
            render: (text, record) => <Text>{text || '-'}</Text>,
        },
        {
            title: 'Start Date',
            dataIndex: 'start_date',
            sorter: (a, b) => {
                return a.start_date && a.start_date.localeCompare(b.start_date);
            },
            render: (text, record) => <Text>{text || '-'}</Text>,
        },
        {
            title: 'End Date',
            dataIndex: 'end_date',
            sorter: (a, b) => {
                return a.end_date && a.end_date.localeCompare(b.end_date);
            },
            render: (text, record) => <Text>{text || '-'}</Text>,
        },
        {
            title: 'Access',
            dataIndex: 'is_public',
            sorter: (a, b) => {
                return a.is_public.localeCompare(b.is_public);
            },
            render: (text, record) => <Text>{text || '-'}</Text>,
        },
        {
            title: 'Status',
            dataIndex: 'is_active',
            sorter: (a, b) => {
                return a.is_active.localeCompare(b.is_active);
            },
            render: (text, record) => <Text>{text || '-'}</Text>,
        },

        // {
        // 	title: 'Game Types',
        // 	dataIndex: 'game_types',
        // 	defaultSortOrder: 'descend',
        // 	sorter: (a, b) => {
        // 		return a.game_types.localeCompare(b.game_types);
        // 	}
        // },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <span style={{ whiteSpace: 'nowrap' }}>
                    <Tooltip title="Edit">
                        <Button
                            type="ghost"
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                props.history.push(`/league-manager/${props.league.slug}/divisions/${record.slug}/edit`);
                            }}
                        >
                            <Icon type="edit" />
                        </Button>
                    </Tooltip>
                    {/* <Divider type="vertical" /> */}
                    <Tooltip title="Delete">
                        <Button
                            type="ghost"
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                showConfirm(record);
                            }}
                        >
                            <Icon type="delete" />
                        </Button>
                    </Tooltip>
                </span>
            ),
        },
    ];

    function showConfirm(record) {
        confirm({
            title: `Are you sure you want to remove ${record.name} from this league?`,
            // content: 'Note: This action will not remove real user accounts.',
            centered: true,
            transitionName: 'fade',
            maskTransitionName: 'none',
            // onOk() {
            // 	return new Promise((resolve, reject) => {
            // 		setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
            // 	}).catch(() => console.log('Oops errors!'));
            // },
            onOk() {
                onDelete(record);
            },
            onCancel() {},
        });
    }

    return (
        <Table
            onRow={(record, rowIndex) => {
                return {
                    onClick: (event) => {
                        console.log(record, rowIndex);
                        const slug = slugify(record.name, {
                            replacement: '-',
                            remove: /[*+~.,()'"#!:/@]/g,
                            lower: true,
                        });
                        props.history.push({
                            pathname: `/league-manager/${league.slug}/divisions/${slug}`,
                            // search: '?query=abc',
                            state: { data: record },
                        });
                    }, // click row
                    onDoubleClick: (event) => {}, // double click row
                    onContextMenu: (event) => {}, // right button click row
                    onMouseEnter: (event) => {}, // mouse enter row
                    onMouseLeave: (event) => {}, // mouse leave row
                };
            }}
            columns={columns}
            dataSource={props.divisions}
            onChange={onChange}
            tableLayout="auto"
            scroll={{ x: 400 }}
            pagination={false}
            // size="middle"
            // bordered
        />
    );
}

export default withRouter(DivisionTable);
