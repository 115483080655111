import React, { useState, useRef, useEffect } from 'react';
import { message, Modal, Button, Icon, Typography } from 'antd';
import { TwitterPicker } from 'react-color';
import { BgColorsOutlined } from '@ant-design/icons';
import emptyBox from '../../../../assets/img/empty-square-checkered.png';

function ColorPicker(props) {
    const { onSetColor, defaultColor, colors, theme } = props;
    const [showColorPicker, setShowColorPicker] = useState(false);
    const [currentColor, setCurrentColor] = useState(defaultColor || 'transparent');

    useEffect(() => {
        if (defaultColor) {
            setCurrentColor(defaultColor);
        }
    }, [defaultColor]);

    function handleClick() {
        setShowColorPicker(true);
    }

    function handleClose() {
        setShowColorPicker(false);
    }

    function handleChange(color, event) {
        console.log(color);
        setCurrentColor(color.hex);
        onSetColor(color.hex);
    }

    return (
        <div style={{ position: 'relative' }}>
            <span
                onClick={handleClick}
                style={{
                    background: currentColor === 'transparent' ? `url(${emptyBox}) 50% 50%` : currentColor,
                    height: 20,
                    width: 20,
                    cursor: 'pointer',
                    position: 'relative',
                    outline: 'none',
                    float: 'left',
                    borderRadius: 4,
                    border: theme && theme.name === 'dark' ? '1px solid rgba(255,255,255,0.25)' : '1px solid rgba(0,0,0,0.25)',
                }}
            />
            {/* <Button type="secondary" ghost onClick={handleClick} size="large" style={{ borderColor: currentColor }}>
                <BgColorsOutlined style={{ fontSize: 24, color: currentColor }} />
            </Button> */}

            {showColorPicker && (
                <div style={{ position: 'absolute', top: 34, left: -10, zIndex: 10 }}>
                    <div
                        style={{
                            position: 'fixed',
                            top: '0px',
                            right: '0px',
                            bottom: '0px',
                            left: '0px',
                        }}
                        onClick={handleClose}
                    />
                    <TwitterPicker colors={colors} onChange={handleChange} />
                </div>
            )}
        </div>
    );
}

export default ColorPicker;
