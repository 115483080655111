import gql from 'graphql-tag';

export const GET_LEAGUE_MATCHES_SUBSCRIPTION = gql`
    subscription matches($league_id: Int!, $division_id: Int) {
        matches(where: { league_id: { _eq: $league_id }, division_id: { _eq: $division_id }, status: { _neq: COMPLETED } }) {
            id
            name
            slug
            description
            match_number
            match_type
            game_type
            rule_format
            handicap_format
            scoring_format
            race_to
            player_id
            player_name
            player_score
            player_points
            player_tiebreaker_points
            player_race_to
            player_country
            player_state
            player_fargo
            player_is_winner
            opponent_id
            opponent_name
            opponent_score
            opponent_points
            opponent_tiebreaker_points
            opponent_race_to
            opponent_country
            opponent_state
            opponent_fargo
            opponent_is_winner
            winner_id
            winner_score
            loser_id
            loser_score
            status
            progress
            duration
            livestream_url
            score_verified
            scheduled_time
            start_date_time
            end_date_time
            is_public
            event_id
            venue_id
            venue {
                id
                name
                slug
                address1
                address2
                city
                region
                country
                postal_code
            }

            division_id
            division {
                id
                name
                slug
            }
            pool_table_id
            table_name
            table_size
            scoreboard_balls
            creator_id
            created_at
            updated_at
            created_at
            updated_at
            match_players {
                id
                name
                country
                player_id
                skill_level
                race_to
            }
            division_id
            division {
                id
                name
            }
        }
    }
`;

export const GET_LEAGUE_MATCH_BY_SLUG_SUBSCRIPTION = gql`
    subscription matches($league_id: Int!, $slug: String!) {
        matches(where: { league_id: { _eq: $league_id }, slug: { _eq: $slug } }, order_by: { scheduled_time: desc }) {
            id
            name
            slug
            description
            match_number
            match_type
            game_type
            rule_format
            handicap_format
            scoring_format
            race_to
            player_id
            player_name
            player_score
            player_points
            player_tiebreaker_points
            player_race_to
            player_country
            player_state
            player_fargo
            player_is_winner
            opponent_id
            opponent_name
            opponent_score
            opponent_points
            opponent_tiebreaker_points
            opponent_race_to
            opponent_country
            opponent_state
            opponent_fargo
            opponent_is_winner
            winner_id
            winner_score
            loser_id
            loser_score
            status
            progress
            duration
            livestream_url
            score_verified
            scheduled_time
            start_date_time
            end_date_time
            is_public
            event_id
            venue_id
            venue {
                id
                name
                slug
                address1
                address2
                city
                region
                country
                postal_code
            }

            division_id
            division {
                id
                name
                slug
            }
            pool_table_id
            table_name
            table_size
            scoreboard_balls
            creator_id
            created_at
            updated_at
            created_at
            updated_at
            match_players {
                id
                name
                country
                player_id
                skill_level
                race_to
            }
            division_id
            division {
                id
                name
            }
        }
    }
`;

export const GET_LEAGUE_MATCH_SUBSCRIPTION = gql`
    subscription matches($league_id: Int!, $match_id: Int) {
        matches(where: { league_id: { _eq: $league_id }, id: { _eq: $match_id } }, order_by: { scheduled_time: desc }) {
            id
            name
            slug
            description
            match_number
            match_type
            game_type
            rule_format
            handicap_format
            scoring_format
            race_to
            player_id
            player_name
            player_score
            player_points
            player_tiebreaker_points
            player_race_to
            player_country
            player_state
            player_fargo
            player_is_winner
            opponent_id
            opponent_name
            opponent_score
            opponent_points
            opponent_tiebreaker_points
            opponent_race_to
            opponent_country
            opponent_state
            opponent_fargo
            opponent_is_winner
            winner_id
            winner_score
            loser_id
            loser_score
            status
            progress
            duration
            livestream_url
            score_verified
            scheduled_time
            start_date_time
            end_date_time
            is_public
            event_id
            venue_id
            venue {
                id
                name
                slug
                address1
                address2
                city
                region
                country
                postal_code
            }

            division_id
            division {
                id
                name
                slug
            }
            pool_table_id
            table_name
            table_size
            scoreboard_balls
            creator_id
            created_at
            updated_at
            created_at
            updated_at
            match_players {
                id
                name
                country
                player_id
                skill_level
                race_to
            }
            division_id
            division {
                id
                name
            }
        }
    }
`;

export const GET_LEAGUE_PLAYER_STANDINGS_SUBSCRIPTION = gql`
    subscription league_player_stats($league_id: Int!) {
        league_players(where: { league_id: { _eq: $league_id }, place: { _is_null: false } }, distinct_on: id) {
            id
            name
            place
            country
            skill_level
            points
            challenger1_matches_won: league_matches_challenger1_aggregate(
                where: { challenger1_is_winner: { _eq: true }, status: { _eq: COMPLETED } }
                order_by: { match_number: asc }
            ) {
                aggregate {
                    count
                }
            }
            challenger1_matches_lost: league_matches_challenger1_aggregate(
                where: { challenger1_is_winner: { _eq: false }, status: { _eq: COMPLETED } }
                order_by: { match_number: asc }
            ) {
                aggregate {
                    count
                }
            }
            challenger2_matches_won: league_matches_challenger2_aggregate(
                where: { challenger2_is_winner: { _eq: true }, status: { _eq: COMPLETED } }
                order_by: { match_number: asc }
            ) {
                aggregate {
                    count
                }
            }
            challenger2_matches_lost: league_matches_challenger2_aggregate(
                where: { challenger2_is_winner: { _eq: false }, status: { _eq: COMPLETED } }
                order_by: { match_number: asc }
            ) {
                aggregate {
                    count
                }
            }
            challenger1_matches_games: league_matches_challenger1_aggregate(where: { status: { _eq: COMPLETED } }, order_by: { match_number: asc }) {
                aggregate {
                    count
                    sum {
                        challenger1_score
                        challenger2_score
                    }
                }
                nodes {
                    start_time
                    end_time
                }
            }
            challenger2_matches_games: league_matches_challenger2_aggregate(where: { status: { _eq: COMPLETED } }, order_by: { match_number: asc }) {
                aggregate {
                    count
                    sum {
                        challenger1_score
                        challenger2_score
                    }
                }
                nodes {
                    start_time
                    end_time
                }
            }
        }
    }
`;

export const GET_LEAGUE_PLAYER_STATS_SUBSCRIPTION = gql`
    subscription league_player_stats($league_id: Int!) {
        league_players(where: { league_id: { _eq: $league_id } }, order_by: { name: asc }) {
            id
            name
            place
            country
            player {
                player_matches_won: player_matches_aggregate(
                    where: { player_is_winner: { _eq: true }, status: { _eq: COMPLETED } }
                    order_by: { match_number: asc }
                ) {
                    aggregate {
                        count
                    }
                }
                player_matches_lost: player_matches_aggregate(
                    where: { player_is_winner: { _eq: false }, status: { _eq: COMPLETED } }
                    order_by: { match_number: asc }
                ) {
                    aggregate {
                        count
                    }
                }
                opponent_matches_won: opponent_matches_aggregate(
                    where: { opponent_is_winner: { _eq: true }, status: { _eq: COMPLETED } }
                    order_by: { match_number: asc }
                ) {
                    aggregate {
                        count
                    }
                }
                opponent_matches_lost: opponent_matches_aggregate(
                    where: { opponent_is_winner: { _eq: false }, status: { _eq: COMPLETED } }
                    order_by: { match_number: asc }
                ) {
                    aggregate {
                        count
                    }
                }
                player_matches_games: player_matches_aggregate(where: { status: { _eq: COMPLETED } }, order_by: { match_number: asc }) {
                    aggregate {
                        count
                        sum {
                            player_score
                            opponent_score
                        }
                    }
                    nodes {
                        start_date_time
                        end_date_time
                    }
                }
                opponent_matches_games: opponent_matches_aggregate(where: { status: { _eq: COMPLETED } }, order_by: { match_number: asc }) {
                    aggregate {
                        count
                        sum {
                            player_score
                            opponent_score
                        }
                    }
                    nodes {
                        start_date_time
                        end_date_time
                    }
                }
            }
        }
    }
`;

export const GET_LEAGUE_DIVISION_PLAYER_STATS_SUBSCRIPTION = gql`
    subscription league_players($league_id: Int!, $division_id: Int!) {
        league_players(where: { league_id: { _eq: $league_id } }, order_by: { name: asc }) {
            id
            name
            country
            skill_level
            place
            points
            league_player_divisions(where: { division_id: { _eq: $division_id } }) {
                id
            }
            challenger1_matches_won: league_matches_challenger1_aggregate(
                where: { challenger1_is_winner: { _eq: true }, status: { _eq: COMPLETED } }
                order_by: { match_number: asc }
            ) {
                aggregate {
                    count
                }
            }
            challenger1_matches_lost: league_matches_challenger1_aggregate(
                where: { challenger1_is_winner: { _eq: false }, status: { _eq: COMPLETED } }
                order_by: { match_number: asc }
            ) {
                aggregate {
                    count
                }
            }
            challenger2_matches_won: league_matches_challenger2_aggregate(
                where: { challenger2_is_winner: { _eq: true }, status: { _eq: COMPLETED } }
                order_by: { match_number: asc }
            ) {
                aggregate {
                    count
                }
            }
            challenger2_matches_lost: league_matches_challenger2_aggregate(
                where: { challenger2_is_winner: { _eq: false }, status: { _eq: COMPLETED } }
                order_by: { match_number: asc }
            ) {
                aggregate {
                    count
                }
            }
            challenger1_matches_games: league_matches_challenger1_aggregate(where: { status: { _eq: COMPLETED } }, order_by: { match_number: asc }) {
                aggregate {
                    count
                    sum {
                        challenger1_score
                        challenger2_score
                        challenger1_points
                        challenger2_points
                        challenger1_balls_pocketed
                        challenger2_balls_pocketed
                        challenger1_errors
                        challenger2_errors
                        challenger1_high_break
                        challenger2_high_break
                    }
                }
                nodes {
                    start_time
                    end_time
                }
            }
            challenger2_matches_games: league_matches_challenger2_aggregate(where: { status: { _eq: COMPLETED } }, order_by: { match_number: asc }) {
                aggregate {
                    count
                    sum {
                        challenger1_score
                        challenger2_score
                        challenger1_points
                        challenger2_points
                        challenger1_balls_pocketed
                        challenger2_balls_pocketed
                        challenger1_errors
                        challenger2_errors
                        challenger1_high_break
                        challenger2_high_break
                    }
                }
                nodes {
                    start_time
                    end_time
                }
            }
            challenger1_matches_frames: league_matches_challenger1_aggregate(where: { status: { _eq: COMPLETED } }, order_by: { match_number: asc }) {
                aggregate {
                    count
                    sum {
                        challenger1_frames
                        challenger2_frames
                    }
                }
                nodes {
                    start_time
                    end_time
                }
            }
            challenger2_matches_frames: league_matches_challenger2_aggregate(where: { status: { _eq: COMPLETED } }, order_by: { match_number: asc }) {
                aggregate {
                    count
                    sum {
                        challenger1_frames
                        challenger2_frames
                    }
                }
                nodes {
                    start_time
                    end_time
                }
            }
            challenger1_matches_points: league_matches_challenger1_aggregate(where: { status: { _eq: COMPLETED } }, order_by: { match_number: asc }) {
                aggregate {
                    count
                    sum {
                        challenger1_points
                        challenger2_points
                    }
                }
                nodes {
                    start_time
                    end_time
                }
            }
            challenger2_matches_points: league_matches_challenger2_aggregate(where: { status: { _eq: COMPLETED } }, order_by: { match_number: asc }) {
                aggregate {
                    count
                    sum {
                        challenger1_points
                        challenger2_points
                    }
                }
                nodes {
                    start_time
                    end_time
                }
            }
        }
    }
`;

export const GET_LEAGUE_DIVISION_MATCH_STATS_SUBSCRIPTION = gql`
    subscription league_matches($league_id: Int!, $division_id: Int!, $match_id: Int!) {
        league_matches(where: { league_id: { _eq: $league_id }, division_id: { _eq: $division_id }, id: { _eq: $match_id } }) {
            id
            challenger1_id
            challenger1_name
            challenger1_country
            challenger1_is_winner
            challenger1_score
            challenger1_frames
            challenger1_points
            challenger2_id
            challenger2_name
            challenger2_country
            challenger2_is_winner
            challenger2_score
            challenger2_frames
            challenger2_points
            league_match_frames_aggregate {
                aggregate {
                    count
                    sum {
                        challenger1_points
                        challenger2_points
                        challenger1_balls_pocketed
                        challenger2_balls_pocketed
                        challenger1_errors
                        challenger2_errors
                        challenger1_high_break
                        challenger2_high_break
                    }
                }
                nodes {
                    id
                    challenger1_points
                    challenger2_points
                    challenger1_balls_pocketed
                    challenger2_balls_pocketed
                    challenger1_errors
                    challenger2_errors
                    challenger1_high_break
                    challenger2_high_break
                }
            }
        }
    }
`;
