import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Empty, Col, Typography } from 'antd';
import Card, { Meta } from '../../../components/Card';
import CircularLoader from '../../../components/CircularLoader';
import { Subscription } from '@apollo/client/react/components';
import { GET_MATCH_BY_SLUG_SUBSCRIPTION } from './data/subscriptions';
import qs from 'query-string';
import StreamOverlayLayout1 from './StreamOverlayLayout1';
import StreamOverlayLayout2 from './StreamOverlayLayout2';
import StreamOverlayLayout3 from './StreamOverlayLayout3';
import StreamOverlayOmega from './StreamOverlayOmega';
import ZeroState from '../../../components/ZeroState';
import numeral from 'numeral';
import moment from 'moment-timezone';
import momentDurationFormatSetup from 'moment-duration-format';
momentDurationFormatSetup(moment);

const { Title, Text } = Typography;

const styles = {
    cellTitle: {
        width: 50,
        textAlign: 'center',
        fontSize: 12,
        lineHeight: '2em',
    },
    cell1: {
        background: 'linear-gradient(90deg, rgb(0, 137, 205), rgb(0, 69, 181))',
        width: 50,
        color: '#fff',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontWeight: 600,
        fontSize: 16,
        height: '100%',
    },
    cell2: {
        width: 50,
        background: 'linear-gradient(-90deg, black, transparent)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontWeight: 600,
        fontSize: 16,
        height: '100%',
    },
};

function MatchStreamOverlay(props) {
    const { client } = props;
    console.log(props);
    const params = qs.parse(props.location.search, { ignoreQueryPrefix: true });
    const layout = params.layout || '1';

    return (
        <Subscription
            subscription={GET_MATCH_BY_SLUG_SUBSCRIPTION}
            fetchPolicy="cache-and-network"
            notifyOnNetworkStatusChange
            awaitRefetchQueries
            variables={{ slug: props.match.params.slug }}
            onSubscriptionData={(res) => {
                const match =
                    res && res.subscriptionData && res.subscriptionData.data && res.subscriptionData.data.matches && res.subscriptionData.data.matches[0];

                console.log(match);

                // setMatchData(match);
                // if (!currentFrame) {
                // 	setCurrentFrame(frames[0]);
                // } else {
                // 	setCurrentFrame(frames.filter((frame) => frame.id === currentFrame.id)[0]);
                // }
            }}
        >
            {({ loading, error, data }) => {
                if (loading) return <CircularLoader />;
                if (error) return <div style={styles.container}>Error: {error.message}</div>;
                if ((data && !data.matches) || (data && data.matches.length === 0)) {
                    return (
                        <Col xs={24}>
                            <Card
                                bodyStyle={{ padding: 0 }}
                                // style={{
                                // 	width: '100%',
                                // 	borderRadius: 8,
                                // 	overflow: 'hidden',
                                // 	border: 'none',
                                // 	boxShadow: '0 2px 5px 0px rgba(0, 0, 0, 0.1)'
                                // }}
                            >
                                <ZeroState showImage message="That match does not exist." style={{ margin: 30 }} {...props} />
                            </Card>
                        </Col>
                    );
                }

                const matchData = data.matches && data.matches[0];
                const ballsMade = matchData.scoreboard_balls || {};

                let duration;
                if (matchData.start_time && matchData.end_time) {
                    var start = moment(matchData.start_time);
                    var end = moment(matchData.end_time);
                    duration = moment.duration(end.diff(start, 'milliseconds')).format('h [hrs] m [min]');
                    // duration = moment.duration(end.diff(start, 'milliseconds')).format('hh:mm');
                } else {
                    duration = moment.duration(moment.utc().diff(matchData.start_date_time, 'milliseconds')).format('h [hours] m [minutes]');
                    // duration = moment.duration(moment.utc().diff(matchData.start_time, 'milliseconds')).format('hh:mm');
                }

                const matchStats = matchData.match_stats;
                const playerStats = matchStats.filter((stat) => {
                    return stat.metric.indexOf('player_') !== -1;
                });

                const opponentStats = matchStats.filter((stat) => {
                    return stat.metric.indexOf('opponent_') !== -1;
                });

                // TPA formula = balls pocketed / balls pocketed + errors
                function calculateTPA(ballsPocketed, errors) {
                    return numeral(ballsPocketed / (ballsPocketed + errors)).format('0.000');
                }

                function getPlayerTPA() {
                    const playerBallsPocketed = playerStats.filter((stat) => stat.metric === 'player_balls_pocketed')[0];
                    const playerBallsPocketedValue = playerBallsPocketed ? playerBallsPocketed.value : 0;
                    const playerErrors = playerStats.filter((stat) => stat.metric === 'player_unforced_errors')[0];
                    const playerErrorValue = playerErrors ? playerErrors.value : 0;
                    const tpaScore = calculateTPA(playerBallsPocketedValue, playerErrorValue);
                    return tpaScore;
                }

                function getOpponentTPA() {
                    const opponentBallsPocketed = opponentStats.filter((stat) => stat.metric === 'opponent_balls_pocketed')[0];
                    const opponentBallsPocketedValue = opponentBallsPocketed ? opponentBallsPocketed.value : 0;
                    const opponentErrors = opponentStats.filter((stat) => stat.metric === 'opponent_unforced_errors')[0];
                    const opponentErrorValue = opponentErrors ? opponentErrors.value : 0;
                    const tpaScore = calculateTPA(opponentBallsPocketedValue, opponentErrorValue);
                    return tpaScore;
                }

                const stats = {
                    playerStats,
                    playerTPA: getPlayerTPA(),
                    opponentStats,
                    opponentTPA: getOpponentTPA(),
                };

                return (
                    <React.Fragment>
                        {layout === '1' ? (
                            <StreamOverlayLayout1 matchData={matchData} ballsMade={ballsMade} stats={stats} params={params} {...props} />
                        ) : layout === '2' ? (
                            <StreamOverlayLayout2 matchData={matchData} ballsMade={ballsMade} stats={stats} params={params} {...props} />
                        ) : layout === '3' ? (
                            <StreamOverlayLayout3 matchData={matchData} ballsMade={ballsMade} stats={stats} params={params} {...props} />
                        ) : layout === 'omegatv' || layout === 'omegatv2' ? (
                            <StreamOverlayOmega matchData={matchData} ballsMade={ballsMade} stats={stats} params={params} {...props} />
                        ) : (
                            <StreamOverlayLayout1 matchData={matchData} ballsMade={ballsMade} stats={stats} params={params} {...props} />
                        )}
                    </React.Fragment>
                );
            }}
        </Subscription>
    );
}

export default withRouter(MatchStreamOverlay);
