import React, { useState } from 'react';
import { Subscription } from '@apollo/client/react/components';
import { Button, notification } from 'antd';
import { GET_NOTIFICATIONS_SEEN } from './screens/digitalpool/data/subscriptions';
import { store, view } from 'react-easy-state';
import appStore from './lib/appStore';

export const NotificationContext = React.createContext(null);

notification.config({
    placement: 'topRight',
    top: 80,
    duration: 3,
});

function NotificationProvider(props) {
    const { authState } = props;
    const [isRendered, setIsRendered] = useState(false);

    const openNotification = (data) => {
        console.log(data);
        notification['info']({
            message: data.title,
            description: data.description,
            onClick: () => {
                props.history.push('/notifications');
            },
        });
    };

    return (
        <React.Fragment>
            {authState && authState.user && authState.user.id ? (
                <Subscription
                    subscription={GET_NOTIFICATIONS_SEEN}
                    fetchPolicy="cache-and-network"
                    notifyOnNetworkStatusChange
                    variables={{ user_id: authState && authState.user && authState.user.id }}
                    onSubscriptionData={(res) => {
                        let data = res && res.subscriptionData && res.subscriptionData.data && res.subscriptionData.data.notifications;
                        let count = 0;
                        if (data.length) {
                            for (let i = 0; i < data.length; i++) {
                                if (data[i].seen === false) {
                                    if (isRendered === true && i === data.length - 1) {
                                        openNotification(data[i]);
                                    }
                                    count++;
                                }
                            }
                            appStore.notificationCount = count;
                            appStore.notifications = data;
                        } else {
                            appStore.notificationCount = 0;
                            appStore.notifications = [];
                        }

                        setIsRendered(true);
                    }}
                >
                    {({ loading, error, data }) => {
                        return (
                            <NotificationContext.Provider
                                value={{
                                    notificationCount: appStore.notificationCount,
                                    notifications: appStore.notifications,
                                    notificationLoading: loading,
                                }}
                            >
                                {props.children}
                            </NotificationContext.Provider>
                        );
                    }}
                </Subscription>
            ) : (
                <React.Fragment>{props.children}</React.Fragment>
            )}
        </React.Fragment>
    );
}

export default view(NotificationProvider);

// var count = 0;
// const notifications = data && data && data.notifications;

// notifications &&
//   notifications.forEach((notification) => {
//     if (notification.seen === false) {
//       count++;
//     }
//   });

// import React, { useState } from 'react';
// import { Subscription } from '@apollo/client/react/components';
// import { GET_NOTIFICATIONS_SEEN } from './screens/digitalpool/data/subscriptions';

// export const NotificationContext = React.createContext(null);

// function NotificationProvider(props) {
// 	const { authState } = props;
// 	// const [ notificationCount, setNotificationCount ] = useState(0);

// 	return (
// 		<Subscription
// 			subscription={GET_NOTIFICATIONS_SEEN}
// 			fetchPolicy="cache-and-network"
// 			notifyOnNetworkStatusChange
// 			variables={{ user_id: authState.user.id }}
// 			onSubscriptionData={(res) => {
// 				// let data =
// 				// 	res && res.subscriptionData && res.subscriptionData.data && res.subscriptionData.data.notifications;
// 				// let count = 0;
// 				// if (data.length) {
// 				// 	for (let i = 0; i < data.length; i++) {
// 				// 		if (data[i].seen === false) {
// 				// 			count++;
// 				// 		}
// 				// 	}
// 				// 	setNotificationCount(count);
// 				// } else {
// 				// 	setNotificationCount(0);
// 				// }
// 			}}
// 		>
// 			{({ loading, error, data }) => {
// 				var count = 0;
// 				const notifications = data && data && data.notifications;

// 				notifications &&
// 					notifications.forEach((notification) => {
// 						if (notification.seen === false) {
// 							count++;
// 						}
// 					});

// 				return (
// 					<NotificationContext.Provider
// 						value={{
// 							notificationCount: count
// 						}}
// 					>
// 						{props.children}
// 					</NotificationContext.Provider>
// 				);
// 			}}
// 		</Subscription>
// 	);
// }

// export default NotificationProvider;
