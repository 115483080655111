import React, { useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { message, Descriptions, Tooltip, Row, Avatar, Button, Empty, Tag, Icon, List, Skeleton, Col, Typography } from 'antd';
import SectionContent from '../../../components/SectionContent';
import SectionWrapper from '../../../components/SectionWrapper';
import Card, { Meta } from '../../../components/Card';
import CircularLoader from '../../../components/CircularLoader';
import { getGameType, displayEnumType } from '../../../utils';
import { Subscription } from '@apollo/client/react/components';
import { GET_MATCH_BY_SLUG_SUBSCRIPTION } from './data/subscriptions';
import { UPDATE_MATCH_MUTATION } from './data/mutations';
import { GET_MATCH_QUERY, GET_MATCH_PLAYER_SCORES_QUERY } from './data/queries';
import useBreakpoint from 'use-breakpoint';
import Flag from '../../../components/Flag';
import qs from 'query-string';
import ZeroState from '../../../components/ZeroState';
import { defaultBreakpoints } from '../../../utils/defaultBreakpoints';
import moment from 'moment-timezone';
import momentDurationFormatSetup from 'moment-duration-format';
momentDurationFormatSetup(moment);

const { Title, Text } = Typography;

const styles = {
    cellTitle: {
        width: 50,
        textAlign: 'center',

        lineHeight: '2em',
        color: 'rgba(255,255,255,0.7)',
    },
    cell1: {
        background: 'linear-gradient(90deg, rgb(0, 137, 205), rgb(0, 69, 181))',
        width: 100,
        paddingLeft: 10,
        paddingRight: 10,
        color: '#fff',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontWeight: 700,
        fontSize: 40,
        height: '100%',
    },
    cell2: {
        background: 'linear-gradient(90deg, black, transparent)',
        display: 'flex',
        width: 100,
        paddingLeft: 10,
        paddingRight: 10,
        alignItems: 'center',
        justifyContent: 'center',
        fontWeight: 700,
        fontSize: 40,
        height: '100%',
    },
};

function MatchLargeScoreOverlay(props) {
    console.log(props);
    const params = qs.parse(props.location.search, { ignoreQueryPrefix: true });
    console.log(params);

    return (
        <Subscription
            subscription={GET_MATCH_BY_SLUG_SUBSCRIPTION}
            fetchPolicy="cache-and-network"
            notifyOnNetworkStatusChange
            awaitRefetchQueries
            variables={{ slug: props.match.params.slug }}
            onSubscriptionData={(res) => {
                const match =
                    res && res.subscriptionData && res.subscriptionData.data && res.subscriptionData.data.matches && res.subscriptionData.data.matches[0];

                console.log(match);

                // setMatchData(match);
                // if (!currentFrame) {
                // 	setCurrentFrame(frames[0]);
                // } else {
                // 	setCurrentFrame(frames.filter((frame) => frame.id === currentFrame.id)[0]);
                // }
            }}
        >
            {({ loading, error, data }) => {
                if (loading) return <CircularLoader />;
                if (error) return <div style={styles.container}>Error: {error.message}</div>;
                if ((data && !data.matches) || (data && data.matches.length === 0)) {
                    return (
                        <Col xs={24}>
                            <Card
                                bodyStyle={{ padding: 0 }}
                                // style={{
                                // 	width: '100%',
                                // 	borderRadius: 8,
                                // 	overflow: 'hidden',
                                // 	border: 'none',
                                // 	boxShadow: '0 2px 5px 0px rgba(0, 0, 0, 0.1)'
                                // }}
                            >
                                <ZeroState showImage message="That match does not exist." style={{ margin: 30 }} {...props} />
                            </Card>
                        </Col>
                    );
                }

                const matchData = data.matches && data.matches[0];
                const ballsMade = matchData.scoreboard_balls || {};

                let duration;
                if (matchData.start_time && matchData.end_time) {
                    var start = moment(matchData.start_time);
                    var end = moment(matchData.end_time);
                    duration = moment.duration(end.diff(start, 'milliseconds')).format('h [hrs] m [min]');
                    // duration = moment.duration(end.diff(start, 'milliseconds')).format('hh:mm');
                } else {
                    duration = moment.duration(moment.utc().diff(matchData.start_date_time, 'milliseconds')).format('h [hours] m [minutes]');
                    // duration = moment.duration(moment.utc().diff(matchData.start_time, 'milliseconds')).format('hh:mm');
                }

                function showBallCount() {
                    return params.show_ball_count === 'true' || matchData.game_type === 'ONE_POCKET' || matchData.game_type === 'STRAIGHT_POOL';
                }

                function showBallTracker() {
                    return params.show_balls !== 'false' ? true : false;
                }

                return (
                    <div
                        style={{
                            minWidth: 300,

                            // position: 'absolute',
                            // left: '50%',
                            // top: '50%',
                            // transform: 'translate(-50%, -50%)',
                            borderRadius: 8,
                            overflow: 'hidden',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                flexDirection: 'column',
                                color: '#fff',
                            }}
                        >
                            <div
                                style={{
                                    background: 'linear-gradient(rgb(32, 54, 76), rgb(17, 27, 39))',
                                    display: 'flex',
                                    width: '100%',
                                    marginBottom: 1,
                                }}
                            >
                                <div
                                    style={{
                                        flex: 1,
                                        paddingTop: 5,
                                        paddingBottom: 5,
                                        paddingLeft: 10,
                                        paddingRight: 10,
                                    }}
                                >
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        {matchData.player_country && (
                                            <Flag
                                                name={matchData.player_country || 'Unknown'}
                                                format="png"
                                                pngSize={32}
                                                shiny={false}
                                                alt="Flag"
                                                className="flag"
                                                basePath="/images/flags"
                                            />
                                        )}

                                        <div style={{ fontSize: 30, marginLeft: 8 }}>{matchData.player_name}</div>
                                    </div>
                                </div>
                                {showBallCount() ? (
                                    <React.Fragment>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <div style={styles.cell2}>{matchData.player_points}</div>
                                            <div style={styles.cell1}>{matchData.player_score}</div>
                                        </div>
                                    </React.Fragment>
                                ) : (
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <div style={styles.cell1}>{matchData.player_score}</div>
                                    </div>
                                )}
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    width: '100%',
                                    background: 'linear-gradient(rgb(32, 54, 76), rgb(17, 27, 39))',
                                }}
                            >
                                <div
                                    style={{
                                        flex: 1,
                                        paddingTop: 5,
                                        paddingBottom: 5,
                                        paddingLeft: 10,
                                        paddingRight: 10,
                                    }}
                                >
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        {matchData.opponent_country && (
                                            <Flag
                                                name={matchData.opponent_country || 'Unknown'}
                                                format="png"
                                                pngSize={32}
                                                shiny={false}
                                                alt="Flag"
                                                className="flag"
                                                basePath="/images/flags"
                                            />
                                        )}

                                        <div style={{ fontSize: 30, marginLeft: 8 }}>{matchData.opponent_name}</div>
                                    </div>
                                </div>

                                {showBallCount() ? (
                                    <React.Fragment>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <div style={styles.cell2}>{matchData.opponent_points}</div>
                                            <div style={styles.cell1}>{matchData.opponent_score}</div>
                                        </div>
                                    </React.Fragment>
                                ) : (
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <div style={styles.cell1}>{matchData.opponent_score}</div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                );
            }}
        </Subscription>
    );
}

export default withRouter(MatchLargeScoreOverlay);
