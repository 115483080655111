import React from 'react';
import { Tabs } from 'antd';
import UserLeagues from './UserLeagues';
import UserTournaments from './UserTournaments';
import UserNearbyVenues from './UserNearbyVenues';

const { TabPane } = Tabs;

const styles = {
    tabPane: {
        padding: 20,
    },
};

export default function ProfileTabs(props) {
    const { user } = props;

    function callback(key) {
        // console.log(key);
    }

    return (
        <Tabs
            // onTabClick={handleTabClick}

            animated={{ inkBar: true, tabPane: false }}
            tabBarStyle={{
                textTransform: 'uppercase',
                paddingLeft: 10,
                paddingRight: 10,
            }}
            defaultActiveKey="tournaments"
            onChange={callback}
            tabBarGutter={10}
        >
            {/* <TabPane tab="Feed" key="1" style={styles.tabPane}>
				Content of Tab Pane 1
			</TabPane> */}
            <TabPane
                tab={`Tournaments (${
                    (user &&
                        user.tournament_players_aggregate &&
                        user.tournament_players_aggregate.aggregate &&
                        user.tournament_players_aggregate.aggregate.count) +
                        (user &&
                            user.tournament_organizer_aggregate &&
                            user.tournament_organizer_aggregate.aggregate &&
                            user.tournament_organizer_aggregate.aggregate.count) || 0
                })`}
                key="tournaments"
                style={styles.tabPane}
            >
                <UserTournaments {...props} />
            </TabPane>
            <TabPane
                tab={`Leagues (${
                    (user && user.league_players_aggregate && user.league_players_aggregate.aggregate && user.league_players_aggregate.aggregate.count) +
                        (user && user.league_owner_aggregate && user.league_owner_aggregate.aggregate && user.league_owner_aggregate.aggregate.count) || 0
                })`}
                key="leagues"
                style={styles.tabPane}
            >
                <UserLeagues {...props} />
            </TabPane>
            <TabPane tab={`Venues Near Me`} key="venues" style={styles.tabPane}>
                <UserNearbyVenues {...props} />
            </TabPane>
        </Tabs>
    );
}
