import React, { useState, useEffect } from 'react';
import { Popconfirm, Table, Modal, message, Layout, Row, Col, Steps, Empty, Alert, Typography, Button, Icon, Result } from 'antd';
import { Query } from '@apollo/client/react/components';
import {
    GET_TOURNAMENT_CHIP_AMOUNTS,
    GET_TOURNAMENT_PLAYERS_QUERY,
    GET_TOURNAMENT_QUERY,
    GET_TOURNAMENT_BY_SLUG_QUERY,
    GET_TOURNAMENT_TABLES_QUERY,
} from './data/queries';
import {
    UPDATE_TOURNAMENT_PLAYER_MUTATION,
    CREATE_TOURNAMENT_BRACKET_MUTATION,
    DELETE_TOURNAMENT_MATCHES,
    RESET_TABLE_STATUS_MUTATION,
    UPDATE_TOURNAMENT_MUTATION,
    UPDATE_TOURNAMENT_PLAYERS,
} from './data/mutations';
import { useMutation } from '@apollo/react-hooks';
import { ReloadOutlined } from '@ant-design/icons';
import CircularLoader from '../../components/CircularLoader';
import { DigitalPoolAPI } from '../../lib/api';
import { getCountryName } from '../../utils';
import Hashids from 'hashids';
import _ from 'lodash';
import moment from 'moment-timezone';
import momentDurationFormatSetup from 'moment-duration-format';
momentDurationFormatSetup(moment);

const { Text, Title } = Typography;
const { confirm } = Modal;

const styles = {
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        padding: 20,
    },
};

var seeder = function () {
    var seed = [];
    return {
        set: function (length) {
            for (var i = 0; i < length; i++) {
                seed.push(Math.random());
            }
            return seed;
        },
        get: function () {
            return seed;
        },
        clear: function () {
            seed = [];
        },
    };
};

function TBPreviewRoundRobin(props) {
    const { client, tournament, goBack, onContinue } = props;
    console.log(tournament);
    const [tournamentPlayers, setTournamentPlayers] = useState([]);
    const [chipAmounts, setChipAmounts] = useState(null);
    const [matches, setMatches] = useState();
    const [createMatchesMutation] = useMutation(CREATE_TOURNAMENT_BRACKET_MUTATION);
    const [updateTournament] = useMutation(UPDATE_TOURNAMENT_MUTATION);
    const seed = seeder();
    const hashids = new Hashids(`Tournament salt`);
    const tables =
        tournament && tournament.pool_tables_aggregate && tournament.pool_tables_aggregate.aggregate && tournament.pool_tables_aggregate.aggregate.count;

    function randomShuffle(ar, seed) {
        var numbers = [];
        for (var a = 0, max = ar.length; a < max; a++) {
            numbers.push(a);
        }
        var shuffled = [];

        for (var i = 0, len = ar.length; i < len; i++) {
            var r = parseInt(seed[i] * (len - i));
            shuffled.push(ar[numbers[r]]);
            numbers.splice(r, 1);
        }
        return shuffled;
    }

    function getRandomArbitrary(min, max) {
        return Math.random() * (max - min) + min;
    }

    function generateSeeds(playerData, chips) {
        seed.set(playerData.length);
        const randShuffle = randomShuffle(playerData, seed.get());
        // const roundRobinPairings = makeRoundRobinPairings(randShuffle);
        // const roundRobinPairings2 = roundRobin(randShuffle.length, randShuffle);
        console.log('shuffle', randShuffle);
        const updatedPlayers = randShuffle.map((player) => {
            return {
                ...player,
                chips_left: getPlayerChipCount(player, chips),
            };
        });

        console.log('updated players', updatedPlayers);

        return _.compact(updatedPlayers);
    }

    function generateMatches(players) {
        let matchData = [];
        const roundData = makeRoundRobinPairings2(players);
        console.log('round data', roundData);
        let counter = 1;

        roundData.forEach((match, index) => {
            const identifier = `R${match.round}-M${counter}`;
            const randomNum = getRandomArbitrary(1, 10000).toFixed(0);
            const hashId = hashids.encode(randomNum, tournament.id, index + 1);
            const player = match.players[0];
            const opponent = match.players[1];
            const updatedMatch = {
                // ...match,
                round: match.round,
                tournament_id: tournament.id,
                match_number: counter,
                identifier: identifier,
                hash_id: hashId,
                challenger1_id: player && player.id,
                challenger1_name: player && player.name,
                challenger1_seed: match.order[0],
                challenger1_country: player && player.country,
                challenger1_score: 0,
                challenger1_race_to: (player && player.race_to) || tournament.winners_race_to,
                challenger1_skill_level: player && player.skill_level,
                challenger2_id: opponent && opponent.id,
                challenger2_name: opponent && opponent.name,
                challenger2_seed: match.order[1],
                challenger2_country: opponent && opponent.country,
                challenger2_score: 0,
                challenger2_race_to: (opponent && opponent.race_to) || tournament.winners_race_to,
                challenger2_skill_level: opponent && opponent.skill_level,
            };

            if (updatedMatch.challenger1_id && updatedMatch.challenger2_id) {
                matchData.push(updatedMatch);
                counter++;
            }
        });

        return matchData;
    }

    function makeRoundRobinPairings(players) {
        // if (players.length % 2 === 1) {
        //     players.push(null);
        // }

        const playerCount = players.length;
        const rounds = 1;
        const half = playerCount / 2;
        const tournamentPairings = [];
        const playerIndexes = players.map((_, i) => i).slice(1);
        let counter = 1;

        for (let round = 0; round < rounds; round++) {
            const roundPairings = [];
            const newPlayerIndexes = [0].concat(playerIndexes);
            const firstHalf = newPlayerIndexes.slice(0, half);
            const secondHalf = newPlayerIndexes.slice(half, playerCount).reverse();

            for (let i = 0; i < firstHalf.length; i++) {
                // roundPairings.push([players[firstHalf[i]], players[secondHalf[i]]]);
                const match = {
                    round: round + 1,
                    match_number: counter,
                    players: [players[firstHalf[i]], players[secondHalf[i]]],
                    order: [firstHalf[i] + 1, secondHalf[i] + 1],
                };
                roundPairings.push(match);
                counter++;
            }
            // rotating the array
            playerIndexes.push(playerIndexes.shift());
            shuffle(roundPairings);
            tournamentPairings.push(roundPairings);
        }

        console.log(tournamentPairings[0]);

        return tournamentPairings[0];
    }

    function makeRoundRobinPairings2(players) {
        const playerCount = players.length;
        const tournamentPairings = [];
        const roundPairings = [];
        const playersPerGroup = tournament.players_per_table || 2;
        const totalGroups = Math.round(playerCount / playersPerGroup);
        let counter = 1;
        const rounds = 1;
        console.log('total groups', totalGroups);

        let playerGroups = players.reduce((resultArray, item, index) => {
            const groupIndex = Math.floor(index / playersPerGroup);
            if (!resultArray[groupIndex]) {
                resultArray[groupIndex] = []; // start a new chunk
            }
            resultArray[groupIndex].push(item);
            return resultArray;
        }, []);

        playerGroups.forEach((group, i) => {
            const match = {
                round: rounds,
                match_number: counter,
                players: [group[0], group[1]],
                order: [i + counter, i + counter + 1],
            };
            roundPairings.push(match);
            counter++;
        });

        tournamentPairings.push(roundPairings);
        return tournamentPairings[0];
    }

    function shuffle(array) {
        var m = array.length,
            t,
            i;
        // While there remain elements to shuffle…
        while (m) {
            // Pick a remaining element…
            i = Math.floor(Math.random() * m--);
            // And swap it with the current element.
            t = array[m];
            array[m] = array[i];
            array[i] = t;
        }

        return array;
    }

    function getPlayerChipCount(player, chips) {
        const playerSkillLevel = parseInt(player.skill_level);
        let playerChips;
        console.log('player', player);
        console.log('chips', chips);
        if (player.chips_left && parseInt(player.chips_left) > 0) {
            playerChips = parseInt(player.chips_left);
        } else if (playerSkillLevel && chips) {
            chips.forEach((item) => {
                const skillLevel = item.skill_level.split('-');
                if (skillLevel[0] && skillLevel[1]) {
                    const min = parseInt(skillLevel[0]);
                    const max = parseInt(skillLevel[1]);

                    if (playerSkillLevel >= min && playerSkillLevel <= max) {
                        playerChips = item.amount;
                    }
                } else {
                    if (item.skill_level.indexOf('-') !== -1) {
                        const min = parseInt(skillLevel[0]);
                        if (playerSkillLevel <= min) {
                            playerChips = item.amount;
                        }
                    } else if (item.skill_level.indexOf('+') !== -1) {
                        const max = parseInt(skillLevel[0]);
                        if (playerSkillLevel >= max) {
                            playerChips = item.amount;
                        }
                    }
                }
            });
        } else {
            playerChips = parseInt(tournament.default_chip_amount);
        }
        return playerChips;
    }

    function updatePlayers() {
        const playersToUpdate = _.compact(
            tournamentPlayers.map((item, index) => {
                if (item && item.id) {
                    return {
                        id: parseInt(item.id),
                        skill_level: item.skill_level,
                        chips_left: parseInt(item.chips_left),
                        race_to: tournament.winners_race_to,
                        place: null,
                        seed: index + 1,
                        is_eliminated: false,
                    };
                }
            })
        );
        console.log(playersToUpdate);

        const key = 'updatable';
        message.loading({ content: `Updating ${playersToUpdate.length} player chip counts...`, key, duration: 0 });

        let result = playersToUpdate.reduce((accumulatorPromise, nextPlayer) => {
            return accumulatorPromise.then(() => {
                return updatePlayer(nextPlayer);
            });
        }, Promise.resolve());

        result.then((e) => {
            message.success({
                content: `Updated player chip counts.`,
                key,
                duration: 2,
            });
            // console.log('PLAYER UPDATE COMPLETE', playersToUpdate);
        });
    }

    function updatePlayer(player) {
        return props.client
            .mutate({
                mutation: UPDATE_TOURNAMENT_PLAYER_MUTATION,
                variables: {
                    id: player.id,
                    tournament_id: tournament.id,
                    changes: player,
                },
                // notifyOnNetworkStatusChange: true,
                // awaitRefetchQueries: true,
                // refetchQueries: [
                //     {
                //         query: GET_TOURNAMENT_PLAYERS_QUERY,
                //         variables: { tournament_id: tournament.id },
                //     },
                //     {
                //         query: GET_TOURNAMENT_QUERY,
                //         variables: { id: tournament.id },
                //     },
                // ],
            })
            .then((data) => {
                // console.log(data);
                return data;
            })
            .catch((error) => {
                console.error(error);
            });
    }

    function createInitialMatches() {
        const key = 'bracket';
        message.loading({
            content: `Creating new tournament matches...`,
            key,
            duration: 0,
        });
        console.log('matches', matches);

        props.client
            .mutate({
                mutation: DELETE_TOURNAMENT_MATCHES,
                variables: {
                    tournament_id: tournament.id,
                },
                // awaitRefetchQueries: true,
                // refetchQueries: [
                //     {
                //         query: GET_TOURNAMENT_PAYOUTS,
                //         variables: { tournament_id: tournament.id },
                //     },
                // ],
            })
            .then(async (data) => {
                createMatchesMutation({
                    variables: {
                        objects: matches,
                    },
                    notifyOnNetworkStatusChange: true,
                    awaitRefetchQueries: true,
                    refetchQueries: [
                        {
                            query: GET_TOURNAMENT_BY_SLUG_QUERY,
                            variables: {
                                slug: props.match.params.slug || tournament.slug,
                            },
                        },
                        {
                            query: GET_TOURNAMENT_PLAYERS_QUERY,
                            variables: {
                                tournament_id: tournament.id,
                            },
                        },
                    ],
                })
                    .then((data) => {
                        message.success({
                            content: `Tournament matches successfully created`,
                            key,
                            duration: 2,
                        });
                        // setbracketCreated(true);
                        // setCurrentStep(3);
                        onContinue();
                    })
                    .catch((error) => {
                        console.log(error);
                        message.error({
                            content: `There was an error ${JSON.stringify(error)}`,
                            key,
                            duration: 2,
                        });
                    });

                props.client
                    .mutate({
                        mutation: RESET_TABLE_STATUS_MUTATION,
                        variables: {
                            tournament_id: tournament.id,
                        },
                        notifyOnNetworkStatusChange: true,
                        // awaitRefetchQueries: true,
                        // refetchQueries: [
                        //     {
                        //         query: GET_TOURNAMENT_TABLES_QUERY,
                        //         variables: { tournament_id: tournament.id, status: 'OPEN' },
                        //     },
                        // ],
                    })
                    .then((data) => {
                        // console.log(data);
                    })
                    .catch((e) => {
                        console.error(e);
                        message.error({ content: JSON.stringify(e), key, duration: 2 });
                    });

                updateTournament({
                    variables: {
                        id: tournament.id,
                        changes: {
                            start_date_time: moment.utc(),
                            progress: '0',
                            status: 'NOT_STARTED',
                        },
                    },
                    notifyOnNetworkStatusChange: true,
                    awaitRefetchQueries: true,
                    refetchQueries: [
                        {
                            query: GET_TOURNAMENT_QUERY,
                            variables: { id: tournament.id },
                        },
                    ],
                })
                    .then((data) => {
                        console.log(data);
                    })
                    .catch((error) => {
                        console.log(error);
                        message.info('There was an error', error);
                    });

                return props.client
                    .mutate({
                        mutation: UPDATE_TOURNAMENT_PLAYERS,
                        variables: {
                            tournament_id: tournament.id,
                            changes: {
                                place: null,
                            },
                        },
                        // notifyOnNetworkStatusChange: true,
                        // awaitRefetchQueries: true,
                        // refetchQueries: [
                        //     {
                        //         query: GET_TOURNAMENT_PLAYERS_W_STATS,
                        //         variables: { tournament_id: tournament.id },
                        //     },
                        // ],
                    })
                    .then((data) => {
                        // console.log(data);
                        return data;
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            })
            .catch((error) => {
                console.log(error);
                message.error({
                    content: `There was an error ${JSON.stringify(error)}`,
                    key,
                    duration: 2,
                });
            });
    }

    const columns = [
        {
            title: 'Order',
            dataIndex: 'order',
            sorter: (a, b) => {
                return a.order - b.order;
            },
            render: (text, record) => {
                return <Text>{text || '-'}</Text>;
            },
        },
        {
            title: 'Player',
            dataIndex: 'name',
            sorter: (a, b) =>
                b.name.localeCompare(a.name, undefined, {
                    numeric: true,
                    sensitivity: 'base',
                }),
            render: (text, record) => {
                return <Text>{text || '-'}</Text>;
            },
        },

        // {
        //     title: 'Country',
        //     dataIndex: 'country',
        //     sorter: (a, b) => {
        //         return a.country && a.country.localeCompare(b.country);
        //     },
        //     render: (text, record) => {
        //         const country = record.country ? getCountryName(record.country) : text;
        //         return (
        //             <div
        //                 style={{
        //                     display: 'flex',
        //                     alignItems: 'center',
        //                 }}
        //             >
        //                 {country ? _.startCase(country) : '-'}
        //             </div>
        //         );
        //     },
        // },

        {
            title: 'Skill Level',
            dataIndex: 'skill_level',
            sorter: (a, b) => {
                return a.skill_level - b.skill_level;
            },
            render: (text, record) => {
                return <Text>{text || '-'}</Text>;
            },
        },
        {
            title: 'Chips',
            dataIndex: 'chips',
            sorter: (a, b) => {
                return a.chips_left - b.chips_left;
            },
            render: (text, record) => {
                return <Text>{text || '-'}</Text>;
            },
        },
    ];

    const columnsMatches = [
        {
            title: 'Match',
            dataIndex: 'match_number',
            sorter: (a, b) => {
                return a.match_number - b.match_number;
            },
            render: (text, record) => {
                return <Text>{text || '-'}</Text>;
            },
        },
        {
            title: 'Players',
            dataIndex: 'players',
            sorter: (a, b) =>
                b.players.localeCompare(a.players, undefined, {
                    numeric: true,
                    sensitivity: 'base',
                }),
            render: (text, record) => {
                return <Text>{text || '-'}</Text>;
            },
        },

        {
            title: 'Race to',
            dataIndex: 'race_to',
            sorter: (a, b) => {
                return a.race_to - b.race_to;
            },
            render: (text, record) => {
                return <Text>{text || '-'}</Text>;
            },
        },
    ];

    return (
        <Query
            query={GET_TOURNAMENT_PLAYERS_QUERY}
            fetchPolicy="cache-and-network"
            notifyOnNetworkStatusChange={true}
            variables={{
                tournament_id: tournament.id,
            }}
            onCompleted={async (data) => {
                const players = data.tournament_players;

                let res = await client.query({
                    query: GET_TOURNAMENT_CHIP_AMOUNTS,
                    variables: {
                        tournament_id: tournament.id,
                    },
                });
                const chips =
                    res &&
                    res.data &&
                    res.data.tournament_chips &&
                    res.data.tournament_chips.map((item) => {
                        return {
                            skill_level: item.skill_level,
                            amount: item.amount,
                        };
                    });

                const seededPlayers = generateSeeds(players, chips);
                const matches = generateMatches(seededPlayers);
                const filteredMatches = matches.slice(0, tables);
                console.log(matches);
                setChipAmounts(chips);
                setTournamentPlayers(seededPlayers);
                setMatches(filteredMatches);
            }}
        >
            {({ loading, error, data }) => {
                if (loading) {
                    return (
                        <div style={styles.container}>
                            <CircularLoader />
                        </div>
                    );
                }
                if (error) {
                    return <div style={styles.container}>Error: {error.message}</div>;
                }

                if (!data || !data.tournament_players) {
                    return (
                        <div style={styles.container}>
                            <CircularLoader />
                        </div>
                    );
                }

                const players = data.tournament_players;

                return (
                    <div
                        style={{
                            paddingLeft: 20,
                            paddingRight: 20,
                            paddingBottom: 20,
                        }}
                    >
                        <div
                            style={{
                                marginTop: 20,
                                marginBottom: 20,
                            }}
                        >
                            <div
                                style={{
                                    marginBottom: 20,
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <div>
                                        <Title
                                            level={4}
                                            style={{
                                                marginBottom: 5,
                                            }}
                                        >
                                            Preview Draw
                                        </Title>
                                        <Text>The draw is generated using a randomized seeding algorithm.</Text>
                                    </div>
                                    <div>
                                        <Button
                                            type="default"
                                            onClick={() => {
                                                const seededPlayers = generateSeeds(players, chipAmounts);
                                                const matches = generateMatches(seededPlayers);
                                                const filteredMatches = matches.slice(0, tables);
                                                setTournamentPlayers(seededPlayers);
                                                setMatches(filteredMatches);
                                            }}
                                        >
                                            <ReloadOutlined /> Re-shuffle players
                                        </Button>
                                    </div>
                                </div>
                            </div>
                            <div className="table_nowrap">
                                <Table
                                    onRow={(record, rowIndex) => {
                                        return {
                                            onClick: (event) => {},
                                            onDoubleClick: (event) => {}, // double click row
                                            onContextMenu: (event) => {}, // right button click row
                                            onMouseEnter: (event) => {}, // mouse enter row
                                            onMouseLeave: (event) => {}, // mouse leave row
                                        };
                                    }}
                                    columns={columns}
                                    dataSource={
                                        tournamentPlayers &&
                                        tournamentPlayers.map((player, index) => {
                                            return {
                                                order: index + 1,
                                                name: player && player.name,
                                                country: player && player.country,
                                                skill_level: player && player.skill_level,
                                                chips: player && player.chips_left,
                                                key: index,
                                            };
                                        })
                                    }
                                    pagination={false}
                                    tableLayout="auto"
                                    scroll={{ x: 400 }}
                                />
                            </div>

                            <div style={{ marginTop: 20 }}>
                                <Title level={4}>Round 1 Matches</Title>
                                <Table
                                    onRow={(record, rowIndex) => {
                                        return {
                                            onClick: (event) => {},
                                            onDoubleClick: (event) => {}, // double click row
                                            onContextMenu: (event) => {}, // right button click row
                                            onMouseEnter: (event) => {}, // mouse enter row
                                            onMouseLeave: (event) => {}, // mouse leave row
                                        };
                                    }}
                                    columns={columnsMatches}
                                    dataSource={
                                        matches &&
                                        matches.map((row, index) => {
                                            return {
                                                match_number: index + 1,
                                                players: `${row.challenger1_name} ${
                                                    row.challenger1_skill_level ? `(${row.challenger1_skill_level})` : ''
                                                } vs. ${
                                                    row.challenger2_name
                                                        ? row.challenger2_name
                                                        : row.challenger2_skill_level
                                                        ? `(${row.challenger2_skill_level})`
                                                        : 'N/A'
                                                }`,
                                                race_to: tournament.winners_race_to,
                                                key: index,
                                            };
                                        })
                                    }
                                    pagination={false}
                                    tableLayout="auto"
                                    scroll={{ x: 400 }}
                                />
                            </div>

                            <Button
                                type="primary"
                                size="large"
                                onClick={() => {
                                    if (
                                        tournament &&
                                        tournament.tournament_brackets_aggregate &&
                                        tournament.tournament_brackets_aggregate.aggregate &&
                                        tournament.tournament_brackets_aggregate.aggregate.count > 0
                                    ) {
                                        confirm({
                                            title: 'Are you sure you want to reset the draw?',
                                            content: 'This action will clear any existing matches.',
                                            centered: true,
                                            transitionName: 'fade',
                                            maskTransitionName: 'none',
                                            okText: 'Reset Draw',
                                            cancelText: `Cancel`,
                                            onOk() {
                                                updatePlayers();
                                                createInitialMatches();
                                            },
                                            onCancel() {
                                                console.log('NO RESET');
                                            },
                                        });
                                    } else {
                                        updatePlayers();
                                        createInitialMatches();
                                    }
                                }}
                                style={{
                                    marginTop: 20,
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Text
                                            style={{
                                                color: '#fff',
                                            }}
                                        >
                                            Save and go to step 5
                                        </Text>
                                    </div>
                                </div>
                            </Button>

                            <Button
                                type="secondary"
                                size="large"
                                onClick={() => {
                                    goBack();
                                }}
                                style={{
                                    marginTop: 20,
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Text>Go back</Text>
                                </div>
                            </Button>
                        </div>
                    </div>
                );
            }}
        </Query>
    );
}

export default TBPreviewRoundRobin;
