import React, { useState } from 'react';
import { Table, Typography, Button, Icon, Modal, Tooltip } from 'antd';

const { Text } = Typography;
const { confirm } = Modal;

export default function VenueTable(props) {
	const { authState, onDelete } = props;
	const { onSelected, onEditSelected } = props;
	const [ selectedKeys, setSelectedKeys ] = useState([]);

	function onSelectChange(selectedKeys) {
		// console.log('selectedRowKeys changed: ', selectedKeys);
		setSelectedKeys(selectedKeys);
		onSelected(selectedKeys);
	}

	const rowSelection = {
		selectedKeys,
		onChange: onSelectChange
	};

	const columns = [
		{
			title: 'Name',
			dataIndex: 'name',
			defaultSortOrder: 'asc',
			sorter: (a, b) => {
				return a.name && a.name.localeCompare(b.name);
			},
			render: (text, record) => {
				return <Text style={{ fontWeight: 'bold' }}>{text}</Text>;
			}
		},

		{
			title: 'Type',
			dataIndex: 'venue_type',
			sorter: (a, b) => {
				return a.venue_type && a.venue_type.localeCompare(b.venue_type);
			}
		},
		{
			title: 'Address',
			dataIndex: 'address1',
			sorter: (a, b) => {
				return a.address1 && a.address1.localeCompare(b.address1);
			}
		},
		{
			title: 'City',
			dataIndex: 'city',
			sorter: (a, b) => {
				return a.city && a.city.localeCompare(b.city);
			}
		},
		{
			title: 'State/Region',
			dataIndex: 'region',
			sorter: (a, b) => {
				return a.region && b.region.localeCompare(b.region);
			}
		},
		{
			title: 'Country',
			dataIndex: 'country',
			sorter: (a, b) => {
				return a.country && a.country.localeCompare(b.country);
			}
		},
		{
			title: 'Tables',
			dataIndex: 'pool_tables',
			sorter: (a, b) => {
				return a.pool_tables - b.pool_tables;
			}
		},
		{
			title: 'Action',
			key: 'action',
			render: (text, record) => (
				<React.Fragment>
					<span style={{ whiteSpace: 'nowrap' }}>
						<Tooltip title="Edit">
							<Button
								type="ghost"
								onClick={(e) => {
									e.preventDefault();
									e.stopPropagation();
									onEditSelected(record.id);
								}}
							>
								<Icon type="edit" />
							</Button>
						</Tooltip>
						<Tooltip title={record.owner_id !== authState.user.id ? 'No access to delete' : 'Delete'}>
							<Button
								type="ghost"
								onClick={(e) => {
									e.preventDefault();
									e.stopPropagation();
									showConfirm(record);
								}}
								disabled={record.owner_id !== authState.user.id}
								style={{ marginLeft: 10 }}
							>
								<Icon type="delete" />
							</Button>
						</Tooltip>
					</span>
				</React.Fragment>
			)
		}
	];

	function onChange(pagination, filters, sorter) {
		console.log('params', pagination, filters, sorter);
	}

	function showConfirm(record) {
		confirm({
			title: `Are you sure you want to remove ${record.name}?`,
			centered: true,
			transitionName: 'fade',
			maskTransitionName: 'none',
			// onOk() {
			// 	return new Promise((resolve, reject) => {
			// 		setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
			// 	}).catch(() => console.log('Oops errors!'));
			// },
			onOk() {
				onDelete(record);
			},
			onCancel() {}
		});
	}

	return (
		<Table
			rowSelection={rowSelection}
			onRow={(record, rowIndex) => {
				return {
					onClick: (event) => {
						console.log(record, rowIndex);
						props.history.push({
							pathname: `/tournament-builder/${props.tournament.slug}/venues/${record.slug}`,
							// search: '?query=abc',
							state: { data: record }
						});
					},
					onDoubleClick: (event) => {}, // double click row
					onContextMenu: (event) => {}, // right button click row
					onMouseEnter: (event) => {}, // mouse enter row
					onMouseLeave: (event) => {} // mouse leave row
				};
			}}
			columns={columns}
			dataSource={props.tournaments}
			onChange={onChange}
			tableLayout="auto"
			scroll={{ x: 400 }}
			// size="middle"
			// bordered
		/>
	);
}
