import React from 'react';
import { Layout } from 'antd';
import { Link, Route, withRouter } from 'react-router-dom';
import useTheme from './theme/useTheme';

function StreamOverlayLayout(props, { authUser }) {
    const { component: Component, defaultTheme, ...rest } = props;
    const [theme, toggleTheme] = useTheme(defaultTheme);

    return <Route {...rest} render={(matchProps) => <Component {...rest}>{props.children}</Component>} />;
}

export default StreamOverlayLayout;
