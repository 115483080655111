import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Row, Col, Empty, Typography, Steps, Button, Result } from 'antd';
import Card from '../../components/Card';
import SectionHeader from '../../components/SectionHeader';
import SectionContent from '../../components/SectionContent';
import SectionWrapper from '../../components/SectionWrapper';
import CircularLoader from '../../components/CircularLoader';
import LMCreateOrganizationForm from './LMCreateOrganizationForm';
import LMCreateLeagueForm from './LMCreateLeagueForm';
import LMCreateAddDivisions from './LMCreateAddDivisions';
import { Query } from '@apollo/client/react/components';
import { GET_LEAGUE_QUERY } from './data/queries';

const { Step } = Steps;
const { Text, Title } = Typography;

const styles = {
    cardHeader: {
        paddingTop: 5,
        paddingBottom: 5,
        background: 'linear-gradient(0deg, rgb(241, 244, 246), rgb(255, 255, 255))',
        // boxShadow: 'rgba(0, 0, 0, 0.05) 0px 2px 8px 0px',
        borderRadius: '10px 10px 0px 0px',
    },
};

function LMCreate(props) {
    const [currentStep, setCurrentStep] = useState(0);
    const [organization, setOrganization] = useState();
    const [league, setLeague] = useState();

    function onChange(current) {
        setCurrentStep(current);
    }

    return (
        <SectionWrapper>
            <SectionHeader
                title={props.title}
                titleStyle={{
                    color: '#fff',
                    textTransform: 'uppercase',
                    margin: 0,
                    display: 'flex',
                }}
                height={120}
                backButton={
                    <Button
                        onClick={() =>
                            props.match.params.slug
                                ? props.history.push(`/league-manager/${props.match.params.slug}/dashboard`)
                                : props.history.push(`/league-manager`)
                        }
                        type="ghost"
                        shape="circle"
                        icon="arrow-left"
                        size="large"
                        ghost
                        style={{ border: 'none' }}
                    />
                }
                subtitle="Create and manage your own pool league in a few simple steps."
                subtitleStyle={{ color: '#fff' }}
                theme="leagueManager"
            />

            <SectionContent padding="20px 40px">
                <Row gutter={24} justify="center" type="flex">
                    <Col xs={24}>
                        <Card>
                            <Row gutter={0} justify="start" type="flex">
                                <Col xs={24} sm={4}>
                                    <Steps direction="vertical" current={currentStep} onChange={onChange}>
                                        {/* <Step title="Organization Setup" /> */}
                                        <Step title="League Setup" />
                                        {/* <Step title="League Operator" /> */}
                                        {/* <Step title="Division Setup" /> */}
                                    </Steps>
                                </Col>
                                <Col xs={24} sm={20}>
                                    {/* {currentStep === 0 && (
                                        <div style={{ paddingLeft: 20 }}>
                                            <LMCreateOrganizationForm
                                                {...props}
                                                onCreated={(organization) => {
                                                    console.log(organization);
                                                    setOrganization(organization);
                                                    setCurrentStep(1);
                                                }}
                                                onSkipStep={() => {
                                                    setCurrentStep(1);
                                                }}
                                            />
                                        </div>
                                    )} */}

                                    {currentStep === 0 && (
                                        <div style={{ paddingLeft: 20 }}>
                                            {props.match.params.slug ? (
                                                <Query
                                                    query={GET_LEAGUE_QUERY}
                                                    fetchPolicy="cache-and-network"
                                                    notifyOnNetworkStatusChange={true}
                                                    variables={{ slug: props.match.params.slug }}
                                                    onCompleted={(data) => {
                                                        console.log(data);
                                                    }}
                                                >
                                                    {({ loading, error, data }) => {
                                                        if (loading)
                                                            return (
                                                                <div style={styles.container}>
                                                                    <CircularLoader />
                                                                </div>
                                                            );
                                                        if (error) return <div style={styles.container}>Error: {error.message}</div>;
                                                        if (!loading && data && !data.leagues) {
                                                            return (
                                                                <Empty
                                                                    image="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original"
                                                                    imageStyle={{
                                                                        height: 100,
                                                                    }}
                                                                    description={<Text style={{ fontSize: 18 }}>You have not created any leagues yet.</Text>}
                                                                    style={{ margin: 10 }}
                                                                >
                                                                    <Button>Create League</Button>
                                                                </Empty>
                                                            );
                                                        }

                                                        return (
                                                            <React.Fragment>
                                                                <LMCreateLeagueForm
                                                                    {...props}
                                                                    onCreated={(league) => {
                                                                        console.log(league);
                                                                        setLeague(league);
                                                                        props.history.push(`/league-manager/${league.slug}/dashboard`);
                                                                        // setCurrentStep(1);
                                                                    }}
                                                                    onSkipStep={() => {
                                                                        console.log('skip step');
                                                                        // setCurrentStep(1);
                                                                    }}
                                                                    organization={organization}
                                                                    league={data.leagues[0]}
                                                                />
                                                            </React.Fragment>
                                                        );
                                                    }}
                                                </Query>
                                            ) : (
                                                <LMCreateLeagueForm
                                                    {...props}
                                                    onCreated={(league) => {
                                                        console.log(league);
                                                        setLeague(league);
                                                        props.history.push(`/league-manager/${league.slug}/dashboard`);
                                                        // setCurrentStep(1);
                                                    }}
                                                    onSkipStep={() => {
                                                        console.log('skip step');
                                                        // setCurrentStep(1);
                                                    }}
                                                    organization={organization}
                                                />
                                            )}
                                        </div>
                                    )}

                                    {currentStep === 1 && league ? (
                                        <div style={{ paddingBottom: 20, paddingLeft: 20, paddingRight: 20 }}>
                                            <LMCreateAddDivisions
                                                {...props}
                                                organization={organization}
                                                league={league}
                                                onContinue={() => {
                                                    props.history.push(`/league-manager/${league.slug}/dashboard`);
                                                    // const playerCount =
                                                    // 	tournament &&
                                                    // 	tournament.tournament_players_aggregate.aggregate.count;
                                                    // const tableCount =
                                                    // 	tournament && tournament.pool_tables_aggregate.aggregate.count;

                                                    // if (playerCount > 0 && tableCount > 0) {
                                                    // 	props.history.push(
                                                    // 		`/tournament-builder/${tournament.slug}/bracket`
                                                    // 	);
                                                    // } else {
                                                    // 	message.error(
                                                    // 		`You must have at least ${playerCount > 0
                                                    // 			? ''
                                                    // 			: '1 player and '} ${tableCount > 0
                                                    // 			? ''
                                                    // 			: '1 table'} before you can start the tournament.`
                                                    // 	);
                                                    // }
                                                }}
                                            />
                                        </div>
                                    ) : (
                                        currentStep === 1 &&
                                        !league && (
                                            <Result
                                                status="info"
                                                title="You must finish creating a league before you can add divisions."
                                                extra={
                                                    <Button type="primary" key="console" size="large" onClick={() => setCurrentStep(0)}>
                                                        Go Back
                                                    </Button>
                                                }
                                            />
                                        )
                                    )}
                                </Col>
                            </Row>
                        </Card>
                        {/* <Card
							headStyle={styles.cardHeader}
							title={
								<Steps size="small" current={0}>
									<Step title="General Information" />
									<Step title="League Operator Info" />
									<Step title="Division Setup" />
								</Steps>
							}
						>
							<LMCreateLeagueForm {...props} />
						</Card> */}
                    </Col>
                </Row>
            </SectionContent>
        </SectionWrapper>
    );
}

export default withRouter(LMCreate);
