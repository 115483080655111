import React from 'react';
import { Typography } from 'antd';
import Flag from '../../components/Flag';

const { Text } = Typography;

export default function PlayerName(props) {
    const { country, player_name, player_fargo, player_fargo_odds } = props;

    return (
        <React.Fragment>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                {country && <Flag name={country || 'Unknown'} format="png" pngSize={24} shiny={false} alt="Flag" className="flag" basePath="/images/flags" />}
                <div style={{ fontSize: 16, marginLeft: 8 }}>
                    {player_name}{' '}
                    {player_fargo && player_fargo_odds ? (
                        <React.Fragment>
                            <Text style={{ color: '#fff' }}>- {player_fargo} </Text>
                            <Text style={{ fontSize: 14, color: 'rgba(255,255,255,0.7)' }}>({player_fargo_odds})</Text>
                        </React.Fragment>
                    ) : player_fargo ? (
                        `- ${player_fargo}`
                    ) : null}
                </div>
            </div>
        </React.Fragment>
    );
}
