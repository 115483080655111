import React, { useState, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { Form, FormItem, Input, Radio, Select, DatePicker, SubmitButton, ResetButton, AutoComplete } from 'formik-antd';
import { Formik, ErrorMessage } from 'formik';
import { message, Typography, Button, Alert, Icon, Divider } from 'antd';
import { useMutation } from '@apollo/react-hooks';
import { SEARCH_VENUES_QUERY, GET_EVENT_QUERY } from '../data/queries';
import { CREATE_EVENT_MUTATION, UPDATE_EVENT_MUTATION } from '../data/mutations';
import slugify from 'slugify';
import * as Yup from 'yup';
import AddVenueModal from '../../tournament-builder/AddVenueModal';
import moment from 'moment-timezone';
import momentDurationFormatSetup from 'moment-duration-format';
momentDurationFormatSetup(moment);

const { TextArea } = Input;
const { Text, Title } = Typography;
const { Option } = Select;

const styles = {
    error: { color: '#f5222d', marginTop: 10 },
};

function CreateEditEventForm(props) {
    const { event, onContinue, client } = props;
    const [loading, setLoading] = useState(false);
    const [createEvent, { createLoading, createError }] = useMutation(CREATE_EVENT_MUTATION);
    const [updateEvent, { updateLoading, updateError }] = useMutation(UPDATE_EVENT_MUTATION);
    const [venueModalVisible, setVenueModalVisible] = useState(false);
    const [venueDataSource, setVenueDataSource] = useState('');
    const formRef = useRef();

    // const formItemLayout = {
    //     labelCol: {
    //         xs: { span: 24 },
    //         sm: { span: 8 },
    //     },
    //     wrapperCol: {
    //         xs: { span: 24 },
    //         sm: { span: 16 },
    //     },
    // };

    // const tailFormItemLayout = {
    //     wrapperCol: {
    //         xs: {
    //             span: 24,
    //             offset: 0,
    //         },
    //         sm: {
    //             span: 16,
    //             offset: 8,
    //         },
    //     },
    // };

    // const showLoading = () => {
    //     const hide = message.loading('Action in progress..', 0);
    //     // Dismiss manually and asynchronously
    //     setTimeout(hide, 2500);
    // };

    function onOk(value) {
        const tzDate = moment.tz(value, moment.tz.guess(value)).format('YYYY-MM-DD hh:mm A z');
        console.log('onOK', tzDate);
        return tzDate;
    }

    function onSearch(name) {
        if (name && name.length > 2) {
            queryVenues(name);
        }
    }

    function onSelect(value, option) {
        console.log({
            value,
            option,
        });
        const venue = venueDataSource.filter((venue) => {
            return venue.id === parseInt(value);
        })[0];
        formRef.current.setValues({ ...formRef.current.state.values, venue_id: parseInt(value), venue_name: venue.name });
    }

    async function queryVenues(venueName) {
        let query = await client.query({
            query: SEARCH_VENUES_QUERY,
            variables: {
                name: `%${venueName}%`,
            },
        });

        let dataSource = query.data.venues;

        setVenueDataSource(dataSource);
    }

    function handleVenueModalOk(e) {
        setVenueModalVisible(false);
    }

    function handleAddVenue() {
        setVenueModalVisible(true);
    }

    function handleVenueModalCancel(e) {
        setVenueModalVisible(false);
    }

    function handleFormFieldUpdates(event) {
        const caret = event.target.selectionStart;
        const element = event.target;
        window.requestAnimationFrame(() => {
            element.selectionStart = caret;
            element.selectionEnd = caret;
        });
    }

    const CreateEventSchema = Yup.object().shape({
        name: Yup.string().min(2, 'Too Short!').max(100, 'Too Long!').required('Required'),
        event_type: Yup.string().required('Required'),
        description: Yup.string().required('Required'),
        // venue_id: Yup.string().required('Required'),
        start_date_time: Yup.string().required('Required'),
    });

    function handleSubmit(values, actions) {
        const { name, description, start_date_time, end_date_time, owner_id, event_type, venue_id, is_public } = values;
        setLoading(true);
        const slug = slugify(name, {
            replacement: '-',
            remove: /[*+~.,()'"#!:/@]/g,
            lower: true,
        });

        if (event) {
            updateEvent({
                variables: {
                    id: event.id,
                    changes: {
                        name,
                        slug,
                        description,
                        start_date_time,
                        end_date_time,
                        owner_id,
                        venue_id,
                        event_type,
                        is_public,
                    },
                },

                refetchQueries: () => [
                    {
                        query: GET_EVENT_QUERY,
                        variables: {
                            eventSlug: event.slug,
                        },
                        notifyOnNetworkStatusChange: true,
                        awaitRefetchQueries: true,
                    },
                ],
            })
                .then((res) => {
                    actions.setSubmitting(false);
                    // actions.resetForm();
                    setLoading(false);
                    message.success('Changes saved');
                    console.log(res.data);
                    const result = res.data.update_events.returning[0];
                    if (onContinue) {
                        onContinue(result);
                    } else {
                        props.history.push(`/events/${result.slug}`);
                    }
                })
                .catch((e) => {
                    actions.setSubmitting(false);
                    message.error((e && e.message) || JSON.stringify(e));
                    console.log(e);
                });
        } else {
            createEvent({
                variables: {
                    objects: [
                        {
                            name,
                            slug,
                            description,
                            owner_id: props.authState.user.id,
                            venue_id,
                            start_date_time,
                            end_date_time,
                            event_type,
                            is_public,
                        },
                    ],
                },
            })
                .then((res) => {
                    actions.setSubmitting(false);
                    actions.resetForm();
                    console.log(res);
                    setLoading(false);
                    const result = res.data.insert_events.returning[0];
                    message.success('Event created');
                    if (onContinue) {
                        onContinue(result);
                    } else {
                        props.history.push(`/events/${result.slug}`);
                    }
                })
                .catch((e) => {
                    actions.setSubmitting(false);
                    message.error((e && e.message) || JSON.stringify(e));
                    console.log(e);
                });
        }
    }

    return (
        <Formik
            ref={formRef}
            enableReinitialize
            initialValues={
                event
                    ? {
                          ...event,
                          is_public: event.is_public,
                          venue_id: event.venue && event.venue.id,
                          venue_name: event.venue && event.venue.name,
                      }
                    : {
                          name: '',
                          venue_id: undefined,
                          venue_name: undefined,
                          event_type: 'tournament',
                          start_date_time: undefined,
                          end_date_time: undefined,
                          is_public: true,
                      }
            }
            validationSchema={CreateEventSchema}
            onSubmit={(values, actions) => {
                console.log(values);
                handleSubmit(values, actions);
            }}
            render={({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                <React.Fragment>
                    <div
                        style={{
                            marginTop: 10,
                            marginBottom: 20,
                        }}
                    >
                        <div>
                            <Alert
                                message="How are events unique?"
                                description="Events are useful for grouping multiple tournament brackets or singles matches together. This makes it easy to keep them organized under a single event that can be shared and also provides unique capabilities like being able to see the live scores across multiple tournaments at the same time."
                                type="info"
                                closable
                                showIcon
                            />
                        </div>
                        <Title level={4} style={{ marginTop: 20, fontSize: 18 }}>
                            Event Details
                        </Title>

                        <Form onSubmit={handleSubmit} layout="vertical" style={{ maxWidth: 600 }}>
                            <FormItem label="Name" name="name" required hasFeedback showValidateSuccess>
                                <Input
                                    name="name"
                                    placeholder="Name"
                                    size="large"
                                    onChange={(event) => {
                                        handleFormFieldUpdates(event);
                                    }}
                                />
                            </FormItem>
                            <FormItem label="Event Type" name="event_type" required hasFeedback showValidateSuccess>
                                <Select
                                    showSearch
                                    name="event_type"
                                    placeholder="Select an event type"
                                    size="large"
                                    optionFilterProp="children"
                                    defaultValue="tournament"
                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                >
                                    {[
                                        {
                                            type: 'Tournament',
                                            value: 'tournament',
                                        },
                                        {
                                            type: 'Match',
                                            value: 'match',
                                        },
                                        {
                                            type: 'Live stream',
                                            value: 'live_stream',
                                        },

                                        {
                                            type: 'Other',
                                            value: 'other',
                                        },
                                    ].map((item, index) => (
                                        <Option key={index} value={item.value}>
                                            {item.type}
                                        </Option>
                                    ))}
                                </Select>
                            </FormItem>
                            <FormItem label="Description" name="description" required hasFeedback showValidateSuccess>
                                <TextArea
                                    rows={4}
                                    name="description"
                                    placeholder="Description"
                                    size="large"
                                    onChange={(event) => {
                                        handleFormFieldUpdates(event);
                                    }}
                                />
                            </FormItem>
                            <FormItem label="Venue" name="venue_id" required hasFeedback showValidateSuccess>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <AutoComplete
                                        name="venue_name"
                                        dataSource={venueDataSource}
                                        placeholder="Search for a venue"
                                        onSearch={onSearch}
                                        onSelect={onSelect}
                                        size="large"
                                        filterOption={(inputValue, option) => {
                                            return (
                                                option.props &&
                                                option.props.children &&
                                                option.props.children.toString().toLowerCase().indexOf(inputValue.toString().toLowerCase()) >= 0
                                            );
                                        }}
                                        dropdownRender={(menu) => (
                                            <div>
                                                {menu}
                                                <Divider style={{ margin: '4px 0' }} />
                                                <div
                                                    style={{
                                                        padding: '4px 8px',
                                                        cursor: 'pointer',
                                                    }}
                                                    onMouseDown={(e) => e.preventDefault()}
                                                    onClick={handleAddVenue}
                                                >
                                                    <Icon type="plus" /> Add Venue
                                                </div>
                                            </div>
                                        )}
                                    >
                                        {venueDataSource &&
                                            venueDataSource.map((item, index) => (
                                                <Option key={index} value={`${item.id}`}>
                                                    {item.name} - {item.city}, {item.region}
                                                </Option>
                                            ))}
                                    </AutoComplete>
                                    <Button type="primary" ghost size="large" onClick={handleAddVenue} style={{ marginLeft: 10 }}>
                                        <Icon type="plus" /> Add Venue
                                    </Button>
                                </div>
                            </FormItem>

                            <FormItem label="Start Date & Time" name="start_date_time" required hasFeedback showValidateSuccess>
                                <DatePicker
                                    showTime
                                    name="start_date_time"
                                    size="large"
                                    format="YYYY-MM-DD hh:mm A z"
                                    // onChange={onDateChange}
                                    onOk={onOk}
                                />
                            </FormItem>
                            <FormItem
                                label="End Date & Time"
                                name="end_date_time"
                                hasFeedback
                                showValidateSuccess
                                // className={
                                // 	'ant-form-item-control' +
                                // 	(errors.end_date || errors.general ? ' has-error' : '')
                                // }
                            >
                                <DatePicker
                                    showTime
                                    name="end_date_time"
                                    size="large"
                                    format="YYYY-MM-DD hh:mm A z"
                                    // onChange={onDateChange}
                                    onOk={onOk}
                                />
                                {/* <ErrorMessage name="end_date" component="div" className="ant-form-explain" /> */}
                            </FormItem>

                            <FormItem name="is_public" label="Access">
                                <Radio.Group name="is_public">
                                    <Radio value={true}>Public - Anyone can view and RSVP for this event.</Radio>
                                    <br />
                                    <Radio value={false}>Private - Only users with the link can see this event.</Radio>
                                </Radio.Group>
                            </FormItem>

                            <SubmitButton
                                size="large"
                                disabled={Object.keys(errors).length > 0 ? true : false}
                                type={Object.keys(errors).length > 0 ? 'danger' : 'primary'}
                                loading={loading}
                            >
                                {event ? 'Update' : 'Create'} and Continue
                            </SubmitButton>

                            <div>
                                <Text style={styles.error}>{errors.general}</Text>
                            </div>
                            <div>
                                <Text style={{ color: '#f5222d', marginTop: 10 }}>{errors.general}</Text>
                            </div>
                            <AddVenueModal {...props} modalVisible={venueModalVisible} onModalOk={handleVenueModalOk} onModalCancel={handleVenueModalCancel} />
                        </Form>
                    </div>
                </React.Fragment>
            )}
        />
    );
}

export default withRouter(CreateEditEventForm);
