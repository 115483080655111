import React, { useState, useEffect } from 'react';
import { Icon, Row, Col, Typography, Modal, Progress, Button, Radio, Input, Tabs, Empty, Table } from 'antd';
import Card from '../../../components/Card';
import { Link } from 'react-router-dom';
import { Query, Subscription } from '@apollo/client/react/components';
import { getCountryName } from '../../../utils/getCountryName';
import CircularLoader from '../../../components/CircularLoader';
import SectionTitle from '../../../components/SectionTitle';
import SectionHeader from '../../../components/SectionHeader';
import SectionContent from '../../../components/SectionContent';
import SectionWrapper from '../../../components/SectionWrapper';
import TournamentProgressBar from '../../tournament-builder/TournamentProgressBar';
import TBManageButtons from '../../tournament-builder/TBManageButtons';
import CircleFlag from '../../../components/CircleFlag';
import { isoCountries } from '../../../utils/isoCountryCodes';
import {
    GET_TOURNAMENT_BRACKET_BY_STATUS_SUBSCRIPTION,
    GET_TOURNAMENT_BRACKET_W_PLAYERS_SUBSCRIPTION,
    GET_TOURNAMENT_BRACKET_IN_PROGRESS_MATCHES_SUBSCRIPTION,
    GET_TOURNAMENT_BRACKET_UPCOMING_MATCHES_SUBSCRIPTION,
} from './data/subscriptions';
import Fade from 'react-reveal/Fade';
import useBreakpoint from 'use-breakpoint';
import { defaultBreakpoints } from '../../../utils/defaultBreakpoints';
import MatchOverlay from './bracket/MatchOverlay';
import _ from 'lodash';
import moment from 'moment-timezone';
import momentDurationFormatSetup from 'moment-duration-format';
momentDurationFormatSetup(moment);

const { Text, Title } = Typography;
const ButtonGroup = Button.Group;
const dataSource = [];

function TBSchedule(props) {
    const { tournament, theme } = props;
    const [currentMatch, setCurrentMatch] = useState();
    const { breakpoint, maxWidth, minWidth } = useBreakpoint(defaultBreakpoints);
    const defaultSize = breakpoint === 'mobile' ? 'small' : 'small';
    var zone_name = moment.tz.guess();
    var timezone = moment.tz(zone_name).zoneAbbr();

    const styles = {
        row: {
            display: 'flex',
            flexGrow: 1,
        },
        tab: {
            paddingTop: 5,
            paddingLeft: 24,
            paddingRight: 24,
            paddingBottom: 24,
        },
        selectedToggle: {
            boxShadow: 'none',
        },
        defaultToggle: {
            backgroundColor: 'transparent',
            boxShadow: 'none',
        },
    };

    const columns = [
        {
            title: 'Match',
            dataIndex: 'match_number',
            key: 'match',
            width: 40,
        },

        {
            title: 'Table',
            dataIndex: 'table_name',
            align: 'left',
            key: 'table',
            width: 150,
            render: (text, record) => {
                return (
                    <div style={{ whiteSpace: 'nowrap' }}>
                        {/* <img src={tableBlue} width={25} alt="Table 1" /> */}
                        {/* <span style={{ marginLeft: 10 }}>{text || '-'}</span> */}
                        <span>{text || '-'}</span>
                    </div>
                );
            },
        },

        {
            title: 'Player 1',
            dataIndex: 'challenger1_name',
            key: 'player1',
            align: 'right',
            width: 300,
            render: (text, record) => {
                return (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                        <div
                            style={{
                                marginRight: record.challenger1_country ? 10 : 0,
                                fontWeight:
                                    record.challenger1_is_winner === true || record.challenger1_score === getChallengerRaceTo(record, 'challenger1')
                                        ? 'bold'
                                        : 'normal',
                            }}
                        >
                            {text || '-'}
                        </div>
                        {record.challenger1_country && <CircleFlag country={record.challenger1_country} left={0} />}

                        <span
                            style={{
                                marginLeft: 10,
                                padding: '5px 10px',
                                backgroundColor:
                                    record.challenger1_is_winner === true || record.challenger1_score === getChallengerRaceTo(record, 'challenger1')
                                        ? '#1890fe'
                                        : theme && theme.name === 'dark'
                                        ? '#135da2'
                                        : '#8dc8ff',
                                borderRadius: 5,
                                color: '#fff',
                            }}
                        >
                            {record.challenger1_is_forfeit === true ? 'FF' : record.challenger1_is_withdraw === true ? 'WD' : record.challenger1_score || 0}
                        </span>
                    </div>
                );
            },
        },
        {
            title: 'Player 2',
            dataIndex: 'challenger2_name',
            key: 'player2',
            width: 300,
            render: (text, record) => {
                return (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                        <span
                            style={{
                                marginRight: 20,
                                padding: '5px 10px',
                                backgroundColor:
                                    record.challenger2_is_winner === true || record.challenger2_score === getChallengerRaceTo(record, 'challenger2')
                                        ? '#1890fe'
                                        : theme && theme.name === 'dark'
                                        ? '#135da2'
                                        : '#8dc8ff',
                                borderRadius: 5,
                                color: '#fff',
                            }}
                        >
                            {record.challenger2_is_forfeit === true ? 'FF' : record.challenger2_is_withdraw === true ? 'WD' : record.challenger2_score || 0}
                        </span>

                        {record.challenger2_country && <CircleFlag country={record.challenger2_country} left={0} />}
                        <span
                            style={{
                                marginLeft: 0,
                                fontWeight:
                                    record.challenger2_is_winner === true || record.challenger2_score === getChallengerRaceTo(record, 'challenger2')
                                        ? 'bold'
                                        : 'normal',
                            }}
                        >
                            {text || '-'}
                        </span>
                    </div>
                );
            },
        },
        {
            title: 'Time',
            dataIndex: 'start_time',
            key: 'start_time',
            width: 200,
            render: (text, record) => {
                const matches = record.matches;
                let followsMatch;

                if (matches && matches.length > 0) {
                    const follows = matches.filter((match) => {
                        if (
                            match.priority &&
                            match.priority === record.priority - 1 &&
                            record.scheduled_table_id === match.scheduled_table_id &&
                            match.status !== 'COMPLETED'
                        ) {
                            console.log(match.status);
                            return match;
                        }
                    })[0];

                    if (follows) {
                        followsMatch = follows.match_number;
                    }
                }

                return (
                    <div style={{ whiteSpace: 'nowrap' }}>
                        {(!record.start_time || !record.scheduled_time) && followsMatch
                            ? `Follows Match ${followsMatch}`
                            : record.status === 'COMPLETED' && record.end_time
                            ? `Ended - ${moment.utc(record.end_time).local().format('lll z')} ${timezone}`
                            : record.status !== 'COMPLETED' && record.scheduled_time
                            ? `Scheduled - ${moment.utc(record.scheduled_time).local().format('lll z')} ${timezone}`
                            : record.status !== 'COMPLETED' && record.start_time
                            ? `Started - ${moment.utc(record.start_time).local().format('lll z')} ${timezone}`
                            : record.status !== 'COMPLETED' && record.start_date_time
                            ? `Started - ${moment.utc(record.start_date_time).local().format('lll z')} ${timezone}`
                            : '-'}
                    </div>
                );
            },
        },

        {
            title: 'Order',
            dataIndex: 'priority',
            key: 'priority',
            width: 80,
            // defaultSortOrder: 'desc',
            sorter: (a, b) => b.priority - a.priority,
            render: (text, record) => {
                return <div>{text || '-'}</div>;
            },
        },

        {
            title: 'Identifier',
            dataIndex: 'identifier',
            key: 'identifier',
            width: 100,
        },

        {
            title: 'Progress',
            dataIndex: 'progress',
            key: 'progress',
            render: (text, record) => {
                return (
                    <React.Fragment>
                        <Progress
                            percent={record.progress || 0}
                            status={record.progress < 100 ? 'active' : record.progress === 100 ? 'success' : 'normal'}
                            size="small"
                            // showInfo={false}
                            strokeWidth={6}
                            strokeLinecap="square"
                        />
                        {/* <div
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'flex-start',
							width: '100%'
						}}
					>
						<span
							style={{
								width: '100%',
								textAlign: 'left',
								backgroundColor: '#ececec',
								height: 10,
								position: 'relative'
							}}
						>
							<span
								style={{
									display: 'inline-block',
									// backgroundColor: '#bac2cc',
									backgroundColor: '#1890fe',
									width: `${(record.progress || 0) / 100 * 100}%`,
									height: '100%',
									position: 'absolute',
									transition: 'background-color .3s ease',
									cursor: 'pointer'
								}}
							/>
						</span>
						<span
							style={{
								width: 80,
								marginLeft: 10
							}}
						>
							{record.progress || 0}%
						</span>
					</div> */}
                    </React.Fragment>
                );
            },
        },
        {
            title: 'Duration',
            dataIndex: 'duration',
            key: 'duration',
            render: (text, record) => {
                var start = moment(record.start_time);
                var end = moment(record.end_time);
                const duration = moment.duration(end.diff(start, 'milliseconds')).format('h [hrs], m [min]');

                return duration !== '0 mins' ? (
                    <div>
                        <Icon type="clock-circle" style={{ marginRight: 3 }} /> {duration}
                    </div>
                ) : (
                    <div>-</div>
                );
            },
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
        },
    ];

    function getChallengerRaceTo(currentMatch, challenger) {
        const identifier = currentMatch.identifier && currentMatch.identifier.slice(0, 1);
        const challenger_race_to =
            challenger === 'challenger1' ? currentMatch.challenger1_race_to : challenger === 'challenger2' ? currentMatch.challenger2_race_to : null;
        if (challenger_race_to) {
            return challenger_race_to;
        } else {
            if (identifier === 'W') {
                return tournament.winners_race_to;
            } else {
                return tournament.losers_race_to;
            }
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [currentMatch]);

    function getClassName(record) {
        const status =
            record.status === 'IN_PROGRESS'
                ? 'in_progress'
                : record.status === 'PENDING'
                ? 'pending blinking'
                : record.status === 'COMPLETED'
                ? 'completed'
                : record.status === 'NOT_STARTED'
                ? 'not_started'
                : '';
        const isStreamingTable = record.pool_table && record.pool_table.is_streaming_table === true ? 'streaming' : '';
        const isFeaturedTable = record.pool_table && record.pool_table.is_featured_table === true ? 'featured' : '';
        return `${status} ${isStreamingTable} ${isFeaturedTable}`;
    }

    return (
        <React.Fragment>
            <Row gutter={24} justify="center" type="flex">
                <Col xs={24}>
                    <Subscription
                        subscription={GET_TOURNAMENT_BRACKET_IN_PROGRESS_MATCHES_SUBSCRIPTION}
                        fetchPolicy="cache-and-network"
                        notifyOnNetworkStatusChange={true}
                        variables={{ tournament_id: tournament.id }}
                        onSubscriptionData={(data) => {
                            // console.log(data);
                        }}
                    >
                        {({ loading, error, data }) => {
                            if (loading) return <CircularLoader />;
                            if (error) return <div style={styles.container}>Error: {error.message}</div>;

                            const matches =
                                data.tournament_brackets &&
                                data.tournament_brackets.map((item, index) => {
                                    return {
                                        ...item,
                                        table_name: item.table_name || (item && item.scheduled_table && item.scheduled_table.label),
                                        table_id: item.table_id || (item.pool_table && item.pool_table.id),
                                        matches: data.tournament_brackets,
                                        key: index,
                                    };
                                });
                            return (
                                <React.Fragment>
                                    <Card
                                        // title="In Progress"
                                        bodyStyle={{ padding: 0 }}
                                        // style={{
                                        // 	borderRadius: 8,
                                        // 	overflow: 'hidden',
                                        // 	border: 'none',
                                        // 	boxShadow: '0 2px 5px 0px rgba(0, 0, 0, 0.1)'
                                        // }}
                                        cover={
                                            <div
                                                style={{
                                                    paddingTop: 16,
                                                    paddingBottom: 16,
                                                    paddingLeft: 24,
                                                    paddingRight: 24,
                                                    // background:'linear-gradient(rgb(239, 243, 245), rgb(255, 255, 255))',
                                                    borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
                                                }}
                                            >
                                                <Title level={4} style={{ margin: 0, padding: 0, fontSize: 18 }}>
                                                    In Progress
                                                </Title>
                                            </div>
                                        }
                                    >
                                        <div className="table_nowrap">
                                            <Table
                                                dataSource={matches}
                                                columns={columns}
                                                className="match-table table-striped-rows"
                                                tableLayout="auto"
                                                scroll={{ x: 400 }}
                                                size={defaultSize}
                                                pagination={false}
                                                // components={{
                                                // 	body: {
                                                // 		row: RenderRow
                                                // 	}
                                                // }}
                                                onRow={(record, rowIndex) => {
                                                    return {
                                                        onClick: (event) => {
                                                            // console.log("E", record);
                                                            setCurrentMatch(record);
                                                        }, // click row
                                                        onDoubleClick: (event) => {}, // double click row
                                                        onContextMenu: (event) => {}, // right button click row
                                                        onMouseEnter: (event) => {}, // mouse enter row
                                                        onMouseLeave: (event) => {}, // mouse leave row
                                                        className: getClassName(record),
                                                    };
                                                }}
                                                onHeaderRow={(column) => {
                                                    return {
                                                        onClick: () => {}, // click header row
                                                    };
                                                }}
                                            />
                                        </div>
                                    </Card>
                                </React.Fragment>
                            );
                        }}
                    </Subscription>
                </Col>
                <Col xs={24}>
                    <Subscription
                        subscription={GET_TOURNAMENT_BRACKET_UPCOMING_MATCHES_SUBSCRIPTION}
                        fetchPolicy="cache-and-network"
                        notifyOnNetworkStatusChange={true}
                        variables={{ tournament_id: tournament.id }}
                        onSubscriptionData={(data) => {
                            // console.log(data);
                        }}
                    >
                        {({ loading, error, data }) => {
                            if (loading) return <CircularLoader />;
                            if (error) return <div style={styles.container}>Error: {error.message}</div>;

                            const matches =
                                data.tournament_brackets &&
                                data.tournament_brackets.map((item, index) => {
                                    return {
                                        ...item,
                                        table_name: item.table_name || (item && item.scheduled_table && item.scheduled_table.label),
                                        table_id: item.table_id || (item.pool_table && item.pool_table.id),
                                        matches: data.tournament_brackets,
                                        key: index,
                                    };
                                });
                            return (
                                <React.Fragment>
                                    <Card
                                        // title="In Progress"
                                        bodyStyle={{ padding: 0 }}
                                        // style={{
                                        // 	borderRadius: 8,
                                        // 	overflow: 'hidden',
                                        // 	border: 'none',
                                        // 	boxShadow: '0 2px 5px 0px rgba(0, 0, 0, 0.1)'
                                        // }}
                                        cover={
                                            <div
                                                style={{
                                                    paddingTop: 16,
                                                    paddingBottom: 16,
                                                    paddingLeft: 24,
                                                    paddingRight: 24,
                                                    // background:'linear-gradient(rgb(239, 243, 245), rgb(255, 255, 255))',
                                                    borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
                                                }}
                                            >
                                                <Title level={4} style={{ margin: 0, padding: 0, fontSize: 18 }}>
                                                    Upcoming
                                                </Title>
                                            </div>
                                        }
                                    >
                                        <div className="table_nowrap">
                                            <Table
                                                dataSource={matches}
                                                columns={columns}
                                                className="match-table table-striped-rows"
                                                tableLayout="auto"
                                                scroll={{ x: 400 }}
                                                size={defaultSize}
                                                pagination={false}
                                                onRow={(record, rowIndex) => {
                                                    return {
                                                        onClick: (event) => {
                                                            // console.log("E", record);
                                                            setCurrentMatch(record);
                                                        }, // click row
                                                        onDoubleClick: (event) => {}, // double click row
                                                        onContextMenu: (event) => {}, // right button click row
                                                        onMouseEnter: (event) => {}, // mouse enter row
                                                        onMouseLeave: (event) => {}, // mouse leave row
                                                        className: getClassName(record),
                                                    };
                                                }}
                                                onHeaderRow={(column) => {
                                                    return {
                                                        onClick: () => {}, // click header row
                                                    };
                                                }}
                                            />
                                        </div>
                                    </Card>
                                </React.Fragment>
                            );
                        }}
                    </Subscription>
                </Col>
                <Col xs={24}>
                    <Subscription
                        subscription={GET_TOURNAMENT_BRACKET_BY_STATUS_SUBSCRIPTION}
                        fetchPolicy="cache-and-network"
                        notifyOnNetworkStatusChange={true}
                        variables={{ tournament_id: tournament.id, status: 'COMPLETED' }}
                        onSubscriptionData={(data) => {
                            // console.log(data);
                        }}
                    >
                        {({ loading, error, data }) => {
                            if (loading) return <CircularLoader />;
                            if (error) return <div style={styles.container}>Error: {error.message}</div>;

                            const matches =
                                data.tournament_brackets &&
                                data.tournament_brackets.map((item, index) => {
                                    return {
                                        ...item,
                                        table_name: item.table_name || (item && item.scheduled_table && item.scheduled_table.label),
                                        table_id: item.table_id || (item.pool_table && item.pool_table.id),
                                        matches: data.tournament_brackets,
                                        key: index,
                                    };
                                });
                            return (
                                <React.Fragment>
                                    <Card
                                        // title="In Progress"
                                        bodyStyle={{ padding: 0 }}
                                        // style={{
                                        // 	borderRadius: 8,
                                        // 	overflow: 'hidden',
                                        // 	border: 'none',
                                        // 	boxShadow: '0 2px 5px 0px rgba(0, 0, 0, 0.1)'
                                        // }}
                                        cover={
                                            <div
                                                style={{
                                                    paddingTop: 16,
                                                    paddingBottom: 16,
                                                    paddingLeft: 24,
                                                    paddingRight: 24,
                                                    // background:'linear-gradient(rgb(239, 243, 245), rgb(255, 255, 255))',
                                                    borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
                                                }}
                                            >
                                                <Title level={4} style={{ margin: 0, padding: 0, fontSize: 18 }}>
                                                    Completed
                                                </Title>
                                            </div>
                                        }
                                    >
                                        <div className="table_nowrap">
                                            <Table
                                                dataSource={matches}
                                                columns={columns}
                                                className="match-table table-striped-rows"
                                                tableLayout="auto"
                                                scroll={{ x: 400 }}
                                                size={defaultSize}
                                                pagination={false}
                                                onRow={(record, rowIndex) => {
                                                    return {
                                                        onClick: (event) => {
                                                            // console.log("E", record);
                                                            setCurrentMatch(record);
                                                        }, // click row
                                                        onDoubleClick: (event) => {}, // double click row
                                                        onContextMenu: (event) => {}, // right button click row
                                                        onMouseEnter: (event) => {}, // mouse enter row
                                                        onMouseLeave: (event) => {}, // mouse leave row
                                                        className: getClassName(record),
                                                    };
                                                }}
                                                onHeaderRow={(column) => {
                                                    return {
                                                        onClick: () => {}, // click header row
                                                    };
                                                }}
                                            />
                                        </div>
                                    </Card>
                                </React.Fragment>
                            );
                        }}
                    </Subscription>
                </Col>
            </Row>
        </React.Fragment>
    );
}

export default TBSchedule;
