import React, { useState, useRef } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { Form, FormItem, Input, Radio, InputNumber, Select, Checkbox, DatePicker, SubmitButton, FormikDebug, ResetButton } from 'formik-antd';
import { Formik, ErrorMessage } from 'formik';
import { message, Button, Typography, Avatar } from 'antd';
import * as Yup from 'yup';
import { Query, Mutation } from '@apollo/client/react/components';
import gql from 'graphql-tag';
import slugify from 'slugify';
import { getGameType } from '../../../utils';
import moment from 'moment-timezone';
import { CREATE_DIVISION_MUTATION, UPDATE_DIVISION_MUTATION, UPSERT_DIVISION_GAME_TYPES_MUTATION } from '../data/mutations';
import { GET_LEAGUE_QUERY, GET_LEAGUE_DIVISION_BY_SLUG_QUERY, GET_DIVISION_TYPES_QUERY, GET_GAME_TYPES_QUERY } from '../data/queries';
import _ from 'lodash';

const { TextArea } = Input;
const { Title, Text } = Typography;
const { Option } = Select;

const styles = {
    error: { color: '#f5222d', marginTop: 10 },
};

function LMDivisionCreateEditForm(props) {
    console.log(props);
    const { theme } = props;
    const [loading, setLoading] = useState(false);
    const formRef = useRef(null);
    const dateFormat = 'MM-DD-YYYY';

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    };

    const tailFormItemLayout = {
        wrapperCol: {
            xs: {
                span: 24,
                offset: 0,
            },
            sm: {
                span: 16,
                offset: 8,
            },
        },
    };

    const SignupSchema = Yup.object().shape({
        name: Yup.string().min(2, 'Too Short!').max(100, 'Too Long!').required('Required'),
        division_type: Yup.string().required('Required'),
        game_types: Yup.string().required('Required'),
    });

    function onOk(value) {
        const tzDate = moment.tz(value, moment.tz.guess(value)).format('YYYY-MM-DD hh:mm A z');
        console.log('onOK', tzDate);
        return tzDate;
    }

    function onWeeksChange(value) {
        const formValues = formRef.current.state.values;
        const startDate = formValues.start_date;
        if (value && startDate) {
            formRef.current.setValues({
                ...formValues,
                num_weeks: value,
                end_date: moment(startDate).add(value, 'W'),
            });
        }
    }

    function onDateChange(value, dateString) {
        const formValues = formRef.current.state.values;
        const numWeeks = formValues.num_weeks;
        if (numWeeks && value) {
            formRef.current.setValues({
                ...formValues,
                start_date: moment(value),
                end_date: moment(value).add(numWeeks, 'W'),
            });
        }
    }

    function handleSubmit(values, createDivision, updateDivision, actions) {
        console.log(values);
        const {
            name,
            description,
            division_type,
            game_types,
            // division_game_types,
            session_id,
            num_weeks,
            start_date,
            end_date,
            is_public,
            is_active,
            is_handicapped,
            scoring_format,
            best_of_num,
            race_to_num,
            winner_determined_by,
        } = values;

        setLoading(true);

        const slug = slugify(name, {
            replacement: '-',
            remove: /[*+~.,()'"#!:/@]/g,
            lower: true,
        });

        if (props.division) {
            // division_game_types.forEach((game_type) => {
            // 	console.log(game_type);
            // 	props.client.mutate({
            // 		mutation: UPSERT_DIVISION_GAME_TYPES_MUTATION,
            // 		variables: {
            // 			objects: [
            // 				{
            // 					division_id: props.division.id,
            // 					game_type: game_type
            // 				}
            // 			]
            // 		},
            // 		optimisticResponse: {}
            // 	});
            // });

            updateDivision({
                variables: {
                    id: props.division.id,
                    changes: {
                        name,
                        slug,
                        description,
                        division_type,
                        game_types: game_types.map((game_type) => {
                            return {
                                game_type: game_type,
                            };
                        }),
                        scoring_format: scoring_format,
                        best_of_num,
                        race_to_num,
                        winner_determined_by: winner_determined_by,
                        session_id,
                        num_weeks: parseInt(num_weeks),
                        start_date,
                        end_date,
                        is_public,
                        is_active,
                        is_handicapped,
                    },
                },
                notifyOnNetworkStatusChange: true,
                awaitRefetchQueries: true,
                refetchQueries: [
                    {
                        query: GET_LEAGUE_DIVISION_BY_SLUG_QUERY,
                        variables: {
                            leagueId: props.league.id,
                            divisionSlug: slug,
                        },
                    },
                ],
            })
                .then((values) => {
                    actions.setSubmitting(false);
                })
                .catch((e) => {
                    actions.setSubmitting(false);
                    message.error((e && e.message) || JSON.stringify(e));
                    console.log(e);
                });
        } else {
            createDivision({
                variables: {
                    objects: [
                        {
                            name,
                            slug,
                            description,
                            division_type,
                            game_types: game_types.map((game_type) => {
                                return {
                                    game_type: game_type,
                                };
                            }),
                            // division_game_types: {
                            // 	data: division_game_types.map((game_type) => {
                            // 		return {
                            // 			game_type: game_type
                            // 		};
                            // 	})
                            // },
                            scoring_format,
                            best_of_num,
                            race_to_num,
                            winner_determined_by,
                            session_id,
                            num_weeks: parseInt(num_weeks),
                            start_date,
                            end_date,
                            is_public,
                            is_active,
                            is_handicapped,
                            league_id: props.league.id,
                            operator_id: props.authState.user.id,
                        },
                    ],
                },
                notifyOnNetworkStatusChange: true,
                awaitRefetchQueries: true,
                refetchQueries: [
                    {
                        query: GET_LEAGUE_QUERY,
                        variables: {
                            id: props.league.id,
                        },
                    },
                ],
            })
                .then((values) => {
                    actions.setSubmitting(false);
                    actions.resetForm();
                })
                .catch((e) => {
                    actions.setSubmitting(false);
                    actions.resetForm();
                    message.error((e && e.message) || JSON.stringify(e));
                    console.log(e);
                });
        }
    }

    return (
        <Mutation
            mutation={CREATE_DIVISION_MUTATION}
            onCompleted={(data) => {
                console.log(data);
                setLoading(false);
                message.success('Division successfully created');
                const result = data && data.insert_divisions && data.insert_divisions.returning[0];
                if (result) {
                    props.history.push(`/league-manager/${props.league.slug}/divisions/${result.slug}`);
                } else {
                    props.history.push(`/league-manager/${props.league.slug}/divisions`);
                }

                // const { createLeague } = data;
                // history.push(`/league-manager/${createLeague.slug}`);
            }}
            awaitRefetchQueries
        >
            {(createDivision, { error }) => (
                <Mutation
                    mutation={UPDATE_DIVISION_MUTATION}
                    onCompleted={(data) => {
                        console.log(data);
                        setLoading(false);
                        message.success('Changes saved');
                        const result = data && data.update_divisions && data.update_divisions.returning[0];
                        if (result) {
                            props.history.push(`/league-manager/${props.league.slug}/divisions/${result.slug}`);
                        } else {
                            props.history.push(`/league-manager/${props.league.slug}/divisions`);
                        }
                    }}
                    // awaitRefetchQueries
                >
                    {(updateDivision, { loading, error }) => {
                        if (error) {
                            alert('Something went wrong');
                        }
                        return (
                            <Formik
                                initialValues={
                                    props.division
                                        ? {
                                              ...props.division,
                                              game_types: props.division.game_types ? props.division.game_types.map((item) => item.game_type) : [],
                                              is_public: props.division.is_public,
                                              is_active: props.division.is_active,
                                              is_handicapped: props.division.is_handicapped,
                                          }
                                        : {
                                              name: '',
                                              description: '',
                                              division_type: undefined,
                                              game_types: [],
                                              session_id: null,
                                              num_weeks: null,
                                              start_date: moment(),
                                              //   end_date: undefined,
                                              is_public: true,
                                              is_active: true,
                                              is_handicapped: false,
                                              scoring_format: 'TEN_POINT_SYSTEM',
                                              winner_determined_by: 'GAMES_WON',
                                              best_of_num: null,
                                              race_to_num: null,
                                          }
                                }
                                ref={formRef}
                                enableReinitialize
                                validationSchema={SignupSchema}
                                onSubmit={(values, actions) => {
                                    handleSubmit(values, createDivision, updateDivision, actions);
                                }}
                                render={({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                                    <Form {...formItemLayout} onSubmit={handleSubmit} layout="horizontal" style={{ maxWidth: 600 }}>
                                        <FormItem label="Division Name" name="name" autoFocus required hasFeedback showValidateSuccess>
                                            <Input name="name" placeholder="Name" size="large" />
                                        </FormItem>

                                        <FormItem label=" Description" name="description">
                                            <TextArea rows={4} name="description" placeholder="Description" size="large" />
                                        </FormItem>
                                        <FormItem label="Division Type" name="division_type" required hasFeedback showValidateSuccess>
                                            <Query query={GET_DIVISION_TYPES_QUERY} fetchPolicy="cache-and-network" notifyOnNetworkStatusChange>
                                                {({ loading, error, data }) => {
                                                    if (error) return <div>Error: {error.message}</div>;

                                                    return (
                                                        <Select
                                                            showSearch
                                                            name="division_type"
                                                            placeholder="Select a division type"
                                                            size="large"
                                                            loading={loading}
                                                            optionFilterProp="children"
                                                            // defaultValue="lucy"
                                                            filterOption={(input, option) =>
                                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                            }
                                                        >
                                                            {data &&
                                                                data.league_division_types &&
                                                                data.league_division_types.map((item, index) => (
                                                                    <Option key={index} value={item.value}>
                                                                        <Text>{_.startCase(_.toLower(item.value.replace('_', ' ')))}</Text>
                                                                    </Option>
                                                                ))}
                                                        </Select>
                                                    );
                                                }}
                                            </Query>
                                        </FormItem>
                                        <FormItem label="Game Types" name="game_types" required hasFeedback showValidateSuccess>
                                            <Query query={GET_GAME_TYPES_QUERY} fetchPolicy="cache-and-network" notifyOnNetworkStatusChange>
                                                {({ loading, error, data }) => {
                                                    if (error) return <div>Error: {error.message}</div>;

                                                    return (
                                                        <Select
                                                            showSearch
                                                            mode="multiple"
                                                            name="game_types"
                                                            loading={loading}
                                                            placeholder="Select games available in division"
                                                            size="large"
                                                            optionFilterProp="children"
                                                            // defaultValue={[ 'jack', 'lucy' ]}
                                                            filterOption={(input, option) =>
                                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                            }
                                                        >
                                                            {data &&
                                                                data.game_types &&
                                                                data.game_types
                                                                    .sort((a, b) => a.value.localeCompare(b.value))
                                                                    .map((item, index) => (
                                                                        <Option key={index} value={item.value}>
                                                                            <Avatar
                                                                                size={24}
                                                                                src={getGameType(item.value, theme)}
                                                                                style={{
                                                                                    display: 'inline-block',
                                                                                    marginRight: 8,
                                                                                }}
                                                                            />
                                                                            <Text>{_.startCase(_.toLower(item.value.replace('_', ' ')))}</Text>
                                                                        </Option>
                                                                    ))}
                                                        </Select>
                                                    );
                                                }}
                                            </Query>
                                        </FormItem>
                                        <FormItem label="Scoring format" name="scoring_format" hasFeedback showValidateSuccess style={{ flex: 1 }}>
                                            <Select name="scoring_format" defaultValue="TEN_POINT_SYSTEM" size="large">
                                                <Option value="TEN_POINT_SYSTEM">10 point system</Option>
                                                <Option value="RACE_TO">Race to number</Option>
                                                <Option value="BEST_OF">Best of number</Option>
                                                <Option value="CUSTOM">Custom</Option>
                                            </Select>
                                        </FormItem>

                                        {values.scoring_format === 'BEST_OF' ? (
                                            <FormItem label="Best of number" name="best_of_num" hasFeedback showValidateSuccess>
                                                <InputNumber name="best_of_num" min={0} placeholder="0" size="large" />
                                            </FormItem>
                                        ) : values.scoring_format === 'RACE_TO' ? (
                                            <FormItem label="Race to number" name="race_to_num" hasFeedback showValidateSuccess>
                                                <InputNumber name="race_to_num" min={0} placeholder="0" size="large" />
                                            </FormItem>
                                        ) : null}

                                        <FormItem label="Winner determined by" name="winner_determined_by" hasFeedback showValidateSuccess style={{ flex: 1 }}>
                                            <Select name="winner_determined_by" defaultValue="GAMES_WON" size="large">
                                                <Option value="GAMES_WON">Games won</Option>
                                                <Option value="FRAMES_WON">Frames won</Option>
                                                <Option value="POINTS_WON">Points won</Option>
                                                <Option value="SETS_WON">Sets won</Option>
                                            </Select>
                                        </FormItem>

                                        <FormItem label="Unique Session ID" name="session_id">
                                            <Input name="session_id" placeholder="Session ID" size="large" />
                                        </FormItem>
                                        <FormItem label="Number of weeks" name="num_weeks">
                                            <InputNumber
                                                name="num_weeks"
                                                placeholder="Number of weeks"
                                                size="large"
                                                onChange={onWeeksChange}
                                                style={{ width: 300 }}
                                            />
                                        </FormItem>
                                        <FormItem label="Start Date" name="start_date">
                                            <DatePicker
                                                name="start_date"
                                                size="large"
                                                format={dateFormat}
                                                // defaultValue={moment('01-01-2015', dateFormat)}
                                                onChange={onDateChange}
                                                onOk={onOk}
                                            />
                                        </FormItem>
                                        <FormItem label="End Date" name="end_date">
                                            <DatePicker name="end_date" size="large" format={dateFormat} onOk={onOk} />
                                        </FormItem>

                                        <FormItem label="Handicapped" name="is_handicappted">
                                            <Radio.Group name="is_handicapped">
                                                <Radio value={true}>Yes - This division is handicapped.</Radio>
                                                <br />
                                                <Radio value={false}>No - This division is not handicapped.</Radio>
                                            </Radio.Group>
                                        </FormItem>
                                        <FormItem label="Access" name="is_public">
                                            <Radio.Group name="is_public">
                                                <Radio value={true}>Public - Anyone can view this league and request to join.</Radio>
                                                <br />
                                                <Radio value={false}>Private - Only users who have been sent an invite can view or join this league.</Radio>
                                            </Radio.Group>
                                        </FormItem>
                                        <FormItem label="Status" name="is_active">
                                            <Radio.Group name="is_active">
                                                <Radio value={true}>Active</Radio>
                                                <br />
                                                <Radio value={false}>Inactive</Radio>
                                            </Radio.Group>
                                        </FormItem>
                                        <SubmitButton
                                            size="large"
                                            disabled={Object.keys(errors).length > 0 ? true : false}
                                            type={Object.keys(errors).length > 0 ? 'danger' : 'primary'}
                                            loading={loading}
                                        >
                                            {props.division ? (loading ? 'Updating...' : 'Update') : 'Create'} Division
                                        </SubmitButton>
                                        <ResetButton size="large">Clear</ResetButton>
                                        <div>
                                            <Text style={styles.error}>{errors.general}</Text>
                                        </div>
                                        {/* <FormikDebug /> */}
                                    </Form>
                                )}
                            />
                        );
                    }}
                </Mutation>
            )}
        </Mutation>
    );
}

export default withRouter(LMDivisionCreateEditForm);
