import React, { useState, useEffect, useRef } from 'react';
import { message, Modal, Button, Upload, Icon, Typography } from 'antd';
import * as Yup from 'yup';
import { Form, FormItem, Input, Radio, Select, SubmitButton, ResetButton, AutoComplete } from 'formik-antd';
import { Formik, ErrorMessage, validateYupSchema } from 'formik';
import { Query, Mutation } from '@apollo/client/react/components';
import { UPDATE_TOURNAMENT_POINTS, CREATE_TOURNAMENT_POINTS_MUTATION } from './data/mutations';
import { GET_TOURNAMENT_QUERY, GET_TOURNAMENT_POINTS_BY_ID, GET_TOURNAMENT_POINTS } from './data/queries';
import { TrophyFilled } from '@ant-design/icons';

export default function PointsModal(props) {
    const { onModalOk, onModalCancel, selectedPointsId, modalVisible, tournamentID } = props;
    const [tableDataSource, setTableDataSource] = useState();

    console.log(props);

    const formRef = useRef();

    function handleSubmit(values, createPoints, updatePoints) {
        if (selectedPointsId) {
            updatePoints({
                variables: {
                    id: selectedPointsId,
                    place: values.place,
                    points: parseInt(values.points),
                },
                notifyOnNetworkStatusChange: true,
                awaitRefetchQueries: true,
                refetchQueries: [
                    {
                        query: GET_TOURNAMENT_QUERY,
                        variables: {
                            id: parseInt(tournamentID),
                        },
                    },
                    {
                        query: GET_TOURNAMENT_POINTS,
                        variables: {
                            tournament_id: parseInt(tournamentID),
                        },
                    },
                ],
            });
        } else {
            createPoints({
                variables: {
                    objects: {
                        tournament_id: parseInt(tournamentID),
                        place: values.place,
                        points: parseInt(values.points),
                    },
                },
                notifyOnNetworkStatusChange: true,
                awaitRefetchQueries: true,
                refetchQueries: [
                    {
                        query: GET_TOURNAMENT_QUERY,
                        variables: {
                            id: parseInt(tournamentID),
                        },
                    },
                    {
                        query: GET_TOURNAMENT_POINTS,
                        variables: {
                            tournament_id: parseInt(tournamentID),
                        },
                    },
                ],
            });
        }
    }

    function cancelSubmit(e) {
        onModalCancel();
    }

    const schema = Yup.object().shape({
        // place: Yup.string().matches(/([0-9+]{0-1}|[0-9]+\-[0-9]+|[0-9+]\+)/, "Single number or number ranges are allowed: eg 1 or 5-10")
    });

    const pointsMutations = (data, loading, error) => {
        return (
            <Mutation
                mutation={CREATE_TOURNAMENT_POINTS_MUTATION}
                onCompleted={(data) => {
                    console.log(data);
                    // setLoading(false);
                    message.success('Points Saved');
                    // const result = data.insert_venues.returning[0];
                    // props.history.push(`/tournament-builder/${props.tournament.slug}/venues/${result.slug}`);
                    onModalOk();
                }}
                awaitRefetchQueries
            >
                {(createPoints, { error }) => (
                    <Mutation
                        mutation={UPDATE_TOURNAMENT_POINTS}
                        onCompleted={(data) => {
                            message.success('Points updated');
                            onModalOk();
                        }}
                        awaitRefetchQueries
                    >
                        {(updatePoints, { error }) => {
                            if (error) return error;
                            if (loading) return '..loading';

                            const points = data && data.tournament_points && data.tournament_points.length > 0 && data.tournament_points[0];

                            const initialValues = selectedPointsId
                                ? {
                                      place: points.place,
                                      points: points.points,
                                  }
                                : {
                                      place: '',
                                      points: '',
                                  };
                            return (
                                <React.Fragment>
                                    <Formik
                                        ref={formRef}
                                        validationSchema={schema}
                                        onSubmit={(values, actions) => {
                                            actions.resetForm();
                                            handleSubmit(values, createPoints, updatePoints);
                                        }}
                                        initialValues={initialValues}
                                        enableReinitialize
                                        render={({ errors, touched, values }) => (
                                            <Modal
                                                title={!selectedPointsId ? 'Add Points' : 'Edit Points'}
                                                visible={modalVisible}
                                                onOk={onModalOk}
                                                onCancel={onModalCancel}
                                                transitionName="fade"
                                                maskTransitionName="none"
                                                validationSchema={schema}
                                                footer={[
                                                    <Button key="cancel" onClick={cancelSubmit}>
                                                        Cancel
                                                    </Button>,
                                                    <Button
                                                        key="submit"
                                                        type="primary"
                                                        onClick={(e) => {
                                                            if (formRef.current) {
                                                                formRef.current.handleSubmit();
                                                            }
                                                        }}
                                                    >
                                                        {selectedPointsId === 0 ? `Add` : `Edit`} Points
                                                    </Button>,
                                                ]}
                                                destroyOnClose={true}
                                            >
                                                <Form
                                                    layout="vertical"
                                                    style={{ maxWidth: 600 }}
                                                    onSubmit={(e) => {
                                                        e.preventDefault();
                                                        // handleSubmit(values, createPayout, updatePayout);
                                                    }}
                                                >
                                                    <FormItem label="Place" name="place" hasFeedback>
                                                        <Input
                                                            addonBefore={<TrophyFilled style={{ color: 'rgba(0,0,0,.25)' }} />}
                                                            name="place"
                                                            placeholder="Place"
                                                            size="large"
                                                            autoFocus
                                                        />
                                                    </FormItem>
                                                    <FormItem label="Points" name="points" style={{ width: '100%' }} value="10">
                                                        <Input name="points" placeholder="Points" size="large" />
                                                    </FormItem>
                                                </Form>
                                            </Modal>
                                        )}
                                    />
                                </React.Fragment>
                            );
                        }}
                    </Mutation>
                )}
            </Mutation>
        );
    };

    return (
        <React.Fragment>
            {selectedPointsId ? (
                <Query
                    query={GET_TOURNAMENT_POINTS_BY_ID}
                    fetchPolicy="no-cache"
                    notifyOnNetworkStatusChange={true}
                    variables={{ id: selectedPointsId }}
                    onCompleted={async (data) => {
                        // console.log(data);
                    }}
                >
                    {({ loading, error, data }) => pointsMutations(data, loading, error)}
                </Query>
            ) : (
                pointsMutations()
            )}
        </React.Fragment>
    );
}
