import React, { useState, useEffect } from 'react';
import { withRouter, Route, Switch } from 'react-router-dom';
import { PageHeader, Tag, Icon, Tabs, Button, Typography } from 'antd';
// import SectionHeader from '../../../components/SectionHeader';
// import SectionContent from '../../../components/SectionContent';
import Card from '../../../components/Card';
import CircularLoader from '../../../components/CircularLoader';
// import usOpenHeader from '../../../assets/img/us-open-header.png';
import { Query } from '@apollo/client/react/components';
// import Sticky from 'react-stickynode';
import { StickyContainer, Sticky } from 'react-sticky';
import SectionHeader from '../../../components/SectionHeader';
import DPDivisionOverview from './DPDivisionOverview';
import DPDivisionStandings from './DPDivisionStandings';
import DPDivisionStats from './DPDivisionStats';
import DPDivisionScores from './DPDivisionScores';
import DPDivisionPlayers from './DPDivisionPlayers';
import DPDivisionTeams from './DPDivisionTeams';
import DPDivisionSchedule from './DPDivisionSchedule2';
import DPDivisionMatches from './DPDivisionMatches';
import DPDivisionMatchDetail from './DPDivisionMatchDetail';
import BreadcrumbContainer from '../../../components/BreadcrumbContainer';
import * as routes from '../../../config/routes';
import _ from 'lodash';
import gql from 'graphql-tag';
import useBreakpoint from 'use-breakpoint';
import { defaultBreakpoints } from '../../../utils/defaultBreakpoints';
import { GET_LEAGUE_DIVISION_QUERY } from '../data/queries';
// import blueBg from '../../../assets/img/abstract7.jpg';

const { TabPane } = Tabs;
const { Title, Text } = Typography;

function DPDivisionDetail(props) {
    const { authState, league, history } = props;
    const urlPath = window.location.pathname;
    const params = props.match.params;
    const slug = params.slug;
    const division = params.division;
    const splitPath = urlPath.split('/');
    const dpIncludes = ['overview', 'schedule', 'matches', 'teams', 'players', 'scores', 'standings', 'stats'];
    const [current, setCurrent] = useState();
    const { breakpoint, maxWidth, minWidth } = useBreakpoint(defaultBreakpoints);

    useEffect(() => {
        let pathMatch = _.includes(dpIncludes, splitPath[splitPath.length - 1])
            ? splitPath[splitPath.length - 1]
            : _.includes(dpIncludes, splitPath[splitPath.length - 2])
            ? splitPath[splitPath.length - 2]
            : _.includes(dpIncludes, splitPath[splitPath.length - 3])
            ? splitPath[splitPath.length - 3]
            : _.includes(dpIncludes, splitPath[splitPath.length - 4])
            ? splitPath[splitPath.length - 4]
            : 'overview';

        setCurrent(pathMatch);
        // console.log(pathMatch);
    }, [urlPath, dpIncludes, splitPath, params]);

    const styles = {
        row: {
            display: 'flex',
            flexGrow: 1,
        },
        tab: {
            paddingTop: 5,
            paddingLeft: 24,
            paddingRight: 24,
            paddingBottom: 24,
        },
        button: {
            textTransform: 'uppercase',
        },
        headerImg: {
            background: `linear-gradient(-90deg, #002eaf, #008dd0) 100% 100% no-repeat`,
            // background: `linear-gradient(0deg, rgba(0,0,0,0.2), rgba(0,0,0, 0.2)), url(${blueBg})`,
            // backgroundAttachment: 'fixed',
            backgroundRepeat: 'no-repeat',
            backgroundSize: '100%',
            // backgroundPosition: '0px -400px'

            // backgroundPosition: '0px -40px'
        },
        header: {
            // background: `linear-gradient(rgba(61, 185, 248, 0.8), rgba(24, 144, 255, 0.1)), url(${blueBg})`,
            // backgroundRepeat: 'no-repeat',
            // backgroundSize: '100%',
            paddingTop: 16,
            paddingLeft: breakpoint === 'mobile' ? 10 : 30,
            paddingRight: breakpoint === 'mobile' ? 10 : 30,
            paddingBottom: 16,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
    };

    const handleTabClick = (tab) => {
        setCurrent(tab);
        props.history.push(`/leagues/${slug}/divisions/${division}/${tab}`);
    };

    return (
        <Query
            query={GET_LEAGUE_DIVISION_QUERY}
            fetchPolicy="cache-and-network"
            notifyOnNetworkStatusChange={true}
            variables={{ leagueId: league.id, divisionSlug: props.match.params.division }}
            onCompleted={async (data) => {
                console.log(data);
            }}
        >
            {({ loading, error, data }) => {
                if (loading) return <CircularLoader />;
                if (error) return <div style={styles.container}>Error: {error.message}</div>;
                if (data && !data.league_divisions) {
                    return <div>Division not found.</div>;
                }
                if (data && data.league_divisions.length === 0) {
                    return <div>Division not found.</div>;
                }

                const divisions = data.league_divisions
                    .map((item, index) => {
                        return {
                            ...item,
                            key: index,
                            is_public: item.is_public === true ? 'Public' : 'Private',
                            is_active: item.is_active === true ? 'Active' : 'Inactive',
                            is_handicapped: item.is_handicapped === true ? 'Yes' : 'No',
                            division_type: _.startCase(_.toLower(item.division_type.replace('_', ' '))),
                            game_types: item.game_types.map((item) => _.startCase(_.toLower(item.game_type.replace('_', ' ')))).join(', '),
                        };
                    })
                    .map((division, index) => {
                        return division;
                    });

                const division = divisions[0];

                const renderTabBar = (props, DefaultTabBar) => (
                    <div
                        style={{
                            background: `linear-gradient(-90deg, #002eaf, #008dd0) 100% 100% no-repeat`,
                            // background: `linear-gradient(-45deg, rgb(80, 215, 255), rgb(0, 30, 175)) 0% 0% / 100% no-repeat fixed`,
                            boxShadow: 'rgba(0, 0, 0, 0.2) 0px 1px 15px 0px',
                            zIndex: 1,
                        }}
                    >
                        <div>
                            {breakpoint === 'mobile' && (
                                <div style={{ padding: '20px 40px 0px 40px' }}>
                                    <BreadcrumbContainer theme="dark" />
                                </div>
                            )}

                            {/* <PageHeader
										onBack={() => props.history.push(`/leagues/${league.slug}/divisions`)}
										backIcon={<Icon type="arrow-left" style={{ color: '#fff' }} />}
										title={<Text style={{ color: '#fff' }}>{division.name.toUpperCase()}</Text>}
										// subTitle={<Tag color="red">LIVE</Tag>}
										extra={[
											<Button key="join" ghost style={styles.button}>
												Join Division
											</Button>
										]}
										style={styles.header}
									/> */}

                            <SectionHeader
                                title={division.name.toUpperCase()}
                                titleStyle={{
                                    color: '#fff',
                                    textTransform: 'uppercase',
                                    margin: 0,
                                    display: 'flex',
                                    fontWeight: 400,
                                    fontSize: 22,
                                    justifyContent: 'space-between',
                                }}
                                // breadCrumb={<BreadcrumbContainer theme="dark" />}
                                backButton={
                                    <div
                                        style={{
                                            paddingLeft: 10,
                                            paddingBottom: 10,
                                            paddingTop: 10,
                                            paddingRight: 0,
                                        }}
                                    >
                                        <Icon
                                            type="arrow-left"
                                            onClick={() => history.push(`/leagues/${league.slug}/divisions`)}
                                            style={{ color: '#fff', fontSize: 16, margin: 0 }}
                                        />
                                    </div>
                                }
                                // style={styles.header}
                                // theme="tournamentBuilder"
                            >
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                    {authState && authState.user && authState.user.role === 'admin' && (
                                        <Button ghost onClick={() => history.push(`/league-manager/${league.slug}/divisions/${division.slug}`)}>
                                            <Icon type="setting" /> Manage
                                        </Button>
                                    )}

                                    <Button key="join" ghost>
                                        Join Division
                                    </Button>
                                </div>
                            </SectionHeader>
                        </div>
                        <DefaultTabBar {...props} />
                    </div>
                );

                return (
                    <Switch>
                        <Route
                            path={`${props.match.path}/matches/:match`}
                            render={(matchProps) => <DPDivisionMatchDetail league={league} division={division} {...matchProps} {...props} />}
                        />

                        <Route
                            path={`${props.match.path}`}
                            render={() => (
                                <Tabs
                                    defaultActiveKey={current}
                                    activeKey={current}
                                    animated={{ inkBar: true, tabPane: false }}
                                    // renderTabBar={() => <ScrollableInkTabBar />}
                                    // renderTabContent={() => <TabContent />}
                                    onTabClick={handleTabClick}
                                    tabPosition="top"
                                    className="tabs-bg"
                                    renderTabBar={renderTabBar}
                                    tabBarStyle={{
                                        textTransform: 'uppercase',
                                        paddingLeft: breakpoint === 'mobile' ? 10 : 40,
                                        paddingRight: breakpoint === 'mobile' ? 10 : 40,
                                        paddingTop: 0,
                                        // backgroundColor: '#132339',
                                        // boxShadow: 'rgba(0, 0, 0, 0.2) 0px 1px 15px 0px',
                                        color: '#fff',
                                        zIndex: 1,
                                        position: 'relative',
                                        // borderBottom: 'none',
                                        // borderTop: '1px solid rgba(255,255,255,0.1)',
                                        borderBottom: '1px solid transparent',
                                        borderTop: '1px solid rgba(255,255,255,0.1)',
                                        height: 'auto',
                                    }}
                                >
                                    <TabPane tab="Overview" key="overview">
                                        <DPDivisionOverview league={league} division={division} {...props} />
                                    </TabPane>
                                    <TabPane tab="Schedule" key="schedule">
                                        <DPDivisionSchedule league={league} division={division} {...props} />
                                    </TabPane>
                                    <TabPane tab={`Matches (${division.league_matches_aggregate.aggregate.count})`} key="matches">
                                        <DPDivisionMatches league={league} division={division} {...props} />
                                    </TabPane>
                                    <TabPane tab={`Teams (${division.league_team_divisions_aggregate.aggregate.count})`} key="teams">
                                        <DPDivisionTeams league={league} division={division} {...props} />
                                    </TabPane>
                                    <TabPane tab={`Players (${division.league_player_divisions_aggregate.aggregate.count})`} key="players">
                                        <DPDivisionPlayers league={league} division={division} {...props} />
                                    </TabPane>
                                    <TabPane tab="Live Scores" key="scores">
                                        <DPDivisionScores league={league} division={division} {...props} />
                                    </TabPane>
                                    <TabPane tab="Standings" key="standings">
                                        <DPDivisionStandings league={league} division={division} {...props} />
                                    </TabPane>
                                    <TabPane tab="Stats" key="stats">
                                        <DPDivisionStats league={league} division={division} {...props} />
                                    </TabPane>
                                </Tabs>
                            )}
                        />
                    </Switch>
                );
            }}
        </Query>
    );
}

export default withRouter(DPDivisionDetail);
