import React, { useState, useEffect } from 'react';
import { message, Icon, Alert, Row, Col, Typography, Button, Radio, Input, Tabs, Empty, Modal, Drawer } from 'antd';
import { withRouter } from 'react-router-dom';
import Card from '../../components/Card';
import { Query, Subscription } from '@apollo/client/react/components';
import { GET_ALL_TOURNAMENT_TABLES_QUERY, GET_TOURNAMENT_QUERY } from './data/queries';
import { DELETE_TABLE_MUTATION } from './data/mutations';
import { useMutation, useQuery } from '@apollo/react-hooks';
import CircularLoader from '../../components/CircularLoader';
import TablesAvailable from './TablesAvailable';
import AddTableModal from './AddTableModal';
import TablesList from './TablesList';

const { Text, Title } = Typography;
const { TabPane } = Tabs;

const styles = {
    row: {
        display: 'flex',
        flexGrow: 1,
    },
    tab: {
        // paddingTop: 10
    },
    selectedToggle: {
        color: '#000',
        border: '1px solid #fff',
        boxShadow: 'none',
    },
    defaultToggle: {
        color: '#fff',
        backgroundColor: 'transparent',
        border: '1px solid #fff',
        boxShadow: 'none',
    },
};

function TBCreateAddTables(props) {
    const { tournament, onContinue, goBack } = props;
    const [tableModalVisible, setTableModalVisible] = useState(false);
    const [selectedTableId, setSelectedTableId] = useState();
    const [tables, setTables] = useState();
    const [deleteTable, { loading, error }] = useMutation(DELETE_TABLE_MUTATION);

    useEffect(() => {
        console.log('tournament changed');
    }, [tournament]);

    function showTableModal() {
        setTableModalVisible(true);
    }

    function handleTableModalOk(e) {
        console.log(e);
        setTableModalVisible(false);
    }

    function handleTableModalCancel(e) {
        setSelectedTableId();
        setTableModalVisible(false);
    }

    function handleTableSelected(selected) {
        console.log(selected);
        setSelectedTableId(selected);
    }

    function handleEditTableSelected(selected) {
        console.log(selected);
        setSelectedTableId(selected);
        setTableModalVisible(true);
    }

    return (
        <Query
            query={GET_ALL_TOURNAMENT_TABLES_QUERY}
            fetchPolicy="cache-and-network"
            notifyOnNetworkStatusChange
            awaitRefetchQueries
            variables={{ tournament_id: tournament.id }}
            onCompleted={(data, error, loading) => {
                console.log(data);
                // const tables =
                //     data.pool_tables &&
                //     data.pool_tables.map((item, index) => {
                //         return {
                //             ...item,
                //             key: index,
                //             index: index + 1,
                //         };
                //     });
                // setTables(tables);
            }}
        >
            {({ loading, error, data }) => {
                if (loading)
                    return (
                        <div style={styles.container}>
                            <CircularLoader />
                        </div>
                    );
                if (error) return <div style={styles.container}>Error: {error.message}</div>;

                const tables =
                    data.pool_tables &&
                    data.pool_tables.map((item, index) => {
                        return {
                            ...item,
                            key: index,
                            index: index + 1,
                        };
                    });

                return (
                    <React.Fragment>
                        <div>
                            <Alert
                                message="Helpful tips for adding tables"
                                description="You can add tables individually or many at once by creating multiple tables, or import pre-existing tables from a venue."
                                type="info"
                                closable
                                showIcon
                            />
                        </div>

                        <div
                            style={{
                                marginTop: 20,
                                marginBottom: 20,
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <Title level={4} style={{ margin: 0 }}>
                                All Tables ({tables && tables.length})
                            </Title>
                            <div>
                                <Button type="primary" onClick={showTableModal} style={{ marginLeft: 20 }}>
                                    <Icon type="plus" /> Add Tables
                                </Button>

                                {/* <Button onClick={onContinue}>
									Go to Bracket <Icon type="arrow-right" style={{ marginTop: 3 }} />
								</Button> */}
                            </div>
                        </div>
                        <TablesList
                            {...props}
                            onSelected={handleTableSelected}
                            onEditSelected={handleEditTableSelected}
                            size="small"
                            onDelete={(record) => {
                                deleteTable({
                                    variables: {
                                        id: record.id,
                                    },
                                    notifyOnNetworkStatusChange: true,
                                    awaitRefetchQueries: true,
                                    refetchQueries: [
                                        {
                                            query: GET_ALL_TOURNAMENT_TABLES_QUERY,
                                            variables: { tournament_id: tournament.id },
                                        },
                                        {
                                            query: GET_TOURNAMENT_QUERY,
                                            variables: { id: tournament.id },
                                        },
                                    ],
                                })
                                    .then((data) => {
                                        console.log(data);
                                        message.success('Table successfully deleted');
                                    })
                                    .catch((error) => {
                                        console.log(error);
                                        message.error('There was an error', error);
                                    });
                            }}
                            tables={tables}
                        />
                        <Button type="primary" size="large" onClick={onContinue} style={{ marginTop: 20 }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Text style={{ color: '#fff' }}>Save and go to step 4</Text>
                            </div>
                        </Button>
                        <Button
                            type="secondary"
                            size="large"
                            onClick={() => {
                                goBack();
                            }}
                            style={{ marginTop: 20 }}
                        >
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Text>Go back</Text>
                            </div>
                        </Button>
                        {/* <TablesAvailable
							{...props}
							tournament={tournament}
							data={data.pool_tables}
							tableModalVisible={tableModalVisible}
							setTableModalVisible={setTableModalVisible}
							onTableSelected={(tableId) => {
								setSelectedTableId(tableId);
								setTableModalVisible(true);
							}}
						/> */}
                        <AddTableModal
                            {...props}
                            tournament={tournament}
                            venue={tournament && tournament.venue}
                            selectedTableId={selectedTableId}
                            tableModalVisible={tableModalVisible}
                            onTableModalOk={handleTableModalOk}
                            onTableModalCancel={handleTableModalCancel}
                            redirectAfterSave={false}
                        />
                    </React.Fragment>
                );
            }}
        </Query>
    );
}

export default withRouter(TBCreateAddTables);
