import useMediaQuery from './useMediaQuery';

export default function useBreakpoints() {
	return {
		isXs: useMediaQuery('(max-width: 640px)'),
		isSm: useMediaQuery('(min-width: 641px) and (max-width: 768px)'),
		isMd: useMediaQuery('(min-width: 769px) and (max-width: 1024px)'),
		isLg: useMediaQuery('(min-width: 1025px) and (max-width: 1280px)'),
		isXl: useMediaQuery('(min-width: 1281px)')
	};
}
