import React from 'react';
import { withRouter } from 'react-router-dom';
import { Descriptions, Row, Avatar, List, Skeleton, Button, Empty, Icon, Col, Typography } from 'antd';
import { Link } from 'react-router-dom';
import CircularLoader from '../../../components/CircularLoader';
import SectionHeader from '../../../components/SectionHeader';
import SectionContent from '../../../components/SectionContent';
import SectionWrapper from '../../../components/SectionWrapper';
import Card, { Meta } from '../../../components/Card';
// import DPDivisionCard from './DPDivisionCard';
// import DPTournamentSchedule from '../tournaments/DPTournamentSchedule';
import { getGameType, displayEnumType } from '../../../utils';
import { Query } from '@apollo/client/react/components';
import { GET_LEAGUE_DIVISION_SCHEDULE_BY_SLUG, GET_UPCOMING_LEAGUE_MATCHES_QUERY } from '../data/queries';
import blueBg from '../../../assets/img/blue-bg.png';
import defaultImg from '../../../assets/img/ball/default.png';
import _ from 'lodash';
import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';
momentDurationFormatSetup(moment);

const { Title, Text } = Typography;

const styles = {
    headerImg: {
        height: 120,
        overflow: 'hidden',
        borderRadius: 0,
        background: `linear-gradient(rgba(61, 185, 248, 0.8), rgba(24, 144, 255, 0.1)), url(${blueBg}) 0px 0px no-repeat`,
        // backgroundAttachment: 'fixed',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100%',
        display: 'flex',
        alignItems: 'center',
        paddingLeft: 40,
        paddingRight: 40,
        // backgroundPosition: '0px -40px'
    },
};

function LMDivisionScheduleDetail(props) {
    const { league, division } = props;
    console.log(props);

    return (
        <SectionWrapper>
            <SectionHeader
                title={division.name}
                titleStyle={{ color: '#fff', margin: 0, textTransform: 'uppercase' }}
                subtitle="Division Schedule"
                subtitlePrefix={true}
                subtitleStyle={{
                    color: 'rgba(255,255,255,0.8)',
                    textTransform: 'uppercase',
                    fontSize: 12,
                    letterSpacing: 3,
                }}
                theme="leagueManager"
                backButton={
                    <Button
                        onClick={() => props.history.push(`/league-manager/${props.league.slug}/divisions/${props.division.slug}/schedule`)}
                        type="default"
                        shape="circle"
                        icon="arrow-left"
                        size="large"
                        ghost
                        style={{ border: 'none' }}
                    />
                }
            >
                <Button
                    size="large"
                    type="ghost"
                    ghost
                    onClick={() => {
                        props.history.push(`/league-manager/${league.slug}/divisions/${division.slug}/schedule/${props.match.params.schedule}/edit`);
                    }}
                >
                    <Icon type="edit" /> Edit
                </Button>

                <Link to={`/leagues/${league.slug}/divisions/${props.match.params.division}/schedule`} style={{ marginLeft: 10 }}>
                    <Button size="large" type="ghost" ghost>
                        View Public Profile
                        <Icon type="arrow-right" />
                    </Button>
                </Link>
            </SectionHeader>
            <SectionContent padding="20px 40px">
                <Row gutter={24} justify="center" type="flex">
                    <Col xs={24}>
                        <Query
                            query={GET_LEAGUE_DIVISION_SCHEDULE_BY_SLUG}
                            fetchPolicy="cache-and-network"
                            notifyOnNetworkStatusChange={true}
                            variables={{
                                slug: props.match.params.schedule,
                            }}
                            onCompleted={(data) => {
                                console.log(data);
                            }}
                        >
                            {({ loading, error, data }) => {
                                if (loading) return <CircularLoader />;
                                if (error) return <div style={styles.container}>Error: {error.message}</div>;

                                const schedule =
                                    data.league_schedules &&
                                    data.league_schedules.map((item, index) => {
                                        return {
                                            ...item,
                                            key: index,
                                        };
                                    })[0];

                                let weeks = [];
                                let weekNum = 0;

                                schedule.league_scheduled_matches &&
                                    schedule.league_scheduled_matches.forEach((match, i) => {
                                        if (i > 0 && match.week === schedule.league_scheduled_matches[i - 1].week) {
                                            weeks[weekNum].push(match);
                                        } else if (i === 0) {
                                            weeks.push([match]);
                                            // if (!round.challenger1_seed) {
                                            // 	unseeded = true;
                                            // }
                                        } else if (i > 0 && match.week !== schedule.league_scheduled_matches[i - 1].week) {
                                            weeks.push([match]);
                                            weekNum++;
                                        }
                                    });

                                console.log(weeks);

                                return (
                                    <React.Fragment>
                                        {schedule && (
                                            <Row gutter={24}>
                                                <Col xs={24} lg={11}>
                                                    <Card
                                                        cover={
                                                            <div
                                                                style={{
                                                                    paddingTop: 16,
                                                                    paddingBottom: 16,
                                                                    paddingLeft: 24,
                                                                    paddingRight: 24,
                                                                    borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'space-between',
                                                                }}
                                                            >
                                                                <Title level={4} style={{ margin: 0, padding: 0, fontSize: 18 }}>
                                                                    {schedule && schedule.name}
                                                                </Title>
                                                                {/* <div
																	style={{
																		display: 'flex',
																		alignItems: 'center',
																		justifyContent: 'space-between'
																	}}
																>
																	<Button
																		type="secondary"
																		onClick={() => {
																			props.history.push(
																				`/league-manager/${league.slug}/divisions/${division.slug}/schedule/${schedule.slug}/edit`
																			);
																		}}
																	>
																		<Icon type="edit" /> Edit
																	</Button>
																</div> */}
                                                            </div>
                                                        }
                                                    >
                                                        <Row gutter={24} justify="center" type="flex">
                                                            <Col xs={24}>
                                                                <Descriptions
                                                                    // title="League Details"
                                                                    size="small"
                                                                    column={2}
                                                                    bordered={false}
                                                                    layout="horizontal"
                                                                >
                                                                    <Descriptions.Item label="Description">
                                                                        <Text>{schedule.description}</Text>
                                                                    </Descriptions.Item>

                                                                    <Descriptions.Item label="Start Date">
                                                                        {moment(schedule.start_date).format('lll')}
                                                                    </Descriptions.Item>
                                                                    {/* <Descriptions.Item label="Start Time">
																		{moment(schedule.start_time).format('h:mm A')}
																	</Descriptions.Item> */}
                                                                    <Descriptions.Item label="Duration Type">
                                                                        <Text>{schedule.duration_type ? displayEnumType(schedule.duration_type) : ''}</Text>
                                                                    </Descriptions.Item>

                                                                    <Descriptions.Item label="Total Weeks">
                                                                        <Text>{schedule.total_weeks}</Text>
                                                                    </Descriptions.Item>

                                                                    <Descriptions.Item label="Active">
                                                                        <Text>{schedule.is_active ? 'Yes' : 'No'}</Text>
                                                                    </Descriptions.Item>
                                                                </Descriptions>
                                                            </Col>
                                                        </Row>
                                                    </Card>
                                                </Col>

                                                <Col xs={24} lg={13}>
                                                    <Card
                                                        cover={
                                                            <div
                                                                style={{
                                                                    paddingTop: 16,
                                                                    paddingBottom: 16,
                                                                    paddingLeft: 24,
                                                                    paddingRight: 24,
                                                                    // background:'linear-gradient(rgb(239, 243, 245), rgb(255, 255, 255))',
                                                                    borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'space-between',
                                                                }}
                                                            >
                                                                <Title level={4} style={{ margin: 0, padding: 0 }}>
                                                                    Scheduled Matches
                                                                </Title>
                                                                <div
                                                                    style={{
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                        justifyContent: 'space-between',
                                                                    }}
                                                                >
                                                                    <Button
                                                                        type="secondary"
                                                                        onClick={() => {
                                                                            console.log('set round times');
                                                                            props.history.push(
                                                                                `/league-manager/${props.league.slug}/divisions/${props.division.slug}/schedule/${schedule.slug}/matches/edit`
                                                                            );
                                                                        }}
                                                                    >
                                                                        <Icon type="edit" /> Edit Matches
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        }
                                                    >
                                                        {weeks.map((week, index) => {
                                                            console.log(week);
                                                            return (
                                                                <div key={index} style={{ marginBottom: 30 }}>
                                                                    <div
                                                                        style={{
                                                                            width: '100%',
                                                                            position: 'relative',
                                                                            marginBottom: 5,
                                                                        }}
                                                                    >
                                                                        <Title level={4}>
                                                                            <b>Week {week[0].week}</b> - {moment(week[0].start_date).format('lll')}
                                                                        </Title>
                                                                    </div>
                                                                    {week.map((match, index) => {
                                                                        return (
                                                                            <Text
                                                                                key={index}
                                                                                style={{
                                                                                    display: 'block',
                                                                                    // fontWeight: 600,
                                                                                    fontSize: 16,
                                                                                    marginBottom: 5,
                                                                                }}
                                                                            >
                                                                                {match.home_team ? (
                                                                                    <Link
                                                                                        to={`/league-manager/${league.slug}/divisions/${props.match.params.division}/teams/${match.home_team.slug}`}
                                                                                        style={{ marginRight: 5 }}
                                                                                    >
                                                                                        {match.home_team_name}
                                                                                    </Link>
                                                                                ) : (
                                                                                    <React.Fragment>{match.home_team_name}</React.Fragment>
                                                                                )}{' '}
                                                                                vs.
                                                                                {match.visitor_team ? (
                                                                                    <Link
                                                                                        to={`/league-manager/${league.slug}/divisions/${props.match.params.division}/teams/${match.visitor_team.slug}`}
                                                                                        style={{ marginLeft: 5 }}
                                                                                    >
                                                                                        {match.visitor_team_name}
                                                                                    </Link>
                                                                                ) : (
                                                                                    <React.Fragment>{match.visitor_team_name}</React.Fragment>
                                                                                )}
                                                                            </Text>
                                                                        );
                                                                    })}

                                                                    <Text>{week[0].venue_name}</Text>
                                                                </div>
                                                            );
                                                        })}

                                                        {/* {schedule.league_scheduled_matches && (
													<React.Fragment>
														<table>
															<tbody>
																<tr>
																	<th>Week</th>
																	<th>Date</th>
																	<th>Time</th>
																	<th>Matchups</th>
																</tr>
																{schedule.league_scheduled_matches.map(
																	(match, index) => {
																		return (
																			<tr key={index}>
																				<th>{match.week}</th>
																				<th>{match.date}</th>
																				<th>{match.time}</th>
																				<th>
																					<ul>
																						{match.matches.map(
																							(match, index2) => {
																								return (
																									<li key={index2}>
																										{match[0].team.name}{' '}
																										vs.{' '}
																										{match[1].team.name}
																									</li>
																								);
																							}
																						)}
																					</ul>
																				</th>
																			</tr>
																		);
																	}
																)}
															</tbody>
														</table>
													</React.Fragment>
												)} */}
                                                    </Card>
                                                </Col>
                                            </Row>
                                        )}
                                    </React.Fragment>
                                );
                            }}
                        </Query>
                    </Col>
                </Row>
            </SectionContent>
        </SectionWrapper>
    );
}

export default withRouter(LMDivisionScheduleDetail);
