import { fromPromise } from 'apollo-link';
import React, { useState } from 'react';
import { Table } from 'antd';
import { Query } from '@apollo/react-components';
import CircularLoader from '../../../../components/CircularLoader';
import { GET_GHOST_GAMES_STATS_QUERY } from '../../data/queries';

function orderData(data) {
    let one, two, tmp, setPlace;

    for(let i=0; i < data.length; i++) {
        one = data[i].points;

        for(let j=0; j < data.length; j++) {
            two = data[j].points;

            if(one >= two) {
                tmp = data[i];
                data[i] = data[j];
                data[j] = tmp;
            }
        }
    }

    for(let i=0; i < data.length; i++) {
        data[i].place = i + 1;
    }

    return data;
}

export default function StatsTable(props) {
    const { ghostEventId } = props;

    const [ tableData, setTableData ] = useState([]);

    const columns = [
        {
            title: 'Place',
            dataIndex: 'place',
            key: 'place',
            width: '15%'
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: 'Average Points Per Rack',
            dataIndex: 'average_points_per_rack',
            key: 'average_points_per_rack'
        },
        {
            title: 'Average Points Per Attempt',
            dataIndex: 'average_points_per_attempt',
            key: 'average_points_per_attempt'
        },
        {
            title: 'Best Rack',
            dataIndex: 'best_rack',
            key: 'best_rack'
        },
        {
            title: 'Best Attempt',
            dataIndex: 'best_attempt',
            key: 'best_attempt'
        },
        {
            title: 'Points',
            dataIndex: 'points',
            key: 'points'
        }
    ];

    return (
        <Query
            query={GET_GHOST_GAMES_STATS_QUERY}
            fetchPolicy="cache-and-network"
            notifyOnNetworkStatusChange
            awaitRefetchQueries
            variables={{ id: ghostEventId }}
            onCompleted={(data) => {
                var oldAttempt;
                var rackPoints = 0;
                var rounds;
                var ppaArr = [];
                var points = 0;
                var avg;
                var oldRackPoints = 0;
                var bestRackPoints = 0;
                var bestAttemptPoints = 0;
                var oldAttemptPoints = 0;
                var attemptForward = 0
                var overallPoints = 0;
                var preorderedData = [];
                var orderedData = [];

                const d = data && data.ghost_games[0] && data.ghost_games[0].ghost_games_players;
                oldAttempt = d && d[0] && d[0].ghost_games_rounds && d[0].ghost_games_rounds[0] && d[0].ghost_games_rounds[0].attempt;

                const td = d.map((val, key) => {
                    for(let i=0; i < val.ghost_games_rounds.length; i++) {
                        rounds = val.ghost_games_rounds[i];

                        attemptForward = (val.ghost_games_rounds[i + 1]) ? val.ghost_games_rounds[i + 1].attempt : 0;
                        rackPoints += rounds.points;

                        if(rounds.points > bestRackPoints) {
                            bestRackPoints = rounds.points;
                        }

                        if(attemptForward !== rounds.attempt || i === val.ghost_games_rounds.length - 1) {
                            ppaArr.push(rackPoints);
                            oldAttempt = rounds.attempt;
                            rackPoints = 0;
                        }
                    }

                    for(let i=0; i < ppaArr.length; i++) {
                        if(ppaArr[i] > bestAttemptPoints) {
                            bestAttemptPoints = ppaArr[i];
                        }

                        overallPoints += ppaArr[i];
                    }

                    avg = (!isNaN(overallPoints / ppaArr.length)) ? Math.floor(overallPoints / ppaArr.length) : 0;
                    
                    var ba = bestAttemptPoints;
                    var br = bestRackPoints;

                    ppaArr = [];
                    points = 0;
                    bestRackPoints = 0;
                    oldRackPoints = 0;
                    oldAttemptPoints = 0;
                    bestAttemptPoints = 0;
                    overallPoints = 0;

                    preorderedData.push({
                        key: key,
                        place: null,
                        name: (val.user.first_name) ? `${val.user.first_name} ${val.user.last_name}` : val.user.display_name,
                        average_points_per_rack: Math.floor(val.ghost_games_rounds_aggregate.aggregate.avg.points),
                        average_points_per_attempt: avg,
                        best_rack: br,
                        best_attempt: ba,
                        points: val.ghost_games_rounds_aggregate.aggregate.sum.points
                    });
                });

                orderedData = orderData(preorderedData);

                setTableData(preorderedData);
            }}
        >
            {({loading, error, data}) => {
                if(loading) return <CircularLoader />;
                if(error) return <div>{error}</div>;
                
                return (
                    <Table columns={columns} dataSource={tableData} pagination={false} />
                )
            }}
        </Query>
    );
}