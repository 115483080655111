import React, { useState, useEffect } from 'react';
import { withRouter, Route } from 'react-router-dom';
import Match from './Match';
import Sticky from 'react-stickynode';
import { Button, Affix, Icon, Typography } from 'antd';
import { coordinatesByIdentifier } from '../../../../lib/playerBracketCoords';
import { getOrdinalSuffix } from '../../../../utils';
import numeral from 'numeral';
import _ from 'lodash';
import moment from 'moment-timezone';
import momentDurationFormatSetup from 'moment-duration-format';
momentDurationFormatSetup(moment);

const { Text } = Typography;

function Round(props) {
    const {
        history,
        tournament,
        stage,
        stages,
        bracket,
        tables,
        matches,
        bracketDisplayMode,
        rounds,
        compact,
        isPublic,
        i,
        onShowScoreModal,
        onUpdateCoordinates,
        onSetRoundTime,
        onMatchSelected,
        onSelectedStage,
        setRoundTimes,
        theme,
    } = props;

    const consolidationFinals = stage ? stage.consolidation_finals : tournament ? tournament.consolidation_finals : false;
    const tournamentType = (stage && stage.tournament_type) || (tournament && tournament.tournament_type);
    const alphabet = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', ' Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];

    async function identifierClick(round, match) {
        let identifier = match.loser_to === null && match.winner_to ? match.winner_to : match.loser_to;
        let coords = coordinatesByIdentifier(identifier);
        if (coords && onUpdateCoordinates) {
            onUpdateCoordinates(coords);
        }
    }

    function getRoundTitle(rounds, index) {
        const round = rounds && rounds.length > 0 && parseInt(rounds[0].round);

        const title =
            round > 1 && index === bracket.length - 1
                ? tournament.tournament_stage_format === 'multi'
                    ? stage && stage.number === stages.length
                        ? `${stage && stage.name} Final`
                        : stages && stages[1]
                        ? `${stages[1].name} Qualification`
                        : consolidationFinals === true
                        ? 'Double Elim Final'
                        : 'Final'
                    : consolidationFinals === true
                    ? 'Double Elim Final'
                    : 'Final'
                : round > 1 && index === bracket.length - 1
                ? tournament.tournament_stage_format === 'multi'
                    ? stage && stage.number === stages.length
                        ? `${stage && stage.name} Final`
                        : stages && stages[1]
                        ? `${stages[1].name} Qualification`
                        : consolidationFinals === true
                        ? 'Double Elim Final'
                        : 'Final'
                    : consolidationFinals === true
                    ? 'Final'
                    : 'Semi-Final'
                : round > 1 && index === bracket.length - 2
                ? consolidationFinals === true
                    ? 'WB Final'
                    : stage && stage.number === stages.length
                    ? 'WB Semi-Final'
                    : `WB Round ${round}`
                : round > 1
                ? `WB Round ${round}`
                : round === 1
                ? `WB Round ${round}`
                : round < 0
                ? round < 0 && index === bracket.length - 1
                    ? tournament.tournament_stage_format === 'multi'
                        ? stages && stages[1] && `${stages[1].name} Qualification`
                        : `LB Round ${round.toString().substring(1)}`
                    : `LB Round ${round.toString().substring(1)}`
                : null;
        return title;
    }

    function getRoundPlace(rounds, index) {
        const place = rounds && rounds.length > 0 && rounds[0].place;
        return place ? place === '2' ? consolidationFinals === true ? '2ND' : '1ST-2ND' : getOrdinalSuffix(place) : <div>&nbsp;</div>;
    }

    function handleScoreModal(match, round) {
        onShowScoreModal(match, round);
    }

    function renderFooterText(round, match) {
        return (
            <Button
                type="link"
                onClick={(e) => {
                    if (stages && match.advance_to) {
                        const nextStage = stages && stages[stages.length - 1];
                        if (isPublic === true) {
                            props.history.push(`/tournaments/${tournament.slug}/bracket/${nextStage.slug}`);
                        } else {
                            props.history.push(`/tournament-builder/${tournament.slug}/bracket/${nextStage.slug}`);
                        }
                        onSelectedStage(nextStage);
                    } else {
                        identifierClick(round, match);
                    }
                }}
                className="match-link"
                style={{ color: match.advance_to ? '#ff6163' : '#888' }}
            >
                {match.advance_to
                    ? `Advance to ${stages && stages[stages.length - 1] && stages[stages.length - 1].name} ⇢`
                    : round === 1 && tournamentType === 'double_elimination'
                    ? `L to ${match.loser_to_num}`
                    : round === bracket.length + 1 && consolidationFinals === true
                    ? `Double Elim Final`
                    : round === bracket.length && match.is_finals === true && consolidationFinals === true
                    ? `Final`
                    : round > 1 && round === bracket.length && consolidationFinals !== true && tournamentType === 'double_elimination'
                    ? stage && stage.number === 1
                        ? `L to ${match.loser_to_num}`
                        : `Semi-Final (L to ${match.loser_to_num})`
                    : round > 1 && round === bracket.length && consolidationFinals !== true
                    ? `Semi-Final`
                    : round === bracket.length + 1 && match.is_finals === true && consolidationFinals !== true
                    ? `Final`
                    : round > 1 && match.loser_to && tournamentType === 'double_elimination'
                    ? `L to ${match.loser_to_num}`
                    : round % 2 === 0 && match.loser_from && tournamentType === 'double_elimination'
                    ? `L from ${match.loser_from_num} - W to ${match.winner_to_num}`
                    : Math.abs(round) % 2 === 1
                    ? `${match.winner_to_num ? `W to ${match.winner_to_num}` : ''}`
                    : round > 1 && match.loser_to && tournamentType === 'single_elimination'
                    ? `W to ${match.winner_to_num}`
                    : `${match.identifier}`}
                {/* <span> - Place: {match.place}</span> */}
            </Button>
        );
    }

    function renderFooterTextWithIdentifiers(round, match) {
        return (
            <Button
                type="link"
                onClick={(e) => {
                    identifierClick(round, match);
                }}
                className="match-link"
            >
                {round === 1
                    ? `${match.identifier} (L to ${match.loser_to_num})`
                    : round === bracket.length + 1
                    ? consolidationFinals === true
                        ? `${match.identifier} (Double Elim Final)`
                        : `${match.identifier} (Final)`
                    : round === bracket.length
                    ? consolidationFinals === true
                        ? `${match.identifier} (Final)`
                        : `${match.identifier}`
                    : round > 1 && match.loser_to_num
                    ? `${match.identifier} (L to ${match.loser_to_num})`
                    : round % 2 === 0 && match.loser_from
                    ? `${match.identifier} (L from ${match.loser_from_num} - W to ${match.winner_to_num})`
                    : Math.abs(round) % 2 === 1
                    ? `${match.winner_to_num ? `${match.identifier} (W to ${match.winner_to_num})` : ''}`
                    : `${match.identifier}`}
            </Button>
        );
    }

    let payout = rounds && tournament.tournament_payouts && tournament.tournament_payouts.filter((payout) => rounds[0].place === payout.place)[0];
    // console.log(payout);

    var zone_name = moment.tz.guess();
    var timezone = moment.tz(zone_name).zoneAbbr();

    return (
        <div
            className={`round r${rounds ? rounds[0].round : ''} ${compact === true && 'compact'}`}
            style={
                payout && payout.money
                    ? theme && theme.bracket && theme.bracket.moneyRound && { backgroundColor: theme.bracket.moneyRound.backgroundColor }
                    : {}
            }
        >
            {/* <Sticky
				enabled={true}
				top={100}
				innerZ={10}
				activeClass="title-sticky-active"
				releasedClass="title-sticky-released"
			>
				<div className="round-header">
					<div>{getRoundTitle(rounds, i)}</div>
					<div>{getRoundPlace(rounds, i)}</div>
				</div>
			</Sticky> */}
            {/* <div className="background">
				<Affix
					offsetBottom={50}
					target={() => document.querySelector('.scroll-container')}
					onChange={(affixed) => console.log('affixed', affixed)}
				>
					<div className="round-header">
						<div>{getRoundTitle(rounds, i)}</div>
						<div>
							{payout && payout.money ? (
								<b>
									{getRoundPlace(rounds, i)} (${payout.money})
								</b>
							) : (
								getRoundPlace(rounds, i)
							)}
						</div>
					</div>
				</Affix>
			</div> */}

            <div
                className="round-header"
                style={{
                    color: theme && theme.bracket && theme.bracket.roundHeader && theme.bracket.roundHeader.color,
                    backgroundColor: theme && theme.bracket && theme.bracket.roundHeader && theme.bracket.roundHeader.backgroundColor,
                }}
            >
                <div>
                    {getRoundTitle(rounds, i)}
                    {rounds && rounds.length > 0 && setRoundTimes !== false ? (
                        <div style={{ marginTop: 5, marginBottom: 5 }}>
                            {rounds[0] && rounds[0].scheduled_time ? (
                                <Button
                                    type="text"
                                    size="small"
                                    ghost={theme && theme.name === 'dark' ? true : false}
                                    onClick={() => {
                                        onSetRoundTime(rounds[0], rounds);
                                    }}
                                >
                                    {/* <Icon type="edit" style={{ marginRight: 3 }} />{' '} */}
                                    <Icon type="clock-circle" style={{ marginRight: 3 }} /> {moment.utc(rounds[0].scheduled_time).local().format('lll z')}{' '}
                                    {timezone}
                                </Button>
                            ) : (
                                <Button
                                    type="ghost"
                                    size="small"
                                    ghost={theme && theme.name === 'dark' ? true : false}
                                    onClick={() => {
                                        onSetRoundTime(rounds[0], rounds);
                                    }}
                                >
                                    <Icon type="clock-circle" style={{ marginRight: 3 }} /> Set times
                                </Button>
                            )}
                        </div>
                    ) : (
                        <div>
                            {rounds && rounds[0] && rounds[0].scheduled_time && (
                                <Text style={{ color: theme && theme.bracket && theme.bracket.roundHeader && theme.bracket.roundHeader.color }}>
                                    {moment.utc(rounds[0].scheduled_time).local().format('lll z')} {timezone}
                                </Text>
                            )}
                        </div>
                    )}
                </div>

                {payout && payout.money ? (
                    <div>
                        <b>
                            {getRoundPlace(rounds, i)} (${numeral(payout.money).format('0,0')})
                        </b>
                    </div>
                ) : (
                    <div>{getRoundPlace(rounds, i)}</div>
                )}
            </div>

            {/* {rounds &&
										parseInt(rounds[0].round) < 1 &&
										parseInt(rounds[0].round) % 2 === 0 && <div className="spacer" />} */}
            <div className="spacer" />
            <React.Fragment>
                {rounds &&
                    rounds.map((match, index) => {
                        // console.log('round match', match);
                        const challenger1_score = parseInt(match.challenger1_score);
                        const challenger2_score = parseInt(match.challenger2_score);

                        return (
                            <React.Fragment key={index}>
                                <Match
                                    {...props}
                                    tournament={tournament}
                                    match={match}
                                    matches={matches}
                                    tables={tables}
                                    index={index}
                                    className={`match ${rounds.length > 1 ? (index % 2 === 0 ? 'top' : 'bottom') : ''} w${rounds[0].round}-${index + 1} ${
                                        (match.status && match.status.toLowerCase()) || 'not_started'
                                    } ${
                                        match.status === 'IN_PROGRESS' &&
                                        match.pool_table &&
                                        match.pool_table.is_streaming_table &&
                                        match.pool_table.is_streaming_table === true
                                            ? 'streaming'
                                            : ''
                                    }  ${match.is_bye === true ? 'bye' : ''}  ${rounds[0].round % 2 === 0 ? 'even' : 'odd'} ${
                                        match.status === 'NOT_STARTED' && match.pool_table && match.pool_table.id ? 'assigned' : ''
                                    } ${match.pool_table && match.pool_table.status === 'ASSIGNED' ? 'assigned' : ''} ${
                                        match.challenger1_name && match.challenger2_name ? 'has_players' : ''
                                    } ${payout && payout.money ? 'has_payout' : ''}`}
                                    roundNumber={match.round}
                                    totalRounds={rounds.length}
                                    startTime={match.start_time}
                                    scheduledTime={match.scheduled_time}
                                    number={match.match_number}
                                    status={match.status}
                                    table={match.pool_table && match.pool_table}
                                    tableName={match.pool_table && match.pool_table.label}
                                    isLive={match.status === 'LIVE' || match.status === 'IN_PROGRESS'}
                                    isScheduled={match.scheduled_time}
                                    isCompleted={match.status === 'COMPLETED'}
                                    isPending={match.status === 'PENDING'}
                                    payout={payout}
                                    progress={match.progress}
                                    players={[
                                        {
                                            id: match.challenger1_id,
                                            playerName: match.challenger1_name,
                                            playerNumber: '',
                                            score: challenger1_score,
                                            place: match.challenger1 && match.challenger1.place,
                                            skillLevel: match.challenger1 ? match.challenger1.skill_level : match.challenger1_skill_level,
                                            raceTo:
                                                match && match.tournament_match_sets && match.tournament_match_sets[0]
                                                    ? match.tournament_match_sets[0].challenger1_race_to
                                                    : stage
                                                    ? stage.winners_race_to
                                                    : match.challenger1_race_to
                                                    ? match.challenger1_race_to
                                                    : tournament.winners_race_to,
                                            isWinner: match.challenger1_is_winner,
                                            isWinning: challenger1_score && challenger1_score > challenger2_score ? true : false,
                                            isLoser: match.status === 'COMPLETED' && match.challenger1_is_winner === false ? true : false,
                                            isLosing:
                                                match.status !== 'COMPLETED' &&
                                                (challenger1_score || challenger2_score) &&
                                                challenger1_score < challenger2_score
                                                    ? true
                                                    : false,
                                            isTied: (challenger1_score || challenger2_score) && challenger1_score === challenger2_score ? true : false,
                                            isForfeit: match.challenger1_is_forfeit,
                                            isWithdraw: match.challenger1_is_withdraw,
                                            seed: match.challenger1_seed && match.challenger1_seed,
                                            country: match.challenger1_country,
                                        },
                                        {
                                            id: match.challenger2_id,
                                            playerName: match.challenger2_name,
                                            playerNumber: '',
                                            score: challenger2_score,
                                            place: match.challenger2 && match.challenger2.place,
                                            raceTo:
                                                match && match.tournament_match_sets && match.tournament_match_sets[0]
                                                    ? match.tournament_match_sets[0].challenger2_race_to
                                                    : stage
                                                    ? stage.winners_race_to
                                                    : match.challenger2_race_to
                                                    ? match.challenger2_race_to
                                                    : tournament.winners_race_to,
                                            skillLevel: match.challenger2 ? match.challenger2.skill_level : match.challenger2_skill_level,
                                            isWinner: match.challenger2_is_winner,
                                            isWinning: challenger2_score && challenger2_score > challenger1_score ? true : false,
                                            isLoser: match.status === 'COMPLETED' && match.challenger2_is_winner === false ? true : false,
                                            isLosing:
                                                match.status !== 'COMPLETED' &&
                                                (challenger1_score || challenger2_score) &&
                                                challenger2_score < challenger1_score
                                                    ? true
                                                    : false,
                                            isTied: (challenger1_score || challenger2_score) && challenger1_score === challenger2_score ? true : false,
                                            isForfeit: match.challenger2_is_forfeit,
                                            isWithdraw: match.challenger2_is_withdraw,
                                            seed: match.challenger2_seed && match.challenger2_seed,
                                            country: match.challenger2_country,
                                        },
                                    ]}
                                    style={{ minHeight: 66 }}
                                    theme={theme}
                                    footerText={compact !== true && renderFooterText(rounds[0].round, match)}
                                    // ref={this.r1m1}
                                    onClick={(e) => {
                                        // console.log(rounds);
                                        console.log('match', match);

                                        if (isPublic !== true) {
                                            // console.log(e.defaultPrevented);
                                            // MapInteraction will set defaultPrevented to true if the touchend/mouseup event happened after a drag, and false if it was a click. See index.stories.js for an example.
                                            // https://github.com/transcriptic/react-map-interaction
                                            if (e.defaultPrevented === false || e.defaultPrevented === true) {
                                                handleScoreModal(match, rounds);
                                            }
                                            // showDrawer({
                                            // 	title: `Match R1-M${index + 1}`,
                                            // 	slug: `match-r1-m${index + 1}`,
                                            // 	status: 'completed'
                                            // });
                                        } else {
                                            if (match.match_custom_link) {
                                                history.push(match.match_custom_link);
                                            } else {
                                                onMatchSelected(match);
                                            }
                                        }
                                    }}
                                    onTouchEnd={(e) => {
                                        if (isPublic !== true) {
                                            console.log(e.defaultPrevented);
                                            // MapInteraction will set defaultPrevented to true if the touchend/mouseup event happened after a drag, and false if it was a click. See index.stories.js for an example.
                                            // https://github.com/transcriptic/react-map-interaction
                                            if (e.defaultPrevented === false) {
                                                handleScoreModal(match, rounds);
                                            }

                                            // showDrawer({
                                            // 	title: `Match R1-M${index + 1}`,
                                            // 	slug: `match-r1-m${index + 1}`,
                                            // 	status: 'completed'
                                            // });
                                        }
                                    }}
                                />

                                {rounds.length > 1 && index === 0 ? (
                                    <div
                                        className={`match-spacer blue ${rounds[0].round % 2 === 0 ? 'even' : 'odd'} ${
                                            bracketDisplayMode === 'draw' && 'draw-only'
                                        }`}
                                        style={{ border: rounds[0].advance_to ? 'none' : null }}
                                    >
                                        {/* {rounds[index].round > 1 && `${alphabet[rounds[0].round - 2]}${index + 1}-${alphabet[rounds[0].round - 2]}${index + 2}`} */}
                                    </div>
                                ) : null}
                                {index > 0 && index % 2 === 0 ? (
                                    <div
                                        className={`match-spacer red ${rounds[0].round % 2 === 0 ? 'even' : 'odd'} ${
                                            bracketDisplayMode === 'draw' && 'draw-only'
                                        }`}
                                        style={{ border: rounds[0].advance_to ? 'none' : null }}
                                    >
                                        {/* {rounds[index].round > 1 && `${alphabet[rounds[0].round - 2]}${index + 1}-${alphabet[rounds[0].round - 2]}${index + 2}`} */}
                                    </div>
                                ) : null}

                                {index < rounds.length - 1 && index % 2 === 1 ? <div className="match-spacer  green no-connector" /> : null}
                            </React.Fragment>
                        );
                    })}
            </React.Fragment>
            <div className="spacer" />
            {/* {rounds &&
										parseInt(rounds[0].round) < 1 &&
										parseInt(rounds[0].round) % 2 === 0 && <div className="spacer" />} */}
        </div>
    );
}

export default withRouter(Round);
