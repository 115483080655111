import React, { useState, useEffect } from 'react';
import { Typography, Table } from 'antd';
import CircularLoader from '../../../components/CircularLoader';
import ZeroState from '../../../components/ZeroState';
import { getCountryName } from '../../../utils';
import { GET_PRO_WORLD_RANKINGS_QUERY } from './data/queries';
import { Query } from '@apollo/client/react/components';
import CircleFlag from '../../../components/CircleFlag';
import moment from 'moment';

const { Title, Text } = Typography;

const styles = {
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
};

export default function ProWorldRankings(props) {
    const { theme, selected, onSelected } = props;

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',

            sorter: (a, b) => {
                return a.name.localeCompare(b.name, undefined, {
                    numeric: true,
                    sensitivity: 'base',
                });
            },

            render: (text, record) => {
                return (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            {record.country && <CircleFlag country={getCountryName(record.country)} size="small" left={0} />} <Text>{text}</Text>
                        </div>
                        <div>
                            <Text>{record.country}</Text>
                        </div>
                    </div>
                );
            },
        },
        {
            title: 'Ranking',
            dataIndex: 'ranking',
            defaultSortOrder: 'asc',
            key: 'ranking',
            // width: 100,
            sorter: (a, b) => {
                return a.ranking - b.ranking;
            },
            render: (text, record) => {
                return (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Text>{text || '-'}</Text>{' '}
                        {record.ranking_change && (
                            <Text
                                style={{
                                    color: record.ranking_change.includes('+')
                                        ? theme.colors.success500
                                        : record.ranking_change.includes('-')
                                        ? theme.colors.error500
                                        : 'inherit',
                                }}
                            >
                                {record.ranking_change}
                            </Text>
                        )}
                    </div>
                );
            },
        },

        {
            title: 'Ranking system',
            dataIndex: 'ranking_system',
            key: 'ranking_system',
            // sorter: (a, b) => {
            //     return a.ranking_system.localeCompare(b.ranking_system, undefined, {
            //         numeric: true,
            //         sensitivity: 'base',
            //     });
            // },
            render: (text, record) => {
                return <Text>{text || '-'}</Text>;
            },
        },

        {
            title: 'Last updated',
            dataIndex: 'rank_date',
            key: 'rank_date',
            // sorter: (a, b) => {
            //     return a.rank_date - b.rank_date;
            // },

            render: (text, record) => {
                return <Text>{moment(text).format('ll')}</Text>;
            },
        },
    ];

    function calculateRankingChange(history) {
        if (history[0] && history[1]) {
            if (history[0].ranking > history[1].ranking) {
                const diff = history[0].ranking - history[1].ranking;
                return `-${diff}`;
            } else if (history[0].ranking < history[1].ranking) {
                const diff = history[1].ranking - history[0].ranking;
                return `+${diff}`;
            } else {
                return null;
            }
        } else {
            return null;
        }
    }

    return (
        <Query
            query={GET_PRO_WORLD_RANKINGS_QUERY}
            fetchPolicy="cache-and-network"
            notifyOnNetworkStatusChange
            variables={{ code: selected }}
            onCompleted={(data, loading, error, subscribeToMore) => {
                if (!loading && !error) {
                    console.log(data);
                }
            }}
        >
            {({ loading, error, data, subscribeToMore, networkStatus }) => {
                if (networkStatus === 1 || loading) {
                    return (
                        <div style={styles.container}>
                            <CircularLoader />
                        </div>
                    );
                }
                if (loading)
                    return (
                        <div style={styles.container}>
                            <CircularLoader />
                        </div>
                    );
                if (error) return <div style={styles.container}>Error: {error.message}</div>;
                if ((data && !data.players) || (data && data.players.length === 0)) {
                    return <ZeroState message="No players found." theme={theme} />;
                }

                const players = data.players;

                const sortedRankings = players
                    .map((player) => {
                        return {
                            ...player,
                            ranking: player.pro_world_rankings[0] && player.pro_world_rankings[0].ranking,
                            ranking_system: player.pro_world_rankings[0] && player.pro_world_rankings[0].ranking_system.display_name,
                            ranking_change: calculateRankingChange(player.pro_world_rankings),
                        };
                    })
                    .sort((a, b) => {
                        return a.ranking - b.ranking;
                    });

                return (
                    <div className="table_nowrap">
                        <Table
                            rowKey="id"
                            onRow={(record, rowIndex) => {
                                return {
                                    onClick: (event) => {
                                        console.log(record, rowIndex);
                                        props.history.push({
                                            pathname: `/players/${record.name_slug}`,
                                            // search: '?query=abc',
                                            state: { data: record },
                                        });
                                    }, // click row
                                    onDoubleClick: (event) => {}, // double click row
                                    onContextMenu: (event) => {}, // right button click row
                                    onMouseEnter: (event) => {}, // mouse enter row
                                    onMouseLeave: (event) => {}, // mouse leave row
                                };
                            }}
                            columns={columns}
                            dataSource={sortedRankings}
                            pagination={false}
                            tableLayout="auto"
                            scroll={{ x: 400 }}
                        />
                    </div>
                );
            }}
        </Query>
    );
}
