import React, { useState } from 'react';
import { Popconfirm, Modal, message, Layout, Row, Col, Steps, Empty, Alert, Typography, Button, Icon, Result } from 'antd';
import { Query } from '@apollo/client/react/components';
import {
    GET_TOURNAMENT_BY_SLUG_QUERY,
    GET_TOURNAMENT_PLAYERS_QUERY,
    GET_TOURNAMENT_QUERY,
    GET_TOURNAMENT_BRACKET_QUERY,
    GET_TOURNAMENT_POINTS,
    GET_TOURNAMENT_PAYOUTS,
    GET_TOURNAMENT_STATUS,
    GET_TOURNAMENT_TABLES_QUERY,
} from './data/queries';
import {
    CREATE_TOURNAMENT_BRACKET_MUTATION,
    DELETE_TOURNAMENT_BRACKET_MUTATION,
    UPDATE_TOURNAMENT_STATUS,
    DELETE_TOURNAMENT_POINTS_MUTATION,
    DELETE_TOURNAMENT_PAYOUTS_MUTATION,
    RESET_TOURNAMENT_PLAYER_PLACEMENTS,
    UPDATE_TOURNAMENT_PLAYER_MUTATION,
    RESET_TABLE_STATUS_MUTATION,
    DELETE_TOURNAMENT_MATCHES,
    UPDATE_TOURNAMENT_MUTATION,
    UPDATE_TOURNAMENT_PLAYERS,
} from './data/mutations';
import { useMutation } from '@apollo/react-hooks';
import { ReloadOutlined } from '@ant-design/icons';
import CircularLoader from '../../components/CircularLoader';
import { DigitalPoolAPI } from '../../lib/api';
import Match from '../../screens/digitalpool/tournaments/bracket/Match';
import Hashids from 'hashids';
import _ from 'lodash';
import moment from 'moment-timezone';
import momentDurationFormatSetup from 'moment-duration-format';
momentDurationFormatSetup(moment);

const { Text, Title } = Typography;
const { confirm } = Modal;

const styles = {
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        padding: 20,
    },
};

var seeder = function () {
    var seed = [];
    return {
        set: function (length) {
            for (var i = 0; i < length; i++) {
                seed.push(Math.random());
            }
            return seed;
        },
        get: function () {
            return seed;
        },
        clear: function () {
            seed = [];
        },
    };
};

function TBPreviewRoundRobin(props) {
    const { tournament, onGoBack, onContinue, theme } = props;
    const [rounds, setRounds] = useState([]);
    const [bracketCreated, setbracketCreated] = useState(false);
    const [matches, setMatches] = useState(false);
    const [groups, setGroups] = useState();
    const [players, setPlayers] = useState();
    const [createBracketMutation, bracketProps] = useMutation(CREATE_TOURNAMENT_BRACKET_MUTATION);
    const [createMatchesMutation] = useMutation(CREATE_TOURNAMENT_BRACKET_MUTATION);
    const [updateTournament] = useMutation(UPDATE_TOURNAMENT_MUTATION);
    const seed = seeder();
    const hashids = new Hashids(`Tournament salt`);

    var DUMMY = -1;
    // returns an array of round representations (array of player pairs).
    // http://en.wikipedia.org/wiki/Round-robin_tournament#Scheduling_algorithm
    function roundRobin(n, ps) {
        // n = num players
        var rs = []; // rs = round array
        let counter = 1;
        if (!ps) {
            ps = [];
            for (var k = 1; k <= n; k += 1) {
                ps.push(k);
            }
        } else {
            ps = ps.slice();
        }

        if (n % 2 === 1) {
            ps.push(DUMMY); // so we can match algorithm for even numbers
            n += 1;
        }
        for (var j = 0; j < n - 1; j += 1) {
            rs[j] = []; // create inner match array for round j
            for (var i = 0; i < n / 2; i += 1) {
                if (ps[i] !== DUMMY && ps[n - 1 - i] !== DUMMY) {
                    rs[j].push({
                        round: j + 1,
                        match_number: counter,
                        players: [ps[i], ps[n - 1 - i]],
                        order: [i + 1, n - i],
                    }); // insert pair as a match
                    counter++;
                }
            }

            ps.splice(1, 0, ps.pop()); // permutate for next round
        }

        return rs;
    }

    function shuffle(array) {
        var m = array.length,
            t,
            i;

        // While there remain elements to shuffle…
        while (m) {
            // Pick a remaining element…
            i = Math.floor(Math.random() * m--);

            // And swap it with the current element.
            t = array[m];
            array[m] = array[i];
            array[i] = t;
        }

        return array;
    }

    // function shuffle(array) {
    //     let currentIndex = array.length;
    //     let randomIndex;

    //     // While there remain elements to shuffle...
    //     while (currentIndex !== 0) {
    //         // Pick a remaining element...
    //         randomIndex = Math.floor(Math.random() * currentIndex);
    //         currentIndex--;

    //         // And swap it with the current element.
    //         [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
    //     }

    //     return array;
    // }

    function generateRounds(players) {
        const numRounds = players.length - 1;
        let rounds = [];
        let counter = 1;

        for (let i = 0; i < numRounds; i++) {
            let round = i + 1;
            let roundMatches = [];

            for (let j = 0; j < players.length / 2; j++) {
                roundMatches.push({
                    round: round,
                    match_number: counter,
                    order: [j + 1, players.length - j],
                    players: [players[j], players[players.length - 1 - j]],
                });
                counter++;
            }
            rounds.push(roundMatches);

            players.splice(1, 0, players[players.length - 1]);
            players.pop();
        }
        return rounds;
    }

    function randomShuffle(ar, seed) {
        var numbers = [];
        for (var a = 0, max = ar.length; a < max; a++) {
            numbers.push(a);
        }
        var shuffled = [];

        for (var i = 0, len = ar.length; i < len; i++) {
            var r = parseInt(seed[i] * (len - i));
            shuffled.push(ar[numbers[r]]);
            numbers.splice(r, 1);
        }
        return shuffled;
    }

    function getRandomArbitrary(min, max) {
        return Math.random() * (max - min) + min;
    }

    function createGroups(players) {
        const playerCount = players.length;
        const playersPerGroup = tournament.players_per_group || 2;
        console.log('players per group', playersPerGroup);
        const totalGroups = Math.round(playerCount / playersPerGroup);
        console.log('total groups', totalGroups);

        let playerGroups = players.reduce((resultArray, item, index) => {
            const groupIndex = Math.floor(index / playersPerGroup);
            if (!resultArray[groupIndex]) {
                resultArray[groupIndex] = []; // start a new chunk
            }
            resultArray[groupIndex].push(item);
            return resultArray;
        }, []);

        console.log(playerGroups);
        return playerGroups;
    }

    function generateSeeds(playerData) {
        seed.set(playerData.length);
        const randShuffle = randomShuffle(playerData, seed.get());
        console.log('shuffle', randShuffle);
        return randShuffle;
    }

    function generateRoundMatches(players, groupNumber) {
        let matchData = [];
        const roundData = makeRoundRobinPairings(players);
        console.log('round data', roundData);
        let counter = 1;

        const updatedRounds = roundData.map((round, index1) => {
            return round.map((match, index2) => {
                const identifier = `G${groupNumber}-R${match.round}-M${counter}`;
                const randomNum = getRandomArbitrary(1, 10000).toFixed(0);
                const hashId = hashids.encode(randomNum, tournament.id, index1 + 1, index2 + 1);
                const player = match.players[0];
                const opponent = match.players[1];
                const updatedMatch = {
                    // ...match,
                    group: groupNumber,
                    round: match.round,
                    tournament_id: tournament.id,
                    match_number: counter,
                    identifier: identifier,
                    hash_id: hashId,
                    challenger1_id: player && player.id,
                    challenger1_name: player && player.name,
                    challenger1_seed: match.order[0],
                    challenger1_country: player && player.country,
                    challenger1_score: 0,
                    challenger1_race_to: (player && player.race_to) || tournament.winners_race_to,
                    challenger1_skill_level: player && player.skill_level,
                    challenger2_id: opponent && opponent.id,
                    challenger2_name: opponent && opponent.name,
                    challenger2_seed: match.order[1],
                    challenger2_country: opponent && opponent.country,
                    challenger2_score: 0,
                    challenger2_race_to: (opponent && opponent.race_to) || tournament.winners_race_to,
                    challenger2_skill_level: opponent && opponent.skill_level,
                };
                matchData.push(updatedMatch);
                counter++;
                return updatedMatch;
            });
        });
        // const roundData = roundRobin(randShuffle.length, randShuffle);
        // const roundData = generateRounds(randShuffle);

        // console.log('round robin 1', roundRobinPairings);
        // console.log('round robin 2', roundRobinPairings2);
        console.log('updated rounds', updatedRounds);

        return {
            matches: matchData,
            rounds: updatedRounds,
        };
    }

    function makeRoundRobinPairings(players) {
        if (players.length % 2 === 1) {
            players.push(null);
        }

        const playerCount = players.length;
        const rounds = playerCount - 1;
        const half = playerCount / 2;
        const tournamentPairings = [];
        const playerIndexes = players.map((_, i) => i).slice(1);
        let counter = 1;

        for (let round = 0; round < rounds; round++) {
            const roundPairings = [];
            const newPlayerIndexes = [0].concat(playerIndexes);
            const firstHalf = newPlayerIndexes.slice(0, half);
            const secondHalf = newPlayerIndexes.slice(half, playerCount).reverse();

            for (let i = 0; i < firstHalf.length; i++) {
                // roundPairings.push([players[firstHalf[i]], players[secondHalf[i]]]);
                const match = {
                    round: round + 1,
                    match_number: counter,
                    players: [players[firstHalf[i]], players[secondHalf[i]]],
                    order: [firstHalf[i] + 1, secondHalf[i] + 1],
                };
                roundPairings.push(match);
                counter++;
            }
            // rotating the array
            playerIndexes.push(playerIndexes.shift());
            shuffle(roundPairings);
            tournamentPairings.push(roundPairings);
        }

        return tournamentPairings;
    }

    function createBracket(matches) {
        const key = 'bracket';
        message.loading({
            content: `Creating new tournament bracket...`,
            key,
            duration: 0,
        });
        console.log('matches', matches);
        createBracketMutation({
            variables: {
                objects: matches,
            },
            notifyOnNetworkStatusChange: true,
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: GET_TOURNAMENT_BY_SLUG_QUERY,
                    variables: {
                        slug: props.match.params.slug || tournament.slug,
                    },
                },
                {
                    query: GET_TOURNAMENT_PLAYERS_QUERY,
                    variables: {
                        tournament_id: tournament.id,
                    },
                },
            ],
        })
            .then((data) => {
                message.success({
                    content: `Tournament bracket successfully created`,
                    key,
                    duration: 2,
                });
                setbracketCreated(true);
                // setCurrentStep(3);
                onContinue();
            })
            .catch((error) => {
                console.log(error);
                message.error({
                    content: `There was an error ${JSON.stringify(error)}`,
                    key,
                    duration: 2,
                });
            });
    }

    function createInitialMatches() {
        const key = 'bracket';
        message.loading({
            content: `Creating new tournament matches...`,
            key,
            duration: 0,
        });
        console.log('matches', matches);

        props.client
            .mutate({
                mutation: DELETE_TOURNAMENT_MATCHES,
                variables: {
                    tournament_id: tournament.id,
                },
                // awaitRefetchQueries: true,
                // refetchQueries: [
                //     {
                //         query: GET_TOURNAMENT_PAYOUTS,
                //         variables: { tournament_id: tournament.id },
                //     },
                // ],
            })
            .then(async (data) => {
                createMatchesMutation({
                    variables: {
                        objects: matches,
                    },
                    notifyOnNetworkStatusChange: true,
                    awaitRefetchQueries: true,
                    refetchQueries: [
                        {
                            query: GET_TOURNAMENT_BY_SLUG_QUERY,
                            variables: {
                                slug: props.match.params.slug || tournament.slug,
                            },
                        },
                        {
                            query: GET_TOURNAMENT_PLAYERS_QUERY,
                            variables: {
                                tournament_id: tournament.id,
                            },
                        },
                    ],
                })
                    .then((data) => {
                        message.success({
                            content: `Tournament matches successfully created`,
                            key,
                            duration: 2,
                        });
                        // setbracketCreated(true);
                        // setCurrentStep(3);
                        onContinue();
                    })
                    .catch((error) => {
                        console.log(error);
                        message.error({
                            content: `There was an error ${JSON.stringify(error)}`,
                            key,
                            duration: 2,
                        });
                    });

                props.client
                    .mutate({
                        mutation: RESET_TABLE_STATUS_MUTATION,
                        variables: {
                            tournament_id: tournament.id,
                        },
                        notifyOnNetworkStatusChange: true,
                        awaitRefetchQueries: true,
                        refetchQueries: [
                            {
                                query: GET_TOURNAMENT_TABLES_QUERY,
                                variables: { tournament_id: tournament.id, status: 'OPEN' },
                            },
                        ],
                    })
                    .then((data) => {
                        console.log(data);
                    })
                    .catch((e) => {
                        console.error(e);
                        message.error({ content: JSON.stringify(e), key, duration: 2 });
                    });

                updateTournament({
                    variables: {
                        id: tournament.id,
                        changes: {
                            start_date_time: moment.utc(),
                            progress: '0',
                            status: 'NOT_STARTED',
                        },
                    },
                    notifyOnNetworkStatusChange: true,
                    awaitRefetchQueries: true,
                    refetchQueries: [
                        {
                            query: GET_TOURNAMENT_QUERY,
                            variables: { id: tournament.id },
                        },
                    ],
                })
                    .then((data) => {
                        console.log(data);
                    })
                    .catch((error) => {
                        console.log(error);
                        message.info('There was an error', error);
                    });

                updatePlayers(players);

                // return props.client
                //     .mutate({
                //         mutation: UPDATE_TOURNAMENT_PLAYERS,
                //         variables: {
                //             tournament_id: tournament.id,
                //             changes: {
                //                 place: null,
                //             },
                //         },
                //         // notifyOnNetworkStatusChange: true,
                //         // awaitRefetchQueries: true,
                //         // refetchQueries: [
                //         //     {
                //         //         query: GET_TOURNAMENT_PLAYERS_W_STATS,
                //         //         variables: { tournament_id: tournament.id },
                //         //     },
                //         // ],
                //     })
                //     .then((data) => {
                //         // console.log(data);
                //         return data;
                //     })
                //     .catch((error) => {
                //         console.error(error);
                //     });
            })
            .catch((error) => {
                console.log(error);
                message.error({
                    content: `There was an error ${JSON.stringify(error)}`,
                    key,
                    duration: 2,
                });
            });
    }

    function updatePlayers(playersToUpdate) {
        const toUpdate =
            playersToUpdate &&
            playersToUpdate.map((player) => {
                return {
                    id: player.id,
                    name: player.name,
                    country: player.country,
                    email: player.email,
                    phone_number: player.phone_number,
                    skill_level: player.skill_level,
                    status: player.status,
                    points: player.points,
                    seed: player.seed,
                    race_to: player.race_to,
                    place: player.place,
                    group: player.group,
                };
            });
        let result = toUpdate.reduce((accumulatorPromise, nextPlayer) => {
            return accumulatorPromise.then(() => {
                return updatePlayer(nextPlayer);
            });
        }, Promise.resolve());

        result.then((e) => {
            console.log('PLAYER UPDATE COMPLETE', playersToUpdate);
        });
    }

    function updatePlayer(player) {
        return props.client
            .mutate({
                mutation: UPDATE_TOURNAMENT_PLAYER_MUTATION,
                variables: {
                    id: player.id,
                    tournament_id: tournament.id,
                    changes: player,
                },
                // awaitRefetchQueries: true,
                // refetchQueries: [
                // 	{
                // 		query: GET_TOURNAMENT_BRACKET_QUERY,
                // 		variables: { tournamentId: tournament.id }
                // 	}
                // ]
            })
            .then((data) => {
                console.log(data);
                return data;
            })
            .catch((error) => {
                console.error(error);
            });
    }

    function setupGroups(players) {
        let matchData = [];
        let playerData = [];
        const groups = createGroups(players);
        const groupsWithRounds =
            groups &&
            groups.map((group, index) => {
                const groupNumber = index + 1;
                const seeds = generateSeeds(group);
                const players =
                    seeds &&
                    seeds.map((player) => {
                        const formatted = {
                            ...player,
                            group: groupNumber,
                            place: null,
                        };
                        playerData.push(formatted);
                        return formatted;
                    });
                const roundMatches = generateRoundMatches(players, groupNumber);
                roundMatches &&
                    roundMatches.matches.forEach((match) => {
                        matchData.push(match);
                    });

                return {
                    group: groupNumber,
                    players: players,
                    rounds: roundMatches.rounds,
                };
            });
        setGroups(groupsWithRounds);
        setPlayers(playerData);
        console.log(matchData);
        setMatches(matchData);
        console.log('groups with rounds', groupsWithRounds);
    }

    return (
        <Query
            query={GET_TOURNAMENT_PLAYERS_QUERY}
            fetchPolicy="cache-and-network"
            notifyOnNetworkStatusChange={true}
            variables={{
                tournament_id: tournament.id,
            }}
            onCompleted={async (data) => {
                const players = data.tournament_players;
                setupGroups(players);
            }}
        >
            {({ loading, error, data }) => {
                if (loading) {
                    return (
                        <div style={styles.container}>
                            <CircularLoader />
                        </div>
                    );
                }
                if (error) {
                    return <div style={styles.container}>Error: {error.message}</div>;
                }

                if (!data || !data.tournament_players) {
                    return (
                        <div style={styles.container}>
                            <CircularLoader />
                        </div>
                    );
                }

                const players = data.tournament_players;

                const bracketNotCreated =
                    bracketCreated === false &&
                    tournament &&
                    tournament.tournament_brackets_aggregate &&
                    tournament.tournament_brackets_aggregate.aggregate &&
                    tournament.tournament_brackets_aggregate.aggregate.count === 0
                        ? true
                        : false;

                console.log('bracket not created', bracketNotCreated);

                return (
                    <div
                        style={{
                            paddingLeft: 20,
                            paddingRight: 20,
                            paddingBottom: 20,
                        }}
                    >
                        <div
                            style={{
                                marginTop: 20,
                                marginBottom: 20,
                            }}
                        >
                            <div
                                style={{
                                    marginBottom: 20,
                                }}
                            >
                                {bracketNotCreated === false && (
                                    <div
                                        style={{
                                            marginBottom: 20,
                                        }}
                                    >
                                        <Alert
                                            message="Draw already exists!"
                                            description={
                                                <div>
                                                    <Text>
                                                        A draw already exists for this tournament. Reset the bracket below to generate a new one or skip this
                                                        step without making changes.
                                                    </Text>
                                                    <br />
                                                    <Button
                                                        type="danger"
                                                        ghost
                                                        style={{
                                                            marginTop: 10,
                                                        }}
                                                        onClick={() => {
                                                            confirm({
                                                                title: 'Are you sure you want to reset the draw?',
                                                                content: 'This action will clear any existing matches.',
                                                                centered: true,
                                                                transitionName: 'fade',
                                                                maskTransitionName: 'none',
                                                                okText: 'Reset Draw',
                                                                cancelText: `Cancel`,
                                                                onOk() {
                                                                    createInitialMatches();
                                                                },
                                                                onCancel() {
                                                                    console.log('NO RESET');
                                                                },
                                                            });
                                                        }}
                                                    >
                                                        Reset draw
                                                    </Button>
                                                </div>
                                            }
                                            type="error"
                                            closable={false}
                                            showIcon
                                        />
                                    </div>
                                )}

                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <div>
                                        <Title
                                            level={4}
                                            style={{
                                                marginBottom: 5,
                                            }}
                                        >
                                            Preview Draw
                                        </Title>
                                        <Text>The first round draw is generated using a randomized seeding algorithm.</Text>
                                    </div>
                                    <div>
                                        <Button
                                            type="default"
                                            onClick={() => {
                                                setupGroups(players);
                                            }}
                                        >
                                            <ReloadOutlined /> Reload draw
                                        </Button>
                                    </div>
                                </div>
                            </div>

                            {groups &&
                                groups.map((group, groupIndex) => {
                                    return (
                                        <div key={groupIndex} style={{ marginTop: 10 }}>
                                            <Title level={4}>Group {groupIndex + 1}</Title>
                                            <div className={`bracket-compact ${theme && theme.name}`}>
                                                <div style={{ width: '100%', overflow: 'auto', display: 'flex' }}>
                                                    {group.rounds &&
                                                        group.rounds.map((row, index) => {
                                                            return (
                                                                <div key={index} style={{ minWidth: 300, transform: 'scale(0.9)' }}>
                                                                    <div style={{ padding: 5 }}>Round {index + 1}</div>
                                                                    {row.map((match, index2) => {
                                                                        const challenger1_score = parseInt(match.challenger1_score);
                                                                        const challenger2_score = parseInt(match.challenger2_score);

                                                                        return (
                                                                            <div
                                                                                key={index2}
                                                                                style={{
                                                                                    display: 'flex',
                                                                                    alignItems: 'center',
                                                                                    width: '100%',
                                                                                }}
                                                                            >
                                                                                <div style={{ width: '100%' }}>
                                                                                    <Match
                                                                                        {...props}
                                                                                        tournament={tournament}
                                                                                        match={match}
                                                                                        matches={matches}
                                                                                        className={`match  ${
                                                                                            (match.status && match.status.toLowerCase()) || 'not_started'
                                                                                        } ${
                                                                                            match.status === 'IN_PROGRESS' &&
                                                                                            match.pool_table &&
                                                                                            match.pool_table.is_streaming_table &&
                                                                                            match.pool_table.is_streaming_table === true
                                                                                                ? 'streaming'
                                                                                                : ''
                                                                                        }  ${match.is_bye === true ? 'bye' : ''}  ${
                                                                                            match.status === 'NOT_STARTED' &&
                                                                                            match.pool_table &&
                                                                                            match.pool_table.id
                                                                                                ? 'assigned'
                                                                                                : ''
                                                                                        } ${
                                                                                            match.pool_table && match.pool_table.status === 'ASSIGNED'
                                                                                                ? 'assigned'
                                                                                                : ''
                                                                                        } ${
                                                                                            match.challenger1_name && match.challenger2_name
                                                                                                ? 'has_players'
                                                                                                : ''
                                                                                        }`}
                                                                                        activePlayerHover={false}
                                                                                        roundNumber={match.round}
                                                                                        startTime={match.start_time}
                                                                                        scheduledTime={match.scheduled_time}
                                                                                        number={match.match_number}
                                                                                        status={match.status}
                                                                                        table={match.pool_table && match.pool_table}
                                                                                        tableName={match.pool_table && match.pool_table.label}
                                                                                        isLive={match.status === 'LIVE' || match.status === 'IN_PROGRESS'}
                                                                                        isScheduled={match.scheduled_time}
                                                                                        isCompleted={match.status === 'COMPLETED'}
                                                                                        isPending={match.status === 'PENDING'}
                                                                                        // payout={payout}
                                                                                        progress={match.progress}
                                                                                        players={[
                                                                                            {
                                                                                                id: match.challenger1_id,
                                                                                                playerName: match.challenger1_name,
                                                                                                playerNumber: '',
                                                                                                score: challenger1_score,
                                                                                                place: match.challenger1 && match.challenger1.place,
                                                                                                skillLevel: match.challenger1
                                                                                                    ? match.challenger1.skill_level
                                                                                                    : match.challenger1_skill_level,
                                                                                                raceTo: match.challenger1_race_to,
                                                                                                isWinner: match.challenger1_is_winner,
                                                                                                isWinning:
                                                                                                    challenger1_score && challenger1_score > challenger2_score
                                                                                                        ? true
                                                                                                        : false,
                                                                                                isLoser:
                                                                                                    match.status === 'COMPLETED' &&
                                                                                                    match.challenger1_is_winner === false
                                                                                                        ? true
                                                                                                        : false,
                                                                                                isLosing:
                                                                                                    match.status !== 'COMPLETED' &&
                                                                                                    (challenger1_score || challenger2_score) &&
                                                                                                    challenger1_score < challenger2_score
                                                                                                        ? true
                                                                                                        : false,
                                                                                                isTied:
                                                                                                    (challenger1_score || challenger2_score) &&
                                                                                                    challenger1_score === challenger2_score
                                                                                                        ? true
                                                                                                        : false,
                                                                                                isForfeit: match.challenger1_is_forfeit,
                                                                                                isWithdraw: match.challenger1_is_withdraw,
                                                                                                seed: match.challenger1_seed && match.challenger1_seed,
                                                                                                country: match.challenger1_country,
                                                                                            },
                                                                                            {
                                                                                                id: match.challenger2_id,
                                                                                                playerName: match.challenger2_name,
                                                                                                playerNumber: '',
                                                                                                score: challenger2_score,
                                                                                                place: match.challenger2 && match.challenger2.place,
                                                                                                raceTo: match.challenger2_race_to,
                                                                                                skillLevel: match.challenger2
                                                                                                    ? match.challenger2.skill_level
                                                                                                    : match.challenger2_skill_level,
                                                                                                isWinner: match.challenger2_is_winner,
                                                                                                isWinning:
                                                                                                    challenger2_score && challenger2_score > challenger1_score
                                                                                                        ? true
                                                                                                        : false,
                                                                                                isLoser:
                                                                                                    match.status === 'COMPLETED' &&
                                                                                                    match.challenger2_is_winner === false
                                                                                                        ? true
                                                                                                        : false,
                                                                                                isLosing:
                                                                                                    match.status !== 'COMPLETED' &&
                                                                                                    (challenger1_score || challenger2_score) &&
                                                                                                    challenger2_score < challenger1_score
                                                                                                        ? true
                                                                                                        : false,
                                                                                                isTied:
                                                                                                    (challenger1_score || challenger2_score) &&
                                                                                                    challenger1_score === challenger2_score
                                                                                                        ? true
                                                                                                        : false,
                                                                                                isForfeit: match.challenger2_is_forfeit,
                                                                                                isWithdraw: match.challenger2_is_withdraw,
                                                                                                seed: match.challenger2_seed && match.challenger2_seed,
                                                                                                country: match.challenger2_country,
                                                                                            },
                                                                                        ]}
                                                                                        style={{ minHeight: 66 }}
                                                                                        theme={theme}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        );
                                                                    })}
                                                                </div>
                                                            );
                                                        })}
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}

                            {/* <div>
                                <table width="100%">
                                    <thead>
                                        <tr>
                                            <th>Rank</th>
                                            <th>Player</th>
                                            <th>Played</th>
                                            <th>Remaining</th>
                                            <th>W-L-T</th>
                                            <th>Tie wins</th>
                                            <th>Set wins</th>
                                            <th>Set ties</th>
                                            <th>Points</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {players.map((row, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{row.name}</td>
                                                    <td>0</td>
                                                    <td>0</td>
                                                    <td>0-0-0</td>
                                                    <td>0</td>
                                                    <td>0</td>
                                                    <td>0</td>
                                                    <td>0</td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>

                                <table width="100%">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <strong>
                                                    <em>Player’s Name</em>
                                                </strong>
                                            </td>
                                            <td>
                                                <strong>
                                                    <em>HC</em>
                                                </strong>
                                            </td>
                                            <td>
                                                <strong>
                                                    <em>Player</em>
                                                </strong>
                                                <strong>
                                                    <em>#</em>
                                                </strong>
                                            </td>
                                            <td>
                                                <strong>
                                                    <em>1</em>
                                                </strong>
                                            </td>
                                            <td>
                                                <strong>
                                                    <em>2</em>
                                                </strong>
                                            </td>
                                            <td>
                                                <strong>
                                                    <em>3</em>
                                                </strong>
                                            </td>
                                            <td>
                                                <strong>
                                                    <em>4</em>
                                                </strong>
                                            </td>
                                            <td>
                                                <strong>
                                                    <em>5</em>
                                                </strong>
                                            </td>
                                            <td>
                                                <strong>
                                                    <em>6</em>
                                                </strong>
                                            </td>
                                            <td>
                                                <strong>
                                                    <em>7</em>
                                                </strong>
                                            </td>
                                            <td>
                                                <em>Total</em>
                                                <strong>
                                                    <em>Wins</em>
                                                </strong>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>&nbsp;</td>
                                            <td>1 &nbsp; &nbsp;</td>
                                            <td>xxx </td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>&nbsp;</td>
                                            <td>2 &nbsp; &nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td>xxx </td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>&nbsp;</td>
                                            <td>3 &nbsp; &nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td>xxx </td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>&nbsp;</td>
                                            <td>4 &nbsp; &nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td>xxx </td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>&nbsp;</td>
                                            <td>5 &nbsp; &nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td>xxx </td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>&nbsp;</td>
                                            <td>6 &nbsp; &nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td>xxx </td>
                                            <td>&nbsp;</td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>&nbsp;</td>
                                            <td>7 &nbsp; &nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td>xxx </td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div> */}
                            <Button
                                type="primary"
                                size="large"
                                onClick={() => {
                                    const key = 'bracket';
                                    if (bracketNotCreated === true) {
                                        createBracket(matches);
                                    } else {
                                        message.error({
                                            content: `Tournament draw already exists, skipping...`,
                                            key,
                                            duration: 2,
                                        });
                                        onContinue();
                                    }
                                }}
                                style={{
                                    marginTop: 20,
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Text
                                            style={{
                                                color: '#fff',
                                            }}
                                        >
                                            Save and go to step 4
                                        </Text>
                                    </div>
                                </div>
                            </Button>

                            <Button
                                type="secondary"
                                size="large"
                                onClick={() => {
                                    onGoBack();
                                }}
                                style={{
                                    marginTop: 20,
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Text>Go back</Text>
                                </div>
                            </Button>
                        </div>
                    </div>
                );
            }}
        </Query>
    );
}

export default TBPreviewRoundRobin;
