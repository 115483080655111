import React, { useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { Row, Col, Typography, Icon, Button } from 'antd';
import Card from '../../components/Card';
import SectionHeader from '../../components/SectionHeader';
import SectionContent from '../../components/SectionContent';
import SectionWrapper from '../../components/SectionWrapper';
import PayoutsTable from './components/GEPayouts/Table';
import PayoutsModal from './components/GEPayouts/Modal';
import { ghostEventId } from './data/atoms';
import { payoutsModalVisible, payoutsSubmitButtonText, payoutsInitialFormValues, isNewPayoutsEntry } from './data/atoms';
import { fromPromise } from 'apollo-link';

const { Title } = Typography;

export default function GEPayouts(props) {
    const { ghostEvent, client } = props;
    // const modalVisibleValue = useRecoilValue(payoutsModalVisible);
    const setModalVisibleValue = useSetRecoilState(payoutsModalVisible);
    const setPayoutsSubmitButtonText = useSetRecoilState(payoutsSubmitButtonText);
    const setPayoutsInitialFormValues = useSetRecoilState(payoutsInitialFormValues);
    const setIsNewPayoutsEntry = useSetRecoilState(isNewPayoutsEntry);

    // const [ modalVisible, setModalVisible ] = useState(false);

    // async function onModalOk(e) {
    //     setModalVisible(false);
    // }

    // function onModalCancel(e) {
    //     setModalVisible(false);
    // }

    return (
        <React.Fragment>
            <SectionWrapper>
                <SectionHeader
                    title={props.title}
                    titleStyle={{ color: '#fff', margin: 0, textTransform: 'uppercase' }}
                    subtitle="Ghost Game Name"
                    subtitlePrefix={true}
                    subtitleStyle={{
                        color: 'rgba(255,255,255,0.8)',
                        textTransform: 'uppercase',
                        fontWeight: 600,
                        fontSize: 12,
                        letterSpacing: 3
                    }}
                    theme="tournamentBuilder"
                >
                </SectionHeader>
                <SectionContent padding="20px 40px">
                    <Row gutter={24} justify="center" type="flex">
                        <Col xs={24}>
                            <Card
                                bodyStyle={{ padding: 0 }}
                                cover={
                                    <div
                                        style={{
                                            paddingTop: 16,
                                            paddingBottom: 16,
                                            paddingLeft: 24,
                                            paddingRight: 24,
                                            borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between'
                                        }}
                                    >
                                        <Title level={4} style={{ margin: 0, padding: 0, fontSize: 18 }}>
                                            Payouts
                                        </Title>
            
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'right',
                                                justifyContent: 'space-between'
                                            }}
                                        >
                                            <Button
                                                type="primary"
                                                onClick={(e) => {
                                                    setPayoutsInitialFormValues({
                                                        place: '',
                                                        amount: ''
                                                    });
                                                    setPayoutsSubmitButtonText('Add Payout');
                                                    setIsNewPayoutsEntry(true);
                                                    setModalVisibleValue(true);
                                                }}
                                            >
                                                <Icon type="plus-circle" /> Add Payout
                                            </Button>
                                        </div>
                                    </div>
                                }
                            >
                            <div>
                                <PayoutsTable ghostEventId={ghostEvent.id} client={client} />
                            </div>
                            </Card>
                        </Col>
                    </Row>
                </SectionContent>
            </SectionWrapper>
            <PayoutsModal client={client} ghostEventId={ghostEvent.id} />
        </React.Fragment>
    );
}