import React from 'react';
import { Card, Avatar, Typography, Tag } from 'antd';
import { withRouter, Link } from 'react-router-dom';
import { Query } from '@apollo/client/react/components';
import { GET_USER_TOURNAMENTS_QUERY } from '../data/queries';
import CircularLoader from '../../../components/CircularLoader';
import ZeroState from '../../../components/ZeroState';
import { GlobalOutlined } from '@ant-design/icons';
import { getGameType } from '../../../utils';
import moment from 'moment';
import _ from 'lodash';

const styles = {
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 20,
    },
};

const { Title, Text } = Typography;

function UserTournaments(props) {
    const { authState, user, theme } = props;

    return (
        <Query
            query={GET_USER_TOURNAMENTS_QUERY}
            fetchPolicy="no-cache"
            variables={{ userId: (user && user.id) || authState.user.id }}
            notifyOnNetworkStatusChange
            awaitRefetchQueries
            onCompleted={(data, loading, error, subscribeToMore) => {}}
        >
            {({ loading, error, data }) => {
                if (loading)
                    return (
                        <div style={styles.container}>
                            <CircularLoader />
                        </div>
                    );
                if (error) return <div style={styles.container}>Error: {error.message}</div>;
                if (data && (!data.tournament_players || data.tournament_players.length === 0) && (!data.tournaments || data.tournaments.length === 0)) {
                    return (
                        <div style={styles.container}>
                            <ZeroState
                                message="No tournaments created or played in yet."
                                messageStyle={{ fontSize: 16 }}
                                showImage={false}
                                style={{ textAlign: 'left' }}
                            />
                        </div>
                    );
                }

                const tournamentPlayer =
                    data && data.tournament_players
                        ? data.tournament_players.map((player) => {
                              return {
                                  ...player.tournament,
                                  is_member: true,
                              };
                          })
                        : [];

                const tournamentOrganizer =
                    data && data.tournaments
                        ? data.tournaments
                              .filter((tournament) => {
                                  return (user && tournament.organizer_id === user.id) || (user && tournament.director_id === user.id);
                              })
                              .map((tournament) => {
                                  return {
                                      ...tournament,
                                      is_organizer: true,
                                  };
                              })
                        : [];

                const tournaments = [...tournamentPlayer, ...tournamentOrganizer].sort((a, b) => {
                    const createdAtA = moment(a.created_at).valueOf();
                    const createdAtB = moment(b.created_at).valueOf();
                    return createdAtB - createdAtA;
                });

                return (
                    <React.Fragment>
                        {tournaments.map((tournament, index) => {
                            return (
                                <Card
                                    // title={league.name}
                                    bordered={false}
                                    hoverable
                                    style={{ marginBottom: 10 }}
                                    // extra={<a href="#">More</a>}
                                    key={index}
                                >
                                    <Link to={`/tournaments/${tournament.slug}`}>
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                            }}
                                        >
                                            <div style={{ display: 'flex' }}>
                                                <div
                                                    style={{
                                                        width: 50,
                                                        height: 50,
                                                    }}
                                                >
                                                    <Avatar
                                                        size={50}
                                                        style={{
                                                            cursor: 'pointer',
                                                        }}
                                                        src={
                                                            tournament.avatar
                                                                ? tournament.avatar
                                                                : tournament.game_type
                                                                ? getGameType(tournament.game_type, theme)
                                                                : null
                                                        }
                                                    />
                                                </div>
                                                <div style={{ marginLeft: 15 }}>
                                                    <Title level={4} style={{ margin: 0 }}>
                                                        {tournament.name}
                                                    </Title>
                                                    {/* <Text type="primary">{tournament.description}</Text> */}

                                                    <Text type="secondary" style={{ marginTop: 5, display: 'block' }}>
                                                        {moment(tournament.created_at).format('MMMM Do YYYY')}
                                                    </Text>
                                                </div>
                                            </div>
                                            <div>
                                                {tournament.is_organizer === true ? (
                                                    <Tag size="large" color="#f50">
                                                        ORGANIZER
                                                    </Tag>
                                                ) : tournament.is_director === true ? (
                                                    <Tag color="#87d068">DIRECTOR</Tag>
                                                ) : (
                                                    <Tag color="#1890ff">PLAYER</Tag>
                                                )}
                                            </div>
                                        </div>
                                    </Link>
                                </Card>
                            );
                        })}
                    </React.Fragment>
                );
            }}
        </Query>
    );
}

export default withRouter(UserTournaments);
