import React, { useState } from 'react';
import { Link, withRouter, Route } from 'react-router-dom';
import { Query } from '@apollo/client/react/components';
import { Row, Col, Button, Avatar, Empty, Typography } from 'antd';
import Card, { Meta } from '../../../components/Card';
import CircularLoader from '../../../components/CircularLoader';
import SectionContent from '../../../components/SectionContent';
import ZeroState from '../../../components/ZeroState';
import Masonry from '../../../components/Masonry';
import SearchFilterBar from './SearchFilterBar';
import useBreakpoint from 'use-breakpoint';
import { defaultBreakpoints } from '../../../utils/defaultBreakpoints';
import { GET_USER_CREATED_LEAGUES_QUERY } from '../data/queries';
import defaultImg from '../../../assets/img/blue-bg.png';
import poolTableImg from '../../../assets/img/rasson-table.png';
import AdZoneTemplate from '../../../components/advertising/AdZoneTemplate';

const { Text, Title } = Typography;
const masonryBreakPoints = [600, 960, 1280, 1920];

const styles = {
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
    },
};

export default function MyLeagues(props) {
    const { authState } = props;
    const { breakpoint, maxWidth, minWidth } = useBreakpoint(defaultBreakpoints);

    return (
        <React.Fragment>
            {/* <div
				style={{
					background: 'linear-gradient( #fafafa, #fff)',
					boxShadow: 'rgba(0, 0, 0, 0.05) 0px 4px 6px 0px',
					padding: '10px 30px'
				}}
			>
				<SearchFilterBar {...props} />
			</div> */}

            <SectionContent padding="20px 40px">
                <Query
                    query={GET_USER_CREATED_LEAGUES_QUERY}
                    fetchPolicy="cache-and-network"
                    variables={{ ownerId: authState.user.id }}
                    notifyOnNetworkStatusChange
                    awaitRefetchQueries
                    onCompleted={(data, loading, error, subscribeToMore) => {
                        if (!loading && !error) {
                            console.log(data);
                        }
                    }}
                >
                    {({ loading, error, data, subscribeToMore, networkStatus }) => {
                        if (networkStatus === 1 || loading) {
                            return (
                                <div style={styles.container}>
                                    <CircularLoader />
                                </div>
                            );
                        }
                        if (loading)
                            return (
                                <div style={styles.container}>
                                    <CircularLoader />
                                </div>
                            );
                        if (error) return <div style={styles.container}>Error: {error.message}</div>;
                        if ((data && !data.leagues) || (data && data.leagues.length === 0)) {
                            return (
                                <Card>
                                    <ZeroState message="You are not part of any leagues yet.">
                                        <Link to="/league-manager/new">
                                            <Button size="large">Create League</Button>
                                        </Link>
                                        <Link to="/leagues/all-leagues" style={{ marginLeft: 10 }}>
                                            <Button size="large">Join A League</Button>
                                        </Link>
                                    </ZeroState>
                                </Card>
                            );
                        }

                        return (
                            <Row gutter={16} justify="center" type="flex">
                                <Col xs={24}>
                                    <AdZoneTemplate device={breakpoint || 'desktop'} {...props} />
                                </Col>
                                <Col xs={24} style={{ paddingTop: 15, paddingBottom: 15 }}>
                                    <div className="masonry-container">
                                        <Masonry breakPoints={masonryBreakPoints}>
                                            {data.leagues.map((league, index) => {
                                                return (
                                                    <React.Fragment key={index}>
                                                        <Card
                                                            hoverable
                                                            loading={loading}
                                                            cover={
                                                                <React.Fragment>
                                                                    {league.logo ? (
                                                                        <div
                                                                            style={{
                                                                                // height: 140,
                                                                                display: 'flex',
                                                                                alignItems: 'center',
                                                                                padding: 14,
                                                                            }}
                                                                        >
                                                                            <img
                                                                                alt="Logo"
                                                                                src={league.logo}
                                                                                style={{
                                                                                    width: '100%',
                                                                                    borderRadius: 6,
                                                                                    overflow: 'hidden',
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    ) : (
                                                                        <div
                                                                            style={{
                                                                                // height: 140,
                                                                                alignItems: 'center',
                                                                                padding: 14,
                                                                            }}
                                                                        >
                                                                            <div
                                                                                style={{
                                                                                    height: 140,
                                                                                    borderRadius: 6,
                                                                                    overflow: 'hidden',
                                                                                    background: `linear-gradient(rgba(61, 185, 248, 0.8), rgba(24, 144, 255, 0.1)), url(${defaultImg})`,
                                                                                    backgroundRepeat: 'no-repeat',
                                                                                    backgroundSize: 'cover',
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </React.Fragment>
                                                            }
                                                            onClick={() => {
                                                                props.history.push(`/leagues/${league.slug}`);
                                                            }}
                                                        >
                                                            <Meta
                                                                avatar={<Avatar src={league.avatar} />}
                                                                title={<Title level={4}>{league.name}</Title>}
                                                                description={league.short_description}
                                                            />
                                                        </Card>
                                                    </React.Fragment>
                                                );
                                            })}
                                        </Masonry>
                                    </div>
                                </Col>
                            </Row>
                        );
                    }}
                </Query>
            </SectionContent>
        </React.Fragment>
    );
}
