import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Form, FormItem, Select, Input, Radio, SubmitButton } from 'formik-antd';
import { Formik, ErrorMessage } from 'formik';
import { message, Collapse, Col, Row, Alert, Button, Tooltip, Typography, Avatar, Icon, Divider, InputNumber } from 'antd';
import * as Yup from 'yup';
import { Query, Mutation } from '@apollo/client/react/components';
import slugify from 'slugify';
import { GET_LEAGUE_BY_SLUG_QUERY, GET_ORGANIZATIONS_QUERY } from '../data/queries';
import { UPDATE_LEAGUE_MUTATION } from '../data/mutations';
import AddOrganizationModal from '../AddOrganizationModal';
import { QuestionCircleOutlined, QuestionCircleFilled, InfoCircleFilled } from '@ant-design/icons';

const { TextArea } = Input;
const { Text } = Typography;
const { Option } = Select;

function LMBasicSettingsForm(props) {
    const { organization, league, history } = props;
    const [loading, setLoading] = useState(false);
    const [organizationModalVisible, setOrganizationModalVisible] = useState(false);

    const SignupSchema = Yup.object().shape({
        name: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required'),
        short_description: Yup.string().required('Required'),
        league_type: Yup.string().required('Required'),
    });

    function handleOrganizationModalOk(e) {
        setOrganizationModalVisible(false);
    }

    function handleOrganizationModalCancel(e) {
        setOrganizationModalVisible(false);
    }

    function handleCreateOrganization() {
        setOrganizationModalVisible(true);
    }

    return (
        <Mutation
            mutation={UPDATE_LEAGUE_MUTATION}
            onCompleted={(res) => {
                console.log(res);
                const league = res.update_leagues.returning[0];
                setLoading(false);
                message.success('Changes saved');
                // const result = data.insert_leagues.returning[0];
                // props.history.push(`/league-manager/${result.slug}/dashboard`);
                // const { updateLeague } = data;
                history.push(`/league-manager/${league.slug}/settings/basic`);
            }}
            awaitRefetchQueries
        >
            {(updateLeague, { error }) => {
                if (error) {
                    alert('Something went wrong');
                }
                return (
                    <Formik
                        initialValues={
                            league
                                ? {
                                      ...league,
                                      is_public: league && league.is_public === true ? 'public' : 'private',
                                  }
                                : {
                                      name: '',
                                      league_type: 'teams',
                                      short_description: '',
                                      long_description: '',
                                      is_public: true,
                                      has_team_support: true,
                                      has_division_support: true,
                                      has_challenge_support: false,
                                      organization_id: undefined,
                                  }
                        }
                        validationSchema={SignupSchema}
                        onSubmit={(values) => {
                            console.log(values);
                            const {
                                name,
                                league_type,
                                short_description,
                                long_description,
                                is_public,
                                organization_id,
                                has_team_support,
                                has_division_support,
                                has_challenge_support,
                            } = values;
                            setLoading(true);
                            const slug = slugify(name, {
                                replacement: '-',
                                remove: /[*+~.,()'"#!:/@]/g,
                                lower: true,
                            });

                            updateLeague({
                                variables: {
                                    id: props.league.id,
                                    changes: {
                                        name,
                                        slug,
                                        league_type,
                                        // owner_id: props.authState.user.id,
                                        // operator_id: props.authState.user.id,
                                        short_description,
                                        long_description,
                                        organization_id,
                                        is_public: is_public === 'public' ? true : false,
                                        has_team_support,
                                        has_division_support,
                                        has_challenge_support,
                                    },
                                },
                                refetchQueries: () => [
                                    {
                                        query: GET_LEAGUE_BY_SLUG_QUERY,
                                        variables: { slug: props.league.slug },
                                        notifyOnNetworkStatusChange: true,
                                        awaitRefetchQueries: true,
                                    },
                                ],
                            });
                        }}
                        render={({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                            <Form onSubmit={handleSubmit} layout="vertical" style={{ maxWidth: 600 }}>
                                <FormItem
                                    name="name"
                                    label="League Name"
                                    required={true}
                                    className={'ant-form-item-control' + (errors.name || errors.general ? ' has-error' : '')}
                                >
                                    <Input name="name" placeholder="Name" size="large" />
                                    <ErrorMessage name="name" component="div" className="ant-form-explain" />
                                </FormItem>
                                <FormItem label="League Type" name="league_type" required hasFeedback showValidateSuccess>
                                    <Select
                                        showSearch
                                        name="league_type"
                                        placeholder="Select a league type"
                                        size="large"
                                        optionFilterProp="children"
                                        defaultValue="teams"
                                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    >
                                        {[
                                            {
                                                type: 'Teams',
                                                value: 'teams',
                                            },
                                            {
                                                type: 'Singles',
                                                value: 'singles',
                                            },
                                            {
                                                type: 'Ladder',
                                                value: 'ladder',
                                            },

                                            {
                                                type: 'Other',
                                                value: 'other',
                                            },
                                        ].map((item, index) => (
                                            <Option key={index} value={item.value}>
                                                {item.type}
                                            </Option>
                                        ))}
                                    </Select>
                                </FormItem>

                                <FormItem
                                    name="short_description"
                                    label="Short Description"
                                    required={true}
                                    className={'ant-form-item-control' + (errors.short_description || errors.general ? ' has-error' : '')}
                                >
                                    <Input name="short_description" placeholder="Short Description" size="large" />
                                    <ErrorMessage name="short_description" component="div" className="ant-form-explain" />
                                </FormItem>
                                <FormItem name="long_description" label="Long Description">
                                    <TextArea rows={6} name="long_description" placeholder="Long Description" size="large" />
                                </FormItem>
                                <FormItem
                                    label={
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            Associate with an organization (optional){' '}
                                            <Tooltip
                                                placement="right"
                                                title={<div>Organizations are used to group multiple leagues and tournaments together under one entity.</div>}
                                            >
                                                <QuestionCircleFilled style={{ marginLeft: 5 }} />
                                            </Tooltip>
                                        </div>
                                    }
                                    name="organization_id"
                                >
                                    <Query query={GET_ORGANIZATIONS_QUERY} fetchPolicy="cache-and-network" notifyOnNetworkStatusChange={true}>
                                        {({ loading, error, data }) => {
                                            if (error) return <div>Error: {error.message}</div>;

                                            return (
                                                <Select
                                                    showSearch
                                                    name="organization_id"
                                                    placeholder="Select an organization"
                                                    size="large"
                                                    loading={loading}
                                                    // disabled={organization && organization.id ? true : false}
                                                    // defaultValue={[ 'jack', 'lucy' ]}
                                                    optionFilterProp="children"
                                                    optionLabelProp="children"
                                                    filterOption={(input, option) => {
                                                        return option.props.children.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                                    }}
                                                    dropdownRender={(menu) => (
                                                        <div>
                                                            {menu}
                                                            <Divider style={{ margin: '4px 0' }} />
                                                            <div
                                                                style={{
                                                                    padding: '4px 8px',
                                                                    cursor: 'pointer',
                                                                }}
                                                                onMouseDown={(e) => e.preventDefault()}
                                                                onClick={handleCreateOrganization}
                                                            >
                                                                <Icon type="plus" /> Create Organization
                                                            </div>
                                                        </div>
                                                    )}
                                                >
                                                    {data &&
                                                        data.organizations &&
                                                        data.organizations.map((item, index) => (
                                                            <Option key={index} name={item.name} value={item.id}>
                                                                <Text>{item.name}</Text>
                                                            </Option>
                                                        ))}
                                                </Select>
                                            );
                                        }}
                                    </Query>
                                </FormItem>
                                <FormItem
                                    name="has_team_support"
                                    label={
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            Includes support for teams{' '}
                                            <Tooltip
                                                placement="right"
                                                title={<div>This feature is recommended if your league includes one or more teams.</div>}
                                            >
                                                <QuestionCircleFilled style={{ marginLeft: 5 }} />
                                            </Tooltip>
                                        </div>
                                    }
                                >
                                    <Radio.Group name="has_team_support">
                                        <Radio value={true}>Yes</Radio>
                                        <br />
                                        <Radio value={false}>No</Radio>
                                    </Radio.Group>
                                </FormItem>
                                <FormItem
                                    name="has_division_support"
                                    label={
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            Includes support for divisions{' '}
                                            <Tooltip
                                                placement="right"
                                                title={<div>This feature is recommended if your league includes one or more divisions.</div>}
                                            >
                                                <QuestionCircleFilled style={{ marginLeft: 5 }} />
                                            </Tooltip>
                                        </div>
                                    }
                                >
                                    <Radio.Group name="has_division_support">
                                        <Radio value={true}>Yes</Radio>
                                        <br />
                                        <Radio value={false}>No</Radio>
                                    </Radio.Group>
                                </FormItem>
                                <FormItem
                                    name="has_challenge_support"
                                    label={
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            Includes support for challenge matches{' '}
                                            <Tooltip
                                                placement="right"
                                                title={
                                                    <div>
                                                        This feature is recommended if your league allows players or teams to challenge one another outside of a
                                                        set schedule.
                                                    </div>
                                                }
                                            >
                                                <QuestionCircleFilled style={{ marginLeft: 5 }} />
                                            </Tooltip>
                                        </div>
                                    }
                                >
                                    <Radio.Group name="has_challenge_support">
                                        <Radio value={true}>Yes</Radio>
                                        <br />
                                        <Radio value={false}>No</Radio>
                                    </Radio.Group>
                                </FormItem>
                                <FormItem name="is_public" label="Access">
                                    <Radio.Group name="is_public">
                                        <Radio value="public">Public - Anyone can view and request to join.</Radio>
                                        <br />
                                        <Radio value="private">Private - Only users who have been sent an invite can view or join.</Radio>
                                    </Radio.Group>
                                </FormItem>
                                <SubmitButton
                                    size="large"
                                    disabled={Object.keys(errors).length > 0 ? true : false}
                                    type={Object.keys(errors).length > 0 ? 'danger' : 'primary'}
                                    loading={loading}
                                >
                                    Update
                                </SubmitButton>
                                <div>
                                    <Text style={{ color: '#f5222d', marginTop: 10 }}>{errors.general}</Text>
                                </div>

                                <AddOrganizationModal
                                    {...props}
                                    modalVisible={organizationModalVisible}
                                    onModalOk={handleOrganizationModalOk}
                                    onModalCancel={handleOrganizationModalCancel}
                                />
                            </Form>
                        )}
                    />
                );
            }}
        </Mutation>
    );
}

export default withRouter(LMBasicSettingsForm);
