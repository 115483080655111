import React, { useState } from 'react';
import { Modal, Button, Row, Col } from 'antd';
import { Link } from 'react-router-dom';
import Card from '../../components/Card';

export default function TournamentTypeModal(props) {
    const { modalVisible, onOk, onCancel } = props;

    return (
        <div>
            <Modal
                visible={modalVisible}
                title="Select tournament format"
                onOk={onOk}
                onCancel={onCancel}
                footer={null}
                centered
                transitionName="fade"
                // transitionName="none"
                maskTransitionName="none"
                bodyStyle={{ maxHeight: 500, overflowY: 'auto', padding: '10px 20px' }}
                destroyOnClose={true}
            >
                <Row gutter={16}>
                    <Col xs={24}>
                        <Link to="/tournament-builder/new">
                            <Card hoverable>Single stage tournament</Card>
                        </Link>
                    </Col>
                    <Col xs={24}>
                        <Link to="/tournament-builder/new/advanced">
                            <Card hoverable>Multi-stage tournament</Card>
                        </Link>
                    </Col>
                </Row>
            </Modal>
        </div>
    );
}
