import React, { useState } from 'react';
import { Table, Typography, Button, Icon, Tooltip, Modal } from 'antd';
import numeral from 'numeral';

const { Text, Title } = Typography;
const { confirm } = Modal;

export default function PayoutsTable(props) {
    const { onEditSelected, onDeleteSelected, onDelete } = props;

    const columns = [
        {
            title: 'Place',
            dataIndex: 'place',
            defaultSortOrder: 'ascend',
            width: 100,
            // width: 80
            // sorter: (a, b) => {
            // 	return a.position.localeCompare(b.position);
            // }
        },
        {
            title: 'Payout',
            dataIndex: 'payout',

            render: (text, record) => {
                return (
                    <div>
                        <Text>{text !== undefined ? numeral(text).format('$0,0') : '-'}</Text>
                    </div>
                );
            },
        },

        {
            title: 'Percent',
            dataIndex: 'percent',
            render: (text, record) => {
                return (
                    <div>
                        <Text>{text ? `${text}%` : '-'}</Text>
                    </div>
                );
            },
        },
        {
            title: 'Auction',
            dataIndex: 'calcutta',

            render: (text, record) => {
                return (
                    <div>
                        <Text>{text !== undefined ? numeral(text).format('$0,0') : '-'}</Text>
                    </div>
                );
            },
        },
        {
            title: 'Action',
            key: 'action',
            width: 100,
            render: (text, record) => (
                <span style={{ whiteSpace: 'nowrap' }}>
                    <Tooltip title="Edit">
                        <Button
                            type="ghost"
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                onEditSelected(record);
                                // props.history.push(
                                // 	`/tournament-builder/${props.tournament.slug}/venues/${record.slug}/edit`
                                // );
                            }}
                        >
                            <Icon type="edit" />
                        </Button>
                    </Tooltip>
                    {/* <Divider type="vertical" /> */}
                    <Tooltip title="Delete">
                        <Button
                            type="ghost"
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                showConfirm(record);
                            }}
                        >
                            <Icon type="delete" />
                        </Button>
                    </Tooltip>
                </span>
            ),
        },
    ];

    function showConfirm(record) {
        confirm({
            title: `Are you sure you want to remove points ${record.place}?`,
            content: 'Note: This action cannot be undone.',
            centered: true,
            transitionName: 'fade',
            maskTransitionName: 'none',
            // onOk() {
            // 	return new Promise((resolve, reject) => {
            // 		setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
            // 	}).catch(() => console.log('Oops errors!'));
            // },
            onOk() {
                onDelete(record);
            },
            onCancel() {},
        });
    }

    return (
        <Table dataSource={props.formattedData} columns={columns} className="table-striped-rows" pagination={false} tableLayout="auto" scroll={{ x: 400 }} />
    );
}
