import React, { useRef } from 'react';
import { Button } from 'antd';
import ReactToPrint from 'react-to-print';
import MatchScoresheet from './MatchScoresheet';
import { PrinterOutlined } from '@ant-design/icons';

export default function PrintComponent(props) {
    const { currentMatch, tournament } = props;
    let componentRef = useRef(null);

    return (
        <React.Fragment>
            <div>
                {/* button to trigger printing of target component */}
                <ReactToPrint
                    trigger={() => (
                        <Button type="text" size="large">
                            <PrinterOutlined />
                        </Button>
                    )}
                    content={() => componentRef.current}
                />

                {/* component to be printed */}
                <div style={{ display: 'none' }}>
                    <MatchScoresheet ref={componentRef} currentMatch={currentMatch} tournament={tournament} {...props} />
                </div>
            </div>
        </React.Fragment>
    );
}
