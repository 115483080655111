import React, { useState, useEffect } from 'react';
import { Table, Typography, Row, Col, Empty, Drawer } from 'antd';
import CircularLoader from '../../../components/CircularLoader';
import SectionTitle from '../../../components/SectionTitle';
import SectionContent from '../../../components/SectionContent';
import SectionWrapper from '../../../components/SectionWrapper';
import SectionFooter from '../../../components/SectionFooter';
import ZeroState from '../../../components/ZeroState';
import Card, { Meta } from '../../../components/Card';
import blueBg from '../../../assets/img/blue-bg.png';
import TableScoreCard from '../../digitalpool/tournaments/TableScoreCard';
import { Query, Subscription } from '@apollo/client/react/components';
import { GET_LEAGUE_MATCHES_SUBSCRIPTION } from '../data/subscriptions';

const { Title, Text } = Typography;

const styles = {};

export default function LMDivisionScores(props) {
    const { league, division } = props;

    const [drawerVisible, setDrawerVisible] = useState(false);
    const [currentMatch, setCurrentMatch] = useState({
        title: '',
        slug: '',
        status: '',
    });

    useEffect(() => {
        return function cleanup() {
            if (drawerVisible === true) {
                setDrawerVisible(false);
            }
        };
    });

    function showDrawer(match) {
        setCurrentMatch(match);
        setDrawerVisible(true);
        window.scrollTo(0, 0);
    }

    function onClose() {
        setDrawerVisible(false);
    }

    return (
        <SectionWrapper>
            {/* <SectionTitle
				title="Schedule"
				titleStyle={{
					color: '#fff',
					margin: 0,
					textTransform: 'uppercase'
				}}
				height={160}
				theme="leagueManager"
			/> */}
            <SectionContent padding="20px 40px">
                <Row
                    gutter={24}
                    justify="center"
                    type="flex"
                    // style={{ padding: 24, position: 'relative', marginTop: -120 }}
                >
                    <Col xs={24} style={{ paddingTop: 0, paddingBottom: 0 }}>
                        <Row gutter={16}>
                            <Subscription
                                subscription={GET_LEAGUE_MATCHES_SUBSCRIPTION}
                                fetchPolicy="cache-and-network"
                                notifyOnNetworkStatusChange
                                awaitRefetchQueries
                                variables={{
                                    league_id: league.id,
                                    division_id: division.id,
                                }}
                                onCompleted={(data) => {
                                    // console.log(data);
                                }}
                            >
                                {({ loading, error, data }) => {
                                    if (loading) return <CircularLoader />;
                                    if (error) return <div style={styles.container}>Error: {error.message}</div>;
                                    if ((data && !data.league_matches) || (data && data.league_matches.length === 0)) {
                                        return (
                                            <Card bodyStyle={{ padding: 0 }}>
                                                <ZeroState showImage message="No live matches found." style={{ margin: 30 }} {...props} />
                                            </Card>
                                        );
                                    }

                                    return (
                                        <React.Fragment>
                                            {data.league_matches &&
                                                data.league_matches.map((match, index) => {
                                                    console.log(match);
                                                    return (
                                                        <Col key={index} xs={24} sm={12} md={12} lg={8} xl={8} xxl={6}>
                                                            <TableScoreCard
                                                                title={`Match ${index + 1}`}
                                                                label={match.table_name}
                                                                matchData={{
                                                                    ...match,
                                                                    challenger1_score: match.challenger1_frames,
                                                                    challenger2_score: match.challenger2_frames,
                                                                }}
                                                                status={match.status}
                                                                gameType={match.game_type}
                                                                onClick={() => {
                                                                    props.history.push(
                                                                        `/leagues/${league.slug}/divisions/${division.slug}/matches/${match.slug}`
                                                                    );
                                                                }}
                                                            />
                                                        </Col>
                                                    );
                                                })}
                                        </React.Fragment>
                                    );
                                }}
                            </Subscription>
                        </Row>
                    </Col>
                </Row>
            </SectionContent>
            <SectionFooter {...props} />

            <Drawer
                title={currentMatch.title}
                // getContainer={() => document.querySelector('#scores')}
                // mask={false}
                // placement="bottom"
                width={400}
                closable
                onClose={onClose}
                visible={drawerVisible}
            >
                <p>Status: {currentMatch.status}</p>
            </Drawer>
        </SectionWrapper>
    );
}
