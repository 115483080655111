import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { message, Modal, Button, Upload, Icon, Tooltip, Typography, Avatar } from 'antd';
import { Form, FormItem, Input, Radio, Select, SubmitButton, ResetButton, AutoComplete } from 'formik-antd';
import * as Yup from 'yup';
import { Formik, ErrorMessage } from 'formik';
import { countryRegionData } from '../../utils/countryRegionData';
import geo from 'countrycitystatejson';
import CircularLoader from '../../components/CircularLoader';
import { Query, Mutation } from '@apollo/client/react/components';
import {
    GET_TOURNAMENT_PLAYER_STATUS,
    GET_TOURNAMENT_QUERY,
    GET_TOURNAMENT_PLAYER_QUERY,
    GET_TOURNAMENT_PLAYERS_QUERY,
    SEARCH_TOURNAMENT_PLAYERS,
    SEARCH_USERS,
    SEARCH_PLAYERS,
    FIND_TOURNAMENT_PLAYER_IN_BRACKET,
} from './data/queries';
import { v4 as uuidv4 } from 'uuid';
import { CheckCircleOutlined, WarningOutlined } from '@ant-design/icons';
import { formatPlayerName } from '../../utils';
import { useMutation, useQuery } from '@apollo/react-hooks';
import {
    CREATE_TOURNAMENT_PLAYER,
    UPDATE_TOURNAMENT_PLAYER,
    UPDATE_TOURNAMENT_BRACKET_MATCH_MUTATION,
    UPDATE_TOURNAMENT_BRACKET_CHALLENGER1_MATCH_MUTATION,
    UPDATE_TOURNAMENT_BRACKET_CHALLENGER2_MATCH_MUTATION,
} from './data/mutations';
import _ from 'lodash';
import CircleFlag from '../../components/CircleFlag';
import axios from 'axios';
import GetFargoModal from './GetFargoModal';
import { displayEnumType, getCountryName } from '../../utils';

const { Title, Text } = Typography;
const { Option } = Select;
const { confirm } = Modal;

function AddPlayerModal(props) {
    const { client, selectedPlayerId, players, userID, tournament, tournamentID, modalVisible, onModalOk, onModalCancel } = props;
    const [loading, setLoading] = useState(false);
    const [acDataSource, setacDataSource] = useState();
    const [nicknameForm, setNicknameForm] = useState('');
    const [autocompleteValue, setAutoCompleteValue] = useState('');
    const [fargoModalVisible, setFargoModalVisible] = useState(false);
    const [selected, setSelected] = useState();
    // const { data } = useQuery(GET_TOURNAMENT_PLAYER_QUERY, {
    // 	variables: { id: selectedPlayerId, tournament_id: tournamentID }
    // });
    const [createTournamentPlayer, { createLoading, createError }] = useMutation(CREATE_TOURNAMENT_PLAYER);
    const [updateTournamentPlayer, { updateLoading, updateError }] = useMutation(UPDATE_TOURNAMENT_PLAYER);

    const countries = geo.getCountries();
    const countryPhones = countries
        .sort((a, b) => {
            return a.phone.localeCompare(b.phone, undefined, {
                numeric: true,
                sensitivity: 'base',
            });
        })
        .filter((country) => {
            return country.shortName !== 'UM';
        })
        .map((country) => {
            return {
                phone: country.phone,
                emoji: country.emoji,
                shortName: country.shortName,
            };
        });

    console.log(countries);

    const formRef = useRef();

    // useEffect(() => {
    //     console.log('player id', selectedPlayerId);
    // }, [selectedPlayerId]);

    // async function queryDB(name) {
    //     setacDataSource([]);
    //     var tournamentPlayers = [];
    //     var users = [];
    //     const res = await client.query({
    //         query: SEARCH_TOURNAMENT_PLAYERS,
    //         variables: {
    //             name: `%${name}%`,
    //             nickname: `%${name}%`,
    //             tournament_id: tournamentID,
    //         },
    //     });

    //     for (let i = 0; i < res.data.tournament_players.length; i++) {
    //         let player = res.data.tournament_players[i];
    //         const { id, name, nickname, user_id } = player;

    //         tournamentPlayers.push({
    //             ...player,
    //             id,
    //             name,
    //             nickname,
    //             user_id,
    //             key: uuidv4(),
    //         });
    //     }

    //     const res2 = await client.query({
    //         query: SEARCH_USERS,
    //         variables: {
    //             first_name: `%${name}%`,
    //             last_name: `%${name}%`,
    //             nickname: `%${name}%`,
    //         },
    //     });

    //     for (let i = 0; i < res2.data.users.length; i++) {
    //         let user = res2.data.users[i];
    //         const { id, first_name, last_name, display_name, avatar, city, region, nickname } = user;

    //         users.push({
    //             ...user,
    //             id,
    //             name: display_name ? `${display_name}` : `${first_name} ${last_name}`,
    //             nickname,
    //             city,
    //             region,
    //             avatar,
    //             user_id: id,
    //             key: uuidv4(),
    //         });
    //     }

    //     const dataSet = users.sort((a, b) => a.name.localeCompare(b.name));
    //     console.log(dataSet);

    //     setacDataSource(dataSet);
    // }

    async function queryDB(name) {
        setacDataSource([]);
        var players = [];

        const res = await client.query({
            query: SEARCH_PLAYERS,
            variables: {
                name: `%${name}%`,
            },
        });

        for (let i = 0; i < res.data.players.length; i++) {
            let player = res.data.players[i];
            const { id, name, aliases, country, country_phone, phone_number, image_large, image_small, properties, user_id } = player;
            const user = player.user;

            players.push({
                ...user,
                ...player,
                player_id: id,
                name: name,
                country: country,
                aliases,
                user_id: user_id,
                image_large,
                image_small,
                country_phone,
                phone_number,
                city: properties && properties.fargo_data && properties.fargo_data.city,
                state: properties && properties.fargo_data && properties.fargo_data.state,
                robustness: properties && properties.fargo_data && properties.fargo_data.robustness,
                fargo_id: properties && properties.fargo_data && properties.fargo_data.membershipId,
                skill_level: properties && properties.fargo_data && properties.fargo_data.effectiveRating,
                key: uuidv4(),
            });
        }

        const dataSet = _.uniqBy([...players], 'name').sort((a, b) => a.name.localeCompare(b.name));

        setacDataSource(dataSet);
    }

    function onChange(event) {
        setNicknameForm(event.target.value);
    }

    function handleCancel() {
        setSelected();
        setacDataSource([]);
        formRef.current.setValues({});
        onModalCancel();
    }

    function onAutoCompleteChange(value) {
        console.log(value);
        console.log(formRef);
        if (value) {
            formRef.current.setValues({
                name: value,
            });
        }

        // setAutoCompleteValue(value);
    }

    function onSearch(name) {
        if (name && name.length > 2) {
            queryDB(name);
        } else {
            setSelected();
            setacDataSource([]);
        }
    }

    function handleChange(name) {
        if (name && name.length === 0) {
            formRef.current.setValues([]);
        }
    }

    function onSelect(value, option) {
        console.log(value);
        // let match = value.match(/\"[a-zA-Z\ \-\']+\"/g);
        const player = acDataSource.filter((item) => item.key === value);
        console.log(player);
        if (player) {
            setSelected(player[0]);
            console.log(player);
            formRef.current.setValues(player[0]);
        } else {
            setSelected();
            console.log('test');
            formRef.current.setValues([]);
        }

        // if (match) {
        //     let replaceNickname = match[0].replace(/\"/g, '');
        //     setNicknameForm(replaceNickname);
        // }
    }

    const getRegions = (country) => {
        if (!country) {
            return [];
        }
        if (typeof country === 'string') {
            const regions = countryRegionData.filter((item) => {
                return item.countryShortCode === country;
            })[0];
            country = regions;
        }
        const selectedRegion = country[2].split('|').map((regionPair) => {
            let [regionName, regionShortCode = null] = regionPair.split('~');
            return regionName;
        });
        return selectedRegion;
    };

    const submitAdd = (data) => {
        console.log('add player', data);
        setLoading(true);
        createTournamentPlayer({
            variables: {
                objects: [
                    {
                        tournament_id: tournament.id,
                        // user_id: props.userID,
                        name: data.name,
                        ...(data.nickname && { nickname: data.nickname }),
                        ...(data.email && { email: data.email }),
                        ...(data.country_phone && { country_phone: data.country_phone }),
                        ...(data.phone_number && { phone_number: data.phone_number }),
                        ...(data.country && { country: data.country }),
                        ...(data.skill_level && { skill_level: parseInt(data.skill_level) }),
                        ...(data.fargo_id && { fargo_id: parseInt(data.fargo_id) }),
                        ...(data.status && { status: data.status }),
                        ...(data.seed && { seed: parseInt(data.seed) }),
                        ...(data.race_to && { race_to: parseInt(data.race_to) }),
                        ...(data.chips_left && { chips_left: parseInt(data.chips_left) || 0 }),
                        ...(data.user_id && { user_id: data.user_id }),
                        ...(data.player_id && { player_id: data.player_id }),
                    },
                ],
            },
            notifyOnNetworkStatusChange: true,
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: GET_TOURNAMENT_PLAYERS_QUERY,
                    variables: { tournament_id: tournament.id },
                },

                {
                    query: GET_TOURNAMENT_QUERY,
                    variables: { id: tournament.id },
                },
            ],
        })
            .then((res) => {
                console.log(res);
                setLoading(false);
                message.success('Player successfully added');
                const player = res.data.insert_tournament_players.returning[0];
                // checkIfPlayerInBracket(player);
                onModalOk(player);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
                message.error('There was an error', error);
                onModalOk();
            });
    };

    // async function checkIfPlayerInBracket(id) {
    //     console.log(id);
    //     if (id) {
    //         let res = await client.query({
    //             query: FIND_TOURNAMENT_PLAYER_IN_BRACKET,
    //             variables: {
    //                 id: id,
    //                 tournament_id: tournamentID,
    //             },
    //         });

    //         const player = res && res.data && res.data.tournament_players && res.data.tournament_players[0];

    //         const playerInBracket =
    //             (player && player.tournament_matches_challenger1 && player.tournament_matches_challenger1.length > 0) ||
    //             (player && player.tournament_matches_challenger2 && player.tournament_matches_challenger2.length > 0);

    //         console.log('found player in bracket', playerInBracket);

    //         if (playerInBracket === false) {
    //             const playerData =
    //                 (player.tournament_matches_challenger1 && player.tournament_matches_challenger1[0]) ||
    //                 (player.tournament_matches_challenger2 && player.tournament_matches_challenger2[0]);
    //             showConfirmAddToBye(playerData);
    //         } else {
    //             if (playerInBracket === true) {
    //                 updatePlayerMatches({
    //                     challenger1: player.tournament_matches_challenger1,
    //                     challenger2: player.tournament_matches_challenger2,
    //                 });
    //             }
    //         }
    //         return playerInBracket;
    //     }
    //     return;
    // }

    function isPlayerInBracket(player) {
        const playerInBracket =
            (player && player.tournament_matches_challenger1 && player.tournament_matches_challenger1.length > 0) ||
            (player && player.tournament_matches_challenger2 && player.tournament_matches_challenger2.length > 0);

        return playerInBracket;
    }

    function updatePlayerMatches(player) {
        if (player) {
            updateChallenger1Match({
                challenger1_id: player.id,
                challenger1_name: player.name || '',
                challenger1_country: player.country || null,
                challenger1_seed: player.seed || null,
            });

            updateChallenger2Match({
                challenger2_id: player.id,
                challenger2_name: player.name || '',
                challenger2_country: player.country || null,
                challenger2_seed: player.seed || null,
            });
        }

        function updateChallenger1Match(data) {
            return props.client
                .mutate({
                    mutation: UPDATE_TOURNAMENT_BRACKET_CHALLENGER1_MATCH_MUTATION,
                    variables: {
                        player_id: player.id,
                        tournament_id: tournament.id,
                        changes: data,
                    },
                    notifyOnNetworkStatusChange: true,
                    awaitRefetchQueries: true,
                    refetchQueries: [
                        {
                            query: GET_TOURNAMENT_PLAYER_QUERY,
                            variables: { id: selectedPlayerId, tournament_id: tournament.id },
                        },
                    ],
                })
                .then((data) => {
                    console.log(data);
                    return data;
                })
                .catch((error) => {
                    console.error(error);
                });
        }

        function updateChallenger2Match(data) {
            return props.client
                .mutate({
                    mutation: UPDATE_TOURNAMENT_BRACKET_CHALLENGER2_MATCH_MUTATION,
                    variables: {
                        player_id: player.id,
                        tournament_id: tournament.id,
                        changes: data,
                    },
                    notifyOnNetworkStatusChange: true,
                    awaitRefetchQueries: true,
                    refetchQueries: [
                        {
                            query: GET_TOURNAMENT_PLAYER_QUERY,
                            variables: { id: selectedPlayerId, tournament_id: tournament.id },
                        },
                    ],
                })
                .then((data) => {
                    console.log(data);
                    return data;
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    }

    function showConfirmAddToBye(playerData) {
        console.log(playerData);
        confirm({
            title: `This player was not found in the bracket. Would you like to automatically add them to a BYE spot?`,
            centered: true,
            transitionName: 'fade',
            maskTransitionName: 'none',
            // onOk() {
            // 	return new Promise((resolve, reject) => {
            // 		setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
            // 	}).catch(() => console.log('Oops errors!'));
            // },
            onOk() {
                console.log('Player added to bracket in match #12');
            },
            onCancel() {},
            cancelText: 'Not now',
        });
    }

    const submitUpdate = (data) => {
        console.log(data);
        setLoading(true);
        updateTournamentPlayer({
            variables: {
                id: selectedPlayerId,
                changes: {
                    name: data.name,
                    nickname: data.nickname,
                    email: data.email,
                    country_phone: data.country_phone,
                    phone_number: data.phone_number,
                    country: data.country,
                    skill_level: parseInt(data.skill_level) || null,
                    fargo_id: parseInt(data.fargo_id),
                    status: data.status,
                    seed: parseInt(data.seed) || null,
                    race_to: parseInt(data.race_to) || null,
                    ...(data.chips_left && { chips_left: parseInt(data.chips_left) || null }),
                    ...(data.user_id && { user_id: data.user_id }),
                    ...(data.player_id && { player_id: data.player_id }),
                },
            },
            notifyOnNetworkStatusChange: true,
            awaitRefetchQueries: true,
            refetchQueries: [
                // {
                //     query: GET_TOURNAMENT_PLAYERS_QUERY,
                //     variables: { tournament_id: tournamentID },
                // },
                {
                    query: GET_TOURNAMENT_PLAYER_QUERY,
                    variables: { id: selectedPlayerId, tournament_id: tournament.id },
                },
                {
                    query: GET_TOURNAMENT_QUERY,
                    variables: { id: tournament.id },
                },
            ],
        })
            .then(async (data) => {
                console.log(data);
                setLoading(false);
                message.success('Changes saved');
                const player =
                    data &&
                    data.data.update_tournament_players &&
                    data.data.update_tournament_players.returning &&
                    data.data.update_tournament_players.returning[0];

                console.log(player);

                const playerInBracket = isPlayerInBracket(player);
                if (playerInBracket === false) {
                    // const playerData =
                    //     (player.tournament_matches_challenger1 && player.tournament_matches_challenger1[0]) ||
                    //     (player.tournament_matches_challenger2 && player.tournament_matches_challenger2[0]);
                    // showConfirmAddToBye(playerData);
                } else {
                    if (playerInBracket === true) {
                        updatePlayerMatches(player);
                    }
                }

                onModalOk();
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
                message.error('There was an error', JSON.stringify(error.message));
                onModalOk();
            });
    };

    const schema = Yup.object().shape({
        name: Yup.string().required(),
    });

    const validator = (values, props) => {
        console.log(values);
        console.log(props);
        const errors = {};

        if (!values.name) {
            errors.name = 'Required';
        }

        return errors;
    };

    function handleFormFieldUpdates(event) {
        const caret = event.target.selectionStart;
        const element = event.target;
        window.requestAnimationFrame(() => {
            element.selectionStart = caret;
            element.selectionEnd = caret;
        });
    }

    async function getPlayerInfoAsync(playerSearch) {
        console.log(playerSearch);
        try {
            const nameSplit = playerSearch.split(' ');
            let url;
            if (nameSplit.length > 1) {
                const firstName = nameSplit[0];
                const lastName = nameSplit[1];
                url = 'https://dashboard.fargorate.com/api/indexsearch?q=' + firstName.toLowerCase() + '%20' + lastName.toLowerCase();
            } else {
                url = 'https://dashboard.fargorate.com/api/indexsearch?q=' + playerSearch.toLowerCase();
            }

            var response = await axios(url);
            const player = response.data.value[0];
            // const fargoProfile = await getFargoProfileById(player.id);
            const combinedProfile = {
                ...player,
                // ...fargoProfile
            };
            console.log(combinedProfile);
            message.success(`${player.effectiveRating} Fargo Rating found for ${playerSearch}`);
            return player;
        } catch (error) {
            const text = error && error.response && error.response.data && error.response.data.Message;
            console.log(text);
            if (text) {
                message.error(`Error: ${text}`);
            } else {
                message.error(`Error: Player not found.`);
            }
            return text;
        }
    }

    async function lookupPlayerFargoRating(value) {
        const fargoRating = await getPlayerInfoAsync(value);

        if (fargoRating) {
            console.log(fargoRating);
            formRef.current.setValues({
                ...formRef.current.state.values,
                skill_level: parseInt(fargoRating.effectiveRating),
                fargo_id: parseInt(fargoRating.membershipId),
            });
        }
    }

    async function handleFargoModalOk(player) {
        if (player) {
            formRef.current.setValues({
                ...formRef.current.state.values,
                fargo_id: player.fargoId,
                skill_level: player.effectiveRating,
                // fargo_readable_id: player.readableId,
                // fargo_robustness: player.robustness,
            });
        }

        setFargoModalVisible(false);
    }

    function handleFargoModalCancel(e) {
        setFargoModalVisible(false);
    }

    function handleGetFargoRating() {
        setFargoModalVisible(true);
    }

    const renderForm = (player) => {
        const initialValues = selectedPlayerId
            ? {
                  ...player,
              }
            : {
                  name: '',
                  nickname: '',
                  email: '',
                  country_phone: '',
                  phone_number: '',
                  country: undefined,
                  skill_level: '',
                  fargo_id: '',
                  seed: '',
                  status: undefined,
                  race_to: '',
                  chips_left: '',
                  user_id: '',
              };

        return (
            <React.Fragment>
                <Formik
                    ref={formRef}
                    onSubmit={(data, actions) => {
                        let merged;
                        if (selected) {
                            merged = {
                                ...selected,
                                ...data,
                            };
                        } else {
                            merged = data;
                        }

                        console.log('data to submit', merged);
                        if (selectedPlayerId) {
                            submitUpdate(merged, player);
                        } else {
                            actions.setSubmitting(false);
                            actions.resetForm();
                            submitAdd(merged);
                        }
                    }}
                    validationSchema={schema}
                    enableReinitialize={true}
                    initialValues={initialValues}
                    render={({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                        <Modal
                            title={selectedPlayerId ? 'Update Player' : 'Add Player'}
                            // getContainer={() => document.querySelector('.tables')}
                            visible={modalVisible}
                            onOk={onModalOk}
                            onCancel={handleCancel}
                            centered
                            transitionName="fade"
                            // transitionName="none"
                            maskTransitionName="none"
                            footer={[
                                <Button key="cancel" size="large" onClick={handleCancel}>
                                    Cancel
                                </Button>,
                                <Button
                                    key="submit"
                                    size="large"
                                    disabled={Object.keys(errors).length > 0 ? true : false}
                                    type={Object.keys(errors).length > 0 ? 'danger' : 'primary'}
                                    onClick={() => {
                                        const existingPlayer =
                                            players &&
                                            players.filter((item) => {
                                                return item.name === values.name;
                                            })[0];

                                        if (selectedPlayerId || !existingPlayer) {
                                            formRef.current.handleSubmit();
                                        } else {
                                            message.error('Player has already been added.');
                                        }
                                    }}
                                >
                                    {selectedPlayerId ? (loading ? 'Updating...' : 'Update Player') : loading ? 'Adding Player...' : 'Add Player'}
                                </Button>,
                            ]}
                            bodyStyle={{ maxHeight: 500, overflowY: 'auto', padding: '10px 20px' }}
                            destroyOnClose={true}
                        >
                            <Form
                                onSubmit={handleSubmit}
                                layout="vertical"
                                style={{ maxWidth: 600 }}
                                // onSubmit={(e) => {
                                // 	e.preventDefault();
                                // 	console.log(values);
                                // 	// handleSubmit(values);
                                // }}
                            >
                                {/* <FormItem label="Name" name="name" required hasFeedback showValidateSuccess>
								<Input name="name" placeholder="Name" size="large" />
							</FormItem> */}
                                <FormItem label="Name" name="name" required hasFeedback showValidateSuccess>
                                    <AutoComplete
                                        dataSource={acDataSource}
                                        // value={autocompleteValue}
                                        // validate={validator}
                                        style={{ width: '100%' }}
                                        onSearch={onSearch}
                                        onSelect={onSelect}
                                        onChange={handleChange}
                                        // onChange={onAutoCompleteChange}
                                        size="large"
                                        name="name"
                                        placeholder="Search for a player name"
                                        optionFilterProp="children"
                                        optionLabelProp="value"
                                        autoFocus
                                        allowClear
                                        filterOption={(inputValue, option) => {
                                            return (
                                                option &&
                                                option.props &&
                                                option.props.children &&
                                                option.props.children.props &&
                                                option.props.children.props.children &&
                                                option.props.children.props.children[1] &&
                                                option.props.children.props.children[1].toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
                                            );
                                        }}
                                        dropdownRender={(menu) => (
                                            <React.Fragment>
                                                <div>{menu}</div>
                                            </React.Fragment>
                                        )}
                                    >
                                        {acDataSource &&
                                            acDataSource.map((item, index) => (
                                                <Option key={item.key} value={item.key}>
                                                    <span style={{ display: 'flex', alignItems: 'center' }}>
                                                        {item.country && (
                                                            <span role="img" aria-label={item.country}>
                                                                <CircleFlag country={getCountryName(item.country)} left={0} />{' '}
                                                            </span>
                                                        )}
                                                        {formatPlayerName(item.name)}
                                                        {item.properties &&
                                                            item.properties.fargo_data &&
                                                            item.properties.fargo_data.location &&
                                                            item.properties.fargo_data.location !== ' ' &&
                                                            ` - ${item.properties.fargo_data.location}`}
                                                        {item.properties &&
                                                            item.properties.fargo_data &&
                                                            item.properties.fargo_data.effectiveRating &&
                                                            ` - ${item.properties.fargo_data.effectiveRating}`}
                                                    </span>
                                                </Option>
                                            ))}
                                    </AutoComplete>
                                </FormItem>
                                {selectedPlayerId && (
                                    <div>
                                        <Text>
                                            {isPlayerInBracket(player) ? (
                                                <React.Fragment>
                                                    <CheckCircleOutlined style={{ color: '#6cdb35', marginRight: 5 }} /> Player found in bracket
                                                </React.Fragment>
                                            ) : (
                                                <React.Fragment>
                                                    <WarningOutlined style={{ color: '#ffac38', marginRight: 5 }} /> Player not found in bracket
                                                </React.Fragment>
                                            )}
                                        </Text>
                                    </div>
                                )}

                                <FormItem label="Nickname" name="nickname" hasFeedback showValidateSuccess>
                                    <Input
                                        name="nickname"
                                        placeholder="Nickname"
                                        size="large"
                                        // value={nicknameForm || (player && player.nickname)}
                                        onChange={(event) => {
                                            // onChange(event);
                                            handleFormFieldUpdates(event);
                                        }}
                                    />
                                </FormItem>
                                <FormItem label="Email" name="email" hasFeedback showValidateSuccess>
                                    <Input
                                        name="email"
                                        placeholder="Email Address"
                                        size="large"
                                        onChange={(event) => {
                                            handleFormFieldUpdates(event);
                                        }}
                                    />
                                </FormItem>
                                <FormItem label="Phone Number" name="phone_number" hasFeedback showValidateSuccess>
                                    <Input
                                        addonBefore={
                                            <Select
                                                name="country_phone"
                                                defaultValue="+1 US"
                                                showSearch
                                                style={{ minWidth: 120 }}
                                                onChange={handleChange}
                                                placeholder={values.country_phone || '+1 US'}
                                            >
                                                {countryPhones.map((country, index) => {
                                                    return (
                                                        <Select.Option value={`${country.shortName}-${country.phone}`} key={index}>
                                                            {country.emoji} +{country.phone} {country.shortName}
                                                        </Select.Option>
                                                    );
                                                })}
                                            </Select>
                                        }
                                        name="phone_number"
                                        placeholder="Phone Number"
                                        size="large"
                                        onChange={(event) => {
                                            handleFormFieldUpdates(event);
                                        }}
                                    />
                                </FormItem>
                                <FormItem label="Country" name="country" hasFeedback showValidateSuccess>
                                    <Select
                                        showSearch
                                        name="country"
                                        placeholder="Please select a country"
                                        optionFilterProp="children"
                                        size="large"
                                        filterOption={(input, option) => {
                                            return (
                                                option &&
                                                option.props &&
                                                option.props.children &&
                                                option.props.children.props &&
                                                option.props.children.props.children &&
                                                option.props.children.props.children[1] &&
                                                option.props.children.props.children[1].toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            );
                                        }}
                                        dropdownRender={(menu) => (
                                            <React.Fragment>
                                                <div>{menu}</div>
                                            </React.Fragment>
                                        )}
                                    >
                                        {countryRegionData.map((option, index) => {
                                            return (
                                                <Option value={option.countryShortCode} key={index} style={{ display: 'flex', alignItems: 'center' }}>
                                                    <span style={{ display: 'flex', alignItems: 'center' }}>
                                                        <span role="img" aria-label={option.countryName}>
                                                            <CircleFlag country={option.countryShortCode} left={0} />{' '}
                                                        </span>

                                                        {option.countryName}
                                                    </span>
                                                </Option>
                                            );
                                        })}
                                    </Select>
                                </FormItem>
                                <FormItem label="Seed" name="seed" hasFeedback showValidateSuccess>
                                    <Input
                                        name="seed"
                                        placeholder="Seed"
                                        size="large"
                                        onChange={(event) => {
                                            handleFormFieldUpdates(event);
                                        }}
                                    />
                                </FormItem>

                                <FormItem label="Fargo ID" name="fargo_id" hasFeedback showValidateSuccess>
                                    <Input
                                        name="fargo_id"
                                        placeholder="Fargo ID"
                                        size="large"
                                        onChange={(event) => {
                                            handleFormFieldUpdates(event);
                                        }}
                                    />
                                </FormItem>

                                <FormItem label="Skill Level" name="skill_level">
                                    <Input
                                        name="skill_level"
                                        placeholder="Skill Level"
                                        size="large"
                                        onChange={(event) => {
                                            handleFormFieldUpdates(event);
                                        }}
                                        addonAfter={
                                            <Button
                                                type="ghost"
                                                onClick={() => {
                                                    // lookupPlayerFargoRating(values.name);
                                                    handleGetFargoRating();
                                                }}
                                                disabled={values.name ? false : true}
                                            >
                                                Lookup Fargo
                                            </Button>
                                        }
                                    />
                                </FormItem>
                                {/* <FormItem>
								<Button
									key="submit"
									disabled={Object.keys(errors).length > 0 ? true : false}
									type={Object.keys(errors).length > 0 ? 'danger' : 'primary'}
									onClick={() => {
										formRef.current.handleSubmit();
									}}
								>
									{'Get Fargo'}
								</Button>
							</FormItem> */}
                                <FormItem label="Race to" name="race_to" hasFeedback showValidateSuccess>
                                    <Input
                                        name="race_to"
                                        placeholder="Race to"
                                        size="large"
                                        onChange={(event) => {
                                            handleFormFieldUpdates(event);
                                        }}
                                    />
                                </FormItem>
                                {tournament && tournament.tournament_type === 'chip' && (
                                    <FormItem label="Chips left" name="chips_left" hasFeedback showValidateSuccess>
                                        <Input
                                            name="chips_left"
                                            placeholder="Chips left"
                                            size="large"
                                            onChange={(event) => {
                                                handleFormFieldUpdates(event);
                                            }}
                                        />
                                    </FormItem>
                                )}

                                <FormItem label="Status" name="status" hasFeedback showValidateSuccess>
                                    <Query query={GET_TOURNAMENT_PLAYER_STATUS} fetchPolicy="cache-and-network" notifyOnNetworkStatusChange={true}>
                                        {({ loading, error, data }) => {
                                            if (loading) return <div>Loading...</div>;
                                            if (error) return <div>Error: {error.message}</div>;

                                            return (
                                                <Select showSearch name="status" placeholder="Select player status" size="large" optionFilterProp="children">
                                                    {data.tournament_player_status &&
                                                        data.tournament_player_status.map((item, index) => (
                                                            <Option key={index} value={item.value}>
                                                                {item.value}
                                                            </Option>
                                                        ))}
                                                </Select>
                                            );
                                        }}
                                    </Query>
                                </FormItem>
                                <FormItem name="user_id">
                                    <Input name="user_id" type="hidden" />
                                </FormItem>

                                <GetFargoModal
                                    player={values}
                                    modalVisible={fargoModalVisible}
                                    onModalOk={(players) => {
                                        handleFargoModalOk(players);
                                    }}
                                    onModalCancel={handleFargoModalCancel}
                                />
                            </Form>
                        </Modal>
                    )}
                />
            </React.Fragment>
        );
    };

    return (
        <React.Fragment>
            {selectedPlayerId ? (
                <Query
                    query={GET_TOURNAMENT_PLAYER_QUERY}
                    fetchPolicy="cache-and-network"
                    notifyOnNetworkStatusChange={true}
                    variables={{ id: selectedPlayerId, tournament_id: tournament.id }}
                    onCompleted={async (data) => {
                        console.log(data);
                    }}
                >
                    {({ loading, error, data }) => {
                        if (loading) return <CircularLoader />;
                        if (error) return <div>Error: {error.message}</div>;

                        const player = data && data.tournament_players && data.tournament_players.length > 0 ? data.tournament_players[0] : null;

                        return <React.Fragment>{renderForm(player)}</React.Fragment>;
                    }}
                </Query>
            ) : (
                <React.Fragment>{renderForm()}</React.Fragment>
            )}
        </React.Fragment>
    );
}

export default withRouter(AddPlayerModal);
