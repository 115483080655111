import React, { useState, useRef } from 'react';
import { Modal, Avatar, Icon, Tabs, Button, message } from 'antd';
import { Formik } from 'formik';
import { Field, Form, FormItem, Input, Radio, Select, SubmitButton, ResetButton, AutoComplete } from 'formik-antd';
import { formatPlayerName } from '../../utils';
import {
    SEARCH_USERS,
    GET_TOURNAMENT_MANAGERS,
    GET_TOURNAMENT_MANAGERS_INVITE_KEY,
    GET_USER_BY_EMAIL_QUERY,
    GET_TOURNAMENT_MANAGERS_USER_BY_MANAGER_ID,
    GET_TOURNAMENT_MANAGERS_INVITED_USER_BY_MANAGER_EMAIL,
} from './data/queries';
import { INSERT_TOURNAMENT_MANAGERS, INSERT_TOURNAMENT_MANAGERS_INVITE_KEY } from './data/mutations';
import { v4 as uuidv4 } from 'uuid';
import { functions } from '../../firebase';
import { connectFunctionsEmulator, httpsCallable } from 'firebase/functions';
import _ from 'lodash';
import generateInviteKey from '../../lib/generateInviteKey';

const { Option } = Select;
const { TabPane } = Tabs;

const styles = {
    tab: {
        padding: 20,
    },
    error: { color: '#f5222d', marginTop: 10 },
};

export default function AddManagerModal(props) {
    const { visible, onOk, onCancel, onClick, client, tournamentSlug, tournamentId, userId, tournamentName, authState } = props;

    const [acDataSource, setacDataSource] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const [formType, setFormType] = useState('add');
    const [modalOkButtonText, setModalOkButtonText] = useState('Add');
    const [modalAddInviteLoading, setModalAddInviteLoading] = useState(false);
    const formRefAdd = useRef();
    const formRefInvite = useRef();

    async function queryDB(name) {
        setacDataSource([]);
        var tournamentPlayers = [];
        var users = [];

        const res2 = await client.query({
            query: SEARCH_USERS,
            variables: {
                first_name: `%${name}%`,
                last_name: `%${name}%`,
                nickname: `%${name}%`,
            },
        });

        for (let i = 0; i < res2.data.users.length; i++) {
            let user = res2.data.users[i];

            users.push({
                manager_id: user.id,
                city: user.city,
                region: user.region,
                name: user.display_name ? `${user.display_name}` : `${user.first_name} ${user.last_name}`,
                key: uuidv4(),
            });
        }

        const dataSet = users.sort((a, b) => a.name.localeCompare(b.name));
        console.log('ds', dataSet);

        setacDataSource(dataSet);
    }

    function onTabChange(key) {
        switch (key) {
            case 'add':
                setFormType('add');
                setModalOkButtonText('Add');
                break;

            case 'invite':
                setFormType('invite');
                setModalOkButtonText('Invite');
                break;
            default:
                break;
        }
    }

    function onModalOk() {
        switch (formType) {
            case 'add':
                formRefAdd.current.handleSubmit();
                break;

            case 'invite':
                formRefInvite.current.handleSubmit();
                break;
            default:
                break;
        }
    }

    function onModalCancel() {
        setFormType('add');
        setModalOkButtonText('Add');
        onCancel();
    }

    function onSelect(value, option) {
        const player = acDataSource.filter((item) => item.key === value);
        console.log(player);
        if (player) {
            setSelectedItem(player[0]);
            formRefAdd.current.setValues(player[0]);
        } else {
            formRefAdd.current.setValues([]);
        }
    }

    function onSearch(text) {
        if (text && text.length > 2) {
            queryDB(text);
        } else {
            setacDataSource([]);
        }
    }

    function onChange() {}

    async function insertData(data) {
        if (_.isEmpty(data)) return;
        const { manager_id } = data;

        setModalAddInviteLoading(true);

        let tournamentManagerCheck = await client.query({
            query: GET_TOURNAMENT_MANAGERS_USER_BY_MANAGER_ID,
            variables: {
                manager_id: manager_id,
                tournament_id: tournamentId,
            },
        });

        if (
            tournamentManagerCheck &&
            tournamentManagerCheck.data &&
            tournamentManagerCheck.data.tournament_managers &&
            tournamentManagerCheck.data.tournament_managers[0]
        ) {
            console.log(tournamentManagerCheck);
            message.warning('Manager already added', 5);
            return;
        }

        let res = await client.mutate({
            mutation: INSERT_TOURNAMENT_MANAGERS,
            variables: {
                objects: {
                    manager_id: manager_id,
                    tournament_id: tournamentId,
                    tournament_slug: tournamentSlug,
                    invite_accepted: true,
                },
            },
            notifyOnNetworkStatusChange: true,
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: GET_TOURNAMENT_MANAGERS,
                    variables: { tournament_id: tournamentId },
                },
            ],
        });

        console.log('res', res.data.insert_tournament_managers.returning[0].manager.email);

        if (window.location.hostname.indexOf('localhost') > -1) {
            connectFunctionsEmulator(functions, 'localhost', 5001);
        }

        try {
            const sendInvite = httpsCallable(functions, 'tournamentManagerInvite');
            const results = await sendInvite({
                to_email: res.data.insert_tournament_managers.returning[0].manager.email,
                is_user: true,
                from_name: authState.user.displayName,
                from_tournament_manager_name: tournamentName,
                from_tournament_manager_slug: tournamentSlug,
                redirect_url: `/tournament-builder/${tournamentSlug}/dashboard`,
            });
            console.log(results);
            message.success(`Invite email sent to ${res.data.insert_tournament_managers.returning[0].manager.email}`);
        } catch (e) {
            console.log(e);
            message.error(`Email send error: ${JSON.stringify(e)}`);
        }

        setModalAddInviteLoading(false);
        onOk();
        setFormType('add');
        setModalOkButtonText('Add');
    }

    async function inviteManager(data) {
        if (_.isEmpty(data)) return;
        const { email } = data;

        let inviteKeyLength = 100;

        let userCheck = await client.query({
            query: GET_USER_BY_EMAIL_QUERY,
            variables: {
                email: email,
            },
        });

        if (userCheck && userCheck.data && userCheck.data.users && userCheck.data.users[0]) {
            message.warning('The email address you provided is already in the system.  Try searching for them in the Add Manager tab.', 7);
            return;
        }

        let tournamentManagerCheck = await client.query({
            query: GET_TOURNAMENT_MANAGERS_INVITED_USER_BY_MANAGER_EMAIL,
            variables: {
                email: email,
                tournament_id: tournamentId,
            },
        });

        console.log('tm', tournamentId, email);
        console.log(tournamentManagerCheck);

        // return;

        if (
            tournamentManagerCheck &&
            tournamentManagerCheck.data &&
            tournamentManagerCheck.data.tournament_managers &&
            tournamentManagerCheck.data.tournament_managers[0]
        ) {
            message.error('Manager already invited');
            return;
        }

        try {
            await client.mutate({
                mutation: INSERT_TOURNAMENT_MANAGERS,
                variables: {
                    objects: {
                        email: email,
                        tournament_id: tournamentId,
                        tournament_slug: tournamentSlug,
                        invite_accepted: false,
                    },
                },
                notifyOnNetworkStatusChange: true,
                awaitRefetchQueries: true,
                refetchQueries: [
                    {
                        query: GET_TOURNAMENT_MANAGERS,
                        variables: { tournament_id: tournamentId },
                    },
                ],
            });

            var generatedKey = generateInviteKey(inviteKeyLength);
            var loop = true;

            while (loop === true) {
                const inviteKeyCheck = await client.query({
                    query: GET_TOURNAMENT_MANAGERS_INVITE_KEY,
                    variables: {
                        email: email,
                        tournament_id: tournamentId,
                        key: generatedKey,
                    },
                });

                if (inviteKeyCheck.data.tournament_managers_invite_keys.length > 0) {
                    generatedKey = generateInviteKey(inviteKeyLength);
                } else {
                    loop = false;
                }
            }

            const inviteKey = await client.mutate({
                mutation: INSERT_TOURNAMENT_MANAGERS_INVITE_KEY,
                variables: {
                    objects: {
                        email: email,
                        tournament_id: tournamentId,
                        tournament_slug: tournamentSlug,
                        key: generatedKey,
                    },
                },
            });

            if (inviteKey.data.insert_tournament_managers_invite_keys.returning.length > 0) {
                console.log('invite email sent', {
                    to_email: email,
                    is_user: false,
                    from_name: authState.user.displayName,
                    from_tournament_manager_name: tournamentName,
                    from_tournament_manager_slug: tournamentSlug,
                    redirect_url: `/tournament-builder/${tournamentSlug}/dashboard`,
                });

                if (window.location.hostname.indexOf('localhost') > -1) {
                    connectFunctionsEmulator(functions, 'localhost', 5001);
                }

                try {
                    const sendInvite = httpsCallable(functions, 'tournamentManagerInvite');
                    const results = await sendInvite({
                        to_email: email,
                        is_user: false,
                        from_name: authState.user.displayName,
                        from_tournament_manager_name: tournamentName,
                        from_tournament_manager_slug: tournamentSlug,
                        redirect_url: `/tournament-builder/${tournamentSlug}/dashboard`,
                        invite_key: generatedKey,
                    });

                    console.log(results);

                    message.success(`Invite email sent to ${email}`);
                } catch (e) {
                    console.log(e);
                    message.error(`Email send error: ${JSON.stringify(e)}`);
                }
            }
        } catch (error) {
            console.log(error);
        }

        onOk();
        setFormType('add');
        setModalOkButtonText('Add');
    }

    return (
        <Modal
            title="Add a manager"
            centered
            visible={visible}
            onCancel={onModalCancel}
            okText={modalOkButtonText}
            transitionName="fade"
            destroyOnClose={true}
            bodyStyle={{ maxHeight: 500, overflowY: 'auto', padding: 0 }}
            footer={[
                <Button key="back" onClick={onModalCancel}>
                    Cancel
                </Button>,
                <Button key="submit" type="primary" loading={modalAddInviteLoading} onClick={onModalOk}>
                    {modalOkButtonText}
                </Button>,
            ]}
        >
            <React.Fragment>
                <Tabs
                    animated={{ inkBar: true, tabPane: false }}
                    defaultActiveKey="1"
                    onChange={onTabChange}
                    tabBarStyle={{
                        textTransform: 'uppercase',
                        margin: 0,
                        borderBottom: '1px solid #ccc',
                    }}
                >
                    <TabPane tab="Add Manager" key="add" style={styles.tab}>
                        <Formik
                            ref={formRefAdd}
                            onSubmit={(data) => {
                                insertData(data);
                            }}
                            render={({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => {
                                return (
                                    <Form onSubmit={handleSubmit} layout="vertical">
                                        <FormItem label="Name" name="name">
                                            <AutoComplete
                                                dataSource={acDataSource}
                                                onSearch={onSearch}
                                                onSelect={onSelect}
                                                onChange={onChange}
                                                size="large"
                                                name="name"
                                                placeholder="Search for a player name"
                                                optionFilterProp="children"
                                                optionLabelProp="value"
                                                autoFocus
                                                allowClear
                                                filterOption={(inputValue, option) => {
                                                    return (
                                                        option.props &&
                                                        option.props.children &&
                                                        option.props.children.toString().toLowerCase().indexOf(inputValue.toString().toLowerCase()) >= 0
                                                    );
                                                }}
                                            >
                                                {acDataSource &&
                                                    acDataSource.map((item, index) => (
                                                        <Option key={item.key} value={item.key}>
                                                            {item.manager_id && (
                                                                <Avatar
                                                                    size={24}
                                                                    style={{
                                                                        display: 'inline-block',
                                                                        marginRight: 8,
                                                                    }}
                                                                    src={item.avatar}
                                                                >
                                                                    <Icon type="user" />
                                                                </Avatar>
                                                            )}
                                                            {formatPlayerName(item)} {item.city && item.region && ` - ${item.city}, ${item.region}`}{' '}
                                                        </Option>
                                                    ))}
                                            </AutoComplete>
                                        </FormItem>
                                    </Form>
                                );
                            }}
                        />
                    </TabPane>
                    <TabPane tab="Invite Manager" key="invite" style={styles.tab}>
                        <Formik
                            ref={formRefInvite}
                            onSubmit={(data) => {
                                inviteManager(data);
                            }}
                            render={({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => {
                                return (
                                    <Form onSubmit={handleSubmit} layout="vertical">
                                        <FormItem label="Email" name="email">
                                            <Input name="email" placeholder="Email address" size="large" />
                                        </FormItem>
                                    </Form>
                                );
                            }}
                        />
                    </TabPane>
                </Tabs>
            </React.Fragment>
        </Modal>
    );
}
