import React from 'react';
import { Icon, Typography, Button, Avatar } from 'antd';
import Card, { Meta } from '../../../components/Card';
import { displayEnumType, getGameType } from '../../../utils/';
import { UserOutlined, TrophyOutlined, CalendarOutlined, EnvironmentOutlined } from '@ant-design/icons';
import tournamentThumb from '../../../assets/img/tournament-thumb.png';
import tournamentThumb8Ball from '../../../assets/img/tournament-thumb-8ball.png';
import tournamentThumb9Ball from '../../../assets/img/tournament-thumb-9ball.png';
import tournamentThumb10Ball from '../../../assets/img/tournament-thumb-10ball.png';
// import abstractBg from '../../../assets/img/abstract5.jpg';
import moment from 'moment';

const { Title, Text } = Typography;

export default function LMDivisionCard(props) {
    const { league, division } = props;
    console.log(division);
    return (
        <Card
            hoverable
            cover={
                <div>
                    <img
                        alt="example"
                        src={
                            division.flyer
                                ? division.flyer
                                : division.game_types === 'Nine Ball'
                                ? tournamentThumb9Ball
                                : division.game_types === 'Eight Ball'
                                ? tournamentThumb8Ball
                                : division.game_types === 'Ten Ball'
                                ? tournamentThumb10Ball
                                : tournamentThumb
                        }
                        style={{ width: '100%' }}
                    />
                </div>
            }
            onClick={() =>
                props.history.push({
                    pathname: `/league-manager/${league.slug}/divisions/${division.slug}`,
                    // state: { detail: division }
                })
            }
            // actions={[
            // 	<Button
            // 		type="link"
            // 		onClick={(e) => {
            // 			e.preventDefault();
            // 			e.stopPropagation();
            // 			props.history.push(`/league-manager/${league.slug}/divisions/${division.slug}`);
            // 		}}
            // 	>
            // 		<Icon type="eye" key="view" /> View
            // 	</Button>,
            // 	<Button
            // 		type="link"
            // 		onClick={(e) => {
            // 			e.preventDefault();
            // 			e.stopPropagation();
            // 			props.history.push(`/league-manager/${league.slug}/divisions/${division.slug}/edit`);
            // 		}}
            // 	>
            // 		<Icon type="edit" key="edit" /> Edit
            // 	</Button>
            // ]}
        >
            <Meta
                // avatar={<Avatar src={division && getGameType(division.game_types)} />}
                title={
                    <div>
                        <div style={{ fontWeight: 700 }}>{division && division.name} </div>
                        <div style={{ marginTop: 14 }}>
                            <CalendarOutlined /> <Text>{moment(division.start_date).format('MMMM Do, YYYY')} Start</Text>
                            <br />
                            <UserOutlined /> <Text>{division.league_player_divisions_aggregate.aggregate.count} Players</Text>
                            <br />
                            <UserOutlined /> <Text>{division.league_team_divisions_aggregate.aggregate.count} Teams</Text>
                            <br />
                            <Avatar size={20} src={getGameType(division.game_types)} style={{ marginLeft: -2, position: 'relative' }} />{' '}
                            <Text>{division.game_types}</Text>
                            {division.venue && (
                                <React.Fragment>
                                    <br />
                                    <EnvironmentOutlined />{' '}
                                    <Text>
                                        {division.venue && division.venue.name} - {division.venue && division.venue.city},{' '}
                                        {division.venue && division.venue.region}
                                    </Text>
                                </React.Fragment>
                            )}
                        </div>
                    </div>
                }
                description={division && division.description}
            />
        </Card>
    );
}
