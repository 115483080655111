import React, { useState, useRef } from 'react';
import { Avatar, Typography, Alert, Row, Col } from 'antd';
import { getGameType, displayEnumType } from '../../../utils';
import moment from 'moment-timezone';
import momentDurationFormatSetup from 'moment-duration-format';
momentDurationFormatSetup(moment);

const { Text, Title } = Typography;

const styles = {};

export default function ReviewEvent(props) {
    const { event } = props;
    var zone_name = moment.tz.guess();
    var timezone = moment.tz(zone_name).zoneAbbr();

    return (
        <div>
            <div>
                <Alert
                    message="Review and verify your settings before finalizing"
                    description={`Please ensure your data looks correct.`}
                    type="info"
                    closable
                    showIcon
                />
            </div>

            <div
                style={{
                    marginTop: 20,
                    marginBottom: 20,
                }}
            >
                <Title level={4} style={{ marginBottom: 20 }}>
                    Review Event
                </Title>
                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={12}>
                        <Title level={4} style={{ fontSize: 18, marginBottom: 20 }}>
                            Details
                        </Title>
                        <table width="100%" className="info-table">
                            <tbody>
                                <tr>
                                    <td>Name</td>
                                    <td>{event.name}</td>
                                </tr>
                                <tr>
                                    <td>Description</td>
                                    <td className="display-linebreak">{event.description || 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Start date</td>
                                    <td>
                                        {moment(event.start_date_time).format('llll')} ({timezone})
                                    </td>
                                </tr>
                                <tr>
                                    <td>End date</td>
                                    <td>
                                        {moment(event.end_date_time).format('llll')} ({timezone})
                                    </td>
                                </tr>
                                <tr>
                                    <td>Venue</td>
                                    <td>{event.venue.name}</td>
                                </tr>

                                <tr>
                                    <td>Event Type</td>
                                    <td>{event.event_type ? displayEnumType(event.event_type) : 'N/A'}</td>
                                </tr>

                                <tr>
                                    <td>Access</td>
                                    <td>
                                        <Text>{event.is_public ? 'Public' : 'Private'}</Text>
                                    </td>
                                </tr>
                                {event.flyer && (
                                    <tr>
                                        <td>Flyer</td>
                                        <td>
                                            <img
                                                src={event.flyer}
                                                width="100%"
                                                alt="Flyer"
                                                style={{
                                                    maxWidth: 300,
                                                    borderRadius: 10,
                                                    overflow: 'hidden',
                                                }}
                                            />
                                        </td>
                                    </tr>
                                )}

                                {event.logo && (
                                    <tr>
                                        <td>Logo</td>
                                        <td>
                                            <img
                                                src={event.logo}
                                                width="100%"
                                                alt="Logo"
                                                style={{
                                                    maxWidth: 300,
                                                    borderRadius: 10,
                                                    overflow: 'hidden',
                                                }}
                                            />
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </Col>
                </Row>
            </div>
        </div>
    );
}
