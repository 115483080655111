import React from 'react';
import useBreakpoint from 'use-breakpoint';
import { defaultBreakpoints } from '../utils/defaultBreakpoints';

function SectionContent(props) {
    const { breakpoint, maxWidth, minWidth } = useBreakpoint(defaultBreakpoints);

    return (
        <React.Fragment>
            <div
                className="section-content"
                style={
                    props.style
                        ? props.style
                        : {
                              flexGrow: 1,
                              padding: breakpoint === 'mobile' ? '0px' : props.padding || '10px 40px',
                          }
                }
            >
                {props.children}
            </div>
        </React.Fragment>
    );
}

export default SectionContent;
