import moment from 'moment';

export function tournamentPreviewStats(numPlayers = 64, winnersRacingTo = 7, losersRacingTo = 5, tables = 10, gameType = 'NINE_BALL') {
    const winnerRounds = Math.ceil(Math.log2(numPlayers));
    const L2 = Math.log2(numPlayers);
    const loserRounds = Math.ceil(L2) + Math.ceil(Math.log2(L2));
    const totalRounds = Math.ceil(winnerRounds + loserRounds);
    const maxPlayers = Math.pow(2, winnerRounds);
    const firstRoundByes = maxPlayers - numPlayers;
    const totalByes = Math.round(firstRoundByes + firstRoundByes / 2);
    const totalLoserByes = totalByes - firstRoundByes;
    const loserSideArr = loserRoundMatches(maxPlayers);
    const winnerSideArr = winnerRoundMatches(winnerRounds, 0, 1);
    const totalLoserMatches = loserSideArr.reduce((a, b) => a + b, 0);
    const totalWinnerMatches = winnerSideArr.reduce((a, b) => a + b, 0);
    const totalMatches = totalLoserMatches + totalWinnerMatches;
    const totalMatchesExcludingByes = totalMatches - totalByes;
    const totalWinnerMatchesExcludingByes = totalWinnerMatches - firstRoundByes;
    const totalLoserMatchesExcludingByes = totalLoserMatches - totalLoserByes;
    // const avgGamesPerMatch = winnersRacingTo + losersRacingTo / 2;
    const avgGamesPerMatchWinnerSide = winnersRacingTo + winnersRacingTo / 2;
    const avgGamesPerMatchLosersSide = losersRacingTo + losersRacingTo / 2;
    // const totalGames = avgGamesPerMatch * totalMatchesExcludingByes;
    const totalWinnerSideGames = avgGamesPerMatchWinnerSide * totalWinnerMatchesExcludingByes;
    const totalLoserSideGames = avgGamesPerMatchLosersSide * totalLoserMatchesExcludingByes;
    const totalGames = totalWinnerSideGames + totalLoserSideGames;
    const avgTimePerGame = getAverageGameTime(gameType);
    // const averageTimePerMatch = avgTimePerGame * avgGamesPerMatch;
    const averageTimePerWinnerSideMatch = avgTimePerGame * avgGamesPerMatchWinnerSide;
    const averageTimePerLoserSideMatch = avgTimePerGame * avgGamesPerMatchLosersSide;
    const totalTables = tables || 10;

    const timesPerRoundWinners = [];
    const timesPerRoundLosers = [];
    let totalEstimatedTimeWinners;
    let totalEstimatedTimeLosers;
    let totalEstimatedTime;

    winnerSideArr.forEach((matches, index) => {
        const playersInRound = matches * 2;
        let multiplier = Math.floor(tables / (index === 0 ? 1 : index + 1));
        const totalTimeForMatchesInRound = averageTimePerWinnerSideMatch * matches;
        const totalTimeWithTables = totalTimeForMatchesInRound / multiplier;

        // console.log({
        // 	round: index + 1,
        // 	matches: matches,
        // 	players: playersInRound,
        // 	multiplier,
        // 	tables,
        // 	averageTimePerWinnerSideMatch,
        // 	totalTimeForAllMatchesInRound: `${totalTimeForMatchesInRound} minutes (${(totalTimeForMatchesInRound /
        // 		60).toFixed(1)} hours)`,
        // 	totalTimeWithTables: `${totalTimeWithTables} minutes (${(totalTimeWithTables / 60).toFixed(1)} hours)`
        // });
        timesPerRoundWinners.push(totalTimeWithTables);
    });

    loserSideArr.forEach((matches, index) => {
        const playersInRound = matches * 2;
        let multiplier = Math.floor(tables / (index === 0 ? 1 : index + 1));
        const totalTimeForMatchesInRound = averageTimePerWinnerSideMatch * matches;
        const totalTimeWithTables = totalTimeForMatchesInRound / multiplier;

        // console.log({
        // 	round: index + 1,
        // 	matches: matches,
        // 	players: playersInRound,
        // 	multiplier,
        // 	tables,
        // 	averageTimePerWinnerSideMatch,
        // 	totalTimeForAllMatchesInRound: `${totalTimeForMatchesInRound} minutes (${(totalTimeForMatchesInRound /
        // 		60).toFixed(1)} hours)`,
        // 	totalTimeWithTables: `${totalTimeWithTables} minutes (${(totalTimeWithTables / 60).toFixed(1)} hours)`
        // });
        timesPerRoundLosers.push(totalTimeWithTables);
    });

    totalEstimatedTimeWinners = timesPerRoundWinners.reduce((a, b) => a + b, 0);
    totalEstimatedTimeLosers = timesPerRoundLosers.reduce((a, b) => a + b, 0);
    totalEstimatedTime = totalEstimatedTimeWinners + totalEstimatedTimeLosers;

    console.log(
        'TIMES PER ROUND WINNERS:',
        timesPerRoundWinners.map((time) => {
            return `${time} minutes ${(time / 60).toFixed(1)} hours`;
        })
    );

    console.log(
        'TIMES PER ROUND LOSERS:',
        timesPerRoundLosers.map((time) => {
            return `${time} minutes ${(time / 60).toFixed(1)} hours`;
        })
    );

    console.log('TOTAL ESTIMATED TIME WINNERS:', `${totalEstimatedTimeWinners} minutes (${(totalEstimatedTimeWinners / 60).toFixed(1)} hours)`);

    console.log('TOTAL ESTIMATED TIME LOSERS:', `${totalEstimatedTimeLosers} minutes (${(totalEstimatedTimeLosers / 60).toFixed(1)} hours)`);

    console.log('TOTAL ESTIMATED TIME:', `${totalEstimatedTime} minutes (${(totalEstimatedTime / 60).toFixed(1)} hours)`);

    const estimatedTimeToCompleteWinnerSideMatches = calcTimeToCompletePerTable(averageTimePerWinnerSideMatch, totalWinnerMatchesExcludingByes, tables);
    const estimatedTimeToCompleteLosersSideMatches = calcTimeToCompletePerTable(averageTimePerLoserSideMatch, totalLoserMatchesExcludingByes, tables);
    const estimatedTimeToCompleteTournament = {
        hours: (parseFloat(estimatedTimeToCompleteWinnerSideMatches.hours) + parseFloat(estimatedTimeToCompleteLosersSideMatches.hours)).toFixed(1),
        days: (parseFloat(estimatedTimeToCompleteWinnerSideMatches.days) + parseFloat(estimatedTimeToCompleteLosersSideMatches.days)).toFixed(1),
        summary: `${(parseFloat(estimatedTimeToCompleteWinnerSideMatches.hours) + parseFloat(estimatedTimeToCompleteLosersSideMatches.hours)).toFixed(
            1
        )} hours (${(parseFloat(estimatedTimeToCompleteWinnerSideMatches.days) + parseFloat(estimatedTimeToCompleteLosersSideMatches.days)).toFixed(1)} days)`,
    };

    // console.log('total loser side matches:', totalLoserMatches);
    // console.log('total winner side matches:', totalWinnerMatches);
    // console.log('total matches:', totalMatches);
    // console.log('max players:', maxPlayers);

    let result = {
        total_players: numPlayers,
        total_tables: tables,
        bracket_size: maxPlayers,
        total_rounds: totalRounds,
        total_matches: totalMatches,
        total_byes: totalByes,
        total_matches_excluding_byes: `${totalMatchesExcludingByes} (excluding BYEs)`,
        total_winner_rounds: winnerRounds,
        total_loser_rounds: loserRounds,
        total_winner_matches: totalWinnerMatches,
        total_winner_matches_excluding_byes: totalWinnerMatchesExcludingByes,
        total_loser_matches: totalLoserMatches,
        total_loser_matches_excluding_byes: totalLoserMatchesExcludingByes,
        total_games: totalGames,
        // avg_games_per_match: avgGamesPerMatch,
        avg_time_per_game: `${avgTimePerGame} minutes`,
        // avg_time_per_match: `${averageTimePerMatch} minutes`,
        estimated_time_to_complete_winner_side_matches: `${estimatedTimeToCompleteWinnerSideMatches.summary} using ${tables} tables`,
        estimated_time_to_complete_loser_side_matches: `${estimatedTimeToCompleteLosersSideMatches.summary} using ${tables} tables`,
        estimated_time_to_complete_tournament: `${estimatedTimeToCompleteTournament.summary} using ${tables} tables`,
    };

    const minTables = 20;
    const maxTables = totalTables <= minTables ? minTables : totalTables;

    for (var i = 1; i <= maxTables; i++) {
        const winnerSideMatchTime = calcTimeToCompletePerTable(averageTimePerWinnerSideMatch, totalWinnerMatchesExcludingByes, i);
        const loserSideMatchTime = calcTimeToCompletePerTable(averageTimePerLoserSideMatch, totalLoserMatchesExcludingByes, i);
        const summary = `${(parseFloat(winnerSideMatchTime.hours) + parseFloat(loserSideMatchTime.hours)).toFixed(1)} hours (${(
            parseFloat(winnerSideMatchTime.days) + parseFloat(loserSideMatchTime.days)
        ).toFixed(1)} days) using ${i} tables`;
        result = {
            ...result,
            [`using_${i}_tables`]: summary,
        };
    }

    return result;
}

function calcTimeToCompletePerTable(averageTimePerMatch, totalMatches, tables) {
    const minutes = averageTimePerMatch * (totalMatches / tables);
    const hours = minutes / 60;
    const data = {
        minutes: minutes.toFixed(1),
        hours: hours.toFixed(1),
        days: (hours / 24).toFixed(1),
        summary: `${hours.toFixed(1)} hours (${(hours / 24).toFixed(1)} days)`,
    };
    // console.log({
    // 	averageTimePerMatch,
    // 	totalMatches,
    // 	tables,
    // 	...data
    // });
    return data;
}

function getAverageGameTime(gameType) {
    /* There should be a table that allows someone to configure game types with average game times, maybe add a column to game_types */
    if (gameType === 'NINE_BALL') return 7;
    else if (gameType === 'EIGHT_BALL') return 10;
    else if (gameType === 'TEN_BALL') return 9;
    else if (gameType === 'ONE_POCKET') return 15;
    else if (gameType === 'STRAIGHT_POOL') return 15;
    else if (gameType === 'SNOOKER') return 30;
    else if (gameType === 'BANK_POOL') return 15;
    return 7;
}

function loserRoundMatches(numPlayers) {
    let loserRoundArr = [];
    let multiplier = numPlayers / 2;
    let i = 0;
    while (multiplier > 1) {
        if (i % 2 === 0) {
            multiplier = Math.ceil(multiplier / 2);
        }
        loserRoundArr.push(multiplier);
        i++;
    }
    loserRoundArr.push(1);
    return loserRoundArr;
}

function winnerRoundMatches(start, end, trueDoubleElim) {
    let extra = 1;
    if (trueDoubleElim === 1) {
        extra = extra + 1;
    }
    return Array(start - end + extra)
        .fill()
        .map((item, index) => {
            return Math.ceil(Math.pow(2, start - index) / 2);
        });
}

function calculateMatchLength(match) {
    return 60; // 60 mins
}

export function estimateProjectedStartTimeEndTime(match, matches) {
    let projectedStartTime;
    let projectedEndTime;
    if (match) {
        let prevMatchA = matches[match.winner_from_top_num - 1];
        let prevMatchB = matches[match.winner_from_bottom_num - 1];

        if (prevMatchA) {
            prevMatchA.projectedStartTime = prevMatchA && prevMatchA.start_time ? moment(prevMatchA.start_time).valueOf() : moment().valueOf();
            prevMatchA.projectedEndTime = prevMatchA && prevMatchA.end_time ? moment(prevMatchA.end_time).valueOf() : moment().valueOf();
        }
        if (prevMatchB) {
            prevMatchB.projectedStartTime = prevMatchB && prevMatchB.start_time ? moment(prevMatchB.start_time).valueOf() : moment().valueOf();
            prevMatchB.projectedEndTime = prevMatchB && prevMatchB.end_time ? moment(prevMatchB.end_time).valueOf() : moment().valueOf();
        }

        // console.log({
        // 	match,
        // 	matches,
        // 	prevMatchA,
        // 	prevMatchB
        // });

        /**
	Each match should be initialized with projectedStartTime and projectedEndTime and be set to
	the actual start and end time from the database if the match was started and possibly finished
	(these can be null if the match hasn't started)
	during initialization if the match was started but not completed, atore the projected end time by
	calling calculateMatchLength()
	 */
        if (prevMatchA && prevMatchB) {
            projectedStartTime = Math.max(prevMatchA.projectedEndTime, prevMatchB.projectedEndTime);
        } else {
            projectedStartTime = moment().valueOf();
        }
        projectedEndTime = projectedStartTime + calculateMatchLength(1000);

        const currentTime = moment();
        const projectedStartTimeHuman = moment(projectedStartTime).format('llll');
        const projectedEndTimeHuman = moment(projectedEndTime).format('llll');
        // console.log({
        // 	projectedStartTimeHuman,
        // 	projectedEndTimeHuman
        // });

        return `Estimated start: ${`${moment(projectedEndTime).format('LT')} (${moment(projectedEndTime).fromNow()})`}`;
    } else {
        return null;
    }

    return null;

    // return moment(projectedEndTime).format('hh:mm A');
}

export function estimateStartTime(matchNumber, totalTables, activeTables, avgMatchTime, totalMatches) {
    const matchesLeft = totalMatches + matchNumber - totalMatches - 1;
    const timeToComplete = calcTimeToCompletePerTable(avgMatchTime, matchesLeft, totalTables);
    const currentTime = moment();
    const projectedStartTime = currentTime.add(timeToComplete.hours, 'hours');
    // console.log({
    // 	matchNumber,
    // 	totalTables,
    // 	activeTables,
    // 	totalMatches,
    // 	matchesLeft,
    // 	avgMatchTime,
    // 	timeToComplete,
    // 	currentTime
    // });

    return `Estimated start: ${projectedStartTime.format('LT')} (in ${
        timeToComplete.hours >= 1
            ? `${parseFloat(timeToComplete.hours).toFixed(0)} hour${parseFloat(timeToComplete.hours).toFixed(0) > 1 ? 's' : ''}`
            : `${parseFloat(timeToComplete.minutes).toFixed(0)} minute${parseFloat(timeToComplete.minutes).toFixed(0) > 1 ? 's' : ''}`
    })`;
}

// so it could just be a function that takes current match number, total tables being used, avg match time, and total number of matches to calculate it.

// it needs to know what hours we are closed too, because the tournament is going to overlap to the next day
