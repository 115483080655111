import gql from "graphql-tag";

export const INSERT_GHOST_EVENT_MUTATION = gql`
    mutation insert_ghost_games($objects: [ghost_games_insert_input!]!) {
        insert_ghost_games(objects: $objects) {
            affected_rows
            returning {
                id
            }
        }
    }
`;

export const DELETE_GHOST_EVENT_PLAYER_ROUNDS_MUTATION = gql`
    mutation delete_ghost_games_rounds($ghost_games_id: Int!, $player_id: Int!) {
        delete_ghost_games_rounds(where: {ghost_games_id: {_eq: $ghost_games_id}, _and: {player_id: {_eq: $player_id}}}) {
            affected_rows
            returning {
                id
            }
        }
    }
`;

export const DELETE_GHOST_EVENT_PLAYER_MUTATION = gql`
    mutation delete_ghost_games_players($ghost_games_players_id: Int!) {
        delete_ghost_games_players(where: {id: {_eq: $ghost_games_players_id}}) {
            affected_rows
            returning {
                id
            }
        }
    }
`;

export const INSERT_GHOST_EVENT_PLAYER_MUTATION = gql`
    mutation insert_ghost_games_players($objects: [ghost_games_players_insert_input!]!) {
        insert_ghost_games_players(objects: $objects) {
            affected_rows
            returning {
                id
            }
        }
    }
`;

export const UPDATE_GHOST_GAMES_MUTATION = gql`
    mutation update_ghost_games($id: Int!, $changes: ghost_games_set_input) {
        update_ghost_games(where: { id: { _eq: $id } }, _set: $changes) {
            affected_rows
            returning {
                id
                slug
            }
        }
    }
`;

export const UPDATE_GHOST_GAMES_BY_SLUG_MUTATION = gql`
    mutation update_ghost_games($slug: String!, $changes: ghost_games_set_input) {
        update_ghost_games(where: { slug: { _eq: $slug } }, _set: $changes) {
            affected_rows
            returning {
                id
                slug
            }
        }
    }
`;

export const UPDATE_GHOST_GAMES_PLAYERS_MUTATION = gql`
    mutation update_ghost_games($id: Int!, $players: String!) {
        update_ghost_games(where: {id: {_eq: $id}}, _set: {players: $players}) {
            affected_rows
            returning {
                id
            }
        }
    }
`;

export const INSERT_GHOST_GAMES_PAYOUTS_ENTRY = gql`
    mutation insert_ghost_games_payouts($objects: [ghost_games_payouts_insert_input!]!) {
        insert_ghost_games_payouts(objects: $objects) {
            affected_rows
            returning {
                id
            }
        }
    }
`;

export const UPDATE_GHOST_GAMES_PAYOUTS_RECORD = gql`
    mutation update_ghost_games_payouts($id: Int!, $changes: ghost_games_payouts_set_input) {
        update_ghost_games_payouts(where: {id: {_eq: $id}}, _set: $changes) {
            affected_rows
            returning {
                id
            }
        }
    }
`;

export const DELETE_GHOST_GAMES_PAYOUTS_ENTRY = gql`
    mutation delete_ghost_games_payouts($id: Int!) {
        delete_ghost_games_payouts(where: {id: {_eq: $id}}) {
            affected_rows
            returning {
                id
            }
        }
    }
`;

export const INSERT_GHOST_GAMES_INVITE_KEY = gql`
    mutation insert_ghost_games_invite_keys_one($object: ghost_games_invite_keys_insert_input!) {
        insert_ghost_games_invite_keys_one(object: $object) {
            id
        }
    }
`;

export const INSERT_GHOST_GAMES_PLAYER_MUTATION = gql`
	mutation insert_ghost_games_players($objects: [ghost_games_players_insert_input!]!) {
		insert_ghost_games_players(objects: $objects) {
			affected_rows
			returning {
				id
			}
		}
	}
`;

export const UPDATE_GHOST_GAMES_INVITE_PLAYER = gql`
	mutation update_ghost_games_players($ghost_games_id: Int!, $email_address: String!, $changes: ghost_games_players_set_input) {
		update_ghost_games_players(where: {ghost_games_id: {_eq: $ghost_games_id} _and: {email_address: {_eq: $email_address}}}, _set: $changes) {
			affected_rows
			returning {
				id
			}
		}
	}
`;

export const DELETE_GHOST_GAMES_USER_INVITE_KEY = gql`
    mutation delete_ghost_games_invite_keys($ghost_games_id: Int!, $email: String!) {
        delete_ghost_games_invite_keys(where: {ghost_games_id: {_eq: $ghost_games_id}, email: {_eq: $email}}) {
            affected_rows
            returning {
                id
            }
        }
    }
`;

export const UPDATE_GHOST_GAMES_PLAYER_INVITED = gql`
    mutation update_ghost_games_players($id: Int!, $paid: Boolean!) {
        update_ghost_games_players(where: {id: {_eq: $id}}, _set: {paid: $paid}) {
            affected_rows
            returning {
                id
            }
        }
    }
`;

export const DELETE_GHOST_GAMES_PLAYER_BY_EMAIL = gql`
    mutation delete_ghost_games_players($ghost_games_id: Int!, $email_address: String!) {
        delete_ghost_games_players(where: {ghost_games_id: {_eq: $ghost_games_id}, _and: {email_address: {_eq: $email_address}}}) {
            affected_rows
            returning {
                id
            }
        }
    }
`;