import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { message, Icon, Upload, Avatar, Typography } from 'antd';
import { functions } from '../../../firebase';
import { connectFunctionsEmulator, httpsCallable } from 'firebase/functions';
import axios from 'axios';
import gql from 'graphql-tag';
import moment from 'moment-timezone';
import { getGameType, displayEnumType } from '../../../utils';

const { Dragger } = Upload;
const { Title } = Typography;

function TBFlyerUploadForm(props) {
    const [image, setImage] = useState();
    const fileReader = new FileReader();

    useEffect(() => {
        if (image) {
            props.client
                .mutate({
                    mutation: gql`
                        mutation update_tournaments($id: Int, $flyerUrl: String) {
                            update_tournaments(where: { id: { _eq: $id } }, _set: { flyer: $flyerUrl }) {
                                affected_rows
                                returning {
                                    id
                                    name
                                    slug
                                    flyer
                                }
                            }
                        }
                    `,
                    variables: {
                        id: props.tournament.id,
                        flyerUrl: image,
                    },
                })
                .then(async (res) => {
                    const results = res.data.update_tournaments.returning[0];
                    console.log(results);
                })
                .catch((error) => {
                    console.log(error);
                    console.error(error);
                });
        }
    }, [image, props.tournament, props.client]);

    const beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG files!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('Image must smaller than 2MB!');
        }
        return isJpgOrPng && isLt2M;
    };

    const onChange = (info) => {
        if (!fileReader.onloadend) {
            // fileReader.onloadend = (obj) => {
            // 	setImage(obj.srcElement.result);
            // };
            // can be any other read function ( any reading function from
            // previously created instance can be used )
            const { status } = info.file;
            // if (status !== 'uploading') {
            // 	console.log(info.file, info.fileList);
            // }
            if (status === 'done') {
                message.success(`${info.file.name} file uploaded successfully.`);
            } else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
            // fileReader.readAsArrayBuffer(info.file.originFileObj);
        }
    };

    const customRequest = async (option) => {
        const { onSuccess, onError, file, action, onProgress } = option;
        // const url = action;

        const data = {
            S3BucketName: 'digitalpool',
            key: `tournament-flyer/${file.name}`,
            contentType: file.type,
        };

        if (window.location.hostname.indexOf('localhost') > -1) {
            connectFunctionsEmulator(functions, 'localhost', 5001);
        }

        // Retrieve the Cloud Function
        var getPost = await httpsCallable(functions, 'uploadImageS3');
        // await new Promise((resolve) => waitUntilImageLoaded(resolve));
        // Call the cloud function
        return getPost(data)
            .then((result) => {
                // S3 pre-signed URL
                const options = {
                    onUploadProgress: (e) => {
                        onProgress({ percent: (e.loaded / e.total) * 100 });
                    },
                    headers: {
                        'Content-Type': file.type,
                        'x-amz-acl': 'public-read',
                        'x-amz-server-side-encryption': 'AES256',
                    },
                };

                const signedRequest = result.data.signedRequest;
                const imageUrl = result.data.url;
                // Run a HTTP PUT request to the pre-signed URL
                return axios
                    .put(signedRequest, file, options)
                    .then((response) => {
                        setImage(imageUrl);
                        const tournament = props.tournament && {
                            ...props.tournament,
                            flyer: imageUrl,
                        };
                        if (tournament) {
                            sendTournamentInfo(tournament);
                        }
                        onSuccess(response.body);
                    })
                    .catch((err) => {
                        console.log(err);
                        onError(err);
                    });
            })
            .catch((e) => {
                console.log(e);
                return e;
            });
    };

    function sendTournamentInfo(tournament) {
        // console.log(tournament);
        const startTime = moment(tournament.start_date_time).format('LL');
        const endTime = moment(tournament.start_end_time).format('LL');
        const gameType = makeHashTag(formatGameType(tournament.game_type));
        const address = `${tournament.venue && tournament.venue.name} ${tournament.venue && tournament.venue.city}, ${
            tournament.venue && tournament.venue.region
        } ${tournament.venue && tournament.venue.country}`;

        const tournamentInfo = {
            flyer: tournament.flyer,
            description: `${tournament.name}\n${startTime && endTime ? `${startTime} - ${endTime}` : startTime}\n${displayEnumType(
                tournament.tournament_type
            )}\n${tournament.venue && tournament.venue.name && tournament.venue.city ? address : tournament.venue.name}\n\n${
                tournament.description
            }\n\nTournament details: https://digitalpool.com/tournaments/${tournament.slug}\n\n${gameType ? gameType : ''} #digitalpool`,
        };

        console.log(tournamentInfo);
        axios
            .post('https://elicit.waypointsoftware.io/capture_post.php?xAuthentication=09e648487cf9156231d88645459d5ef0', tournamentInfo, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
            .then((response) => console.log(response))
            .catch((error) => {
                console.error('There was an error!', error);
            });
    }

    function makeHashTag(str) {
        if (str) {
            const strSplit = str.split(' ');
            let wordArray = strSplit.filter((char) => char !== '');
            let result = '#';

            if (wordArray.length === 0) {
                return false;
            }

            result =
                result +
                wordArray
                    .map((word) => {
                        let capitalizedWord = word.charAt(0).toUpperCase() + word.slice(1);
                        return capitalizedWord;
                    })
                    .join('');

            if (result.length > 140) {
                return false;
            } else {
                return result;
            }
        }
    }

    function formatGameType(type) {
        // console.log(type);
        const lower = type.toLowerCase();
        if (type) {
            return lower.match(/(eight_ball|eight ball|8ball|8 ball|8-ball)/)
                ? '8ball'
                : lower.match(/(nine_ball|nine ball|9ball|9 ball|9-ball)/)
                ? '9ball'
                : lower.match(/(one_pocket|one pocket)/)
                ? '1pocket'
                : lower.match(/(ten_ball|ten ball|10ball|10 ball|10-ball)/)
                ? '10ball'
                : lower.match(/(snooker)/)
                ? 'snooker'
                : null;
        } else {
            return;
        }
    }

    return (
        <div style={{ maxWidth: 400 }}>
            <Dragger
                name="file"
                multiple={false}
                className="flyer-uploader"
                // showUploadList={false}
                customRequest={customRequest}
                beforeUpload={beforeUpload}
                onChange={onChange}
            >
                {image || props.tournament.flyer ? (
                    <img src={image || props.tournament.flyer} alt="Flyer" width={300} />
                ) : (
                    <p className="ant-upload-drag-icon">
                        <Icon type="inbox" />
                    </p>
                )}

                <p className="ant-upload-text" style={{ marginTop: 10, paddingLeft: 24, paddingRight: 24 }}>
                    Click or drag a flyer image here to upload
                </p>
            </Dragger>
        </div>
    );
}

export default withRouter(TBFlyerUploadForm);
