function generateInviteKey(length) {
    var key = [
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        0,
        'a',
        'b',
        'c',
        'd',
        'e',
        'f',
        'g',
        'h',
        'i',
        'j',
        'k',
        'l',
        'm',
        'n',
        'o',
        'p',
        'q',
        'r',
        's',
        't',
        'u',
        'v',
        'w',
        'x',
        'y',
        'z',
        'A',
        'B',
        'C',
        'D',
        'E',
        'F',
        'G',
        'H',
        'I',
        'J',
        'K',
        'L',
        'M',
        'N',
        'O',
        'P',
        'Q',
        'R',
        'S',
        'T',
        'U',
        'N',
        'W',
        'X',
        'Y',
        'Z',
    ];

    var appendStr = '';
    for (let i = 1; i <= length; i++) {
        let rand = Math.floor(Math.random() * key.length);
        appendStr += key[rand];
    }

    return appendStr;
}

export default generateInviteKey;
