import React from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useMutation } from '@apollo/react-hooks';
import { Button } from 'antd';
import { removePlayerButtonSelector, playerTableSelector } from '../../data/selectors';
import { removeButtonLoadingAtom, rowSelectAtom, removeButtonDisabledAtom } from '../../data/atoms';
import { DELETE_GHOST_EVENT_PLAYER_MUTATION, DELETE_GHOST_EVENT_PLAYER_ROUNDS_MUTATION, DELETE_GHOST_GAMES_USER_INVITE_KEY } from '../../data/mutations';
import { GET_GHOST_EVENT_PLAYERS_QUERY } from '../../data/queries';

export default function RemoveButton(props) {
    const { client, ghostEventId } = props;

    const state = useRecoilValue(removePlayerButtonSelector);
    const table = useRecoilValue(playerTableSelector);
    const setLoading = useSetRecoilState(removeButtonLoadingAtom);
    const setSelectedRows = useSetRecoilState(rowSelectAtom);
    const setRemoveButtonDisabled = useSetRecoilState(removeButtonDisabledAtom);

    function onClick() {
        setLoading(true);

        setSelectedRows([]);
        table.selectedRows.forEach(async (element) => {
            await client.mutate({
                mutation: DELETE_GHOST_GAMES_USER_INVITE_KEY,
                variables: {
                    ghost_games_id: element.ghost_games_id,
                    email: element.email,
                },
            });

            await client.mutate({
                mutation: DELETE_GHOST_EVENT_PLAYER_ROUNDS_MUTATION,
                variables: {
                    player_id: element.id,
                    ghost_games_id: element.ghost_games_id,
                },
                notifyOnNetworkStatusChange: true,
                awaitRefetchQueries: true,
            });

            await client.mutate({
                mutation: DELETE_GHOST_EVENT_PLAYER_MUTATION,
                variables: { ghost_games_players_id: element.id },
                notifyOnNetworkStatusChange: true,
                awaitRefetchQueries: true,
                refetchQueries: [
                    {
                        query: GET_GHOST_EVENT_PLAYERS_QUERY,
                        variables: { ghost_games_id: ghostEventId },
                    },
                ],
            });
        });

        setRemoveButtonDisabled(true);
        setLoading(false);
    }

    return (
        <Button onClick={onClick} type="danger" disabled={state.buttonDisabled} loading={state.buttonLoading}>
            Remove
        </Button>
    );
}
