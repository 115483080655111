import React, { useState, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { message, Modal, Button, Upload, Icon, Typography } from 'antd';
// import parse from 'csv-parse';
import parse from '../../lib/csv-parse';
import { useMutation, useLazyQuery } from '@apollo/react-hooks';
import { ApolloConsumer } from '@apollo/client/react/components';
import promiseTimeout from '../../utils/promiseTimeout';
import { GET_USER, GET_TOURNAMENT_PLAYERS_QUERY, GET_TOURNAMENT_QUERY } from './data/queries';
import { CREATE_TOURNAMENT_PLAYER } from './data/mutations';
import { FIND_TOURNAMENT_PLAYER_BY_ID, FIND_TOURNAMENT_PLAYER_BY_EMAIL } from './data/queries';
import _ from 'lodash';
import axios from 'axios';

const { Text } = Typography;
const { confirm } = Modal;

function ImportPlayersModal(props) {
    const { tournament, players, onModalOk, onModalCancel, modalVisible } = props;
    const [fileList, setFileList] = useState();
    const [importing, setImporting] = useState(false);
    const [importedPlayers, setImportedPlayers] = useState();
    const [file, setFile] = useState();
    const [createTournamentPlayer, { mutationLoading, mutationError }] = useMutation(CREATE_TOURNAMENT_PLAYER);
    const [duplicateError, setDuplicateError] = useState('');

    const uploadProps = {
        onChange: handleFile,
        beforeUpload: (file) => {
            return false;
        },
    };

    function handleFile(info) {
        let localFileList = info.fileList;
        localFileList = localFileList.slice(-1);
        setFileList(localFileList);
        setFile(info.file);
    }

    async function checkIfUserExists(email, phone) {
        // Check if user exists in users table
        const res = await props.client.query({
            query: GET_USER,
            variables: {
                email: email,
                phone_number: phone,
            },
        });
        console.log(res.data);
        if (res.data && res.data.users && res.data.users.length > 0) {
            return res.data.users[0];
        }
    }

    async function checkIfPlayerExistsById(userId, tournamentId) {
        // Check if user exists in tournament players table
        const res = await props.client.query({
            query: FIND_TOURNAMENT_PLAYER_BY_ID,
            variables: {
                user_id: userId,
                tournament_id: tournamentId,
            },
        });
        if (res.data && res.data.tournament_players && res.data.tournament_players.length > 0) {
            return res.data.tournament_players[0];
        }
    }

    async function checkIfPlayerExistsByEmail(email, tournamentId) {
        // Check if user exists in tournament players table
        const res = await props.client.query({
            query: FIND_TOURNAMENT_PLAYER_BY_EMAIL,
            variables: {
                email: email.toString(),
                tournament_id: tournamentId,
            },
        });
        if (res.data && res.data.tournament_players && res.data.tournament_players.length > 0) {
            return res.data.tournament_players[0];
        }
    }

    function showConfirmStart() {
        confirm({
            title: 'Now that you have added players, would you like to go to the tournament bracket?',
            content: 'The bracket page is the primary interface for managing your tournament.',
            centered: true,
            transitionName: 'fade',
            maskTransitionName: 'none',
            okText: `Yes take me there`,
            cancelText: 'Cancel',
            onOk() {
                props.history.push(`/tournament-builder/${tournament.slug}/bracket`);
            },
            onCancel() {},
        });
    }

    function hasDuplicatePhoneNumber(source, phoneNumber, index) {
        if (phoneNumber === '') return false;

        const totalPlayers = source.length;
        var foundNum = 0;

        for (let i = 0; i < totalPlayers; i++) {
            let phone_number = source[i][index];
            if (phoneNumber === phone_number) foundNum++;
            if (phoneNumber === phone_number && foundNum === 2) {
                return true;
            }
        }

        return false;
    }

    function findValue(value, keys) {
        let index;
        if (Array.isArray(keys)) {
            console.log(value);
            console.log('array', keys);
            keys.forEach((key) => {
                const hasIndex = _.indexOf(value, key);
                if (hasIndex !== -1) {
                    index = hasIndex;
                }
            });
        } else {
            console.log(value);
            index = _.indexOf(value, keys);
        }
        console.log(index);
        return index;
    }

    function doesPlayerExist(name) {
        if (name && players) {
            const existingPlayer = players.filter((item) => {
                return item.name.toLowerCase().trim() === name.toLowerCase().trim();
            })[0];
            return existingPlayer ? true : false;
        } else {
            return false;
        }
    }

    async function submit(e) {
        let reader = new FileReader();
        reader.onload = (e) => {
            parse(e.target.result, {}, async (err, output) => {
                const firstRow = output[0];
                const lowerFirstRow = firstRow.map((label) => label.toLowerCase().replace(/\s+/g, ''));
                const nameIndex = findValue(lowerFirstRow, 'name');
                const emailIndex = findValue(lowerFirstRow, ['email', 'email_address', 'emailaddress']);
                const countryIndex = findValue(lowerFirstRow, 'country');
                const countryPhoneIndex = findValue(lowerFirstRow, ['country_phone', 'countryphone']);
                const phoneIndex = findValue(lowerFirstRow, ['phone', 'phone_number', 'phonenumber']);
                const skillIndex = findValue(lowerFirstRow, ['skill', 'skill_level', 'skilllevel']);
                const raceToIndex = findValue(lowerFirstRow, ['race', 'race_to', 'raceto']);
                const seedIndex = findValue(lowerFirstRow, 'seed');
                const fargoIndex = findValue(lowerFirstRow, ['fargo', 'fargo_id', 'fargoid']);
                let skippedPlayers = 0;

                console.log({
                    nameIndex,
                    emailIndex,
                    countryIndex,
                    countryPhoneIndex,
                    phoneIndex,
                    skillIndex,
                    raceToIndex,
                    seedIndex,
                    fargoIndex,
                });

                output = output.slice(1, output.length);
                const totalPlayers = output.length;

                setImporting(true);
                setImportedPlayers(output);

                const promises = [];
                const playersToInsert = [];
                console.log(output);

                for (let i = 0; i < output.length; i++) {
                    let name = nameIndex !== null && output[i][nameIndex];
                    let email = emailIndex !== null && output[i][emailIndex];
                    let country_phone = countryPhoneIndex !== null && output[i][countryPhoneIndex];
                    let phone_number = phoneIndex !== null && output[i][phoneIndex];
                    let skill_level = skillIndex !== null && output[i][skillIndex];
                    let race_to = raceToIndex !== null && output[i][raceToIndex];
                    let seed = seedIndex !== null && output[i][seedIndex];
                    let country = countryIndex !== null && output[i][countryIndex];
                    let fargo_id = fargoIndex !== null && output[i][fargoIndex];
                    let user;

                    // if (email || phone_number) {
                    //     user = await checkIfUserExists(email, phone_number);
                    // }

                    const playerExists = doesPlayerExist(name);

                    if (playerExists === true) {
                        message.error(`Player ${name} already exists. Skipping...`, 5);
                        skippedPlayers++;
                        continue;
                    } else {
                        if (phone_number) {
                            let formatNumber = phone_number.replace(/[^\d]/g, '');
                            if (formatNumber) {
                                if (formatNumber.length !== 10 && formatNumber.length !== 11) {
                                    message.error(`Invalid phone number length found for ${name}: ${phone_number}. It must be 10 or 11 characters long.`, 25);
                                    // setImporting(false);
                                    skippedPlayers++;
                                    continue;
                                }
                                if (hasDuplicatePhoneNumber(output, phone_number, phoneIndex)) {
                                    message.error(`Duplicate phone number found for ${name}: ${phone_number}. Skipping...`, 5);
                                    // setImporting(false);
                                    skippedPlayers++;
                                    continue;
                                }
                            }
                            phone_number = formatNumber;
                        } else {
                            phone_number = null;
                        }
                    }

                    // const fargo = await getFargoRatingByName(name);
                    // console.log('fargo rating', fargo);
                    // skill_level = fargo && fargo.rating;
                    // If user exists in users table
                    if (user) {
                        console.log('user found in users table, checking for existing player record...');
                        console.log('user found:', user);
                        var existingPlayer = await checkIfPlayerExistsById(user.id, props.tournamentID);
                        // if player exists in tournament_players table don't insert
                        if (!existingPlayer) {
                            // If tournament player doesnt exist then insert it using the user data
                            const existingUser = {
                                tournament_id: props.tournamentID,
                                user_id: user.id,
                                ...(user.first_name && { name: `${user.first_name.trim()} ${user.last_name.trim()}` }),
                                ...(user.country && { country: user.country }),
                                ...(user.email && { email: user.email }),
                                ...(user.country_phone && { country_phone: user.country_phone }),
                                ...(user.phone_number && { phone_number: user.phone_number }),
                                ...(user.skill_level && { skill_level: parseInt(user.skill_level) }),
                                ...(user.fargo_id && { fargo_id: parseInt(user.fargo_id) }),
                                ...(user.race_to && { race_to: parseInt(user.race_to) }),
                                ...(user.seed && { seed: parseInt(user.seed) }),
                            };
                            // console.log('user not found in tournament_players table, adding...');
                            // let insertPromise = promiseTimeout(1000, insertTournamentPlayerMutation(existingUser));
                            // promises.push(insertPromise);
                            playersToInsert.push(existingUser);
                        } else {
                            // console.log('player found in tournament_players table, skipping...');
                            // console.log('player found:', existinPlayer);
                        }
                    } else {
                        // console.log(`user not found with email ${email}, checking for existing player record...`);
                        // var existingPlayer = await checkIfPlayerExistsByEmail(email, props.tournamentID);
                        // If no user or tournament players exist, insert into tournament players db
                        const newTourneyPlayer = {
                            tournament_id: props.tournamentID,
                            ...(name && { name: name.trim() }),
                            ...(country && { country: country }),
                            ...(email && { email: email }),
                            ...(country_phone && { country_phone: country_phone }),
                            ...(phone_number && { phone_number: phone_number }),
                            ...(skill_level && { skill_level: parseInt(skill_level) }),
                            ...(race_to && { race_to: parseInt(race_to) }),
                            ...(seed && { seed: parseInt(seed) }),
                            ...(fargo_id && { fargo_id: parseInt(fargo_id) }),
                        };
                        // console.log(`player record not found for ${email} in tournament_players table, adding...`);
                        // let insertPromise = promiseTimeout(1000, insertTournamentPlayerMutation(newTourneyPlayer));
                        // promises.push(insertPromise);
                        playersToInsert.push(newTourneyPlayer);
                    }

                    // console.log('---------------------------');
                }
                // console.log('----- end of player list ----');

                // console.log('Multiple fargo results', multipleFargoResults);

                createTournamentPlayer({
                    variables: {
                        objects: playersToInsert,
                    },
                    notifyOnNetworkStatusChange: true,
                    awaitRefetchQueries: true,
                    refetchQueries: [
                        {
                            query: GET_TOURNAMENT_PLAYERS_QUERY,
                            variables: { tournament_id: tournament.id },
                        },
                        {
                            query: GET_TOURNAMENT_QUERY,
                            variables: { id: tournament.id },
                        },
                    ],
                })
                    .then((data) => {
                        console.log(data);
                        setImporting(false);
                        const totalPlayersImported = totalPlayers - skippedPlayers;
                        if (totalPlayersImported > 0) {
                            message.success(totalPlayersImported + ' Players imported successfully');

                            // props.client.query({
                            //     query: GET_TOURNAMENT_PLAYERS_QUERY,
                            //     variables: {
                            //         tournament_id: tournament.id,
                            //     },
                            // });
                        } else {
                            message.warning('No players were imported');
                        }

                        onModalOk();
                        // showConfirmStart();
                    })
                    .catch((error) => {
                        console.error(error);
                        setImporting(false);
                        message.error('There was an error', JSON.stringify(error));
                        onModalOk();
                    });
            });
        };
        if (file) {
            reader.readAsText(file);
        } else {
            alert('Please choose a file to import');
        }

        // props.onModalOk();
    }

    return (
        <Modal
            title="Import Player Spreadsheet"
            // getContainer={() => document.querySelector('.tables')}
            visible={modalVisible}
            onOk={onModalOk}
            onCancel={onModalCancel}
            centered
            transitionName="fade"
            // transitionName="none"
            maskTransitionName="none"
            footer={[
                <Button key="cancel" onClick={onModalCancel}>
                    Cancel
                </Button>,
                <Button key="submit" type="primary" onClick={submit} loading={importing}>
                    {importing === true
                        ? `Importing ${importedPlayers ? importedPlayers.length : ''} players...`
                        : `Import ${importedPlayers ? `${importedPlayers.length} Players` : 'Players'}`}
                </Button>,
            ]}
            bodyStyle={{ maxHeight: 500, overflowY: 'auto' }}
        >
            <Upload {...uploadProps} fileList={fileList}>
                <Button size="large">
                    <Icon type="upload" /> Upload players spreadsheet (.csv only)
                </Button>
                {duplicateError}
            </Upload>
            <div style={{ marginTop: 10 }}>
                <Text>Download sample players spreadsheet:</Text>
                <ul style={{ marginTop: 10 }}>
                    <li>
                        <a
                            href="https://docs.google.com/spreadsheets/d/e/2PACX-1vTJS5alqUSm1vAKzTtGrrXFT-VVnSUQvvfJqvm1pmhNA-zcHCPPUuNIteewZPZUhyO5LshmDkPcM7yk/pub?gid=0&single=true&output=csv"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            8 players
                        </a>
                    </li>
                    <li>
                        <a
                            href="https://docs.google.com/spreadsheets/d/e/2PACX-1vTJS5alqUSm1vAKzTtGrrXFT-VVnSUQvvfJqvm1pmhNA-zcHCPPUuNIteewZPZUhyO5LshmDkPcM7yk/pub?gid=226496687&single=true&output=csv"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            16 players
                        </a>
                    </li>
                    <li>
                        <a
                            href="https://docs.google.com/spreadsheets/d/e/2PACX-1vTJS5alqUSm1vAKzTtGrrXFT-VVnSUQvvfJqvm1pmhNA-zcHCPPUuNIteewZPZUhyO5LshmDkPcM7yk/pub?gid=1488275380&single=true&output=csv"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            32 players
                        </a>
                    </li>
                    <li>
                        <a
                            href="https://docs.google.com/spreadsheets/d/e/2PACX-1vTJS5alqUSm1vAKzTtGrrXFT-VVnSUQvvfJqvm1pmhNA-zcHCPPUuNIteewZPZUhyO5LshmDkPcM7yk/pub?gid=635549493&single=true&output=csv"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            64 players
                        </a>
                    </li>
                    <li>
                        <a
                            href="https://docs.google.com/spreadsheets/d/e/2PACX-1vTJS5alqUSm1vAKzTtGrrXFT-VVnSUQvvfJqvm1pmhNA-zcHCPPUuNIteewZPZUhyO5LshmDkPcM7yk/pub?gid=1250317935&single=true&output=csv"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            128 players
                        </a>
                    </li>
                    <li>
                        <a
                            href="https://docs.google.com/spreadsheets/d/e/2PACX-1vTJS5alqUSm1vAKzTtGrrXFT-VVnSUQvvfJqvm1pmhNA-zcHCPPUuNIteewZPZUhyO5LshmDkPcM7yk/pub?gid=461599153&single=true&output=csv"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            256 players
                        </a>
                    </li>
                </ul>
            </div>
        </Modal>
    );
}

export default withRouter(ImportPlayersModal);
