import React from 'react';
import { Typography } from 'antd';

const { Text } = Typography;

export default function TPA(props) {
    const { player_stats, player_tpa } = props;

    return (
        <React.Fragment>
            {player_stats && player_tpa && (
                <div style={{ marginLeft: 10, marginRight: 10, fontSize: 16, lineHeight: 1.2 }}>
                    <Text style={{ color: 'rgba(255,255,255,0.5)' }}>TPA:</Text> <Text style={{ color: '#fff' }}>{player_tpa}</Text>
                </div>
            )}
        </React.Fragment>
    );
}
