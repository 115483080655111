import gql from 'graphql-tag';

export const GET_MATCH_SUBSCRIPTION = gql`
    subscription matches($match_id: Int!) {
        matches(where: { id: { _eq: $match_id } }) {
            id
            name
            slug
            description
            match_number
            match_type
            game_type
            rule_format
            handicap_format
            race_to
            player_id
            player_name
            player_score
            player_points
            player_tiebreaker_points
            player_race_to
            player_country
            player_state
            player_fargo
            player_is_winner
            opponent_id
            opponent_name
            opponent_score
            opponent_points
            opponent_tiebreaker_points
            opponent_race_to
            opponent_country
            opponent_state
            opponent_fargo
            opponent_is_winner
            winner_id
            winner_score
            loser_id
            loser_score
            status
            progress
            duration
            livestream_url
            score_verified
            scheduled_time
            start_date_time
            end_date_time
            venue_id
            venue {
                id
                name
                slug
                address1
                address2
                city
                region
                country
                postal_code
            }
            pool_table_id
            pool_table {
                id
                label
                slug
                kiosk_ip_address
                kiosk_password
            }
            match_players {
                id
                match_id
                user_id
                name
                country
                skill_level
                race_to
                score
                is_winner
            }
            match_stats(order_by: { time: desc }) {
                id
                metric
                value
                user_id
                match_id
                match_game_id
                player_id
                opponent_id
                game_number
                time
            }
            table_size
            scoreboard_balls
            creator_id
            created_at
            updated_at
        }
    }
`;

export const GET_MATCH_BY_SLUG_SUBSCRIPTION = gql`
    subscription matches($slug: String!) {
        matches(where: { slug: { _eq: $slug } }) {
            id
            name
            slug
            description
            match_number
            match_type
            game_type
            rule_format
            handicap_format
            race_to
            player_id
            player_name
            player_score
            player_points
            player_tiebreaker_points
            player_race_to
            player_country
            player_state
            player_fargo
            player_is_winner
            opponent_id
            opponent_name
            opponent_score
            opponent_points
            opponent_tiebreaker_points
            opponent_race_to
            opponent_country
            opponent_state
            opponent_fargo
            opponent_is_winner
            winner_id
            winner_score
            loser_id
            loser_score
            status
            progress
            duration
            livestream_url
            score_verified
            scheduled_time
            start_date_time
            end_date_time
            venue_id
            venue {
                id
                name
                slug
                address1
                address2
                city
                region
                country
                postal_code
            }
            pool_table_id
            pool_table {
                id
                label
                slug
                kiosk_ip_address
                kiosk_password
            }
            match_players {
                id
                match_id
                user_id
                name
                country
                skill_level
                race_to
                score
                is_winner
            }
            match_stats(order_by: { time: desc }) {
                id
                metric
                value
                user_id
                match_game_id
                player_id
                time
            }
            table_size
            scoreboard_balls
            creator_id
            created_at
            updated_at
        }
    }
`;

export const GET_MATCH_STATS_SUBSCRIPTION = gql`
    subscription match_stats($match_id: Int!) {
        match_stats(where: { match_id: { _eq: $match_id } }, order_by: { time: desc }) {
            id
            metric
            value
            user_id
            match_id
            match_game_id
            player_id
            opponent_id
            game_number
            time
        }
    }
`;

export const GET_MATCH_STATS_BY_SLUG_SUBSCRIPTION = gql`
    subscription match_stats($slug: String!) {
        match_stats(where: { slug: { _eq: $slug } }, order_by: { time: desc }) {
            id
            metric
            value
            user_id
            match_id
            match_game_id
            player_id
            opponent_id
            game_number
            time
            match {
                id
                slug
                match_number
                match_type
                game_type
                rule_format
                handicap_format
                race_to
                player_id
                player_name
                player_score
                player_points
                player_tiebreaker_points
                player_race_to
                player_country
                player_state
                player_fargo
                player_is_winner
                opponent_id
                opponent_name
                opponent_score
                opponent_points
                opponent_tiebreaker_points
                opponent_race_to
                opponent_country
                opponent_state
                opponent_fargo
                opponent_is_winner
                winner_id
                winner_score
                loser_id
                loser_score
                status
                progress
                duration
                livestream_url
                score_verified
                scheduled_time
                start_date_time
                end_date_time
            }
        }
    }
`;

export const GET_MATCH_LONGEST_WINNING_STREAK = gql`
    subscription match_stats($match_id: Int!) {
        vw_match_longest_winning_streaks(where: { match_id: { _eq: $match_id } }) {
            longest_opponent_winning_streak
            longest_player_winning_streak
            match_id
            opponent_name
            player_name
        }
    }
`;

export const GET_MATCH_BREAK_AND_RUN_PACKAGES = gql`
    subscription vw_break_and_run_streaks($match_id: Int!) {
        vw_break_and_run_streaks(where: { match_id: { _eq: $match_id } }) {
            first_game
            last_game
            match_id
            name
            player_streak_length
        }
    }
`;

export const GET_MATCH_PLAYER_CONSECUTIVE_BREAK_AND_RUNS = gql`
    subscription vw_break_and_run_streaks($match_id: Int!) {
        vw_break_and_run_streaks(where: { match_id: { _eq: $match_id }, name: { _eq: "player" } }, order_by: { player_streak_length: desc }, limit: 1) {
            first_game
            last_game
            match_id
            name
            player_streak_length
        }
    }
`;

export const GET_MATCH_OPPONENT_CONSECUTIVE_BREAK_AND_RUNS = gql`
    subscription vw_break_and_run_streaks($match_id: Int!) {
        vw_break_and_run_streaks(where: { match_id: { _eq: $match_id }, name: { _eq: "opponent" } }, order_by: { player_streak_length: desc }, limit: 1) {
            first_game
            last_game
            match_id
            name
            player_streak_length
        }
    }
`;

export const GET_MATCH_DAY_STATS = gql`
    subscription vw_match_day_stats($match_id: Int!) {
        vw_match_day_stats(where: { match_id: { _eq: $match_id } }) {
            daily_metric_change
            day
            match_id
            max_metric_value
            metric
            min_metric_value
        }
    }
`;

export const GET_SHOTCAST_EVENTS_SUBSCRIPTION = gql`
    subscription shotcast_events($match_id: Int!) {
        shotcast_events(where: { match_id: { _eq: $match_id } }, limit: 10, order_by: { event_timestamp: desc }) {
            id
            tournament_bracket_id
            match_id
            event_timestamp
            player_name
            description
            shot_data
            created_at
            updated_at
        }
    }
`;
