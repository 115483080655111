import React, { useState, useEffect } from 'react';
import { withRouter, Route, Redirect, Switch } from 'react-router-dom';
import { message, Result, Button, Row, Col, Layout, Menu, Icon, Typography } from 'antd';
import Card from '../../components/Card';
import { Link } from 'react-router-dom';
// import Card from '../../components/Card';
// import SectionHeader from '../../components/SectionHeader';
// import SectionContent from '../../components/SectionContent';
import { Query } from '@apollo/client/react/components';
import SectionContent from '../../components/SectionContent';
import SectionFooter from '../../components/SectionFooter';
import TBDashboard from './TBDashboard';
import TBSettings from './TBSettings';
import TBSchedule from './TBSchedule2';
import TBBrackets from './TBBrackets2';
import TBViewer from './TBViewer';
import TBScores from './TBScores';
import TBResults from './TBResults';
import TBMatches from './TBMatches';
import TBNotifications from './TBNotifications';
import TBTables from './TBTables';
import TBTableDetail from './TBTableDetail';
import TBStats from './TBStats2';
import TBPlayers from './TBPlayers';
import NotifyAllPlayers from './NotifyAllPlayers';
import TBPlayerDetail from './TBPlayerDetail';
import TBPointsPayoutsContainer from './TBPointsPayoutsContainer';
import TBVenues from './TBVenues';
import TBVenueDetail from './TBVenueDetail';
import CircularLoader from '../../components/CircularLoader';
import * as routes from '../../config/routes';
import { GET_TOURNAMENT_BY_SLUG_QUERY } from './data/queries';
import _ from 'lodash';

import withAuthorization from '../auth/withAuthorization';
import { canAccess } from '../../utils/canAccess';

const { Sider, Content } = Layout;
const { Text } = Typography;

const styles = {
    row: {
        display: 'flex',
        flexGrow: 1,
    },
    tab: {
        paddingTop: 5,
        paddingLeft: 24,
        paddingRight: 24,
        paddingBottom: 24,
    },
};

function TBDetail(props, context) {
    // const title = _.startCase(_.toLower(props.match.params.slug.replace('-', ' ')));
    const { match, authState, navType } = props;
    const slug = match.params.slug;
    const urlPath = window.location.pathname;
    const splitPath = urlPath.split('/');
    const tbIncludes = [
        'dashboard',
        'settings',
        'schedule',
        'bracket',
        'scores',
        'results',
        'matches',
        'notifications',
        'tables',
        'stats',
        'players',
        'payouts_points',
        'venues',
        'viewer',
        'rr-viewer',
    ];
    const [collapsed, setCollapsed] = useState(true);

    let pathMatch =
        splitPath[splitPath.length] === match.params.slug
            ? splitPath[splitPath.length - 1]
            : _.includes(tbIncludes, splitPath[splitPath.length - 1])
            ? splitPath[splitPath.length - 1]
            : _.includes(tbIncludes, splitPath[splitPath.length - 2])
            ? splitPath[splitPath.length - 2]
            : _.includes(tbIncludes, splitPath[splitPath.length - 3])
            ? splitPath[splitPath.length - 3]
            : 'tournament-builder';

    const handleClick = (e) => {
        const url = e.key ? `/tournament-builder/${match.params.slug}/${e.key}` : `/tournament-builder/${e.key}/dashboard`;
        props.history.push(url);
    };

    const [current, setCurrent] = useState(pathMatch);

    useEffect(() => {
        setCurrent(pathMatch);
    }, [pathMatch]);

    return (
        <Query
            query={GET_TOURNAMENT_BY_SLUG_QUERY}
            fetchPolicy="cache-and-network"
            notifyOnNetworkStatusChange
            variables={{ slug: slug }}
            onCompleted={async (data) => {
                // const tournament = data.tournaments[0];
                // console.log(tournament);
            }}
        >
            {({ loading, error, data }) => {
                if (loading)
                    return (
                        <div
                            style={{
                                height: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <CircularLoader />
                            <Text style={{ marginLeft: 10 }}>Loading...</Text>
                        </div>
                    );
                if (error) return <div style={styles.container}>Error: {error.message}</div>;
                if (data && !data.tournaments) {
                    return <div>Tournament not found.</div>;
                }
                if (data && data.tournaments.length === 0) {
                    return <div>Tournament not found.</div>;
                }

                const tournament = data.tournaments[0];
                const managers = data && data.tournaments[0].tournament_managers;
                var manager;
                var isManager = false;

                for (let i = 0; i < managers.length; i++) {
                    manager = managers[i].manager;
                    if (!manager) continue;
                    if (manager.id === authState.user.id) {
                        isManager = true;
                        break;
                    }
                }

                return (
                    <React.Fragment>
                        {(authState && authState.user && authState.user.role === 'admin') ||
                        (authState &&
                            authState.user &&
                            ((tournament.organizer_id && tournament.organizer_id === authState.user.id) ||
                                (tournament.director_id && tournament.director_id === authState.user.id))) ||
                        isManager ||
                        slug === 'test-tournament-1' ? (
                            <Layout style={navType === 'mobile' ? { height: 'calc(100% - 64px)' } : { height: '100%' }}>
                                <Sider
                                    width={40}
                                    collapsed
                                    // collapsed={collapsed}
                                    // onMouseEnter={() => {
                                    //     setCollapsed(false);
                                    // }}
                                    // onMouseLeave={() => {
                                    //     setCollapsed(true);
                                    // }}
                                >
                                    <Menu
                                        onClick={handleClick}
                                        defaultSelectedKeys={['dashboard']}
                                        selectedKeys={[current]}
                                        mode="inline"
                                        theme="dark"
                                        // inlineCollapsed
                                        style={{ height: '100%' }}
                                    >
                                        <Menu.Item key="dashboard" style={{ padding: '0px !important' }}>
                                            <Icon type="dashboard" /> <span>Dashboard</span>
                                        </Menu.Item>
                                        {tournament && (tournament.tournament_type === 'chip' || tournament.tournament_type === 'round_robin') ? (
                                            <Menu.Item key="viewer">
                                                <Icon type="eye" /> <span>Viewer</span>
                                            </Menu.Item>
                                        ) : (
                                            <Menu.Item key="bracket">
                                                <Icon type="apartment" /> <span>Bracket</span>
                                            </Menu.Item>
                                        )}

                                        <Menu.Item key="tables">
                                            <Icon type="appstore" /> <span>Tables</span>
                                        </Menu.Item>
                                        <Menu.Item key="players">
                                            <Icon type="user" /> <span>Players</span>
                                        </Menu.Item>
                                        <Menu.Item key="notifications">
                                            <Icon type="bell" /> <span>Notifications</span>
                                        </Menu.Item>
                                        <Menu.Item key="matches">
                                            <Icon type="thunderbolt" /> <span>Matches</span>
                                        </Menu.Item>
                                        <Menu.Item key="schedule">
                                            <Icon type="schedule" /> <span>Schedule</span>
                                        </Menu.Item>
                                        <Menu.Item key="results">
                                            <Icon type="trophy" /> <span>Results</span>
                                        </Menu.Item>
                                        <Menu.Item key="stats">
                                            <Icon type="bar-chart" /> <span>Stats</span>
                                        </Menu.Item>
                                        <Menu.Item key="payouts_points">
                                            <Icon type="dollar" /> <span>Payouts / Points</span>
                                        </Menu.Item>

                                        {/* <Menu.Item key="venues">
											<Icon type="environment" /> <span>Venues</span>
										</Menu.Item> */}
                                        <Menu.Item key="settings">
                                            <Icon type="setting" /> <span>Settings</span>
                                        </Menu.Item>
                                    </Menu>
                                </Sider>

                                <Layout>
                                    <Switch>
                                        <Redirect
                                            exact
                                            strict
                                            from={`/tournament-builder/${props.match.params.slug}`}
                                            to={`/tournament-builder/${props.match.params.slug}/dashboard`}
                                        />

                                        <Route
                                            exact
                                            path={routes.TOURNAMENT_BUILDER.DETAIL_DASHBOARD}
                                            render={() => <TBDashboard {...props} title="Dashboard" tournament={tournament} />}
                                        />
                                        <Route
                                            path={routes.TOURNAMENT_BUILDER.DETAIL_SETTINGS}
                                            render={() => <TBSettings {...props} title="Settings" tournament={tournament} />}
                                        />
                                        <Route
                                            path={routes.TOURNAMENT_BUILDER.DETAIL_SCHEDULE}
                                            render={() => <TBSchedule {...props} title="Schedule" tournament={tournament} />}
                                        />
                                        <Route
                                            exact
                                            path={routes.TOURNAMENT_BUILDER.DETAIL_BRACKET}
                                            render={() => <TBBrackets {...props} title="Bracket" tournament={tournament} />}
                                        />
                                        <Route
                                            exact
                                            path={routes.TOURNAMENT_BUILDER.DETAIL_BRACKET_STAGE}
                                            render={() => <TBBrackets {...props} title="Bracket" tournament={tournament} />}
                                        />
                                        <Route
                                            exact
                                            path={routes.TOURNAMENT_BUILDER.DETAIL_VIEWER}
                                            render={() => <TBViewer {...props} title="Viewer" tournament={tournament} />}
                                        />

                                        <Route
                                            exact
                                            path={routes.TOURNAMENT_BUILDER.DETAIL_SCORES}
                                            render={() => <TBScores {...props} title="Live Scores" tournament={tournament} />}
                                        />
                                        <Route
                                            exact
                                            path={routes.TOURNAMENT_BUILDER.DETAIL_RESULTS}
                                            render={() => <TBResults {...props} title="Results" tournament={tournament} />}
                                        />
                                        <Route
                                            exact
                                            path={routes.TOURNAMENT_BUILDER.DETAIL_MATCHES}
                                            render={() => <TBMatches {...props} title="Matches" tournament={tournament} />}
                                        />
                                        <Route
                                            exact
                                            path={routes.TOURNAMENT_BUILDER.DETAIL_TABLES}
                                            render={() => <TBTables {...props} title="Tables" tournament={tournament} />}
                                        />
                                        <Route
                                            exact
                                            path={routes.TOURNAMENT_BUILDER.DETAIL_TABLE_DETAIL}
                                            render={() => <TBTableDetail {...props} title="Table Detail" tournament={tournament} />}
                                        />

                                        <Route
                                            exact
                                            path={routes.TOURNAMENT_BUILDER.DETAIL_STATS}
                                            render={() => <TBStats {...props} title="Statistics" tournament={tournament} />}
                                        />
                                        <Route
                                            exact
                                            path={routes.TOURNAMENT_BUILDER.DETAIL_PLAYERS}
                                            render={() => <TBPlayers {...props} title="Players" tournament={tournament} />}
                                        />
                                        <Route
                                            exact
                                            path={routes.TOURNAMENT_BUILDER.DETAIL_NOTIFICATIONS}
                                            render={() => <TBNotifications {...props} title="Notifications" tournament={tournament} />}
                                        />
                                        <Route
                                            exact
                                            path={routes.TOURNAMENT_BUILDER.NOTIFY_PLAYERS}
                                            render={() => <NotifyAllPlayers {...props} title="Players" tournament={tournament} />}
                                        />
                                        <Route
                                            exact
                                            path={routes.TOURNAMENT_BUILDER.DETAIL_PLAYER_INFO}
                                            render={() => <TBPlayerDetail {...props} title="Player" tournament={tournament} />}
                                        />
                                        <Route
                                            exact
                                            path={routes.TOURNAMENT_BUILDER.DETAIL_POINTS_PAYOUTS}
                                            render={() => <TBPointsPayoutsContainer {...props} title="Payouts / Points" tournament={tournament} />}
                                        />
                                        <Route
                                            exact
                                            path={routes.TOURNAMENT_BUILDER.DETAIL_VENUES}
                                            render={() => <TBVenues {...props} title="Venues" tournament={tournament} />}
                                        />
                                        <Route
                                            exact
                                            path={routes.TOURNAMENT_BUILDER.DETAIL_VENUE_INFO}
                                            render={() => <TBVenueDetail {...props} title="Venue" tournament={tournament} />}
                                        />
                                        {/* <Route
								exact
								path={routes.LEAGUE_MANAGER.DETAIL_DIVISIONS}
								render={() => <LMDivisions {...props} title="Divisions" data={tournament} />}
							/>
							<Route
								exact
								path={routes.LEAGUE_MANAGER.DETAIL_DIVISION_CREATE}
								render={() => <LMDivisionCreate {...props} title="Create New Division" data={tournament} />}
							/>
							<Route
								exact
								path={routes.LEAGUE_MANAGER.DETAIL_DIVISION_EDIT}
								render={() => <LMDivisionCreate {...props} title="Edit Division" data={tournament} />}
							/>
							<Route
								exact
								path={routes.LEAGUE_MANAGER.DETAIL_DIVISION}
								render={() => <LMDivisionDetail title="Division Detail" data={tournament} />}
							/>

							<Route
								exact
								path={routes.LEAGUE_MANAGER.DETAIL_TEAMS}
								render={() => <LMTeams {...props} title="Teams" data={tournament} />}
							/>
							<Route
								exact
								path={routes.LEAGUE_MANAGER.DETAIL_PLAYERS}
								render={() => <LMPlayers {...props} title="Players" data={tournament} />}
							/>
							<Route
								exact
								path={routes.LEAGUE_MANAGER.DETAIL_SCHEDULE}
								render={() => <LMSchedule {...props} title="Schedule" data={tournament} />}
							/>
							<Route
								exact
								path={routes.LEAGUE_MANAGER.DETAIL_MATCHES}
								render={() => <LMMatches {...props} title="Matches" data={tournament} />}
							/>
							<Route
								exact
								path={routes.LEAGUE_MANAGER.DETAIL_RESULTS}
								render={() => <LMResults {...props} title="Results" data={tournament} />}
							/>
							<Route
								exact
								path={routes.LEAGUE_MANAGER.DETAIL_STATS}
								render={() => <LMStats {...props} title="Stats" data={tournament} />}
							/>
							<Route
								exact
								path={routes.LEAGUE_MANAGER.DETAIL_VENUES}
								render={() => <LMVenues {...props} title="Venues" data={tournament} />}
							/>
							<Route
								exact
								path={routes.LEAGUE_MANAGER.DETAIL_VENUE_CREATE}
								render={() => <LMVenueCreate {...props} title="Create New Venue" data={tournament} />}
							/>
							<Route
								exact
								path={routes.LEAGUE_MANAGER.DETAIL_VENUE_EDIT}
								render={() => <LMVenueCreate {...props} title="Edit Venue" data={tournament} />}
							/>
							<Route
								exact
								path={routes.LEAGUE_MANAGER.DETAIL_VENUE}
								render={() => <LMVenueDetail title="Venue Detail" data={tournament} />}
							/> */}
                                    </Switch>
                                </Layout>
                            </Layout>
                        ) : (
                            <SectionContent padding="20px 40px">
                                <Row gutter={24} justify="center" type="flex">
                                    <Col xs={24}>
                                        <Card>
                                            <Result
                                                status="error"
                                                title="Error 403 - Access denied"
                                                subTitle={`You do not have permission to access ${tournament.name}.`}
                                                extra={[
                                                    <Link to="/tournament-builder" key="tournament-builder">
                                                        <Button type="primary" size="large">
                                                            Go to Tournament Builder
                                                        </Button>
                                                    </Link>,
                                                ]}
                                            />
                                        </Card>
                                    </Col>
                                </Row>
                            </SectionContent>
                        )}
                    </React.Fragment>
                );
            }}
        </Query>
    );
}

const authCondition = (authUser) => !!authUser;
const WithAuthorization = withAuthorization(authCondition)(TBDetail);
export default withRouter(WithAuthorization);
