import React, { useState, useEffect } from 'react';
import { withRouter, Link } from 'react-router-dom';
import CircularLoader from '../../../components/CircularLoader';
import { GET_TOURNAMENT_TABLE_QUERY, GET_TOURNAMENT_BY_SLUG, GET_TOURNAMENT_MATCH_GAMES_QUERY } from './data/queries';
import { GET_TOURNAMENT_TABLE_SUBSCRIPTION } from './data/subscriptions';
import { Query, Subscription } from '@apollo/client/react/components';
import EventScoreboard from './EventScoreboard';
import EventScoreboard1P from '../tournaments/EventScoreboard1P';
import moment from 'moment-timezone';
import momentDurationFormatSetup from 'moment-duration-format';
momentDurationFormatSetup(moment);

const styles = {
    container: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 20,
    },
};

function DPTournamentTableScoring(props) {
    const { client } = props;
    const { match, authState } = props;
    const params = match.params;
    const tournamentSlug = params.slug;
    const tableSlug = params.table;

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
                height: '100%',
                justifyContent: 'center',
                background: 'linear-gradient(180deg, #3E5267 0%, #1F3349 21%, #121A27 100%)',
                color: '#fff',
            }}
        >
            <Query
                query={GET_TOURNAMENT_BY_SLUG}
                fetchPolicy="cache-and-network"
                notifyOnNetworkStatusChange={true}
                variables={{ slug: tournamentSlug }}
                onCompleted={(data) => {
                    // var set = [];
                    // for (let i = 0; i < data.tournament_payouts.length; i++) {
                    // 	set.push(data.tournament_payouts[i]);
                    // }
                    // let formattedData = orderAndFormatData(set);
                    // setTableData(formattedData);
                }}
            >
                {({ loading, error, data, refetch }) => {
                    if (loading) return <CircularLoader />;
                    if (error) return <div>Error: {error.message}</div>;

                    const tournamentData = data.tournaments[0];
                    console.log(tournamentData);

                    if (tournamentData) {
                        return (
                            <Query
                                query={GET_TOURNAMENT_TABLE_QUERY}
                                fetchPolicy="cache-and-network"
                                notifyOnNetworkStatusChange={true}
                                variables={{ tournament_id: tournamentData.id, table_slug: tableSlug }}
                                onCompleted={(data, error, loading) => {
                                    console.log(data);
                                }}
                            >
                                {({ loading, error, data, refetch, fetchMore, subscribeToMore, networkStatus }) => {
                                    if (loading) return <CircularLoader />;
                                    if (error) return <div>Error: {error.message}</div>;
                                    if (data && !data.pool_tables) {
                                        return <div style={styles.container}>No tables found.</div>;
                                    }
                                    if (data && data.pool_tables.length === 0) {
                                        return <div style={styles.container}>No tables found.</div>;
                                    }

                                    const tableData = data.pool_tables && data.pool_tables[0];
                                    const matchData = tableData && tableData.tournament_match_table && tableData.tournament_match_table[0];

                                    if (!matchData) {
                                        return <div style={styles.container}>No table scores found</div>;
                                    }

                                    console.log(matchData);

                                    return (
                                        <React.Fragment>
                                            {tournamentData.game_type === 'ONE_POCKET' ? (
                                                <EventScoreboard1P
                                                    backUrl={`/tournaments/${tournamentSlug}/scores`}
                                                    tournamentData={tournamentData}
                                                    tableData={tableData}
                                                    matchData={{
                                                        ...matchData,
                                                        game_type: match.game_type || (tournamentData && tournamentData.game_type),
                                                        tournament: tournamentData,
                                                        tableSlug,
                                                    }}
                                                    subscribeToMore={subscribeToMore}
                                                    subscribeToNewData={() => {
                                                        subscribeToMore({
                                                            document: GET_TOURNAMENT_TABLE_SUBSCRIPTION,
                                                            variables: { tournament_id: tournamentData.id, table_slug: tableData.slug },
                                                            updateQuery: (prev, { subscriptionData }) => {
                                                                if (!subscriptionData.data) return prev;
                                                                const newTable = subscriptionData.data.pool_tables;
                                                                let updated;
                                                                if (prev) {
                                                                    updated = Object.assign({}, prev, {
                                                                        pool_tables: [...newTable],
                                                                    });
                                                                } else {
                                                                    updated = Object.assign({}, prev, {
                                                                        pool_tables: [...newTable],
                                                                    });
                                                                }
                                                                return updated;
                                                            },
                                                        });
                                                    }}
                                                    {...props}
                                                />
                                            ) : (
                                                <EventScoreboard
                                                    backUrl={`/tournaments/${tournamentSlug}/scores`}
                                                    tournamentData={tournamentData}
                                                    tableData={tableData}
                                                    matchData={{
                                                        ...matchData,
                                                        game_type: match.game_type || (tournamentData && tournamentData.game_type),
                                                        tournament: tournamentData,
                                                        tableSlug,
                                                    }}
                                                    subscribeToMore={subscribeToMore}
                                                    subscribeToNewData={() => {
                                                        subscribeToMore({
                                                            document: GET_TOURNAMENT_TABLE_SUBSCRIPTION,
                                                            variables: { tournament_id: tournamentData.id, table_slug: tableData.slug },
                                                            updateQuery: (prev, { subscriptionData }) => {
                                                                if (!subscriptionData.data) return prev;
                                                                const newTable = subscriptionData.data.pool_tables;
                                                                let updated;
                                                                if (prev) {
                                                                    updated = Object.assign({}, prev, {
                                                                        pool_tables: [...newTable],
                                                                    });
                                                                } else {
                                                                    updated = Object.assign({}, prev, {
                                                                        pool_tables: [...newTable],
                                                                    });
                                                                }
                                                                return updated;
                                                            },
                                                        });
                                                    }}
                                                    {...props}
                                                />
                                            )}
                                        </React.Fragment>
                                    );
                                }}
                            </Query>
                        );
                    } else {
                        return <div style={styles.container}>Tournament not found</div>;
                    }
                }}
            </Query>
        </div>
    );
}

export default withRouter(DPTournamentTableScoring);
