import React, { useState } from 'react';
import { Table, Typography, Row, Col, List, Avatar, Empty } from 'antd';
import SectionContent from '../../../components/SectionContent';
import SectionTitle from '../../../components/SectionTitle';
import SectionWrapper from '../../../components/SectionWrapper';
import Card, { Meta } from '../../../components/Card';
import { isoCountries } from '../../../utils/isoCountryCodes';
import blueBg from '../../../assets/img/blue-bg.png';
import { Query, Mutation } from '@apollo/client/react/components';
import CircleFlag from '../../../components/CircleFlag';
import Masonry from '../../../components/Masonry';
import ZeroState from '../../../components/ZeroState';
import CircularLoader from '../../../components/CircularLoader';
import { GET_LEAGUE_DIVISION_TEAMS_QUERY } from '../data/queries';

const { Title, Text } = Typography;

const styles = {
    headerImg: {
        height: 120,
        overflow: 'hidden',
        borderRadius: 0,
        background: `linear-gradient(rgba(61, 185, 248, 0.8), rgba(24, 144, 255, 0.1)), url(${blueBg}) 0px 0px no-repeat`,
        // backgroundAttachment: 'fixed',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100%',
        display: 'flex',
        alignItems: 'center',
        paddingLeft: 40,
        paddingRight: 40,
        // backgroundPosition: '0px -40px'
    },
    cardHeader: {
        paddingTop: 16,
        paddingBottom: 16,
        paddingLeft: 24,
        paddingRight: 24,
        background: 'linear-gradient(rgb(239, 243, 245), rgb(255, 255, 255))',
        borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
};

const masonryBreakPoints = [600, 960, 1280, 1920];

export default function DPDivisionTeams(props) {
    const { league, division } = props;

    return (
        <SectionWrapper>
            {/* <SectionTitle
				title="Teams"
				titleStyle={{ color: '#fff', margin: 0, textTransform: 'uppercase' }}
				height={120}
			/> */}
            <SectionContent padding="20px 40px">
                <Row
                    gutter={24}
                    justify="center"
                    type="flex"
                    // style={{ padding: 24, position: 'relative', marginTop: -120 }}
                >
                    <Col xs={24} sm={props.navType === 'desktop' ? 24 : 22}>
                        <Row gutter={24} type="flex" justify="center">
                            <Query
                                query={GET_LEAGUE_DIVISION_TEAMS_QUERY}
                                fetchPolicy="cache-and-network"
                                notifyOnNetworkStatusChange
                                awaitRefetchQueries
                                variables={{ league_id: league.id, division_id: division.id }}
                                onCompleted={(data) => {
                                    console.log(data);
                                }}
                            >
                                {({ loading, error, data }) => {
                                    if (loading) return <CircularLoader />;
                                    if (error) return <div style={styles.container}>Error: {error.message}</div>;
                                    if ((data && !data.league_teams) || (data && data.league_teams.length === 0)) {
                                        return (
                                            <Col xs={24}>
                                                <Card
                                                    bodyStyle={{ padding: 0 }}
                                                    // style={{
                                                    // 	width: '100%',
                                                    // 	borderRadius: 8,
                                                    // 	overflow: 'hidden',
                                                    // 	border: 'none',
                                                    // 	boxShadow: '0 2px 5px 0px rgba(0, 0, 0, 0.1)'
                                                    // }}
                                                >
                                                    <ZeroState showImage message="No Teams have been added yet" style={{ margin: 30 }} {...props} />
                                                </Card>
                                            </Col>
                                        );
                                    }

                                    return (
                                        <div className="masonry-container">
                                            <Masonry breakPoints={masonryBreakPoints}>
                                                {data.league_teams &&
                                                    data.league_teams.map((team, index) => {
                                                        return (
                                                            <Card
                                                                // title="4 Horsemen"
                                                                key={index}
                                                                cover={
                                                                    <div
                                                                        style={{
                                                                            paddingTop: 16,
                                                                            paddingBottom: 16,
                                                                            paddingLeft: 24,
                                                                            paddingRight: 24,
                                                                            borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
                                                                            display: 'flex',
                                                                            alignItems: 'center',
                                                                            justifyContent: 'space-between',
                                                                        }}
                                                                    >
                                                                        <Title
                                                                            level={4}
                                                                            style={{
                                                                                margin: 0,
                                                                                padding: 0,
                                                                                fontSize: 18,
                                                                            }}
                                                                        >
                                                                            {team.name}
                                                                        </Title>
                                                                        <div>
                                                                            <a href="#">TEAM PAGE</a>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                bodyStyle={{ padding: 0 }}
                                                            >
                                                                <List
                                                                    className="hover-list"
                                                                    size="small"
                                                                    itemLayout="horizontal"
                                                                    header={
                                                                        <div
                                                                            style={{
                                                                                padding: '0px 20px',
                                                                                margin: 0,
                                                                                display: 'flex',
                                                                                alignItems: 'center',
                                                                                justifyContent: 'space-between',
                                                                            }}
                                                                        >
                                                                            <Text>Name</Text>
                                                                            <Text>Skill Level</Text>
                                                                        </div>
                                                                    }
                                                                    dataSource={team.league_team_players}
                                                                    renderItem={(item, index) => {
                                                                        const player = item.league_player;

                                                                        return (
                                                                            <List.Item
                                                                                actions={[<div>{player.skill_level || '-'}</div>]}
                                                                                style={{ padding: '10px 16px' }}
                                                                            >
                                                                                <List.Item.Meta
                                                                                    avatar={
                                                                                        <React.Fragment>
                                                                                            {player.avatarImg ? (
                                                                                                <Avatar src={player.avatarImg} />
                                                                                            ) : player.country ? (
                                                                                                <CircleFlag country={player.country} left={0} />
                                                                                            ) : null}
                                                                                        </React.Fragment>
                                                                                    }
                                                                                    title={player.name}
                                                                                />
                                                                            </List.Item>
                                                                        );
                                                                    }}
                                                                />
                                                                <div
                                                                    style={{
                                                                        padding: '10px 18px',
                                                                        backgroundColor: '#fafafa',
                                                                        borderTop: '1px solid #e8e8e8',
                                                                    }}
                                                                >
                                                                    <div
                                                                        style={{
                                                                            display: 'flex',
                                                                            alignItems: 'center',
                                                                            justifyContent: 'space-between',
                                                                        }}
                                                                    >
                                                                        <div>Totals</div>
                                                                        <div>
                                                                            {team.league_team_players &&
                                                                                team.league_team_players
                                                                                    .map((item) => {
                                                                                        return item.league_player;
                                                                                    })
                                                                                    .reduce((acc, player) => {
                                                                                        return acc + player.skill_level;
                                                                                    }, 0)}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Card>
                                                        );
                                                    })}
                                            </Masonry>
                                        </div>
                                    );
                                }}
                            </Query>
                        </Row>
                    </Col>
                </Row>
            </SectionContent>
        </SectionWrapper>
    );
}
