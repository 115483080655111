import React from 'react';
import { Typography } from 'antd';

const { Text } = Typography;

const styles = {
    cellTitle: {
        width: 50,
        textAlign: 'center',
        fontSize: 12,
        lineHeight: '2em',
        color: 'rgba(255,255,255,0.7)',
    }    
};

export default function ScoringFooter(props) {
	const { show_ball_count, race_to } = props;

    return (
		<React.Fragment>
			<div style={{ backgroundColor: '#000000', display: 'flex', width: '100%' }}>
                <div style={{ flex: 1, paddingLeft: 5 }}>
                    <Text style={{ color: 'rgba(255,255,255,0.7)', fontSize: 12 }}>
                        Race to {race_to}
                    </Text>
                </div>

                {show_ball_count !== 'false' && <div style={styles.cellTitle}>Balls</div>}
                <div style={styles.cellTitle}>Games</div>
			</div>
		</React.Fragment>
	);
}
