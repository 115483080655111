import React, { useState, useEffect } from 'react';
import { message, Descriptions, Row, Icon, Col, Button, Empty, List, Avatar, Skeleton, Typography, Modal } from 'antd';
import Card, { Meta } from '../../../components/Card';
import tourneyFlyer from '../../../assets/img/flyers/poster-9.jpg';
import poolCuesImg from '../../../assets/img/rack-wallpaper2.jpg';
import blueBg from '../../../assets/img/blue-bg.png';
import rackWallpaper from '../../../assets/img/rack-wallpaper3.jpg';
import abstractBg from '../../../assets/img/abstract5.jpg';
// import worldCup from '../../../assets/img/world-cup.jpg';
import SectionContent from '../../../components/SectionContent';
import SectionTitle from '../../../components/SectionTitle';
import SectionWrapper from '../../../components/SectionWrapper';
import ZeroState from '../../../components/ZeroState';
import moment from 'moment';
import Leagues from '../Leagues';
import DPDivisionCard from './DPDivisionCard';
import DPLeaguePosts from './DPLeaguePosts';
import { getGameType } from '../../../utils';
import FeedPostList from '../../league-manager/feed/FeedPostList';
import defaultImg from '../../../assets/img/blue-bg.png';
import { GET_LEAGUE_INVITE } from '../data/queries';
import { Query } from '@apollo/client/react/components';
import { GET_LEAGUE_DIVISIONS_QUERY, GET_LEAGUE_PLAYER } from '../data/queries';
import qs from 'query-string';
import _ from 'lodash';

const { Title, Text } = Typography;

const styles = {
    headerImg: {
        height: 120,
        overflow: 'hidden',
        borderRadius: 0,
        border: 'none',
        background: `linear-gradient(rgba(61, 185, 248, 0.8), rgba(24, 144, 255, 0.1)), url(${blueBg}) 0px 0px no-repeat`,
        // backgroundAttachment: 'fixed',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100%',
        display: 'flex',
        alignItems: 'center',
        paddingLeft: 40,
        paddingRight: 40,
        // backgroundPosition: '0px -40px'
    },
};

export default function DPLeagueOverview(props) {
    const { league, authState, history, client, theme } = props;
    const [isLeagueMember, setIsLeagueMember] = useState();

    const params = qs.parse(props.location.search, { ignoreQueryPrefix: true });

    useEffect(() => {
        async function isLeagueMember() {
            const res = await client.query({
                query: GET_LEAGUE_PLAYER,
                variables: {
                    leagueId: league.id,
                    userId: authState.user.id,
                },
            });
            const isMember = res && res.data && res.data.league_players && res.data.league_players > 0;
            setIsLeagueMember(isMember);
            return isMember;
        }

        if (authState && authState.user) {
            isLeagueMember(authState.user.id);
        }
    }, [authState, client, league.id]);

    function popupLeagueInvite() {
        Modal.info({
            title: `League Invite`,
            content: <div>Thank you for accepting the invitation to join {props.league.name}. You are now signed up!</div>,
            centered: true,
            transitionName: 'fade',
            maskTransitionName: 'none',
            onOk() {},
            okText: 'Ok',
        });
    }

    function formatPhoneNumber(phoneNumberString) {
        var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
        var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            var intlCode = match[1] ? '+1 ' : '';
            return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
        }
        return null;
    }

    if (localStorage.getItem('joinedLeague') === 'true') {
        message.success('You have joined this league!');
        localStorage.setItem('joinedLeague', false);
    }

    return (
        <SectionWrapper>
            {/* <SectionTitle
				title="Overview"
				titleStyle={{ color: '#fff', margin: 0, textTransform: 'uppercase' }}
				height={120}
			/> */}
            <SectionContent padding="20px 40px">
                <Row gutter={16} justify="center" type="flex">
                    <Col xs={24} sm={22}>
                        <Row gutter={24} justify="center" type="flex">
                            <Col xs={24} sm={6} style={{ paddingTop: 0, paddingBottom: 0 }}>
                                <Row gutter={24} justify="center" type="flex">
                                    <Col xs={24}>
                                        <Card
                                            cover={
                                                <React.Fragment>
                                                    <div style={{ padding: 24 }}>
                                                        {league.logo ? (
                                                            <div
                                                                style={{
                                                                    minHeight: 160,
                                                                    borderRadius: 8,
                                                                    height: 'auto',
                                                                    overflow: 'hidden',
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                }}
                                                            >
                                                                <img src={league.logo} alt="Logo" style={{ width: '100%' }} />
                                                            </div>
                                                        ) : (
                                                            <div
                                                                style={{
                                                                    height: 160,
                                                                    borderRadius: 6,
                                                                    overflow: 'hidden',
                                                                    background: `linear-gradient(rgba(61, 185, 248, 0.8), rgba(24, 144, 255, 0.1)), url(${defaultImg})`,
                                                                    backgroundRepeat: 'no-repeat',
                                                                    backgroundSize: 'cover',
                                                                }}
                                                            />
                                                        )}
                                                    </div>
                                                </React.Fragment>
                                            }
                                            // actions={
                                            //     authState && authState.user && authState.user.id === league.owner_id
                                            //         ? [
                                            //               <div onClick={() => props.history.push(`/league-manager/${league.slug}/settings`)}>
                                            //                   <Icon type="setting" style={{ marginRight: 5 }} /> Settings
                                            //               </div>,
                                            //               <div onClick={() => props.history.push(`/league-manager/${league.slug}/edit`)}>
                                            //                   <Icon type="setting" style={{ marginRight: 5 }} /> Manage
                                            //               </div>,
                                            //           ]
                                            //         : []
                                            // }
                                        >
                                            <Meta
                                                title={<Title level={4}>{league.name}</Title>}
                                                description={
                                                    <div
                                                        style={{
                                                            whiteSpace: 'pre-wrap',
                                                        }}
                                                    >
                                                        {league.long_description ? league.long_description : league.short_description}
                                                    </div>
                                                }
                                            />

                                            {/* <Title level={4} style={{ marginBottom: 20 }}>
									League Information
								</Title> */}

                                            <Descriptions
                                                // title="League Details"
                                                size="small"
                                                column={1}
                                                bordered={false}
                                                layout="vertical"
                                                style={{ marginTop: 20 }}
                                            >
                                                {league.address1 ||
                                                    league.city ||
                                                    league.region ||
                                                    (league.postalCode && (
                                                        <Descriptions.Item label="Location">
                                                            <React.Fragment>
                                                                {league.address1}
                                                                {league.address2 && (
                                                                    <React.Fragment>
                                                                        <br />
                                                                        {league.address2}
                                                                    </React.Fragment>
                                                                )}
                                                                <br />
                                                                {league.city}, {league.region} {league.postalCode}
                                                                <br />
                                                                {league.country}
                                                            </React.Fragment>
                                                        </Descriptions.Item>
                                                    ))}

                                                {league.phone_number ||
                                                    league.email_address ||
                                                    (league.website && (
                                                        <Descriptions.Item label="Contact Info">
                                                            {formatPhoneNumber(league.phone_number)} <br />
                                                            <a href={`mailto:${league.email_address}`} target="_blank" rel="noopener noreferrer">
                                                                {league.email_address}
                                                            </a>{' '}
                                                            <br />
                                                            <a href={league.website} target="_blank" rel="noopener noreferrer">
                                                                {league.website}
                                                            </a>
                                                        </Descriptions.Item>
                                                    ))}

                                                <Descriptions.Item label="League Operator">
                                                    {league.operator && `${league.operator.first_name} ${league.operator.last_name}`}
                                                </Descriptions.Item>

                                                <Descriptions.Item label="Total Players">
                                                    {league.league_players_aggregate.aggregate.count || 0}
                                                </Descriptions.Item>
                                            </Descriptions>
                                        </Card>
                                    </Col>
                                    {league.organization && (
                                        <Col xs={24}>
                                            <Card title="Organization" bodyStyle={{ padding: 0 }}>
                                                <List
                                                    className="hover-list"
                                                    size="small"
                                                    itemLayout="horizontal"
                                                    dataSource={[league.organization]}
                                                    renderItem={(item) => (
                                                        <List.Item
                                                            // actions={[ <a key="list-loadmore-edit">View</a> ]}
                                                            style={{ padding: '10px 16px' }}
                                                            onClick={() => history.push(`/organizations/${item.slug}`)}
                                                        >
                                                            <List.Item.Meta
                                                                avatar={
                                                                    <div>
                                                                        <Icon
                                                                            type="bank"
                                                                            style={{
                                                                                color: '#1890ff',
                                                                                fontSize: 30,
                                                                            }}
                                                                        />
                                                                    </div>
                                                                }
                                                                title={
                                                                    <Text
                                                                        style={{
                                                                            fontSize: 16,
                                                                        }}
                                                                    >
                                                                        {item.name}
                                                                    </Text>
                                                                }
                                                                description={<div>{item.short_description}</div>}
                                                            />
                                                        </List.Item>
                                                    )}
                                                />
                                            </Card>
                                        </Col>
                                    )}
                                    {league.has_division_support === true && (
                                        <Col xs={24}>
                                            <Card title="Divisions" bodyStyle={{ padding: 0 }}>
                                                <Query
                                                    query={GET_LEAGUE_DIVISIONS_QUERY}
                                                    fetchPolicy="cache-and-network"
                                                    notifyOnNetworkStatusChange={true}
                                                    variables={{ leagueId: league.id }}
                                                    onCompleted={async (data) => {
                                                        console.log(data);
                                                    }}
                                                >
                                                    {({ loading, error, data }) => {
                                                        if (loading) return <div>Loading...</div>;
                                                        if (error) return <div>Error: {error.message}</div>;
                                                        if (!loading && data && !data.league_divisions) {
                                                            return (
                                                                <ZeroState
                                                                    showImage
                                                                    message="You have not added any divisions yet."
                                                                    style={{ margin: 30 }}
                                                                    {...props}
                                                                >
                                                                    <Button>Create New Division</Button>
                                                                </ZeroState>
                                                            );
                                                        }
                                                        return (
                                                            <List
                                                                className="hover-list"
                                                                size="small"
                                                                loading={loading}
                                                                itemLayout="horizontal"
                                                                dataSource={data.league_divisions}
                                                                renderItem={(item) => (
                                                                    <List.Item
                                                                        // actions={[ <a key="list-loadmore-edit">View</a> ]}
                                                                        style={{ padding: '10px 16px' }}
                                                                        onClick={() => props.history.push(`/leagues/${league.slug}/divisions/${item.slug}`)}
                                                                    >
                                                                        <Skeleton avatar title={false} loading={loading} active>
                                                                            <List.Item.Meta
                                                                                avatar={
                                                                                    <React.Fragment>
                                                                                        {item.avatar ? (
                                                                                            <Avatar size={30} src={item.avatar} />
                                                                                        ) : item.game_types ? (
                                                                                            <Avatar
                                                                                                size={30}
                                                                                                src={getGameType(item.game_types[0].game_type, theme)}
                                                                                            />
                                                                                        ) : (
                                                                                            <Avatar size={30} src={defaultImg} />
                                                                                        )}
                                                                                    </React.Fragment>
                                                                                }
                                                                                title={
                                                                                    item.deleted_at
                                                                                        ? `${item.name} (Deleted ${moment(item.deleted_at).format('ll')})`
                                                                                        : item.name
                                                                                }
                                                                                description={item.short_description}
                                                                            />
                                                                        </Skeleton>
                                                                    </List.Item>
                                                                )}
                                                            />
                                                        );
                                                    }}
                                                </Query>
                                            </Card>
                                        </Col>
                                    )}
                                </Row>
                            </Col>

                            <Col xs={24} sm={18}>
                                <FeedPostList league={league} {...props} />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </SectionContent>
        </SectionWrapper>
    );
}
