import React, { useState } from 'react';
import { Link, withRouter, Route, Switch } from 'react-router-dom';
import NewsFeed from './feed/NewsFeed';
import SectionHeader from '../../components/SectionHeader';
import SectionContent from '../../components/SectionContent';
import SectionTitle from '../../components/SectionTitle';
import { Tabs, Col, Row, Statistic, Progress, Avatar, Form, Icon, Input, Pagination, Button, Empty, Typography, Select } from 'antd';
import Card, { Meta } from '../../components/Card';
import Masonry from '../../components/Masonry';
import { getCountryName, formatPlayerName, displayEnumType, getGameType, getOrdinalSuffix } from '../../utils/';
import { GET_TOURNAMENT_LIST, SEARCH_TOURNAMENTS_QUERY } from './tournaments/data/queries';
import { Query } from '@apollo/client/react/components';
import ZeroState from '../../components/ZeroState';
import TournamentStagePicker from './TournamentStagePicker';
import TournamentsTable from './tournaments/TournamentsTable';
import CircularLoader from '../../components/CircularLoader';
import {
    CheckCircleOutlined,
    ClockCircleOutlined,
    DollarOutlined,
    UserOutlined,
    TeamOutlined,
    TrophyOutlined,
    CalendarOutlined,
    EnvironmentOutlined,
} from '@ant-design/icons';
import { useQuery } from '@apollo/react-hooks';
import moment from 'moment';
import { TrophyFilled } from '@ant-design/icons';
import useBreakpoint from 'use-breakpoint';
import { defaultBreakpoints } from '../../utils/defaultBreakpoints';
import Highlighter from 'react-highlight-words';
import AdZoneTemplate from '../../components/advertising/AdZoneTemplate';
import defaultImg from '../../assets/img/blue-bg.png';
import poolHall from '../../assets/img/pool-hall13.jpg';
import flyerExample from '../../assets/img/dfw-9ball-tour.jpg';
import tournamentThumb from '../../assets/img/tournament-thumb.png';
import tournamentThumb8Ball from '../../assets/img/tournament-thumb-8ball.png';
import tournamentThumb9Ball from '../../assets/img/tournament-thumb-9ball.png';
import tournamentThumb10Ball from '../../assets/img/tournament-thumb-10ball.png';
import tournamentThumbOnePocket from '../../assets/img/tournament-thumb-one-pocket.png';
import SearchFilterTournaments from '../../screens/digitalpool/tournaments/SearchFilterTournaments';
// import * as results from './tournaments/data/tournament-results-2020.json';
import numeral from 'numeral';

const { TabPane } = Tabs;
const { Title, Text } = Typography;
const { Option } = Select;
const { Search } = Input;

const masonryBreakPoints = [600, 960, 1280, 1920];

const styles = {
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
    },
    tab: {
        paddingLeft: 4,
        paddingRight: 4,
    },
    filters: {
        padding: '0px 40px',
        // marginBottom: 10,
        background: 'linear-gradient(#fff, #fafafa)',
        boxShadow: 'rgba(0, 0, 0, 0.05) 0px 4px 6px 0px',
        borderTop: '1px solid #e8e8e8',
        position: 'relative',
    },
};

function createMarkup(markup) {
    return { __html: markup };
}

export default function Tournaments(props) {
    const { authState, signOut, client, theme } = props;
    const { breakpoint, maxWidth, minWidth } = useBreakpoint(defaultBreakpoints);
    const [filters, setFilters] = useState({
        gameType: 'all',
    });
    const [searchValue, setSearchValue] = useState();
    const [searching, setSearching] = useState(false);
    const [searchResults, setSearchResults] = useState([]);
    const defaultToggle = localStorage.getItem('digitalpool__tournaments_viewToggle');
    const [viewToggle, setViewToggle] = useState(defaultToggle || 'grid');
    const defaultLimit = 30;
    const [limit, setLimit] = useState(defaultLimit);

    // let tourneys = [];
    // const formattedResults = results.default.data.tournaments.map((tournament) => {
    // 	tournament.tournament_players.map((player) => {
    // 		tourneys.push({
    // 			event: tournament.name,
    // 			player_name: player.name,
    // 			place: player.place
    // 		});
    // 	});
    // });

    // console.log('2020 RESULTS', tourneys);

    // const { loading, error, data } = useQuery(GET_TOURNAMENT_LIST);

    function handleSubmit(values, actions) {
        actions.setSubmitting(false);
        actions.resetForm();
        console.log('Received values of form: ', values);
    }

    function handleShowMore() {
        setLimit(limit + defaultLimit);
    }

    async function handleTournamentSearch(value) {
        setSearchValue(value);
        if (value) {
            setSearching(true);
            const res = await client.query({
                query: SEARCH_TOURNAMENTS_QUERY,
                variables: {
                    // name: `%${encodeURI(value)}%`,
                    name: value,
                },
            });
            setSearching(false);
            console.log(res);
            const tournaments = res.data.search_tournaments;
            console.log('search results', tournaments);
            setSearchResults(tournaments);
        }
    }

    function handleGameTypeChange(value) {
        console.log(value);
        setFilters({
            ...filters,
            gameType: value,
        });
    }

    function handleStatusChange(value) {
        console.log(value);
        setFilters({
            ...filters,
            status: value,
        });
    }

    function gameTypeFilter(tournament) {
        const filter = filters.gameType;
        if (filter && filter !== 'all') {
            return tournament.game_type === filter;
        } else {
            return tournament;
        }
    }

    function statusFilter(tournament) {
        const filter = filters.status;
        if (filter && filter !== 'all') {
            return tournament.status === filter;
        } else {
            return tournament;
        }
    }

    function handleViewToggle(value) {
        localStorage.setItem('digitalpool__tournaments_viewToggle', value);
        setViewToggle(value);
    }

    function renderTournamentData(tournaments) {
        if (searching) {
            return (
                <div style={styles.container}>
                    <CircularLoader />
                    <Text style={{ marginLeft: 10 }}>Searching...</Text>
                </div>
            );
        } else if (!tournaments || tournaments.length === 0) {
            return (
                <ZeroState showImage message="No tournaments found." style={{ margin: 30 }} {...props}>
                    <Link to="/tournament-builder/new">
                        <Button type="primary">Create Tournament</Button>
                    </Link>
                </ZeroState>
            );
        } else {
            return (
                <React.Fragment>
                    <div className="masonry-container">
                        <Masonry breakPoints={masonryBreakPoints}>
                            {tournaments.slice(0, limit).map((item, index) => {
                                let duration;
                                if (item.start_date_time && item.end_date_time && item.status === 'COMPLETED') {
                                    var start = moment(item.start_date_time);
                                    var end = moment(item.end_date_time);

                                    duration = moment.duration(end.diff(start, 'milliseconds')).format('h [hrs] m [min]');

                                    // moment.duration(moment.utc().diff(match.start_time, 'milliseconds')).format('h [hrs] m [min]');
                                } else {
                                    duration = moment.duration(moment.utc().diff(item.start_date_time, 'milliseconds')).format('h [hours] m [minutes]');
                                }

                                const totalPayouts =
                                    item.tournament_payouts_aggregate &&
                                    item.tournament_payouts_aggregate.aggregate.sum &&
                                    item.tournament_payouts_aggregate.aggregate.sum.money + item.tournament_payouts_aggregate.aggregate.sum.calcutta;

                                return (
                                    <Card
                                        hoverable
                                        key={index}
                                        // style={{
                                        //     width: '100%',
                                        //     minWidth: 300,
                                        //     borderRadius: '8px',
                                        //     overflow: 'hidden',
                                        //     border: 'none',
                                        //     boxShadow: '0 8px 20px 0 rgba(218,224,235,.6)',
                                        //     height: '100%',
                                        //     transition: 'all 150ms cubic-bezier(0.4, 0.0, 0.2, 1)',
                                        // }}

                                        cover={
                                            <div>
                                                <div>
                                                    <img
                                                        alt="example"
                                                        src={
                                                            item.flyer
                                                                ? item.flyer
                                                                : item.game_type === 'NINE_BALL'
                                                                ? tournamentThumb9Ball
                                                                : item.game_type === 'EIGHT_BALL'
                                                                ? tournamentThumb8Ball
                                                                : item.game_type === 'TEN_BALL'
                                                                ? tournamentThumb10Ball
                                                                : item.game_type === 'ONE_POCKET'
                                                                ? tournamentThumbOnePocket
                                                                : tournamentThumb
                                                        }
                                                        style={{ width: '100%' }}
                                                    />
                                                    <div style={{ position: 'relative' }}>
                                                        <TournamentStagePicker tournament={item} {...props} />
                                                    </div>
                                                </div>

                                                {item.is_featured === true && (
                                                    <div
                                                        style={{
                                                            backgroundColor: '#dc1717',
                                                            color: '#fff',
                                                            paddingLeft: 10,
                                                            paddingRight: 10,
                                                            paddingTop: 5,
                                                            paddingBottom: 5,
                                                            position: 'absolute',
                                                            // width: '100%',
                                                            right: 0,
                                                            bottom: 0,
                                                            fontWeight: 700,
                                                            display: 'inline-block',
                                                            textTransform: 'uppercase',
                                                            letterSpacing: 2,
                                                        }}
                                                    >
                                                        Featured
                                                    </div>
                                                )}
                                            </div>
                                        }
                                        onClick={() => {
                                            props.history.push(`/tournaments/${item.slug}`);
                                        }}
                                    >
                                        <Meta
                                            avatar={
                                                <div>
                                                    {item.status === 'IN_PROGRESS' ? (
                                                        <ClockCircleOutlined
                                                            style={{
                                                                fontSize: 20,
                                                                marginTop: 5,
                                                                color: '#1890ff',
                                                            }}
                                                        />
                                                    ) : item.status === 'NOT_STARTED' ? (
                                                        <ClockCircleOutlined
                                                            style={{
                                                                fontSize: 20,
                                                                marginTop: 5,
                                                                color: theme && theme.name === 'dark' ? 'rgba(255,255,255, 0.45)' : 'rgba(0, 0, 0, 0.45)',
                                                            }}
                                                        />
                                                    ) : (
                                                        <CheckCircleOutlined
                                                            style={{
                                                                fontSize: 20,
                                                                marginTop: 5,
                                                                color: '#3ebb00',
                                                            }}
                                                        />
                                                    )}
                                                </div>
                                            }
                                            // avatar={
                                            // 	<div>
                                            // 		{getGameType(item.game_type) && (
                                            // 			<Avatar
                                            // 				size={28}
                                            // 				src={getGameType(item.game_type)}
                                            // 			/>
                                            // 		)}
                                            // 	</div>
                                            // }
                                            title={
                                                <Title level={3} style={{ margin: 0 }}>
                                                    <Highlighter
                                                        highlightClassName="highlighted-text"
                                                        searchWords={[searchValue]}
                                                        autoEscape={true}
                                                        textToHighlight={item.name}
                                                    />
                                                </Title>
                                            }
                                            description={
                                                <div
                                                    style={{
                                                        maxHeight: 300,
                                                        overflowY: 'auto',
                                                    }}
                                                >
                                                    {item.progress > 0 && item.progress < 100 && (
                                                        <div
                                                            style={{
                                                                marginTop: 5,
                                                                marginBottom: 5,
                                                            }}
                                                        >
                                                            <div>
                                                                <Statistic
                                                                    value={item.progress ? parseInt(item.progress).toFixed(0) : 0}
                                                                    valueStyle={{
                                                                        fontSize: 20,
                                                                    }}
                                                                    suffix={`% Complete`}
                                                                />
                                                                <Progress
                                                                    percent={parseInt(item.progress)}
                                                                    status={
                                                                        parseInt(item.progress) < 100
                                                                            ? 'active'
                                                                            : parseInt(item.progress) === 100
                                                                            ? 'success'
                                                                            : 'normal'
                                                                    }
                                                                    size="small"
                                                                    showInfo={false}
                                                                />
                                                            </div>
                                                        </div>
                                                    )}

                                                    {item.status === 'COMPLETED' && (
                                                        <div>
                                                            {item.tournament_players &&
                                                                item.tournament_players.map((player, index) => {
                                                                    const isSplit = item.tournament_players.filter((player) => {
                                                                        return player.place === '1';
                                                                    });
                                                                    return (
                                                                        <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
                                                                            <div>
                                                                                {player.place === '1' ? (
                                                                                    <TrophyFilled
                                                                                        style={{
                                                                                            color: '#eab700',
                                                                                            marginRight: 5,
                                                                                        }}
                                                                                    />
                                                                                ) : player.place === '2' ? (
                                                                                    <TrophyFilled
                                                                                        style={{
                                                                                            color: '#9baab1',
                                                                                            marginRight: 5,
                                                                                        }}
                                                                                    />
                                                                                ) : (
                                                                                    player.place === '3' && (
                                                                                        <TrophyFilled
                                                                                            style={{
                                                                                                color: '#f19921',
                                                                                                marginRight: 5,
                                                                                            }}
                                                                                        />
                                                                                    )
                                                                                )}
                                                                            </div>
                                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                                <Text
                                                                                    style={{
                                                                                        marginLeft: 0,
                                                                                        width: '100%',
                                                                                        whiteSpace: 'nowrap',
                                                                                        overflow: 'hidden',
                                                                                        textOverflow: 'ellipsis',
                                                                                    }}
                                                                                >
                                                                                    {formatPlayerName(player)}{' '}
                                                                                    {player.skill_level ? `(${player.skill_level})` : null} -{' '}
                                                                                    {player.place
                                                                                        ? `${getOrdinalSuffix(player.place)} ${
                                                                                              player.place === '1' && isSplit.length > 1 ? '(split)' : ''
                                                                                          }`
                                                                                        : '-'}
                                                                                </Text>
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                })}
                                                        </div>
                                                    )}

                                                    <div style={{ marginTop: 14 }}>
                                                        <CalendarOutlined /> <Text>{moment(item.start_date_time).format('MMMM Do YYYY')}</Text>
                                                        <br />
                                                        <TeamOutlined /> <Text>{item.tournament_players_aggregate.aggregate.count} Players</Text> -{' '}
                                                        {displayEnumType(item.tournament_type)}
                                                        {item.venue && (
                                                            <React.Fragment>
                                                                <br />
                                                                <EnvironmentOutlined />{' '}
                                                                <Text>
                                                                    <Highlighter
                                                                        highlightClassName="highlighted-text"
                                                                        searchWords={[searchValue]}
                                                                        autoEscape={true}
                                                                        textToHighlight={`${item.venue.name} -
                                                                            ${item.venue.city}, 
                                                                            ${item.venue.region}`}
                                                                    />
                                                                </Text>
                                                            </React.Fragment>
                                                        )}
                                                        <br />
                                                        <UserOutlined />{' '}
                                                        <Text>
                                                            <Highlighter
                                                                highlightClassName="highlighted-text"
                                                                searchWords={[searchValue]}
                                                                autoEscape={true}
                                                                textToHighlight={`${item.director && item.director.first_name} ${
                                                                    item.director && item.director.last_name
                                                                }`}
                                                            />
                                                        </Text>{' '}
                                                        - Director
                                                        {totalPayouts > 0 && (
                                                            <React.Fragment>
                                                                <br />
                                                                <DollarOutlined /> <Text>{numeral(totalPayouts).format('$0,0')}</Text> - Payouts
                                                            </React.Fragment>
                                                        )}
                                                        <br />
                                                        <Avatar
                                                            size={18}
                                                            src={getGameType(item.game_type, theme)}
                                                            style={{
                                                                marginLeft: -1,
                                                                position: 'relative',
                                                            }}
                                                        />{' '}
                                                        <Text>
                                                            <Highlighter
                                                                highlightClassName="highlighted-text"
                                                                searchWords={[searchValue]}
                                                                autoEscape={true}
                                                                textToHighlight={displayEnumType(item.game_type)}
                                                            />
                                                        </Text>
                                                        - Race to {item.winners_race_to}
                                                    </div>

                                                    <div
                                                        style={{
                                                            marginTop: 15,
                                                            color: theme.name === 'dark' ? '#fff' : 'rgba(0, 0, 0, 0.85)',
                                                        }}
                                                        className="display-linebreak"
                                                        dangerouslySetInnerHTML={createMarkup(item.description)}
                                                    />
                                                </div>
                                            }
                                        />
                                    </Card>
                                );
                            })}
                        </Masonry>
                        {limit && tournaments.length > limit && (
                            <div
                                style={{
                                    marginTop: 20,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <Button size="large" type="primary" onClick={handleShowMore}>
                                    Show more
                                </Button>
                            </div>
                        )}
                    </div>
                </React.Fragment>
            );
        }
    }

    function renderTournamentTable(tournaments) {
        if (searching) {
            return (
                <div style={styles.container}>
                    <CircularLoader />
                    <Text style={{ marginLeft: 10 }}>Searching...</Text>
                </div>
            );
        } else if (!tournaments || tournaments.length === 0) {
            return (
                <ZeroState showImage message="No tournaments found." style={{ margin: 30 }} {...props}>
                    <Link to="/tournament-builder/new">
                        <Button type="primary">Create Tournament</Button>
                    </Link>
                </ZeroState>
            );
        } else {
            return (
                <TournamentsTable
                    limit={limit}
                    defaultLimit={defaultLimit}
                    onShowMore={handleShowMore}
                    totalTournaments={tournaments.length}
                    tournaments={tournaments.sort((a, b) => b.updated_at && b.updated_at.localeCompare(a.updated_at)).slice(0, limit)}
                    {...props}
                />
            );
        }
    }

    return (
        <React.Fragment>
            <SectionHeader
                title={props.title}
                titleStyle={{
                    color: '#fff',
                    textTransform: 'uppercase',
                    margin: 0,
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
                subtitle={breakpoint !== 'mobile' && 'Everything you need to run pool tournaments more efficiently.'}
                subtitleStyle={{ color: '#fff' }}
                height={breakpoint === 'mobile' ? 80 : 100}
                theme="digitalpool"
            >
                <Link to={authState && authState.status === 'in' ? '/tournament-builder/new' : '/login'}>
                    {breakpoint === 'mobile' ? (
                        <Button size="large" type="ghost" ghost>
                            <Icon type="plus" />
                        </Button>
                    ) : (
                        <Button size="large" type="ghost" ghost>
                            <Icon type="plus" /> Create Tournament
                        </Button>
                    )}
                </Link>
            </SectionHeader>

            <SectionContent padding="20px 40px">
                <Query
                    query={GET_TOURNAMENT_LIST}
                    fetchPolicy="cache-and-network"
                    notifyOnNetworkStatusChange={true}
                    // variables={{ limit: 15 }}
                    onCompleted={async (data) => {
                        console.log(data);
                        // const tournaments = data.tournaments;
                        // setTournaments(tournaments);
                        // const { tournament_brackets } = data;
                        // setBracket(tournament_brackets);
                    }}
                >
                    {({ loading, error, data, networkStatus }) => {
                        if (networkStatus === 1 || loading) {
                            return (
                                <div style={styles.container}>
                                    <CircularLoader />
                                    <Text style={{ marginLeft: 10 }}>Loading...</Text>
                                </div>
                            );
                        }
                        if (loading)
                            return (
                                <div style={styles.container}>
                                    <CircularLoader />
                                </div>
                            );
                        if (error) return <div style={styles.container}>Error: {error.message}</div>;
                        if ((data && !data.tournaments) || (data && data.tournaments.length === 0)) {
                            return (
                                <div style={styles.container}>
                                    <ZeroState showImage message="No tournaments found." style={{ margin: 30 }} {...props}>
                                        <Link to="/tournament-builder/new">
                                            <Button type="primary">Create Tournament</Button>
                                        </Link>
                                    </ZeroState>
                                </div>
                            );
                        }

                        let filteredTournaments;
                        if (searchResults && searchValue) {
                            filteredTournaments = searchResults.filter(gameTypeFilter).filter(statusFilter);
                        } else {
                            filteredTournaments = data.tournaments.filter(gameTypeFilter).filter(statusFilter);
                        }

                        const featuredTournaments = filteredTournaments.sort((a, b) => (a.is_featured > b.is_featured ? -1 : 1));

                        return (
                            <React.Fragment>
                                <Row gutter={16} justify="center" type="flex">
                                    <Col xs={24}>
                                        <AdZoneTemplate device={breakpoint || 'desktop'} {...props} />
                                    </Col>
                                    <Col xs={24}>
                                        <Card
                                            bodyStyle={{
                                                // background: 'linear-gradient( #fff, #fafafa)',
                                                boxShadow: 'rgba(0, 0, 0, 0.05) 0px 4px 6px 0px',
                                                padding: '10px 20px',
                                                borderRadius: 10,
                                                overflow: 'hidden',
                                            }}
                                        >
                                            <SearchFilterTournaments
                                                searching={searching}
                                                tournaments={searchResults && searchValue ? searchResults : data.tournaments}
                                                onSearchTournament={handleTournamentSearch}
                                                onGameTypeChange={handleGameTypeChange}
                                                onStatusChange={handleStatusChange}
                                                viewToggle={viewToggle}
                                                onViewToggle={handleViewToggle}
                                                {...props}
                                            />
                                        </Card>
                                    </Col>

                                    <Col xs={24}>
                                        {viewToggle === 'list' ? renderTournamentTable(featuredTournaments) : renderTournamentData(featuredTournaments)}
                                    </Col>
                                </Row>
                            </React.Fragment>
                        );
                    }}
                </Query>
            </SectionContent>
        </React.Fragment>
    );
}

// let date = new Date(item.start_date_time);

// var month = [];
// month[0] = 'January';
// month[1] = 'February';
// month[2] = 'March';
// month[3] = 'April';
// month[4] = 'May';
// month[5] = 'June';
// month[6] = 'July';
// month[7] = 'August';
// month[8] = 'September';
// month[9] = 'October';
// month[10] = 'November';
// month[11] = 'December';
// let m = month[date.getMonth()];
// let day = date.getUTCDay();
// let year = date.getUTCFullYear();
