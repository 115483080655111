import React, { useState } from 'react';
import { Icon, Row, Col, Typography, Button, Input, Tabs, Empty } from 'antd';
import Card from '../../components/Card';
import { Link } from 'react-router-dom';
import SectionTitle from '../../components/SectionTitle';
import SectionContent from '../../components/SectionContent';

const { Text } = Typography;

const styles = {
	row: {
		display: 'flex',
		flexGrow: 1
	},
	tab: {
		paddingTop: 5,
		paddingLeft: 24,
		paddingRight: 24,
		paddingBottom: 24
	}
};

export default function DPLVenues(props) {
	return (
		<div style={{ background: 'linear-gradient(#eaedef, #fafafa)' }}>
			<SectionTitle
				title={props.title}
				titleStyle={{ color: '#fff', margin: 0, textTransform: 'uppercase' }}
				height={120}
			>
				<Link to={`/dpl/venues/new`}>
					<Button size="large" type="ghost" ghost>
						Add Venue
						<Icon type="arrow-right" />
					</Button>
				</Link>
			</SectionTitle>

			<SectionContent padding="20px 40px">
				<Row gutter={16} justify="center" type="flex">
					<Col xs={24}>
						<Card title="All Venues">
							<Empty
								image="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original"
								imageStyle={{
									height: 100
								}}
								description={<Text style={{ fontSize: 18 }}>There are no venues yet.</Text>}
								style={{ margin: 10 }}
							>
								<Button>Add A Venue</Button>
							</Empty>
						</Card>
					</Col>
				</Row>
			</SectionContent>
		</div>
	);
}
