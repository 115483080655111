import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { message, PageHeader, Icon, Row, Modal, Col, Typography, Button, Descriptions, Empty } from 'antd';
import Card from '../../../components/Card';
import SectionWrapper from '../../../components/SectionWrapper';
import SectionHeader from '../../../components/SectionHeader';
import SectionContent from '../../../components/SectionContent';
import axios from 'axios';
import qs from 'querystring';
import apiEndPoint from '../../../utils/apiEndpoint';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { CREATE_VENUE_MUTATION } from './data/mutations';
import slugify from 'slugify';

const { Title, Text } = Typography;

// curl -X GET "https://c2cre726.caspio.com/rest/v2/tables/GO_rooms/records?q.limit=50" -H  "accept: application/json" -H  "Authorization: bearer USN6kuSQUUDn5vH_gSvxOpfFCbU7aTC9DCaATJOcP0x0QoJzF9Z1DKJR9BZgX00S52OO5TX51jFMnr5Xw93XsdXzAGyI9DyjmIu8UemhO9Hyw7JFIKhNm5cVl-OMVTfybp9YqNxzg5RMq4gSAjO-RSfFrEqIs5scMvL4o7QNvdUMpUsQze90BZNtsnOf7Q1Of5rRDvFz94FY2cNm6PQGqDV6QLnxk6lRzL4W6KBgIgrqW9pXgjtcrixBRA4MeeI5GPjr1s14vy3ELkfRy4BAiviKxwQKwZ4RbEXkZq8IjeQnC0KntMk4FSAIvPxpRu2qj2JbvbEEsFkEbQxfLdT7NONjTNoYh7NpQ4ytUyzQT35aiMzyrVUNayW6dEmN6Zl6"
function DPTournamentMap(props) {
    const { authState } = props;
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(100);
    const [token, setToken] = useState(JSON.parse(localStorage.getItem('caspio-auth')));
    const [venues, setVenues] = useState([]);
    const [createVenue] = useMutation(CREATE_VENUE_MUTATION);

    useEffect(() => {
        if (token && token.access_token) {
            getVenues(token.access_token);
            // getRoomsCanada(token.access_token);
            // getFlyers(token.access_token);
        } else {
            getAuthToken();
        }
    }, [token, currentPage]);

    // "Admin_login",
    // "Admin_login_kamui",
    // "Admin_login_reps",
    // "cb_ErrorLog",
    // "Dropdown_days_of_the_week",
    // "Dropdown_discount_types",
    // "Dropdown_PAYMENT_options",
    // "Dropdown_STATES_options",
    // "Dropdown_STATES_options_canada",
    // "Dropdown_ZIP_options",
    // "Emails_Users",
    // "GO_discounts",
    // "GO_discounts_TEMP",
    // "GO_flyers",
    // "GO_flyers_CaspioTest",
    // "GO_rooms",
    // "GO_rooms_canada",
    // "Installers",
    // "Partners"

    function getAuthToken() {
        return axios
            .post(
                'https://c2cre726.caspio.com/oauth/token',
                qs.stringify({
                    grant_type: 'client_credentials',
                    client_id: process.env.REACT_APP_CASPIO_CLIENT_ID,
                    client_secret: process.env.REACT_APP_CASPIO_CLIENT_SECRET,
                }),
                {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                }
            )
            .then((res) => {
                console.log('caspio auth token', res);
                const tokenData = res.data;
                localStorage.setItem('caspio-auth', JSON.stringify(tokenData));
                setToken(tokenData);
                return res;
            })
            .catch((error) => {
                console.log('caspio auth error', error);
                throw error;
            });
    }

    function getVenues(authToken) {
        // const token =
        //     'F9zJ0h9ieJK2gewdQ6djtmu6V7MVB5CrT49_L405RmDNJHSL_ykUgWPKUJwsCYvjuKnYFqgsOJ7w4j_ZZBdUX0MMD3R_Cfs4ulJAKZP_5YsSUkCmMfWZCLIYKuAVzn27Ad3sXysEdsaYYqIZ-w3zo3T_kOuzxSFeHpfqbMlkmrHhxoiWNThmaWlQotLLyK6SwhzgxhsLW0a4SMCR0Xe5eup7fv3Q7hIrdPqKqXcOzrU1ATAZLZMQOKvBkMh3nPT1TmpZFvFfinz08Z8FY634Tb3IPCSXeBLFQv-WP-YL4K-ez_mP1MohIVDWB556pcDQ2e3Vqqmufl5lp0OFO4llgN3rn2ov3l_eY5T92LeQ4mMIcZp7cHWmXLPGes2GyqoB';
        return axios
            .get(
                `https://c2cre726.caspio.com/rest/v2/tables/GO_rooms/records?q.pageNumber=${currentPage}&q.pageSize=${pageSize}&q.orderBy=room_name&q.tourneys=true`,
                {
                    headers: {
                        Authorization: `bearer ${authToken}`,
                    },
                }
            )
            .then((res) => {
                const venues = res && res.data && res.data.Result;
                console.log('venues', venues);
                setVenues(venues);
                return res;
            })
            .catch((error) => {
                const message = error.message;
                if (message.includes('401')) {
                    getAuthToken();
                }
                console.log(error);
                throw error;
            });
    }

    function getRoomsCanada(authToken) {
        return axios
            .get('https://c2cre726.caspio.com/rest/v2/tables/GO_rooms_canada/records?q.limit=100', {
                headers: {
                    Authorization: `bearer ${authToken}`,
                },
            })
            .then((res) => {
                console.log('rooms canada', res);
                return res;
            })
            .catch((error) => {
                console.log(error);
                throw error;
            });
    }

    function getFlyers(authToken) {
        return axios
            .get('https://c2cre726.caspio.com/rest/v2/tables/GO_flyers/records?q.limit=100', {
                headers: {
                    Authorization: `bearer ${authToken}`,
                },
            })
            .then((res) => {
                console.log('flyers', res);
                return res;
            })
            .catch((error) => {
                console.log(error);
                throw error;
            });
    }

    function saveRecords(venues) {
        if (venues && venues.length > 0) {
            venues.forEach(async (venue, index) => {
                message.loading(`Venue ${index + 1} of ${venues.length} added`);
                await insertVenue(venue);
            });
        }
    }

    function geocodeAddress(values) {
        return axios
            .post(`${apiEndPoint}mapquestGeocodeAddress`, { street: values.address1, city: values.city, state: values.region, postalCode: values.postal_code })
            .then((res) => {
                const result = res && res.data && res.data.results && res.data.results[0] && res.data.results[0].locations && res.data.results[0].locations[0];
                const latLng = result.latLng;
                console.log(latLng);
                return latLng;
            })
            .catch((error) => {
                console.log(error);
                message.error(`There was an error: ${JSON.stringify(error)}`);
                return error;
            });
    }

    async function insertVenue(venue) {
        const slug = slugify(venue.room_name, {
            replacement: '-',
            remove: /[*+~.,()'"#!:/@]/g,
            lower: true,
        });

        const location = await geocodeAddress({
            address1: venue.room_address,
            city: venue.room_city,
            region: venue.room_state,
            postal_code: venue.room_zip,
        });
        console.log('location', location);

        createVenue({
            variables: {
                objects: [
                    {
                        name: venue.room_name,
                        slug: slug,
                        venue_type: 'POOL_HALL',
                        address1: venue.room_address,
                        city: venue.room_city,
                        country: 'US',
                        region: venue.room_state,
                        postal_code: venue.room_zip,
                        logo: venue.room_logo,
                        phone_number: venue.room_phone,
                        email_address: venue.business_email,
                        website: venue.website,
                        social_facebook: venue.facebook,
                        social_twitter: venue.twitter,
                        social_yelp: venue.yelp,
                        lat: location && location.lat && location.lat.toString(),
                        lng: location && location.lng && location.lng.toString(),
                        ...(location && {
                            location: {
                                type: 'Point',
                                coordinates: [location.lat, location.lng],
                            },
                        }),
                        owner_id: authState.user.id,
                        is_public: true,
                    },
                ],
            },
        })
            .then((res) => {
                console.log(res);
                message.success('Venue added');
            })
            .catch((error) => {
                console.log(error);
                // setLoading(false);
                message.error('There was an error', error);
            });
    }

    return (
        <SectionWrapper>
            <SectionHeader
                title="Venues"
                titleStyle={{
                    color: '#fff',
                    textTransform: 'uppercase',
                    margin: 0,
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
                height={80}
                theme="digitalpool"
            />

            <SectionContent>
                <Row gutter={16}>
                    <Col xs={24}>
                        <Card title={`Page ${currentPage}`}>
                            <table width="100%">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Address</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {venues &&
                                        venues.map((venue, index) => {
                                            return (
                                                <React.Fragment key={index}>
                                                    <tr>
                                                        <td>
                                                            <Text>{`${venue.room_name}`}</Text>
                                                        </td>
                                                        <td>
                                                            <Text>
                                                                {venue.room_address} {venue.room_city}, {venue.room_state} {venue.room_zip}
                                                            </Text>
                                                        </td>
                                                    </tr>
                                                </React.Fragment>
                                            );
                                        })}
                                </tbody>
                            </table>
                            <div style={{ marginTop: 10, display: 'flex', alignItems: 'center' }}>
                                <div>
                                    <Button
                                        type="primary"
                                        disabled={currentPage === 1}
                                        onClick={() => {
                                            if (currentPage > 1) {
                                                setCurrentPage(currentPage - 1);
                                            }
                                        }}
                                    >
                                        Prev Page
                                    </Button>
                                    <Button
                                        type="primary"
                                        onClick={() => {
                                            setCurrentPage(currentPage + 1);
                                        }}
                                    >
                                        Page {currentPage + 1}
                                    </Button>
                                </div>
                                <div style={{ marginLeft: 20 }}>
                                    <Button
                                        type="primary"
                                        ghost
                                        onClick={() => {
                                            saveRecords(venues);
                                        }}
                                    >
                                        Save Records
                                    </Button>
                                </div>
                            </div>
                        </Card>
                    </Col>
                </Row>
            </SectionContent>
        </SectionWrapper>
    );
}

export default withRouter(DPTournamentMap);

// Closed: false
// Commentbox: "042819 Added"
// DateCLOSED: null
// Featured: false
// ID: "GoPlayPool_locations3VTZ9FS41WYK"
// Member: false
// Membership: false
// Officeuse: ""
// Owneraddress: ""
// Ownercity: ""
// Ownerstate: ""
// Ownerzip: ""
// Prospect: false
// TimeStampNEW: "2019-04-28T14:05:30"
// TimeStampUPDATE: "2019-04-28T16:35:20"
// UniquePrefix: "AZ+Glendale+007-Pub"
// VenueOnly: false
// Verified: true
// a3cushion: null
// a7footer: 1
// a8footer: null
// a9footer: null
// a10footer: null
// aboutsection: ""
// acceptscreditcards: "Y"
// age: true
// agolftable: null
// alcohol: true
// asnooker: null
// atms: ""
// autonumber: 7272
// barbecue: ""
// beers: "Y"
// bigscreens: ""
// business_email: ""
// capacity100: "Y"
// carryouts: ""
// contests: ""
// darts: "Y"
// date_registered: null
// delivery: ""
// draft: ""
// email_ads01: ""
// email_ads02: ""
// email_ads03: ""
// facebook: "https://www.facebook.com/007pub/"
// file_desk_mobile: ""
// file_image: ""
// flyerslink: ""
// foodspecials: ""
// foosball: ""
// fullbar: "Y"
// fullkitchen: ""
// happyhours: ""
// jukebox: "Y"
// karokedj: "Y"
// leagues: false
// limitedmenu: ""
// loungeareas: ""
// manager: "Vicki Haenitsch"
// manager_email: ""
// manager_personal_email: ""
// manager_phone: ""
// menu: ""
// ntntrivia: ""
// other_email: ""
// other_tables: ""
// othergames: "Pinball Machines"
// owner_manager: ""
// owner_phone: ""
// partyplatters: ""
// partyspecials: ""
// paymentcycle: ""
// paymentform: ""
// personal_email: ""
// pingpong: ""
// pitchers: ""
// pizzas: ""
// popcorn: "Y"
// privateparking: ""
// privateparties: ""
// promolink: ""
// retaildescription: ""
// retailsection: ""
// room_address: "4330 W Union Hills Drive"
// room_city: "Glendale"
// room_logo: ""
// room_name: "007 Pub"
// room_phone: "(623) 580-4007"
// room_state: "Arizona"
// room_zip: "85308"
// shuffleboard: ""
// smokingareas: ""
// sodasnack: "Y"
// sportschannels: ""
// stagemusic: "Y"
// state_ab: "AZ"
// stream: ""
// table_count: 1
// tabletypes: "Valley"
// tourneys: false
// tvs: "Y"
// twitter: ""
// videogames: ""
// website: "https://the007pub.com/"
// wheelchairaccessible: "Y"
// wifi: "Y"
// wine: ""
// yelp: "https://www.yelp.com/biz/the-007-pub-glendale"
