import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Menu, Icon } from 'antd';
import { Query } from '@apollo/client/react/components';
import gql from 'graphql-tag';
import CircularLoader from '../../components/CircularLoader';
import { GET_USER_LEAGUES_QUERY, GET_LEAGUE_BY_SLUG_QUERY } from './data/queries';
import _ from 'lodash';

// const USER_LEAGUES_SUBSCRIPTION = gql`
// 	subscription leagues {
// 		leagues(order_by: { id: desc }) {
// 			id
// 			name
// 			slug
// 		}
// 	}
// `;

const styles = {
    menuItemGroup: {
        marginTop: 10,
        marginLeft: 10,
        textTransform: 'uppercase',
        fontSize: 12,
        fontWeight: 600,
        color: 'rgba(255,255,255,0.4)',
    },
    titleText: {
        color: '#fff',
        padding: '15px 20px',
    },
};

function LMNav(props) {
    const { match, authState } = props;
    const slug = match.params.slug;

    const [theme, setTheme] = useState('dark');
    const [current, setCurrent] = useState(slug);

    const handleClick = (e) => {
        if (e.key === 'back') {
            setCurrent('league-manager');
            props.history.push('/league-manager');
        } else if (e.key === 'all') {
            props.history.push('/league-manager');
            props.onClicked();
        } else if (e.key === 'profile') {
            props.history.push(`/leagues/${match.params.slug}`);
            props.onClicked();
        } else {
            props.onClicked();
            const url = e.key === 'league-manager' ? '/league-manager' : e.key === 'new' ? `/league-manager/new` : `/league-manager/${e.key}/dashboard`;
            // if (_.includes(lmIncludes, e.key)) {
            // 	setTimeout(() => {
            // 		props.onClicked();
            // 	}, 300);
            // }

            props.history.push(url);
        }
    };

    useEffect(() => {
        setCurrent(slug);
    }, [slug]);

    return (
        <React.Fragment>
            <div>
                <Menu
                    theme={theme}
                    onClick={handleClick}
                    // style={{ width: 256 }}
                    // defaultOpenKeys={[ 'sub1' ]}
                    selectedKeys={[current]}
                    mode="inline"
                >
                    <Menu.Item key="league-manager">
                        <Icon type="home" /> Home
                    </Menu.Item>

                    <Menu.Item key="new">
                        <Icon type="plus" /> Create League
                    </Menu.Item>

                    <Menu.Divider style={{ backgroundColor: '#324454' }} />
                </Menu>
                <Query
                    query={GET_USER_LEAGUES_QUERY}
                    fetchPolicy="cache-and-network"
                    notifyOnNetworkStatusChange={true}
                    variables={{ owner_id: props.authState.user.id, limit: 5 }}
                    onCompleted={async (data) => {
                        console.log(data);
                    }}
                >
                    {({ loading, error, data }) => {
                        if (loading) return <div style={styles.titleText}>Loading...</div>;
                        if (error) return <div style={styles.titleText}>Error: {error.message}</div>;
                        if (data && !data.leagues) {
                            return <div style={styles.titleText}>League not found.</div>;
                        }

                        return (
                            <Menu
                                theme={theme}
                                onClick={handleClick}
                                // style={{ width: 256 }}
                                // defaultOpenKeys={[ 'sub1' ]}
                                selectedKeys={[current]}
                                mode="inline"
                            >
                                <Menu.ItemGroup key="leagues" title={<div style={styles.menuItemGroup}>My Leagues</div>}>
                                    {data.leagues.length > 0 ? (
                                        data.leagues.map((league, index) => (
                                            <Menu.Item key={league.slug} className={league.deleted_at && 'deleted'}>
                                                <Icon type="global" /> {league.deleted_at ? `${league.name} (Deleted)` : league.name}
                                            </Menu.Item>
                                        ))
                                    ) : (
                                        <div style={{ marginTop: 16, marginLeft: 24 }}>None yet</div>
                                    )}

                                    {data.leagues.length > 0 && (
                                        <Menu.Item key="all">
                                            <Icon type="arrow-right" /> View All
                                        </Menu.Item>
                                    )}
                                </Menu.ItemGroup>
                            </Menu>
                        );
                    }}
                </Query>
            </div>
        </React.Fragment>
    );
}

export default withRouter(LMNav);
