import React from 'react';
import { Table, Icon, Divider, Button, Typography, Modal, Tooltip } from 'antd';
import { Link, Route, withRouter } from 'react-router-dom';
const slugify = require('slugify');

const { Text } = Typography;
const { confirm } = Modal;

function VenueTable(props) {
    const { onSelected, onEditSelected, onDelete } = props;

    function onChange(pagination, filters, sorter) {
        console.log('params', pagination, filters, sorter);
    }

    function showConfirm(record) {
        confirm({
            title: `Are you sure you want to remove ${record.name}?`,
            centered: true,
            transitionName: 'fade',
            maskTransitionName: 'none',
            // onOk() {
            // 	return new Promise((resolve, reject) => {
            // 		setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
            // 	}).catch(() => console.log('Oops errors!'));
            // },
            onOk() {
                onDelete(record);
            },
            onCancel() {},
        });
    }

    const columns = [
        {
            title: '#',
            dataIndex: 'index',
            width: 80,
            defaultSortOrder: 'ascend',
            sorter: (a, b) => {
                return a.index - b.index;
            },
        },
        {
            title: 'Name',
            dataIndex: 'name',
            // filters: [
            // 	{
            // 		text: 'Platinum Division',
            // 		value: 'platinum'
            // 	},
            // 	{
            // 		text: 'Gold Division',
            // 		value: 'gold'
            // 	},
            // 	{
            // 		text: 'Silver Division',
            // 		value: 'silver'
            // 	},
            // 	{
            // 		text: 'Bronze Division',
            // 		value: 'bronze'
            // 	},
            // 	{
            // 		text: 'Trophy Division',
            // 		value: 'trophy'
            // 	}
            // ],
            // // specify the condition of filtering result
            // // here is that finding the name started with `value`
            // onFilter: (value, record) => record.name.indexOf(value) === 0,
            sorter: (a, b) => {
                return a.name.localeCompare(b.name);
            },
            // render: (text, record) => (
            // 	<div>
            // 		<i className="icon ion-md-business" style={{ fontSize: 18, marginRight: 10 }} />
            // 		{text}
            // 	</div>
            // ),
            defaultSortOrder: 'ascend',
            render: (text, record) => <Text style={{ fontWeight: 600 }}>{text || '-'}</Text>,
        },
        {
            title: 'Description',
            dataIndex: 'description',
            defaultSortOrder: 'descend',
            sorter: (a, b) => {
                return a.description.localeCompare(b.description);
            },
            render: (text, record) => (
                <div className="ellipsis" style={{ maxWidth: 300 }}>
                    <Text>{text || '-'}</Text>
                </div>
            ),
        },
        {
            title: 'Venue Type',
            dataIndex: 'venue_type',
            defaultSortOrder: 'descend',
            sorter: (a, b) => {
                return a.venue_type.localeCompare(b.venue_type);
            },
        },
        {
            title: 'City',
            dataIndex: 'city',
            sorter: (a, b) => {
                return a.city.localeCompare(b.city);
            },
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Region',
            dataIndex: 'region',
            sorter: (a, b) => {
                return a.region.localeCompare(b.region);
            },
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Country',
            dataIndex: 'country',
            sorter: (a, b) => {
                return a.country.localeCompare(b.country);
            },
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Access',
            dataIndex: 'is_public',
            sorter: (a, b) => {
                return a.is_public.localeCompare(b.is_public);
            },
            sortDirections: ['descend', 'ascend'],
        },

        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <span style={{ whiteSpace: 'nowrap' }}>
                    <Tooltip title="Edit">
                        <Button
                            type="ghost"
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                onEditSelected(record.id);
                                // props.history.push(`/league-manager/${props.league.slug}/venues/${record.slug}/edit`);
                            }}
                        >
                            <Icon type="edit" />
                        </Button>
                        {/* <Divider type="vertical" /> */}
                        <Button
                            type="danger"
                            ghost
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                showConfirm(record);
                            }}
                        >
                            <Icon type="delete" />
                        </Button>
                    </Tooltip>
                </span>
            ),
        },
    ];

    return (
        <Table
            onRow={(record, rowIndex) => {
                return {
                    onClick: (event) => {
                        console.log(record, rowIndex);
                        const slug = slugify(record.name, {
                            replacement: '-',
                            remove: /[*+~.,()'"#!:/@]/g,
                            lower: true,
                        });
                        props.history.push({
                            pathname: `/league-manager/${props.league.slug}/venues/${slug}`,
                            // search: '?query=abc',
                            state: { data: record },
                        });
                    }, // click row
                    onDoubleClick: (event) => {}, // double click row
                    onContextMenu: (event) => {}, // right button click row
                    onMouseEnter: (event) => {}, // mouse enter row
                    onMouseLeave: (event) => {}, // mouse leave row
                };
            }}
            columns={columns}
            dataSource={props.venues}
            onChange={onChange}
            pagination={false}
            tableLayout="auto"
            scroll={{ x: 400 }}
            // size="middle"
            // bordered
        />
    );
}

export default withRouter(VenueTable);
