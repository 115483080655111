import React, { useState, useEffect, useRef } from 'react';
import { message, Comment, Icon, Card, Button, Divider, Tooltip, Avatar, Typography } from 'antd';
import CircularLoader from '../../../components/CircularLoader';
import { GET_POST_COMMENTS } from '../data/queries';
import { Query } from '@apollo/client/react/components';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import NestedComment from './NestedComment';
import FeedCommentForm from './FeedCommentForm';
import moment from 'moment';
import Linkify from 'react-linkify';
import { UserOutlined } from '@ant-design/icons';
import pluralize from 'pluralize';
import LikePostButton from './LikePostButton';
import FeedShareButton from './FeedShareButton';

const { Title, Text } = Typography;
const styles = {};

export default function FeedPost(props) {
    const { authState, post, onDelete, onEditSelected } = props;
    const likes = (post.post_likes_aggregate && post.post_likes_aggregate.aggregate && post.post_likes_aggregate.aggregate.count) || 0;
    const shares = (post.post_shares_aggregate && post.post_shares_aggregate.aggregate && post.post_shares_aggregate.aggregate.count) || 0;
    const [action, setAction] = useState(null);
    const comments = (post.post_comments_aggregate && post.post_comments_aggregate.aggregate && post.post_comments_aggregate.aggregate.count) || 0;

    return (
        <Card
            title={
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ marginRight: 12 }}>
                            <div className="gradient-border">
                                <Avatar
                                    size={45}
                                    src={
                                        post.user
                                            ? (authState && authState.user && authState.user.photoURL) || (post.user && post.user.avatar)
                                            : props.authState && props.authState.user && props.authState.user.photoURL
                                    }
                                >
                                    <UserOutlined
                                        style={{
                                            fontSize: 50,
                                            lineHeight: '60px',
                                            color: '#000',
                                        }}
                                    />
                                </Avatar>
                            </div>
                        </div>
                        <div>
                            <Title level={4} style={{ marginBottom: 0, fontSize: 18 }}>
                                {post.user && `${post.user.first_name} ${post.user.last_name}`}
                            </Title>
                            <Text type="secondary" style={{ fontSize: 14 }}>
                                <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
                                    <span>{moment(post.created_at).fromNow()}</span>
                                </Tooltip>
                            </Text>
                        </div>
                    </div>

                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {authState && authState.user && authState.user.id && <FeedShareButton post={post} {...props} />}
                        {authState && authState.user && authState.user.id === post.user_id && (
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Tooltip title="Edit post">
                                    <Button
                                        type="ghost"
                                        onClick={() => {
                                            if (onEditSelected) {
                                                onEditSelected(post.id);
                                            }
                                        }}
                                    >
                                        <Icon type="edit" />
                                    </Button>
                                </Tooltip>
                                <Tooltip title="Delete post">
                                    <Button
                                        type="danger"
                                        ghost
                                        onClick={() => {
                                            console.log('delete');
                                            console.log(onDelete);
                                            if (onDelete) {
                                                onDelete(post);
                                            }
                                        }}
                                    >
                                        <Icon type="delete" />
                                    </Button>
                                </Tooltip>
                            </div>
                        )}
                    </div>
                </div>
            }
            bordered={false}
            bodyStyle={{ padding: 0 }}
            style={{ marginBottom: 20 }}
            // actions={actions}
        >
            {post.image_url && (
                <div style={{ width: '100%', textAlign: 'center', backgroundColor: '#000000' }}>
                    <img src={post.image_url} alt="Post" style={{ height: 'auto', maxHeight: 600, maxWidth: '100%' }} />
                </div>
            )}

            <div style={{ padding: 20 }}>
                <Linkify
                    componentDecorator={(decoratedHref, decoratedText, key) => (
                        <a target="blank" href={decoratedHref} key={key}>
                            {decoratedText}
                        </a>
                    )}
                >
                    <p style={{ maxWidth: 800, fontSize: 22 }} className="display-linebreak">
                        {post.message}
                    </p>
                </Linkify>

                <div style={{ marginBottom: 10 }}>
                    <span key="comment-basic-like">
                        {authState && authState.user && authState.user.id ? (
                            <LikePostButton likes={likes} post={post} {...props} />
                        ) : (
                            <span>
                                {likes} {pluralize('Like', likes)}
                            </span>
                        )}
                    </span>
                    {/* <span key="comment-basic-share" style={{ marginLeft: 20 }}>
                            {authState && authState.user && authState.user.id ? (
                                <FeedShareButton post={post} {...props} />
                            ) : (
                                <span>
                                    {shares} {pluralize('Share', shares)}
                                </span>
                            )}
                        </span> */}
                    <span key="comment-basic-comment" style={{ marginLeft: 30 }}>
                        <Icon type="message" theme={action === 'comment' ? 'filled' : 'outlined'} />

                        <span style={{ paddingLeft: 10, cursor: 'auto' }}>
                            {comments} {pluralize('Comment', comments)}
                        </span>
                    </span>
                </div>
                {/* <Divider style={{ margin: '4px 0' }} /> */}

                {/* <FeedComment /> */}
                {/* <Title level={4}>Add comment</Title> */}
                {props.authState && props.authState.user && props.authState.user.id && <FeedCommentForm post={post} {...props} />}

                {post && (
                    <Query
                        query={GET_POST_COMMENTS}
                        fetchPolicy="cache-and-network"
                        notifyOnNetworkStatusChange={true}
                        variables={{ post_id: post.id }}
                        onCompleted={(data) => {
                            // console.log(data);
                        }}
                    >
                        {({ loading, error, data }) => {
                            if (loading) return <CircularLoader />;
                            if (error) return <div style={styles.container}>Error: {error.message}</div>;
                            if (data && !data.post_comments) {
                                return null;
                            }
                            if (data && data.post_comments.length === 0) {
                                return null;
                            }
                            const postComments = data.post_comments;

                            return (
                                <React.Fragment>
                                    {postComments &&
                                        postComments.length > 0 &&
                                        postComments
                                            .filter((comment) => {
                                                return comment.parent_id === null;
                                            })
                                            .map((comment) => {
                                                return (
                                                    <React.Fragment key={comment.id}>
                                                        <NestedComment post={post} comment={comment} {...props} />
                                                    </React.Fragment>
                                                );
                                            })}
                                </React.Fragment>
                            );
                        }}
                    </Query>
                )}
            </div>
        </Card>
    );
}
