import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { Popconfirm, message, Menu, Dropdown, Modal, Progress, List, Button, Upload, Icon, Typography, Avatar } from 'antd';
import axios from 'axios';
import _ from 'lodash';
import { DownOutlined } from '@ant-design/icons';
import numeral from 'numeral';

const { Text } = Typography;

function ListFargoModal(props) {
    const { tournament, players, modalVisible, onModalOk, onModalCancel } = props;
    const [fargoResults, setFargoResults] = useState([]);
    const [playerList, setPlayerList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [progress, setProgress] = useState(0);
    const [counter, setCounter] = useState(0);
    const [totalPlayers, setTotalPlayers] = useState(0);
    const [confirmPopupVisible, setConfirmPopupVisible] = useState(false);

    useEffect(() => {
        let promises = [];
        function fetchData() {
            setLoading(true);
            setProgress(0);
            setCounter(0);
            setTotalPlayers(players.length);
            players.forEach((player, index) => {
                const updatedProgress = Math.round((index / players.length) * 100);

                promises.push(
                    getFargoRatingByName(player.name).then((item) => {
                        console.log(item);
                        console.log('progress', updatedProgress);
                        setProgress(updatedProgress);
                        setCounter(index);

                        if (item && item.length) {
                            return item.map((row) => {
                                return {
                                    id: player.id,
                                    skillLevel: player.skill_level,
                                    firstName: row.firstName,
                                    lastName: row.lastName,
                                    effectiveRating: row.effectiveRating,
                                    rating: row.rating,
                                    robustness: row.robustness,
                                    location: row.location,
                                    fargoId: row.fargoId,
                                    fargoReadableId: row.fargoReadableId,
                                };
                            });
                        }
                    })
                );
            });

            Promise.all(promises)
                .then((results) => {
                    console.log(_.compact(results));
                    setFargoResults(_.compact(results));
                    const flatList = results.map((item) => {
                        return item && item[0];
                    });
                    setPlayerList(_.compact(flatList));
                    setLoading(false);
                    setProgress(100);
                })
                .catch((error) => {
                    console.log(error);
                    message.error('There was an error', error);
                    setLoading(false);
                    setProgress(100);
                });
        }

        if (players && modalVisible === true) {
            fetchData();
        }
    }, [players, modalVisible]);

    // // 9900004703802

    // function getFargoProfileById(id) {
    //     let url = 'https://dashboard.fargorate.com/api/playerprofiles/' + id;

    //     return axios
    //         .get(url)
    //         .then((response) => {
    //             console.log(response);
    //             const result = response.data;
    //             return result;
    //         })
    //         .catch((error) => {
    //             console.log(error);
    //         });
    // }

    function getFargoRatingByName(name) {
        return new Promise((resolve, reject) => {
            const nameSplit = name.split(' ');
            let url;
            if (nameSplit.length > 1) {
                const firstName = nameSplit[0];
                const lastName = nameSplit[1];
                url = 'https://dashboard.fargorate.com/api/indexsearch?q=' + firstName.toLowerCase() + '%20' + lastName.toLowerCase();
            } else {
                url = 'https://dashboard.fargorate.com/api/indexsearch?q=' + name.toLowerCase();
            }
            //const url = 'https://dashboard.fargorate.com/api/indexsearch?q=jimmy%20lejeune';
            //const url = 'https://dashboard.fargorate.com/api/indexsearch?q='+player.name.toLowerCase();
            // const url = 'https://dashboard.fargorate.com/api/indexsearch?q='+formRef.current.state.values.first_name.toLowerCase()+'%20'+formRef.current.state.values.last_name.toLowerCase();
            //const url = 'https://dashboard.fargorate.com/api/indexsearch?q=jimmy%20lejeune';
            //const url = 'https://dashboard.fargorate.com/api/indexsearch?q='+player.name.toLowerCase();
            // const url = 'https://dashboard.fargorate.com/api/indexsearch?q='+formRef.current.state.values.first_name.toLowerCase()+'%20'+formRef.current.state.values.last_name.toLowerCase();

            axios
                .get(url)
                .then((response) => {
                    const results = response.data.value;
                    if (results && results.length) {
                        console.log(results);
                        const fargoRatings = results.map((row) => {
                            return {
                                firstName: row.firstName,
                                lastName: row.lastName,
                                effectiveRating: row.effectiveRating,
                                rating: row.rating,
                                robustness: row.robustness,
                                location: row.location,
                                fargoId: row.membershipId,
                                fargoReadableId: row.readableId,
                            };
                        });
                        resolve(fargoRatings);
                    } else {
                        resolve();
                    }
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                });
        });
    }

    return (
        <Modal
            title="Get Fargo Ratings"
            centered
            transitionName="fade"
            wrapClassName="fargo-modal"
            // transitionName="none"
            maskTransitionName="none"
            bodyStyle={{ maxHeight: 500, overflowY: 'auto', padding: 0 }}
            destroyOnClose={false}
            footer={[
                <Button key="cancel" onClick={onModalCancel}>
                    Cancel
                </Button>,
                <Popconfirm
                    key="submit"
                    title="Are you sure? This will replace all skill levels with Fargo Ratings."
                    onConfirm={() => {
                        setConfirmPopupVisible(false);
                        onModalOk(playerList);
                    }}
                    onCancel={() => {
                        setConfirmPopupVisible(false);
                    }}
                    visible={confirmPopupVisible}
                    okText="Yes"
                    cancelText="No"
                    placement="topRight"
                >
                    <Button
                        disabled={loading ? true : false}
                        type="primary"
                        onClick={() => {
                            setConfirmPopupVisible(true);
                        }}
                        style={{ marginLeft: 5 }}
                    >
                        Apply Ratings
                    </Button>
                </Popconfirm>,
            ]}
            visible={modalVisible}
            // onOk={() => {
            //     setConfirmPopupVisible(true);
            // }}
            onCancel={onModalCancel}
            // okText="Apply Ratings"
            // cancelText="Dismiss"
        >
            <React.Fragment>
                {loading ? (
                    <div style={{ padding: '10px 20px' }}>
                        Finding player: {counter} of {totalPlayers}
                        <Progress
                            percent={progress || 0}
                            status={progress < 100 ? 'active' : progress === 100 ? 'success' : 'normal'}
                            size="small"
                            // showInfo={false}
                            strokeWidth={6}
                            strokeLinecap="square"
                        />
                    </div>
                ) : (
                    <List className="hover-list" size="small" loading={loading}>
                        <div>
                            {fargoResults.map((item, index) => {
                                let selectedItem = item && item[0];

                                return (
                                    <React.Fragment key={index}>
                                        {item && item.length > 1 ? (
                                            <Dropdown
                                                getPopupContainer={() => document.querySelectorAll('.fargo-modal .ant-modal-body')[0]}
                                                overlay={() => {
                                                    return (
                                                        <Menu selectedKeys={[`${index}-0`]}>
                                                            {item.map((row, index2) => {
                                                                return (
                                                                    <Menu.Item
                                                                        key={`${index}-${index2}`}
                                                                        onClick={() => {
                                                                            let newFargoResults = fargoResults;
                                                                            let newItems = item;
                                                                            newItems[index2] = newItems[0];
                                                                            newItems[0] = row;
                                                                            newFargoResults[index] = newItems;
                                                                            let newPlayerList = playerList;
                                                                            newPlayerList[index] = row;
                                                                            // console.log(newPlayerList);
                                                                            // console.log(row);
                                                                            setPlayerList([...newPlayerList]);
                                                                            setFargoResults([...newFargoResults]);
                                                                        }}
                                                                    >
                                                                        <div
                                                                            style={{
                                                                                display: 'flex',
                                                                                alignItems: 'center',
                                                                                justifyContent: 'space-between',
                                                                            }}
                                                                        >
                                                                            <div>
                                                                                <Text>
                                                                                    {row.firstName} {row.lastName}, {row.location} - {row.effectiveRating}
                                                                                </Text>
                                                                            </div>
                                                                            <div>
                                                                                <Text type="secondary">{row.fargoId}</Text>
                                                                            </div>
                                                                        </div>
                                                                    </Menu.Item>
                                                                );
                                                            })}
                                                        </Menu>
                                                    );
                                                }}
                                                overlayStyle={{
                                                    maxHeight: 300,
                                                    maxWidth: 300,
                                                    overflowY: 'auto',
                                                    position: 'relative',
                                                    margin: 0,
                                                    padding: '4px 0',
                                                    textAlign: 'left',
                                                    listStyleType: 'none',
                                                    backgroundColor: '#fff',
                                                    backgroundClip: 'padding-box',
                                                    borderRadius: 4,
                                                    outline: 'none',

                                                    boxShadow: '0 2px 8px rgb(0 0 0 / 15%)',
                                                }}
                                                trigger={['click']}
                                            >
                                                <List.Item
                                                    extra={
                                                        <Text>
                                                            {item.length - 1} other matches
                                                            <DownOutlined />
                                                        </Text>
                                                    }
                                                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                                                >
                                                    <List.Item.Meta
                                                        avatar={
                                                            <Avatar size={30}>
                                                                <Icon type="user" />
                                                            </Avatar>
                                                        }
                                                        title={`${selectedItem.firstName} ${selectedItem.lastName} - ${selectedItem.effectiveRating}`}
                                                        description={
                                                            <Text type="secondary">
                                                                {selectedItem.location}
                                                                <br />
                                                                Fargo ID: {selectedItem.fargoId}
                                                                <br />
                                                                Robustness: {numeral(selectedItem.robustness).format('0,0')}
                                                            </Text>
                                                        }
                                                    />
                                                </List.Item>
                                            </Dropdown>
                                        ) : (
                                            <List.Item>
                                                <List.Item.Meta
                                                    avatar={
                                                        <Avatar size={30}>
                                                            <Icon type="user" />
                                                        </Avatar>
                                                    }
                                                    title={`${selectedItem.firstName} ${selectedItem.lastName} - ${selectedItem.effectiveRating}`}
                                                    description={
                                                        <Text type="secondary">
                                                            {selectedItem.location}
                                                            <br />
                                                            Fargo ID: {selectedItem.fargoId}
                                                            <br />
                                                            Robustness: {numeral(selectedItem.robustness).format('0,0')}
                                                        </Text>
                                                    }
                                                />
                                            </List.Item>
                                        )}
                                    </React.Fragment>
                                );
                            })}
                        </div>
                    </List>
                )}
            </React.Fragment>
        </Modal>
    );
}

export default withRouter(ListFargoModal);
