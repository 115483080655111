import React, { useState } from 'react';
import { Form, Input, Select, Typography } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { Query, Mutation } from '@apollo/client/react/components';
import _ from 'lodash';

const { Search } = Input;
const { Option } = Select;
const { Title, Text } = Typography;

export default function SearchFilterPlayers(props) {
    const { players, searching, onSearchPlayer, placeholder } = props;

    function handleSubmit(values, actions) {
        actions.setSubmitting(false);
        actions.resetForm();
        console.log('Received values of form: ', values);
    }

    return (
        <Form layout="inline">
            <Form.Item>
                <span style={{ display: 'flex', alignItems: 'center' }}>
                    {/* <SearchOutlined size="large" style={{ marginRight: 10 }} /> */}
                    <Search
                        allowClear
                        placeholder={placeholder || 'Search all players'}
                        size="large"
                        style={{ width: 325 }}
                        enterButton
                        onSearch={onSearchPlayer}
                        loading={searching}
                    />
                </span>
            </Form.Item>
        </Form>
    );
}
