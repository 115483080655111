import React from 'react';
import { getRecoilValue, useSetRecoilState } from 'recoil';
import { Table, Tooltip, Button, Icon, message, Modal } from 'antd';
import { Query } from '@apollo/react-components';
import { GET_GHOST_GAMES_PAYOUTS, GET_GHOST_GAMES_PAYOUTS_RECORD } from '../../data/queries';
import { DELETE_GHOST_GAMES_PAYOUTS_ENTRY } from '../../data/mutations';
import { formatTimeStr } from 'antd/lib/statistic/utils';
import CircularLoader from '../../../../components/CircularLoader';
import { payoutsInitialFormValues, payoutsModalVisible, payoutsSubmitButtonText, isNewPayoutsEntry } from '../../data/atoms';

const { confirm } = Modal;

function orderAndFormatData(arr) {
    let count = arr.length;
    let tmp, split, cNum1, cNum2, split2, cNum11, cNum21;
    let formatArr = [];

    for (let i = 0; i < count; i++) {
        if (arr[i].place.includes('-')) {
            split = arr[i].place.split('-');
            cNum1 = parseInt(split[0]);
            cNum2 = parseInt(split[1]);
        }

        for (let j = i + 1; j < count; j++) {
            if (arr[j].place.includes('-')) {
                split2 = arr[j].place.split('-');
                cNum11 = parseInt(split2[0]);
                cNum21 = parseInt(split2)[1];

                if (split) {
                    if (cNum2 > cNum21) {
                        tmp = arr[i];
                        arr[i] = arr[j];
                        arr[j] = tmp;
                    }
                } else {
                    if (parseInt(arr[i].place) > parseInt(cNum21)) {
                        tmp = arr[i];
                        arr[i] = arr[j];
                        arr[j] = tmp;
                    }
                }
            } else {
                if (parseInt(arr[i].place) > parseInt(arr[j].place)) {
                    tmp = arr[i];
                    arr[i] = arr[j];
                    arr[j] = tmp;
                }
            }

            if (parseInt(arr[i].place) > parseInt(arr[j].place)) {
                tmp = arr[i];
                arr[i] = arr[j];
                arr[j] = tmp;
            }
        }

        split = false;

        formatArr.push({
            id: arr[i].id,
            place: arr[i].place,
            amount: arr[i].amount,
            key: i + 1,
        });
    }

    return formatArr;
}

export default function PayoutsTable(props) {
    const { ghostEventId, client } = props;
    const setPayoutsInitialFormValues = useSetRecoilState(payoutsInitialFormValues);
    const setPayoutsSubmitButtonText = useSetRecoilState(payoutsSubmitButtonText);
    const setPayoutsModalVisible = useSetRecoilState(payoutsModalVisible);
    const setIsNewPayoutsEntry = useSetRecoilState(isNewPayoutsEntry);

    function editModal(record) {
        setPayoutsInitialFormValues({
            id: record.id,
            place: record.place,
            amount: record.amount,
        });
        setIsNewPayoutsEntry(false);
        setPayoutsSubmitButtonText('Edit Payout');
        setPayoutsModalVisible(true);
    }

    async function deleteEntry(id) {
        confirm({
            title: `Are you sure you want to remove this entry from payouts?`,
            // content: 'Note: This action will not remove real user accounts.',
            centered: true,
            transitionName: 'fade',
            maskTransitionName: 'none',
            // onOk() {
            // 	return new Promise((resolve, reject) => {
            // 		setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
            // 	}).catch(() => console.log('Oops errors!'));
            // },
            onOk() {
                const res = client
                    .mutate({
                        mutation: DELETE_GHOST_GAMES_PAYOUTS_ENTRY,
                        variables: {
                            id: id,
                        },
                        notifyOnNetworkStatusChange: true,
                        awaitRefetchQueries: true,
                        refetchQueries: [
                            {
                                query: GET_GHOST_GAMES_PAYOUTS,
                                variables: { ghost_games_id: ghostEventId },
                            },
                        ],
                    })
                    .then((res) => {});
            },
            onCancel() {},
        });
    }

    let columns = [
        {
            title: 'Place',
            dataIndex: 'place',
            key: 'place',
            width: 100,
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
        },
        {
            title: 'Action',
            key: 'action',
            width: 100,
            render: (text, record) => (
                <span style={{ whiteSpace: 'nowrap' }}>
                    <Tooltip title="Edit">
                        <Button
                            type="ghost"
                            onClick={async (e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                editModal(record);
                                // onEditSelected(record);
                                // props.history.push(
                                // 	`/tournament-builder/${props.tournament.slug}/venues/${record.slug}/edit`
                                // );
                            }}
                        >
                            <Icon type="edit" />
                        </Button>
                    </Tooltip>
                    {/* <Divider type="vertical" /> */}
                    <Tooltip title="Delete">
                        <Button
                            type="ghost"
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                deleteEntry(record.id);
                                // showConfirm(record);
                            }}
                        >
                            <Icon type="delete" />
                        </Button>
                    </Tooltip>
                </span>
            ),
        },
    ];

    return (
        <Query
            query={GET_GHOST_GAMES_PAYOUTS}
            fetchPolicy="cache-and-network"
            notifyOnNetworkStatusChange
            awaitRefetchQueries
            variables={{ ghost_games_id: ghostEventId }}
            onCompleted={(data) => {
                console.log('d', data);
            }}
        >
            {({ loading, error, data }) => {
                if (loading) return <CircularLoader />;
                if (error) return <div>{error}</div>;

                const formattedTableData = orderAndFormatData(data.ghost_games_payouts);

                return <Table columns={columns} dataSource={formattedTableData} pagination={false} />;
            }}
        </Query>
    );
}
