import gql from 'graphql-tag';

export const GET_USER = gql`
    query users($email: String!) {
        users(where: { email: { _eq: $email } }) {
            id
            first_name
            last_name
            slug
            email
            country
            region
            city
            avatar
        }
    }
`;

export const GET_PLAYER_BY_ID = gql`
    query players($id: Int!) {
        players(where: { id: { _eq: $id } }) {
            id
            name
            name_slug
            email
            aliases
            country
            country_phone
            city
            region
            phone_number
            gender
            properties
            image_large
            image_small
            fargo_id
            fargo_readable_id
            fargo_robustness
            fargo_effective_rating
            fargo_rating
            fargo_uuid
            user_id
            user {
                id
                first_name
                last_name
                slug
            }
        }
    }
`;

export const GET_PLAYER_BY_SLUG = gql`
    query players($slug: String!) {
        players(where: { slug: { _eq: $slug } }) {
            id
            name
            name_slug
            email
            aliases
            country
            country_phone
            city
            region
            phone_number
            gender
            properties
            image_large
            image_small
            fargo_id
            fargo_readable_id
            fargo_robustness
            fargo_effective_rating
            fargo_rating
            fargo_uuid
            user_id
            user {
                id
                first_name
                last_name
                slug
            }
        }
    }
`;

export const GET_USER_PROFILE_BY_EMAIL = gql`
    query users($email: String!) {
        users(where: { email: { _eq: $email } }) {
            id
            first_name
            last_name
            slug
            email
            phone_number
            country_phone
            skill_level
            email_verified
            photo_url
            gender
            nickname
            display_name
            status
            fargo_id
            player_id
            fargo_readable_id
            fargo_effective_rating
            fargo_rating
            fargo_robustness
            fargo_uuid
            role
            avatar
            last_seen
            last_typed
            uid
            uuid
            country
            region
            city
            created_at
            updated_at
            pro_site_enabled
            player {
                id
                name
                aliases
                properties
                image_large
                image_small
                country
                country_phone
                phone_number
                email
                gender
                name_slug
                user_id
                fargo_id
                fargo_readable_id
                fargo_robustness
                fargo_uuid
            }
            followers_aggregate {
                aggregate {
                    count
                }
            }
            following_aggregate {
                aggregate {
                    count
                }
            }
            friends1_aggregate {
                aggregate {
                    count
                }
            }
            friends2_aggregate {
                aggregate {
                    count
                }
            }
            friend_requests_aggregate {
                aggregate {
                    count
                }
            }
            tournament_director_aggregate {
                aggregate {
                    count
                }
            }
            tournament_organizer_aggregate {
                aggregate {
                    count
                }
            }
            tournament_players_aggregate {
                aggregate {
                    count
                }
            }
            league_owner_aggregate {
                aggregate {
                    count
                }
            }
            league_operator_aggregate {
                aggregate {
                    count
                }
            }
            league_players_aggregate {
                aggregate {
                    count
                }
            }
        }
    }
`;

export const GET_USER_PROFILE_BY_SLUG = gql`
    query users($slug: String!) {
        users(where: { slug: { _eq: $slug } }) {
            id
            first_name
            last_name
            slug
            email
            phone_number
            country_phone
            skill_level
            email_verified
            photo_url
            gender
            nickname
            display_name
            status
            fargo_id
            player_id
            fargo_robustness
            fargo_readable_id
            fargo_effective_rating
            fargo_rating
            fargo_uuid
            role
            avatar
            last_seen
            last_typed
            uid
            uuid
            country
            region
            city
            created_at
            updated_at
            pro_site_enabled
            player {
                id
                name
                aliases
                properties
                image_large
                image_small
                country
                country_phone
                phone_number
                email
                gender
                name_slug
                user_id
                fargo_id
                fargo_readable_id
                fargo_robustness
                fargo_uuid
            }
            followers_aggregate {
                aggregate {
                    count
                }
            }
            following_aggregate {
                aggregate {
                    count
                }
            }
            friends1_aggregate {
                aggregate {
                    count
                }
            }
            friends2_aggregate {
                aggregate {
                    count
                }
            }
            friend_requests_aggregate {
                aggregate {
                    count
                }
            }
            tournament_director_aggregate {
                aggregate {
                    count
                }
            }
            tournament_organizer_aggregate {
                aggregate {
                    count
                }
            }
            tournament_players_aggregate {
                aggregate {
                    count
                }
            }
            league_owner_aggregate {
                aggregate {
                    count
                }
            }
            league_operator_aggregate {
                aggregate {
                    count
                }
            }
            league_players_aggregate {
                aggregate {
                    count
                }
            }
        }
    }
`;

export const GET_USER_PROFILE_BY_ID = gql`
    query users($id: Int!) {
        users(where: { id: { _eq: $id } }) {
            id
            first_name
            last_name
            slug
            email
            phone_number
            country_phone
            skill_level
            email_verified
            photo_url
            nickname
            display_name
            status
            fargo_id
            player_id
            fargo_readable_id
            fargo_effective_rating
            fargo_rating
            fargo_robustness
            role
            avatar
            last_seen
            last_typed
            uid
            uuid
            country
            region
            city
            created_at
            updated_at
            pro_site_enabled
            player {
                id
                name
                aliases
                properties
                image_large
                image_small
                country
                country_phone
                phone_number
                email
                gender
                name_slug
                user_id
                fargo_id
                fargo_readable_id
                fargo_robustness
            }
            followers_aggregate {
                aggregate {
                    count
                }
            }
            following_aggregate {
                aggregate {
                    count
                }
            }
            friends1_aggregate {
                aggregate {
                    count
                }
            }
            friends2_aggregate {
                aggregate {
                    count
                }
            }
            friend_requests_aggregate {
                aggregate {
                    count
                }
            }
            tournament_director_aggregate {
                aggregate {
                    count
                }
            }
            tournament_organizer_aggregate {
                aggregate {
                    count
                }
            }
            tournament_players_aggregate {
                aggregate {
                    count
                }
            }
            league_owner_aggregate {
                aggregate {
                    count
                }
            }
            league_operator_aggregate {
                aggregate {
                    count
                }
            }
            league_players_aggregate {
                aggregate {
                    count
                }
            }
        }
    }
`;

export const GET_USER_LEAGUES_QUERY = gql`
    query league_players($userId: Int, $limit: Int) {
        league_players(where: { user_id: { _eq: $userId } }, order_by: { updated_at: desc }, limit: $limit) {
            id
            league {
                name
                slug
                short_description
                avatar
                logo
                deleted_at
            }
        }
        leagues(where: { _or: [{ owner_id: { _eq: $userId }, operator_id: { _eq: $userId } }] }, order_by: { updated_at: desc }, limit: $limit) {
            id
            name
            slug
            short_description
            avatar
            logo
            deleted_at
        }
    }
`;

export const GET_USER_TOURNAMENTS_QUERY = gql`
    query tournament_players($userId: Int, $limit: Int) {
        tournament_players(where: { user_id: { _eq: $userId } }, order_by: { updated_at: asc }, limit: $limit) {
            id
            tournament {
                name
                slug
                description
                game_type
                avatar
                logo
                organizer_id
                director_id
                created_at
                updated_at
            }
        }
        tournaments(where: { _or: [{ director_id: { _eq: $userId }, organizer_id: { _eq: $userId } }] }, order_by: { updated_at: asc }, limit: $limit) {
            id
            name
            slug
            description
            game_type
            avatar
            logo
            organizer_id
            director_id
            created_at
            updated_at
        }
    }
`;

export const GET_USER_POSTS = gql`
    query posts($user_id: Int!) {
        posts(where: { user_id: { _eq: $user_id } }, order_by: { created_at: desc }) {
            id
            message
            slug
            user_id
            image_url
            created_at
            updated_at
            post_comments_aggregate {
                aggregate {
                    count
                }
            }
            post_likes_aggregate(where: { comment_id: { _is_null: true } }) {
                aggregate {
                    count
                }
            }
        }
    }
`;

export const GET_POST_QUERY = gql`
    query posts($id: Int!) {
        posts(where: { id: { _eq: $id } }) {
            id
            message
            slug
            user_id
            image_url
            created_at
            updated_at
            user {
                id
                first_name
                last_name
                slug
                email
                photo_url
                avatar
                display_name
                country
            }
            post_comments_aggregate {
                aggregate {
                    count
                }
            }
            post_likes_aggregate {
                aggregate {
                    count
                }
            }
        }
    }
`;

export const GET_POST_BY_SLUG = gql`
    query posts($slug: String!) {
        posts(where: { slug: { _eq: $slug } }) {
            id
            message
            slug
            user_id
            image_url
            created_at
            updated_at
            user {
                id
                first_name
                last_name
                slug
                email
                photo_url
                avatar
                display_name
                country
            }
            post_comments_aggregate {
                aggregate {
                    count
                }
            }
            post_likes_aggregate {
                aggregate {
                    count
                }
            }
        }
    }
`;

export const GET_POST_COMMENTS = gql`
    query post_comments($post_id: Int!) {
        post_comments(where: { post_id: { _eq: $post_id } }, order_by: { created_at: desc }) {
            id
            message
            user_id
            user {
                id
                first_name
                last_name
                slug
                avatar
            }
            post_id
            parent_id
            created_at
            likes_aggregate(where: { comment_id: { _is_null: false } }) {
                aggregate {
                    count
                }
            }
            children_aggregate {
                aggregate {
                    count
                }
            }
            children(order_by: { created_at: desc }) {
                id
                message
                user_id
                user {
                    id
                    first_name
                    last_name
                    slug
                    avatar
                }
                post_id
                parent_id
                created_at
                likes_aggregate(where: { comment_id: { _is_null: false } }) {
                    aggregate {
                        count
                    }
                }
                children_aggregate {
                    aggregate {
                        count
                    }
                }
                children(order_by: { created_at: desc }) {
                    id
                    message
                    user_id
                    user {
                        id
                        first_name
                        last_name
                        slug
                        avatar
                    }
                    post_id
                    parent_id
                    created_at
                    likes_aggregate(where: { comment_id: { _is_null: false } }) {
                        aggregate {
                            count
                        }
                    }
                    children_aggregate {
                        aggregate {
                            count
                        }
                    }
                    children(order_by: { created_at: desc }) {
                        id
                        message
                        user_id
                        user {
                            id
                            first_name
                            last_name
                            slug
                            avatar
                        }
                        post_id
                        parent_id
                        created_at
                        likes_aggregate(where: { comment_id: { _is_null: false } }) {
                            aggregate {
                                count
                            }
                        }
                        children_aggregate {
                            aggregate {
                                count
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const GET_POST_LIKES = gql`
    query post_likes($post_id: Int!) {
        post_likes(where: { post_id: { _eq: $post_id } }, order_by: { created_at: desc }) {
            id
            post_id
            user_id
            comment_id
            created_at
            updated_at
        }
    }
`;

export const GET_POST_LIKE_COUNT = gql`
    query post_likes_aggregate($post_id: Int!) {
        post_likes_aggregate(where: { post_id: { _eq: $post_id } }, order_by: { created_at: desc }) {
            aggregate {
                count
            }
        }
    }
`;

export const GET_POST_COMMENT_LIKE_COUNT = gql`
    query post_likes_aggregate($post_id: Int!, $comment_id: Int!) {
        post_likes_aggregate(where: { post_id: { _eq: $post_id }, comment_id: { _eq: $comment_id } }, order_by: { created_at: desc }) {
            aggregate {
                count
            }
        }
    }
`;

export const GET_USER_POST_LIKES = gql`
    query post_likes($post_id: Int!, $user_id: Int!) {
        post_likes(where: { user_id: { _eq: $user_id }, post_id: { _eq: $post_id }, comment_id: { _is_null: true } }, order_by: { created_at: desc }) {
            id
            post_id
            user_id
            comment_id
            created_at
            updated_at
        }
    }
`;

export const GET_USER_POST_COMMENT_LIKES = gql`
    query post_likes($post_id: Int!, $user_id: Int!, $comment_id: Int!) {
        post_likes(where: { user_id: { _eq: $user_id }, post_id: { _eq: $post_id }, comment_id: { _eq: $comment_id } }, order_by: { created_at: desc }) {
            id
            post_id
            user_id
            comment_id
            created_at
            updated_at
        }
    }
`;

export const GET_USER_FOLLOWERS = gql`
    query followers($followed_user_id: Int!) {
        followers(where: { followed_user_id: { _eq: $followed_user_id } }, order_by: { created_at: desc }) {
            id
            follower_user_id
            followed_user_id
            followed_date
            unfollowed_date
            created_at
            updated_at
        }
    }
`;

export const FOLLOWER_USER_QUERY = gql`
    query followers($followed_user_id: Int!, $follower_user_id: Int!) {
        followers(where: { followed_user_id: { _eq: $followed_user_id }, follower_user_id: { _eq: $follower_user_id } }, limit: 1) {
            id
            follower_user_id
            followed_user_id
            followed_date
            unfollowed_date
            created_at
            updated_at
        }
    }
`;

export const GET_USER_FRIEND_REQUESTS_QUERY = gql`
    query friend_requests($to_user_id: Int!) {
        friend_requests(where: { to_user_id: { _eq: $to_user_id }, is_accepted: { _is_null: false } }) {
            id
            from_user_id
            from_user {
                id
                slug
                first_name
                last_name
                avatar
            }
            to_user_id
            is_accepted
            created_at
            updated_at
        }
    }
`;

export const GET_USER_FRIEND_REQUESTS_SENT_QUERY = gql`
    query friend_requests($to_user_id: Int!, $from_user_id: Int!) {
        friend_requests(where: { to_user_id: { _eq: $to_user_id }, from_user_id: { _eq: $from_user_id } }, limit: 1) {
            id
            from_user_id
            to_user_id
            is_accepted
            created_at
            updated_at
        }
    }
`;

export const GET_USER_FRIENDS_QUERY = gql`
    query friends($user_id: Int!) {
        friends(where: { _or: [{ user1_id: { _eq: $user_id } }, { user2_id: { _eq: $user_id } }] }) {
            id
            user1_id
            user1 {
                id
                slug
                first_name
                last_name
                avatar
            }
            user2_id
            user2 {
                id
                slug
                first_name
                last_name
                avatar
            }
            status
            created_at
            updated_at
        }
    }
`;
export const GET_USER_NEARBY_VENUES_QUERY = gql`
    query vw_user_venue_distance($user_id: Int!) {
        vw_user_venue_distance(where: { user_id: { _eq: $user_id }, distance: { _lt: 40233 } }, order_by: { distance: asc }) {
            id
            name
            slug
            distance
            venue {
                id
                name
                slug
                address1
                city
                region
                country
                postal_code
            }
        }
    }
`;

export const GET_VENUE_NEARBY_USERS_QUERY = gql`
    query vw_user_venue_distance($venue_id: Int!) {
        vw_user_venue_distance(where: { id: { _eq: $venue_id }, distance: { _lt: 991000 } }) {
            user_id
            id
            name
            slug
            distance
        }
    }
`;

export const SEARCH_PLAYERS = gql`
    query players($name: String!) {
        players(where: { name: { _ilike: $name } }) {
            id
            name
            name_slug
            email
            aliases
            country
            country_phone
            city
            region
            phone_number
            gender
            properties
            image_large
            image_small
            fargo_id
            fargo_readable_id
            fargo_robustness
            fargo_effective_rating
            fargo_rating
            fargo_uuid
            user_id
            user {
                id
                first_name
                last_name
                slug
            }
        }
    }
`;
