import React, { useState, useEffect } from 'react';
import { Table, Tooltip, Button, Icon, Modal } from 'antd';
import { Query } from '@apollo/client/react/components';
import { GET_TOURNAMENT_MANAGERS, CHECK_TOURNAMENT_MANAGERS_INVITE_USER } from './data/queries';
import { DELETE_TOURNAMENT_MANAGER, DELETE_TOURNAMENT_MANAGER_INVITE_KEY } from './data/mutations';
import { reduce } from 'highcharts';
import CircularLoader from '../../components/CircularLoader';

const { confirm } = Modal;

function TableManagerTable(props) {
    const { tournamentId, tournamentDirectorId, client, userId } = props;
    const [tableDataSource, setTableDataSource] = useState([]);

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            defaultSortOrder: 'ascend',
            key: 'name',
            width: 200,
            sorter: (a, b) => {
                return a.name.localeCompare(b.name);
            },
        },
        {
            title: 'Role',
            dataIndex: 'role',
            key: 'role',
            sorter: (a, b) => {
                return a.role.localeCompare(b.role);
            },
        },
        {
            title: 'Accepted',
            dataIndex: 'accepted_invite',
            key: 'accepted_invite',
            sorter: (a, b) => {
                return a.accepted_invite.localeCompare(b.accepted_invite);
            },
            render: (text, record) => (
                <React.Fragment>
                    <span style={record.accepted_invite === 'Yes' ? { color: 'green' } : record.accepted_invite === 'No' ? { color: 'red' } : {}}>
                        {text || '-'}
                    </span>
                </React.Fragment>
            ),
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => {
                return record.user_id !== userId && record.user_id !== tournamentDirectorId ? (
                    <span style={{ whiteSpace: 'nowrap' }}>
                        <Tooltip title="Delete">
                            <Button
                                type="ghost"
                                onClick={async (e) => {
                                    e.preventDefault();
                                    e.stopPropagation();

                                    confirm({
                                        title: `Are you sure you want to delete this manager?`,
                                        centered: true,
                                        transitionName: 'fade',
                                        maskTransitionName: 'none',
                                        async onOk() {
                                            await client.mutate({
                                                mutation: DELETE_TOURNAMENT_MANAGER,
                                                variables: {
                                                    id: record.id,
                                                },
                                                notifyOnNetworkStatusChange: true,
                                                awaitRefetchQueries: true,
                                                refetchQueries: [
                                                    {
                                                        query: GET_TOURNAMENT_MANAGERS,
                                                        variables: { tournament_id: tournamentId },
                                                    },
                                                ],
                                            });

                                            if (!record.email) return; // the user was not invited

                                            let managerInviteKeyCheck = await client.query({
                                                query: CHECK_TOURNAMENT_MANAGERS_INVITE_USER,
                                                variables: {
                                                    tournament_id: tournamentId,
                                                    email: record.email,
                                                },
                                            });

                                            if (
                                                managerInviteKeyCheck &&
                                                managerInviteKeyCheck.data &&
                                                managerInviteKeyCheck.data.tournament_managers_invite_keys &&
                                                managerInviteKeyCheck.data.tournament_managers_invite_keys[0]
                                            ) {
                                                console.log('mi', managerInviteKeyCheck);
                                                await client.mutate({
                                                    mutation: DELETE_TOURNAMENT_MANAGER_INVITE_KEY,
                                                    variables: {
                                                        tournament_id: tournamentId,
                                                        email: record.email,
                                                    },
                                                });
                                            }
                                        },
                                        onCancel() {},
                                        cancelText: 'No',
                                        okText: 'Yes',
                                    });
                                }}
                            >
                                <Icon type="delete" />
                            </Button>
                        </Tooltip>
                    </span>
                ) : (
                    <div></div>
                );
            },
        },
    ];

    return (
        <React.Fragment>
            {tournamentId && (
                <Query
                    query={GET_TOURNAMENT_MANAGERS}
                    fetchPolicy="cache-and-network"
                    notifyOnNetworkStatusChange
                    awaitRefetchQueries
                    variables={{
                        tournament_id: tournamentId,
                    }}
                >
                    {({ loading, error, data }) => {
                        if (loading) return <CircularLoader />;
                        if (error) return null;

                        let tournamentManagers = data && data.tournament_managers;
                        let dataSource = [];
                        let tournamentManagerObj, manager;

                        console.log('managers', tournamentManagers);

                        for (let i = 0; i < tournamentManagers.length; i++) {
                            manager = tournamentManagers[i];

                            tournamentManagerObj = {
                                id: manager.id,
                                email: manager.email,
                                user_id: manager && manager.manager !== null ? manager.manager.id : '',
                                name: manager.manager
                                    ? manager.manager.first_name
                                        ? `${manager.manager.first_name} ${manager.manager.last_name}`
                                        : manager.manager.display_name
                                    : manager.email,
                                accepted_invite: manager.invite_accepted ? 'Yes' : 'No',
                                role: manager && manager.manager && tournamentDirectorId === manager.manager.id ? 'Director' : 'Manager',
                                key: i,
                            };

                            dataSource.push(tournamentManagerObj);
                        }

                        return (
                            <Table
                                columns={columns}
                                // dataSource={dataSource.filter(item => item.user_id !== userId && item.user_id !== tournamentDirectorId)}
                                dataSource={dataSource}
                                pagination={false}
                                tableLayout="auto"
                                scroll={{ x: 400 }}
                                size="default"
                            />
                        );
                    }}
                </Query>
            )}
        </React.Fragment>
    );
}

export default TableManagerTable;
