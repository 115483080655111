import React, { useState, useEffect } from 'react';
import { message, List, Tabs, Row, Col, Radio, Table, Modal, Layout, Button, Tag, Icon, Tooltip, Typography, Empty, Input } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import Match from '../../screens/digitalpool/tournaments/bracket/Match';
import CircleFlag from '../../components/CircleFlag';
import PlayerModal from '../../screens/digitalpool/tournaments/bracket/PlayerModal';

const { TabPane } = Tabs;
const { Search } = Input;
const { Text, Title } = Typography;
const { Sider, Content, Header } = Layout;
const { confirm } = Modal;

export default function RoundRobinGroup(props) {
    const { history, tournament, group, players, index, matches, tables, isPublic, theme, onShowScoreModal } = props;
    const [toggle, setToggle] = useState('matches');
    const [playerModalVisible, setPlayerModalVisible] = useState(false);
    const [selectedPlayer, setSelectedPlayer] = useState({});

    const columns = [
        {
            title: 'Rank',
            dataIndex: 'rank',
            key: 'rank',
            defaultSortOrder: 'ascend',
            width: 80,
            sorter: (a, b) => {
                return a.rank - b.rank;
            },
            render: (text, record) => {
                console.log(record);
                return <Text>{text}</Text>;
            },
        },
        {
            title: 'Player',
            dataIndex: 'player',
            key: 'player',
            sorter: (a, b) => {
                return a.player && b.player && a.player.name.localeCompare(b.player.name);
            },
            render: (text, record) => {
                return (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        {record.country && <CircleFlag country={record.country} left={0} />}
                        <span style={{ marginLeft: record && record.country ? 10 : 0 }}>{record.name}</span>
                    </div>
                );
            },
        },
        {
            title: 'Played',
            dataIndex: 'played',
            key: 'played',
            sorter: (a, b) => {
                return a.played - b.played;
            },
            render: (text, record) => {
                return <Text>{text || 0}</Text>;
            },
        },
        {
            title: 'Remaining',
            dataIndex: 'remaining',
            key: 'remaining',
            sorter: (a, b) => {
                return a.remaining - b.remaining;
            },
            render: (text, record) => {
                return <Text>{text || 0}</Text>;
            },
        },
        {
            title: 'W-L-T',
            dataIndex: 'win_loss_tie',
            key: 'win_loss_tie',
            sorter: (a, b) => {
                return a.win_loss_tie - b.win_loss_tie;
            },
            render: (text, record) => {
                return <Text>{text || `0-0-0`}</Text>;
            },
        },
        {
            title: 'TB',
            dataIndex: 'tiebreak',
            key: 'tiebreak',
            sorter: (a, b) => {
                return a.tiebreak - b.tiebreak;
            },
            render: (text, record) => {
                return <Text>{text || 0}</Text>;
            },
        },
        {
            title: 'Set wins',
            dataIndex: 'set_wins',
            key: 'set_wins',
            sorter: (a, b) => {
                return a.set_wins - b.set_wins;
            },
            render: (text, record) => {
                return <Text>{text || 0}</Text>;
            },
        },
        {
            title: 'Set ties',
            dataIndex: 'set_ties',
            key: 'set_ties',
            sorter: (a, b) => {
                return a.set_ties - b.set_ties;
            },
            render: (text, record) => {
                return <Text>{text || 0}</Text>;
            },
        },
        {
            title: 'Points',
            dataIndex: 'points',
            key: 'points',
            sorter: (a, b) => {
                return a.points - b.points;
            },
            render: (text, record) => {
                return <Text>{text || 0}</Text>;
            },
        },
        {
            title: 'Match History',
            dataIndex: 'matches',
            key: 'matches',
            disabled: true,
            render: (text, record) => {
                return <Text>{renderMatchHistory(record.matches, record.id)}</Text>;
            },
        },
    ];

    function renderMatchHistory(matchesWithoutByes, playerId) {
        return (
            <Text>
                {matchesWithoutByes
                    .sort((a, b) => {
                        return a.match_number - b.match_number;
                    })
                    .filter((match) => {
                        return match.challenger1_is_winner === true || match.challenger2_is_winner === true;
                    })
                    .map((match, index) => {
                        const result =
                            match.challenger1_id === playerId && match.challenger1_is_winner === true
                                ? 'W'
                                : match.challenger2_id === playerId && match.challenger2_is_winner === true
                                ? 'W'
                                : match.challenger1_is_winner !== true && match.challenger2_is_winner !== true
                                ? '-'
                                : match.challenger1_is_forfeit === true || match.challenger2_is_forfeit === true
                                ? 'FF'
                                : 'L';

                        const opponentName =
                            match.challenger1_id === playerId ? match.challenger2_name : match.challenger2_id === playerId ? match.challenger1_name : null;
                        const playerScore =
                            match.challenger1_id === playerId ? match.challenger1_score : match.challenger2_id === playerId ? match.challenger2_score : 0;
                        const opponentScore =
                            match.challenger1_id !== playerId ? match.challenger1_score : match.challenger2_id !== playerId ? match.challenger2_score : 0;

                        return (
                            <React.Fragment key={index}>
                                <Tooltip placement="top" title={`${result} vs. ${opponentName} (${playerScore}-${opponentScore})`}>
                                    <span
                                        className={
                                            (match.challenger1_id === playerId && match.challenger1_is_winner === true) ||
                                            (match.challenger2_id === playerId && match.challenger2_is_winner === true)
                                                ? 'match-badge win'
                                                : 'match-badge loss'
                                        }
                                    >
                                        {result}
                                    </span>
                                </Tooltip>
                            </React.Fragment>
                        );
                    })}
            </Text>
        );
    }

    function handleScoreModal(match, round) {
        onShowScoreModal(match, round);
    }

    function handleGroupToggle(event) {
        const toggle = event.target.value;
        setToggle(toggle);
    }

    function handleShowPlayerModal(player) {
        console.log(player);
        setSelectedPlayer(player);
        setPlayerModalVisible(true);
    }

    function handlePlayerModalVisible(visibility) {
        setPlayerModalVisible(visibility);
    }

    return (
        <div style={{ marginTop: 15 }}>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: 15 }}>
                <Title level={4} style={{ fontSize: 18, margin: 0, padding: 0, color: theme.bracket.panels.textColor }}>
                    Group {index + 1}
                </Title>
                <div style={{ marginLeft: 20 }}>
                    <Radio.Group onChange={handleGroupToggle} value={toggle} defaultValue="matches" size="small" buttonStyle="outlined">
                        <Radio.Button value="matches">Matches</Radio.Button>
                        <Radio.Button value="standings">Standings</Radio.Button>
                    </Radio.Group>
                </div>
            </div>
            {toggle === 'matches' ? (
                <div style={{ position: 'relative', borderRadius: 7 }} className={`bracket-compact ${theme && theme.name}`}>
                    <div style={{ width: '100%', overflow: 'auto', display: 'flex' }}>
                        {group.rounds &&
                            group.rounds.map((row, index) => {
                                return (
                                    <div key={index} style={{ minWidth: 300 }}>
                                        <div
                                            style={{
                                                padding: 10,
                                                marginBottom: 10,
                                                borderBottom: theme && theme.name === 'dark' ? '1px solid rgba(255,255,255,0.1)' : '1px solid rgba(0,0,0,0.1)',
                                            }}
                                        >
                                            <Text>Round {index + 1}</Text>
                                        </div>
                                        {row.map((match, index2) => {
                                            const challenger1_score = parseInt(match.challenger1_score);
                                            const challenger2_score = parseInt(match.challenger2_score);

                                            return (
                                                <div
                                                    key={index2}
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        width: '100%',
                                                    }}
                                                >
                                                    <div style={{ width: '100%', transform: 'scale(0.9)' }}>
                                                        <Match
                                                            {...props}
                                                            tournament={tournament}
                                                            match={match}
                                                            matches={matches}
                                                            tables={tables}
                                                            className={`match  ${(match.status && match.status.toLowerCase()) || 'not_started'} ${
                                                                match.status === 'IN_PROGRESS' &&
                                                                match.pool_table &&
                                                                match.pool_table.is_streaming_table &&
                                                                match.pool_table.is_streaming_table === true
                                                                    ? 'streaming'
                                                                    : ''
                                                            }  ${match.is_bye === true ? 'bye' : ''}  ${
                                                                match.status === 'NOT_STARTED' && match.pool_table && match.pool_table.id ? 'assigned' : ''
                                                            } ${match.pool_table && match.pool_table.status === 'ASSIGNED' ? 'assigned' : ''} ${
                                                                match.challenger1_name && match.challenger2_name ? 'has_players' : ''
                                                            }`}
                                                            activePlayerHover={false}
                                                            roundNumber={match.round}
                                                            startTime={match.start_time}
                                                            scheduledTime={match.scheduled_time}
                                                            number={match.match_number}
                                                            status={match.status}
                                                            table={match.pool_table && match.pool_table}
                                                            tableName={match.pool_table && match.pool_table.label}
                                                            isLive={match.status === 'LIVE' || match.status === 'IN_PROGRESS'}
                                                            isScheduled={match.scheduled_time}
                                                            isCompleted={match.status === 'COMPLETED'}
                                                            isPending={match.status === 'PENDING'}
                                                            // payout={payout}
                                                            progress={match.progress}
                                                            players={[
                                                                {
                                                                    id: match.challenger1_id,
                                                                    playerName: match.challenger1_name,
                                                                    playerNumber: '',
                                                                    score: challenger1_score,
                                                                    place: match.challenger1 && match.challenger1.place,
                                                                    skillLevel: match.challenger1
                                                                        ? match.challenger1.skill_level
                                                                        : match.challenger1_skill_level,
                                                                    raceTo: match.challenger1_race_to,
                                                                    isWinner: match.challenger1_is_winner,
                                                                    isWinning: challenger1_score && challenger1_score > challenger2_score ? true : false,
                                                                    isLoser:
                                                                        match.status === 'COMPLETED' && match.challenger1_is_winner === false ? true : false,
                                                                    isLosing:
                                                                        match.status !== 'COMPLETED' &&
                                                                        (challenger1_score || challenger2_score) &&
                                                                        challenger1_score < challenger2_score
                                                                            ? true
                                                                            : false,
                                                                    isTied:
                                                                        (challenger1_score || challenger2_score) && challenger1_score === challenger2_score
                                                                            ? true
                                                                            : false,
                                                                    isForfeit: match.challenger1_is_forfeit,
                                                                    isWithdraw: match.challenger1_is_withdraw,
                                                                    seed: match.challenger1_seed && match.challenger1_seed,
                                                                    country: match.challenger1_country,
                                                                },
                                                                {
                                                                    id: match.challenger2_id,
                                                                    playerName: match.challenger2_name,
                                                                    playerNumber: '',
                                                                    score: challenger2_score,
                                                                    place: match.challenger2 && match.challenger2.place,
                                                                    raceTo: match.challenger2_race_to,
                                                                    skillLevel: match.challenger2
                                                                        ? match.challenger2.skill_level
                                                                        : match.challenger2_skill_level,
                                                                    isWinner: match.challenger2_is_winner,
                                                                    isWinning: challenger2_score && challenger2_score > challenger1_score ? true : false,
                                                                    isLoser:
                                                                        match.status === 'COMPLETED' && match.challenger2_is_winner === false ? true : false,
                                                                    isLosing:
                                                                        match.status !== 'COMPLETED' &&
                                                                        (challenger1_score || challenger2_score) &&
                                                                        challenger2_score < challenger1_score
                                                                            ? true
                                                                            : false,
                                                                    isTied:
                                                                        (challenger1_score || challenger2_score) && challenger1_score === challenger2_score
                                                                            ? true
                                                                            : false,
                                                                    isForfeit: match.challenger2_is_forfeit,
                                                                    isWithdraw: match.challenger2_is_withdraw,
                                                                    seed: match.challenger2_seed && match.challenger2_seed,
                                                                    country: match.challenger2_country,
                                                                },
                                                            ]}
                                                            style={{ minHeight: 66 }}
                                                            theme={theme}
                                                            // footerText={compact !== true && renderFooterText(rounds[0].round, match)}
                                                            // ref={this.r1m1}
                                                            onClick={(e) => {
                                                                // console.log(rounds);
                                                                console.log(match);

                                                                if (isPublic !== true) {
                                                                    // console.log(e.defaultPrevented);
                                                                    // MapInteraction will set defaultPrevented to true if the touchend/mouseup event happened after a drag, and false if it was a click. See index.stories.js for an example.
                                                                    // https://github.com/transcriptic/react-map-interaction
                                                                    if (e.defaultPrevented === false || e.defaultPrevented === true) {
                                                                        handleScoreModal(match, group.rounds);
                                                                    }
                                                                    // showDrawer({
                                                                    // 	title: `Match R1-M${index + 1}`,
                                                                    // 	slug: `match-r1-m${index + 1}`,
                                                                    // 	status: 'completed'
                                                                    // });
                                                                }
                                                            }}
                                                            onTouchEnd={(e) => {
                                                                if (isPublic !== true) {
                                                                    console.log(e.defaultPrevented);
                                                                    // MapInteraction will set defaultPrevented to true if the touchend/mouseup event happened after a drag, and false if it was a click. See index.stories.js for an example.
                                                                    // https://github.com/transcriptic/react-map-interaction
                                                                    if (e.defaultPrevented === false) {
                                                                        handleScoreModal(match, group.rounds);
                                                                    }

                                                                    // showDrawer({
                                                                    // 	title: `Match R1-M${index + 1}`,
                                                                    // 	slug: `match-r1-m${index + 1}`,
                                                                    // 	status: 'completed'
                                                                    // });
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                );
                            })}
                    </div>
                    <div
                        style={{
                            padding: 10,
                            marginBottom: 10,
                            borderTop: theme && theme.name === 'dark' ? '1px solid rgba(255,255,255,0.1)' : '1px solid rgba(0,0,0,0.1)',
                        }}
                    >
                        <Text>Players advancing: N/A</Text>
                    </div>
                </div>
            ) : (
                <div style={{ position: 'relative', borderRadius: 7 }} className={`bracket-compact ${theme && theme.name}`}>
                    <Table
                        rowKey="id"
                        pagination={false}
                        className="match-table table-striped-rows"
                        columns={columns}
                        dataSource={
                            players &&
                            players.map((player, inxex) => {
                                console.log(player);
                                return {
                                    ...player,
                                    rank: index + 1,
                                    played: player.matches && player.matches.length,
                                    remaining: player.matches && player.matches.length - player.matches_won + player.matches_lost,
                                    win_loss_tie: `${player.matches_won}-${player.matches_lost}-0`,
                                    tiebreak: 0,
                                    set_wins: player.matches_won,
                                    set_ties: player.matches_tied,
                                    points: player.points,
                                    matches: player.matches,
                                };
                            })
                        }
                        size="small"
                        tableLayout="auto"
                        scroll={{ x: 400 }}
                        // pagination={{ pageSize: 25 }}
                        onRow={(record, rowIndex) => {
                            console.log(record);
                            return {
                                onClick: (event) => {
                                    handleShowPlayerModal(record);
                                }, // click row
                                onDoubleClick: (event) => {}, // double click row
                                onContextMenu: (event) => {}, // right button click row
                                onMouseEnter: (event) => {}, // mouse enter row
                                onMouseLeave: (event) => {}, // mouse leave row
                            };
                        }}
                        onHeaderRow={(column) => {
                            return {
                                onClick: () => {}, // click header row
                            };
                        }}
                    />
                    <div
                        style={{
                            padding: 10,
                            marginBottom: 10,
                            borderTop: theme && theme.name === 'dark' ? '1px solid rgba(255,255,255,0.1)' : '1px solid rgba(0,0,0,0.1)',
                        }}
                    >
                        <Text>Players advancing: N/A</Text>
                    </div>
                </div>
            )}

            <PlayerModal
                {...props}
                tournament={tournament}
                tournamentID={tournament.id}
                tables={tables}
                matches={matches}
                playerId={selectedPlayer.id}
                modalVisible={playerModalVisible}
                onPlayerModalVisible={handlePlayerModalVisible}
            />
        </div>
    );
}

{
    /* <div className="match-data">
    <div className="match-scores">
        <div className="player">
            <div className="player-seed">{match.challenger1_seed}</div>
            <div className="player-name">{match.challenger1_name}</div>
            <div className="player-score">-</div>
        </div>
        <div className="player">
            <div className="player-seed">{match.challenger2_seed}</div>
            <div className="player-name">{match.challenger2_name}</div>
            <div className="player-score">-</div>
        </div>
    </div>
</div>
 <div>{match.identifier}</div> */
}
