import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { message, Button, Layout, Alert } from 'antd';
import App from './App';
import { auth, ref, providers, helpers } from './firebase';
import { gql } from '@apollo/client';
// import gql from 'graphql-tag';
// import { InMemoryCache } from 'apollo-cache-inmemory';
// import { persistCache } from 'apollo-cache-persist';
// import { split } from 'apollo-link';
// import { HttpLink } from 'apollo-link-http';
// import { WebSocketLink } from 'apollo-link-ws';
// import { SubscriptionClient } from 'subscriptions-transport-ws'; // <- import this
// import { getMainDefinition } from 'apollo-utilities';

import ScrollingWrapper from './components/ScrollingWrapper';
import CircularLoader from './components/CircularLoader';
import moment from 'moment';
import slugify from 'slugify';
import { Offline, Online } from 'react-detect-offline';
import clearRender from 'clear-render';
import _ from 'lodash';
import { getAuthStatus } from './getAuthStatus';
import { getAuth, signInWithPopup, FacebookAuthProvider, GoogleAuthProvider, TwitterAuthProvider } from 'firebase/auth';
import { useApolloClient } from '@apollo/client';
import mixpanel from 'mixpanel-browser';
mixpanel.init('bd28bad325e033118fc0145d76d3c378');

// function GlobalLoadingIndicator() {
//     const status = useApolloNetworkStatus();

//     if (status.numPendingQueries > 0) {
//         return (
//             <div
//                 style={{
//                     position: 'absolute',
//                     top: 20,
//                     left: 65,
//                     zIndex: 100,
//                 }}
//             >
//                 <CircularLoader />
//             </div>
//         );
//     } else {
//         return null;
//     }
// }

const { Header, Content, Footer } = Layout;

const CREATE_USER_MUTATION = gql`
    mutation upsert_users($objects: [users_insert_input!]!) {
        insert_users(objects: $objects, on_conflict: { constraint: users_uid_key, update_columns: [] }) {
            affected_rows
            returning {
                id
                first_name
                last_name
                slug
                email
                email_verified
                role
                uid
                uuid
                last_seen
                token
                photo_url
                fargo_id
                player_id
            }
        }
    }
`;

const UPDATE_USER_MUTATION = gql`
    mutation update_users($uid: String, $timestamp: timestamptz!, $token: String) {
        update_users(where: { uid: { _eq: $uid }, _and: { uid: { _neq: "" } } }, _set: { last_seen: $timestamp, token: $token }) {
            affected_rows
            returning {
                id
                first_name
                last_name
                slug
                country
                region
                city
                country_phone
                fargo_id
                player_id
                email
                role
                uid
                uuid
                last_seen
                token
                avatar
            }
        }
    }
`;

export const GET_USER = gql`
    query users($email: String!) {
        users(where: { email: { _eq: $email } }) {
            id
            first_name
            last_name
            slug
            country
            region
            city
            country_phone
            fargo_id
            player_id
            email
            role
            uid
            uuid
            last_seen
            token
            avatar
        }
    }
`;

// let wsClient;
let client;

function Auth(props) {
    const [authState, setAuthStateObj] = useState({ status: 'loading' });

    // const client = useApolloClient();
    console.log('auth client', client);

    useEffect(() => {
        const isLogin = localStorage.getItem('digitalpool-auth');
        const savedAuthState = isLogin && JSON.parse(isLogin);
        let isIn;
        let authToken;

        console.log('local storage auth', savedAuthState);

        let newAuthState = {};
        if (savedAuthState && savedAuthState.status === 'in') {
            const authUser = JSON.parse(localStorage.getItem('digitalpool-auth'));
            isIn = authUser.status === 'in';
            authToken = authUser.token;
            // if (isIn) {
            // 	console.log(authUser);
            // 	message.success(`Logged in as ${authUser.user.email}`);
            // }
            newAuthState = authUser;
            console.log('new auth state', newAuthState);
            setAuthState(newAuthState);
        } else {
            getAuthStatus()
                .then(async (user) => {
                    // message.success(`Logged in as ${user.email}`);
                    // var userProfile = auth.currentUser;
                    const { email, displayName, emailVerified, photoURL, isAnonymous, uid, providerData } = user;
                    const userProfile = {
                        displayName,
                        email,
                        emailVerified,
                        photoURL,
                        isAnonymous,
                        uid,
                        providerData,
                    };

                    console.log('user profile from firebase', userProfile);

                    const token = await user.getIdToken(true);
                    const idTokenResult = await user.getIdTokenResult();
                    // console.log('token from firebase', token);
                    console.log('token result from firebase', idTokenResult);
                    const hasuraClaim = idTokenResult.claims['https://hasura.io/jwt/claims'];

                    const timestamp = moment().format();

                    mixpanel.identify(uid);
                    mixpanel.people.set({
                        $email: email, // only special properties need the $
                        'User ID': uid, // use human-readable names
                        $name: displayName,
                        'Last Seen': timestamp,
                        'Account Type': 'user',
                    });

                    let res;

                    if (client) {
                        res = await client.mutate({
                            mutation: UPDATE_USER_MUTATION,
                            variables: {
                                uid: uid,
                                timestamp: timestamp,
                                token: token,
                            },
                        });
                    }

                    if (hasuraClaim && res) {
                        console.log('hasura claim', hasuraClaim);
                        let userHasura = res.data.update_users.returning[0];
                        console.log('user from hasura', userHasura);
                        if (!displayName && userHasura && userHasura.firstName) {
                            userProfile.displayName = `${userHasura.first_name} ${userHasura.last_name}`;
                        }

                        const mergedUser = _.assign({}, userProfile, userHasura);
                        localStorage.setItem('digitalpool-auth', JSON.stringify({ status: 'in', user: mergedUser, token }));
                        newAuthState = { status: 'in', user: mergedUser, token, uid, timestamp };
                    } else {
                        if (userProfile && userProfile.providerData && userProfile.providerData[0] && userProfile.providerData[0].providerId !== 'password') {
                            // Check if refresh is required.
                            // const metadataRef = ref('metadata/' + user.uid + '/refreshTime');
                            // metadataRef.on('value', async () => {
                            //     // Force refresh to pick up the latest custom claims changes.
                            //     const token = await user.getIdToken(true);
                            //     // console.log(token);
                            //     localStorage.setItem('digitalpool-auth', JSON.stringify({ status: 'in', user, userProfile, token }));
                            //     context.setAuthState({ status: 'in', user, userProfile, token });
                            // });

                            const token = await user.getIdToken(true);
                            // console.log(token);
                            localStorage.setItem('digitalpool-auth', JSON.stringify({ status: 'in', user, userProfile, token }));
                            newAuthState = { status: 'in', user, userProfile, token };
                        }
                    }

                    setAuthState(newAuthState);
                })
                .catch((err, context) => {
                    console.log(err);
                    console.log('remove user from local storage');
                    localStorage.removeItem('digitalpool-auth');
                    setAuthState({ status: 'out' });
                });
        }
    }, []);

    const setAuthState = (state) => {
        localStorage.setItem('digitalpool-auth', JSON.stringify(state));
        if (state.status === 'in') {
            message.success(`Logged in as ${state.user.email}`);
        }
        setAuthStateObj(state);
    };

    const resetPassword = (email) => {
        helpers
            .resetPassword(email)
            .then(() => {
                console.log(`Password reset instructions sent to ${email}.`);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const signInWithGoogle = () => {
        try {
            return signInWithPopup(auth, providers.google).then(async (result) => {
                // This gives you a Google Access Token. You can use it to access the Google API.
                const credential = GoogleAuthProvider.credentialFromResult(result);
                const token = credential.accessToken;
                // The signed-in user info.
                const user = result.user;

                let existingUser = await client.query({
                    query: GET_USER,
                    variables: {
                        email: decodeURIComponent(user.email),
                    },
                });

                const userHasura = existingUser.data.users[0];
                if (userHasura) {
                    const mergedUser = userHasura;
                    setAuthState({ status: 'in', user: mergedUser, token });
                    message.success('You are now signed in with your Google account');
                } else {
                    const timestamp = moment().format();
                    const slug = slugify(user.displayName, {
                        replacement: '-',
                        remove: /[*+~.,()'"#!:/@]/g,
                        lower: true,
                    });
                    let firstName;
                    let lastName;
                    const name = user.displayName.split(' ');
                    if (name) {
                        firstName = name[0];
                        lastName = name[1];
                    }

                    const changes = {
                        first_name: firstName,
                        last_name: lastName,
                        slug: slug,
                        email: user.email,
                        email_verified: user.emailVerified,
                        role: 'user',
                        uid: user.uid,
                        last_seen: timestamp,
                        token: token,
                        photo_url: user.photoURL,
                    };

                    return client
                        .mutate({
                            mutation: CREATE_USER_MUTATION,
                            variables: {
                                objects: changes,
                            },
                        })
                        .then((res) => {
                            const userData = res.data && res.data.insert_users && res.data.insert_users.returning && res.data.insert_users.returning[0];
                            // const mergedUser = _.assign({}, user, userHasura);
                            console.log('merged new user', userData);
                            setAuthState({ status: 'in', user: userData, token });
                            message.success('You are now signed in with your Google account');
                            return userData;
                        })
                        .catch((e) => {
                            const err = (e && e.message) || JSON.stringify(e);
                            console.log('error', err);
                            return err;
                        });
                }
            });
        } catch (error) {
            console.log(error);
            return error;
        }
    };

    const signInWithFacebook = () => {
        try {
            return signInWithPopup(auth, providers.facebook)
                .then(async (result) => {
                    // The signed-in user info.
                    var user = result.user;

                    // This gives you a Facebook Access Token. You can use it to access the Facebook API.
                    const credential = FacebookAuthProvider.credentialFromResult(result);
                    const token = credential.accessToken;

                    let existingUser = await client.query({
                        query: GET_USER,
                        variables: {
                            email: decodeURIComponent(user.email),
                        },
                    });

                    const userHasura = existingUser.data.users[0];
                    if (userHasura) {
                        const mergedUser = userHasura;
                        setAuthState({ status: 'in', user: mergedUser, token });
                        message.success('You are now signed in with your Google account');
                    } else {
                        const timestamp = moment().format();
                        const slug = slugify(user.displayName, {
                            replacement: '-',
                            remove: /[*+~.,()'"#!:/@]/g,
                            lower: true,
                        });
                        let firstName;
                        let lastName;
                        const name = user.displayName.split(' ');
                        if (name) {
                            firstName = name[0];
                            lastName = name[1];
                        }

                        const changes = {
                            first_name: firstName,
                            last_name: lastName,
                            slug: slug,
                            email: user.email,
                            email_verified: user.emailVerified,
                            role: 'user',
                            uid: user.uid,
                            last_seen: timestamp,
                            token: token,
                            photo_url: user.photoURL,
                        };

                        return client
                            .mutate({
                                mutation: CREATE_USER_MUTATION,
                                variables: {
                                    objects: changes,
                                },
                            })
                            .then((res) => {
                                const userData = res.data && res.data.insert_users && res.data.insert_users.returning && res.data.insert_users.returning[0];
                                // const mergedUser = _.assign({}, user, userHasura);
                                console.log('merged new user', userData);
                                setAuthState({ status: 'in', user: userData, token });
                                message.success('You are now signed in with your Google account');
                                return userData;
                            })
                            .catch((e) => {
                                const err = (e && e.message) || JSON.stringify(e);
                                console.log('error', err);
                                return err;
                            });
                    }
                })
                .catch(function (error) {
                    // Handle Errors here.
                    var errorCode = error.code;
                    var errorMessage = error.message;
                    // The email of the user's account used.
                    var email = error.email;
                    // The firebase.auth.AuthCredential type that was used.
                    var credential = error.credential;

                    console.log({
                        errorCode,
                        errorMessage,
                        email,
                        credential,
                    });

                    return {
                        error: {
                            errorCode,
                            errorMessage,
                            email,
                            credential,
                        },
                    };
                });
        } catch (error) {
            console.log(error);
            return error;
        }
    };

    const signInWithTwitter = () => {
        try {
            return auth
                .signInWithPopup(auth, providers.twitter)
                .then(async (result) => {
                    // This gives you a the Twitter OAuth 1.0 Access Token and Secret.
                    // You can use these server side with your app's credentials to access the Twitter API.
                    const credential = TwitterAuthProvider.credentialFromResult(result);
                    const token = credential.accessToken;
                    const secret = credential.secret;

                    // The signed-in user info.
                    const user = result.user;

                    let existingUser = await client.query({
                        query: GET_USER,
                        variables: {
                            email: decodeURIComponent(user.email),
                        },
                    });

                    const userHasura = existingUser.data.users[0];
                    if (userHasura) {
                        const mergedUser = userHasura;
                        setAuthState({ status: 'in', user: mergedUser, token });
                        message.success('You are now signed in with your Google account');
                    } else {
                        const timestamp = moment().format();
                        const slug = slugify(user.displayName, {
                            replacement: '-',
                            remove: /[*+~.,()'"#!:/@]/g,
                            lower: true,
                        });
                        let firstName;
                        let lastName;
                        const name = user.displayName.split(' ');
                        if (name) {
                            firstName = name[0];
                            lastName = name[1];
                        }

                        const changes = {
                            first_name: firstName,
                            last_name: lastName,
                            slug: slug,
                            email: user.email,
                            email_verified: user.emailVerified,
                            role: 'user',
                            uid: user.uid,
                            last_seen: timestamp,
                            token: token,
                            photo_url: user.photoURL,
                        };

                        return client
                            .mutate({
                                mutation: CREATE_USER_MUTATION,
                                variables: {
                                    objects: changes,
                                },
                            })
                            .then((res) => {
                                const userData = res.data && res.data.insert_users && res.data.insert_users.returning && res.data.insert_users.returning[0];
                                // const mergedUser = _.assign({}, user, userHasura);
                                console.log('merged new user', userData);
                                setAuthState({ status: 'in', user: userData, token });
                                message.success('You are now signed in with your Google account');
                                return userData;
                            })
                            .catch((e) => {
                                const err = (e && e.message) || JSON.stringify(e);
                                console.log('error', err);
                                return err;
                            });
                    }
                })
                .catch(function (error) {
                    // Handle Errors here.
                    var errorCode = error.code;
                    var errorMessage = error.message;
                    // The email of the user's account used.
                    var email = error.email;
                    // The firebase.auth.AuthCredential type that was used.
                    var credential = error.credential;

                    console.log({
                        errorCode,
                        errorMessage,
                        email,
                        credential,
                    });

                    return {
                        error: {
                            errorCode,
                            errorMessage,
                            email,
                            credential,
                        },
                    };
                });
        } catch (error) {
            console.log(error);
        }
    };

    const signOut = async () => {
        try {
            // this.setAuthState({ status: 'loading' });
            if (authState && authState.user) {
                const { user } = authState;
                mixpanel.track('Logout', {
                    ID: user.id,
                    Name: user.displayName,
                    Email: user.email,
                    Country: user.country,
                    Region: user.region,
                    City: user.city,
                    'Fargo ID': user.fargo_id,
                    'Email Verified': user.emailVerified,
                    UID: user.uid,
                    Source: 'web',
                });
            }
            await auth.signOut();
            setAuthState({ status: 'out' });
            // wsClient.unsubscribeAll();
            // wsClient.close();

            const str = 'You are logged out';
            message.warn(str);
            return Promise.resolve(str);
        } catch (error) {
            console.log(error);
            return Promise.reject(error);
        }
    };

    const authContext = React.createContext({
        authState: authState,
        authUser: authState.user,
        setAuthState: setAuthState,
        signInWithFacebook: signInWithFacebook,
        signInWithGoogle: signInWithGoogle,
        signInWithTwitter: signInWithTwitter,
        resetPassword: resetPassword,
        client: client,
    });

    return (
        <div className={`logged-${authState ? authState.status : 'out'}`} id="auth">
            {authState.status !== 'loading' && (
                <React.Fragment>
                    {/* <GlobalLoadingIndicator /> */}
                    {/* <Online>Only shown when you're online</Online> */}
                    {/* <Offline>
                            <Alert
                                message="You're offline right now. Check your connection."
                                type="error"
                                banner
                                closable
                                style={{ position: 'relative', zIndex: 100 }}
                            />
                        </Offline> */}
                    <App
                        {...props}
                        {...authContext}
                        authState={authState}
                        setAuthState={setAuthState}
                        signInWithGoogle={signInWithGoogle}
                        signInWithFacebook={signInWithFacebook}
                        signInWithTwitter={signInWithTwitter}
                        signOut={signOut}
                        resetPassword={resetPassword}
                    />
                </React.Fragment>
            )}
        </div>
    );
}

// export const webSocketClient = wsClient;

// export default clearRender(Auth);
export default React.memo(Auth);
