import axios from 'axios';

export function displayOdds(odds, numGames, bet, playerInfo) {
	console.log(bet);
	const p1Spread = odds.spread;
	const p2Spread = odds.spread && -odds.spread;
	const p1Line = odds.american1;
	const p2Line = odds.american2;
	const p1OverUnder = odds.overUnder;
	const p2OverUnder = odds.overUnder;
	const p1Odds = odds.oneOdds || 0;
	const p2Odds = odds.twoOdds || 0;
	const p1Payout =
		odds.american1 < 0
			? bet / parseFloat(-1 * odds.american1 / 100).toFixed(2)
			: bet * parseFloat(odds.american1 / 100).toFixed(2);
	const p1Profit = parseFloat(p1Payout - bet).toFixed(2);
	const p2Payout =
		odds.american2 < 0
			? bet / parseFloat(-1 * odds.american2 / 100).toFixed(2)
			: bet * parseFloat(odds.american2 / 100).toFixed(2);
	const p2Profit = parseFloat(p2Payout - bet).toFixed(2);

	const calculations = {
		oneOdds: odds.oneOdds,
		twoOdds: odds.twoOdds,
		impliedProbability: odds.implied,
		moneyline: odds.american,
		moneylineDecimal: odds.decimal,
		moneylineFractional: odds.fractional,
		line25: odds.line25,
		line50: odds.line50,
		line75: odds.line75,
		line100: odds.line100,
		overUnder: odds.overUnder
	};

	const payouts = {
		bet: bet || 0,
		player1Payout: bet ? p1Payout : 0,
		player1Profit: bet ? p1Profit : 0,
		player2Payout: bet ? p2Payout : 0,
		player2Profit: bet ? p2Profit : 0
	};
	const playerData = [
		{
			key: '1',
			player: playerInfo && playerInfo.player1Name,
			odds: p1Odds,
			rating: playerInfo && playerInfo.player1Rating,
			games: numGames,
			spread: p1Spread,
			moneyline: p1Line,
			overUnder: p1OverUnder
		},
		{
			key: '2',
			player: playerInfo && playerInfo.player2Name,
			odds: p2Odds,
			rating: playerInfo && playerInfo.player2Rating,
			games: numGames,
			spread: p2Spread,
			moneyline: p2Line,
			overUnder: p2OverUnder
		}
	];
	console.log('calculations', calculations);
	console.log('player data', playerData);

	return {
		calculations,
		payouts,
		playerData
	};
}

export function isBlank(str) {
	return !str || /^\s*$/.test(str);
}

export function calculateLine(raceTo, impliedProbabilty) {
	var line = raceTo - impliedProbabilty * 2 * raceTo;
	console.log('LINE', line);
	return round(line, 0.5);
}

export function getOverUnderMultiplier(line) {
	console.log('line', line);
	if (line >= 25) {
		return 1.0;
	} else if (line >= 10.5) {
		// Create a gradient of 2.0 -> 1.0 over 10.5 -> 25
		var multiplierMin = 1.0;
		var multiplierMax = 2.0;
		var lineMin = 10.5;
		var lineMax = 25.0;
		var rangePart = (multiplierMax - multiplierMin) / (lineMax - lineMin);
		var numParts = line - lineMin;
		return multiplierMax - rangePart * numParts;
	} else if (line >= 9) {
		return 2.5;
	} else if (line >= 7.5) {
		return 2.75;
	} else if (line >= 5.5) {
		return 3.0;
	} else if (line >= 3.5) {
		return 3.5;
	}

	return 4;
}

export function calculateOverUnder(line, raceTo) {
	var absLine = Math.abs(line);
	return (2 * raceTo - 1 - absLine * getOverUnderMultiplier(absLine)).toFixed();
}

export async function getFargoOddsAsync(fargo1, fargo2, race1, race2) {
	var odds = {
		oneOdds: '',
		twoOdds: '',
		implied: '',
		decimal: '',
		decimal1: '',
		decimal2: '',
		fractional: '',
		american: '',
		american1: '',
		american2: '',
		line25: '',
		line50: '',
		line75: '',
		line100: '',
		spread: '',
		overUnder: ''
	};

	console.log({
		fargo1,
		fargo2,
		race1,
		race2
	});

	if (fargo1 && fargo2 && race1 && race2) {
		try {
			var response = await axios({
				method: 'get',
				url: `https://lms.fargorate.com/api/ratingcalc/odds/${fargo1}/${fargo2}/${race1}/${race2}`
			});
			const data = response.data;
			const totalGames = race1 + race2;
			// console.log(totalGames);
			console.log(data);

			if (data && data.oneOdds) {
				odds.oneOdds = data.oneOdds;
				odds.twoOdds = data.twoOdds;
				odds.decimal = prob2dec(data.oneOdds);
				odds.decimal1 = prob2dec(data.oneOdds);
				odds.decimal2 = prob2dec(data.twoOdds);
				odds.implied = (1 / odds.decimal * 100).toFixed(1) + '%';
				odds.implied1 = (1 / odds.decimal * 100).toFixed(1);
				odds.implied2 = (100 - odds.implied1).toFixed(1);
				odds.fractional = `${dec2frac(odds.decimal)}/1`;
				odds.fractional1 = `${dec2frac(odds.decimal1)}/1`;
				odds.fractional2 = `${dec2frac(odds.decimal2)}/1`;
				odds.american = dec2US(odds.decimal);
				odds.american1 = dec2US(odds.decimal1);
				odds.american2 = odds.american1 < 1 ? Math.abs(odds.american1) : -odds.american1;
				odds.line25 = calculateLine(25, data.oneOdds);
				odds.line50 = calculateLine(50, data.oneOdds);
				odds.line75 = calculateLine(75, data.oneOdds);
				odds.line100 = calculateLine(100, data.oneOdds);
				odds.spread = calculateLine(totalGames, data.oneOdds);
				var overUnder100 = calculateOverUnder(odds.line100, 100);
				odds.overUnder = (overUnder100 / (100 / totalGames)).toFixed();
			}

			console.log(odds);
		} catch (error) {
			alert('Failed to update odds: ' + error);
		}
	}

	// const fractional = dec2frac(odds.decimal).split('/');
	// const probability = fractional[0] / (fractional[1] - fractional[0]);

	// console.log('probability', probability);
	// console.log('line100', calculateLine(100, probability));
	// console.log('lineGames', calculateLine(games, probability));

	console.log(odds);
	return odds;
}

export async function clearOdds() {
	var emptyOdds = await getFargoOddsAsync();
	displayOdds(emptyOdds);
}

export function dec2Odds(dec) {
	return 1 / (dec - 1);
}

export function dec2frac(e) {
	return (e - 1).toFixed(2);
}

// export function dec2frac(e) {
// 	e = parseFloat(e - 1);
// 	var t = Math.round(e) + '/1',
// 		n = Math.round(e),
// 		o = Math.abs(n - e);

// 	for (var i = 2; i <= 200; i++) {
// 		var a = Math.round(e * i) / i,
// 			r = Math.abs(a - e);
// 		if (o > r) {
// 			if (((t = Math.round(e * i) + '/' + i), (n = a), 0 == r)) break;
// 			o = r;
// 		}
// 	}
// 	return t;
// }

// export function dec2US(e) {
// 	const dec = parseFloat(e);
// 	const output = dec > 0 ? (dec - 1) * 100 : dec < 0 ? -100 / (dec - 1) : null;
// 	console.log('dec2us', output.toFixed(0));
// 	return output.toFixed(0);
// }

// https://www.pinnacle.com/en/betting-articles/educational/converting-between-american-and-decimal-odds/PBS2VKQZ7ZB5TZDB
export function us2Dec(e) {
	const odds = e;
	return odds > 0 ? odds / 100 + 1 : odds < 0 ? 100 / odds + 1 : null;
}

export function dec2US(e) {
	var t;
	return (
		(e = parseFloat(e)),
		(t = 1 >= e || 0 / 0 == e ? 0 / 0 : 2 > e ? -100 / (e - 1) : 100 * (e - 1)),
		(t > 0 ? '+' : '') + round(Math.round(100 * t) / 100, 1.0)
	);
}

export function odds2Prob(odds) {
	return odds / (1 + odds);
}

export function prob2dec(impliedProbabilty) {
	const prob = (impliedProbabilty / (1 - impliedProbabilty)).toFixed(4);
	console.log('PROB', prob);
	return prob;
}

// export function prob2dec(impliedProbabilty) {
// 	return (1 / impliedProbabilty).toFixed(4);
// }

// round(2.74, 0.1) = 2.7
// round(2.74, 0.25) = 2.75
// round(2.74, 0.5) = 2.5
// round(2.74, 1.0) = 3.0
export function round(value, step) {
	step || (step = 1.0);
	var inv = 1.0 / step;
	return Math.round(value * inv) / inv;
}

export function gameOptions(limit = 150) {
	let options = [];
	for (let i = 1; i <= limit; i++) {
		options.push(i);
	}
	return options;
}

export function isNormalInteger(n) {
	return /^\+?(0|[1-9]\d*)$/.test(n);
}
