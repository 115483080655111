import React from 'react';
import { Row, Col, Steps } from 'antd';
import Card from '../../components/Card';
import SectionHeader from '../../components/SectionHeader';
import SectionContent from '../../components/SectionContent';
import PLCreateEditStreamForm from './PLCreateEditStreamForm';

const { Step } = Steps;

const styles = {
	cardHeader: {
		paddingTop: 5,
		paddingBottom: 5,
		background: 'linear-gradient(0deg, rgb(241, 244, 246), rgb(255, 255, 255))',
		// boxShadow: 'rgba(0, 0, 0, 0.05) 0px 2px 8px 0px',
		borderRadius: '10px 10px 0px 0px'
	}
};

export default function PLCreateStream(props) {
	return (
		<React.Fragment>
			<SectionHeader title={props.title} subtitle="Stream live pool matches easily from your own device." />
			<SectionContent>
				<Row>
					<Col>
						<Card headStyle={styles.cardHeader}>
							<PLCreateEditStreamForm {...props} />
						</Card>
					</Col>
				</Row>
			</SectionContent>
		</React.Fragment>
	);
}
