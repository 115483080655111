import React, { useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { Link } from 'react-router-dom';
import { Button, Icon, Row, Col, Alert, Typography } from 'antd';
import Card from '../../components/Card';
import SectionHeader from '../../components/SectionHeader';
import SectionContent from '../../components/SectionContent';
import SectionWrapper from '../../components/SectionWrapper';
import PlayerTable from './PlayerTable';
import RemoveButton from './components/GEPlayers/RemoveButton';
import SelectedPlayers from './components/GEPlayers/SelectedPlayers';
import PlayerCount from './components/GEPlayers/PlayerCount';
import InvitePlayerModal from './InvitePlayerModal';
import { inviteModalVisible } from './data/atoms';

const { Title } = Typography;

export default function GGPlayers(props) {
    const { ghostEvent, client, authState } = props;

    console.log("ge", authState);

    const setInvitePlayerModalVisible = useSetRecoilState(inviteModalVisible);
    console.log("render once");
    return (
            <React.Fragment>
                <SectionWrapper>
                    <SectionHeader
                        title={props.title}
                        titleStyle={{ color: '#fff', margin: 0, textTransform: 'uppercase' }}
                        subtitle="Ghost Game Name"
                        subtitlePrefix={true}
                        subtitleStyle={{
                            color: 'rgba(255,255,255,0.8)',
                            textTransform: 'uppercase',
                            fontWeight: 600,
                            fontSize: 12,
                            letterSpacing: 3
                        }}
                        theme="tournamentBuilder"
                    >
                    </SectionHeader>
                    <SectionContent padding="20px 40px">
                        <Row gutter={24} justify="center" type="flex">
                            <Col xs={24}>
                                {/* <Alert
                                    message="Helpful tips for adding players"
                                    description="You can add players individually or many at once by using the Player Import feature. If you include phone numbers, you will be able to send SMS notifications to those players during the tournament. After adding your players, head over to the bracket page to start managing your tournament. If there are any duplicate phone numers, player import will not work."
                                    type="info"
                                    closable
                                    showIcon
                                /> */}
                            </Col>
                            <Col xs={24}>
                                <Card
                                    // title="Players"
                                    bodyStyle={{ padding: 0 }}
                                    // style={{
                                    // 	borderRadius: 8,
                                    // 	overflow: 'hidden',
                                    // 	border: 'none',
                                    // 	boxShadow: '0 2px 5px 0px rgba(0, 0, 0, 0.1)'
                                    // }}
                                    cover={
                                        <div
                                            style={{
                                                paddingTop: 16,
                                                paddingBottom: 16,
                                                paddingLeft: 24,
                                                paddingRight: 24,
                                                // background:'linear-gradient(rgb(239, 243, 245), rgb(255, 255, 255))',
                                                borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'space-between'
                                            }}
                                        >
                                            <Title level={4} style={{ margin: 0, padding: 0, fontSize: 18 }}>
                                                Players (<PlayerCount />)
                                            </Title>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'space-between'
                                                }}
                                            >
                                                <Button type="primary" onClick={() => { setInvitePlayerModalVisible(true) }}>
                                                    <Icon type="user-add" /> Invite Player
                                                </Button>
                                                <Button type="default">
                                                    <Icon type="bell" /> Reminder
                                                </Button>
                                                <RemoveButton client={client} ghostEventId={ghostEvent.id} />
                                                <div style={{ marginLeft: 10 }}>
                                                    <span style={{ marginLeft: 8 }}>
                                                        <SelectedPlayers />
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                >
                                <div>
                                    <PlayerTable ghostEventId={ghostEvent.id} client={client} />
                                </div>
                                </Card>
                            </Col>
                        </Row>
                        <InvitePlayerModal client={client} ghostEvent={ghostEvent} emailAddress={authState.user.email} userFirstName={authState.user.first_name} />
                    </SectionContent>
                </SectionWrapper>
            </React.Fragment>
    );
}