import React, { useState, useEffect, useRef } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { message, Modal, Button, Col, Icon, Typography } from 'antd';
import CircularLoader from '../../../components/CircularLoader';
import Card from '../../../components/Card';
import { GET_LEAGUE_POSTS } from '../data/queries';
import { DELETE_POST_MUTATION } from '../data/mutations';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { Query } from '@apollo/client/react/components';
import { functions } from '../../../firebase';
import { connectFunctionsEmulator, httpsCallable } from 'firebase/functions';
import FeedPost from './FeedPost';
import AddPostModal from './AddPostModal';
import ZeroState from '../../../components/ZeroState';

const { Text, Title } = Typography;
const { confirm } = Modal;

const styles = {};

function FeedPostList(props) {
    const { authState, league, isLeagueMember } = props;
    const [deletePost] = useMutation(DELETE_POST_MUTATION);
    const [selectedPostId, setSelectedPostId] = useState();
    const [postAddModalVisible, setPostAddModalVisible] = useState(false);

    async function handleDeletePost(record) {
        message.info('Deleting post...');
        if (record.image_url) {
            const imageUrl = record.image_url;
            const splitPath = imageUrl.split('/');
            const fileName = splitPath[splitPath.length - 1];
            const deleteImg = await handleDeleteImage(fileName);
            console.log('deleted', deleteImg);
        }

        deletePost({
            variables: {
                id: record.id,
            },
            notifyOnNetworkStatusChange: true,
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: GET_LEAGUE_POSTS,
                    variables: { id: league.id },
                },
            ],
        })
            .then((data) => {
                console.log(data);
                message.success('Post deleted');
                // props.history.push(`/venues`);
            })
            .catch((error) => {
                console.log(error);
                message.info('There was an error', error);
                return error;
            });
    }

    async function handleDeleteImage(fileName) {
        const config = {
            S3BucketName: 'digitalpool',
            key: `post-images/${fileName}`,
            action: 'delete',
        };

        if (window.location.hostname.indexOf('localhost') > -1) {
            connectFunctionsEmulator(functions, 'localhost', 5001);
        }
        try {
            var deleteImage = await httpsCallable(functions, 'uploadImageS3');
            return deleteImage(config)
                .then((result) => {
                    console.log(result);
                    return result;
                })
                .catch((e) => {
                    console.log(e);
                    return e;
                });
        } catch (e) {
            console.log(e);
            return e;
        }
    }

    function showConfirmDelete(record) {
        confirm({
            title: 'Are you sure you want to delete this post?',
            content: 'This action will permanently remove the post and any comments.',
            centered: true,
            transitionName: 'fade',
            maskTransitionName: 'none',
            okText: `Delete`,
            onOk() {
                handleDeletePost(record);
            },
            onCancel() {},
        });
    }

    function handlePostAddModalOk(data) {
        console.log(data);
        if (data) {
            // const updatedPlayers = players.concat(data);
            // setPlayers(updatedPlayers);
        }
        setPostAddModalVisible(false);
    }

    function handleEditSelected(selected) {
        setSelectedPostId(selected);
        setPostAddModalVisible(true);
    }

    function handlePostAddModalCancel(e) {
        setSelectedPostId();
        setPostAddModalVisible(false);
    }

    function handlePostAdd() {
        setPostAddModalVisible(true);
        setSelectedPostId(null);
    }

    return (
        <Card
            title="News feed"
            bodyStyle={{
                padding: 10,
                // height: 'calc(100% - 90px)',
                // display: 'flex',
                // alignItems: 'center',
            }}
            extra={
                <React.Fragment>
                    {authState && authState.user && authState.user.id === league.owner_id && (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <Button
                                size="default"
                                onClick={() => {
                                    handlePostAdd();
                                }}
                            >
                                <Icon type="plus" />
                                Create post
                            </Button>
                        </div>
                    )}
                </React.Fragment>
            }
        >
            <Col xs={24}>
                <Query
                    query={GET_LEAGUE_POSTS}
                    fetchPolicy="cache-and-network"
                    notifyOnNetworkStatusChange={true}
                    variables={{ id: league.id }}
                    onCompleted={(data) => {
                        // console.log(data);
                    }}
                >
                    {({ loading, error, data }) => {
                        if (loading) return <CircularLoader />;
                        if (error) return <div style={styles.container}>Error: {error.message}</div>;
                        if (data && !data.league_posts) {
                            return <ZeroState showImage message="No posts found." style={{ margin: 30 }} {...props} />;
                        }
                        if (data && data.league_posts.length === 0) {
                            return <ZeroState showImage message="No posts found." style={{ margin: 30 }} {...props} />;
                        }
                        const posts =
                            data.league_posts &&
                            data.league_posts.map((item) => {
                                return item.post;
                            });

                        return (
                            <React.Fragment>
                                {posts &&
                                    posts.map((post, index) => {
                                        return (
                                            <FeedPost
                                                key={index}
                                                post={post}
                                                league={league}
                                                onEditSelected={handleEditSelected}
                                                onDelete={(post) => showConfirmDelete(post)}
                                                {...props}
                                            />
                                        );
                                    })}
                            </React.Fragment>
                        );
                    }}
                </Query>
                <AddPostModal
                    selectedPostId={selectedPostId}
                    onModalOk={handlePostAddModalOk}
                    onModalCancel={handlePostAddModalCancel}
                    modalVisible={postAddModalVisible}
                    {...props}
                />
            </Col>
        </Card>
    );
}

export default withRouter(FeedPostList);
