import React, { useState } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { Menu, Icon, Avatar, Typography } from 'antd';

const { Text } = Typography;

function SidebarFooter(props) {
    const { authState, signOut } = props;
    const [theme, setTheme] = useState('dark');
    const [current, setCurrent] = useState('home');

    const handleClick = (e) => {
        // console.log('click ', e);
        setCurrent(e.key);
        if (e.key === 'logout') {
            signOut();
        }
    };

    return (
        <React.Fragment>
            <Menu
                theme={theme}
                // onClick={handleClick}
                // style={{ width: 256 }}
                // defaultOpenKeys={[ 'sub1' ]}
                selectedKeys={[current]}
                mode="inline"
            >
                {/* {authState &&
			authState.status === 'in' && (
				<Menu.Item key="profile">
					<Icon type="user" /> {props.authState.user.displayName}
				</Menu.Item>
			)}
			{authState &&
			authState.status === 'in' && (
				<Menu.Item key="settings">
					<Icon type="setting" /> Settings
				</Menu.Item>
			)}
			{authState &&
			authState.status === 'in' && (
				<Menu.Item
					key="logout"
					onClick={() => {
						signOut()
							.then(() => {
								props.history.push('/');
							})
							.catch((e) => {
								console.log(e);
							});
					}}
				>
					<Icon type="logout" /> Log Out
				</Menu.Item>
			)}
			{authState &&
			authState.status !== 'in' && (
				<Menu.Item key="login" onClick={() => props.history.push('/login')}>
					<Icon type="login" /> Log In
				</Menu.Item>
			)}
			{authState &&
			authState.status !== 'in' && (
				<Menu.Item key="register" onClick={() => props.history.push('/register')}>
					<Icon type="poweroff" /> Sign Up
				</Menu.Item>
			)} */}
                <Menu.Item
                    key="expand-collapse"
                    onClick={() => {
                        if (props.navType === 'mobile') {
                            props.toggleNavType('desktop');
                        } else {
                            props.toggleNavType('mobile');
                        }
                    }}
                    style={{ borderTop: '1px solid rgba(255,255,255,0.1)', textAlign: 'center' }}
                >
                    {props.navType === 'mobile' ? <Icon type="double-right" /> : <Icon type="double-left" />}
                </Menu.Item>
            </Menu>
        </React.Fragment>
    );
}

export default withRouter(SidebarFooter);
