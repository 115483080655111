import React, { useState, useMutation, useRef, createRef, forwardRef } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { message, Modal, Tabs, Button, Typography } from 'antd';
import { Form, FormItem, Select, Input, ResetButton, SubmitButton } from 'formik-antd';
import { Formik } from 'formik';
import { GET_TOURNAMENT_TABLES_QUERY, GET_TOURNAMENT_TABLES_NAMES_QUERY } from '../data/queries';
import { UPDATE_TABLE_STATUS_MUTATION, UPDATE_TOURNAMENT_BRACKET_MATCH_MUTATION } from '../data/mutations';
import create from 'antd/lib/icon/IconFont';
import { isCompositeType } from 'graphql';
import moment from 'moment';
import { functions } from '../../../../firebase';
import { connectFunctionsEmulator, httpsCallable } from 'firebase/functions';

const { TabPane } = Tabs;
const { Option } = Select;
const { Title, Text } = Typography;

const TableAssignForm = forwardRef(function Tables(props, ref) {
    const { tournament, tournamentId, onDeckMatches, notifyPlayer, onOk, openTables, players, selectedMatch, updateTableStatus, updateTournamentBracket } =
        props;
    const [assignedTable, setAssignedTable] = useState();

    // const { error, loading, data } = useQuery(GET_TOURNAMENT_TABLES_NAMES_QUERY, {
    // 	variables: {
    // 		tournament_id: tournamentId,
    // 		status: 'OPEN'
    // 	}
    // });

    // if (loading) return null;

    const tables = openTables.sort((a, b) => {
        return a.label.localeCompare(b.label, undefined, {
            numeric: true,
            sensitivity: 'base',
        });
    });

    function getPlayer(id) {
        return (
            players &&
            players.filter((player) => {
                return player && player.id === id;
            })[0]
        );
    }

    function assignTables() {
        console.log('table assignment', assignedTable);

        console.log(tables);
        const table = tables.filter((row) => {
            return row.id === assignedTable;
        })[0];

        const match = selectedMatch;

        const changes = {
            challenger1_id: match.challenger1_id,
            challenger1_name: match.challenger1_name,
            challenger1_country: match.challenger1_country,
            challenger1_score: match.challenger1_score,
            challenger1_race_to: match.challenger1_race_to,
            challenger1_seed: match.challenger1_seed,
            challenger2_id: match.challenger2_id,
            challenger2_name: match.challenger2_name,
            challenger2_country: match.challenger2_country,
            challenger2_score: match.challenger2_score,
            challenger2_race_to: match.challenger2_race_to,
            challenger2_seed: match.challenger2_seed,
            start_time: moment.utc(),
            table_name: table.label,
            table_id: table.id,
            status: 'IN_PROGRESS',
            progress: 0,
        };

        const player1 = getPlayer(match.challenger1_id);
        const player2 = getPlayer(match.challenger2_id);

        // console.log({
        //     player1,
        //     player2
        // })

        if (player1 && player1.phone_number) {
            notifyPlayer(player1, player2, table, match);
        }
        if (player2 && player2.phone_number) {
            notifyPlayer(player2, player1, table, match);
        }

        // console.log({
        // 	id: match.id,
        // 	selectedMatch,
        // 	players,
        // 	tournament_id: tournamentId,
        // 	changes: changes
        // });

        updateTournamentBracket({
            variables: {
                id: match.id,
                tournament_id: tournamentId,
                changes: changes,
            },
        }).then((data) => {
            console.log(data);
            message.success(`Match ${match.match_number} assigned to ${table.label}`);
            updateTableStatus({
                variables: {
                    id: table.id,
                    changes: {
                        // status: 'ASSIGNED'
                        status: 'IN_USE',
                    },
                },
            }).then((data) => {
                onOk();
            });
        });
    }

    function handleChange(value) {
        setAssignedTable(value);
    }

    return (
        <div style={{ padding: 10 }}>
            <Formik
                onSubmit={(data, actions) => {
                    const { table } = data;
                    console.log(table);
                }}
                ref={ref}
                render={(errors, touched, values) => {
                    return (
                        <Form>
                            <FormItem name="table">
                                <div>
                                    <Select name="table" onChange={handleChange} placeholder="Select table assignment" size="large">
                                        {tables.map((table, key) => {
                                            return (
                                                <Option key={key} value={table.id}>
                                                    {table.label} - {table.status} {table.is_streaming_table ? '(Streaming Table)' : ''}
                                                    {table.is_featured_table ? '(Featured Table)' : ''}
                                                </Option>
                                            );
                                        })}
                                    </Select>
                                </div>
                                <div style={{ marginTop: 10 }}>
                                    <Button
                                        type="primary"
                                        onClick={() => {
                                            assignTables();
                                        }}
                                        disabled={assignedTable ? false : true}
                                    >
                                        Assign Table
                                    </Button>
                                </div>
                            </FormItem>
                        </Form>
                    );
                }}
            />
        </div>
    );
});

const TableAssignFormMemo = React.memo((props) => {
    return <TableAssignForm tournamentId={props.tournamentId} ref={props.passRef} />;
});

const SMSPlayerForm = forwardRef(function (props, ref) {
    const { key, onOk, notifyPlayer, tournament, tournamentId, players, selectedMatch } = props;
    const match = selectedMatch;

    async function sendSMS(player, smsMessage) {
        if (tournament.use_text_messaging !== false) {
            console.log({
                player,
                smsMessage,
            });
            if (window.location.hostname.indexOf('localhost') > -1) {
                connectFunctionsEmulator(functions, 'localhost', 5001);
            }

            try {
                const sendSMS = httpsCallable(functions, 'sms');
                const messageToSend = `DigitalPool.com\n${smsMessage}\n${moment().format('llll')}`;
                const result = await sendSMS({
                    phone_number: `${player.country_phone || '+1'}${player.phone_number}`,
                    message: messageToSend,
                    user_id: player.id,
                    tournament_id: tournamentId,
                    // token: ''
                });
                console.log(result);
                message.success(`Notification sent to ${player.name}`);
                onOk();
            } catch (e) {
                console.log(e);
                message.error(`Notification error: ${JSON.stringify(e)}`);
            }
        } else {
            message.error('SMS notifications are currently disabled');
            console.log('SMS notifications are currently disabled');
        }
    }

    function getPlayer(id) {
        return (
            players &&
            players.filter((player) => {
                return player && player.id === id;
            })[0]
        );
    }

    return (
        <div style={{ padding: 10 }}>
            <Formik
                onSubmit={(values, actions) => {
                    console.log('send sms', values);
                    const message = values.smsMessage;
                    const player1 = getPlayer(match.challenger1_id);
                    const player2 = getPlayer(match.challenger2_id);

                    if (player1 && player1.phone_number) {
                        sendSMS(player1, message);
                    }
                    if (player2 && player2.phone_number) {
                        sendSMS(player2, message);
                    }
                    actions.setSubmitting(false);
                }}
                ref={ref}
                initialValues={{
                    smsMessage: 'Please make your way to the tournament area. Your match will be called soon.',
                }}
                render={({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => {
                    return (
                        <Form onSubmit={handleSubmit}>
                            <Input.TextArea name="smsMessage" placeholder="Message" />

                            <SubmitButton type="primary" style={{ marginTop: 8 }}>
                                Send SMS
                            </SubmitButton>
                        </Form>
                    );
                }}
            />
        </div>
    );
});

function SideBarPlayerModal(props) {
    const { onOk, notifyPlayer, updateTableStatus, updateTournamentBracket } = props;

    const { visible, challenger1Id, challenger2Id, tournament, tournamentId, selectedMatch, players, openTables, onCancel, onDeckMatches } = props.data;
    const tableFormRef = createRef();
    const smsFormRef = createRef();
    const modal = useRef();

    var _tabKey = 1;

    function onSubmit() {
        switch (_tabKey) {
            case 1:
                smsFormRef.current.handleSubmit();
                break;

            case 2:
                tableFormRef.current.handleSubmit();
                break;
        }
    }

    return (
        <React.Fragment>
            <Modal
                ref={modal}
                title={`Configure On Deck Match ${selectedMatch && selectedMatch.match_number}`}
                visible={visible}
                onOk={(e) => {
                    onSubmit();
                    onOk();
                }}
                onCancel={() => {
                    onOk();
                }}
                style={{
                    top: '50%',
                    // left: isWidthDown('xs', width) ? 0 : -120,
                    transform: 'translateY(-50%)',
                }}
                transitionName="fade"
                maskTransitionName="none"
                destroyOnClose={true}
                footer={null}
                bodyStyle={{ padding: 0 }}
            >
                {selectedMatch && (
                    <div style={{ paddingTop: 10, paddingLeft: 20, paddingRight: 20 }}>
                        <b>
                            <Text>
                                {selectedMatch.challenger1_name}
                                {selectedMatch.challenger1_name &&
                                    selectedMatch.is_bye !== true &&
                                    selectedMatch.challenger1_race_to &&
                                    ` (${selectedMatch.challenger1_race_to})`}{' '}
                                vs. {selectedMatch.challenger2_name}
                                {selectedMatch.challenger1_name &&
                                    selectedMatch.is_bye !== true &&
                                    selectedMatch.challenger2_race_to &&
                                    ` (${selectedMatch.challenger2_race_to})`}
                            </Text>
                        </b>
                    </div>
                )}

                <Tabs
                    defaultActiveKey="1"
                    animated={{ inkBar: true, tabPane: false }}
                    onChange={(key) => {
                        _tabKey = parseInt(key);
                    }}
                    tabPosition="top"
                    tabBarStyle={{
                        textTransform: 'uppercase',
                        paddingTop: 0,
                        // backgroundColor: '#132339',
                        // backgroundColor: '#070f20',

                        zIndex: 1,
                        position: 'relative',
                    }}
                >
                    <TabPane tab="Assign Table" key="1">
                        <TableAssignForm
                            ref={tableFormRef}
                            tournament={tournament}
                            tournamentId={tournamentId}
                            onDeckMatches={onDeckMatches}
                            notifyPlayer={notifyPlayer}
                            onOk={onOk}
                            openTables={openTables}
                            players={players}
                            selectedMatch={selectedMatch}
                            updateTableStatus={updateTableStatus}
                            updateTournamentBracket={updateTournamentBracket}
                        />
                    </TabPane>
                    <TabPane tab="Send SMS Message" key="2">
                        <SMSPlayerForm
                            ref={smsFormRef}
                            tournament={tournament}
                            tournamentId={tournamentId}
                            players={players}
                            notifyPlayer={notifyPlayer}
                            selectedMatch={selectedMatch}
                            onOk={onOk}
                        />
                    </TabPane>
                </Tabs>
            </Modal>
        </React.Fragment>
    );
}

export default SideBarPlayerModal;
