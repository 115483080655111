import gql from 'graphql-tag';

export const CREATE_ORGANIZATION_MUTATION = gql`
    mutation insert_organizations($objects: [organizations_insert_input!]!) {
        insert_organizations(objects: $objects) {
            affected_rows
            returning {
                id
                name
                slug
                description
                address1
                address2
                city
                country
                region
                postal_code
                phone_number
                fax_number
                email_address
                website
                owner_id
            }
        }
    }
`;

export const UPDATE_ORGANIZATION_MUTATION = gql`
    mutation update_organizations($id: Int, $changes: organizations_set_input) {
        update_organizations(where: { id: { _eq: $id } }, _set: $changes) {
            affected_rows
        }
    }
`;

export const CREATE_LEAGUE_MUTATION = gql`
    mutation insert_leagues($objects: [leagues_insert_input!]!) {
        insert_leagues(objects: $objects) {
            affected_rows
            returning {
                id
                name
                slug
            }
        }
    }
`;

export const UPDATE_LEAGUE_MUTATION = gql`
    mutation update_leagues($id: Int, $changes: leagues_set_input) {
        update_leagues(where: { id: { _eq: $id } }, _set: $changes) {
            affected_rows
            returning {
                id
                name
                slug
                short_description
                long_description
                is_public
                owner {
                    id
                    first_name
                    last_name
                    email
                }
                operator {
                    id
                    first_name
                    last_name
                    email
                }
                address1
                address2
                city
                county
                country
                region
                postal_code
                phone_number
                fax_number
                email_address
                website
                social_facebook
                social_instagram
                social_twitter
                created_at
                updated_at
            }
        }
    }
`;

export const CREATE_DIVISION_MUTATION = gql`
    mutation insert_league_divisions($objects: [league_divisions_insert_input!]!) {
        insert_league_divisions(objects: $objects) {
            affected_rows
            returning {
                id
                name
                slug
                description
                division_type
                game_types
                # division_game_types {
                # 	id
                # 	game_type
                # }
                session_id
                num_weeks
                start_date
                end_date
                is_public
                is_active
                is_handicapped
            }
        }
    }
`;

export const UPDATE_DIVISION_MUTATION = gql`
    mutation update_league_divisions($id: Int, $changes: league_divisions_set_input) {
        update_league_divisions(where: { id: { _eq: $id } }, _set: $changes) {
            affected_rows
            returning {
                id
                name
                slug
                description
                division_type
                game_types
                # division_game_types {
                # 	id
                # 	game_type
                # }
                session_id
                num_weeks
                start_date
                end_date
                is_public
                is_active
                is_handicapped
            }
        }
    }
`;

export const DELETE_DIVISION_MUTATION = gql`
    mutation delete_league_divisions($id: Int!, $league_id: Int) {
        delete_league_divisions(where: { id: { _eq: $id }, league_id: { _eq: $league_id } }) {
            affected_rows
        }
    }
`;

export const CREATE_SCHEDULED_MATCHES_MUTATION = gql`
    mutation insert_league_scheduled_matches($objects: [league_scheduled_matches_insert_input!]!) {
        insert_league_scheduled_matches(objects: $objects) {
            affected_rows
            returning {
                id
                league_id
                division_id
                start_date
                start_time
                week
                schedule_id
                home_team_id
                home_team_name
                visitor_team_id
                visitor_team_name
                venue_id
                venue_name
                status
                league_match_id
                created_at
                updated_at
            }
        }
    }
`;

export const UPDATE_SCHEDULED_MATCHES_MUTATION = gql`
    mutation update_league_scheduled_matches($schedule_id: Int, $changes: league_scheduled_matches_set_input) {
        update_league_scheduled_matches(where: { schedule_id: { _eq: $schedule_id } }, _set: $changes) {
            affected_rows
            returning {
                id
                league_id
                division_id
                start_date
                start_time
                week
                schedule_id
                home_team_id
                home_team_name
                visitor_team_id
                visitor_team_name
                venue_id
                venue_name
                status
                league_match_id
                created_at
                updated_at
            }
        }
    }
`;

export const DELETE_SCHEDULED_MATCHES_MUTATION = gql`
    mutation delete_league_scheduled_matches($schedule_id: Int!) {
        delete_league_scheduled_matches(where: { schedule_id: { _eq: $schedule_id } }) {
            affected_rows
        }
    }
`;

export const CREATE_LEAGUE_SCHEDULE_MUTATION = gql`
    mutation insert_league_schedules($objects: [league_schedules_insert_input!]!) {
        insert_league_schedules(objects: $objects) {
            affected_rows
            returning {
                id
                name
                slug
                description
                division_id
                season_id
                start_date
                duration_type
                duration
                reserved_weeks
                weeks_off
                is_active
            }
        }
    }
`;

export const UPDATE_LEAGUE_SCHEDULE_MUTATION = gql`
    mutation update_league_schedules($id: Int, $changes: league_schedules_set_input) {
        update_league_schedules(where: { id: { _eq: $id } }, _set: $changes) {
            affected_rows
            returning {
                id
                name
                slug
                description
                division_id
                season_id
                start_date
                duration_type
                duration
                reserved_weeks
                weeks_off
                is_active
            }
        }
    }
`;

export const DELETE_LEAGUE_SCHEDULE_MUTATION = gql`
    mutation delete_league_schedules($id: Int!, $league_id: Int) {
        delete_league_schedules(where: { id: { _eq: $id }, league_id: { _eq: $league_id } }) {
            affected_rows
        }
    }
`;

export const UPSERT_DIVISION_GAME_TYPES_MUTATION = gql`
    mutation insert_division_game_types($objects: [division_game_types_insert_input!]!) {
        insert_division_game_types(objects: $objects, on_conflict: { constraint: division_game_types_pkey, update_columns: [game_type] }) {
            affected_rows
            returning {
                id
            }
        }
    }
`;

export const CREATE_VENUE_MUTATION = gql`
    mutation insert_venues($objects: [venues_insert_input!]!) {
        insert_venues(objects: $objects) {
            affected_rows
            returning {
                id
                name
                slug
                description
                venue_type
                avatar
                address1
                address2
                city
                country
                region
                postal_code
                phone_number
                email_address
                website
                operating_hours
                social_facebook
                social_instagram
                social_twitter
                is_public
                is_claimed
                claimed_by_id
                owner_id
            }
        }
    }
`;

export const UPDATE_VENUE_MUTATION = gql`
    mutation update_venues($id: Int, $changes: venues_set_input) {
        update_venues(where: { id: { _eq: $id } }, _set: $changes) {
            affected_rows
            returning {
                id
                name
                slug
                description
                venue_type
                avatar
                address1
                address2
                city
                country
                region
                postal_code
                phone_number
                email_address
                website
                operating_hours
                social_facebook
                social_instagram
                social_twitter
                is_public
                is_claimed
                claimed_by_id
                owner_id
            }
        }
    }
`;

export const DELETE_LEAGUE_VENUE_MUTATION = gql`
    mutation delete_league_venues($league_id: Int!, $venue_id: Int!) {
        delete_league_venues(where: { league_id: { _eq: $league_id }, venue_id: { _eq: $venue_id } }) {
            affected_rows
        }
    }
`;

export const CREATE_LEAGUE_VENUE_MUTATION = gql`
    mutation insert_league_venues($objects: [league_venues_insert_input!]!) {
        insert_league_venues(objects: $objects) {
            affected_rows
        }
    }
`;

export const UPDATE_LEAGUE_VENUE_MUTATION = gql`
    mutation update_league_venues($id: Int!, $league_id: Int!, $changes: league_venues_set_input) {
        update_league_venues(where: { id: { _eq: $id }, league_id: { _eq: $league_id } }, _set: $changes) {
            affected_rows
        }
    }
`;

export const LINK_LEAGUE_VENUE_MUTATION = gql`
    mutation insert_league_venues($objects: [league_venues_insert_input!]!) {
        insert_league_venues(objects: $objects) {
            affected_rows
        }
    }
`;

export const DELETE_LEAGUE_PLAYER_MUTATION = gql`
    mutation delete_league_players($league_id: Int!, $id: Int!) {
        delete_league_players(where: { league_id: { _eq: $league_id }, id: { _eq: $id } }) {
            affected_rows
        }
    }
`;

export const CREATE_LEAGUE_PLAYER = gql`
    mutation upsert_league_players($objects: [league_players_insert_input!]!) {
        insert_league_players(objects: $objects, on_conflict: { constraint: league_players_email_league_id_key, update_columns: [] }) {
            affected_rows
            returning {
                id
                user_id
                player_id
                name
                nickname
                country
                country_phone
                gender
                email
                phone_number
                fargo_id
                skill_level
                race_to
                special_group
                status
            }
        }
    }
`;

export const UPDATE_LEAGUE_PLAYER = gql`
    mutation update_league_players($id: Int, $changes: league_players_set_input) {
        update_league_players(where: { id: { _eq: $id } }, _set: $changes) {
            affected_rows
            returning {
                id
                user_id
                player_id
                name
                nickname
                country
                country_phone
                gender
                email
                phone_number
                fargo_id
                skill_level
                race_to
                special_group
                status
            }
        }
    }
`;

export const UPDATE_LEAGUE_PLAYER_DIVISION = gql`
    mutation upsert_league_player_divisions($league_player_id: Int!, $objects: [league_player_divisions_insert_input!]!) {
        insert_league_player_divisions(
            objects: $objects
            on_conflict: {
                constraint: league_player_divisions_pkey
                update_columns: [division_id, league_player_id]
                where: { league_player_id: { _eq: $league_player_id } }
            }
        ) {
            affected_rows
            returning {
                id
                division_id
                league_player_id
            }
        }
    }
`;

export const DELETE_LEAGUE_PLAYER_DIVISION = gql`
    mutation delete_league_player_divisions($league_player_id: Int!) {
        delete_league_player_divisions(where: { league_player_id: { _eq: $league_player_id } }) {
            affected_rows
        }
    }
`;

export const UPDATE_LEAGUE_PLAYER_TEAM = gql`
    mutation upsert_league_team_players($league_player_id: Int!, $objects: [league_team_players_insert_input!]!) {
        insert_league_team_players(
            objects: $objects
            on_conflict: {
                constraint: league_team_players_pkey
                update_columns: [team_id, league_player_id]
                where: { league_player_id: { _eq: $league_player_id } }
            }
        ) {
            affected_rows
            returning {
                id
                league_player_id
                team_id
            }
        }
    }
`;

export const DELETE_LEAGUE_PLAYER_TEAM = gql`
    mutation delete_league_team_players($league_player_id: Int!) {
        delete_league_team_players(where: { league_player_id: { _eq: $league_player_id } }) {
            affected_rows
        }
    }
`;

export const DELETE_LEAGUE_TEAM_MUTATION = gql`
    mutation delete_league_teams($league_id: Int!, $id: Int!) {
        delete_league_teams(where: { league_id: { _eq: $league_id }, id: { _eq: $id } }) {
            affected_rows
        }
    }
`;

export const CREATE_LEAGUE_TEAM = gql`
    mutation upsert_league_teams($objects: [league_teams_insert_input!]!) {
        insert_league_teams(objects: $objects) {
            affected_rows
            returning {
                id
                name
                status
            }
        }
    }
`;

export const UPDATE_LEAGUE_TEAM = gql`
    mutation update_league_teams($id: Int, $changes: league_teams_set_input) {
        update_league_teams(where: { id: { _eq: $id } }, _set: $changes) {
            affected_rows
            returning {
                id
                name
                status
            }
        }
    }
`;

export const UPDATE_LEAGUE_TEAM_PLAYER = gql`
    mutation upsert_league_team_players($team_id: Int!, $objects: [league_team_players_insert_input!]!) {
        insert_league_team_players(
            objects: $objects
            on_conflict: { constraint: league_team_players_pkey, update_columns: [team_id, league_player_id], where: { team_id: { _eq: $team_id } } }
        ) {
            affected_rows
            returning {
                id
                team_id
                league_player_id
            }
        }
    }
`;

export const UPDATE_LEAGUE_TEAM_DIVISION = gql`
    mutation upsert_league_team_divisions($team_id: Int!, $objects: [league_team_divisions_insert_input!]!) {
        insert_league_team_divisions(
            objects: $objects
            on_conflict: { constraint: league_team_divisions_pkey, update_columns: [team_id, division_id], where: { team_id: { _eq: $team_id } } }
        ) {
            affected_rows
            returning {
                id
                team_id
                division_id
            }
        }
    }
`;

export const DELETE_LEAGUE_TEAM_DIVISION = gql`
    mutation delete_league_team_divisions($team_id: Int!) {
        delete_league_team_divisions(where: { team_id: { _eq: $team_id } }) {
            affected_rows
        }
    }
`;

export const DELETE_LEAGUE_TEAM_PLAYER = gql`
    mutation delete_league_team_players($team_id: Int!) {
        delete_league_team_players(where: { team_id: { _eq: $team_id } }) {
            affected_rows
        }
    }
`;

export const DELETE_LEAGUE_MUTATION = gql`
    mutation soft_delete_league($id: Int!, $owner_id: Int!) {
        update_leagues(where: { id: { _eq: $id }, owner_id: { _eq: $owner_id } }, _set: { deleted_at: "now()" }) {
            affected_rows
            returning {
                id
                name
                deleted_at
            }
        }
    }
`;

export const RESTORE_LEAGUE_MUTATION = gql`
    mutation restore_league($id: Int!, $owner_id: Int!) {
        update_leagues(where: { id: { _eq: $id }, owner_id: { _eq: $owner_id } }, _set: { deleted_at: null }) {
            affected_rows
            returning {
                id
                name
                deleted_at
            }
        }
    }
`;

export const CREATE_LEAGUE_MATCH_OLD = gql`
    mutation insert_league_matches($objects: [league_matches_insert_input!]!) {
        insert_league_matches(objects: $objects) {
            affected_rows
            returning {
                id
                league_id
                division_id
                match_type
                game_type
                league_match_players {
                    id
                    league_id
                    league_player_id
                    division_id
                    name
                    country
                    skill_level
                    race_to
                    score
                    is_winner
                }
            }
        }
    }
`;

export const CREATE_LEAGUE_MATCH = gql`
    mutation insert_matches($objects: [matches_insert_input!]!) {
        insert_matches(objects: $objects) {
            affected_rows
            returning {
                id
                league_id
                division_id
                match_type
                game_type
                match_players {
                    id
                    league_id
                    player_id
                    division_id
                    name
                    country
                    skill_level
                    race_to
                    score
                    is_winner
                }
            }
        }
    }
`;

export const UPDATE_LEAGUE_MATCH = gql`
    mutation update_matches($id: Int!, $league_id: Int!, $changes: matches_set_input) {
        update_matches(where: { league_id: { _eq: $league_id }, id: { _eq: $id } }, _set: $changes) {
            affected_rows
            returning {
                id
            }
        }
    }
`;

export const DELETE_LEAGUE_MATCH_MUTATION = gql`
    mutation delete__matches($league_id: Int!, $id: Int!) {
        delete_matches(where: { league_id: { _eq: $league_id }, id: { _eq: $id } }) {
            affected_rows
        }
    }
`;

export const CREATE_LEAGUE_MATCH_FRAME = gql`
    mutation insert_league_match_frames($objects: [league_match_frames_insert_input!]!) {
        insert_league_match_frames(objects: $objects) {
            affected_rows
            returning {
                id
            }
        }
    }
`;

export const UPDATE_LEAGUE_MATCH_PLAYER = gql`
    mutation upsert_match_players($league_id: Int!, $match_id: Int!, $objects: [match_players_insert_input!]!) {
        insert_match_players(
            objects: $objects
            on_conflict: { constraint: match_players_pkey, update_columns: [id], where: { league_id: { _eq: $league_id }, match_id: { _eq: $match_id } } }
        ) {
            affected_rows
            returning {
                id
            }
        }
    }
`;

export const DELETE_LEAGUE_MATCH_PLAYER = gql`
    mutation delete_match_players($league_id: Int!, $match_id: Int!) {
        delete_match_players(where: { league_id: { _eq: $league_id }, match_id: { _eq: $match_id } }) {
            affected_rows
            returning {
                id
            }
        }
    }
`;

export const CREATE_LEAGUE_CHALLENGE = gql`
    mutation insert_challenges($objects: [challenges_insert_input!]!) {
        insert_challenges(objects: $objects) {
            affected_rows
            returning {
                id
                league_id
                division_id
            }
        }
    }
`;

export const UPDATE_LEAGUE_CHALLENGE = gql`
    mutation update_challenges($id: Int, $changes: challenges_set_input) {
        update_challenges(where: { id: { _eq: $id } }, _set: $changes) {
            affected_rows
            returning {
                id
            }
        }
    }
`;

export const DELETE_LEAGUE_CHALLENGE = gql`
    mutation delete_challenges($league_id: Int!, $id: Int!) {
        delete_challenges(where: { league_id: { _eq: $league_id }, id: { _eq: $id } }) {
            affected_rows
            returning {
                id
                match_id
            }
        }
    }
`;

export const DELETE_MATCH_MUTATION = gql`
    mutation delete_matches($id: Int!) {
        delete_matches(where: { id: { _eq: $id } }) {
            affected_rows
        }
    }
`;

export const CREATE_TABLE_MUTATION = gql`
    mutation insert_pool_tables($objects: [pool_tables_insert_input!]!) {
        insert_pool_tables(objects: $objects) {
            affected_rows
            returning {
                id
                label
                type
                size
                year_built
                make
                model
                livestream_url
                venue_id
                match_id
                manufacturer_id
                is_streaming_table
                is_featured_table
                created_at
                updated_at
            }
        }
    }
`;

export const UPDATE_TABLE_MUTATION = gql`
    mutation update_pool_tables($id: Int, $changes: pool_tables_set_input) {
        update_pool_tables(where: { id: { _eq: $id } }, _set: $changes) {
            affected_rows
            returning {
                id
                label
                type
                size
                year_built
                make
                model
                livestream_url
                venue_id
                match_id
                manufacturer_id
                is_streaming_table
                is_featured_table
                created_at
                updated_at
            }
        }
    }
`;

export const DELETE_TABLE_MUTATION = gql`
    mutation delete_pool_tables($id: Int) {
        delete_pool_tables(where: { id: { _eq: $id } }) {
            affected_rows
        }
    }
`;

export const CREATE_LEAGUE_INVITE_MUTATION = gql`
    mutation insert_league_invite_keys($objects: [league_invite_keys_insert_input!]!) {
        insert_league_invite_keys(objects: $objects) {
            affected_rows
            returning {
                id
            }
        }
    }
`;

export const CREATE_LEAGUE_INVITE_NOTIFICATION_MUTATION = gql`
    mutation insert_notifications($objects: [notifications_insert_input!]!) {
        insert_notifications(objects: $objects) {
            affected_rows
            returning {
                id
            }
        }
    }
`;

export const DUPLICATE_POOL_TABLE = gql`
    mutation insert_pool_tables($objects: [pool_tables_insert_input!]!) {
        __typename
        insert_pool_tables(objects: $objects) {
            affected_rows
            returning {
                id
            }
        }
    }
`;

// League Manager Feed mutations

export const CREATE_LEAGUE_POST_MUTATION = gql`
    mutation insert_league_posts($objects: [league_posts_insert_input!]!) {
        insert_league_posts(objects: $objects) {
            affected_rows
        }
    }
`;

export const CREATE_POST_MUTATION = gql`
    mutation insert_posts($objects: [posts_insert_input!]!) {
        insert_posts(objects: $objects) {
            affected_rows
            returning {
                id
                message
                slug
                user_id
                image_url
                created_at
                updated_at
            }
        }
    }
`;

export const UPDATE_POST_MUTATION = gql`
    mutation update_posts($id: Int, $changes: posts_set_input) {
        update_posts(where: { id: { _eq: $id } }, _set: $changes) {
            affected_rows
            returning {
                id
                message
                slug
                user_id
                image_url
                created_at
                updated_at
            }
        }
    }
`;

export const DELETE_POST_MUTATION = gql`
    mutation delete_posts($id: Int!) {
        delete_posts(where: { id: { _eq: $id } }) {
            affected_rows
        }
    }
`;

export const CREATE_POST_COMMENT_MUTATION = gql`
    mutation insert_post_comments($objects: [post_comments_insert_input!]!) {
        insert_post_comments(objects: $objects) {
            affected_rows
            returning {
                id
                message
                user_id
                post_id
                parent_id
                created_at
                updated_at
            }
        }
    }
`;

export const UPDATE_POST_COMMENT_MUTATION = gql`
    mutation update_post_comments($id: Int, $changes: post_comments_set_input) {
        update_post_comments(where: { id: { _eq: $id } }, _set: $changes) {
            affected_rows
            returning {
                id
                message
                user_id
                post_id
                parent_id
                created_at
                updated_at
            }
        }
    }
`;

export const DELETE_POST_COMMENT_MUTATION = gql`
    mutation delete_post_comments($id: Int!) {
        delete_post_comments(where: { id: { _eq: $id } }) {
            affected_rows
        }
    }
`;

export const CREATE_POST_LIKE_MUTATION = gql`
    mutation insert_post_likes($objects: [post_likes_insert_input!]!) {
        insert_post_likes(objects: $objects) {
            affected_rows
            returning {
                id
                post_id
                user_id
                comment_id
                created_at
                updated_at
            }
        }
    }
`;

export const UPDATE_POST_LIKE_MUTATION = gql`
    mutation update_post_likes($id: Int, $changes: post_likes_set_input) {
        update_post_likes(where: { id: { _eq: $id } }, _set: $changes) {
            affected_rows
            returning {
                id
                post_id
                user_id
                comment_id
                created_at
                updated_at
            }
        }
    }
`;

export const DELETE_POST_LIKE_MUTATION = gql`
    mutation delete_post_likes($post_id: Int!, $user_id: Int!) {
        delete_post_likes(where: { post_id: { _eq: $post_id }, user_id: { _eq: $user_id }, comment_id: { _is_null: true } }) {
            affected_rows
        }
    }
`;

export const CREATE_POST_COMMENT_LIKE_MUTATION = gql`
    mutation insert_post_likes($objects: [post_likes_insert_input!]!) {
        insert_post_likes(objects: $objects) {
            affected_rows
            returning {
                id
                post_id
                user_id
                comment_id
                created_at
                updated_at
            }
        }
    }
`;

export const UPDATE_POST_COMMENT_LIKE_MUTATION = gql`
    mutation update_post_likes($id: Int, $changes: post_likes_set_input) {
        update_post_likes(where: { id: { _eq: $id } }, _set: $changes) {
            affected_rows
            returning {
                id
                post_id
                user_id
                comment_id
                created_at
                updated_at
            }
        }
    }
`;

export const DELETE_POST_COMMENT_LIKE_MUTATION = gql`
    mutation delete_post_likes($post_id: Int!, $user_id: Int!, $comment_id: Int!) {
        delete_post_likes(where: { post_id: { _eq: $post_id }, user_id: { _eq: $user_id }, comment_id: { _eq: $comment_id } }) {
            affected_rows
        }
    }
`;

export const UPDATE_MATCH_MUTATION = gql`
    mutation update_matches($id: Int, $changes: matches_set_input) {
        update_matches(where: { id: { _eq: $id } }, _set: $changes) {
            __typename
            returning {
                id
                name
                slug
                description
                match_number
                match_type
                game_type
                rule_format
                handicap_format
                race_to
                player_id
                player_name
                player_score
                player_points
                player_tiebreaker_points
                player_race_to
                player_country
                player_fargo
                player_is_winner
                opponent_id
                opponent_name
                opponent_score
                opponent_points
                opponent_tiebreaker_points
                opponent_race_to
                opponent_country
                opponent_fargo
                opponent_is_winner
                winner_id
                winner_score
                loser_id
                loser_score
                status
                progress
                duration
                livestream_url
                score_verified
                scheduled_time
                start_date_time
                end_date_time
                is_public
                table_size
                scoreboard_balls
                creator_id
                created_at
                updated_at
            }
        }
    }
`;

export const UPDATE_LEAGUE_MATCH_GAME = gql`
    mutation update_match_games($id: Int, $changes: match_games_set_input) {
        update_match_games(where: { id: { _eq: $id } }, _set: $changes) {
            affected_rows
        }
    }
`;

export const CREATE_LEAGUE_MATCH_GAME = gql`
    mutation insert_match_games($objects: [match_games_insert_input!]!) {
        insert_match_games(objects: $objects) {
            affected_rows
        }
    }
`;

export const DELETE_LEAGUE_MATCH_GAME = gql`
    mutation delete_match_games($id: Int, $match_id: Int) {
        delete_match_games(where: { id: { _eq: $id }, match_id: { _eq: $match_id } }) {
            affected_rows
        }
    }
`;

export const CREATE_CHALLENGE_COMMENT_MUTATION = gql`
    mutation insert_challenge_comments($objects: [challenge_comments_insert_input!]!) {
        insert_challenge_comments(objects: $objects) {
            affected_rows
            returning {
                id
                comment
                user_id
                challenge_id
                parent_id
                created_at
                updated_at
            }
        }
    }
`;

export const UPDATE_CHALLENGE_COMMENT_MUTATION = gql`
    mutation update_challenge_comments($id: Int, $changes: challenge_comments_set_input) {
        update_challenge_comments(where: { id: { _eq: $id } }, _set: $changes) {
            affected_rows
            returning {
                id
                comment
                user_id
                challenge_id
                parent_id
                created_at
                updated_at
            }
        }
    }
`;

export const DELETE_CHALLENGE_COMMENT_MUTATION = gql`
    mutation delete_challenge_comments($id: Int!) {
        delete_challenge_comments(where: { id: { _eq: $id } }) {
            affected_rows
        }
    }
`;
