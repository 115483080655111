import React from 'react';
import { Row, Button, Empty, Icon, Col, Typography } from 'antd';
import SectionContent from '../../../components/SectionContent';
import SectionTitle from '../../../components/SectionTitle';
import SectionWrapper from '../../../components/SectionWrapper';
import ZeroState from '../../../components/ZeroState';
import moment from 'moment';
import _ from 'lodash';
import DPDivisionCard from './DPDivisionCard';
import { Query } from '@apollo/client/react/components';
import gql from 'graphql-tag';
import blueBg from '../../../assets/img/blue-bg.png';
import { GET_LEAGUE_DIVISIONS_QUERY } from '../data/queries';

const { Title, Text } = Typography;

const styles = {
    headerImg: {
        height: 120,
        overflow: 'hidden',
        // backgroundColor: '#14171d',
        borderRadius: 0,
        background: `linear-gradient(rgba(61, 185, 248, 0.8), rgba(24, 144, 255, 0.1)), url(${blueBg}) 0px 0px no-repeat`,
        // backgroundAttachment: 'fixed',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100%',
        display: 'flex',
        alignItems: 'center',
        paddingLeft: 40,
        paddingRight: 40,
        // backgroundPosition: '0px -40px'
    },
};

export default function DPLeagueDivisions(props) {
    const { league } = props;

    return (
        <SectionWrapper>
            {/* <SectionTitle
				title="Divisions"
				titleStyle={{ color: '#fff', margin: 0, textTransform: 'uppercase' }}
				height={120}
			/> */}
            <SectionContent padding="20px 40px">
                <Row gutter={16} justify="center" type="flex">
                    <Col xs={24}>
                        <Query
                            query={GET_LEAGUE_DIVISIONS_QUERY}
                            fetchPolicy="cache-and-network"
                            notifyOnNetworkStatusChange={true}
                            variables={{ leagueId: league.id }}
                            onCompleted={async (data) => {
                                console.log(data);
                            }}
                        >
                            {({ loading, error, data }) => {
                                if (loading) return <div>Loading...</div>;
                                if (error) return <div>Error: {error.message}</div>;
                                if (!loading && data && !data.league_divisions) {
                                    return (
                                        <ZeroState showImage message="You have not added any divisions yet." style={{ margin: 30 }} {...props}>
                                            <Button>Create New Division</Button>
                                        </ZeroState>
                                    );
                                }
                                return (
                                    <Row gutter={16} justify="start" type="flex">
                                        {data.league_divisions &&
                                            data.league_divisions
                                                .map((item, index) => {
                                                    return {
                                                        ...item,
                                                        key: index,
                                                        is_public: item.is_public === true ? 'Public' : 'Private',
                                                        is_active: item.is_active === true ? 'Active' : 'Inactive',
                                                        division_type: _.startCase(_.toLower(item.division_type.replace('_', ' '))),
                                                        game_types: item.game_types
                                                            .map((item) => _.startCase(_.toLower(item.game_type.replace('_', ' '))))
                                                            .join(', '),
                                                    };
                                                })
                                                .map((division, index) => (
                                                    <Col
                                                        xs={24}
                                                        sm={12}
                                                        md={8}
                                                        lg={8}
                                                        xl={6}
                                                        key={index}
                                                        style={{
                                                            display: 'flex',
                                                        }}
                                                    >
                                                        <DPDivisionCard league={league} division={division} {...props} />
                                                    </Col>
                                                ))}
                                    </Row>
                                );
                            }}
                        </Query>
                    </Col>
                </Row>
            </SectionContent>
        </SectionWrapper>
    );
}
