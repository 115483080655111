import React, { useState } from 'react';
import { message, Icon, Row, Col, Typography, Button, Input, Tabs, Empty } from 'antd';
import Card from '../../components/Card';
import { Link } from 'react-router-dom';
import ZeroState from '../../components/ZeroState';
import CircularLoader from '../../components/CircularLoader';
import SectionHeader from '../../components/SectionHeader';
import SectionContent from '../../components/SectionContent';
import SectionWrapper from '../../components/SectionWrapper';
import SectionFooter from '../../components/SectionFooter';
import { Subscription, Query } from '@apollo/client/react/components';
import VenueTable from './venues/VenueTable';
import * as routes from '../../config/routes';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { GET_LEAGUE_VENUES_QUERY, GET_LEAGUE_QUERY } from './data/queries';
import AddVenueModal from './AddVenueModal';
import { DELETE_LEAGUE_VENUE_MUTATION } from './data/mutations';
import _ from 'lodash';

const { Text, Title } = Typography;

const styles = {
    row: {
        display: 'flex',
        flexGrow: 1,
    },
    tab: {
        paddingTop: 5,
        paddingLeft: 24,
        paddingRight: 24,
        paddingBottom: 24,
    },
};

export default function LMVenues(props) {
    const { league } = props;
    const [selectedKeys, setSelectedKeys] = useState([]);
    const [selectedVenueId, setSelectedVenueId] = useState();
    const [venues, setVenues] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);
    const [deleteLeagueVenue, { loading, error }] = useMutation(DELETE_LEAGUE_VENUE_MUTATION);

    function handleSelected(selected) {
        console.log(selected);
        setSelectedKeys(selected);
    }

    function handleEditSelected(selected) {
        // console.log(selected);
        setSelectedVenueId(selected);
        setModalVisible(true);
    }

    const hasSelected = selectedKeys && selectedKeys.length > 0;

    function handleModalOk(e) {
        console.log(e);
        setModalVisible(false);
    }

    function handleModalCancel(e) {
        setModalVisible(false);
    }

    function handleAddVenue() {
        setModalVisible(true);
        setSelectedVenueId(null);
    }

    return (
        <SectionWrapper>
            <SectionHeader
                title={props.title}
                titleStyle={{ color: '#fff', margin: 0, textTransform: 'uppercase' }}
                avatar={league.avatar}
                subtitle={league.name}
                subtitlePrefix={true}
                subtitleStyle={{
                    color: 'rgba(255,255,255,0.8)',
                    textTransform: 'uppercase',
                    fontWeight: 600,
                    fontSize: 12,
                    letterSpacing: 3,
                }}
                theme="leagueManager"
            >
                <Link to={`/leagues/${props.match.params.slug}`}>
                    <Button type="ghost" ghost>
                        Public Profile
                    </Button>
                </Link>
            </SectionHeader>

            <SectionContent padding="20px 40px">
                <Row gutter={16} justify="center" type="flex">
                    <Col xs={24}>
                        <Card
                            title={`All Venues (${league.league_venues_aggregate.aggregate.count})`}
                            extra={
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <Button type="primary" onClick={handleAddVenue}>
                                        + Add Venue
                                    </Button>
                                    <div style={{ marginLeft: 10 }}>
                                        <Button type="danger" disabled={!hasSelected}>
                                            Remove
                                        </Button>
                                        <span style={{ marginLeft: 8 }}>{hasSelected ? `Selected ${selectedKeys.length} items` : ''}</span>
                                    </div>
                                </div>
                            }
                            bodyStyle={{ padding: 0 }}
                        >
                            <Query
                                query={GET_LEAGUE_VENUES_QUERY}
                                fetchPolicy="cache-and-network"
                                notifyOnNetworkStatusChange
                                variables={{
                                    leagueId: league.id,
                                }}
                                onCompleted={(data) => {
                                    console.log(data);
                                }}
                            >
                                {({ loading, error, data }) => {
                                    if (loading) return <CircularLoader />;
                                    if (error) return <div style={styles.container}>Error: {error.message}</div>;
                                    if ((data && !data.venues) || (data && data.venues.length === 0)) {
                                        return <ZeroState showImage message="No venues have been added yet." style={{ margin: 30 }} {...props} />;
                                    }
                                    return (
                                        <VenueTable
                                            {...props}
                                            league={league}
                                            onDelete={(record) => {
                                                deleteLeagueVenue({
                                                    variables: {
                                                        league_id: league.id,
                                                        venue_id: record.id,
                                                    },
                                                    notifyOnNetworkStatusChange: true,
                                                    awaitRefetchQueries: true,
                                                    refetchQueries: [
                                                        {
                                                            query: GET_LEAGUE_QUERY,
                                                            variables: { id: league.id },
                                                        },
                                                        {
                                                            query: GET_LEAGUE_VENUES_QUERY,
                                                            variables: { leagueId: league.id },
                                                        },
                                                    ],
                                                })
                                                    .then((data) => {
                                                        console.log(data);
                                                        message.success('Venue removed');
                                                    })
                                                    .catch((error) => {
                                                        console.log(error);
                                                        message.info('There was an error', error);
                                                    });
                                            }}
                                            onSelected={handleSelected}
                                            onEditSelected={handleEditSelected}
                                            venues={
                                                data.venues &&
                                                data.venues.map((item, index) => {
                                                    return {
                                                        ...item,
                                                        key: index,
                                                        index: index + 1,
                                                        is_public: item.is_public === true ? 'Public' : 'Private',
                                                        venue_type: _.startCase(_.toLower(item.venue_type.replace('_', ' '))),
                                                    };
                                                })
                                            }
                                        />
                                    );
                                }}
                            </Query>
                        </Card>
                    </Col>
                </Row>
            </SectionContent>
            <SectionFooter {...props} />

            <AddVenueModal
                {...props}
                league={league}
                selectedVenueId={selectedVenueId}
                // venue={
                // 	venues.filter((item, index) => {
                // 		return item.id === selectedVenueId;
                // 	})[0]
                // }
                modalVisible={modalVisible}
                onModalOk={handleModalOk}
                onModalCancel={handleModalCancel}
            />
        </SectionWrapper>
    );
}
