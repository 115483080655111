import React from 'react';
import EventNamePaymentForm from './EventNamePaymentForm';
import SettingsForm from './SettingsForm';
import Completed from './Completed';

export default function ContentController(props) {
    const { ghostEventStatus } = props;
    var jsx = null;

    switch(ghostEventStatus) {
        case 'NOT_STARTED':
            jsx = <SettingsForm {...props} />
        break;

        case 'IN_PROGRESS':
            jsx = <EventNamePaymentForm {...props} />
        break;

        case 'COMPLETED':
            jsx = <Completed {...props} />
        break;
    }

    return jsx;
}