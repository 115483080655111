import React from 'react';
import { Descriptions, Row, Col, Avatar, Input, Button, List, Typography } from 'antd';
import Card, { Meta } from '../../../components/Card';
import DPLeagueComments from './DPLeagueComments';

const { Text, Title } = Typography;

const data = [
	{
		title: 'Ant Design Title 1'
	}
];

export default function DPLeaguePosts(props) {
	return (
		<Row gutter={24}>
			{/* <Title level={4}>Posts</Title> */}
			<Col xs={24} style={{ marginTop: -6 }}>
				<Card
					style={{
						borderRadius: 8,
						overflow: 'hidden',
						border: 'none',
						boxShadow: '0 2px 5px 0px rgba(0, 0, 0, 0.1)'
					}}
				>
					<div
						style={{
							display: 'flex',
							alignItems: 'center'
						}}
					>
						<Input placeholder="Post to League..." size="large" />
						<Button type="link" size="large" style={{ marginLeft: 10, textTransform: 'uppercase' }}>
							Post
						</Button>
					</div>
				</Card>
			</Col>
			<Col xs={24}>
				<Card
					style={{
						borderRadius: 8,
						overflow: 'hidden',
						border: 'none',
						boxShadow: '0 2px 5px 0px rgba(0, 0, 0, 0.1)'
					}}
				>
					<div style={{ marginBottom: 20 }}>
						<List
							itemLayout="horizontal"
							dataSource={data}
							renderItem={(item) => (
								<List.Item style={{ marginTop: 0, paddingTop: 0 }}>
									<List.Item.Meta
										avatar={
											<Avatar
												src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"
												size={40}
											/>
										}
										title="Han Solo"
										description="4hr ago"
									/>
								</List.Item>
							)}
						/>
						<Text>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc finibus rutrum nisi, non
							aliquet metus fermentum interdum. Mauris egestas ex ut metus auctor mattis. Nam lacinia,
							ligula nec vestibulum vulputate, lectus risus congue ante, a iaculis velit ipsum vel mauris.
							Nulla sit amet imperdiet urna, eu varius justo. Sed ultrices auctor nibh, et scelerisque
							turpis feugiat quis. Aenean ac pharetra erat, blandit gravida nulla. Nunc placerat finibus
							nibh. Curabitur quis erat interdum, elementum enim id, sollicitudin justo. Cras at est nisl.
							Cras et placerat tellus. Nunc placerat lectus enim, a ornare orci tincidunt a. In
							consectetur est ante, vel egestas mi mattis ac. Proin tincidunt felis vel est elementum,
							pulvinar aliquet sapien pharetra. Vestibulum sed massa id massa venenatis auctor vel eget
							lorem. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos
							himenaeos.
						</Text>
					</div>
					<DPLeagueComments />
				</Card>
			</Col>

			<Col xs={24}>
				<Card
					style={{
						borderRadius: 8,
						overflow: 'hidden',
						border: 'none',
						boxShadow: '0 2px 5px 0px rgba(0, 0, 0, 0.1)'
					}}
				>
					<div style={{ marginBottom: 20 }}>
						<List
							itemLayout="horizontal"
							dataSource={data}
							renderItem={(item) => (
								<List.Item style={{ marginTop: 0, paddingTop: 0 }}>
									<List.Item.Meta
										avatar={
											<Avatar
												src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"
												size={40}
											/>
										}
										title="Han Solo"
										description="4hr ago"
									/>
								</List.Item>
							)}
						/>
						<Text>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc finibus rutrum nisi, non
							aliquet metus fermentum interdum. Mauris egestas ex ut metus auctor mattis. Nam lacinia,
							ligula nec vestibulum vulputate, lectus risus congue ante, a iaculis velit ipsum vel mauris.
							Nulla sit amet imperdiet urna, eu varius justo. Sed ultrices auctor nibh, et scelerisque
							turpis feugiat quis. Aenean ac pharetra erat, blandit gravida nulla. Nunc placerat finibus
							nibh. Curabitur quis erat interdum, elementum enim id, sollicitudin justo. Cras at est nisl.
							Cras et placerat tellus. Nunc placerat lectus enim, a ornare orci tincidunt a. In
							consectetur est ante, vel egestas mi mattis ac. Proin tincidunt felis vel est elementum,
							pulvinar aliquet sapien pharetra. Vestibulum sed massa id massa venenatis auctor vel eget
							lorem. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos
							himenaeos.
						</Text>
					</div>
					<DPLeagueComments />
				</Card>
			</Col>
		</Row>
	);
}
