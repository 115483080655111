import React from 'react';
import { Table, Typography, Progress } from 'antd';
import Card from '../../../components/Card';
import CircleFlag from '../../../components/CircleFlag';
import { getCountryName } from '../../../utils/getCountryName';
import { Link } from 'react-router-dom';

const { Text, Title } = Typography;

export default function StatsTable(props) {
    const columns = [
        {
            title: 'Place',
            dataIndex: 'place',
            key: 'place',
            width: 80,
            defaultSortOrder: 'ascend',
            sorter: (a, b) => {
                return a.place - b.place;
            },
            render: (text, record) => <Text>{text || '-'}</Text>,
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => {
                return a.name && a.name.localeCompare(b.name);
            },
            render: (text, record) => {
                const country = getCountryName(record.country);

                return (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        {record.country && <CircleFlag country={record.country} left={0} />}

                        <span>{text}</span>
                    </div>
                );
            },
        },

        {
            title: 'Matches',
            dataIndex: 'matches',
            key: 'matches',
            sorter: (a, b) => {
                return a.matchesWon - b.matchesWon;
            },
            render: (text, record) => <Text>{text || '-'}</Text>,
        },
        {
            title: 'Match win %',
            dataIndex: 'matchWinPercentage',
            key: 'matchWinPercentage',
            sorter: (a, b) => {
                return a.matchWinPercentage - b.matchWinPercentage;
            },
            render: (text, record) => (
                <React.Fragment>
                    <Progress
                        percent={parseInt(record.matchWinPercentage.toFixed(0)) || 0}
                        status="normal"
                        size="small"
                        // showInfo={false}
                        strokeWidth={6}
                        strokeLinecap="square"
                    />
                </React.Fragment>
            ),
        },
        // {
        // 	title: 'Games',
        // 	dataIndex: 'games',
        // 	key: 'games',
        // 	sorter: (a, b) => {
        // 		return a.gamesWon - b.gamesWon;
        // 	}
        // },
        // {
        // 	title: 'Game win %',
        // 	dataIndex: 'gameWinPercentage',
        // 	key: 'gameWinPercentage',
        // 	sorter: (a, b) => {
        // 		return a.gameWinPercentage - b.gameWinPercentage;
        // 	},
        // 	render: (text, record) => (
        // 		<React.Fragment>
        // 			<Progress
        // 				percent={parseInt(record.gameWinPercentage.toFixed(0)) || 0}
        // 				status="normal"
        // 				size="small"
        // 				// showInfo={false}
        // 				strokeWidth={6}
        // 				strokeLinecap="square"
        // 			/>
        // 		</React.Fragment>
        // 	)
        // },
        {
            title: 'Frames',
            dataIndex: 'frames',
            key: 'frames',
            sorter: (a, b) => {
                return a.framesWon - b.framesWon;
            },
            render: (text, record) => <Text>{text || '-'}</Text>,
        },
        {
            title: 'Frame win %',
            dataIndex: 'frameWinPercentage',
            key: 'frameWinPercentage',
            sorter: (a, b) => {
                return a.frameWinPercentage - b.frameWinPercentage;
            },
            render: (text, record) => (
                <React.Fragment>
                    <Progress
                        percent={parseInt(record.frameWinPercentage.toFixed(0)) || 0}
                        status="normal"
                        size="small"
                        // showInfo={false}
                        strokeWidth={6}
                        strokeLinecap="square"
                    />
                </React.Fragment>
            ),
        },
        // {
        // 	title: 'Points',
        // 	dataIndex: 'points',
        // 	key: 'points',
        // 	sorter: (a, b) => {
        // 		return a.pointsWon - b.pointsWon;
        // 	}
        // },
        {
            title: 'Points for',
            dataIndex: 'pointsWon',
            key: 'pointsWon',
            sorter: (a, b) => {
                return a.pointsWon - b.pointsWon;
            },
            render: (text, record) => <Text>{text || '-'}</Text>,
        },
        {
            title: 'Points against',
            dataIndex: 'pointsLost',
            key: 'pointsLost',
            sorter: (a, b) => {
                return a.pointsLost - b.pointsLost;
            },
            render: (text, record) => <Text>{text || '-'}</Text>,
        },
        {
            title: 'Point win %',
            dataIndex: 'pointWinPercentage',
            key: 'pointWinPercentage',
            sorter: (a, b) => {
                return a.pointWinPercentage - b.pointWinPercentage;
            },
            render: (text, record) => (
                <React.Fragment>
                    <Progress
                        percent={parseInt(record.pointWinPercentage.toFixed(0)) || 0}
                        status="normal"
                        size="small"
                        // showInfo={false}
                        strokeWidth={6}
                        strokeLinecap="square"
                    />
                </React.Fragment>
            ),
        },
        {
            title: 'Balls pocketed',
            dataIndex: 'ballsPocketed',
            key: 'ballsPocketed',
            sorter: (a, b) => {
                return a.ballsPocketed - b.ballsPocketed;
            },
            render: (text, record) => <Text>{text || '-'}</Text>,
        },
        {
            title: 'Errors',
            dataIndex: 'errors',
            key: 'errors',
            sorter: (a, b) => {
                return a.errors - b.errors;
            },
            render: (text, record) => <Text>{text || '-'}</Text>,
        },
        {
            title: 'High Break',
            dataIndex: 'highBreak',
            key: 'highBreak',
            sorter: (a, b) => {
                return a.highBreak - b.highBreak;
            },
            render: (text, record) => <Text>{text || '-'}</Text>,
        },
        // {
        // 	title: 'Match History',
        // 	dataIndex: 'matchHistory',
        // 	key: 'matchHistory',
        // 	render: (text, record) => {
        // 		return <Button type="link">View</Button>;
        // 		// return record.matchHistory.map((result, index) => {
        // 		// 	return (
        // 		// 		<span key={index} className={result === 'W' ? 'match-badge win' : 'match-badge loss'}>
        // 		// 			{result}
        // 		// 		</span>
        // 		// 	);
        // 		// });
        // 	}
        // }
    ];

    return (
        <React.Fragment>
            <Card
                // title="Results"
                bodyStyle={{ padding: 0 }}
                // style={{
                // 	borderRadius: 8,
                // 	overflow: 'hidden',
                // 	border: 'none',
                // 	boxShadow: '0 2px 5px 0px rgba(0, 0, 0, 0.1)'
                // }}
            >
                <div className="table_nowrap">
                    <Table
                        pagination={false}
                        columns={columns}
                        dataSource={props.formattedResults}
                        size="default"
                        tableLayout="auto"
                        scroll={{ x: 400 }}
                        // pagination={{ pageSize: 25 }}
                    />
                </div>
            </Card>
        </React.Fragment>
    );
}
