import React, { useState, useEffect } from 'react';
import { withRouter, Route, Redirect, Switch } from 'react-router-dom';
import { message, Typography, Tabs, Result, Menu, Icon, Button, Layout, Row, Col } from 'antd';
import Card from '../../components/Card';
import { Link } from 'react-router-dom';
// import SectionHeader from '../../components/SectionHeader';
// import SectionContent from '../../components/SectionContent';
import { Query } from '@apollo/client/react/components';
import SectionContent from '../../components/SectionContent';
import LMDashboard from './LMDashboard';
import LMSettings from './LMSettings';
import LMDivisions from './LMDivisions';
import LMDivisionDetail from './divisions/LMDivisionDetail';
import LMDivisionCreate from './divisions/LMDivisionCreate';
import LMDivisionScheduleCreate from './divisions/LMDivisionScheduleCreate';
import LMTeams from './LMTeams';
import LMTeamDetail from './LMTeamDetail';
import LMPlayers from './LMPlayers';
import LMPlayerDetail from './LMPlayerDetail';
import LMSchedule from './LMSchedule';
import LMMatches from './LMMatches';
import LMChallenges from './LMChallenges';
import LMChallengeDetail from './LMChallengeDetail';
import LMMatchDetail from './LMMatchDetail';
import LMNotifications from './LMNotifications';
import LMPayments from './LMPayments';
import LMStandings from './LMStandings';
import LMStats from './LMStats';
import LMVenues from './LMVenues';
import LMVenueCreate from './venues/LMVenueCreate';
import LMVenueDetail from './LMVenueDetail';
import CircularLoader from '../../components/CircularLoader';
import * as routes from '../../config/routes';
import { GET_LEAGUE_BY_SLUG_QUERY } from './data/queries';
import _ from 'lodash';
import withAuthorization from '../auth/withAuthorization';
import { canAccess, canEdit } from '../../utils/canAccess';

const { Sider, Content } = Layout;
const { TabPane } = Tabs;
const { Text } = Typography;

const styles = {
    row: {
        display: 'flex',
        flexGrow: 1,
    },
    tab: {
        paddingTop: 5,
        paddingLeft: 24,
        paddingRight: 24,
        paddingBottom: 24,
    },
};

function LMDetail(props) {
    const { match, authState, navType } = props;
    const title = _.startCase(_.toLower(props.match.params.slug.replace('-', ' ')));
    const slug = props.match.params.slug;
    const urlPath = window.location.pathname;
    const splitPath = urlPath.split('/');
    const lmIncludes = [
        'dashboard',
        'divisions',
        'teams',
        'players',
        'schedule',
        'matches',
        'challenges',
        'settings',
        'venues',
        'standings',
        'stats',
        'notifications',
        'payments',
    ];

    function canAccess(league) {
        if (
            (authState && authState.user && authState.user.role === 'admin') ||
            (authState &&
                authState.user &&
                ((league.owner_id && league.owner_id === authState.user.id) || (league.operator_id && league.operator_id === authState.user.id)))
        ) {
        }
    }

    let pathMatch =
        splitPath[splitPath.length] === slug
            ? splitPath[1]
            : _.includes(lmIncludes, splitPath[1])
            ? splitPath[1]
            : _.includes(lmIncludes, splitPath[2])
            ? splitPath[2]
            : _.includes(lmIncludes, splitPath[3])
            ? splitPath[3]
            : 'league-manager';

    const handleClick = (e) => {
        const url = e.key ? `/league-manager/${match.params.slug}/${e.key}` : `/league-manager/${e.key}/dashboard`;
        props.history.push(url);
    };

    const handleTabClick = (key) => {
        const url = key ? `/league-manager/${slug}/${key}` : `/league-manager/${key}/dashboard`;
        props.history.push(url);
        // setCurrent(key);
    };

    const [current, setCurrent] = useState(pathMatch);

    useEffect(() => {
        setCurrent(pathMatch);
    }, [pathMatch]);

    return (
        <Query
            query={GET_LEAGUE_BY_SLUG_QUERY}
            fetchPolicy="cache-and-network"
            notifyOnNetworkStatusChange
            variables={{ slug: slug }}
            onCompleted={async (data) => {
                // console.log(data);
            }}
        >
            {({ loading, error, data }) => {
                if (loading)
                    return (
                        <div
                            style={{
                                height: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <CircularLoader />
                            <Text style={{ marginLeft: 10 }}>Loading...</Text>
                        </div>
                    );
                if (error) return <div style={styles.container}>Error: {error.message}</div>;
                if (data && !data.leagues) {
                    return <div>League not found.</div>;
                }
                if (data && data.leagues.length === 0) {
                    return <div>League not found.</div>;
                }

                const league = data.leagues && data.leagues[0];
                const organization = league && league.organization;
                console.log(league);

                return (
                    <React.Fragment>
                        {(authState && authState.user && authState.user.role === 'admin') ||
                        (authState &&
                            authState.user &&
                            ((league.owner_id && league.owner_id === authState.user.id) ||
                                (league.operator_id && league.operator_id === authState.user.id))) ? (
                            <Layout style={navType === 'mobile' ? { height: 'calc(100% - 64px)' } : { height: '100%' }}>
                                <Sider
                                    width={40}
                                    collapsed
                                    // collapsed={collapsed}
                                    // onMouseEnter={() => {
                                    //     setCollapsed(false);
                                    // }}
                                    // onMouseLeave={() => {
                                    //     setCollapsed(true);
                                    // }}
                                >
                                    <Menu
                                        onClick={handleClick}
                                        defaultSelectedKeys={['dashboard']}
                                        selectedKeys={[current]}
                                        mode="inline"
                                        theme="dark"
                                        // inlineCollapsed
                                        style={{ height: '100%' }}
                                    >
                                        <Menu.Item key="dashboard" style={{ padding: '0px !important' }}>
                                            <Icon type="dashboard" /> <span>Dashboard</span>
                                        </Menu.Item>
                                        {league.has_division_support && (
                                            <Menu.Item key="divisions">
                                                <Icon type="block" /> <span>Divisions</span>
                                            </Menu.Item>
                                        )}

                                        {league.has_team_support && (
                                            <Menu.Item key="teams">
                                                <Icon type="team" /> <span>Teams</span>
                                            </Menu.Item>
                                        )}
                                        <Menu.Item key="players">
                                            <Icon type="user" /> <span>Players</span>
                                        </Menu.Item>
                                        <Menu.Item key="schedule">
                                            <Icon type="calendar" /> <span>Schedule</span>
                                        </Menu.Item>
                                        <Menu.Item key="challenges">
                                            <Icon type="star" /> <span>Challenges</span>
                                        </Menu.Item>
                                        <Menu.Item key="matches">
                                            <Icon type="thunderbolt" /> <span>Matches</span>
                                        </Menu.Item>
                                        <Menu.Item key="standings">
                                            <Icon type="trophy" /> <span>Standings</span>
                                        </Menu.Item>
                                        <Menu.Item key="stats">
                                            <Icon type="bar-chart" /> <span>Stats</span>
                                        </Menu.Item>
                                        <Menu.Item key="notifications">
                                            <Icon type="bell" /> <span>Notifications</span>
                                        </Menu.Item>
                                        <Menu.Item key="payments">
                                            <Icon type="dollar" /> <span>Payments</span>
                                        </Menu.Item>
                                        <Menu.Item key="venues">
                                            <Icon type="environment" /> <span>Venues</span>
                                        </Menu.Item>
                                        <Menu.Item key="settings">
                                            <Icon type="setting" /> <span>Settings</span>
                                        </Menu.Item>
                                    </Menu>
                                </Sider>

                                <Layout>
                                    <Switch>
                                        <Redirect
                                            exact
                                            strict
                                            from={`/league-manager/${props.match.params.slug}`}
                                            to={`/league-manager/${props.match.params.slug}/dashboard`}
                                        />
                                        <Route
                                            exact
                                            path={routes.LEAGUE_MANAGER.DETAIL_DASHBOARD}
                                            render={() => <LMDashboard {...props} title="Dashboard" league={league} organization={organization} />}
                                        />
                                        <Route
                                            exact
                                            path={routes.LEAGUE_MANAGER.DETAIL_DIVISIONS}
                                            render={() => <LMDivisions {...props} title="Divisions" league={league} organization={organization} />}
                                        />
                                        <Route
                                            exact
                                            path={routes.LEAGUE_MANAGER.DETAIL_DIVISION_CREATE}
                                            render={() => <LMDivisionCreate {...props} title="Create Division" league={league} organization={organization} />}
                                        />

                                        <Route
                                            path={routes.LEAGUE_MANAGER.DETAIL_DIVISION}
                                            render={() => <LMDivisionDetail {...props} title="Division Detail" league={league} organization={organization} />}
                                        />
                                        <Route
                                            exact
                                            path={routes.LEAGUE_MANAGER.DETAIL_TEAMS}
                                            render={() => <LMTeams {...props} title="Teams" league={league} organization={organization} />}
                                        />
                                        <Route
                                            exact
                                            path={routes.LEAGUE_MANAGER.DETAIL_TEAM_INFO}
                                            render={() => <LMTeamDetail {...props} title="Team" league={league} organization={organization} />}
                                        />
                                        <Route
                                            exact
                                            path={routes.LEAGUE_MANAGER.DETAIL_PLAYERS}
                                            render={() => <LMPlayers {...props} title="Players" league={league} organization={organization} />}
                                        />
                                        <Route
                                            exact
                                            path={routes.LEAGUE_MANAGER.DETAIL_PLAYER_INFO}
                                            render={() => <LMPlayerDetail {...props} title="Player" league={league} organization={organization} />}
                                        />
                                        <Route
                                            path={routes.LEAGUE_MANAGER.DETAIL_SETTINGS}
                                            render={() => <LMSettings {...props} title="Settings" league={league} organization={organization} />}
                                        />
                                        <Route
                                            exact
                                            path={routes.LEAGUE_MANAGER.DETAIL_CHALLENGES}
                                            render={() => <LMChallenges {...props} title="Challenges" league={league} organization={organization} />}
                                        />
                                        <Route
                                            exact
                                            path={routes.LEAGUE_MANAGER.DETAIL_CHALLENGE_INFO}
                                            render={() => <LMChallengeDetail {...props} title="Challenge Detail" league={league} organization={organization} />}
                                        />
                                        <Route
                                            exact
                                            path={routes.LEAGUE_MANAGER.DETAIL_MATCHES}
                                            render={() => <LMMatches {...props} title="Matches" league={league} organization={organization} />}
                                        />
                                        <Route
                                            exact
                                            path={routes.LEAGUE_MANAGER.DETAIL_NOTIFICATIONS}
                                            render={() => <LMNotifications {...props} title="Notifications" league={league} organization={organization} />}
                                        />
                                        <Route
                                            exact
                                            path={routes.LEAGUE_MANAGER.DETAIL_PAYMENTS}
                                            render={() => <LMPayments {...props} title="Payments" league={league} organization={organization} />}
                                        />
                                        <Route
                                            exact
                                            path={routes.LEAGUE_MANAGER.DETAIL_VENUES}
                                            render={() => <LMVenues {...props} title="Venues" league={league} organization={organization} />}
                                        />
                                        <Route
                                            exact
                                            path={routes.LEAGUE_MANAGER.DETAIL_VENUE_CREATE}
                                            render={() => <LMVenueCreate {...props} title="Create New Venue" league={league} organization={organization} />}
                                        />
                                        <Route
                                            exact
                                            path={routes.LEAGUE_MANAGER.DETAIL_VENUE_EDIT}
                                            render={() => <LMVenueCreate {...props} title="Edit Venue" league={league} organization={organization} />}
                                        />

                                        <Route
                                            exact
                                            path={routes.LEAGUE_MANAGER.DETAIL_VENUE_INFO}
                                            render={() => <LMVenueDetail {...props} title="Venue Detail" league={league} organization={organization} />}
                                        />
                                        <Route
                                            exact
                                            path={routes.LEAGUE_MANAGER.DETAIL_SCHEDULE}
                                            render={() => <LMSchedule {...props} title="Schedule" league={league} organization={organization} />}
                                        />

                                        <Route
                                            exact
                                            path={routes.LEAGUE_MANAGER.DETAIL_MATCH_INFO}
                                            render={() => <LMMatchDetail {...props} title="Match" league={league} organization={organization} />}
                                        />
                                        <Route
                                            exact
                                            path={routes.LEAGUE_MANAGER.DETAIL_MATCH_SCORING}
                                            render={() => <LMMatchDetail {...props} title="Live Scoring" league={league} organization={organization} />}
                                        />
                                        <Route
                                            exact
                                            path={routes.LEAGUE_MANAGER.DETAIL_STANDINGS}
                                            render={() => <LMStandings {...props} title="Standings" league={league} organization={organization} />}
                                        />
                                        <Route
                                            exact
                                            path={routes.LEAGUE_MANAGER.DETAIL_STATS}
                                            render={() => <LMStats {...props} title="Stats" league={league} organization={organization} />}
                                        />
                                        {/* <Tabs
                                            animated={{ inkBar: true, tabPane: false }}
                                            defaultActiveKey={current}
                                            activeKey={current}
                                            onTabClick={handleTabClick}
                                            tabBarStyle={{
                                                borderColor: '#13253c',
                                                background: 'linear-gradient(#0b1a2d, #13253c)',
                                                boxShadow: 'rgba(0, 0, 0, 0.05) 0px 2px 8px 0px',
                                                color: 'rgba(255,255,255,0.8)',
                                                margin: 0,
                                                display: 'flex',
                                                justifyContent: 'center',
                                                textTransform: 'uppercase',
                                                zIndex: 2,
                                            }}
                                        >
                                            <TabPane tab="Dashboard" key="dashboard" style={styles.tabPane}>
                                                <Route
                                                    exact
                                                    path={routes.LEAGUE_MANAGER.DETAIL_DASHBOARD}
                                                    render={() => <LMDashboard {...props} title="Dashboard" league={league} />}
                                                />
                                            </TabPane>
                                            <TabPane tab="Divisions" key="divisions" style={styles.tabPane}>
                                                <Route
                                                    exact
                                                    path={routes.LEAGUE_MANAGER.DETAIL_DIVISIONS}
                                                    render={() => <LMDivisions {...props} title="Divisions" league={league} />}
                                                />
                                                <Route
                                                    exact
                                                    path={routes.LEAGUE_MANAGER.DETAIL_DIVISION_CREATE}
                                                    render={() => <LMDivisionCreate {...props} title="Create New Division" league={league} />}
                                                />

                                                <Route
                                                    path={routes.LEAGUE_MANAGER.DETAIL_DIVISION}
                                                    render={() => <LMDivisionDetail {...props} title="Division Detail" league={league} />}
                                                />
                                            </TabPane>
                                            <TabPane tab="Teams" key="teams" style={styles.tabPane}>
                                                <Route
                                                    exact
                                                    path={routes.LEAGUE_MANAGER.DETAIL_TEAMS}
                                                    render={() => <LMTeams {...props} title="Teams" league={league} />}
                                                />
                                                <Route
                                                    exact
                                                    path={routes.LEAGUE_MANAGER.DETAIL_TEAM_INFO}
                                                    render={() => <LMTeamDetail {...props} title="Team" league={league} />}
                                                />
                                            </TabPane>
                                            <TabPane tab="Players" key="players" style={styles.tabPane}>
                                                <Route
                                                    exact
                                                    path={routes.LEAGUE_MANAGER.DETAIL_PLAYERS}
                                                    render={() => <LMPlayers {...props} title="Players" league={league} />}
                                                />
                                                <Route
                                                    exact
                                                    path={routes.LEAGUE_MANAGER.DETAIL_PLAYER_INFO}
                                                    render={() => <LMPlayerDetail {...props} title="Player" league={league} />}
                                                />
                                            </TabPane>
                                            <TabPane tab="Settings" key="settings" style={styles.tabPane}>
                                                <Route
                                                    path={routes.LEAGUE_MANAGER.DETAIL_SETTINGS}
                                                    render={() => <LMSettings {...props} title="Settings" league={league} />}
                                                />
                                            </TabPane>
                                            <TabPane tab="Matches" key="matches" style={styles.tabPane}>
                                                <Route
                                                    exact
                                                    path={routes.LEAGUE_MANAGER.DETAIL_MATCHES}
                                                    render={() => <LMMatches {...props} title="Matches" league={league} />}
                                                />
                                            </TabPane>
                                            <TabPane tab="Venues" key="venues" style={styles.tabPane}>
                                                <Route
                                                    exact
                                                    path={routes.LEAGUE_MANAGER.DETAIL_VENUES}
                                                    render={() => <LMVenues {...props} title="Venues" league={league} />}
                                                />
                                                <Route
                                                    exact
                                                    path={routes.LEAGUE_MANAGER.DETAIL_VENUE_CREATE}
                                                    render={() => <LMVenueCreate {...props} title="Create New Venue" league={league} />}
                                                />
                                                <Route
                                                    exact
                                                    path={routes.LEAGUE_MANAGER.DETAIL_VENUE_EDIT}
                                                    render={() => <LMVenueCreate {...props} title="Edit Venue" league={league} />}
                                                />

                                                <Route
                                                    exact
                                                    path={routes.LEAGUE_MANAGER.DETAIL_VENUE_INFO}
                                                    render={() => <LMVenueDetail {...props} title="Venue Detail" league={league} />}
                                                />
                                            </TabPane>
                                        </Tabs> */}

                                        {/* <Route
                                            exact
                                            path={routes.LEAGUE_MANAGER.DETAIL_SCHEDULE}
                                            render={() => <LMSchedule {...props} title="Schedule" league={league} />}
                                        />

                                        <Route
                                            exact
                                            path={routes.LEAGUE_MANAGER.DETAIL_RESULTS}
                                            render={() => <LMResults {...props} title="Results" league={league} />}
                                        />
                                        <Route
                                            exact
                                            path={routes.LEAGUE_MANAGER.DETAIL_STATS}
                                            render={() => <LMStats {...props} title="Stats" league={league} />}
                                        /> */}
                                    </Switch>
                                </Layout>
                            </Layout>
                        ) : (
                            <SectionContent padding="20px 40px">
                                <Row gutter={24} justify="center" type="flex">
                                    <Col xs={24}>
                                        <Card>
                                            <Result
                                                status="error"
                                                title="Error 403 - Access denied"
                                                subTitle={`You do not have permission to access ${league.name}.`}
                                                extra={[
                                                    <Link to="/league-manager" key="league-manager">
                                                        <Button type="primary" size="large">
                                                            Go to League Manager
                                                        </Button>
                                                    </Link>,
                                                ]}
                                            />
                                            {/* <Redirect to={`/league-manager`} /> */}
                                        </Card>
                                    </Col>
                                </Row>
                            </SectionContent>
                        )}
                    </React.Fragment>
                );
            }}
        </Query>
    );
}

const authCondition = (authUser) => !!authUser;
const WithAuthorization = withAuthorization(authCondition)(LMDetail);
export default withRouter(WithAuthorization);
