import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { Formik, ErrorMessage } from 'formik';
import { message, Button, Modal, Typography } from 'antd';
import { Form, FormItem, Input, Radio, InputNumber, Select } from 'formik-antd';
import { Query, Mutation } from '@apollo/client/react/components';
import { UPDATE_TABLE_MUTATION, CREATE_TABLE_MUTATION } from './data/mutations';
import { GET_VENUE_QUERY, GET_TABLE_MANUFACTURERS_QUERY, GET_TABLE_BY_ID_QUERY } from './data/queries';
import * as Yup from 'yup';
import _ from 'lodash';
import slugify from 'slugify';

const { Option } = Select;
const { Title, Text } = Typography;

const styles = {};

function AddTableModal(props) {
    const { authState, tableModalVisible, venue, matchData, selectedTableId, redirectAfterSave, onTableModalOk, onTableModalCancel } = props;
    const [loading, setLoading] = useState(false);
    const [manufacturers, setManufacturers] = useState();
    const formRef = useRef();

    const CreateTableSchema = Yup.object().shape({
        label: Yup.string().max(50, 'Too Long!').required('Required'),
    });

    function handleSubmit(values, createTable, updateTable) {
        const { label, type, size, year_built, model, manufacturer_id, livestream_url, is_featured_table, is_streaming_table, status } = values;
        setLoading(true);

        let make;
        if (manufacturer_id) {
            make = manufacturers.filter((item) => {
                return item.id === manufacturer_id;
            })[0].name;
        }

        const slug = slugify(label, {
            replacement: '-',
            remove: /[*+~.,()'"#!:/@]/g,
            lower: true,
        });

        if (selectedTableId) {
            updateTable({
                variables: {
                    id: selectedTableId,
                    changes: {
                        label,
                        slug,
                        type,
                        size,
                        year_built,
                        make,
                        model,
                        livestream_url,
                        manufacturer_id,
                        is_streaming_table,
                        is_featured_table,
                        status,
                        // ...(venue && { venue_id: venue && venue.id }),
                        user_id: props.authState.user.id,
                        match_id: matchData && matchData.id,
                    },
                },
                notifyOnNetworkStatusChange: true,
                awaitRefetchQueries: true,
                refetchQueries: [
                    {
                        query: GET_TABLE_BY_ID_QUERY,
                        variables: {
                            id: selectedTableId,
                        },
                    },
                ],
            });
        } else {
            createTable({
                variables: {
                    objects: [
                        {
                            label,
                            slug,
                            type,
                            size,
                            year_built,
                            make,
                            model,
                            livestream_url,
                            manufacturer_id,
                            is_streaming_table,
                            is_featured_table,
                            status,
                            // ...(venue && venue.id && { venue_id: venue && venue.id }),
                            user_id: props.authState.user.id,
                            match_id: matchData && matchData.id,
                        },
                    ],
                },
                notifyOnNetworkStatusChange: true,
                awaitRefetchQueries: true,
                // refetchQueries: [
                //     {
                //         query: GET_VENUE_QUERY,
                //         variables: {
                //             slug: venue.slug,
                //         },
                //     },
                // ],
                // refetchQueries: () => [ 'tournament_brackets' ],
                // refetchQueries: [
                // 	{
                // 		query: GET_TOURNAMENT_BRACKET_QUERY,
                // 		variables: { tournamentId: tournament.id }
                // 		// awaitRefetchQueries: true,
                // 		// onCompleted: (data) => {
                // 		// 	console.log(data);
                // 		// }
                // 		// notifyOnNetworkStatusChange: true
                // 		// fetchPolicy: 'cache-and-network'
                // 	}
                // ]
            });
        }
    }

    function handleFormFieldUpdates(event) {
        const caret = event.target.selectionStart;
        const element = event.target;
        window.requestAnimationFrame(() => {
            element.selectionStart = caret;
            element.selectionEnd = caret;
        });
    }

    const tableMutations = (data, loading, error) => {
        return (
            <Mutation
                mutation={CREATE_TABLE_MUTATION}
                // variables={venue ? { venue_id: venue.id } : null}
                onCompleted={(data) => {
                    setLoading(false);
                    message.success('Table(s) successfully created');
                    // const result = data.insert_pool_tables.returning[0];
                    if (redirectAfterSave === false) {
                        onTableModalOk();
                    } else {
                        props.history.push(`/matches/${matchData.slug}`);
                        onTableModalOk();
                    }
                }}
                awaitRefetchQueries
            >
                {(createTable, { error }) => (
                    <Mutation
                        mutation={UPDATE_TABLE_MUTATION}
                        // variables={venue ? { id: selectedTableId, venue_id: venue.id } : { id: selectedTableId }}
                        onCompleted={(data) => {
                            console.log(data);
                            setLoading(false);
                            message.success('Table changes saved');
                            // const result = data.update_pool_tables.returning[0];

                            if (redirectAfterSave === false) {
                                onTableModalOk();
                            } else {
                                props.history.push(`/matches/${matchData.slug}`);
                                onTableModalOk();
                            }
                        }}
                        // awaitRefetchQueries
                    >
                        {(updateTable, { error }) => {
                            if (error) {
                                alert('Something went wrong');
                            }
                            const pool_table = data && data.pool_tables && data.pool_tables.length > 0 && data.pool_tables[0];

                            const initialValues = pool_table
                                ? {
                                      ...pool_table,
                                  }
                                : {
                                      label: '',
                                      make: undefined,
                                      size: undefined,
                                      livestream_url: '',
                                      manufacturer_id: undefined,
                                      is_streaming_table: false,
                                      is_featured_table: false,
                                      status: 'OPEN',
                                  };

                            return (
                                <React.Fragment>
                                    {!loading && !error && (
                                        <Modal
                                            title={<Text style={{ fontWeight: 700 }}>{selectedTableId ? 'Update Table' : 'Add Table(s)'}</Text>}
                                            // getContainer={() => document.querySelector('.tables')}
                                            visible={tableModalVisible}
                                            onOk={onTableModalOk}
                                            onCancel={onTableModalCancel}
                                            // enableReinitialize
                                            centered
                                            transitionName="fade"
                                            // transitionName="none"
                                            maskTransitionName="none"
                                            destroyOnClose={true}
                                            footer={[
                                                <Button key="cancel" onClick={onTableModalCancel}>
                                                    Cancel
                                                </Button>,
                                                <Button
                                                    key="submit"
                                                    type="primary"
                                                    loading={loading}
                                                    onClick={() => {
                                                        formRef.current.handleSubmit();
                                                    }}
                                                >
                                                    {selectedTableId ? (loading ? 'Updating...' : 'Update') : 'Create'} Table
                                                </Button>,
                                            ]}
                                            bodyStyle={{
                                                maxHeight: 500,
                                                overflowY: 'auto',
                                                padding: '10px 20px',
                                            }}
                                        >
                                            <Formik
                                                ref={formRef}
                                                enableReinitialize
                                                initialValues={initialValues}
                                                validationSchema={CreateTableSchema}
                                                onSubmit={(values, actions) => {
                                                    // actions.setSubmitting(false);
                                                    // actions.resetForm();
                                                    handleSubmit(values, createTable, updateTable);
                                                }}
                                                render={({ errors, touched, values }) => (
                                                    <Form layout="vertical" style={{ maxWidth: 600 }}>
                                                        <FormItem label="Label" name="label" autoFocus required hasFeedback showValidateSuccess>
                                                            <Input
                                                                name="label"
                                                                placeholder="How to refer to this table (e.g. Table 1)"
                                                                size="large"
                                                                onChange={(event) => {
                                                                    handleFormFieldUpdates(event);
                                                                }}
                                                            />
                                                        </FormItem>

                                                        <FormItem name="manufacturer_id" label="Manufacturer" hasFeedback showValidateSuccess>
                                                            <Query
                                                                query={GET_TABLE_MANUFACTURERS_QUERY}
                                                                fetchPolicy="cache-and-network"
                                                                notifyOnNetworkStatusChange={true}
                                                                onCompleted={(data) => {
                                                                    setManufacturers(data.pool_table_manufacturers);
                                                                }}
                                                            >
                                                                {({ loading, error, data }) => {
                                                                    if (loading) return <div>Loading...</div>;
                                                                    if (error) return <div>Error: {error.message}</div>;

                                                                    return (
                                                                        <Select
                                                                            showSearch
                                                                            name="manufacturer_id"
                                                                            placeholder="Select a manufacturer"
                                                                            size="large"
                                                                            optionFilterProp="children"
                                                                            // defaultValue="lucy"
                                                                            filterOption={(input, option) =>
                                                                                option.props.children.props.children
                                                                                    .toLowerCase()
                                                                                    .indexOf(input.toLowerCase()) >= 0
                                                                            }
                                                                        >
                                                                            {data.pool_table_manufacturers.map((item, index) => (
                                                                                <Option key={index} value={item.id}>
                                                                                    <Text>{item.name}</Text>
                                                                                </Option>
                                                                            ))}
                                                                        </Select>
                                                                    );
                                                                }}
                                                            </Query>
                                                        </FormItem>
                                                        <FormItem name="size" label="Size" hasFeedback showValidateSuccess>
                                                            <Radio.Group name="size" defaultValue="8 Foot" size="medium">
                                                                <Radio.Button value="6.5 Foot">6.5 Foot</Radio.Button>
                                                                <Radio.Button value="7 Foot">7 Foot</Radio.Button>
                                                                <Radio.Button value="8 Foot">8 Foot</Radio.Button>
                                                                <Radio.Button value="9 Foot">9 Foot</Radio.Button>
                                                                <Radio.Button value="10 Foot">10 Foot</Radio.Button>
                                                                <Radio.Button value="12 Foot">12 Foot</Radio.Button>
                                                            </Radio.Group>
                                                        </FormItem>
                                                        <FormItem name="is_streaming_table" label="Live Streaming table">
                                                            <Radio.Group name="is_streaming_table">
                                                                <Radio value={true}>Yes</Radio>
                                                                <Radio value={false}>No</Radio>
                                                            </Radio.Group>
                                                        </FormItem>
                                                        <FormItem name="livestream_url" label="Live Stream URL" hasFeedback showValidateSuccess>
                                                            <Input
                                                                name="livestream_url"
                                                                placeholder="https://www.domain.com"
                                                                size="large"
                                                                onChange={(event) => {
                                                                    handleFormFieldUpdates(event);
                                                                }}
                                                            />
                                                        </FormItem>

                                                        <FormItem label="Status" name="status" hasFeedback showValidateSuccess>
                                                            <Radio.Group name="status" defaultValue="OPEN" size="medium">
                                                                <Radio.Button value="OPEN">OPEN</Radio.Button>
                                                                <Radio.Button value="CLOSED">CLOSED</Radio.Button>
                                                                <Radio.Button value="IN_USE">IN_USE</Radio.Button>
                                                            </Radio.Group>
                                                        </FormItem>
                                                        <button type="submit" hidden />
                                                        {/* <FormikDebug /> */}
                                                    </Form>
                                                )}
                                            />
                                        </Modal>
                                    )}
                                </React.Fragment>
                            );
                        }}
                    </Mutation>
                )}
            </Mutation>
        );
    };

    return (
        <React.Fragment>
            {selectedTableId ? (
                <Query
                    query={GET_TABLE_BY_ID_QUERY}
                    fetchPolicy="cache-and-network"
                    notifyOnNetworkStatusChange={true}
                    variables={{ id: selectedTableId }}
                    onCompleted={async (data) => {
                        console.log(data);
                    }}
                >
                    {({ loading, error, data }) => tableMutations(data, loading, error)}
                </Query>
            ) : (
                tableMutations()
            )}
        </React.Fragment>
    );
}

export default withRouter(AddTableModal);
