import React, { useState, useEffect, useRef } from 'react';
import { message, Button, Icon, Typography, Popconfirm, Avatar, Drawer, Badge, Empty, Modal, Menu, Dropdown } from 'antd';
import { Form, FormItem, AutoComplete, Input, Radio, InputNumber, Checkbox, DatePicker, Select, FormikDebug, SubmitButton, ResetButton } from 'formik-antd';

import { Formik, ErrorMessage } from 'formik';
import {
    FILTER_TOURNAMENT_PLAYERS,
    GET_TOURNAMENT_BRACKET_QUERY,
    GET_TOURNAMENT_BRACKET_MATCH_QUERY,
    GET_TOURNAMENT_TABLES_QUERY,
    GET_TOURNAMENT_PLAYERS_QUERY,
    GET_TOURNAMENT_QUERY,
} from '../data/queries';
import {
    ADVANCE_TOURNAMENT_BRACKET_MUTATION,
    UPDATE_TOURNAMENT_BRACKET_MATCH_MUTATION,
    UPDATE_TABLE_STATUS_MUTATION,
    UPDATE_TOURNAMENT_MUTATION,
    UPDATE_TOURNAMENT_PLAYER_MUTATION,
} from '../data/mutations';
import { functions } from '../../../../firebase';
import { connectFunctionsEmulator, httpsCallable } from 'firebase/functions';
import { DigitalPoolAPI } from '../../../../lib/api';
import * as Yup from 'yup';
import useBreakpoint from 'use-breakpoint';
import { defaultBreakpoints } from '../../../../utils/defaultBreakpoints';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { Query, Mutation } from '@apollo/client/react/components';
import _ from 'lodash';
import moment from 'moment';

const { Title, Text } = Typography;
const { Option, OptGroup } = Select;

const styles = {
    input: {
        minWidth: 300,
        padding: 5,
    },
    inputSmall: {
        // maxWidth: 50,
        padding: 5,
        textAlign: 'center',
    },
};

function MatchOverlay(props) {
    const { currentMatch, matches, modalScoreVisible, onModalScoreVisible, tournament, bracket, isFullScreen, onTournamentComplete } = props;
    const { breakpoint, maxWidth, minWidth } = useBreakpoint(defaultBreakpoints);

    console.log(currentMatch);

    // const [ value, setValue ] = useState();
    const [loading, setLoading] = useState(false);
    const [selectedTable, setSelectedTable] = useState();
    const [tables, setTables] = useState();
    // const [ dataSource, setDataSource ] = useState([]);
    const [players, setPlayers] = useState([]);
    const [players1, setPlayers1] = useState([]);
    const [players2, setPlayers2] = useState([]);
    const [winner, setWinner] = useState();
    const [challenger1Result, setChallenger1Result] = useState();
    const [challenger2Result, setChallenger2Result] = useState();
    const [confirmPopupVisible, setConfirmPopupVisible] = useState(false);
    const [confirmEndTournamentPopupVisible, setConfirmEndTournamentPopupVisible] = useState(false);
    const [confirmTablePopupVisible, setConfirmTablePopupVisible] = useState(false);
    const [tournamentComplete, setTournamentComplete] = useState(false);
    const [updateTableStatus, tableProps] = useMutation(UPDATE_TABLE_STATUS_MUTATION);
    const [updateTournament, tournamentProps] = useMutation(UPDATE_TOURNAMENT_MUTATION);
    const [updateTournamentPlayer, tournamentPlayerProps] = useMutation(UPDATE_TOURNAMENT_PLAYER_MUTATION);
    const formRef = useRef(null);
    const API = DigitalPoolAPI(props);

    useEffect(() => {
        async function fetchData() {
            const res = await props.client.query({
                query: GET_TOURNAMENT_PLAYERS_QUERY,
                variables: {
                    tournament_id: tournament.id,
                },
            });
            const players = res.data.tournament_players;
            setPlayers(players);
            return res;
        }
        fetchData();
    }, [props.client, tournament.id]);

    // useEffect(
    // 	() => {
    // 		if (currentMatch) {
    // 			if (currentMatch.challenger1_is_winner === true) {
    // 				setChallenger1Result('winner');
    // 				setChallenger2Result();
    // 			} else if (currentMatch.challenger2_is_winner === true) {
    // 				setChallenger2Result('winner');
    // 				setChallenger1Result();
    // 			} else if (currentMatch.challenger1_is_forfeit === true) {
    // 				setChallenger1Result('forfeit');
    // 			} else if (currentMatch.challenger2_is_forfeit === true) {
    // 				setChallenger2Result('forfeit');
    // 			}
    // 		}
    // 	},
    // 	[ currentMatch ]
    // );

    function handleScoreModalCancel(e) {
        onModalScoreVisible(false);
        setChallenger1Result();
        setChallenger2Result();
    }

    async function queryDB(name) {
        var dataSet = [];
        const res = await props.client.query({
            query: FILTER_TOURNAMENT_PLAYERS,
            variables: {
                name: `%${name}%`,
                tournament_id: tournament.id,
            },
            fetchPolicy: 'no-cache',
        });

        for (let i = 0; i < res.data.tournament_players.length; i++) {
            let player = res.data.tournament_players[i];
            dataSet.push(player);
        }
        return dataSet;
    }

    async function onSearch1(searchText) {
        console.log(searchText);
        const players = await queryDB(searchText);
        setPlayers1(players);
    }

    async function onSearch2(searchText) {
        console.log(searchText);
        const players = await queryDB(searchText);
        setPlayers2(players);
    }

    const onSelectPlayer1 = async (value) => {
        console.log(value);
    };

    const onSelectPlayer2 = async (value) => {
        console.log(value);
    };

    // function handleBlur(e) {
    // 	e.preventDefault();
    // 	e.stopPropagation();
    // 	const { value } = e.target;
    // 	console.log(value);
    // }

    // function onChange(e) {
    // 	e.preventDefault();
    // 	e.stopPropagation();
    // }

    // function onEnter(e) {
    // 	e.preventDefault();
    // 	e.stopPropagation();
    // 	const { value } = e.target;
    // 	console.log(value);
    // }

    function handleFocus(event) {
        event.target.select();
    }

    function handleSelectTableChange(id) {
        const table = tables.filter((item) => {
            return item.id === id;
        })[0];
        setSelectedTable(table);
    }

    function getWinnerLoser(values) {
        const {
            challenger1_id,
            challenger1_name,
            challenger1_score,
            challenger1_race_to,
            challenger1_seed,
            challenger1_country,
            challenger2_id,
            challenger2_name,
            challenger2_score,
            challenger2_race_to,
            challenger2_seed,
            challenger2_country,
        } = values;

        let winner;
        let loser;

        if (challenger1Result === 'forfeit' || challenger2Result === 'forfeit') {
            if (challenger1Result === 'forfeit') {
                winner = {
                    challenger2_id,
                    challenger2_name,
                    challenger2_score,
                    challenger2_race_to,
                    challenger2_seed,
                    challenger2_country,
                    challenger2_is_winner: true,
                    challenger2_is_forfeit: false,
                };
                loser = {
                    challenger1_id,
                    challenger1_name,
                    challenger1_score,
                    challenger1_race_to,
                    challenger1_seed,
                    challenger1_country,
                    challenger1_is_winner: false,
                    challenger1_is_forfeit: true,
                };
            } else if (challenger2Result === 'forfeit') {
                winner = {
                    challenger1_id,
                    challenger1_name,
                    challenger1_score,
                    challenger1_race_to,
                    challenger1_seed,
                    challenger1_country,
                    challenger1_is_winner: true,
                    challenger1_is_forfeit: false,
                };
                loser = {
                    challenger2_id,
                    challenger2_name,
                    challenger2_score,
                    challenger2_race_to,
                    challenger2_seed,
                    challenger2_country,
                    challenger2_is_winner: false,
                    challenger2_is_forfeit: true,
                };
            }
        } else if (challenger1Result === 'winner' || (challenger1_score && challenger1_score === challenger1_race_to)) {
            winner = {
                challenger1_id,
                challenger1_name,
                challenger1_score,
                challenger1_race_to,
                challenger1_seed,
                challenger1_country,
                challenger1_is_winner: true,
            };
            loser = {
                challenger2_id,
                challenger2_name,
                challenger2_score,
                challenger2_race_to,
                challenger2_seed,
                challenger2_country,
                challenger2_is_winner: false,
            };
        } else if (challenger2Result === 'winner' || (challenger2_score && challenger2_score === challenger2_race_to)) {
            winner = {
                challenger2_id,
                challenger2_name,
                challenger2_score,
                challenger2_race_to,
                challenger2_seed,
                challenger2_country,
                challenger2_is_winner: true,
            };
            loser = {
                challenger1_id,
                challenger1_name,
                challenger1_score,
                challenger1_race_to,
                challenger1_seed,
                challenger1_country,
                challenger1_is_winner: false,
            };
        }

        const results = {
            winner,
            loser,
        };
        console.log(results);
        return results;
    }

    function advanceToWinnerSide(values) {
        const { currentMatch, tournament, nextRecord, match, advanceTournamentBracket } = values;
        const nextMatch = match;

        // const nextWinnerSideMatch = matches.filter((match) => {
        // 	return match.match_number === nextRecord && nextRecord.winner_to_num;
        // });

        const nextWinnerSideMatch = matches[currentMatch.winner_to_num - 1];
        // console.log(matches);
        // console.log(nextWinnerSideMatch);
        if (currentMatch.winner_to_num) {
            advanceTournamentBracket({
                variables: {
                    match_number: currentMatch.winner_to_num,
                    tournament_id: tournament.id,
                    changes: {
                        // winner_from: currentMatch.identifier,
                        ...nextMatch,
                        ...(nextWinnerSideMatch &&
                            nextWinnerSideMatch.is_bye === true && {
                                status: 'COMPLETED',
                                progress: 100,
                                is_bye: true,
                            }),
                    },
                },
            });

            if (nextWinnerSideMatch && nextWinnerSideMatch.is_bye === true) {
                advanceTournamentBracket({
                    variables: {
                        match_number: nextWinnerSideMatch.winner_to_num,
                        tournament_id: tournament.id,
                        changes: {
                            // winner_from: currentMatch.identifier,
                            ...(nextWinnerSideMatch.challenger1_name
                                ? {
                                      challenger2_id: match.challenger1_id || match.challenger2_id,
                                      challenger2_name: match.challenger1_name || match.challenger2_name,
                                      challenger2_seed: match.challenger1_seed || match.challenger2_seed,
                                      challenger2_country: match.challenger1_country || match.challenger2_country,
                                      challenger2_race_to: match.challenger1_race_to || match.challenger2_race_to || tournament.winners_race_to,
                                      challenger2_score: 0,
                                  }
                                : {
                                      challenger2_id: match.challenger1_id || match.challenger2_id,
                                      challenger2_name: match.challenger1_name || match.challenger2_name,
                                      challenger2_seed: match.challenger1_seed || match.challenger2_seed,
                                      challenger2_country: match.challenger1_country || match.challenger2_country,
                                      challenger2_race_to: match.challenger1_race_to || match.challenger2_race_to || tournament.winners_race_to,
                                      challenger2_score: 0,
                                  }),
                        },
                    },
                });
            }
        }
    }

    function advanceToLoserSide(values) {
        const { currentMatch, tournament, nextRecord, match, advanceTournamentBracket } = values;
        // const nextLoserSideMatch = matches.filter((match) => {
        // 	return match.match_number === nextRecord && nextRecord.winner_to_num;
        // });

        console.log(match);

        const nextLoserSideMatch = matches[currentMatch.loser_to_num - 1];

        if (currentMatch.loser_to_num) {
            advanceTournamentBracket({
                variables: {
                    match_number: currentMatch.loser_to_num,
                    tournament_id: tournament.id,
                    changes: {
                        // loser_from: currentMatch.identifier,
                        ...match,
                        challenger1_race_to: tournament.losers_race_to,
                        challenger2_race_to: tournament.losers_race_to,
                        ...(nextLoserSideMatch &&
                            nextLoserSideMatch.is_bye === true && {
                                status: 'COMPLETED',
                                progress: 100,
                                is_bye: true,
                            }),
                    },
                },
            });

            if (nextLoserSideMatch && nextLoserSideMatch.is_bye === true) {
                advanceTournamentBracket({
                    variables: {
                        match_number: nextLoserSideMatch.winner_to_num,
                        tournament_id: tournament.id,
                        changes: {
                            // loser_from: currentMatch.identifier,
                            ...(nextLoserSideMatch.winner_from_top_num || nextLoserSideMatch.loser_from_top_num
                                ? {
                                      challenger1_id: match.challenger1_id || match.challenger2_id,
                                      challenger1_name: match.challenger1_name || match.challenger2_name,
                                      challenger1_seed: match.challenger1_seed || match.challenger2_seed,
                                      challenger1_country: match.challenger1_country || match.challenger2_country,
                                      challenger1_race_to: tournament.losers_race_to,
                                      challenger1_score: 0,
                                  }
                                : {
                                      challenger2_id: match.challenger1_id || match.challenger2_id,
                                      challenger2_name: match.challenger1_name || match.challenger2_name,
                                      challenger2_seed: match.challenger1_seed || match.challenger2_seed,
                                      challenger2_country: match.challenger1_country || match.challenger2_country,
                                      challenger2_race_to: tournament.losers_race_to,
                                      challenger2_score: 0,
                                  }),
                        },
                    },
                });
            }
        }
    }

    async function notifyPlayer(player1, player2, table, currentMatch) {
        if (tournament.use_text_messaging !== false) {
            let player1NameSplit;
            let player2NameSplit;
            let player1FirstName;
            let player2FirstName;
            let text;
            let player1Race;
            let player2Race;

            console.log({ player1, player2, table, currentMatch });
            const tableName =
                table && table.is_streaming_table === true
                    ? `${table.label} (Live Stream Table)`
                    : table && table.is_featured_table === true
                    ? `${table.label} (Featured Table)`
                    : table.label;

            if (player1) {
                player1NameSplit = player1.name && player1.name.split(' ');
                player1FirstName = player1NameSplit && player1NameSplit.length > 0 ? player1NameSplit[0] : player1.name;
            }

            if (player2) {
                player2NameSplit = player2.name && player2.name.split(' ');
                player2FirstName = player2NameSplit && player2NameSplit.length > 0 ? player2NameSplit[0] : player2.name;
            }

            if (player1 && player2) {
                if (currentMatch.identifier.includes('L')) {
                    player1Race = player1 && player1.race_to ? player1.race_to : tournament.losers_race_to;
                    player2Race = player2 && player2.race_to ? player2.race_to : tournament.losers_race_to;
                } else {
                    player1Race = player1 && player1.race_to ? player1.race_to : tournament.winners_race_to;
                    player2Race = player2 && player2.race_to ? player2.race_to : tournament.winners_race_to;
                }
                text = `Your match against ${
                    player2 && player2.name
                } has been assigned to ${tableName}. ${player1FirstName} goes to ${player1Race} and ${player2FirstName} goes to ${player2Race}`;
            } else if (player1 && !player2) {
                text = `Your match has been assigned to ${tableName}. ${player1 && player1.race_to ? `${player1FirstName} goes to ${player1.race_to}` : ''}`;
            } else if (!player1 && player2) {
                text = `Your match has been assigned to ${tableName}. ${player2 && player2.race_to ? `${player2FirstName} goes to ${player2.race_to}` : ''}`;
            } else {
                throw new Error('Text message could not be sent. No players found!');
            }

            if (window.location.hostname.indexOf('localhost') > -1) {
                connectFunctionsEmulator(functions, 'localhost', 5001);
            }
            try {
                const sendSMS = httpsCallable(functions, 'sms');
                const messageToSend = `DigitalPool.com\n${text}\n${moment().format('llll')}`;
                if (player1) {
                    const resultPlayer1 = await sendSMS({
                        phone_number: `+1${player1.phone_number}`,
                        message: messageToSend,
                        user_id: player1.id,
                        tournament_id: tournament.id,
                        // token: ''
                    });
                    console.log(player1);
                    console.log(resultPlayer1);
                }

                setLoading(false);
                // message.success(`SMS Notification sent to ${player1.name}`);
            } catch (e) {
                setLoading(false);
                console.log(e);
                // message.success(`SMS Notification error: ${JSON.stringify(e)}`);
            }
        } else {
            console.log('SMS notifications are currently disabled');
        }
    }

    function playerEliminated(player) {
        const playerId = player.challenger1_id || player.challenger2_id;

        if (playerId) {
            updateTournamentPlayer({
                variables: {
                    id: playerId,
                    tournament_id: tournament.id,
                    changes: {
                        place: currentMatch.is_finals === true ? '2' : currentMatch.is_consolidation_finals ? '2' : currentMatch.place,
                    },
                    // changes: {
                    // 	place:
                    // 		currentMatch.is_finals === true
                    // 			? '2'
                    // 			: currentMatch.is_consolidation_finals ? '2' : currentMatch.place
                    // }
                },
                // awaitRefetchQueries: true,
                // refetchQueries: [
                // 	{
                // 		query: GET_TOURNAMENT_QUERY,
                // 		variables: { id: tournament.id }
                // 	}
                // ]
            })
                .then((data) => {
                    console.log(data);
                })
                .catch((error) => {
                    console.log(error);
                    message.info('There was an error', error);
                });
        }
    }

    function completeTournament(winner) {
        const playerId = winner.challenger1_id || winner.challenger2_id;

        setTournamentComplete(true);
        updateTournamentPlayer({
            variables: {
                id: playerId,
                tournament_id: tournament.id,
                changes: {
                    place: '1',
                },
            },
        })
            .then((data) => {
                console.log(data);
            })
            .catch((error) => {
                console.log(error);
                message.info('There was an error', error);
            });

        updateTournament({
            variables: {
                id: tournament.id,
                changes: {
                    end_date_time: moment.utc(),
                    progress: '100',
                    status: 'COMPLETED',
                },
            },
            notifyOnNetworkStatusChange: true,
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: GET_TOURNAMENT_QUERY,
                    variables: { id: tournament.id },
                },
            ],
        })
            .then((data) => {
                onTournamentComplete(winner);
            })
            .catch((error) => {
                console.log(error);
                message.info('There was an error', error);
            });
    }

    function isPlayerInNextMatch(player, match) {
        if (
            (player && match && match.challenger1_name && match.challenger1_name === player.challenger1_name) ||
            (player && match && match.challenger2_name && match.challenger2_name === player.challenger2_name)
        ) {
            return true;
        } else {
            return false;
        }
    }

    function locateNextBracketPosition(match, player, side, fromMatchNumber, identifier) {
        let advanceTo;
        console.log('FROM MATCH', fromMatchNumber);
        console.log('PLAYER CONTENT', player);
        console.log('SIDE', side);
        console.log('MATCH CONTENT', match);
        console.log('CURRENT IDENTIFIER', identifier);
        // if (match.challenger1_name || match.challenger2_name) {
        // 	if (isPlayerInNextMatch(player, match) === true) {
        // 		console.log('player is already in match', match.match_number);
        // 		if (match.challenger1_name === player.challenger1_name) {
        // 			console.log(side + ' advances to top match', match.match_number);
        // 			advanceTo = 'top';
        // 		} else {
        // 			console.log(side + ' advances to bottom match', match.match_number);
        // 			advanceTo = 'bottom';
        // 		}
        // 	}
        // 	else {
        // 		if (match.challenger1_name) {
        // 			console.log(side + ' advances to bottom match', match.match_number);
        // 			advanceTo = 'bottom';
        // 		} else {
        // 			console.log(side + ' advances to topmatch', match.match_number);
        // 			advanceTo = 'top';
        // 		}
        // 	}
        // } else {
        if (side === 'winner') {
            if (match.identifier.includes('L')) {
                var hashMarkerForIdentifierIndex = match.identifier.indexOf('-');
                if (match.identifier.substring(1, hashMarkerForIdentifierIndex) % 2 === 0) {
                    // alert('code block 1');
                    console.log('match identifier: ', match.identifier);
                    console.log(side + ' advances to top match', match.match_number);
                    advanceTo = 'top';
                } else {
                    if (fromMatchNumber === match.winner_from_top_num) {
                        // alert('code block 2');
                        console.log(side + ' advances to top match', match.match_number);
                        advanceTo = 'top';
                    } else if (fromMatchNumber === match.winner_from_bottom_num) {
                        // alert('code block 3');
                        console.log(side + ' advances to bottom match', match.match_number);
                        advanceTo = 'bottom';
                    } else {
                        // alert('code block 4');
                        console.log(side + ' advances to top match', match.match_number);
                        advanceTo = 'top';
                    }
                }
            } else if (fromMatchNumber === match.winner_from_top_num) {
                // alert('code block 5');
                console.log(side + ' advances to top match', match.match_number);
                advanceTo = 'top';
            } else if (fromMatchNumber === match.winner_from_bottom_num) {
                // alert('code block 6');
                console.log(side + ' advances to bottom match', match.match_number);
                advanceTo = 'bottom';
            } else {
                // alert('code block 7');
                console.log(side + ' advances to top match', match.match_number);
                advanceTo = 'top';
            }
        } else {
            if (match.loser_from && match.loser_from.includes('W1')) {
                if (match.loser_from_top_num === fromMatchNumber) {
                    // alert('code block 8');
                    console.log(side + ' advances to top match', match.match_number);
                    advanceTo = 'top';
                } else if (match.loser_from_bottom_num === fromMatchNumber) {
                    // alert('code block 9');
                    console.log(side + ' advances to bottom match', match.match_number);
                    advanceTo = 'bottom';
                }
            } else {
                // alert('code block 10');
                console.log(side + ' advances to bottom match', match.match_number);
                advanceTo = 'bottom';
            }
        }

        return advanceTo;
    }

    function advanceMatches(data) {
        const { winner, loser, advanceTournamentBracket } = data;
        // const currentMatchNum = currentMatch.id % 2 === 0 ? 'even' : 'odd';
        // const currentPosition = winner.challenger1_id ? 'top' : 'bottom';
        // console.log('current match Num:', currentMatchNum);
        // console.log('current position:', currentPosition);

        // const nextWinnerSideMatch = matches.filter((match) => {
        // 	return match.match_number === currentMatch.winner_to_num;
        // });

        const nextWinnerSideMatch = [matches[currentMatch.winner_to_num - 1]];

        const nextWinnerMatchData = nextWinnerSideMatch.map((item) => {
            if (item) {
                // const nextMatchNum = item.id % 2 === 0 ? 'even' : 'odd';
                // const nextPosition = item.challenger1_id ? 'bottom' : 'top';

                const position = locateNextBracketPosition(item, winner, 'winner', currentMatch.match_number, currentMatch.identifier);
                console.log('new position', position);

                if (item && item.is_consolidation_finals === true) {
                    return {
                        challenger1_id: currentMatch.challenger1_id || currentMatch.challenger2_id,
                        challenger1_name: currentMatch.challenger1_name || currentMatch.challenger2_name,
                        challenger1_seed: currentMatch.challenger1_seed || currentMatch.challenger2_seed,
                        challenger1_country: currentMatch.challenger1_country || currentMatch.challenger2_country,
                        challenger1_race_to: currentMatch.challenger1_race_to || currentMatch.challenger2_race_to || tournament.winners_race_to,
                        challenger1_score: 0,

                        challenger2_id: currentMatch.challenger2_id || currentMatch.challenger1_id,
                        challenger2_name: currentMatch.challenger2_name || currentMatch.challenger1_name,
                        challenger2_seed: currentMatch.challenger2_seed || currentMatch.challenger1_seed,
                        challenger2_country: currentMatch.challenger2_country || currentMatch.challenger1_country,
                        challenger2_race_to: currentMatch.challenger2_race_to || currentMatch.challenger1_race_to || tournament.winners_race_to,
                        challenger2_score: 0,
                    };
                } else {
                    if (position === 'top') {
                        return {
                            challenger1_id: winner.challenger1_id || winner.challenger2_id,
                            challenger1_name: winner.challenger1_name || winner.challenger2_name,
                            challenger1_seed: winner.challenger1_seed || winner.challenger2_seed,
                            challenger1_country: winner.challenger1_country || winner.challenger2_country,
                            challenger1_race_to:
                                item && item.is_finals === true
                                    ? winner.challenger1_race_to || winner.challenger2_race_to || tournament.winners_race_to
                                    : winner.challenger1_race_to || winner.challenger2_race_to || tournament.winners_race_to,
                            challenger1_score: 0,
                        };
                    } else if (position === 'bottom') {
                        return {
                            challenger2_id: winner.challenger1_id || winner.challenger2_id,
                            challenger2_name: winner.challenger1_name || winner.challenger2_name,
                            challenger2_seed: winner.challenger1_seed || winner.challenger2_seed,
                            challenger2_country: winner.challenger1_country || winner.challenger2_country,
                            challenger2_race_to:
                                item && item.is_finals === true
                                    ? winner.challenger1_race_to || winner.challenger2_race_to || tournament.winners_race_to
                                    : winner.challenger1_race_to || winner.challenger2_race_to || tournament.winners_race_to,
                            challenger2_score: 0,
                        };
                    } else {
                        console.error('not sure where to put them');
                    }
                }
            }
        })[0];

        // const nextLoserSideMatch = matches.filter((match) => {
        // 	return match.match_number === currentMatch.loser_to_num;
        // });

        const nextLoserSideMatch = [matches[currentMatch.loser_to_num - 1]];

        const nextLoserMatchData = nextLoserSideMatch.map((item) => {
            if (item) {
                const position = locateNextBracketPosition(item, loser, 'loser', currentMatch.match_number, currentMatch.identifier);
                console.log('new position', position);

                if (item && item.is_consolidation_finals === true) {
                    return {
                        challenger1_id: currentMatch.challenger1_id || currentMatch.challenger2_id,
                        challenger1_name: currentMatch.challenger1_name || currentMatch.challenger2_name,
                        challenger1_seed: currentMatch.challenger1_seed || currentMatch.challenger2_seed,
                        challenger1_country: currentMatch.challenger1_country || currentMatch.challenger2_country,
                        challenger1_race_to: currentMatch.challenger1_race_to || currentMatch.challenger2_race_to || tournament.winners_race_to,
                        challenger1_score: 0,

                        challenger2_id: currentMatch.challenger2_id || currentMatch.challenger1_id,
                        challenger2_name: currentMatch.challenger2_name || currentMatch.challenger1_name,
                        challenger2_seed: currentMatch.challenger2_seed || currentMatch.challenger1_seed,
                        challenger2_country: currentMatch.challenger2_country || currentMatch.challenger1_country,
                        challenger2_race_to: currentMatch.challenger2_race_to || currentMatch.challenger1_race_to || tournament.winners_race_to,
                        challenger2_score: 0,
                    };
                } else {
                    if (position === 'top') {
                        return {
                            challenger1_id: loser.challenger1_id || loser.challenger2_id,
                            challenger1_name: loser.challenger1_name || loser.challenger2_name,
                            challenger1_seed: loser.challenger1_seed || loser.challenger2_seed,
                            challenger1_country: loser.challenger1_country || loser.challenger2_country,
                            challenger1_race_to: loser.challenger1_race_to || loser.challenger2_race_to || tournament.losers_race_to,
                            challenger1_score: 0,
                        };
                    } else if (position === 'bottom') {
                        return {
                            challenger2_id: loser.challenger1_id || loser.challenger2_id,
                            challenger2_name: loser.challenger1_name || loser.challenger2_name,
                            challenger2_seed: loser.challenger1_seed || loser.challenger2_seed,
                            challenger2_country: loser.challenger1_country || loser.challenger2_country,
                            challenger2_race_to: loser.challenger1_race_to || loser.challenger2_race_to || tournament.losers_race_to,
                            challenger2_score: 0,
                        };
                    } else {
                        console.error('not sure where to put them');
                    }
                }
            }
        })[0];

        console.log({
            winner,
            loser,
        });
        console.log('next winner side match', nextWinnerSideMatch);
        console.log('next loser side match', nextLoserSideMatch);
        console.log('current match', currentMatch);

        // const hotSeatMatch = matches.filter((match) => {
        // 	return match.is_semi_finals === true;
        // })[0];

        if (
            currentMatch &&
            currentMatch.is_finals === true &&
            (currentMatch.challenger1_id === winner.challenger1_id || currentMatch.challenger2_id === winner.challenger2_id)
        ) {
            // alert('is finals');
            setWinner(winner);
            playerEliminated(loser);
            setConfirmEndTournamentPopupVisible(true);

            if (tournamentComplete === false) {
                advanceToWinnerSide({
                    advanceTournamentBracket,
                    currentMatch,
                    tournament,
                    match: nextWinnerMatchData,
                    // nextRecord: nextWinnerSideMatch[0]
                });

                advanceToLoserSide({
                    advanceTournamentBracket,
                    currentMatch,
                    tournament,
                    match: nextLoserMatchData,
                    // nextRecord: nextLoserSideMatch[0]
                });
            }
        } else if (currentMatch && currentMatch.is_consolidation_finals === true) {
            // 	alert('is consolidation finals');
            setWinner(winner);
            playerEliminated(loser);
            setConfirmEndTournamentPopupVisible(true);
        } else {
            // alert('not finals or consolidation finals');
            if (!nextWinnerMatchData && !nextLoserMatchData) {
                setWinner(winner);
                setConfirmEndTournamentPopupVisible(true);
            }

            if (!nextLoserMatchData) {
                playerEliminated(loser);
            }

            advanceToWinnerSide({
                advanceTournamentBracket,
                currentMatch,
                tournament,
                match: nextWinnerMatchData,
                // nextRecord: nextWinnerSideMatch[0]
            });

            advanceToLoserSide({
                advanceTournamentBracket,
                currentMatch,
                tournament,
                match: nextLoserMatchData,
                // nextRecord: nextLoserSideMatch[0]
            });
        }

        return {
            nextWinnerSideMatch: nextWinnerSideMatch[0],
            nextWinnerMatchData,
            nextLoserSideMatch: nextLoserSideMatch[0],
            nextLoserMatchData,
        };
    }

    async function updateTournamentProgress() {
        const inProgressMatches = matches.filter((match) => {
            return match.progress !== null;
        });

        const byeMatches = matches.filter((match) => {
            return match.is_bye === true;
        });

        const completedMatches = matches
            .filter((match) => {
                return match.status === 'COMPLETED';
            })
            .filter((match) => {
                return match.is_bye !== true;
            });

        const progress = inProgressMatches
            .map((match) => {
                return match.progress;
            })
            .reduce((acc, progress) => {
                return acc + progress;
            }, 0);

        // console.log(matches.length);
        // console.log(inProgressMatches.length);
        const currentMatchesProgress = progress / inProgressMatches.length;
        const newProgress = (completedMatches.length / (matches.length - byeMatches.length)) * 100;
        console.log(currentMatchesProgress);
        console.log(newProgress);
        await API.updateTournamentProgress(newProgress);
        return newProgress;
    }

    function getChallengerRaceTo(currentMatch, challenger) {
        const identifier = currentMatch.identifier && currentMatch.identifier.slice(0, 1);
        const challenger_race_to =
            challenger === 'challenger1' ? currentMatch.challenger1_race_to : challenger === 'challenger2' ? currentMatch.challenger2_race_to : null;
        if (challenger_race_to) {
            return challenger_race_to;
        } else {
            if (identifier === 'W') {
                return tournament.winners_race_to;
            } else {
                return tournament.losers_race_to;
            }
        }
    }

    function handleSubmit(values, updateTournamentBracket, advanceTournamentBracket) {
        let challenger1_id;
        let challenger1_seed;
        let challenger1_country;
        let challenger1_sms_sent;
        let challenger2_id;
        let challenger2_seed;
        let challenger2_country;
        let challenger2_sms_sent;
        console.log(values);

        let {
            challenger1_name,
            challenger1_score,
            challenger1_race_to,
            challenger2_name,
            challenger2_score,
            challenger2_race_to,
            table_id,
            // start_time,
            // end_time
            // scheduled_time
        } = values;

        let player1;
        let player2;
        let start_time;
        let end_time;
        let matchStatus;
        let tableStatus;
        let winner;
        let loser;
        let results;
        let is_bye;

        setLoading(true);

        // returns true if the variable does NOT contain a valid number
        if (isNaN(challenger1_name) !== true) {
            const id = parseInt(challenger1_name);
            player1 = players.filter((item) => {
                return item.id === id;
            })[0];
        } else {
            player1 = players.filter((item) => {
                return item.name === challenger1_name;
            })[0];
        }

        if (isNaN(challenger2_name) !== true) {
            const id = parseInt(challenger2_name);
            player2 = players.filter((item) => {
                return item.id === id;
            })[0];
        } else {
            player2 = players.filter((item) => {
                return item.name === challenger2_name;
            })[0];
        }

        challenger1_name = player1 ? (player1.name ? player1.name : challenger1_name) : challenger1_name;
        challenger1_id = player1 ? (player1.id ? player1.id : challenger1_id) : challenger1_id;
        challenger1_seed = player1 ? (player1.seed ? player1.seed : challenger1_seed) : challenger1_seed;
        challenger1_country = player1 ? (player1.country ? player1.country : challenger1_country) : challenger1_country;
        challenger1_sms_sent = player1 ? (player1.sms_sent ? player1.sms_sent : challenger1_sms_sent) : challenger1_sms_sent;
        challenger2_name = player2 ? (player2.name ? player2.name : challenger2_name) : challenger2_name;
        challenger2_id = player2 ? (player2.id ? player2.id : challenger2_id) : challenger2_id;
        challenger2_seed = player2 ? (player2.seed ? player2.seed : challenger2_seed) : challenger2_seed;
        challenger2_country = player2 ? (player2.country ? player2.country : challenger2_country) : challenger2_country;
        challenger2_sms_sent = player2 ? (player2.sms_sent ? player2.sms_sent : challenger2_sms_sent) : challenger2_sms_sent;

        if (challenger1_name && challenger1_name !== 'BYE' && challenger2_name && challenger2_name !== 'BYE') {
            is_bye = false;
        } else if (challenger1_name === 'BYE' || challenger2_name === 'BYE') {
            is_bye = true;
        }

        if (challenger1Result === 'forfeit' || challenger2Result === 'forfeit') {
            matchStatus = 'COMPLETED';
            tableStatus = 'OPEN';
            end_time = moment.utc();
            results = getWinnerLoser({
                challenger1_id,
                challenger1_name,
                challenger1_country,
                challenger1_score,
                challenger1_race_to,
                challenger1_seed,
                challenger2_id,
                challenger2_name,
                challenger2_country,
                challenger2_score,
                challenger2_race_to,
                challenger2_seed,
            });
            winner = results.winner;
            loser = results.loser;
        } else if (table_id) {
            if (
                challenger1Result === 'winner' ||
                challenger2Result === 'winner' ||
                challenger1_score === challenger1_race_to ||
                challenger2_score === challenger2_race_to
            ) {
                matchStatus = 'COMPLETED';
                tableStatus = 'OPEN';
                end_time = moment.utc();
                results = getWinnerLoser({
                    challenger1_id,
                    challenger1_name,
                    challenger1_country,
                    challenger1_score,
                    challenger1_race_to,
                    challenger1_seed,
                    challenger2_id,
                    challenger2_name,
                    challenger2_country,
                    challenger2_score,
                    challenger2_race_to,
                    challenger2_seed,
                });
                winner = results.winner;
                loser = results.loser;
            } else if (
                (challenger1_score && challenger1_score > 0 && challenger1_score !== challenger1_race_to) ||
                (challenger2_score && challenger2_score > 0 && challenger2_score !== challenger2_race_to)
            ) {
                tableStatus = 'IN_USE';
                matchStatus = 'IN_PROGRESS';
                if (!start_time) {
                    start_time = moment.utc();
                }
            } else if (
                challenger1_name &&
                challenger2_name &&
                (challenger1_score === null || challenger1_score === 0) &&
                (challenger2_score === null || challenger1_score === 0)
            ) {
                // tableStatus = 'ASSIGNED';
                tableStatus = 'IN_USE';
                matchStatus = 'IN_PROGRESS';
                if (!start_time) {
                    start_time = moment.utc();
                }
            } else {
                tableStatus = 'IN_USE';
                if (!start_time) {
                    start_time = moment.utc();
                }
            }
        } else {
            if (challenger1Result === 'winner' || challenger2Result === 'winner' || challenger1_score || challenger2_score) {
                if (
                    challenger1Result === 'winner' ||
                    challenger2Result === 'winner' ||
                    challenger1_score === challenger1_race_to ||
                    challenger2_score === challenger2_race_to
                ) {
                    matchStatus = 'COMPLETED';
                    tableStatus = 'OPEN';
                    end_time = moment.utc();
                    results = getWinnerLoser({
                        challenger1_id,
                        challenger1_name,
                        challenger1_country,
                        challenger1_score,
                        challenger1_race_to,
                        challenger1_seed,
                        challenger2_id,
                        challenger2_name,
                        challenger2_country,
                        challenger2_score,
                        challenger2_race_to,
                        challenger2_seed,
                    });
                    winner = results.winner;
                    loser = results.loser;
                } else {
                    // tableStatus = 'IN_USE';
                    matchStatus = 'IN_PROGRESS';
                    if (!start_time) {
                        start_time = moment.utc();
                    }
                }
            } else {
                matchStatus = 'NOT_STARTED';
                tableStatus = 'OPEN';
            }
        }

        // console.log('table status:', tableStatus);
        // console.log('match status:', matchStatus);
        console.log('winner:', winner);
        console.log('loser:', loser);

        // console.log(table_id);
        // console.log(tables);
        // console.log(finals);

        const table = tables.filter((item) => {
            return item.id === table_id;
        })[0];

        const totalMatches = parseInt(challenger1_race_to + challenger2_race_to) - 1;
        const challengerScores = parseInt(challenger1_score + challenger2_score);
        const matchProgress =
            matchStatus === 'COMPLETED'
                ? 100
                : challenger1_score === challenger1_race_to || challenger2_score === challenger2_race_to
                ? 100
                : ((challengerScores / totalMatches) * 100).toFixed(0);

        if (currentMatch.table_id && currentMatch.table_id !== table_id) {
            console.log('SWITCH TABLES');
            // Switch the current table back to OPEN before assigning a new table
            updateTableStatus({
                variables: {
                    id: currentMatch.table_id,
                    changes: {
                        status: 'OPEN',
                    },
                },
            });
        }

        if (table_id) {
            // console.log(`Table ${table.label}`, tableStatus);
            updateTableStatus({
                variables: {
                    id: table_id,
                    changes: {
                        status: tableStatus,
                    },
                },
            })
                .then((data) => {
                    // if (tableStatus === 'ASSIGNED') {
                    if (!challenger1_score && !challenger2_score && tableStatus === 'IN_USE') {
                        if (player1 && player1.phone_number) {
                            notifyPlayer(player1, player2, table, currentMatch);
                        }
                        if (player2 && player2.phone_number) {
                            notifyPlayer(player2, player1, table, currentMatch);
                        }
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        }

        if (matchStatus === 'COMPLETED') {
            const advance = advanceMatches({
                winner,
                loser,
                advanceTournamentBracket,
            });

            console.log('advance to', advance);
            setLoading(false);
            updateTournamentProgress();
            message.success(`Match ${currentMatch.match_number} updated`);
        }

        let changes;

        if (winner && loser) {
            changes = {
                ...winner,
                ...loser,
            };
        } else {
            changes = {
                challenger1_id,
                challenger1_name,
                challenger1_country,
                challenger1_score,
                challenger1_race_to,
                challenger1_seed,
                challenger1_is_winner: false,
                challenger1_is_forfeit: false,
                challenger2_id,
                challenger2_name,
                challenger2_country,
                challenger2_score,
                challenger2_race_to,
                challenger2_seed,
                challenger2_is_winner: false,
                challenger2_is_forfeit: false,
            };
        }

        changes = {
            ...changes,
            start_time,
            end_time,
            table_name: table && table.label,
            table_id: tableStatus === 'OPEN' ? null : table_id,
            status: matchStatus,
            progress: matchProgress,
            is_bye,
        };

        console.log(changes);

        updateTournamentBracket({
            variables: {
                id: currentMatch.id,
                tournament_id: tournament.id,
                changes: changes,
            },
            // optimisticResponse: {
            // 	__typename: 'Mutation',
            // 	update_tournament_brackets: {
            // 		id: currentMatch.id,
            // 		tournament_id: tournament.id,
            // 		__typename: 'tournament_brackets',
            // 		changes: changes,
            // 		affected_rows: []
            // 	}
            // },
            notifyOnNetworkStatusChange: true,
            awaitRefetchQueries: true,
            // refetchQueries: [
            // 	{
            // 		query: GET_TOURNAMENT_TABLES_QUERY,
            // 		variables: { tournament_id: tournament.id }
            // 	},
            // 	{
            // 		query: GET_TOURNAMENT_BRACKET_QUERY,
            // 		variables: { tournamentId: tournament.id }
            // 	}
            // ]
        })
            .then((data) => {})
            .catch((error) => {
                console.log(error);
                setLoading(false);
                message.info('There was an error', error);
            });
    }

    function handleMenuClick(e, position) {
        console.log('click', e);
        console.log(formRef.current);

        if (e.key === 'forfeit') {
            formRef.current.setValues({
                ...formRef.current.state.values,
                challenger1_score: 0,
                challenger2_score: 0,
            });
        }
        if (position === 'top') {
            if (e.key !== 'none') {
                setChallenger1Result(e.key);
            } else {
                setChallenger1Result();
            }
            setChallenger2Result();
        } else if (position === 'bottom') {
            if (e.key !== 'none') {
                setChallenger2Result(e.key);
            } else {
                setChallenger2Result();
            }
            setChallenger1Result();
        }
    }

    function confirmEditCompletedMatch(form) {
        formRef.current.handleSubmit();
    }

    function cancelEditCompletedMatch(e) {
        handleScoreModalCancel();
        // console.log(e);
        // message.error('Click on No');
    }

    function cancelEndTournament(e) {
        setConfirmEndTournamentPopupVisible(false);
    }

    function handleVisibleChange(visible) {
        setConfirmPopupVisible(visible);
    }

    function confirmRemoveTableAssignment(updateTournamentBracket) {
        handleRemoveTableAssignment(updateTournamentBracket);
    }

    function cancelRemoveTableAssignment(e) {
        // handleScoreModalCancel();
        // console.log(e);
        // message.error('Click on No');
    }

    function handleRemoveTableVisibleChange(visible) {
        setConfirmTablePopupVisible(visible);
    }

    const MatchSchema = Yup.object().shape({
        // challenger1_name: Yup.string().nullable().required('Required'),
        // challenger2_name: Yup.string().nullable().required('Required'),
        // challenger1_score: Yup.number().nullable(),
        // challenger2_score: Yup.number().nullable()
        // division_type: Yup.string().required('Required'),
        // allowed_games: Yup.string().required('Required')
    });

    const actionsMenuTop = (
        <Menu
            onClick={(e) => {
                handleMenuClick(e, 'top');
            }}
            selectedKeys={[challenger1Result]}
        >
            <Menu.Item key="winner">Winner</Menu.Item>
            <Menu.Item key="forfeit">Forfeit</Menu.Item>
            <Menu.Divider />
            <Menu.Item key="none">None</Menu.Item>
        </Menu>
    );

    const actionsMenuBottom = (
        <Menu
            onClick={(e) => {
                handleMenuClick(e, 'bottom');
            }}
            selectedKeys={[challenger2Result]}
        >
            <Menu.Item key="winner">Winner</Menu.Item>
            <Menu.Item key="forfeit">Forfeit</Menu.Item>
            <Menu.Divider />
            <Menu.Item key="none">None</Menu.Item>
        </Menu>
    );

    async function handleRemoveTableAssignment(updateTournamentBracket) {
        const key = 'updatable';
        message.loading({ content: `Removing table assignment...`, key, duration: 0 });
        updateTableStatus({
            variables: {
                id: currentMatch.table_id,
                changes: {
                    status: 'OPEN',
                },
            },
            notifyOnNetworkStatusChange: true,
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: GET_TOURNAMENT_TABLES_QUERY,
                    variables: { tournament_id: tournament.id },
                    // awaitRefetchQueries: true,
                    // onCompleted: (data) => {
                    // 	console.log(data);
                    // },
                    // notifyOnNetworkStatusChange: true,
                    // fetchPolicy: 'cache-and-network'
                },
                // {
                // 	query: GET_TOURNAMENT_BRACKET_QUERY,
                // 	variables: { tournamentId: tournament.id },
                // 	awaitRefetchQueries: true
                // }
            ],
        });

        updateTournamentBracket({
            variables: {
                id: currentMatch.id,
                tournament_id: tournament.id,
                changes: {
                    table_id: null,
                    status: 'NOT_STARTED',
                },
            },
            notifyOnNetworkStatusChange: true,
            awaitRefetchQueries: true,
            refetchQueries: [
                // {
                // 	query: GET_TOURNAMENT_TABLES_QUERY,
                // 	variables: { tournament_id: tournament.id }
                // }
                // {
                // 	query: GET_TOURNAMENT_BRACKET_MATCH_QUERY,
                // 	variables: { match_id: currentMatch.id, tournament_id: tournament.id }
                // }
            ],
        })
            .then((data) => {
                // console.log(data);
                message.success({
                    content: `Table assignment removed for Match ${currentMatch.match_number}.`,
                    key,
                    duration: 2,
                });
            })
            .catch((error) => {
                console.log(error);
                message.error({ content: `There was an error ${JSON.stringify(error)}`, key, duration: 2 });
            });
    }

    return (
        <Mutation
            mutation={UPDATE_TOURNAMENT_BRACKET_MATCH_MUTATION}
            onCompleted={(data) => {
                // console.log(data);
                setLoading(false);
                // message.success('Changes saved');
                // const result = data.update_tournament_brackets.returning[0];
                // console.log(result);
                // props.history.push(`/league-manager/${props.league.slug}/venues/${result.slug}`);
                onModalScoreVisible(false);
                setChallenger1Result();
                setChallenger2Result();
                // props.refetch();
            }}
            // update={(cache, { data: { update_tournament_brackets } }) => {
            // 	const data = cache.readQuery({
            // 		query: GET_TOURNAMENT_BRACKET_QUERY,
            // 		variables: { tournamentId: tournament.id }
            // 	});
            // 	const updatedMatch = data.tournament_brackets.find(
            // 		(match) => match.id === update_tournament_brackets.returning[0].id
            // 	);

            // 	const updatedBracket = data.tournament_brackets.map((match) => {
            // 		if (match.id === updatedMatch.id) {
            // 			return updatedMatch;
            // 		} else {
            // 			return match;
            // 		}
            // 	});

            // 	cache.writeQuery({
            // 		query: GET_TOURNAMENT_BRACKET_QUERY,
            // 		variables: { tournamentId: tournament.id },
            // 		data: { tournament_brackets: [ ...updatedBracket ] }
            // 	});
            // }}
            // refetchQueries={() => [
            // 	{
            // 		query: GET_TOURNAMENT_BRACKET_QUERY,
            // 		variables: { tournamentId: tournament.id }
            // 		// awaitRefetchQueries: true,
            // 		// onCompleted: (data) => {
            // 		// 	console.log(data);
            // 		// }
            // 		// notifyOnNetworkStatusChange: true
            // 		// fetchPolicy: 'cache-and-network'
            // 	}
            // ]}
            awaitRefetchQueries
        >
            {(updateTournamentBracket, { error }) => (
                <Mutation
                    mutation={ADVANCE_TOURNAMENT_BRACKET_MUTATION}
                    onCompleted={(data) => {
                        // console.log(data);
                        setLoading(false);
                        // message.success('Changes saved');
                        // const result = data.update_tournament_brackets.returning[0];
                        // console.log(result);
                        // props.history.push(`/league-manager/${props.league.slug}/venues/${result.slug}`);
                        onModalScoreVisible(false);
                        setChallenger1Result();
                        setChallenger2Result();
                        // props.refetch();
                    }}
                >
                    {(advanceTournamentBracket, { error }) => {
                        if (error) {
                            alert(error);
                        }
                        return (
                            <React.Fragment>
                                <Modal
                                    title={`Match ${currentMatch && currentMatch.match_number} (${currentMatch && currentMatch.identifier})`}
                                    getContainer={() =>
                                        breakpoint === 'mobile'
                                            ? document.body
                                            : isFullScreen === true
                                            ? document.querySelector('#bracket-container')
                                            : document.body
                                    }
                                    visible={modalScoreVisible}
                                    // okText="Save Match"
                                    // okButtonProps={{ htmlType: 'submit', loading: loading }}
                                    // onOk={handleScoreModalOk}
                                    onCancel={handleScoreModalCancel}
                                    // centered
                                    style={{
                                        top: '50%',
                                        // left: isWidthDown('xs', width) ? 0 : -120,
                                        transform: 'translateY(-50%)',
                                    }}
                                    // transitionName="none"
                                    transitionName="fade"
                                    maskTransitionName="none"
                                    destroyOnClose={true}
                                    footer={null}
                                >
                                    <Formik
                                        initialValues={
                                            currentMatch
                                                ? {
                                                      challenger1_name: currentMatch.challenger1_name,
                                                      challenger1_race_to: getChallengerRaceTo(currentMatch, 'challenger1'),
                                                      challenger1_score: currentMatch.challenger1_score || 0,
                                                      challenger2_name: currentMatch.challenger2_name,
                                                      challenger2_race_to: getChallengerRaceTo(currentMatch, 'challenger2'),
                                                      challenger2_score: currentMatch.challenger2_score || 0,
                                                      table_id: currentMatch.table_id || undefined,
                                                  }
                                                : {
                                                      challenger1_name: '',
                                                      challenger1_race_to: tournament.winners_race_to,
                                                      challenger1_score: 0,
                                                      challenger2_name: '',
                                                      challenger2_race_to: tournament.winners_race_to,
                                                      challenger2_score: 0,
                                                      table_id: undefined,
                                                  }
                                        }
                                        ref={formRef}
                                        enableReinitialize
                                        validationSchema={MatchSchema}
                                        onSubmit={(values, actions) => {
                                            // actions.setSubmitting(false);
                                            // actions.resetForm();
                                            handleSubmit(values, updateTournamentBracket, advanceTournamentBracket);
                                        }}
                                        render={({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                                            <Form onSubmit={handleSubmit} layout="vertical">
                                                <table style={{ width: '100%' }}>
                                                    <thead>
                                                        <tr>
                                                            <th style={{ paddingBottom: 5 }}>Player</th>
                                                            <th style={{ textAlign: 'center' }}>Race To</th>
                                                            <th style={{ textAlign: 'center' }}>Score</th>
                                                            {/* <th /> */}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td style={{ width: '100%' }}>
                                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                    {currentMatch.challenger1_is_winner === true ||
                                                                    challenger1Result === 'winner' ||
                                                                    challenger2Result === 'forfeit' ||
                                                                    values.challenger1_score === getChallengerRaceTo(currentMatch, 'challenger1') ? (
                                                                        <span style={{ marginRight: 5 }}>
                                                                            <Avatar
                                                                                style={{
                                                                                    backgroundColor: '#87d068',
                                                                                }}
                                                                            >
                                                                                W
                                                                            </Avatar>
                                                                        </span>
                                                                    ) : (
                                                                        ((currentMatch.status === 'COMPLETED' &&
                                                                            currentMatch.challenger1_is_winner === false) ||
                                                                            challenger2Result === 'winner' ||
                                                                            challenger1Result === 'forfeit' ||
                                                                            values.challenger2_score === getChallengerRaceTo(currentMatch, 'challenger2')) && (
                                                                            <span style={{ marginRight: 5 }}>
                                                                                <Avatar
                                                                                    style={{
                                                                                        backgroundColor: '#f5222d',
                                                                                    }}
                                                                                >
                                                                                    {currentMatch.challenger1_is_forfeit || challenger1Result === 'forfeit'
                                                                                        ? 'FF'
                                                                                        : 'L'}
                                                                                </Avatar>
                                                                            </span>
                                                                        )
                                                                    )}
                                                                    <FormItem
                                                                        name="challenger1_name"
                                                                        // required
                                                                        style={{ width: '100%' }}
                                                                    >
                                                                        <AutoComplete
                                                                            dataSource={players1}
                                                                            style={{ width: '100%' }}
                                                                            onSelect={onSelectPlayer1}
                                                                            onSearch={onSearch1}
                                                                            name="challenger1_name"
                                                                            placeholder="Player 1 Name"
                                                                            size="large"
                                                                            // required
                                                                            optionFilterProp="children"
                                                                            filterOption={(inputValue, option) => {
                                                                                return (
                                                                                    option.props.children &&
                                                                                    option.props.children
                                                                                        .toString()
                                                                                        .toLowerCase()
                                                                                        .indexOf(inputValue.toLowerCase()) >= 0
                                                                                );
                                                                            }}
                                                                        >
                                                                            {players1 &&
                                                                                players1.length > 0 &&
                                                                                players1.map((item, index) => (
                                                                                    <Option key={index} value={item.id.toString()}>
                                                                                        {item.name}
                                                                                    </Option>
                                                                                ))}
                                                                        </AutoComplete>
                                                                    </FormItem>
                                                                </div>
                                                            </td>
                                                            <td
                                                                style={{
                                                                    textAlign: 'center',
                                                                    paddingLeft: 5,
                                                                    paddingRight: 5,
                                                                }}
                                                            >
                                                                <FormItem name="challenger1_race_to">
                                                                    <InputNumber
                                                                        name="challenger1_race_to"
                                                                        placeholder="0"
                                                                        min={0}
                                                                        // value={score}
                                                                        // onChange={onChange}
                                                                        onFocus={handleFocus}
                                                                        // max={getChallengerRaceTo(
                                                                        // 	currentMatch,
                                                                        // 	'challenger1'
                                                                        // )}
                                                                        // onBlur={handleBlur}
                                                                        // style={styles.inputSmall}
                                                                        // onPressEnter={onEnter}
                                                                        size="large"
                                                                        // disabled
                                                                    />
                                                                </FormItem>
                                                            </td>
                                                            <td style={{ textAlign: 'center' }}>
                                                                <FormItem name="challenger1_score">
                                                                    <InputNumber
                                                                        name="challenger1_score"
                                                                        placeholder="0"
                                                                        min={0}
                                                                        max={getChallengerRaceTo(currentMatch, 'challenger1')}
                                                                        defaultValue={0}
                                                                        // value={score}
                                                                        // onChange={onChange}
                                                                        onFocus={handleFocus}
                                                                        // onBlur={handleBlur}
                                                                        // style={styles.inputSmall}
                                                                        // onPressEnter={onEnter}
                                                                        style={{ backgroundColor: '#bfe0ff' }}
                                                                        size="large"
                                                                        disabled={
                                                                            challenger1Result === 'forfeit' || challenger2Result === 'forfeit' ? true : false
                                                                        }
                                                                    />
                                                                </FormItem>
                                                            </td>
                                                            {/* <td>
															<div style={{ display: 'flex', marginLeft: 5 }}>
																<Button type="ghost" size="large">
																	W
																</Button>
																<Button type="danger" ghost size="large">
																	F
																</Button>
															</div>
														</td> */}
                                                            <td>
                                                                <Dropdown
                                                                    overlay={actionsMenuTop}
                                                                    trigger={['click']}
                                                                    disabled={challenger2Result ? true : false}
                                                                >
                                                                    <Button size="large" style={{ marginLeft: 5 }}>
                                                                        {challenger1Result ? _.capitalize(challenger1Result) : 'Actions'} <Icon type="down" />
                                                                    </Button>
                                                                </Dropdown>
                                                            </td>
                                                        </tr>
                                                        {/* <tr>
														<td colSpan={4}>
															<FormItem name="challenger1_result">
																<Radio.Group name="challenger1_result">
																	<Radio value="winner">Winner</Radio>
																	<Radio value="forfeit">Forfeit</Radio>
																</Radio.Group>
															</FormItem>
														</td>
													</tr> */}
                                                        <tr>
                                                            <td style={{ width: '100%' }}>
                                                                <div
                                                                    style={{
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                        width: '100%',
                                                                    }}
                                                                >
                                                                    {currentMatch.challenger2_is_winner === true ||
                                                                    challenger2Result === 'winner' ||
                                                                    challenger1Result === 'forfeit' ||
                                                                    values.challenger2_score === getChallengerRaceTo(currentMatch, 'challenger2') ? (
                                                                        <span style={{ marginRight: 5 }}>
                                                                            <Avatar
                                                                                style={{
                                                                                    backgroundColor: '#87d068',
                                                                                }}
                                                                            >
                                                                                W
                                                                            </Avatar>
                                                                        </span>
                                                                    ) : (
                                                                        ((currentMatch.status === 'COMPLETED' &&
                                                                            currentMatch.challenger2_is_winner === false) ||
                                                                            challenger1Result === 'winner' ||
                                                                            challenger2Result === 'forfeit' ||
                                                                            values.challenger1_score === getChallengerRaceTo(currentMatch, 'challenger1')) && (
                                                                            <span style={{ marginRight: 5 }}>
                                                                                <Avatar
                                                                                    style={{
                                                                                        backgroundColor: '#f5222d',
                                                                                    }}
                                                                                >
                                                                                    {currentMatch.challenger2_is_forfeit || challenger2Result === 'forfeit'
                                                                                        ? 'FF'
                                                                                        : 'L'}
                                                                                </Avatar>
                                                                            </span>
                                                                        )
                                                                    )}
                                                                    <FormItem
                                                                        name="challenger2_name"
                                                                        // required
                                                                        style={{ width: '100%' }}
                                                                    >
                                                                        <AutoComplete
                                                                            dataSource={players2}
                                                                            style={{ width: '100%' }}
                                                                            onSelect={onSelectPlayer2}
                                                                            onSearch={onSearch2}
                                                                            name="challenger2_name"
                                                                            placeholder="Player 2 Name"
                                                                            size="large"
                                                                            // required
                                                                            optionFilterProp="children"
                                                                            filterOption={(inputValue, option) => {
                                                                                return (
                                                                                    option.props.children &&
                                                                                    option.props.children
                                                                                        .toString()
                                                                                        .toLowerCase()
                                                                                        .indexOf(inputValue.toLowerCase()) >= 0
                                                                                );
                                                                            }}
                                                                        >
                                                                            {players2 &&
                                                                                players2.length > 0 &&
                                                                                players2.map((item, index) => (
                                                                                    <Option key={index} value={item.id.toString()}>
                                                                                        {item.name}
                                                                                    </Option>
                                                                                ))}
                                                                        </AutoComplete>
                                                                    </FormItem>
                                                                </div>
                                                            </td>
                                                            <td
                                                                style={{
                                                                    textAlign: 'center',
                                                                }}
                                                            >
                                                                <FormItem name="challenger2_race_to">
                                                                    <InputNumber
                                                                        name="challenger2_race_to"
                                                                        placeholder="0"
                                                                        min={0}
                                                                        // max={getChallengerRaceTo(
                                                                        // 	currentMatch,
                                                                        // 	'challenger2'
                                                                        // )}
                                                                        // value={score}
                                                                        // onChange={onChange}
                                                                        onFocus={handleFocus}
                                                                        // onBlur={handleBlur}
                                                                        // style={styles.inputSmall}
                                                                        // onPressEnter={onEnter}

                                                                        size="large"
                                                                        // disabled
                                                                    />
                                                                </FormItem>
                                                            </td>
                                                            <td style={{ textAlign: 'center' }}>
                                                                <FormItem name="challenger2_score">
                                                                    <InputNumber
                                                                        name="challenger2_score"
                                                                        placeholder="0"
                                                                        min={0}
                                                                        defaultValue={0}
                                                                        max={getChallengerRaceTo(currentMatch, 'challenger2')}
                                                                        // value={score}
                                                                        // onChange={onChange}
                                                                        onFocus={handleFocus}
                                                                        // onBlur={handleBlur}
                                                                        // style={styles.inputSmall}
                                                                        // onPressEnter={onEnter}
                                                                        style={{ backgroundColor: '#bfe0ff' }}
                                                                        size="large"
                                                                        disabled={
                                                                            (challenger1Result === 'forfeit') | (challenger2Result === 'forfeit') ? true : false
                                                                        }
                                                                    />
                                                                </FormItem>
                                                            </td>
                                                            {/* <td>
															<div style={{ display: 'flex', marginLeft: 5 }}>
																<Button type="ghost" size="large">
																	W
																</Button>
																<Button type="danger" ghost size="large">
																	F
																</Button>
															</div>
														</td> */}
                                                            <td>
                                                                <Dropdown
                                                                    overlay={actionsMenuBottom}
                                                                    trigger={['click']}
                                                                    disabled={challenger1Result ? true : false}
                                                                >
                                                                    <Button size="large" style={{ marginLeft: 5 }}>
                                                                        {challenger2Result ? _.capitalize(challenger2Result) : 'Actions'} <Icon type="down" />
                                                                    </Button>
                                                                </Dropdown>
                                                            </td>
                                                        </tr>
                                                        {/* <tr>
														<td colSpan={4}>
															<FormItem name="challenger2_result">
																<Radio.Group name="challenger2_result">
																	<Radio value="winner">Winner</Radio>
																	<Radio value="forfeit">Forfeit</Radio>
																</Radio.Group>
															</FormItem>
														</td>
													</tr> */}
                                                    </tbody>
                                                </table>
                                                <br />
                                                <Text style={{ fontWeight: 700 }}>Assign Table</Text>
                                                <FormItem name="table_id">
                                                    <Query
                                                        query={GET_TOURNAMENT_TABLES_QUERY}
                                                        fetchPolicy="no-cache"
                                                        // notifyOnNetworkStatusChange={true}
                                                        variables={{ tournament_id: tournament.id }}
                                                        onCompleted={(data) => {
                                                            // console.log(data);
                                                            setTables(data.pool_tables);
                                                        }}
                                                    >
                                                        {({ loading, error, data }) => {
                                                            if (loading) return <div>Loading...</div>;
                                                            if (error) return <div>Error: {error.message}</div>;
                                                            if ((data && !data.pool_tables) || (data && data.pool_tables.length === 0)) {
                                                                return <div>No tables found</div>;
                                                            }

                                                            return (
                                                                <Select
                                                                    name="table_id"
                                                                    defaultValue={currentMatch && currentMatch.table_id}
                                                                    // style={{ minWidth: 300 }}
                                                                    onChange={handleSelectTableChange}
                                                                    placeholder="Select table assignment"
                                                                    size="large"
                                                                >
                                                                    {/* {currentMatch &&
														currentMatch.table_id && (
															<Option
																value={null}
																style={{
																	borderBottom: '1px solid #e8e8e8',
																	padding: '8px 12px'
																}}
															>
																Remove table assignment
															</Option>
														)} */}

                                                                    <OptGroup label="TABLES">
                                                                        {/* <Option value={1}>Table 1 (Streaming Table)</Option> */}

                                                                        {data.pool_tables
                                                                            .filter((item, index) => {
                                                                                return item.status !== 'CLOSED';
                                                                            })
                                                                            .sort((a, b) => {
                                                                                return a.label.localeCompare(b.label, undefined, {
                                                                                    numeric: true,
                                                                                    sensitivity: 'base',
                                                                                });
                                                                            })
                                                                            .sort((a, b) => {
                                                                                return (b.status === 'OPEN') - (a.status === 'OPEN');
                                                                            })
                                                                            .map((item, index) => (
                                                                                <Option
                                                                                    key={index + 1}
                                                                                    value={item.id}
                                                                                    disabled={item.status === 'IN_USE' || item.status === 'ASSIGNED'}
                                                                                >
                                                                                    {item.label} - {item.status}{' '}
                                                                                    {item.is_streaming_table ? '(Streaming Table)' : ''}
                                                                                    {item.is_featured_table ? '(Featured Table)' : ''}
                                                                                </Option>
                                                                            ))}
                                                                    </OptGroup>
                                                                </Select>
                                                            );
                                                        }}
                                                    </Query>
                                                    {currentMatch.table_id !== null && (
                                                        <Popconfirm
                                                            title="Are you sure you want to remove this table assignment?"
                                                            onConfirm={() => {
                                                                confirmRemoveTableAssignment(updateTournamentBracket);
                                                            }}
                                                            onCancel={cancelRemoveTableAssignment}
                                                            visible={confirmTablePopupVisible}
                                                            onVisibleChange={handleRemoveTableVisibleChange}
                                                            okText="Yes"
                                                            cancelText="No"
                                                            placement="topLeft"
                                                        >
                                                            <Button type="link" className="remove-table-assignment-btn">
                                                                <Icon type="close-circle" /> Remove table assignment
                                                            </Button>
                                                        </Popconfirm>
                                                    )}
                                                </FormItem>
                                                <div>
                                                    {/* <SubmitButton type="primary" size="large">
														{selectedTable ? 'Update Match' : 'Save Match'}
													</SubmitButton> */}

                                                    <Button
                                                        type="primary"
                                                        disabled={Object.keys(errors).length > 0 ? true : false}
                                                        loading={loading}
                                                        className="update-match-btn"
                                                        size="large"
                                                        onClick={() => {
                                                            if (currentMatch.status && currentMatch.status === 'COMPLETED') {
                                                                setConfirmPopupVisible(true);
                                                            } else {
                                                                formRef.current.handleSubmit();
                                                            }
                                                        }}
                                                    >
                                                        {selectedTable ? 'Update Match' : loading ? 'Saving...' : 'Save Match'}
                                                    </Button>

                                                    <Button type="default" size="large" onClick={handleScoreModalCancel}>
                                                        Cancel
                                                    </Button>

                                                    {/* {Object.keys(errors).length > 0 && (
													<div>
														<Text style={styles.error}>
															Validation errors: ({Object.keys(errors).length})
														</Text>
														<ul style={{ margin: 0 }}>
															{Object.entries(errors).map(([ key, value ]) => {
																return (
																	<li key={key} style={styles.error}>
																		<Text style={styles.error}>{value}</Text>
																	</li>
																);
															})}
														</ul>
													</div>
												)} */}
                                                    {/* <FormikDebug style={{ maxWidth: 400 }} /> */}
                                                </div>
                                                <Popconfirm
                                                    title="Are you sure you want to edit this completed match?"
                                                    onConfirm={() => {
                                                        confirmEditCompletedMatch(formRef);
                                                    }}
                                                    onCancel={cancelEditCompletedMatch}
                                                    visible={confirmPopupVisible}
                                                    onVisibleChange={handleVisibleChange}
                                                    okText="Yes"
                                                    cancelText="No"
                                                    placement="topLeft"
                                                />
                                            </Form>
                                        )}
                                    />
                                </Modal>

                                <Modal
                                    title="Confirm Tournament Winner"
                                    visible={confirmEndTournamentPopupVisible}
                                    onOk={() => {
                                        completeTournament(winner);
                                    }}
                                    onCancel={cancelEndTournament}
                                    okText="Yes"
                                    cancelText="No"
                                    centered
                                    transitionName="fade"
                                    maskTransitionName="none"
                                    destroyOnClose={true}
                                >
                                    <p>Did {winner && (winner.challenger1_name || winner.challenger2_name)} win the tournament?</p>
                                </Modal>
                            </React.Fragment>
                        );
                    }}
                </Mutation>
            )}
        </Mutation>
    );
}

export default MatchOverlay;
