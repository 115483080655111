import gql from 'graphql-tag';

export const GET_LEAGUE_MATCH_BY_SLUG_QUERY = gql`
    query matches($league_id: Int, $division_id: Int, $match_slug: String) {
        matches(where: { league_id: { _eq: $league_id }, division_id: { _eq: $division_id }, slug: { _eq: $match_slug } }) {
            id
            name
            slug
            description
            match_number
            match_type
            game_type
            rule_format
            handicap_format
            scoring_format
            race_to
            player_id
            player_name
            player_score
            player_points
            player_tiebreaker_points
            player_race_to
            player_country
            player_state
            player_fargo
            player_skill_level
            player_is_winner
            opponent_id
            opponent_name
            opponent_score
            opponent_points
            opponent_tiebreaker_points
            opponent_race_to
            opponent_country
            opponent_state
            opponent_fargo
            opponent_skill_level
            opponent_is_winner
            winner_id
            winner_score
            loser_id
            loser_score
            status
            progress
            duration
            livestream_url
            score_verified
            scheduled_time
            start_date_time
            end_date_time
            is_public
            event_id
            venue_id
            match_games(order_by: { created_at: desc }) {
                id
                match_id
                match_number
                game_number
                player_id
                player_score
                player_points
                player_is_winner
                player_break_run
                player_runout
                player_tpa
                player_unforced_errors
                player_balls_pocketed
                opponent_id
                opponent_score
                opponent_points
                opponent_is_winner
                opponent_break_run
                opponent_runout
                opponent_tpa
                opponent_unforced_errors
                opponent_balls_pocketed
                status
                progress
                duration
                table_id
                start_date_time
                end_date_time
                created_at
                updated_at
            }
            match_players {
                id
                name
                country
                player_id
                skill_level
                race_to
            }
            division_id
            division {
                id
                name
            }
            venue_id
            venue {
                id
                name
                slug
                address1
                address2
                city
                region
                country
                postal_code
            }
            status
            progress
            duration
            scheduled_time
            start_time
            end_time
            created_at
            updated_at
        }
    }
`;

export const GET_LEAGUE_MATCH_QUERY = gql`
    query matches($league_id: Int, $division_id: Int, $match_id: Int) {
        matches(where: { league_id: { _eq: $league_id }, division_id: { _eq: $division_id }, id: { _eq: $match_id } }) {
            id
            name
            slug
            description
            match_number
            match_type
            game_type
            rule_format
            handicap_format
            scoring_format
            race_to
            player_id
            player_name
            player_score
            player_points
            player_tiebreaker_points
            player_race_to
            player_country
            player_state
            player_fargo
            player_skill_level
            player_is_winner
            opponent_id
            opponent_name
            opponent_score
            opponent_points
            opponent_tiebreaker_points
            opponent_race_to
            opponent_country
            opponent_state
            opponent_fargo
            opponent_skill_level
            opponent_is_winner
            winner_id
            winner_score
            loser_id
            loser_score
            status
            progress
            duration
            livestream_url
            score_verified
            scheduled_time
            start_date_time
            end_date_time
            is_public
            event_id
            venue_id
            match_games(order_by: { created_at: desc }) {
                id
                match_id
                match_number
                game_number
                player_id
                player_score
                player_points
                player_is_winner
                player_break_run
                player_runout
                player_tpa
                player_unforced_errors
                player_balls_pocketed
                opponent_id
                opponent_score
                opponent_points
                opponent_is_winner
                opponent_break_run
                opponent_runout
                opponent_tpa
                opponent_unforced_errors
                opponent_balls_pocketed
                status
                progress
                duration
                table_id
                start_date_time
                end_date_time
                created_at
                updated_at
            }
            match_players {
                id
                name
                country
                player_id
                skill_level
                race_to
            }
            division_id
            division {
                id
                name
            }
            venue_id
            venue {
                id
                name
                slug
                address1
                address2
                city
                region
                country
                postal_code
            }
            status
            progress
            duration
            scheduled_time
            start_time
            end_time
            created_at
            updated_at
        }
    }
`;

export const GET_LEAGUE_MATCH_GAMES = gql`
    query match_games($league_id: Int, $division_id: Int, $match_id: Int) {
        match_games(
            where: { league_id: { _eq: $league_id }, division_id: { _eq: $division_id }, league_match_id: { _eq: $match_id } }
            order_by: { created_at: desc }
        ) {
            id
            match_id
            match_number
            game_number
            player_id
            player_score
            player_points
            player_is_winner
            player_break_run
            player_runout
            player_tpa
            player_unforced_errors
            player_balls_pocketed
            opponent_id
            opponent_score
            opponent_points
            opponent_is_winner
            opponent_break_run
            opponent_runout
            opponent_tpa
            opponent_unforced_errors
            opponent_balls_pocketed
            status
            progress
            duration
            table_id
            start_date_time
            end_date_time
            created_at
            updated_at
        }
    }
`;

export const GET_LEAGUE_MATCH_GAME = gql`
    query match_games($match_id: Int) {
        match_games(where: { match_id: { _eq: $match_id } }, order_by: { created_at: desc }) {
            id
            match_id
            match_number
            game_number
            player_id
            player_score
            player_points
            player_is_winner
            player_break_run
            player_runout
            player_tpa
            player_unforced_errors
            player_balls_pocketed
            opponent_id
            opponent_score
            opponent_points
            opponent_is_winner
            opponent_break_run
            opponent_runout
            opponent_tpa
            opponent_unforced_errors
            opponent_balls_pocketed
            status
            progress
            duration
            table_id
            start_date_time
            end_date_time
            created_at
            updated_at
        }
    }
`;

export const GET_LEAGUE_MATCH_GAME_POINTS = gql`
    query match_games($match_id: Int) {
        match_games(where: { match_id: { _eq: $match_id } }, order_by: { created_at: desc }) {
            id
            player_points
            opponent_points
            created_at
            updated_at
        }
    }
`;

export const GET_LEAGUE_PLACES = gql`
    query league_players($league_id: Int!, $division_id: Int!) {
        league_players(where: { league_id: { _eq: $league_id } }, order_by: { name: asc }) {
            id
            name
            country
            skill_level
            place
            points
            league_player_divisions(where: { division_id: { _eq: $division_id } }) {
                id
            }
            player_matches_won: league_matches_player_aggregate(
                where: { player_is_winner: { _eq: true }, status: { _eq: COMPLETED } }
                order_by: { match_number: asc }
            ) {
                aggregate {
                    count
                }
            }
            player_matches_lost: league_matches_player_aggregate(
                where: { player_is_winner: { _eq: false }, status: { _eq: COMPLETED } }
                order_by: { match_number: asc }
            ) {
                aggregate {
                    count
                }
            }
            opponent_matches_won: league_matches_opponent_aggregate(
                where: { opponent_is_winner: { _eq: true }, status: { _eq: COMPLETED } }
                order_by: { match_number: asc }
            ) {
                aggregate {
                    count
                }
            }
            opponent_matches_lost: league_matches_opponent_aggregate(
                where: { opponent_is_winner: { _eq: false }, status: { _eq: COMPLETED } }
                order_by: { match_number: asc }
            ) {
                aggregate {
                    count
                }
            }
            player_matches_games: league_matches_player_aggregate(where: { status: { _eq: COMPLETED } }, order_by: { match_number: asc }) {
                aggregate {
                    count
                    sum {
                        player_score
                        opponent_score
                        player_points
                        opponent_points
                        player_balls_pocketed
                        opponent_balls_pocketed
                        player_errors
                        opponent_errors
                        player_high_break
                        opponent_high_break
                    }
                }
                nodes {
                    start_time
                    end_time
                }
            }
            opponent_matches_games: league_matches_opponent_aggregate(where: { status: { _eq: COMPLETED } }, order_by: { match_number: asc }) {
                aggregate {
                    count
                    sum {
                        player_score
                        opponent_score
                        player_points
                        opponent_points
                        player_balls_pocketed
                        opponent_balls_pocketed
                        player_errors
                        opponent_errors
                        player_high_break
                        opponent_high_break
                    }
                }
                nodes {
                    start_time
                    end_time
                }
            }
            player_matches_games: league_matches_player_aggregate(where: { status: { _eq: COMPLETED } }, order_by: { match_number: asc }) {
                aggregate {
                    count
                    sum {
                        player_games
                        opponent_games
                    }
                }
                nodes {
                    start_time
                    end_time
                }
            }
            opponent_matches_games: league_matches_opponent_aggregate(where: { status: { _eq: COMPLETED } }, order_by: { match_number: asc }) {
                aggregate {
                    count
                    sum {
                        player_games
                        opponent_games
                    }
                }
                nodes {
                    start_time
                    end_time
                }
            }
            player_matches_points: league_matches_player_aggregate(where: { status: { _eq: COMPLETED } }, order_by: { match_number: asc }) {
                aggregate {
                    count
                    sum {
                        player_points
                        opponent_points
                    }
                }
                nodes {
                    start_time
                    end_time
                }
            }
            opponent_matches_points: league_matches_opponent_aggregate(where: { status: { _eq: COMPLETED } }, order_by: { match_number: asc }) {
                aggregate {
                    count
                    sum {
                        player_points
                        opponent_points
                    }
                }
                nodes {
                    start_time
                    end_time
                }
            }
        }
    }
`;
