import React, { useState } from 'react';
import { message, Icon, Row, Col, Typography, Button, Input, Tabs, Empty } from 'antd';
import Card from '../../components/Card';
import { Link } from 'react-router-dom';
import CircularLoader from '../../components/CircularLoader';
import SectionHeader from '../../components/SectionHeader';
import SectionContent from '../../components/SectionContent';
import SectionWrapper from '../../components/SectionWrapper';
import SectionFooter from '../../components/SectionFooter';
import ZeroState from '../../components/ZeroState';
import AddTeamModal from './AddTeamModal';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { GET_LEAGUE_TEAMS_QUERY, GET_LEAGUE_QUERY } from './data/queries';
import { Query, Mutation } from '@apollo/client/react/components';
import TeamTable from './TeamTable';
import { DELETE_LEAGUE_TEAM_MUTATION } from './data/mutations';

const { Text, Title } = Typography;

const styles = {
    row: {
        display: 'flex',
        flexGrow: 1,
    },
    tab: {
        paddingTop: 5,
        paddingLeft: 24,
        paddingRight: 24,
        paddingBottom: 24,
    },
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        padding: 20,
    },
};

export default function LMTeams(props) {
    const { league, authState } = props;
    const [selectedTeamId, setSelectedTeamId] = useState();
    const [teamModalVisible, setTeamModalVisible] = useState(false);
    const [selectedKeys, setSelectedKeys] = useState([]);
    const [selected, setSelected] = useState();
    const [deleteLeagueTeam, { loading, error }] = useMutation(DELETE_LEAGUE_TEAM_MUTATION);

    function handleAddTeam() {
        setTeamModalVisible(true);
        setSelectedTeamId(null);
    }

    function handleTeamModalOk(e) {
        setTeamModalVisible(false);
    }

    function handleTeamModalCancel(e) {
        setTeamModalVisible(false);
    }

    function handleSelected(selected) {
        // console.log(selected);
        setSelectedKeys(selected);
    }

    function handleEditSelected(selected) {
        // console.log(selected);
        setSelectedTeamId(selected);
        setTeamModalVisible(true);
    }

    return (
        <SectionWrapper>
            <SectionHeader
                title={props.title}
                titleStyle={{ color: '#fff', margin: 0, textTransform: 'uppercase' }}
                avatar={league.avatar}
                subtitle={league.name}
                subtitlePrefix={true}
                subtitleStyle={{
                    color: 'rgba(255,255,255,0.8)',
                    textTransform: 'uppercase',
                    fontWeight: 600,
                    fontSize: 12,
                    letterSpacing: 3,
                }}
                theme="leagueManager"
            >
                <Link to={`/leagues/${props.match.params.slug}`}>
                    <Button type="ghost" ghost>
                        Public Profile
                    </Button>
                </Link>
            </SectionHeader>

            <SectionContent padding="20px 40px">
                <Row gutter={16} justify="center" type="flex">
                    <Col xs={24}>
                        <Card
                            title={`All Teams (${league.league_teams_aggregate.aggregate.count})`}
                            extra={
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <Button type="primary" onClick={handleAddTeam}>
                                        <Icon type="user-add" /> Add Team
                                    </Button>
                                </div>
                            }
                            bodyStyle={{ padding: 0 }}
                        >
                            <Query
                                query={GET_LEAGUE_TEAMS_QUERY}
                                fetchPolicy="cache-and-network"
                                notifyOnNetworkStatusChange
                                awaitRefetchQueries
                                variables={{ league_id: league.id }}
                                onCompleted={(data) => {
                                    // setPlayers(data.league_players);
                                    console.log(data);
                                    console.log(league);
                                }}
                            >
                                {({ loading, error, data }) => {
                                    if (loading)
                                        return (
                                            <div style={styles.container}>
                                                <CircularLoader />
                                            </div>
                                        );
                                    if (error) return <div style={styles.container}>Error: {error.message}</div>;
                                    if ((data && !data.league_teams) || (data && data.league_teams.length === 0)) {
                                        return <ZeroState showImage message="No teams have been added yet." style={{ margin: 30 }} {...props} />;
                                    }

                                    return (
                                        <div className="table_nowrap">
                                            <TeamTable
                                                {...props}
                                                league={league}
                                                onDelete={(record) => {
                                                    deleteLeagueTeam({
                                                        variables: {
                                                            league_id: league.id,
                                                            id: record.id,
                                                        },
                                                        notifyOnNetworkStatusChange: true,
                                                        awaitRefetchQueries: true,
                                                        refetchQueries: [
                                                            {
                                                                query: GET_LEAGUE_QUERY,
                                                                variables: { id: league.id },
                                                            },
                                                            {
                                                                query: GET_LEAGUE_TEAMS_QUERY,
                                                                variables: { league_id: league.id },
                                                            },
                                                        ],
                                                    })
                                                        .then((data) => {
                                                            console.log(data);
                                                            message.success('Team deleted from league');
                                                        })
                                                        .catch((error) => {
                                                            console.log(error);
                                                            message.info('There was an error', error);
                                                        });
                                                }}
                                                onSelected={handleSelected}
                                                onEditSelected={handleEditSelected}
                                                teams={data.league_teams.map((item, index) => {
                                                    return {
                                                        ...item,
                                                        players: item.league_team_players
                                                            .map((item) => {
                                                                return item.league_player;
                                                            })
                                                            .join(', '),
                                                        index: index + 1,
                                                        key: index,
                                                    };
                                                })}
                                            />
                                        </div>
                                    );
                                }}
                            </Query>
                        </Card>
                    </Col>
                </Row>
            </SectionContent>
            <SectionFooter {...props} />

            <AddTeamModal
                selectedTeamId={selectedTeamId}
                userID={authState.user.id}
                league={league}
                leagueID={league.id}
                modalVisible={teamModalVisible}
                onModalOk={handleTeamModalOk}
                onModalCancel={handleTeamModalCancel}
                {...props}
            />
        </SectionWrapper>
    );
}
