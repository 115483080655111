import React, { useState, useEffect } from 'react';
import { Link, withRouter, Route, Switch } from 'react-router-dom';
import { Layout, Tabs, Icon, Button } from 'antd';
import SectionHeader from '../../components/SectionHeader';
import SectionContent from '../../components/SectionContent';
import SectionTitle from '../../components/SectionTitle';
import AllUsers from './admin/AllUsers';
import AllPlayers from './admin/AllPlayers';
import UserDetail from './admin/UserDetail';
import useBreakpoint from 'use-breakpoint';
import { defaultBreakpoints } from '../../utils/defaultBreakpoints';
import * as routes from '../../config/routes';
import withAuthorization from '../auth/withAuthorization';
import _ from 'lodash';

const { TabPane } = Tabs;

const styles = {
    headerImg: {
        background: `linear-gradient(-90deg, #002eaf, #008dd0) 100% 100% no-repeat`,
        // background: `linear-gradient(0deg, rgba(0,0,0,0.2), rgba(0,0,0, 0.2)), url(${blueBg})`,
        // backgroundAttachment: 'fixed',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100%',
        // backgroundPosition: '0px -400px'

        // backgroundPosition: '0px -40px'
    },
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        // height: '100%'
    },
};

function Admin(props) {
    const { history, authState } = props;
    const urlPath = window.location.pathname;
    const params = props.match.params;
    const splitPath = urlPath.split('/');
    const dpIncludes = ['users', 'players'];
    const [current, setCurrent] = useState('users');
    const { breakpoint, maxWidth, minWidth } = useBreakpoint(defaultBreakpoints);

    const handleTabClick = (tab) => {
        setCurrent(tab);
        props.history.push(`/admin/${tab}`);
    };

    useEffect(() => {
        let pathMatch = _.includes(dpIncludes, splitPath[splitPath.length - 1])
            ? splitPath[splitPath.length - 1]
            : _.includes(dpIncludes, splitPath[splitPath.length - 2])
            ? splitPath[splitPath.length - 2]
            : _.includes(dpIncludes, splitPath[splitPath.length - 3])
            ? splitPath[splitPath.length - 3]
            : 'users';

        setCurrent(pathMatch);
    }, [urlPath, dpIncludes, splitPath, params]);

    return (
        <div style={styles.headerImg}>
            {/* <SectionHeader title={props.title} subtitle={props.subtitle} /> */}
            <SectionTitle title={props.title} height={breakpoint === 'mobile' ? 80 : 100} />

            <Tabs
                defaultActiveKey={current}
                activeKey={current}
                animated={{ inkBar: true, tabPane: false }}
                // renderTabBar={() => <ScrollableInkTabBar />}
                // renderTabContent={() => <TabContent />}
                onTabClick={handleTabClick}
                className="tabs-bg"
                tabPosition="top"
                tabBarStyle={{
                    textTransform: 'uppercase',
                    paddingLeft: 24,
                    paddingRight: 24,
                    paddingTop: 0,
                    // backgroundColor: '#132339',
                    boxShadow: 'rgba(0, 0, 0, 0.2) 0px 1px 15px 0px',
                    color: '#fff',
                    zIndex: 1,
                    position: 'relative',
                    borderBottom: 'none',
                    borderTop: '1px solid rgba(255,255,255,0.1)',
                }}
            >
                <TabPane tab="Users" key="users" style={styles.tab}>
                    <Switch>
                        <Route path={routes.DIGITALPOOL.ADMIN} render={(matchProps) => <AllUsers {...matchProps} {...props} />} />
                        <Route path={routes.DIGITALPOOL.ADMIN_USERS} render={(matchProps) => <AllUsers {...matchProps} {...props} />} />
                        <Route path={routes.DIGITALPOOL.ADMIN_USER_DETAIL} render={(matchProps) => <UserDetail {...matchProps} {...props} />} />
                    </Switch>
                </TabPane>
                <TabPane tab="Players" key="players" style={styles.tab}>
                    <Switch>
                        <Route path={routes.DIGITALPOOL.ADMIN_PLAYERS} render={(matchProps) => <AllPlayers {...matchProps} {...props} />} />
                        <Route path={routes.DIGITALPOOL.ADMIN_PLAYER_DETAIL} render={(matchProps) => <UserDetail {...matchProps} {...props} />} />
                    </Switch>
                </TabPane>
            </Tabs>
        </div>
    );
}

const authCondition = (authUser) => {
    const isUser = !!authUser;
    const isAdmin = authUser && authUser.role === 'admin';
    console.log('is user', isUser);
    console.log('is admin', isAdmin);
    return isUser && isAdmin;
};
const WithAuthorization = withAuthorization(authCondition)(Admin);
export default withRouter(WithAuthorization);
