import React from 'react';
import { Avatar, Typography } from 'antd';
import CircularLoader from '../../../components/CircularLoader';
import { GET_TOURNAMENT_BY_SLUG } from './data/queries';
import { GET_TOURNAMENT_STREAM_OVERLAY_QUERY } from './data/queries';
import { GET_TOURNAMENT_STREAM_OVERLAY_SUBSCRIPTION } from './data/subscriptions';
import { Query, Subscription } from '@apollo/client/react/components';
import qs from 'query-string';
import DefaultStreamOverlayTemplate from './templates/DefaultStreamOverlayTemplate';
import APFStreamOverlayTemplate from './templates/APFStreamOverlayTemplate';

export default function DPTournamentStreamOverlay(props) {
    const { theme } = props;
    const params = qs.parse(props.location.search, { ignoreQueryPrefix: true });
    const showScores = params && params.show_scores && params.show_scores !== 'false';
    const tableId = params && params.table_id;
    const template = params && params.template;
    const chromaColor = params && params.chroma_color;

    return (
        <Query
            query={GET_TOURNAMENT_BY_SLUG}
            fetchPolicy="cache-and-network"
            notifyOnNetworkStatusChange={true}
            variables={{ slug: props.computedMatch.params.slug }}
            onCompleted={(data) => {
                // console.log(data)
            }}
        >
            {({ loading, error, data, refetch }) => {
                if (loading) return <CircularLoader />;
                if (error) return <div>Error: {error.message}</div>;

                const tournamentData = data.tournaments[0];
                console.log(tournamentData);

                return (
                    <Query
                        query={GET_TOURNAMENT_STREAM_OVERLAY_QUERY}
                        fetchPolicy="cache-and-network"
                        notifyOnNetworkStatusChange={true}
                        variables={{ tournament_id: tournamentData.id }}
                        onCompleted={(data, error, loading) => {
                            console.log(data);
                        }}
                    >
                        {({ loading, error, data, refetch, fetchMore, subscribeToMore, networkStatus }) => {
                            if (loading) return <CircularLoader />;
                            if (error) return <div>Error: {error.message}</div>;

                            let selectedTable;
                            const tables = data.pool_tables;

                            if (tableId) {
                                selectedTable = tables.filter((table) => {
                                    return table.id === parseInt(tableId);
                                })[0];
                            } else {
                                selectedTable = tables[0];
                            }

                            const matchData = selectedTable && selectedTable.tournament_match_table && selectedTable.tournament_match_table[0];
                            console.log('table data:', tables);
                            console.log('match data:', matchData);

                            if (template && template === 'apf') {
                                return (
                                    <APFStreamOverlayTemplate
                                        matchData={matchData}
                                        tournamentData={tournamentData}
                                        showScores={showScores}
                                        chromaColor={chromaColor}
                                        subscribeToMore={subscribeToMore}
                                        subscribeToNewData={() => {
                                            subscribeToMore({
                                                document: GET_TOURNAMENT_STREAM_OVERLAY_SUBSCRIPTION,
                                                variables: { tournament_id: tournamentData.id },
                                                updateQuery: (prev, { subscriptionData }) => {
                                                    if (!subscriptionData.data) return prev;
                                                    const tables = subscriptionData.data.pool_tables;
                                                    let updated;
                                                    if (prev) {
                                                        updated = Object.assign({}, prev, {
                                                            pool_tables: [...tables],
                                                        });
                                                    } else {
                                                        updated = Object.assign({}, prev, {
                                                            pool_tables: [...tables],
                                                        });
                                                    }
                                                    return updated;
                                                },
                                            });
                                        }}
                                        {...props}
                                    />
                                );
                            } else {
                                return (
                                    <DefaultStreamOverlayTemplate
                                        matchData={matchData}
                                        tournamentData={tournamentData}
                                        showScores={showScores}
                                        subscribeToMore={subscribeToMore}
                                        subscribeToNewData={() => {
                                            subscribeToMore({
                                                document: GET_TOURNAMENT_STREAM_OVERLAY_SUBSCRIPTION,
                                                variables: { tournament_id: tournamentData.id },
                                                updateQuery: (prev, { subscriptionData }) => {
                                                    if (!subscriptionData.data) return prev;
                                                    const tables = subscriptionData.data.pool_tables;
                                                    let updated;
                                                    if (prev) {
                                                        updated = Object.assign({}, prev, {
                                                            pool_tables: [...tables],
                                                        });
                                                    } else {
                                                        updated = Object.assign({}, prev, {
                                                            pool_tables: [...tables],
                                                        });
                                                    }
                                                    return updated;
                                                },
                                            });
                                        }}
                                        {...props}
                                    />
                                );
                            }
                        }}
                    </Query>
                );
            }}
        </Query>
    );
}
