import React, { useState, useEffect } from 'react';
import { Row, Col } from 'antd';
import SectionContent from '../../../components/SectionContent';
import SectionWrapper from '../../../components/SectionWrapper';
import Card, { Meta } from '../../../components/Card';
import { displayEnumType } from '../../../utils';
import useBreakpoint from 'use-breakpoint';
import CircularLoader from '../../../components/CircularLoader';
import MultiLineChart from './MultiLineChart';
import StatsTable from './StatsTable';
import { matchStats as statsConfig } from '../../../utils';
import { Query, Subscription } from '@apollo/client/react/components';
import { GET_MATCH_STATS_QUERY } from './data/queries';
import { GET_MATCH_STATS_SUBSCRIPTION } from './data/subscriptions';
import { defaultBreakpoints } from '../../../utils/defaultBreakpoints';
import _ from 'lodash';
import { Tabs } from 'antd';

const { TabPane } = Tabs;

const styles = {};

export default function MatchStats(props) {
    const { matchData, matchId } = props;
    const { breakpoint, maxWidth, minWidth } = useBreakpoint(defaultBreakpoints);

    return (
        <SectionWrapper>
            <SectionContent padding="20px 40px">
                <Subscription
                    subscription={GET_MATCH_STATS_SUBSCRIPTION}
                    fetchPolicy="cache-and-network"
                    notifyOnNetworkStatusChange={true}
                    variables={{ match_id: matchData.id }}
                    onSubscriptionData={(data) => {
                        console.log(data);
                    }}
                >
                    {({ loading, error, data }) => {
                        if (loading) return <CircularLoader />;
                        if (error) return <div style={styles.container}>Error: {error.message}</div>;
                        if (data && !data.match_stats) {
                            return <div>Match stats not found.</div>;
                        }
                        if (data && data.match_stats.length === 0) {
                            return <div>Match stats not found.</div>;
                        }

                        console.log(data);
                        const matchStats = data.match_stats;

                        const playerStats = matchStats.filter((stat) => {
                            return stat.metric.indexOf('player_') !== -1;
                        });

                        const opponentStats = matchStats.filter((stat) => {
                            return stat.metric.indexOf('opponent_') !== -1;
                        });

                        // const uniquePlayerStats = _.uniqBy(playerStats, 'metric');
                        // const uniqueOpponentStats = _.uniqBy(opponentStats, 'metric');

                        const stats = ['Score', ...statsConfig];

                        //const stats = [
                        //	'Score',
                        //	'Points',
                        //	'Balls pocketed',
                        //	'Balls made on the break',
                        //	'Balls missed',
                        //	'Unforced errors',
                        //	'Fouls',
                        //	'Illegal breaks',
                        //	'Dry breaks',
                        //	'Runouts',
                        //	'Break and runs',
                        //	'Golden breaks',
                        //	'Racks won on break',
                        //	'Racks won against break'
                        //];

                        // const stats = [
                        // 	'Score',
                        // 	'Points',
                        // 	'Balls made',
                        // 	'Errors',
                        // 	'High run',
                        // 	'Balls made on the break',
                        // 	'Balls missed',
                        // 	'Fouls',
                        // 	'Illegal breaks',
                        // 	'Dry breaks',
                        // 	'Runouts',
                        // 	'Break and runs',
                        // 	'Golden breaks',
                        // 	'Racks won on break',
                        // 	'Racks won against break',
                        // 	'Bank shots made',
                        // 	'Bank shots missed',
                        // 	'Kick shots made',
                        // 	'Kick shots missed',
                        // 	'Cut shots made',
                        // 	'Cut shots missed',
                        // 	'Straight shots made',
                        // 	'Straight shots missed',
                        // 	'Carom shots made',
                        // 	'Carom shots missed',
                        // 	'Combo shots made',
                        // 	'Combo shots missed',
                        // 	'Safety shots made',
                        // 	'Safety shots missed',
                        // 	'Fluked shots',
                        // 	'Kick safes made',
                        // 	'Kick safes missed',
                        // 	'Successful breaks',
                        // 	'Unsuccessful breaks'
                        // ];

                        // const playerStatsFormatted = stats.map((stat) => {
                        //	const statUnderscored = stat.split(' ').join('_').toLowerCase();
                        //	return `player_${statUnderscored}`;
                        // });

                        // const opponentStatsFormatted = stats.map((stat) => {
                        //	const statUnderscored = stat.split(' ').join('_').toLowerCase();
                        //	return `opponent_${statUnderscored}`;
                        // });

                        const combinedStats = [
                            {
                                name: matchData.player_name,
                                stat_prefix: 'player',
                                series: playerStats,
                            },
                            {
                                name: matchData.opponent_name,
                                stat_prefix: 'opponent',
                                series: opponentStats,
                            },
                        ];

                        {
                            /* const playerStatsGrouped = _.chain(playerStats)
                            // Group the elements of Array based on `color` property
                            .groupBy('game_number')
                            // `key` is group's name (color), `value` is the array of objects
                            .map((value, key) => value)
                            .value();
                        const playerStatsFormatted = playerStatsGrouped.map((game, index) => {
                            const stats = _.compact(
                                statsConfig.map((stat) => {
                                    const statUnderscored = stat.split(' ').join('_').toLowerCase();
                                    const statFormatted = `player_${statUnderscored}`;
                                    const filtered = game
                                        .filter((game, index) => {
                                            return game.metric === statFormatted;
                                        })
                                        .map((item) => {
                                            return item.metric;
                                        });

                                    var result = _.countBy(filtered, 'length');
                                    const values = Object.values(result)[0];

                                    if (values) {
                                        return {
                                            game: index,
                                            metric: statUnderscored,
                                            value: values,
                                        };
                                    }
                                })
                            );
                            console.log('player stats', stats);
                            return stats;
                        });

                        const opponentStatsGrouped = _.chain(opponentStats)
                            // Group the elements of Array based on `color` property
                            .groupBy('game_number')
                            // `key` is group's name (color), `value` is the array of objects
                            .map((value, key) => value)
                            .value();
                        const opponentStatsFormatted = opponentStatsGrouped.map((game, index) => {
                            const stats = _.compact(
                                statsConfig.map((stat) => {
                                    const statUnderscored = stat.split(' ').join('_').toLowerCase();
                                    const statFormatted = `opponent_${statUnderscored}`;
                                    const filtered = game
                                        .filter((game, index) => {
                                            return game.metric === statFormatted;
                                        })
                                        .map((item) => {
                                            return item.metric;
                                        });

                                    var result = _.countBy(filtered, 'length');
                                    const values = Object.values(result)[0];

                                    if (values) {
                                        return {
                                            game: index,
                                            metric: statUnderscored,
                                            value: values,
                                        };
                                    }
                                })
                            );
                            console.log('opponent stats', stats);
                            return stats;
                        });

                        const combinedStatsGrouped = [
                            {
                                name: matchData.player_name,
                                stat_prefix: 'player',
                                series: playerStatsFormatted,
                            },
                            {
                                name: matchData.opponent_name,
                                stat_prefix: 'opponent',
                                series: opponentStatsFormatted,
                            },
                        ];

                        console.log(combinedStatsGrouped); */
                        }

                        return (
                            <Row gutter={24} justify="center" type="flex">
                                <Col xs={24} style={{ paddingTop: 0, paddingBottom: 0 }}>
                                    <Card title="Match stats" bodyStyle={{ padding: 0 }}>
                                        <div
                                            style={{
                                                padding: breakpoint === 'mobile' ? '20px 0px' : '20px 0px',
                                            }}
                                        >
                                            <Row
                                                gutter={24}
                                                justify="center"
                                                type="flex"
                                                style={{
                                                    paddingLeft: breakpoint === 'mobile' ? 10 : 40,
                                                    paddingRight: breakpoint === 'mobile' ? 10 : 40,
                                                }}
                                            >
                                                <Col xs={24} align="center">
                                                    <div style={{ maxWidth: 800 }}>
                                                        <StatsTable matchData={matchData} {...props} />
                                                    </div>
                                                </Col>

                                                <Col xs={24} align="center">
                                                    {stats.map((stat, index) => {
                                                        return (
                                                            <div style={{ marginTop: 15 }} key={index}>
                                                                <MultiLineChart
                                                                    metric={stat}
                                                                    metric_lowercase={stat.split(' ').join('_').toLowerCase()}
                                                                    data={combinedStats}
                                                                    height={400}
                                                                    {...props}
                                                                />
                                                            </div>
                                                        );
                                                    })}
                                                    {/* <Tabs
                                                        defaultActiveKey="1"
                                                        animated={{ inkBar: true, tabPane: false }}
                                                        className="tabs-bg2"
                                                        tabBarStyle={{
                                                            textTransform: 'uppercase',
                                                            paddingLeft: 10,
                                                            paddingRight: 10,
                                                        }}
                                                    >
                                                        <TabPane tab="Over Time" key="1">
                                                            {stats.map((stat, index) => {
                                                                return (
                                                                    <div style={{ marginTop: 15 }} key={index}>
                                                                        <MultiLineChart
                                                                            metric={stat}
                                                                            metric_lowercase={stat.split(' ').join('_').toLowerCase()}
                                                                            data={combinedStats}
                                                                            height={400}
                                                                        />
                                                                    </div>
                                                                );
                                                            })}
                                                        </TabPane>
                                                        <TabPane tab="By Game" key="2">
                                                            <table width="100%" className="info-table-stats">
                                                                <tbody>
                                                                    {playerStatsFormatted.map((player, index) => {
                                                                        return player.map((stat, index2) => {
                                                                            return (
                                                                                <tr key={index2}>
                                                                                    <td>Game: {stat.game}</td>
                                                                                    <td>{stat.metric}</td>
                                                                                    <td>{stat.value}</td>
                                                                                </tr>
                                                                            );
                                                                        });
                                                                    })}
                                                                </tbody>
                                                            </table>
                                                            
                                                        </TabPane>
                                                    </Tabs> */}
                                                </Col>

                                                {/* <Col xs={24} align="center">
													{playerStatsFormatted.map((stat, index) => {
														return (
															<div style={{ marginTop: 15 }} key={index}>
																<LineChart
																	metric={stat}
																	data={playerStats}
																	height={400}
																/>
															</div>
														);
													})}
													{opponentStatsFormatted.map((stat, index) => {
														return (
															<div style={{ marginTop: 15 }} key={index}>
																<LineChart
																	metric={stat}
																	data={opponentStats}
																	height={400}
																/>
															</div>
														);
													})}
												</Col> */}
                                            </Row>
                                        </div>
                                    </Card>
                                </Col>
                            </Row>
                        );
                    }}
                </Subscription>
            </SectionContent>
        </SectionWrapper>
    );
}
