import React from 'react';
import { Col, Row, Avatar, Icon, Skeleton, Typography } from 'antd';
import Card, { Meta } from '../../../components/Card';
import { displayEnumType, getGameType } from '../../../utils/';
import { UserOutlined, TrophyOutlined, CalendarOutlined, EnvironmentOutlined } from '@ant-design/icons';
import abstractBg from '../../../assets/img/abstract5.jpg';
import snookerImg from '../../../assets/img/snooker-img.png';
import tournamentThumb from '../../../assets/img/tournament-thumb.png';
import tournamentThumb8Ball from '../../../assets/img/tournament-thumb-8ball.png';
import tournamentThumb9Ball from '../../../assets/img/tournament-thumb-9ball.png';
import tournamentThumb10Ball from '../../../assets/img/tournament-thumb-10ball.png';
import moment from 'moment';

const { Title, Text } = Typography;

const styles = {
    headerImg: {
        overflow: 'hidden',
        background: 'linear-gradient(180deg, #fff, #f0f2f5)',
        // background: 'linear-gradient(#3db9f8, #1890ff)',
        borderRadius: 0,
        // background: `url(${abstractBg})`,
        // backgroundAttachment: 'fixed',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingLeft: 50,
        paddingRight: 50,
        paddingTop: 30,
        paddingBottom: 30,
    },
};

export default function DPDivisionCard(props) {
    const { league, division, theme } = props;
    return (
        <Card
            hoverable
            // cover={
            // 	<div style={styles.headerImg}>
            // 		<img alt="example" src={getGameType(division.game_types)} width={100} height={100} />
            // 	</div>
            // }
            cover={
                <div>
                    <img
                        alt="example"
                        src={
                            division.flyer
                                ? division.flyer
                                : division.game_types === 'Nine Ball'
                                ? tournamentThumb9Ball
                                : division.game_types === 'Eight Ball'
                                ? tournamentThumb8Ball
                                : division.game_types === 'Ten Ball'
                                ? tournamentThumb10Ball
                                : tournamentThumb
                        }
                        style={{ width: '100%' }}
                    />
                </div>
            }
            onClick={() =>
                props.history.push({
                    pathname: `/leagues/${league.slug}/divisions/${division.slug}`,
                    // state: { detail: division }
                })
            }
            // actions={[
            // 	<Icon type="setting" key="setting" />,
            // 	<Icon type="edit" key="edit" />,
            // 	<Icon type="ellipsis" key="ellipsis" />
            // ]}
            // style={{
            // 	width: '100%',
            // 	borderRadius: 8,
            // 	overflow: 'hidden',
            // 	border: 'none',
            // 	boxShadow: '0 2px 5px 0px rgba(0, 0, 0, 0.1)'
            // }}
        >
            <Meta
                // avatar={<Avatar src={division && getGameType(division.game_types)} />}
                title={
                    <div>
                        <Title level={4} style={{ fontWeight: 700 }}>
                            {division && division.name}
                        </Title>
                        <div style={{ marginTop: 14 }}>
                            {division.start_date && (
                                <React.Fragment>
                                    <CalendarOutlined /> <Text>{moment(division.start_date).format('MMMM Do, YYYY')} Start date</Text>
                                    <br />
                                </React.Fragment>
                            )}
                            {division.end_date && (
                                <React.Fragment>
                                    <CalendarOutlined /> <Text>{moment(division.end_date).format('MMMM Do, YYYY')} End date</Text>
                                    <br />
                                </React.Fragment>
                            )}
                            <UserOutlined /> <Text>{division.league_player_divisions_aggregate.aggregate.count} Players</Text>
                            <br />
                            <UserOutlined /> <Text>{division.league_team_divisions_aggregate.aggregate.count} Teams</Text>
                            <br />
                            <Avatar size={20} src={getGameType(division.game_types, theme)} style={{ marginLeft: -2, position: 'relative' }} />{' '}
                            <Text>{displayEnumType(division.game_types)}</Text>
                            {division.venue && (
                                <React.Fragment>
                                    <br />
                                    <EnvironmentOutlined />{' '}
                                    <Text>
                                        {division.venue && division.venue.name} - {division.venue && division.venue.city},{' '}
                                        {division.venue && division.venue.region}
                                    </Text>
                                </React.Fragment>
                            )}
                        </div>
                    </div>
                }
                description={division && division.description}
            />
        </Card>
    );
}
