import React from 'react';
import { Statistic, Avatar, Progress, Table, Typography } from 'antd';
import { getGameType, displayEnumType } from '../../../utils';
import moment from 'moment';
import { CheckCircleOutlined, ClockCircleOutlined } from '@ant-design/icons';

const { Text, Title } = Typography;
const styles = {};

export default function MatchTable(props) {
    const { matches, theme } = props;

    const columns = [
        {
            title: 'Status',
            dataIndex: 'status',
            width: 50,
            sorter: (a, b) =>
                b.status.localeCompare(a.status, undefined, {
                    numeric: true,
                    sensitivity: 'base',
                }),
            render: (text, record) => {
                return (
                    <div>
                        {record.status === 'IN_PROGRESS' ? (
                            <ClockCircleOutlined
                                style={{
                                    fontSize: 20,
                                    marginTop: 5,
                                    color: '#1890ff',
                                }}
                            />
                        ) : record.status === 'NOT_STARTED' ? (
                            <ClockCircleOutlined
                                style={{
                                    fontSize: 20,
                                    marginTop: 5,
                                    color: theme && theme.name === 'dark' ? 'rgba(255,255,255, 0.45)' : 'rgba(0, 0, 0, 0.45)',
                                }}
                            />
                        ) : (
                            <CheckCircleOutlined
                                style={{
                                    fontSize: 20,
                                    marginTop: 5,
                                    color: '#3ebb00',
                                }}
                            />
                        )}
                    </div>
                );
            },
        },
        {
            title: 'Name',
            dataIndex: 'name',
            sorter: (a, b) =>
                b.name.localeCompare(a.name, undefined, {
                    numeric: true,
                    sensitivity: 'base',
                }),
            render: (text, record) => {
                return <Text>{text || '-'}</Text>;
            },
        },

        {
            title: 'Type',
            dataIndex: 'game_type',
            render: (text, record) => {
                return (
                    <div>
                        <Avatar
                            size={18}
                            src={getGameType(record.game_type, theme)}
                            style={{
                                position: 'relative',
                                marginRight: 5,
                            }}
                        />
                        <Text>{displayEnumType(record.game_type)}</Text>
                    </div>
                );
            },
        },
        {
            title: 'Progress',
            dataIndex: 'progress',
            render: (text, record) => {
                return (
                    <div>
                        <Progress
                            percent={parseInt(record.progress)}
                            status={parseInt(record.progress) < 100 ? 'active' : parseInt(record.progress) === 100 ? 'success' : 'normal'}
                            size="small"
                        />
                    </div>
                );
            },
        },
        {
            title: 'Date',
            dataIndex: 'start_date_time',
            defaultSortOrder: 'desc',
            width: 100,
            sorter: (a, b) => {
                if (b.start_date_time && a.start_date_time) {
                    b.start_date_time.localeCompare(a.start_date_time, undefined, {
                        numeric: true,
                        sensitivity: 'base',
                    });
                }
            },

            render: (text, record) => {
                return <Text>{moment(text).format('llll')}</Text>;
            },
        },
    ];

    return (
        <div className="table_nowrap" style={{ width: '100%' }}>
            <Table
                rowKey="id"
                onRow={(record, rowIndex) => {
                    return {
                        onClick: (event) => {
                            console.log(record, rowIndex);
                            props.history.push({
                                pathname: `/matches/${record.slug}`,
                                // search: '?query=abc',
                                state: { data: record },
                            });
                        }, // click row
                        onDoubleClick: (event) => {}, // double click row
                        onContextMenu: (event) => {}, // right button click row
                        onMouseEnter: (event) => {}, // mouse enter row
                        onMouseLeave: (event) => {}, // mouse leave row
                    };
                }}
                columns={columns}
                dataSource={matches}
                pagination={false}
                tableLayout="auto"
                scroll={{ x: 400 }}
            />
        </div>
    );
}
