import React, { useState } from 'react';
import { message, Icon, Row, Col, Typography, Button, Radio, Avatar, Input, List, Tabs, Empty, Skeleton } from 'antd';
import Card from '../../components/Card';
import { Link } from 'react-router-dom';
import SectionHeader from '../../components/SectionHeader';
import SectionContent from '../../components/SectionContent';
import SectionWrapper from '../../components/SectionWrapper';
import { Subscription, Query } from '@apollo/client/react/components';
import DivisionTable from './divisions/DivisionTable';
import LMDivisionCard from './divisions/LMDivisionCard';
import * as routes from '../../config/routes';
import ZeroState from '../../components/ZeroState';
import CircularLoader from '../../components/CircularLoader';
import { GET_LEAGUE_DIVISIONS_QUERY } from './data/queries';
import { DELETE_DIVISION_MUTATION } from './data/mutations';
import { useMutation, useQuery } from '@apollo/react-hooks';
import _ from 'lodash';
import Ripples from 'react-ripples';

const { Text, Title } = Typography;

const styles = {
    row: {
        display: 'flex',
        flexGrow: 1,
    },
    tab: {
        paddingTop: 5,
        paddingLeft: 24,
        paddingRight: 24,
        paddingBottom: 24,
    },
    selectedToggle: {
        border: '1px solid #fff',
        boxShadow: 'none',
    },
    defaultToggle: {
        color: '#fff',
        backgroundColor: 'transparent',
        border: '1px solid #fff',
        boxShadow: 'none',
    },
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        padding: 20,
    },
};

export default function LMDivisions(props) {
    const { league } = props;
    const [current, setCurrent] = useState('list');
    const [deleteDivision] = useMutation(DELETE_DIVISION_MUTATION);

    console.log(league);

    return (
        <SectionWrapper>
            <SectionHeader
                title={props.title}
                titleStyle={{ color: '#fff', margin: 0, textTransform: 'uppercase' }}
                avatar={league.avatar}
                subtitle={league.name}
                subtitlePrefix={true}
                subtitleStyle={{
                    color: 'rgba(255,255,255,0.8)',
                    textTransform: 'uppercase',
                    fontWeight: 600,
                    fontSize: 12,
                    letterSpacing: 3,
                }}
                theme="leagueManager"
            >
                <div>
                    <Radio.Group
                        value={current}
                        type="ghost"
                        onChange={(e) => {
                            setCurrent(e.target.value);
                        }}
                        style={{ marginRight: 10 }}
                    >
                        <Radio.Button value="grid" style={current === 'grid' ? styles.selectedToggle : styles.defaultToggle}>
                            <Icon type="appstore" />
                        </Radio.Button>
                        <Radio.Button value="list" style={current === 'list' ? styles.selectedToggle : styles.defaultToggle}>
                            <Icon type="unordered-list" />
                        </Radio.Button>
                    </Radio.Group>

                    {/* <Link to={`/league-manager/${props.match.params.slug}/settings`} style={{ marginRight: 10 }}>
                        <Button type="ghost" ghost>
                            <Icon type="setting" />
                        </Button>
                    </Link> */}
                    <Link to={`/leagues/${props.match.params.slug}/divisions`}>
                        <Button type="ghost" ghost>
                            Public Profile
                        </Button>
                    </Link>
                </div>
            </SectionHeader>
            <SectionContent padding="20px 40px">
                <Row gutter={16} justify="center" type="flex">
                    <Col xs={24}>
                        <Query
                            query={GET_LEAGUE_DIVISIONS_QUERY}
                            fetchPolicy="cache-and-network"
                            notifyOnNetworkStatusChange={true}
                            variables={{ leagueId: league.id }}
                            onCompleted={async (data) => {
                                console.log(data);
                            }}
                        >
                            {({ loading, error, data }) => {
                                if (loading)
                                    return (
                                        <div style={styles.container}>
                                            <CircularLoader />
                                        </div>
                                    );
                                if (error) return <div style={styles.container}>Error: {error.message}</div>;
                                if ((!loading && data && !data.league_divisions) || (data && data.league_divisions && data.league_divisions.length === 0)) {
                                    return (
                                        <Card
                                            title="Manage Divisions"
                                            extra={
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'space-between',
                                                    }}
                                                >
                                                    <Link to={`/league-manager/${props.match.params.slug}/divisions/new`}>
                                                        <Button type="primary">
                                                            <Icon type="plus" /> Add Division
                                                        </Button>
                                                    </Link>
                                                </div>
                                            }
                                            bodyStyle={{ padding: 0 }}
                                        >
                                            <ZeroState showImage message="No divisions have been added yet." style={{ margin: 30 }} {...props} />
                                        </Card>
                                    );
                                }
                                return (
                                    <React.Fragment>
                                        {current === 'grid' ? (
                                            <Row gutter={[16, 16]} justify="start" type="flex">
                                                <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={6}>
                                                    <Ripples color="#ccc" className="ripple-effect">
                                                        <Card
                                                            hoverable
                                                            style={{
                                                                width: '100%',
                                                                height: '100%',
                                                                minHeight: 220,
                                                                borderRadius: '8px',
                                                                // backgroundColor: '#fff',
                                                                // border: theme && theme.name === 'light' ? '3px dashed #ccc' : '3px dashed rgba(255,255,255,0.2)',
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                                textAlign: 'center',
                                                            }}
                                                            className="dashed-card"
                                                            onClick={() => {
                                                                props.history.push(`/league-manager/${props.match.params.slug}/divisions/new`);
                                                            }}
                                                        >
                                                            <Icon
                                                                type="plus"
                                                                style={{
                                                                    fontSize: 50,
                                                                    color: '#8a8a8a',
                                                                    fontWeight: 'bold',
                                                                }}
                                                            />
                                                        </Card>
                                                    </Ripples>
                                                </Col>
                                                {data.league_divisions &&
                                                    data.league_divisions
                                                        .map((item, index) => {
                                                            return {
                                                                ...item,
                                                                key: index,
                                                                is_public: item.is_public === true ? 'Public' : 'Private',
                                                                is_active: item.is_active === true ? 'Active' : 'Inactive',
                                                                division_type: _.startCase(_.toLower(item.division_type.replace('_', ' '))),
                                                                game_types: item.game_types
                                                                    .map((item) => _.startCase(_.toLower(item.game_type.replace('_', ' '))))
                                                                    .join(', '),
                                                            };
                                                        })
                                                        .map((division, index) => (
                                                            <Col
                                                                xs={24}
                                                                sm={12}
                                                                md={12}
                                                                lg={8}
                                                                xl={8}
                                                                xxl={6}
                                                                key={index}
                                                                style={{
                                                                    display: 'flex',
                                                                }}
                                                            >
                                                                <LMDivisionCard league={league} division={division} {...props} />
                                                            </Col>
                                                        ))}
                                                {/* <Col>
                                                    <List
                                                        className="hover-list"
                                                        size="small"
                                                        loading={loading}
                                                        itemLayout="horizontal"
                                                        dataSource={data.league_divisions}
                                                        renderItem={(item) => (
                                                            <List.Item
                                                                actions={[<a key="list-loadmore-edit">View</a>]}
                                                                style={{ padding: '10px 16px' }}
                                                                onClick={() => props.history.push(`/league-manager/${props.data.slug}/divisions/${item.slug}`)}
                                                            >
                                                                <Skeleton avatar title={false} loading={loading} active>
                                                                    <List.Item.Meta
                                                                        avatar={<Avatar src={<Icon type="global" />} />}
                                                                        title={item.name}
                                                                        description={item.description}
                                                                    />
                                                                </Skeleton>
                                                            </List.Item>
                                                        )}
                                                    />
                                                </Col> */}
                                            </Row>
                                        ) : (
                                            <Card
                                                title={`All Divisions (${data.league_divisions && data.league_divisions.length})`}
                                                style={{
                                                    borderRadius: 8,
                                                    overflow: 'hidden',
                                                    border: 'none',
                                                    boxShadow: '0 2px 5px 0px rgba(0, 0, 0, 0.1)',
                                                }}
                                                extra={
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'space-between',
                                                        }}
                                                    >
                                                        <Link
                                                            to={`/league-manager/${props.match.params.slug}/divisions/new`}
                                                            style={{ marginBottom: '0.5em', marginRight: 10 }}
                                                        >
                                                            <Button type="primary">+ Create Division</Button>
                                                        </Link>
                                                    </div>
                                                }
                                                bodyStyle={{ padding: 0, overflow: 'auto' }}
                                            >
                                                <DivisionTable
                                                    {...props}
                                                    league={league}
                                                    divisions={
                                                        data.league_divisions &&
                                                        data.league_divisions.map((item, index) => {
                                                            return {
                                                                ...item,
                                                                key: index,
                                                                index: index + 1,
                                                                players: item.league_player_divisions_aggregate.aggregate.count,
                                                                is_public: item.is_public === true ? 'Public' : 'Private',
                                                                is_active: item.is_active === true ? 'Active' : 'Inactive',
                                                                division_type: _.startCase(_.toLower(item.division_type.replace('_', ' '))),
                                                                game_types: item.game_types
                                                                    .map((item) => _.startCase(_.toLower(item.game_type.replace('_', ' '))))
                                                                    .join(', '),
                                                            };
                                                        })
                                                    }
                                                    onDelete={(record) => {
                                                        deleteDivision({
                                                            variables: {
                                                                league_id: league.id,
                                                                id: record.id,
                                                            },
                                                            notifyOnNetworkStatusChange: true,
                                                            awaitRefetchQueries: true,
                                                            refetchQueries: [
                                                                {
                                                                    query: GET_LEAGUE_DIVISIONS_QUERY,
                                                                    variables: { leagueId: league.id },
                                                                },
                                                            ],
                                                        })
                                                            .then((data) => {
                                                                console.log(data);
                                                                message.success('Division deleted from league');
                                                            })
                                                            .catch((error) => {
                                                                console.log(error);
                                                                message.info('There was an error', error);
                                                            });
                                                    }}
                                                />
                                            </Card>
                                        )}
                                    </React.Fragment>
                                );
                            }}
                        </Query>
                    </Col>
                </Row>
            </SectionContent>
        </SectionWrapper>
    );
}
