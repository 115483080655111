import React, { useRef, forwardRef, createRef } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { Button, message, Modal, Tabs } from 'antd';
import { inviteModalVisible, inviteModalButtonDisabled, inviteModalButtonLoading, ghostEventId } from './data/atoms';
import { set } from 'lodash';
import { Form, FormItem, Input, Radio, Select, SubmitButton, ResetButton, AutoComplete } from 'formik-antd';
import { Formik } from 'formik';
import * as Yup from 'yup';
import InviteButton from './components/ImportPlayerModal/InviteButton';
import { functions } from '../../firebase';
import { connectFunctionsEmulator, httpsCallable } from 'firebase/functions';
import {
    GET_USER_BY_EMAIL_QUERY,
    GET_CHECK_INVITE_CODE_QUERY,
    GET_GHOST_GAMES_INVITE_KEY,
    GET_GHOST_GAMES_PLAYER_QUERY,
    GET_GHOST_EVENT_PLAYERS_QUERY,
} from './data/queries';
import { INSERT_GHOST_EVENT_PLAYER_MUTATION, INSERT_GHOST_GAMES_INVITE_KEY } from './data/mutations';
import create from 'antd/lib/icon/IconFont';

const { Option } = Select;
const { TabPane } = Tabs;

function generateInviteKey() {
    var key = [
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        0,
        'a',
        'b',
        'c',
        'd',
        'e',
        'f',
        'g',
        'h',
        'i',
        'j',
        'k',
        'l',
        'm',
        'n',
        'o',
        'p',
        'q',
        'r',
        's',
        't',
        'u',
        'v',
        'w',
        'x',
        'y',
        'z',
        'A',
        'B',
        'C',
        'D',
        'E',
        'F',
        'G',
        'H',
        'I',
        'J',
        'K',
        'L',
        'M',
        'N',
        'O',
        'P',
        'Q',
        'R',
        'S',
        'T',
        'U',
        'N',
        'W',
        'X',
        'Y',
        'Z',
    ];
    var length = key.length;

    var appendStr = '';
    for (let i = 0; i < 99; i++) {
        let rand = Math.floor(Math.random() * length);
        appendStr += key[rand];
    }

    return appendStr;
}

const AddPlayerForm = (props) => {
    const { ghostEvent, client, _ref, emailAddress, userFirstName } = props;

    const setInviteButtonDisabled = useSetRecoilState(inviteModalButtonDisabled);
    const setInviteButtonLoading = useSetRecoilState(inviteModalButtonLoading);

    const schema = Yup.object().shape({
        name: Yup.string().required(),
    });

    function onChange(e) {
        setInviteButtonDisabled(!e.currentTarget.value ? true : false);
    }

    return (
        <Formik
            ref={_ref}
            onSubmit={async (values) => {
                setInviteButtonLoading(true);
                let _userId = null,
                    _displayName,
                    _firstName,
                    _user,
                    _isUser = false;
                let _loop = true;
                let _inviteCodeId;
                let _playerCheckEmail = values.email;

                const userCheck = await client.query({
                    query: GET_GHOST_GAMES_PLAYER_QUERY,
                    variables: {
                        ghost_games_id: parseInt(ghostEvent.id),
                        email_address: _playerCheckEmail,
                    },
                    fetchPolicy: 'no-cache',
                });

                console.log(userCheck);
                console.log({
                    ghost_games_id: ghostEvent.id,
                    email_address: values.email,
                });

                if (userCheck.data.ghost_games_players.length > 0) {
                    console.log(userCheck);
                    message.warning('This user is already invited.  Try the reminder button');
                    setInviteButtonLoading(false);
                    return;
                }

                if (window.location.hostname.indexOf('localhost') > -1) {
                    connectFunctionsEmulator(functions, 'localhost', 5001);
                }

                const sendInvite = httpsCallable(functions, 'ghostEventPlayerInvite');

                const res = await client.query({
                    query: GET_USER_BY_EMAIL_QUERY,
                    variables: {
                        email: values.email,
                    },
                });

                if (res && res.data && res.data.users && res.data.users[0]) {
                    _user = res.data.users[0];

                    _userId = _user.id;
                    _displayName = _user.display_name;
                    _firstName = _user.first_name;
                    _isUser = true;
                }

                const inviteLinkIdRes = await client.query({
                    query: GET_GHOST_GAMES_INVITE_KEY,
                    variables: {
                        ghost_games_id: ghostEvent.id,
                    },
                });

                const inviteLinkId =
                    inviteLinkIdRes &&
                    inviteLinkIdRes.data &&
                    inviteLinkIdRes.data.ghost_games &&
                    inviteLinkIdRes.data.ghost_games[0] &&
                    inviteLinkIdRes.data.ghost_games[0].invite_link_id;

                const resPlayers = await client.mutate({
                    mutation: INSERT_GHOST_EVENT_PLAYER_MUTATION,
                    variables: {
                        objects: {
                            ghost_games_id: ghostEvent.id,
                            user_id: _userId,
                            email_address: values.email,
                            is_invited: true,
                        },
                    },
                    refetchQueries: [
                        {
                            query: GET_GHOST_EVENT_PLAYERS_QUERY,
                            variables: { ghost_games_id: ghostEvent.id },
                        },
                    ],
                });

                var generatedKey = generateInviteKey();
                var loop = true;

                while (loop === true) {
                    const inviteKeyCheck = await client.query({
                        query: GET_GHOST_GAMES_INVITE_KEY,
                        variables: {
                            ghost_games_id: ghostEvent.id,
                            key: generatedKey,
                        },
                    });

                    if (inviteKeyCheck) {
                        generatedKey = generateInviteKey();
                        loop = false;
                    }
                }

                const inviteKey = await client.mutate({
                    mutation: INSERT_GHOST_GAMES_INVITE_KEY,
                    variables: {
                        object: {
                            ghost_games_id: ghostEvent.id,
                            key: generatedKey,
                            slug: ghostEvent.slug,
                            email: values.email,
                        },
                    },
                });

                if (resPlayers) {
                    console.log('inviting player...');
                    sendInvite({
                        to_email: values.email,
                        // to_name: fromName,
                        from_name: userFirstName,
                        // from_message: values.message,
                        from_ghost_event_name: ghostEvent.name,
                        from_ghost_event_slug: ghostEvent.slug,
                        redirect_url: `/ghost-event-manager/${ghostEvent.slug}`,
                        user_invite_key: generatedKey,
                        is_user: _isUser,
                    })
                        .then(async (res) => {
                            message.success('Player invited');
                            setInviteButtonLoading(false);
                        })
                        .catch((err) => {
                            message.error(`There was an error inviting the user:${err}`);
                            setInviteButtonLoading(false);
                        });
                } else {
                    message.error('There was an error inviting the user');
                    setInviteButtonLoading(false);
                }
            }}
            render={({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => {
                return (
                    <Form onSubmit={handleSubmit} layout="vertical" style={{ maxWidth: 6000 }}>
                        <FormItem label="Email Address" name="email">
                            <Input name="email" placeholder="Email Address" onChange={onChange} />
                        </FormItem>
                    </Form>
                );
            }}
        />
    );
};

const PlayerForwardRef = forwardRef((props, ref) => {
    return <AddPlayerForm {...props} _ref={ref} />;
});

export default function InvitePlayerModal(props) {
    const { client, ghostEvent, emailAddress, userFirstName } = props;

    const visible = useRecoilValue(inviteModalVisible);
    const setVisible = useSetRecoilState(inviteModalVisible);
    const setInviteButtonDisabled = useSetRecoilState(inviteModalButtonDisabled);

    const _ref = useRef();

    function okOk() {
        setVisible(false);
        setInviteButtonDisabled(true);
    }

    function onCancel() {
        setVisible(false);
        setInviteButtonDisabled(true);
    }

    function onInviteButtonClick() {
        _ref.current.handleSubmit();
    }

    return (
        <Modal
            onOk={okOk}
            onCancel={onCancel}
            visible={visible}
            title="Invite Player"
            destroyOnClose={true}
            footer={[
                <Button key="cancel" onClick={onCancel}>
                    Cancel
                </Button>,
                <InviteButton key="invite" onClick={onInviteButtonClick} />,
            ]}
        >
            <PlayerForwardRef client={client} ghostEvent={ghostEvent} ref={_ref} emailAddress={emailAddress} userFirstName={userFirstName} />
        </Modal>
    );
}
