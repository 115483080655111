import gql from 'graphql-tag';

export const LEAGUE_FIELDS = gql`
    fragment leagueFields2 on leagues {
        id
        name
        slug
        league_type
        short_description
        long_description
        avatar
        logo
        is_public
        has_team_support
        has_division_support
        has_challenge_support
        organization_id
        organization {
            id
            name
            slug
            description
            address1
            address2
            city
            country
            region
            postal_code
            phone_number
            fax_number
            email_address
            website
            owner_id
            created_at
            updated_at
        }
        owner_id
        owner {
            id
            first_name
            last_name
            slug
            email
        }
        operator_id
        operator {
            id
            first_name
            last_name
            slug
            email
        }
        address1
        address2
        city
        county
        country
        region
        postal_code
        phone_number
        fax_number
        email_address
        website
        social_facebook
        social_instagram
        social_twitter
        match_notifications_in_app
        match_notifications_sms
        league_divisions_aggregate {
            aggregate {
                count
            }
        }
        league_teams_aggregate {
            aggregate {
                count
            }
        }
        league_players_aggregate {
            aggregate {
                count
            }
        }
        matches_aggregate {
            aggregate {
                count
            }
        }
        league_schedules_aggregate {
            aggregate {
                count
            }
        }
        in_progress_matches: matches_aggregate(where: { status: { _eq: IN_PROGRESS } }) {
            aggregate {
                count
            }
        }
        completed_matches: matches_aggregate(where: { status: { _eq: COMPLETED } }) {
            aggregate {
                count
            }
        }
        not_started_matches: matches_aggregate(where: { status: { _eq: NOT_STARTED } }) {
            aggregate {
                count
            }
        }
        league_venues_aggregate {
            aggregate {
                count
            }
        }
        created_at
        updated_at
        deleted_at
    }
`;

export const LEAGUE_DIVISION_FIELDS = gql`
    fragment leagueDivisionFields on league_divisions {
        id
        name
        slug
        description
        division_type
        game_types
        best_of_num
        race_to_num
        scoring_format
        winner_determined_by
        # division_game_types {
        # 	game_type
        # }
        session_id
        num_weeks
        start_date
        end_date
        is_public
        is_active
        is_handicapped
        league_schedules_aggregate {
            aggregate {
                count
            }
        }
        league_player_divisions_aggregate {
            aggregate {
                count
            }
            nodes {
                id
            }
        }
        league_matches_aggregate {
            aggregate {
                count
            }
        }
        # league_player_divisions {
        #	 id
        # }
        league_team_divisions_aggregate {
            aggregate {
                count
            }
        }
        league_team_divisions {
            id
            league_team {
                id
                name
                slug
            }
        }
    }
`;

export const LEAGUE_MATCH_FIELDS = gql`
    fragment leagueMatchFields on matches {
        id
        name
        slug
        description
        match_number
        match_type
        game_type
        rule_format
        handicap_format
        scoring_format
        race_to
        player_id
        player_name
        player_score
        player_points
        player_tiebreaker_points
        player_race_to
        player_country
        player_state
        player_fargo
        player_skill_level
        player_is_winner
        opponent_id
        opponent_name
        opponent_score
        opponent_points
        opponent_tiebreaker_points
        opponent_race_to
        opponent_country
        opponent_state
        opponent_fargo
        opponent_skill_level
        opponent_is_winner
        winner_id
        winner_score
        loser_id
        loser_score
        status
        progress
        duration
        livestream_url
        score_verified
        scheduled_time
        start_date_time
        end_date_time
        is_public
        event_id
        venue_id
        venue {
            id
            name
            slug
            address1
            address2
            city
            region
            country
            postal_code
        }
        division_id
        division {
            id
            name
            slug
        }
        challenges {
            id
            name
            slug
        }
        pool_table_id
        table_name
        table_size
        scoreboard_balls
        creator_id
        created_at
        updated_at
        created_at
        updated_at
        match_players {
            id
            name
            country
            player_id
            skill_level
            race_to
        }
        division_id
        division {
            id
            name
        }
    }
`;
