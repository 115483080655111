import React, { useState, useEffect } from 'react';
import { Avatar, Typography, Row, Col, Button, Icon } from 'antd';
import { withRouter, Link } from 'react-router-dom';
import CircularLoader from '../../../components/CircularLoader';
import liveStreamSS from '../../../assets/img/live-stream-bg.png';
import { GET_TOURNAMENT_BY_SLUG, GET_TOURNAMENT_TABLE_QUERY } from './data/queries';
import { GET_TOURNAMENT_TABLE_SUBSCRIPTION } from './data/subscriptions';
import { Query, Subscription } from '@apollo/client/react/components';
import TVRackTracker from './TVRackTracker';
import qs from 'query-string';

const styles = {
    container: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 20,
    },
};

function DPTournamentTVRackTracker(props) {
    const { client } = props;
    const { match, authState } = props;
    const params = qs.parse(props.location.search, { ignoreQueryPrefix: true });
    const tournamentSlug = match.params.slug;
    const tableSlug = match.params.table;
    const showPhoto = params && params.photo === 'true' ? true : false;

    return (
        <React.Fragment>
            {showPhoto === true && (
                <div style={{ position: 'fixed', top: 0, left: 0, width: '100%' }}>
                    <img src={liveStreamSS} alt="Live stream screenshot" width="100%" />
                </div>
            )}

            <Query query={GET_TOURNAMENT_BY_SLUG} fetchPolicy="cache-and-network" notifyOnNetworkStatusChange={true} variables={{ slug: tournamentSlug }}>
                {({ loading, error, data, refetch }) => {
                    if (loading) return <CircularLoader />;
                    if (error) return <div>Error: {error.message}</div>;

                    const tournamentData = data.tournaments[0];
                    console.log(tournamentData);

                    return (
                        <React.Fragment>
                            <Subscription
                                subscription={GET_TOURNAMENT_TABLE_SUBSCRIPTION}
                                fetchPolicy="cache-and-network"
                                notifyOnNetworkStatusChange={true}
                                variables={{ tournament_id: tournamentData.id, table_slug: tableSlug }}
                                onCompleted={(data, error, loading) => {
                                    console.log(data);
                                }}
                            >
                                {({ loading, error, data, refetch, fetchMore, subscribeToMore, networkStatus }) => {
                                    if (loading) return <CircularLoader />;
                                    if (error) return <div>Error: {error.message}</div>;
                                    if (data && !data.pool_tables) {
                                        return <div style={styles.container}>Match not found.</div>;
                                    }
                                    if (data && data.pool_tables.length === 0) {
                                        return <div style={styles.container}>Match not found.</div>;
                                    }

                                    const tableData = data.pool_tables && data.pool_tables[0];
                                    const matchData = tableData && tableData.tournament_match_table && tableData.tournament_match_table[0];

                                    return <TVRackTracker tournamentData={tournamentData} matchData={matchData} tableData={tableData} {...props} />;
                                }}
                            </Subscription>
                        </React.Fragment>
                    );
                }}
            </Query>
        </React.Fragment>
    );
}

export default withRouter(DPTournamentTVRackTracker);
