import React from 'react';
import { Typography } from 'antd';

const { Text } = Typography;

const styles = {
    cell1: {
        background: 'linear-gradient(90deg, rgb(0, 137, 205), rgb(0, 69, 181))',
        width: 50,
        color: '#fff',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontWeight: 600,
        fontSize: 18,
        height: '100%',
    },
};

export default function PlayerPoints(props) {
    const { show_ball_count, player_points, scoring_prefix } = props;

    return (
        <React.Fragment>
            {show_ball_count !== false && (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {scoring_prefix && (
                        <div
                            style={{
                                background: 'rgba(0,0,0,0.5)',
                                paddingLeft: 5,
                                paddingRight: 5,
                                height: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                fontSize: 12,
                            }}
                        >
                            {scoring_prefix}
                        </div>
                    )}
                    <div style={styles.cell1}>{player_points || 0}</div>
                </div>
            )}
        </React.Fragment>
    );
}
