import React from 'react';
import Flag from 'react-flags';
import twIoc from '../assets/img/flags/tw-ioc-rect.png';
import scotland from '../assets/img/flags/scotland.png';

export default function FlagComponent(props) {
    const { name } = props;

    return (
        <span style={{ position: 'relative' }}>
            {name &&
            (name.toLowerCase() === 'tw' || name.toLowerCase() === 'taiwan' || name.toLowerCase() === 'tpe' || name.toLowerCase() === 'chinese taipei') ? (
                <img src={twIoc} height={48} alt="Flag" />
            ) : name && name.toLowerCase() === 'scotland' ? (
                <img src={scotland} height={48} alt="Flag" />
            ) : (
                <Flag {...props} />
            )}
        </span>
    );
}
