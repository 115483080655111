import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { message, Modal, Button, Upload, Divider, Icon, Typography, Avatar } from 'antd';
import { Form, FormItem, Input, InputNumber, Radio, Select, SubmitButton, ResetButton, AutoComplete, DatePicker } from 'formik-antd';
import * as Yup from 'yup';
import { isoCountries } from '../../utils/isoCountryCodes';
import { Formik, ErrorMessage } from 'formik';
import { CountryRegionData } from 'react-country-region-selector';
import CircleFlag from '../../components/CircleFlag';
import slugify from 'slugify';
import { v4 as uuidv4 } from 'uuid';
import { Query, Mutation } from '@apollo/client/react/components';
import CircularLoader from '../../components/CircularLoader';
import { getGameType } from '../../utils';
import {
    GET_LEAGUE_QUERY,
    GET_LEAGUE_MATCH_QUERY,
    GET_LEAGUE_MATCHES_QUERY,
    GET_GAME_TYPES_QUERY,
    GET_DIVISION_TYPES_QUERY,
    GET_VENUES_QUERY,
    GET_LEAGUE_DIVISION_PLAYERS_QUERY,
    GET_LEAGUE_PLAYERS_QUERY,
    SEARCH_VENUES_QUERY,
    FILTER_LEAGUE_PLAYERS,
    FILTER_USERS,
    GET_LEAGUE_DIVISIONS_QUERY,
    GET_VENUE_TABLES_QUERY,
} from './data/queries';
import { useMutation, useQuery } from '@apollo/react-hooks';
import AddVenueModal from './AddVenueModal';
import { CREATE_LEAGUE_MATCH, UPDATE_LEAGUE_MATCH, DELETE_LEAGUE_MATCH_PLAYER, UPDATE_LEAGUE_MATCH_PLAYER, CREATE_LEAGUE_MATCH_GAME } from './data/mutations';
import _ from 'lodash';
import moment from 'moment-timezone';

const { Title, Text } = Typography;
const { Option } = Select;

function AddMatchModal(props) {
    const { authState, client, league, selectedMatchId, userID, leagueID, divisionID, modalVisible, onModalOk, onModalCancel, theme } = props;
    const [loading, setLoading] = useState(false);
    const [players, setPlayers] = useState();
    const [tables, setTables] = useState();
    const [venueModalVisible, setVenueModalVisible] = useState(false);
    const [venueDataSource, setVenueDataSource] = useState('');
    const [createLeagueMatch, { createLoading, createError }] = useMutation(CREATE_LEAGUE_MATCH);
    const [updateLeagueMatch, { updateLoading, updateError }] = useMutation(UPDATE_LEAGUE_MATCH);
    const [updateLeagueMatchPlayer] = useMutation(UPDATE_LEAGUE_MATCH_PLAYER);
    const [deleteLeagueMatchPlayer] = useMutation(DELETE_LEAGUE_MATCH_PLAYER);
    const [createMatchGame] = useMutation(CREATE_LEAGUE_MATCH_GAME);
    const formRef = useRef();

    function onOk(value) {
        const tzDate = moment.tz(value, moment.tz.guess(value)).format('YYYY-MM-DD hh:mm A z');
        console.log('onOK', tzDate);
        return tzDate;
    }

    function handleVenueModalOk(e) {
        setVenueModalVisible(false);
    }

    function handleVenueModalCancel(e) {
        setVenueModalVisible(false);
    }

    function handleAddVenue() {
        setVenueModalVisible(true);
    }

    function handleFocus(event) {
        event.target.select();
    }

    const submitAdd = (data, actions) => {
        const {
            name,
            division_id,
            player_id,
            opponent_id,
            player_race_to,
            opponent_race_to,
            scoring_format,
            match_type,
            game_type,
            pool_table_id,
            venue_id,
            scheduled_time,
        } = data;
        const table = tables.filter((table) => table.id === pool_table_id);
        const player = _.first(players.filter((player) => player.player_id === player_id));
        const opponent = _.first(players.filter((player) => player.player_id === opponent_id));
        const matchName = name ? name : player && opponent ? `${player.name} vs. ${opponent.name}` : '';
        const match_players = [player, opponent];

        let slug;
        if (matchName) {
            slug = slugify(`${matchName} ${moment(scheduled_time).format('MM-DD-YYYY')}`, {
                replacement: '-',
                remove: /[*+~.,()'"#!:/@]/g,
                lower: true,
            });
        }

        setLoading(true);
        createLeagueMatch({
            variables: {
                objects: [
                    {
                        name: matchName,
                        ...(slug && { slug: slug }),
                        match_type,
                        game_type,
                        scoring_format,
                        venue_id,
                        league_id: leagueID,
                        division_id,
                        player_id: player.player_id,
                        player_name: player.name,
                        player_country: player.country,
                        player_fargo: player.fargo,
                        player_skill_level: player.skill_level || player.fargo,
                        ...(player_race_to && { player_race_to: player_race_to }),
                        opponent_id: opponent.player_id,
                        opponent_name: opponent.name,
                        opponent_country: opponent.country,
                        opponent_fargo: opponent.fargo,
                        opponent_skill_level: opponent.skill_level || opponent.fargo,
                        ...(opponent_race_to && { opponent_race_to: opponent_race_to }),
                        ...(pool_table_id && { pool_table_id: pool_table_id }),
                        ...(table && table.length && { table_name: table[0].label }),
                        ...(table && table.length && { table_size: table[0].size }),
                        scheduled_time,
                        match_players: {
                            data: match_players.map((item) => {
                                const player = players.filter((player) => player.player_id === item.player_id);
                                return {
                                    league_id: leagueID,
                                    player_id: item.player_id,
                                    division_id: division_id,
                                    name: player && player[0].name,
                                    country: player && player[0].country,
                                    skill_level: player && player[0].skill_level && player[0].skill_level.toString(),
                                    race_to: player && player[0].race_to,
                                };
                            }),
                            // on_conflict: {
                            //   constraint: leagues_pkey,
                            //   update_columns: [name, league_player_id, league_id, division_id, country, skill_level, race_to]
                            // }
                        },
                        managers: {
                            data: [
                                {
                                    user_id: authState && authState.user && authState.user.id,
                                    email: authState && authState.user && authState.user.email,
                                    match_slug: slug,
                                    invite_accepted: true,
                                    admin: true,
                                    creator: true,
                                },
                            ],
                        },
                    },
                ],
            },
            notifyOnNetworkStatusChange: true,
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: GET_LEAGUE_MATCHES_QUERY,
                    variables: { league_id: leagueID },
                },
                {
                    query: GET_LEAGUE_QUERY,
                    variables: { id: leagueID },
                },
            ],
        })
            .then((res) => {
                console.log(res);
                setLoading(false);
                actions.setSubmitting(false);
                actions.resetForm();
                message.success('Match successfully added');
                const added = res.data.insert_matches.returning[0];
                createMatchGame({
                    variables: {
                        objects: [
                            {
                                league_id: leagueID,
                                division_id: division_id,
                                match_id: added.id,
                                player_id: player_id,
                                opponent_id: opponent_id,
                                start_date_time: moment.utc(),
                                status: 'IN_PROGRESS',
                                game_number: 1,
                            },
                        ],
                    },
                })
                    .then((res) => {
                        console.log(res);
                        // message.success('Match frame created');
                    })
                    .catch((error) => {
                        console.log(error);
                        // setLoading(false);
                        message.error('There was an error', error);
                    });

                onModalOk(added);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
                actions.setSubmitting(false);
                actions.resetForm();
                message.info('There was an error', error);
                onModalOk();
            });
    };

    const submitUpdate = (data, match) => {
        console.log(data);
        const {
            name,
            division_id,
            player_id,
            opponent_id,
            player_race_to,
            opponent_race_to,
            scoring_format,
            match_type,
            game_type,
            pool_table_id,
            venue_id,
            scheduled_time,
        } = data;
        const table = tables.filter((table) => table.id === pool_table_id);
        const player = _.first(players.filter((player) => player.player_id === player_id));
        const opponent = _.first(players.filter((player) => player.player_id === opponent_id));
        const matchName = name ? name : player && opponent ? `${player.name} vs. ${opponent.name}` : '';
        const match_players = [player, opponent];
        // const playerNames =
        // 	league_match_players &&
        // 	league_match_players.map((id) => {
        // 		const playerData = players.filter((player) => player.id === id);
        // 		return {
        // 			name: playerData && playerData[0].name,
        // 			skill_level: playerData && playerData[0].skill_level
        // 		};
        // 	});
        // const matchName =
        // 	playerNames && playerNames.length > 0
        // 		? `${playerNames[0].name} ${playerNames[0].skill_level
        // 				? `(${playerNames[0].skill_level})`
        // 				: ''} vs. ${playerNames[1].name} ${playerNames[1].skill_level
        // 				? `(${playerNames[1].skill_level})`
        // 				: ''}`
        // 		: '';
        let slug;
        if (matchName) {
            slug = slugify(`${matchName} ${moment(scheduled_time).format('MM-DD-YYYY')}`, {
                replacement: '-',
                remove: /[*+~.,()'"#!:/@]/g,
                lower: true,
            });
        }

        // console.log(challenger1);
        // console.log(challenger2);
        // console.log(matchName);

        console.log({
            selectedMatchId,
            leagueID,
        });

        setLoading(true);
        updateLeagueMatch({
            variables: {
                id: selectedMatchId,
                league_id: leagueID,
                changes: {
                    name: matchName,
                    ...(slug && { slug: slug }),
                    match_type,
                    game_type,
                    scoring_format,
                    venue_id,
                    league_id: leagueID,
                    division_id,
                    player_id: player.player_id,
                    player_name: player.name,
                    player_country: player.country,
                    player_fargo: player.fargo,
                    player_skill_level: player.skill_level,
                    ...(player_race_to && { player_race_to: player_race_to }),
                    opponent_id: opponent.player_id,
                    opponent_name: opponent.name,
                    opponent_country: opponent.country,
                    opponent_fargo: opponent.fargo,
                    opponent_skill_level: opponent.skill_level,
                    ...(opponent_race_to && { opponent_race_to: opponent_race_to }),
                    ...(pool_table_id && { pool_table_id: pool_table_id }),
                    ...(table && table.length && { table_name: table[0].label }),
                    ...(table && table.length && { table_size: table[0].size }),
                    scheduled_time,
                },
            },
        })
            .then((res) => {
                const original = match.match_players.map((id) => id);
                const toUpdate = _.uniq(
                    match_players.map((player) => player.player_id),
                    original
                );
                const toDelete = _.difference(original, toUpdate);
                console.log('original', original);
                console.log(
                    'new',
                    match_players.map((player) => player.player_id)
                );
                console.log('update', toUpdate);
                console.log('delete', toDelete);

                if (toDelete.length > 0) {
                    deleteLeagueMatchPlayer({
                        variables: {
                            league_id: leagueID,
                            match_id: match.id,
                        },
                    })
                        .then((data) => {
                            console.log(data);
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                }

                updateLeagueMatchPlayer({
                    variables: {
                        league_id: leagueID,
                        match_id: match.id,
                        objects: toUpdate.map((playerId) => {
                            const player = players.filter((player) => player.player_id === playerId);
                            console.log(player);
                            return {
                                league_id: leagueID,
                                match_id: match.id,
                                player_id: playerId,
                                division_id: division_id,
                                name: player && player[0].name,
                                country: player && player[0].country,
                                skill_level: player && player[0].skill_level && player[0].skill_level.toString(),
                                race_to: player && player[0].race_to,
                            };
                        }),
                    },
                    notifyOnNetworkStatusChange: true,
                    awaitRefetchQueries: true,
                    refetchQueries: [
                        {
                            query: GET_LEAGUE_MATCH_QUERY,
                            variables: { match_id: selectedMatchId, league_id: leagueID },
                        },
                    ],
                })
                    .then((data) => {
                        console.log(data);
                        setLoading(false);
                        message.success('Changes saved');
                        onModalOk();
                    })
                    .catch((error) => {
                        console.log(error);
                        setLoading(false);
                        message.info('There was an error', error);
                        onModalOk();
                    });
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
                message.error('There was an error', error);
                onModalOk();
            });
    };

    const schema = Yup.object().shape({
        // division_id: Yup.number().required('Required'),
        match_type: Yup.string().required('Required'),
        game_type: Yup.string().required('Required'),
        // match_players: Yup.string().required('Required'),
        venue_id: Yup.number().required('Required'),
        scheduled_time: Yup.string().required('Required'),
    });

    const validator = (values, props) => {
        console.log(values);
        console.log(props);
        const errors = {};

        if (!values.name) {
            errors.name = 'Required';
        }

        return errors;
    };

    async function queryVenues(venueName) {
        let query = await client.query({
            query: SEARCH_VENUES_QUERY,
            variables: {
                name: `%${venueName}%`,
            },
        });

        let dataSource = query.data.venues;

        setVenueDataSource(dataSource);
    }

    function onSearchVenue(name) {
        if (name && name.length > 2) {
            queryVenues(name);
        }
    }

    function onSelectVenue(value, option) {
        console.log({
            value,
            option,
        });
        const venue = venueDataSource.filter((venue) => {
            return venue.id === parseInt(value);
        })[0];
        formRef.current.setValues({ ...formRef.current.state.values, venue_id: parseInt(value), venue_name: venue.name });
    }

    function handleFormFieldUpdates(event) {
        const caret = event.target.selectionStart;
        const element = event.target;
        window.requestAnimationFrame(() => {
            element.selectionStart = caret;
            element.selectionEnd = caret;
        });
    }

    const renderForm = (match) => {
        const initialValues = selectedMatchId
            ? {
                  ...match,
                  venue_name: match && match.venue && match.venue.name,
                  match_players:
                      match &&
                      match.match_players &&
                      match.match_players.map((player) => {
                          return player.player_id;
                      }),
              }
            : {
                  division_id: divisionID ? divisionID : undefined,
                  match_type: undefined,
                  game_type: undefined,
                  venue_id: undefined,
                  venue_name: undefined,
                  scoring_format: '10_POINT_SYSTEM',
                  player_id: undefined,
                  player_race_to: '',
                  opponent_id: undefined,
                  opponent_race_to: '',
                  table_size: '8 Foot',
                  table_id: undefined,
                  scheduled_time: '',
              };

        return (
            <Formik
                ref={formRef}
                onSubmit={(data, actions) => {
                    // console.log(data);
                    // const merged = {
                    // 	...data,
                    // 	...selected
                    // };
                    if (selectedMatchId) {
                        submitUpdate(data, match);
                    } else {
                        submitAdd(data, actions);
                    }
                }}
                validationSchema={schema}
                enableReinitialize
                initialValues={initialValues}
                render={({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                    <Modal
                        title={selectedMatchId ? 'Update Match' : 'Create Match'}
                        // getContainer={() => document.querySelector('.tables')}
                        visible={modalVisible}
                        onOk={onModalOk}
                        onCancel={onModalCancel}
                        centered
                        transitionName="fade"
                        // transitionName="none"
                        maskTransitionName="none"
                        footer={[
                            <Button key="cancel" onClick={onModalCancel}>
                                Cancel
                            </Button>,
                            <Button
                                key="submit"
                                disabled={Object.keys(errors).length > 0 ? true : false}
                                type={Object.keys(errors).length > 0 ? 'danger' : 'primary'}
                                onClick={() => {
                                    formRef.current.handleSubmit();
                                }}
                            >
                                {selectedMatchId ? (loading ? 'Updating...' : 'Update Match') : 'Add Match'}
                            </Button>,
                        ]}
                        bodyStyle={{ maxHeight: 500, overflowY: 'auto', padding: '10px 20px' }}
                        destroyOnClose={true}
                    >
                        <Form
                            onSubmit={handleSubmit}
                            layout="vertical"
                            style={{ maxWidth: 600 }}
                            // onSubmit={(e) => {
                            // 	e.preventDefault();
                            // 	console.log(values);
                            // 	// handleSubmit(values);
                            // }}
                        >
                            <FormItem label="Name" name="name" autoFocus>
                                <Input
                                    name="name"
                                    placeholder="Name"
                                    size="large"
                                    onChange={(event) => {
                                        handleFormFieldUpdates(event);
                                    }}
                                />
                            </FormItem>
                            {league.has_division_support === true && (
                                <FormItem label="Division" name="division_id" required hasFeedback showValidateSuccess>
                                    <Query
                                        query={GET_LEAGUE_DIVISIONS_QUERY}
                                        fetchPolicy="cache-and-network"
                                        variables={{
                                            leagueId: leagueID,
                                        }}
                                        notifyOnNetworkStatusChange={true}
                                    >
                                        {({ loading, error, data }) => {
                                            if (error) return <div>Error: {error.message}</div>;

                                            return (
                                                <Select
                                                    showSearch
                                                    name="division_id"
                                                    placeholder="Select division"
                                                    size="large"
                                                    loading={loading}
                                                    disabled={divisionID ? true : false}
                                                    // defaultValue={[ 'jack', 'lucy' ]}
                                                    optionFilterProp="children"
                                                    optionLabelProp="children"
                                                    filterOption={(inputValue, option) => {
                                                        return option.props.name.toString().toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
                                                    }}
                                                >
                                                    {data &&
                                                        data.league_divisions &&
                                                        data.league_divisions.map((item, index) => (
                                                            <Option key={index} name={item.name} value={item.id}>
                                                                <Avatar
                                                                    size={24}
                                                                    src={getGameType(item.game_types && item.game_types[0].game_type, theme)}
                                                                    style={{
                                                                        display: 'inline-block',
                                                                        marginRight: 8,
                                                                    }}
                                                                />
                                                                <Text>{item.name}</Text>
                                                            </Option>
                                                        ))}
                                                </Select>
                                            );
                                        }}
                                    </Query>
                                </FormItem>
                            )}

                            <FormItem label="Match Type" name="match_type" required hasFeedback showValidateSuccess>
                                <Query query={GET_DIVISION_TYPES_QUERY} fetchPolicy="cache-and-network" notifyOnNetworkStatusChange={true}>
                                    {({ loading, error, data }) => {
                                        if (error) return <div>Error: {error.message}</div>;

                                        return (
                                            <Select
                                                showSearch
                                                name="match_type"
                                                placeholder="Select a match type"
                                                size="large"
                                                loading={loading}
                                                optionFilterProp="children"
                                                optionLabelProp="children"
                                                filterOption={(inputValue, option) => {
                                                    return option.props.value.toString().toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
                                                }}
                                            >
                                                {data &&
                                                    data.league_division_types &&
                                                    data.league_division_types.map((item, index) => (
                                                        <Option key={index} value={item.value}>
                                                            <Text>{_.startCase(_.toLower(item.value.replace('_', ' ')))}</Text>
                                                        </Option>
                                                    ))}
                                            </Select>
                                        );
                                    }}
                                </Query>
                            </FormItem>
                            <FormItem label="Game Type" name="game_type" required hasFeedback showValidateSuccess>
                                <Query query={GET_GAME_TYPES_QUERY} fetchPolicy="cache-and-network" notifyOnNetworkStatusChange={true}>
                                    {({ loading, error, data }) => {
                                        if (loading) return <div>Loading...</div>;
                                        if (error) return <div>Error: {error.message}</div>;

                                        return (
                                            <Select
                                                showSearch
                                                name="game_type"
                                                placeholder="Select game to be played"
                                                size="large"
                                                optionFilterProp="children"
                                                optionLabelProp="children"
                                                filterOption={(inputValue, option) => {
                                                    return option.props.value.toString().toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
                                                }}
                                            >
                                                {data &&
                                                    data.game_types &&
                                                    data.game_types
                                                        .sort((a, b) => a.value.localeCompare(b.value))
                                                        .map((item, index) => (
                                                            <Option key={index} value={item.value}>
                                                                <Avatar
                                                                    size={24}
                                                                    src={getGameType(item.value, theme)}
                                                                    style={{
                                                                        display: 'inline-block',
                                                                        marginRight: 8,
                                                                    }}
                                                                />
                                                                <Text>{_.startCase(_.toLower(item.value.replace('_', ' ')))}</Text>
                                                            </Option>
                                                        ))}
                                            </Select>
                                        );
                                    }}
                                </Query>
                            </FormItem>

                            <FormItem label="Scoring format" name="scoring_format" hasFeedback showValidateSuccess style={{ flex: 1 }}>
                                <Select name="scoring_format" defaultValue="10_POINT_SYSTEM" size="large">
                                    <Option value="10_POINT_SYSTEM">10 point system</Option>
                                    <Option value="RACE_TO">Race to number</Option>
                                    <Option value="BEST_OF">Best of number</Option>
                                </Select>
                            </FormItem>

                            {values.scoring_format === 'BEST_OF' ? (
                                <FormItem label="Best of number" name="best_of_num" hasFeedback showValidateSuccess>
                                    <InputNumber name="best_of_num" min={0} placeholder="0" size="large" />
                                </FormItem>
                            ) : values.scoring_format === 'RACE_TO' ? (
                                <FormItem label="Race to number" name="race_to_num" hasFeedback showValidateSuccess>
                                    <InputNumber name="race_to_num" min={0} placeholder="0" size="large" />
                                </FormItem>
                            ) : null}

                            {/* <FormItem label="Winner determined by" name="winner_determined_by" hasFeedback showValidateSuccess style={{ flex: 1 }}>
                                <Select
                                    name="winner_determined_by"
                                    defaultValue={values.scoring_format === '10_POINT_SYSTEM' ? 'POINTS_WON' : 'GAMES_WON'}
                                    size="large"
                                >
                                    <Option value="GAMES_WON">Games won</Option>
                                    <Option value="FRAMES_WON">Frames won</Option>
                                    <Option value="POINTS_WON">Points won</Option>
                                    <Option value="SETS_WON">Sets won</Option>
                                </Select>
                            </FormItem> */}

                            {/* <FormItem label="Players" name="league_match_players" required hasFeedback showValidateSuccess>
							<Query
								query={GET_LEAGUE_DIVISION_PLAYERS_QUERY}
								fetchPolicy="cache-and-network"
								notifyOnNetworkStatusChange
								awaitRefetchQueries
								variables={{ league_id: leagueID }}
								onCompleted={(data) => {
									console.log(data);
									setPlayers(data.league_players);
								}}
							>
								{({ loading, error, data }) => {
									if (error) return <div>Error: {error.message}</div>;

									return (
										<Select
											showSearch
											mode="multiple"
											name="league_match_players"
											loading={loading}
											placeholder="Select match players"
											size="large"
											optionFilterProp="children"
											optionLabelProp="children"
											filterOption={(inputValue, option) => {
												return (
													option.props.value
														.toString()
														.toLowerCase()
														.indexOf(inputValue.toLowerCase()) >= 0
												);
											}}
										>
											{data &&
												data.league_players &&
												data.league_players
													.sort((a, b) => a.name.localeCompare(b.name))
													.map((player, index) => {
														const countryName =
															player.country &&
															Object.entries(isoCountries)
																.map(([ key, value ]) => {
																	return {
																		name: key,
																		value: value.toLowerCase()
																	};
																})
																.filter((item, index) => {
																	const country = player.country.toLowerCase();

																	return (
																		item.value === country || item.name === country
																	);
																});

														const country =
															countryName && countryName[0] ? countryName[0].value : null;
														return (
															<Option key={index} name={player.name} value={player.id}>
																{player.avatarImg ? (
																	<React.Fragment>
																		<Avatar
																			size={24}
																			src={player.avatarImg}
																			style={{
																				display: 'inline-block',
																				marginRight: 8
																			}}
																		/>
																		<Text style={{ marginLeft: 10 }}>
																			{player.name}{' '}
																			{player.skill_level && `(${player.skill_level})`}
																		</Text>
																	</React.Fragment>
																) : country ? (
																	<React.Fragment>
																		<Flag
																			name={country}
																			format="png"
																			pngSize={24}
																			shiny={false}
																			alt="Canada Flag"
																			className="flag"
																			basePath="/images/flags"
																		/>
																		<Text style={{ marginLeft: 10 }}>
																			{player.name}{' '}
																			{player.skill_level && `(${player.skill_level})`}
																		</Text>
																	</React.Fragment>
																) : (
																	<Text>
																		{player.name}{' '}
																		{player.skill_level && `(${player.skill_level})`}
																	</Text>
																)}
															</Option>
														);
													})}
										</Select>
									);
								}}
							</Query>
						</FormItem> */}

                            <FormItem label="Player" name="player_id" required hasFeedback showValidateSuccess>
                                <Query
                                    query={GET_LEAGUE_PLAYERS_QUERY}
                                    fetchPolicy="cache-and-network"
                                    notifyOnNetworkStatusChange
                                    awaitRefetchQueries
                                    variables={{ league_id: leagueID }}
                                    onCompleted={(data) => {
                                        // console.log(data);
                                        setPlayers(data.league_players);
                                    }}
                                >
                                    {({ loading, error, data }) => {
                                        if (error) return <div>Error: {error.message}</div>;

                                        return (
                                            <Select
                                                showSearch
                                                // mode="multiple"
                                                name="player_id"
                                                loading={loading}
                                                placeholder="Select player"
                                                size="large"
                                                optionFilterProp="children"
                                                optionLabelProp="children"
                                                filterOption={(input, option) => {
                                                    return (
                                                        option &&
                                                        option.props &&
                                                        option.props.children &&
                                                        option.props.children.props &&
                                                        option.props.children.props.children &&
                                                        option.props.children.props.children[1] &&
                                                        option.props.children.props.children[1].toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    );
                                                }}
                                            >
                                                {data &&
                                                    data.league_players &&
                                                    data.league_players
                                                        .sort((a, b) => a.name.localeCompare(b.name))
                                                        .map((player, index) => {
                                                            return (
                                                                <Option key={index} name={player.name} value={player.player_id || player.id}>
                                                                    {player.avatarImg ? (
                                                                        <span style={{ display: 'flex', alignItems: 'center' }}>
                                                                            <Avatar
                                                                                size={24}
                                                                                src={player.avatarImg}
                                                                                style={{
                                                                                    display: 'inline-block',
                                                                                    marginRight: 8,
                                                                                }}
                                                                            />
                                                                            {player.name} {player.skill_level && `(${player.skill_level})`}
                                                                        </span>
                                                                    ) : player.country ? (
                                                                        <span style={{ display: 'flex', alignItems: 'center' }}>
                                                                            <CircleFlag country={player.country} left={0} />
                                                                            {player.name} {player.skill_level && `(${player.skill_level})`}
                                                                        </span>
                                                                    ) : (
                                                                        <span style={{ display: 'flex', alignItems: 'center' }}>
                                                                            <span />
                                                                            {player.name} {player.skill_level && `(${player.skill_level})`}
                                                                        </span>
                                                                    )}
                                                                </Option>
                                                            );
                                                        })}
                                            </Select>
                                        );
                                    }}
                                </Query>
                            </FormItem>

                            <FormItem label="Player race to" name="plaayer_race_to" hasFeedback showValidateSuccess>
                                <InputNumber
                                    name="player_race_to"
                                    placeholder="0"
                                    min={0}
                                    // value={score}
                                    // onChange={onChange}
                                    // onFocus={handleFocus}
                                    // onBlur={handleBlur}
                                    // style={styles.inputSmall}
                                    // onPressEnter={onEnter}
                                    size="large"
                                    // disabled
                                />
                            </FormItem>

                            <FormItem label="Opponent" name="opponent_id" required hasFeedback showValidateSuccess>
                                <Query
                                    query={GET_LEAGUE_PLAYERS_QUERY}
                                    fetchPolicy="cache-and-network"
                                    notifyOnNetworkStatusChange
                                    awaitRefetchQueries
                                    variables={{ league_id: leagueID }}
                                    onCompleted={(data) => {
                                        // console.log(data);
                                        setPlayers(data.league_players);
                                    }}
                                >
                                    {({ loading, error, data }) => {
                                        if (error) return <div>Error: {error.message}</div>;

                                        return (
                                            <Select
                                                showSearch
                                                // mode="multiple"
                                                name="opponent_id"
                                                loading={loading}
                                                placeholder="Select opponent"
                                                size="large"
                                                optionFilterProp="children"
                                                optionLabelProp="children"
                                                filterOption={(input, option) => {
                                                    return (
                                                        option &&
                                                        option.props &&
                                                        option.props.children &&
                                                        option.props.children.props &&
                                                        option.props.children.props.children &&
                                                        option.props.children.props.children[1] &&
                                                        option.props.children.props.children[1].toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    );
                                                }}
                                            >
                                                {data &&
                                                    data.league_players &&
                                                    data.league_players
                                                        .sort((a, b) => a.name.localeCompare(b.name))
                                                        .map((player, index) => {
                                                            return (
                                                                <Option key={index} name={player.name} value={player.player_id || player.id}>
                                                                    {player.avatarImg ? (
                                                                        <span style={{ display: 'flex', alignItems: 'center' }}>
                                                                            <Avatar
                                                                                size={24}
                                                                                src={player.avatarImg}
                                                                                style={{
                                                                                    display: 'inline-block',
                                                                                    marginRight: 8,
                                                                                }}
                                                                            />
                                                                            {player.name} {player.skill_level && `(${player.skill_level})`}
                                                                        </span>
                                                                    ) : player.country ? (
                                                                        <span style={{ display: 'flex', alignItems: 'center' }}>
                                                                            <CircleFlag country={player.country} left={0} />
                                                                            {player.name} {player.skill_level && `(${player.skill_level})`}
                                                                        </span>
                                                                    ) : (
                                                                        <span style={{ display: 'flex', alignItems: 'center' }}>
                                                                            {player.name} {player.skill_level && `(${player.skill_level})`}
                                                                        </span>
                                                                    )}
                                                                </Option>
                                                            );
                                                        })}
                                            </Select>
                                        );
                                    }}
                                </Query>
                            </FormItem>

                            <FormItem label="Opponent race to" name="opponent_race_to" hasFeedback showValidateSuccess>
                                <InputNumber
                                    name="opponent_race_to"
                                    placeholder="0"
                                    min={0}
                                    // value={score}
                                    // onChange={onChange}
                                    // onFocus={handleFocus}
                                    // onBlur={handleBlur}
                                    // style={styles.inputSmall}
                                    // onPressEnter={onEnter}
                                    size="large"
                                    // disabled
                                />
                            </FormItem>

                            <FormItem label="Venue" name="venue_id" required hasFeedback showValidateSuccess>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <AutoComplete
                                        name="venue_name"
                                        dataSource={venueDataSource}
                                        placeholder="Search for a venue"
                                        onSearch={onSearchVenue}
                                        onSelect={onSelectVenue}
                                        size="large"
                                        filterOption={(inputValue, option) => {
                                            return (
                                                option.props &&
                                                option.props.children &&
                                                option.props.children.toString().toLowerCase().indexOf(inputValue.toString().toLowerCase()) >= 0
                                            );
                                        }}
                                        dropdownRender={(menu) => (
                                            <div>
                                                {menu}
                                                <Divider style={{ margin: '4px 0' }} />
                                                <div
                                                    style={{
                                                        padding: '4px 8px',
                                                        cursor: 'pointer',
                                                    }}
                                                    onMouseDown={(e) => e.preventDefault()}
                                                    onClick={handleAddVenue}
                                                >
                                                    <Icon type="plus" /> Add Venue
                                                </div>
                                            </div>
                                        )}
                                    >
                                        {venueDataSource &&
                                            venueDataSource.map((item, index) => (
                                                <Option key={index} value={`${item.id}`}>
                                                    {item.name} - {item.city}, {item.region}
                                                </Option>
                                            ))}
                                    </AutoComplete>
                                    <Button type="primary" ghost size="large" onClick={handleAddVenue} style={{ marginLeft: 10 }}>
                                        <Icon type="plus" /> Add Venue
                                    </Button>
                                </div>
                            </FormItem>

                            {values.venue_id && (
                                <FormItem label="Table" name="pool_table_id" hasFeedback showValidateSuccess>
                                    <Query
                                        query={GET_VENUE_TABLES_QUERY}
                                        fetchPolicy="cache-and-network"
                                        notifyOnNetworkStatusChange={true}
                                        variables={{
                                            venueId: values.venue_id,
                                        }}
                                        onCompleted={(data) => {
                                            console.log(data);
                                            setTables(data.pool_tables);
                                        }}
                                    >
                                        {({ loading, error, data }) => {
                                            if (loading) return <div>Loading...</div>;
                                            if (error) return <div>Error: {error.message}</div>;

                                            return (
                                                <Select
                                                    showSearch
                                                    // mode="multiple"
                                                    name="pool_table_id"
                                                    placeholder="Assign a table from the selected venue"
                                                    size="large"
                                                    optionFilterProp="children"
                                                    // defaultValue={[ 'jack', 'lucy' ]}
                                                    filterOption={(input, option) => {
                                                        return option.props.children.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                                    }}
                                                >
                                                    {data.pool_tables.map((item, index) => (
                                                        <Option key={index} value={item.id}>
                                                            <Text>{item.label}</Text>
                                                        </Option>
                                                    ))}
                                                </Select>
                                            );
                                        }}
                                    </Query>
                                </FormItem>
                            )}

                            <FormItem label="Scheduled date and time" name="scheduled_time" required hasFeedback showValidateSuccess>
                                <DatePicker
                                    showTime
                                    name="scheduled_time"
                                    size="large"
                                    format="YYYY-MM-DD hh:mm A z"
                                    // onChange={onDateChange}
                                    onOk={onOk}
                                />
                            </FormItem>
                            <AddVenueModal {...props} modalVisible={venueModalVisible} onModalOk={handleVenueModalOk} onModalCancel={handleVenueModalCancel} />
                        </Form>
                    </Modal>
                )}
            />
        );
    };

    return (
        <React.Fragment>
            {selectedMatchId ? (
                <Query
                    query={GET_LEAGUE_MATCH_QUERY}
                    fetchPolicy="cache-and-network"
                    notifyOnNetworkStatusChange={true}
                    variables={{ match_id: selectedMatchId, league_id: leagueID }}
                    onCompleted={(data) => {
                        console.log(data);
                    }}
                >
                    {({ loading, error, data }) => {
                        // if (loading) return <CircularLoader />;
                        // if (error) return <div>Error: {error.message}</div>;
                        const match = data && data.matches && data.matches.length > 0 ? data.matches[0] : null;
                        return <React.Fragment>{renderForm(match)}</React.Fragment>;
                    }}
                </Query>
            ) : (
                <React.Fragment>{renderForm()}</React.Fragment>
            )}
        </React.Fragment>
    );
}

export default withRouter(AddMatchModal);
