import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Form, FormItem, Input, Radio, Select, SubmitButton, ResetButton } from 'formik-antd';
import { Formik, ErrorMessage } from 'formik';
import { message, Typography, Button } from 'antd';
import * as Yup from 'yup';
import { Mutation } from '@apollo/client/react/components';
import { useMutation } from '@apollo/react-hooks';
import { CountryRegionData } from 'react-country-region-selector';
import { CREATE_ORGANIZATION_MUTATION, UPDATE_ORGANIZATION_MUTATION } from './data/mutations';
import slugify from 'slugify';

const { TextArea } = Input;
const { Text } = Typography;
const { Option } = Select;

const styles = {
    error: { color: '#f5222d', marginTop: 10 },
};

function CreateOrganizationForm(props) {
    const { organization, onCreated, onSkipStep } = props;
    const [loading, setLoading] = useState(false);
    const [createOrganization, { createLoading, createError }] = useMutation(CREATE_ORGANIZATION_MUTATION);
    const [updateOrganization, { updateLoading, updateError }] = useMutation(UPDATE_ORGANIZATION_MUTATION);

    const showLoading = () => {
        const hide = message.loading('Action in progress..', 0);
        // Dismiss manually and asynchronously
        setTimeout(hide, 2500);
    };

    // const SignupSchema = Yup.object().shape({
    //     name: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required'),
    //     venue_id: Yup.string().required('Required'),
    //     winners_race_to: Yup.number().nullable().required('Required'),
    //     // losers_race_to: Yup.number().nullable().required('Required'),
    //     player_type: Yup.string().required('Required'),
    //     game_type: Yup.string().required('Required'),
    //     tournament_type: Yup.string().required('Required'),
    //     start_date_time: Yup.date().nullable().required('Required'),
    //     end_date_time: Yup.date().nullable().required('Required'),
    //     max_players: Yup.number().required('Required'),
    // });

    const getRegions = (country) => {
        if (!country) {
            return [];
        }
        if (typeof country === 'string') {
            const regions = CountryRegionData.filter((item) => {
                return item[1] === country;
            })[0];
            country = regions;
        }
        const selectedRegion = country[2].split('|').map((regionPair) => {
            let [regionName, regionShortCode = null] = regionPair.split('~');
            return regionName;
        });
        return selectedRegion;
    };

    function handleSubmit(values, actions) {
        const { name, description, address1, address2, city, country, region, postal_code, phone_number, fax_number, email_address, website } = values;
        setLoading(true);
        const slug = slugify(name, {
            replacement: '-',
            remove: /[*+~.,()'"#!:/@]/g,
            lower: true,
        });

        if (organization) {
            updateOrganization({
                variables: {
                    id: organization.id,
                    changes: {
                        name,
                        slug,
                        description,
                        address1,
                        address2,
                        city,
                        country,
                        region,
                        postal_code,
                        phone_number,
                        fax_number,
                        email_address,
                        website,
                    },
                },
                // refetchQueries: () => [
                // 	{
                // 		query: GET_organization_DIVISION_QUERY,
                // 		variables: {
                // 			organizationId: props.data.id,
                // 			divisionSlug: slug
                // 		},
                // 		awaitRefetchQueries: true
                // 	}
                // ]
            })
                .then((res) => {
                    actions.setSubmitting(false);
                    // actions.resetForm();
                    setLoading(false);
                    message.success('Changes saved');
                    // const result = res.data.update_organizations.returning[0];
                    // props.history.push(`/organizations/${result.slug}`);
                })
                .catch((e) => {
                    actions.setSubmitting(false);
                    message.error((e && e.message) || JSON.stringify(e));
                    console.log(e);
                });
        } else {
            createOrganization({
                variables: {
                    objects: [
                        {
                            name,
                            slug,
                            description,
                            address1,
                            address2,
                            city,
                            country,
                            region,
                            postal_code,
                            phone_number,
                            fax_number,
                            email_address,
                            website,
                        },
                    ],
                },
            })
                .then((res) => {
                    actions.setSubmitting(false);
                    actions.resetForm();
                    console.log(res);
                    setLoading(false);
                    const result = res.data.insert_organizations.returning[0];
                    message.success('organization created');
                    if (onCreated) {
                        onCreated(result);
                    }
                    // props.history.push(`/organizations/${result.slug}`);
                })
                .catch((e) => {
                    actions.setSubmitting(false);
                    message.error((e && e.message) || JSON.stringify(e));
                    console.log(e);
                });
        }
    }

    return (
        <Formik
            initialValues={{
                name: '',
                description: '',
                address1: '',
                address2: '',
                city: '',
                country: undefined,
                region: undefined,
                postal_code: '',
                phone_number: '',
                fax_number: '',
                email_address: '',
                website: '',
            }}
            // validationSchema={SignupSchema}
            onSubmit={(values, actions) => {
                console.log(values);
                handleSubmit(values, actions);
            }}
            render={({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                <Form onSubmit={handleSubmit} layout="vertical" style={{ maxWidth: 400 }}>
                    <FormItem label="Organization Name" name="name">
                        <Input name="name" placeholder="Name" size="large" />
                    </FormItem>
                    <FormItem label="Description" name="description">
                        <TextArea rows={4} name="description" placeholder="Description" size="large" />
                    </FormItem>
                    <FormItem label="Address 1" name="address1">
                        <Input name="address1" placeholder="Address 1" size="large" />
                    </FormItem>
                    <FormItem label="Address 2" name="address2">
                        <Input name="address2" placeholder="Address 2" size="large" />
                    </FormItem>
                    <FormItem label="City" name="city">
                        <Input name="city" placeholder="City" size="large" />
                    </FormItem>

                    <FormItem label="Country" name="country">
                        <Select
                            showSearch
                            name="country"
                            placeholder="Please select a country"
                            optionFilterProp="children"
                            size="large"
                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        >
                            {CountryRegionData.map((option, index) => {
                                return (
                                    <Option value={option[1]} key={index}>
                                        {option[0]}
                                    </Option>
                                );
                            })}
                        </Select>
                    </FormItem>
                    <FormItem label="Region" name="region">
                        {/* <Input name="region" placeholder="Select State/Province/Region" size="large" /> */}
                        <Select
                            showSearch
                            name="region"
                            placeholder="Please select a state/province/region"
                            size="large"
                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        >
                            {getRegions(values.country).map((option, index) => (
                                <Option value={option} key={index}>
                                    {option}
                                </Option>
                            ))}
                        </Select>
                    </FormItem>
                    <FormItem label="Postal Code" name="postal_code">
                        <Input name="postal_code" placeholder="Postal Code" size="large" />
                    </FormItem>
                    <FormItem label="Phone Number" name="phone_number">
                        <Input name="phone_number" placeholder="Phone Number" size="large" />
                    </FormItem>
                    <FormItem label="Fax Number" name="fax_number">
                        <Input name="fax_number" placeholder="Fax Number" size="large" />
                    </FormItem>
                    <FormItem label="Email Address" name="email_address">
                        <Input name="email_address" placeholder="Email Address" size="large" />
                    </FormItem>
                    <FormItem label="Website" name="website">
                        <Input name="website" placeholder="Website" size="large" />
                    </FormItem>

                    <SubmitButton
                        size="large"
                        disabled={Object.keys(errors).length > 0 ? true : false}
                        type={Object.keys(errors).length > 0 ? 'danger' : 'primary'}
                        loading={loading}
                    >
                        Create Organization
                    </SubmitButton>
                    <Button type="default" size="large" style={{ marginLeft: 10 }} onClick={onSkipStep}>
                        Skip step
                    </Button>
                    <div>
                        <Text style={styles.error}>{errors.general}</Text>
                    </div>
                    <div>
                        <Text style={{ color: '#f5222d', marginTop: 10 }}>{errors.general}</Text>
                    </div>
                </Form>
            )}
        />
    );
}

export default withRouter(CreateOrganizationForm);
