import React, { useState, useRef, useEffect } from 'react';
import { message, Table, Typography, Button, Icon, Modal, Input, Tooltip } from 'antd';
import CircleFlag from '../../components/CircleFlag';
import { isoCountries } from '../../utils/isoCountryCodes';
import _ from 'lodash';

import { SendOutlined } from '@ant-design/icons';
import moment from 'moment-timezone';
import momentDurationFormatSetup from 'moment-duration-format';
momentDurationFormatSetup(moment);

const { Text } = Typography;
const { confirm } = Modal;

export default function NotificationsTable(props) {
    // const { tournament } = props;
    const { onSelected, onResend, onEditSelected, selectedRowKeys, onDelete, size, theme } = props;
    const [selectedKeys, setSelectedKeys] = useState(selectedRowKeys || []);

    var zone_name = moment.tz.guess();
    var timezone = moment.tz(zone_name).zoneAbbr();

    useEffect(() => {
        setSelectedKeys(selectedRowKeys);
    }, [selectedRowKeys]);

    function onSelectChange(selectedRowKeys, selectedRows) {
        // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        setSelectedKeys(selectedRowKeys);
        onSelected(selectedRowKeys, selectedRows);
    }

    const rowSelection = {
        selectedRowKeys: selectedKeys,
        onChange: onSelectChange,
        getCheckboxProps: (record) => ({
            name: record.name,
        }),
    };

    const columns = [
        // {
        //     title: '#',
        //     dataIndex: 'index',

        //     width: 80,
        //     sorter: (a, b) => {
        //         return a.index - b.index;
        //     },
        // },

        {
            title: 'Match',
            dataIndex: 'match_number',
            width: 110,
            sorter: (a, b) => {
                return a.match_number - b.match_number;
            },
            render: (text, record) => <React.Fragment>{text || '-'}</React.Fragment>,
        },

        {
            title: 'Player Name',
            dataIndex: 'player_name',

            sorter: (a, b) => {
                return a.player_name && a.player_name.localeCompare(b.player_name);
            },
            render: (text, record) => <React.Fragment>{text || '-'}</React.Fragment>,
        },
        {
            title: 'Phone Number',
            dataIndex: 'player_phone_number',
            sorter: (a, b) => {
                return a.phone_number && a.phone_number.localeCompare(b.phone_number);
            },
            render: (text, record) => <React.Fragment>{text || '-'}</React.Fragment>,
        },

        {
            title: 'Message',
            dataIndex: 'message',
            sorter: (a, b) => {
                return a.message && a.message.localeCompare(b.message);
            },
            render: (text, record) => <div>{text || '-'}</div>,
        },
        {
            title: 'Message Status',
            dataIndex: 'message_status',
            sorter: (a, b) => {
                return a.message_status && a.message_status.localeCompare(b.message_status);
            },
            render: (text, record) => (
                <div
                    style={{
                        color:
                            text && text === 'accepted'
                                ? '#35b37f'
                                : text === 'delivered'
                                ? '#198fff'
                                : text === 'failed' || text === 'rejected'
                                ? '#f5212d'
                                : null,
                    }}
                >
                    {text ? _.capitalize(text) : 'Sent'}
                </div>
            ),
        },
        // {
        //     title: 'Error',
        //     dataIndex: 'message_error',
        //     sorter: (a, b) => {
        //         return a.message_error && a.message_error.localeCompare(b.message_error);
        //     },
        //     render: (text, record) => (
        //         <div>{record.message_status && (record.message_status === 'failed' || record.message_status === 'rejected') ? record.message_error : '-'}</div>
        //     ),
        // },
        {
            title: 'Message ID',
            dataIndex: 'message_id',

            sorter: (a, b) => {
                return a.message_id && a.message_id.localeCompare(b.message_id);
            },
            render: (text, record) => <React.Fragment>{text || '-'}</React.Fragment>,
        },
        {
            title: 'Sent At',
            dataIndex: 'message_sent_at',
            defaultSortOrder: 'descend',
            sorter: (a, b) => {
                return a.message_sent_at && a.message_sent_at.localeCompare(b.message_sent_at);
            },
            render: (text, record) => <div style={{ whiteSpace: 'nowrap' }}>{text ? `${moment(text).format('MM-DD-YYYY hh:mm A')} ${timezone}` : '-'}</div>,
        },
        {
            title: 'Delivered At',
            dataIndex: 'message_delivered_at',
            sorter: (a, b) => {
                return a.message_delivered_at && a.message_delivered_at.localeCompare(b.message_delivered_at);
            },
            render: (text, record) => <div style={{ whiteSpace: 'nowrap' }}>{text ? `${moment(text).format('MM-DD-YYYY hh:mm A')} ${timezone}` : '-'}</div>,
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <span style={{ whiteSpace: 'nowrap' }}>
                    <Tooltip title="Send again">
                        <Button
                            type="ghost"
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();

                                onResend(record);

                                // onEditSelected(record.id);
                                // props.history.push(
                                // 	`/tournament-builder/${props.tournament.slug}/venues/${record.slug}/edit`
                                // );
                            }}
                        >
                            <SendOutlined />
                        </Button>
                    </Tooltip>
                    {/* <Divider type="vertical" /> */}
                    <Tooltip title="Delete">
                        <Button
                            type="ghost"
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                showConfirm(record);
                            }}
                        >
                            <Icon type="delete" />
                        </Button>
                    </Tooltip>
                </span>
            ),
        },
    ];

    function onChange(pagination, filters, sorter) {
        console.log('params', pagination, filters, sorter);
    }

    function showConfirm(record) {
        console.log(record);
        confirm({
            title: `Are you sure you want to remove ID:${record.id}?`,
            content: 'Note: This action cannot be undone.',
            centered: true,
            transitionName: 'fade',
            maskTransitionName: 'none',
            // onOk() {
            // 	return new Promise((resolve, reject) => {
            // 		setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
            // 	}).catch(() => console.log('Oops errors!'));
            // },
            onOk() {
                onDelete(record);
            },
            onCancel() {},
        });
    }

    return (
        <Table
            className="player-table"
            // rowSelection={rowSelection}
            // onRow={(record, rowIndex) => {
            // 	return {
            // 		onClick: (event) => {
            // 			// console.log(record, rowIndex);
            // 			props.history.push({
            // 				pathname: `/tournament-builder/${props.tournament.slug}/players/${record.name}`,
            // 				// search: '?query=abc',
            // 				state: {
            // 					data: record,
            // 					backPath: `/tournament-builder/${props.tournament.slug}/players`
            // 				}
            // 			});
            // 		},
            // 		onDoubleClick: (event) => {}, // double click row
            // 		onContextMenu: (event) => {}, // right button click row
            // 		onMouseEnter: (event) => {}, // mouse enter row
            // 		onMouseLeave: (event) => {} // mouse leave row
            // 	};
            // }}
            columns={columns}
            dataSource={props.notifications}
            onChange={onChange}
            pagination={false}
            tableLayout="auto"
            // className="noselect"
            scroll={{ x: 400 }}
            // pagination={{ pageSize: 25 }}
            size={size ? size : 'default'}
            // bordered
            onRow={(record, rowIndex) => {
                return {
                    onClick: (event) => {
                        // console.log("E", record);
                        // setCurrentMatch(record);
                        // handleModal();
                    }, // click row
                    onDoubleClick: (event) => {}, // double click row
                    onContextMenu: (event) => {}, // right button click row
                    onMouseEnter: (event) => {}, // mouse enter row
                    onMouseLeave: (event) => {}, // mouse leave row
                    style: {
                        backgroundColor:
                            record.message_status && record.message_status === 'accepted'
                                ? theme && theme.name === 'dark'
                                    ? '#2b654c'
                                    : '#dafbed'
                                : record.message_status === 'delivered'
                                ? theme && theme.name === 'dark'
                                    ? '#144d67'
                                    : '#d5f2ff'
                                : record.message_status === 'failed' || record.message_status === 'rejected'
                                ? theme && theme.name === 'dark'
                                    ? '#551b1e'
                                    : '#ffe9ea'
                                : theme && theme.name === 'dark'
                                ? 'transparent'
                                : '#fff',
                    },
                };
            }}
        />
    );
}
