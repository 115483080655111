import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { message, Progress, Steps, Tabs, Modal, Descriptions, Row, Avatar, List, Divider, Skeleton, Button, Empty, Icon, Col, Typography } from 'antd';
import { Link } from 'react-router-dom';
import SectionHeader from '../../components/SectionHeader';
import SectionContent from '../../components/SectionContent';
import SectionWrapper from '../../components/SectionWrapper';
import SectionFooter from '../../components/SectionFooter';
import ZeroState from '../../components/ZeroState';
import { getGameType, displayEnumType, getCountryName, formatPlayerName, getOrdinalSuffix } from '../../utils';
import CircularLoader from '../../components/CircularLoader';
import CircleFlag from '../../components/CircleFlag';
import Card, { Meta } from '../../components/Card';
import { Query } from '@apollo/client/react/components';
import { GET_CHALLENGE_COMMENTS, GET_LEAGUE_CHALLENGES_QUERY, GET_LEAGUE_CHALLENGE_BY_SLUG_QUERY } from './data/queries';
import { DELETE_LEAGUE_CHALLENGE, DELETE_MATCH_MUTATION } from './data/mutations';
import useBreakpoint from 'use-breakpoint';
import { defaultBreakpoints } from '../../utils/defaultBreakpoints';
import TableScoreCard from '../digitalpool/tournaments/TableScoreCard';
import AddChallengeModal from './AddChallengeModal';
import ChallengeNestedComment from './ChallengeNestedComment';
import ChallengeCommentForm from './ChallengeCommentForm';
import { useMutation, useQuery } from '@apollo/react-hooks';
import slugify from 'slugify';
import _ from 'lodash';
import moment from 'moment-timezone';
import momentDurationFormatSetup from 'moment-duration-format';
momentDurationFormatSetup(moment);

const { Title, Text } = Typography;
const { TabPane } = Tabs;
const { Step } = Steps;
const { confirm } = Modal;

const styles = {};

function LMChallengeDetail(props) {
    const { authState, league, division, history, theme } = props;
    const [selectedChallengeId, setSelectedChallengeId] = useState();
    const [modalVisible, setModalVisible] = useState(false);
    const { breakpoint, maxWidth, minWidth } = useBreakpoint(defaultBreakpoints);
    const [deleteChallenge] = useMutation(DELETE_LEAGUE_CHALLENGE);
    const [deleteMatch] = useMutation(DELETE_MATCH_MUTATION);
    console.log(props);

    var zone_name = moment.tz.guess();
    var timezone = moment.tz(zone_name).zoneAbbr();

    function handleEditSelected(selected) {
        setSelectedChallengeId(selected);
        setModalVisible(true);
    }

    function handleModalOk(e) {
        console.log(e);
        setModalVisible(false);
    }

    function handleModalCancel(e) {
        setModalVisible(false);
    }

    function showConfirmDelete(record) {
        confirm({
            title: `Are you sure you want to remove ${record.name}?`,
            centered: true,
            transitionName: 'fade',
            maskTransitionName: 'none',
            // onOk() {
            // 	return new Promise((resolve, reject) => {
            // 		setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
            // 	}).catch(() => console.log('Oops errors!'));
            // },
            onOk() {
                handleDelete(record);
            },
            onCancel() {},
        });
    }

    function handleDelete(challenge) {
        deleteChallenge({
            variables: {
                league_id: league.id,
                id: challenge.id,
            },
        })
            .then((res) => {
                console.log(res);
                const deleted =
                    res && res.data && res.data.delete_challenges && res.data.delete_challenges.returning && res.data.delete_challenges.returning[0];

                deleteMatch({
                    variables: {
                        id: deleted.match_id,
                    },
                    notifyOnNetworkStatusChange: true,
                    awaitRefetchQueries: true,
                    refetchQueries: [
                        {
                            query: GET_LEAGUE_CHALLENGES_QUERY,
                            variables: { league_id: league.id },
                        },
                    ],
                })
                    .then((data) => {
                        console.log(data);
                        message.success('Challenge deleted');
                        history.push(`/league-manager/${props.league.slug}/challenges`);
                    })
                    .catch((error) => {
                        console.log(error);
                        message.info('There was an error', error);
                    });
            })
            .catch((error) => {
                console.log(error);
                message.info('There was an error', error);
            });
    }

    const sanitizeMarkup = (markup) => {
        return { __html: markup };
    };

    return (
        <SectionWrapper>
            <SectionHeader
                title={props.title}
                titleStyle={{ color: '#fff', margin: 0, textTransform: 'uppercase' }}
                avatar={league.avatar}
                subtitle={league.name}
                subtitlePrefix={true}
                subtitleStyle={{
                    color: 'rgba(255,255,255,0.8)',
                    textTransform: 'uppercase',
                    fontWeight: 600,
                    fontSize: 12,
                    letterSpacing: 3,
                }}
                backButton={
                    <Button
                        onClick={() => props.history.goBack()}
                        type="ghost"
                        shape="circle"
                        icon="arrow-left"
                        size="large"
                        ghost
                        style={{ border: 'none' }}
                    />
                }
                theme="leagueManager"
            >
                <Link to={`/leagues/${props.match.params.slug}`}>
                    <Button type="ghost" ghost>
                        Public Profile
                    </Button>
                </Link>
            </SectionHeader>
            <SectionContent padding="20px 40px">
                <Query
                    query={GET_LEAGUE_CHALLENGE_BY_SLUG_QUERY}
                    fetchPolicy="cache-and-network"
                    notifyOnNetworkStatusChange
                    variables={{ league_id: league.id, slug: props.match.params.challenge }}
                    onCompleted={(data) => {
                        // console.log(data);
                        // const match = data && data.league_matches && data.league_matches[0];
                        // setMatch(match);
                    }}
                >
                    {({ loading, error, data }) => {
                        if (loading) return <CircularLoader />;
                        if (error) return <div style={styles.container}>Error: {error.message}</div>;
                        if ((data && !data.challenges) || (data && data.challenges.length === 0)) {
                            return (
                                <Col xs={24}>
                                    <Card
                                        bodyStyle={{ padding: 0 }}
                                        // style={{
                                        // 	width: '100%',
                                        // 	borderRadius: 8,
                                        // 	overflow: 'hidden',
                                        // 	border: 'none',
                                        // 	boxShadow: '0 2px 5px 0px rgba(0, 0, 0, 0.1)'
                                        // }}
                                    >
                                        No challenge found
                                    </Card>
                                </Col>
                            );
                        }

                        const challenge = data && data.challenges && data.challenges[0];
                        const match = challenge.match;
                        console.log(challenge);

                        return (
                            <Row gutter={16}>
                                <Col xs={24}>
                                    <Row gutter={24} justify="end" type="flex">
                                        <Col xs={24} lg={8}>
                                            <Row gutter={24}>
                                                <Col xs={24} style={{ paddingTop: 0 }}>
                                                    <Card
                                                        title="Players"
                                                        bodyStyle={{ padding: 0 }}
                                                        style={{
                                                            borderRadius: 8,
                                                            overflow: 'hidden',
                                                            border: 'none',
                                                            boxShadow: '0 2px 5px 0px rgba(0, 0, 0, 0.1)',
                                                        }}
                                                    >
                                                        <Row gutter={8} type="flex" justify="start">
                                                            <div
                                                                style={{
                                                                    padding: '12px 24px',
                                                                    width: '100%',
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'center',
                                                                }}
                                                            >
                                                                <div
                                                                    style={{
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                        justifyContent: 'space-between',
                                                                    }}
                                                                >
                                                                    <div
                                                                        style={{
                                                                            display: 'flex',
                                                                            alignItems: 'center',
                                                                            justifyContent: 'center',
                                                                            flexDirection: 'column',
                                                                        }}
                                                                    >
                                                                        {match && match.player_country && (
                                                                            <CircleFlag
                                                                                country={match.player_country}
                                                                                left={0}
                                                                                size="large"
                                                                                style={{ margin: 0 }}
                                                                            />
                                                                        )}
                                                                        <div style={{ marginTop: 5, fontSize: 16 }}>
                                                                            {match && match.player_name && match.player_race_to
                                                                                ? `${match.player_name} (${match.player_race_to})`
                                                                                : match.player_name
                                                                                ? match.player_name
                                                                                : '-'}
                                                                        </div>
                                                                        <div>
                                                                            <Text type="secondary">Challenger</Text>
                                                                        </div>
                                                                    </div>

                                                                    <div style={{ marginLeft: 20, marginRight: 20 }}>vs.</div>
                                                                    <div
                                                                        style={{
                                                                            display: 'flex',
                                                                            alignItems: 'center',
                                                                            justifyContent: 'center',
                                                                            flexDirection: 'column',
                                                                        }}
                                                                    >
                                                                        {match && match.opponent_country && (
                                                                            <CircleFlag
                                                                                country={match.opponent_country}
                                                                                left={0}
                                                                                size="large"
                                                                                style={{ margin: 0 }}
                                                                            />
                                                                        )}
                                                                        <div style={{ marginTop: 5, fontSize: 16 }}>
                                                                            {match && match.opponent_name && match.opponent_race_to
                                                                                ? `${match.opponent_name} (${match.opponent_race_to})`
                                                                                : match.opponent_name
                                                                                ? match.opponent_name
                                                                                : '-'}
                                                                        </div>
                                                                        <div>
                                                                            <Text type="secondary">Opponent</Text>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Row>
                                                    </Card>
                                                </Col>
                                                <Col xs={24}>
                                                    <Card
                                                        title="Progress"
                                                        bodyStyle={{ padding: 0 }}
                                                        style={{
                                                            borderRadius: 8,
                                                            overflow: 'hidden',
                                                            border: 'none',
                                                            boxShadow: '0 2px 5px 0px rgba(0, 0, 0, 0.1)',
                                                        }}
                                                    >
                                                        <Row gutter={8} type="flex" justify="start">
                                                            <div
                                                                style={{
                                                                    padding: '12px 24px',
                                                                }}
                                                            >
                                                                <Row gutter={24}>
                                                                    <Col xs={24}>
                                                                        <div style={{ marginBottom: 15 }}>
                                                                            <Progress
                                                                                percent={20}
                                                                                strokeWidth={6}
                                                                                status="active"
                                                                                format={(percent, successPercent) => {
                                                                                    // return `${percent}% (1/5)`;
                                                                                    return '2 of 5';
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        <Steps direction="vertical" size="small" current={1}>
                                                                            <Step
                                                                                title="Created"
                                                                                description={`Challenge created by ${match && match.player_name} at ${moment(
                                                                                    match && match.created_at
                                                                                ).format('llll')}`}
                                                                            />
                                                                            <Step
                                                                                title="Confirming"
                                                                                description={`Awaiting confirmation from ${
                                                                                    match && match.opponent_name
                                                                                } (7 days left)`}
                                                                            />
                                                                            <Step title="Scheduled" description="Challenge scheduled" />
                                                                            <Step title="Completed" description="Challenge completed" />
                                                                            <Step title="Approved" description="Results approved by league operator" />
                                                                        </Steps>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        </Row>
                                                    </Card>
                                                </Col>
                                                <Col xs={24}>
                                                    <Card title="Venue" bodyStyle={{ padding: 0 }}>
                                                        <List
                                                            className="hover-list"
                                                            size="small"
                                                            itemLayout="horizontal"
                                                            dataSource={[challenge.venue]}
                                                            renderItem={(item) => (
                                                                <List.Item
                                                                    // actions={[ <a key="list-loadmore-edit">View</a> ]}
                                                                    style={{ padding: '10px 16px' }}
                                                                    onClick={() => history.push(`/venues/${item.slug}`)}
                                                                >
                                                                    <List.Item.Meta
                                                                        avatar={
                                                                            <div>
                                                                                <Icon
                                                                                    type="environment"
                                                                                    theme="filled"
                                                                                    style={{
                                                                                        color: '#1890ff',
                                                                                        fontSize: 30,
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        }
                                                                        title={
                                                                            <Text
                                                                                style={{
                                                                                    fontSize: 16,
                                                                                }}
                                                                            >
                                                                                {item.name}
                                                                            </Text>
                                                                        }
                                                                        description={
                                                                            <div>
                                                                                {item.address1}, {item.city}, {item.region} {item.postal_code} {item.country}
                                                                            </div>
                                                                        }
                                                                    />
                                                                </List.Item>
                                                            )}
                                                        />
                                                    </Card>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={24} lg={16}>
                                            <Row gutter={[16, 16]}>
                                                <Col
                                                    xs={24}
                                                    style={{
                                                        display: 'flex',
                                                    }}
                                                >
                                                    <Card
                                                        title="Overview"
                                                        extra={
                                                            <div>
                                                                <Button type="ghost" onClick={() => handleEditSelected(challenge.id)}>
                                                                    <Icon type="edit" /> Edit
                                                                </Button>
                                                                <Button
                                                                    type="danger"
                                                                    ghost
                                                                    onClick={() => {
                                                                        showConfirmDelete(challenge);
                                                                    }}
                                                                >
                                                                    <Icon type="delete" /> Delete
                                                                </Button>
                                                            </div>
                                                        }
                                                    >
                                                        {/* <div
                                                            style={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                flexDirection: 'column',
                                                                justifyContent: 'center',
                                                                paddingBottom: 10,
                                                                backgroundColor: theme.name === 'dark' ? '#0b1a2c' : '#fafafa',
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    maxWidth: 600,
                                                                }}
                                                            >
                                                                <TableScoreCard
                                                                    title={`Match ${match.match_number}`}
                                                                    table={match.pool_table}
                                                                    label={match.pool_table && match.pool_table.label}
                                                                    matchData={{
                                                                        ...match,
                                                                        challenger1_name: match.player_fargo
                                                                            ? `${match.player_name} (${match.player_fargo})`
                                                                            : match.player_name,
                                                                        challenger1_country: match.player_country,
                                                                        challenger1_state: match.player_state,
                                                                        challenger1_score: match.player_score,
                                                                        challenger1_points: match.player_points,
                                                                        challenger2_name: match.opponent_fargo
                                                                            ? `${match.opponent_name} (${match.opponent_fargo})`
                                                                            : match.opponent_name,
                                                                        challenger2_country: match.opponent_country,
                                                                        challenger2_state: match.opponent_state,
                                                                        challenger2_score: match.opponent_score,
                                                                        challenger2_points: match.opponent_points,
                                                                        race_to: match.race_to,
                                                                        start_time: match.start_date_time,
                                                                        end_time: match.start_end_time,
                                                                    }}
                                                                    status={match.status}
                                                                    gameType={match.game_type}
                                                                    hoverable={false}
                                                                    tableSize={breakpoint === 'mobile' ? 'small' : 'large'}
                                                                />
                                                            </div>
                                                        </div> */}
                                                        <div>
                                                            <table width="100%" className="info-table">
                                                                <tbody>
                                                                    <tr>
                                                                        <td>Name:</td>
                                                                        <td>{challenge.name || 'N/A'}</td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td>Match type</td>
                                                                        <td>{displayEnumType(match.match_type) || 'N/A'}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Game type</td>
                                                                        <td>
                                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                                <Avatar size={20} src={getGameType(match.game_type, theme)} />{' '}
                                                                                <span style={{ marginLeft: 5 }}>
                                                                                    {displayEnumType(match.game_type) || 'N/A'}
                                                                                </span>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Scoring format</td>
                                                                        <td>{displayEnumType(match.scoring_format) || 'N/A'}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Races</td>
                                                                        <td>
                                                                            <Link to={`/users/${slugify(match.player_name.toLowerCase())}`}>
                                                                                {match.player_name}
                                                                            </Link>{' '}
                                                                            goes to {match.player_race_to}
                                                                            <br />
                                                                            <Link to={`/users/${slugify(match.opponent_name.toLowerCase())}`}>
                                                                                {match.opponent_name}
                                                                            </Link>{' '}
                                                                            goes to {match.opponent_race_to}
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Proposed time</td>
                                                                        <td>
                                                                            {moment(challenge.scheduled_time).format('llll') || 'N/A'} ({timezone})
                                                                        </td>
                                                                    </tr>
                                                                    {challenge.venue && (
                                                                        <tr>
                                                                            <td>Location</td>
                                                                            <td>
                                                                                <Link to={`/venues/${challenge.venue.slug}`}>{challenge.venue.name}</Link>
                                                                                <br />
                                                                                {challenge.venue.address1}, {challenge.venue.city}, {challenge.venue.region}{' '}
                                                                                {challenge.venue.postal_code} {challenge.venue.country}
                                                                                <br />
                                                                                <Link
                                                                                    to={{
                                                                                        pathname: `http://maps.google.com/?q=${challenge.venue.name} ${challenge.venue.address1}, ${challenge.venue.city}, ${challenge.venue.region} ${challenge.venue.postal_code}`,
                                                                                    }}
                                                                                    target="_blank"
                                                                                    rel="noopener noreferrer"
                                                                                >
                                                                                    View map
                                                                                </Link>
                                                                            </td>
                                                                        </tr>
                                                                    )}

                                                                    <tr>
                                                                        <td>Status</td>
                                                                        <td>{challenge.status || 'N/A'}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Notes</td>
                                                                        <td
                                                                            dangerouslySetInnerHTML={sanitizeMarkup(
                                                                                (challenge.notes || 'N/A').replace(/(?:\r\n|\r|\n)/g, '<br />')
                                                                            )}
                                                                        />
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        <div style={{ marginTop: 20, maxWidth: 800 }}>
                                                            <Title level={4}>Next steps</Title>
                                                            <Text>
                                                                This challenge is awaiting approval. Your opponent ({match && match.opponent_name}) has 7 days
                                                                to either Accept or Deny this request before it expires. Once accepted, you will need to
                                                                complete the match at the agreed upon time and location and submit your scores for approval.{' '}
                                                                <br />
                                                                <br />
                                                                You can comment on the challenge below, or message your opponent directly if you need to modify
                                                                any details.
                                                            </Text>
                                                        </div>
                                                        {/* <Divider /> */}
                                                        <div
                                                            style={{
                                                                marginTop: 20,
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                width: '100%',
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    flex: 1,
                                                                }}
                                                            >
                                                                <Button
                                                                    type="primary"
                                                                    size="large"
                                                                    color="default"
                                                                    onClick={() => {
                                                                        console.log('accept challenge');
                                                                    }}
                                                                    disabled={
                                                                        challenge.opponent_id === authState && authState.user && authState.user.player_id
                                                                            ? false
                                                                            : true
                                                                    }
                                                                    style={{
                                                                        width: '100%',
                                                                        textTransform: 'uppercase',
                                                                    }}
                                                                >
                                                                    Accept Challenge
                                                                </Button>
                                                            </div>
                                                            <div
                                                                style={{
                                                                    flex: 1,
                                                                    marginLeft: 10,
                                                                }}
                                                            >
                                                                <Link to={`/league-manager/${league.slug}/matches/${match.slug}`}>
                                                                    <Button
                                                                        ghost
                                                                        size="large"
                                                                        style={{
                                                                            width: '100%',
                                                                            textTransform: 'uppercase',
                                                                        }}
                                                                    >
                                                                        Match Summary
                                                                    </Button>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </Card>
                                                </Col>

                                                <Col xs={24}>
                                                    <Card title="More information">
                                                        <Link to="">How challenges work</Link>
                                                        <br />
                                                        <Link to="">Frequently asked questions</Link>
                                                    </Card>
                                                </Col>
                                                <Col xs={24}>
                                                    <Card title="Comments">
                                                        {props.authState && props.authState.user && props.authState.user.id && (
                                                            <ChallengeCommentForm challenge={challenge} {...props} />
                                                        )}
                                                        {challenge && (
                                                            <Query
                                                                query={GET_CHALLENGE_COMMENTS}
                                                                fetchPolicy="cache-and-network"
                                                                notifyOnNetworkStatusChange={true}
                                                                variables={{ challenge_id: challenge.id }}
                                                                onCompleted={(data) => {
                                                                    // console.log(data);
                                                                }}
                                                            >
                                                                {({ loading, error, data }) => {
                                                                    if (loading) return <CircularLoader />;
                                                                    if (error) return <div style={styles.container}>Error: {error.message}</div>;
                                                                    if (data && !data.challenge_comments) {
                                                                        return null;
                                                                    }
                                                                    if (data && data.challenge_comments.length === 0) {
                                                                        return null;
                                                                    }
                                                                    const challengeComments = data.challenge_comments;

                                                                    return (
                                                                        <React.Fragment>
                                                                            {challengeComments &&
                                                                                challengeComments.length > 0 &&
                                                                                challengeComments
                                                                                    .filter((comment) => {
                                                                                        return comment.parent_id === null;
                                                                                    })
                                                                                    .map((comment) => {
                                                                                        return (
                                                                                            <React.Fragment key={comment.id}>
                                                                                                <ChallengeNestedComment
                                                                                                    challenge={challenge}
                                                                                                    comment={comment}
                                                                                                    {...props}
                                                                                                />
                                                                                            </React.Fragment>
                                                                                        );
                                                                                    })}
                                                                        </React.Fragment>
                                                                    );
                                                                }}
                                                            </Query>
                                                        )}
                                                    </Card>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        );
                    }}
                </Query>
                <AddChallengeModal
                    {...props}
                    selectedChallengeId={selectedChallengeId}
                    userID={authState.user.id}
                    leagueID={league.id}
                    modalVisible={modalVisible}
                    onModalOk={handleModalOk}
                    onModalCancel={handleModalCancel}
                />
            </SectionContent>
            <SectionFooter {...props} />
        </SectionWrapper>
    );
}

export default withRouter(LMChallengeDetail);
