import React, { useState, useEffect, useLayoutEffect, useRef, createRef } from 'react';
import { message, Checkbox, List, Modal, Avatar, Badge, Typography, Progress, Button, Icon, Tabs, Empty } from 'antd';
import { Query, Subscription } from '@apollo/client/react/components';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { displayEnumType } from '../../../../utils';
import { functions } from '../../../../firebase';
import { connectFunctionsEmulator, httpsCallable } from 'firebase/functions';
import {
    GET_TOURNAMENT_TABLES_SUBSCRIPTION,
    GET_TOURNAMENT_BRACKET_PLAYERS_SUBSCRIPTION,
    GET_TOURNAMENT_BRACKET_SUBSCRIPTION,
    GET_TOURNAMENT_PLAYERS_SUBSCRIPTION,
} from '../data/subscriptions';
import {
    UPDATE_TABLE_STATUS_MUTATION,
    UPDATE_TOURNAMENT_BRACKET_MATCH_MUTATION,
    LOG_PLAYER_SMS_NOTIFICATION,
    COMPLETE_TOURNAMENT_MUTATION,
} from '../data/mutations';
import { GET_TOURNAMENT_BY_SLUG } from '../data/queries';
import _ from 'lodash';
import clearRender from 'clear-render';
import Fade from 'react-reveal/Fade';
import Draggable from 'react-draggable';
import ZeroState from '../../../../components/ZeroState';
import CircularLoader from '../../../../components/CircularLoader';
import { estimateStartTime, estimateProjectedStartTimeEndTime } from '../../../../utils/tournamentUtils';
import SideBarPlayerModal from './SideBarPlayerModal';
import dpIcon from '../../../../assets/img/dp-icon.png';
import dpIconDark from '../../../../assets/img/dp-icon-dark.png';
import pluralize from 'pluralize';
import moment from 'moment-timezone';
import momentDurationFormatSetup from 'moment-duration-format';
momentDurationFormatSetup(moment);

const { TabPane } = Tabs;
const { Title, Text } = Typography;
const { confirm } = Modal;

const styles = {
    blueText: {
        color: '#0084ff',
    },
    redText: {
        color: '#f5222d',
    },
    greenText: {
        color: '#1e9c00',
    },
    orangeText: {
        color: '#ff5d1e',
    },
    cell: {
        fontWeight: 700,
    },
    container: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
};

function Autopilot(props) {
    const { tournament, tables, bracketHeight, theme, showPanel, setShowStatusPanel, children, onShowScoreModal, onStartTournament } = props;
    const [players, setPlayers] = useState([]);
    const [matches, setMatches] = useState([]);
    const [loading, setLoading] = useState(false);
    const [notificationsSent, setNotificationsSent] = useState(false);
    const [bracketState, setBracketState] = useState();
    const [onDeckMatches, setOnDeckMatches] = useState();
    const [onDeckMatchesUpcoming, setOnDeckMatchesUpcoming] = useState();
    const [completedMatches, setCompletedMatches] = useState();
    const [pendingMatches, setPendingMatches] = useState();
    const [scheduledMatches, setScheduledMatches] = useState();
    const [pendingMatchesDialogVisible, setPendingMatchesDialogVisible] = useState(false);
    const [openTables, setOpenTables] = useState();
    const [inProgressMatches, setInProgressMatches] = useState();
    const [sideBarPlayerModalPlayerData, setSideBarPlayerModalPlayerData] = useState({ visible: false });
    const [updateTableStatus, { mutationLoading, mutationError }] = useMutation(UPDATE_TABLE_STATUS_MUTATION);
    const [logSMSNotification, { smsNotificationLoading, smsNotificationError }] = useMutation(LOG_PLAYER_SMS_NOTIFICATION);
    const [updateTournamentBracket, { updateBracketLoading, updateBracketError }] = useMutation(UPDATE_TOURNAMENT_BRACKET_MATCH_MUTATION);
    const [completeTournament] = useMutation(COMPLETE_TOURNAMENT_MUTATION);

    var zone_name = moment.tz.guess();
    var timezone = moment.tz(zone_name).zoneAbbr();

    useEffect(() => {
        function getCurrentState() {
            let matchState = {
                inProgress: 0,
                notStarted: 0,
                scheduled: 0,
                completed: 0,
                pending: 0,
                winnerSide: 0,
                loserSide: 0,
            };

            let tableState = {
                inUse: 0,
                open: 0,
                closed: 0,
                assigned: 0,
                winnerSide: 0,
                loserSide: 0,
            };

            let playerState = {
                winnerSide: 0,
                loserSide: 0,
            };

            let progress = Math.round(tournament.progress) || 0;

            matches
                .filter((match) => {
                    return match.is_bye !== true;
                })
                .forEach((match, i) => {
                    switch (match.status) {
                        case 'IN_PROGRESS':
                            matchState.inProgress++;
                            break;
                        case 'NOT_STARTED':
                            matchState.notStarted++;
                            break;
                        case 'COMPLETED':
                            matchState.completed++;
                            break;
                        case 'PENDING':
                            matchState.pending++;
                            break;
                        case 'SCHEDULED':
                            matchState.scheduled++;
                            break;
                        default:
                            break;
                    }
                });

            tables &&
                tables.forEach((table, index) => {
                    switch (table.status) {
                        case 'OPEN':
                            tableState.open++;
                            break;
                        case 'CLOSED':
                            tableState.closed++;
                            break;
                        case 'ASSIGNED':
                            tableState.assigned++;
                            break;
                        case 'IN_USE':
                            tableState.inUse++;
                            break;
                        default:
                            break;
                    }

                    // Check to see if a table is either in USE or has a match ID assigned to it
                    // if (
                    // 	table.status === 'IN_USE' ||
                    // 	(table.tournament_match_table && table.tournament_match_table.length > 0)
                    // ) {
                    // 	tableState.inUse++;
                    // }

                    if (table.tournament_match_table.length > 0) {
                        if (table.tournament_match_table[0].round >= 1) {
                            tableState.winnerSide++;
                        } else {
                            tableState.loserSide++;
                        }
                    }
                });

            matches &&
                matches
                    .filter((match) => {
                        return match.challenger1_id && match.challenger2_id;
                    })
                    .filter((match) => {
                        return match.is_bye !== true;
                    })
                    .forEach((match, index) => {
                        if (match.round >= 1) {
                            matchState.winnerSide++;
                        } else {
                            matchState.loserSide++;
                        }
                    });

            return {
                matchState,
                tableState,
                // playerState,
                progress,
            };
        }

        setLoading(true);
        const bracketState = getCurrentState();
        // console.log(bracketState);
        setBracketState(bracketState);
        const inProgress = getMatchesInProgress(bracketState, matches);
        setInProgressMatches(inProgress);
        const onDeckCurrent = getMatchesOnDeck(bracketState, matches);
        setOnDeckMatches(onDeckCurrent);
        // const scheduled = getScheduledMatches(bracketState, matches);
        // setScheduledMatches(scheduled);
        const onDeckUpcoming = getMatchesOnDeckUpcoming(bracketState, matches);
        setOnDeckMatchesUpcoming(onDeckUpcoming);
        const pending = getPendingMatches(bracketState, matches);
        setPendingMatches(pending);
        const completed = getCompletedMatches(bracketState, matches);
        setCompletedMatches(completed);
        const openTables = getOpenTables();
        setOpenTables(openTables);

        // if (tournament.status === 'IN_PROGRESS') {
        // 	console.log('ASSIGN MATCHES & SEND NOTIFICATIONS');
        // 	assignOnDeckMatches(onDeckMatches, openTables);
        // }
        setLoading(false);
    }, [tournament, tables, matches]);

    function sideBarModalOnOk(props) {
        setSideBarPlayerModalPlayerData({ visible: false });
    }

    function sideBarModalOnCancel(props) {
        setSideBarPlayerModalPlayerData({ visible: false });
    }

    function getMatchesInProgress(bracketState, matches) {
        let onDeck = [];
        matches &&
            matches
                .filter((match) => {
                    return match.status === 'IN_PROGRESS';
                })
                .filter((match) => {
                    return match.is_bye !== true;
                })
                .slice(0, bracketState.tableState.in_progress)
                .forEach((match, index) => {
                    onDeck.push(match);
                });

        return onDeck;
    }

    function getScheduledMatches(bracketState, matches) {
        let scheduled = [];
        matches &&
            matches
                .filter((match) => {
                    return match.status === 'SCHEDULED';
                })
                .filter((match) => {
                    return match.is_bye !== true;
                })
                .slice(0, bracketState.tableState.scheduled)
                .forEach((match, index) => {
                    scheduled.push(match);
                });

        return scheduled;
    }

    function getCompletedMatches(bracketState, matches) {
        let completed = [];
        matches &&
            matches
                .filter((match) => {
                    return match.status === 'COMPLETED';
                })
                .filter((match) => {
                    return match.is_bye !== true;
                })
                .slice(0, bracketState.tableState.completed)
                .forEach((match, index) => {
                    completed.push(match);
                });

        return completed;
    }

    function getPendingMatches(bracketState, matches) {
        let pending = [];
        matches &&
            matches
                .filter((match) => {
                    return match.status === 'PENDING';
                })
                .filter((match) => {
                    return match.is_bye !== true;
                })
                .slice(0, bracketState.tableState.pending)
                .forEach((match, index) => {
                    pending.push(match);
                });

        // if (pending && pending.length > 0) {
        //     const key = 'pending';
        //     // if (pendingMatchesDialogVisible !== true) {
        //     //     showConfirmPendingScores(pending);
        //     // }

        //     let needsApproval = [];
        //     pending &&
        //         pending.forEach((match) => {
        //             needsApproval.push(`Match ${match.match_number} - ${match.challenger1_name} vs. ${match.challenger2_name}`);
        //         });

        //     showConfirmPendingScores(pending);

        //     // message.warning({
        //     //     content: (
        //     //         <div style={{ display: 'flex', flexDirection: 'column' }}>
        //     //             <div>
        //     //                 {pending.length} pending {pluralize('match', pending.length)} {pending.length > 1 ? 'need' : 'needs'} your approval
        //     //             </div>
        //     //             <div>
        //     //                 {needsApproval.map((match, index) => (
        //     //                     <div key={index}>{match}</div>
        //     //                 ))}
        //     //             </div>
        //     //         </div>
        //     //     ),
        //     //     key,
        //     //     duration: 5,
        //     // });
        // }

        return pending;
    }

    function showConfirmPendingScores(pending) {
        console.log(pending);
        setPendingMatchesDialogVisible(true);
        let needsApproval = [];
        const pendingMatches =
            pending &&
            pending.map((match) => {
                needsApproval.push(`Match ${match.match_number} - ${match.challenger1_name} vs. ${match.challenger2_name}`);
            });

        Modal.info({
            title: `${pending.length} pending ${pluralize('match', pending.length)} ${pending.length > 1 ? 'need' : 'needs'} your approval`,
            centered: true,
            transitionName: 'fade',
            maskTransitionName: 'none',
            content: (
                <div>
                    {needsApproval.map((match, index) => (
                        <div key={index}>{match}</div>
                    ))}
                </div>
            ),
            onOk() {
                setPendingMatchesDialogVisible(false);
                console.log('ok');
            },
        });
    }

    function getOpenTables() {
        return tables
            .filter((table) => {
                return table.status === 'OPEN';
            })
            .sort((a, b) => {
                return a.label.localeCompare(b.label, undefined, {
                    numeric: true,
                    sensitivity: 'base',
                });
            });
    }

    function hasBothPlayers(match) {
        return match.challenger1_name && match.challenger2_name ? true : false;
    }

    function getMatchByNumber(matchNum) {
        return matches.filter((item) => {
            return item.match_number === matchNum;
        })[0];
    }

    function getMatchesOnDeck(bracketState, matches) {
        let onDeck = [];
        console.log('open tables', bracketState.tableState.open);

        matches &&
            matches
                .filter((match) => {
                    return hasBothPlayers(match) === true && match.status !== 'COMPLETED' && (match.status === 'NOT_STARTED' || match.status === 'SCHEDULED');
                })
                .filter((match) => {
                    return match.is_bye !== true;
                })
                .slice(0, bracketState.tableState.open)
                .forEach((match, index) => {
                    onDeck.push(match);
                });

        return onDeck;
    }

    function getMatchesOnDeckUpcoming(bracketState, matches) {
        let upcoming = [];

        console.log('open tables', bracketState.tableState.open);

        matches &&
            matches
                .filter((match) => {
                    return hasBothPlayers(match) === true && match.status !== 'COMPLETED' && (match.status === 'NOT_STARTED' || match.status === 'SCHEDULED');
                })
                .filter((match) => {
                    return match.is_bye !== true;
                })
                .slice(bracketState.tableState.open, matches.length)
                .forEach((match, index) => {
                    upcoming.push(match);
                });

        return upcoming;
    }

    function logSMSMessage(options) {
        const { match, player, tournament } = options;
        console.log('LOG SMS MESSAGE', options);

        return logSMSNotification({
            variables: {
                objects: [
                    {
                        tournament_id: tournament.id,
                        tournament_bracket_id: match.id,
                        type: 'SMS',
                        round_number: match.round,
                        match_number: match.match_number,
                        player_name: player.name,
                        player_id: player.id,
                        player_phone_number: `${player.country_phone || '+1'}${player.phone_number}`,
                        message: options.message.text,
                        message_id: options.message.id,
                        message_sent: options.message.sent,
                        message_delivered: options.message.delivered,
                        message_error: options.message.error,
                        message_undeliverable: message.undeliverable,
                        message_sent_at: options.message.sent_at,
                        message_delivered_at: options.message.delivered_at,
                    },
                ],
            },
            // notifyOnNetworkStatusChange: true,
            // awaitRefetchQueries: true,
            // refetchQueries: [
            // 	{
            // 		query: GET_MATCH_STATS_QUERY,
            // 		variables: { match_id: matchId }
            // 	}
            // ]
        })
            .then((values) => {
                console.log(values);
                return values;
            })
            .catch((e) => {
                const err = (e && e.message) || JSON.stringify(e);
                message.error(err);
                console.log(err);
                return err;
            });
    }

    async function notifyPlayer(player1, player2, table, currentMatch) {
        if (tournament.use_text_messaging !== false) {
            let player1NameSplit;
            let player2NameSplit;
            let player1FirstName;
            let player2FirstName;
            let text;
            let scoreText;
            let player1Race;
            let player2Race;

            console.log({ player1, player2, table });
            const tableName =
                table && table.is_streaming_table === true
                    ? `${table.label} (Live Stream Table)`
                    : table && table.is_featured_table === true
                    ? `${table.label} (Featured Table)`
                    : table.label;

            if (player1) {
                player1NameSplit = player1.name && player1.name.split(' ');
                player1FirstName = player1NameSplit && player1NameSplit.length > 0 ? player1NameSplit[0] : player1.name;
            }

            if (player2) {
                player2NameSplit = player2.name && player2.name.split(' ');
                player2FirstName = player2NameSplit && player2NameSplit.length > 0 ? player2NameSplit[0] : player2.name;
            }

            const liveScoringLink = `Live score your match #${currentMatch.match_number}: https://digitalpool.com/m/${currentMatch.hash_id}`;

            if (player1 && player2) {
                if (currentMatch.identifier.includes('L')) {
                    player1Race = player1 && player1.race_to ? player1.race_to : tournament.losers_race_to;
                    player2Race = player2 && player2.race_to ? player2.race_to : tournament.losers_race_to;
                } else {
                    player1Race = player1 && player1.race_to ? player1.race_to : tournament.winners_race_to;
                    player2Race = player2 && player2.race_to ? player2.race_to : tournament.winners_race_to;
                }
                if (currentMatch.scheduled_time) {
                    text = `Your scheduled match #${currentMatch.match_number} against ${player2 && player2.name} will start ${moment(
                        currentMatch.scheduled_time
                    ).calendar()} on ${tableName}. Please arrive early to start on time.`;
                    scoreText = liveScoringLink;
                } else {
                    text = `Your match #${currentMatch.match_number} against ${
                        player2 && player2.name
                    } has been assigned to ${tableName}. ${player1FirstName} goes to ${player1Race} and ${player2FirstName} goes to ${player2Race}.`;
                    scoreText = liveScoringLink;
                }
            } else if (player1 && !player2) {
                if (currentMatch.scheduled_time) {
                    text = `Your scheduled match #${currentMatch.match_number} will start ${moment(
                        currentMatch.scheduled_time
                    ).calendar()} on ${tableName}. Please arrive early to start on time.`;
                    scoreText = liveScoringLink;
                } else {
                    text = `Your match #${currentMatch.match_number} has been assigned to ${tableName}. ${
                        player1 && player1.race_to ? `${player1FirstName} goes to ${player1.race_to}.` : ''
                    }`;
                    scoreText = player1 && player1.race_to && liveScoringLink;
                }
            } else if (!player1 && player2) {
                if (currentMatch.scheduled_time) {
                    text = `Your scheduled match #${currentMatch.match_number} will start ${moment(
                        currentMatch.scheduled_time
                    ).calendar()} on ${tableName}. Please arrive early to start on time.`;
                    scoreText = liveScoringLink;
                } else {
                    text = `Your match #${currentMatch.match_number} has been assigned to ${tableName}. ${
                        player2 && player2.race_to ? `${player2FirstName} goes to ${player2.race_to}.` : ''
                    }`;
                    scoreText = player2 && player2.race_to && liveScoringLink;
                }
            } else {
                throw new Error('Text message could not be sent. No players found!');
            }

            if (window.location.hostname.indexOf('localhost') > -1) {
                connectFunctionsEmulator(functions, 'localhost', 5001);
            }

            const sendSMS = httpsCallable(functions, 'sms');
            const messageToSend = `DigitalPool.com\n${text}`;
            // const messageToSend = `DigitalPool.com\n${text}\n${moment().format('MM/DD/YYYY h:mm a')}`;
            if (player1) {
                try {
                    const resultPlayer1 = await sendSMS({
                        phone_number: `${player1.country_phone || '+1'}${player1.phone_number}`,
                        message: messageToSend,
                        user_id: player1.id,
                        tournament_id: tournament.id,
                        // token: ''
                    })
                        .then(async (values) => {
                            console.log(values);
                            await logSMSMessage({
                                tournament,
                                match: currentMatch,
                                player: player1,
                                message: {
                                    id: values && values.data && values.data['message-id'],
                                    text: messageToSend,
                                    sent: true,
                                    error: null,
                                    sent_at: moment.utc(),
                                    delivered: null,
                                    delivered_at: null,
                                    undeliverable: null,
                                },
                            });
                            message.success(`SMS Notification sent to ${player1.name}`);
                            setLoading(false);
                            return values;
                        })
                        .catch(async (e) => {
                            const err = (e && e.message) || JSON.stringify(e);
                            console.log(err);
                            await logSMSMessage({
                                tournament,
                                match: currentMatch,
                                player: player1,
                                message: {
                                    id: err && err.data && err.data['message-id'],
                                    text: messageToSend,
                                    sent: true,
                                    error: err,
                                    sent_at: moment.utc(),
                                    delivered: false,
                                    delivered_at: null,
                                    undeliverable: true,
                                },
                            });
                            setLoading(false);
                            message.error(`SMS Notification error: ${err}`);
                            return err;
                        });
                    console.log(player1);
                    console.log(resultPlayer1);

                    if (scoreText) {
                        const scoringLinkToSend = `DigitalPool.com\n${scoreText}`;
                        // const scoringLinkToSend = `DigitalPool.com\n${scoreText}\n${moment().format('MM/DD/YYYY h:mm a')}`;
                        await sendSMS({
                            phone_number: `${player1.country_phone || '+1'}${player1.phone_number}`,
                            message: scoringLinkToSend,
                            user_id: player1.id,
                            tournament_id: tournament.id,
                            // token: ''
                        })
                            .then(async (values) => {
                                console.log(values);
                                await logSMSMessage({
                                    tournament,
                                    match: currentMatch,
                                    player: player1,
                                    message: {
                                        id: values && values.data && values.data['message-id'],
                                        text: scoreText,
                                        sent: true,
                                        error: null,
                                        sent_at: moment.utc(),
                                        delivered: null,
                                        delivered_at: null,
                                        undeliverable: null,
                                    },
                                });

                                return values;
                            })
                            .catch(async (e) => {
                                const err = (e && e.message) || JSON.stringify(e);
                                console.log(err);
                                await logSMSMessage({
                                    tournament,
                                    match: currentMatch,
                                    player: player1,
                                    message: {
                                        id: err && err.data && err.data['message-id'],
                                        text: scoreText,
                                        sent: true,
                                        error: err,
                                        sent_at: moment.utc(),
                                        delivered: false,
                                        delivered_at: null,
                                        undeliverable: true,
                                    },
                                });

                                return err;
                            });
                    }
                } catch (e) {
                    const err = (e && e.message) || JSON.stringify(e);
                    console.log(err);
                    await logSMSMessage({
                        tournament,
                        match: currentMatch,
                        player: player1,
                        message: {
                            id: err && err.data && err.data['message-id'],
                            text: messageToSend,
                            sent: true,
                            error: err,
                            sent_at: moment.utc(),
                            delivered: false,
                            delivered_at: null,
                            undeliverable: true,
                        },
                    });
                    setLoading(false);
                    message.error(`SMS Notification error: ${err}`);
                }
            }
        } else {
            console.log('SMS notifications are currently disabled');
        }
    }

    function getPlayer(id) {
        return players.filter((player) => {
            return player && player.id === id;
        })[0];
    }

    function showConfirmAssignTable(match, table, promises) {
        confirm({
            title: `Are you sure you want to assign ${table.label} to the same player back-to-back?`,
            centered: true,
            transitionName: 'fade',
            maskTransitionName: 'none',
            // onOk() {
            // 	return new Promise((resolve, reject) => {
            // 		setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
            // 	}).catch(() => console.log('Oops errors!'));
            // },
            onOk() {
                console.log('ok');
                assignMatchToTable(match, table, promises);
            },
            onCancel() {},
            cancelText: 'Cancel',
        });
    }

    function isTableAllowedToAssign(winnerPrevMatch, loserPrevMatch, table) {
        let allowedToAssign;
        console.log('loser prev matches', {
            winnerPrevMatch,
            loserPrevMatch,
        });

        if (winnerPrevMatch && loserPrevMatch) {
            if (winnerPrevMatch.table_id !== table.id && loserPrevMatch.table_id !== table.id) {
                allowedToAssign = true;
            } else {
                allowedToAssign = false;
            }
        } else {
            allowedToAssign = true;
        }
        return allowedToAssign;
    }

    function assignMatchToTable(match, table, promises) {
        const changes = {
            challenger1_id: match.challenger1_id,
            challenger1_name: match.challenger1_name,
            challenger1_country: match.challenger1_country,
            challenger1_score: match.challenger1_score,
            challenger1_race_to: match.challenger1_race_to,
            challenger1_skill_level: match.challenger1_skill_level,
            challenger1_seed: match.challenger1_seed,
            challenger2_id: match.challenger2_id,
            challenger2_name: match.challenger2_name,
            challenger2_country: match.challenger2_country,
            challenger2_score: match.challenger2_score,
            challenger2_race_to: match.challenger2_race_to,
            challenger2_skill_level: match.challenger2_skill_level,
            challenger2_seed: match.challenger2_seed,
            start_time: moment.utc(),
            table_name: table.label,
            table_id: table.id,
            status: 'IN_PROGRESS',
            progress: 0,
        };

        const player1 = getPlayer(match.challenger1_id);
        const player2 = getPlayer(match.challenger2_id);

        if (player1 && player1.phone_number) {
            notifyPlayer(player1, player2, table, match);
        }
        if (player2 && player2.phone_number) {
            notifyPlayer(player2, player1, table, match);
        }

        promises.push(
            updateTournamentBracket({
                variables: {
                    id: match.id,
                    tournament_id: tournament.id,
                    changes: changes,
                },
            }).then((data) => {
                console.log(data);
                // message.success(`Match ${match.match_number} assigned to ${table.label}`);
                updateTableStatus({
                    variables: {
                        id: table.id,
                        changes: {
                            status: 'IN_USE',
                        },
                    },
                })
                    .then((values) => {
                        console.log(values);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            })
        );
    }

    function assignOnDeckMatches(onDeckMatches, openTables) {
        let promises = [];
        setLoading(true);
        return new Promise(async (resolve, reject) => {
            if (onDeckMatches && onDeckMatches.length > 0 && openTables && openTables.length > 0) {
                openTables.slice(0, onDeckMatches.length).forEach((table, index) => {
                    const match = onDeckMatches[index];
                    console.log(match);
                    let allowedToAssign;

                    if (match.identifier.includes('L')) {
                        const winnerPrevMatchTop = getMatchByNumber(match.winner_from_top_num);
                        const loserPrevMatchBottom = getMatchByNumber(match.loser_from_bottom_num);
                        allowedToAssign = isTableAllowedToAssign(winnerPrevMatchTop, loserPrevMatchBottom, table);
                    } else {
                        const winnerPrevMatchTop = getMatchByNumber(match.winner_from_top_num);
                        const winnerPrevMatchBottom = getMatchByNumber(match.winner_from_bottom_num);
                        allowedToAssign = isTableAllowedToAssign(winnerPrevMatchTop, winnerPrevMatchBottom, table);
                    }

                    if (allowedToAssign === true) {
                        assignMatchToTable(match, table, promises);
                    } else {
                        showConfirmAssignTable(match, table, promises);
                        // message.error(`Cannot auto-assign ${table.label} to the same player back-to-back`);
                        return;
                    }

                    Promise.all(promises)
                        .then((values) => {
                            message.success(`Matches have been assigned to ${openTables.length} open tables.`);
                            setLoading(false);
                            resolve(values);
                        })
                        .catch((error) => {
                            console.log(error);
                            message.error('There was an error', error);
                            setLoading(false);
                            reject(error);
                        });
                });
            }
        }).catch((error) => {
            console.log(error);
            return error;
        });
    }

    function assignTables() {
        assignOnDeckMatches(onDeckMatches, openTables);
    }

    function formatMatchDisplay(match) {
        return (
            <React.Fragment>
                {match.pool_table &&
                    `${match.pool_table.label} ${match.pool_table && match.pool_table.is_streaming_table ? 'Streaming' : ''} ${
                        match.pool_table && match.pool_table.is_featured_table ? 'Featured' : ''
                    }`}{' '}
                ({match.challenger1_score ? `${match.challenger1_score} ` : `0 `} -{match.challenger2_score ? ` ${match.challenger2_score}` : ` 0`}) -{' '}
                {displayEnumType(match.status)}
                {match.status !== 'NOT_STARTED' && (
                    <React.Fragment>
                        <br />
                        <Progress
                            percent={match.progress || 0}
                            status={match.status === 'PENDING' ? 'success' : match.progress < 100 ? 'active' : match.progress === 100 ? 'normal' : 'normal'}
                            size="small"
                            strokeWidth={4}
                            strokeLinecap="square"
                            // strokeColor={match.status === 'PENDING' && '#ff5d1e'}
                            className={theme && theme.name}
                            style={{
                                top: -5,
                                padding: 0,
                                height: 5,
                                position: 'relative',
                            }}
                        />
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }

    function renderTournamentStats() {
        return (
            <React.Fragment>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        justifyContent: 'space-between',
                        padding: 20,
                        color: theme.bracket.panels.textColor,
                    }}
                >
                    <div style={{ width: '100%' }}>
                        <Title level={4} style={{ fontSize: 14, color: theme.bracket.panels.textColor }}>
                            Matches (
                            {matches &&
                                matches.filter((match) => {
                                    return match.is_bye !== true;
                                }).length}
                            )
                        </Title>
                        <table style={{ width: '100%', flex: 1 }}>
                            <tbody>
                                <tr>
                                    <td>Winners Side</td>
                                    <td style={styles.cell}>{bracketState.matchState.winnerSide}</td>
                                </tr>
                                <tr>
                                    <td>Losers Side</td>
                                    <td style={styles.cell}>{bracketState.matchState.loserSide}</td>
                                </tr>
                                <tr>
                                    <td>In Progress</td>
                                    <td style={styles.cell}>{bracketState.matchState.inProgress}</td>
                                </tr>
                                <tr>
                                    <td>Not Started</td>
                                    <td style={styles.cell}>{bracketState.matchState.notStarted}</td>
                                </tr>
                                <tr>
                                    <td>Scheduled</td>
                                    <td style={styles.cell}>{bracketState.matchState.scheduled}</td>
                                </tr>
                                <tr>
                                    <td>Completed</td>
                                    <td style={styles.cell}>{bracketState.matchState.completed}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div
                        style={{
                            marginLeft: 20,
                            width: '100%',
                        }}
                    >
                        <Title level={4} style={{ fontSize: 14, color: theme.bracket.panels.textColor }}>
                            Tables ({tables && tables.length})
                        </Title>
                        <table style={{ width: '100%', flex: 1 }}>
                            <tbody>
                                <tr>
                                    <td>Winners Side</td>
                                    <td style={styles.cell}>{bracketState.tableState.winnerSide}</td>
                                </tr>
                                <tr>
                                    <td>Losers Side</td>
                                    <td style={styles.cell}>{bracketState.tableState.loserSide}</td>
                                </tr>
                                <tr>
                                    <td>Tables In Use</td>
                                    <td style={styles.cell}>{bracketState.tableState.inUse}</td>
                                </tr>
                                <tr>
                                    <td>Open Tables</td>
                                    <td style={styles.cell}>{bracketState.tableState.open}</td>
                                </tr>
                                <tr>
                                    <td>Closed Tables</td>
                                    <td style={styles.cell}>{bracketState.tableState.closed}</td>
                                </tr>
                                <tr>
                                    <td>Assigned Tables</td>
                                    <td style={styles.cell}>{bracketState.tableState.assigned}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </React.Fragment>
        );
    }

    function showCompleteDialog() {
        confirm({
            title: 'Are you sure you want to complete the tournament?',
            content: 'This action will lock the tournament bracket.',
            centered: true,
            transitionName: 'fade',
            maskTransitionName: 'none',
            okText: 'Complete Tournament',
            cancelText: 'Cancel',
            onOk() {
                completeTournament({
                    variables: {
                        id: tournament.id,
                    },
                    awaitRefetchQueries: true,
                    notifyOnNetworkStatusChange: true,
                    refetchQueries: [
                        {
                            query: GET_TOURNAMENT_BY_SLUG,
                            variables: { slug: tournament.slug },
                        },
                    ],
                })
                    .then((data) => {
                        console.log(data);
                        message.success('Tournament completed!');
                    })
                    .catch((error) => {
                        console.log(error);

                        message.error('There was an error', error);
                    });
            },
            onCancel() {},
        });
    }

    return (
        <React.Fragment>
            <SideBarPlayerModal
                data={sideBarPlayerModalPlayerData}
                onOk={sideBarModalOnOk}
                notifyPlayer={notifyPlayer}
                updateTableStatus={updateTableStatus}
                updateTournamentBracket={updateTournamentBracket}
            />
            {tournament && tournament.status === 'IN_PROGRESS' ? (
                <Subscription
                    subscription={GET_TOURNAMENT_BRACKET_SUBSCRIPTION}
                    fetchPolicy="cache-and-network"
                    notifyOnNetworkStatusChange={true}
                    variables={{ tournamentId: tournament.id }}
                    onSubscriptionData={(data, loading, error) => {
                        if (!loading && !error) {
                            const { tournament_brackets } = data.subscriptionData.data;
                            setMatches(tournament_brackets);
                        }
                    }}
                >
                    {({ subscribeToMore, ...result }) => (
                        <Subscription
                            subscription={GET_TOURNAMENT_PLAYERS_SUBSCRIPTION}
                            fetchPolicy="cache-and-network"
                            notifyOnNetworkStatusChange={true}
                            variables={{ tournament_id: tournament.id }}
                            onSubscriptionData={(data, loading, error) => {
                                if (!loading && !error) {
                                    const { tournament_players } = data.subscriptionData.data;
                                    setPlayers(tournament_players);
                                }
                            }}
                        >
                            {({ loading, error, data }) => {
                                if (result.loading || loading) {
                                    return (
                                        <div style={styles.container}>
                                            <CircularLoader />
                                        </div>
                                    );
                                }
                                // const poolTables =
                                //	(tournament.pool_tables_aggregate &&
                                //		tournament.pool_tables_aggregate.aggregate &&
                                //		tournament.pool_tables_aggregate.aggregate.count) ||
                                //	1;

                                // const totalPlayers = data && data.tournament_players && data.tournament_players.length;
                                // const winnersRaceTo = tournament.winners_race_to;
                                // const losersRaceTo = tournament.losers_race_to;
                                // const gameType = tournament.game_type;

                                // const activeTables = bracketState.tableState.inUse;
                                // const totalTables = poolTables;
                                // const avgMatchTime = 60;
                                // const totalMatches =
                                //	result.data &&
                                //	result.data.tournament_brackets &&
                                //	result.data.tournament_brackets.length;

                                return (
                                    <React.Fragment>
                                        {showPanel !== false && !result.loading && !loading && !error && (
                                            <div>
                                                <Fade up distance="40px" delay={300} duration={500}>
                                                    <div
                                                        style={{
                                                            margin: 0,
                                                            padding: 0,
                                                            overflowY: 'auto',
                                                            height: bracketHeight,
                                                            overflow: 'hidden',
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                        }}
                                                    >
                                                        {tournament.status === 'IN_PROGRESS' ? (
                                                            <React.Fragment>
                                                                <div
                                                                    style={{
                                                                        width: '100%',
                                                                        paddingTop: 10,
                                                                        paddingLeft: 20,
                                                                        paddingRight: 10,
                                                                        paddingBottom: 10,
                                                                        // borderBottom: `1px solid rgba(0, 0, 0, 0.07)`,
                                                                    }}
                                                                >
                                                                    <div
                                                                        style={{
                                                                            display: 'flex',
                                                                            alignItems: 'center',
                                                                            justifyContent: 'space-between',
                                                                            // cursor: 'grab',
                                                                        }}
                                                                        className="tournament-status-title"
                                                                    >
                                                                        <Title
                                                                            level={4}
                                                                            style={{
                                                                                fontSize: 18,
                                                                                margin: 0,
                                                                                padding: 0,
                                                                                color: theme.bracket.panels.textColor,
                                                                            }}
                                                                        >
                                                                            Tournament Status
                                                                        </Title>
                                                                        <Button type="link" size="large" onClick={() => setShowStatusPanel(false)}>
                                                                            <Icon type="close" style={{ color: theme.bracket.panels.iconColor }} />
                                                                        </Button>
                                                                    </div>
                                                                    <div
                                                                        style={{
                                                                            width: '100%',
                                                                        }}
                                                                    >
                                                                        <Progress
                                                                            percent={bracketState.progress}
                                                                            status={
                                                                                bracketState.progress < 100
                                                                                    ? 'active'
                                                                                    : bracketState.progress === 100
                                                                                    ? 'success'
                                                                                    : 'normal'
                                                                            }
                                                                            strokeWidth={6}
                                                                            strokeLinecap="square"
                                                                            className={theme && theme.name}
                                                                            style={{
                                                                                top: -4,
                                                                                padding: 0,
                                                                                position: 'relative',
                                                                            }}
                                                                        />
                                                                        <div
                                                                            style={{
                                                                                display: 'flex',
                                                                                alignItems: 'center',
                                                                                justifyContent: 'space-between',
                                                                                paddingRight: 10,
                                                                            }}
                                                                        >
                                                                            <Text style={{ color: theme.bracket.panels.textColor }}>
                                                                                {displayEnumType(tournament.status)}
                                                                            </Text>
                                                                            <Text style={{ color: theme.bracket.panels.textColor }}>
                                                                                <Icon type="clock-circle" style={{ marginRight: 3 }} />{' '}
                                                                                {moment
                                                                                    .duration(moment.utc().diff(tournament.start_date_time, 'milliseconds'))
                                                                                    .format('h [hours] m [minutes]')}
                                                                            </Text>
                                                                        </div>
                                                                        {matches &&
                                                                            matches.filter((match) => {
                                                                                return match.is_bye !== true;
                                                                            }).length === completedMatches.length &&
                                                                            tournament.status === 'IN_PROGRESS' && (
                                                                                <div style={{ marginTop: 10 }}>
                                                                                    <Button
                                                                                        type="primary"
                                                                                        style={{ width: '100%' }}
                                                                                        onClick={() => {
                                                                                            showCompleteDialog();
                                                                                        }}
                                                                                    >
                                                                                        Complete Tournament
                                                                                    </Button>
                                                                                </div>
                                                                            )}
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    style={{
                                                                        overflowY: 'scroll',
                                                                        height: 'calc(100% - 80px)',
                                                                        width: '100%',
                                                                    }}
                                                                >
                                                                    {renderTournamentStats()}
                                                                    <Tabs
                                                                        animated={{ inkBar: true, tabPane: false }}
                                                                        defaultActiveKey="up-next"
                                                                        // onChange={callback}
                                                                        // type="card"
                                                                        tabBarStyle={{
                                                                            textTransform: 'uppercase',
                                                                            padding: 0,
                                                                            margin: 0,
                                                                            color: theme.bracket.panels.textColor,
                                                                            borderBottom: `1px solid ${theme.bracket.panels.borderColor}`,
                                                                        }}
                                                                        tabBarGutter={0}
                                                                        style={{ height: 5000 }}
                                                                        className="no-scroll"
                                                                    >
                                                                        <TabPane
                                                                            tab={`Pending (${bracketState.matchState.pending})`}
                                                                            key="in-progress"
                                                                            style={{ padding: 0 }}
                                                                        >
                                                                            <div
                                                                                style={{
                                                                                    paddingLeft: 20,
                                                                                    paddingRight: 20,
                                                                                }}
                                                                            >
                                                                                <Title
                                                                                    level={4}
                                                                                    style={{
                                                                                        fontSize: 14,
                                                                                        marginTop: 15,
                                                                                        color: theme.bracket.panels.textColor,
                                                                                    }}
                                                                                >
                                                                                    Pending Score Approval ({pendingMatches.length})
                                                                                </Title>
                                                                            </div>
                                                                            <ul
                                                                                style={{
                                                                                    margin: 0,
                                                                                    padding: 0,
                                                                                    listStyle: 'none',
                                                                                    color: theme.bracket.panels.textColor,
                                                                                }}
                                                                            >
                                                                                {pendingMatches && pendingMatches.length > 0 ? (
                                                                                    <List
                                                                                        className={`hover-list ${theme && theme.name}`}
                                                                                        size="small"
                                                                                        itemLayout="horizontal"
                                                                                        dataSource={pendingMatches}
                                                                                        renderItem={(match, index) => (
                                                                                            <List.Item
                                                                                                onClick={() => {
                                                                                                    console.log('m', match);
                                                                                                    onShowScoreModal(match);
                                                                                                }}
                                                                                                style={{
                                                                                                    borderBottom: `1px solid ${theme.bracket.panels.borderColor}`,
                                                                                                }}
                                                                                            >
                                                                                                <List.Item.Meta
                                                                                                    title={
                                                                                                        <Text style={{ color: theme.bracket.panels.textColor }}>
                                                                                                            Match {match.match_number} ({match.identifier})
                                                                                                        </Text>
                                                                                                    }
                                                                                                    description={
                                                                                                        <React.Fragment>
                                                                                                            <b>
                                                                                                                <Text
                                                                                                                    style={{
                                                                                                                        color: theme.bracket.panels.textColor,
                                                                                                                    }}
                                                                                                                >
                                                                                                                    {match.challenger1_name}
                                                                                                                    {match.challenger1_name &&
                                                                                                                        match.is_bye !== true &&
                                                                                                                        match.challenger1_race_to &&
                                                                                                                        `(${match.challenger1_race_to})`}{' '}
                                                                                                                    vs. {match.challenger2_name}
                                                                                                                    {match.challenger1_name &&
                                                                                                                        match.is_bye !== true &&
                                                                                                                        match.challenger2_race_to &&
                                                                                                                        `(${match.challenger2_race_to})`}
                                                                                                                </Text>
                                                                                                            </b>

                                                                                                            <br />
                                                                                                            <span style={styles.greenText}>
                                                                                                                {formatMatchDisplay(match)}
                                                                                                            </span>
                                                                                                        </React.Fragment>
                                                                                                    }
                                                                                                />
                                                                                            </List.Item>
                                                                                        )}
                                                                                    />
                                                                                ) : (
                                                                                    <div
                                                                                        style={{
                                                                                            paddingLeft: 20,
                                                                                            paddingRight: 20,
                                                                                            color: theme.bracket.panels.textColor,
                                                                                        }}
                                                                                    >
                                                                                        No pending matches.
                                                                                    </div>
                                                                                )}
                                                                            </ul>
                                                                            <div
                                                                                style={{
                                                                                    paddingLeft: 20,
                                                                                    paddingRight: 20,
                                                                                }}
                                                                            >
                                                                                <Title
                                                                                    level={4}
                                                                                    style={{
                                                                                        fontSize: 14,
                                                                                        marginTop: 15,
                                                                                        color: theme.bracket.panels.textColor,
                                                                                    }}
                                                                                >
                                                                                    In Progress ({inProgressMatches.length})
                                                                                </Title>
                                                                            </div>
                                                                            <ul
                                                                                style={{
                                                                                    margin: 0,
                                                                                    paddingLeft: 20,
                                                                                    paddingRight: 20,
                                                                                    listStyle: 'none',
                                                                                }}
                                                                            >
                                                                                {inProgressMatches && inProgressMatches.length > 0 ? (
                                                                                    inProgressMatches.map((match, index) => (
                                                                                        <li key={index}>
                                                                                            <Text style={{ color: theme.bracket.panels.textColor }}>
                                                                                                Match {match.match_number} ({match.identifier})
                                                                                            </Text>
                                                                                            <br />
                                                                                            <b>
                                                                                                <Text style={{ color: theme.bracket.panels.textColor }}>
                                                                                                    {match.challenger1_name}
                                                                                                    {match.challenger1_name &&
                                                                                                        match.is_bye !== true &&
                                                                                                        match.challenger1_race_to &&
                                                                                                        ` (${match.challenger1_race_to})`}{' '}
                                                                                                    vs. {match.challenger2_name}
                                                                                                    {match.challenger1_name &&
                                                                                                        match.is_bye !== true &&
                                                                                                        match.challenger2_race_to &&
                                                                                                        ` (${match.challenger2_race_to})`}
                                                                                                </Text>
                                                                                            </b>
                                                                                            <br />

                                                                                            <span style={styles.blueText}>{formatMatchDisplay(match)}</span>
                                                                                        </li>
                                                                                    ))
                                                                                ) : (
                                                                                    <div style={{ color: theme.bracket.panels.textColor }}>
                                                                                        No matches in progress yet.
                                                                                    </div>
                                                                                )}
                                                                            </ul>
                                                                        </TabPane>
                                                                        <TabPane
                                                                            tab={`On Deck (${onDeckMatches && onDeckMatches.length})`}
                                                                            key="up-next"
                                                                            // style={{ padding: 20 }}
                                                                        >
                                                                            <div
                                                                                style={{
                                                                                    paddingTop: 20,
                                                                                    paddingLeft: 20,
                                                                                    paddingRight: 20,
                                                                                    color: theme.bracket.panels.textColor,
                                                                                }}
                                                                            >
                                                                                {onDeckMatches.length > 0 && (
                                                                                    <Button
                                                                                        type="primary"
                                                                                        style={{
                                                                                            marginBottom: 10,
                                                                                            width: '100%',
                                                                                        }}
                                                                                        onClick={() => {
                                                                                            assignTables();
                                                                                        }}
                                                                                    >
                                                                                        Assign {onDeckMatches.length} Match
                                                                                        {onDeckMatches.length > 1 ? 'es' : ''} to Open Tables
                                                                                    </Button>
                                                                                )}

                                                                                <Title
                                                                                    level={4}
                                                                                    style={{ fontSize: 14, color: theme.bracket.panels.textColor }}
                                                                                >
                                                                                    Ready to Assign ({onDeckMatches.length})
                                                                                </Title>
                                                                            </div>

                                                                            <ul
                                                                                style={{
                                                                                    margin: 0,
                                                                                    padding: 0,
                                                                                    listStyle: 'none',
                                                                                    color: theme.bracket.panels.textColor,
                                                                                }}
                                                                            >
                                                                                {onDeckMatches && onDeckMatches.length > 0 ? (
                                                                                    <List
                                                                                        className={`hover-list ${theme && theme.name}`}
                                                                                        size="small"
                                                                                        itemLayout="horizontal"
                                                                                        dataSource={onDeckMatches}
                                                                                        renderItem={(match, index) => {
                                                                                            // const updatedOpenTables = openTables;
                                                                                            // const updatedOpenTables = openTables && _.shuffle(openTables);
                                                                                            return (
                                                                                                <React.Fragment>
                                                                                                    {/* <Checkbox
                                                                                                    defaultChecked
                                                                                                    onChange={(e) => {
                                                                                                        e.preventDefault();
                                                                                                        e.stopPropagation();
                                                                                                        console.log('checked');
                                                                                                    }}
                                                                                                    style={{ marginRight: 10 }}
                                                                                                /> */}

                                                                                                    <List.Item
                                                                                                        onClick={() => {
                                                                                                            console.log('m', match);
                                                                                                            setSideBarPlayerModalPlayerData({
                                                                                                                visible: true,
                                                                                                                selectedMatch: match,
                                                                                                                openTables,
                                                                                                                players,
                                                                                                                onDeckMatches,
                                                                                                                tournament,
                                                                                                                tournamentId: tournament.id,
                                                                                                            });
                                                                                                        }}
                                                                                                        style={{
                                                                                                            borderBottom: `1px solid ${theme.bracket.panels.borderColor}`,
                                                                                                        }}
                                                                                                    >
                                                                                                        <List.Item.Meta
                                                                                                            title={
                                                                                                                <React.Fragment>
                                                                                                                    <Text
                                                                                                                        style={{
                                                                                                                            color: theme.bracket.panels
                                                                                                                                .textColor,
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        Match {match.match_number} (
                                                                                                                        {match.identifier}) -{' '}
                                                                                                                    </Text>

                                                                                                                    <Text
                                                                                                                        style={{
                                                                                                                            fontSize: 12,
                                                                                                                            backgroundColor:
                                                                                                                                theme.bracket.panels
                                                                                                                                    .tagBackgroundColor,
                                                                                                                            borderRadius: 4,
                                                                                                                            padding: '2px 5px',
                                                                                                                            color: theme.bracket.panels
                                                                                                                                .tagTextColor,
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        {openTables &&
                                                                                                                            openTables[index] &&
                                                                                                                            openTables[index].label}
                                                                                                                    </Text>
                                                                                                                </React.Fragment>
                                                                                                            }
                                                                                                            description={
                                                                                                                <React.Fragment>
                                                                                                                    <b>
                                                                                                                        <Text
                                                                                                                            style={{
                                                                                                                                color: theme.bracket.panels
                                                                                                                                    .textColor,
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            {match.challenger1_name}
                                                                                                                            {match.challenger1_name &&
                                                                                                                                match.is_bye !== true &&
                                                                                                                                match.challenger1_race_to &&
                                                                                                                                `(${match.challenger1_race_to})`}{' '}
                                                                                                                            vs. {match.challenger2_name}
                                                                                                                            {match.challenger1_name &&
                                                                                                                                match.is_bye !== true &&
                                                                                                                                match.challenger2_race_to &&
                                                                                                                                `(${match.challenger2_race_to})`}
                                                                                                                        </Text>
                                                                                                                    </b>
                                                                                                                    <br />
                                                                                                                    {/* <Text>
																											{estimateProjectedStartTimeEndTime(
																												match,
																												matches
																											)}
																										</Text> */}
                                                                                                                    <Text>
                                                                                                                        {match &&
                                                                                                                            match.tournament_bracket_projections &&
                                                                                                                            match
                                                                                                                                .tournament_bracket_projections[0] && (
                                                                                                                                <React.Fragment>
                                                                                                                                    <Text
                                                                                                                                        style={{
                                                                                                                                            color: '#888',
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        Projected:{' '}
                                                                                                                                        {`${moment
                                                                                                                                            .utc(
                                                                                                                                                match
                                                                                                                                                    .tournament_bracket_projections[0]
                                                                                                                                                    .start_time
                                                                                                                                            )
                                                                                                                                            .local()
                                                                                                                                            .format(
                                                                                                                                                'LT'
                                                                                                                                            )} ${timezone} (${moment
                                                                                                                                            .utc(
                                                                                                                                                match
                                                                                                                                                    .tournament_bracket_projections[0]
                                                                                                                                                    .start_time
                                                                                                                                            )
                                                                                                                                            .local()
                                                                                                                                            .fromNow()})`}
                                                                                                                                    </Text>
                                                                                                                                    <br />
                                                                                                                                </React.Fragment>
                                                                                                                            )}
                                                                                                                    </Text>

                                                                                                                    {/* <Text>
																											{estimateStartTime(
																												match.match_number,
																												totalTables,
																												activeTables,
																												30,
																												totalMatches
																											)}
																										</Text> */}

                                                                                                                    <span style={styles.redText}>
                                                                                                                        {openTables[index] &&
                                                                                                                            !match.pool_table &&
                                                                                                                            `${openTables[index].label}`}{' '}
                                                                                                                        {formatMatchDisplay(match)}
                                                                                                                    </span>
                                                                                                                </React.Fragment>
                                                                                                            }
                                                                                                        />
                                                                                                    </List.Item>
                                                                                                </React.Fragment>
                                                                                            );
                                                                                        }}
                                                                                    />
                                                                                ) : (
                                                                                    <div
                                                                                        style={{
                                                                                            paddingLeft: 20,
                                                                                            paddingRight: 20,
                                                                                            color: theme.bracket.panels.textColor,
                                                                                        }}
                                                                                    >
                                                                                        No matches are ready to assign yet.
                                                                                    </div>
                                                                                )}
                                                                            </ul>

                                                                            <div
                                                                                style={{
                                                                                    paddingLeft: 20,
                                                                                    paddingRight: 20,
                                                                                }}
                                                                            >
                                                                                <Title
                                                                                    level={4}
                                                                                    style={{
                                                                                        fontSize: 14,
                                                                                        marginTop: 15,
                                                                                        color: theme.bracket.panels.textColor,
                                                                                    }}
                                                                                >
                                                                                    Up Next ({onDeckMatchesUpcoming.length})
                                                                                </Title>
                                                                            </div>
                                                                            <ul
                                                                                style={{
                                                                                    margin: 0,
                                                                                    padding: 0,
                                                                                    listStyle: 'none',
                                                                                    color: theme.bracket.panels.textColor,
                                                                                }}
                                                                            >
                                                                                {onDeckMatchesUpcoming && onDeckMatchesUpcoming.length > 0 ? (
                                                                                    <List
                                                                                        className={`hover-list ${theme && theme.name}`}
                                                                                        size="small"
                                                                                        itemLayout="horizontal"
                                                                                        dataSource={onDeckMatchesUpcoming}
                                                                                        renderItem={(match, index) => (
                                                                                            <List.Item
                                                                                                onClick={() => {
                                                                                                    console.log('m', match);
                                                                                                    setSideBarPlayerModalPlayerData({
                                                                                                        visible: true,
                                                                                                        selectedMatch: match,
                                                                                                        openTables,
                                                                                                        players,
                                                                                                        onDeckMatches,
                                                                                                        tournament,
                                                                                                        tournamentId: tournament.id,
                                                                                                    });
                                                                                                }}
                                                                                                style={{
                                                                                                    borderBottom: `1px solid ${theme.bracket.panels.borderColor}`,
                                                                                                }}
                                                                                            >
                                                                                                <List.Item.Meta
                                                                                                    title={
                                                                                                        <Text style={{ color: theme.bracket.panels.textColor }}>
                                                                                                            Match {match.match_number} ({match.identifier})
                                                                                                        </Text>
                                                                                                    }
                                                                                                    description={
                                                                                                        <React.Fragment>
                                                                                                            <b>
                                                                                                                <Text
                                                                                                                    style={{
                                                                                                                        color: theme.bracket.panels.textColor,
                                                                                                                    }}
                                                                                                                >
                                                                                                                    {match.challenger1_name}
                                                                                                                    {match.challenger1_name &&
                                                                                                                        match.is_bye !== true &&
                                                                                                                        match.challenger1_race_to &&
                                                                                                                        `(${match.challenger1_race_to})`}{' '}
                                                                                                                    vs. {match.challenger2_name}
                                                                                                                    {match.challenger1_name &&
                                                                                                                        match.is_bye !== true &&
                                                                                                                        match.challenger2_race_to &&
                                                                                                                        `(${match.challenger2_race_to})`}
                                                                                                                </Text>
                                                                                                            </b>

                                                                                                            {match &&
                                                                                                                match.tournament_bracket_projections &&
                                                                                                                match.tournament_bracket_projections[0] && (
                                                                                                                    <React.Fragment>
                                                                                                                        <br />
                                                                                                                        <Text
                                                                                                                            style={{
                                                                                                                                color: '#888',
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            Projected:{' '}
                                                                                                                            {`${moment
                                                                                                                                .utc(
                                                                                                                                    match
                                                                                                                                        .tournament_bracket_projections[0]
                                                                                                                                        .start_time
                                                                                                                                )
                                                                                                                                .local()
                                                                                                                                .format(
                                                                                                                                    'LT'
                                                                                                                                )} ${timezone} (${moment
                                                                                                                                .utc(
                                                                                                                                    match
                                                                                                                                        .tournament_bracket_projections[0]
                                                                                                                                        .start_time
                                                                                                                                )
                                                                                                                                .local()
                                                                                                                                .fromNow()})`}
                                                                                                                        </Text>
                                                                                                                    </React.Fragment>
                                                                                                                )}

                                                                                                            {/* <Text>
																											{estimateStartTime(
																												match.match_number,
																												totalTables,
																												activeTables,
																												30,
																												totalMatches
																											)}
																										</Text> */}
                                                                                                        </React.Fragment>
                                                                                                    }
                                                                                                />
                                                                                            </List.Item>
                                                                                        )}
                                                                                    />
                                                                                ) : (
                                                                                    <div
                                                                                        style={{
                                                                                            paddingLeft: 20,
                                                                                            paddingRight: 20,
                                                                                            color: theme.bracket.panels.textColor,
                                                                                        }}
                                                                                    >
                                                                                        No upcoming matches.
                                                                                    </div>
                                                                                )}
                                                                            </ul>
                                                                        </TabPane>
                                                                        <TabPane
                                                                            tab={`Done (${completedMatches && completedMatches.length})`}
                                                                            key="completed"
                                                                            style={{ padding: 20 }}
                                                                        >
                                                                            <ul
                                                                                style={{
                                                                                    margin: 0,
                                                                                    padding: 0,
                                                                                    listStyle: 'none',
                                                                                }}
                                                                            >
                                                                                {completedMatches && completedMatches.length > 0 ? (
                                                                                    completedMatches.map((match, index) => (
                                                                                        <li key={index}>
                                                                                            <Text style={{ color: theme.bracket.panels.textColor }}>
                                                                                                Match {match.match_number} ({match.identifier})
                                                                                            </Text>
                                                                                            <br />
                                                                                            <b>
                                                                                                <Text style={{ color: theme.bracket.panels.textColor }}>
                                                                                                    {match.challenger1_name}
                                                                                                    {match.challenger1_name &&
                                                                                                        match.is_bye !== true &&
                                                                                                        match.challenger1_race_to &&
                                                                                                        ` (${match.challenger1_race_to})`}{' '}
                                                                                                    vs. {match.challenger2_name}
                                                                                                    {match.challenger1_name &&
                                                                                                        match.is_bye !== true &&
                                                                                                        match.challenger2_race_to &&
                                                                                                        ` (${match.challenger2_race_to})`}
                                                                                                </Text>
                                                                                            </b>
                                                                                            <br />
                                                                                            <span style={styles.blueText}>{formatMatchDisplay(match)}</span>
                                                                                        </li>
                                                                                    ))
                                                                                ) : (
                                                                                    <div>No matches completed yet.</div>
                                                                                )}
                                                                            </ul>
                                                                        </TabPane>
                                                                    </Tabs>
                                                                </div>
                                                            </React.Fragment>
                                                        ) : (
                                                            <div
                                                                style={{
                                                                    width: '100%',
                                                                    height: '100%',
                                                                    paddingTop: 10,
                                                                    paddingLeft: 20,
                                                                    paddingRight: 10,
                                                                    paddingBottom: 10,
                                                                    borderBottom: '1px solid rgba(0, 0, 0, 0.07)',
                                                                }}
                                                            >
                                                                <div
                                                                    style={{
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                        justifyContent: 'space-between',
                                                                        cursor: 'grab',
                                                                    }}
                                                                    className="tournament-status-title"
                                                                >
                                                                    <Title
                                                                        level={4}
                                                                        style={{ fontSize: 18, margin: 0, padding: 0, color: theme.bracket.panels.textColor }}
                                                                    >
                                                                        Tournament Status
                                                                    </Title>
                                                                    <Button type="link" size="large" onClick={() => setShowStatusPanel(false)}>
                                                                        <Icon
                                                                            type="close"
                                                                            style={{ color: theme.name && theme.name === 'dark' ? '#fff' : '#000' }}
                                                                        />
                                                                    </Button>
                                                                </div>
                                                                <div
                                                                    style={{
                                                                        height: '100%',
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                    }}
                                                                >
                                                                    <ZeroState showImage message="Tournament not started." style={{ margin: 30 }} {...props}>
                                                                        <Button type="primary" onClick={onStartTournament}>
                                                                            Start Tournament
                                                                        </Button>
                                                                    </ZeroState>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </Fade>
                                            </div>
                                        )}
                                        {children}
                                    </React.Fragment>
                                );
                            }}
                        </Subscription>
                    )}
                </Subscription>
            ) : (
                <div
                    style={{
                        width: '100%',
                        height: '100%',
                        paddingTop: 10,
                        paddingLeft: 20,
                        paddingRight: 10,
                        paddingBottom: 10,
                        borderBottom: '1px solid rgba(0, 0, 0, 0.07)',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            cursor: 'grab',
                        }}
                        className="tournament-status-title"
                    >
                        <Title level={4} style={{ fontSize: 18, margin: 0, padding: 0, color: theme.bracket.panels.textColor }}>
                            Tournament Status
                        </Title>
                        <Button type="link" size="large" onClick={() => setShowStatusPanel(false)}>
                            <Icon type="close" style={{ color: theme.name && theme.name === 'dark' ? '#fff' : '#000' }} />
                        </Button>
                    </div>
                    <div
                        style={{
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <ZeroState showImage message="Tournament not started." style={{ margin: 30 }} {...props}>
                            <Button type="primary" onClick={onStartTournament}>
                                Start Tournament
                            </Button>
                        </ZeroState>
                    </div>
                </div>
            )}
        </React.Fragment>
    );
}

export default Autopilot;

// export default clearRender(Autopilot);
