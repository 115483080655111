import React, { useState, useEffect } from 'react';
import { message, Row, Col, Typography, Modal, List, Skeleton, Tag, Avatar, Empty, Icon, Button } from 'antd';
import CircularLoader from '../CircularLoader';
import SectionContent from '../SectionContent';
import SectionWrapper from '../SectionWrapper';
import Card from '../Card';
import { getGameType, displayEnumType } from '../../utils';
import useBreakpoint from 'use-breakpoint';
import { defaultBreakpoints } from '../../utils/defaultBreakpoints';
import { Subscription } from '@apollo/client/react/components';
import defaultImg from '../../assets/img/ball/default.png';
import { GET_LEAGUE_MATCH_QUERY, GET_LEAGUE_PLACES } from '../../screens/league-manager/data/queries';
import { GET_LEAGUE_MATCH_SUBSCRIPTION } from '../../screens/league-manager/data/subscriptions';
import { CREATE_LEAGUE_MATCH_GAME, UPDATE_LEAGUE_MATCH, UPDATE_LEAGUE_MATCH_GAME, DELETE_LEAGUE_MATCH_GAME } from '../../screens/league-manager/data/mutations';
import TPAControls from './TPAControls';
import PlayerInfo from './PlayerInfo';
import ZeroState from '../../components/ZeroState';
import { useMutation, useQuery } from '@apollo/react-hooks';
import ScoringControls from './ScoringControls';
import CenterDisplay from './CenterDisplay';
import moment from 'moment';

const { Text, Title } = Typography;
const { confirm } = Modal;

function BasicMatchScoring(props) {
    const { client, leagueID, divisionID, matchID, match, width } = props;
    const [loading, setLoading] = useState(false);
    const [games, setGames] = useState([]);
    const [currentGame, setCurrentGame] = useState();
    const [matchData, setMatchData] = useState();
    const [createGame] = useMutation(CREATE_LEAGUE_MATCH_GAME);
    const [deleteGame] = useMutation(DELETE_LEAGUE_MATCH_GAME);
    const [updateGame] = useMutation(UPDATE_LEAGUE_MATCH_GAME);
    const [endGame] = useMutation(UPDATE_LEAGUE_MATCH_GAME);
    const [endMatch] = useMutation(UPDATE_LEAGUE_MATCH);
    const [updateMatch] = useMutation(UPDATE_LEAGUE_MATCH);
    const { breakpoint, maxWidth, minWidth } = useBreakpoint(defaultBreakpoints);
    const styles = {
        cell: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            alignSelf: 'flex-start',
            marginTop: breakpoint === 'mobile' ? 0 : 60,
            padding: breakpoint === 'mobile' ? 0 : '20px 30px',
            textAlign: 'center',
        },
        game: { padding: '10px 16px' },
        gameActive: { padding: '10px 16px', backgroundColor: '#e2efff' },
    };

    useEffect(() => {
        if (matchData && matchData.status !== 'COMPLETED' && matchEndReached() === true) {
            const { winner, loser } = determineMatchWinner();
            console.log({
                winner,
                loser,
            });
            if (winner && loser) {
                showConfirmEndMatch(winner, loser);
            }
        }
    }, [matchData]);

    function determineMatchWinner() {
        const playerGames = matchData ? parseInt(matchData.player_games) : 0;
        const opponentGames = matchData ? parseInt(matchData.opponent_games) : 0;
        let winner;
        let loser;
        if ((matchData.player_goes_to && playerGames >= matchData.player_goes_to) || (playerGames >= matchData.race_to_num && playerGames > opponentGames)) {
            winner = {
                id: matchData.player_id,
                name: matchData.player_name,
                country: matchData.player_country,
                games: matchData.player_games,
                points: matchData.player_points,
                is_winner: true,
            };
            loser = {
                id: matchData.opponent_id,
                name: matchData.opponent_name,
                country: matchData.opponent_country,
                games: matchData.opponent_games,
                points: matchData.opponent_points,
                is_winner: false,
            };
        } else if (
            (matchData.opponent_goes_to && opponentGames >= matchData.opponent_goes_to) ||
            (opponentGames >= matchData.race_to_num && opponentGames > playerGames)
        ) {
            winner = {
                id: matchData.opponent_id,
                name: matchData.opponent_name,
                country: matchData.opponent_country,
                games: matchData.opponent_games,
                points: matchData.opponent_points,
                is_winner: true,
            };
            loser = {
                id: matchData.player_id,
                name: matchData.player_name,
                country: matchData.player_country,
                games: matchData.player_games,
                points: matchData.player_points,
                is_winner: false,
            };
        }

        if (winner && loser) {
            return {
                winner,
                loser,
            };
        }

        console.log({
            winner,
            loser,
        });
        return null;
    }

    function matchEndReached() {
        if (determineMatchWinner() !== null) {
            return true;
        } else {
            return false;
        }
    }

    function handleEndMatch(matchData) {
        console.log(matchData);
        const { player_score, opponent_score, player_games, opponent_games } = matchData;

        let playerWinner = false;
        let opponentWinner = false;

        if (parseInt(player_games) > parseInt(opponent_games)) {
            playerWinner = true;
        } else {
            opponentWinner = true;
        }
        setLoading(true);
        endMatch({
            variables: {
                league_id: leagueID,
                id: matchID,
                changes: {
                    player_score: parseInt(player_score),
                    opponent_score: parseInt(opponent_score),
                    player_games: parseInt(player_games),
                    opponent_games: parseInt(opponent_games),
                    end_date_time: moment.utc(),
                    status: 'COMPLETED',
                    progress: '100',
                    player_is_winner: playerWinner,
                    opponent_is_winner: opponentWinner,
                },
            },
            notifyOnNetworkStatusChange: true,
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: GET_LEAGUE_MATCH_QUERY,
                    variables: { league_id: leagueID, match_id: matchID },
                },
            ],
        })
            .then((res) => {
                console.log(res);
                setLoading(false);
                message.success('Match completed');

                orderLeagueDivisionPlaces(leagueID, divisionID);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
                message.error('There was an error', error);
            });
    }

    async function orderLeagueDivisionPlaces(league_id, division_id) {
        let q = await client.query({
            query: GET_LEAGUE_PLACES,
            variables: {
                // "league_id": 5,
                // "division_id": 18
                league_id: league_id,
                division_id: division_id,
            },
        });

        function orderAndFormatData(arr) {
            let count = arr.length;
            let formattedResults = [];

            for (let i = 0; i < count; i++) {
                let player = arr[i];

                let matchesWon = player.player_matches_won.aggregate.count + player.opponent_matches_won.aggregate.count;
                let matchesLost = player.player_matches_lost.aggregate.count + player.opponent_matches_lost.aggregate.count;
                let gamesWon = player.player_matches_games.aggregate.sum.player_games + player.opponent_matches_games.aggregate.sum.opponent_games;
                let gamesLost = player.player_matches_games.aggregate.sum.opponent_games + player.opponent_matches_games.aggregate.sum.player_games;
                let pointsWon = player.player_matches_points.aggregate.sum.player_points + player.opponent_matches_points.aggregate.sum.opponent_points;
                let pointsLost = player.player_matches_points.aggregate.sum.opponent_points + player.opponent_matches_points.aggregate.sum.player_points;

                formattedResults.push({
                    key: player.id,
                    place: player.place,
                    name: player.name,
                    matchesWon: matchesWon,
                    matchesLost: matchesLost,
                    gamesWon: gamesWon,
                    gamesLost: gamesLost,
                    pointsWon: pointsWon,
                    pointsLost: pointsLost,
                });
            }

            return formattedResults;
        }

        function formatResultsTableData(data) {
            if (data) {
                let arr = orderAndFormatData(data);

                let orderedData = arr
                    .sort((a, b) => {
                        return (
                            b.matchesWon - a.matchesWon ||
                            a.matchesLost - b.matchesLost ||
                            b.gamesWon - a.gamesWon ||
                            b.gamesLost - a.gamesLost ||
                            b.pointsWon - a.pointsWon ||
                            a.pointsLost - b.pointsLost
                        );
                    })
                    .map((player, index) => {
                        return {
                            ...player,
                            place: index + 1,
                        };
                    });

                return orderedData;
            }
            return false;
        }

        async function applyPlaceOrderToDB() {
            let players = formatResultsTableData(q.data.league_players);

            console.log(players);
            for (let i = 0; i < players.length; i++) {
                let player = players[i];

                // put update mutation code here
            }
        }

        applyPlaceOrderToDB(q.data.league_players);
    }

    function resumeMatch(matchData) {
        setLoading(true);
        updateMatch({
            variables: {
                league_id: leagueID,
                id: matchID,
                changes: {
                    end_date_time: null,
                    status: 'IN_PROGRESS',
                    progress: '99',
                    player_is_winner: false,
                    opponent_is_winner: false,
                },
            },
            notifyOnNetworkStatusChange: true,
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: GET_LEAGUE_MATCH_QUERY,
                    variables: { league_id: leagueID, match_id: matchID },
                },
            ],
        })
            .then((res) => {
                console.log(res);
                setLoading(false);
                message.success('Match resumed');
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
                message.error('There was an error', error);
            });
    }

    function handleCreateGame() {
        createGame({
            variables: {
                objects: [
                    {
                        league_id: leagueID,
                        division_id: divisionID,
                        match_id: matchID,
                        player_id: matchData.player_id,
                        opponent_id: matchData.opponent_id,
                        game_number: (currentGame && currentGame.game_number + 1) || 1,
                        status: 'NOT_STARTED',
                    },
                ],
            },
            notifyOnNetworkStatusChange: true,
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: GET_LEAGUE_MATCH_QUERY,
                    variables: { league_id: leagueID, match_id: matchID },
                },
            ],
        })
            .then((res) => {
                console.log(res);
                const currentGame =
                    res && res.data && res.data.insert_match_games && res.data.insert_match_games.returning && res.data.insert_match_games.returning[0];
                setCurrentGame(currentGame);
                setLoading(false);
                message.success(`Match game created`);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
                message.error('There was an error', error);
            });
    }

    function isActive(item, games) {
        if (currentGame) {
            return item.id === currentGame.id;
        }
    }

    function handleSelectedGame(e, game) {
        setCurrentGame(game);
    }

    function showConfirmCreateGame() {
        confirm({
            title: `Do you want to create a new game?`,
            centered: true,
            transitionName: 'fade',
            maskTransitionName: 'none',
            // onOk() {
            // 	return new Promise((resolve, reject) => {
            // 		setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
            // 	}).catch(() => console.log('Oops errors!'));
            // },
            onOk() {
                handleCreateGame();
            },
            onCancel() {},
            cancelText: 'Not now',
        });
    }

    function showConfirmEndMatch(winner, loser) {
        confirm({
            title: `${winner.name} has won the match ${winner.games} - ${loser.games}!`,
            centered: true,
            transitionName: 'fade',
            maskTransitionName: 'none',
            // onOk() {
            // 	return new Promise((resolve, reject) => {
            // 		setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
            // 	}).catch(() => console.log('Oops errors!'));
            // },
            onOk() {
                handleEndMatch(matchData);
            },
            onCancel() {},
            cancelText: 'Dismiss',
        });
    }

    function showConfirm(record, matchData) {
        confirm({
            title: `Are you sure you want to remove this game?`,
            centered: true,
            transitionName: 'fade',
            maskTransitionName: 'none',
            // onOk() {
            // 	return new Promise((resolve, reject) => {
            // 		setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
            // 	}).catch(() => console.log('Oops errors!'));
            // },
            onOk() {
                updateMatch({
                    variables: {
                        id: matchID,
                        changes: {
                            // status: 'IN_PROGRESS',
                            // progress: '0',
                            player_games: currentGame.player_is_winner === true ? parseInt(matchData.player_games) - 1 : parseInt(matchData.player_games),
                            opponent_games:
                                currentGame.opponent_is_winner === true ? parseInt(matchData.opponent_games) - 1 : parseInt(matchData.opponent_games),
                        },
                    },
                    // awaitRefetchQueries: true,
                    // refetchQueries: [
                    // 	{
                    // 		query: GET_LEAGUE_MATCH_QUERY,
                    // 		variables: { match_id: matchID }
                    // 	}
                    // ]
                })
                    .then((data) => {
                        console.log(data);
                        message.success('Match game updated');
                    })
                    .catch((error) => {
                        console.log(error);
                        message.info('There was an error', error);
                    });

                deleteGame({
                    variables: {
                        match_id: matchID,
                        id: record.id,
                    },
                    notifyOnNetworkStatusChange: true,
                    awaitRefetchQueries: true,
                    refetchQueries: [
                        {
                            query: GET_LEAGUE_MATCH_QUERY,
                            variables: { league_id: leagueID, match_id: matchID },
                        },
                    ],
                })
                    .then((data) => {
                        console.log(data);
                        const updatedGames = games.shift();
                        setCurrentGame(updatedGames[0]);
                        // message.success('Match game deleted');
                    })
                    .catch((error) => {
                        console.log(error);
                        message.info('There was an error', error);
                    });
            },
            onCancel() {},
        });
    }

    return (
        <Subscription
            subscription={GET_LEAGUE_MATCH_SUBSCRIPTION}
            fetchPolicy="cache-and-network"
            notifyOnNetworkStatusChange
            awaitRefetchQueries
            variables={{ league_id: leagueID, match_id: matchID }}
            onSubscriptionData={(res) => {
                const match =
                    res && res.subscriptionData && res.subscriptionData.data && res.subscriptionData.data.matches && res.subscriptionData.data.matches[0];
                const games = match.match_games || [];

                setGames(games);
                setMatchData(match);
                if (!currentGame) {
                    setCurrentGame(games[0]);
                } else {
                    setCurrentGame(games.filter((game) => game.id === currentGame.id)[0]);
                }
            }}
        >
            {({ loading, error, data }) => {
                if (loading) return <CircularLoader />;
                if (error) return <div style={styles.container}>Error: {error.message}</div>;
                if ((data && !data.matches) || (data && data.matches.length === 0)) {
                    return (
                        <Col xs={24}>
                            <Card
                                bodyStyle={{ padding: 0 }}
                                // style={{
                                // 	width: '100%',
                                // 	borderRadius: 8,
                                // 	overflow: 'hidden',
                                // 	border: 'none',
                                // 	boxShadow: '0 2px 5px 0px rgba(0, 0, 0, 0.1)'
                                // }}
                            >
                                <ZeroState showImage message="That match does not exist" style={{ margin: 30 }} {...props} />
                            </Card>
                        </Col>
                    );
                }

                return (
                    <SectionWrapper>
                        <SectionContent padding="20px 40px">
                            <Row gutter={24} justify="center" type="flex">
                                <Col xs={22} sm={18}>
                                    <Card
                                        title="Live Scoring"
                                        extra={
                                            <React.Fragment>
                                                <Button
                                                    type={currentGame && currentGame.status === 'COMPLETED' ? 'ghost' : 'primary'}
                                                    loading={loading}
                                                    onClick={() => {
                                                        if (currentGame && currentGame.status === 'NOT_STARTED') {
                                                            updateGame({
                                                                variables: {
                                                                    id: currentGame.id,
                                                                    changes: {
                                                                        start_date_time: moment.utc(),
                                                                        status: 'IN_PROGRESS',
                                                                        progress: '0',
                                                                        player_is_winner: false,
                                                                        opponent_is_winner: false,
                                                                    },
                                                                },
                                                                notifyOnNetworkStatusChange: true,
                                                                awaitRefetchQueries: true,
                                                                refetchQueries: [
                                                                    {
                                                                        query: GET_LEAGUE_MATCH_QUERY,
                                                                        variables: { league_id: leagueID, match_id: matchID },
                                                                    },
                                                                ],
                                                            })
                                                                .then((data) => {
                                                                    console.log(data);
                                                                    message.success('Match game started');
                                                                })
                                                                .catch((error) => {
                                                                    console.log(error);
                                                                    message.info('There was an error', error);
                                                                });
                                                        } else if (currentGame && currentGame.status === 'COMPLETED') {
                                                            updateGame({
                                                                variables: {
                                                                    id: currentGame.id,
                                                                    changes: {
                                                                        end_date_time: null,
                                                                        status: 'IN_PROGRESS',
                                                                        progress: '0',
                                                                        player_is_winner: false,
                                                                        opponent_is_winner: false,
                                                                    },
                                                                },
                                                                // awaitRefetchQueries: true,
                                                                // refetchQueries: [
                                                                // 	{
                                                                // 		query: GET_LEAGUE_MATCH_QUERY,
                                                                // 		variables: { match_id: matchID }
                                                                // 	}
                                                                // ]
                                                            })
                                                                .then((data) => {
                                                                    console.log(data);
                                                                    message.success('Match game resumed');
                                                                })
                                                                .catch((error) => {
                                                                    console.log(error);
                                                                    message.info('There was an error', error);
                                                                });

                                                            updateMatch({
                                                                variables: {
                                                                    id: matchID,
                                                                    changes: {
                                                                        // status: 'IN_PROGRESS',
                                                                        // progress: '0',
                                                                        player_games:
                                                                            currentGame && currentGame.player_is_winner === true
                                                                                ? parseInt(matchData.player_games) - 1
                                                                                : parseInt(matchData.player_games),
                                                                        opponent_games:
                                                                            currentGame && currentGame.opponent_is_winner === true
                                                                                ? parseInt(matchData.opponent_games) - 1
                                                                                : parseInt(matchData.opponent_games),
                                                                    },
                                                                },
                                                                awaitRefetchQueries: true,
                                                                refetchQueries: [
                                                                    {
                                                                        query: GET_LEAGUE_MATCH_QUERY,
                                                                        variables: { league_id: leagueID, match_id: matchID },
                                                                    },
                                                                ],
                                                            })
                                                                .then((data) => {
                                                                    console.log(data);
                                                                })
                                                                .catch((error) => {
                                                                    console.log(error);
                                                                    message.info('There was an error', error);
                                                                });
                                                        } else {
                                                            const playerPoints = currentGame ? parseInt(currentGame.player_points) : 0;
                                                            const opponentPoints = currentGame ? parseInt(currentGame.opponent_points) : 0;
                                                            let winner;
                                                            if (playerPoints > opponentPoints) {
                                                                winner = 'player';
                                                            } else if (opponentPoints > playerPoints) {
                                                                winner = 'opponent';
                                                            }

                                                            endGame({
                                                                variables: {
                                                                    id: currentGame.id,
                                                                    changes: {
                                                                        end_date_time: moment.utc(),
                                                                        status: 'COMPLETED',
                                                                        progress: '100',

                                                                        player_is_winner: winner === 'player' ? true : false,
                                                                        opponent_is_winner: winner === 'opponent' ? true : false,
                                                                    },
                                                                },
                                                            })
                                                                .then((data) => {
                                                                    console.log(data);
                                                                    message.success('Match game completed');

                                                                    updateMatch({
                                                                        variables: {
                                                                            id: matchID,
                                                                            changes: {
                                                                                // status: 'IN_PROGRESS',
                                                                                // progress: '0',
                                                                                player_games:
                                                                                    winner === 'player'
                                                                                        ? parseInt(matchData.player_games) + 1
                                                                                        : parseInt(matchData.player_games),
                                                                                opponent_games:
                                                                                    winner === 'opponent'
                                                                                        ? parseInt(matchData.opponent_games) + 1
                                                                                        : parseInt(matchData.opponent_games),
                                                                            },
                                                                        },
                                                                        notifyOnNetworkStatusChange: true,
                                                                        awaitRefetchQueries: true,
                                                                        refetchQueries: [
                                                                            {
                                                                                query: GET_LEAGUE_MATCH_QUERY,
                                                                                variables: { league_id: leagueID, match_id: matchID },
                                                                            },
                                                                        ],
                                                                    })
                                                                        .then((data) => {
                                                                            // if (matchEndReached() === true) {
                                                                            // 	const {
                                                                            // 		winner,
                                                                            // 		loser
                                                                            // 	} = determineMatchWinner();
                                                                            // 	handleEndMatch(matchData);
                                                                            // 	showConfirmEndMatch(winner, loser);
                                                                            // } else {
                                                                            // 	showConfirmCreateGame();
                                                                            // }
                                                                            // message.success('Match game updated');
                                                                        })
                                                                        .catch((error) => {
                                                                            console.log(error);
                                                                            message.info('There was an error', error);
                                                                        });
                                                                })
                                                                .catch((error) => {
                                                                    console.log(error);
                                                                    // setLoading(false);
                                                                    message.error('There was an error', error);
                                                                });
                                                        }
                                                    }}
                                                >
                                                    {currentGame && currentGame.status === 'NOT_STARTED'
                                                        ? 'Start Game'
                                                        : currentGame && currentGame.status === 'COMPLETED'
                                                        ? 'Resume Game'
                                                        : 'End Game'}
                                                </Button>
                                                <Button type="danger" loading={loading} onClick={() => showConfirm(currentGame, matchData)}>
                                                    <Icon type="delete" />
                                                </Button>
                                                <Button
                                                    type={matchData && matchData.status === 'COMPLETED' ? 'ghost' : 'default'}
                                                    loading={loading}
                                                    onClick={() => {
                                                        if (matchData && matchData.status === 'COMPLETED') {
                                                            resumeMatch(matchData);
                                                        } else {
                                                            handleEndMatch(matchData);
                                                        }
                                                    }}
                                                    style={matchData && matchData.status !== 'COMPLETED' ? { backgroundColor: '#000', color: '#fff' } : {}}
                                                >
                                                    {matchData && matchData.status === 'COMPLETED' ? 'Resume Match' : 'End Match'}
                                                </Button>
                                            </React.Fragment>
                                        }
                                        bodyStyle={{ padding: 0 }}
                                    >
                                        <Row
                                            gutter={0}
                                            justify="center"
                                            type="flex"
                                            style={
                                                currentGame && currentGame.status !== 'IN_PROGRESS'
                                                    ? { opacity: 0.5, padding: 30 }
                                                    : { opacity: 1, padding: 30 }
                                            }
                                        >
                                            <Col xs={24} sm={10}>
                                                <PlayerInfo
                                                    challenger="player"
                                                    currentGame={currentGame}
                                                    matchData={matchData}
                                                    leagueID={leagueID}
                                                    divisionID={divisionID}
                                                    matchID={matchID}
                                                    {...props}
                                                />
                                                <ScoringControls
                                                    layout="left"
                                                    currentGame={currentGame}
                                                    matchData={matchData}
                                                    leagueID={leagueID}
                                                    divisionID={divisionID}
                                                    matchID={matchID}
                                                    onDeleteGame={(game) => {
                                                        const updatedGames = games.shift();
                                                        setCurrentGame(updatedGames[0]);
                                                    }}
                                                    onCreateGame={(game) => {
                                                        setCurrentGame(game);
                                                    }}
                                                    {...props}
                                                />
                                                <TPAControls
                                                    layout="left"
                                                    currentGame={currentGame}
                                                    matchData={matchData}
                                                    leagueID={leagueID}
                                                    divisionID={divisionID}
                                                    matchID={matchID}
                                                    {...props}
                                                />
                                            </Col>

                                            <Col xs={24} sm={4}>
                                                <CenterDisplay
                                                    currentGame={currentGame}
                                                    matchData={matchData}
                                                    leagueID={leagueID}
                                                    divisionID={divisionID}
                                                    matchID={matchID}
                                                    {...props}
                                                />
                                            </Col>

                                            <Col xs={24} sm={10}>
                                                <PlayerInfo
                                                    challenger="opponent"
                                                    currentGame={currentGame}
                                                    matchData={matchData}
                                                    leagueID={leagueID}
                                                    divisionID={divisionID}
                                                    matchID={matchID}
                                                    {...props}
                                                />
                                                <ScoringControls
                                                    layout="right"
                                                    currentGame={currentGame}
                                                    matchData={matchData}
                                                    leagueID={leagueID}
                                                    divisionID={divisionID}
                                                    matchID={matchID}
                                                    onDeleteGame={(game) => {
                                                        const updatedGames = games.shift();
                                                        console.log(updatedGames);
                                                        setCurrentGame(updatedGames[0]);
                                                    }}
                                                    onCreateGame={(game) => {
                                                        setCurrentGame(game);
                                                    }}
                                                    {...props}
                                                />
                                                <TPAControls
                                                    layout="right"
                                                    currentGame={currentGame}
                                                    matchData={matchData}
                                                    leagueID={leagueID}
                                                    divisionID={divisionID}
                                                    matchID={matchID}
                                                    {...props}
                                                />
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>

                                <Col xs={22} sm={6}>
                                    <Card
                                        title={`Games (${matchData.match_games && matchData.match_games.length})`}
                                        extra={
                                            <Button
                                                type={currentGame && currentGame.status === 'COMPLETED' ? 'primary' : 'default'}
                                                onClick={() => {
                                                    handleCreateGame();
                                                }}
                                                disabled={matchData && matchData.status === 'COMPLETED' ? true : false}
                                            >
                                                <Icon type="plus" /> Add Game
                                            </Button>
                                        }
                                        bodyStyle={{ padding: 0 }}
                                    >
                                        <List
                                            className="hover-list"
                                            size="small"
                                            loading={loading}
                                            itemLayout="horizontal"
                                            dataSource={matchData.match_games}
                                            // header={
                                            // 	<div
                                            // 		style={{
                                            // 			padding: '0px 20px',
                                            // 			margin: 0,
                                            // 			display: 'flex',
                                            // 			alignItems: 'center',
                                            // 			justifyContent: 'space-between'
                                            // 		}}
                                            // 	>
                                            // 		<Text style={{ fontWeight: 700 }}>Game</Text>
                                            // 		<Text style={{ fontWeight: 700, marginLeft: 10 }}>
                                            // 			Challenger 1
                                            // 		</Text>

                                            // 		<Text style={{ fontWeight: 700 }}>Challenger 2</Text>
                                            // 	</div>
                                            // }
                                            renderItem={(item, index) => (
                                                <List.Item
                                                    // actions={[ <a key="list-loadmore-edit">View</a> ]}

                                                    style={isActive(item, matchData.match_games) === true ? styles.gameActive : styles.game}
                                                    onClick={(e) => {
                                                        handleSelectedGame(e, item);
                                                    }}
                                                >
                                                    <Skeleton avatar title={false} loading={loading} active>
                                                        <List.Item.Meta
                                                            title={`Game ${matchData.match_games.length - index}`}
                                                            extra={
                                                                <Button
                                                                    type="default"
                                                                    onClick={() => {
                                                                        handleCreateGame();
                                                                    }}
                                                                >
                                                                    <Icon type="plus" /> Add Game
                                                                </Button>
                                                            }
                                                            description={
                                                                <Text
                                                                    type="secondary"
                                                                    style={
                                                                        item && item.status === 'NOT_STARTED'
                                                                            ? { color: '#fe4d4f' }
                                                                            : item.status === 'IN_PROGRESS' && {
                                                                                  color: '#52c41a',
                                                                              }
                                                                    }
                                                                >
                                                                    {displayEnumType(item.status)}
                                                                </Text>
                                                            }
                                                        />
                                                        <List.Item.Meta
                                                            title={
                                                                <div style={{ whiteSpace: 'nowrap' }}>
                                                                    {item.player.name}{' '}
                                                                    {item.player_is_winner !== true && item.opponent_is_winner !== true ? (
                                                                        ''
                                                                    ) : (
                                                                        <span style={{ marginLeft: 5 }}>
                                                                            {item.player_is_winner === true ? (
                                                                                <Tag color="green">W</Tag>
                                                                            ) : (
                                                                                <Tag color="red">L</Tag>
                                                                            )}
                                                                        </span>
                                                                    )}
                                                                </div>
                                                            }
                                                            description={<Text type="secondary">{item.player_points}</Text>}
                                                        />
                                                        <List.Item.Meta
                                                            title={
                                                                <div style={{ whiteSpace: 'nowrap' }}>
                                                                    {item.opponent.name}{' '}
                                                                    {item.player_is_winner !== true && item.opponent_is_winner !== true ? (
                                                                        ''
                                                                    ) : (
                                                                        <span style={{ marginLeft: 5 }}>
                                                                            {item.opponent_is_winner === true ? (
                                                                                <Tag color="green">W</Tag>
                                                                            ) : (
                                                                                <Tag color="red">L</Tag>
                                                                            )}
                                                                        </span>
                                                                    )}
                                                                </div>
                                                            }
                                                            description={<Text type="secondary">{item.opponent_points}</Text>}
                                                            style={{ marginLeft: 10 }}
                                                        />
                                                    </Skeleton>
                                                </List.Item>
                                            )}
                                        />
                                    </Card>
                                </Col>
                            </Row>
                        </SectionContent>
                    </SectionWrapper>
                );
            }}
        </Subscription>
    );
}

export default BasicMatchScoring;
