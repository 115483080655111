import React, { useState } from 'react';
import { Link, withRouter, Route } from 'react-router-dom';
import { Query } from '@apollo/client/react/components';
import { Row, Col, Button, Avatar, Empty, Typography } from 'antd';
import Card, { Meta } from '../../../components/Card';
import CircularLoader from '../../../components/CircularLoader';
import SectionContent from '../../../components/SectionContent';
import ZeroState from '../../../components/ZeroState';
import SearchFilterBar from './SearchFilterBar';
import useBreakpoint from 'use-breakpoint';
import { defaultBreakpoints } from '../../../utils/defaultBreakpoints';
import { GET_LEAGUES_QUERY } from '../data/queries';
import defaultImg from '../../../assets/img/blue-bg.png';
import AdZoneTemplate from '../../../components/advertising/AdZoneTemplate';

const { Text, Title } = Typography;

const styles = {
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
    },
};

export default function AllLeagues(props) {
    const { breakpoint, maxWidth, minWidth } = useBreakpoint(defaultBreakpoints);

    return (
        <React.Fragment>
            {/* <div
				style={{
					background: 'linear-gradient( #fafafa, #fff)',
					boxShadow: 'rgba(0, 0, 0, 0.05) 0px 4px 6px 0px',
					padding: '10px 30px'
				}}
			>
				<SearchFilterBar {...props} />
			</div> */}

            <SectionContent>
                <Query
                    query={GET_LEAGUES_QUERY}
                    fetchPolicy="cache-and-network"
                    notifyOnNetworkStatusChange
                    awaitRefetchQueries
                    onCompleted={(data, loading, error, subscribeToMore) => {
                        if (!loading && !error) {
                            console.log(data);
                        }
                    }}
                >
                    {({ loading, error, data, subscribeToMore, networkStatus }) => {
                        if (networkStatus === 1 || loading) {
                            return (
                                <div style={styles.container}>
                                    <CircularLoader />
                                </div>
                            );
                        }
                        if (loading)
                            return (
                                <div style={styles.container}>
                                    <CircularLoader />
                                </div>
                            );
                        if (error) return <div style={styles.container}>Error: {error.message}</div>;
                        if ((data && !data.leagues) || (data && data.leagues.length === 0)) {
                            return (
                                <ZeroState showImage message="You have not created any leagues yet." style={{ margin: 30 }} {...props}>
                                    <Link to="/league-manager/new">
                                        <Button>Create League</Button>
                                    </Link>
                                    <Button style={{ marginLeft: 10 }}>Add Existing League</Button>
                                </ZeroState>
                            );
                        }

                        return (
                            <Row gutter={16} justify="center" type="flex">
                                <Col xs={24}>
                                    <AdZoneTemplate device={breakpoint || 'desktop'} {...props} />
                                </Col>
                                <Col xs={24}>
                                    <Row gutter={16} type="flex">
                                        {data.leagues.map((league, index) => {
                                            return (
                                                <Col xs={24} sm={12} md={8} lg={8} xl={8} xxl={6} key={index}>
                                                    <Card
                                                        hoverable
                                                        loading={loading}
                                                        cover={
                                                            <React.Fragment>
                                                                {league.logo ? (
                                                                    <div
                                                                        style={{
                                                                            height: 140,
                                                                            display: 'flex',
                                                                            alignItems: 'center',

                                                                            overflow: 'hidden',
                                                                        }}
                                                                    >
                                                                        <img alt="Logo" src={league.logo} style={{ width: '100%' }} />
                                                                    </div>
                                                                ) : (
                                                                    <div
                                                                        style={{
                                                                            height: 140,
                                                                            overflow: 'hidden',
                                                                            background: `linear-gradient(rgba(61, 185, 248, 0.8), rgba(24, 144, 255, 0.1)), url(${defaultImg})`,
                                                                            backgroundRepeat: 'no-repeat',
                                                                            backgroundSize: 'cover',
                                                                        }}
                                                                    />
                                                                )}
                                                            </React.Fragment>
                                                        }
                                                        onClick={() => {
                                                            props.history.push(`/leagues/${league.slug}`);
                                                        }}
                                                    >
                                                        <Meta
                                                            avatar={<Avatar src={league.avatar} />}
                                                            title={<Title level={4}>{league.name}</Title>}
                                                            description={league.short_description}
                                                        />
                                                    </Card>
                                                </Col>
                                            );
                                        })}
                                    </Row>
                                </Col>
                            </Row>
                        );
                    }}
                </Query>
            </SectionContent>
        </React.Fragment>
    );
}
