import React, { useState } from 'react';
import { message, Icon, Row, Switch, Col, Modal, Statistic, Tabs, Alert, Steps, List, Typography, Button, Skeleton, Avatar, Progress, Layout } from 'antd';
import Card from '../../components/Card';
import { Link } from 'react-router-dom';
import SectionTitle from '../../components/SectionTitle';
import SectionHeader from '../../components/SectionHeader';
import SectionContent from '../../components/SectionContent';
import SectionWrapper from '../../components/SectionWrapper';
import SectionFooter from '../../components/SectionFooter';
// import TBSubnav from './TBSubnav';
import { Line, Circle } from 'rc-progress';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { getGameType, displayEnumType } from '../../utils';
import _ from 'lodash';
import TournamentProgressBar from './TournamentProgressBar';
import TBManageButtons from './TBManageButtons';
import TournamentManagerTable from './TournamentManagerTable';
import AddManagerModal from './AddManagerModal';
import Timer from 'react-compound-timer';
import useBreakpoint from 'use-breakpoint';
import { defaultBreakpoints } from '../../utils/defaultBreakpoints';
import { tournamentPreviewStats } from '../../utils/tournamentUtils';
import { Query, Mutation } from '@apollo/client/react/components';
import CircularLoader from '../../components/CircularLoader';
import { UPDATE_TOURNAMENT_MUTATION, DELETE_TOURNAMENT_MUTATION } from './data/mutations';
import { GET_TOURNAMENT_TIMING_STATS, GET_TOURNAMENT_QUERY, GET_USER_TOURNAMENTS_QUERY } from './data/queries';
import numeral from 'numeral';
import axios from 'axios';
import moment from 'moment-timezone';
import momentDurationFormatSetup from 'moment-duration-format';
momentDurationFormatSetup(moment);

const { Text, Title } = Typography;
const { Step } = Steps;
const { TabPane } = Tabs;
const { confirm } = Modal;

const styles = {
    row: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
    },
    tab: {
        paddingTop: 5,
        paddingLeft: 24,
        paddingRight: 24,
        paddingBottom: 24,
    },
    avatar: {
        marginBottom: 10,
        background: 'linear-gradient(rgba(61, 185, 248, 1), rgba(24, 144, 255, 1))',
    },
    statistic: {
        fontSize: 20,
    },
};

const defaultWidth = 3;

function TBDashboard(props) {
    const { tournament, history, authState, theme } = props;
    const { breakpoint, maxWidth, minWidth } = useBreakpoint(defaultBreakpoints);
    const hasPlayers = tournament.tournament_players_aggregate.aggregate.count > 0 ? true : false;
    const hasTables = tournament.pool_tables_aggregate.aggregate.count > 0 ? true : false;
    var zone_name = moment.tz.guess();
    var timezone = moment.tz(zone_name).zoneAbbr();

    const [addManagerModalVisible, setAddManagerModalVisible] = useState(false);
    const [isPublic, setIsPublic] = useState(tournament.is_public);
    const [updateTournamentAccess] = useMutation(UPDATE_TOURNAMENT_MUTATION);
    const [deleteTournament, { loading, error }] = useMutation(DELETE_TOURNAMENT_MUTATION);

    let tournamentSteps = [
        {
            title: 'Add Players',
            description: (
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Text>Add at least 1 player to the tournament</Text>
                    {hasPlayers !== true && (
                        <Button
                            type={hasPlayers === true ? 'default' : 'primary'}
                            size="small"
                            onClick={() => props.history.push(`/tournament-builder/${tournament.slug}/players`)}
                            disabled={hasPlayers === true ? true : false}
                        >
                            Add
                        </Button>
                    )}
                </div>
            ),
            finished: hasPlayers === true ? true : false,
            link: `/tournament-builder/${tournament.slug}/players`,
        },
        {
            title: 'Add Tables',
            description: (
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Text>Add at least 1 table to the tournament</Text>
                    {hasTables !== true && (
                        <Button
                            type={hasTables === true ? 'default' : 'primary'}
                            size="small"
                            onClick={() => props.history.push(`/tournament-builder/${tournament.slug}/tables`)}
                            disabled={hasTables === true ? true : false}
                        >
                            Add
                        </Button>
                    )}
                </div>
            ),
            finished: hasTables === true ? true : false,
            link: `/tournament-builder/${tournament.slug}/tables`,
        },
        {
            title: 'Start Tournament',
            description: 'Seed the bracket and start the tournament',
            finished: tournament.status === 'IN_PROGRESS' || tournament.status === 'COMPLETED' ? true : false,
            link: `/tournament-builder/${tournament.slug}/bracket`,
        },
    ];
    const currentStep = _.sumBy(tournamentSteps, (a) => a.finished === true);

    let duration;

    if (tournament.start_date_time && tournament.end_date_time && tournament.status === 'COMPLETED') {
        var start = moment(tournament.start_date_time);
        var end = moment(tournament.end_date_time);

        duration = moment.duration(end.diff(start, 'milliseconds')).format('h [hrs] m [min]');

        // moment.duration(moment.utc().diff(match.start_time, 'milliseconds')).format('h [hrs] m [min]');
    } else if (tournament.status === 'IN_PROGRESS') {
        duration = moment.duration(moment.utc().diff(tournament.start_date_time, 'milliseconds')).format('h [hours] m [minutes]');
    } else {
        duration = 'Not Started';
    }

    function createMarkup(markup) {
        return { __html: markup };
    }

    function format(value) {
        if (value !== undefined || value !== null) {
            if (_.isBoolean(value)) {
                if (value === true) {
                    return 'Yes';
                } else {
                    return 'No';
                }
            } else {
                return value;
            }
        } else {
            return 'N/A';
        }
    }

    function onChange(current) {
        console.log('onChange:', current);
        props.history.push(tournamentSteps[current].link);
    }

    function onClickAddManager() {
        setAddManagerModalVisible(true);
    }

    function makeHashTag(str) {
        if (str) {
            const strSplit = str.split(' ');
            let wordArray = strSplit.filter((char) => char !== '');
            let result = '#';

            if (wordArray.length === 0) {
                return false;
            }

            result =
                result +
                wordArray
                    .map((word) => {
                        let capitalizedWord = word.charAt(0).toUpperCase() + word.slice(1);
                        return capitalizedWord;
                    })
                    .join('');

            if (result.length > 140) {
                return false;
            } else {
                return result;
            }
        }
    }

    function formatGameType(type) {
        // console.log(type);
        const lower = type.toLowerCase();
        if (type) {
            return lower.match(/(eight_ball|eight ball|8ball|8 ball|8-ball)/)
                ? '8ball'
                : lower.match(/(nine_ball|nine ball|9ball|9 ball|9-ball)/)
                ? '9ball'
                : lower.match(/(one_pocket|one pocket)/)
                ? '1pocket'
                : lower.match(/(ten_ball|ten ball|10ball|10 ball|10-ball)/)
                ? '10ball'
                : lower.match(/(snooker)/)
                ? 'snooker'
                : null;
        } else {
            return;
        }
    }

    function sendTournamentInfo() {
        // console.log(tournament);
        const startTime = moment(tournament.start_date_time).format('LL');
        const endTime = moment(tournament.start_end_time).format('LL');
        const gameType = makeHashTag(formatGameType(tournament.game_type));
        const address = `${tournament.venue && tournament.venue.name} ${tournament.venue && tournament.venue.city}, ${
            tournament.venue && tournament.venue.region
        } ${tournament.venue && tournament.venue.country}`;

        const tournamentInfo = {
            flyer: tournament.flyer,
            description: `${tournament.name}\n${startTime && endTime ? `${startTime} - ${endTime}` : startTime}\n${displayEnumType(
                tournament.tournament_type
            )}\n${tournament.venue && tournament.venue.name && tournament.venue.city ? address : tournament.venue.name}\n\n${
                tournament.description
            }\n\nTournament details: https://digitalpool.com/tournaments/${tournament.slug}\n\n${gameType ? gameType : ''} #digitalpool`,
        };

        console.log(tournamentInfo);
        axios
            .post('https://elicit.waypointsoftware.io/capture_post.php?xAuthentication=09e648487cf9156231d88645459d5ef0', tournamentInfo, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
            .then((response) => console.log(response))
            .catch((error) => {
                console.error('There was an error!', error);
            });
    }

    const tournamentPlayers =
        tournament.tournament_players_aggregate && tournament.tournament_players_aggregate.aggregate && tournament.tournament_players_aggregate.aggregate.count;

    console.log(tournament);
    console.log(tournamentPlayers);
    let tournamentStats;

    if (tournamentPlayers) {
        const poolTables =
            (tournament.pool_tables_aggregate && tournament.pool_tables_aggregate.aggregate && tournament.pool_tables_aggregate.aggregate.count) || 1;
        tournamentStats = tournamentPreviewStats(
            tournamentPlayers.length,
            tournament.winners_race_to,
            tournament.losers_race_to,
            poolTables,
            tournament.game_type
        );
    }

    function onToggleAccess(checked) {
        // console.log(`switch to ${checked}`);
        setIsPublic(checked);

        return updateTournamentAccess({
            variables: {
                id: tournament.id,
                changes: {
                    is_public: checked,
                },
            },
            notifyOnNetworkStatusChange: true,
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: GET_TOURNAMENT_QUERY,
                    variables: { id: tournament.id },
                },
            ],
        })
            .then((data) => {
                console.log(data);
                message.success(`Access is set to ${checked === true ? 'Public' : 'Private'}`);
                return data;
            })
            .catch((error) => {
                console.error(error);
                message.info('There was an error', error);
                return error;
            });
    }

    function showConfirmDelete(record) {
        confirm({
            title: `Are you sure you want to remove ${record.name}?`,
            content: 'This will permanently delete this tournament and cannot be undone.',
            centered: true,
            transitionName: 'fade',
            maskTransitionName: 'none',
            // onOk() {
            // 	return new Promise((resolve, reject) => {
            // 		setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
            // 	}).catch(() => console.log('Oops errors!'));
            // },
            onOk() {
                const key = 'delete';
                message.loading({
                    content: `Deleting tournament...`,
                    key,
                    duration: 0,
                });
                deleteTournament({
                    variables: {
                        tournament_id: record.id,
                    },
                    notifyOnNetworkStatusChange: true,
                    awaitRefetchQueries: true,
                    refetchQueries: [
                        {
                            query: GET_USER_TOURNAMENTS_QUERY,
                            variables: {
                                manager_id: props.authState.user.id,
                            },
                        },
                    ],
                })
                    .then((data) => {
                        console.log(data);
                        message.success({
                            content: `Tournament successfully deleted`,
                            key,
                            duration: 2,
                        });
                        props.history.push(`/tournament-builder`);
                    })
                    .catch((error) => {
                        console.log(error);
                        message.error({
                            content: `There was an error ${JSON.stringify(error)}`,
                            key,
                            duration: 2,
                        });
                    });
            },
            onCancel() {},
        });
    }

    return (
        <SectionWrapper>
            <SectionHeader
                title={props.title}
                titleStyle={{ color: '#fff', margin: 0, textTransform: 'uppercase' }}
                subtitle={tournament.name}
                subtitlePrefix={true}
                subtitleStyle={{
                    color: 'rgba(255,255,255,0.8)',
                    textTransform: 'uppercase',
                    fontWeight: 600,
                    fontSize: 12,
                    letterSpacing: 3,
                }}
                height={90}
                theme="tournamentBuilder"
            >
                <TBManageButtons page="overview" {...props} />
            </SectionHeader>
            {/* <TournamentProgressBar /> */}
            <SectionContent padding="20px 40px">
                <Row gutter={16}>
                    <Col xs={24}>
                        <div>
                            {isPublic === true ? (
                                <Alert
                                    message={
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                            <Text>Access to your tournament is currently public and visible on the main /tournaments page.</Text>
                                            <div style={{ marginRight: 20 }}>
                                                Public <Switch defaultChecked={isPublic} onChange={onToggleAccess} style={{ marginLeft: 5 }} />
                                            </div>
                                        </div>
                                    }
                                    type="info"
                                    closable
                                    showIcon
                                />
                            ) : (
                                <Alert
                                    message={
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                            <Text>Access to your tournament is currently private and not visible on the main /tournaments page.</Text>
                                            <div style={{ marginRight: 20 }}>
                                                Private <Switch defaultChecked={isPublic} onChange={onToggleAccess} style={{ marginLeft: 5 }} />
                                            </div>
                                        </div>
                                    }
                                    type="warning"
                                    closable
                                    showIcon
                                />
                            )}
                        </div>
                    </Col>
                    <Col xs={24} sm={8}>
                        <Row gutter={16}>
                            <Col xs={24}>
                                <Card
                                    title="Todo List"
                                    bodyStyle={{
                                        paddingTop: 20,
                                        paddingLeft: 20,
                                        paddingRight: 20,
                                        paddingBottom: 10,
                                    }}
                                >
                                    <Steps direction="vertical" size="small" current={currentStep} onChange={onChange}>
                                        {tournamentSteps.map((step, index) => (
                                            <Step
                                                key={index}
                                                title={step.title}
                                                subTitle={step.finished === true ? 'Complete' : 'Not Finished'}
                                                description={step.description}
                                            />
                                        ))}
                                    </Steps>
                                    <div style={{ width: '100%', marginBottom: 10 }}>
                                        <Link to={`/tournament-builder/${tournament.slug}/edit`}>
                                            <Button type="primary" size="large" style={{ width: '100%' }}>
                                                <Icon type="setting" /> Tournament Setup
                                            </Button>
                                        </Link>
                                    </div>
                                    <div style={{ width: '100%', marginBottom: 10 }}>
                                        {tournament.tournament_type === 'chip' || tournament.tournament_type === 'round_robin' ? (
                                            <Link to={`/tournament-builder/${tournament.slug}/viewer`}>
                                                <Button size="large" style={{ width: '100%', color: '#fff', background: '#0d1e35' }}>
                                                    <Icon type="eye" /> Go to Viewer
                                                </Button>
                                            </Link>
                                        ) : (
                                            <Link to={`/tournament-builder/${tournament.slug}/bracket`}>
                                                <Button size="large" style={{ width: '100%', color: '#fff', background: '#0d1e35' }}>
                                                    <Icon type="apartment" /> View Bracket
                                                </Button>
                                            </Link>
                                        )}
                                    </div>
                                    {/* {authState && authState.user && authState.user.role === 'admin' && (
                                        <div style={{ width: '100%', marginBottom: 10 }}>
                                            <Button type="secondary" size="large" onClick={sendTournamentInfo} style={{ width: '100%' }}>
                                                Send Tournament Info
                                            </Button>
                                        </div>
                                    )} */}
                                </Card>
                            </Col>

                            <Col xs={24}>
                                <Card title="Venue" bodyStyle={{ padding: 0 }}>
                                    <List
                                        className="hover-list"
                                        size="small"
                                        itemLayout="horizontal"
                                        dataSource={[tournament.venue]}
                                        renderItem={(item) => (
                                            <List.Item
                                                // actions={[ <a key="list-loadmore-edit">View</a> ]}
                                                style={{ padding: '10px 16px' }}
                                                onClick={() => history.push(`/venues/${item.slug}`)}
                                            >
                                                <List.Item.Meta
                                                    avatar={
                                                        <div>
                                                            <Icon
                                                                type="environment"
                                                                theme="filled"
                                                                style={{
                                                                    color: '#1890ff',
                                                                    fontSize: 30,
                                                                }}
                                                            />
                                                        </div>
                                                    }
                                                    title={
                                                        <Text
                                                            style={{
                                                                fontSize: 16,
                                                            }}
                                                        >
                                                            {item.name}
                                                        </Text>
                                                    }
                                                    description={
                                                        <div>
                                                            {item.address1}, {item.city}, {item.region} {item.postal_code} {item.country}
                                                        </div>
                                                    }
                                                />
                                            </List.Item>
                                        )}
                                    />
                                </Card>
                            </Col>

                            {tournament.event && (
                                <Col xs={24}>
                                    <Card title="Event" bodyStyle={{ padding: 0 }}>
                                        <List
                                            className="hover-list"
                                            size="small"
                                            itemLayout="horizontal"
                                            dataSource={[tournament.event]}
                                            renderItem={(item) => (
                                                <List.Item
                                                    // actions={[ <a key="list-loadmore-edit">View</a> ]}
                                                    style={{ padding: '10px 16px' }}
                                                    onClick={() => history.push(`/events/${item.slug}`)}
                                                >
                                                    <List.Item.Meta
                                                        avatar={
                                                            <div>
                                                                <Icon
                                                                    type="calendar"
                                                                    style={{
                                                                        color: '#1890ff',
                                                                        fontSize: 30,
                                                                    }}
                                                                />
                                                            </div>
                                                        }
                                                        title={
                                                            <Text
                                                                style={{
                                                                    fontSize: 16,
                                                                }}
                                                            >
                                                                {item.name}
                                                            </Text>
                                                        }
                                                        description={<div>{item.description}</div>}
                                                    />
                                                </List.Item>
                                            )}
                                        />
                                    </Card>
                                </Col>
                            )}

                            <Col xs={24}>
                                <Card
                                    title="Managers"
                                    bodyStyle={{ padding: 0 }}
                                    extra={
                                        <Button type="default" onClick={onClickAddManager}>
                                            Add Manager
                                        </Button>
                                    }
                                >
                                    <Row gutter={16} justify="start" type="flex">
                                        <div style={{ width: '100%' }}>
                                            <TournamentManagerTable
                                                userId={props.authState.user.id}
                                                tournamentDirectorId={tournament.director_id}
                                                tournamentId={tournament.id}
                                                client={props.client}
                                            />
                                        </div>
                                    </Row>
                                </Card>
                            </Col>

                            {/* <Col xs={24}>
                                <Card title="Status" bodyStyle={{ padding: '10px 25px' }}>
                                    <Row gutter={8} justify="start" type="flex">
                                        <Col xs={12}>
                                            <Title level={4} style={{ fontSize: 14 }}>
                                                Matches ({tournament.tournament_brackets_aggregate.aggregate.count - tournament.bye_matches.aggregate.count})
                                            </Title>
                                            <table width="100%" className="info-table">
                                                <tbody>
                                                    <tr>
                                                        <td>Completed</td>
                                                        <td>{tournament.completed_matches.aggregate.count}</td>
                                                    </tr>

                                                    <tr>
                                                        <td>In progress</td>
                                                        <td>{tournament.in_progress_matches.aggregate.count}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Upcoming</td>
                                                        <td>{tournament.not_started_matches.aggregate.count}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </Col>

                                        <Col xs={12}>
                                            <Title level={4} style={{ fontSize: 14 }}>
                                                Players ({tournament.tournament_players_aggregate.aggregate.count})
                                            </Title>
                                            <table width="100%" className="info-table">
                                                <tbody>
                                                    <tr>
                                                        <td>Confirmed</td>
                                                        <td>{tournament.confirmed_players.aggregate.count}</td>
                                                    </tr>

                                                    <tr>
                                                        <td>Unconfirmed</td>
                                                        <td>{tournament.unconfirmed_players.aggregate.count}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Waiting list</td>
                                                        <td>{tournament.waiting_list_players.aggregate.count}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </Col>

                                        <Col xs={12}>
                                            <Title level={4} style={{ fontSize: 14 }}>
                                                Tables ({tournament.pool_tables_aggregate.aggregate.count})
                                            </Title>
                                            <table width="100%" className="info-table">
                                                <tbody>
                                                    <tr>
                                                        <td>In Use</td>
                                                        <td>{tournament.in_use_tables.aggregate.count}</td>
                                                    </tr>

                                                    <tr>
                                                        <td>Open</td>
                                                        <td>{tournament.open_tables.aggregate.count}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Close</td>
                                                        <td>{tournament.closed_tables.aggregate.count}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </Col>

                                        <Col xs={12}>
                                            <Title level={4} style={{ fontSize: 14 }}>
                                                Payouts
                                            </Title>
                                            <table width="100%" className="info-table">
                                                <tbody>
                                                    <tr>
                                                        <td>Collected</td>
                                                        <td>${tournament.confirmed_players.aggregate.count * tournament.entry_fee || 0}</td>
                                                    </tr>

                                                    <tr>
                                                        <td>Entry fee</td>
                                                        <td>${tournament.entry_fee}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Added money</td>
                                                        <td>${tournament.added_money}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col> */}
                        </Row>
                    </Col>
                    <Col xs={24} sm={16}>
                        <Row gutter={16}>
                            <Col xs={24}>
                                <Card
                                    title={
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                            <Title level={4} style={{ margin: 0, padding: 0, fontSize: 16 }}>
                                                Overview
                                            </Title>
                                            <Button type="danger" ghost onClick={() => showConfirmDelete(tournament)}>
                                                <Icon type="delete" /> Delete
                                            </Button>
                                        </div>
                                    }
                                >
                                    <div style={{ marginBottom: 10 }}>
                                        <div
                                            style={{
                                                backgroundColor:
                                                    theme && theme.name === 'light' ? (tournament.status === 'COMPLETED' ? '#fff' : '#e2efff') : '#06101b',
                                                borderRadius: 8,
                                                padding: 20,
                                                border:
                                                    tournament.status === 'COMPLETED'
                                                        ? theme && theme.name === 'light'
                                                            ? '2px solid #f0f0f0'
                                                            : '2px solid #06101b'
                                                        : 'none',
                                            }}
                                        >
                                            <Statistic
                                                value={tournament.progress ? parseInt(tournament.progress).toFixed(0) : 0}
                                                valueStyle={{ fontSize: 30 }}
                                                suffix={`% Complete (${tournament.completed_matches.aggregate.count} of ${
                                                    tournament.tournament_brackets_aggregate.aggregate.count - tournament.bye_matches.aggregate.count
                                                } matches)`}
                                                // style={{ color: '#fff' }}
                                            />

                                            <Progress
                                                percent={parseInt(tournament.progress)}
                                                status={
                                                    parseInt(tournament.progress) < 100
                                                        ? 'active'
                                                        : parseInt(tournament.progress) === 100
                                                        ? 'success'
                                                        : 'normal'
                                                }
                                                size="small"
                                                showInfo={false}
                                            />

                                            <div
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'space-between',
                                                    marginTop: 10,
                                                }}
                                            >
                                                <Text>
                                                    <span>
                                                        <Icon type="clock-circle" style={{ marginRight: 3 }} /> {duration || 'N/A'}
                                                    </span>
                                                </Text>
                                                <Text
                                                    style={{ textTransform: 'uppercase' }}
                                                    className={`${
                                                        tournament.status === 'PENDING' ? 'pending' : tournament.status === 'IN_PROGRESS' ? 'live' : 'completed'
                                                    }-text`}
                                                >
                                                    {tournament.status === 'IN_PROGRESS' ? 'LIVE' : displayEnumType(tournament.status)}
                                                </Text>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <Tabs
                                            defaultActiveKey="details"
                                            animated={{ inkBar: true, tabPane: false }}
                                            className="no-scroll tabs-bg2"
                                            tabBarStyle={{
                                                textTransform: 'uppercase',
                                            }}
                                        >
                                            <TabPane tab="Tournament Details" key="details">
                                                <Row gutter={16} style={{ maxHeight: 500, overflowY: 'auto' }}>
                                                    {breakpoint === 'mobile' && tournament.flyer && (
                                                        <Col xs={24}>
                                                            <img
                                                                src={tournament.flyer}
                                                                width={'100%'}
                                                                alt="Flyer"
                                                                style={{
                                                                    borderRadius: 10,
                                                                    overflow: 'hidden',
                                                                }}
                                                            />
                                                        </Col>
                                                    )}

                                                    <Col xs={24}>
                                                        <Row gutters={[16, 16]}>
                                                            <Col xs={24} sm={12}>
                                                                <table width="100%" className="info-table">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>Name</td>
                                                                            <td>
                                                                                {tournament.name} (ID: {tournament.id})
                                                                            </td>
                                                                        </tr>

                                                                        <tr>
                                                                            <td>Description</td>
                                                                            {tournament && tournament.description && (
                                                                                <td className="display-linebreak">
                                                                                    <div
                                                                                        dangerouslySetInnerHTML={
                                                                                            tournament.description
                                                                                                ? createMarkup(tournament.description)
                                                                                                : 'N/A'
                                                                                        }
                                                                                    />
                                                                                </td>
                                                                            )}
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Start date</td>
                                                                            <td>
                                                                                {moment(tournament.start_date_time).format('llll')} ({timezone})
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>End date</td>
                                                                            <td>
                                                                                {moment(tournament.end_date_time).format('llll')} ({timezone})
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Venue</td>
                                                                            <td>
                                                                                <Link to={`/venues/${tournament.venue.slug}`}>{tournament.venue.name}</Link>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Player type</td>
                                                                            <td>{tournament.player_type ? displayEnumType(tournament.player_type) : 'N/A'}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Tournament Type</td>
                                                                            <td>
                                                                                {tournament.tournament_type
                                                                                    ? displayEnumType(tournament.tournament_type)
                                                                                    : 'N/A'}
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Game type</td>
                                                                            <td>
                                                                                <Avatar size={25} src={getGameType(tournament.game_type, theme)} />
                                                                                <Text style={{ marginLeft: 5 }}>
                                                                                    {tournament.game_type ? displayEnumType(tournament.game_type) : 'N/A'}
                                                                                </Text>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Winners race to</td>
                                                                            <td>{tournament.winners_race_to}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Losers race to</td>
                                                                            <td>{tournament.losers_race_to}</td>
                                                                        </tr>

                                                                        <tr>
                                                                            <td>Bracket size</td>
                                                                            <td>{tournament.max_players}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Players</td>
                                                                            <td>{tournament.tournament_players_aggregate.aggregate.count} Players</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Tables</td>
                                                                            <td>{tournament.pool_tables_aggregate.aggregate.count} Tables</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>

                                                                <table width="100%" className="info-table">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>Rating system</td>
                                                                            <td>
                                                                                {tournament.rating_system ? displayEnumType(tournament.rating_system) : 'N/A'}
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Break format</td>
                                                                            <td>
                                                                                {tournament.break_format ? displayEnumType(tournament.break_format) : 'N/A'}
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Rules</td>
                                                                            <td>{tournament.rule_format.toUpperCase()}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Entry fee</td>
                                                                            <td>{tournament.entry_fee ? `$${tournament.entry_fee}` : 'N/A'}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Added money</td>
                                                                            <td>{tournament.added_money ? `$${tournament.added_money}` : 'N/A'}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Payout type</td>
                                                                            <td>{tournament.payout_type ? displayEnumType(tournament.payout_type) : 'N/A'}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Draw type</td>
                                                                            <td>{tournament.draw_type ? displayEnumType(tournament.draw_type) : 'N/A'}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Signup cutoff time</td>
                                                                            <td>
                                                                                {tournament.signup_cutoff_time
                                                                                    ? displayEnumType(tournament.signup_cutoff_time)
                                                                                    : 'N/A'}
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Handicap</td>
                                                                            <td>{tournament.handicapFormat || 'N/A'}</td>
                                                                        </tr>
                                                                        {tournament.director && (
                                                                            <tr>
                                                                                <td>Tournament director</td>
                                                                                <td>
                                                                                    <Link to={`/users/${tournament.director.slug}`}>
                                                                                        {tournament.director.first_name} {tournament.director.last_name}
                                                                                    </Link>
                                                                                    <br />
                                                                                    {tournament.director.email}
                                                                                </td>
                                                                            </tr>
                                                                        )}

                                                                        <tr>
                                                                            <td>Allow players to RSVP</td>
                                                                            <td>
                                                                                <Text>{tournament.rsvp_allowed ? 'Yes' : 'No'}</Text>
                                                                            </td>
                                                                        </tr>

                                                                        <tr>
                                                                            <td>Use text messaging</td>
                                                                            <td>
                                                                                <Text>{tournament.use_text_messaging ? 'Yes' : 'No'}</Text>
                                                                            </td>
                                                                        </tr>

                                                                        <tr>
                                                                            <td>True double elimination</td>
                                                                            <td>
                                                                                <Text>{tournament.consolidation_finals ? 'Yes' : 'No'}</Text>
                                                                            </td>
                                                                        </tr>

                                                                        <tr>
                                                                            <td>Access</td>
                                                                            <td>
                                                                                <Text>{tournament.is_public ? 'Public' : 'Private'}</Text>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </Col>

                                                            {tournament.flyer && (
                                                                <Col xs={24} sm={12}>
                                                                    <img
                                                                        src={tournament.flyer}
                                                                        width={'100%'}
                                                                        alt="Flyer"
                                                                        style={{
                                                                            marginLeft: 40,
                                                                            borderRadius: 10,
                                                                            overflow: 'hidden',
                                                                        }}
                                                                    />
                                                                </Col>
                                                            )}
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </TabPane>
                                            <TabPane tab="Time Projections & Stats" key="players">
                                                <Row gutter={16} style={{ maxHeight: 500, overflowY: 'auto' }}>
                                                    <Col xs={24} sm={12}>
                                                        {tournamentStats ? (
                                                            <table width="100%" className="info-table">
                                                                <tbody>
                                                                    {Object.keys(tournamentStats).map((key, index) => {
                                                                        return (
                                                                            <tr key={index}>
                                                                                <td>{displayEnumType(key)}</td>
                                                                                <td>{tournamentStats[key]}</td>
                                                                            </tr>
                                                                        );
                                                                    })}
                                                                </tbody>
                                                            </table>
                                                        ) : (
                                                            <div>You must add at least 1 player to see this data.</div>
                                                        )}
                                                    </Col>

                                                    {tournamentStats && (
                                                        <Col xs={24} sm={12}>
                                                            <Query
                                                                query={GET_TOURNAMENT_TIMING_STATS}
                                                                fetchPolicy="cache-and-network"
                                                                notifyOnNetworkStatusChange
                                                                variables={{ tournament_id: tournament.id }}
                                                                onCompleted={(data) => {
                                                                    console.log(data);

                                                                    // const tournament = data.tournaments[0];
                                                                    // console.log(tournament);
                                                                }}
                                                            >
                                                                {({ loading, error, data }) => {
                                                                    if (loading) return <CircularLoader />;
                                                                    if (error) return <div style={styles.container}>Error: {error.message}</div>;

                                                                    const averageMatchDuration =
                                                                        data &&
                                                                        data.vw_average_match_duration &&
                                                                        data.vw_average_match_duration.map((match) => {
                                                                            return {
                                                                                average_skill_level: match.average_skill_level,
                                                                                average_match_duration: match.average_match_duration,
                                                                                average_game_duration: match.average_game_duration,
                                                                            };
                                                                        })[0];
                                                                    const timeBetweenMatchesByTable =
                                                                        data &&
                                                                        data.vw_time_between_matches_by_table &&
                                                                        data.vw_time_between_matches_by_table.map((table) => {
                                                                            return {
                                                                                table_name: table.table_name,
                                                                                time_between_matches: table.time_between_matches,
                                                                            };
                                                                        });

                                                                    return (
                                                                        <React.Fragment>
                                                                            {averageMatchDuration && (
                                                                                <React.Fragment>
                                                                                    <Title level={4} style={{ fontSize: 14, marginBottom: 15 }}>
                                                                                        Averages
                                                                                    </Title>
                                                                                    <table width="100%" className="info-table">
                                                                                        <tbody>
                                                                                            <tr>
                                                                                                <td>Average Skill Level</td>
                                                                                                <td>{averageMatchDuration.average_skill_level || '-'}</td>
                                                                                            </tr>

                                                                                            <tr>
                                                                                                <td>Average Match Duration</td>
                                                                                                <td>
                                                                                                    {numeral(
                                                                                                        averageMatchDuration.average_match_duration
                                                                                                    ).format('00:00:00') || '-'}
                                                                                                </td>
                                                                                            </tr>

                                                                                            <tr>
                                                                                                <td>Average Game Duration</td>
                                                                                                <td>
                                                                                                    {numeral(averageMatchDuration.average_game_duration).format(
                                                                                                        '00:00:00'
                                                                                                    ) || '-'}
                                                                                                </td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                </React.Fragment>
                                                                            )}

                                                                            <br />

                                                                            {timeBetweenMatchesByTable && timeBetweenMatchesByTable.length > 0 && (
                                                                                <React.Fragment>
                                                                                    <Title level={4} style={{ fontSize: 14, marginBottom: 15 }}>
                                                                                        Time Between Matches By Table
                                                                                    </Title>

                                                                                    <table width="100%" className="info-table">
                                                                                        <tbody>
                                                                                            {timeBetweenMatchesByTable
                                                                                                .sort((a, b) => {
                                                                                                    return a.table_name.localeCompare(b.table_name, undefined, {
                                                                                                        numeric: true,
                                                                                                        sensitivity: 'base',
                                                                                                    });
                                                                                                })
                                                                                                .map((table, index) => {
                                                                                                    return (
                                                                                                        <tr key={index}>
                                                                                                            <td>{table.table_name}</td>
                                                                                                            <td>
                                                                                                                {numeral(table.time_between_matches).format(
                                                                                                                    '00:00:00'
                                                                                                                ) || '-'}
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    );
                                                                                                })}
                                                                                        </tbody>
                                                                                    </table>
                                                                                </React.Fragment>
                                                                            )}
                                                                        </React.Fragment>
                                                                    );
                                                                }}
                                                            </Query>
                                                        </Col>
                                                    )}
                                                </Row>
                                            </TabPane>
                                        </Tabs>
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col xs={24} sm={12}>
                                <Card
                                    title={`Matches (${tournament.tournament_brackets_aggregate.aggregate.count - tournament.bye_matches.aggregate.count})`}
                                    extra={
                                        <Link to={`/tournament-builder/${tournament.slug}/matches`}>
                                            <Button type="default">View All</Button>
                                        </Link>
                                    }
                                    bodyStyle={{ padding: '10px 25px' }}
                                >
                                    <Row gutter={16} justify="start" type="flex">
                                        <Col xs={8}>
                                            <Statistic value={tournament.completed_matches.aggregate.count} title="Completed" valueStyle={styles.statistic} />
                                        </Col>
                                        <Col xs={8}>
                                            <Statistic
                                                value={tournament.in_progress_matches.aggregate.count}
                                                title="In progress"
                                                valueStyle={styles.statistic}
                                            />
                                        </Col>
                                        <Col xs={8}>
                                            <Statistic value={tournament.not_started_matches.aggregate.count} title="Upcoming" valueStyle={styles.statistic} />
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>

                            <Col xs={24} sm={12}>
                                <Card
                                    title={`Players (${tournament.tournament_players_aggregate.aggregate.count})`}
                                    extra={
                                        <Link to={`/tournament-builder/${tournament.slug}/players`}>
                                            <Button type="default">View All</Button>
                                        </Link>
                                    }
                                    bodyStyle={{ padding: '10px 25px' }}
                                >
                                    <Row gutter={16} justify="start" type="flex">
                                        <Col xs={8}>
                                            <Statistic value={tournament.confirmed_players.aggregate.count} title="Confirmed" valueStyle={styles.statistic} />
                                        </Col>
                                        <Col xs={8}>
                                            <Statistic
                                                value={tournament.unconfirmed_players.aggregate.count}
                                                title="Unconfirmed"
                                                valueStyle={styles.statistic}
                                            />
                                        </Col>
                                        <Col xs={8}>
                                            <Statistic
                                                value={tournament.waiting_list_players.aggregate.count}
                                                title="Waiting list"
                                                valueStyle={styles.statistic}
                                            />
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>

                            <Col xs={24} sm={12}>
                                <Card
                                    title={`Tables (${tournament.pool_tables_aggregate.aggregate.count})`}
                                    extra={
                                        <Link to={`/tournament-builder/${tournament.slug}/tables`}>
                                            <Button type="default">View All</Button>
                                        </Link>
                                    }
                                    bodyStyle={{ padding: '10px 25px' }}
                                >
                                    <Row gutter={16} justify="start" type="flex">
                                        <Col xs={8}>
                                            <Statistic value={tournament.in_use_tables.aggregate.count} title="In Use" valueStyle={styles.statistic} />
                                        </Col>
                                        <Col xs={8}>
                                            <Statistic value={tournament.open_tables.aggregate.count} title="Open" valueStyle={styles.statistic} />
                                        </Col>
                                        <Col xs={8}>
                                            <Statistic value={tournament.closed_tables.aggregate.count} title="Closed" valueStyle={styles.statistic} />
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>

                            <Col xs={24} sm={12}>
                                <Card
                                    title="Payouts"
                                    extra={
                                        <Link to={`/tournament-builder/${tournament.slug}/payouts_points`}>
                                            <Button type="default">View All</Button>
                                        </Link>
                                    }
                                    bodyStyle={{ padding: '10px 25px' }}
                                >
                                    <Row gutter={16} justify="start" type="flex">
                                        <Col xs={8}>
                                            <Statistic
                                                value={tournament.confirmed_players.aggregate.count * tournament.entry_fee || 0}
                                                title="Collected"
                                                prefix="$"
                                                valueStyle={styles.statistic}
                                            />
                                        </Col>
                                        <Col xs={8}>
                                            <Statistic value={tournament.entry_fee || ''} title="Entry fee" prefix="$" valueStyle={styles.statistic} />
                                        </Col>
                                        <Col xs={8}>
                                            <Statistic value={tournament.added_money || ''} title="Added money" prefix="$" valueStyle={styles.statistic} />
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                    </Col>

                    {/* <Col xs={24}>
						<Row gutter={16}>
							<Col xs={24} sm={8} md={6} align="center">
								<Link to={`/tournament-builder/${tournament.slug}/settings`}>
									<Card hoverable>
										<Avatar size={48} style={styles.avatar}>
											<i className="icon ion-md-settings" style={{ fontSize: 24 }} />
										</Avatar>
										<Title level={4} style={{ margin: 0, fontWeight: 600 }}>
											Settings
										</Title>
									</Card>
								</Link>
							</Col>
							<Col xs={24} sm={8} md={6} align="center">
								<Link to={`/tournament-builder/${tournament.slug}/schedule`}>
									<Card hoverable>
										<Avatar size={48} style={styles.avatar}>
											<i className="icon ion-md-calendar" style={{ fontSize: 24 }} />
										</Avatar>
										<Title level={4} style={{ margin: 0, fontWeight: 600 }}>
											Schedule
										</Title>
									</Card>
								</Link>
							</Col>
							<Col xs={24} sm={8} md={6} align="center">
								<Link to={`/tournament-builder/${tournament.slug}/bracket`}>
									<Card hoverable>
										<Avatar size={48} style={styles.avatar}>
											<i className="icon ion-md-calendar" style={{ fontSize: 24 }} />
										</Avatar>
										<Title level={4} style={{ margin: 0, fontWeight: 600 }}>
											Bracket
										</Title>
									</Card>
								</Link>
							</Col>
							<Col xs={24} sm={8} md={6} align="center">
								<Link to={`/tournament-builder/${tournament.slug}/scores`}>
									<Card hoverable>
										<Avatar size={48} style={styles.avatar}>
											<Icon
												type="appstore"
												style={{ fontSize: 24, top: 2, position: 'relative' }}
											/>
										</Avatar>
										<Title level={4} style={{ margin: 0, fontWeight: 600 }}>
											Tables
										</Title>
									</Card>
								</Link>
							</Col>
							<Col xs={24} sm={8} md={6} align="center">
								<Link to={`/tournament-builder/${tournament.slug}/results`}>
									<Card hoverable>
										<Avatar size={48} style={styles.avatar}>
											<i className="icon ion-md-trophy" style={{ fontSize: 24 }} />
										</Avatar>
										<Title level={4} style={{ margin: 0, fontWeight: 600 }}>
											Results
										</Title>
									</Card>
								</Link>
							</Col>
							<Col xs={24} sm={8} md={6} align="center">
								<Link to={`/tournament-builder/${tournament.slug}/matches`}>
									<Card hoverable>
										<Avatar size={48} style={styles.avatar}>
											<i className="icon ion-md-play" style={{ fontSize: 24 }} />
										</Avatar>
										<Title level={4} style={{ margin: 0, fontWeight: 600 }}>
											Matches
										</Title>
									</Card>
								</Link>
							</Col>
							<Col xs={24} sm={8} md={6} align="center">
								<Link to={`/tournament-builder/${tournament.slug}/stats`}>
									<Card hoverable>
										<Avatar size={48} style={styles.avatar}>
											<i className="icon ion-md-stats" style={{ fontSize: 24 }} />
										</Avatar>
										<Title level={4} style={{ margin: 0, fontWeight: 600 }}>
											Stats
										</Title>
									</Card>
								</Link>
							</Col>
							<Col xs={24} sm={8} md={6} align="center">
								<Link to={`/tournament-builder/${tournament.slug}/players`}>
									<Card hoverable>
										<Avatar size={48} style={styles.avatar}>
											<i className="icon ion-md-people" style={{ fontSize: 24 }} />
										</Avatar>
										<Title level={4} style={{ margin: 0, fontWeight: 600 }}>
											Players
										</Title>
									</Card>
								</Link>
							</Col>
						</Row>
					</Col> */}
                </Row>
            </SectionContent>
            <SectionFooter {...props} />
            <AddManagerModal
                userId={props.authState.user.id}
                tournamentId={tournament.id}
                tournamentSlug={tournament.slug}
                tournamentName={tournament.name}
                authState={props.authState}
                client={props.client}
                visible={addManagerModalVisible}
                onOk={() => {
                    setAddManagerModalVisible(false);
                }}
                onCancel={() => {
                    setAddManagerModalVisible(false);
                }}
            />
        </SectionWrapper>
    );
}

export default TBDashboard;
