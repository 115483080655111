import React, { useState } from 'react';
import { Link, Route, withRouter } from 'react-router-dom';
import { Row, Col, Table, Typography, Tabs, List, Badge, Tag, Drawer, Empty, Modal, Button } from 'antd';
import Card, { Meta } from '../../../components/Card';
import SectionWrapper from '../../../components/SectionWrapper';
import SectionContent from '../../../components/SectionContent';
import CircularLoader from '../../../components/CircularLoader';
import CircleFlag from '../../../components/CircleFlag';
import { GET_EVENT_TABLES_QUERY } from '../data/queries';
import { getCountryName } from '../../../utils/getCountryName';
import { Query } from '@apollo/client/react/components';
import Flag from '../../../components/Flag';
import _ from 'lodash';

const { TabPane } = Tabs;
const { Title, Text } = Typography;

const styles = {};

function EventTables(props) {
    const { event, tournaments, authState } = props;

    const columns = [
        {
            title: '#',
            dataIndex: 'index',
            key: 'index',
            width: 100,
            sorter: (a, b) => {
                return a.index - b.index;
            },
            render: (text, record) => {
                return <Text>{text || '-'}</Text>;
            },
        },
        {
            title: 'Label',
            dataIndex: 'label',
            key: 'label',
            defaultSortOrder: 'ascend',
            sorter: (a, b) => {
                return (
                    a.label &&
                    a.label.localeCompare(b.label, undefined, {
                        numeric: true,
                        sensitivity: 'base',
                    })
                );
            },
            render: (text, record) => {
                return <Text>{text || '-'}</Text>;
            },
        },
        {
            title: 'Players',
            dataIndex: 'names',
            key: 'names',
            sorter: (a, b) => {
                return (
                    a.names &&
                    a.names.localeCompare(b.names, undefined, {
                        numeric: true,
                        sensitivity: 'base',
                    })
                );
            },
            render: (text, record) => {
                return <Text>{text || '-'}</Text>;
            },
        },
        {
            title: 'Make',
            dataIndex: 'make',
            key: 'make',
            sorter: (a, b) => {
                return (
                    a.make &&
                    a.make.localeCompare(b.make, undefined, {
                        numeric: true,
                        sensitivity: 'base',
                    })
                );
            },
            render: (text, record) => {
                return <Text>{text || '-'}</Text>;
            },
        },
        {
            title: 'Size',
            dataIndex: 'size',
            key: 'size',
            sorter: (a, b) => {
                return (
                    a.size &&
                    a.size.localeCompare(b.size, undefined, {
                        numeric: true,
                        sensitivity: 'base',
                    })
                );
            },
            render: (text, record) => {
                return <Text>{text || '-'}</Text>;
            },
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            sorter: (a, b) => {
                return (
                    a.type &&
                    a.type.localeCompare(b.type, undefined, {
                        numeric: true,
                        sensitivity: 'base',
                    })
                );
            },
            render: (text, record) => {
                return <Text>{record.type} Table</Text>;
            },
        },

        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            sorter: (a, b) => {
                return (
                    a.status &&
                    a.status.localeCompare(b.status, undefined, {
                        numeric: true,
                        sensitivity: 'base',
                    })
                );
            },
            render: (text, record) => {
                return <Text>{text || '-'}</Text>;
            },
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            render: (text, record) => {
                return (
                    <div>
                        <Link to={`/events/${event.slug}/tables/${record.slug}/scoring`} target="_blank" rel="noopener noreferrer">
                            <Button type="ghost" size="large">
                                Tablet Scoring
                            </Button>
                        </Link>

                        <Link to={`/events/${event.slug}/tables/${record.slug}/tvdisplay`} target="_blank" rel="noopener noreferrer">
                            <Button type="ghost" size="large" style={{ marginLeft: 10 }}>
                                TV Display
                            </Button>
                        </Link>
                    </div>
                );
            },
        },
    ];

    function getChallengerRaceTo(currentMatch, challenger) {
        const identifier = currentMatch.identifier && currentMatch.identifier.slice(0, 1);
        const challenger_race_to =
            challenger === 'challenger1' ? currentMatch.challenger1_race_to : challenger === 'challenger2' ? currentMatch.challenger2_race_to : null;
        if (challenger_race_to) {
            return challenger_race_to;
        }
    }

    return (
        <SectionWrapper>
            <SectionContent padding="14px 40px">
                <Row gutter={24} justify="center" type="flex">
                    <Col xs={24}>
                        <Query
                            query={GET_EVENT_TABLES_QUERY}
                            fetchPolicy="cache-and-network"
                            notifyOnNetworkStatusChange={true}
                            variables={{ id: event.id }}
                            onCompleted={(data) => {
                                // var set = [];
                                // for (let i = 0; i < data.tournament_payouts.length; i++) {
                                // 	set.push(data.tournament_payouts[i]);
                                // }
                                // let formattedData = orderAndFormatData(set);
                                // setTableData(formattedData);
                            }}
                        >
                            {({ loading, error, data, refetch }) => {
                                if (loading) return <CircularLoader />;
                                if (error) return <div style={styles.container}>Error: {error.message}</div>;

                                let eventTables = [];
                                const events = data && data.events;
                                console.log(events);

                                events &&
                                    events.forEach((event) => {
                                        const eventMatches = event.matches;
                                        const eventTournaments = event.tournaments;

                                        eventMatches.forEach((match) => {
                                            if (match.pool_table) {
                                                eventTables.push({
                                                    ...match.pool_table,
                                                    match: match,
                                                    names: `${match.player_name} vs. ${match.opponent_name}`,
                                                    type: 'Match',
                                                });
                                            }
                                        });

                                        eventTournaments.forEach((tournament) => {
                                            if (tournament.pool_tables) {
                                                tournament.pool_tables.forEach((table) => {
                                                    if (table.tournament_match_table && table.tournament_match_table[0]) {
                                                        const match = table.tournament_match_table[0];
                                                        eventTables.push({
                                                            ...table,
                                                            tournament: tournament,
                                                            match: match,
                                                            names: `${match.challenger1_name} vs. ${match.challenger2_name}`,
                                                            type: 'Tournament',
                                                        });
                                                    } else {
                                                        eventTables.push({
                                                            ...table,
                                                            tournament: tournament,
                                                            type: 'Tournament',
                                                        });
                                                    }
                                                });
                                            }
                                        });
                                    });

                                {
                                    /* const uniqueMatches = _.uniqBy(matches, 'id').sort((a, b) => {
                                    if (a.table_name && b.table_name) {
                                        return a.table_name.localeCompare(b.table_name, undefined, {
                                            numeric: true,
                                            sensitivity: 'base',
                                        });
                                    } else if (a.table.label && b.table.label) {
                                        return a.table.label.localeCompare(b.table.label, undefined, {
                                            numeric: true,
                                            sensitivity: 'base',
                                        });
                                    }
                                }); */
                                }

                                const tables =
                                    eventTables &&
                                    eventTables.map((table, index) => {
                                        return {
                                            ...table,
                                            index: index + 1,
                                        };
                                    });

                                console.log(tables);

                                return (
                                    <Card title="Event Tables" bodyStyle={{ padding: 0 }}>
                                        <Table
                                            rowKey="index"
                                            onRow={(record, rowIndex) => {
                                                return {
                                                    onClick: (event) => {
                                                        console.log(record, rowIndex);
                                                        if (record.tournament) {
                                                            props.history.push({
                                                                pathname: `/tournament-builder/${record.tournament.slug}/tables/${record.slug}`,
                                                            });
                                                        } else {
                                                            props.history.push({
                                                                pathname: `/matches/${record.match.slug}`,
                                                            });
                                                        }
                                                    }, // click row
                                                    onDoubleClick: (event) => {}, // double click row
                                                    onContextMenu: (event) => {}, // right button click row
                                                    onMouseEnter: (event) => {}, // mouse enter row
                                                    onMouseLeave: (event) => {}, // mouse leave row
                                                };
                                            }}
                                            pagination={false}
                                            className="match-table table-striped-rows"
                                            columns={columns}
                                            dataSource={tables}
                                            size="default"
                                            tableLayout="auto"
                                            scroll={{ x: 400 }}
                                            // pagination={{ pageSize: 25 }}
                                        />
                                    </Card>
                                );
                            }}
                        </Query>
                    </Col>
                </Row>
            </SectionContent>
        </SectionWrapper>
    );
}

export default withRouter(EventTables);
