import React from 'react';
import { Table, Typography, Row, Col, Empty } from 'antd';
import SectionContent from '../../../components/SectionContent';
import SectionTitle from '../../../components/SectionTitle';
import SectionWrapper from '../../../components/SectionWrapper';
import Card, { Meta } from '../../../components/Card';
import CircularLoader from '../../../components/CircularLoader';
import CircleFlag from '../../../components/CircleFlag';
import blueBg from '../../../assets/img/blue-bg.png';
import { getCountryName } from '../../../utils';
import ZeroState from '../../../components/ZeroState';
import { isoCountries } from '../../../utils/isoCountryCodes';
import { Query, Mutation } from '@apollo/client/react/components';
import { GET_LEAGUE_DIVISION_PLAYERS_QUERY } from '../data/queries';

const { Title, Text } = Typography;

const columns = [
    {
        title: '#',
        dataIndex: 'index',
        width: 80,
        sorter: (a, b) => {
            return a.index - b.index;
        },
    },
    {
        title: 'Name',
        dataIndex: 'name',
        defaultSortOrder: 'ascend',
        sorter: (a, b) => {
            return a.name && a.name.localeCompare(b.name);
        },
        render: (text, record) => {
            return (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    {record.country ? (
                        <React.Fragment>
                            <CircleFlag country={record.country} left={0} />
                            <Text>{text}</Text>
                        </React.Fragment>
                    ) : (
                        <Text>{text}</Text>
                    )}
                </div>
            );
        },
    },
    // {
    // 	title: 'Divisions',
    // 	dataIndex: 'divisions',
    // 	sorter: (a, b) => {
    // 		return a.divisions.localeCompare(b.divisions, undefined, {
    // 			numeric: true,
    // 			sensitivity: 'base'
    // 		});
    // 	},
    // 	render: (text, record) => <React.Fragment>{text || '-'}</React.Fragment>
    // },

    {
        title: 'Skill Level',
        dataIndex: 'skill_level',
        sorter: (a, b) => a.skill_level - b.skill_level,
        render: (text, record) => <React.Fragment>{text || '-'}</React.Fragment>,
    },
    // {
    // 	title: 'Race to',
    // 	dataIndex: 'race_to',
    // 	sorter: (a, b) => a.race_to - b.race_to,
    // 	render: (text, record) => <React.Fragment>{text || '-'}</React.Fragment>
    // }
];

const styles = {
    headerImg: {
        height: 120,
        overflow: 'hidden',
        borderRadius: 0,
        background: `linear-gradient(rgba(61, 185, 248, 0.8), rgba(24, 144, 255, 0.1)), url(${blueBg}) 0px 0px no-repeat`,
        // backgroundAttachment: 'fixed',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100%',
        display: 'flex',
        alignItems: 'center',
        paddingLeft: 40,
        paddingRight: 40,
        // backgroundPosition: '0px -40px'
    },
};

export default function DPDivisionPlayers(props) {
    const { league, division } = props;
    return (
        <SectionWrapper>
            {/* <SectionTitle
				title="Players"
				titleStyle={{ color: '#fff', margin: 0, textTransform: 'uppercase' }}
				height={120}
			/> */}
            <SectionContent padding="20px 40px">
                <Row
                    gutter={24}
                    justify="center"
                    type="flex"
                    // style={{ padding: 24, position: 'relative', marginTop: -120 }}
                >
                    <Col xs={24} sm={props.navType === 'desktop' ? 24 : 22}>
                        <Card
                            cover={
                                <div
                                    style={{
                                        paddingTop: 16,
                                        paddingBottom: 16,
                                        paddingLeft: 24,
                                        paddingRight: 24,
                                        // background:'linear-gradient(rgb(239, 243, 245), rgb(255, 255, 255))',
                                        borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
                                    }}
                                >
                                    <Title level={4} style={{ margin: 0, padding: 0 }}>
                                        Division players
                                    </Title>
                                </div>
                            }
                            bodyStyle={{ padding: 0 }}
                            // style={{
                            // 	width: '100%',
                            // 	borderRadius: 8,
                            // 	overflow: 'hidden',
                            // 	border: 'none',
                            // 	boxShadow: '0 2px 5px 0px rgba(0, 0, 0, 0.1)'
                            // }}
                        >
                            <Query
                                query={GET_LEAGUE_DIVISION_PLAYERS_QUERY}
                                fetchPolicy="cache-and-network"
                                notifyOnNetworkStatusChange
                                awaitRefetchQueries
                                variables={{ league_id: league.id, division_id: division.id }}
                                onCompleted={(data) => {
                                    console.log(data);
                                }}
                            >
                                {({ loading, error, data }) => {
                                    if (loading) return <CircularLoader />;
                                    if (error) return <div style={styles.container}>Error: {error.message}</div>;
                                    if ((data && !data.league_players) || (data && data.league_players.length === 0)) {
                                        return <ZeroState showImage message="No Players have been added yet." style={{ margin: 30 }} {...props} />;
                                    }

                                    return (
                                        <Table
                                            columns={columns}
                                            dataSource={
                                                data.league_players &&
                                                data.league_players.map((player, index) => {
                                                    return {
                                                        ...player,
                                                        divisions: player.league_player_divisions
                                                            .map((item) => {
                                                                return item.division.name;
                                                            })
                                                            .join(', '),
                                                        index: index + 1,
                                                        key: index,
                                                    };
                                                })
                                            }
                                            pagination={false}
                                            tableLayout="auto"
                                            scroll={{ x: 400 }}
                                            // pagination={{ pageSize: 25 }}

                                            // bordered
                                        />
                                    );
                                }}
                            </Query>
                        </Card>
                    </Col>
                </Row>
            </SectionContent>
        </SectionWrapper>
    );
}
