import React, { useEffect, useState } from 'react';
import { message, Row, Col, Avatar, Descriptions, Alert, Switch, Modal, Statistic, List, Typography, Button, Empty, Icon, Tag } from 'antd';
import Card, { Meta } from '../../components/Card';
import { Link } from 'react-router-dom';
import { Query, Mutation } from '@apollo/client/react/components';
import SectionHeader from '../../components/SectionHeader';
import SectionContent from '../../components/SectionContent';
import SectionWrapper from '../../components/SectionWrapper';
import SectionFooter from '../../components/SectionFooter';
import FeedPostList from './feed/FeedPostList';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { GET_DIVISIONS_COUNT_QUERY, GET_TEAMS_COUNT_QUERY, GET_PLAYERS_COUNT_QUERY, GET_VENUES_COUNT_QUERY, GET_LEAGUE_QUERY } from './data/queries';
import { RESTORE_LEAGUE_MUTATION, UPDATE_LEAGUE_MUTATION } from './data/mutations';

const { Text, Title } = Typography;
const { confirm } = Modal;

export default function LMDashboard(props) {
    const { league, match, history, authState } = props;
    const slug = match.params.slug;
    const [restoreLeague, { loading, error }] = useMutation(RESTORE_LEAGUE_MUTATION);
    const [isPublic, setIsPublic] = useState(league.is_public);
    const [updateLeagueAccess] = useMutation(UPDATE_LEAGUE_MUTATION);

    useEffect(() => {
        if (league.deleted_at) {
            confirm({
                title: 'This league currently deleted and hidden from public view and search results',
                content: `Would you like to restore it?`,
                centered: true,
                okText: 'Restore',
                cancelText: 'Not now',
                onOk() {
                    restoreLeague({
                        variables: {
                            id: league.id,
                            owner_id: authState.user.id,
                        },
                        notifyOnNetworkStatusChange: true,
                        awaitRefetchQueries: true,
                        refetchQueries: [
                            {
                                query: GET_LEAGUE_QUERY,
                                variables: { slug: league.slug },
                            },
                        ],
                    })
                        .then((data) => {
                            console.log(data);
                            message.success('League restored!');
                        })
                        .catch((error) => {
                            console.log(error);
                            message.error('There was an error', error);
                        });
                },
                onCancel() {
                    console.log('Cancel');
                },
            });

            // Modal.warning({
            // 	title: 'This league is in a deleted state',
            // 	content: `Would you like to undo this action?`,
            // 	centered: true,
            // 	okText: 'Undo',
            // 	cancelText: 'Cancel',
            // 	onOk: () => {
            // 		console.log('ok');
            // 	},
            // 	onCancel: () => {
            // 		console.log('cancel');
            // 	}
            // });
        }
    }, [league]);

    function onToggleAccess(checked) {
        // console.log(`switch to ${checked}`);
        setIsPublic(checked);

        return updateLeagueAccess({
            variables: {
                id: league.id,
                changes: {
                    is_public: checked,
                },
            },
            notifyOnNetworkStatusChange: true,
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: GET_LEAGUE_QUERY,
                    variables: { id: league.id },
                },
            ],
        })
            .then((data) => {
                console.log(data);
                message.success(`Access is set to ${checked === true ? 'Public' : 'Private'}`);
                return data;
            })
            .catch((error) => {
                console.error(error);
                message.info('There was an error', error);
                return error;
            });
    }

    function formatPhoneNumber(phoneNumberString) {
        var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
        var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            var intlCode = match[1] ? '+1 ' : '';
            return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
        }
        return null;
    }

    return (
        <SectionWrapper>
            <SectionHeader
                // avatar={<Avatar icon="global" size={30} src={props.avatar} style={{ marginRight: 10 }} />}
                // titleExtra={<Tag style={{ marginTop: 3 }}>PUBLIC</Tag>}
                title={props.title}
                titleStyle={{ color: '#fff', margin: 0, textTransform: 'uppercase' }}
                avatar={league.avatar}
                subtitle={league.name}
                subtitlePrefix={true}
                subtitleStyle={{
                    color: 'rgba(255,255,255,0.8)',
                    textTransform: 'uppercase',
                    fontWeight: 600,
                    fontSize: 12,
                    letterSpacing: 3,
                }}
                theme="leagueManager"
            >
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                    <Link to={`/league-manager/${props.match.params.slug}/settings`} style={{ marginRight: 10 }}>
                        <Button type="ghost" ghost>
                            <Icon type="setting" /> Settings
                        </Button>
                    </Link>
                    <Link to={`/leagues/${props.match.params.slug}`}>
                        <Button type="ghost" ghost>
                            Public Page
                        </Button>
                    </Link>
                </div>
            </SectionHeader>
            <SectionContent padding="20px 40px">
                <Row gutter={24} justify="center" type="flex">
                    <Col xs={24}>
                        <div>
                            {isPublic === true ? (
                                <Alert
                                    message={
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                            <Text>Access to your league is currently public and visible on the main /leagues page.</Text>
                                            <div style={{ marginRight: 20 }}>
                                                Public <Switch defaultChecked={isPublic} onChange={onToggleAccess} style={{ marginLeft: 5 }} />
                                            </div>
                                        </div>
                                    }
                                    type="info"
                                    closable
                                    showIcon
                                />
                            ) : (
                                <Alert
                                    message={
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                            <Text>Access to your league is currently private and not visible on the main /leagues page.</Text>
                                            <div style={{ marginRight: 20 }}>
                                                Private <Switch defaultChecked={isPublic} onChange={onToggleAccess} style={{ marginLeft: 5 }} />
                                            </div>
                                        </div>
                                    }
                                    type="warning"
                                    closable
                                    showIcon
                                />
                            )}
                        </div>
                    </Col>
                    <Col xs={22} sm={props.navType === 'desktop' ? 24 : 24} style={{ paddingTop: 0, paddingBottom: 0 }}>
                        <Row gutter={24} justify="center" type="flex">
                            <Col xs={24} sm={6}>
                                <Row gutter={[16, 16]} justify="center" type="flex">
                                    <Col xs={24}>
                                        {/* {league.logo && (
                                            <Col xs={24}>
                                                <Card>
                                                    <div
                                                        style={{
                                                            // height: 140,
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                        }}
                                                    >
                                                        <img
                                                            alt="Logo"
                                                            src={league.logo}
                                                            style={{
                                                                width: '100%',
                                                                borderRadius: 6,
                                                                overflow: 'hidden',
                                                            }}
                                                        />
                                                    </div>
                                                </Card>
                                            </Col>
                                        )} */}
                                        <Card
                                            cover={
                                                <React.Fragment>
                                                    <div>
                                                        {league.logo && (
                                                            <div
                                                                style={{
                                                                    minHeight: 160,
                                                                    borderRadius: 8,
                                                                    height: 'auto',
                                                                    overflow: 'hidden',
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                }}
                                                            >
                                                                <img src={league.logo} alt="Logo" style={{ width: '100%' }} />
                                                            </div>
                                                        )}
                                                    </div>
                                                </React.Fragment>
                                            }
                                        >
                                            <Meta
                                                title={<Title level={4}>{league.name}</Title>}
                                                description={
                                                    <div
                                                        style={{
                                                            whiteSpace: 'pre-wrap',
                                                        }}
                                                    >
                                                        {league.long_description ? league.long_description : league.short_description}
                                                    </div>
                                                }
                                            />

                                            <Descriptions
                                                // title="League Details"
                                                size="small"
                                                column={1}
                                                bordered={false}
                                                layout="vertical"
                                                style={{ marginTop: 20 }}
                                            >
                                                {league.address1 ||
                                                    league.city ||
                                                    league.region ||
                                                    (league.postalCode && (
                                                        <Descriptions.Item label="Location">
                                                            <React.Fragment>
                                                                {league.address1}
                                                                {league.address2 && (
                                                                    <React.Fragment>
                                                                        <br />
                                                                        {league.address2}
                                                                    </React.Fragment>
                                                                )}
                                                                <br />
                                                                {league.city}, {league.region} {league.postalCode}
                                                                <br />
                                                                {league.country}
                                                            </React.Fragment>
                                                        </Descriptions.Item>
                                                    ))}

                                                {league.phone_number ||
                                                    league.email_address ||
                                                    (league.website && (
                                                        <Descriptions.Item label="Contact Info">
                                                            {formatPhoneNumber(league.phone_number)} <br />
                                                            <a href={`mailto:${league.email_address}`} target="_blank" rel="noopener noreferrer">
                                                                {league.email_address}
                                                            </a>{' '}
                                                            <br />
                                                            <a href={league.website} target="_blank" rel="noopener noreferrer">
                                                                {league.website}
                                                            </a>
                                                        </Descriptions.Item>
                                                    ))}

                                                {league.operator && (
                                                    <React.Fragment>
                                                        <Descriptions.Item label="League Operator">
                                                            <Link to={`/users/${league.operator.slug}`}>
                                                                {league.operator.first_name} {league.operator.last_name}
                                                            </Link>
                                                        </Descriptions.Item>
                                                    </React.Fragment>
                                                )}
                                            </Descriptions>
                                        </Card>
                                    </Col>

                                    <Col xs={12}>
                                        <Link to={`/league-manager/${props.match.params.slug}/divisions`}>
                                            <Card hoverable>
                                                <Statistic
                                                    title="Divisions"
                                                    value={(league.league_divisions_aggregate && league.league_divisions_aggregate.aggregate.count) || 0}
                                                />
                                            </Card>
                                        </Link>
                                    </Col>
                                    <Col xs={12}>
                                        <Link to={`/league-manager/${props.match.params.slug}/teams`}>
                                            <Card hoverable>
                                                <Statistic
                                                    title="Teams"
                                                    value={(league.league_teams_aggregate && league.league_teams_aggregate.aggregate.count) || 0}
                                                />
                                            </Card>
                                        </Link>
                                    </Col>
                                    <Col xs={12}>
                                        <Link to={`/league-manager/${props.match.params.slug}/players`}>
                                            <Card hoverable>
                                                <Statistic
                                                    title="Players"
                                                    value={(league.league_players_aggregate && league.league_players_aggregate.aggregate.count) || 0}
                                                />
                                            </Card>
                                        </Link>
                                    </Col>
                                    <Col xs={12}>
                                        <Link to={`/league-manager/${props.match.params.slug}/venues`}>
                                            <Card hoverable>
                                                <Statistic
                                                    title="Venues"
                                                    value={(league.league_venues_aggregate && league.league_venues_aggregate.aggregate.count) || 0}
                                                />
                                            </Card>
                                        </Link>
                                    </Col>
                                    {league.organization && (
                                        <Col xs={24}>
                                            <Card title="Organization" bodyStyle={{ padding: 0 }}>
                                                <List
                                                    className="hover-list"
                                                    size="small"
                                                    itemLayout="horizontal"
                                                    dataSource={[league.organization]}
                                                    renderItem={(item) => (
                                                        <List.Item
                                                            // actions={[ <a key="list-loadmore-edit">View</a> ]}
                                                            style={{ padding: '10px 16px' }}
                                                            onClick={() => history.push(`/organizations/${item.slug}`)}
                                                        >
                                                            <List.Item.Meta
                                                                avatar={
                                                                    <div>
                                                                        <Icon
                                                                            type="bank"
                                                                            style={{
                                                                                color: '#1890ff',
                                                                                fontSize: 30,
                                                                            }}
                                                                        />
                                                                    </div>
                                                                }
                                                                title={
                                                                    <Text
                                                                        style={{
                                                                            fontSize: 16,
                                                                        }}
                                                                    >
                                                                        {item.name}
                                                                    </Text>
                                                                }
                                                                description={<div>{item.short_description}</div>}
                                                            />
                                                        </List.Item>
                                                    )}
                                                />
                                            </Card>
                                        </Col>
                                    )}
                                </Row>
                            </Col>

                            <Col xs={24} sm={18}>
                                <FeedPostList league={league} {...props} />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </SectionContent>
            <SectionFooter {...props} />
        </SectionWrapper>
    );
}
