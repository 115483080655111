import React, { useState } from 'react';
import { message, Icon, Row, Col, Typography, Button, Input, Tabs, Empty } from 'antd';
import Card from '../../components/Card';
import { Link } from 'react-router-dom';
import CircularLoader from '../../components/CircularLoader';
import SectionHeader from '../../components/SectionHeader';
import SectionContent from '../../components/SectionContent';
import SectionWrapper from '../../components/SectionWrapper';
import SectionFooter from '../../components/SectionFooter';
import { GET_LEAGUE_MATCHES_QUERY } from './data/queries';
import { DELETE_LEAGUE_MATCH_MUTATION } from './data/mutations';
import { Query } from '@apollo/client/react/components';
import ZeroState from '../../components/ZeroState';
import MatchesTable from './MatchesTable';
import AddMatchModal from './AddMatchModal';
import { useMutation, useQuery } from '@apollo/react-hooks';
import _ from 'lodash';

const { Text, Title } = Typography;

const styles = {
    row: {
        display: 'flex',
        flexGrow: 1,
    },
    tab: {
        paddingTop: 5,
        paddingLeft: 24,
        paddingRight: 24,
        paddingBottom: 24,
    },
};

export default function LMMatches(props) {
    const { league, authState } = props;
    const [selectedKeys, setSelectedKeys] = useState([]);
    const [selectedMatchId, setSelectedMatchId] = useState();
    const [modalVisible, setModalVisible] = useState(false);
    const [deleteLeagueMatch] = useMutation(DELETE_LEAGUE_MATCH_MUTATION);

    function handleSelected(selected) {
        console.log(selected);
        setSelectedKeys(selected);
    }

    function handleEditSelected(selected) {
        // console.log(selected);
        setSelectedMatchId(selected);
        setModalVisible(true);
    }

    const hasSelected = selectedKeys && selectedKeys.length > 0;

    function handleModalOk(e) {
        console.log(e);
        setModalVisible(false);
    }

    function handleModalCancel(e) {
        setModalVisible(false);
    }

    function handleAddMatch() {
        setModalVisible(true);
        setSelectedMatchId(null);
    }

    return (
        <SectionWrapper>
            <SectionHeader
                title={props.title}
                titleStyle={{ color: '#fff', margin: 0, textTransform: 'uppercase' }}
                avatar={league.avatar}
                subtitle={league.name}
                subtitlePrefix={true}
                subtitleStyle={{
                    color: 'rgba(255,255,255,0.8)',
                    textTransform: 'uppercase',
                    fontWeight: 600,
                    fontSize: 12,
                    letterSpacing: 3,
                }}
                theme="leagueManager"
            >
                <Link to={`/leagues/${props.match.params.slug}`}>
                    <Button type="ghost" ghost>
                        Public Profile
                    </Button>
                </Link>
            </SectionHeader>
            <SectionContent padding="20px 40px">
                <Row gutter={16} justify="center" type="flex">
                    <Col xs={24}>
                        <Card
                            title={`All Matches (${league.matches_aggregate.aggregate.count})`}
                            extra={
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <Button type="primary" onClick={handleAddMatch}>
                                        + Create Match
                                    </Button>
                                    <div style={{ marginLeft: 10 }}>
                                        <Button type="danger" disabled={!hasSelected}>
                                            Remove
                                        </Button>
                                        <span style={{ marginLeft: 8 }}>{hasSelected ? `Selected ${selectedKeys.length} items` : ''}</span>
                                    </div>
                                </div>
                            }
                            bodyStyle={{ padding: 0 }}
                        >
                            <Query
                                query={GET_LEAGUE_MATCHES_QUERY}
                                fetchPolicy="cache-and-network"
                                notifyOnNetworkStatusChange
                                awaitRefetchQueries
                                variables={{
                                    league_id: league.id,
                                }}
                                onCompleted={(data) => {
                                    // console.log(data);
                                }}
                            >
                                {({ loading, error, data }) => {
                                    if (loading) return <CircularLoader />;
                                    if (error) return <div style={styles.container}>Error: {error.message}</div>;
                                    if ((data && !data.matches) || (data && data.matches.length === 0)) {
                                        return <ZeroState showImage message="No matches have been created yet." style={{ margin: 30 }} {...props} />;
                                    }

                                    return (
                                        <div className="table_nowrap">
                                            <MatchesTable
                                                {...props}
                                                onSelected={handleSelected}
                                                onEditSelected={handleEditSelected}
                                                onDelete={(record) => {
                                                    deleteLeagueMatch({
                                                        variables: {
                                                            league_id: league.id,
                                                            id: record.id,
                                                        },
                                                        notifyOnNetworkStatusChange: true,
                                                        awaitRefetchQueries: true,
                                                        refetchQueries: [
                                                            {
                                                                query: GET_LEAGUE_MATCHES_QUERY,
                                                                variables: { league_id: league.id },
                                                            },
                                                        ],
                                                    })
                                                        .then((data) => {
                                                            console.log(data);
                                                            message.success('Match deleted');
                                                        })
                                                        .catch((error) => {
                                                            console.log(error);
                                                            message.info('There was an error', error);
                                                        });
                                                }}
                                                matches={
                                                    data.matches &&
                                                    data.matches.map((item, index) => {
                                                        const players = _.compact(
                                                            item.match_players &&
                                                                item.match_players.length > 0 &&
                                                                item.match_players.map((player) => player.name)
                                                        );
                                                        return {
                                                            ...item,
                                                            index: index + 1,
                                                            division: item.division,
                                                            players: players && players.length > 0 ? players.join(', ') : null,
                                                            key: index,
                                                        };
                                                    })
                                                }
                                            />
                                        </div>
                                    );
                                }}
                            </Query>
                        </Card>
                    </Col>
                </Row>
            </SectionContent>
            <SectionFooter {...props} />

            <AddMatchModal
                {...props}
                selectedMatchId={selectedMatchId}
                userID={authState.user.id}
                leagueID={league.id}
                modalVisible={modalVisible}
                onModalOk={handleModalOk}
                onModalCancel={handleModalCancel}
            />
        </SectionWrapper>
    );
}
