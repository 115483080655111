import React, { useEffect, useState } from 'react';
import { Avatar, Typography } from 'antd';
import firstPlaceImg from '../../../../assets/img/podium-1st.png';
import secondPlaceImg from '../../../../assets/img/podium-2nd.png';
import thirdPlaceImg from '../../../../assets/img/podium-3rd.png';
import { GET_GHOST_GAMES_RESULTS } from '../../data/queries';
import './podium.css';
import CircularLoader from '../../../../components/CircularLoader';
import { Query } from '@apollo/react-components';

const { Text } = Typography;

export default function ResultsPodium(props) {
    const { client, ghostEventId } = props;

    return (
        <React.Fragment>
            <Query
                query={GET_GHOST_GAMES_RESULTS}
                fetchPolicy="cache-and-network"
                variables={{
                        id: ghostEventId
                    }
                }
            >
            {({loading, error, data}) => {
                if(loading) return <CircularLoader />
                if(error) return error;

                var _firstPlaceName = '';
                var _firstPlacePayout = '';

                var _secondPlaceName = '';
                var _secondPlacePayout = '';

                var _thirdPlaceName1 = '';
                var _thirdPlacePayout = '';

                const ghostData = data && data.ghost_games && data.ghost_games[0];

                if(ghostData.status === 'COMPLETED') {
                    if(ghostData.ghost_games_payouts && ghostData.ghost_games_payouts[0] && ghostData.ghost_games_payouts[0].place === '1') {
                        _firstPlaceName = ghostData.ghost_games_players && ghostData.ghost_games_players[0] && ghostData.ghost_games_players[0].user.display_name;
                    }

                    if(ghostData.ghost_games_payouts && ghostData.ghost_games_payouts[1] && ghostData.ghost_games_payouts[1].place === '2') {
                        _secondPlaceName = ghostData.ghost_games_players && ghostData.ghost_games_players[1] && ghostData.ghost_games_players[1].user.display_name;
                    }

                    if(ghostData.ghost_games_payouts && ghostData.ghost_games_payouts[2] && ghostData.ghost_games_payouts[2].place === '3') {
                        _thirdPlaceName1 = ghostData.ghost_games_players && ghostData.ghost_games_players[2] && ghostData.ghost_games_players[2].user.display_name;
                    }
                }

                _firstPlacePayout = ghostData.ghost_games_payouts && ghostData.ghost_games_payouts[0] && ghostData.ghost_games_payouts[0].amount;
                _secondPlacePayout = ghostData.ghost_games_payouts && ghostData.ghost_games_payouts[1] && ghostData.ghost_games_payouts[1].amount;
                _thirdPlacePayout = ghostData.ghost_games_payouts && ghostData.ghost_games_payouts[2] && ghostData.ghost_games_payouts[2].amount;
                
                return (
                    <div className="podium" style={props.style}>
                        <div className="place position2">
                            <div className="participant">
                                <div className="info">
                                    <Avatar src={secondPlaceImg} />
                                    <Text className="name">{_secondPlaceName}</Text>
                                </div>
                            </div>
                            <div className="base" style={{ borderRadius: '6px 0px 0px 0px' }} />
                            <div className="number">{_secondPlacePayout}</div>
                        </div>
                        <div className="place position1">
                            <div className="participant">
                                <div className="info">
                                    <Avatar src={firstPlaceImg} />
                                    <Text className="name">{_firstPlaceName}</Text>
                                </div>
                            </div>
                            <div className="base" />
                            <div className="number">
                                <b>{_firstPlacePayout}</b>
                            </div>
                        </div>
                        <div className="place position3">
                            <div className="participant">
                                <div className="info">
                                    <Avatar src={thirdPlaceImg} />
                                    <Text className="name">{_thirdPlaceName1}</Text>
                                </div>
                            </div>
                            <div className="base" />
                            <div className="number">{_thirdPlacePayout}</div>
                        </div>
                    </div>
                );
            }}
            </Query>
        </React.Fragment>
    );
}