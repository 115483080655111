import React, { useEffect, useLayoutEffect, useState } from 'react';
import { message } from 'antd';

//refer to figma to fill these in:
// https://www.figma.com/file/dzzJqut0WAvsIvXpLEyekF/LunarCRUSH-Design-System?node-id=138%3A0
export const colors = {
    black: '#000000',
    white: '#FFFFFF',
    gray50: '#F5FAFA',
    gray100: '#E1E5E6',
    gray200: '#C2CBCC',
    gray300: '#A4B1B3',
    gray400: '#859799',
    gray500: '#677D80',
    gray600: '#4D5E60',
    gray700: '#343F40',
    gray800: '#1A1F20',
    gray900: '#0A0D0D',
    primary100: '#D0F4FF',
    primary200: '#A0E9FF',
    primary300: '#71DDFF',
    primary400: '#41D2FF',
    primary500: '#12C7FF',
    primary50: '#E8FAFF',
    primary600: '#0E95BF',
    primary700: '#096480',
    primary800: '#053240',
    primary900: '#02141A',
    secondary100: '#E2DCF7',
    secondary200: '#C5BAEF',
    secondary300: '#A797E6',
    secondary400: '#8A75DE',
    secondary500: '#6D52D6',
    secondary50: '#F1EEFB',
    secondary600: '#523EA1',
    secondary700: '#37296B',
    secondary800: '#1B1536',
    secondary900: '#0B0815',
    success100: '#D8F4DF',
    success200: '#B0EABF',
    success300: '#89DFA0',
    success400: '#61D580',
    success500: '#3ACA60',
    success50: '#ECFAF0',
    success600: '#2C9848',
    success700: '#1D6530',
    success800: '#0F3318',
    success900: '#06140A',
    tertiary50: '#FBECEE',
    tertiary100: '#F6D7DD',
    tertiary200: '#EEAFBA',
    tertiary300: '#E58798',
    tertiary400: '#DD5F75',
    tertiary500: '#D43753',
    tertiary600: '#9F293E',
    tertiary700: '#6A1C2A',
    tertiary800: '#350E15',
    tertiary900: '#150608',
    error100: '#FDCCDD',
    error200: '#FB99BC',
    error300: '#F9669A',
    error400: '#F73379',
    error500: '#F50057',
    error50: '#FFE6EF',
    error600: '#B80041',
    error700: '#7B002C',
    error800: '#3D0016',
    error900: '#190009',
    warning50: '#FFFBEC',
    warning100: '#FFF6D9',
    warning200: '#FFEDB2',
    warning300: '#FFE48C',
    warning400: '#FFDB65',
    warning500: '#FFD23F',
    warning600: '#BF9E2F',
    warning700: '#806920',
    warning800: '#403510',
    warning900: '#1A1506',
};
colors.body = colors.gray50;
colors.background = colors.gray800;

const reverseColorMap = {
    50: '900',
    100: '800',
    200: '700',
    300: '600',
    400: '500',
    500: '400',
    600: '300',
    700: '200',
    800: '100',
    900: '50',
};
function reverseTheColors() {
    let newColors = {};
    Object.keys(colors).forEach((colorCode) => {
        let num = colorCode.replace(/[^0-9]/gi, '');
        let name = colorCode.replace(/[0-9]/gi, '');
        newColors[name + num] = colors[name + reverseColorMap[num]];
    });
    return newColors;
}

const themes = {
    dark: {
        name: 'dark',
        colors: colors,
        layout: {
            headerHeight: 47,
            bottomMenuHeight: 78,
        },
        bodyTitle: {
            color: colors.white,
        },
        bodyText: {
            color: 'rgba(255, 255, 255, 0.7)',
        },
        borders: {
            common: {
                size: 1,
                color: colors.gray50 + '7d',
            },
        },
        shadows: {
            text: { textShadowColor: 'rgba(0,0,0,0.5)', textShadowRadius: 6, textShadowOffset: { width: 4, height: 4 } },
        },
        bracket: {
            backgroundColor: '#10243a',
            // backgroundColor: '#060e17',
            controls: {
                backgroundColor: 'rgba(0,0,0,0.9)',
                borderColor: 'rgba(255,255,255, 0.2)',
            },
            roundHeader: {
                color: '#fff',
                backgroundColor: '#0d1f34',
            },
            moneyRound: {
                backgroundColor: 'rgb(6 78 45 / 22%)',
            },
            matchData: {
                border: '1px solid #2b3c4e',
                numberBackgroundColor: '#172b40',
                textColor: '#fff',
                borderColor: 'rgba(0,0,0,0.5)',
            },
            matchScores: {
                textColor: '#fff',
                completedMatchTableBackgroundColor: 'rgb(241 248 255 / 10%)',
                liveMatchTableBackgroundColor: '#f17b82',
            },
            panels: {
                backgroundColor: '#06101b',
                iconColor: '#fff',
                textColor: 'rgba(255,255,255,0.7)',
                borderColor: 'rgba(255,255,255, 0.2)',
                boxShadow: 'rgb(0 0 0 / 8%) 0px 2px 2px 0px, rgb(0 0 0 / 60%) 0px 8px 20px 0px',
                tagBackgroundColor: '#f17b82',
                tagScheduledBackgroundColor: '#a7e063',
                tagTextColor: '#000',
            },
        },
        /* our spacing system uses an 8 pixel grid by default
        please use this variable in multiples e.g. if design wants 24 its theme.spacing * 3
        */
        spacing: 8,
    },

    // ONLY IF DIFFERENT THAN DARK
    light: {
        name: 'light',
        colors: {
            ...reverseTheColors(),
            ...{
                body: colors.gray800,
                background: colors.gray50,
            },
        },
        bodyTitle: {
            color: '#000',
        },
        bodyText: {
            color: 'rgba(0, 0, 0, 0.85)',
        },
        borders: {
            common: {
                color: colors.gray800 + '7d',
            },
        },
        bracket: {
            backgroundColor: '#dff4ff',
            controls: {
                backgroundColor: 'rgba(255,255,255,0.9)',
                borderColor: '#eeeeee',
            },
            roundHeader: {
                color: '#777',
                backgroundColor: '#fff',
            },
            moneyRound: {
                backgroundColor: 'rgba(230, 255, 230, 0.35)',
            },
            matchData: {
                border: '1px solid #d3dce4',
                numberBackgroundColor: '#d3dce4',
                numberColor: '#d3dce4',
                borderColor: 'rgb(237, 237, 237)',
            },
            matchScores: {
                textColor: 'rgba(0, 0, 0, 0.65)',
                completedMatchTableBackgroundColor: '#f1f8ff',
                liveMatchTableBackgroundColor: '#ffc9cc',
            },
            panels: {
                backgroundColor: '#fff',
                iconColor: '#000',
                textColor: 'rgba(0, 0, 0, 0.65)',
                borderColor: '#eeeeee',
                boxShadow: 'rgb(0 0 0 / 8%) 0px 2px 2px 0px, rgb(218 224 235 / 60%) 0px 8px 20px 0px',
                tagBackgroundColor: '#ffdddf',
                tagScheduledBackgroundColor: '#d9f4b8',
                tagTextColor: '#000',
            },
        },
    },
};

// const setTheme = (_theme) => {
//     console.log(_theme);
//     themeName = _theme;
//     theme = themeName === 'dark' ? themes.dark : { ...themes.dark, ...themes[themeName] };
//     return theme;
// };

// let theme;
// setTheme(theme);
// export const GetTheme;

// export function useTheme(_theme) {
//     useLayoutEffect(
//         () => {
//             console.log(_theme);
//             setTheme(_theme);
//         },
//         [_theme] // Only call again if theme object reference changes
//     );

//     return [theme, setTheme];
// }

export default function useTheme() {
    const [theme, setTheme] = useState('dark');

    const toggleTheme = (newTheme) => {
        let currentTheme;
        if (newTheme && (newTheme === 'light' || newTheme === 'dark')) {
            currentTheme = newTheme;
        } else {
            if (theme !== 'dark') {
                currentTheme = 'dark';
            } else {
                currentTheme = 'light';
            }
        }
        setTheme(currentTheme);
        setThemeClass(currentTheme);
        localStorage.setItem('theme', currentTheme);
        message.success(`Switched to ${currentTheme} mode`);
    };

    function setThemeClass(theme) {
        if (theme === 'dark') {
            document.body.classList.add('dark-mode');
            document.body.classList.remove('light-mode');
        } else {
            document.body.classList.remove('dark-mode');
            document.body.classList.add('light-mode');
        }
    }

    useEffect(() => {
        const localTheme = localStorage.getItem('theme');
        // const localTheme = localStorage.getItem('theme')
        if (localTheme && localTheme !== theme) {
            setTheme(localTheme);
            setThemeClass(localTheme);
        } else {
            setThemeClass(theme);
        }
    }, [theme]);

    // useEffect(() => {
    //     let newTheme;
    //     if (theme === 'light') {
    //         newTheme = 'light';
    //         localStorage.setItem('theme', newTheme);
    //         setThemeClass(newTheme);
    //     } else {
    //         newTheme = 'dark';
    //         localStorage.setItem('theme', newTheme);
    //         setThemeClass(newTheme);
    //     }
    //     console.log('current theme', theme);
    // }, [theme]);

    console.log('current theme', themes[theme]);

    return [themes[theme], toggleTheme];
}
