import gql from 'graphql-tag';

export const GET_LEAGUE_MATCHES_SUBSCRIPTION = gql`
    subscription league_matches($league_id: Int, $division_id: Int) {
        league_matches(where: { league_id: { _eq: $league_id }, division_id: { _eq: $division_id }, status: { _neq: COMPLETED } }) {
            id
            name
            slug
            match_type
            match_number
            match_id
            game_type
            table_id
            table_name
            scoring_format
            best_of_num
            race_to_num
            winner_determined_by
            challenger1_id
            challenger2_id
            challenger1_name
            challenger2_name
            challenger1_country
            challenger2_country
            challenger1_score
            challenger2_score
            challenger1_points
            challenger2_points
            challenger1_frames
            challenger2_frames
            challenger1_skill_level
            challenger2_skill_level
            challenger1_goes_to
            challenger2_goes_to
            challenger1_is_winner
            challenger2_is_winner
            league_match_players {
                id
                name
                country
                league_player_id
                skill_level
                race_to
            }
            division_id
            division {
                id
                name
            }
            table_id
            venue_id
            venue {
                id
                name
                slug
                address1
                address2
                city
                region
                country
                postal_code
            }
            status
            progress
            duration
            scheduled_time
            start_time
            end_time
            created_at
            updated_at
            updated_at
        }
    }
`;

export const GET_LEAGUE_MATCH_BY_SLUG_SUBSCRIPTION = gql`
    subscription league_matches($league_id: Int, $division_id: Int, $slug: String) {
        league_matches(where: { league_id: { _eq: $league_id }, division_id: { _eq: $division_id }, slug: { _eq: $slug } }) {
            id
            name
            slug
            match_type
            match_number
            match_id
            game_type
            table_id
            table_name
            scoring_format
            best_of_num
            race_to_num
            winner_determined_by
            challenger1_id
            challenger2_id
            challenger1_name
            challenger2_name
            challenger1_country
            challenger2_country
            challenger1_score
            challenger2_score
            challenger1_points
            challenger2_points
            challenger1_frames
            challenger2_frames
            challenger1_skill_level
            challenger2_skill_level
            challenger1_goes_to
            challenger2_goes_to
            challenger1_is_winner
            challenger2_is_winner
            league_match_players {
                id
                name
                country
                league_player_id
                skill_level
                race_to
            }
            division_id
            division {
                id
                name
            }
            venue_id
            venue {
                id
                name
                slug
                address1
                address2
                city
                region
                country
                postal_code
            }
            status
            progress
            duration
            scheduled_time
            start_time
            end_time
            created_at
            updated_at
            updated_at
        }
    }
`;

export const GET_LEAGUE_MATCH_SUBSCRIPTION = gql`
    subscription league_matches($league_id: Int, $division_id: Int, $slug: String, $match_id: Int) {
        league_matches(where: { league_id: { _eq: $league_id }, division_id: { _eq: $division_id }, _or: [{ slug: { _eq: $slug }, id: { _eq: $match_id } }] }) {
            id
            name
            slug
            match_type
            match_number
            match_id
            game_type
            table_id
            table_name
            scoring_format
            best_of_num
            race_to_num
            winner_determined_by
            challenger1_id
            challenger2_id
            challenger1_name
            challenger2_name
            challenger1_country
            challenger2_country
            challenger1_score
            challenger2_score
            challenger1_points
            challenger2_points
            challenger1_frames
            challenger2_frames
            challenger1_skill_level
            challenger2_skill_level
            challenger1_goes_to
            challenger2_goes_to
            challenger1_is_winner
            challenger2_is_winner
            league_match_players {
                id
                name
                country
                league_player_id
                skill_level
                race_to
            }
            division_id
            division {
                id
                name
            }
            venue_id
            venue {
                id
                name
                slug
                address1
                address2
                city
                region
                country
                postal_code
            }
            status
            progress
            duration
            scheduled_time
            start_time
            end_time
            created_at
            updated_at
            updated_at
        }
    }
`;

export const GET_NOTIFICATIONS_SUBSCRIPTION = gql`
    subscription notifications($user_id: Int!) {
        notifications(where: { user_id: { _eq: $user_id } }) {
            id
            name
            link
            seen
            seen_at
            title
            description
            updated_at
            user_id
            action
            type
            data
            created_at
        }
    }
`;

export const GET_NOTIFICATIONS_SEEN = gql`
    subscription notifications($user_id: Int!) {
        notifications(where: { user_id: { _eq: $user_id } }) {
            id
            seen
            title
            description
            created_at
        }
    }
`;

export const GET_LEAGUE_DIVISON_PLAYER_STATS_SUBSCRIPTION = gql`
    subscription league_players($league_id: Int!, $division_id: Int!) {
        league_players(where: { league_id: { _eq: $league_id } }, order_by: { name: asc }) {
            id
            name
            country
            skill_level
            place
            points
            league_player_divisions(where: { division_id: { _eq: $division_id } }) {
                id
            }
            challenger1_matches_won: league_matches_challenger1_aggregate(
                where: { challenger1_is_winner: { _eq: true }, status: { _eq: COMPLETED } }
                order_by: { match_number: asc }
            ) {
                aggregate {
                    count
                }
            }
            challenger1_matches_lost: league_matches_challenger1_aggregate(
                where: { challenger1_is_winner: { _eq: false }, status: { _eq: COMPLETED } }
                order_by: { match_number: asc }
            ) {
                aggregate {
                    count
                }
            }
            challenger2_matches_won: league_matches_challenger2_aggregate(
                where: { challenger2_is_winner: { _eq: true }, status: { _eq: COMPLETED } }
                order_by: { match_number: asc }
            ) {
                aggregate {
                    count
                }
            }
            challenger2_matches_lost: league_matches_challenger2_aggregate(
                where: { challenger2_is_winner: { _eq: false }, status: { _eq: COMPLETED } }
                order_by: { match_number: asc }
            ) {
                aggregate {
                    count
                }
            }
            challenger1_matches_games: league_matches_challenger1_aggregate(where: { status: { _eq: COMPLETED } }, order_by: { match_number: asc }) {
                aggregate {
                    count
                    sum {
                        challenger1_score
                        challenger2_score
                        challenger1_points
                        challenger2_points
                        challenger1_balls_pocketed
                        challenger2_balls_pocketed
                        challenger1_errors
                        challenger2_errors
                        challenger1_high_break
                        challenger2_high_break
                    }
                }
                nodes {
                    start_time
                    end_time
                }
            }
            challenger2_matches_games: league_matches_challenger2_aggregate(where: { status: { _eq: COMPLETED } }, order_by: { match_number: asc }) {
                aggregate {
                    count
                    sum {
                        challenger1_score
                        challenger2_score
                        challenger1_points
                        challenger2_points
                        challenger1_balls_pocketed
                        challenger2_balls_pocketed
                        challenger1_errors
                        challenger2_errors
                        challenger1_high_break
                        challenger2_high_break
                    }
                }
                nodes {
                    start_time
                    end_time
                }
            }
            challenger1_matches_frames: league_matches_challenger1_aggregate(where: { status: { _eq: COMPLETED } }, order_by: { match_number: asc }) {
                aggregate {
                    count
                    sum {
                        challenger1_frames
                        challenger2_frames
                    }
                }
                nodes {
                    start_time
                    end_time
                }
            }
            challenger2_matches_frames: league_matches_challenger2_aggregate(where: { status: { _eq: COMPLETED } }, order_by: { match_number: asc }) {
                aggregate {
                    count
                    sum {
                        challenger1_frames
                        challenger2_frames
                    }
                }
                nodes {
                    start_time
                    end_time
                }
            }
            challenger1_matches_points: league_matches_challenger1_aggregate(where: { status: { _eq: COMPLETED } }, order_by: { match_number: asc }) {
                aggregate {
                    count
                    sum {
                        challenger1_points
                        challenger2_points
                    }
                }
                nodes {
                    start_time
                    end_time
                }
            }
            challenger2_matches_points: league_matches_challenger2_aggregate(where: { status: { _eq: COMPLETED } }, order_by: { match_number: asc }) {
                aggregate {
                    count
                    sum {
                        challenger1_points
                        challenger2_points
                    }
                }
                nodes {
                    start_time
                    end_time
                }
            }
        }
    }
`;

export const GET_LEAGUE_DIVISON_MATCH_STATS_SUBSCRIPTION = gql`
    subscription league_matches($league_id: Int!, $division_id: Int!, $match_id: Int!) {
        league_matches(where: { league_id: { _eq: $league_id }, division_id: { _eq: $division_id }, id: { _eq: $match_id } }) {
            id
            challenger1_id
            challenger1_name
            challenger1_country
            challenger1_is_winner
            challenger1_score
            challenger1_frames
            challenger1_points
            challenger2_id
            challenger2_name
            challenger2_country
            challenger2_is_winner
            challenger2_score
            challenger2_frames
            challenger2_points
            league_match_frames_aggregate {
                aggregate {
                    count
                    sum {
                        challenger1_points
                        challenger2_points
                        challenger1_balls_pocketed
                        challenger2_balls_pocketed
                        challenger1_errors
                        challenger2_errors
                        challenger1_high_break
                        challenger2_high_break
                    }
                }
                nodes {
                    id
                    challenger1_points
                    challenger2_points
                    challenger1_balls_pocketed
                    challenger2_balls_pocketed
                    challenger1_errors
                    challenger2_errors
                    challenger1_high_break
                    challenger2_high_break
                }
            }
        }
    }
`;

export const GET_LIVE_CHAT_STREAM_SUBSCRIPTION = gql`
    subscription live_chat($live_id: Int!) {
        live_chat(order_by: { id: desc }, limit: 1, where: { live_id: { _eq: $live_id } }) {
            user_id
            message
            user {
                first_name
            }
        }
    }
`;

export const GET_LIVE_CHAT_MESSAGE_STREAM_SUBSCRIPTION = gql`
    subscription live_chat($live_id: Int!) {
        live_chat(where: { live_id: { _eq: $live_id } }) {
            message
        }
    }
`;

// export const GET_EVENT_LIVE_TABLE_SCORES_SUBSCRIPTION = gql`
//     subscription pool_tables($event_id: Int!) {
//         pool_tables(where: { _or: [{ matches: { event_id: { _eq: $event_id } } }, { tournament: { event_id: { _eq: $event_id } } }] }) {
//             id
//             slug
//             label
//             matches {
//                 id
//                 name
//                 slug
//                 description
//                 match_number
//                 match_type
//                 game_type
//                 rule_format
//                 handicap_format
//                 race_to
//                 player_id
//                 player_name
//                 player_score
//                 player_points
//                 player_tiebreaker_points
//                 player_race_to
//                 player_country
//                 player_fargo
//                 player_is_winner
//                 opponent_id
//                 opponent_name
//                 opponent_score
//                 opponent_points
//                 opponent_tiebreaker_points
//                 opponent_race_to
//                 opponent_country
//                 opponent_fargo
//                 opponent_is_winner
//                 winner_id
//                 winner_score
//                 loser_id
//                 loser_score
//                 status
//                 progress
//                 duration
//                 livestream_url
//                 score_verified
//                 scheduled_time
//                 start_date_time
//                 end_date_time
//                 is_public
//                 event_id
//                 event {
//                     id
//                     name
//                     slug
//                     description
//                 }
//                 venue_id
//                 venue {
//                     id
//                     name
//                     slug
//                     address1
//                     address2
//                     city
//                     region
//                     country
//                     postal_code
//                 }

//                 match_players {
//                     id
//                     match_id
//                     user_id
//                     name
//                     country
//                     skill_level
//                     race_to
//                     score
//                     is_winner
//                 }
//                 match_stats(order_by: { time: desc }) {
//                     id
//                     metric
//                     value
//                     user_id
//                     match_id
//                     match_game_id
//                     player_id
//                     opponent_id
//                     game_number
//                     time
//                 }
//                 managers {
//                     id
//                     user_id
//                     match_id
//                     admin
//                     creator
//                 }
//                 table_size
//                 scoreboard_balls
//                 creator_id
//                 created_at
//                 updated_at
//             }
//             tournament_match_table {
//                 id
//                 hash_id
//                 round
//                 identifier
//                 place
//                 challenger1 {
//                     id
//                     name
//                     country
//                     skill_level
//                     place
//                     race_to
//                     seed
//                 }
//                 challenger2 {
//                     id
//                     name
//                     country
//                     skill_level
//                     place
//                     race_to
//                     seed
//                 }
//                 challenger1_id
//                 challenger1_name
//                 challenger1_country
//                 challenger1_score
//                 challenger1_points
//                 challenger1_race_to
//                 challenger1_skill_level
//                 challenger1_seed
//                 challenger1_is_winner
//                 challenger1_is_forfeit
//                 challenger1_is_withdraw
//                 challenger2_id
//                 challenger2_name
//                 challenger2_country
//                 challenger2_score
//                 challenger2_points
//                 challenger2_race_to
//                 challenger2_skill_level
//                 challenger2_seed
//                 challenger2_is_winner
//                 challenger2_is_forfeit
//                 challenger2_is_withdraw
//                 challenger1_sms_sent
//                 challenger2_sms_sent
//                 tournament_player_id
//                 match_number
//                 match_id
//                 team_id
//                 scheduled_time
//                 start_time
//                 end_time
//                 duration
//                 table_id
//                 table_name
//                 seed
//                 status
//                 progress
//                 loser_from
//                 loser_from_num
//                 loser_to
//                 loser_to_num
//                 winner_from
//                 winner_to
//                 winner_to_num
//                 winner_from_top_num
//                 winner_from_bottom_num
//                 loser_from_top_num
//                 loser_from_bottom_num
//                 is_bye
//                 is_split
//                 is_semi_finals
//                 is_finals
//                 is_consolidation_finals
//                 is_scoring_started
//             }
//         }
//     }
// `;

export const GET_EVENT_LIVE_TABLE_SCORES_SUBSCRIPTION = gql`
    subscription events($event_id: Int!) {
        events(where: { id: { _eq: $event_id } }) {
            id
            slug
            name
            matches {
                id
                name
                slug
                pool_table {
                    id
                    label
                    slug
                    matches(where: { _or: [{ status: { _eq: IN_PROGRESS } }, { status: { _eq: SCHEDULED } }] }, order_by: { start_date_time: desc }) {
                        id
                        name
                        slug
                        description
                        match_number
                        match_type
                        game_type
                        rule_format
                        handicap_format
                        race_to
                        player_id
                        player_name
                        player_score
                        player_points
                        player_tiebreaker_points
                        player_race_to
                        player_country
                        player_fargo
                        player_is_winner
                        opponent_id
                        opponent_name
                        opponent_score
                        opponent_points
                        opponent_tiebreaker_points
                        opponent_race_to
                        opponent_country
                        opponent_fargo
                        opponent_is_winner
                        winner_id
                        winner_score
                        loser_id
                        loser_score
                        status
                        progress
                        duration
                        livestream_url
                        score_verified
                        scheduled_time
                        start_date_time
                        end_date_time
                        is_public
                        event_id
                        event {
                            id
                            name
                            slug
                            description
                        }
                        venue_id
                        venue {
                            id
                            name
                            slug
                            address1
                            address2
                            city
                            region
                            country
                            postal_code
                        }

                        match_players {
                            id
                            match_id
                            user_id
                            name
                            country
                            skill_level
                            race_to
                            score
                            is_winner
                        }
                        match_stats(order_by: { time: desc }) {
                            id
                            metric
                            value
                            user_id
                            match_id
                            match_game_id
                            player_id
                            opponent_id
                            game_number
                            time
                        }
                        managers {
                            id
                            user_id
                            match_id
                            admin
                            creator
                        }
                        table_size
                        scoreboard_balls
                        creator_id
                        created_at
                        updated_at
                    }
                }
            }
            tournaments {
                id
                name
                slug
                pool_tables(where: { _or: [{ status: { _eq: IN_USE } }, { status: { _eq: ASSIGNED } }] }) {
                    id
                    label
                    slug
                    status
                    is_streaming_table
                    tournament_match_table {
                        id
                        hash_id
                        round
                        identifier
                        place
                        challenger1 {
                            id
                            name
                            country
                            skill_level
                            place
                            race_to
                            seed
                        }
                        challenger2 {
                            id
                            name
                            country
                            skill_level
                            place
                            race_to
                            seed
                        }
                        challenger1_id
                        challenger1_name
                        challenger1_country
                        challenger1_score
                        challenger1_points
                        challenger1_race_to
                        challenger1_skill_level
                        challenger1_seed
                        challenger1_is_winner
                        challenger1_is_forfeit
                        challenger1_is_withdraw
                        challenger2_id
                        challenger2_name
                        challenger2_country
                        challenger2_score
                        challenger2_points
                        challenger2_race_to
                        challenger2_skill_level
                        challenger2_seed
                        challenger2_is_winner
                        challenger2_is_forfeit
                        challenger2_is_withdraw
                        challenger1_sms_sent
                        challenger2_sms_sent
                        challenger1_is_playing
                        challenger2_is_playing
                        tournament_player_id
                        match_number
                        match_id
                        team_id
                        scheduled_time
                        start_time
                        end_time
                        duration
                        table_id
                        table_name
                        seed
                        status
                        progress
                        loser_from
                        loser_from_num
                        loser_to
                        loser_to_num
                        winner_from
                        winner_to
                        winner_to_num
                        winner_from_top_num
                        winner_from_bottom_num
                        loser_from_top_num
                        loser_from_bottom_num
                        is_bye
                        is_split
                        is_semi_finals
                        is_finals
                        is_consolidation_finals
                        is_scoring_started
                        scoreboard_balls
                    }
                }
            }
        }
    }
`;

export const GET_EVENT_LIVE_SCORES_SUBSCRIPTION = gql`
    subscription tournaments($event_id: Int!) {
        tournaments(where: { event_id: { _eq: $event_id } }) {
            id
            name
            slug
            description
            avatar
            flyer
            logo
            tournament_type
            tournament_format
            handicap_format
            player_type
            game_type
            winners_race_to
            losers_race_to
            race_to
            consolidation_finals
            max_players
            max_tables
            rule_format
            break_format
            status
            progress
            start_date_time
            end_date_time
            entry_fee
            added_money
            payout_type
            draw_type
            rating_system
            use_text_messaging
            rsvp_allowed
            show_player_skill_levels
            show_player_races
            is_public
            signup_cutoff_time
            pool_tables {
                id
                slug
                label
                tournament_match_table(where: { status: { _neq: COMPLETED } }, order_by: { updated_at: desc }) {
                    id
                    hash_id
                    round
                    identifier
                    place
                    status
                    challenger1 {
                        id
                        name
                        country
                        skill_level
                        race_to
                        place
                        seed
                        user_id
                    }
                    challenger2 {
                        id
                        name
                        country
                        skill_level
                        race_to
                        place
                        seed
                        user_id
                    }
                    challenger1_id
                    challenger1_name
                    challenger1_country
                    challenger1_score
                    challenger1_points
                    challenger1_race_to
                    challenger1_skill_level
                    challenger1_seed
                    challenger1_is_winner
                    challenger1_is_forfeit
                    challenger1_is_withdraw
                    challenger2_id
                    challenger2_name
                    challenger2_country
                    challenger2_score
                    challenger2_points
                    challenger2_race_to
                    challenger2_skill_level
                    challenger2_seed
                    challenger2_is_winner
                    challenger2_is_forfeit
                    challenger2_is_withdraw
                    challenger1_sms_sent
                    challenger2_sms_sent
                    challenger1_is_playing
                    challenger2_is_playing
                    tournament_player_id
                    match_number
                    match_id
                    team_id
                    scheduled_time
                    start_time
                    end_time
                    duration
                    table_id
                    table_name
                    seed
                    status
                    progress
                    loser_from
                    loser_from_num
                    loser_to
                    loser_to_num
                    winner_from
                    winner_to
                    winner_to_num
                    winner_from_top_num
                    winner_from_bottom_num
                    loser_from_top_num
                    loser_from_bottom_num
                    is_bye
                    is_split
                    is_semi_finals
                    is_finals
                    is_consolidation_finals
                    is_scoring_started
                    scoreboard_balls
                }
            }
        }
    }
`;

export const GET_ALL_EVENT_TABLES_TV_DISPLAY_SUBSCRIPTION = gql`
    subscription tournaments($event_id: Int!) {
        tournaments(where: { event_id: { _eq: $event_id } }) {
            id
            name
            slug
            description
            avatar
            flyer
            logo
            tournament_type
            tournament_format
            handicap_format
            player_type
            game_type
            winners_race_to
            losers_race_to
            race_to
            consolidation_finals
            max_players
            max_tables
            rule_format
            break_format
            status
            progress
            start_date_time
            end_date_time
            entry_fee
            added_money
            payout_type
            draw_type
            rating_system
            use_text_messaging
            rsvp_allowed
            show_player_skill_levels
            show_player_races
            is_public
            signup_cutoff_time
            pool_tables {
                id
                slug
                label
                type
                size
                year_built
                make
                model
                created_at
                updated_at
                status
                is_streaming_table
                is_featured_table
                livestream_url
                manufacturer {
                    id
                    name
                }
                venue {
                    id
                    name
                }
                user {
                    id
                    email
                    country
                }
                tournament_match_table(where: { status: { _neq: COMPLETED } }, order_by: { updated_at: desc }) {
                    id
                    hash_id
                    round
                    identifier
                    place
                    challenger1 {
                        id
                        name
                        country
                        skill_level
                        place
                        race_to
                        seed
                    }
                    challenger2 {
                        id
                        name
                        country
                        skill_level
                        place
                        race_to
                        seed
                    }
                    challenger1_id
                    challenger1_name
                    challenger1_country
                    challenger1_score
                    challenger1_points
                    challenger1_race_to
                    challenger1_skill_level
                    challenger1_seed
                    challenger1_is_winner
                    challenger1_is_forfeit
                    challenger1_is_withdraw
                    challenger2_id
                    challenger2_name
                    challenger2_country
                    challenger2_score
                    challenger2_points
                    challenger2_race_to
                    challenger2_skill_level
                    challenger2_seed
                    challenger2_is_winner
                    challenger2_is_forfeit
                    challenger2_is_withdraw
                    challenger1_sms_sent
                    challenger2_sms_sent
                    challenger1_is_playing
                    challenger2_is_playing
                    tournament_player_id
                    match_number
                    match_id
                    team_id
                    scheduled_time
                    start_time
                    end_time
                    duration
                    table_id
                    table_name
                    seed
                    status
                    progress
                    loser_from
                    loser_from_num
                    loser_to
                    loser_to_num
                    winner_from
                    winner_to
                    winner_to_num
                    winner_from_top_num
                    winner_from_bottom_num
                    loser_from_top_num
                    loser_from_bottom_num
                    is_bye
                    is_split
                    is_semi_finals
                    is_finals
                    is_consolidation_finals
                    is_scoring_started
                    scoreboard_balls
                }
            }
        }
    }
`;

export const GET_EVENT_LIVE_TABLE_SUBSCRIPTION = gql`
    subscription tournaments($event_slug: String!, $table_slug: String!) {
        tournaments(where: { event: { slug: { _eq: $event_slug } } }) {
            id
            name
            slug
            description
            avatar
            flyer
            logo
            tournament_type
            tournament_format
            handicap_format
            player_type
            game_type
            winners_race_to
            losers_race_to
            race_to
            consolidation_finals
            max_players
            max_tables
            rule_format
            break_format
            status
            progress
            start_date_time
            end_date_time
            entry_fee
            added_money
            payout_type
            draw_type
            rating_system
            use_text_messaging
            rsvp_allowed
            show_player_skill_levels
            show_player_races
            is_public
            signup_cutoff_time
            pool_tables(where: { slug: { _eq: $table_slug } }, limit: 1) {
                id
                slug
                label
                type
                size
                year_built
                make
                model
                created_at
                updated_at
                status
                is_streaming_table
                is_featured_table
                livestream_url
                kiosk_ip_address
                kiosk_password
                manufacturer {
                    id
                    name
                }
                venue {
                    id
                    name
                }
                user {
                    id
                    email
                    country
                }
                tournament_match_table(where: { status: { _neq: COMPLETED } }, order_by: { updated_at: desc }) {
                    id
                    hash_id
                    round
                    identifier
                    place
                    challenger1 {
                        id
                        name
                        country
                        skill_level
                        place
                        race_to
                        seed
                    }
                    challenger2 {
                        id
                        name
                        country
                        skill_level
                        place
                        race_to
                        seed
                    }
                    challenger1_id
                    challenger1_name
                    challenger1_country
                    challenger1_score
                    challenger1_points
                    challenger1_race_to
                    challenger1_skill_level
                    challenger1_seed
                    challenger1_is_winner
                    challenger1_is_forfeit
                    challenger1_is_withdraw
                    challenger2_id
                    challenger2_name
                    challenger2_country
                    challenger2_score
                    challenger2_points
                    challenger2_race_to
                    challenger2_skill_level
                    challenger2_seed
                    challenger2_is_winner
                    challenger2_is_forfeit
                    challenger2_is_withdraw
                    challenger1_sms_sent
                    challenger2_sms_sent
                    challenger1_is_playing
                    challenger2_is_playing
                    tournament_player_id
                    match_number
                    match_id
                    team_id
                    scheduled_time
                    start_time
                    end_time
                    duration
                    table_id
                    table_name
                    seed
                    status
                    progress
                    loser_from
                    loser_from_num
                    loser_to
                    loser_to_num
                    winner_from
                    winner_to
                    winner_to_num
                    winner_from_top_num
                    winner_from_bottom_num
                    loser_from_top_num
                    loser_from_bottom_num
                    is_bye
                    is_split
                    is_semi_finals
                    is_finals
                    is_consolidation_finals
                    is_scoring_started
                    scoreboard_balls
                }
            }
        }
    }
`;

export const GET_EVENT_LIVE_TABLE_SUBSCRIPTION2 = gql`
    subscription events($event_slug: String!, $table_slug: String!) {
        events(where: { slug: { _eq: $event_slug } }) {
            id
            slug
            name
            venue_id
            venue {
                id
                name
                slug
                pool_tables(where: { slug: { _eq: $table_slug } }, limit: 1) {
                    id
                    slug
                    label
                    type
                    size
                    year_built
                    make
                    model
                }
            }

            matches(where: { pool_table: { slug: { _eq: $table_slug } } }, limit: 1) {
                id
                name
                slug
                pool_table {
                    id
                    label
                    slug
                    kiosk_ip_address
                    kiosk_password
                    matches(where: { status: { _neq: COMPLETED } }) {
                        id
                        name
                        slug
                        description
                        match_number
                        match_type
                        game_type
                        rule_format
                        handicap_format
                        race_to
                        player_id
                        player_name
                        player_score
                        player_points
                        player_tiebreaker_points
                        player_race_to
                        player_country
                        player_state
                        player_fargo
                        player_is_winner
                        opponent_id
                        opponent_name
                        opponent_score
                        opponent_points
                        opponent_tiebreaker_points
                        opponent_race_to
                        opponent_country
                        opponent_state
                        opponent_fargo
                        opponent_is_winner
                        winner_id
                        winner_score
                        loser_id
                        loser_score
                        status
                        progress
                        duration
                        livestream_url
                        score_verified
                        scheduled_time
                        start_date_time
                        end_date_time
                        is_public
                        is_scoring_started
                        event_id
                        event {
                            id
                            name
                            slug
                            description
                        }
                        venue_id
                        venue {
                            id
                            name
                            slug
                            address1
                            address2
                            city
                            region
                            country
                            postal_code
                        }

                        match_players {
                            id
                            match_id
                            user_id
                            name
                            country
                            skill_level
                            race_to
                            score
                            is_winner
                        }
                        match_stats(order_by: { time: desc }) {
                            id
                            metric
                            value
                            user_id
                            match_id
                            match_game_id
                            player_id
                            opponent_id
                            game_number
                            time
                        }
                        managers {
                            id
                            user_id
                            match_id
                            admin
                            creator
                        }
                        table_size
                        scoreboard_balls
                        creator_id
                        created_at
                        updated_at
                    }
                }
            }
            tournaments {
                id
                name
                slug
                description
                avatar
                flyer
                logo
                tournament_type
                tournament_format
                handicap_format
                player_type
                game_type
                winners_race_to
                losers_race_to
                race_to
                consolidation_finals
                max_players
                max_tables
                rule_format
                break_format
                status
                progress
                start_date_time
                end_date_time
                entry_fee
                added_money
                payout_type
                draw_type
                rating_system
                use_text_messaging
                rsvp_allowed
                show_player_skill_levels
                show_player_races
                is_public
                signup_cutoff_time
                pool_tables(where: { slug: { _eq: $table_slug } }, limit: 1) {
                    id
                    slug
                    label
                    type
                    size
                    year_built
                    make
                    model
                    created_at
                    updated_at
                    status
                    is_streaming_table
                    is_featured_table
                    livestream_url
                    manufacturer {
                        id
                        name
                    }
                    venue {
                        id
                        name
                    }
                    user {
                        id
                        email
                        country
                    }
                    tournament_match_table(where: { status: { _neq: COMPLETED } }, order_by: { updated_at: desc }) {
                        id
                        hash_id
                        round
                        identifier
                        place
                        challenger1 {
                            id
                            name
                            country
                            skill_level
                            place
                            race_to
                            seed
                        }
                        challenger2 {
                            id
                            name
                            country
                            skill_level
                            place
                            race_to
                            seed
                        }
                        challenger1_id
                        challenger1_name
                        challenger1_country
                        challenger1_score
                        challenger1_points
                        challenger1_race_to
                        challenger1_skill_level
                        challenger1_seed
                        challenger1_is_winner
                        challenger1_is_forfeit
                        challenger1_is_withdraw
                        challenger2_id
                        challenger2_name
                        challenger2_country
                        challenger2_score
                        challenger2_points
                        challenger2_race_to
                        challenger2_skill_level
                        challenger2_seed
                        challenger2_is_winner
                        challenger2_is_forfeit
                        challenger2_is_withdraw
                        challenger1_sms_sent
                        challenger2_sms_sent
                        challenger1_is_playing
                        challenger2_is_playing
                        tournament_player_id
                        match_number
                        match_id
                        team_id
                        scheduled_time
                        start_time
                        end_time
                        duration
                        table_id
                        table_name
                        seed
                        status
                        progress
                        loser_from
                        loser_from_num
                        loser_to
                        loser_to_num
                        winner_from
                        winner_to
                        winner_to_num
                        winner_from_top_num
                        winner_from_bottom_num
                        loser_from_top_num
                        loser_from_bottom_num
                        is_bye
                        is_split
                        is_semi_finals
                        is_finals
                        is_consolidation_finals
                        is_scoring_started
                        scoreboard_balls
                    }
                }
            }
        }
    }
`;
