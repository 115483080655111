import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Menu, Icon, Badge } from 'antd';
import _ from 'lodash';

import { Link, withRouter } from 'react-router-dom';
const { SubMenu } = Menu;

export default function DPNav(props, context) {
    // const { match } = props;
    const { authState, match } = props;
    const urlPath = window.location.pathname;
    const splitPath = urlPath.split('/');
    const dpIncludes = [
        'network',
        'feeds',
        'live',
        'events',
        'leagues',
        'tournaments',
        'matches',
        'venues',
        'people',
        'players',
        'organizations',
        'dashboard',
        'chat',
        'statistics',
        'notifications',
        'friends',
        'billing',
        'ghost-games',
    ];

    const [theme, setTheme] = useState('dark');
    const [current, setCurrent] = useState();

    useEffect(() => {
        let pathMatch = _.includes(dpIncludes, splitPath[splitPath.length - 1])
            ? splitPath[splitPath.length - 1]
            : _.includes(dpIncludes, splitPath[splitPath.length - 2])
            ? splitPath[splitPath.length - 2]
            : _.includes(dpIncludes, splitPath[splitPath.length - 3])
            ? splitPath[splitPath.length - 3]
            : _.includes(dpIncludes, splitPath[splitPath.length - 4])
            ? splitPath[splitPath.length - 4]
            : _.includes(dpIncludes, splitPath[splitPath.length - 5])
            ? splitPath[splitPath.length - 5]
            : _.includes(dpIncludes, splitPath[splitPath.length - 6])
            ? splitPath[splitPath.length - 6]
            : _.includes(dpIncludes, splitPath[splitPath.length - 7])
            ? splitPath[splitPath.length - 7]
            : 'dashboard';
        setCurrent(pathMatch);
    }, [urlPath, splitPath, dpIncludes]);

    const handleClick = (e) => {
        // console.log('click ', e);
        setCurrent(e.key);
        const url = e.key === 'network' ? '/dashboard' : `/${e.key}`;
        setTimeout(() => {
            props.onClicked();
        }, 300);
        props.history.push(url);
    };

    return (
        <React.Fragment>
            <Menu
                theme={theme}
                onClick={handleClick}
                // style={{ width: 256 }}
                defaultOpenKeys={['dashboard']}
                selectedKeys={[current]}
                mode="inline"
            >
                <Menu.Item key="dashboard">
                    <Icon type="dashboard" /> Dashboard
                </Menu.Item>
                {/* <Menu.Item key="feeds">
					<Icon type="bars" /> Feeds
				</Menu.Item> */}
                <Menu.Item key="tournaments">
                    <Icon type="trophy" /> Tournaments
                </Menu.Item>
                <Menu.Item key="leagues">
                    <Icon type="global" /> Leagues
                </Menu.Item>
                {/* <Menu.Item key="live">
					<Icon type="play-circle" /> Live
				</Menu.Item>*/}
                <Menu.Item key="events">
                    <Icon type="calendar" /> Events
                </Menu.Item>

                <Menu.Item key="players">
                    <Icon type="team" /> Players
                </Menu.Item>
                <Menu.Item key="matches">
                    <Icon type="thunderbolt" /> Matches
                </Menu.Item>
                <Menu.Item key="venues">
                    <Icon type="environment" /> Venues
                </Menu.Item>
                {/* <Menu.Item key="organizations">
					<Icon type="bank" /> Organizations
				</Menu.Item> */}
                {/* <SubMenu
					key="network"
					title={
						<span>
							<Icon type="deployment-unit" />
							<span>My stuff</span>
						</span>
					}
				>
					<Menu.Item key="my-matches">
						<Icon type="bars" /> My Matches
					</Menu.Item>
					<Menu.Item key="my-leagues">
						<Icon type="caret-right" /> My Leagues
					</Menu.Item>
					<Menu.Item key="my-divisions">
						<Icon type="calendar" /> My Divisions
					</Menu.Item>
					<Menu.Item key="my-teams">
						<Icon type="global" /> My Teams
					</Menu.Item>
					<Menu.Item key="my-tournaments">
						<Icon type="trophy" /> My Tournaments
					</Menu.Item>
				</SubMenu> */}
            </Menu>
            {/* <Menu
				theme={theme}
				onClick={handleClick}
				// style={{ width: 256 }}
				defaultOpenKeys={[ 'network' ]}
				selectedKeys={[ current ]}
				mode="inline"
			>
				<Menu.Item key="dashboard" disabled={authState && authState.status === 'in' ? false : true}>
					<Icon type="dashboard" /> Dashboard
				</Menu.Item>
				<Menu.Item key="chat" disabled={authState && authState.status === 'in' ? false : true}>
					<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
						<div>
							<Icon type="message" /> Chat
						</div>
						{authState &&
						authState.status === 'in' && (
							<div>
								<Badge count={1} />
							</div>
						)}
					</div>
				</Menu.Item>
				<Menu.Item key="stats" disabled={authState && authState.status === 'in' ? false : true}>
					<Icon type="bar-chart" /> Stats
				</Menu.Item>

				<Menu.Item key="notifications" disabled={authState && authState.status === 'in' ? false : true}>
					<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
						<div>
							<Icon type="bell" /> Notifications
						</div>
						{authState &&
						authState.status === 'in' && (
							<div>
								<Badge count={3} />
							</div>
						)}
					</div>
				</Menu.Item>

				<Menu.Item key="friends" disabled={authState && authState.status === 'in' ? false : true}>
					<Icon type="team" /> Friends
				</Menu.Item>
				<Menu.Item key="billing" disabled={authState && authState.status === 'in' ? false : true}>
					<Icon type="dollar" /> Billing
				</Menu.Item>
			</Menu> */}
        </React.Fragment>
    );
}

DPNav.contextTypes = {
    authState: PropTypes.object,
    authUser: PropTypes.object,
    setAuthState: PropTypes.func,
};
