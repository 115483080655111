import React, { useState, useEffect, useRef } from 'react';
import { message, Layout, Button, Statistic, Icon, Tooltip, Modal, Row, Col, Tabs, Table, Typography } from 'antd';
import { Link } from 'react-router-dom';
import SectionHeader from '../../components/SectionHeader';
import SectionContent from '../../components/SectionContent';
import SectionWrapper from '../../components/SectionWrapper';
import SectionFooter from '../../components/SectionFooter';
import Card from '../../components/Card';
import CircularLoader from '../../components/CircularLoader';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { GET_TOURNAMENT_BRACKET_TEMPLATES } from './data/queries';
import { DELETE_TOURNAMENT_BRACKET_TEMPLATE } from './data/mutations';
import { Subscription, Query } from '@apollo/client/react/components';
import ZeroState from '../../components/ZeroState';
import numeral from 'numeral';
import moment from 'moment-timezone';
import momentDurationFormatSetup from 'moment-duration-format';
momentDurationFormatSetup(moment);

const { Sider, Content, Header } = Layout;
const { Title, Text } = Typography;
const { confirm } = Modal;

const styles = {
    row: {
        display: 'flex',
        flexGrow: 1,
    },
    tab: {
        paddingTop: 5,
        paddingLeft: 24,
        paddingRight: 24,
        paddingBottom: 24,
    },
};

function TBBracketCreator(props) {
    const { authState, theme } = props;
    const [deleteBracketTemplate] = useMutation(DELETE_TOURNAMENT_BRACKET_TEMPLATE);

    const columns = [
        {
            title: 'Date',
            dataIndex: 'updated_at',
            defaultSortOrder: 'desc',
            width: 100,
            sorter: (a, b) =>
                b.updated_at.localeCompare(a.updated_at, undefined, {
                    numeric: true,
                    sensitivity: 'base',
                }),

            render: (text, record) => {
                return <Text>{moment(text).format('lll')}</Text>;
            },
        },
        {
            title: 'Name',
            dataIndex: 'name',
            sorter: (a, b) =>
                b.name.localeCompare(a.name, undefined, {
                    numeric: true,
                    sensitivity: 'base',
                }),
            render: (text, record) => {
                return <Text>{text || '-'}</Text>;
            },
        },

        {
            title: 'Type',
            dataIndex: 'type',
            sorter: (a, b) =>
                b.type.localeCompare(a.type, undefined, {
                    numeric: true,
                    sensitivity: 'base',
                }),
            render: (text, record) => {
                return <Text>{text || '-'}</Text>;
            },
        },

        {
            title: 'Action',
            key: 'action',
            // fixed: 'right',
            render: (text, record) => (
                <React.Fragment>
                    <span style={{ whiteSpace: 'nowrap' }}>
                        <Tooltip title="Edit">
                            <Button
                                type="ghost"
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    props.history.push(`/tournament-builder/bracket-creator/${record.slug}`);
                                }}
                            >
                                <Icon type="edit" />
                            </Button>
                        </Tooltip>
                        <Tooltip title={record.creator_id !== authState.user.id ? 'No access to delete' : 'Delete'}>
                            <Button
                                type="ghost"
                                color="red"
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    showConfirmDelete(record);
                                }}
                                disabled={record.creator_id !== authState.user.id}
                            >
                                <Icon type="delete" />
                            </Button>
                        </Tooltip>
                    </span>
                </React.Fragment>
            ),
        },
    ];

    function showConfirmDelete(record) {
        confirm({
            title: `Are you sure you want to delete ${record.name}?`,
            centered: true,
            transitionName: 'fade',
            maskTransitionName: 'none',
            // onOk() {
            // 	return new Promise((resolve, reject) => {
            // 		setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
            // 	}).catch(() => console.log('Oops errors!'));
            // },
            onOk() {
                deleteBracketTemplate({
                    variables: {
                        id: record.id,
                    },
                    notifyOnNetworkStatusChange: true,
                    awaitRefetchQueries: true,
                    refetchQueries: [
                        {
                            query: GET_TOURNAMENT_BRACKET_TEMPLATES,
                            variables: {
                                creator_id: authState.user.id,
                            },
                        },
                    ],
                })
                    .then((data) => {
                        console.log(data);
                        message.success(`Template ${record.id} deleted`);
                    })
                    .catch((error) => {
                        console.log(error);
                        message.info('There was an error', error);
                    });
            },
            onCancel() {},
        });
    }

    return (
        <SectionWrapper>
            <SectionHeader
                title="Bracket Creator"
                titleStyle={{ color: '#fff', margin: 0, textTransform: 'uppercase' }}
                subtitle="Create tournament bracket template"
                subtitlePrefix={true}
                subtitleStyle={{
                    color: 'rgba(255,255,255,0.8)',
                    textTransform: 'uppercase',
                    fontWeight: 600,
                    fontSize: 12,
                    letterSpacing: 3,
                }}
                height={90}
                theme="tournamentBuilder"
            >
                <Link to={`/tournament-builder/bracket-creator/new`}>
                    <Button type="ghost" ghost size="large">
                        + New Template
                    </Button>
                </Link>
            </SectionHeader>
            <SectionContent padding="20px 40px">
                <Row gutter={16}>
                    <Col>
                        <Card title="My Templates" bodyStyle={{ padding: 0 }}>
                            <Query
                                query={GET_TOURNAMENT_BRACKET_TEMPLATES}
                                fetchPolicy="cache-and-network"
                                notifyOnNetworkStatusChange={true}
                                variables={{
                                    creator_id: authState.user.id,
                                }}
                                onCompleted={(data) => {
                                    console.log(data);
                                }}
                            >
                                {({ loading, error, data }) => {
                                    if (loading) return <CircularLoader />;
                                    if (error) return <div style={styles.container}>Error: {error.message}</div>;
                                    if ((data && !data.tournament_bracket_templates) || (data && data.tournament_bracket_templates.length === 0)) {
                                        return <ZeroState showImage message="No templates found." style={{ margin: 30 }} {...props} />;
                                    }

                                    const templates = data.tournament_bracket_templates;

                                    return (
                                        <React.Fragment>
                                            {error && <div>Error: {error.message}</div>}

                                            {!loading && data && templates && templates.length > 0 ? (
                                                <div className="table_nowrap">
                                                    <Table
                                                        rowKey="id"
                                                        onRow={(record, rowIndex) => {
                                                            return {
                                                                onClick: (event) => {
                                                                    console.log(record, rowIndex);
                                                                    props.history.push({
                                                                        pathname: `/tournament-builder/bracket-creator/${record.slug}`,
                                                                        // search: '?query=abc',
                                                                        state: { data: record },
                                                                    });
                                                                }, // click row
                                                                onDoubleClick: (event) => {}, // double click row
                                                                onContextMenu: (event) => {}, // right button click row
                                                                onMouseEnter: (event) => {}, // mouse enter row
                                                                onMouseLeave: (event) => {}, // mouse leave row
                                                            };
                                                        }}
                                                        columns={columns}
                                                        dataSource={templates}
                                                        pagination={false}
                                                        tableLayout="auto"
                                                        scroll={{ x: 400 }}
                                                    />
                                                </div>
                                            ) : (
                                                <ZeroState
                                                    message="Your tournament templates will appear here."
                                                    messageStyle={{ fontSize: 16 }}
                                                    showImage={false}
                                                    style={{ margin: 25, textAlign: 'left' }}
                                                />
                                            )}
                                        </React.Fragment>
                                    );
                                }}
                            </Query>
                        </Card>
                    </Col>
                </Row>
            </SectionContent>
            <SectionFooter {...props} />
        </SectionWrapper>
    );
}

export default React.memo(TBBracketCreator);
