import React, { useState, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { Icon, Row, Col, PageHeader, Result, Steps, Typography, Button, Avatar, Input, List, Tabs, Empty, Skeleton } from 'antd';
import Card from '../../../components/Card';
import SectionHeader from '../../../components/SectionHeader';
import SectionContent from '../../../components/SectionContent';
import CreateEditEventForm from './CreateEditEventForm';
import CircularLoader from '../../../components/CircularLoader';
import { Query } from '@apollo/client/react/components';
import { GET_EVENT_QUERY } from '../data/queries';
import _ from 'lodash';
import qs from 'query-string';
import axios from 'axios';
import ZeroState from '../../../components/ZeroState';
import EventUploadFlyer from './EventUploadFlyer';
import ReviewEvent from './ReviewEvent';

const { Step } = Steps;
const { Text, Title } = Typography;

const styles = {
    cardHeader: {
        paddingTop: 5,
        paddingBottom: 5,
        background: 'linear-gradient(0deg, rgb(241, 244, 246), rgb(255, 255, 255))',
        // boxShadow: 'rgba(0, 0, 0, 0.05) 0px 2px 8px 0px',
        borderRadius: '10px 10px 0px 0px',
    },
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
};

function CreateEvent(props) {
    const params = qs.parse(props.location.search, { ignoreQueryPrefix: true });
    const step = params && params.step && params.step - 1;
    const [currentStep, setCurrentStep] = useState(step || 0);
    const [event, setEvent] = useState();

    function onChange(current) {
        setCurrentStep(current);
    }

    return (
        <React.Fragment>
            <SectionHeader
                title={props.title}
                titleStyle={{
                    color: '#fff',
                    textTransform: 'uppercase',
                    margin: 0,
                    display: 'flex',
                }}
                subtitle={props.match.params.slug ? 'Edit event details.' : 'Create en event in a few simple steps.'}
                subtitleStyle={{ color: '#fff' }}
                theme="digitalpool"
                backButton={
                    <Button
                        onClick={() => props.history.push(`/events`)}
                        type="default"
                        shape="circle"
                        icon="arrow-left"
                        size="large"
                        ghost
                        style={{ border: 'none' }}
                    />
                }
            />

            <SectionContent padding="20px 40px">
                <Row gutter={24} justify="center" type="flex">
                    <Col xs={24}>
                        <Card headStyle={styles.cardHeader} bodyStyle={{ padding: 0 }}>
                            <Row gutter={0} justify="start" type="flex">
                                <Col xs={24} sm={6} md={4}>
                                    <Steps
                                        direction="vertical"
                                        current={currentStep}
                                        onChange={onChange}
                                        style={{
                                            marginBottom: 20,
                                            paddingTop: 20,
                                            paddingLeft: 20,
                                            paddingRight: 20,
                                        }}
                                    >
                                        <Step title="Details" />
                                        <Step title="Upload Flyer" />
                                        <Step title="Review" />
                                    </Steps>
                                </Col>
                                <Col xs={24} sm={18} md={20}>
                                    {props.match.params.slug || event ? (
                                        <Query
                                            query={GET_EVENT_QUERY}
                                            fetchPolicy="cache-and-network"
                                            notifyOnNetworkStatusChange={true}
                                            variables={{ eventSlug: props.match.params.slug || event.slug }}
                                            onCompleted={async (data) => {
                                                console.log(data);
                                                const event = data && data.events && data.events[0];
                                                setEvent(event);
                                            }}
                                        >
                                            {({ loading, error, data }) => {
                                                if (loading)
                                                    return (
                                                        <div style={styles.container}>
                                                            <CircularLoader />
                                                        </div>
                                                    );
                                                if (error) return <div style={styles.container}>Error: {error.message}</div>;
                                                if (!loading && data && !data.events) {
                                                    return (
                                                        <ZeroState showImage message="You have not added any events yet." style={{ margin: 30 }} {...props} />
                                                    );
                                                }

                                                const event = data.events[0];

                                                return (
                                                    <React.Fragment>
                                                        {currentStep === 0 && (
                                                            <CreateEditEventForm
                                                                {...props}
                                                                event={event}
                                                                onContinue={(players) => {
                                                                    setCurrentStep(1);
                                                                    window.scrollTo(0, 0);
                                                                }}
                                                            />
                                                        )}

                                                        {currentStep === 1 && (
                                                            <React.Fragment>
                                                                {event && (
                                                                    <div>
                                                                        <EventUploadFlyer
                                                                            {...props}
                                                                            event={event}
                                                                            goBack={() => {
                                                                                setCurrentStep(0);
                                                                            }}
                                                                            onContinue={(players) => {
                                                                                setCurrentStep(2);
                                                                                window.scrollTo(0, 0);
                                                                            }}
                                                                        />
                                                                    </div>
                                                                )}
                                                            </React.Fragment>
                                                        )}

                                                        {currentStep === 2 && (
                                                            <div
                                                                style={{
                                                                    padding: 20,
                                                                }}
                                                            >
                                                                <ReviewEvent event={event} />
                                                                <Button
                                                                    type="primary"
                                                                    size="large"
                                                                    onClick={() => {
                                                                        props.history.push(`/events/${event.slug}`);
                                                                    }}
                                                                    style={{ marginTop: 20 }}
                                                                >
                                                                    <div
                                                                        style={{
                                                                            display: 'flex',
                                                                            alignItems: 'center',
                                                                        }}
                                                                    >
                                                                        <Text style={{ color: '#fff' }}>Go to Event</Text>
                                                                        {/* <Icon type="arrow-right" style={{ marginLeft: 3 }} /> */}
                                                                    </div>
                                                                </Button>
                                                                <Button
                                                                    type="secondary"
                                                                    size="large"
                                                                    onClick={() => {
                                                                        setCurrentStep(1);
                                                                    }}
                                                                    style={{ marginTop: 20 }}
                                                                >
                                                                    <div
                                                                        style={{
                                                                            display: 'flex',
                                                                            alignItems: 'center',
                                                                        }}
                                                                    >
                                                                        <Text>Go back</Text>
                                                                    </div>
                                                                </Button>
                                                            </div>
                                                        )}
                                                    </React.Fragment>
                                                );
                                            }}
                                        </Query>
                                    ) : (
                                        <React.Fragment>
                                            {currentStep === 0 && (
                                                <div style={{ padding: 20 }}>
                                                    <CreateEditEventForm
                                                        {...props}
                                                        onContinue={(event) => {
                                                            console.log(event);
                                                            setEvent(event);
                                                            setCurrentStep(1);
                                                        }}
                                                    />
                                                </div>
                                            )}

                                            {currentStep === 1 && (
                                                <Result
                                                    status="info"
                                                    title="You must create the event before you can upload a flyer."
                                                    extra={
                                                        <Button type="primary" key="console" size="large" onClick={() => setCurrentStep(0)}>
                                                            Go Back
                                                        </Button>
                                                    }
                                                />
                                            )}

                                            {currentStep === 2 && (
                                                <Result
                                                    status="info"
                                                    title="You must create the event before you can review it."
                                                    extra={
                                                        <Button type="primary" key="console" size="large" onClick={() => setCurrentStep(0)}>
                                                            Go to step 1
                                                        </Button>
                                                    }
                                                />
                                            )}
                                        </React.Fragment>
                                    )}
                                </Col>
                            </Row>
                        </Card>
                        {/* <Card
							headStyle={styles.cardHeader}
							title={
								<Steps size="small" current={0}>
									<Step title="General Information" />
									<Step title="League Operator Info" />
									<Step title="Division Setup" />
								</Steps>
							}
						>
							<CreateLeagueForm {...props} />
						</Card> */}
                    </Col>
                </Row>
            </SectionContent>
        </React.Fragment>
    );
}

export default withRouter(CreateEvent);
