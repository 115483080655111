import React from 'react';

export default function SectionWrapper(props) {
    const styles = {
        bg: {
            // backgroundColor: '#f0f2f5'
            background: 'linear-gradient(#fafafa,  #f9fafc)',
            // height: 'calc(100% - 109px)'
            // height: '100%'
            // overflowY: 'auto'
            // paddingBottom: 50
        },
    };

    return <div style={styles.bg}>{props.children}</div>;
}
