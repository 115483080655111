import React from 'react';
import ImageLoader from 'react-load-image';
import loadingSpinner from '../assets/img/spinner.gif';

function ImageLoading(props) {
	return <img src={loadingSpinner} alt="loading" />;
}

function ImageError(props) {
	return <div>Error</div>;
}

const Image = (props) => {
	return (
		<ImageLoader {...props.children.props}>
			{props.children}
			<ImageError />
			<ImageLoading />
		</ImageLoader>
	);
};

export default Image;
