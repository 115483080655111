import React, { useState, useEffect } from 'react';
import { Avatar, Typography, Row, Col, Button, Icon } from 'antd';
import { withRouter, Link } from 'react-router-dom';
import CircleFlag from '../../../components/CircleFlag';
import dpLogoWatermarkColor from '../../../assets/img/dp-logo-watermark-color.png';
import { getGameType, getCountryName, displayEnumType } from '../../../utils';

const { Text, Title } = Typography;

const styles = {
    container: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 20,
    },
};

function StreamOverlaySmall(props) {
    const { tournamentData, matchData, tableData, subscribeToMore, subscribeToNewData } = props;

    console.log({
        tournamentData,
        matchData,
        tableData,
    });

    let challenger1RaceTo;
    let challenger2RaceTo;
    if (matchData.identifier.includes('L')) {
        challenger1RaceTo = matchData.challenger1_race_to || matchData.tournament.losers_race_to || 0;
        challenger2RaceTo = matchData.challenger2_race_to || matchData.tournament.losers_race_to || 0;
    } else {
        challenger1RaceTo = matchData.challenger1_race_to || matchData.tournament.winners_race_to || 0;
        challenger2RaceTo = matchData.challenger2_race_to || matchData.tournament.winners_race_to || 0;
    }
    const currentGame = matchData && matchData.tournament_match_games && matchData.tournament_match_games[matchData.tournament_match_games.length - 1];

    useEffect(() => {
        let unsubscribe;
        if (subscribeToNewData) {
            unsubscribe = subscribeToNewData(subscribeToMore);
        }
        return () => {
            if (unsubscribe) {
                unsubscribe();
            }
        };
    }, []);

    return (
        <div style={{ flex: 1, paddingTop: 20, paddingLeft: 20, paddingRight: 10, paddingBottom: 20, display: 'flex' }}>
            <Row gutter={16} type="flex" justify="space-between" style={{ width: '100%' }}>
                <Col xs={12} sm={12}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {tournamentData.logo && <img src={tournamentData.logo} alt="Logo" height={120} />}

                        <div style={{ marginLeft: 20, display: 'flex', flexDirection: 'column' }}>
                            <div
                                style={{
                                    flex: 1,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    minWidth: 250,
                                }}
                            >
                                <div style={{ display: 'flex', alignItems: 'center', paddingRight: 50 }}>
                                    {matchData.challenger1_country && (
                                        <CircleFlag country={getCountryName(matchData.challenger1_country) || 'Unknown'} size="medium" />
                                    )}
                                    <Title
                                        level={3}
                                        style={{
                                            margin: 0,
                                            color: '#fff',
                                            letterSpacing: 0,
                                            textShadow: '0 2px 5px rgba(0,0,0,0.50)',
                                            textTransform: 'uppercase',
                                        }}
                                    >
                                        {matchData.challenger1_name}{' '}
                                        {matchData.challenger1_name &&
                                            matchData.challenger1_name !== 'BYE' &&
                                            matchData.challenger1_skill_level &&
                                            `(${matchData.challenger1_skill_level})`}
                                    </Title>
                                </div>

                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    {currentGame && currentGame.player_tpa && (
                                        <div
                                            style={{
                                                fontSize: 20,
                                                marginRight: 30,
                                            }}
                                        >
                                            <Text style={{ color: '#fff', fontWeight: 500, fontSize: 25 }}>TPA: {currentGame.player_tpa}</Text>
                                        </div>
                                    )}

                                    {tournamentData && tournamentData.game_type === 'ONE_POCKET' && (
                                        <Title
                                            level={2}
                                            style={{
                                                margin: 0,
                                                color: 'rgba(255,255,255,0.75)',
                                                letterSpacing: 0,
                                                textShadow: '0 2px 5px rgba(0,0,0,0.50)',
                                                marginRight: 20,
                                                minWidth: 20,
                                                textAlign: 'center',
                                            }}
                                        >
                                            {matchData.challenger1_points || 0} B
                                        </Title>
                                    )}

                                    <Title
                                        level={2}
                                        style={{
                                            margin: 0,
                                            color: '#fff',
                                            letterSpacing: 0,
                                            textShadow: '0 2px 5px rgba(0,0,0,0.50)',
                                            minWidth: 20,
                                            textAlign: 'center',
                                        }}
                                    >
                                        {matchData.challenger1_score || 0}
                                    </Title>
                                </div>
                            </div>
                            <div style={{ marginTop: 5, marginBottom: 5, borderTop: '1px solid rgba(255,255,255,0.2)' }} />
                            <div
                                style={{
                                    flex: 1,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    minWidth: 250,
                                }}
                            >
                                <div style={{ display: 'flex', alignItems: 'center', paddingRight: 50 }}>
                                    {matchData.challenger2_country && (
                                        <CircleFlag country={getCountryName(matchData.challenger2_country) || 'Unknown'} size="medium" />
                                    )}
                                    <Title
                                        level={3}
                                        style={{
                                            margin: 0,
                                            color: '#fff',
                                            letterSpacing: 0,
                                            textShadow: '0 2px 5px rgba(0,0,0,0.50)',
                                            textTransform: 'uppercase',
                                        }}
                                    >
                                        {matchData.challenger2_name}{' '}
                                        {matchData.challenger2_name &&
                                            matchData.challenger2_name !== 'BYE' &&
                                            matchData.challenger2_skill_level &&
                                            `(${matchData.challenger2_skill_level})`}
                                    </Title>
                                </div>

                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    {currentGame && currentGame.opponent_tpa && (
                                        <div
                                            style={{
                                                fontSize: 20,
                                                marginRight: 30,
                                            }}
                                        >
                                            <Text style={{ color: '#fff', fontWeight: 500, fontSize: 25 }}>TPA: {currentGame.opponent_tpa}</Text>
                                        </div>
                                    )}

                                    {tournamentData && tournamentData.game_type === 'ONE_POCKET' && (
                                        <Title
                                            level={2}
                                            style={{
                                                margin: 0,
                                                color: 'rgba(255,255,255,0.75)',
                                                letterSpacing: 0,
                                                textShadow: '0 2px 5px rgba(0,0,0,0.50)',
                                                marginRight: 20,
                                                minWidth: 20,
                                                textAlign: 'center',
                                            }}
                                        >
                                            {matchData.challenger2_points || 0} B
                                        </Title>
                                    )}

                                    <Title
                                        level={2}
                                        style={{
                                            margin: 0,
                                            color: '#fff',
                                            letterSpacing: 0,
                                            textShadow: '0 2px 5px rgba(0,0,0,0.50)',
                                            minWidth: 20,
                                            textAlign: 'center',
                                        }}
                                    >
                                        {matchData.challenger2_score || 0}
                                    </Title>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col xs={12} sm={12} align="right">
                    <img src={dpLogoWatermarkColor} alt="Digital Pool Logo" width={80} />
                </Col>
            </Row>
        </div>
    );
}

export default withRouter(StreamOverlaySmall);
