import React from 'react';
import { Typography, Tooltip, Row, Col, Icon, Button, Table, Progress } from 'antd';
import { Query, Subscription } from '@apollo/client/react/components';
import CircularLoader from '../../../components/CircularLoader';
import SectionTitle from '../../../components/SectionTitle';
import SectionContent from '../../../components/SectionContent';
import SectionWrapper from '../../../components/SectionWrapper';
import SectionFooter from '../../../components/SectionFooter';
import { getCountryName } from '../../../utils/getCountryName';
import { getGameType, displayEnumType } from '../../../utils';
import Card, { Meta } from '../../../components/Card';
import blueBg from '../../../assets/img/blue-bg.png';
import _ from 'lodash';
import { GET_LEAGUE_DIVISION_SCHEDULES } from '../data/queries';
import useBreakpoint from 'use-breakpoint';
import { defaultBreakpoints } from '../../../utils/defaultBreakpoints';
import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';
momentDurationFormatSetup(moment);

const { Title, Text } = Typography;
const defaultSize = 'medium';

function LMDivisionSchedule(props) {
    const { league, division } = props;
    const { breakpoint, maxWidth, minWidth } = useBreakpoint(defaultBreakpoints);

    const styles = {};

    const columns = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
            width: 100,
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => {
                return <Text>{text}</Text>;
            },
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            render: (text, record) => {
                return <Text style={{ whiteSpace: 'pre-wrap' }}>{text}</Text>;
            },
        },
        {
            title: 'Start Date',
            dataIndex: 'start_date',
            key: 'start_date',
            render: (text, record) => {
                return <Text style={{ whiteSpace: 'nowrap' }}>{moment(text).format('lll')}</Text>;
            },
        },
        {
            title: 'Start Time',
            dataIndex: 'start_time',
            key: 'start_time',
            render: (text, record) => {
                return <Text style={{ whiteSpace: 'nowrap' }}>{moment(text).format('h:mm A')}</Text>;
            },
        },

        {
            title: 'Duration Type',
            dataIndex: 'duration_type',
            key: 'duration_type',
            render: (text, record) => {
                return (
                    <Text style={{ whiteSpace: 'pre-wrap' }}>
                        {displayEnumType(text)}
                        {/* {record.duration_type === 'SAME_OPPONENT_PLAYS' ? (
							'Number of times all opponents plays each other in the division'
						) : (
							'Total weeks of play in the division'
						)} */}
                    </Text>
                );
            },
        },
        {
            title: 'Duration',
            dataIndex: 'duration',
            key: 'duration',
            render: (text, record) => {
                return <Text>{text}</Text>;
            },
        },
        {
            title: 'Total Weeks',
            dataIndex: 'total_weeks',
            key: 'total_weeks',
            render: (text, record) => {
                return <Text>{text}</Text>;
            },
        },
        {
            title: 'Total Teams',
            dataIndex: 'total_teams',
            key: 'total_teams',
            render: (text, record) => {
                return <Text>{text}</Text>;
            },
        },
        {
            title: 'Total Players',
            dataIndex: 'total_players',
            key: 'total_players',
            render: (text, record) => {
                return <Text>{text}</Text>;
            },
        },

        // {
        // 	title: 'Reserved weeks',
        // 	dataIndex: 'reserved_weeks',
        // 	key: 'reserved_weeks',
        // 	width: 200,
        // 	render: (text, record) => {
        // 		return <Text>{text}</Text>;
        // 	}
        // },
        // {
        // 	title: 'Weeks off',
        // 	dataIndex: 'weeks_off',
        // 	key: 'weeks_off',
        // 	width: 200,
        // 	render: (text, record) => {
        // 		return <Text>{text}</Text>;
        // 	}
        // },
        {
            title: 'Status',
            dataIndex: 'is_active',
            key: 'is_active',
            render: (text, record) => {
                return <Text>{record.is_active === true ? 'Active' : 'Inactive'}</Text>;
            },
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <span style={{ whiteSpace: 'nowrap' }}>
                    <Tooltip title="Edit">
                        <Button
                            type="ghost"
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                // onEditSelected(record.id);
                                props.history.push(`/league-manager/${props.league.slug}/divisions/${props.division.slug}/schedule/${record.slug}/edit`);
                            }}
                        >
                            <Icon type="edit" />
                        </Button>
                    </Tooltip>
                    {/* <Divider type="vertical" /> */}
                    {/* <Tooltip title="Delete">
						<Button
							type="ghost"
							onClick={(e) => {
								e.preventDefault();
								e.stopPropagation();
								// showConfirm(record);
							}}
						>
							<Icon type="delete" />
						</Button>
					</Tooltip> */}
                </span>
            ),
        },
    ];

    function getClassName(record) {
        const status =
            record.status === 'IN_PROGRESS'
                ? 'in_progress'
                : record.status === 'COMPLETED'
                ? 'completed'
                : record.status === 'NOT_STARTED'
                ? 'not_started'
                : '';
        const isStreamingTable = record.pool_table && record.pool_table.is_streaming_table === true ? 'streaming' : '';
        const isFeaturedTable = record.pool_table && record.pool_table.is_featured_table === true ? 'featured' : '';
        return `${status} ${isStreamingTable} ${isFeaturedTable}`;
    }

    return (
        <SectionWrapper>
            {/* <SectionTitle
				title="Schedule"
				titleStyle={{
					color: '#fff',
					margin: 0,
					textTransform: 'uppercase'
				}}
				height={160}
				theme="leagueManager"
			/> */}
            <SectionContent padding="20px 40px">
                <Row gutter={24} justify="center" type="flex">
                    {/* <Col xs={24}>
						<div
							style={{
								paddingBottom: 10,
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'space-between'
							}}
						>
							<Title level={3} style={{ margin: 0, padding: 0 }}>
								Division Schedule
							</Title>
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'space-between'
								}}
							>
								<Button
									type="primary"
									onClick={() => {
										props.history.push(
											`/league-manager/${league.slug}/divisions/${division.slug}/schedule/new`
										);
									}}
								>
									<Icon type="plus" /> Create Schedule
								</Button>
							</div>
						</div>
					</Col> */}
                    <Col xs={24}>
                        <Query
                            query={GET_LEAGUE_DIVISION_SCHEDULES}
                            fetchPolicy="cache-and-network"
                            notifyOnNetworkStatusChange={true}
                            variables={{ leagueId: league.id, divisionId: division.id }}
                            onCompleted={(data) => {
                                console.log(data);
                            }}
                        >
                            {({ loading, error, data }) => {
                                if (loading) return <CircularLoader />;
                                if (error) return <div style={styles.container}>Error: {error.message}</div>;

                                const schedules =
                                    data.league_schedules &&
                                    data.league_schedules.map((item, index) => {
                                        return {
                                            ...item,
                                            total_teams:
                                                item.league_division &&
                                                item.league_division.league_team_divisions_aggregate &&
                                                item.league_division.league_team_divisions_aggregate.aggregate &&
                                                item.league_division.league_team_divisions_aggregate.aggregate.count,
                                            total_players:
                                                item.league_division &&
                                                item.league_division.league_player_divisions_aggregate &&
                                                item.league_division.league_player_divisions_aggregate.aggregate &&
                                                item.league_division.league_player_divisions_aggregate.aggregate.count,
                                            key: index,
                                        };
                                    });
                                return (
                                    <Card
                                        // title="In Progress"
                                        bodyStyle={{ padding: 0 }}
                                        // style={{
                                        // 	borderRadius: 8,
                                        // 	overflow: 'hidden',
                                        // 	border: 'none',
                                        // 	boxShadow: '0 2px 5px 0px rgba(0, 0, 0, 0.1)'
                                        // }}
                                        // cover={
                                        // 	<div
                                        // 		style={{
                                        // 			paddingTop: 16,
                                        // 			paddingBottom: 16,
                                        // 			paddingLeft: 24,
                                        // 			paddingRight: 24,
                                        // 			// background:'linear-gradient(rgb(239, 243, 245), rgb(255, 255, 255))',
                                        // 			borderBottom: '1px solid rgba(0, 0, 0, 0.05)'
                                        // 		}}
                                        // 	>
                                        // 		<Title level={4} style={{ margin: 0, padding: 0, fontSize: 18 }}>
                                        // 			Manage Schedules ({schedules && schedules.length})
                                        // 		</Title>
                                        // 	</div>
                                        // }

                                        cover={
                                            <div
                                                style={{
                                                    paddingTop: 16,
                                                    paddingBottom: 16,
                                                    paddingLeft: 24,
                                                    paddingRight: 24,
                                                    borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'space-between',
                                                }}
                                            >
                                                <Title level={4} style={{ margin: 0, padding: 0, fontSize: 18 }}>
                                                    Division Schedule ({schedules && schedules.length})
                                                </Title>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'space-between',
                                                    }}
                                                >
                                                    <Button
                                                        type="primary"
                                                        onClick={() => {
                                                            props.history.push(`/league-manager/${league.slug}/divisions/${division.slug}/schedule/new`);
                                                        }}
                                                    >
                                                        <Icon type="plus" /> Generate Schedule
                                                    </Button>
                                                </div>
                                            </div>
                                        }
                                    >
                                        <div className="table_nowrap">
                                            <Table
                                                dataSource={schedules}
                                                columns={columns}
                                                className="match-table table-striped-rows"
                                                tableLayout="auto"
                                                scroll={{ x: 400 }}
                                                size={defaultSize}
                                                pagination={false}
                                                // components={{
                                                // 	body: {
                                                // 		row: RenderRow
                                                // 	}
                                                // }}
                                                onRow={(record, rowIndex) => {
                                                    return {
                                                        onClick: (e) => {
                                                            e.preventDefault();
                                                            e.stopPropagation();
                                                            props.history.push(
                                                                `/league-manager/${props.league.slug}/divisions/${props.division.slug}/schedule/${record.slug}`
                                                            );
                                                        }, // click row
                                                        onDoubleClick: (event) => {}, // double click row
                                                        onContextMenu: (event) => {}, // right button click row
                                                        onMouseEnter: (event) => {}, // mouse enter row
                                                        onMouseLeave: (event) => {}, // mouse leave row
                                                        className: getClassName(record),
                                                    };
                                                }}
                                                onHeaderRow={(column) => {
                                                    return {
                                                        onClick: () => {}, // click header row
                                                    };
                                                }}
                                            />
                                        </div>
                                    </Card>
                                );
                            }}
                        </Query>
                    </Col>
                </Row>
            </SectionContent>
            <SectionFooter {...props} />
        </SectionWrapper>
    );
}

export default LMDivisionSchedule;
