import React from 'react';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';

// import './styles.css';

const renderTime = ({ remainingTime }) => {
    // if (remainingTime === 0) {
    //     return <div className="timer">Too late...</div>;
    // }

    return (
        <div className="timer">
            {/* <div className="text">Remaining</div> */}
            <div className="value">{remainingTime}</div>
            {/* <div className="text">seconds</div> */}
        </div>
    );
};

export default function CountdownTimerProgress(props) {
    const { duration, isPlaying } = props;
    console.log(props);

    return (
        <div className="timer-wrapper">
            <CountdownCircleTimer
                size={50}
                isPlaying={isPlaying}
                duration={duration || 7}
                strokeWidth={4}
                trailStrokeWidth={4}
                trailColor={'#999'}
                colors={['#004777', '#F7B801', '#A30000', '#A30000']}
                colorsTime={[10, 6, 3, 0]}
                onComplete={() => ({ shouldRepeat: true, delay: 0 })}
            >
                {renderTime}
            </CountdownCircleTimer>
        </div>
    );
}
