import React, { useState, useEffect } from 'react';
import { message, List, Modal, Tabs, Row, Col, Button, Icon, Tooltip, Typography, Empty, Input } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import { Query, Subscription } from '@apollo/client/react/components';
import { GET_ALL_TOURNAMENT_TABLES_SUBSCRIPTION, GET_TOURNAMENT_PLAYERS_SUBSCRIPTION_W_STATS } from './data/subscriptions';
import { UPDATE_TOURNAMENT_PLAYER_MUTATION } from './data/mutations';
import { GET_TOURNAMENT_PLAYERS_W_STATS } from './data/queries';
import { formatPlayerName, getOrdinalSuffix } from '../../utils';
import CircleFlag from '../../components/CircleFlag';
import ZeroState from '../../components/ZeroState';
import { TrophyFilled } from '@ant-design/icons';
import CircularLoader from '../../components/CircularLoader';
import PlayerModal from './PlayerModal';
import ViewerResults from './ViewerResults';
import Fade from 'react-reveal/Fade';
import _ from 'lodash';

const { TabPane } = Tabs;
const { Search } = Input;
const { Text, Title } = Typography;
const { confirm } = Modal;

const styles = {
    container: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
};

const tabHeight = 45;

var seeder = function () {
    var seed = [];
    return {
        set: function (length) {
            for (var i = 0; i < length; i++) {
                seed.push(Math.random());
            }
            return seed;
        },
        get: function () {
            return seed;
        },
        clear: function () {
            seed = [];
        },
    };
};

export default function ViewerPlayers(props) {
    const { tournament, players, tables, isPublic, playerQueue, matches, bracketHeight, theme, onStartTournament } = props;
    const [searchResults, setSearchResults] = useState();
    const [playerModalVisible, setPlayerModalVisible] = useState(false);
    const [selectedPlayer, setSelectedPlayer] = useState({});
    const seed = seeder();

    function handlePlayerModalVisible(visibility) {
        setPlayerModalVisible(visibility);
    }

    function escapeRegexCharacters(str) {
        return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    }

    function onChange(e) {
        const value = e.target.value;
        const suggestions = getSuggestions(value);
        setSearchResults(suggestions);
    }

    function handleSearch(value) {
        const suggestions = getSuggestions(value);
        setSearchResults(suggestions);
    }

    function getSuggestions(value) {
        const inputValue = value.trim().toLowerCase();
        const escapedValue = escapeRegexCharacters(inputValue);

        if (escapedValue === '') {
            return [];
        }
        const regex = new RegExp('\\b' + escapedValue, 'i');
        const suggestions = players;
        const filtered = suggestions && suggestions.filter((suggestion) => regex.test(suggestion.name));
        return filtered;
    }

    function handleShowPlayerModal(player) {
        console.log(player);
        setSelectedPlayer(player);
        setPlayerModalVisible(true);
    }

    function randomShuffle(ar, seed) {
        var numbers = [];
        for (var a = 0, max = ar.length; a < max; a++) {
            numbers.push(a);
        }
        var shuffled = [];

        for (var i = 0, len = ar.length; i < len; i++) {
            var r = parseInt(seed[i] * (len - i));
            shuffled.push(ar[numbers[r]]);
            numbers.splice(r, 1);
        }
        return shuffled;
    }

    function generateSeeds(playerData) {
        seed.set(playerData.length);
        const randShuffle = randomShuffle(playerData, seed.get());
        // const roundRobinPairings = makeRoundRobinPairings(randShuffle);
        // const roundRobinPairings2 = roundRobin(randShuffle.length, randShuffle);
        console.log('shuffle', randShuffle);
        const updatedPlayers = randShuffle.map((player) => {
            return {
                ...player,
            };
        });

        console.log('updated players', updatedPlayers);
        return _.compact(updatedPlayers);
    }

    function reshufflePlayers(players) {
        const seededPlayers = generateSeeds(players);
        // setTournamentPlayers(seededPlayers);

        const playersToUpdate = seededPlayers.map((player, index) => {
            return {
                id: player.id,
                seed: index + 1,
                last_played_at: null,
            };
        });
        let result = playersToUpdate.reduce((accumulatorPromise, nextPlayer) => {
            return accumulatorPromise.then(() => {
                return updatePlayer(nextPlayer);
            });
        }, Promise.resolve());

        result.then((e) => {
            message.success(`Up next players re-shuffled`);
            console.log('PLAYER UPDATE COMPLETE', playersToUpdate);
        });
    }

    function updatePlayer(player) {
        return props.client
            .mutate({
                mutation: UPDATE_TOURNAMENT_PLAYER_MUTATION,
                variables: {
                    id: player.id,
                    tournament_id: tournament.id,
                    changes: player,
                },
                // notifyOnNetworkStatusChange: true,
                // awaitRefetchQueries: true,
                // refetchQueries: [
                //     {
                //         query: GET_TOURNAMENT_PLAYERS_W_STATS,
                //         variables: { tournament_id: tournament.id },
                //     },
                // ],
            })
            .then((data) => {
                // console.log(data);
                return data;
            })
            .catch((error) => {
                console.error(error);
            });
    }

    const playersInMatches = players.filter((player) => {
        return player.is_eliminated === false && player.table;
    });

    const playersLeft = players.filter((player) => {
        return player.is_eliminated === false;
    });

    const playersEliminated = players
        .filter((player) => {
            return player.is_eliminated === true;
        })
        .sort((a, b) => {
            return a.place - b.place;
        });

    const results =
        players &&
        players.map((player) => {
            return {
                ...(player.id && { id: player.id }),
                ...(player.id && { key: player.id }),
                ...(player.place && { place: player.place }),
                ...(player.name && { name: player.name }),
                ...(player.country && { country: player.country }),
                ...(player.skill_level && { skillLevel: player.skill_level }),
                ...(player.payouts && player.payouts.money && { earnings: player.payouts.money }),
                ...(player.payouts && player.payouts.calcutta && { calcutta: player.payouts.calcutta }),
                ...(player.player_points && player.player_points.points && { points: player.player_points.points }),
                ...(player.matches && { matches: player.matches }),
                // totalMatchDurationMinutes,
                // avgMatchDurationMinutes,
                // totalMatchDurationHours,
                // avgMatchDurationHours
            };
        });

    return (
        <React.Fragment>
            <Row
                gutter={16}
                justify="center"
                type="flex"
                style={{
                    margin: 0,
                    padding: 0,
                    height: bracketHeight - tabHeight - 59,
                    width: '100%',
                }}
            >
                {tournament.status === 'IN_PROGRESS' ? (
                    <React.Fragment>
                        <Col xs={24} style={{ borderBottom: `1px solid ${theme.bracket.panels.borderColor}` }}>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    color: theme.bracket.panels.textColor,
                                }}
                            >
                                <div>
                                    Total players: <b>{players.length}</b>
                                </div>
                                <div>
                                    Players left: <b>{playersLeft.length}</b>
                                </div>
                                <div>
                                    Eliminated: <b>{playersEliminated.length}</b>
                                </div>
                            </div>
                        </Col>
                        <Col xs={24} style={{ borderBottom: `1px solid ${theme.bracket.panels.borderColor}` }}>
                            <Search
                                placeholder="Search by player name"
                                onSearch={handleSearch}
                                onChange={onChange}
                                className={theme && theme.name === 'dark' && 'player-search-clear'}
                            />
                        </Col>

                        <Col
                            xs={24}
                            style={{
                                margin: 0,
                                padding: 0,
                                overflowY: 'auto',
                                height: isPublic === true ? 'calc(100% - 30px)' : 'calc(100% - 100px)',
                                width: '100%',
                                alignItems: 'center',
                            }}
                        >
                            <div>
                                <List
                                    className="hover-list player-list"
                                    loading={false}
                                    itemLayout="horizontal"
                                    dataSource={
                                        searchResults && searchResults.length > 0
                                            ? searchResults.filter((player) => {
                                                  return player.is_eliminated === false;
                                              })
                                            : playersInMatches
                                        //   .filter((player) => {
                                        //       return player.table;
                                        //   })
                                    }
                                    header={
                                        <div
                                            style={{
                                                paddingRight: 10,
                                                margin: 0,
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                            }}
                                        >
                                            <div>
                                                <Text style={{ fontWeight: 700, marginLeft: 10, color: theme.bracket.panels.textColor }}>#</Text>
                                                <Text style={{ fontWeight: 700, marginLeft: 15, color: theme.bracket.panels.textColor }}>Name</Text>
                                            </div>
                                            {/* <Text style={{ fontWeight: 700, color: theme.bracket.panels.textColor }}>Place</Text> */}
                                            <div>
                                                <Text style={{ fontWeight: 700, color: theme.bracket.panels.textColor }}>Chips</Text>
                                                <Text style={{ marginLeft: 20, fontWeight: 700, color: theme.bracket.panels.textColor }}>W/L</Text>
                                            </div>
                                        </div>
                                    }
                                    renderItem={(player, index) => {
                                        const matches =
                                            player.matches &&
                                            player.matches
                                                .filter((match) => {
                                                    return match.status === 'IN_PROGRESS';
                                                })
                                                .sort((a, b) => {
                                                    return b.match_number - a.match_number;
                                                });

                                        // console.log('player', player);
                                        // console.log('matches', player.matches);

                                        // console.log({
                                        //     matchesWon,
                                        //     matchesLost,
                                        // });

                                        let versus;
                                        if (matches && matches[0]) {
                                            versus =
                                                matches[0].challenger1_name !== player.name
                                                    ? matches[0].challenger1_name
                                                    : matches[0].challenger2_name !== player.name
                                                    ? matches[0].challenger2_name
                                                    : null;
                                        }

                                        return (
                                            <Fade up cascade distance="40px" delay={300} duration={500}>
                                                <React.Fragment>
                                                    {player && player.name && (
                                                        <List.Item
                                                            style={{
                                                                padding: '10px 10px',
                                                                color: theme.bracket.panels.textColor,
                                                            }}
                                                            onClick={(event) => {
                                                                handleShowPlayerModal(player);
                                                            }}
                                                            // actions={[
                                                            //     <Text style={{ whiteSpace: 'nowrap', color: theme.bracket.panels.textColor }}>
                                                            //         {player.chips_left || '-'}
                                                            //     </Text>,
                                                            // ]}
                                                        >
                                                            {/* <Text style={{ width: 80, color: theme.bracket.panels.textColor }}>
                                                                            {player.seed || '-'}
                                                                        </Text> */}
                                                            <div
                                                                style={{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    width: '100%',
                                                                }}
                                                            >
                                                                <div>
                                                                    <Text style={{ color: theme.bracket.panels.textColor }}>{index + 1}</Text>
                                                                </div>
                                                                <div
                                                                    style={{
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                        flex: 1,
                                                                        marginLeft: 15,
                                                                    }}
                                                                >
                                                                    <div>{player.country && <CircleFlag country={player.country} left={0} />}</div>

                                                                    <Text
                                                                        style={{
                                                                            marginLeft: 0,
                                                                            width: '100%',
                                                                            whiteSpace: 'nowrap',
                                                                            overflow: 'hidden',
                                                                            textOverflow: 'ellipsis',
                                                                            color: theme.bracket.panels.textColor,
                                                                        }}
                                                                    >
                                                                        <div className="ellipsis">
                                                                            <b>
                                                                                {formatPlayerName(player)}{' '}
                                                                                {player.skill_level ? `(${player.skill_level})` : null}
                                                                            </b>
                                                                        </div>
                                                                        {player.table && <div style={{ color: '#1890ff' }}>{player.table}</div>}

                                                                        {/* {player.table ? (
                                                                <div style={{ color: '#1890ff' }}>
                                                                    {player.table} {versus && `vs. ${versus}`}
                                                                </div>
                                                            ) : !player.table && versus ? (
                                                                <div style={{ color: '#1890ff' }}>{versus && `vs. ${versus}`}</div>
                                                            ) : (
                                                                <div style={{ color: '#a7e063' }}>Up Next</div>
                                                            )} */}

                                                                        {/* {!player.table && !versus && <div style={{ color: '#a7e063' }}>Up Next</div>} */}
                                                                    </Text>
                                                                </div>

                                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                    {/* <Text style={{ color: player.chips_left === 1 ? '#ff9800' : theme.bracket.panels.textColor }}> */}
                                                                    <Text
                                                                        style={{ color: player.chips_left === 1 ? '#f5222d' : theme.bracket.panels.textColor }}
                                                                    >
                                                                        {player.chips_left || 0}
                                                                    </Text>

                                                                    <Text
                                                                        style={{
                                                                            marginLeft: 15,
                                                                            width: 40,
                                                                            textAlign: 'right',
                                                                            color: theme.bracket.panels.textColor,
                                                                        }}
                                                                    >
                                                                        {player.matches_won || player.matches_lost
                                                                            ? `${player.matches_won}-${player.matches_lost}`
                                                                            : '0-0'}
                                                                    </Text>
                                                                </div>
                                                            </div>
                                                        </List.Item>
                                                    )}
                                                </React.Fragment>
                                            </Fade>
                                        );
                                    }}
                                />
                                <div
                                    style={{
                                        paddingTop: 15,
                                        color: theme.bracket.panels.textColor,
                                    }}
                                >
                                    <Title level={4} style={{ paddingLeft: 20, paddingRight: 20, fontSize: 14, color: theme.bracket.panels.textColor }}>
                                        Players up next
                                    </Title>
                                    {playerQueue && playerQueue.length > 0 ? (
                                        <ul
                                            style={{
                                                margin: 0,
                                                padding: 0,
                                                listStyle: 'none',
                                                color: theme.bracket.panels.textColor,
                                            }}
                                        >
                                            <List
                                                className={`hover-list ${theme && theme.name}`}
                                                size="small"
                                                itemLayout="horizontal"
                                                dataSource={
                                                    searchResults && searchResults.length > 0
                                                        ? searchResults
                                                              .filter((player) => {
                                                                  return player.is_eliminated === false && !player.table;
                                                              })
                                                              .sort((a, b) => {
                                                                  return a.seed - b.seed && a.matches_lost - b.matches_lost;
                                                              })
                                                        : playerQueue
                                                }
                                                renderItem={(player, index) => {
                                                    const matches =
                                                        player.matches &&
                                                        player.matches
                                                            .filter((match) => {
                                                                return match.status === 'IN_PROGRESS';
                                                            })
                                                            .sort((a, b) => {
                                                                return b.match_number - a.match_number;
                                                            });

                                                    let versus;
                                                    if (matches && matches[0]) {
                                                        versus =
                                                            matches[0].challenger1_name !== player.name
                                                                ? matches[0].challenger1_name
                                                                : matches[0].challenger2_name !== player.name
                                                                ? matches[0].challenger2_name
                                                                : null;
                                                    }

                                                    return (
                                                        <React.Fragment>
                                                            <List.Item
                                                                style={{
                                                                    padding: '10px 10px',
                                                                    color: theme.bracket.panels.textColor,
                                                                }}
                                                                onClick={(event) => {
                                                                    handleShowPlayerModal(player);
                                                                }}
                                                                // actions={[
                                                                //     <Text style={{ whiteSpace: 'nowrap', color: theme.bracket.panels.textColor }}>
                                                                //         {player.chips_left || '-'}
                                                                //     </Text>,
                                                                // ]}
                                                            >
                                                                {/* <Text style={{ width: 80, color: theme.bracket.panels.textColor }}>
                                                                            {player.seed || '-'}
                                                                        </Text> */}
                                                                <div
                                                                    style={{
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                        width: '100%',
                                                                    }}
                                                                >
                                                                    <div>
                                                                        <Text style={{ color: theme.bracket.panels.textColor }}>{index + 1}</Text>
                                                                    </div>
                                                                    <div
                                                                        style={{
                                                                            display: 'flex',
                                                                            alignItems: 'center',
                                                                            flex: 1,
                                                                            marginLeft: 15,
                                                                        }}
                                                                    >
                                                                        <div>{player.country && <CircleFlag country={player.country} left={0} />}</div>

                                                                        <Text
                                                                            style={{
                                                                                marginLeft: 0,
                                                                                width: '100%',
                                                                                whiteSpace: 'nowrap',
                                                                                overflow: 'hidden',
                                                                                textOverflow: 'ellipsis',
                                                                                color: theme.bracket.panels.textColor,
                                                                            }}
                                                                        >
                                                                            <div className="ellipsis">
                                                                                <b>
                                                                                    {formatPlayerName(player)}{' '}
                                                                                    {player.skill_level ? `(${player.skill_level})` : null}
                                                                                </b>
                                                                            </div>

                                                                            {/* {player.table ? (
                                                                <div style={{ color: '#1890ff' }}>
                                                                    {player.table} {versus && `vs. ${versus}`}
                                                                </div>
                                                            ) : !player.table && versus ? (
                                                                <div style={{ color: '#1890ff' }}>{versus && `vs. ${versus}`}</div>
                                                            ) : (
                                                                <div style={{ color: '#a7e063' }}>Up Next</div>
                                                            )} */}

                                                                            {!player.table && !versus && <div style={{ color: '#a7e063' }}>Up Next</div>}
                                                                        </Text>
                                                                    </div>

                                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                        {/* <Text style={{ color: player.chips_left === 1 ? '#ff9800' : theme.bracket.panels.textColor }}> */}
                                                                        <Text
                                                                            style={{
                                                                                color: player.chips_left === 1 ? '#f5222d' : theme.bracket.panels.textColor,
                                                                            }}
                                                                        >
                                                                            {player.chips_left || 0}
                                                                        </Text>

                                                                        <Text
                                                                            style={{
                                                                                marginLeft: 15,
                                                                                width: 40,
                                                                                textAlign: 'right',
                                                                                color: theme.bracket.panels.textColor,
                                                                            }}
                                                                        >
                                                                            {player.matches_won || player.matches_lost
                                                                                ? `${player.matches_won}-${player.matches_lost}`
                                                                                : '0-0'}
                                                                        </Text>
                                                                    </div>
                                                                </div>
                                                            </List.Item>
                                                        </React.Fragment>
                                                    );
                                                }}
                                            />
                                            {/* {playersEliminated &&
                                    playersEliminated.map((player, index) => {
                                        return (
                                            <li key={index} style={styles.error}>
                                                <Text style={styles.error}>{player.name}</Text>
                                            </li>
                                        );
                                    })} */}
                                        </ul>
                                    ) : (
                                        <div
                                            style={{
                                                paddingLeft: 20,
                                                paddingRight: 20,
                                                color: theme.bracket.panels.textColor,
                                            }}
                                        >
                                            All players are currently playing.
                                        </div>
                                    )}
                                </div>
                                <div
                                    style={{
                                        paddingTop: 15,
                                        color: theme.bracket.panels.textColor,
                                    }}
                                >
                                    <Title level={4} style={{ paddingLeft: 20, paddingRight: 20, fontSize: 14, color: theme.bracket.panels.textColor }}>
                                        Players eliminated
                                    </Title>
                                    {playersEliminated && playersEliminated.length > 0 ? (
                                        <ul
                                            style={{
                                                margin: 0,
                                                padding: 0,
                                                listStyle: 'none',
                                                color: theme.bracket.panels.textColor,
                                            }}
                                        >
                                            <List
                                                className={`hover-list ${theme && theme.name}`}
                                                size="small"
                                                itemLayout="horizontal"
                                                dataSource={
                                                    searchResults && searchResults.length > 0
                                                        ? searchResults.filter((player) => {
                                                              return player.is_eliminated === true;
                                                          })
                                                        : playersEliminated.filter((player) => {
                                                              return player.is_eliminated === true;
                                                          })
                                                }
                                                renderItem={(player, index) => {
                                                    return (
                                                        <React.Fragment>
                                                            <List.Item
                                                                style={{
                                                                    padding: '10px 10px',
                                                                    // textDecoration: player.place !== null ? 'line-through' : 'normal',
                                                                    // opacity: player.place !== null ? 0.5 : 1,
                                                                    color: theme.bracket.panels.textColor,
                                                                }}
                                                                onClick={(event) => {
                                                                    handleShowPlayerModal(player);
                                                                }}
                                                                // actions={[
                                                                //     <Text style={{ whiteSpace: 'nowrap', color: theme.bracket.panels.textColor }}>
                                                                //         {player.chips_left || '-'}
                                                                //     </Text>,
                                                                // ]}
                                                            >
                                                                {/* <Text style={{ width: 80, color: theme.bracket.panels.textColor }}>
                                                                            {player.seed || '-'}
                                                                        </Text> */}
                                                                <div
                                                                    style={{
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                        width: '100%',
                                                                    }}
                                                                >
                                                                    <div>
                                                                        <Text style={{ color: theme.bracket.panels.textColor }}>{index + 1}</Text>
                                                                    </div>
                                                                    <div
                                                                        style={{
                                                                            display: 'flex',
                                                                            alignItems: 'center',
                                                                            flex: 1,
                                                                            marginLeft: 15,
                                                                        }}
                                                                    >
                                                                        <div>{player.country && <CircleFlag country={player.country} left={0} />}</div>

                                                                        <Text
                                                                            style={{
                                                                                marginLeft: 0,
                                                                                width: '100%',
                                                                                whiteSpace: 'nowrap',
                                                                                overflow: 'hidden',
                                                                                textOverflow: 'ellipsis',
                                                                                color: theme.bracket.panels.textColor,
                                                                            }}
                                                                        >
                                                                            <div className="ellipsis">
                                                                                <b>
                                                                                    {formatPlayerName(player)}{' '}
                                                                                    {player.place === '1' ? (
                                                                                        <React.Fragment>
                                                                                            {' - '}
                                                                                            <TrophyFilled
                                                                                                style={{
                                                                                                    color: '#eab700',
                                                                                                    marginRight: 5,
                                                                                                }}
                                                                                            />
                                                                                        </React.Fragment>
                                                                                    ) : player.place === '2' ? (
                                                                                        <React.Fragment>
                                                                                            {' - '}
                                                                                            <TrophyFilled
                                                                                                style={{
                                                                                                    color: '#9baab1',
                                                                                                    marginRight: 5,
                                                                                                }}
                                                                                            />
                                                                                        </React.Fragment>
                                                                                    ) : (
                                                                                        player.place === '3' && (
                                                                                            <React.Fragment>
                                                                                                {' - '}
                                                                                                <TrophyFilled
                                                                                                    style={{
                                                                                                        color: '#f19921',
                                                                                                        marginRight: 5,
                                                                                                    }}
                                                                                                />
                                                                                            </React.Fragment>
                                                                                        )
                                                                                    )}
                                                                                    {player.place && parseInt(player.place) > 3 && ' - '}
                                                                                    {player.place ? `${getOrdinalSuffix(player.place)}` : null}
                                                                                    {/* {player.place ? `(${getOrdinalSuffix(player.place)})` : null} */}
                                                                                </b>
                                                                            </div>

                                                                            <div style={{ color: '#f5222d' }}>Eliminated</div>
                                                                        </Text>
                                                                    </div>

                                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                        <Text style={{ color: theme.bracket.panels.textColor }}>{player.chips_left || 0}</Text>

                                                                        <Text
                                                                            style={{
                                                                                marginLeft: 15,
                                                                                width: 40,
                                                                                textAlign: 'right',
                                                                                color: theme.bracket.panels.textColor,
                                                                            }}
                                                                        >
                                                                            {player.matches_won || player.matches_lost
                                                                                ? `${player.matches_won}-${player.matches_lost}`
                                                                                : '0-0'}
                                                                        </Text>
                                                                    </div>
                                                                </div>
                                                            </List.Item>
                                                        </React.Fragment>
                                                    );
                                                }}
                                            />
                                            {/* {playersEliminated &&
                                    playersEliminated.map((player, index) => {
                                        return (
                                            <li key={index} style={styles.error}>
                                                <Text style={styles.error}>{player.name}</Text>
                                            </li>
                                        );
                                    })} */}
                                        </ul>
                                    ) : (
                                        <div
                                            style={{
                                                paddingLeft: 20,
                                                paddingRight: 20,
                                                color: theme.bracket.panels.textColor,
                                            }}
                                        >
                                            No players have been eliminated yet.
                                        </div>
                                    )}
                                </div>
                            </div>
                        </Col>
                    </React.Fragment>
                ) : tournament.status === 'COMPLETED' ? (
                    <div
                        style={{
                            width: '100%',

                            paddingLeft: 10,
                            paddingRight: 10,

                            borderBottom: '1px solid rgba(0, 0, 0, 0.07)',
                        }}
                    >
                        <ViewerResults tournament={tournament} tournamentID={tournament.id} formattedResults={results} />
                    </div>
                ) : (
                    <div
                        style={{
                            width: '100%',
                            height: '100%',
                            paddingTop: 10,
                            paddingLeft: 20,
                            paddingRight: 10,
                            paddingBottom: 10,
                            borderBottom: '1px solid rgba(0, 0, 0, 0.07)',
                        }}
                    >
                        <div
                            style={{
                                height: '100%',
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <ZeroState showImage message="Tournament not started." style={{ margin: 30 }} {...props}>
                                <Button type="primary" onClick={onStartTournament}>
                                    Start Tournament
                                </Button>
                            </ZeroState>
                        </div>
                    </div>
                )}
            </Row>
            {isPublic !== true && tournament.status !== 'COMPLETED' && (
                <Row>
                    <Col
                        xs={24}
                        style={{
                            margin: 0,
                            paddingLeft: 8,
                            paddingRight: 8,
                            borderTop: `1px solid ${theme.bracket.panels.borderColor}`,
                            backgroundColor: theme.bracket.panels.backgroundColor,
                        }}
                    >
                        <Button
                            onClick={() => {
                                reshufflePlayers(playerQueue);
                            }}
                            size="large"
                            type="ghost"
                            style={{ width: '100%' }}
                        >
                            Re-shuffle players
                        </Button>
                        {/* <Link to={`/tournament-builder/${tournament.slug}/players`}>
                                        <Button size="large" type="ghost" style={{ width: '100%', color: theme.bracket.panels.textColor }}>
                                            Manage Players
                                        </Button>
                                    </Link> */}
                    </Col>
                </Row>
            )}

            <PlayerModal
                {...props}
                tournament={tournament}
                tournamentID={tournament.id}
                tables={tables}
                matches={matches}
                playerId={selectedPlayer.id}
                modalVisible={playerModalVisible}
                onPlayerModalVisible={handlePlayerModalVisible}
            />
        </React.Fragment>
    );
}
