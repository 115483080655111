import React from 'react';
import { Typography } from 'antd';
import { getCountryName } from '../../utils';
import CircleFlag from '../../components/CircleFlag';
import numeral from 'numeral';

const { Text } = Typography;

export default function PlayerInfo(props) {
    const { challenger, matchData } = props;

    const challengerData =
        challenger === 'player'
            ? {
                  name: matchData.player_name,
                  country: matchData.player_country,
                  skill_level: matchData.player_skill_level || matchData.player_fargo,
                  tpa: calculateTPA('player'),
              }
            : {
                  name: matchData.opponent_name,
                  country: matchData.opponent_country,
                  skill_level: matchData.opponent_skill_level || matchData.opponent_fargo,
                  tpa: calculateTPA('opponent'),
              };

    // TPA formula = balls pocketed / balls pocketed + errors
    function calculateTPA(challenger) {
        let ballsPocketed;
        let errors;
        if (challenger === 'player') {
            ballsPocketed = matchData.player_balls_pocketed;
            errors = matchData.player_errors;
        } else {
            ballsPocketed = matchData.opponent_balls_pocketed;
            errors = matchData.opponent_errors;
        }

        const tpa = numeral(ballsPocketed / (ballsPocketed + errors)).format('0.000');
        // console.log({
        // 	ballsPocketed,
        // 	errors,
        // 	tpa
        // });
        return tpa;
    }

    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
            }}
        >
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                }}
            >
                {challengerData.country && <CircleFlag country={challengerData.country} left={0} />}

                <Text
                    style={{
                        fontSize: 25,
                        margin: 0,
                        fontWeight: 600,
                    }}
                >
                    {challengerData.name && challengerData.skill_level ? `${challengerData.name} (${challengerData.skill_level})` : challengerData.name}
                </Text>
            </div>
            <div>
                <Text style={{ fontSize: 25 }}>{challengerData.tpa} TPA</Text>
            </div>
        </div>
    );
}
