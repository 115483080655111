import React, { useState, useRef, useEffect } from 'react';
import { Table, Typography, Button, Icon, Modal, Input, Tooltip } from 'antd';
import CircleFlag from '../../components/CircleFlag';
import { getCountryName } from '../../utils';
import _ from 'lodash';
import Highlighter from 'react-highlight-words';
import geo from 'countrycitystatejson';
import { CheckCircleOutlined, WarningOutlined } from '@ant-design/icons';

const { Text } = Typography;
const { confirm } = Modal;

export default function PlayerTable(props) {
    // const { tournament } = props;
    const { tournament, players, onSelected, onEditSelected, selectedRowKeys, onDelete, size } = props;
    const [selectedKeys, setSelectedKeys] = useState(selectedRowKeys || []);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);

    const countries = geo.getCountries();
    const countryPhones = countries
        .sort((a, b) => {
            return a.phone.localeCompare(b.phone, undefined, {
                numeric: true,
                sensitivity: 'base',
            });
        })
        .filter((country) => {
            return country.shortName !== 'UM';
        })
        .map((country) => {
            return {
                phone: country.phone,
                emoji: country.emoji,
                shortName: country.shortName,
            };
        });

    useEffect(() => {
        setSelectedKeys(selectedRowKeys);
    }, [selectedRowKeys]);

    useEffect(() => {
        console.log('players changed');
    }, [players]);

    function onSelectChange(selectedRowKeys, selectedRows) {
        // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        setSelectedKeys(selectedRowKeys);
        onSelected(selectedRowKeys, selectedRows);
    }

    function handleSearch(selectedKeys, confirm, dataIndex) {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    }

    function handleReset(clearFilters) {
        clearFilters();
        setSearchText('');
    }

    function isPlayerInBracket(player) {
        const playerInBracket =
            (player && player.tournament_matches_challenger1 && player.tournament_matches_challenger1.length > 0) ||
            (player && player.tournament_matches_challenger2 && player.tournament_matches_challenger2.length > 0);

        return playerInBracket;
    }

    function getColumnSearchProps(dataIndex) {
        return {
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={searchInput}
                        placeholder={`Search ${dataIndex}`}
                        value={selectedKeys[0]}
                        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        style={{ width: 188, marginBottom: 8, display: 'block' }}
                    />
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon="search"
                        size="small"
                        style={{ width: 90, marginRight: 8 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                </div>
            ),
            filterIcon: (filtered) => <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
            onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
            onFilterDropdownVisibleChange: (visible) => {
                if (visible) {
                    setTimeout(() => searchInput.current.select());
                }
            },
            render: (text) =>
                searchedColumn === dataIndex ? (
                    <Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={[searchText]}
                        autoEscape
                        textToHighlight={text.toString()}
                    />
                ) : (
                    text
                ),
        };
    }

    const rowSelection = {
        selectedRowKeys: selectedKeys,
        onChange: onSelectChange,
        getCheckboxProps: (record) => ({
            name: record.name,
        }),
    };

    const columns = [
        {
            title: '#',
            dataIndex: 'index',
            key: 'index',
            sorter: (a, b) => {
                return a.index - b.index;
            },
        },
        {
            title: 'Name',
            dataIndex: 'name',
            defaultSortOrder: 'ascend',
            key: 'name',
            ...getColumnSearchProps('name'),
            sorter: (a, b) => {
                return a.name && a.name.localeCompare(b.name);
            },
            render: (text, record) => {
                const nameSplit = record.name && record.name.split(' ');
                const firstName = nameSplit && nameSplit[0];
                const lastName = nameSplit && nameSplit[1];
                const nickname = record.nickname;
                let displayName;

                if (firstName && lastName && nickname) {
                    displayName = `${firstName} "${record.nickname}" ${lastName}`;
                } else if (firstName && !lastName && nickname) {
                    displayName = `${firstName} "${record.nickname}"`;
                } else {
                    displayName = record.name;
                }

                return (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            {record.country && <CircleFlag country={record.country} left={0} />}
                            <span>{displayName}</span>
                        </div>
                        {tournament && tournament.tournament_type !== 'chip' && (
                            <div>
                                <Text>
                                    {isPlayerInBracket(record) ? (
                                        <Tooltip placement="right" title="Player found in bracket">
                                            <CheckCircleOutlined style={{ color: '#6cdb35', marginLeft: 5 }} />
                                        </Tooltip>
                                    ) : (
                                        <Tooltip placement="right" title="Player not found in bracket">
                                            <WarningOutlined style={{ color: '#ffac38', marginLeft: 5 }} />
                                        </Tooltip>
                                    )}
                                </Text>
                            </div>
                        )}
                    </div>
                );
            },
        },

        {
            title: 'Country',
            dataIndex: 'country',
            key: 'country',
            sorter: (a, b) => {
                return a.country && a.country.localeCompare(b.country);
            },
            render: (text, record) => {
                const country = record.country ? getCountryName(record.country) : text;
                return (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        {country ? _.startCase(country) : '-'}
                    </div>
                );
            },
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            sorter: (a, b) => {
                return a.email && a.email.localeCompare(b.email);
            },
            render: (text, record) => <React.Fragment>{text || '-'}</React.Fragment>,
        },
        {
            title: 'Country Phone',
            dataIndex: 'country_phone',
            key: 'country_phone',
            sorter: (a, b) => {
                return a.country_phone && a.country_phone.localeCompare(b.country_phone);
            },
            render: (text, record) => {
                const phonePrefix = countryPhones.filter((country, index) => {
                    if (record.country_phone === `${country.shortName}-${country.phone}`) {
                        return country;
                    }
                })[0];
                return (
                    <React.Fragment>
                        {record.country_phone && phonePrefix
                            ? `${phonePrefix.emoji && phonePrefix.emoji} +${phonePrefix.phone} ${phonePrefix.shortName}`
                            : record.country_phone || '-'}
                    </React.Fragment>
                );
            },
        },
        {
            title: 'Phone Number',
            dataIndex: 'phone_number',
            key: 'phone_number',
            sorter: (a, b) => {
                return a.phone_number && a.phone_number.localeCompare(b.phone_number);
            },
            render: (text, record) => <React.Fragment>{text || '-'}</React.Fragment>,
        },
        {
            title: 'Fargo ID',
            dataIndex: 'fargo_id',
            key: 'fargo_id',
            sorter: (a, b) => {
                return a.fargo_id.localeCompare(b.fargo_id, undefined, {
                    numeric: true,
                    sensitivity: 'base',
                });
            },

            render: (text, record) => <React.Fragment>{text || '-'}</React.Fragment>,
        },
        // {
        //     title: 'Special Group',
        //     dataIndex: 'special_group',
        //     sorter: (a, b) => {
        //         return a.special_group.localeCompare(b.special_group, undefined, {
        //             numeric: true,
        //             sensitivity: 'base',
        //         });
        //     },

        //     render: (text, record) => <React.Fragment>{text || '-'}</React.Fragment>,
        // },
        {
            title: 'Seed',
            dataIndex: 'seed',
            key: 'seed',
            sorter: (a, b) => a.seed - b.seed,
            render: (text, record) => <React.Fragment>{text || '-'}</React.Fragment>,
        },
        {
            title: 'Skill Level',
            dataIndex: 'skill_level',
            key: 'skill_level',
            sorter: (a, b) => a.skill_level - b.skill_level,
            render: (text, record) => <React.Fragment>{text || '-'}</React.Fragment>,
        },
        {
            title: 'Race to',
            dataIndex: 'race_to',
            key: 'race_to',
            sorter: (a, b) => a.race_to - b.race_to,
            render: (text, record) => <React.Fragment>{text || '-'}</React.Fragment>,
        },
        {
            title: 'Chips',
            dataIndex: 'chips_left',
            key: 'chips_left',
            sorter: (a, b) => a.chips_left - b.chips_left,
            render: (text, record) => <React.Fragment>{text || 0}</React.Fragment>,
        },
        {
            title: 'Status',
            dataIndex: 'Status',
            key: 'status',
            sorter: (a, b) => {
                return a.status && a.status.localeCompare(b.status);
            },
            render: (text, record) => {
                return record.status || '-';
            },
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <span style={{ whiteSpace: 'nowrap' }}>
                    <Tooltip title="Edit">
                        <Button
                            type="ghost"
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                onEditSelected(record.id);
                                // props.history.push(
                                // 	`/tournament-builder/${props.tournament.slug}/venues/${record.slug}/edit`
                                // );
                            }}
                        >
                            <Icon type="edit" />
                        </Button>
                    </Tooltip>
                    {/* <Divider type="vertical" /> */}
                    <Tooltip title="Delete">
                        <Button
                            type="danger"
                            ghost
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                showConfirm(record);
                            }}
                        >
                            <Icon type="delete" />
                        </Button>
                    </Tooltip>
                </span>
            ),
        },
    ];

    function onChange(pagination, filters, sorter) {
        console.log('params', pagination, filters, sorter);
    }

    function showConfirm(record) {
        confirm({
            title: `Are you sure you want to remove ${record.name} from this tournament?`,
            content: 'Note: This action will not remove real user accounts.',
            centered: true,
            transitionName: 'fade',
            maskTransitionName: 'none',
            // onOk() {
            // 	return new Promise((resolve, reject) => {
            // 		setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
            // 	}).catch(() => console.log('Oops errors!'));
            // },
            onOk() {
                onDelete(record);
            },
            onCancel() {},
        });
    }

    return (
        <Table
            className="player-table"
            rowSelection={rowSelection}
            onRow={(record, rowIndex) => {
                return {
                    onClick: (event) => {
                        onEditSelected(record.id);
                        // console.log(record, rowIndex);
                        // props.history.push({
                        // 	pathname: `/tournament-builder/${props.tournament.slug}/players/${record.name}`,
                        // 	// search: '?query=abc',
                        // 	state: {
                        // 		data: record,
                        // 		backPath: `/tournament-builder/${props.tournament.slug}/players`
                        // 	}
                        // });
                    },
                    onDoubleClick: (event) => {}, // double click row
                    onContextMenu: (event) => {}, // right button click row
                    onMouseEnter: (event) => {}, // mouse enter row
                    onMouseLeave: (event) => {}, // mouse leave row
                };
            }}
            columns={columns.filter((column) => {
                if (tournament.tournament_type !== 'chip' && column.dataIndex === 'chips_left') {
                    return null;
                } else {
                    return column;
                }
            })}
            dataSource={players}
            onChange={onChange}
            pagination={false}
            tableLayout="auto"
            // className="noselect"
            scroll={{ x: 400 }}
            // pagination={{ pageSize: 25 }}
            size={size ? size : 'default'}
            // bordered
        />
    );
}
