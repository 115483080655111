import React, { useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Dropdown, Button, Menu } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import CircularLoader from '../../components/CircularLoader';
import ZeroState from '../../components/ZeroState';
import { GET_TOURNAMENT_STAGES } from './tournaments/data/queries';
import { Query } from '@apollo/client/react/components';

const styles = {};

function TournamentStagePicker(props) {
    const { theme, tournament, history } = props;
    const stages = tournament && tournament.tournament_stages;

    return (
        <div
            style={{
                display: 'flex',
                margin: 10,
            }}
        >
            {stages &&
                stages.map((item) => {
                    return (
                        <Button
                            size="large"
                            type="ghost"
                            // type={tournament.slug === item.stage.slug ? 'danger' : 'secondary'}
                            key={item.stage.slug}
                            style={{ width: '100%' }}
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                history.push(`/tournaments/${item.stage.slug}/bracket`);
                            }}
                        >
                            {item.stage.name}
                        </Button>
                    );
                })}
        </div>
    );
}

export default withRouter(TournamentStagePicker);
