import React, { useState, useEffect } from 'react';
// import { BrowserRouter as Router, Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { Router, Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { message, Result, Button, Spin, Icon, Alert } from 'antd';
// import HelmetMetaData from './components/HelmetMetaData';
import { ApolloClient, InMemoryCache, ApolloProvider, persistCache, gql } from '@apollo/client';
import apolloClient from '../src/apollo/client';
import Loadable from './Loadable';
import Privacy from './screens/digitalpool/Privacy';
import Terms from './screens/digitalpool/Terms';
import Use from './screens/digitalpool/Use';
import Feeds from './screens/digitalpool/Feeds';
import Admin from './screens/digitalpool/Admin';
import Live from './screens/digitalpool/Live';
import LiveDetail from './screens/digitalpool/live/LiveDetail';
import PlayerDetail from './screens/digitalpool/players/PlayerDetail';
import Players from './screens/digitalpool/Players';
import Advertising from './components/advertising/Advertising';
import Events from './screens/digitalpool/Events';
import EventDetail from './screens/digitalpool/events/EventDetail';
import EventLiveScoring from './screens/digitalpool/events/EventLiveScoring3';
import CreateEvent from './screens/digitalpool/events/CreateEvent';
import EditEvent from './screens/digitalpool/events/CreateEvent';
import Matches from './screens/digitalpool/Matches';
import MatchDetail from './screens/digitalpool/matches/MatchDetail';
import MatchStreamOverlay from './screens/digitalpool/matches/MatchStreamOverlay';
import MatchStatsOverlay from './screens/digitalpool/matches/MatchStatsOverlay';
import MatchLargeScoreOverlay from './screens/digitalpool/matches/MatchLargeScoreOverlay';
import StreamTableOverlay from './screens/digitalpool/matches/StreamTableOverlay';
import MatchCreate from './screens/digitalpool/matches/MatchCreate';
import Scoreboard from './screens/digitalpool/scoreboard/Scoreboard';
import StreamOverlay from './screens/digitalpool/scoreboard/StreamOverlay';
import Leagues from './screens/digitalpool/Leagues';
import Tournaments from './screens/digitalpool/Tournaments2';
import Venues from './screens/digitalpool/Venues2';
import VenueDetail from './screens/digitalpool/venues/VenueDetail';
import VenueMap from './screens/digitalpool/venues/VenueMap';
import People from './screens/digitalpool/People';
import Organizations from './screens/digitalpool/Organizations';
import VerifyEmail from './screens/auth/VerifyEmail';
// import FantasyLines from './screens/digitalpool/FantasyLines';
import DPLeagueDetail from './screens/digitalpool/leagues/DPLeagueDetail';
import DPDivisionDetail from './screens/digitalpool/leagues/DPDivisionDetail';
import DPTournamentDetail from './screens/digitalpool/tournaments/DPTournamentDetail';
import DPTournamentRegistration from './screens/digitalpool/tournaments/DPTournamentRegistration';
import DPTourDetail from './screens/digitalpool/tours/DPTourDetail';
import DPTournamentStreamOverlay from './screens/digitalpool/tournaments/DPTournamentStreamOverlay';
import DPTournamentMatchScoring from './screens/digitalpool/tournaments/DPTournamentMatchScoring';
import DPTournamentTableScoringMobile from './screens/digitalpool/tournaments/DPTournamentTableScoringMobile';
import DPTournamentMatchDetailScoresheet from './screens/digitalpool/tournaments/DPTournamentMatchDetailScoresheet';
import DPTournamentTableScoring from './screens/digitalpool/tournaments/DPTournamentTableScoring';
import DPTournamentTableLiveScores from './screens/digitalpool/tournaments/DPTournamentTableLiveScores';
import DPTournamentTVScoreDisplay from './screens/digitalpool/tournaments/DPTournamentTVScoreDisplay';
import DPTournamentTVOverlaySmall from './screens/digitalpool/tournaments/DPTournamentTVOverlaySmall';
import DPTournamentTVOverlayLarge from './screens/digitalpool/tournaments/DPTournamentTVOverlayLarge';
import DPTournamentTVRackTracker from './screens/digitalpool/tournaments/DPTournamentTVRackTracker';
import DPTournamentMap from './screens/digitalpool/tournaments/DPTournamentMap';
import EventTableLiveScores from './screens/digitalpool/events/EventTableLiveScores2';
import EventTableTVScoreDisplay from './screens/digitalpool/events/EventTableTVScoreDisplay2';
import EventStreamOverlayLarge from './screens/digitalpool/events/EventStreamOverlayLarge';
import EventStreamOverlaySmall from './screens/digitalpool/events/EventStreamOverlaySmall';
import EventStreamOverlayRackTracker from './screens/digitalpool/events/EventStreamOverlayRackTracker';
import DPLMain from './screens/dpl/DPLMain';
import DPLMarketing from './screens/dpl/DPLMarketing';
import DPLDashboard from './screens/dpl/DPLDashboard';
import DPLDivisions from './screens/dpl/DPLDivisions';
import DPLDivisionDetail from './screens/dpl/divisions/DPLDivisionDetail';
import DPLSchedule from './screens/dpl/DPLSchedule';
import DPLStats from './screens/dpl/DPLStats';
import DPLResults from './screens/dpl/DPLResults';
import DPLMatches from './screens/dpl/DPLMatches';
import DPLTeams from './screens/dpl/DPLTeams';
import DPLPlayers from './screens/dpl/DPLPlayers';
import DPLVenues from './screens/dpl/DPLVenues';
import DPLRules from './screens/dpl/DPLRules';
import DPLSettings from './screens/dpl/DPLSettings';
import DPLFaq from './screens/dpl/DPLFaq';
import DPLAbout from './screens/dpl/DPLAbout';
import DPLApp from './screens/dpl/DPLApp';
import DPLSupport from './screens/dpl/DPLSupport';
import LMMain from './screens/league-manager/LMMain';
import LMMarketing from './screens/league-manager/LMMarketing';
import LMCreate from './screens/league-manager/LMCreate';
import LMDetail from './screens/league-manager/LMDetail';
import TBMain from './screens/tournament-builder/TBMain';
import TBMarketing from './screens/tournament-builder/TBMarketing';
import TBCreate from './screens/tournament-builder/TBCreate';
import TBBracketCreator from './screens/tournament-builder/TBBracketCreator';
import TBBracketCreatorTemplateDetail from './screens/tournament-builder/TBBracketCreatorTemplateDetail';
import TBBracketCreateTemplate from './screens/tournament-builder/TBBracketCreateTemplate';
import TBTournamentSetup from './screens/tournament-builder/TBTournamentSetup';
import TBCreateAdvancedTournament from './screens/tournament-builder/TBCreateAdvancedTournament';
import TBDetail from './screens/tournament-builder/TBDetail';
import PLMain from './screens/pool-live/PLMain';
import TRBMain from './screens/tour-builder/TRBMain';
import GEMain from './screens/ghost-event-manager/GEMain';
import GECreate from './screens/ghost-event-manager/GECreate';
import GEDetail from './screens/ghost-event-manager/GEDetail';
// import DPMain from './screens/digitalpool/DPMain';
// import Register from './screens/auth/Register';
// import Login from './screens/auth/Login';
// import Feeds from './screens/digitalpool/Feeds';
// import Live from './screens/digitalpool/Live';
// import DPMarketing from './screens/digitalpool/DPMarketing';
// import Stats from './screens/digitalpool/Stats';
// import Friends from './screens/digitalpool/Friends';
// import Notifications from './screens/digitalpool/Notifications';
// import Billing from './screens/digitalpool/Billing';
import ProgrammingLanguages from './screens/ProgrammingLanguages';
import AppLayoutMobile from './AppLayoutMobile';
import AppLayoutDesktop from './AppLayoutDesktop';
import EmptyLayout from './EmptyLayout';
import StreamOverlayLayout from './StreamOverlayLayout';
import * as routes from './config/routes';
import useTheme from './theme/useTheme';
import NotificationProvider, { NotificationContext } from './NotificationProvider';
// import DPTournamentDetail from './screens/digitalpool/tournaments/DPTournamentDetail';
import PLMarketing from './screens/pool-live/PLMarketing';
import PLStreamDetail from './screens/pool-live/PLStreamDetail';
import { createBrowserHistory } from 'history';
import { wrapHistory } from 'oaf-react-router';
import useBreakpoint from 'use-breakpoint';
import { Offline, Online } from 'react-detect-offline';
import { defaultBreakpoints } from './utils/defaultBreakpoints';
import PLCreateStream from './screens/pool-live/PLCreateStream';

const history = createBrowserHistory(); // or createHashHistory()
wrapHistory(history);

const AsyncDPMain = Loadable({
    loader: () => import('./screens/digitalpool/DPMain'),
});

const AsyncDashboard = Loadable({
    loader: () => import('./screens/digitalpool/Dashboard2'),
});

const AsyncChat = Loadable({
    loader: () => import('./screens/digitalpool/Chat'),
});

const AsyncStats = Loadable({
    loader: () => import('./screens/digitalpool/Stats'),
});

const AsyncDPMarketing = Loadable({
    loader: () => import('./screens/digitalpool/DPMarketing'),
});

const AsyncNotifications = Loadable({
    loader: () => import('./screens/digitalpool/Notifications'),
});

const AsyncFriends = Loadable({
    loader: () => import('./screens/digitalpool/Friends'),
});

const AsyncBilling = Loadable({
    loader: () => import('./screens/digitalpool/Billing'),
});

const AsyncProfile = Loadable({
    loader: () => import('./screens/auth/Profile'),
});

const AsyncProfilePro = Loadable({
    loader: () => import('./screens/auth/ProfilePro'),
});

const AsyncRegister = Loadable({
    loader: () => import('./screens/auth/Register'),
});

const AsyncForgotPassword = Loadable({
    loader: () => import('./screens/auth/ForgotPassword'),
});

const AsyncChangePassword = Loadable({
    loader: () => import('./screens/auth/ChangePassword'),
});

const AsyncLogin = Loadable({
    loader: () => import('./screens/auth/Login'),
});

const AsyncAccountSettings = Loadable({
    loader: () => import('./screens/auth/AccountSettings'),
});

const AsyncFantasyLines = Loadable({
    loader: () => import('./screens/digitalpool/FantasyLines'),
});

const MatchDayStatsOverlay = Loadable({
    loader: () => import('./screens/digitalpool/matches/MatchDayStatsOverlay'),
});

function AppLayout(props) {
    const { width, mobileLayout, defaultTheme } = props;
    const [theme, toggleTheme] = useTheme(defaultTheme);
    const { component: Component, ...rest } = props;
    const navToggle = localStorage.getItem('digitalpool__navToggle');
    const [navType, setNavType] = useState('mobile');

    const { breakpoint, maxWidth, minWidth } = useBreakpoint(defaultBreakpoints);
    console.log(`The current breakpoint is ${breakpoint}!`);

    useEffect(() => {
        const type = breakpoint === 'mobile' ? 'mobile' : 'desktop';
        if (!navType) {
            localStorage.setItem('digitalpool__navToggle', type);
            setNavType(type);
        }
    }, [width, navType, breakpoint]);

    function toggleNavType(type) {
        localStorage.setItem('digitalpool__navToggle', type);
        setNavType(type);
    }

    return (
        <React.Fragment>
            <Offline>
                <Alert message="You're offline right now. Check your connection." type="error" banner closable style={{ position: 'relative', zIndex: 100 }} />
            </Offline>

            <NotificationProvider authState={props.authState} history={history}>
                <NotificationContext.Consumer>
                    {(notificationProps) => {
                        return (
                            <React.Fragment>
                                {navType === 'desktop' ? (
                                    <AppLayoutDesktop
                                        authState={props.authState}
                                        exact={props.exact}
                                        path={props.path}
                                        component={Component}
                                        title={props.title}
                                        breadcrumb={false}
                                        toggleNavType={toggleNavType}
                                        navType={navType}
                                        breakpoint={breakpoint}
                                        theme={theme}
                                        toggleTheme={toggleTheme}
                                        {...notificationProps}
                                        {...rest}
                                    />
                                ) : (
                                    <AppLayoutMobile
                                        authState={props.authState}
                                        exact={props.exact}
                                        path={props.path}
                                        component={Component}
                                        title={props.title}
                                        breadcrumb={props.breadcrumb}
                                        toggleNavType={toggleNavType}
                                        navType={navType}
                                        breakpoint={breakpoint}
                                        theme={theme}
                                        toggleTheme={toggleTheme}
                                        {...notificationProps}
                                        {...rest}
                                    />
                                )}
                            </React.Fragment>
                        );
                    }}
                </NotificationContext.Consumer>
            </NotificationProvider>
        </React.Fragment>
    );
}

export default function AppRoutes(props) {
    const { authState } = props;

    const NotFound = (props) => {
        return (
            <Result
                status="404"
                title="404"
                subTitle="Sorry, that page could not be found."
                extra={
                    <Button type="primary" size="large" onClick={() => history.push('/')}>
                        Back Home
                    </Button>
                }
                style={{ color: '#000' }}
            />
        );
    };

    const fireGATracking = () => {
        // ReactGA.pageview(window.location.pathname + window.location.search);
    };

    const renderLoggedIn = (
        <Switch>
            <AppLayout exact path={routes.DIGITALPOOL.ADVERTISING} component={Advertising} title="Advertising" {...props} />
            <AppLayout exact path={routes.DIGITALPOOL.VERIFY_EMAIL} component={VerifyEmail} title="Verify Email" {...props} />
            <AppLayout
                exact
                path={routes.DIGITALPOOL.INDEX}
                component={AsyncDashboard}
                title="Dashboard"
                // breadcrumb
                {...props}
            />

            <AppLayout
                exact
                path={routes.DIGITALPOOL.LIVE}
                component={Live}
                title="Live"
                // breadcrumb={false}
                {...props}
            />

            <AppLayout
                // exact
                path={routes.DIGITALPOOL.LIVE_DETAIL_TWITCH}
                component={LiveDetail}
                title="Live Detail"
                // breadcrumb={false}
                {...props}
            />

            <AppLayout
                // exact
                path={routes.DIGITALPOOL.LIVE_DETAIL_YOUTUBE}
                component={LiveDetail}
                title="Live Detail"
                // breadcrumb={false}
                {...props}
            />

            <AppLayout
                exact
                path={routes.DIGITALPOOL.FEEDS}
                component={Feeds}
                title="Feeds"
                // breadcrumb
                {...props}
            />

            <AppLayout
                path={routes.DIGITALPOOL.ADMIN}
                component={Admin}
                title="Admin"
                // breadcrumb
                {...props}
            />

            <EmptyLayout
                exact
                path={routes.DIGITALPOOL.EVENT_TABLE_SCORING}
                component={EventLiveScoring}
                title="Event Table Scoring"
                defaultTheme="dark"
                // showFooter={false}
                // breadcrumb={false}
                {...props}
            />

            <EmptyLayout
                exact
                path={routes.DIGITALPOOL.EVENT_TABLE_LIVE_SCORES}
                component={EventTableLiveScores}
                title="Event Table Scoring"
                defaultTheme="dark"
                // showFooter={false}
                // breadcrumb={false}
                {...props}
            />

            <EmptyLayout
                exact
                path={routes.DIGITALPOOL.EVENT_TABLE_TV_SCORE_DISPLAY}
                component={EventTableTVScoreDisplay}
                title="Tournament TV Score Display"
                defaultTheme="dark"
                // showFooter={false}
                // breadcrumb={false}
                {...props}
            />

            <StreamOverlayLayout
                exact
                path={routes.DIGITALPOOL.EVENT_TABLE_STREAM_OVERLAY_RACK_TRACKER}
                component={EventStreamOverlayRackTracker}
                title="Event Stream Overlay Rack Tracker"
                defaultTheme="dark"
                // showFooter={false}
                // breadcrumb={false}
                {...props}
            />

            <StreamOverlayLayout
                exact
                path={routes.DIGITALPOOL.EVENT_TABLE_STREAM_OVERLAY_SMALL}
                component={EventStreamOverlaySmall}
                title="Event Stream Overlay Small"
                defaultTheme="dark"
                // showFooter={false}
                // breadcrumb={false}
                {...props}
            />

            <StreamOverlayLayout
                exact
                path={routes.DIGITALPOOL.EVENT_TABLE_STREAM_OVERLAY_LARGE}
                component={EventStreamOverlayLarge}
                title="Event Stream Overlay Large"
                defaultTheme="dark"
                // showFooter={false}
                // breadcrumb={false}
                {...props}
            />

            <AppLayout
                exact
                path={routes.DIGITALPOOL.EVENTS}
                component={Events}
                title="Events"
                // breadcrumb
                {...props}
            />

            <AppLayout exact path={routes.DIGITALPOOL.ALL_EVENTS} component={Events} title="Events" {...props} />
            <AppLayout exact path={routes.DIGITALPOOL.MY_EVENTS} component={Events} title="Events" {...props} />

            <AppLayout
                exact
                path={routes.DIGITALPOOL.EVENT_CREATE}
                component={CreateEvent}
                title="Create Event"
                // breadcrumb
                {...props}
            />
            <AppLayout
                exact
                path={routes.DIGITALPOOL.EVENT_EDIT}
                component={EditEvent}
                title="Edit Event"
                // breadcrumb
                {...props}
            />

            <AppLayout
                exact
                path={routes.DIGITALPOOL.EVENT_DETAIL_SCORES}
                component={EventDetail}
                title="Event Detail"
                // breadcrumb
                {...props}
            />
            <AppLayout
                path={routes.DIGITALPOOL.EVENT_DETAIL}
                component={EventDetail}
                title="Event Detail"
                // breadcrumb
                {...props}
            />

            {/* <EmptyLayout
        exact
        path={routes.DIGITALPOOL.EVENT_SCORING}
        component={EventScoring}
        title="Event Scoring"
        // breadcrumb
        {...props}
    /> */}

            <AppLayout
                exact
                path={routes.DIGITALPOOL.MATCHES}
                component={Matches}
                title="Matches"
                // breadcrumb
                {...props}
            />
            <AppLayout
                exact
                path={routes.DIGITALPOOL.MATCHES_CREATE}
                component={MatchCreate}
                title="Create Match"
                // breadcrumb
                {...props}
            />

            <AppLayout
                exact
                path={routes.DIGITALPOOL.MATCHES_EDIT}
                component={MatchCreate}
                title="Edit Match"
                // breadcrumb
                {...props}
            />

            <StreamOverlayLayout
                exact
                path={routes.DIGITALPOOL.MATCH_DETAIL_STREAM_OVERLAY}
                component={MatchStreamOverlay}
                title="Match Stream Overlay"
                // breadcrumb
                {...props}
            />

            <StreamOverlayLayout
                exact
                path={routes.DIGITALPOOL.MATCH_DETAIL_STATS_OVERLAY}
                component={MatchStatsOverlay}
                title="Match Stats Overlay"
                // breadcrumb
                {...props}
            />

            <StreamOverlayLayout
                exact
                path={routes.DIGITALPOOL.MATCH_DETAIL_DAY_STATS_OVERLAY}
                component={MatchDayStatsOverlay}
                title="Match Day Stats Overlay"
                // breadcrumb
                {...props}
            />

            <StreamOverlayLayout
                exact
                path={routes.DIGITALPOOL.MATCH_DETAIL_LARGE_SCORE_OVERLAY}
                component={MatchLargeScoreOverlay}
                title="Match Large Score Overlay"
                // breadcrumb
                {...props}
            />

            <StreamOverlayLayout
                exact
                path={routes.DIGITALPOOL.MATCH_DETAIL_STREAM_TABLE_OVERLAY}
                component={StreamTableOverlay}
                title="Stream Table Overlay"
                // breadcrumb
                {...props}
            />

            <AppLayout
                path={routes.DIGITALPOOL.MATCH_DETAIL}
                component={MatchDetail}
                title="Match Detail"
                // breadcrumb
                {...props}
            />

            <AppLayout
                exact
                path={routes.DIGITALPOOL.SCOREBOARD}
                component={Scoreboard}
                title="Scoreboard"
                // breadcrumb
                {...props}
            />
            <StreamOverlayLayout
                exact
                path={routes.DIGITALPOOL.STREAM_OVERLAY}
                component={StreamOverlay}
                title="Stream Overlay"
                // breadcrumb
                {...props}
            />
            <AppLayout path={routes.DIGITALPOOL.FANTASY_LINES} component={AsyncFantasyLines} title="Fantasy Lines" {...props} />
            <AppLayout
                exact
                path={routes.DIGITALPOOL.PEOPLE}
                component={People}
                title="People"
                // breadcrumb
                {...props}
            />

            {/* <AppLayout
        path={routes.DIGITALPOOL.PLAYER_DETAIL}
        component={PlayerDetail}
        title="Player Detail"
        {...props}
    /> */}

            <AppLayout
                path={routes.DIGITALPOOL.PLAYERS}
                component={Players}
                title="Players"
                // breadcrumb
                {...props}
            />

            <AppLayout
                exact
                path={routes.DIGITALPOOL.LEAGUES}
                component={Leagues}
                title="Leagues"
                // breadcrumb
                {...props}
            />
            <AppLayout exact path={routes.DIGITALPOOL.ALL_LEAGUES} component={Leagues} title="Leagues" {...props} />
            <AppLayout exact path={routes.DIGITALPOOL.MY_LEAGUES} component={Leagues} title="Leagues" {...props} />

            <AppLayout
                path={routes.DIGITALPOOL.LEAGUE_DETAIL}
                component={DPLeagueDetail}
                title="League Detail"
                // breadcrumb={false}
                {...props}
            />

            <AppLayout
                exact
                path={routes.DIGITALPOOL.TOURNAMENTS}
                component={Tournaments}
                title="Tournaments"
                // breadcrumb
                {...props}
            />

            <AppLayout
                exact
                path={routes.DIGITALPOOL.TOURNAMENT_MATCH_SCORING_SHORT_URL}
                component={DPTournamentMatchScoring}
                title="Tournament Match Scoring"
                defaultTheme="dark"
                // showFooter={false}
                // breadcrumb={false}
                {...props}
            />

            <AppLayout
                exact
                path={routes.DIGITALPOOL.TOURNAMENT_MATCH_SCORING}
                component={DPTournamentMatchScoring}
                title="Tournament Match Scoring"
                defaultTheme="dark"
                // showFooter={false}
                // breadcrumb={false}
                {...props}
            />

            <EmptyLayout
                exact
                path={routes.DIGITALPOOL.TOURNAMENT_TABLE_SCORING_MOBILE}
                component={DPTournamentTableScoringMobile}
                title="Tournament Table Scoring"
                defaultTheme="dark"
                // showFooter={false}
                // breadcrumb={false}
                {...props}
            />

            <EmptyLayout
                exact
                path={routes.DIGITALPOOL.TOURNAMENT_MATCH_DETAIL_SCORESHEET}
                component={DPTournamentMatchDetailScoresheet}
                title="Tournament Match Detail Scorecard"
                defaultTheme="light"
                // showFooter={false}
                // breadcrumb={false}
                {...props}
            />

            <StreamOverlayLayout
                // exact
                path={routes.DIGITALPOOL.TOURNAMENT_STREAM_OVERLAY}
                component={DPTournamentStreamOverlay}
                title="Tournament Stream Overlay"
                defaultTheme="dark"
                // breadcrumb={false}
                {...props}
            />

            <AppLayout
                exact
                path={routes.DIGITALPOOL.TOURNAMENT_MAP}
                component={DPTournamentMap}
                title="Tournament Map"
                // breadcrumb={false}
                {...props}
            />

            <EmptyLayout
                exact
                path={routes.DIGITALPOOL.TOURNAMENT_TABLE_SCOREBOARD}
                component={DPTournamentTableScoring}
                title="Tournament Table Scoring"
                defaultTheme="dark"
                // showFooter={false}
                // breadcrumb={false}
                {...props}
            />

            <EmptyLayout
                exact
                path={routes.DIGITALPOOL.TOURNAMENT_TABLE_LIVE_SCORES}
                component={DPTournamentTableLiveScores}
                title="Tournament Table Scoring"
                defaultTheme="dark"
                // showFooter={false}
                // breadcrumb={false}
                {...props}
            />

            <EmptyLayout
                exact
                path={routes.DIGITALPOOL.TOURNAMENT_TABLE_TV_SCORE_DISPLAY}
                component={DPTournamentTVScoreDisplay}
                title="Tournament TV Score Display"
                defaultTheme="dark"
                // showFooter={false}
                // breadcrumb={false}
                {...props}
            />

            <EmptyLayout
                exact
                path={routes.DIGITALPOOL.TOURNAMENT_TABLE_TV_RACK_TRACKER}
                component={DPTournamentTVRackTracker}
                title="Tournament TV Score Display"
                defaultTheme="dark"
                // showFooter={false}
                // breadcrumb={false}
                {...props}
            />

            <EmptyLayout
                exact
                path={routes.DIGITALPOOL.TOURNAMENT_TABLE_TV_SCORE_OVERLAY_SMALL}
                component={DPTournamentTVOverlaySmall}
                title="Tournament TV Score Overlay Small"
                defaultTheme="dark"
                // showFooter={false}
                // breadcrumb={false}
                {...props}
            />

            <EmptyLayout
                exact
                path={routes.DIGITALPOOL.TOURNAMENT_TABLE_TV_SCORE_OVERLAY_LARGE}
                component={DPTournamentTVOverlayLarge}
                title="Tournament TV Score Overlay Large"
                defaultTheme="dark"
                // showFooter={false}
                // breadcrumb={false}
                {...props}
            />

            <AppLayout
                exact
                path={routes.DIGITALPOOL.TOURNAMENT_REGISTRATION}
                component={DPTournamentRegistration}
                title="Tournament Registration"
                // breadcrumb={false}
                {...props}
            />

            <AppLayout
                exact
                path={routes.DIGITALPOOL.TOURNAMENT_DETAIL_BRACKET_STAGE}
                component={DPTournamentDetail}
                title="Tournaments"
                // breadcrumb={false}
                {...props}
            />

            <AppLayout
                // exact
                path={routes.DIGITALPOOL.TOURNAMENT_DETAIL}
                component={DPTournamentDetail}
                title="Tournaments"
                // breadcrumb={false}
                {...props}
            />

            <AppLayout
                // exact
                path={routes.DIGITALPOOL.TOUR_DETAIL}
                component={DPTourDetail}
                title="Tour"
                // breadcrumb={false}
                {...props}
            />

            <AppLayout
                exact
                path={routes.DIGITALPOOL.VENUE_MAP}
                component={VenueMap}
                title="Venue Map"
                // breadcrumb
                {...props}
            />

            <AppLayout
                exact
                path={routes.DIGITALPOOL.VENUE_DETAIL}
                component={VenueDetail}
                title="Venue Detail"
                // breadcrumb
                {...props}
            />

            <AppLayout
                exact
                path={routes.DIGITALPOOL.VENUES}
                component={Venues}
                title="Venues"
                // breadcrumb
                {...props}
            />

            <AppLayout
                exact
                path={routes.DIGITALPOOL.ORGANIZATIONS}
                component={Organizations}
                title="Organizations"
                // breadcrumb
                {...props}
            />
            <AppLayout
                exact
                path={routes.DIGITALPOOL.DASHBOARD}
                component={AsyncDashboard}
                title="Dashboard"
                // breadcrumb
                {...props}
            />
            <AppLayout
                exact
                path={routes.DIGITALPOOL.CHAT}
                component={AsyncChat}
                title="Chat"
                // breadcrumb
                {...props}
            />
            <AppLayout
                exact
                path={routes.DIGITALPOOL.STATS}
                component={AsyncStats}
                title="Stats"
                // breadcrumb
                {...props}
            />
            <AppLayout
                exact
                path={routes.DIGITALPOOL.NOTIFICATIONS}
                component={AsyncNotifications}
                title="Notifications"
                // breadcrumb
                {...props}
            />
            <AppLayout
                exact
                path={routes.DIGITALPOOL.FRIENDS}
                component={AsyncFriends}
                title="Friends"
                // breadcrumb
                {...props}
            />
            <AppLayout
                exact
                path={routes.DIGITALPOOL.BILLING}
                component={AsyncBilling}
                title="Billing"
                // breadcrumb
                {...props}
            />

            <AppLayout exact path={routes.DIGITALPOOL.REGISTER} sidebar={false} component={AsyncRegister} {...props} />

            <AppLayout exact path={routes.DIGITALPOOL.FORGOT_PASSWORD} component={AsyncForgotPassword} {...props} />
            <AppLayout exact path={routes.DIGITALPOOL.CHANGE_PASSWORD} component={AsyncChangePassword} {...props} />
            <AppLayout exact path={routes.DIGITALPOOL.LOGIN} sidebar={false} component={AsyncLogin} {...props} />
            <AppLayout exact path={routes.DIGITALPOOL.PROFILE} component={AsyncProfile} {...props} />
            <AppLayout exact path={routes.DIGITALPOOL.USER_DETAILS} component={AsyncProfile} {...props} />
            <AppLayout exact path={routes.DIGITALPOOL.ACCOUNT_SETTINGS} component={AsyncAccountSettings} {...props} />
            <AppLayout exact path={routes.DIGITALPOOL.ACCOUNT_PROFILE_SETTINGS} component={AsyncAccountSettings} {...props} />
            <AppLayout exact path={routes.DIGITALPOOL.ACCOUNT_NOTIFICATION_SETTINGS} component={AsyncAccountSettings} {...props} />

            <AppLayout
                exact
                path={routes.DPL.INDEX}
                // breadcrumb={false}
                component={DPLMain}
                title="Digital Pool League"
                {...props}
            />
            <AppLayout exact path={routes.DPL.DASHBOARD} component={DPLDashboard} title="Dashboard" {...props} />
            <AppLayout exact path={routes.DPL.DIVISIONS} component={DPLDivisions} title="Divisions" {...props} />
            <AppLayout path={routes.DPL.DIVISION_DETAIL} component={DPLDivisionDetail} title="Division Detail" {...props} />
            <AppLayout exact path={routes.DPL.SCHEDULE} component={DPLSchedule} title="Schedule" {...props} />
            <AppLayout exact path={routes.DPL.STATS} component={DPLStats} title="Stats" {...props} />
            <AppLayout exact path={routes.DPL.RESULTS} component={DPLResults} title="Results" {...props} />
            <AppLayout exact path={routes.DPL.MATCHES} component={DPLMatches} title="Matches" {...props} />
            <AppLayout exact path={routes.DPL.TEAMS} component={DPLTeams} title="Teams" {...props} />
            <AppLayout exact path={routes.DPL.PLAYERS} component={DPLPlayers} title="Players" {...props} />
            <AppLayout exact path={routes.DPL.VENUES} component={DPLVenues} title="Venues" {...props} />
            <AppLayout exact path={routes.DPL.RULES} component={DPLRules} title="Rules" {...props} />
            <AppLayout exact path={routes.DPL.SETTINGS} component={DPLSettings} title="Settings" {...props} />
            <AppLayout exact path={routes.DPL.ABOUT} component={DPLAbout} title="About" {...props} />
            <AppLayout exact path={routes.DPL.APP} component={DPLApp} title="App" {...props} />
            <AppLayout exact path={routes.DPL.SUPPORT} component={DPLSupport} title="Support" {...props} />
            <AppLayout exact path={routes.DPL.FAQ} component={DPLFaq} title="Frequently Asked Questions" {...props} />
            <AppLayout exact path={routes.LEAGUE_MANAGER.INDEX} component={LMMain} title="League Manager" {...props} />
            <AppLayout exact path={routes.LEAGUE_MANAGER.EDIT} component={LMCreate} title="Edit League" {...props} />
            <AppLayout exact path={routes.LEAGUE_MANAGER.CREATE} component={LMCreate} title="Create New League" {...props} />
            <AppLayout path={routes.LEAGUE_MANAGER.DETAIL} component={LMDetail} title="Manage League" {...props} />

            <AppLayout exact path={routes.TOURNAMENT_BUILDER.INDEX} component={TBMain} title="Tournament Builder" {...props} />
            <AppLayout exact path={routes.TOURNAMENT_BUILDER.CREATE} component={TBTournamentSetup} title="Create New Tournament" {...props} />
            <AppLayout
                exact
                path={routes.TOURNAMENT_BUILDER.CREATE_TOURNAMENT_BRACKET_TEMPLATE}
                component={TBBracketCreateTemplate}
                title="Create New Bracket Template"
                {...props}
            />
            <AppLayout exact path={routes.TOURNAMENT_BUILDER.BRACKET_TEMPLATE_CREATOR} component={TBBracketCreator} title="Create New Tournament" {...props} />
            <AppLayout
                exact
                path={routes.TOURNAMENT_BUILDER.BRACKET_TEMPLATE_CREATOR_DETAIL}
                component={TBBracketCreatorTemplateDetail}
                title="Tournament Bracket Template"
                {...props}
            />

            <AppLayout
                exact
                path={routes.TOURNAMENT_BUILDER.CREATE_SIMPLE_TOURNAMENT}
                component={TBTournamentSetup}
                title="Create Simple Tournament"
                {...props}
            />
            <AppLayout
                exact
                path={routes.TOURNAMENT_BUILDER.CREATE_ADVANCED_TOURNAMENT}
                component={TBCreateAdvancedTournament}
                title="Create Advanced Tournament"
                {...props}
            />
            <AppLayout exact path={routes.TOURNAMENT_BUILDER.EDIT} component={TBTournamentSetup} title="Edit Tournament" {...props} />
            <AppLayout path={routes.TOURNAMENT_BUILDER.DETAIL} component={TBDetail} title="Manage Tournament" {...props} />

            <AppLayout exact path={routes.TOUR_BUILDER.INDEX} component={TRBMain} title="Tour Builder" {...props} />
            {/* <AppLayout
        exact
        path={routes.TOUR_BUILDER.CREATE}
        component={TRBCreate}
        title="Create New Tour"
        {...props}
    /> */}

            <AppLayout exact path={routes.POOL_LIVE.INDEX} component={PLMain} title="Pool.Live" {...props} />
            <AppLayout exact path={routes.POOL_LIVE.CREATE} component={PLCreateStream} title="Create Live Stream" {...props} />
            <AppLayout exact path={routes.POOL_LIVE.STREAM_DETAIL} component={PLStreamDetail} title="Pool.Live" {...props} />

            <AppLayout exact path={routes.GHOST_EVENTS_MANAGER.INDEX} component={GEMain} title="Ghost Games" {...props} />
            <AppLayout exact path={routes.GHOST_EVENTS_MANAGER.CREATE} component={GECreate} title="Create Ghost Game" {...props} />
            <AppLayout path={routes.GHOST_EVENTS_MANAGER.DETAIL} component={GEDetail} title="Manage Ghost Game" {...props} />

            <AppLayout exact path={routes.PRIVACY} component={Privacy} title="Privacy" {...props} />
            <AppLayout exact path={routes.TERMS} component={Terms} title="Terms" {...props} />
            <AppLayout exact path={routes.USE} component={Use} title="Use" {...props} />

            <Route
                exact
                path="/programming-languages"
                render={() => (
                    <AppLayout {...props}>
                        <ProgrammingLanguages title="Programming Languages" {...props} />
                    </AppLayout>
                )}
            />
            {/* <AppLayout exact path={routes.DIGITALPOOL.LOGIN} sidebar={false} component={AsyncLogin} {...props} /> */}
            {/* {authState.status !== 'in' && <Redirect to={routes.DIGITALPOOL.INDEX} />} */}
            <Redirect exact strict from="/" to="/dashboard" />
            <EmptyLayout exact path={routes.NOT_FOUND} component={NotFound} title="404 Not Found" {...props} />
            <AppLayout path={routes.DIGITALPOOL.PROFILE_PRO} component={AsyncProfilePro} {...props} />
            <Redirect to={routes.NOT_FOUND} />
        </Switch>
    );

    const renderLoggedOut = (
        <Switch>
            <AppLayout exact path={routes.DIGITALPOOL.INDEX} component={AsyncDPMarketing} title="Home" defaultTheme="light" {...props} />
            <AppLayout exact path={routes.DIGITALPOOL.FEEDS} component={AsyncDPMarketing} title="Feeds" {...props} />
            <AppLayout exact path={routes.DIGITALPOOL.LIVE} component={AsyncDPMarketing} title="Live" {...props} />

            <AppLayout
                exact
                path={routes.DIGITALPOOL.DASHBOARD}
                component={AsyncDashboard}
                title="Dashboard"
                // breadcrumb
                {...props}
            />

            <EmptyLayout
                exact
                path={routes.DIGITALPOOL.EVENT_TABLE_SCORING}
                component={EventLiveScoring}
                title="Event Table Scoring"
                defaultTheme="dark"
                // showFooter={false}
                // breadcrumb={false}
                {...props}
            />

            <EmptyLayout
                exact
                path={routes.DIGITALPOOL.EVENT_TABLE_LIVE_SCORES}
                component={EventTableLiveScores}
                title="Event Table Scoring"
                defaultTheme="dark"
                // showFooter={false}
                // breadcrumb={false}
                {...props}
            />

            <EmptyLayout
                exact
                path={routes.DIGITALPOOL.EVENT_TABLE_TV_SCORE_DISPLAY}
                component={EventTableTVScoreDisplay}
                title="Tournament TV Score Display"
                defaultTheme="dark"
                // showFooter={false}
                // breadcrumb={false}
                {...props}
            />

            <StreamOverlayLayout
                exact
                path={routes.DIGITALPOOL.EVENT_TABLE_STREAM_OVERLAY_RACK_TRACKER}
                component={EventStreamOverlayRackTracker}
                title="Event Stream Overlay Rack Tracker"
                defaultTheme="dark"
                // showFooter={false}
                // breadcrumb={false}
                {...props}
            />

            <StreamOverlayLayout
                exact
                path={routes.DIGITALPOOL.EVENT_TABLE_STREAM_OVERLAY_SMALL}
                component={EventStreamOverlaySmall}
                title="Event Stream Overlay Small"
                defaultTheme="dark"
                // showFooter={false}
                // breadcrumb={false}
                {...props}
            />

            <StreamOverlayLayout
                exact
                path={routes.DIGITALPOOL.EVENT_TABLE_STREAM_OVERLAY_LARGE}
                component={EventStreamOverlayLarge}
                title="Event Stream Overlay Large"
                defaultTheme="dark"
                // showFooter={false}
                // breadcrumb={false}
                {...props}
            />

            <AppLayout
                exact
                path={routes.DIGITALPOOL.EVENTS}
                component={AsyncDPMarketing}
                title="Events"
                // breadcrumb
                {...props}
            />

            <AppLayout exact path={routes.DIGITALPOOL.ALL_EVENTS} component={AsyncDPMarketing} title="Events" {...props} />
            <AppLayout exact path={routes.DIGITALPOOL.MY_EVENTS} component={AsyncDPMarketing} title="Events" {...props} />

            <AppLayout
                exact
                path={routes.DIGITALPOOL.EVENT_CREATE}
                component={CreateEvent}
                title="Create Event"
                // breadcrumb
                {...props}
            />
            <AppLayout
                exact
                path={routes.DIGITALPOOL.EVENT_EDIT}
                component={EditEvent}
                title="Edit Event"
                // breadcrumb
                {...props}
            />
            <AppLayout
                exact
                path={routes.DIGITALPOOL.EVENT_DETAIL_SCORES}
                component={EventDetail}
                title="Event Detail"
                // breadcrumb
                {...props}
            />
            <AppLayout
                path={routes.DIGITALPOOL.EVENT_DETAIL}
                component={EventDetail}
                title="Event Detail"
                // breadcrumb
                {...props}
            />
            {/* <EmptyLayout
                    exact
                    path={routes.DIGITALPOOL.EVENT_SCORING}
                    component={EventScoring}
                    title="Event Scoring"
                    {...props}
                /> */}

            <AppLayout exact path={routes.DIGITALPOOL.LEAGUES} component={AsyncDPMarketing} title="Leagues" {...props} />

            <AppLayout
                path={routes.DIGITALPOOL.LEAGUE_DETAIL}
                component={AsyncDPMarketing}
                title="League Detail"
                // breadcrumb={false}
                {...props}
            />

            <StreamOverlayLayout
                // exact
                path={routes.DIGITALPOOL.TOURNAMENT_STREAM_OVERLAY}
                component={DPTournamentStreamOverlay}
                title="Tournament Stream Overlay"
                defaultTheme="dark"
                // breadcrumb={false}
                {...props}
            />

            <AppLayout
                exact
                path={routes.DIGITALPOOL.TOURNAMENT_MAP}
                component={DPTournamentMap}
                title="Tournament Map"
                // breadcrumb={false}
                {...props}
            />

            <EmptyLayout
                exactf
                path={routes.DIGITALPOOL.TOURNAMENT_TABLE_SCOREBOARD}
                component={DPTournamentTableScoring}
                title="Tournament Table Scoring"
                defaultTheme="dark"
                // showFooter={false}
                // breadcrumb={false}
                {...props}
            />

            <EmptyLayout
                exact
                path={routes.DIGITALPOOL.TOURNAMENT_TABLE_LIVE_SCORES}
                component={DPTournamentTableLiveScores}
                title="Tournament Table Scoring"
                defaultTheme="dark"
                // showFooter={false}
                // breadcrumb={false}
                {...props}
            />

            <EmptyLayout
                exact
                path={routes.DIGITALPOOL.TOURNAMENT_TABLE_TV_SCORE_DISPLAY}
                component={DPTournamentTVScoreDisplay}
                title="Tournament TV Score Display"
                defaultTheme="dark"
                // showFooter={false}
                // breadcrumb={false}
                {...props}
            />

            <EmptyLayout
                exact
                path={routes.DIGITALPOOL.TOURNAMENT_TABLE_TV_RACK_TRACKER}
                component={DPTournamentTVRackTracker}
                title="Tournament TV Score Display"
                defaultTheme="dark"
                // showFooter={false}
                // breadcrumb={false}
                {...props}
            />

            <EmptyLayout
                exact
                path={routes.DIGITALPOOL.TOURNAMENT_TABLE_TV_SCORE_OVERLAY_SMALL}
                component={DPTournamentTVOverlaySmall}
                title="Tournament TV Score Overlay Small"
                defaultTheme="dark"
                // showFooter={false}
                // breadcrumb={false}
                {...props}
            />

            <EmptyLayout
                exact
                path={routes.DIGITALPOOL.TOURNAMENT_TABLE_TV_SCORE_OVERLAY_LARGE}
                component={DPTournamentTVOverlayLarge}
                title="Tournament TV Score Overlay Large"
                defaultTheme="dark"
                // showFooter={false}
                // breadcrumb={false}
                {...props}
            />

            <AppLayout
                exact
                path={routes.DIGITALPOOL.TOURNAMENT_REGISTRATION}
                component={DPTournamentRegistration}
                title="Tournament Registration"
                // breadcrumb={false}
                {...props}
            />

            <AppLayout
                exact
                path={routes.DIGITALPOOL.TOURNAMENT_DETAIL_BRACKET_STAGE}
                component={DPTournamentDetail}
                title="Tournaments"
                // breadcrumb={false}
                {...props}
            />

            <AppLayout
                // exact
                path={routes.DIGITALPOOL.TOURNAMENT_DETAIL}
                component={DPTournamentDetail}
                title="Tournaments"
                // breadcrumb={false}
                {...props}
            />

            <AppLayout path={routes.DIGITALPOOL.TOURNAMENTS} component={AsyncDPMarketing} title="Tournaments" {...props} />

            <AppLayout
                exact
                path={routes.DIGITALPOOL.TOURNAMENT_MATCH_SCORING_SHORT_URL}
                component={DPTournamentMatchScoring}
                title="Tournament Match Scoring"
                defaultTheme="dark"
                // showFooter={false}
                // breadcrumb={false}
                {...props}
            />

            <AppLayout
                exact
                path={routes.DIGITALPOOL.TOURNAMENT_MATCH_SCORING}
                component={DPTournamentMatchScoring}
                title="Tournament Match Scoring"
                defaultTheme="dark"
                // showFooter={false}
                // breadcrumb={false}
                {...props}
            />

            <EmptyLayout
                exact
                path={routes.DIGITALPOOL.TOURNAMENT_MATCH_DETAIL_SCORESHEET}
                component={DPTournamentMatchDetailScoresheet}
                title="Tournament Match Detail Scoresheet"
                defaultTheme="light"
                // showFooter={false}
                // breadcrumb={false}
                {...props}
            />

            <EmptyLayout
                exact
                path={routes.DIGITALPOOL.TOURNAMENT_TABLE_SCORING_MOBILE}
                component={DPTournamentTableScoringMobile}
                title="Tournament Table Scoring"
                defaultTheme="dark"
                // showFooter={false}
                // breadcrumb={false}
                {...props}
            />

            <EmptyLayout
                exact
                path={routes.DIGITALPOOL.TOURNAMENT_TABLE_SCOREBOARD}
                component={DPTournamentTableScoring}
                title="Tournament Table Scoring"
                defaultTheme="dark"
                // showFooter={false}
                // breadcrumb={false}
                {...props}
            />

            <EmptyLayout
                exact
                path={routes.DIGITALPOOL.TOURNAMENT_TABLE_LIVE_SCORES}
                component={DPTournamentTableLiveScores}
                title="Tournament Table Scoring"
                defaultTheme="dark"
                // showFooter={false}
                // breadcrumb={false}
                {...props}
            />

            <AppLayout
                // exact
                path={routes.DIGITALPOOL.TOUR_DETAIL}
                component={DPTourDetail}
                title="Tour"
                // breadcrumb={false}
                {...props}
            />

            <AppLayout path={routes.DIGITALPOOL.PEOPLE} component={AsyncDPMarketing} title="People" {...props} />

            {/* <AppLayout
                    path={routes.DIGITALPOOL.PLAYER_DETAIL}
                    component={PlayerDetail}
                    title="Player Detail"
                    {...props}
                /> */}

            <AppLayout
                path={routes.DIGITALPOOL.PLAYERS}
                component={Players}
                title="Players"
                // breadcrumb
                {...props}
            />

            <AppLayout path={routes.DIGITALPOOL.FANTASY_LINES} component={AsyncFantasyLines} title="Fantasy Lines" {...props} />

            <AppLayout
                exact
                path={routes.DIGITALPOOL.VENUE_MAP}
                component={VenueMap}
                title="Venue Map"
                // breadcrumb
                {...props}
            />

            <AppLayout
                exact
                path={routes.DIGITALPOOL.VENUE_DETAIL}
                component={VenueDetail}
                title="Venue Detail"
                // breadcrumb
                {...props}
            />

            <AppLayout exact path={routes.DIGITALPOOL.VENUES} component={AsyncDPMarketing} title="Venues" {...props} />
            <AppLayout exact path={routes.DIGITALPOOL.ORGANIZATIONS} component={AsyncDPMarketing} title="Organizations" {...props} />
            <AppLayout
                exact
                path={routes.DIGITALPOOL.MATCHES}
                component={AsyncDPMarketing}
                title="Matches"
                // breadcrumb
                {...props}
            />
            <AppLayout
                exact
                path={routes.DIGITALPOOL.MATCHES_CREATE}
                component={MatchCreate}
                title="Create Match"
                // breadcrumb
                {...props}
            />

            <StreamOverlayLayout
                exact
                path={routes.DIGITALPOOL.MATCH_DETAIL_STREAM_OVERLAY}
                component={MatchStreamOverlay}
                title="Match Stream Overlay"
                // breadcrumb
                {...props}
            />

            <StreamOverlayLayout
                exact
                path={routes.DIGITALPOOL.MATCH_DETAIL_STATS_OVERLAY}
                component={MatchStatsOverlay}
                title="Match Stats Overlay"
                // breadcrumb
                {...props}
            />

            <StreamOverlayLayout
                exact
                path={routes.DIGITALPOOL.MATCH_DETAIL_DAY_STATS_OVERLAY}
                component={MatchDayStatsOverlay}
                title="Match Day Stats Overlay"
                // breadcrumb
                {...props}
            />

            <StreamOverlayLayout
                exact
                path={routes.DIGITALPOOL.MATCH_DETAIL_LARGE_SCORE_OVERLAY}
                component={MatchLargeScoreOverlay}
                title="Match Large Score Overlay"
                // breadcrumb
                {...props}
            />

            <StreamOverlayLayout
                exact
                path={routes.DIGITALPOOL.MATCH_DETAIL_STREAM_TABLE_OVERLAY}
                component={StreamTableOverlay}
                title="Stream Table Overlay"
                // breadcrumb
                {...props}
            />

            <AppLayout
                path={routes.DIGITALPOOL.MATCH_DETAIL}
                component={MatchDetail}
                title="Match Detail"
                // breadcrumb
                {...props}
            />

            <AppLayout
                exact
                path={routes.DIGITALPOOL.SCOREBOARD}
                component={Scoreboard}
                title="Scoreboard"
                // breadcrumb
                {...props}
            />
            <AppLayout exact path={routes.DIGITALPOOL.ADVERTISING} component={Advertising} title="Advertising" {...props} />
            <AppLayout exact path={routes.DIGITALPOOL.VERIFY_EMAIL} component={VerifyEmail} title="Verify Email" {...props} />
            <AppLayout exact path={routes.DIGITALPOOL.REGISTER} component={AsyncRegister} {...props} />
            <AppLayout exact path={routes.DIGITALPOOL.LOGIN} component={AsyncLogin} {...props} />
            <AppLayout exact path={routes.DIGITALPOOL.FORGOT_PASSWORD} component={AsyncForgotPassword} {...props} />
            <AppLayout exact path={routes.DIGITALPOOL.CHANGE_PASSWORD} component={AsyncChangePassword} {...props} />
            <AppLayout exact path={routes.DIGITALPOOL.PROFILE} component={AsyncProfile} {...props} />
            <AppLayout exact path={routes.DIGITALPOOL.USER_DETAILS} component={AsyncProfile} {...props} />
            <AppLayout exact path={routes.DIGITALPOOL.ACCOUNT_SETTINGS} component={AsyncAccountSettings} {...props} />
            <AppLayout exact path={routes.DIGITALPOOL.ACCOUNT_PROFILE_SETTINGS} component={AsyncAccountSettings} {...props} />
            <AppLayout exact path={routes.DIGITALPOOL.ACCOUNT_NOTIFICATION_SETTINGS} component={AsyncAccountSettings} {...props} />

            <AppLayout exact path={routes.DPL.INDEX} component={DPLMarketing} title="Digital Pool League" {...props} />
            <AppLayout exact path={routes.LEAGUE_MANAGER.INDEX} component={LMMarketing} title="League Manager" {...props} />

            {/* <Redirect from="/league-manager/:slug" to="/league-manager/:slug/dashboard" /> */}
            <AppLayout exact path={routes.TOURNAMENT_BUILDER.INDEX} component={TBMarketing} title="Tournament Builder" {...props} />

            <AppLayout exact path={routes.POOL_LIVE.INDEX} component={PLMarketing} title="Pool.Live" {...props} />
            <AppLayout exact path={routes.POOL_LIVE.STREAM_DETAIL} component={PLStreamDetail} title="Pool.Live" {...props} />

            <AppLayout exact path={routes.PRIVACY} component={Privacy} title="Privacy" {...props} />
            <AppLayout exact path={routes.TERMS} component={Terms} title="Terms" {...props} />
            <AppLayout exact path={routes.USE} component={Use} title="Use" {...props} />

            <Route
                exact
                path="/programming-languages"
                render={() => (
                    <AppLayout {...props}>
                        <ProgrammingLanguages title="Programming Languages" {...props} />
                    </AppLayout>
                )}
            />
            {/* {authState.status !== 'in' && <Redirect to={routes.DIGITALPOOL.INDEX} />} */}
            <EmptyLayout exact path={routes.NOT_FOUND} component={NotFound} title="404 Not Found" {...props} />
            <AppLayout path={routes.DIGITALPOOL.PROFILE_PRO} component={AsyncProfilePro} {...props} />
            <Redirect to={routes.NOT_FOUND} />
        </Switch>
    );

    return (
        <Router onUpdate={fireGATracking} history={history} location={props.location}>
            {/* <HelmetMetaData /> */}
            {authState && authState.status === 'in' ? renderLoggedIn : renderLoggedOut}
        </Router>
    );
}
