import gql from 'graphql-tag';

export const CREATE_MATCH_MUTATION = gql`
    mutation insert_matches($objects: [matches_insert_input!]!) {
        insert_matches(objects: $objects) {
            affected_rows
            returning {
                id
                slug
            }
        }
    }
`;

export const UPDATE_LEAGUE_MATCH = gql`
    mutation update_league_matches($id: Int, $changes: league_matches_set_input) {
        update_league_matches(where: { id: { _eq: $id } }, _set: $changes) {
            affected_rows
            returning {
                id
            }
        }
    }
`;

export const UPDATE_NOTIFICATIONS_SEEN_AT = gql`
    mutation update_notifications($user_id: Int!, $changes: notifications_set_input) {
        update_notifications(where: { user_id: { _eq: $user_id } }, _set: $changes) {
            affected_rows
            returning {
                id
            }
        }
    }
`;

export const DELETE_NOTIFICATION = gql`
    mutation delete_notifications($id: Int!, $user_id: Int!) {
        delete_notifications(where: { id: { _eq: $id }, user_id: { _eq: $user_id } }) {
            returning {
                id
            }
        }
    }
`;

export const SEND_LIVE_CHAT_MESSAGE = gql`
    mutation insert_live_chat($message: String!, $live_id: Int!, $user_id: Int!) {
        insert_live_chat(objects: { message: $message, live_id: $live_id, user_id: $user_id }) {
            affected_rows
            returning {
                id
            }
        }
    }
`;

export const CREATE_EVENT_MUTATION = gql`
    mutation insert_events($objects: [events_insert_input!]!) {
        insert_events(objects: $objects) {
            affected_rows
            returning {
                id
                name
                slug
                description
            }
        }
    }
`;

export const UPDATE_EVENT_MUTATION = gql`
    mutation update_events($id: Int, $changes: events_set_input) {
        update_events(where: { id: { _eq: $id } }, _set: $changes) {
            affected_rows
            returning {
                id
                name
                slug
            }
        }
    }
`;

export const DELETE_EVENT_MUTATION = gql`
    mutation delete_events($id: Int!, $league_id: Int) {
        delete_events(where: { id: { _eq: $id } }) {
            affected_rows
        }
    }
`;

export const CREATE_PLAYER = gql`
    mutation upsert_players($objects: [players_insert_input!]!) {
        insert_players(objects: $objects, on_conflict: { constraint: ranked_player_pkey, update_columns: [] }) {
            affected_rows
            returning {
                id
            }
        }
    }
`;

export const UPDATE_PLAYER = gql`
    mutation update_players($id: Int, $changes: players_set_input) {
        update_players(where: { id: { _eq: $id } }, _set: $changes) {
            affected_rows
            returning {
                id
                name
                aliases
                properties
                image_large
                image_small
                country
                gender
                name_slug
                user_id
            }
        }
    }
`;

export const UPDATE_USER = gql`
    mutation update_users($id: Int, $changes: users_set_input) {
        update_users(where: { id: { _eq: $id } }, _set: $changes) {
            affected_rows
            returning {
                id
                first_name
                last_name
                slug
                country
                region
                city
                country_phone
                fargo_id
                player_id
                email
                role
                uid
                last_seen
                token
                avatar
            }
        }
    }
`;

export const CREATE_LEAGUE_PLAYER = gql`
    mutation upsert_league_players($objects: [league_players_insert_input!]!) {
        insert_league_players(objects: $objects, on_conflict: { constraint: league_players_email_league_id_key, update_columns: [] }) {
            affected_rows
            returning {
                id
                user_id
                player_id
                name
                nickname
                country
                country_phone
                gender
                email
                phone_number
                fargo_id
                skill_level
                race_to
                special_group
                status
            }
        }
    }
`;

export const DELETE_LEAGUE_PLAYER = gql`
    mutation delete_league_players($user_id: Int!, $league_id: Int!) {
        delete_league_players(where: { user_id: { _eq: $user_id }, league_id: { _eq: $league_id } }) {
            affected_rows
        }
    }
`;
