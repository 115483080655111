import React, { useState, useRef } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { Form, FormItem, Input, Radio, InputNumber, Select, Checkbox, DatePicker, SubmitButton, FormikDebug, ResetButton, AutoComplete } from 'formik-antd';
import { Formik, ErrorMessage } from 'formik';
import { message, Button, Typography, Avatar, Tooltip, Icon, Input as InputAntd, Divider } from 'antd';
import * as Yup from 'yup';
import { Query, Mutation } from '@apollo/client/react/components';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { CountryRegionData } from 'react-country-region-selector';
import CircleFlag from '../../../components/CircleFlag';
import gql from 'graphql-tag';
import slugify from 'slugify';
import axios from 'axios';
import CircleStateFlag from '../../../components/CircleStateFlag';
import { getGameType } from '../../../utils';
import { QuestionCircleFilled } from '@ant-design/icons';
import moment from 'moment-timezone';
import AddVenueModal from './AddVenueModal';
import AddEventModal from './AddEventModal';
import AddTableModal from './AddTableModal';

import { CREATE_MATCH_MUTATION, UPDATE_MATCH_MUTATION, UPDATE_MATCH_PLAYER, DELETE_MATCH_PLAYER, INSERT_MATCH_MANAGERS } from './data/mutations';
import {
    GET_MATCH_QUERY,
    SEARCH_VENUES_QUERY,
    GET_USER_EVENTS_QUERY,
    GET_MATCH_BY_SLUG_QUERY,
    GET_GAME_TYPES_QUERY,
    SEARCH_USERS,
    GET_VENUE_TABLES_QUERY,
    GET_MATCH_TYPES_QUERY,
} from './data/queries';
import _ from 'lodash';
import qs from 'query-string';

const { TextArea } = Input;
const { Search } = InputAntd;
const { Title, Text } = Typography;
const { Option } = Select;

const styles = {
    error: { color: '#f5222d', marginTop: 10 },
};

function MatchCreateEditForm(props) {
    const { client, authState, selectedMatchId, matchData, theme } = props;
    const [loading, setLoading] = useState(false);
    const [acDataSource, setacDataSource] = useState();
    // const [nicknameForm, setNicknameForm] = useState('');
    const [eventModalVisible, setEventModalVisible] = useState(false);
    const [venueModalVisible, setVenueModalVisible] = useState(false);
    const [tableModalVisible, setTableModalVisible] = useState(false);
    const [updateMatchPlayer] = useMutation(UPDATE_MATCH_PLAYER);
    const [deleteMatchPlayer] = useMutation(DELETE_MATCH_PLAYER);
    const [venueDataSource, setVenueDataSource] = useState('');
    // const [ selected, setSelected ] = useState();
    // const [players, setPlayers] = useState();
    const [tables, setTables] = useState();
    const formRef = useRef();
    const params = qs.parse(props.location.search, { ignoreQueryPrefix: true });
    const eventId = params.event_id;

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    };

    const MatchSchema = Yup.object().shape({
        name: Yup.string().min(2, 'Too Short!').max(100, 'Too Long!').required('Required'),
        match_type: Yup.string().required('Required'),
        game_type: Yup.string().required('Required'),
        race_to: Yup.number().required('Required'),
        player_name: Yup.string().required('Required'),
        opponent_name: Yup.string().required('Required'),
        venue_id: Yup.number().required('Required'),
        scheduled_time: Yup.string().required('Required'),
    });

    function onOk(value) {
        const tzDate = moment.tz(value, moment.tz.guess(value)).format('YYYY-MM-DD hh:mm A z');
        console.log('onOK', tzDate);
        return tzDate;
    }

    function handleVenueModalOk(e) {
        setVenueModalVisible(false);
    }

    function handleVenueModalCancel(e) {
        setVenueModalVisible(false);
    }

    function handleAddVenue() {
        setVenueModalVisible(true);
    }

    function handleTableModalOk(e) {
        console.log(e);
        setTableModalVisible(false);
    }

    function handleTableModalCancel(e) {
        setTableModalVisible(false);
    }

    function handleAddTable() {
        setTableModalVisible(true);
    }

    function handleSubmit(values, createMatch, updateMatch, actions) {
        console.log(values);
        const {
            name,
            description,
            match_type,
            game_type,
            race_to,
            player_id,
            player_name,
            player_country,
            player_state,
            player_fargo,
            player_race_to,
            opponent_id,
            opponent_name,
            opponent_country,
            opponent_state,
            opponent_fargo,
            opponent_race_to,
            venue_id,
            pool_table_id,
            table_size,
            scheduled_time,
            livestream_url,
            is_public,
            creator_id,
            event_id,
        } = values;
        setLoading(true);
        const slug = slugify(name, {
            replacement: '-',
            remove: /[*+~.,()'"#!:/@]/g,
            lower: true,
        });

        if (matchData) {
            // match_game_types.forEach((game_type) => {
            // 	console.log(game_type);
            // 	props.client.mutate({
            // 		mutation: UPSERT_match_GAME_TYPES_MUTATION,
            // 		variables: {
            // 			objects: [
            // 				{
            // 					match_id: props.match.id,
            // 					game_type: game_type
            // 				}
            // 			]
            // 		},
            // 		optimisticResponse: {}
            // 	});
            // });

            updateMatch({
                variables: {
                    id: matchData.id,
                    changes: {
                        name,
                        description,
                        slug,
                        match_type,
                        game_type,
                        race_to,
                        player_id,
                        player_name,
                        player_country,
                        player_state,
                        player_fargo: parseInt(player_fargo),
                        player_race_to,
                        opponent_id,
                        opponent_name,
                        opponent_country,
                        opponent_state,
                        opponent_fargo: parseInt(opponent_fargo),
                        opponent_race_to,
                        venue_id,
                        pool_table_id,
                        table_size,
                        creator_id,
                        scheduled_time,
                        livestream_url,
                        is_public,
                        event_id,
                    },
                },
                notifyOnNetworkStatusChange: true,
                awaitRefetchQueries: true,
                refetchQueries: [
                    {
                        query: GET_MATCH_BY_SLUG_QUERY,
                        variables: {
                            slug: slug,
                        },
                    },
                ],
            })
                .then((values) => {
                    actions.setSubmitting(false);

                    console.log('match players', matchData.match_players);

                    const original = matchData.match_players.map((item) => item.id);
                    const toUpdate = _.uniq(matchData.match_players, original);
                    const toDelete = _.difference(original, toUpdate);
                    console.log('original', original);
                    console.log('update', toUpdate);
                    console.log('delete', toDelete);

                    // const original = matchData.match_players.map((player) => {
                    //     return {
                    //         id: player.id,
                    //         name: player.name,
                    //         country: player.country,
                    //         user_id: player.user_id,
                    //     };
                    // });

                    const player = toUpdate.filter((player) => {
                        return player.name === matchData.player_name;
                    })[0];

                    const opponent = toUpdate.filter((opponent) => {
                        return opponent.name === matchData.opponent_name;
                    })[0];

                    const matchPlayers = [player, opponent];

                    // const matchPlayers = [
                    //     {
                    //         name: matchData.player_name,
                    //         country: matchData.player_country,
                    //         user_id: matchData.player_id,
                    //     },
                    //     {
                    //         name: matchData.opponent_name,
                    //         country: matchData.opponent_country,
                    //         user_id: matchData.opponent_id,
                    //     },
                    // ];

                    // // function comparer(otherArray) {
                    // // 	return function(current) {
                    // // 		return (
                    // // 			otherArray.filter(function(other) {
                    // // 				return other.user_id === current.user_id && other.name === current.name;
                    // // 			}).length === 0
                    // // 		);
                    // // 	};
                    // // }
                    // // var onlyInA = original.filter(comparer(toUpdate));
                    // // var onlyInB = toUpdate.filter(comparer(original));
                    // // console.log(onlyInA);
                    // // console.log(onlyInB);
                    // // const result = onlyInA.concat(onlyInB);
                    // // const toDelete = result;

                    // const toUpdate = matchPlayers;
                    // const toDelete = _.differenceBy(original, toUpdate, 'name');

                    // console.log('original', original);
                    console.log('match players', matchPlayers);
                    console.log(
                        'new',
                        matchPlayers.map((player) => player.name)
                    );
                    // console.log('update', toUpdate);
                    // console.log('delete', toDelete);

                    if (toDelete.length > 2) {
                        deleteMatchPlayer({
                            variables: {
                                match_id: matchData.id,
                            },
                        })
                            .then((data) => {
                                console.log(data);
                                updatePlayer();
                            })
                            .catch((error) => {
                                console.log(error);
                            });
                    } else {
                        updatePlayer();
                    }

                    function updatePlayer() {
                        updateMatchPlayer({
                            variables: {
                                match_id: matchData.id,
                                objects: matchPlayers.map((player) => {
                                    console.log(player);
                                    return {
                                        match_id: matchData.id,
                                        ...(player.id && { id: player.id }),
                                        ...(player.user_id && { user_id: player.user_id }),
                                        name: player.name,
                                        country: player.country,
                                    };
                                }),
                            },
                            notifyOnNetworkStatusChange: true,
                            awaitRefetchQueries: true,
                            refetchQueries: [
                                {
                                    query: GET_MATCH_QUERY,
                                    variables: { id: matchData.id },
                                },
                            ],
                        })
                            .then((data) => {
                                console.log(data);
                                setLoading(false);
                                message.success('Changes saved');
                            })
                            .catch((error) => {
                                console.log(error);
                                setLoading(false);
                                message.info('There was an error', error);
                            });
                    }
                })
                .catch((e) => {
                    actions.setSubmitting(false);
                    message.error((e && e.message) || JSON.stringify(e));
                    console.log(e);
                });
        } else {
            createMatch({
                variables: {
                    objects: [
                        {
                            name,
                            slug,
                            description,
                            match_type,
                            game_type,
                            race_to,
                            player_id,
                            player_name,
                            player_country,
                            player_state,
                            player_fargo: parseInt(player_fargo),
                            player_race_to,
                            opponent_id,
                            opponent_name,
                            opponent_country,
                            opponent_state,
                            opponent_fargo: parseInt(opponent_fargo),
                            opponent_race_to,
                            venue_id,
                            pool_table_id,
                            table_size,
                            creator_id: authState && authState.user && authState.user.id,
                            scheduled_time,
                            livestream_url,
                            is_public,
                            event_id,
                            match_players: {
                                data: [
                                    {
                                        name: player_name,
                                        country: player_country,
                                        user_id: player_id,
                                    },
                                    {
                                        name: opponent_name,
                                        country: opponent_country,
                                        user_id: opponent_id,
                                    },
                                ],
                            },
                            managers: {
                                data: [
                                    {
                                        user_id: authState && authState.user && authState.user.id,
                                        email: authState && authState.user && authState.user.email,
                                        match_slug: slug,
                                        invite_accepted: true,
                                        admin: true,
                                        creator: true,
                                    },
                                ],
                            },
                        },
                    ],
                },
            })
                .then((values) => {
                    actions.setSubmitting(false);
                    actions.resetForm();
                })
                .catch((e) => {
                    actions.setSubmitting(false);
                    if (e.message && e.message.includes('Uniqueness violation')) {
                        message.error('Error: There is already a tournament with that name. Please choose a different name.');
                    } else {
                        message.error((e && e.message) || JSON.stringify(e));
                    }
                    console.log(e);
                });
        }
    }

    async function queryVenues(venueName) {
        let query = await client.query({
            query: SEARCH_VENUES_QUERY,
            variables: {
                name: `%${venueName}%`,
            },
        });

        let dataSource = query.data.venues;

        setVenueDataSource(dataSource);
    }

    async function queryDB(name) {
        setacDataSource([]);
        var users = [];

        const res = await client.query({
            query: SEARCH_USERS,
            variables: {
                first_name: `%${name}%`,
                last_name: `%${name}%`,
                nickname: `%${name}%`,
            },
        });

        for (let i = 0; i < res.data.users.length; i++) {
            let user = res.data.users[i];
            const { id, first_name, last_name, display_name, nickname, country } = user;

            users.push({
                ...user,
                id,
                name: display_name ? `${display_name}` : `${first_name} ${last_name}`,
                country: country,
                nickname,
                user_id: id,
            });
        }

        const dataSet = _.uniqBy([...users], 'name').sort((a, b) => a.name.localeCompare(b.name));
        // console.log(dataSet);

        setacDataSource(dataSet);
    }

    function onSearch(name) {
        if (name && name.length > 2) {
            queryDB(name);
        } else {
            setacDataSource([]);
        }
    }

    function onSelect1(value, option) {
        const player = acDataSource.filter((item) => item.name === value);

        if (player) {
            // setSelected(player[0]);
            const playerInfo = player[0];
            console.log(player);
            formRef.current.setValues({
                ...formRef.current.state.values,
                player_id: playerInfo.id,
                player_name: playerInfo.name,
                player_country: playerInfo.country,
            });
        }
    }

    function onSelect2(value, option) {
        const player = acDataSource.filter((item) => item.name === value);

        if (player) {
            // setSelected(player[0]);
            const playerInfo = player[0];
            console.log(player);
            formRef.current.setValues({
                ...formRef.current.state.values,
                opponent_id: playerInfo.id,
                opponent_name: playerInfo.name,
                opponent_country: playerInfo.country,
            });
        }
    }

    function onSearchVenues(name) {
        if (name && name.length > 2) {
            queryVenues(name);
        }
    }

    function onSelectVenue(value, option) {
        console.log({
            value,
            option,
        });
        const venue = venueDataSource.filter((venue) => {
            return venue.id === parseInt(value);
        })[0];
        formRef.current.setValues({ ...formRef.current.state.values, venue_id: parseInt(value), venue_name: venue.name });
    }

    async function getPlayerInfoAsync(playerSearch) {
        console.log(playerSearch);
        try {
            const nameSplit = playerSearch.split(' ');
            let url;
            if (nameSplit.length > 1) {
                const firstName = nameSplit[0];
                const lastName = nameSplit[1];
                url = 'https://dashboard.fargorate.com/api/indexsearch?q=' + firstName.toLowerCase() + '%20' + lastName.toLowerCase();
            } else {
                url = 'https://dashboard.fargorate.com/api/indexsearch?q=' + playerSearch.toLowerCase();
            }

            var response = await axios(url);
            const player = response.data.value[0];
            // const fargoProfile = await getFargoProfileById(player.id);
            const combinedProfile = {
                ...player,
                // ...fargoProfile
            };
            console.log(combinedProfile);
            message.success(`${player.effectiveRating} Fargo Rating found for ${playerSearch}`);
            return player;
        } catch (error) {
            const text = error && error.response && error.response.data && error.response.data.Message;
            console.log(text);
            if (text) {
                message.error(`Error: ${text}`);
            } else {
                message.error(`Error: Player not found.`);
            }
            return text;
        }

        // try {
        // 	var url = `https://dashboard.fargorate.com/api/indexsearch?q=${playerSearch}`;
        // 	var response = await get(url);
        // 	var data = JSON.parse(response);
        // 	if (data.value && data.value[0]) {
        // 		var players = data.value;
        // 		var player = players[0];
        // 		players.forEach(function(p) {
        // 			if (p.robustness > player.robustness) {
        // 				player = p;
        // 			}
        // 		});
        // 		console.log(player);
        // 		return player;
        // 	} else {
        // 		alert('Player not found for: ' + playerSearch);
        // 		return undefined;
        // 	}
        // } catch (error) {
        // 	alert(error);
        // 	return undefined;
        // }
    }

    function handleFormFieldUpdates(event) {
        const caret = event.target.selectionStart;
        const element = event.target;
        window.requestAnimationFrame(() => {
            element.selectionStart = caret;
            element.selectionEnd = caret;
        });
    }

    async function lookupPlayerFargoRating(value) {
        const fargoRating = await getPlayerInfoAsync(value);

        if (fargoRating) {
            console.log(fargoRating);
            formRef.current.setValues({
                ...formRef.current.state.values,
                player_fargo: fargoRating.effectiveRating,
            });
        }
    }

    async function lookupOpponentFargoRating(value) {
        const fargoRating = await getPlayerInfoAsync(value);

        if (fargoRating) {
            console.log(fargoRating);
            formRef.current.setValues({
                ...formRef.current.state.values,
                opponent_fargo: fargoRating.effectiveRating,
            });
        }
    }

    function handleEventModalOk(e) {
        setEventModalVisible(false);
    }

    function handleEventModalCancel(e) {
        setEventModalVisible(false);
    }

    function handleAddEvent() {
        setEventModalVisible(true);
    }

    return (
        <React.Fragment>
            <Mutation
                mutation={CREATE_MATCH_MUTATION}
                onCompleted={(data) => {
                    console.log(data);
                    setLoading(false);
                    message.success('match successfully created');
                    const result = data && data.insert_matches && data.insert_matches.returning[0];
                    if (result) {
                        props.history.push(`/matches/${result.slug}`);
                    } else {
                        props.history.push(`/matches`);
                    }

                    // const { createLeague } = data;
                    // history.push(`/league-manager/${createLeague.slug}`);
                }}
                awaitRefetchQueries
            >
                {(createMatch, { error }) => (
                    <Mutation
                        mutation={UPDATE_MATCH_MUTATION}
                        onCompleted={(data) => {
                            console.log(data);
                            setLoading(false);
                            message.success('Changes saved');
                            const result = data && data.update_matches && data.update_matches.returning[0];
                            if (result) {
                                props.history.push(`/matches/${result.slug}`);
                            } else {
                                props.history.push(`/matches`);
                            }
                        }}
                        // awaitRefetchQueries
                    >
                        {(updateMatch, { loading, error }) => {
                            if (error) {
                                alert('Something went wrong');
                            }
                            return (
                                <Formik
                                    ref={formRef}
                                    initialValues={
                                        matchData
                                            ? {
                                                  ...matchData,
                                                  event_id: matchData.event_id && matchData.event_id,
                                                  venue_id: matchData.venue && matchData.venue.id,
                                                  venue_name: matchData.venue && matchData.venue.name,
                                              }
                                            : {
                                                  name: '',
                                                  venue_id: undefined,
                                                  venue_name: undefined,
                                                  description: '',
                                                  match_type: undefined,
                                                  game_type: undefined,
                                                  race_to: null,
                                                  player_id: undefined,
                                                  player_name: undefined,
                                                  player_country: undefined,
                                                  player_state: undefined,
                                                  player_fargo: undefined,
                                                  player_race_to: null,
                                                  opponent_id: undefined,
                                                  opponent_name: undefined,
                                                  opponent_country: undefined,
                                                  opponent_state: undefined,
                                                  opponent_fargo: undefined,
                                                  opponent_race_to: null,
                                                  table_size: '8 Foot',
                                                  pool_table_id: undefined,
                                                  scheduled_time: '',
                                                  livestream_url: '',
                                                  is_public: false,
                                                  event_id: eventId ? parseInt(eventId) : undefined,
                                              }
                                    }
                                    validationSchema={MatchSchema}
                                    onSubmit={(values, actions) => {
                                        handleSubmit(values, createMatch, updateMatch, actions);
                                    }}
                                    render={({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                                        <Form {...formItemLayout} onSubmit={handleSubmit} layout="horizontal" style={{ maxWidth: 600 }}>
                                            <FormItem label="Match Name" name="name" autoFocus required hasFeedback showValidateSuccess>
                                                <Input
                                                    name="name"
                                                    placeholder="Name"
                                                    size="large"
                                                    onChange={(event) => {
                                                        handleFormFieldUpdates(event);
                                                    }}
                                                />
                                            </FormItem>

                                            <FormItem name="description" label="Description">
                                                <TextArea
                                                    rows={4}
                                                    name="description"
                                                    placeholder="Description"
                                                    size="large"
                                                    onChange={(event) => {
                                                        handleFormFieldUpdates(event);
                                                    }}
                                                />
                                            </FormItem>

                                            <FormItem label="Match Type" name="match_type" required hasFeedback showValidateSuccess>
                                                <Query query={GET_MATCH_TYPES_QUERY} fetchPolicy="cache-and-network" notifyOnNetworkStatusChange={true}>
                                                    {({ loading, error, data }) => {
                                                        if (error) return <div>Error: {error.message}</div>;

                                                        return (
                                                            <Select
                                                                showSearch
                                                                name="match_type"
                                                                placeholder="Select a match type"
                                                                size="large"
                                                                loading={loading}
                                                                optionFilterProp="children"
                                                                optionLabelProp="children"
                                                                filterOption={(inputValue, option) => {
                                                                    return option.props.value.toString().toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
                                                                }}
                                                            >
                                                                {data &&
                                                                    data.match_types &&
                                                                    data.match_types.map((item, index) => (
                                                                        <Option key={index} value={item.value}>
                                                                            <Text>{_.startCase(_.toLower(item.value.replace('_', ' ')))}</Text>
                                                                        </Option>
                                                                    ))}
                                                            </Select>
                                                        );
                                                    }}
                                                </Query>
                                            </FormItem>

                                            <FormItem label="Game Type" name="game_type" required hasFeedback showValidateSuccess>
                                                <Query query={GET_GAME_TYPES_QUERY} fetchPolicy="cache-and-network" notifyOnNetworkStatusChange>
                                                    {({ loading, error, data }) => {
                                                        if (error) return <div>Error: {error.message}</div>;
                                                        if (loading) return null;

                                                        return (
                                                            <Select
                                                                showSearch
                                                                name="game_type"
                                                                loading={loading}
                                                                placeholder="Select game"
                                                                size="large"
                                                                optionFilterProp="children"
                                                                // defaultValue={[ 'jack', 'lucy' ]}
                                                                filterOption={(input, option) =>
                                                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                }
                                                            >
                                                                {data.game_types &&
                                                                    data.game_types
                                                                        .sort((a, b) => a.value.localeCompare(b.value))
                                                                        .map((item, index) => (
                                                                            <Option key={index} value={item.value}>
                                                                                <Avatar
                                                                                    size={24}
                                                                                    src={getGameType(item.value, theme)}
                                                                                    style={{
                                                                                        display: 'inline-block',
                                                                                        marginRight: 8,
                                                                                    }}
                                                                                />
                                                                                <Text>{_.startCase(_.toLower(item.value.replace('_', ' ')))}</Text>
                                                                            </Option>
                                                                        ))}
                                                            </Select>
                                                        );
                                                    }}
                                                </Query>
                                            </FormItem>
                                            <FormItem label="Default race to" name="race_to" required hasFeedback showValidateSuccess>
                                                <InputNumber name="race_to" min={0} placeholder="0" size="large" />
                                            </FormItem>
                                            <FormItem label="Scheduled Date & Time" name="scheduled_time" required hasFeedback showValidateSuccess>
                                                <DatePicker
                                                    showTime={{ format: 'HH:mm' }}
                                                    showNow
                                                    showToday
                                                    use12Hours
                                                    name="scheduled_time"
                                                    size="large"
                                                    format="YYYY-MM-DD h:mm A z"
                                                    // onChange={onDateChange}
                                                    onOk={onOk}
                                                />
                                            </FormItem>

                                            <FormItem label={<React.Fragment>Associate with an event</React.Fragment>} name="event_id">
                                                <Query
                                                    query={GET_USER_EVENTS_QUERY}
                                                    fetchPolicy="cache-and-network"
                                                    notifyOnNetworkStatusChange={true}
                                                    variables={{ user_id: props.authState.user.id }}
                                                    onCompleted={async (data) => {
                                                        console.log(data);
                                                    }}
                                                >
                                                    {({ loading, error, data }) => {
                                                        if (loading) return <div>Loading...</div>;
                                                        if (error) return <div>Error: {error.message}</div>;

                                                        return (
                                                            <Select
                                                                showSearch
                                                                // mode="multiple"
                                                                name="event_id"
                                                                placeholder="Select an event"
                                                                size="large"
                                                                optionFilterProp="children"
                                                                suffixIcon={
                                                                    <Tooltip
                                                                        title={
                                                                            <div>
                                                                                Events can be used for marketing purposes and linking multiple matches together.
                                                                                In the near future, players will also be able to pre-register for an event.
                                                                                Creating an event is optional.
                                                                            </div>
                                                                        }
                                                                    >
                                                                        <QuestionCircleFilled style={{ marginLeft: 5 }} />
                                                                    </Tooltip>
                                                                }
                                                                // defaultValue={eventId}
                                                                filterOption={(input, option) => {
                                                                    return (
                                                                        option.props.children.props.children
                                                                            .toString()
                                                                            .toLowerCase()
                                                                            .indexOf(input.toLowerCase()) >= 0
                                                                    );
                                                                }}
                                                                dropdownRender={(menu) => (
                                                                    <div>
                                                                        {menu}
                                                                        <Divider style={{ margin: '4px 0' }} />
                                                                        <div
                                                                            style={{
                                                                                padding: '4px 8px',
                                                                                cursor: 'pointer',
                                                                            }}
                                                                            onMouseDown={(e) => e.preventDefault()}
                                                                            onClick={handleAddEvent}
                                                                        >
                                                                            <Icon type="plus" /> Add Event
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            >
                                                                {data.events
                                                                    .sort((a, b) => {
                                                                        return a.name.localeCompare(b.name, undefined, {
                                                                            numeric: true,
                                                                            sensitivity: 'base',
                                                                        });
                                                                    })
                                                                    .map((item, index) => (
                                                                        <Option key={index} value={item.id}>
                                                                            <Text>{item.name}</Text>
                                                                        </Option>
                                                                    ))}
                                                            </Select>
                                                        );
                                                    }}
                                                </Query>
                                            </FormItem>

                                            <FormItem label="Live stream URL" name="livestream_url">
                                                <Input name="livestream_url" placeholder="https://" size="large" />
                                            </FormItem>

                                            <FormItem label="Player name" name="player_name" required hasFeedback showValidateSuccess>
                                                <AutoComplete
                                                    dataSource={acDataSource}
                                                    // value={autocompleteValue}
                                                    // validate={validator}
                                                    style={{ width: '100%' }}
                                                    onSearch={onSearch}
                                                    onSelect={onSelect1}
                                                    // onChange={onAutoCompleteChange}
                                                    size="large"
                                                    name="player_name"
                                                    placeholder="Search for a player name"
                                                    optionFilterProp="children"
                                                    optionLabelProp="value"
                                                    filterOption={(inputValue, option) => {
                                                        return (
                                                            option.props &&
                                                            option.props.children &&
                                                            option.props.children.toString().toLowerCase().indexOf(inputValue.toString().toLowerCase()) >= 0
                                                        );
                                                    }}
                                                >
                                                    {acDataSource &&
                                                        acDataSource.map((item, index) => (
                                                            <Option key={index} value={item.name}>
                                                                {item.user_id && (
                                                                    <Avatar
                                                                        size={24}
                                                                        style={{
                                                                            display: 'inline-block',
                                                                            marginRight: 8,
                                                                        }}
                                                                    >
                                                                        <Icon type="user" />
                                                                    </Avatar>
                                                                )}
                                                                {
                                                                    /* {(item.name.match(/\"[a-zA-Z\ \-\']+\"/g)) ? item.name.replace(/\"[a-zA-Z\ \-\']+\"/g, "") : item.name} */ item.name
                                                                }
                                                            </Option>
                                                        ))}
                                                </AutoComplete>
                                            </FormItem>

                                            <FormItem label="Player country" name="player_country" hasFeedback showValidateSuccess>
                                                <Select
                                                    showSearch
                                                    name="player_country"
                                                    placeholder="Please select a country"
                                                    optionFilterProp="children"
                                                    size="large"
                                                    filterOption={(input, option) => {
                                                        return (
                                                            option &&
                                                            option.props &&
                                                            option.props.children &&
                                                            option.props.children.props &&
                                                            option.props.children.props.children &&
                                                            option.props.children.props.children[1] &&
                                                            option.props.children.props.children[1].toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        );
                                                    }}
                                                    dropdownRender={(menu) => (
                                                        <React.Fragment>
                                                            <div>{menu}</div>
                                                        </React.Fragment>
                                                    )}
                                                >
                                                    {CountryRegionData.map((option, index) => {
                                                        return (
                                                            <Option value={option[1]} key={index}>
                                                                <span style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <span role="img" aria-label={option[0]}>
                                                                        <CircleFlag country={option[1]} left={0} />{' '}
                                                                    </span>

                                                                    {option[0]}
                                                                </span>
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                            </FormItem>

                                            {values && values.player_country === 'US' && (
                                                <FormItem label="Player state" name="player_state" hasFeedback showValidateSuccess>
                                                    <Select
                                                        showSearch
                                                        name="player_state"
                                                        placeholder="Please select a state"
                                                        optionFilterProp="children"
                                                        size="large"
                                                        filterOption={(input, option) => {
                                                            return (
                                                                option &&
                                                                option.props &&
                                                                option.props.children &&
                                                                option.props.children.props &&
                                                                option.props.children.props.children &&
                                                                option.props.children.props.children[1] &&
                                                                option.props.children.props.children[1].toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                            );
                                                        }}
                                                        dropdownRender={(menu) => (
                                                            <React.Fragment>
                                                                <div>{menu}</div>
                                                            </React.Fragment>
                                                        )}
                                                    >
                                                        {CountryRegionData.filter((country) => {
                                                            return country[1] === 'US';
                                                        }).map((option, index) => {
                                                            const states = option[2].split('|');

                                                            return states.map((state, index2) => {
                                                                const stateSplit = state.split('~');
                                                                const stateName = stateSplit[0];
                                                                const stateCode = stateSplit[1];
                                                                return (
                                                                    <Option value={stateCode} key={`${index}-${index2}`}>
                                                                        <span style={{ display: 'flex', alignItems: 'center' }}>
                                                                            <span role="img" aria-label={stateName}>
                                                                                <CircleStateFlag state={stateCode} left={0} size="small" />{' '}
                                                                            </span>

                                                                            {stateName}
                                                                        </span>
                                                                    </Option>
                                                                );
                                                            });
                                                        })}
                                                    </Select>
                                                </FormItem>
                                            )}

                                            <FormItem label="Player Fargo Rating" name="player_fargo">
                                                <Input
                                                    name="player_fargo"
                                                    placeholder="500"
                                                    size="large"
                                                    addonAfter={
                                                        <Button
                                                            type="text"
                                                            onClick={() => {
                                                                lookupPlayerFargoRating(values.player_name);
                                                            }}
                                                        >
                                                            Lookup Fargo
                                                        </Button>
                                                    }
                                                />
                                            </FormItem>

                                            <FormItem label="Player race to" name="player_race_to" hasFeedback showValidateSuccess>
                                                <InputNumber name="player_race_to" min={0} placeholder="0" size="large" />
                                            </FormItem>

                                            {/* <FormItem
												label="Player goes to"
												name="player_goes_to"
												hasFeedback
												showValidateSuccess
											>
												<InputNumber
													name="player_goes_to"
													placeholder="0"
													min={0}
													// value={score}
													// onChange={onChange}
													// onFocus={handleFocus}
													// onBlur={handleBlur}
													// style={styles.inputSmall}
													// onPressEnter={onEnter}
													size="large"
													// disabled
												/>
											</FormItem> */}

                                            <FormItem label="Opponent name" name="opponent_name" required hasFeedback showValidateSuccess>
                                                <AutoComplete
                                                    dataSource={acDataSource}
                                                    // value={autocompleteValue}
                                                    // validate={validator}
                                                    style={{ width: '100%' }}
                                                    onSearch={onSearch}
                                                    onSelect={onSelect2}
                                                    // onChange={onAutoCompleteChange}
                                                    size="large"
                                                    name="opponent_name"
                                                    placeholder="Search for a player name"
                                                    optionFilterProp="children"
                                                    optionLabelProp="value"
                                                    filterOption={(inputValue, option) => {
                                                        return (
                                                            option.props &&
                                                            option.props.children &&
                                                            option.props.children.toString().toLowerCase().indexOf(inputValue.toString().toLowerCase()) >= 0
                                                        );
                                                    }}
                                                >
                                                    {acDataSource &&
                                                        acDataSource.map((item, index) => (
                                                            <Option key={index} value={item.name}>
                                                                {item.user_id && (
                                                                    <Avatar
                                                                        size={24}
                                                                        style={{
                                                                            display: 'inline-block',
                                                                            marginRight: 8,
                                                                        }}
                                                                    >
                                                                        <Icon type="user" />
                                                                    </Avatar>
                                                                )}
                                                                {
                                                                    /* {(item.name.match(/\"[a-zA-Z\ \-\']+\"/g)) ? item.name.replace(/\"[a-zA-Z\ \-\']+\"/g, "") : item.name} */ item.name
                                                                }
                                                            </Option>
                                                        ))}
                                                </AutoComplete>
                                            </FormItem>

                                            <FormItem label="Opponent country" name="opponent_country" hasFeedback showValidateSuccess>
                                                <Select
                                                    showSearch
                                                    name="opponent_country"
                                                    placeholder="Please select a country"
                                                    optionFilterProp="children"
                                                    size="large"
                                                    filterOption={(input, option) => {
                                                        return (
                                                            option &&
                                                            option.props &&
                                                            option.props.children &&
                                                            option.props.children.props &&
                                                            option.props.children.props.children &&
                                                            option.props.children.props.children[1] &&
                                                            option.props.children.props.children[1].toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        );
                                                    }}
                                                    dropdownRender={(menu) => (
                                                        <React.Fragment>
                                                            <div>{menu}</div>
                                                        </React.Fragment>
                                                    )}
                                                >
                                                    {CountryRegionData.map((option, index) => {
                                                        return (
                                                            <Option value={option[1]} key={index} style={{ display: 'flex', alignItems: 'center' }}>
                                                                <span style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <span role="img" aria-label={option[0]}>
                                                                        <CircleFlag country={option[1]} left={0} />{' '}
                                                                    </span>

                                                                    {option[0]}
                                                                </span>
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                            </FormItem>

                                            {values && values.opponent_country === 'US' && (
                                                <FormItem label="Opponent state" name="opponent_state" hasFeedback showValidateSuccess>
                                                    <Select
                                                        showSearch
                                                        name="opponent_state"
                                                        placeholder="Please select a state"
                                                        optionFilterProp="children"
                                                        size="large"
                                                        filterOption={(input, option) => {
                                                            return (
                                                                option &&
                                                                option.props &&
                                                                option.props.children &&
                                                                option.props.children.props &&
                                                                option.props.children.props.children &&
                                                                option.props.children.props.children[1] &&
                                                                option.props.children.props.children[1].toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                            );
                                                        }}
                                                        dropdownRender={(menu) => (
                                                            <React.Fragment>
                                                                <div>{menu}</div>
                                                            </React.Fragment>
                                                        )}
                                                    >
                                                        {CountryRegionData.filter((country) => {
                                                            return country[1] === 'US';
                                                        }).map((option, index) => {
                                                            const states = option[2].split('|');

                                                            return states.map((state, index2) => {
                                                                const stateSplit = state.split('~');
                                                                const stateName = stateSplit[0];
                                                                const stateCode = stateSplit[1];

                                                                return (
                                                                    <Option value={stateCode} key={`${index}-${index2}`}>
                                                                        <span style={{ display: 'flex', alignItems: 'center' }}>
                                                                            <span role="img" aria-label={stateName}>
                                                                                <CircleStateFlag state={stateCode} left={0} size="small" />{' '}
                                                                            </span>

                                                                            {stateName}
                                                                        </span>
                                                                    </Option>
                                                                );
                                                            });
                                                        })}
                                                    </Select>
                                                </FormItem>
                                            )}

                                            <FormItem label="Opponent Fargo Rating" name="opponent_fargo">
                                                <Input
                                                    name="opponent_fargo"
                                                    placeholder="500"
                                                    size="large"
                                                    addonAfter={
                                                        <Button
                                                            type="text"
                                                            onClick={() => {
                                                                lookupOpponentFargoRating(values.opponent_name);
                                                            }}
                                                        >
                                                            Lookup Fargo
                                                        </Button>
                                                    }
                                                />
                                            </FormItem>

                                            <FormItem label="Opponent race to" name="opponent_race_to" hasFeedback showValidateSuccess>
                                                <InputNumber name="opponent_race_to" min={0} placeholder="0" size="large" />
                                            </FormItem>

                                            {/* <FormItem
												label="Opponent goes to"
												name="opponent_goes_to"
												hasFeedback
												showValidateSuccess
											>
												<InputNumber
													name="opponent_goes_to"
													placeholder="0"
													min={0}
													// value={score}
													// onChange={onChange}
													// onFocus={handleFocus}
													// onBlur={handleBlur}
													// style={styles.inputSmall}
													// onPressEnter={onEnter}
													size="large"
													// disabled
												/>
											</FormItem> */}

                                            <FormItem label="Venue" name="venue_id" required hasFeedback showValidateSuccess>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <AutoComplete
                                                        name="venue_name"
                                                        dataSource={venueDataSource}
                                                        placeholder="Search for a venue"
                                                        onSearch={onSearchVenues}
                                                        onSelect={onSelectVenue}
                                                        size="large"
                                                        filterOption={(inputValue, option) => {
                                                            return (
                                                                option.props &&
                                                                option.props.children &&
                                                                option.props.children.toString().toLowerCase().indexOf(inputValue.toString().toLowerCase()) >= 0
                                                            );
                                                        }}
                                                        dropdownRender={(menu) => (
                                                            <div>
                                                                {menu}
                                                                <Divider style={{ margin: '4px 0' }} />
                                                                <div
                                                                    style={{
                                                                        padding: '4px 8px',
                                                                        cursor: 'pointer',
                                                                    }}
                                                                    onMouseDown={(e) => e.preventDefault()}
                                                                    onClick={handleAddVenue}
                                                                >
                                                                    <Icon type="plus" /> Add Venue
                                                                </div>
                                                            </div>
                                                        )}
                                                    >
                                                        {venueDataSource &&
                                                            venueDataSource.map((item, index) => (
                                                                <Option key={index} value={`${item.id}`}>
                                                                    {item.name} - {item.city}, {item.region}
                                                                </Option>
                                                            ))}
                                                    </AutoComplete>
                                                    <Button type="primary" ghost size="large" onClick={handleAddVenue} style={{ marginLeft: 10 }}>
                                                        <Icon type="plus" /> Add Venue
                                                    </Button>
                                                </div>
                                            </FormItem>
                                            {values.venue_id && (
                                                <FormItem label="Table" name="pool_table_id">
                                                    <Query
                                                        query={GET_VENUE_TABLES_QUERY}
                                                        fetchPolicy="cache-and-network"
                                                        notifyOnNetworkStatusChange={true}
                                                        variables={{
                                                            venueId: values.venue_id,
                                                        }}
                                                        onCompleted={(data) => {
                                                            console.log(data);
                                                            setTables(data.pool_tables);
                                                        }}
                                                    >
                                                        {({ loading, error, data }) => {
                                                            if (loading) return <div>Loading...</div>;
                                                            if (error) return <div>Error: {error.message}</div>;

                                                            return (
                                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <Select
                                                                        showSearch
                                                                        // mode="multiple"
                                                                        name="pool_table_id"
                                                                        placeholder="Assign a table from the selected venue"
                                                                        size="large"
                                                                        optionFilterProp="children"
                                                                        // defaultValue={[ 'jack', 'lucy' ]}
                                                                        filterOption={(input, option) => {
                                                                            return (
                                                                                option.props.children.props.children
                                                                                    .toString()
                                                                                    .toLowerCase()
                                                                                    .indexOf(input.toLowerCase()) >= 0
                                                                            );
                                                                        }}
                                                                    >
                                                                        {data.pool_tables
                                                                            .sort((a, b) => {
                                                                                return a.label.localeCompare(b.label, undefined, {
                                                                                    numeric: true,
                                                                                    sensitivity: 'base',
                                                                                });
                                                                            })
                                                                            .map((item, index) => (
                                                                                <Option key={index} value={item.id}>
                                                                                    <Text>{item.label}</Text>
                                                                                </Option>
                                                                            ))}
                                                                    </Select>
                                                                    {/* <Button
                                                                        type="primary"
                                                                        ghost
                                                                        size="large"
                                                                        onClick={handleAddTable}
                                                                        style={{ marginLeft: 10 }}
                                                                    >
                                                                        <Icon type="plus" /> Add Table
                                                                    </Button> */}
                                                                </div>
                                                            );
                                                        }}
                                                    </Query>
                                                </FormItem>
                                            )}

                                            <FormItem name="table_size" label="Table Size" hasFeedback showValidateSuccess>
                                                <Radio.Group name="table_size" defaultValue="8 Foot" size="medium">
                                                    <Radio.Button value="6.5 Foot">6.5 Foot</Radio.Button>
                                                    <Radio.Button value="7 Foot">7 Foot</Radio.Button>
                                                    <Radio.Button value="8 Foot">8 Foot</Radio.Button>
                                                    <Radio.Button value="9 Foot">9 Foot</Radio.Button>
                                                    <Radio.Button value="10 Foot">10 Foot</Radio.Button>
                                                </Radio.Group>
                                            </FormItem>

                                            <FormItem name="is_public" label="Access">
                                                <Radio.Group name="is_public">
                                                    <Radio value={true}>Public - Anyone can view this league and request to join.</Radio>
                                                    <br />
                                                    <Radio value={false}>Private - Only users who have been sent an invite can view or join this league.</Radio>
                                                </Radio.Group>
                                            </FormItem>

                                            <div>
                                                <Text style={styles.error}>{errors.general}</Text>
                                            </div>

                                            <FormItem name="player_id">
                                                <Input name="player_id" type="hidden" />
                                            </FormItem>
                                            <FormItem name="opponent_id">
                                                <Input name="opponent_id" type="hidden" />
                                            </FormItem>

                                            <SubmitButton
                                                size="large"
                                                disabled={Object.keys(errors).length > 0 ? true : false}
                                                type={Object.keys(errors).length > 0 ? 'danger' : 'primary'}
                                                loading={loading}
                                            >
                                                {matchData ? (loading ? 'Updating...' : 'Update') : 'Create'} match
                                            </SubmitButton>
                                            <ResetButton size="large">Clear</ResetButton>

                                            {/* <FormikDebug /> */}

                                            <AddEventModal
                                                {...props}
                                                modalVisible={eventModalVisible}
                                                onModalOk={handleEventModalOk}
                                                onModalCancel={handleEventModalCancel}
                                            />

                                            <AddTableModal
                                                {...props}
                                                matchData={matchData}
                                                tableModalVisible={tableModalVisible}
                                                onTableModalOk={handleTableModalOk}
                                                onTableModalCancel={handleTableModalCancel}
                                            />
                                        </Form>
                                    )}
                                />
                            );
                        }}
                    </Mutation>
                )}
            </Mutation>
            <AddVenueModal {...props} modalVisible={venueModalVisible} onModalOk={handleVenueModalOk} onModalCancel={handleVenueModalCancel} />
        </React.Fragment>
    );
}

export default withRouter(MatchCreateEditForm);
