import React, { useState, useEffect, useRef } from 'react';
import { message, Icon, Tooltip, Button } from 'antd';
import { GET_POST_COMMENTS, GET_USER_POST_COMMENT_LIKES, GET_POST_COMMENT_LIKE_COUNT } from '../data/queries';
import { CREATE_POST_COMMENT_LIKE_MUTATION, DELETE_POST_COMMENT_LIKE_MUTATION } from '../data/mutations';
import { useMutation, useQuery } from '@apollo/react-hooks';
import pluralize from 'pluralize';

export default function LikeCommentButton(props) {
    const { authState, user, post, comment } = props;
    const [likes, setLikes] = useState((comment.likes_aggregate && comment.likes_aggregate.aggregate && comment.likes_aggregate.aggregate.count) || 0);
    const [addCommentLike] = useMutation(CREATE_POST_COMMENT_LIKE_MUTATION);
    const [removeCommentLike] = useMutation(DELETE_POST_COMMENT_LIKE_MUTATION);
    const userCommentLikes = useQuery(GET_USER_POST_COMMENT_LIKES, {
        variables: { user_id: authState && authState.user && authState.user.id, post_id: post.id, comment_id: comment.id },
    });
    const myCommentLikes = userCommentLikes && userCommentLikes.data && userCommentLikes.data.post_likes;

    function like(comment) {
        addCommentLike({
            variables: {
                objects: [
                    {
                        post_id: post.id,
                        user_id: authState && authState.user && authState.user.id,
                        comment_id: comment.id,
                    },
                ],
            },
            notifyOnNetworkStatusChange: true,
            awaitRefetchQueries: true,
            refetchQueries: [
                // {
                //     query: GET_POST_COMMENTS,
                //     variables: { post_id: post.id },
                // },
                {
                    query: GET_USER_POST_COMMENT_LIKES,
                    variables: { post_id: post.id, user_id: authState && authState.user && authState.user.id, comment_id: comment.id },
                },
                {
                    query: GET_POST_COMMENT_LIKE_COUNT,
                    variables: { post_id: post.id, comment_id: comment.id },
                },
            ],
        })
            .then((res) => {
                console.log(res);
                setLikes(likes + 1);
                // setAction('liked');
                message.success('Comment liked');
            })
            .catch((e) => {
                if (e.message && e.message.includes('Uniqueness violation')) {
                    message.error('You already liked this comment.');
                } else {
                    message.error((e && e.message) || JSON.stringify(e));
                }
                console.log(e);
            });
    }

    function unlike(comment) {
        removeCommentLike({
            variables: {
                post_id: post.id,
                user_id: authState && authState.user && authState.user.id,
                comment_id: comment.id,
            },
            notifyOnNetworkStatusChange: true,
            awaitRefetchQueries: true,
            refetchQueries: [
                // {
                //     query: GET_POST_COMMENTS,
                //     variables: { post_id: post.id },
                // },
                {
                    query: GET_USER_POST_COMMENT_LIKES,
                    variables: { post_id: post.id, user_id: authState && authState.user && authState.user.id, comment_id: comment.id },
                },
                {
                    query: GET_POST_COMMENT_LIKE_COUNT,
                    variables: { post_id: post.id, comment_id: comment.id },
                },
            ],
        })
            .then((res) => {
                console.log(res);
                setLikes(likes - 1);
                message.success('Comment unliked');
            })
            .catch((error) => {
                console.log(error);
                message.info('There was an error', error);
                return error;
            });
    }

    return (
        <React.Fragment>
            <Tooltip title={myCommentLikes && myCommentLikes.length > 0 ? 'Unlike' : 'Like'} placement="bottom">
                <Button
                    type="text"
                    shape="circle"
                    onClick={() => {
                        myCommentLikes && myCommentLikes.length > 0 ? unlike(comment) : like(comment);
                    }}
                >
                    <Icon type="heart" theme={myCommentLikes && myCommentLikes.length > 0 ? 'filled' : 'outlined'} />
                </Button>
            </Tooltip>
            <span>
                {likes} {pluralize('Like', likes)}
            </span>
        </React.Fragment>
    );
}
