import React, { useState, useEffect } from 'react';
import { Tag, Button } from 'antd';
import { Query, Mutation } from '@apollo/client/react/components';
import { GET_RANKING_SYSTEMS_QUERY } from './data/queries';

export default function RankingSystemPicker(props) {
    const { onSelected, selected } = props;

    function handleClick(index) {
        onSelected(index);
    }

    return (
        <div>
            <Query query={GET_RANKING_SYSTEMS_QUERY} fetchPolicy="cache-and-network" notifyOnNetworkStatusChange>
                {({ loading, error, data }) => {
                    if (loading) return <div>Loading...</div>;
                    if (error) return <div>Error: {error.message}</div>;

                    const rankingSystems = [
                        {
                            code: 'ALL_RANKINGS',
                            display_name: 'All Rankings',
                        },
                        ...data.ranking_systems,
                        // {
                        // code: 'DIGITAL_POOL_RANKINGS',
                        // display_name: 'Digital Pool Ranking',
                        // },
                        // {
                        //  code: 'DIGITAL_POOL_MENS_RANKINGS',
                        //  display_name: "Digital Pool Men's Ranking",
                        // },
                        // {
                        //  code: 'DIGITAL_POOL_WOMENS_RANKINGS',
                        //  display_name: "Digital Pool Women's Ranking",
                        // },
                    ];

                    return rankingSystems.map((rankingSystem) => {
                        return (
                            <Tag
                                color={selected === rankingSystem.code && '#108ee9'}
                                onClick={() => {
                                    handleClick(rankingSystem.code);
                                }}
                                key={rankingSystem.code}
                                style={{
                                    cursor: 'pointer',
                                    marginTop: 5,
                                    marginBottom: 5,
                                    textTransform: 'uppercase',
                                    paddingLeft: 5,
                                    paddingRight: 5,
                                    paddingTop: 3,
                                    paddingBottom: 3,
                                }}
                            >
                                {rankingSystem.display_name}
                            </Tag>
                        );
                    });
                }}
            </Query>
        </div>
    );
}
