import React, { useState, useEffect, useLayoutEffect } from 'react';
import { message, Badge, Layout, Menu, Dropdown, Icon, Tabs, Button, Avatar, Spin, Typography, Input, Tooltip, Drawer } from 'antd';
import Tappable from 'react-tappable';
import { Link, Route, withRouter } from 'react-router-dom';
// import Sidebar from './Sidebar';
import dpLogoHeader from './assets/img/dp-logo-header.png';
import dpLogoHeaderDark from './assets/img/dp-logo-header-dark.png';
import dplLogoHeader from './assets/img/dpl-logo-header.png';
import leagueManagerHeader from './assets/img/league-manager-logo-header.png';
import tournamentBuilderHeader from './assets/img/tournament-builder-logo-header.png';
import tourBuilderHeader from './assets/img/tour-builder-logo-header.png';
import poolLiveHeader from './assets/img/pool-live-logo-header.png';
import ghostEventManagerHeader from './assets/img/pool-live-logo-header.png';
import dpIcon from './assets/img/dp-icon.png';
import dplIcon from './assets/img/dpl-icon.png';
import leagueManagerIcon from './assets/img/league-manager-icon.png';
import tournamentBuilderIcon from './assets/img/tournament-builder-icon.png';
import tourBuilderIcon from './assets/img/tour-builder-icon.png';
import poolLiveIcon from './assets/img/pool-live-icon.png';
import BreadcrumbContainer from './components/BreadcrumbContainer';
import DPNav from './screens/digitalpool/DPNav';
// import DPProductNav from './screens/digitalpool/DPProductNav';
import DPLNav from './screens/dpl/DPLNav';
import LMNav from './screens/league-manager/LMNav2';
import TBNav from './screens/tournament-builder/TBNav2';
import TRBNav from './screens/tour-builder/TRBNav';
import PLNav from './screens/pool-live/PLNav';
import GENav from './screens/ghost-event-manager/GENav';
import SidebarFooter from './components/SidebarFooter';
import SectionFooter from './components/SectionFooter';
import ScrollToTop from './components/ScrollToTop';

import Headroom from 'react-headroom';
import qs from 'query-string';
// import clearRender from 'clear-render';
import _ from 'lodash';
import { BgColorsOutlined } from '@ant-design/icons';
import './layout.css';
// import { signOut } from './firebase/helpers';

const { Search } = Input;
const { SubMenu } = Menu;
const { Title, Text } = Typography;
const { Header, Sider, Content, Footer } = Layout;

message.config({
    top: 100,
    duration: 2,
    maxCount: 3,
    getContainer: () => document.querySelector('#auth'),
});

const styles = {
    active: { transition: 'all 250ms ease' },
    inactive: {
        // marginRight: 8,
        filter: 'grayscale(100%)',
        opacity: 0.6,
        transition: 'all 250ms ease',
    },
    menuItem: {
        height: 50,
        display: 'flex',
        alignItems: 'center',
        margin: 0,
    },
    menuItemActive: {
        height: 50,
        display: 'flex',
        alignItems: 'center',
        margin: 0,
        backgroundColor: '#0b1d34',
        boxShadow: '0px 2px 10px rgba(0,0,0,0.7)',
    },
};

const defaultProductIconSize = 30;

function AppLayoutMobile(props, context) {
    const { authState, signOut, navType, showFooter, breakpoint, theme, toggleTheme } = props;
    const params = qs.parse(props.location.search, { ignoreQueryPrefix: true });
    const { component: Component, ...rest } = props;

    const showNavigation = params && params.navigation === 'false' ? false : true;
    const urlPath = window.location.pathname;
    const splitPath = urlPath.split('/');
    const defaultUrl = '/';
    const dpIncludes = [
        'home',
        'feeds',
        'dashboard',
        'matches',
        'chat',
        'stats',
        'notifications',
        'friends',
        'billing',
        'leagues',
        'live',
        'tournaments',
        'teams',
        'players',
        'people',
        'venues',
        'events',
        'organizations',
        'register',
        'login',
        'ghost-events',
    ];

    const [hovered, setHovered] = useState();
    const [pinnedNav, setPinnedNav] = useState(false);
    const [visible, setVisible] = useState(false);
    const [current, setCurrent] = useState(
        splitPath[splitPath.length - 1]
            ? _.includes(dpIncludes, splitPath[splitPath.length - 1])
                ? 'digitalpool'
                : splitPath[splitPath.length - 1]
            : 'digitalpool'
    );

    const [currentSub, setCurrentSub] = useState(
        splitPath[splitPath.length - 1]
            ? _.includes(dpIncludes, splitPath[splitPath.length - 1])
                ? 'digitalpool'
                : splitPath[splitPath.length - 1]
            : 'digitalpool'
    );

    useEffect(() => {
        function setDefaults() {
            setCurrent(splitPath[1] ? (_.includes(dpIncludes, splitPath[1]) ? 'digitalpool' : splitPath[1]) : 'digitalpool');
            setCurrentSub(splitPath[1] ? (_.includes(dpIncludes, splitPath[1]) ? splitPath[1] : splitPath[1]) : 'digitalpool');
        }
        return setDefaults();
    }, [splitPath, dpIncludes]);

    // useLayoutEffect(() => {
    // 	const messageEl = document.querySelector('.ant-message');
    // 	console.log(messageEl);
    // 	messageEl.style.left = props.sidebar === false ? '125px' : '25px';
    // });

    const handleClick = (e) => {
        // console.log('click ', e);
        setCurrent(e.key);

        const url = e.key === 'digitalpool' ? defaultUrl : `/${e.key}`;
        props.history.push(url);
    };

    const handleClickSub = (e) => {
        // console.log('click ', e);
        setCurrentSub(e.key);
        onClose();
        const url = e.key === 'digitalpool' ? defaultUrl : `/${e.key}`;
        props.history.push(url);
    };

    function handleNotificationClick(e) {
        if (e.key === 'all') {
            props.history.push('/notifications');
        } else {
            props.history.push('/notifications');
        }
    }

    function navigate(url) {
        setCurrentSub(); // disable footer nav items
        props.history.push(url);
    }

    const handleHovered = (section) => {
        setHovered(section);
    };

    function showDrawer() {
        setVisible(true);
    }

    function onClose() {
        setVisible(false);
    }

    const isActive = (link, args) => {
        const urlPath = window.location.pathname;
        const splitPath = urlPath.split('/');
        const isRootPath = args && args.isRootPath;
        const rootPath = args && args.rootPath;
        const includes = args && args.includes;

        if (urlPath === '/' || isRootPath === true) {
            return urlPath === link;
        } else if (urlPath.indexOf(rootPath) !== -1) {
            return true;
        } else if (includes && includes.length > 0) {
            return _.includes(includes, splitPath[1]);
        } else {
            return _.includes(splitPath, link);
        }
    };

    const renderDigitalPoolTitle = (
        <React.Fragment>
            <img
                src={dpIcon}
                alt="DigitalPool Icon"
                width={24}
                style={isActive('', { includes: dpIncludes }) || current === 'digitalpool' ? styles.active : styles.inactive}
            />
            <span style={{ marginLeft: 10 }}>DigitalPool</span>
        </React.Fragment>
    );

    const renderDigitalPoolLeagueTitle = (
        <React.Fragment>
            <img src={dplIcon} alt="Digital Pool League" width={24} style={isActive('dpl') || current === 'dpl' ? styles.active : styles.inactive} />
            <span style={{ marginLeft: 10 }}>DPL</span>
        </React.Fragment>
    );

    const renderLeagueManagerTitle = (
        <React.Fragment>
            <img
                src={leagueManagerIcon}
                alt="League Manager"
                width={24}
                style={isActive('league-manager') || current === 'league-manager' ? styles.active : styles.inactive}
            />
            <span style={{ marginLeft: 10 }}>League Manager</span>
        </React.Fragment>
    );

    const renderTournamentBuilderTitle = (
        <React.Fragment>
            <img
                src={tournamentBuilderIcon}
                alt="Tournament Director"
                width={24}
                style={isActive('tournament-builder') || current === 'tournament-builder' ? styles.active : styles.inactive}
            />
            <span style={{ marginLeft: 10 }}>Tournament Builder</span>
        </React.Fragment>
    );

    const renderPoolLiveTitle = (
        <React.Fragment>
            <img src={poolLiveIcon} alt="Pool.live" width={24} style={isActive('pool-live') || current === 'pool-live' ? styles.active : styles.inactive} />
            <span style={{ marginLeft: 10 }}>Pool.Live</span>
        </React.Fragment>
    );

    const notificationMenu = (
        <Menu onClick={handleNotificationClick} style={{ width: 200 }}>
            <Menu.ItemGroup title="Notifications">
                {props.notifications && props.notifications.length > 0 ? (
                    props.notifications.map((notification, index) => (
                        <Menu.Item key={index}>
                            <Icon type="bell" />
                            {notification.title}
                        </Menu.Item>
                    ))
                ) : (
                    <div style={{ padding: '8px 12px' }}>0 new notifications</div>
                )}
            </Menu.ItemGroup>
            <Menu.Divider />
            <Menu.Item key="all">View all</Menu.Item>
        </Menu>
    );

    const renderGhostEventManagerTitle = (
        <React.Fragment>
            <img
                src={poolLiveIcon}
                alt="Ghost Event Manager"
                width={24}
                style={isActive('ghost-event-manager') || current === 'ghost-event-manager' ? styles.active : styles.inactive}
            />
            <span style={{ marginLeft: 10 }}>Ghost Games</span>
        </React.Fragment>
    );

    const profileMenu = (
        <Menu style={{ width: 200 }}>
            <Menu.Item
                key="profile"
                onClick={() => {
                    props.history.push('/profile');
                }}
            >
                <Icon type="user" /> Profile
            </Menu.Item>
            {authState && authState.user && authState.user.id === 2472 && (
                <Menu.Item
                    key="personal"
                    onClick={() => {
                        props.history.push('/karl-boyes');
                    }}
                >
                    <Icon type="solution" /> Pro Site
                </Menu.Item>
            )}

            <Menu.Item
                key="account"
                onClick={() => {
                    props.history.push('/settings');
                }}
            >
                <Icon type="setting" /> Account Settings
            </Menu.Item>
            <Menu.Item
                key="password"
                onClick={() => {
                    props.history.push('/change-password');
                }}
            >
                <Icon type="lock" /> Change Password
            </Menu.Item>
            {/* <Menu.Item key="lang">
				<Icon type="global" /> Language
			</Menu.Item> */}
            {/* <Menu.Item key="theme">
				<Icon type="bg-colors" /> Theme
			</Menu.Item> */}
            <Menu.Divider />
            {authState && authState.status === 'in' ? (
                <Menu.Item
                    key="3"
                    onClick={() => {
                        signOut().then(() => {
                            props.history.push('/');
                        });
                    }}
                >
                    <Icon type="logout" /> Logout
                </Menu.Item>
            ) : (
                <Menu.Item key="3" onClick={() => props.history.push('/login')}>
                    <Icon type="login" /> Login
                </Menu.Item>
            )}
        </Menu>
    );

    const profileMenuSidebar = (
        <Menu style={{ width: 200 }}>
            <Menu.Item
                key="profile"
                onClick={() => {
                    props.history.push('/profile');
                }}
            >
                <Icon type="user" /> Profile
            </Menu.Item>
            <Menu.Item
                key="account"
                onClick={() => {
                    props.history.push('/settings');
                }}
            >
                <Icon type="setting" /> Account Settings
            </Menu.Item>
            <Menu.Item
                key="password"
                onClick={() => {
                    props.history.push('/change-password');
                }}
            >
                <Icon type="lock" /> Change Password
            </Menu.Item>
            {/* <Menu.Item key="billing">
				<Icon type="dollar" /> Payments
			</Menu.Item> */}
            {/* <Menu.Item key="theme">
				<Icon type="bg-colors" /> Theme
			</Menu.Item> */}
            <Menu.Divider />
            {authState && authState.status === 'in' ? (
                <Menu.Item
                    key="3"
                    onClick={() => {
                        signOut().then(() => {
                            props.history.push('/');
                        });
                    }}
                >
                    <Icon type="logout" /> Logout
                </Menu.Item>
            ) : (
                <Menu.Item key="3" onClick={() => props.history.push('/login')}>
                    <Icon type="login" /> Login
                </Menu.Item>
            )}
        </Menu>
    );

    return (
        <Route
            {...rest}
            render={(matchProps) => (
                <Layout id="app-container" className={`mobile ${theme && theme.name}`} style={{ height: '100%' }}>
                    {authState.status !== 'in' && showNavigation !== false && (
                        <Headroom
                            // Show top menu on mobile devices
                            // onPin={() => console.log('pinned mobile')}
                            // onUnpin={() => console.log('unpinned mobile')}
                            downTolerance={100}
                            // parent={() => document.body}
                        >
                            <Header
                                className="ant-layout-header-dark"
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    padding: '0px 16px 0px 16px',
                                    // width: '97%',
                                    width: authState.status !== 'in' ? '100%' : 200,
                                    position: 'relative',
                                    zIndex: 10,
                                    boxShadow: 'rgba(0, 0, 0, 0.3) 0px 2px 10px 0px',
                                }}
                            >
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <div>
                                            <Link to={_.includes(dpIncludes, splitPath[1]) ? defaultUrl : `/${splitPath[1]}`}>
                                                <img
                                                    src={
                                                        isActive('', { includes: dpIncludes }) || current === 'digitalpool'
                                                            ? breakpoint === 'mobile'
                                                                ? dpIcon
                                                                : dpLogoHeader
                                                            : isActive('dpl') || current === 'dpl'
                                                            ? dplLogoHeader
                                                            : isActive('league-manager') || current === 'league-manager'
                                                            ? leagueManagerHeader
                                                            : isActive('tournament-builder') || current === 'tournament-builder'
                                                            ? tournamentBuilderHeader
                                                            : isActive('tour-builder') || current === 'tour-builder'
                                                            ? tourBuilderHeader
                                                            : isActive('pool-live') || current === 'pool-live'
                                                            ? poolLiveHeader
                                                            : dpIcon
                                                    }
                                                    alt="Logo"
                                                    height={50}
                                                />
                                            </Link>
                                        </div>

                                        {authState.status !== 'in' && (
                                            <Menu onClick={handleClick} selectedkeys={[current]} mode="horizontal" theme="dark" style={{ lineHeight: '64px' }}>
                                                <SubMenu
                                                    title={
                                                        <span className="submenu-title-wrapper">
                                                            Apps
                                                            <Icon type="caret-down" style={{ marginLeft: 5, width: 6, height: 6 }} />
                                                        </span>
                                                    }
                                                    style={{ padding: 0, marginLeft: 10 }}
                                                >
                                                    <Menu.Item key="digitalpool">{renderDigitalPoolTitle}</Menu.Item>
                                                    <Menu.Item key="dpl">{renderDigitalPoolLeagueTitle}</Menu.Item>
                                                    <Menu.Item key="league-manager">{renderLeagueManagerTitle}</Menu.Item>
                                                    <Menu.Item key="tournament-builder">{renderTournamentBuilderTitle}</Menu.Item>
                                                    <Menu.Item key="ghost-event-manager">{renderGhostEventManagerTitle}</Menu.Item>
                                                    <Menu.Item key="pool-live">{renderPoolLiveTitle}</Menu.Item>
                                                </SubMenu>
                                            </Menu>
                                        )}
                                    </div>
                                </div>

                                {authState && authState.status !== 'in' && (
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <Tooltip placement="left" title={theme && theme.name === 'dark' ? 'Dark mode' : 'Light mode'}>
                                            <Button
                                                type="link"
                                                color="default"
                                                ghost
                                                onClick={() => {
                                                    toggleTheme();
                                                }}
                                            >
                                                {theme && theme.name === 'dark' ? (
                                                    <i className="icon ion-md-moon" style={{ fontSize: 18 }} />
                                                ) : (
                                                    <i className="icon ion-md-sunny" style={{ fontSize: 18 }} />
                                                )}
                                            </Button>
                                        </Tooltip>
                                        <Button type="link" size="small" ghost onClick={() => props.history.push('/login')}>
                                            LOGIN
                                        </Button>
                                        <Button type="link" size="small" ghost onClick={() => props.history.push('/register')} style={{ marginLeft: 0 }}>
                                            SIGN UP
                                        </Button>
                                    </div>
                                )}
                            </Header>
                        </Headroom>
                    )}
                    <Layout style={{ height: '100%' }}>
                        {props.authState.status === 'in' && props.sidebar !== false && (
                            <Drawer
                                title={false}
                                placement="left"
                                closable
                                onClose={onClose}
                                visible={visible}
                                bodyStyle={{ padding: 0, height: '100%' }}
                                style={{ padding: 0 }}
                            >
                                <div
                                    width={58}
                                    className="products-menu"
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        height: '100%',
                                        justifyContent: 'space-between',
                                        backgroundColor: '#081629',
                                        // background: 'linear-gradient(180deg, #0c1e35, #050c17)',
                                        boxShadow: 'inset -5px 0 10px -2px rgba(0,0,0,.4)',
                                        alignItems: 'center',
                                        paddingBottom: 14,
                                    }}
                                >
                                    <Menu
                                        // onClick={handleClick}
                                        selectedkeys={[`${current}-mini`]}
                                        mode="vertical"
                                        theme={theme.name}
                                        style={{ marginTop: 8, lineHeight: '64px' }}
                                    >
                                        <Menu.Item
                                            key="digitalpool-mini"
                                            // onMouseEnter={() => handleHovered('digitalpool')}
                                            // onMouseLeave={() => handleHovered('')}
                                            onClick={() => navigate('/')}
                                            style={
                                                isActive('', { includes: dpIncludes }) || current === 'digitalpool' || hovered === 'digitalpool'
                                                    ? styles.menuItemActive
                                                    : styles.menuItem
                                            }
                                        >
                                            <Tooltip placement="right" title="DigitalPool">
                                                <img
                                                    src={dpIcon}
                                                    alt="DigitalPool Icon"
                                                    width={defaultProductIconSize}
                                                    height={defaultProductIconSize}
                                                    style={
                                                        isActive('', { includes: dpIncludes }) || current === 'digitalpool' || hovered === 'digitalpool'
                                                            ? styles.active
                                                            : styles.inactive
                                                    }
                                                />
                                            </Tooltip>
                                        </Menu.Item>

                                        <Menu.Item
                                            key="dpl-mini"
                                            // onMouseEnter={() => handleHovered('dpl')}
                                            // onMouseLeave={() => handleHovered('')}
                                            onClick={() => navigate('/dpl')}
                                            style={isActive('dpl') || current === 'dpl' || hovered === 'dpl' ? styles.menuItemActive : styles.menuItem}
                                        >
                                            <Tooltip placement="right" title="Digital Pool League">
                                                <img
                                                    src={dplIcon}
                                                    alt="Digital Pool League"
                                                    width={defaultProductIconSize}
                                                    height={defaultProductIconSize}
                                                    style={isActive('dpl') || current === 'dpl' || hovered === 'dpl' ? styles.active : styles.inactive}
                                                />
                                            </Tooltip>
                                        </Menu.Item>

                                        <Menu.Item
                                            key="league-manager-mini"
                                            // onMouseEnter={() => handleHovered('league-manager')}
                                            // onMouseLeave={() => handleHovered('')}
                                            onClick={() => navigate('/league-manager')}
                                            style={
                                                isActive('league-manager') || current === 'league-manager' || hovered === 'league-manager'
                                                    ? styles.menuItemActive
                                                    : styles.menuItem
                                            }
                                        >
                                            <Tooltip placement="right" title="League Manager">
                                                <img
                                                    src={leagueManagerIcon}
                                                    alt="League Manager"
                                                    width={defaultProductIconSize}
                                                    height={defaultProductIconSize}
                                                    style={
                                                        isActive('league-manager') || current === 'league-manager' || hovered === 'league-manager'
                                                            ? styles.active
                                                            : styles.inactive
                                                    }
                                                />
                                            </Tooltip>
                                        </Menu.Item>

                                        <Menu.Item
                                            key="tournament-builder-mini"
                                            // onMouseEnter={() => handleHovered('tournament-builder')}
                                            // onMouseLeave={() => handleHovered('')}
                                            onClick={() => navigate('/tournament-builder')}
                                            style={
                                                isActive('tournament-builder') || current === 'tournament-builder' || hovered === 'tournament-builder'
                                                    ? styles.menuItemActive
                                                    : styles.menuItem
                                            }
                                        >
                                            <Tooltip placement="right" title="Tournament Builder">
                                                <img
                                                    src={tournamentBuilderIcon}
                                                    alt="Tournament Builder"
                                                    width={defaultProductIconSize}
                                                    height={defaultProductIconSize}
                                                    style={
                                                        isActive('tournament-builder') || current === 'tournament-builder' || hovered === 'tournament-builder'
                                                            ? styles.active
                                                            : styles.inactive
                                                    }
                                                />
                                            </Tooltip>
                                        </Menu.Item>

                                        <Menu.Item
                                            key="tour-builder-mini"
                                            // onMouseEnter={() => handleHovered('tour-builder')}
                                            // onMouseLeave={() => handleHovered('')}
                                            onClick={() => navigate('/tour-builder')}
                                            style={
                                                isActive('tour-builder') || current === 'tour-builder' || hovered === 'tour-builder'
                                                    ? styles.menuItemActive
                                                    : styles.menuItem
                                            }
                                            // disabled
                                        >
                                            <Tooltip placement="right" title="Tour Builder">
                                                <img
                                                    src={tourBuilderIcon}
                                                    alt="Tour Builder"
                                                    width={defaultProductIconSize}
                                                    height={defaultProductIconSize}
                                                    style={
                                                        isActive('tour-builder') || current === 'tour-builder' || hovered === 'tour-builder'
                                                            ? styles.active
                                                            : styles.inactive
                                                    }
                                                />
                                            </Tooltip>
                                        </Menu.Item>

                                        <Menu.Item
                                            key="pool-live-mini"
                                            // onMouseEnter={() => handleHovered('pool-live')}
                                            // onMouseLeave={() => handleHovered('')}
                                            onClick={() => navigate('/pool-live')}
                                            style={
                                                isActive('pool-live') || current === 'pool-live' || hovered === 'pool-live'
                                                    ? styles.menuItemActive
                                                    : styles.menuItem
                                            }
                                            // disabled
                                        >
                                            <Tooltip placement="right" title="Pool.live">
                                                <img
                                                    src={poolLiveIcon}
                                                    alt="Pool.live"
                                                    width={defaultProductIconSize}
                                                    height={defaultProductIconSize}
                                                    style={
                                                        isActive('pool-live') || current === 'pool-live' || hovered === 'pool-live'
                                                            ? styles.active
                                                            : styles.inactive
                                                    }
                                                />
                                            </Tooltip>
                                        </Menu.Item>
                                        {/* <Menu.Item
											key="ghost-event-manager-mini"
											// onMouseEnter={() => handleHovered('pool-live')}
											// onMouseLeave={() => handleHovered('')}
											onClick={() => navigate('/ghost-event-manager')}
											style={
												isActive('ghost-event-manager') ||
												current === 'ghost-event-manager' ||
												hovered === 'ghost-event-manager' ? (
													styles.menuItemActive
												) : (
													styles.menuItem
												)
											}
											// disabled
										>
											<Tooltip placement="right" title="Ghost Event Manager">
												<img
													src={poolLiveIcon}
													alt="Ghost Event Manager"
													width={defaultProductIconSize}
													height={defaultProductIconSize}
													style={
														isActive('ghost-event-manager') ||
														current === 'ghost-event-manager' ||
														hovered === 'ghost-event-manager' ? (
															styles.active
														) : (
															styles.inactive
														)
													}
												/>
											</Tooltip>
										</Menu.Item> */}
                                    </Menu>

                                    <div
                                        style={{
                                            textAlign: 'center',
                                        }}
                                    >
                                        <Menu
                                            mode="vertical"
                                            // theme={getTheme()}
                                            onClick={handleClickSub}
                                            // style={{ width: 256 }}
                                            // defaultOpenKeys={[ 'sub1' ]}
                                            selectedKeys={[currentSub]}
                                            style={{
                                                textAlign: 'center',
                                            }}
                                        >
                                            {/* <Menu.Item
														key="dashboard"
														disabled={authState && authState.status === 'in' ? false : true}
													>
														<Tooltip placement="right" title="My Dashboard">
															<Icon
																type="dashboard"
																style={{ margin: 0, fontSize: 18 }}
															/>
														</Tooltip>
													</Menu.Item> */}
                                            <Menu.Item key="chat" disabled={authState && authState.status === 'in' ? false : true}>
                                                <Tooltip placement="right" title="My Messages">
                                                    <Badge dot offset={[0, -4]}>
                                                        <Icon type="message" style={{ margin: 0, fontSize: 18, color: 'rgba(255, 255, 255, 0.65)' }} />
                                                    </Badge>
                                                </Tooltip>
                                            </Menu.Item>
                                            <Menu.Item key="stats" disabled={authState && authState.status === 'in' ? false : true}>
                                                <Tooltip placement="right" title="My Stats">
                                                    <Icon type="bar-chart" style={{ margin: 0, fontSize: 18, color: 'rgba(255, 255, 255, 0.65)' }} />
                                                </Tooltip>
                                            </Menu.Item>

                                            <Menu.Item key="notifications" disabled={authState && authState.status === 'in' ? false : true}>
                                                <Tooltip placement="right" title="My Notifications">
                                                    <Badge count={props.notificationCount}>
                                                        <Icon type="bell" style={{ margin: 0, fontSize: 18, color: 'rgba(255, 255, 255, 0.65)' }} />
                                                    </Badge>
                                                </Tooltip>
                                            </Menu.Item>

                                            <Menu.Item key="friends" disabled={authState && authState.status === 'in' ? false : true}>
                                                <Tooltip placement="right" title="My Friends">
                                                    <Icon type="team" style={{ margin: 0, fontSize: 18, color: 'rgba(255, 255, 255, 0.65)' }} />
                                                </Tooltip>
                                            </Menu.Item>
                                            {/* <Menu.Item
											key="billing"
											disabled={authState && authState.status === 'in' ? false : true}
										>
											<Tooltip placement="right" title="My Payments">
												<Icon type="dollar" style={{ margin: 0, fontSize: 18 }} />
											</Tooltip>
										</Menu.Item> */}
                                            {/* <Menu.Item key="settings">
										<Icon type="setting" style={{ margin: 0, fontSize: 18 }} />
									</Menu.Item>
									<Menu.Item key="register" onClick={() => props.history.push('/register')}>
										<Icon type="poweroff" style={{ margin: 0, fontSize: 18 }} />
									</Menu.Item> */}
                                        </Menu>
                                        {/* <Dropdown overlay={profileMenuSidebar} trigger={['click']} placement="bottomLeft">
                                            <Tooltip placement="right" title="My Account">
                                                <Avatar
                                                    size={30}
                                                    style={{
                                                        color: '#fff',
                                                        cursor: 'pointer',
                                                        backgroundColor: '#1890ff',
                                                    }}
                                                    src={props.authState && props.authState.user && props.authState.user.photoURL}
                                                >
                                                    {(props.authState &&
                                                        props.authState.user &&
                                                        props.authState.user.displayName &&
                                                        `${props.authState.user.displayName.split(' ')[0].slice(0, 1)}${props.authState.user.displayName
                                                            .split(' ')[1]
                                                            .slice(0, 1)}`) ||
                                                        '?'}
                                                </Avatar>
                                            </Tooltip>
                                        </Dropdown> */}
                                    </div>
                                </div>

                                <Sider
                                    theme="dark"
                                    // width={'calc(100% - 58px)'}
                                    style={{
                                        // marginTop: 64,
                                        boxShadow: '0 0 10px 0px rgba(0,21,41,.35)',
                                        zIndex: 11,
                                        height: '100%',
                                        marginLeft: 58,
                                    }}
                                >
                                    <Header
                                        className="header ant-layout-header-dark"
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                            padding: '0px 16px 0px 16px',
                                            width: '100%',
                                            boxShadow: 'none',
                                        }}
                                    >
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            {/* <div className={authState && authState.status === 'in' ? 'logo' : 'logo-centered'}> */}
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <div className="logo">
                                                    {authState.status !== 'in' ? (
                                                        <Link to={_.includes(dpIncludes, splitPath[1]) ? defaultUrl : `/${splitPath[1]}`}>
                                                            <img
                                                                src={
                                                                    isActive('', {
                                                                        includes: dpIncludes,
                                                                    }) || current === 'digitalpool'
                                                                        ? dpLogoHeader
                                                                        : isActive('dpl') || current === 'dpl'
                                                                        ? dplLogoHeader
                                                                        : isActive('league-manager') || current === 'league-manager'
                                                                        ? leagueManagerHeader
                                                                        : isActive('tournament-builder') || current === 'tournament-builder'
                                                                        ? tournamentBuilderHeader
                                                                        : isActive('tour-builder') || current === 'tour-builder'
                                                                        ? tourBuilderHeader
                                                                        : isActive('pool-live') || current === 'pool-live'
                                                                        ? poolLiveHeader
                                                                        : dpLogoHeader
                                                                }
                                                                alt="Logo"
                                                                width="100%"
                                                            />
                                                        </Link>
                                                    ) : authState.status === 'in' ? (
                                                        <Link to={_.includes(dpIncludes, splitPath[1]) ? defaultUrl : `/${splitPath[1]}`}>
                                                            <img
                                                                src={
                                                                    isActive('', {
                                                                        includes: dpIncludes,
                                                                    }) || current === 'digitalpool'
                                                                        ? dpLogoHeader
                                                                        : isActive('dpl') || current === 'dpl'
                                                                        ? dplLogoHeader
                                                                        : isActive('league-manager') || current === 'league-manager'
                                                                        ? leagueManagerHeader
                                                                        : isActive('tournament-builder') || current === 'tournament-builder'
                                                                        ? tournamentBuilderHeader
                                                                        : isActive('tour-builder') || current === 'tour-builder'
                                                                        ? tourBuilderHeader
                                                                        : isActive('pool-live') || current === 'pool-live'
                                                                        ? poolLiveHeader
                                                                        : dpLogoHeader
                                                                }
                                                                alt="Logo"
                                                                width="100%"
                                                            />
                                                        </Link>
                                                    ) : null}
                                                </div>

                                                {authState.status !== 'in' && (
                                                    <Menu
                                                        onClick={handleClick}
                                                        selectedkeys={[current]}
                                                        mode="horizontal"
                                                        theme={theme.name}
                                                        style={{ lineHeight: '64px' }}
                                                    >
                                                        <SubMenu
                                                            title={
                                                                <span className="submenu-title-wrapper">
                                                                    {/* <Icon type="appstore" /> */}
                                                                    Apps
                                                                    <Icon
                                                                        type="caret-down"
                                                                        style={{
                                                                            marginLeft: 5,
                                                                            width: 6,
                                                                            height: 6,
                                                                        }}
                                                                    />
                                                                </span>
                                                            }
                                                            style={{ padding: 0 }}
                                                        >
                                                            <Menu.Item key="digitalpool">{renderDigitalPoolTitle}</Menu.Item>
                                                            <Menu.Item key="dpl">{renderDigitalPoolLeagueTitle}</Menu.Item>
                                                            <Menu.Item key="league-manager">{renderLeagueManagerTitle}</Menu.Item>
                                                            <Menu.Item key="tournament-builder">{renderTournamentBuilderTitle}</Menu.Item>
                                                            <Menu.Item key="pool-live">{renderPoolLiveTitle}</Menu.Item>
                                                        </SubMenu>
                                                    </Menu>
                                                )}
                                            </div>
                                        </div>

                                        {/* {authState.status !== 'in' &&
												!isActive('dpl') && (
													<Search
														placeholder="Search"
														size="large"
														theme={theme}
														// shape="rounded"
														onSearch={(value) => console.log(value)}
														style={{
															width: 300,
															position: 'absolute',
															left: `calc(50%)`,
															transform: 'translateX(-50%)'
														}}
													/>
												)} */}

                                        {authState && authState.status !== 'in' && (
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <Button type="link" size="small" ghost onClick={() => props.history.push('/login')}>
                                                    Log In
                                                </Button>
                                                <Button type="link" size="small" ghost onClick={() => props.history.push('/register')}>
                                                    Sign Up
                                                </Button>
                                            </div>
                                        )}
                                    </Header>

                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'space-between',
                                            height: 'calc(100% - 64px)',
                                            // overflowY: 'auto',
                                            zIndex: 1,
                                            // background: 'linear-gradient(180deg, #474a5e, #292c39)'
                                        }}
                                    >
                                        {isActive('', { includes: dpIncludes }) || current === 'digitalpool' ? (
                                            <DPNav {...props} onClicked={onClose} />
                                        ) : isActive('dpl') || current === 'dpl' ? (
                                            <DPLNav {...props} onClicked={onClose} />
                                        ) : isActive('league-manager') || current === 'league-manager' ? (
                                            <LMNav {...props} onClicked={onClose} />
                                        ) : isActive('tournament-builder') || current === 'tournament-builder' ? (
                                            <TBNav {...props} onClicked={onClose} />
                                        ) : isActive('tour-builder') || current === 'tour-builder' ? (
                                            <TRBNav {...props} onClicked={onClose} />
                                        ) : isActive('pool-live') || current === 'pool-live' ? (
                                            <PLNav {...props} onClicked={onClose} />
                                        ) : isActive('ghost-event-manager') || current === 'ghost-event-manager' ? (
                                            <GENav {...props} onClicked={onClose} />
                                        ) : (
                                            <DPNav {...props} onClicked={onClose} />
                                        )}

                                        <SidebarFooter {...props} toggleNavType={props.toggleNavType} navType="mobile" />
                                    </div>
                                </Sider>
                            </Drawer>
                        )}

                        <Layout>
                            <Content
                                // style={{ marginTop: props.authState.status === 'in' ? 0 : 64 }}
                                className={current}
                                style={
                                    current === 'tournament-builder' && authState.status === 'in'
                                        ? { height: '100%', overflowX: 'hidden' }
                                        : { height: '100%', overflowX: 'hidden' }
                                }
                            >
                                {authState && authState.status === 'in' && showNavigation !== false ? (
                                    <React.Fragment>
                                        {/* <Headroom
												// onPin={() => {
												// 	setPinnedNav(true);
												// 	console.log('pinned mobile');
												// }}
												// onUnpin={() => {
												// 	setPinnedNav(false);
												// 	console.log('unpinned mobile');
												// }}
												// parent={() => document.body}
												downTolerance={0}
											> */}
                                        <Header
                                            className={authState.status !== 'in' ? 'header ant-layout-header-light' : 'header ant-layout-header-dark'}
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                                position: 'relative',
                                                padding: '0px 16px 0px 16px',
                                                width: '100%',
                                                // width: '96%',
                                                // borderRadius: '0px 0px 10px 10px'
                                                // width: '100%'
                                                // zIndex: 100
                                                // boxShadow: '0 8px 20px 0 rgba(218,224,235,.6)'
                                                // boxShadow: 'rgba(0, 0, 0, 0.3) 0px 2px 10px 0px'
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <Button
                                                    // size="medium"
                                                    type="default"
                                                    onClick={showDrawer}
                                                    style={{
                                                        border: 'none',
                                                        backgroundColor: 'rgba(255,255,255,0.1)',
                                                        marginRight: 20,
                                                    }}
                                                >
                                                    <Icon type="menu" style={{ color: '#fff' }} />
                                                </Button>
                                                {/* <BreadcrumbContainer theme={theme} /> */}
                                                {/* <Title
														level={4}
														style={{
															margin: 0,
															fontSize: 16,
															fontWeight: 700,
															textTransform: 'uppercase',
															color: '#fff'
														}}
													>
														{props.title}
													</Title> */}
                                            </div>
                                            <div
                                                style={{
                                                    position: 'absolute',
                                                    left: '50%',
                                                    transform: 'translateX(-50%)',
                                                }}
                                            >
                                                <div className="logo">
                                                    <Link to={_.includes(dpIncludes, splitPath[1]) ? defaultUrl : `/${splitPath[1]}`}>
                                                        <img
                                                            src={
                                                                isActive('', { includes: dpIncludes }) || current === 'digitalpool'
                                                                    ? breakpoint === 'mobile'
                                                                        ? dpIcon
                                                                        : dpLogoHeader
                                                                    : isActive('dpl') || current === 'dpl'
                                                                    ? dplLogoHeader
                                                                    : isActive('league-manager') || current === 'league-manager'
                                                                    ? leagueManagerHeader
                                                                    : isActive('tournament-builder') || current === 'tournament-builder'
                                                                    ? tournamentBuilderHeader
                                                                    : isActive('tour-builder') || current === 'tour-builder'
                                                                    ? tourBuilderHeader
                                                                    : isActive('pool-live') || current === 'pool-live'
                                                                    ? poolLiveHeader
                                                                    : breakpoint === 'mobile'
                                                                    ? dpIcon
                                                                    : dpLogoHeader
                                                            }
                                                            alt="Logo"
                                                            style={breakpoint === 'mobile' ? { height: 50 } : { width: '100%' }}
                                                        />
                                                    </Link>
                                                </div>
                                            </div>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <Tooltip placement="left" title={theme && theme.name === 'dark' ? 'Dark mode' : 'Light mode'}>
                                                    <Button
                                                        type="link"
                                                        color="default"
                                                        ghost
                                                        onClick={() => {
                                                            toggleTheme();
                                                        }}
                                                    >
                                                        {theme && theme.name === 'dark' ? (
                                                            <i className="icon ion-md-moon" style={{ fontSize: 18 }} />
                                                        ) : (
                                                            <i className="icon ion-md-sunny" style={{ fontSize: 18 }} />
                                                        )}
                                                    </Button>
                                                </Tooltip>
                                                <Dropdown overlay={notificationMenu} trigger={['hover']}>
                                                    <Button type="link" color="default" ghost style={{ marginRight: 10 }}>
                                                        <Badge count={props.notificationCount}>
                                                            <Icon type="bell" theme="filled" style={{ fontSize: 18 }} />
                                                        </Badge>
                                                    </Button>
                                                </Dropdown>

                                                <Dropdown overlay={profileMenu} trigger={['hover']}>
                                                    <Avatar
                                                        size={30}
                                                        style={{
                                                            color: '#fff',
                                                            cursor: 'pointer',
                                                            border: '1px solid #1890ff',
                                                            padding: 1,
                                                            borderRadius: '50%',
                                                            overflow: 'hidden',
                                                        }}
                                                        src={
                                                            props.authState &&
                                                            props.authState.user &&
                                                            (props.authState.user.avatar || props.authState.user.photoURL)
                                                        }
                                                    >
                                                        {(props.authState &&
                                                            props.authState.user &&
                                                            props.authState.user.displayName &&
                                                            `${props.authState.user.displayName.split(' ')[0].slice(0, 1)}${props.authState.user.displayName
                                                                .split(' ')[1]
                                                                .slice(0, 1)}`) ||
                                                            '?'}
                                                    </Avatar>
                                                </Dropdown>
                                            </div>
                                        </Header>
                                        {/* </Headroom> */}
                                    </React.Fragment>
                                ) : null}

                                {/* <div style={{ marginTop: 64 }} /> */}

                                <Component
                                    {...matchProps}
                                    {...rest}
                                    // pinned={pinnedNav}
                                    // theme={isActive('dpl') ? 'dark' : 'light'}
                                    theme={theme}
                                />

                                {current !== 'tournament-builder' && current !== 'league-manager' && showFooter !== false && <SectionFooter theme={theme} />}
                            </Content>
                        </Layout>
                    </Layout>
                    <div
                        style={{
                            position: 'absolute',
                            top: -150,
                            left: 0,
                            right: 0,
                            pointerEvents: 'none',
                            height: 600,
                            mixBlendMode: 'color',
                            background: 'radial-gradient(50% 50% at 50% 50%, rgb(252, 7, 125) 0%, rgba(255, 255, 255, 0) 100%)',
                            transform: 'translateY(-150vh)',
                            maxWidth: '100vw !important',
                        }}
                    />
                </Layout>
            )}
        />
    );
}

export default withRouter(AppLayoutMobile);
