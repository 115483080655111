import React, { useState } from 'react';
import { message, Button, Modal, Typography, Avatar, Tabs, AutoComplete } from 'antd';
import { withRouter, Link } from 'react-router-dom';
import { Query, Subscription } from '@apollo/client/react/components';
import { GET_TOURNAMENT_BRACKET_MATCH_QUERY } from '../data/queries';
import useBreakpoint from 'use-breakpoint';
import { defaultBreakpoints } from '../../../../utils/defaultBreakpoints';
import CircularLoader from '../../../../components/CircularLoader';
import TableScoreCard from '../TableScoreCard';
import moment from 'moment-timezone';
import momentDurationFormatSetup from 'moment-duration-format';
momentDurationFormatSetup(moment);

const { Title, Text } = Typography;

function MatchInfoModal(props) {
    const { authState, modalVisible, currentMatch, tournament, tournamentID, onMatchModalOk, onMatchModalCancel, theme } = props;
    const [loading, setLoading] = useState(false);
    const { breakpoint, maxWidth, minWidth } = useBreakpoint(defaultBreakpoints);

    console.log({
        currentMatch,
        tournamentID,
    });

    let duration;
    let scheduledT;
    let startT;
    var zone_name = moment.tz.guess();
    var timezone = moment.tz(zone_name).zoneAbbr();

    if (currentMatch) {
        scheduledT = moment.utc(currentMatch.scheduled_time, 'HH:mm a');
        startT = moment.utc(currentMatch.start_time, 'HH:mm a');

        if (currentMatch.start_time && currentMatch.end_time) {
            var start = moment(currentMatch.start_time);
            var end = moment(currentMatch.end_time);
            duration = moment.duration(end.diff(start, 'milliseconds')).format('h [hrs] m [min]');
        } else {
            // duration = moment.duration(moment.utc().diff(match.start_time, 'milliseconds')).format('h [hrs] m [min]');
            // duration = moment.duration(moment.utc().diff(match.start_time, 'milliseconds')).format('h [hours] m [minutes]');
            duration = currentMatch.start_time ? moment(currentMatch.start_time).fromNow() : '0 mins';
        }
    }

    return (
        <Modal
            title={
                <Text style={{ fontWeight: 700 }}>
                    Match{' '}
                    {currentMatch && currentMatch.challenger1_name && currentMatch.challenger2_name
                        ? `${currentMatch.match_number} - ${currentMatch.challenger1_name ? currentMatch.challenger1_name : 'N/A'} vs. 
					${currentMatch.challenger2_name ? currentMatch.challenger2_name : 'N/A'}`
                        : currentMatch.match_number}
                </Text>
            }
            getContainer={() => document.body}
            visible={modalVisible}
            onOk={onMatchModalOk}
            onCancel={onMatchModalCancel}
            // enableReinitialize
            centered
            // style={{
            // 	top: '50%',
            // 	// left: isWidthDown('xs', width) ? 0 : -120,
            // 	transform: 'translateY(-50%)'
            // }}
            transitionName="fade"
            bodyStyle={{ padding: 0 }}
            // transitionName="none"
            maskTransitionName="none"
            destroyOnClose={true}
            footer={null}
            // footer={[
            //     <Button key="cancel" onClick={onMatchModalCancel}>
            //         Close
            //     </Button>,
            // ]}
        >
            {currentMatch && (
                <React.Fragment>
                    <div style={{ maxWidth: 600 }}>
                        <TableScoreCard
                            title={
                                <React.Fragment>
                                    {currentMatch.status !== 'COMPLETED' &&
                                    currentMatch.scheduled_time &&
                                    currentMatch.start_time &&
                                    moment(startT).isAfter(scheduledT) ? (
                                        <React.Fragment>
                                            <Text style={{ color: theme && theme.bracket && theme.bracket.panels && theme.bracket.panels.textColor }}>
                                                Scheduled: {moment(currentMatch.scheduled_time).calendar()} {timezone}
                                            </Text>
                                        </React.Fragment>
                                    ) : currentMatch.start_time && !currentMatch.end_time ? (
                                        <React.Fragment>
                                            <Text style={{ color: theme && theme.bracket && theme.bracket.panels && theme.bracket.panels.textColor }}>
                                                Started: {moment.utc(currentMatch.start_time).local().format('lll')} {timezone}
                                            </Text>
                                        </React.Fragment>
                                    ) : currentMatch.end_time ? (
                                        <React.Fragment>
                                            <Text style={{ color: theme && theme.bracket && theme.bracket.panels && theme.bracket.panels.textColor }}>
                                                Ended: {moment.utc(currentMatch.end_time).local().format('lll')} {timezone}
                                            </Text>
                                            <Text
                                                style={{
                                                    color: currentMatch.end_time
                                                        ? theme && theme.bracket && theme.bracket.panels && theme.bracket.panels.textColor
                                                        : '#198fff',
                                                }}
                                            ></Text>
                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment>
                                            {currentMatch && currentMatch.tournament_bracket_projections && currentMatch.tournament_bracket_projections[0] && (
                                                <Text style={{ color: theme && theme.bracket && theme.bracket.panels && theme.bracket.panels.textColor }}>
                                                    Projected:{' '}
                                                    {`${moment
                                                        .utc(currentMatch.tournament_bracket_projections[0].start_time)
                                                        .local()
                                                        .format('LT')} ${timezone}`}
                                                    <br />
                                                </Text>
                                            )}

                                            {/* <Text style={{ color: theme && theme.bracket && theme.bracket.panels && theme.bracket.panels.textColor }}>
												{estimateProjectedStartTimeEndTime(match, matches)}
											</Text>
											<br /> */}
                                            {/* <Text style={{ color: theme && theme.bracket && theme.bracket.panels && theme.bracket.panels.textColor }}>
												{estimateStartTime(number, totalTables, activeTables, 40, totalMatches)}
											</Text> */}
                                        </React.Fragment>
                                    )}
                                    {!currentMatch.start_time && !currentMatch.end_time && currentMatch.scheduled_time && (
                                        <React.Fragment>
                                            <Text style={{ color: theme && theme.bracket && theme.bracket.panels && theme.bracket.panels.textColor }}>
                                                Scheduled: {moment(currentMatch.scheduled_time).calendar()} {timezone}
                                            </Text>
                                        </React.Fragment>
                                    )}
                                </React.Fragment>
                            }
                            tournament={tournament}
                            table={currentMatch.pool_table}
                            label={currentMatch.table_name}
                            matchData={currentMatch}
                            status={currentMatch.status}
                            gameType={tournament.game_type}
                            hoverable={false}
                            tableSize={breakpoint === 'mobile' ? 'small' : 'large'}
                        />
                    </div>

                    {authState &&
                        authState.user &&
                        currentMatch.challenger1 &&
                        currentMatch.challenger2 &&
                        (authState.user.id === currentMatch.challenger1.user_id ||
                            authState.user.id === currentMatch.challenger2.user_id ||
                            authState.user.role === 'admin') && (
                            <div style={{ marginLeft: 15, marginRight: 15, marginBottom: 20 }}>
                                <Button
                                    type="primary"
                                    size="large"
                                    onClick={() => {
                                        props.history.push(`/tournaments/${tournament.slug}/scoring/${currentMatch.hash_id}`);
                                    }}
                                    disabled={currentMatch.status === 'COMPLETED' ? true : false}
                                    style={{ width: '100%', textTransform: 'uppercase' }}
                                >
                                    Live Match Scoring
                                </Button>
                            </div>
                        )}
                </React.Fragment>
            )}
        </Modal>
    );
}

function propsAreEqual(prevMatch, nextMatch) {
    console.log(prevMatch, nextMatch);
    const comparison = prevMatch.selectedMatchId === nextMatch.selectedMatchId && prevMatch.tournamentID === nextMatch.tournamentID;
    console.log(comparison);
    return comparison;
}

const MemoizedMatchInfoModal = withRouter(React.memo(MatchInfoModal));
export default MemoizedMatchInfoModal;
// export default withRouter(MatchInfoModal);
