import React, { useState, useRef } from 'react';
import { Link, Route, withRouter } from 'react-router-dom';
import { Popconfirm, Modal, message, Layout, Avatar, Row, Col, Steps, Empty, Alert, Typography, Button, Icon, Result } from 'antd';
import Card, { Meta } from '../../components/Card';
import SectionHeader from '../../components/SectionHeader';
import SectionContent from '../../components/SectionContent';
import SectionWrapper from '../../components/SectionWrapper';
import SectionFooter from '../../components/SectionFooter';
import defaultImg from '../../assets/img/blue-bg.png';

const { Text, Title } = Typography;

const styles = {};

function TBCreate(props) {
    return (
        <SectionWrapper>
            <SectionHeader
                title="Tournament type"
                titleStyle={{
                    color: '#fff',
                    textTransform: 'uppercase',
                    margin: 0,
                    display: 'flex',
                }}
                height={120}
                backButton={
                    <Button
                        onClick={() =>
                            props.match.params.slug
                                ? props.history.push(`/tournament-builder/${props.match.params.slug}/dashboard`)
                                : props.history.push(`/tournament-builder`)
                        }
                        type="ghost"
                        shape="circle"
                        icon="arrow-left"
                        size="large"
                        ghost
                        style={{ border: 'none' }}
                    />
                }
                subtitle="Select the type of tournament you want to run"
                subtitleStyle={{ color: '#fff' }}
                theme="tournamentBuilder"
            />
            <SectionContent padding="20px 40px">
                <Row gutter={24} justify="center" type="flex">
                    <Col xs={24} sm={12}>
                        <Link to="/tournament-builder/new/simple">
                            <Card
                                hoverable
                                cover={
                                    <React.Fragment>
                                        <div
                                            style={{
                                                height: 140,
                                                overflow: 'hidden',
                                                background: `linear-gradient(rgba(61, 185, 248, 0.8), rgba(24, 144, 255, 0.1)), url(${defaultImg})`,
                                                backgroundRepeat: 'no-repeat',
                                                backgroundSize: 'cover',
                                            }}
                                        />
                                    </React.Fragment>
                                }
                            >
                                <Meta
                                    avatar={<Avatar style={{ backgroundColor: '#000', fontWeight: 600 }}>1</Avatar>}
                                    title={<Title level={4}>Single Stage Tournament</Title>}
                                    description="Run a tournament with a single bracket"
                                />
                            </Card>
                        </Link>
                    </Col>

                    <Col xs={24} sm={12}>
                        <Link to="/tournament-builder/new/advanced">
                            <Card
                                hoverable
                                cover={
                                    <React.Fragment>
                                        <div
                                            style={{
                                                height: 140,
                                                overflow: 'hidden',
                                                background: `linear-gradient(rgba(61, 185, 248, 0.8), rgba(24, 144, 255, 0.1)), url(${defaultImg})`,
                                                backgroundRepeat: 'no-repeat',
                                                backgroundSize: 'cover',
                                            }}
                                        />
                                    </React.Fragment>
                                }
                            >
                                <Meta
                                    avatar={<Avatar style={{ backgroundColor: '#000', fontWeight: 600 }}>2</Avatar>}
                                    title={<Title level={4}>Multi-Stage Tournament</Title>}
                                    description="Run a tournament with "
                                />
                            </Card>
                        </Link>
                    </Col>
                </Row>
            </SectionContent>
            <SectionFooter {...props} />
        </SectionWrapper>
    );
}

export default withRouter(TBCreate);
