import React, { useState } from 'react';
import { Icon, Button, Row, Col, Statistic, Typography, Progress } from 'antd';
import { Link } from 'react-router-dom';
import { getGameType, displayEnumType } from '../../utils';
import Card from '../../components/Card';
import SectionHeader from '../../components/SectionHeader';
import SectionContent from '../../components/SectionContent';
import SectionWrapper from '../../components/SectionWrapper';
import useBreakpoints from '../../utils/hooks/useBreakpoints';
import moment from 'moment-timezone';
import momentDurationFormatSetup from 'moment-duration-format';
momentDurationFormatSetup(moment);

const { Title, Text } = Typography;

const styles = {
    row: {
        display: 'flex',
        flexGrow: 1,
    },
    statistic: {
        fontSize: 20,
    },
};

const columns = [
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: 'action',
        dataIndex: '',
        key: 'x',
        render: () => <b>Paid</b>,
    },
];

const data = [
    {
        key: 0,
        name: 'Isaac',
    },
    {
        key: 1,
        name: 'Scott',
    },
];

export default function GGDashboard(props) {
    const { ghostEvent } = props;
    const [targetKeys, setTargetKeys] = useState();
    const [disabled, setDisabled] = useState();
    const [showSearch, setShowSearch] = useState(true);
    const breakpoints = useBreakpoints();
    var zone_name = moment.tz.guess();
    var timezone = moment.tz(zone_name).zoneAbbr();

    const leftColumns = [
        {
            title: 'Name',
            dataIndex: 'title',
        },
    ];

    const rightColumns = [
        {
            title: 'Name',
            dataIndex: 'title',
        },
    ];

    const onChange = (nextTargetKeys) => {
        setTargetKeys(nextTargetKeys);
    };

    return (
        <SectionWrapper>
            <SectionHeader
                title={props.title}
                titleStyle={{
                    color: '#fff',
                    textTransform: 'uppercase',
                    margin: 0,
                    display: 'flex',
                }}
                height={116}
                subtitle="Ghost Games Here"
                subtitleSuffix={true}
                subtitleStyle={{ color: '#fff' }}
                theme="leagueManager"
            >
                <Link to="/ghost-game-manager/new">
                    <Button size="large" type="ghost" ghost>
                        Payments
                        <Icon type="arrow-right" />
                    </Button>
                </Link>
            </SectionHeader>
            <SectionContent padding="20px 40px">
                <Row gutter={16}>
                    <Col xs={24} sm={8}>
                        <Row gutter={16}>
                            <Col xs={24}>
                                {ghostEvent.invite_only ? (
                                    <Card
                                        bodyStyle={{ paddingTop: 10, paddingLeft: 20, paddingRight: 20, paddingBottom: 10 }}
                                        title={`Players (${ghostEvent.total_players.aggregate.count})`}
                                        extra={
                                            <Link to={`/ghost-event-manager/${ghostEvent.slug}/players`}>
                                                <Button type="default">View All</Button>
                                            </Link>
                                        }
                                    >
                                        <Row gutter={16} justify="start" type="flex">
                                            <Col xs={8}>
                                                <Statistic value={ghostEvent.invited.aggregate.count} title="Invited" valueStyle={styles.statistic} />
                                            </Col>
                                            <Col xs={8}>
                                                <Statistic
                                                    value={ghostEvent.accepted_invitation.aggregate.count}
                                                    title="Confirmed"
                                                    valueStyle={styles.statistic}
                                                />
                                            </Col>
                                            <Col xs={8}>
                                                <Statistic
                                                    value={ghostEvent.invited.aggregate.count - ghostEvent.accepted_invitation.aggregate.count}
                                                    title="Uncomfirmed"
                                                    valueStyle={styles.statistic}
                                                />
                                            </Col>
                                        </Row>
                                    </Card>
                                ) : (
                                    <Card
                                        bodyStyle={{ paddingTop: 10, paddingLeft: 20, paddingRight: 20, paddingBottom: 10 }}
                                        title={`Players (${ghostEvent.total_players.aggregate.count})`}
                                        extra={
                                            <Link to={`/ghost-event-manager/${ghostEvent.slug}/players`}>
                                                <Button type="default">View All</Button>
                                            </Link>
                                        }
                                    >
                                        <Row gutter={16} justify="start" type="flex">
                                            <Col xs={8}>
                                                <Statistic value={ghostEvent.total_players.aggregate.count} title="Confirmed" valueStyle={styles.statistic} />
                                            </Col>
                                        </Row>
                                    </Card>
                                )}
                            </Col>
                            <Col xs={24}>
                                <Card
                                    title="Games (10)"
                                    bodyStyle={{ padding: '10px 25px' }}
                                    extra={
                                        <Link to={`/ghost-game-manager/${ghostEvent.slug}/games`}>
                                            <Button type="default">View All</Button>
                                        </Link>
                                    }
                                >
                                    <Row gutter={16} justify="start" type="flex">
                                        <Col xs={8}>
                                            <Statistic value={10} title="Finished" valueStyle={styles.statistic} />
                                        </Col>
                                        <Col xs={8}>
                                            <Statistic value={10} title="In Progress" valueStyle={styles.statistic} />
                                        </Col>
                                        <Col xs={8}>
                                            <Statistic value={10} title="Waiting" valueStyle={styles.statistic} />
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={25} sm={16}>
                        <Row gutter={16}>
                            <Col xs={24}>
                                <Card
                                    title={
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                            }}
                                        >
                                            <div>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <Title level={4} style={{ margin: 0, padding: 0 }}>
                                                        Overview
                                                    </Title>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                >
                                    <div style={{ marginBottom: 20 }}>
                                        <div
                                            style={{
                                                backgroundColor: ghostEvent.status === 'COMPLETED' ? '#fff' : '#e2efff',
                                                borderRadius: 8,
                                                padding: 20,
                                                border: ghostEvent.status === 'COMPLETED' ? '2px solid #f0f0f0' : 'none',
                                            }}
                                        >
                                            <Statistic
                                                value={ghostEvent.progress ? parseInt(ghostEvent.progress).toFixed(0) : 0}
                                                valueStyle={{ fontSize: 30 }}
                                                suffix={`% `}
                                                // style={{ color: '#fff' }}
                                            />
                                            <Progress
                                                percent={parseInt(ghostEvent.progress)}
                                                status={
                                                    parseInt(ghostEvent.progress) < 100
                                                        ? 'active'
                                                        : parseInt(ghostEvent.progress) === 100
                                                        ? 'success'
                                                        : 'normal'
                                                }
                                                size="small"
                                                showInfo={false}
                                            />
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'space-between',
                                                }}
                                            >
                                                <Text style={{ marginTop: 10 }}>
                                                    <span>
                                                        <Icon type="clock-circle" style={{ marginRight: 3 }} /> {`10` || 'N/A'}
                                                    </span>
                                                </Text>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <Row gutter={16}>
                                            <Col xs={breakpoints.isXs === true ? 24 : 12}>
                                                <Title level={4} style={{ fontSize: 14, marginBottom: 15 }}>
                                                    Ghost Game Details:
                                                </Title>
                                                <Row gutters={[16, 16]}>
                                                    <Col xs={24} sm={12}>
                                                        <table width="100%" className="info-table">
                                                            <tbody>
                                                                <tr>
                                                                    <td>Name</td>
                                                                    <td>{ghostEvent.name}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Description</td>
                                                                    <td>{ghostEvent.description}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Type</td>
                                                                    <td>{displayEnumType(ghostEvent.type)}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Start date</td>
                                                                    <td>
                                                                        {moment(ghostEvent.start_date).format('llll')} ({timezone})
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>End date</td>
                                                                    <td>
                                                                        {moment(ghostEvent.end_date).format('llll')} ({timezone})
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </Col>
                                                    <Col xs={25} sm={12}>
                                                        <table width="100%" className="info-table">
                                                            <tbody>
                                                                <tr>
                                                                    <td>Max Players</td>
                                                                    <td>{ghostEvent.max_players}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Payment Link</td>
                                                                    <td>{ghostEvent.payment_link}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Cost</td>
                                                                    <td>${ghostEvent.cost}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Invite Only</td>
                                                                    <td>{ghostEvent.invite_only ? 'Yes' : 'No'}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Access</td>
                                                                    <td>{ghostEvent.is_public ? 'Public' : 'Private'}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </SectionContent>
        </SectionWrapper>
    );
}
