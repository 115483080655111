import React from 'react';
import { Card, Avatar, Typography, Tag } from 'antd';
import { withRouter, Link } from 'react-router-dom';
import { Query } from '@apollo/client/react/components';
import { GET_USER_NEARBY_VENUES_QUERY } from '../data/queries';
import CircularLoader from '../../../components/CircularLoader';
import ZeroState from '../../../components/ZeroState';

const styles = {};

const { Title, Text } = Typography;

function UserNearbyVenues(props) {
    const { authState, user } = props;

    function getMiles(i) {
        return i * 0.000621371192;
    }

    return (
        <Query
            query={GET_USER_NEARBY_VENUES_QUERY}
            fetchPolicy="cache-and-network"
            variables={{ user_id: (user && user.id) || authState.user.id }}
            notifyOnNetworkStatusChange
            awaitRefetchQueries
            onCompleted={(data, loading, error, subscribeToMore) => {
                console.log(data);
            }}
        >
            {({ loading, error, data }) => {
                if (loading)
                    return (
                        <div style={styles.container}>
                            <CircularLoader />
                        </div>
                    );
                if (error) return <div style={styles.container}>Error: {error.message}</div>;
                if ((data && !data.vw_user_venue_distance) || (data && data.vw_user_venue_distance.length === 0 && data.vw_user_venue_distance.length === 0)) {
                    return <ZeroState message="No venues nearby." messageStyle={{ fontSize: 16 }} showImage={false} style={{ textAlign: 'left' }} />;
                }

                const venues = data.vw_user_venue_distance;
                console.log(venues);

                return (
                    <React.Fragment>
                        {venues.map((item, index) => {
                            return (
                                <Card
                                    // title={league.name}
                                    bordered={false}
                                    hoverable
                                    style={{ marginBottom: 10 }}
                                    // extra={<a href="#">More</a>}
                                    key={index}
                                >
                                    <Link to={`/venues/${item.slug}`}>
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                            }}
                                        >
                                            <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                <div>
                                                    <Title level={4} style={{ margin: 0 }}>
                                                        {item.name}
                                                    </Title>
                                                    <Text>
                                                        {item.venue.city}, {item.venue.region}
                                                    </Text>
                                                </div>
                                                <Text>{getMiles(item.distance).toFixed(2)} miles</Text>
                                            </div>
                                        </div>
                                    </Link>
                                </Card>
                            );
                        })}
                    </React.Fragment>
                );
            }}
        </Query>
    );
}

export default withRouter(UserNearbyVenues);
