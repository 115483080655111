import React from 'react';
import { Link } from 'react-router-dom';
import poolLiveDetailSS from '../../assets/img/pool-live-detail-ss.png';
import SectionContent from '../../components/SectionContent';

export default function PLStreamDetail(props) {
	return (
		<React.Fragment>
			<img src={poolLiveDetailSS} alt="" width="100%" />
		</React.Fragment>
	);
}
