import React, { useState } from 'react';
// import objtocsv from 'objects-to-csv';
import { message, Table, Icon, Row, Col, Typography, Button } from 'antd';
import Card from '../../../components/Card';
import { Link } from 'react-router-dom';
import { isoCountries } from '../../../utils/isoCountryCodes';
import SectionTitle from '../../../components/SectionTitle';
import SectionHeader from '../../../components/SectionHeader';
import SectionContent from '../../../components/SectionContent';
import SectionWrapper from '../../../components/SectionWrapper';
// import TBManageButtons from './TBManageButtons';
import StandingsTable from './StandingsTable';
import CircularLoader from '../../../components/CircularLoader';
import { Subscription } from '@apollo/client/react/components';
import { GET_LEAGUE_DIVISON_PLAYER_STATS_SUBSCRIPTION } from '../data/subscriptions';
// import { GET_TOURNAMENT_PLAYER_STANDINGS } from './data/queries';
import moment from 'moment';
import _ from 'lodash';
import { Parser } from 'json2csv';

const { Text, Title } = Typography;

const styles = {
    row: {
        display: 'flex',
        flexGrow: 1,
    },
    tab: {
        paddingTop: 5,
        paddingLeft: 24,
        paddingRight: 24,
        paddingBottom: 24,
    },
};

export default function DPDivisionStandings(props) {
    const { league, division, client } = props;
    const [tableData, setTableData] = useState([]);
    console.log('division', division);

    function calcDuration(challenger1_times, challenger2_times) {
        const challenger1Duration = _.compact(
            challenger1_times.map((match) => {
                let duration;
                if (match.start_time && match.end_time) {
                    var start = moment(match.start_time);
                    var end = moment(match.end_time);
                    duration = parseInt(moment.duration(end.diff(start, 'milliseconds')).format('m'));
                }
                return duration;
            })
        ).reduce((acc, minutes) => {
            return acc + minutes;
        }, 0);

        const challenger2Duration = _.compact(
            challenger2_times.map((match) => {
                let duration;
                if (match.start_time && match.end_time) {
                    var start = moment(match.start_time);
                    var end = moment(match.end_time);
                    duration = parseInt(moment.duration(end.diff(start, 'milliseconds')).format('m'));
                }
                return duration;
            })
        ).reduce((acc, minutes) => {
            return acc + minutes;
        }, 0);

        return challenger1Duration + challenger2Duration;
    }

    function orderAndFormatData(arr) {
        let count = arr.length;
        let tmp, split, cNum1, cNum2, split2, cNum11, cNum21;
        let formattedResults = [];
        // let totalGames = 0;
        // let totalMatches = 0;
        // let totalMatchDurationMinutes = 0;
        // let totalMatchDurationHours = 0;

        console.log('a', arr);

        for (let i = 0; i < count; i++) {
            // if (arr[i].place.includes('-')) {
            // 	split = arr[i].place.split('-');
            // 	cNum1 = parseInt(split[0]);
            // 	cNum2 = parseInt(split[1]);
            // }

            // for (let j = i + 1; j < count; j++) {
            // 	if (arr[j].place.includes('-')) {
            // 		split2 = arr[j].place.split('-');
            // 		cNum11 = parseInt(split2[0]);
            // 		cNum21 = parseInt(split2)[1];

            // 		if (split) {
            // 			if (cNum2 > cNum21) {
            // 				tmp = arr[i];
            // 				arr[i] = arr[j];
            // 				arr[j] = tmp;
            // 			}
            // 		} else {
            // 			if (parseInt(arr[i].place) > parseInt(cNum21)) {
            // 				tmp = arr[i];
            // 				arr[i] = arr[j];
            // 				arr[j] = tmp;
            // 			}
            // 		}
            // 	} else {
            // 		if (parseInt(arr[i].place) > parseInt(arr[j].place)) {
            // 			tmp = arr[i];
            // 			arr[i] = arr[j];
            // 			arr[j] = tmp;
            // 		}
            // 	}

            // 	if (parseInt(arr[i].place) > parseInt(arr[j].place)) {
            // 		tmp = arr[i];
            // 		arr[i] = arr[j];
            // 		arr[j] = tmp;
            // 	}
            // }

            // split = false;

            let player = arr[i];

            let matchesWon = player.challenger1_matches_won.aggregate.count + player.challenger2_matches_won.aggregate.count;
            let matchesLost = player.challenger1_matches_lost.aggregate.count + player.challenger2_matches_lost.aggregate.count;
            let gamesWon = player.challenger1_matches_games.aggregate.sum.challenger1_score + player.challenger2_matches_games.aggregate.sum.challenger2_score;
            let gamesLost = player.challenger1_matches_games.aggregate.sum.challenger2_score + player.challenger2_matches_games.aggregate.sum.challenger1_score;
            let framesWon =
                player.challenger1_matches_frames.aggregate.sum.challenger1_frames + player.challenger2_matches_frames.aggregate.sum.challenger2_frames;
            let framesLost =
                player.challenger1_matches_frames.aggregate.sum.challenger2_frames + player.challenger2_matches_frames.aggregate.sum.challenger1_frames;
            let pointsWon =
                player.challenger1_matches_points.aggregate.sum.challenger1_points + player.challenger2_matches_points.aggregate.sum.challenger2_points;
            let pointsLost =
                player.challenger1_matches_points.aggregate.sum.challenger2_points + player.challenger2_matches_points.aggregate.sum.challenger1_points;
            let totalMatches = player.challenger1_matches_games.aggregate.count + player.challenger2_matches_games.aggregate.count;
            let totalFrames =
                player.challenger1_matches_frames.aggregate.sum.challenger1_frames +
                player.challenger1_matches_frames.aggregate.sum.challenger2_frames +
                player.challenger2_matches_frames.aggregate.sum.challenger1_frames +
                player.challenger2_matches_frames.aggregate.sum.challenger2_frames;
            let avgFramesWon = parseFloat((framesWon / totalFrames).toFixed(2));
            let totalGames =
                player.challenger1_matches_games.aggregate.sum.challenger1_score +
                player.challenger1_matches_games.aggregate.sum.challenger2_score +
                player.challenger2_matches_games.aggregate.sum.challenger1_score +
                player.challenger2_matches_games.aggregate.sum.challenger2_score;
            let avgMatchesWon = parseFloat((matchesWon / totalMatches).toFixed(2));
            let avgGamesWon = parseFloat((gamesWon / totalGames).toFixed(2));
            let totalPoints =
                player.challenger1_matches_points.aggregate.sum.challenger1_points +
                player.challenger1_matches_points.aggregate.sum.challenger2_points +
                player.challenger2_matches_points.aggregate.sum.challenger1_points +
                player.challenger2_matches_points.aggregate.sum.challenger2_points;
            let avgPointsWon = parseFloat((pointsWon / totalPoints).toFixed(2));
            let durationMinutes = calcDuration(player.challenger1_matches_games.nodes, player.challenger2_matches_games.nodes);
            let totalMatchDurationMinutes = durationMinutes;
            let avgMatchDurationMinutes = durationMinutes / totalMatches;
            let totalMatchDurationHours = parseFloat((totalMatchDurationMinutes / 60).toFixed(2));
            let avgMatchDurationHours = parseFloat((totalMatchDurationMinutes / 60 / totalMatches).toFixed(2));

            // totalMatches = totalMatches + matchesWon;
            // totalGames = totalGames + gamesWon;
            // totalFrames = totalFrames + framesWon;
            // totalPoints = totalPoints + pointsWon;
            // totalMatchDurationMinutes = totalMatchDurationMinutes + totalMatchDurationMinutes;
            // totalMatchDurationHours = totalMatchDurationHours + totalMatchDurationHours;

            formattedResults.push({
                key: player.id,
                place: player.place,
                name: player.name,
                country: player.country,
                matches: matchesWon + '-' + matchesLost,
                totalMatches: totalMatches,
                matchesWon: matchesWon,
                matchesLost: matchesLost,
                avgMatchesWon: avgMatchesWon,
                frames: framesWon + '-' + framesLost,
                totalFrames: totalFrames,
                framesWon: framesWon,
                framesLost: framesLost,
                avgFramesWon: avgFramesWon,
                games: gamesWon + '-' + gamesLost,
                totalGames: totalGames,
                gamesWon: gamesWon,
                gamesLost: gamesLost,
                avgGamesWon: avgGamesWon,
                points: pointsWon + '-' + pointsLost,
                totalPoints: totalPoints,
                pointsWon: pointsWon,
                pointsLost: pointsLost,
                avgPointsWon: avgPointsWon,
                skillLevel: player.skill_level,
                matchWinPercentage: Math.round((matchesWon / totalMatches) * 100) || 0,
                frameWinPercentage: Math.round((framesWon / totalFrames) * 100) || 0,
                gameWinPercentage: Math.round((gamesWon / totalGames) * 100) || 0,
                pointWinPercentage: Math.round((pointsWon / totalPoints) * 100) || 0,
                // earnings: player.payouts && player.payouts.money,
                // calcutta: player.payouts && player.payouts.calcutta,
                // points: player.player_points && player.player_points.points,
                totalMatchDurationMinutes,
                avgMatchDurationMinutes,
                totalMatchDurationHours,
                avgMatchDurationHours,
            });
        }

        return formattedResults;
    }

    async function formatResultsTableData(data) {
        if (data) {
            let arr = orderAndFormatData(data);

            let orderedData = arr
                .sort((a, b) => {
                    return (
                        b.matchWinPercentage - a.matchWinPercentage ||
                        a.matchesLost - b.matchesLost ||
                        b.framesWon - a.framesWon ||
                        b.framesLost - a.framesLost ||
                        b.pointsWon - a.pointsWon ||
                        a.pointsLost - b.pointsLost
                    );
                })
                .map((player, index) => {
                    return {
                        ...player,
                        place: index + 1,
                    };
                });

            console.log('OD', orderedData);

            setTableData(orderedData);
        }
    }
    return (
        <SectionWrapper>
            <SectionContent padding="20px 40px">
                <Row gutter={24} justify="center" type="flex">
                    <Col xs={24} sm={props.navType === 'desktop' ? 24 : 22}>
                        <Card
                            // title="Players"
                            bodyStyle={{ padding: 0 }}
                            // style={{
                            // 	borderRadius: 8,
                            // 	overflow: 'hidden',
                            // 	border: 'none',
                            // 	boxShadow: '0 2px 5px 0px rgba(0, 0, 0, 0.1)'
                            // }}
                            cover={
                                <div
                                    style={{
                                        paddingTop: 16,
                                        paddingBottom: 16,
                                        paddingLeft: 24,
                                        paddingRight: 24,
                                        // background:'linear-gradient(rgb(239, 243, 245), rgb(255, 255, 255))',
                                        borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <Title level={4} style={{ margin: 0, padding: 0, fontSize: 18 }}>
                                        Standings
                                    </Title>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                        }}
                                    />
                                </div>
                            }
                        >
                            <Subscription
                                subscription={GET_LEAGUE_DIVISON_PLAYER_STATS_SUBSCRIPTION}
                                fetchPolicy="cache-and-network"
                                notifyOnNetworkStatusChange={true}
                                variables={{ league_id: league.id, division_id: division.id }}
                                onSubscriptionData={(data) => {
                                    if (
                                        data &&
                                        data.subscriptionData &&
                                        data.subscriptionData.data &&
                                        data.subscriptionData.data.league_players &&
                                        data.subscriptionData.data.league_players.length
                                    ) {
                                        formatResultsTableData(data.subscriptionData.data.league_players);
                                    }
                                }}
                            >
                                {({ loading, error, data }) => {
                                    if (loading) return <CircularLoader />;

                                    return <StandingsTable divisionID={division.id} formattedResults={tableData} />;
                                }}
                            </Subscription>
                        </Card>
                    </Col>
                </Row>
            </SectionContent>
        </SectionWrapper>
    );
}
