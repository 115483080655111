import { atom } from 'recoil';

export const rowSelectAtom = atom({
    key: 'rowSelectAtom',
    default: [],
});

export const removeButtonLoadingAtom = atom({
    key: 'removeButtonLoading',
    default: false,
});

export const removeButtonDisabledAtom = atom({
    key: 'removeButtonDisabled',
    default: true,
});

export const inviteModalVisible = atom({
    key: 'inviteModalVisible',
    default: false,
});

export const totalPlayerCountAtom = atom({
    key: 'totalPlayerCount',
    default: 0,
});

export const inviteModalButtonDisabled = atom({
    key: 'inviteModalButton',
    default: true,
});

export const inviteModalButtonLoading = atom({
    key: 'inviteModalButtonLoading',
    default: false,
});

export const createStep = atom({
    key: 'createStep',
    default: 0,
});

export const completedStepAtom = atom({
    key: 'completedStep',
    default: -1,
});

export const createGhostGameFormValues = atom({
    key: 'createGhostGameFormValues',
    deafult: [],
});

export const createGhostGameFormPublicRadio = atom({
    key: 'createGhostGameFormPublicRadio',
    default: true,
});

export const createGhostGameFormRebuyRadio = atom({
    key: 'createGhostGameFormInviteRadio',
    default: null,
});

export const createGhostGameInviteInputList = atom({
    key: 'createGhostGameInviteInputList',
    default: [],
});

export const ghostEventId = atom({
    key: 'ghostEventId',
    default: 26,
});

export const invitePlayersAtom = atom({
    key: 'invitePlayers',
    default: [],
});

export const payoutsModalVisible = atom({
    key: 'payoutsModalVisible',
    deafult: false,
});

export const payoutsRowSelectedId = atom({
    key: 'payoutsRowSelectedId',
    default: null,
});

export const payoutsInitialFormValues = atom({
    key: 'payoutsInitialFormValues',
    default: {
        id: 0,
        place: '',
        amount: '',
    },
});

export const payoutsSubmitButtonText = atom({
    key: 'payoutsSubmitButtonText',
    default: 'Add Payout',
});

export const isNewPayoutsEntry = atom({
    key: 'isNewPayoutsEntry',
    default: true,
});

export const playerModalVisible = atom({
    key: 'playerModalVisible',
    default: false,
});
