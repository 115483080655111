import gql from 'graphql-tag';

export const GET_TOURNAMENT_QUERY = gql`
    query tournaments($id: Int!) {
        tournaments(where: { id: { _eq: $id } }) {
            id
            name
            slug
            description
            avatar
            flyer
            logo
            tournament_type
            tournament_format
            bracket_template_id
            participant_times_played
            enable_groups
            enable_sets
            sets_per_match
            set_tiebreaker_format
            split_bracket
            players_per_group
            players_advance_per_group
            players_ranked_by
            handicap_format
            player_type
            game_type
            winners_race_to
            winners_max_race
            losers_race_to
            losers_max_race
            finals_race_to
            race_to
            consolidation_finals
            max_players
            max_tables
            max_tables
            rebuys_allowed
            rule_format
            break_format
            status
            progress
            start_date_time
            end_date_time
            entry_fee
            added_money
            payout_type
            draw_type
            rating_system
            use_text_messaging
            use_in_app_messaging
            rsvp_allowed
            show_player_skill_levels
            show_player_races
            show_unconfirmed_players
            is_public
            is_featured
            tournament_stage_format
            signup_cutoff_time
            usapl_race_format
            filter_by_round
            players_per_table
            redraw_every_round
            default_chip_amount
            autopilot_mode
            event_id
            event {
                id
                name
                slug
                description
            }
            director_id
            director {
                id
                first_name
                last_name
                email
            }
            organizer_id
            organizer {
                id
                first_name
                last_name
                email
            }
            venue {
                id
                name
                slug
                description
                venue_type
                avatar
                address1
                address2
                city
                country
                region
                postal_code
                phone_number
                email_address
                website
                operating_hours
                social_facebook
                social_instagram
                social_twitter
            }
            stages {
                id
                name
                slug
                number
                type
                status
                tournament_id
                draw_type
                tournament_type
                tournament_format
                usapl_race_format
                handicap_format
                autopilot_mode
                bracket_template_id
                consolidation_finals
                max_players
                winners_race_to
                losers_race_to
                split_bracket
                participant_times_played
                players_advance_per_group
                players_per_group
                players_per_table
                players_ranked_by
                default_chip_amount
                enable_groups
                finals_race_to
            }
            tournament_payouts(where: { money: { _is_null: false } }) {
                money
                place
                calcutta
            }
            payout_totals: tournament_payouts_aggregate {
                aggregate {
                    sum {
                        money
                        calcutta
                    }
                }
            }
            tournament_brackets_aggregate {
                aggregate {
                    count
                }
            }
            tournament_players_aggregate {
                aggregate {
                    count
                }
            }
            pool_tables_aggregate {
                aggregate {
                    count
                }
            }
            created_at
            updated_at
        }
    }
`;

export const GET_TOURNAMENT_STAGES = gql`
    query tournament_stages($tournament_id: Int!) {
        tournament_stages(where: { tournament_id: { _eq: $tournament_id } }) {
            id
            tournament_id
            stage_id
            created_at
            updated_at
            stage {
                id
                number
                name
                type
                status
                slug
                created_at
                updated_at
            }
        }
    }
`;

export const GET_TOURNAMENT_BY_SLUG = gql`
    query tournaments($slug: String!) {
        tournaments(where: { slug: { _eq: $slug } }) {
            id
            name
            slug
            description
            avatar
            flyer
            logo
            tournament_type
            tournament_format
            bracket_template_id
            participant_times_played
            enable_groups
            enable_sets
            sets_per_match
            set_tiebreaker_format
            split_bracket
            players_per_group
            players_advance_per_group
            players_ranked_by
            handicap_format
            player_type
            game_type
            winners_race_to
            winners_max_race
            losers_race_to
            losers_max_race
            finals_race_to
            race_to
            consolidation_finals
            max_players
            max_tables
            rebuys_allowed
            max_rebuys
            rule_format
            break_format
            status
            progress
            start_date_time
            end_date_time
            entry_fee
            added_money
            payout_type
            draw_type
            rating_system
            use_text_messaging
            use_in_app_messaging
            rsvp_allowed
            show_player_skill_levels
            show_player_races
            show_unconfirmed_players
            is_public
            is_featured
            tournament_stage_format
            signup_cutoff_time
            usapl_race_format
            filter_by_round
            players_per_table
            redraw_every_round
            default_chip_amount
            autopilot_mode
            event_id
            event {
                id
                name
                slug
                description
            }
            director_id
            director {
                id
                first_name
                last_name
                email
            }
            organizer_id
            organizer {
                id
                first_name
                last_name
                email
            }
            tournament_managers {
                manager {
                    id
                    first_name
                    last_name
                    display_name
                }
            }
            tournament_payouts(where: { money: { _is_null: false } }) {
                money
                place
                calcutta
            }
            tournament_payouts_aggregate {
                aggregate {
                    sum {
                        money
                        calcutta
                    }
                }
            }
            tournament_players_aggregate {
                aggregate {
                    count
                }
            }
            venue {
                id
                name
                slug
                venue_type
                address1
                address2
                city
                country
                region
            }
            stages {
                id
                name
                slug
                number
                type
                status
                tournament_id
                draw_type
                tournament_type
                tournament_format
                usapl_race_format
                handicap_format
                autopilot_mode
                bracket_template_id
                consolidation_finals
                max_players
                winners_race_to
                losers_race_to
                split_bracket
                participant_times_played
                players_advance_per_group
                players_per_group
                players_per_table
                players_ranked_by
                default_chip_amount
                enable_groups
                finals_race_to
            }
        }
    }
`;

export const GET_TOURNAMENT_MATCH_GAMES_QUERY = gql`
    query tournament_match_games($tournament_id: Int!, $tournament_bracket_id: Int!) {
        tournament_match_games(where: { tournament_id: { _eq: $tournament_id }, tournament_bracket_id: { _eq: $tournament_bracket_id } }) {
            id
            tournament_id
            tournament_bracket_id
            match_number
            game_number
            player_id
            player_score
            player_points
            player_is_winner
            player_break_run
            player_runout
            player_tpa
            player_unforced_errors
            player_balls_pocketed
            opponent_id
            opponent_score
            opponent_points
            opponent_is_winner
            opponent_break_run
            opponent_runout
            opponent_tpa
            opponent_unforced_errors
            opponent_balls_pocketed
            status
            progress
            duration
            table_id
            start_time
            end_time
            created_at
            updated_at
        }
    }
`;

export const GET_TOURNAMENT_BRACKET_MATCH_BY_HASH_ID = gql`
    query tournament_brackets($hash_id: String!, $tournament_id: Int!) {
        tournament_brackets(where: { hash_id: { _eq: $hash_id }, tournament_id: { _eq: $tournament_id } }, order_by: { id: asc }) {
            id
            hash_id
            round
            identifier
            challenger1 {
                id
                name
                country
                skill_level
                race_to
                place
                seed
            }
            challenger2 {
                id
                name
                country
                skill_level
                race_to
                place
                seed
            }
            challenger1_id
            challenger1_name
            challenger1_country
            challenger1_score
            challenger1_points
            challenger1_race_to
            challenger1_seed
            challenger1_is_winner
            challenger1_is_forfeit
            challenger2_id
            challenger2_name
            challenger2_country
            challenger2_score
            challenger2_points
            challenger2_race_to
            challenger2_seed
            challenger2_is_winner
            challenger2_is_forfeit
            challenger1_sms_sent
            challenger2_sms_sent
            challenger1_is_playing
            challenger2_is_playing
            tournament_player_id
            game_type
            match_number
            match_id
            match_color
            team_id
            scheduled_table_id
            priority
            scheduled_time
            start_time
            end_time
            duration
            table_id
            table_name
            seed
            status
            progress
            loser_from
            loser_from_num
            loser_to
            loser_to_num
            winner_to
            winner_to_num
            winner_from
            winner_from_top_num
            winner_from_bottom_num
            loser_from_top_num
            loser_from_bottom_num
            advance_to
            stage_id
            stage_number
            is_bye
            is_split
            is_semi_finals
            is_finals
            is_consolidation_finals
            livestream_url
            livestream_thumbnail
            scoreboard_balls
            tournament {
                name
                slug
                winners_race_to
                losers_race_to
                finals_race_to
            }
            pool_table {
                id
                slug
                label
                is_streaming_table
                is_featured_table
                livestream_url
                status
            }
        }
    }
`;

export const GET_TOURNAMENT_PLAYER_EFFECTIVE_RATINGS = gql`
    query vw_tournament_player_effective_ratings($tournament_id: Int!) {
        vw_tournament_player_effective_ratings(where: { tournament_id: { _eq: $tournament_id } }) {
            to_par
            name
            id
            country
            skill_level
            fargo_rating
            effective_rating
            tournament_id
        }
    }
`;

export const GET_TOURNAMENT_OVERVIEW = gql`
    query tournament_overview($slug: String!) {
        tournaments(where: { slug: { _eq: $slug } }) {
            id
            name
            slug
            description
            avatar
            flyer
            tournament_type
            tournament_format
            handicap_format
            player_type
            game_type
            winners_race_to
            losers_race_to
            finals_race_to
            race_to
            consolidation_finals
            max_players
            max_tables
            rule_format
            break_format
            status
            progress
            start_date_time
            end_date_time
            entry_fee
            added_money
            payout_type
            draw_type
            rating_system
            use_text_messaging
            rsvp_allowed
            show_player_skill_levels
            show_player_races
            show_unconfirmed_players
            is_public
            signup_cutoff_time
            director {
                id
                first_name
                last_name
                slug
                email
            }
            organizer {
                id
                first_name
                last_name
                slug
                email
            }
            venue {
                id
                name
                slug
                description
                venue_type
                avatar
                address1
                address2
                city
                country
                region
                postal_code
                phone_number
                email_address
                website
                operating_hours
                social_facebook
                social_instagram
                social_twitter
            }
            event {
                id
                name
                slug
                description
            }
            tournament_payouts(where: { money: { _is_null: false } }) {
                money
                place
                calcutta
            }
            tournament_payouts_aggregate {
                aggregate {
                    sum {
                        money
                        calcutta
                    }
                }
            }
            tournament_brackets_aggregate {
                aggregate {
                    count
                }
            }
            bye_matches: tournament_brackets_aggregate(where: { is_bye: { _eq: true } }) {
                aggregate {
                    count
                }
            }
            in_progress_matches: tournament_brackets_aggregate(where: { status: { _eq: IN_PROGRESS } }) {
                aggregate {
                    count
                }
            }
            completed_matches: tournament_brackets_aggregate(where: { _and: [{ status: { _eq: COMPLETED } }, { is_bye: { _eq: false } }] }) {
                aggregate {
                    count
                }
            }
            not_started_matches: tournament_brackets_aggregate(where: { status: { _eq: NOT_STARTED } }) {
                aggregate {
                    count
                }
            }
            tournament_players_aggregate {
                aggregate {
                    count
                }
            }
            unconfirmed_players: tournament_players_aggregate(where: { status: { _eq: UNCONFIRMED } }) {
                aggregate {
                    count
                }
            }
            confirmed_players: tournament_players_aggregate(where: { status: { _eq: CONFIRMED } }) {
                aggregate {
                    count
                }
            }
            waiting_list_players: tournament_players_aggregate(where: { status: { _eq: WAITING_LIST } }) {
                aggregate {
                    count
                }
            }
            pool_tables_aggregate {
                aggregate {
                    count
                }
            }
            in_use_tables: pool_tables_aggregate(where: { status: { _eq: IN_USE } }) {
                aggregate {
                    count
                }
            }
            open_tables: pool_tables_aggregate(where: { status: { _eq: OPEN } }) {
                aggregate {
                    count
                }
            }
            closed_tables: pool_tables_aggregate(where: { status: { _eq: CLOSED } }) {
                aggregate {
                    count
                }
            }
            created_at
            updated_at
        }
    }
`;

export const GET_TOURNAMENT_PROGRESS_QUERY = gql`
    query tournament_status($id: Int!) {
        tournaments(where: { id: { _eq: $id } }) {
            id
            progress
        }
    }
`;

export const GET_TOURNAMENT_STATUS = gql`
    query tournament_status($id: Int!) {
        tournaments(where: { id: { _eq: $id } }) {
            id
            status
        }
    }
`;

export const GET_TOURNAMENT_PROGRESS = gql`
    query tournament_status($id: Int!) {
        tournaments(where: { id: { _eq: $id } }) {
            id
            progress
        }
    }
`;

export const GET_TOURNAMENT_BRACKET_PROJECTIONS = gql`
    query tournament_bracket_projections($tournament_id: Int!) {
        tournament_bracket_projections(where: { tournament_id: { _eq: $tournament_id } }) {
            id
            round
            round_combo
            start_time
            end_time
            match_duration
        }
    }
`;

export const GET_TOURNAMENT_MATCH_PROJECTION = gql`
    query tournament_bracket_projections($tournament_id: Int!, $match_id: Int!) {
        tournament_bracket_projections(where: { tournament_id: { _eq: $tournament_id }, tournament_bracket_id: { _eq: $match_id } }) {
            id
            round
            round_combo
            start_time
            end_time
            match_duration
        }
    }
`;

export const GET_GAME_TYPES_QUERY = gql`
    query game_types {
        game_types {
            value
            comment
        }
    }
`;

export const GET_TOURNAMENT_LIST = gql`
    query tournaments {
        tournaments(where: { is_public: { _eq: true } }, order_by: [{ updated_at: desc }, { created_at: desc }]) {
            name
            id
            slug
            status
            progress
            start_date_time
            end_date_time
            flyer
            game_type
            tournament_type
            tournament_format
            handicap_format
            max_players
            description
            created_at
            updated_at
            winners_race_to
            losers_race_to
            finals_race_to
            handicapped
            is_featured
            tournament_players(
                where: { _or: [{ place: { _eq: "1" } }, { place: { _eq: "2" } }, { place: { _eq: "3" } }] }
                order_by: { place: asc }
                limit: 4
            ) {
                name
                place
            }
            tournament_players_aggregate {
                aggregate {
                    count
                }
            }
            tournament_payouts_aggregate {
                aggregate {
                    sum {
                        money
                        calcutta
                    }
                }
            }
            director {
                id
                slug
                first_name
                last_name
            }
            venue {
                id
                name
                slug
                city
                country
                region
            }
            tournament_stages {
                id
                stage {
                    id
                    number
                    name
                    type
                    status
                    slug
                    created_at
                    updated_at
                }
            }
        }
    }
`;

export const FILTER_TOURNAMENT_LIST = gql`
    query tournaments($limit: Int!, $offset: Int!, $game_type: String, $status: tournament_status_enum) {
        tournaments(
            where: { is_public: { _eq: true }, game_type: { _eq: $game_type }, status: { _eq: $status } }
            order_by: [{ updated_at: desc }, { created_at: desc }]
            limit: $limit
            offset: $offset
        ) {
            name
            id
            slug
            status
            progress
            start_date_time
            end_date_time
            flyer
            game_type
            tournament_type
            tournament_format
            handicap_format
            max_players
            description
            created_at
            updated_at
            winners_race_to
            losers_race_to
            finals_race_to
            handicapped
            is_featured
            tournament_players(
                where: { _or: [{ place: { _eq: "1" } }, { place: { _eq: "2" } }, { place: { _eq: "3" } }] }
                order_by: { place: asc }
                limit: 4
            ) {
                name
                place
            }
            tournament_players_aggregate {
                aggregate {
                    count
                }
            }
            tournament_payouts_aggregate {
                aggregate {
                    sum {
                        money
                        calcutta
                    }
                }
            }
            director {
                id
                slug
                first_name
                last_name
            }
            venue {
                id
                name
                slug
                city
                country
                region
            }
            tournament_stages {
                id
                stage {
                    id
                    number
                    name
                    type
                    status
                    slug
                    created_at
                    updated_at
                }
            }
        }
    }
`;

export const GET_TOURNAMENT_COUNTS = gql`
    query tournaments {
        all_tournaments: tournaments_aggregate(where: { is_public: { _eq: true } }) {
            aggregate {
                count
            }
        }
        in_progress_tournaments: tournaments_aggregate(where: { is_public: { _eq: true }, status: { _eq: IN_PROGRESS } }) {
            aggregate {
                count
            }
        }
        completed_tournaments: tournaments_aggregate(where: { is_public: { _eq: true }, status: { _eq: COMPLETED } }) {
            aggregate {
                count
            }
        }
        upcoming_tournaments: tournaments_aggregate(where: { is_public: { _eq: true }, status: { _eq: NOT_STARTED } }) {
            aggregate {
                count
            }
        }
        nine_ball_tournaments: tournaments_aggregate(where: { is_public: { _eq: true }, game_type: { _eq: NINE_BALL } }) {
            aggregate {
                count
            }
        }
        ten_ball_tournaments: tournaments_aggregate(where: { is_public: { _eq: true }, game_type: { _eq: TEN_BALL } }) {
            aggregate {
                count
            }
        }
        eight_ball_tournaments: tournaments_aggregate(where: { is_public: { _eq: true }, game_type: { _eq: EIGHT_BALL } }) {
            aggregate {
                count
            }
        }
        one_pocket_tournaments: tournaments_aggregate(where: { is_public: { _eq: true }, game_type: { _eq: ONE_POCKET } }) {
            aggregate {
                count
            }
        }
        straight_pool_tournaments: tournaments_aggregate(where: { is_public: { _eq: true }, game_type: { _eq: STRAIGHT_POOL } }) {
            aggregate {
                count
            }
        }
        snooker_tournaments: tournaments_aggregate(where: { is_public: { _eq: true }, game_type: { _eq: SNOOKER } }) {
            aggregate {
                count
            }
        }
        three_cushion_tournaments: tournaments_aggregate(where: { is_public: { _eq: true }, game_type: { _eq: THREE_CUSHION } }) {
            aggregate {
                count
            }
        }
        mixed_tournaments: tournaments_aggregate(where: { is_public: { _eq: true }, game_type: { _eq: MIXED } }) {
            aggregate {
                count
            }
        }
        bank_pool_tournaments: tournaments_aggregate(where: { is_public: { _eq: true }, game_type: { _eq: BANK_POOL } }) {
            aggregate {
                count
            }
        }
    }
`;

export const SEARCH_TOURNAMENTS_QUERY = gql`
    query search_tournaments($name: String!) {
        search_tournaments(args: { search: $name }) {
            name
            id
            slug
            status
            progress
            start_date_time
            end_date_time
            flyer
            game_type
            tournament_type
            tournament_format
            handicap_format
            max_players
            description
            created_at
            updated_at
            winners_race_to
            losers_race_to
            finals_race_to
            handicapped
            tournament_players_aggregate {
                aggregate {
                    count
                }
            }
            director {
                id
                slug
                first_name
                last_name
            }
            venue {
                id
                name
                slug
                city
                country
                region
            }
        }
    }
`;

export const SEARCH_TOURNAMENTS_QUERY_OLD = gql`
    query tournaments($name: String!) {
        tournaments(
            where: {
                is_public: { _eq: true }
                _or: [
                    { name: { _ilike: $name } }
                    { game_type: { _ilike: $name } }
                    { tournament_type: { _ilike: $name } }
                    {
                        venue: {
                            _or: [
                                { name: { _ilike: $name } }
                                { address1: { _ilike: $name } }
                                { city: { _ilike: $name } }
                                { region: { _ilike: $name } }
                                { postal_code: { _ilike: $name } }
                            ]
                        }
                    }
                    {
                        director: {
                            _or: [
                                { first_name: { _ilike: $name } }
                                { last_name: { _ilike: $name } }
                                { display_name: { _ilike: $name } }
                                { nickname: { _ilike: $name } }
                            ]
                        }
                    }
                    {
                        organizer: {
                            _or: [
                                { first_name: { _ilike: $name } }
                                { last_name: { _ilike: $name } }
                                { display_name: { _ilike: $name } }
                                { nickname: { _ilike: $name } }
                            ]
                        }
                    }
                ]
            }
            order_by: { updated_at: desc }
        ) {
            name
            id
            slug
            status
            progress
            start_date_time
            end_date_time
            flyer
            game_type
            tournament_type
            tournament_format
            handicap_format
            max_players
            description
            created_at
            updated_at
            winners_race_to
            losers_race_to
            finals_race_to
            handicapped
            tournament_players_aggregate {
                aggregate {
                    count
                }
            }
            director {
                id
                slug
                first_name
                last_name
            }
            venue {
                id
                name
                slug
                city
                country
                region
            }
        }
    }
`;

export const GET_TOURNAMENT_BRACKET_QUERY = gql`
    query tournament_brackets($tournamentId: Int!) {
        tournament_brackets(where: { tournament_id: { _eq: $tournamentId } }, order_by: { match_number: asc }) {
            id
            hash_id
            round
            identifier
            place
            challenger1 {
                id
                name
                country
                skill_level
                race_to
                place
                seed
                user_id
                fargo_id
                user {
                    id
                    region
                }
            }
            challenger2 {
                id
                name
                country
                skill_level
                race_to
                place
                seed
                user_id
                fargo_id
                user {
                    id
                    region
                }
            }
            tournament_match_sets {
                id
                name
                slug
                number
                match_id
                challenger1_id
                challenger1_score
                challenger1_race_to
                challenger1_points
                challenger1_is_winner
                challenger1_is_forfeit
                challenger1_is_withdraw
                challenger2_id
                challenger2_score
                challenger2_points
                challenger2_race_to
                challenger2_is_winner
                challenger2_is_forfeit
                challenger2_is_withdraw
                start_time
                end_time
            }
            challenger1_id
            challenger1_name
            challenger1_country
            challenger1_score
            challenger1_points
            challenger1_race_to
            challenger1_seed
            challenger1_is_winner
            challenger1_is_forfeit
            challenger1_is_withdraw
            challenger2_id
            challenger2_name
            challenger2_country
            challenger2_score
            challenger2_points
            challenger2_race_to
            challenger2_seed
            challenger2_is_winner
            challenger2_is_forfeit
            challenger2_is_withdraw
            challenger1_sms_sent
            challenger2_sms_sent
            challenger1_is_playing
            challenger2_is_playing
            match_number
            match_id
            match_color
            table_id
            table_name
            scheduled_table_id
            priority
            scheduled_table {
                slug
                label
                type
                size
                year_built
                make
                model
                created_at
                updated_at
                status
            }
            scheduled_time
            start_time
            end_time
            duration
            status
            progress
            loser_from
            loser_from_num
            loser_to
            loser_to_num
            winner_from
            winner_to
            winner_to_num
            winner_from_top_num
            winner_from_bottom_num
            loser_from_top_num
            loser_from_bottom_num
            advance_to
            stage_id
            stage_number
            is_bye
            is_split
            is_semi_finals
            is_finals
            is_consolidation_finals
            livestream_url
            livestream_thumbnail
            scoreboard_balls
            match_custom_text
            match_custom_link
            tournament {
                start_date_time
            }
            pool_table {
                id
                slug
                label
                status
                is_streaming_table
                is_featured_table
                livestream_url
            }
        }
    }
`;

export const GET_TOURNAMENT_BRACKET_WITH_PROJECTIONS_QUERY = gql`
    query tournament_brackets($tournamentId: Int!) {
        tournament_brackets(where: { tournament_id: { _eq: $tournamentId } }, order_by: { match_number: asc }) {
            id
            hash_id
            round
            identifier
            place
            challenger1 {
                id
                name
                country
                skill_level
                race_to
                place
                seed
                user_id
            }
            challenger2 {
                id
                name
                country
                skill_level
                race_to
                place
                seed
                user_id
            }
            challenger1_id
            challenger1_name
            challenger1_country
            challenger1_score
            challenger1_points
            challenger1_race_to
            challenger1_seed
            challenger1_is_winner
            challenger1_is_forfeit
            challenger1_is_withdraw
            challenger2_id
            challenger2_name
            challenger2_country
            challenger2_score
            challenger2_points
            challenger2_race_to
            challenger2_seed
            challenger2_is_winner
            challenger2_is_forfeit
            challenger2_is_withdraw
            challenger1_sms_sent
            challenger2_sms_sent
            challenger1_is_playing
            challenger2_is_playing
            match_number
            match_id
            match_color
            table_id
            table_name
            scheduled_table_id
            priority
            scheduled_time
            start_time
            end_time
            duration
            status
            progress
            loser_from
            loser_from_num
            loser_to
            loser_to_num
            winner_from
            winner_to
            winner_to_num
            winner_from_top_num
            winner_from_bottom_num
            loser_from_top_num
            loser_from_bottom_num
            advance_to
            stage_id
            stage_number
            is_bye
            is_split
            is_semi_finals
            is_finals
            is_consolidation_finals
            livestream_url
            livestream_thumbnail
            scoreboard_balls
            tournament {
                start_date_time
            }
            pool_table {
                id
                slug
                label
                status
                is_streaming_table
                is_featured_table
                livestream_url
            }
            tournament_bracket_projections {
                id
                round
                round_combo
                start_time
                end_time
                match_duration
            }
        }
    }
`;

export const GET_TOURNAMENT_BRACKET_MATCH_QUERY = gql`
    query tournament_brackets($match_id: Int, $tournament_id: Int!) {
        tournament_brackets(where: { id: { _eq: $match_id }, tournament_id: { _eq: $tournament_id } }, order_by: { id: asc }, limit: 1) {
            id
            hash_id
            round
            identifier
            challenger1 {
                id
                name
                country
                skill_level
                race_to
                place
                seed
                user_id
            }
            challenger2 {
                id
                name
                country
                skill_level
                race_to
                place
                seed
                user_id
            }
            tournament_match_sets {
                id
                name
                slug
                number
                match_id
                challenger1_id
                challenger1_score
                challenger1_race_to
                challenger1_points
                challenger1_is_winner
                challenger1_is_forfeit
                challenger1_is_withdraw
                challenger2_id
                challenger2_score
                challenger2_race_to
                challenger2_points
                challenger2_is_winner
                challenger2_is_forfeit
                challenger2_is_withdraw
                start_time
                end_time
            }
            challenger1_id
            challenger1_name
            challenger1_country
            challenger1_score
            challenger1_points
            challenger1_race_to
            challenger1_seed
            challenger1_is_winner
            challenger1_is_forfeit
            challenger1_is_withdraw
            challenger2_id
            challenger2_name
            challenger2_country
            challenger2_score
            challenger2_points
            challenger2_race_to
            challenger2_seed
            challenger2_is_winner
            challenger2_is_forfeit
            challenger2_is_withdraw
            challenger1_sms_sent
            challenger2_sms_sent
            challenger1_is_playing
            challenger2_is_playing
            tournament_player_id
            match_number
            match_id
            match_color
            team_id
            scheduled_table_id
            priority
            scheduled_time
            start_time
            end_time
            duration
            table_id
            table_name
            seed
            status
            progress
            loser_from
            loser_from_num
            loser_to
            loser_to_num
            winner_to
            winner_to_num
            winner_from
            winner_from_top_num
            winner_from_bottom_num
            loser_from_top_num
            loser_from_bottom_num
            advance_to
            stage_id
            stage_number
            is_bye
            is_split
            is_semi_finals
            is_finals
            is_consolidation_finals
            livestream_url
            livestream_thumbnail
            scoreboard_balls

            pool_table {
                id
                slug
                label
                is_streaming_table
                is_featured_table
                livestream_url
                status
            }
        }
    }
`;

export const GET_TOURNAMENT_BRACKET_MATCH_BY_SLUG_QUERY = gql`
    query tournament_brackets($match_id: Int, $tournament_slug: String!) {
        tournament_brackets(where: { match_id: { _eq: $match_id }, tournament: { slug: { _eq: $tournament_slug } } }, order_by: { id: asc }) {
            id
            hash_id
            round
            identifier
            challenger1 {
                id
                name
                country
                skill_level
                race_to
                place
                seed
                user_id
            }
            challenger2 {
                id
                name
                country
                skill_level
                race_to
                place
                seed
                user_id
            }
            challenger1_id
            challenger1_name
            challenger1_country
            challenger1_score
            challenger1_points
            challenger1_race_to
            challenger1_seed
            challenger1_is_winner
            challenger1_is_forfeit
            challenger1_is_withdraw
            challenger2_id
            challenger2_name
            challenger2_country
            challenger2_score
            challenger2_points
            challenger2_race_to
            challenger2_seed
            challenger2_is_winner
            challenger2_is_forfeit
            challenger2_is_withdraw
            challenger1_sms_sent
            challenger2_sms_sent
            challenger1_is_playing
            challenger2_is_playing
            tournament_player_id
            match_number
            match_id
            match_color
            team_id
            scheduled_table_id
            priority
            scheduled_time
            start_time
            end_time
            duration
            table_id
            table_name
            seed
            status
            progress
            loser_from
            loser_from_num
            loser_to
            loser_to_num
            winner_to
            winner_to_num
            winner_from
            winner_from_top_num
            winner_from_bottom_num
            loser_from_top_num
            loser_from_bottom_num
            advance_to
            stage_id
            stage_number
            is_bye
            is_split
            is_semi_finals
            is_finals
            is_consolidation_finals
            livestream_url
            livestream_thumbnail
            scoreboard_balls
            pool_table {
                id
                slug
                label
                is_streaming_table
                is_featured_table
                livestream_url
                status
            }
        }
    }
`;

export const GET_TOURNAMENT_BRACKET_PLAYERS_QUERY = gql`
    query tournament_players($tournament_id: Int) {
        tournament_brackets(
            where: { tournament_id: { _eq: $tournament_id }, _and: { challenger1_name: { _is_null: false }, _or: { challenger2_name: { _is_null: false } } } }
            order_by: { updated_at: desc }
        ) {
            id
            pool_table {
                id
                slug
                label
            }
            challenger1_seed
            challenger1 {
                id
                name
                country
                email
                country_phone
                phone_number
                skill_level
                status
                points
                seed
                race_to
                place
                user {
                    id
                    first_name
                    last_name
                    country
                    email
                    country_phone
                    phone_number
                    skill_level
                    fargo_id
                }
            }
            challenger2_seed
            challenger2 {
                id
                name
                country
                email
                country_phone
                phone_number
                skill_level
                status
                points
                seed
                race_to
                place
                user {
                    id
                    first_name
                    last_name
                    country
                    email
                    country_phone
                    phone_number
                    skill_level
                    fargo_id
                }
            }
        }
    }
`;

export const GET_TOURNAMENT_PLAYERS_QUERY = gql`
    query tournament_players($tournament_id: Int) {
        tournament_players(where: { tournament_id: { _eq: $tournament_id } }, order_by: { name: asc }) {
            id
            name
            nickname
            country
            skill_level
            fargo_id
            status
            points
            seed
            race_to
            place
            country_phone
            phone_number
            user_id
            user {
                id
                first_name
                last_name
                nickname
                display_name
                avatar
                country
                skill_level
                country_phone
                phone_number
                fargo_id
            }
            payouts {
                money
                calcutta
            }
            player_points {
                points
            }
            tournament_matches_challenger1 {
                id
                match_number
                is_bye
                challenger1_id
                challenger1_name
                challenger1_is_winner
                challenger2_id
                challenger2_name
                challenger2_is_winner
            }
            tournament_matches_challenger2 {
                id
                match_number
                is_bye
                challenger1_id
                challenger1_name
                challenger1_is_winner
                challenger2_id
                challenger2_name
                challenger2_is_winner
            }
        }
    }
`;

export const GET_TOURNAMENT_PLAYER_LIST_QUERY = gql`
    query tournament_players($tournament_id: Int) {
        tournament_players(where: { tournament_id: { _eq: $tournament_id } }, order_by: { name: asc }) {
            id
            name
            nickname
            country
            skill_level
            fargo_id
            seed
            race_to
            place
            user_id
            tournament_matches_challenger1 {
                id
                identifier
                round
                match_number
                is_bye
                status
                challenger1_id
                challenger1_name
                challenger1_score
                challenger1_race_to
                challenger1_is_winner
                challenger1_is_forfeit
                challenger1_is_withdraw
                challenger2_id
                challenger2_name
                challenger2_score
                challenger2_race_to
                challenger2_is_winner
                challenger2_is_forfeit
                challenger2_is_withdraw
            }
            tournament_matches_challenger2 {
                id
                identifier
                round
                match_number
                is_bye
                status
                challenger1_id
                challenger1_name
                challenger1_score
                challenger1_race_to
                challenger1_is_winner
                challenger1_is_forfeit
                challenger1_is_withdraw
                challenger2_id
                challenger2_name
                challenger2_score
                challenger2_race_to
                challenger2_is_winner
                challenger2_is_forfeit
                challenger2_is_withdraw
            }
        }
    }
`;

export const GET_TABLE_QUERY = gql`
    query pool_tables($id: Int) {
        pool_tables(where: { id: { _eq: $id } }) {
            id
            slug
            label
            type
            size
            year_built
            make
            model
            created_at
            updated_at
            is_streaming_table
            is_featured_table
            status
            manufacturer {
                id
                name
            }
            venue {
                id
                name
                slug
            }
            user {
                id
                email
            }
        }
    }
`;

export const GET_SCHEDULED_TABLES_QUERY = gql`
    query pool_tables($tournament_id: Int) {
        pool_tables(where: { tournament_id: { _eq: $tournament_id } }, order_by: { label: asc }) {
            id
            slug
            label
            type
            size
            year_built
            make
            model
            created_at
            updated_at
            is_streaming_table
            is_featured_table
            status
            scheduled_matches(where: { status: { _neq: COMPLETED } }) {
                id
                hash_id
                round
                identifier
                challenger1_id
                challenger1_name
                challenger1_country
                challenger1_score
                challenger1_race_to
                challenger1_seed
                challenger1_is_winner
                challenger1_is_forfeit
                challenger1_seed
                challenger2_id
                challenger2_name
                challenger2_country
                challenger2_score
                challenger2_race_to
                challenger2_seed
                challenger2_is_winner
                challenger2_is_forfeit
                tournament_player_id
                match_number
                match_id
                team_id
                scheduled_time
                priority
                start_time
                end_time
                duration
                table_id
                table_name
                seed
                status
                progress
                loser_from
                loser_from_num
                loser_to
                loser_to_num
                winner_from
                winner_to
                winner_to_num
                winner_from_top_num
                winner_from_bottom_num
                loser_from_top_num
                loser_from_bottom_num
                advance_to
                stage_id
                stage_number
                is_bye
                is_semi_finals
                is_finals
                is_consolidation_finals
            }
        }
    }
`;

export const GET_TOURNAMENT_TABLES_NAMES_QUERY = gql`
    query pool_tables($tournament_id: Int!, $status: pool_table_status_enum) {
        pool_tables(where: { tournament_id: { _eq: $tournament_id }, _and: { status: { _eq: $status } } }) {
            id
            slug
            label
        }
    }
`;

export const GET_ALL_TOURNAMENT_TABLES_QUERY = gql`
    query pool_tables($tournament_id: Int) {
        pool_tables(where: { tournament_id: { _eq: $tournament_id } }, order_by: { updated_at: desc }) {
            id
            slug
            label
            type
            size
            year_built
            make
            model
            created_at
            updated_at
            status
            livestream_url
            is_streaming_table
            is_featured_table
            manufacturer {
                id
                name
            }
            venue {
                id
                name
            }
            user {
                id
                email
            }
            tournament_match_table {
                id
                hash_id
                round
                identifier
                challenger1 {
                    id
                    name
                    country
                    skill_level
                    race_to
                    seed
                }
                challenger2 {
                    id
                    name
                    country
                    skill_level
                    race_to
                    seed
                }
                challenger1_id
                challenger1_name
                challenger1_country
                challenger1_score
                challenger1_points
                challenger1_race_to
                challenger1_seed
                challenger1_is_winner
                challenger1_is_forfeit
                challenger1_is_withdraw
                challenger2_id
                challenger2_name
                challenger2_country
                challenger2_score
                challenger2_points
                challenger2_race_to
                challenger2_seed
                challenger2_is_winner
                challenger2_is_forfeit
                challenger2_is_withdraw
                challenger1_sms_sent
                challenger2_sms_sent
                challenger1_is_playing
                challenger2_is_playing
                tournament_player_id
                match_number
                match_id
                match_color
                team_id
                scheduled_table_id
                priority
                scheduled_time
                start_time
                end_time
                duration
                table_id
                table_name
                seed
                status
                progress
                loser_from
                loser_from_num
                loser_to
                loser_to_num
                winner_from
                winner_to
                winner_to_num
                winner_from_top_num
                winner_from_bottom_num
                loser_from_top_num
                loser_from_bottom_num
                advance_to
                stage_id
                stage_number
                is_bye
                is_split
                is_semi_finals
                is_finals
                is_consolidation_finals
                is_scoring_started
                livestream_url
                livestream_thumbnail
                scoreboard_balls
            }
        }
    }
`;

export const GET_ALL_TOURNAMENT_TABLES_OPTIMIZED_QUERY = gql`
    query pool_tables($tournament_id: Int) {
        pool_tables(where: { tournament_id: { _eq: $tournament_id } }, order_by: { updated_at: desc }) {
            id
            slug
            label
            status
            livestream_url
            is_streaming_table
            is_featured_table
        }
    }
`;

export const GET_ALL_TOURNAMENT_TABLES_SIDEPANEL_QUERY = gql`
    query pool_tables($tournament_id: Int) {
        pool_tables(where: { tournament_id: { _eq: $tournament_id } }, order_by: { updated_at: desc }) {
            id
            slug
            label
            type
            size
            year_built
            make
            model
            created_at
            updated_at
            status
            livestream_url
            is_streaming_table
            is_featured_table
            manufacturer {
                id
                name
            }
            venue {
                id
                name
            }
            user {
                id
                email
            }
            tournament_match_table {
                id
                hash_id
                round
                identifier
                challenger1 {
                    id
                    name
                    country
                    skill_level
                    race_to
                    seed
                }
                challenger2 {
                    id
                    name
                    country
                    skill_level
                    race_to
                    seed
                }
                challenger1_id
                challenger1_name
                challenger1_country
                challenger1_score
                challenger1_points
                challenger1_race_to
                challenger1_seed
                challenger1_is_winner
                challenger1_is_forfeit
                challenger1_is_withdraw
                challenger2_id
                challenger2_name
                challenger2_country
                challenger2_score
                challenger2_points
                challenger2_race_to
                challenger2_seed
                challenger2_is_winner
                challenger2_is_forfeit
                challenger2_is_withdraw
                challenger1_sms_sent
                challenger2_sms_sent
                challenger1_is_playing
                challenger2_is_playing
                tournament_player_id
                match_number
                match_id
                match_color
                team_id
                scheduled_time
                start_time
                end_time
                duration
                table_id
                table_name
                seed
                status
                progress
                loser_from
                loser_from_num
                loser_to
                loser_to_num
                winner_from
                winner_to
                winner_to_num
                winner_from_top_num
                winner_from_bottom_num
                loser_from_top_num
                loser_from_bottom_num
                advance_to
                stage_id
                stage_number
                is_bye
                is_split
                is_semi_finals
                is_finals
                is_consolidation_finals
                is_scoring_started
                livestream_url
                livestream_thumbnail
                scoreboard_balls
            }
        }
    }
`;

export const GET_TOURNAMENT_TABLES_QUERY = gql`
    query pool_tables($tournament_id: Int, $status: String!) {
        pool_tables(where: { tournament_id: { _eq: $tournament_id }, _and: { status: { _eq: $status } } }, order_by: { updated_at: desc }) {
            id
            slug
            label
            type
            size
            year_built
            make
            model
            created_at
            updated_at
            status
            livestream_url
            is_streaming_table
            is_featured_table
            manufacturer {
                id
                name
            }
            venue {
                id
                name
            }
            user {
                id
                email
            }
            tournament_match_table {
                id
                hash_id
                round
                identifier
                challenger1 {
                    id
                    name
                    country
                    skill_level
                    race_to
                    seed
                }
                challenger2 {
                    id
                    name
                    country
                    skill_level
                    race_to
                    seed
                }
                challenger1_id
                challenger1_name
                challenger1_country
                challenger1_score
                challenger1_points
                challenger1_race_to
                challenger1_seed
                challenger1_is_winner
                challenger1_is_forfeit
                challenger1_is_withdraw
                challenger2_id
                challenger2_name
                challenger2_country
                challenger2_score
                challenger2_points
                challenger2_race_to
                challenger2_seed
                challenger2_is_winner
                challenger2_is_forfeit
                challenger2_is_withdraw
                challenger1_sms_sent
                challenger2_sms_sent
                challenger1_is_playing
                challenger2_is_playing
                tournament_player_id
                match_number
                match_id
                match_color
                team_id
                scheduled_time
                start_time
                end_time
                duration
                table_id
                table_name
                seed
                status
                progress
                loser_from
                loser_from_num
                loser_to
                loser_to_num
                winner_from
                winner_to
                winner_to_num
                winner_from_top_num
                winner_from_bottom_num
                loser_from_top_num
                loser_from_bottom_num
                advance_to
                stage_id
                stage_number
                is_bye
                is_split
                is_semi_finals
                is_finals
                is_consolidation_finals
                is_scoring_started
                livestream_url
                livestream_thumbnail
                scoreboard_balls
            }
        }
    }
`;

export const GET_TOURNAMENT_TABLE_QUERY = gql`
    query pool_tables($tournament_id: Int!, $table_slug: String!) {
        pool_tables(where: { tournament_id: { _eq: $tournament_id }, slug: { _eq: $table_slug } }) {
            id
            slug
            label
            type
            size
            year_built
            make
            model
            created_at
            updated_at
            status
            is_streaming_table
            is_featured_table
            livestream_url
            manufacturer {
                id
                name
            }
            venue {
                id
                name
            }
            user {
                id
                email
                country
            }
            tournament_match_table(where: { tournament_id: { _eq: $tournament_id }, status: { _neq: COMPLETED } }, order_by: { status: desc }, limit: 1) {
                id
                hash_id
                round
                identifier
                place
                challenger1 {
                    id
                    name
                    country
                    skill_level
                    place
                    race_to
                    seed
                }
                challenger2 {
                    id
                    name
                    country
                    skill_level
                    place
                    race_to
                    seed
                }
                challenger1_id
                challenger1_name
                challenger1_country
                challenger1_score
                challenger1_points
                challenger1_race_to
                challenger1_skill_level
                challenger1_seed
                challenger1_is_winner
                challenger1_is_forfeit
                challenger1_is_withdraw
                challenger2_id
                challenger2_name
                challenger2_country
                challenger2_score
                challenger2_points
                challenger2_race_to
                challenger2_skill_level
                challenger2_seed
                challenger2_is_winner
                challenger2_is_forfeit
                challenger2_is_withdraw
                challenger1_sms_sent
                challenger2_sms_sent
                challenger1_is_playing
                challenger2_is_playing
                tournament_player_id
                match_number
                match_id
                match_color
                team_id
                scheduled_time
                start_time
                end_time
                duration
                table_id
                table_name
                seed
                status
                progress
                loser_from
                loser_from_num
                loser_to
                loser_to_num
                winner_from
                winner_to
                winner_to_num
                winner_from_top_num
                winner_from_bottom_num
                loser_from_top_num
                loser_from_bottom_num
                advance_to
                stage_id
                stage_number
                is_bye
                is_split
                is_semi_finals
                is_finals
                is_consolidation_finals
                is_scoring_started
                livestream_url
                livestream_thumbnail
                scoreboard_balls
            }
        }
    }
`;

export const FILTER_TOURNAMENT_PLAYERS = gql`
    query tournament_players($name: String!, $tournament_id: Int!) {
        tournament_players(where: { name: { _ilike: $name }, _and: { tournament_id: { _eq: $tournament_id } } }, order_by: { name: asc }) {
            id
            name
            nickname
            country
            email
            country_phone
            phone_number
            skill_level
            fargo_id
            seed
        }
    }
`;

export const GET_TOURNAMENT_PLAYER_MATCHES_QUERY = gql`
    query vw_player_tournament_matches($tournament_id: Int!, $player_name: String) {
        vw_player_tournament_matches(where: { tournament_id: { _eq: $tournament_id }, player_name: { _eq: $player_name } }) {
            match_duration
            match_number
            match_rating
            opponent_name
            opponents_score
            player_name
            players_score
            tournament_id
            identifier
            opponent_skill_level
            opponent_won
            player_skill_level
            player_won
        }
    }
`;

export const GET_TOURNAMENT_PLAYER_QUERY = gql`
    query tournament_players($id: Int, $tournament_id: Int!) {
        tournament_players(where: { tournament_id: { _eq: $tournament_id }, id: { _eq: $id } }) {
            id
            name
            nickname
            country
            email
            country_phone
            phone_number
            skill_level
            fargo_id
            status
            points
            place
            seed
            race_to
            special_group
            user_id
            payouts {
                money
                calcutta
            }
            player_points {
                points
            }
            user {
                id
                first_name
                last_name
                nickname
                display_name
                slug
                avatar
                country
                email
                country_phone
                phone_number
                skill_level
                fargo_id
            }
            tournament_matches_challenger1 {
                id
                identifier
                round
                match_number
                is_bye
                status
                challenger1_id
                challenger1_name
                challenger1_score
                challenger1_race_to
                challenger1_is_winner
                challenger1_is_forfeit
                challenger1_is_withdraw
                challenger2_id
                challenger2_name
                challenger2_score
                challenger2_race_to
                challenger2_is_winner
                challenger2_is_forfeit
                challenger2_is_withdraw
            }
            tournament_matches_challenger2 {
                id
                identifier
                round
                match_number
                is_bye
                status
                challenger1_id
                challenger1_name
                challenger1_score
                challenger1_race_to
                challenger1_is_winner
                challenger1_is_forfeit
                challenger1_is_withdraw
                challenger2_id
                challenger2_name
                challenger2_score
                challenger2_race_to
                challenger2_is_winner
                challenger2_is_forfeit
                challenger2_is_withdraw
            }
            challenger1_matches_won: tournament_matches_challenger1_aggregate(
                where: { challenger1_is_winner: { _eq: true } }
                order_by: { match_number: asc }
            ) {
                aggregate {
                    count
                    sum {
                        challenger1_score
                        challenger2_score
                    }
                }
                nodes {
                    id
                    identifier
                    round
                    match_number
                    is_bye
                    status
                    challenger1_id
                    challenger1_name
                    challenger1_score
                    challenger1_race_to
                    challenger1_is_winner
                    challenger1_is_forfeit
                    challenger1_is_withdraw
                    challenger2_id
                    challenger2_name
                    challenger2_score
                    challenger2_race_to
                    challenger2_is_winner
                    challenger2_is_forfeit
                    challenger2_is_withdraw
                }
            }
            challenger1_matches_lost: tournament_matches_challenger1_aggregate(
                where: { challenger1_is_winner: { _eq: false } }
                order_by: { match_number: asc }
            ) {
                aggregate {
                    count
                    sum {
                        challenger1_score
                        challenger2_score
                    }
                }
                nodes {
                    id
                    identifier
                    round
                    match_number
                    is_bye
                    status
                    challenger1_id
                    challenger1_name
                    challenger1_score
                    challenger1_race_to
                    challenger1_is_winner
                    challenger1_is_forfeit
                    challenger1_is_withdraw
                    challenger2_id
                    challenger2_name
                    challenger2_score
                    challenger2_race_to
                    challenger2_is_winner
                    challenger2_is_forfeit
                    challenger2_is_withdraw
                }
            }
            challenger2_matches_won: tournament_matches_challenger2_aggregate(
                where: { challenger2_is_winner: { _eq: true } }
                order_by: { match_number: asc }
            ) {
                aggregate {
                    count
                    sum {
                        challenger1_score
                        challenger2_score
                    }
                }
                nodes {
                    id
                    identifier
                    round
                    match_number
                    is_bye
                    status
                    challenger1_id
                    challenger1_name
                    challenger1_score
                    challenger1_race_to
                    challenger1_is_winner
                    challenger1_is_forfeit
                    challenger1_is_withdraw
                    challenger2_id
                    challenger2_name
                    challenger2_score
                    challenger2_race_to
                    challenger2_is_winner
                    challenger2_is_forfeit
                    challenger2_is_withdraw
                }
            }
            challenger2_matches_lost: tournament_matches_challenger2_aggregate(
                where: { challenger2_is_winner: { _eq: false } }
                order_by: { match_number: asc }
            ) {
                aggregate {
                    count
                    sum {
                        challenger1_score
                        challenger2_score
                    }
                }
                nodes {
                    id
                    identifier
                    round
                    match_number
                    is_bye
                    status
                    challenger1_id
                    challenger1_name
                    challenger1_score
                    challenger1_race_to
                    challenger1_is_winner
                    challenger1_is_forfeit
                    challenger1_is_withdraw
                    challenger2_id
                    challenger2_name
                    challenger2_score
                    challenger2_race_to
                    challenger2_is_winner
                    challenger2_is_forfeit
                    challenger2_is_withdraw
                }
            }
        }
    }
`;

export const GET_TOURNAMENT_PLAYER_STANDINGS_QUERY = gql`
    query tournament_player_stats($tournament_id: Int!) {
        tournament_players(where: { tournament_id: { _eq: $tournament_id }, place: { _neq: "null" } }, distinct_on: id) {
            id
            name
            nickname
            place
            country
            skill_level
            fargo_id
            payouts {
                money
                calcutta
            }
            player_points {
                points
            }
            tournament_matches_challenger1 {
                id
                identifier
                round
                match_number
                is_bye
                status
                challenger1_id
                challenger1_name
                challenger1_score
                challenger1_race_to
                challenger1_is_winner
                challenger1_is_forfeit
                challenger1_is_withdraw
                challenger2_id
                challenger2_name
                challenger2_score
                challenger2_race_to
                challenger2_is_winner
                challenger2_is_forfeit
                challenger2_is_withdraw
            }
            tournament_matches_challenger2 {
                id
                identifier
                round
                match_number
                is_bye
                status
                challenger1_id
                challenger1_name
                challenger1_score
                challenger1_race_to
                challenger1_is_winner
                challenger1_is_forfeit
                challenger1_is_withdraw
                challenger2_id
                challenger2_name
                challenger2_score
                challenger2_race_to
                challenger2_is_winner
                challenger2_is_forfeit
                challenger2_is_withdraw
            }
        }
    }
`;

export const GET_TOURNAMENT_PLAYER_STATS_QUERY2 = gql`
    query vw_tournament_statistics($tournament_id: Int!, $player_id: Int!) {
        vw_tournament_statistics(where: { tournament_id: { _eq: $tournament_id }, player_id: { _eq: $player_id } }) {
            game_record
            game_win_percentage
            match_record
            match_win_percentage
            place
            player_name
            player_country
            points
            tournament_id
        }
    }
`;

export const GET_TOURNAMENT_PLAYER_STATS_QUERY = gql`
    query tournament_player_stats($tournament_id: Int!) {
        tournament_players(where: { tournament_id: { _eq: $tournament_id }, place: { _neq: "null" } }, order_by: { place: asc }) {
            id
            name
            nickname
            place
            country
            skill_level
            challenger1_matches_won: tournament_matches_challenger1_aggregate(
                where: { challenger1_is_winner: { _eq: true } }
                order_by: { match_number: asc }
            ) {
                aggregate {
                    count
                    sum {
                        challenger1_score
                        challenger2_score
                    }
                }
                nodes {
                    identifier
                    round
                    match_number
                    challenger1_id
                    challenger1_name
                    challenger1_score
                    challenger1_is_winner
                    challenger1_is_forfeit
                    challenger1_is_withdraw
                    challenger2_id
                    challenger2_name
                    challenger2_score
                    challenger2_is_winner
                    challenger2_is_forfeit
                    challenger2_is_withdraw
                }
            }
            challenger1_matches_lost: tournament_matches_challenger1_aggregate(
                where: { challenger1_is_winner: { _eq: false } }
                order_by: { match_number: asc }
            ) {
                aggregate {
                    count
                    sum {
                        challenger1_score
                        challenger2_score
                    }
                }
                nodes {
                    identifier
                    round
                    match_number
                    challenger1_id
                    challenger1_name
                    challenger1_score
                    challenger1_is_winner
                    challenger1_is_forfeit
                    challenger1_is_withdraw
                    challenger2_id
                    challenger2_name
                    challenger2_score
                    challenger2_is_winner
                    challenger2_is_forfeit
                    challenger2_is_withdraw
                }
            }
            challenger2_matches_won: tournament_matches_challenger2_aggregate(
                where: { challenger2_is_winner: { _eq: true } }
                order_by: { match_number: asc }
            ) {
                aggregate {
                    count
                    sum {
                        challenger1_score
                        challenger2_score
                    }
                }
                nodes {
                    identifier
                    round
                    match_number
                    challenger1_id
                    challenger1_name
                    challenger1_score
                    challenger1_is_winner
                    challenger1_is_forfeit
                    challenger1_is_withdraw
                    challenger2_id
                    challenger2_name
                    challenger2_score
                    challenger2_is_winner
                    challenger2_is_forfeit
                    challenger2_is_withdraw
                }
            }
            challenger2_matches_lost: tournament_matches_challenger2_aggregate(
                where: { challenger2_is_winner: { _eq: false } }
                order_by: { match_number: asc }
            ) {
                aggregate {
                    count
                    sum {
                        challenger1_score
                        challenger2_score
                    }
                }
                nodes {
                    identifier
                    round
                    match_number
                    challenger1_id
                    challenger1_name
                    challenger1_score
                    challenger1_is_winner
                    challenger1_is_forfeit
                    challenger1_is_withdraw
                    challenger2_id
                    challenger2_name
                    challenger2_score
                    challenger2_is_winner
                    challenger2_is_forfeit
                    challenger2_is_withdraw
                }
            }
        }
    }
`;

export const GET_TOURNAMENT_POINTS = gql`
    query tournament_points($tournament_id: Int!) {
        tournament_points(where: { tournament_id: { _eq: $tournament_id } }) {
            id
            place
            points
        }
    }
`;

export const GET_TOURNAMENT_PAYOUTS = gql`
    query tournament_points($tournament_id: Int!) {
        tournament_payouts(where: { tournament_id: { _eq: $tournament_id } }) {
            id
            place
            money
        }
    }
`;

export const SEARCH_TOURNAMENT_PLAYERS = gql`
    query tournament_players($name: String!, $nickname: String!, $tournament_id: Int) {
        tournament_players(
            distinct_on: name
            where: { tournament_id: { _eq: $tournament_id }, _or: [{ name: { _ilike: $name } }, { nickname: { _ilike: $nickname } }] }
        ) {
            id
            user_id
            name
            nickname
            email
            country
            nickname
            country_phone
            phone_number
            skill_level
            fargo_id
            seed
            status
        }
    }
`;

export const GET_FILTERED_PLAYER_BRACKET = gql`
    query tournament_brackets($tournament_id: Int!, $challenger_id: Int!) {
        tournament_brackets(
            where: { tournament_id: { _eq: $tournament_id }, _or: [{ challenger1_id: { _eq: $challenger_id } }, { challenger2_id: { _eq: $challenger_id } }] }
            order_by: { id: desc }
            limit: 1
        ) {
            id
            identifier
        }
    }
`;

// export const GET_TOURNAMENT_ROUND_TIMES = gql`
// 	query tournament_bracket_schedules($tournament_id: Int!) {
// 		tournament_bracket_schedules(where: { tournament_id: { _eq: $tournament_id } }) {
// 			id
// 			tournament_id
// 			round
// 			scheduled_time
// 			created_at
// 			updated_at
// 		}
// 	}
// `;

export const GET_EVENT_LIVE_TABLES_QUERY = gql`
    query tournaments($event_id: Int!) {
        tournaments(where: { event: { id: { _eq: $event_id } } }) {
            id
            name
            slug
            pool_tables(order_by: { updated_at: desc }) {
                id
                slug
                label
                type
                size
                year_built
                make
                model
                created_at
                updated_at
                status
                livestream_url
                is_streaming_table
                is_featured_table
                manufacturer {
                    id
                    name
                }
                venue {
                    id
                    name
                }
                user {
                    id
                    email
                }
                tournament_match_table {
                    id
                    hash_id
                    round
                    identifier
                    challenger1 {
                        id
                        name
                        country
                        skill_level
                        race_to
                        seed
                    }
                    challenger2 {
                        id
                        name
                        country
                        skill_level
                        race_to
                        seed
                    }
                    challenger1_id
                    challenger1_name
                    challenger1_country
                    challenger1_score
                    challenger1_points
                    challenger1_race_to
                    challenger1_seed
                    challenger1_is_winner
                    challenger1_is_forfeit
                    challenger1_is_withdraw
                    challenger2_id
                    challenger2_name
                    challenger2_country
                    challenger2_score
                    challenger2_points
                    challenger2_race_to
                    challenger2_seed
                    challenger2_is_winner
                    challenger2_is_forfeit
                    challenger2_is_withdraw
                    challenger1_sms_sent
                    challenger2_sms_sent
                    challenger1_is_playing
                    challenger2_is_playing
                    tournament_player_id
                    match_number
                    match_id
                    match_color
                    team_id
                    scheduled_time
                    start_time
                    end_time
                    duration
                    table_id
                    table_name
                    seed
                    status
                    progress
                    loser_from
                    loser_from_num
                    loser_to
                    loser_to_num
                    winner_from
                    winner_to
                    winner_to_num
                    winner_from_top_num
                    winner_from_bottom_num
                    loser_from_top_num
                    loser_from_bottom_num
                    advance_to
                    stage_id
                    stage_number
                    is_bye
                    is_split
                    is_semi_finals
                    is_finals
                    is_consolidation_finals
                    is_scoring_started
                    livestream_url
                    livestream_thumbnail
                    scoreboard_balls
                }
            }
        }
    }
`;

export const GET_EVENT_LIVE_TABLE_QUERY = gql`
    query tournaments($event_slug: String!, $table_slug: String!) {
        tournaments(where: { event: { slug: { _eq: $event_slug } } }) {
            id
            name
            slug
            description
            avatar
            flyer
            logo
            tournament_type
            game_type
            winners_race_to
            losers_race_to
            finals_race_to
            status
            progress
            start_date_time
            end_date_time
            use_text_messaging
            rsvp_allowed
            show_player_skill_levels
            show_player_races
            show_unconfirmed_players
            is_public
            signup_cutoff_time
            pool_tables(where: { slug: { _eq: $table_slug } }, limit: 1) {
                id
                slug
                label
                status
                is_streaming_table
                livestream_url
                tournament_match_table(where: { status: { _neq: COMPLETED } }, order_by: { updated_at: desc }) {
                    id
                    hash_id
                    round
                    identifier
                    place
                    challenger1 {
                        id
                        name
                        country
                        skill_level
                        fargo_id
                        place
                        race_to
                        seed
                    }
                    challenger2 {
                        id
                        name
                        country
                        skill_level
                        fargo_id
                        place
                        race_to
                        seed
                    }
                    challenger1_id
                    challenger1_name
                    challenger1_country
                    challenger1_score
                    challenger1_points
                    challenger1_race_to
                    challenger1_skill_level
                    challenger1_seed
                    challenger1_is_winner
                    challenger1_is_forfeit
                    challenger1_is_withdraw
                    challenger2_id
                    challenger2_name
                    challenger2_country
                    challenger2_score
                    challenger2_points
                    challenger2_race_to
                    challenger2_skill_level
                    challenger2_seed
                    challenger2_is_winner
                    challenger2_is_forfeit
                    challenger2_is_withdraw
                    challenger1_sms_sent
                    challenger2_sms_sent
                    challenger1_is_playing
                    challenger2_is_playing
                    tournament_player_id
                    match_number
                    match_id
                    match_color
                    team_id
                    scheduled_time
                    start_time
                    end_time
                    duration
                    table_id
                    table_name
                    seed
                    status
                    progress
                    is_scoring_started
                    tournament_match_games {
                        id
                        game_number
                        end_time
                        duration
                        created_at
                        match_number
                        opponent_balls_pocketed
                        opponent_break_run
                        opponent_id
                        opponent_is_winner
                        opponent_points
                        opponent_runout
                        opponent_score
                        opponent_tpa
                        opponent_unforced_errors
                        player_balls_pocketed
                        player_break_run
                        player_id
                        player_is_winner
                        player_points
                        player_runout
                        player_score
                        player_tpa
                        player_unforced_errors
                        progress
                        start_time
                        status
                    }
                }
            }
        }
    }
`;

export const GET_TOURNAMENT_STREAM_OVERLAY_QUERY = gql`
    query pool_tables($tournament_id: Int) {
        pool_tables(where: { tournament_id: { _eq: $tournament_id } }, order_by: { label: asc }) {
            id
            slug
            label
            type
            size
            year_built
            make
            model
            created_at
            updated_at
            status
            is_streaming_table
            is_featured_table
            livestream_url
            manufacturer {
                id
                name
            }
            venue {
                id
                name
            }
            user {
                id
                email
                country
            }
            tournament_match_table(order_by: { updated_at: desc }) {
                id
                hash_id
                round
                identifier
                place
                challenger1 {
                    id
                    name
                    country
                    skill_level
                    fargo_id
                    race_to
                    place
                    seed
                    user_id
                    user {
                        id
                        region
                    }
                }
                challenger2 {
                    id
                    name
                    country
                    skill_level
                    fargo_id
                    race_to
                    place
                    seed
                    user_id
                    user {
                        id
                        region
                    }
                }
                challenger1_id
                challenger1_name
                challenger1_country
                challenger1_score
                challenger1_points
                challenger1_race_to
                challenger1_skill_level
                challenger1_seed
                challenger1_is_winner
                challenger1_is_forfeit
                challenger1_is_withdraw
                challenger2_id
                challenger2_name
                challenger2_country
                challenger2_score
                challenger2_points
                challenger2_race_to
                challenger2_skill_level
                challenger2_seed
                challenger2_is_winner
                challenger2_is_forfeit
                challenger2_is_withdraw
                challenger1_sms_sent
                challenger2_sms_sent
                challenger1_is_playing
                challenger2_is_playing
                tournament_player_id
                match_number
                match_id
                match_color
                team_id
                scheduled_time
                start_time
                end_time
                duration
                table_id
                table_name
                seed
                status
                progress
                loser_from
                loser_from_num
                loser_to
                loser_to_num
                winner_from
                winner_to
                winner_to_num
                winner_from_top_num
                winner_from_bottom_num
                loser_from_top_num
                loser_from_bottom_num
                advance_to
                stage_id
                stage_number
                is_bye
                is_split
                is_semi_finals
                is_finals
                is_consolidation_finals
                is_scoring_started
                livestream_url
                livestream_thumbnail
                scoreboard_balls
            }
        }
    }
`;
