import React, { useState } from 'react';
import { Link, withRouter, Switch, Route } from 'react-router-dom';
import { message, Descriptions, Table, Avatar, Empty, Icon, Tag, Typography, Row, Col, List, Skeleton, Button, Radio, Modal } from 'antd';
import SectionContent from '../../../components/SectionContent';
import SectionTitle from '../../../components/SectionTitle';
import SectionWrapper from '../../../components/SectionWrapper';
import SectionHeader from '../../../components/SectionHeader';
import BreadcrumbContainer from '../../../components/BreadcrumbContainer';
import ZeroState from '../../../components/ZeroState';
import Card, { Meta } from '../../../components/Card';
import { getGameType, displayEnumType } from '../../../utils';
import CircularLoader from '../../../components/CircularLoader';
import blueBg from '../../../assets/img/blue-bg.png';
import { Query, Mutation, Subscription } from '@apollo/client/react/components';
import { GET_LEAGUE_MATCH_BY_SLUG_QUERY, GET_LEAGUE_MATCH_QUERY } from '../data/queries';
import { GET_LEAGUE_MATCH_BY_SLUG_SUBSCRIPTION, GET_LEAGUE_DIVISON_MATCH_STATS_SUBSCRIPTION } from '../data/subscriptions';
import { UPDATE_LEAGUE_MATCH } from '../data/mutations';
import TableScoreCard from '../tournaments/TableScoreCard';
import MatchStatsTable from './MatchStatsTable';
import MatchScoringModal from '../../league-manager/MatchScoringModal';
import BasicMatchScoring from '../../../components/scoring/BasicMatchScoring';
import { useMutation, useQuery } from '@apollo/react-hooks';
import useBreakpoint from 'use-breakpoint';
import { defaultBreakpoints } from '../../../utils/defaultBreakpoints';
import defaultImg from '../../../assets/img/ball/default.png';
import moment from 'moment';
import FramesList from '../../league-manager/divisions/FramesList';
import numeral from 'numeral';

const { Title, Text } = Typography;
const { confirm } = Modal;

function DPDivisionMatchDetails(props) {
    const { league, division, history, authState } = props;
    const [loading, setLoading] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [updateLeagueMatch, { updateLoading, updateError }] = useMutation(UPDATE_LEAGUE_MATCH);
    const params = props.match.params;
    const slug = params.match;
    const { breakpoint, maxWidth, minWidth } = useBreakpoint(defaultBreakpoints);

    function handleModalOk(e) {
        console.log(e);
        setModalVisible(false);
    }

    function handleModalCancel(e) {
        setModalVisible(false);
    }

    function showConfirmStart(match) {
        confirm({
            title: 'Are you ready to start the match?',
            content: 'This action will start the match clock and enable live scoring.',
            centered: true,
            transitionName: 'fade',
            maskTransitionName: 'none',
            okText: 'Start Match',
            cancelText: "I'll do this later",
            onOk() {
                updateLeagueMatch({
                    variables: {
                        id: match.id,
                        changes: {
                            start_time: moment.utc(),
                            status: 'IN_PROGRESS',
                            progress: '0',
                        },
                    },
                    notifyOnNetworkStatusChange: true,
                    awaitRefetchQueries: true,
                    refetchQueries: [
                        {
                            query: GET_LEAGUE_MATCH_QUERY,
                            variables: { match_id: match.id },
                        },
                    ],
                })
                    .then((res) => {
                        console.log(res);
                        setLoading(false);
                        message.success('Match started');
                        props.history.push(`/league-manager/${league.slug}/matches/${match.slug}/scoring`);

                        // updateLeagueMatchPlayer({
                        // 	variables: {
                        // 		league_id: leagueID,
                        // 		league_match_id: match.id,
                        // 		objects: match.league_match_players.map((player, index) => {
                        // 			return {
                        // 				league_id: leagueID,
                        // 				league_match_id: match.id,
                        // 				league_player_id: player.id,
                        // 				division_id: divisionID,
                        // 				score: index === 0 ? parseInt(challenger1_score) : parseInt(challenger2_score)
                        // 			};
                        // 		})
                        // 	},
                        // 	awaitRefetchQueries: true,
                        // 	refetchQueries: [
                        // 		{
                        // 			query: GET_LEAGUE_MATCHES_QUERY,
                        // 			variables: { league_id: leagueID }
                        // 		}
                        // 	]
                        // })
                        // 	.then((data) => {
                        // 		console.log(data);
                        // 		setLoading(false);
                        // 		message.success('Changes saved');
                        // 		onModalOk();
                        // 	})
                        // 	.catch((error) => {
                        // 		console.log(error);
                        // 		setLoading(false);
                        // 		message.info('There was an error', error);
                        // 		onModalOk();
                        // 	});
                    })
                    .catch((error) => {
                        console.log(error);
                        setLoading(false);
                        message.error('There was an error', error);
                    });
            },
            onCancel() {},
        });
    }

    const styles = {
        headerImg: {
            background: `linear-gradient(45deg, rgb(121, 0, 160), rgb(0, 78, 187)) 0% 0% / 100% no-repeat fixed`,
            // background: `linear-gradient(0deg, rgba(0,0,0,0.2), rgba(0,0,0, 0.2)), url(${blueBg})`,
            backgroundAttachment: 'fixed',
            backgroundRepeat: 'no-repeat',
            backgroundSize: '100%',
            // backgroundPosition: '0px -400px'

            // backgroundPosition: '0px -40px'
        },
        header: {
            // background: `linear-gradient(rgba(61, 185, 248, 0.8), rgba(24, 144, 255, 0.1)), url(${blueBg})`,
            // backgroundRepeat: 'no-repeat',
            // backgroundSize: '100%',
            paddingTop: 16,
            paddingLeft: breakpoint === 'mobile' ? 10 : 30,
            paddingRight: breakpoint === 'mobile' ? 10 : 30,
            paddingBottom: 16,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
    };

    // TPA formula = balls pocketed / balls pocketed + errors
    function calculateTPA(challenger, matchData) {
        let ballsPocketed;
        let errors;
        if (challenger === 'challenger1') {
            ballsPocketed = matchData.challenger1_balls_pocketed;
            errors = matchData.challenger1_errors;
        } else {
            ballsPocketed = matchData.challenger2_balls_pocketed;
            errors = matchData.challenger2_errors;
        }
        const tpa = numeral(ballsPocketed / (ballsPocketed + errors)).format('0.000');
        return tpa;
    }

    function orderAndFormatData(match) {
        let formattedResults = [];

        console.log('match', match);

        const player1 = {
            id: match.challenger1_id,
            name: match.challenger1_name,
            is_winner: match.challenger1_is_winner,
            country: match.challenger1_country,
            totalScore: match.challenger1_score,
            framesWon: match.challenger1_frames,
            framesLost: match.challenger2_frames,
            totalFrames: match.league_match_frames_aggregate.aggregate.sum.challenger1_frames,
            pointsWon: match.challenger1_points,
            pointsLost: match.challenger2_points,
            totalPoints: match.league_match_frames_aggregate.aggregate.sum.challenger1_points,
            ballsPocketed: match.league_match_frames_aggregate.aggregate.sum.challenger1_balls_pocketed,
            errors: match.league_match_frames_aggregate.aggregate.sum.challenger1_errors,
            highBreak: match.league_match_frames_aggregate.aggregate.sum.challenger1_high_break,
            frameData: match.league_match_frames_aggregate.nodes,
            tpa: calculateTPA('challenger1', match.league_match_frames_aggregate.aggregate.sum),
        };
        formattedResults.push(player1);

        const player2 = {
            id: match.challenger2_id,
            name: match.challenger2_name,
            is_winner: match.challenger2_is_winner,
            country: match.challenger2_country,
            totalScore: match.challenger2_score,
            framesWon: match.challenger2_frames,
            framesLost: match.challenger1_frames,
            totalFrames: match.league_match_frames_aggregate.aggregate.sum.challenger2_frames,
            pointsWon: match.challenger2_points,
            pointsLost: match.challenger1_points,
            totalPoints: match.league_match_frames_aggregate.aggregate.sum.challenger2_points,
            ballsPocketed: match.league_match_frames_aggregate.aggregate.sum.challenger2_balls_pocketed,
            errors: match.league_match_frames_aggregate.aggregate.sum.challenger2_errors,
            highBreak: match.league_match_frames_aggregate.aggregate.sum.challenger2_high_break,
            frameData: match.league_match_frames_aggregate.nodes,
            tpa: calculateTPA('challenger2', match.league_match_frames_aggregate.aggregate.sum),
        };
        formattedResults.push(player2);

        return formattedResults;
    }

    return (
        <Query
            query={GET_LEAGUE_MATCH_BY_SLUG_QUERY}
            fetchPolicy="cache-and-network"
            notifyOnNetworkStatusChange
            awaitRefetchQueries
            variables={{ league_id: league.id, division_id: division.id, match_slug: slug }}
            onCompleted={(data) => {
                console.log(data);
            }}
        >
            {({ loading, error, data }) => {
                if (loading) return <CircularLoader />;
                if (error) return <div style={styles.container}>Error: {error.message}</div>;
                if ((data && !data.league_matches) || (data && data.league_matches.length === 0)) {
                    return (
                        <Col xs={24}>
                            <Card
                                bodyStyle={{ padding: 0 }}
                                // style={{
                                // 	width: '100%',
                                // 	borderRadius: 8,
                                // 	overflow: 'hidden',
                                // 	border: 'none',
                                // 	boxShadow: '0 2px 5px 0px rgba(0, 0, 0, 0.1)'
                                // }}
                            >
                                <ZeroState showImage message="That match does not exist" style={{ margin: 30 }} {...props} />
                            </Card>
                        </Col>
                    );
                }

                const match = data && data.league_matches && data.league_matches[0];

                return (
                    <div style={styles.headerImg}>
                        <Switch>
                            <Route
                                exact
                                path={`${props.match.path}/scoring`}
                                render={(matchProps) => (
                                    <React.Fragment>
                                        <div style={{ padding: '20px 40px 0px 40px' }}>
                                            <BreadcrumbContainer theme="dark" />
                                        </div>
                                        <SectionHeader
                                            title="Live Scoring"
                                            titleStyle={{
                                                color: '#fff',
                                                textTransform: 'uppercase',
                                                margin: 0,
                                                display: 'flex',
                                                fontWeight: 400,
                                                fontSize: 22,
                                                justifyContent: 'space-between',
                                            }}
                                            backButton={
                                                <div
                                                    style={{
                                                        paddingLeft: 10,
                                                        paddingBottom: 10,
                                                        paddingTop: 10,
                                                        paddingRight: 0,
                                                    }}
                                                >
                                                    <Icon
                                                        type="arrow-left"
                                                        onClick={() => history.push(`/leagues/${league.slug}/divisions/${division.slug}/matches/${match.slug}`)}
                                                        style={{ color: '#fff', fontSize: 16, margin: 0 }}
                                                    />
                                                </div>
                                            }
                                            style={styles.header}
                                            // theme="tournamentBuilder"
                                        />
                                        <BasicMatchScoring leagueID={league.id} divisionID={division.id} matchID={match.id} match={match} />
                                    </React.Fragment>
                                )}
                            />

                            <Route
                                path={`${props.match.path}`}
                                render={(matchProps) => (
                                    <React.Fragment>
                                        <div style={{ padding: '20px 40px 0px 40px' }}>
                                            <BreadcrumbContainer theme="dark" />
                                        </div>
                                        <SectionHeader
                                            title={match.name.toUpperCase()}
                                            titleStyle={{
                                                color: '#fff',
                                                textTransform: 'uppercase',
                                                margin: 0,
                                                display: 'flex',
                                                fontWeight: 400,
                                                fontSize: 22,
                                                justifyContent: 'space-between',
                                            }}
                                            backButton={
                                                <div
                                                    style={{
                                                        paddingLeft: 10,
                                                        paddingBottom: 10,
                                                        paddingTop: 10,
                                                        paddingRight: 0,
                                                    }}
                                                >
                                                    <Icon
                                                        type="arrow-left"
                                                        onClick={() => history.push(`/leagues/${league.slug}/divisions/${division.slug}/matches`)}
                                                        style={{ color: '#fff', fontSize: 16, margin: 0 }}
                                                    />
                                                </div>
                                            }
                                            style={styles.header}
                                            // theme="tournamentBuilder"
                                        />
                                        <SectionWrapper>
                                            <SectionContent padding="20px 40px">
                                                <Row gutter={24} justify="center" type="flex">
                                                    <Col xs={24} style={{ paddingTop: 0, paddingBottom: 0 }}>
                                                        <Row gutter={24}>
                                                            <Col xs={24} sm={8}>
                                                                <Card
                                                                    cover={
                                                                        <div
                                                                            style={{
                                                                                paddingTop: 16,
                                                                                paddingBottom: 16,
                                                                                paddingLeft: 24,
                                                                                paddingRight: 10,
                                                                                // background:'linear-gradient(rgb(239, 243, 245), rgb(255, 255, 255))',
                                                                                borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
                                                                                display: 'flex',
                                                                                alignItems: 'center',
                                                                                justifyContent: 'space-between',
                                                                            }}
                                                                        >
                                                                            <Title level={4} style={{ margin: 0, padding: 0 }}>
                                                                                Match summary
                                                                            </Title>
                                                                            <div>
                                                                                {match.status === 'IN_PROGRESS' ? (
                                                                                    <Tag color="red" size="large">
                                                                                        LIVE
                                                                                    </Tag>
                                                                                ) : (
                                                                                    <Tag size="large">{match.status}</Tag>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                    bodyStyle={{ padding: 0 }}
                                                                >
                                                                    {/* <div
														style={{
															backgroundColor: '#0c5ac9',
															padding: 20,
															textAlign: 'center'
														}}
													>
														<Text
															style={{
																color: '#fff',
																margin: 0,
																fontSize: 16,
																fontWeight: 600
															}}
														>
															{match.name}
														</Text>
													</div> */}
                                                                    <div
                                                                        style={{
                                                                            padding: 20,
                                                                            display: 'flex',
                                                                            alignItems: 'center',
                                                                            justifyContent: 'center',
                                                                            borderBottom: '1px solid #f2f2f2',
                                                                            backgroundColor: '#fafafa',
                                                                        }}
                                                                    >
                                                                        <Avatar
                                                                            size={150}
                                                                            src={getGameType(match.game_type)}
                                                                            // style={{ backgroundColor: '#1890ff' }}
                                                                        />
                                                                    </div>
                                                                    <div style={{ padding: 24 }}>
                                                                        <Descriptions
                                                                            // title="League Details"
                                                                            size="small"
                                                                            column={breakpoint === 'mobile' ? 2 : 1}
                                                                            bordered={false}
                                                                            layout="horizontal"
                                                                        >
                                                                            <Descriptions.Item label="Match Type">
                                                                                <Text>{displayEnumType(match.match_type)}</Text>
                                                                            </Descriptions.Item>
                                                                            <Descriptions.Item label="Game Type">
                                                                                <Text>
                                                                                    <Avatar
                                                                                        size={24}
                                                                                        src={getGameType(match.game_type)}
                                                                                        style={{
                                                                                            display: 'inline-block',
                                                                                            marginRight: 8,
                                                                                        }}
                                                                                    />
                                                                                    {displayEnumType(match.game_type)}
                                                                                </Text>
                                                                            </Descriptions.Item>
                                                                            <Descriptions.Item label="Scoring Format">
                                                                                <Text>{displayEnumType(match.scoring_format)}</Text>
                                                                            </Descriptions.Item>
                                                                            {match.scoring_format === 'BEST_OF' ? (
                                                                                <Descriptions.Item label="Best of">
                                                                                    <Text>{match.best_of_num}</Text>
                                                                                </Descriptions.Item>
                                                                            ) : (
                                                                                <Descriptions.Item label="Race to">
                                                                                    <Text>{match.race_to_num}</Text>
                                                                                </Descriptions.Item>
                                                                            )}

                                                                            <Descriptions.Item label="Winner Determined By">
                                                                                <Text>{displayEnumType(match.winner_determined_by)}</Text>
                                                                            </Descriptions.Item>
                                                                            <Descriptions.Item label="Scheduled Time">
                                                                                {moment(match.scheduled_time).format('llll')}
                                                                            </Descriptions.Item>
                                                                            <Descriptions.Item label="Start Time">
                                                                                {match.start_time ? moment(match.start_time).format('llll') : 'N/A'}
                                                                            </Descriptions.Item>
                                                                            <Descriptions.Item label="End Time">
                                                                                {match.end_time ? moment(match.end_time).format('llll') : 'N/A'}
                                                                            </Descriptions.Item>
                                                                            <Descriptions.Item label="Status">
                                                                                <Text>{displayEnumType(match.status) || 'N/A'}</Text>
                                                                            </Descriptions.Item>
                                                                            <Descriptions.Item label="Progress">
                                                                                <Text>{match.progress || 'N/A'}</Text>
                                                                            </Descriptions.Item>
                                                                            <Descriptions.Item label="Table Name">
                                                                                <Text>{match.table_name || 'N/A'}</Text>
                                                                            </Descriptions.Item>
                                                                        </Descriptions>
                                                                    </div>
                                                                </Card>
                                                                <br />

                                                                <Card
                                                                    cover={
                                                                        <div
                                                                            style={{
                                                                                paddingTop: 16,
                                                                                paddingBottom: 16,
                                                                                paddingLeft: 24,
                                                                                paddingRight: 24,
                                                                                // background:'linear-gradient(rgb(239, 243, 245), rgb(255, 255, 255))',
                                                                                borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
                                                                            }}
                                                                        >
                                                                            <Title level={4} style={{ margin: 0, padding: 0 }}>
                                                                                Venue
                                                                            </Title>
                                                                        </div>
                                                                    }
                                                                    bodyStyle={{ padding: 0 }}
                                                                >
                                                                    <List
                                                                        className="hover-list"
                                                                        size="small"
                                                                        loading={loading}
                                                                        itemLayout="horizontal"
                                                                        dataSource={[match.venue]}
                                                                        renderItem={(item) => (
                                                                            <List.Item
                                                                                // actions={[ <a key="list-loadmore-edit">View</a> ]}
                                                                                style={{ padding: '10px 16px' }}
                                                                                onClick={() => history.push(`/venues/${item.slug}`)}
                                                                            >
                                                                                <Skeleton avatar title={false} loading={loading} active>
                                                                                    <List.Item.Meta
                                                                                        avatar={
                                                                                            <div>
                                                                                                <Icon
                                                                                                    type="environment"
                                                                                                    theme="filled"
                                                                                                    style={{
                                                                                                        color: '#1890ff',
                                                                                                        fontSize: 30,
                                                                                                    }}
                                                                                                />
                                                                                            </div>
                                                                                        }
                                                                                        title={
                                                                                            <Text
                                                                                                style={{
                                                                                                    fontSize: 16,
                                                                                                }}
                                                                                            >
                                                                                                {item.name}
                                                                                            </Text>
                                                                                        }
                                                                                        description={
                                                                                            <div>
                                                                                                {item.address1}, {item.city}, {item.region} {item.postal_code}{' '}
                                                                                                {item.country}
                                                                                            </div>
                                                                                        }
                                                                                    />
                                                                                </Skeleton>
                                                                            </List.Item>
                                                                        )}
                                                                    />
                                                                </Card>
                                                            </Col>

                                                            <Col xs={24} sm={16} style={{ paddingTop: 0, paddingBottom: 0 }}>
                                                                <Row justify="center">
                                                                    <Col xs={24}>
                                                                        <Card
                                                                            cover={
                                                                                <div
                                                                                    style={{
                                                                                        paddingTop: 16,
                                                                                        paddingBottom: 16,
                                                                                        paddingLeft: 24,
                                                                                        paddingRight: 24,
                                                                                        display: 'flex',
                                                                                        alignItems: 'center',
                                                                                        justifyContent: 'space-between',
                                                                                        // background:'linear-gradient(rgb(239, 243, 245), rgb(255, 255, 255))',
                                                                                        borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
                                                                                    }}
                                                                                >
                                                                                    <Title
                                                                                        level={4}
                                                                                        style={{
                                                                                            margin: 0,
                                                                                            padding: 0,
                                                                                        }}
                                                                                    >
                                                                                        Live scores
                                                                                    </Title>
                                                                                    <div />
                                                                                </div>
                                                                            }
                                                                            bodyStyle={{ padding: 0 }}
                                                                        >
                                                                            <Subscription
                                                                                subscription={GET_LEAGUE_MATCH_BY_SLUG_SUBSCRIPTION}
                                                                                fetchPolicy="cache-and-network"
                                                                                notifyOnNetworkStatusChange={true}
                                                                                variables={{
                                                                                    league_id: league.id,
                                                                                    division_id: division.id,
                                                                                    slug: props.match.params.match,
                                                                                }}
                                                                                onSubscriptionData={(res) => {
                                                                                    console.log(res);
                                                                                    // const match =
                                                                                    // 	res &&
                                                                                    // 	res.subscriptionData &&
                                                                                    // 	res.subscriptionData.data &&
                                                                                    // 	res.subscriptionData.data
                                                                                    // 		.league_matches &&
                                                                                    // 	res.subscriptionData.data
                                                                                    // 		.league_matches[0];
                                                                                    // setMatch(match);
                                                                                }}
                                                                            >
                                                                                {({ loading, error, data }) => {
                                                                                    if (loading) return <CircularLoader />;
                                                                                    if (error)
                                                                                        return <div style={styles.container}>Error: {error.message}</div>;
                                                                                    if (
                                                                                        (data && !data.league_matches) ||
                                                                                        (data && data.league_matches.length === 0)
                                                                                    ) {
                                                                                        return (
                                                                                            <Col xs={24}>
                                                                                                <Card
                                                                                                    bodyStyle={{
                                                                                                        padding: 0,
                                                                                                    }}
                                                                                                    // style={{
                                                                                                    // 	width: '100%',
                                                                                                    // 	borderRadius: 8,
                                                                                                    // 	overflow: 'hidden',
                                                                                                    // 	border: 'none',
                                                                                                    // 	boxShadow: '0 2px 5px 0px rgba(0, 0, 0, 0.1)'
                                                                                                    // }}
                                                                                                >
                                                                                                    <ZeroState
                                                                                                        showImage
                                                                                                        message="That match does not exist"
                                                                                                        style={{ margin: 30 }}
                                                                                                        {...props}
                                                                                                    />
                                                                                                </Card>
                                                                                            </Col>
                                                                                        );
                                                                                    }

                                                                                    const match = data && data.league_matches && data.league_matches[0];

                                                                                    return (
                                                                                        <div
                                                                                            style={{
                                                                                                display: 'flex',
                                                                                                alignItems: 'center',
                                                                                                flexDirection: 'column',
                                                                                                justifyContent: 'center',
                                                                                                padding: 30,
                                                                                                backgroundColor: '#fafafa',
                                                                                            }}
                                                                                        >
                                                                                            <div
                                                                                                style={{
                                                                                                    maxWidth: 600,
                                                                                                }}
                                                                                            >
                                                                                                <TableScoreCard
                                                                                                    title={`Match ${match.match_number}`}
                                                                                                    table={match.table_id}
                                                                                                    label={match.table_name}
                                                                                                    matchData={{
                                                                                                        ...match,
                                                                                                        challenger1_score:
                                                                                                            match.winner_determined_by === 'FRAMES_WON'
                                                                                                                ? match.challenger1_frames
                                                                                                                : match.challenger1_score,
                                                                                                        challenger2_score:
                                                                                                            match.winner_determined_by === 'FRAMES_WON'
                                                                                                                ? match.challenger2_frames
                                                                                                                : match.challenger2_score,
                                                                                                    }}
                                                                                                    status={match.status}
                                                                                                    gameType={match.game_type}
                                                                                                    hoverable={false}
                                                                                                    tableSize={breakpoint === 'mobile' ? 'small' : 'large'}
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                    );
                                                                                }}
                                                                            </Subscription>

                                                                            {/* <div
																				style={{
																					padding: '20px',
																					borderTop: '1px solid #f2f2f2',
																					display: 'flex',
																					alignItems: 'center',
																					width: '100%'
																				}}
																			>
																				<div
																					style={{
																						flex: 1
																					}}
																				>
																					{match.status === 'NOT_STARTED' ? (
																						<Button
																							type="primary"
																							size="large"
																							onClick={() =>
																								showConfirmStart(match)}
																							style={{
																								width: '100%',
																								textTransform:
																									'uppercase'
																							}}
																						>
																							Start scoring Match
																						</Button>
																					) : (
																						<Link
																							to={`/leagues/${league.slug}/divisions/${division.slug}/matches/${match.slug}/scoring`}
																						>
																							<Button
																								type="primary"
																								size="large"
																								style={{
																									width: '100%',
																									textTransform:
																										'uppercase'
																								}}
																							>
																								Continue scoring Match
																							</Button>
																						</Link>
																					)}
																				</div>
																				<div
																					style={{
																						flex: 1,
																						marginLeft: 10
																					}}
																				>
																					<Button
																						size="large"
																						color="default"
																						onClick={() =>
																							setModalVisible(true)}
																						style={{
																							width: '100%',
																							textTransform: 'uppercase',
																							backgroundColor:
																								'rgb(50, 65, 85)',
																							color: '#fff'
																						}}
																					>
																						Enter Final Scores
																					</Button>
																				</div>
																			</div> */}
                                                                        </Card>
                                                                    </Col>

                                                                    <Col
                                                                        xs={24}
                                                                        style={{
                                                                            display: 'flex',
                                                                        }}
                                                                    >
                                                                        <Card title="Player stats" bodyStyle={{ padding: 0 }}>
                                                                            <Subscription
                                                                                subscription={GET_LEAGUE_DIVISON_MATCH_STATS_SUBSCRIPTION}
                                                                                fetchPolicy="cache-and-network"
                                                                                notifyOnNetworkStatusChange={true}
                                                                                variables={{
                                                                                    league_id: league.id,
                                                                                    division_id: division.id,
                                                                                    match_id: match.id,
                                                                                }}
                                                                                onSubscriptionData={(data) => {
                                                                                    console.log('stats', data);
                                                                                }}
                                                                            >
                                                                                {({ loading, error, data }) => {
                                                                                    if (loading) {
                                                                                        return <CircularLoader />;
                                                                                    }

                                                                                    let match = data && data.league_matches && data.league_matches[0];

                                                                                    let orderedData = match && orderAndFormatData(match);

                                                                                    console.log(orderedData);

                                                                                    return (
                                                                                        <MatchStatsTable
                                                                                            divisionID={division.id}
                                                                                            formattedResults={orderedData.map((item, index) => {
                                                                                                return {
                                                                                                    ...item,
                                                                                                    key: index,
                                                                                                };
                                                                                            })}
                                                                                        />
                                                                                    );
                                                                                }}
                                                                            </Subscription>
                                                                        </Card>
                                                                    </Col>

                                                                    <Col
                                                                        xs={24}
                                                                        style={{
                                                                            display: 'flex',
                                                                        }}
                                                                    >
                                                                        <Card
                                                                            // title="Frames"
                                                                            cover={
                                                                                <div
                                                                                    style={{
                                                                                        paddingTop: 16,
                                                                                        paddingBottom: 16,
                                                                                        paddingLeft: 24,
                                                                                        paddingRight: 24,
                                                                                        display: 'flex',
                                                                                        alignItems: 'center',
                                                                                        justifyContent: 'space-between',
                                                                                        // background:'linear-gradient(rgb(239, 243, 245), rgb(255, 255, 255))',
                                                                                        borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
                                                                                    }}
                                                                                >
                                                                                    <Title
                                                                                        level={4}
                                                                                        style={{
                                                                                            margin: 0,
                                                                                            padding: 0,
                                                                                        }}
                                                                                    >
                                                                                        Frames
                                                                                    </Title>
                                                                                    <div />
                                                                                </div>
                                                                            }
                                                                            bodyStyle={{ padding: 0 }}
                                                                        >
                                                                            <FramesList
                                                                                {...props}
                                                                                frames={
                                                                                    match &&
                                                                                    match.league_match_frames &&
                                                                                    match.league_match_frames.map((item, index) => {
                                                                                        return {
                                                                                            ...item,
                                                                                            key: index,
                                                                                            index: index + 1,
                                                                                        };
                                                                                    })
                                                                                }
                                                                            />
                                                                        </Card>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                                <MatchScoringModal
                                                    {...props}
                                                    match={match && match}
                                                    selectedMatchId={match && match.id}
                                                    userID={authState.user.id}
                                                    leagueID={league.id}
                                                    divisionID={match && match.division_id}
                                                    modalVisible={modalVisible}
                                                    onModalOk={handleModalOk}
                                                    onModalCancel={handleModalCancel}
                                                />
                                            </SectionContent>
                                        </SectionWrapper>
                                    </React.Fragment>
                                )}
                            />
                        </Switch>
                    </div>
                );
            }}
        </Query>
    );
}

export default withRouter(DPDivisionMatchDetails);
