import React, { useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { message, PageHeader, Icon, Row, Modal, Col, Typography, Button, Descriptions, Empty } from 'antd';
import Card from '../../../components/Card';
import SectionContent from '../../../components/SectionContent';
import SectionHeader from '../../../components/SectionHeader';
import SectionWrapper from '../../../components/SectionWrapper';
import CircularLoader from '../../../components/CircularLoader';
import { Query } from '@apollo/client/react/components';
import { GET_NEW_VENUES_QUERY, GET_VENUE_QUERY, GET_VENUE_TABLES_QUERY } from './data/queries';
import { getGameType, getCountryName, displayEnumType } from '../../../utils';
import { isoCountries } from '../../../utils/isoCountryCodes';
import { DELETE_TABLE_MUTATION, DELETE_VENUE_MUTATION } from './data/mutations';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import useBreakpoint from 'use-breakpoint';
import { defaultBreakpoints } from '../../../utils/defaultBreakpoints';
import CircleFlag from '../../../components/CircleFlag';
import AddTableModal from './AddTableModal';
import AddVenueModal from './AddVenueModal';
import VenueHostModal from './VenueHostModal';
import TablesList from './TablesList';
import VenueMapSmall from './VenueMapSmall';
import ZeroState from '../../../components/ZeroState';
import dpIcon from '../../../assets/img/dp-icon.png';
import dpIconDark from '../../../assets/img/dp-icon-dark.png';
import AdZoneTemplate from '../../../components/advertising/AdZoneTemplate';
import { FacebookFilled, InstagramFilled, TwitterOutlined } from '@ant-design/icons';
import moment from 'moment';
import _ from 'lodash';
import pluralize from 'pluralize';

const { Text, Title } = Typography;
const { confirm } = Modal;

const styles = {
    row: {
        display: 'flex',
        flexGrow: 1,
    },
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
};

function VenueDetail(props) {
    const { authState, theme } = props;
    const [venueHostModalVisible, setVenueHostModalVisible] = useState(false);
    const [selectedVenueId, setSelectedVenueId] = useState();
    const [selectedTableId, setSelectedTableId] = useState();
    const [selectedTab, setSelectedTab] = useState();
    const [tableModalVisible, setTableModalVisible] = useState(false);
    const [venueModalVisible, setVenueModalVisible] = useState(false);
    const [deleteVenueTable] = useMutation(DELETE_TABLE_MUTATION);
    const [deleteVenue] = useMutation(DELETE_VENUE_MUTATION);
    const user = authState && authState.user;
    const { breakpoint, maxWidth, minWidth } = useBreakpoint(defaultBreakpoints);

    function handleTableModalOk(e) {
        // console.log(e);
        setTableModalVisible(false);
        setSelectedTableId();
    }

    function handleTableModalCancel(e) {
        setTableModalVisible(false);
    }

    function handleVenueModalOk(e) {
        // console.log(e);
        setVenueModalVisible(false);
    }

    function handleVenueModalCancel(e) {
        setVenueModalVisible(false);
    }

    function handleAddVenue() {
        setSelectedVenueId();
        setVenueModalVisible(true);
    }

    function handleTableSelected(selected) {
        console.log(selected);
        setSelectedTableId(selected);
    }

    function handleEditTableSelected(selected) {
        console.log(selected);
        setSelectedTableId(selected);
        setTableModalVisible(true);
    }

    function handleDeleteVenue(record) {
        deleteVenue({
            variables: {
                venue_id: record.id,
            },
            notifyOnNetworkStatusChange: true,
            // awaitRefetchQueries: true,
            // refetchQueries: [
            //     {
            //         query: GET_NEW_VENUES_QUERY,
            //     },
            // ],
        })
            .then((data) => {
                console.log(data);
                message.success('Venue deleted');
                props.history.push(`/venues`);
            })
            .catch((error) => {
                console.log(error);
                message.info('There was an error', error);
                return;
            });
    }

    function showConfirmDeleteVenue(record) {
        confirm({
            title: 'Are you sure you want to delete this venue?',
            content: 'This action will permanently remove the venue and any tables within it.',
            centered: true,
            transitionName: 'fade',
            maskTransitionName: 'none',
            okText: `Delete`,
            onOk() {
                // return new Promise((resolve, reject) => {
                //   setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                // }).catch(() => console.log('Oops errors!'));
                handleDeleteVenue(record);
            },
            onCancel() {},
        });
    }

    function showVenueHostModal(tab) {
        setSelectedTab(tab);
        setVenueHostModalVisible(true);
    }

    function handleVenueHostModalOk(e) {
        console.log(e);
        setVenueHostModalVisible(false);
    }

    function handleVenueHostModalCancel(e) {
        setVenueHostModalVisible(false);
    }

    return (
        <SectionWrapper>
            <SectionHeader
                title={props.match.params.slug && displayEnumType(props.match.params.slug)}
                titleStyle={{ color: '#fff', margin: 0, textTransform: 'uppercase' }}
                subtitle={props.title}
                subtitlePrefix={true}
                subtitleStyle={{
                    color: 'rgba(255,255,255,0.8)',
                    textTransform: 'uppercase',
                    fontSize: 12,
                    letterSpacing: 3,
                    fontWeight: 600,
                }}
                height={120}
                backButton={
                    <Button
                        onClick={() => props.history.push(`/venues`)}
                        type="ghost"
                        shape="circle"
                        icon="arrow-left"
                        size="large"
                        ghost
                        style={{ border: 'none' }}
                    />
                }
            >
                {breakpoint === 'mobile' ? (
                    <Button size="large" type="ghost" ghost onClick={handleAddVenue}>
                        <Icon type="plus" />
                    </Button>
                ) : (
                    <Button size="large" type="ghost" ghost onClick={handleAddVenue}>
                        <Icon type="plus" /> Add Venue
                    </Button>
                )}
            </SectionHeader>

            <SectionContent padding="20px 40px">
                <Query
                    query={GET_VENUE_QUERY}
                    fetchPolicy="cache-and-network"
                    notifyOnNetworkStatusChange={true}
                    variables={{ slug: props.match.params.slug }}
                    onCompleted={(data) => {
                        console.log(data);
                    }}
                >
                    {({ loading, error, data }) => {
                        if (loading) return <CircularLoader />;
                        if (error) return <div style={styles.container}>Error: {error.message}</div>;
                        if ((!loading && data && !data.venues) || (!loading && data && data.venues && data.venues.length === 0)) {
                            return (
                                <Card>
                                    <ZeroState showImage message="Venue could not be found" style={{ margin: 30 }} {...props}>
                                        <Button>Create New Venue</Button>
                                    </ZeroState>
                                </Card>
                            );
                        }

                        const venue = data.venues && data.venues[0];
                        console.log(venue);

                        const countryName =
                            venue.country &&
                            Object.entries(isoCountries)
                                .map(([key, value]) => {
                                    return {
                                        name: key,
                                        value: value.toLowerCase(),
                                    };
                                })
                                .filter((item, index) => {
                                    const country = venue.country.toLowerCase();
                                    return item.value === country || item.name === country;
                                });

                        const country = countryName && countryName[0] ? countryName[0].name : null;

                        const tables =
                            venue &&
                            venue.pool_tables &&
                            venue.pool_tables.map((item, index) => {
                                return {
                                    ...item,
                                    key: index,
                                    index: index + 1,
                                };
                            });

                        return (
                            <React.Fragment>
                                <Helmet>
                                    <title>{venue.name}</title>
                                    <meta name="description" content={venue.description} />
                                    <meta name="twitter:card" content="summary_large_image" />
                                    <meta name="twitter:title" content={venue.name || 'DigitalPool - Bridging the gap between player and technology'} />
                                    <meta name="twitter:description" content={venue.description} />
                                    <meta name="twitter:image" content={venue.flyer || 'https://digitalpool.com/images/fb-image.png'} />
                                    <meta property="og:title" content={venue.name || 'DigitalPool - Bridging the gap between player and technology'} />
                                    <meta property="og:description" content={venue.description} />
                                    <meta property="og:image" content={venue.flyer || 'https://digitalpool.com/images/fb-image.png'} />
                                    <meta property="og:url" content={`https://www.digitalpool.com/venues/${venue.slug}`} />
                                    <meta property="og:site_name" content="digitalpool.com" />
                                    <meta property="og:locale" content="en_US" />
                                    <meta property="og:type" content="website" />
                                    <meta property="fb:app_id" content="1697957547011275" />
                                </Helmet>

                                <div>
                                    <Row gutter={24} justify="center" type="flex">
                                        <Col xs={24}>
                                            <Row gutter={24}>
                                                <Col xs={24}>
                                                    <AdZoneTemplate device={breakpoint || 'desktop'} {...props} />
                                                </Col>
                                                <Col xs={24} lg={8}>
                                                    <Card
                                                        title="Venue Information"
                                                        extra={
                                                            <div>
                                                                <Button
                                                                    type="ghost"
                                                                    onClick={() => {
                                                                        setSelectedVenueId(venue.id);
                                                                        setVenueModalVisible(true);
                                                                    }}
                                                                >
                                                                    <Icon type="edit" /> Edit
                                                                </Button>

                                                                {user && user.id === venue.owner_id && (
                                                                    <Button type="danger" ghost onClick={() => showConfirmDeleteVenue(venue)}>
                                                                        <Icon type="delete" /> Delete
                                                                    </Button>
                                                                )}
                                                            </div>
                                                        }
                                                    >
                                                        <Row gutter={16}>
                                                            <Col xs={24}>
                                                                <Title level={4} style={{ fontSize: 18, marginBottom: 20 }}>
                                                                    About
                                                                </Title>
                                                                <table width="100%" className="info-table">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>Name</td>
                                                                            <td>
                                                                                <Text>{`${venue.name} (ID: ${venue.id})` || 'N/A'}</Text>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Address</td>
                                                                            <td>
                                                                                <Text>
                                                                                    {venue.address1}
                                                                                    {venue.address2 && ` ${venue.address2}`}, {venue.city}, {venue.region}{' '}
                                                                                    {venue.postal_code}
                                                                                </Text>
                                                                                <br />
                                                                                <Link
                                                                                    to={{
                                                                                        pathname: `http://maps.google.com/?q=${venue.name} ${venue.address1}, ${venue.city}, ${venue.region} ${venue.postal_code}`,
                                                                                    }}
                                                                                    target="_blank"
                                                                                    rel="noopener noreferrer"
                                                                                >
                                                                                    View map
                                                                                </Link>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Country</td>
                                                                            <td>
                                                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                                    {venue.country && <CircleFlag country={venue.country} left={0} />}

                                                                                    <Text
                                                                                        style={{
                                                                                            width: '100%',
                                                                                            whiteSpace: 'nowrap',
                                                                                            overflow: 'hidden',
                                                                                            textOverflow: 'ellipsis',
                                                                                        }}
                                                                                    >
                                                                                        {country ? displayEnumType(country) : '-'}
                                                                                    </Text>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Description</td>
                                                                            <td>
                                                                                <Text style={{ width: 200 }}>{venue.description || 'N/A'}</Text>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Operating Hour</td>
                                                                            <td>
                                                                                <Text>{venue.operating_hours || 'N/A'}</Text>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Venue Type</td>
                                                                            <td>
                                                                                <Text>
                                                                                    {venue.venue_type &&
                                                                                        _.startCase(_.toLower(venue.venue_type.replace('_', ' ')))}
                                                                                </Text>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Access</td>
                                                                            <td>
                                                                                <Text>{venue.is_public === true ? 'Public' : 'Private'}</Text>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Claimed</td>
                                                                            <td>
                                                                                <Text>{venue.is_claimed === true ? 'Yes' : 'No'}</Text>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Created by</td>
                                                                            <td>
                                                                                <div>
                                                                                    <Text>
                                                                                        <Link to={`/users/${venue.owner.slug}`}>
                                                                                            {(venue.owner &&
                                                                                                `${venue.owner.first_name} ${venue.owner.last_name}`) ||
                                                                                                '-'}
                                                                                        </Link>
                                                                                    </Text>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Venue tournaments</td>
                                                                            <td>
                                                                                <Text>
                                                                                    <Button
                                                                                        type="link"
                                                                                        onClick={() => {
                                                                                            showVenueHostModal('tournaments');
                                                                                        }}
                                                                                        style={{
                                                                                            margin: 0,
                                                                                            padding: 0,
                                                                                            height: 20,
                                                                                        }}
                                                                                    >
                                                                                        {venue.tournaments.aggregate.count}{' '}
                                                                                        {pluralize('tournament', venue.tournaments.aggregate.count)}
                                                                                    </Button>
                                                                                </Text>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Venue leagues</td>
                                                                            <td>
                                                                                <Text>
                                                                                    <Button
                                                                                        type="link"
                                                                                        onClick={() => {
                                                                                            showVenueHostModal('leagues');
                                                                                        }}
                                                                                        style={{
                                                                                            margin: 0,
                                                                                            padding: 0,
                                                                                            height: 20,
                                                                                        }}
                                                                                    >
                                                                                        {venue.leagues.aggregate.count}{' '}
                                                                                        {pluralize('league', venue.leagues.aggregate.count)}
                                                                                    </Button>
                                                                                </Text>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Last Updated</td>
                                                                            <td>
                                                                                <Text>{moment(venue.last_updated).format('llll')}</Text>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>

                                                                <Title level={4} style={{ marginTop: 20, fontSize: 18, marginBottom: 20 }}>
                                                                    Contact
                                                                </Title>
                                                                <table width="100%" className="info-table">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>Phone Number</td>
                                                                            <td>
                                                                                <Text>{venue.phone_number || 'N/A'}</Text>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Email Address</td>
                                                                            <td>
                                                                                <Text>
                                                                                    {venue.email_address ? (
                                                                                        <a
                                                                                            href={`mailto:${venue.email_address}?subject=General Inquiry`}
                                                                                            target="_blank"
                                                                                            rel="noopener noreferrer"
                                                                                        >
                                                                                            {venue.email_address}
                                                                                        </a>
                                                                                    ) : (
                                                                                        'N/A'
                                                                                    )}
                                                                                </Text>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Website</td>
                                                                            <td>
                                                                                <Text>
                                                                                    {venue.website ? (
                                                                                        <a href={venue.website} target="_blank" rel="noopener noreferrer">
                                                                                            {venue.website}
                                                                                        </a>
                                                                                    ) : (
                                                                                        'N/A'
                                                                                    )}
                                                                                </Text>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Facebook Link</td>
                                                                            <td>
                                                                                <Text>
                                                                                    {venue.social_facebook ? (
                                                                                        <a
                                                                                            href={venue.social_facebook}
                                                                                            target="_blank"
                                                                                            rel="noopener noreferrer"
                                                                                        >
                                                                                            <FacebookFilled style={{ fontSize: 20 }} />
                                                                                        </a>
                                                                                    ) : (
                                                                                        'N/A'
                                                                                    )}
                                                                                </Text>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Instagram Link</td>
                                                                            <td>
                                                                                <Text>
                                                                                    {venue.social_instagram ? (
                                                                                        <a
                                                                                            href={venue.social_instagram}
                                                                                            target="_blank"
                                                                                            rel="noopener noreferrer"
                                                                                        >
                                                                                            <InstagramFilled style={{ fontSize: 20 }} />
                                                                                        </a>
                                                                                    ) : (
                                                                                        'N/A'
                                                                                    )}
                                                                                </Text>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Twitter Link</td>
                                                                            <td>
                                                                                <Text>
                                                                                    {venue.social_twitter ? (
                                                                                        <a
                                                                                            href={venue.social_twitter}
                                                                                            target="_blank"
                                                                                            rel="noopener noreferrer"
                                                                                        >
                                                                                            <TwitterOutlined style={{ fontSize: 20 }} />
                                                                                        </a>
                                                                                    ) : (
                                                                                        'N/A'
                                                                                    )}
                                                                                </Text>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                                {/* <div style={{ marginTop: 20 }}>
																	<Button type="ghost" size="large">
																		Host New Tournament
																	</Button>
																	<Button type="ghost" size="large">
																		Host New League
																	</Button>
																</div> */}
                                                            </Col>
                                                        </Row>
                                                    </Card>
                                                </Col>

                                                <Col xs={24} lg={16}>
                                                    <Card
                                                        title={`${
                                                            venue.pool_tables && venue.pool_tables.length > 0
                                                                ? `Tables (${venue.pool_tables.length})`
                                                                : 'Tables'
                                                        }`}
                                                        extra={
                                                            <div
                                                                style={{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'space-between',
                                                                }}
                                                            >
                                                                <Button
                                                                    type="ghost"
                                                                    onClick={() => {
                                                                        setTableModalVisible(true);
                                                                    }}
                                                                >
                                                                    <Icon type="plus-circle" /> Add Tables
                                                                </Button>
                                                            </div>
                                                        }
                                                        bodyStyle={{ padding: 0 }}
                                                    >
                                                        {venue.pool_tables && venue.pool_tables.length > 0 ? (
                                                            <TablesList
                                                                {...props}
                                                                onSelected={handleTableSelected}
                                                                onEditSelected={handleEditTableSelected}
                                                                onDelete={(record) => {
                                                                    deleteVenueTable({
                                                                        variables: {
                                                                            id: record.id,
                                                                        },
                                                                        notifyOnNetworkStatusChange: true,
                                                                        awaitRefetchQueries: true,
                                                                        refetchQueries: [
                                                                            {
                                                                                query: GET_VENUE_QUERY,
                                                                                variables: { slug: venue.slug },
                                                                            },
                                                                        ],
                                                                    })
                                                                        .then((data) => {
                                                                            console.log(data);
                                                                            message.success('Table deleted from venue');
                                                                        })
                                                                        .catch((error) => {
                                                                            console.log(error);
                                                                            message.error(JSON.stringify(error, null, 4), 2);
                                                                        });
                                                                }}
                                                                tables={tables}
                                                            />
                                                        ) : (
                                                            <ZeroState showImage message="No Tables found for this venue." style={{ margin: 30 }} {...props} />
                                                        )}
                                                    </Card>
                                                    {venue.lat && venue.lng && (
                                                        <React.Fragment>
                                                            <br />
                                                            <Card title="Map" bodyStyle={{ padding: 0 }}>
                                                                <VenueMapSmall
                                                                    location={{
                                                                        address: `${venue.address1}, ${venue.city}, ${venue.region}`,
                                                                        lat: parseFloat(venue.lat),
                                                                        lng: parseFloat(venue.lng),
                                                                    }}
                                                                    zoomLevel={10}
                                                                />
                                                            </Card>
                                                        </React.Fragment>
                                                    )}
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </div>
                                <AddTableModal
                                    {...props}
                                    tournament={props.tournament}
                                    venue={venue}
                                    selectedTableId={selectedTableId}
                                    tableModalVisible={tableModalVisible}
                                    onTableModalOk={handleTableModalOk}
                                    onTableModalCancel={handleTableModalCancel}
                                />
                                <AddVenueModal
                                    {...props}
                                    selectedVenueId={selectedVenueId}
                                    // venue={
                                    // 	venues.filter((item, index) => {
                                    // 		return item.id === selectedVenueId;
                                    // 	})[0]
                                    // }
                                    modalVisible={venueModalVisible}
                                    onModalOk={handleVenueModalOk}
                                    onModalCancel={handleVenueModalCancel}
                                />
                                <VenueHostModal
                                    {...props}
                                    venue={venue}
                                    selectedTab={selectedTab}
                                    // venue={
                                    // 	venues.filter((item, index) => {
                                    // 		return item.id === selectedVenueId;
                                    // 	})[0]
                                    // }
                                    modalVisible={venueHostModalVisible}
                                    onModalOk={handleVenueHostModalOk}
                                    onModalCancel={handleVenueHostModalCancel}
                                />
                            </React.Fragment>
                        );
                    }}
                </Query>
            </SectionContent>
        </SectionWrapper>
    );
}

export default withRouter(VenueDetail);
