import React from 'react';
import { useSetRecoilState, useRecoilValue } from 'recoil';
import { Result, Button } from 'antd';
import { createStep, completedStepAtom } from '../../data/atoms';

export default function GoBackStep(props) {
    const setCreateStep = useSetRecoilState(createStep);
    const createStepAtomValue = useRecoilValue(createStep);
    const completedStepAtomValue = useRecoilValue(completedStepAtom);

    let message = '';

    if(createStepAtomValue === 1 && completedStepAtomValue === -1) {
        message = 'You must finish configuring the ghost event before you can invite players.';
    } else if(createStepAtomValue === 2 && completedStepAtomValue === -1) {
        message = 'You must finish configuring the ghost event before you can review your ghost event.';
    }
    
    return (
        <Result
            status="info"
            title={message}
            extra={
                <Button
                    type="primary"
                    key="console"
                    size="large"
                    onClick={() => { setCreateStep(0) }}
                >
                    Go Back
                </Button>
            }
        />
    );
}