import React, { useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Icon, Row, Col, Typography } from 'antd';
import TBContactSettingsForm from './TBContactSettingsForm';
const { Text, Title } = Typography;

function TBContactSettings(props) {
	return (
		<React.Fragment>
			<Title level={4}>{props.title}</Title>
			<TBContactSettingsForm {...props} />
		</React.Fragment>
	);
}

export default withRouter(TBContactSettings);
