import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { message, Modal, Button, Upload, Icon, Typography, Avatar } from 'antd';
import { Form, FormItem, Input, Radio, Select, SubmitButton, ResetButton, AutoComplete, DatePicker } from 'formik-antd';
import * as Yup from 'yup';
import { Formik, ErrorMessage } from 'formik';
import CircularLoader from '../../components/CircularLoader';
import CircleFlag from '../../components/CircleFlag';
import { v4 as uuidv4 } from 'uuid';
import { formatPlayerName, getCountryName } from '../../utils';
import { Query, Mutation } from '@apollo/client/react/components';
import { getGameType } from '../../utils';
import { countryRegionData } from '../../utils/countryRegionData';
import { CountryRegionData } from 'react-country-region-selector';
import CircleStateFlag from '../../components/CircleStateFlag';
import geo from 'countrycitystatejson';
import ZeroState from '../../components/ZeroState';
import {
    GET_LEAGUE_TEAMS_QUERY,
    GET_LEAGUE_DIVISION_TEAMS_QUERY,
    GET_LEAGUE_PLAYER_STATUS,
    GET_LEAGUE_PLAYER_QUERY,
    GET_LEAGUE_PLAYERS_QUERY,
    GET_LEAGUE_DIVISION_QUERY,
    GET_LEAGUE_DIVISION_PLAYERS_QUERY,
    GET_LEAGUE_QUERY,
    FILTER_LEAGUE_PLAYERS,
    FILTER_USERS,
    GET_LEAGUE_DIVISIONS_QUERY,
    GET_LEAGUE_PLAYER_BY_NAME_QUERY,
    SEARCH_PLAYERS,
} from './data/queries';
import { useMutation, useQuery } from '@apollo/react-hooks';
import {
    CREATE_LEAGUE_PLAYER,
    UPDATE_LEAGUE_PLAYER,
    UPDATE_LEAGUE_PLAYER_DIVISION,
    DELETE_LEAGUE_PLAYER_DIVISION,
    UPDATE_LEAGUE_PLAYER_TEAM,
    DELETE_LEAGUE_PLAYER_TEAM,
} from './data/mutations';
import GetFargoModal from '../../components/GetFargoModal';
import _ from 'lodash';
import moment from 'moment-timezone';

const { Title, Text } = Typography;
const { Option } = Select;

const styles = {
    row: {
        display: 'flex',
        flexGrow: 1,
    },
    tab: {
        paddingTop: 5,
        paddingLeft: 24,
        paddingRight: 24,
        paddingBottom: 24,
    },
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        padding: 20,
    },
};

function AddPlayerModal(props) {
    const { client, selectedPlayerId, userID, league, leagueID, divisionID, modalVisible, onModalOk, onModalCancel } = props;
    const [loading, setLoading] = useState(false);
    const [acDataSource, setacDataSource] = useState([]);
    const [nicknameForm, setNicknameForm] = useState('');
    const [fargoModalVisible, setFargoModalVisible] = useState(false);
    const [selected, setSelected] = useState();
    const [selectedDivision, setSelectedDivision] = useState([]);
    const [selectedTeam, setSelectedTeam] = useState([]);

    // const { data } = useQuery(GET_LEAGUE_PLAYER_QUERY, {
    // 	variables: { id: selectedPlayerId, league_id: leagueID }
    // });
    const [createLeaguePlayer, { createLoading, createError }] = useMutation(CREATE_LEAGUE_PLAYER);
    const [updateLeaguePlayer, { updateLoading, updateError }] = useMutation(UPDATE_LEAGUE_PLAYER);
    const [updateLeaguePlayerDivision] = useMutation(UPDATE_LEAGUE_PLAYER_DIVISION);
    const [updateLeaguePlayerTeam] = useMutation(UPDATE_LEAGUE_PLAYER_TEAM);
    const [deleteLeaguePlayerDivision] = useMutation(DELETE_LEAGUE_PLAYER_DIVISION);
    const [deleteLeaguePlayerTeam] = useMutation(DELETE_LEAGUE_PLAYER_TEAM);
    const countries = geo.getCountries();
    const countryPhones = countries
        .sort((a, b) => {
            return a.phone.localeCompare(b.phone, undefined, {
                numeric: true,
                sensitivity: 'base',
            });
        })
        .filter((country) => {
            return country.shortName !== 'UM';
        })
        .map((country) => {
            return {
                phone: country.phone,
                emoji: country.emoji,
                shortName: country.shortName,
            };
        });

    const formRef = useRef();

    useEffect(() => {
        const fetchData = async () => {
            const res = await client.query({
                query: GET_LEAGUE_PLAYER_QUERY,
                variables: {
                    id: selectedPlayerId,
                    league_id: leagueID,
                },
            });

            console.log(res);

            return res.data;
        };

        setSelectedDivision(undefined);
        if (selectedPlayerId) {
            const data = fetchData();
            const player = data && data.league_players && data.league_players.length > 0 ? data.league_players[0] : null;
            console.log(player);
            setSelectedDivision(player && player.league_player_divisions ? player.league_player_divisions.map((item) => item.division_id) : undefined);
        } else if (divisionID) {
            setSelectedDivision(divisionID);
        }
    }, [selectedPlayerId, divisionID, leagueID, client]);

    async function queryDB(name) {
        setacDataSource([]);
        var players = [];

        const res = await client.query({
            query: SEARCH_PLAYERS,
            variables: {
                name: `%${name}%`,
            },
        });

        for (let i = 0; i < res.data.players.length; i++) {
            let player = res.data.players[i];
            const { id, name, aliases, country, country_phone, phone_number, region, image_large, image_small, properties, user_id } = player;
            const user = player.user;

            players.push({
                ...user,
                ...player,
                player_id: id,
                name: name,
                country: country,
                region: region,
                aliases,
                user_id: user_id,
                image_large,
                image_small,
                country_phone,
                phone_number,
                city: properties && properties.fargo_data && properties.fargo_data.city,
                state: properties && properties.fargo_data && properties.fargo_data.state,
                robustness: properties && properties.fargo_data && properties.fargo_data.robustness,
                fargo_id: properties && properties.fargo_data && properties.fargo_data.membershipId,
                skill_level: properties && properties.fargo_data && properties.fargo_data.effectiveRating,
                key: uuidv4(),
            });
        }

        const dataSet = _.uniqBy([...players], 'name').sort((a, b) => a.name.localeCompare(b.name));

        setacDataSource(dataSet);
    }

    function onChange(event) {
        setNicknameForm(event.target.value);
    }

    function handleDivisionChange(value) {
        setSelectedDivision(value);
    }

    function handleTeamChange(value) {
        setSelectedTeam(value);
    }

    function onAutoCompleteChange(value) {
        // console.log(value);
        // console.log(formRef);
        if (value) {
            formRef.current.setValues({
                name: value,
            });
        }

        // setAutoCompleteValue(value);
    }

    function onOk(value) {
        const tzDate = moment.tz(value, moment.tz.guess(value)).format('YYYY-MM-DD hh:mm A z');
        console.log('onOK', tzDate);
        return tzDate;
    }

    function onCancel() {
        onModalCancel();
        formRef.current.setValues({
            league_player_divisions: divisionID ? divisionID : [],
        });
    }

    function onSearch(name) {
        if (name && name.length > 1) {
            queryDB(name);
        } else {
            setSelected();
            setacDataSource([]);
        }
    }

    function handleChange(name) {
        if (name && name.length === 0) {
            formRef.current.setValues([]);
        }
    }

    function onSelect(value, option) {
        // let match = value.match(/\"[a-zA-Z\ \-\']+\"/g);
        const player = acDataSource.filter((item) => item.key === value);

        if (player) {
            setSelected(player[0]);
            console.log(player);
            formRef.current.setValues({
                ...formRef.current.state.values,
                ...player[0],
                league_player_divisions: divisionID ? divisionID : [],
            });
        }

        // if (match) {
        //     let replaceNickname = match[0].replace(/\"/g, '');

        //     setNicknameForm(replaceNickname);
        // }
    }

    const getRegions = (country) => {
        if (!country) {
            return [];
        }
        if (typeof country === 'string') {
            const regions = countryRegionData.filter((item) => {
                return item.countryShortCode === country;
            })[0];
            country = regions;
        }
        const selectedRegion = country[2].split('|').map((regionPair) => {
            let [regionName, regionShortCode = null] = regionPair.split('~');
            return regionName;
        });
        return selectedRegion;
    };

    const submitAdd = (data, actions) => {
        console.log(data);
        const division = _.isArray(data.league_player_divisions) ? data.league_player_divisions : [data.league_player_divisions];
        const team = _.isArray(data.league_team_players) ? data.league_team_players : [data.league_team_players];
        console.log('team', team);
        setLoading(true);
        createLeaguePlayer({
            variables: {
                objects: [
                    {
                        league_id: props.leagueID,
                        name: data.name,
                        ...(data.nickname && { nickname: data.nickname }),
                        ...(data.email && { email: data.email }),
                        ...(data.phone_number && { phone_number: data.phone_number }),
                        ...(data.country && { country: data.country }),
                        ...(data.region && { region: data.region }),
                        ...(data.city && { region: data.city }),
                        ...(data.state && { state: data.state }),
                        ...(data.skill_level && { skill_level: parseInt(data.skill_level) }),
                        ...(data.fargo_id && { fargo_id: parseInt(data.fargo_id) }),
                        ...(data.special_group && { special_group: data.special_group }),
                        ...(data.status && { status: data.status }),
                        ...(data.race_to && { race_to: parseInt(data.race_to) }),
                        ...(data.league_dues_paid && { league_dues_paid: data.league_dues_paid }),
                        ...(data.last_payment_date && { last_payment_date: data.last_payment_date }),
                        ...(data.user_id && { user_id: data.user_id }),
                        ...(data.player_id && { player_id: data.player_id }),
                        ...(data.league_player_divisions &&
                            division.length > 0 && {
                                league_player_divisions: {
                                    data: division.map((divisionId) => {
                                        return {
                                            // league_player_id: selectedPlayerId,
                                            division_id: divisionId,
                                        };
                                    }),
                                },
                            }),
                        ...(data.league_team_players &&
                            team.length > 0 && {
                                league_team_players: {
                                    data: team.map((teamId) => {
                                        return {
                                            league_id: leagueID,
                                            // league_player_id: selectedPlayerId,
                                            team_id: teamId,
                                        };
                                    }),
                                },
                            }),
                    },
                ],
            },
            notifyOnNetworkStatusChange: true,
            awaitRefetchQueries: true,
            refetchQueries: props.division
                ? [
                      {
                          query: GET_LEAGUE_PLAYERS_QUERY,
                          variables: { league_id: leagueID },
                      },
                      {
                          query: GET_LEAGUE_QUERY,
                          variables: { id: leagueID },
                      },
                      {
                          query: GET_LEAGUE_TEAMS_QUERY,
                          variables: { league_id: leagueID },
                      },
                      {
                          query: GET_LEAGUE_DIVISION_PLAYERS_QUERY,
                          variables: { league_id: leagueID, division_id: divisionID },
                      },
                      // {
                      // 	query: GET_LEAGUE_DIVISION_QUERY,
                      // 	variables: { leagueId: leagueID, divisionSlug: props.match.params.division }
                      // }
                  ]
                : [
                      {
                          query: GET_LEAGUE_PLAYERS_QUERY,
                          variables: { league_id: leagueID },
                      },
                      {
                          query: GET_LEAGUE_TEAMS_QUERY,
                          variables: { league_id: leagueID },
                      },
                      {
                          query: GET_LEAGUE_QUERY,
                          variables: { id: leagueID },
                      },
                  ],
        })
            .then((data) => {
                console.log(data);
                setLoading(false);
                actions.setSubmitting(false);
                actions.resetForm();
                message.success('Player successfully added');
                onModalOk();
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
                actions.setSubmitting(false);
                actions.resetForm();
                message.info('There was an error', error);
                onModalOk();
            });
    };

    function hasDuplicates(a) {
        return _.uniq(a).length !== a.length;
    }

    const submitUpdate = (data, player) => {
        console.log('player data', data);
        setLoading(true);
        updateLeaguePlayer({
            variables: {
                id: selectedPlayerId,
                changes: {
                    name: data.name,
                    nickname: data.nickname,
                    email: data.email,
                    country_phone: data.country_phone,
                    region: data.region,
                    phone_number: data.phone_number,
                    country: data.country,
                    city: data.city,
                    state: data.state,
                    skill_level: parseInt(data.skill_level) || null,
                    fargo_id: parseInt(data.fargo_id),
                    special_group: data.special_group,
                    status: data.status,
                    league_dues_paid: data.league_dues_paid,
                    last_payment_date: data.last_payment_date,
                    race_to: parseInt(data.race_to),
                    ...(data.user_id && { user_id: data.user_id }),
                    ...(data.player_id && { player_id: data.player_id }),
                },
            },
            // notifyOnNetworkStatusChange: true,
            // awaitRefetchQueries: true,
            // refetchQueries: [
            // 	{
            // 		query: GET_LEAGUE_PLAYERS_QUERY,
            // 		variables: { league_id: leagueID }
            // 	}
            // 	// {
            // 	// 	query: GET_LEAGUE_QUERY,
            // 	// 	variables: { id: leagueID }
            // 	// }
            // ]
        })
            .then((res) => {
                if (league.has_division_support === true) {
                    const original = player.league_player_divisions.map((item) => item.division_id);
                    const toUpdate = _.uniq(data.league_player_divisions, original);
                    const toDelete = _.differenceBy(original, toUpdate);
                    console.log('original record', player.league_player_divisions);
                    console.log('original ID', original);
                    console.log('new', data.league_player_divisions);
                    console.log('update', toUpdate);
                    console.log('delete', toDelete);

                    // console.log(data);
                    // setLoading(false);
                    // message.success('Changes saved');
                    // onModalOk();

                    if (toDelete.length > 0) {
                        deleteLeaguePlayerDivision({
                            variables: {
                                league_player_id: selectedPlayerId,
                            },
                        })
                            .then((data) => {
                                console.log(data);
                            })
                            .catch((error) => {
                                console.log(error);
                            });
                    }

                    updateLeaguePlayerDivision({
                        variables: {
                            league_player_id: selectedPlayerId,
                            objects: toUpdate.map((divisionId) => {
                                return {
                                    league_id: leagueID,
                                    league_player_id: selectedPlayerId,
                                    division_id: divisionId,
                                };
                            }),
                        },
                        notifyOnNetworkStatusChange: true,
                        awaitRefetchQueries: true,
                        refetchQueries: props.division
                            ? [
                                  {
                                      query: GET_LEAGUE_PLAYERS_QUERY,
                                      variables: { league_id: leagueID },
                                  },
                                  {
                                      query: GET_LEAGUE_DIVISION_PLAYERS_QUERY,
                                      variables: { league_id: leagueID, division_id: divisionID },
                                  },
                                  {
                                      query: GET_LEAGUE_QUERY,
                                      variables: { id: leagueID },
                                  },
                              ]
                            : [
                                  {
                                      query: GET_LEAGUE_PLAYERS_QUERY,
                                      variables: { league_id: leagueID },
                                  },

                                  {
                                      query: GET_LEAGUE_QUERY,
                                      variables: { id: leagueID },
                                  },
                              ],
                    })
                        .then((data) => {
                            console.log(data);
                            setLoading(false);
                            message.success('Changes saved');
                            onModalOk();
                        })
                        .catch((error) => {
                            console.log(error);
                            setLoading(false);
                            message.info('There was an error', error);
                            onModalOk();
                        });

                    console.log(player);
                    console.log(data);
                }

                if (league.has_team_support === true) {
                    const leaguePlayers =
                        data.league_team_players && data.league_team_players.length > 0 ? data.league_team_players : [data.league_team_players];
                    const originalTeam = player.league_team_players.map((item) => item.team_id);
                    const teamToUpdate = _.uniq(leaguePlayers, originalTeam);
                    const teamToDelete = _.differenceBy(originalTeam, teamToUpdate);
                    console.log('original team record', player.league_team_players);
                    console.log('original team ID', originalTeam);
                    console.log('new team', leaguePlayers);
                    console.log('update team', teamToUpdate);
                    console.log('delete team', teamToDelete);

                    if (teamToDelete.length > 0 || leaguePlayers === null) {
                        deleteLeaguePlayerTeam({
                            variables: {
                                league_player_id: selectedPlayerId,
                            },
                        })
                            .then((data) => {
                                console.log(data);
                            })
                            .catch((error) => {
                                console.log(error);
                            });
                    }

                    if (leaguePlayers !== null && !hasDuplicates(originalTeam) && !_.isEqual(teamToUpdate, originalTeam)) {
                        updateLeaguePlayerTeam({
                            variables: {
                                league_player_id: selectedPlayerId,
                                objects: teamToUpdate.map((teamId) => {
                                    return {
                                        league_id: leagueID,
                                        league_player_id: selectedPlayerId,
                                        team_id: teamId,
                                    };
                                }),
                            },
                            notifyOnNetworkStatusChange: true,
                            awaitRefetchQueries: true,
                            refetchQueries: [
                                {
                                    query: GET_LEAGUE_PLAYERS_QUERY,
                                    variables: { league_id: leagueID },
                                },
                                // {
                                //     query: GET_LEAGUE_DIVISION_PLAYERS_QUERY,
                                //     variables: { league_id: leagueID, division_id: divisionID },
                                // },
                                {
                                    query: GET_LEAGUE_QUERY,
                                    variables: { id: leagueID },
                                },
                            ],
                        })
                            .then((data) => {
                                console.log(data);
                                setLoading(false);
                                message.success('Changes saved');
                                onModalOk();
                            })
                            .catch((error) => {
                                console.log(error);
                                setLoading(false);
                                message.info('There was an error', error);
                                onModalOk();
                            });
                    }
                } else {
                    setLoading(false);
                    message.success('Changes saved');
                    onModalOk();
                }
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
                message.info('There was an error', error);
                onModalOk();
            });
    };

    const schema = Yup.object().shape({
        name: Yup.string().required('Required'),
    });

    const validator = (values, props) => {
        console.log(values);
        console.log(props);
        const errors = {};

        if (!values.name) {
            errors.name = 'Required';
        }

        return errors;
    };

    function handleFormFieldUpdates(event) {
        const caret = event.target.selectionStart;
        const element = event.target;
        window.requestAnimationFrame(() => {
            element.selectionStart = caret;
            element.selectionEnd = caret;
        });
    }

    async function handleFargoModalOk(player) {
        if (player) {
            formRef.current.setValues({
                ...formRef.current.state.values,
                fargo_id: player.fargoId,
                skill_level: player.effectiveRating,
                // fargo_readable_id: player.readableId,
                // fargo_robustness: player.robustness,
            });
        }

        setFargoModalVisible(false);
    }

    function handleFargoModalCancel(e) {
        setFargoModalVisible(false);
    }

    function handleGetFargoRating() {
        setFargoModalVisible(true);
    }

    async function checkIfPlayerExists(values) {
        const res = await client.query({
            query: GET_LEAGUE_PLAYER_BY_NAME_QUERY,
            variables: {
                league_id: league.id,
                name: values.name,
            },
        });
        const playerExists = res && res.data && res.data.league_players && res.data.league_players.length > 0;
        return playerExists;
    }

    const renderForm = (player) => {
        const initialValues = selectedPlayerId
            ? {
                  ...player,
                  league_player_divisions: player && player.league_player_divisions ? player.league_player_divisions.map((item) => item.division_id) : [],
                  league_team_players: player && player.league_team_players ? player.league_team_players.map((item) => item.team_id) : [],
              }
            : {
                  league_player_divisions: divisionID ? [divisionID] : [],
                  league_team_players: [],
                  name: '',
                  nickname: '',
                  email: '',
                  phone_number: '',
                  country: undefined,
                  city: undefined,
                  state: undefined,
                  skill_level: undefined,
                  special_group: '',
                  status: undefined,
                  race_to: '',
                  league_dues_paid: false,
                  last_payment_date: '',
              };

        return (
            <React.Fragment>
                <Formik
                    ref={formRef}
                    onSubmit={async (values, actions) => {
                        const merged = {
                            ...selected,
                            ...values,
                        };

                        if (selectedPlayerId) {
                            submitUpdate(merged, player);
                        } else {
                            const playerExists = await checkIfPlayerExists(values);
                            if (playerExists === true) {
                                message.error(`${values.name} already exists in the player list`);
                                actions.setSubmitting(false);
                            } else {
                                submitAdd(merged, actions);
                            }
                        }
                    }}
                    validationSchema={schema}
                    enableReinitialize
                    initialValues={initialValues}
                    render={({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => {
                        return (
                            <Modal
                                title={selectedPlayerId ? 'Update Player' : 'Add Player'}
                                // getContainer={() => document.querySelector('.tables')}
                                visible={modalVisible}
                                onOk={onModalOk}
                                onCancel={onCancel}
                                centered
                                transitionName="fade"
                                // transitionName="none"
                                maskTransitionName="none"
                                footer={[
                                    <Button key="cancel" onClick={onCancel}>
                                        Cancel
                                    </Button>,
                                    <Button
                                        key="submit"
                                        disabled={Object.keys(errors).length > 0 ? true : false}
                                        type={Object.keys(errors).length > 0 ? 'danger' : 'primary'}
                                        onClick={() => {
                                            formRef.current.handleSubmit();
                                        }}
                                    >
                                        {selectedPlayerId ? (loading ? 'Updating...' : 'Update Player') : loading ? 'Adding Player...' : 'Add Player'}
                                    </Button>,
                                ]}
                                bodyStyle={{ maxHeight: 500, overflowY: 'auto', padding: '10px 20px' }}
                                // destroyOnClose={true}
                            >
                                <Form
                                    onSubmit={handleSubmit}
                                    layout="vertical"
                                    style={{ maxWidth: 600 }}
                                    // onSubmit={(e) => {
                                    // 	e.preventDefault();
                                    // 	console.log(values);
                                    // 	// handleSubmit(values);
                                    // }}
                                >
                                    <FormItem label="Name" name="name" required hasFeedback showValidateSuccess>
                                        <AutoComplete
                                            dataSource={acDataSource}
                                            // value={autocompleteValue}
                                            // validate={validator}
                                            style={{ width: '100%' }}
                                            onSearch={onSearch}
                                            onSelect={onSelect}
                                            onChange={handleChange}
                                            // onChange={onAutoCompleteChange}
                                            size="large"
                                            name="name"
                                            autoFocus
                                            allowClear
                                            placeholder="Search for a player name"
                                            optionFilterProp="children"
                                            optionLabelProp="value"
                                            filterOption={(inputValue, option) => {
                                                return (
                                                    option &&
                                                    option.props &&
                                                    option.props.children &&
                                                    option.props.children.props &&
                                                    option.props.children.props.children &&
                                                    option.props.children.props.children[1] &&
                                                    option.props.children.props.children[1].toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
                                                );
                                            }}
                                            dropdownRender={(menu) => (
                                                <React.Fragment>
                                                    <div>{menu}</div>
                                                </React.Fragment>
                                            )}
                                        >
                                            {acDataSource &&
                                                acDataSource.map((item, index) => (
                                                    <Option key={item.key} value={item.key}>
                                                        <span style={{ display: 'flex', alignItems: 'center' }}>
                                                            {item.country && (
                                                                <span role="img" aria-label={item.country}>
                                                                    <CircleFlag country={getCountryName(item.country)} left={0} />{' '}
                                                                </span>
                                                            )}
                                                            {formatPlayerName(item.name)}
                                                            {item.properties &&
                                                                item.properties.fargo_data &&
                                                                item.properties.fargo_data.location &&
                                                                item.properties.fargo_data.location !== ' ' &&
                                                                ` - ${item.properties.fargo_data.location}`}
                                                            {item.properties &&
                                                                item.properties.fargo_data &&
                                                                item.properties.fargo_data.effectiveRating &&
                                                                ` - ${item.properties.fargo_data.effectiveRating}`}
                                                        </span>
                                                    </Option>
                                                ))}
                                        </AutoComplete>
                                    </FormItem>

                                    {league.has_division_support && (
                                        <FormItem
                                            label={props.division ? 'Division' : 'Divisions'}
                                            name="league_player_divisions"
                                            hasFeedback
                                            showValidateSuccess
                                        >
                                            <Query
                                                query={GET_LEAGUE_DIVISIONS_QUERY}
                                                fetchPolicy="cache-and-network"
                                                variables={{
                                                    leagueId: leagueID,
                                                }}
                                                notifyOnNetworkStatusChange
                                            >
                                                {({ loading, error, data }) => {
                                                    if (error) return <div>Error: {error.message}</div>;

                                                    return (
                                                        <Select
                                                            showSearch
                                                            mode={props.division ? 'single' : 'multiple'}
                                                            name="league_player_divisions"
                                                            placeholder="Select division"
                                                            size="large"
                                                            loading={loading}
                                                            defaultValue={selectedDivision}
                                                            // disabled={divisionID ? true : false}
                                                            onChange={handleDivisionChange}
                                                            disabled={props.division ? true : false}
                                                            allowClear={props.division ? false : true}
                                                            optionFilterProp="children"
                                                            optionLabelProp="children"
                                                            filterOption={(inputValue, option) => {
                                                                return option.props.name.toString().toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
                                                            }}
                                                        >
                                                            {data &&
                                                                data.league_divisions &&
                                                                data.league_divisions.map((item, index) => (
                                                                    <Option
                                                                        key={index}
                                                                        name={item.name}
                                                                        value={item.id}
                                                                        style={{ display: 'flex', alignItems: 'center' }}
                                                                    >
                                                                        <Avatar
                                                                            size={24}
                                                                            src={getGameType(item.game_types && item.game_types[0].game_type)}
                                                                            style={{
                                                                                display: 'inline-block',
                                                                                marginRight: 8,
                                                                            }}
                                                                        />
                                                                        <Text>{item.name}</Text>
                                                                    </Option>
                                                                ))}
                                                        </Select>
                                                    );
                                                }}
                                            </Query>
                                        </FormItem>
                                    )}

                                    <FormItem label="Nickname" name="nickname" hasFeedback showValidateSuccess>
                                        <Input
                                            name="nickname"
                                            placeholder="Nickname"
                                            size="large"
                                            // value={nicknameForm}
                                            onChange={(event) => {
                                                // onChange(event);
                                                handleFormFieldUpdates(event);
                                            }}
                                        />
                                    </FormItem>
                                    <FormItem label="Email" name="email" hasFeedback showValidateSuccess>
                                        <Input
                                            name="email"
                                            placeholder="Email Address"
                                            size="large"
                                            onChange={(event) => {
                                                handleFormFieldUpdates(event);
                                            }}
                                        />
                                    </FormItem>
                                    <FormItem label="Phone Number" name="phone_number" hasFeedback showValidateSuccess>
                                        <Input
                                            addonBefore={
                                                <Select
                                                    name="country_phone"
                                                    defaultValue="+1 US"
                                                    showSearch
                                                    style={{ minWidth: 120 }}
                                                    onChange={handleChange}
                                                    placeholder={values.country_phone || '+1 US'}
                                                >
                                                    {countryPhones.map((country, index) => {
                                                        return (
                                                            <Select.Option value={`${country.shortName}-${country.phone}`} key={index}>
                                                                {country.emoji} +{country.phone} {country.shortName}
                                                            </Select.Option>
                                                        );
                                                    })}
                                                </Select>
                                            }
                                            name="phone_number"
                                            placeholder="Phone Number"
                                            size="large"
                                            onChange={(event) => {
                                                handleFormFieldUpdates(event);
                                            }}
                                        />
                                    </FormItem>
                                    <FormItem label="Country" name="country" hasFeedback showValidateSuccess>
                                        <Select
                                            showSearch
                                            name="country"
                                            placeholder="Please select a country"
                                            optionFilterProp="children"
                                            size="large"
                                            filterOption={(input, option) => {
                                                return (
                                                    option &&
                                                    option.props &&
                                                    option.props.children &&
                                                    option.props.children.props &&
                                                    option.props.children.props.children &&
                                                    option.props.children.props.children[1] &&
                                                    option.props.children.props.children[1].toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                );
                                            }}
                                            dropdownRender={(menu) => (
                                                <React.Fragment>
                                                    <div>{menu}</div>
                                                </React.Fragment>
                                            )}
                                        >
                                            {countryRegionData.map((option, index) => {
                                                return (
                                                    <Option value={option.countryShortCode} key={index} style={{ display: 'flex', alignItems: 'center' }}>
                                                        <span style={{ display: 'flex', alignItems: 'center' }}>
                                                            <span role="img" aria-label={option.countryName}>
                                                                <CircleFlag country={option.countryShortCode} left={0} />{' '}
                                                            </span>

                                                            {option.countryName}
                                                        </span>
                                                    </Option>
                                                );
                                            })}
                                        </Select>
                                    </FormItem>
                                    <FormItem label="City" name="city" hasFeedback showValidateSuccess>
                                        <Input
                                            name="city"
                                            placeholder="City"
                                            size="large"
                                            onChange={(event) => {
                                                handleFormFieldUpdates(event);
                                            }}
                                        />
                                    </FormItem>
                                    {values && (values.country === 'US' || values.country === 'USA') && (
                                        <FormItem label="State" name="state" hasFeedback showValidateSuccess>
                                            <Select
                                                showSearch
                                                name="state"
                                                placeholder="Please select a state"
                                                optionFilterProp="children"
                                                size="large"
                                                filterOption={(input, option) => {
                                                    return (
                                                        option &&
                                                        option.props &&
                                                        option.props.children &&
                                                        option.props.children.props &&
                                                        option.props.children.props.children &&
                                                        option.props.children.props.children[1] &&
                                                        option.props.children.props.children[1].toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    );
                                                }}
                                                dropdownRender={(menu) => (
                                                    <React.Fragment>
                                                        <div>{menu}</div>
                                                    </React.Fragment>
                                                )}
                                            >
                                                {CountryRegionData.filter((country) => {
                                                    return country[1] === 'US';
                                                }).map((option, index) => {
                                                    const states = option[2].split('|');

                                                    return states.map((state, index2) => {
                                                        const stateSplit = state.split('~');
                                                        const stateName = stateSplit[0];
                                                        const stateCode = stateSplit[1];
                                                        return (
                                                            <Option value={stateCode} key={`${index}-${index2}`}>
                                                                <span style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <span role="img" aria-label={stateName}>
                                                                        <CircleStateFlag state={stateCode} left={0} size="small" />{' '}
                                                                    </span>

                                                                    {stateName}
                                                                </span>
                                                            </Option>
                                                        );
                                                    });
                                                })}
                                            </Select>
                                        </FormItem>
                                    )}
                                    {league.has_team_support && (
                                        <FormItem label={props.division ? 'Team' : 'Teams'} name="league_team_players" hasFeedback showValidateSuccess>
                                            {props.division ? (
                                                <Query
                                                    query={GET_LEAGUE_DIVISION_TEAMS_QUERY}
                                                    fetchPolicy="cache-and-network"
                                                    notifyOnNetworkStatusChange
                                                    awaitRefetchQueries
                                                    variables={{ league_id: leagueID, division_id: divisionID }}
                                                >
                                                    {({ loading, error, data }) => {
                                                        if (loading)
                                                            return (
                                                                <div style={styles.container}>
                                                                    <CircularLoader />
                                                                </div>
                                                            );
                                                        if (error) return <div style={styles.container}>Error: {error.message}</div>;
                                                        if ((data && !data.league_teams) || (data && data.league_teams.length === 0)) {
                                                            return (
                                                                <ZeroState
                                                                    showImage
                                                                    message="No Teams have been added yet."
                                                                    style={{ margin: 30 }}
                                                                    {...props}
                                                                />
                                                            );
                                                        }

                                                        return (
                                                            <Select
                                                                showSearch
                                                                mode={props.division ? 'single' : 'multiple'}
                                                                name="league_team_players"
                                                                placeholder="Select team"
                                                                size="large"
                                                                loading={loading}
                                                                defaultValue={selectedTeam}
                                                                // disabled={divisionID ? true : false}
                                                                onChange={handleTeamChange}
                                                                allowClear={props.division ? false : true}
                                                                optionFilterProp="children"
                                                                optionLabelProp="children"
                                                                filterOption={(inputValue, option) => {
                                                                    return option.props.name.toString().toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
                                                                }}
                                                            >
                                                                <Option value={null}>
                                                                    <Text>None</Text>
                                                                </Option>
                                                                {data &&
                                                                    data.league_teams &&
                                                                    data.league_teams.map((item, index) => (
                                                                        <Option
                                                                            key={index}
                                                                            name={item.name}
                                                                            value={item.id}
                                                                            style={{ display: 'flex', alignItems: 'center' }}
                                                                        >
                                                                            <Text>{item.name}</Text>
                                                                        </Option>
                                                                    ))}
                                                            </Select>
                                                        );
                                                    }}
                                                </Query>
                                            ) : (
                                                <Query
                                                    query={GET_LEAGUE_TEAMS_QUERY}
                                                    fetchPolicy="cache-and-network"
                                                    notifyOnNetworkStatusChange
                                                    awaitRefetchQueries
                                                    variables={{ league_id: leagueID }}
                                                >
                                                    {({ loading, error, data }) => {
                                                        if (loading)
                                                            return (
                                                                <div style={styles.container}>
                                                                    <CircularLoader />
                                                                </div>
                                                            );
                                                        if (error) return <div style={styles.container}>Error: {error.message}</div>;
                                                        if ((data && !data.league_teams) || (data && data.league_teams.length === 0)) {
                                                            return (
                                                                <ZeroState
                                                                    showImage
                                                                    message="No Teams have been added yet."
                                                                    style={{ margin: 30 }}
                                                                    {...props}
                                                                />
                                                            );
                                                        }

                                                        return (
                                                            <Select
                                                                showSearch
                                                                mode={props.division ? 'single' : 'multiple'}
                                                                name="league_team_players"
                                                                placeholder="Select team"
                                                                size="large"
                                                                loading={loading}
                                                                defaultValue={selectedTeam}
                                                                // disabled={divisionID ? true : false}
                                                                onChange={handleTeamChange}
                                                                allowClear={props.division ? false : true}
                                                                optionFilterProp="children"
                                                                optionLabelProp="children"
                                                                filterOption={(inputValue, option) => {
                                                                    return option.props.name.toString().toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
                                                                }}
                                                            >
                                                                <Option value={null}>
                                                                    <Text>None</Text>
                                                                </Option>
                                                                {data &&
                                                                    data.league_teams &&
                                                                    data.league_teams.map((item, index) => (
                                                                        <Option
                                                                            key={index}
                                                                            name={item.name}
                                                                            value={item.id}
                                                                            style={{ display: 'flex', alignItems: 'center' }}
                                                                        >
                                                                            <Text>{item.name}</Text>
                                                                        </Option>
                                                                    ))}
                                                            </Select>
                                                        );
                                                    }}
                                                </Query>
                                            )}
                                        </FormItem>
                                    )}

                                    {/* <FormItem label="Special Group" name="special_group" hasFeedback showValidateSuccess>
                                    <Input name="special_group" placeholder="Special Group" size="large" />
                                </FormItem> */}

                                    <FormItem label="Fargo ID" name="fargo_id" hasFeedback showValidateSuccess>
                                        <Input
                                            name="fargo_id"
                                            placeholder="Fargo ID"
                                            size="large"
                                            onChange={(event) => {
                                                handleFormFieldUpdates(event);
                                            }}
                                        />
                                    </FormItem>

                                    <FormItem label="Skill Level" name="skill_level" hasFeedback showValidateSuccess>
                                        <Input
                                            name="skill_level"
                                            placeholder="Skill Level"
                                            size="large"
                                            onChange={(event) => {
                                                handleFormFieldUpdates(event);
                                            }}
                                            addonAfter={
                                                <Button
                                                    type="ghost"
                                                    onClick={() => {
                                                        // lookupPlayerFargoRating(values.name);
                                                        handleGetFargoRating();
                                                    }}
                                                    disabled={values.name ? false : true}
                                                >
                                                    Lookup Fargo
                                                </Button>
                                            }
                                        />
                                    </FormItem>
                                    {/* <FormItem label="Race to" name="race_to" hasFeedback showValidateSuccess>
                                    <Input name="race_to" placeholder="Race to" size="large" />
                                </FormItem> */}

                                    <FormItem label="Status" name="status" hasFeedback showValidateSuccess>
                                        <Query query={GET_LEAGUE_PLAYER_STATUS} fetchPolicy="cache-and-network" notifyOnNetworkStatusChange>
                                            {({ loading, error, data }) => {
                                                if (loading) return <div>Loading...</div>;
                                                if (error) return <div>Error: {error.message}</div>;

                                                return (
                                                    <Select
                                                        showSearch
                                                        name="status"
                                                        placeholder="Select player status"
                                                        size="large"
                                                        allowClear
                                                        optionFilterProp="children"
                                                    >
                                                        {data &&
                                                            data.league_player_status &&
                                                            data.league_player_status.map((item, index) => (
                                                                <Option key={index} value={item.value}>
                                                                    {item.value}
                                                                </Option>
                                                            ))}
                                                    </Select>
                                                );
                                            }}
                                        </Query>
                                    </FormItem>
                                    <FormItem name="league_dues_paid" label="League dues paid">
                                        <Radio.Group name="league_dues_paid">
                                            <Radio value={true}>Yes</Radio>
                                            <br />
                                            <Radio value={false}>No</Radio>
                                        </Radio.Group>
                                    </FormItem>
                                    <FormItem label="Last payment date" name="last_payment_date" hasFeedback showValidateSuccess>
                                        <DatePicker
                                            showTime
                                            name="last_payment_date"
                                            size="large"
                                            format="YYYY-MM-DD hh:mm A z"
                                            // onChange={onDateChange}
                                            onOk={onOk}
                                        />
                                    </FormItem>
                                    <FormItem name="user_id">
                                        <Input name="user_id" type="hidden" />
                                    </FormItem>

                                    <GetFargoModal
                                        player={values}
                                        modalVisible={fargoModalVisible}
                                        onModalOk={(players) => {
                                            handleFargoModalOk(players);
                                        }}
                                        onModalCancel={handleFargoModalCancel}
                                    />
                                </Form>
                            </Modal>
                        );
                    }}
                />
            </React.Fragment>
        );
    };

    return (
        <React.Fragment>
            {selectedPlayerId ? (
                <Query
                    query={GET_LEAGUE_PLAYER_QUERY}
                    fetchPolicy="cache-and-network"
                    notifyOnNetworkStatusChange={true}
                    variables={{ id: selectedPlayerId, league_id: leagueID }}
                    onCompleted={async (data) => {
                        console.log(data);
                    }}
                >
                    {({ loading, error, data }) => {
                        if (loading) return <CircularLoader />;
                        if (error) return <div>Error: {error.message}</div>;

                        const player = data && data.league_players && data.league_players.length > 0 ? data.league_players[0] : null;

                        return <React.Fragment>{renderForm(player)}</React.Fragment>;
                    }}
                </Query>
            ) : (
                <React.Fragment>{renderForm()}</React.Fragment>
            )}
        </React.Fragment>
    );
}

export default withRouter(AddPlayerModal);
