import React, { useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Menu, Icon, Switch } from 'antd';
import _ from 'lodash';

export default function PLNav(props) {
	const urlPath = window.location.pathname;
	const splitPath = urlPath.split('/');

	const [ theme, setTheme ] = useState('dark');
	const [ current, setCurrent ] = useState(splitPath[splitPath.length - 1] || 'pool-live');

	const handleClick = (e) => {
		console.log('click ', e);
		props.onClicked();
		setCurrent(e.key);
	};

	const isActive = (link, args) => {
		const urlPath = window.location.pathname;
		const splitPath = urlPath.split('/');
		const isRootPath = args && args.isRootPath;
		const rootPath = args && args.rootPath;
		const includes = args && args.includes;

		if (urlPath === '/' || isRootPath === true) {
			return urlPath === link;
		} else if (urlPath.indexOf(rootPath) !== -1) {
			return true;
		} else if (includes && includes.length > 0) {
			return _.includes(includes, splitPath[1]);
		} else {
			return _.includes(splitPath, link);
		}
	};

	return (
		<Menu
			theme={theme}
			onClick={handleClick}
			// style={{ width: 256 }}
			// defaultOpenKeys={[ 'sub1' ]}
			selectedKeys={[ current ]}
			mode="inline"
		>
			<Menu.Item key="pool-live">
				<Link to="/pool-live">
					<Icon type="home" /> Home
				</Link>
			</Menu.Item>

			<Menu.Item key="new">
				<Link to="/pool-live/new">
					<Icon type="plus" /> Create Stream
				</Link>
			</Menu.Item>

			<Menu.Divider style={{ backgroundColor: '#324454' }} />
			<Menu.Item key="1">
				<Link to="/pool-live/stream-1">
					<Icon type="play-circle" /> Stream 1
				</Link>
			</Menu.Item>
			<Menu.Item key="2">
				<Link to="/pool-live/stream-2">
					<Icon type="play-circle" /> Stream 2
				</Link>
			</Menu.Item>
		</Menu>
	);
}
