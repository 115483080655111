import React, { useState, useEffect } from 'react';
import { List, Tabs, Row, Col, Button, Tooltip, Icon, Typography, Empty, Input } from 'antd';
import { Query, Subscription } from '@apollo/client/react/components';
import CircleFlag from '../../../../components/CircleFlag';
import { getCountryName, formatPlayerName, isoCountries } from '../../../../utils';
import { Link, withRouter } from 'react-router-dom';
import { GET_TOURNAMENT_BRACKET_PLAYERS_QUERY } from '../data/queries';
import { GET_TOURNAMENT_BRACKET_PLAYERS_SUBSCRIPTION } from '../data/subscriptions';
import CircularLoader from '../../../../components/CircularLoader';
import Fade from 'react-reveal/Fade';
import _ from 'lodash';
import PlayerModal from './PlayerModal';

const { Search } = Input;
const { Text } = Typography;

const styles = {};

const tabHeight = 45;

export default function SidePanelPlayers(props) {
    const { tournament, bracketHeight, data, theme } = props;
    const [searchResults, setSearchResults] = useState();
    const [playerModalVisible, setPlayerModalVisible] = useState(false);
    const [selectedPlayer, setSelectedPlayer] = useState({});
    let players = data.tournament_players.map((player, index) => {
        return player;
    });
    // .sort((a, b) => {
    //     if (a.seed && b.seed) {
    //         return a.seed.toString().localeCompare(b.seed.toString(), undefined, {
    //             numeric: true,
    //             sensitivity: 'base',
    //         });
    //     }
    // });

    const playersLeft = players.filter((player) => {
        return player.place === null;
    });

    // console.log(playersLeft);

    // players = _.uniqBy(
    // 	players
    // 		.sort((a, b) => {
    // 			return a.seed - b.seed;
    // 			// return a.name.localeCompare(b.name);
    // 		})
    // 		.filter((item) => {
    // 			return item.seed;
    // 		}),
    // 	'seed'
    // );

    // players = players.sort((a, b) => {
    // 	return a.seed - b.seed;
    // });

    function escapeRegexCharacters(str) {
        return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    }

    function onChange(e) {
        const value = e.target.value;
        const suggestions = getSuggestions(value);
        setSearchResults(suggestions);
    }

    function handleSearch(value) {
        const suggestions = getSuggestions(value);
        setSearchResults(suggestions);
    }

    function getSuggestions(value) {
        const inputValue = value.trim().toLowerCase();
        const escapedValue = escapeRegexCharacters(inputValue);

        if (escapedValue === '') {
            return [];
        }
        const regex = new RegExp('\\b' + escapedValue, 'i');
        const suggestions = players;
        const filtered = suggestions && suggestions.filter((suggestion) => regex.test(suggestion.name));
        return filtered;
    }

    function handleShowPlayerModal(player) {
        console.log(player);
        setSelectedPlayer(player);
        setPlayerModalVisible(true);
    }

    function handlePlayerModalVisible(visibility) {
        setPlayerModalVisible(visibility);
    }

    return (
        <React.Fragment>
            <Row
                gutter={16}
                justify="center"
                type="flex"
                style={{
                    margin: 0,
                    padding: 0,
                    height: bracketHeight - tabHeight - 59,
                    width: '100%',
                }}
            >
                <Col xs={24} style={{ borderBottom: `1px solid ${theme.bracket.panels.borderColor}` }}>
                    <Search
                        placeholder="Search by player name"
                        onSearch={handleSearch}
                        onChange={onChange}
                        style={{ width: 'calc(100% - 10px)' }}
                        className={theme && theme.name === 'dark' && 'player-search-clear'}
                    />
                </Col>
                <Col xs={24} style={{ borderBottom: `1px solid ${theme.bracket.panels.borderColor}` }}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', color: theme.bracket.panels.textColor }}>
                        <div>
                            Total players: <b>{players.length}</b>
                        </div>
                        <div>
                            Players left: <b>{playersLeft.length}</b>
                        </div>
                        <div>
                            Eliminated: <b>{players.length - playersLeft.length}</b>
                        </div>
                    </div>
                </Col>
                <Col
                    xs={24}
                    style={{
                        margin: 0,
                        padding: 0,
                        overflowY: 'auto',
                        height: 'calc(100% - 49px)',
                        width: '100%',
                        alignItems: 'center',
                    }}
                >
                    <List
                        className="hover-list player-list"
                        size="small"
                        loading={false}
                        itemLayout="horizontal"
                        dataSource={searchResults && searchResults.length > 0 ? searchResults : players}
                        header={
                            <div
                                style={{
                                    padding: '0px 20px',
                                    margin: 0,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <div>
                                    <Text style={{ fontWeight: 700, color: theme.bracket.panels.textColor }}>Seed</Text>
                                    <Text style={{ fontWeight: 700, marginLeft: 10, color: theme.bracket.panels.textColor }}>Name</Text>
                                </div>
                                <Text style={{ fontWeight: 700, color: theme.bracket.panels.textColor }}>Place</Text>
                            </div>
                        }
                        renderItem={(player) => {
                            return (
                                <Fade up cascade distance="40px" delay={300} duration={500}>
                                    <React.Fragment>
                                        {player && player.name && (
                                            <List.Item
                                                style={{
                                                    padding: '10px 10px',
                                                    textDecoration: player.place !== null ? 'line-through' : 'normal',
                                                    opacity: player.place !== null ? 0.5 : 1,
                                                    color: theme.bracket.panels.textColor,
                                                }}
                                                onClick={(event) => {
                                                    handleShowPlayerModal(player);
                                                }}
                                                actions={[
                                                    <Text style={{ whiteSpace: 'nowrap', color: theme.bracket.panels.textColor }}>{player.place || '-'}</Text>,
                                                ]}
                                            >
                                                <Text style={{ width: 80, color: theme.bracket.panels.textColor }}>{player.seed || '-'}</Text>

                                                <div>{player.country && <CircleFlag country={player.country} left={0} />}</div>

                                                <Text
                                                    style={{
                                                        marginLeft: 0,
                                                        width: '100%',
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        color: theme.bracket.panels.textColor,
                                                    }}
                                                >
                                                    <Tooltip placement="right" title={formatPlayerName(player)}>
                                                        <div className="ellipsis">
                                                            {formatPlayerName(player)} {player.race_to ? `(${player.race_to})` : null}
                                                        </div>
                                                    </Tooltip>
                                                </Text>
                                            </List.Item>
                                        )}
                                    </React.Fragment>
                                </Fade>
                            );
                        }}
                    />
                </Col>
            </Row>
            <Row>
                <Col
                    xs={24}
                    style={{
                        margin: 0,
                        paddingLeft: 8,
                        paddingRight: 8,
                        borderTop: `1px solid ${theme.bracket.panels.borderColor}`,
                        backgroundColor: theme.bracket.panels.backgroundColor,
                    }}
                >
                    <Link to={`/tournament-builder/${tournament.slug}/players`}>
                        <Button size="large" type="ghost" style={{ width: '100%', color: theme.bracket.panels.textColor }}>
                            Manage Players
                        </Button>
                    </Link>
                </Col>
            </Row>
            <PlayerModal
                tournament={tournament}
                tournamentID={tournament.id}
                playerId={selectedPlayer.id}
                modalVisible={playerModalVisible}
                onPlayerModalVisible={handlePlayerModalVisible}
            />
        </React.Fragment>
    );
}
