import React, { useState, useEffect, useRef } from 'react';
import { message, Icon, Row, Col, Typography, Progress, Button, Input, Tabs, Empty, Table, Modal } from 'antd';

import useBreakpoint from 'use-breakpoint';
import { defaultBreakpoints } from '../../../utils/defaultBreakpoints';
import CircleFlag from '../../../components/CircleFlag';
import Highlighter from 'react-highlight-words';
import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';
momentDurationFormatSetup(moment);

export default function ScheduledTables(props) {
    const { tournament, matches, sortBy, subscribeToMore, subscribeToNewData } = props;
    const { breakpoint, maxWidth, minWidth } = useBreakpoint(defaultBreakpoints);
    const defaultSize = 'small';
    var zone_name = moment.tz.guess();
    var timezone = moment.tz(zone_name).zoneAbbr();
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);

    useEffect(() => {
        let unsubscribe;
        if (subscribeToNewData) {
            unsubscribe = subscribeToNewData(subscribeToMore);
        }
        return () => {
            if (unsubscribe) {
                unsubscribe();
            }
        };
    }, []);

    function handleSearch(selectedKeys, confirm, dataIndex) {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    }

    function handleReset(clearFilters) {
        clearFilters();
        setSearchText('');
    }

    function getColumnSearchProps(dataIndex) {
        return {
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={searchInput}
                        placeholder={`Search ${dataIndex}`}
                        value={selectedKeys[0]}
                        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        style={{ width: 188, marginBottom: 8, display: 'block' }}
                    />
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon="search"
                        size="small"
                        style={{ width: 90, marginRight: 8 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                </div>
            ),
            filterIcon: (filtered) => <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
            onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
            onFilterDropdownVisibleChange: (visible) => {
                if (visible) {
                    setTimeout(() => searchInput.current.select());
                }
            },
            render: (text) =>
                searchedColumn === dataIndex ? (
                    <Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={[searchText]}
                        autoEscape
                        textToHighlight={text.toString()}
                    />
                ) : (
                    text
                ),
        };
    }

    const columns = [
        {
            title: '#',
            dataIndex: 'index',
            key: 'index',
            width: 80,
            // defaultSortOrder: 'desc',
            render: (text, record) => {
                return <div>{text || '-'}</div>;
            },
        },
        {
            title: 'Table',
            dataIndex: 'table_name',
            align: 'left',
            key: 'table',
            ...getColumnSearchProps('table_name'),
            width: 80,
            sorter: (a, b) => {
                return (
                    a.table_name &&
                    b.table_name &&
                    a.table_name.localeCompare(b.table_name, undefined, {
                        numeric: true,
                        sensitivity: 'base',
                    })
                );
            },
            render: (text, record) => {
                return (
                    <div style={{ whiteSpace: 'nowrap' }}>
                        {/* <img src={tableBlue} width={25} alt="Table 1" /> */}
                        {/* <span style={{ marginLeft: 10 }}>{text || '-'}</span> */}
                        <span>{text || '-'}</span>
                    </div>
                );
            },
        },

        {
            title: 'Match',
            dataIndex: 'match_number',
            key: 'match_number',
            ...getColumnSearchProps('match_number'),
            width: 50,

            sorter: (a, b) => b.match_number - a.match_number,
        },

        {
            title: 'Order',
            dataIndex: 'priority',
            key: 'priority',
            width: 80,
            // defaultSortOrder: 'desc',
            sorter: (a, b) => b.priority - a.priority,
            render: (text, record) => {
                return <div>{text || '-'}</div>;
            },
        },

        {
            title: 'Players',
            dataIndex: 'players',
            key: 'players',
            width: 300,
            ...getColumnSearchProps('names'),
            render: (text, record) => {
                const country1 = record.challenger1_country;
                const country2 = record.challenger2_country;

                return (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                        {country1 && <CircleFlag country={country1} left={0} />}

                        <div
                            style={{
                                fontWeight:
                                    record.challenger1_is_winner === true || record.challenger1_score === getChallengerRaceTo(record, 'challenger1')
                                        ? 'bold'
                                        : 'normal',
                            }}
                        >
                            {`${record.challenger1_name || '-'} ${
                                record.challenger1_name && record.challenger1_name !== 'BYE' && record.challenger1_race_to
                                    ? `(${record.challenger1_race_to})`
                                    : ''
                            }` || '-'}
                        </div>

                        {/* <span
                            style={{
                                marginLeft: 10,
                                padding: '5px 10px',
                                backgroundColor: !record.challenger1_name
                                    ? 'rgba(0,0,0,0.4)'
                                    : record.challenger1_is_winner === true || record.challenger1_score === getChallengerRaceTo(record, 'challenger1')
                                    ? '#1890fe'
                                    : theme && theme.name === 'dark'
                                    ? '#135da2'
                                    : '#61b3ff',
                                borderRadius: 5,
                                color: '#fff',
                            }}
                        >
                            {record.challenger1_is_forfeit === true ? 'FF' : record.challenger1_score || 0}
                        </span> */}

                        <div style={{ marginLeft: 10, marginRight: 10 }}>vs.</div>

                        {country2 && <CircleFlag country={country2} left={0} />}

                        {/* <span
                            style={{
                                marginRight: 20,
                                padding: '5px 10px',
                                backgroundColor: !record.challenger2_name
                                    ? 'rgba(0,0,0,0.4)'
                                    : record.challenger2_is_winner === true || record.challenger2_score === getChallengerRaceTo(record, 'challenger2')
                                    ? '#1890fe'
                                    : theme && theme.name === 'dark'
                                    ? '#135da2'
                                    : '#61b3ff',
                                borderRadius: 5,
                                color: '#fff',
                            }}
                        >
                            {record.challenger2_is_forfeit === true ? 'FF' : record.challenger2_score || 0}
                        </span> */}

                        <span
                            style={{
                                marginRight: 10,
                                fontWeight:
                                    record.challenger2_is_winner === true || record.challenger2_score === getChallengerRaceTo(record, 'challenger2')
                                        ? 'bold'
                                        : 'normal',
                            }}
                        >
                            {`${record.challenger2_name || '-'} ${
                                record.challenger2_name && record.challenger2_name !== 'BYE' && record.challenger2_race_to
                                    ? `(${record.challenger2_race_to})`
                                    : ''
                            }` || '-'}
                        </span>
                    </div>
                );
            },
        },

        // {
        //     title: 'Player 2',
        //     dataIndex: 'challenger2_name',
        //     key: 'player2',
        //     width: 300,
        //     sorter: (a, b) => {
        //         return a.challenger2_name.localeCompare(b.challenger2_name, undefined, {
        //             numeric: true,
        //             sensitivity: 'base',
        //         });
        //     },
        //     render: (text, record) => {
        //         const country = record.challenger2_country;

        //         return (
        //             <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
        //                 <span
        //                     style={{
        //                         marginRight: 20,
        //                         padding: '5px 10px',
        //                         backgroundColor: !record.challenger2_name
        //                             ? 'rgba(0,0,0,0.4)'
        //                             : record.challenger2_is_winner === true || record.challenger2_score === getChallengerRaceTo(record, 'challenger2')
        //                             ? '#1890fe'
        //                             : theme && theme.name === 'dark'
        //                             ? '#135da2'
        //                             : '#61b3ff',
        //                         borderRadius: 5,
        //                         color: '#fff',
        //                     }}
        //                 >
        //                     {record.challenger2_is_forfeit === true ? 'FF' : record.challenger2_score || 0}
        //                 </span>

        //                 {country && <CircleFlag country={country} left={0} />}

        //                 <span
        //                     style={{
        //                         marginLeft: 0,
        //                         fontWeight:
        //                             record.challenger2_is_winner === true || record.challenger2_score === getChallengerRaceTo(record, 'challenger2')
        //                                 ? 'bold'
        //                                 : 'normal',
        //                     }}
        //                 >
        //                     {`${record.challenger2_name || ''} ${
        //                         record.challenger2_name !== 'BYE' && record.challenger2_race_to ? `(${record.challenger2_race_to})` : ''
        //                     }` || '-'}
        //                 </span>
        //             </div>
        //         );
        //     },
        // },

        {
            title: 'Time',
            dataIndex: 'scheduled_time',
            key: 'scheduled_time',
            width: 200,
            sorter: (a, b) => a.scheduled_time - b.scheduled_time,
            render: (text, record) => {
                const matches = record.matches;
                let followsMatch;

                if (matches && matches.length > 0) {
                    const follows = matches.filter((match) => {
                        if (match.priority && match.priority === record.priority - 1 && record.table_id === match.table_id) {
                            return match;
                        }
                    })[0];

                    if (follows) {
                        followsMatch = follows.match_number;
                    }
                }

                return (
                    <div style={{ whiteSpace: 'nowrap' }}>
                        {(!record.start_time || !record.scheduled_time) && followsMatch
                            ? `Follows Match ${followsMatch}`
                            : record.status === 'COMPLETED' && record.end_time
                            ? `Ended - ${moment.utc(record.end_time).local().format('lll z')} ${timezone}`
                            : record.status !== 'COMPLETED' && record.scheduled_time
                            ? `Scheduled - ${moment.utc(record.scheduled_time).local().format('lll z')} ${timezone}`
                            : record.status !== 'COMPLETED' && record.start_time
                            ? `Started - ${moment.utc(record.start_time).local().format('lll z')} ${timezone}`
                            : record.status !== 'COMPLETED' && record.start_date_time
                            ? `Started - ${moment.utc(record.start_date_time).local().format('lll z')} ${timezone}`
                            : '-'}
                    </div>
                );
            },
        },

        {
            title: 'Score',
            dataIndex: 'score',

            width: 100,
            render: (text, record) => {
                return (
                    <div style={{ whiteSpace: 'nowrap' }}>
                        {record.challenger1_is_forfeit === true ? 'FF' : record.challenger1_score || 0} -{' '}
                        {record.challenger2_is_forfeit === true ? 'FF' : record.challenger2_score || 0}
                    </div>
                );
            },
            sorter: (a, b) => {
                return a.identifier.localeCompare(b.identifier, undefined, {
                    numeric: true,
                    sensitivity: 'base',
                });
            },
        },

        {
            title: 'Round',
            dataIndex: 'identifier',
            key: (a) => {
                return `identifier-${a.identifier}`;
            },
            width: 100,
            render: (text, record) => {
                return (
                    <div style={{ whiteSpace: 'nowrap' }}>
                        {record.identifier}{' '}
                        {/* {record.loser_to ? (
							`(Loser to ${record.loser_to})`
						) : record.loser_from ? (
							`(Loser from ${record.loser_from})`
						) : record.winner_to ? (
							`(Winner to ${record.winner_to})`
						) : (
							''
						)} */}
                    </div>
                );
            },
            sorter: (a, b) => {
                return a.identifier.localeCompare(b.identifier, undefined, {
                    numeric: true,
                    sensitivity: 'base',
                });
            },
        },

        {
            title: 'Progress',
            dataIndex: 'progress',
            key: 'progress',

            render: (text, record) => {
                return (
                    <React.Fragment>
                        <Progress
                            percent={record.progress || 0}
                            status={record.progress < 100 ? 'active' : record.progress === 100 ? 'success' : 'normal'}
                            size="small"
                            // showInfo={false}
                            strokeWidth={6}
                            strokeLinecap="square"
                        />
                    </React.Fragment>
                );
            },
        },

        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            sorter: (a, b) => {
                return a.status.localeCompare(b.status, undefined, {
                    numeric: true,
                    sensitivity: 'base',
                });
            },
        },
    ];

    function getChallengerRaceTo(currentMatch, challenger) {
        const identifier = currentMatch.identifier && currentMatch.identifier.slice(0, 1);
        const challenger_race_to =
            challenger === 'challenger1' ? currentMatch.challenger1_race_to : challenger === 'challenger2' ? currentMatch.challenger2_race_to : null;
        if (challenger_race_to) {
            return challenger_race_to;
        } else {
            if (identifier === 'W') {
                return tournament.winners_race_to;
            } else {
                return tournament.losers_race_to;
            }
        }
    }

    function getClassName(record) {
        const status =
            record.status === 'IN_PROGRESS'
                ? 'in_progress'
                : record.status === 'PENDING'
                ? 'pending blinking'
                : record.status === 'COMPLETED'
                ? 'completed'
                : record.status === 'NOT_STARTED'
                ? 'not_started'
                : '';
        const isStreamingTable = record.pool_table && record.pool_table.is_streaming_table === true ? 'streaming' : '';
        const isFeaturedTable = record.pool_table && record.pool_table.is_featured_table === true ? 'featured' : '';
        return `${status} ${isStreamingTable} ${isFeaturedTable}`;
    }

    let sortedMatches =
        sortBy === 'table_time'
            ? matches.sort((a, b) => {
                  return (
                      a.table_name.localeCompare(b.table_name, undefined, {
                          numeric: true,
                          sensitivity: 'base',
                      }) ||
                      a.scheduled_time.localeCompare(b.scheduled_time, undefined, {
                          numeric: true,
                          sensitivity: 'base',
                      })
                  );
              })
            : sortBy === 'table_priority'
            ? matches.sort((a, b) => {
                  return (
                      a.table_name.localeCompare(b.table_name, undefined, {
                          numeric: true,
                          sensitivity: 'base',
                      }) || a.priority - b.priority
                  );
              })
            : sortBy === 'table'
            ? matches.sort((a, b) => {
                  return a.table_name.localeCompare(b.table_name, undefined, {
                      numeric: true,
                      sensitivity: 'base',
                  });
              })
            : sortBy === 'time'
            ? matches.sort((a, b) => {
                  return a.scheduled_time.localeCompare(b.scheduled_time, undefined, {
                      numeric: true,
                      sensitivity: 'base',
                  });
              })
            : null;

    const formatted = sortedMatches.map((item, index) => {
        return {
            ...item,
            table_name: item.table_name || (item && item.scheduled_table && item.scheduled_table.label),
            matches: matches,
            index: index + 1,
            key: index,
        };
    });

    return (
        <div className="table_nowrap">
            <Table
                dataSource={formatted}
                columns={columns}
                className="match-table table-striped-rows noselect"
                tableLayout="auto"
                scroll={{ x: 400 }}
                size={defaultSize}
                pagination={false}
                // components={{
                // 	body: {
                // 		row: RenderRow
                // 	}
                // }}
                onRow={(record, rowIndex) => {
                    return {
                        onClick: (event) => {
                            // console.log("E", record);
                            // setCurrentMatch(record);
                            // handleModal();
                        }, // click row
                        onDoubleClick: (event) => {}, // double click row
                        onContextMenu: (event) => {}, // right button click row
                        onMouseEnter: (event) => {}, // mouse enter row
                        onMouseLeave: (event) => {}, // mouse leave row
                        className: getClassName(record),
                    };
                }}
                onHeaderRow={(column) => {
                    return {
                        onClick: () => {}, // click header row
                    };
                }}
            />
        </div>
    );
}
