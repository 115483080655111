import React, { useState, useRef } from 'react';
import { Table, Typography, Button, Icon, Modal, Tooltip, Input } from 'antd';

const { Text } = Typography;
const { confirm } = Modal;

export default function PointsTable(props) {
	const { onEditSelected, onDeleteSelected, onDelete, tableData } = props;
	const columns = [
		{
			title: 'Place',
			dataIndex: 'place',
			defaultSortOrder: 'ascend',
			width: 100,
			render: (text, record) => {
				return (
					<div>
						<Text>{text || '-'}</Text>
					</div>
				);
			}
		},
		{
			title: 'Points',
			dataIndex: 'points',
			render: (text, record) => {
				return (
					<div>
						<Text>{text || '-'}</Text>
					</div>
				);
			}
		},
		{
			title: 'Action',
			key: 'action',
			width: 100,
			render: (text, record) => (
				<span style={{ whiteSpace: 'nowrap' }}>
					<Tooltip title="Edit">
						<Button
							type="ghost"
							onClick={(e) => {
								e.preventDefault();
								e.stopPropagation();
								onEditSelected(record);
								// props.history.push(
								// 	`/tournament-builder/${props.tournament.slug}/venues/${record.slug}/edit`
								// );
							}}
						>
							<Icon type="edit" />
						</Button>
					</Tooltip>
					{/* <Divider type="vertical" /> */}
					<Tooltip title="Delete">
						<Button
							type="ghost"
							onClick={(e) => {
								e.preventDefault();
								e.stopPropagation();
								showConfirm(record);
							}}
						>
							<Icon type="delete" />
						</Button>
					</Tooltip>
				</span>
			)
		}
	];

	function showConfirm(record) {
		confirm({
			title: `Are you sure you want to remove place ${record.place}?`,
			content: 'Note: This action cannot be undone.',
			centered: true,
			transitionName: 'fade',
			maskTransitionName: 'none',
			// onOk() {
			// 	return new Promise((resolve, reject) => {
			// 		setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
			// 	}).catch(() => console.log('Oops errors!'));
			// },
			onOk() {
				onDelete(record);
			},
			onCancel() {}
		});
	}

	return (
		<Table
			dataSource={tableData}
			columns={columns}
			className="table-striped-rows"
			pagination={false}
			tableLayout="auto"
		/>
	);
}
