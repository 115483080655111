import React, { useState } from 'react';
import { Icon, Row, Col, Typography, Button, Progress, Input, Table, Tabs, Empty } from 'antd';
import Card from '../../components/Card';
import { Link } from 'react-router-dom';
import { Query, Subscription } from '@apollo/client/react/components';
import CircularLoader from '../../components/CircularLoader';
import SectionHeader from '../../components/SectionHeader';
import SectionContent from '../../components/SectionContent';
import SectionWrapper from '../../components/SectionWrapper';
import { getCountryName } from '../../utils/getCountryName';
import CircleFlag from '../../components/CircleFlag';
import { GET_LEAGUE_MATCHES_BY_STATUS } from './data/queries';
import _ from 'lodash';
import useBreakpoint from 'use-breakpoint';
import { defaultBreakpoints } from '../../utils/defaultBreakpoints';
import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';
momentDurationFormatSetup(moment);

const { Text, Title } = Typography;

const columns = [
    {
        title: 'Match',
        dataIndex: 'id',
        key: 'id',
        width: 100,
    },

    {
        title: 'Time',
        dataIndex: 'scheduled_time',
        key: 'scheduled_time',
        width: 200,
        render: (text, record) => {
            return <Text style={{ whiteSpace: 'nowrap' }}>{text ? moment(text).format('lll') : moment(record.scheduled_time).format('ll')}</Text>;
        },
    },

    {
        title: 'Player 1',
        dataIndex: 'player1',
        key: 'player1',
        align: 'right',
        width: 300,
        render: (text, record) => {
            const country = record.challenger1_country;

            return (
                <div style={{ whiteSpace: 'nowrap' }}>
                    <Text
                        style={{
                            marginRight: 10,
                            fontWeight: record.challenger1_frames > record.challenger2_frames ? 'bold' : 'normal',
                        }}
                    >
                        {record.challenger1_name} {record.challenger1_skill_level ? `(${record.challenger1_skill_level})` : ''}
                    </Text>
                    {country && <CircleFlag country={country} left={0} />}
                    <span
                        style={{
                            marginLeft: 10,
                            padding: '5px 10px',
                            backgroundColor: record.challenger1_frames > record.challenger2_frames ? '#1890fe' : '#8dc8ff',
                            borderRadius: 5,
                            color: '#fff',
                        }}
                    >
                        {record.challenger1_frames || 0}
                    </span>
                </div>
            );
        },
    },
    {
        title: 'Player 2',
        dataIndex: 'player2',
        key: 'player2',
        width: 300,
        render: (text, record) => {
            const country = record.challenger2_country;

            return (
                <div style={{ whiteSpace: 'nowrap' }}>
                    <span
                        style={{
                            marginRight: 20,
                            padding: '5px 10px',
                            backgroundColor: record.challenger2_frames > record.challenger1_frames ? '#1890fe' : '#8dc8ff',
                            borderRadius: 5,
                            color: '#fff',
                        }}
                    >
                        {record.challenger2_frames || 0}
                    </span>
                    {country && <CircleFlag country={country} left={0} />}
                    <span
                        style={{
                            marginLeft: 10,
                            fontWeight: record.challenger2_frames > record.challenger1_frames ? 'bold' : 'normal',
                        }}
                    >
                        {record.challenger2_name} {record.challenger2_skill_level ? `(${record.challenger2_skill_level})` : ''}
                    </span>
                </div>
            );
        },
    },
    {
        title: 'Venue',
        dataIndex: 'venue',
        key: 'venue',
        render: (text, record) => {
            return <Text>{text || '-'}</Text>;
        },
    },
    {
        title: 'Table',
        dataIndex: 'table_name',
        align: 'left',
        key: 'table',
        width: 150,
        render: (text, record) => {
            return (
                <div style={{ whiteSpace: 'nowrap' }}>
                    {/* <img src={tableBlue} width={25} alt="Table 1" /> */}
                    {/* <span style={{ marginLeft: 10 }}>{text || '-'}</span> */}
                    <Text>{text || '-'}</Text>
                </div>
            );
        },
    },

    {
        title: 'Progress',
        dataIndex: 'progress',
        key: 'progress',
        render: (text, record) => {
            return (
                <React.Fragment>
                    <Progress
                        percent={record.progress || 0}
                        status={record.progress < 100 ? 'active' : record.progress === 100 ? 'success' : 'normal'}
                        size="small"
                        // showInfo={false}
                        strokeWidth={6}
                        strokeLinecap="square"
                    />
                    {/* <div
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'flex-start',
							width: '100%'
						}}
					>
						<span
							style={{
								width: '100%',
								textAlign: 'left',
								backgroundColor: '#ececec',
								height: 10,
								position: 'relative'
							}}
						>
							<span
								style={{
									display: 'inline-block',
									// backgroundColor: '#bac2cc',
									backgroundColor: '#1890fe',
									width: `${(record.progress || 0) / 100 * 100}%`,
									height: '100%',
									position: 'absolute',
									transition: 'background-color .3s ease',
									cursor: 'pointer'
								}}
							/>
						</span>
						<span
							style={{
								width: 80,
								marginLeft: 10
							}}
						>
							{record.progress || 0}%
						</span>
					</div> */}
                </React.Fragment>
            );
        },
    },
    {
        title: 'Duration',
        dataIndex: 'duration',
        key: 'duration',
        render: (text, record) => {
            const duration = moment.duration(moment.utc().diff(record.start_time, 'milliseconds')).format('h [hrs], m [min]');
            return <Text>{duration}</Text>;
        },
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
    },
];

const defaultSize = 'medium';

function LMSchedule(props) {
    const { league } = props;
    const { breakpoint, maxWidth, minWidth } = useBreakpoint(defaultBreakpoints);

    const styles = {
        row: {
            display: 'flex',
            flexGrow: 1,
        },
        tab: {
            paddingTop: 5,
            paddingLeft: 24,
            paddingRight: 24,
            paddingBottom: 24,
        },
    };

    function getClassName(record) {
        const status =
            record.status === 'IN_PROGRESS'
                ? 'in_progress'
                : record.status === 'COMPLETED'
                ? 'completed'
                : record.status === 'NOT_STARTED'
                ? 'not_started'
                : '';
        const isStreamingTable = record.pool_table && record.pool_table.is_streaming_table === true ? 'streaming' : '';
        const isFeaturedTable = record.pool_table && record.pool_table.is_featured_table === true ? 'featured' : '';
        return `${status} ${isStreamingTable} ${isFeaturedTable}`;
    }

    return (
        <SectionWrapper>
            <SectionHeader
                title={props.title}
                titleStyle={{ color: '#fff', margin: 0, textTransform: 'uppercase' }}
                avatar={league.avatar}
                subtitle={league.name}
                subtitlePrefix={true}
                subtitleStyle={{
                    color: 'rgba(255,255,255,0.8)',
                    textTransform: 'uppercase',
                    fontWeight: 600,
                    fontSize: 12,
                    letterSpacing: 3,
                }}
                theme="leagueManager"
            >
                <Link to={`/leagues/${props.match.params.slug}`}>
                    <Button type="ghost" ghost>
                        Public Profile
                    </Button>
                </Link>
            </SectionHeader>

            <SectionContent padding="20px 40px">
                <Row gutter={24} justify="center" type="flex">
                    <Col xs={24}>
                        <Query
                            query={GET_LEAGUE_MATCHES_BY_STATUS}
                            fetchPolicy="cache-and-network"
                            notifyOnNetworkStatusChange={true}
                            variables={{ league_id: league.id, status: 'IN_PROGRESS' }}
                            onCompleted={(data) => {
                                // console.log(data);
                            }}
                        >
                            {({ loading, error, data }) => {
                                if (loading) return <CircularLoader />;
                                if (error) return <div style={styles.container}>Error: {error.message}</div>;

                                const matches =
                                    data.matches &&
                                    data.matches.map((item, index) => {
                                        return {
                                            ...item,
                                            key: index,
                                            player1: item.match_players && item.match_players[0],
                                            player2: item.match_players && item.match_players[1],
                                            venue: item.venue && item.venue.name,
                                        };
                                    });
                                return (
                                    <Card title={`In Progress (${matches.length})`} bodyStyle={{ padding: 0 }}>
                                        <div className="table_nowrap">
                                            <Table
                                                dataSource={matches}
                                                columns={columns}
                                                className="match-table table-striped-rows"
                                                tableLayout="auto"
                                                scroll={{ x: 400 }}
                                                size={defaultSize}
                                                pagination={false}
                                                // components={{
                                                // 	body: {
                                                // 		row: RenderRow
                                                // 	}
                                                // }}
                                                onRow={(record, rowIndex) => {
                                                    return {
                                                        onClick: (event) => {}, // click row
                                                        onDoubleClick: (event) => {}, // double click row
                                                        onContextMenu: (event) => {}, // right button click row
                                                        onMouseEnter: (event) => {}, // mouse enter row
                                                        onMouseLeave: (event) => {}, // mouse leave row
                                                        className: getClassName(record),
                                                    };
                                                }}
                                                onHeaderRow={(column) => {
                                                    return {
                                                        onClick: () => {}, // click header row
                                                    };
                                                }}
                                            />
                                        </div>
                                    </Card>
                                );
                            }}
                        </Query>
                    </Col>
                    <Col xs={24}>
                        <Query
                            query={GET_LEAGUE_MATCHES_BY_STATUS}
                            fetchPolicy="cache-and-network"
                            notifyOnNetworkStatusChange={true}
                            variables={{ league_id: league.id, status: 'NOT_STARTED' }}
                            onCompleted={(data) => {
                                // console.log(data);
                            }}
                        >
                            {({ loading, error, data }) => {
                                if (loading) return <CircularLoader />;
                                if (error) return <div style={styles.container}>Error: {error.message}</div>;

                                console.log(data);

                                const matches =
                                    data.matches &&
                                    data.matches.map((item, index) => {
                                        return {
                                            ...item,
                                            key: index,
                                            player1: item.match_players && item.match_players[0],
                                            player2: item.match_players && item.match_players[1],
                                            venue: item.venue && item.venue.name,
                                        };
                                    });
                                return (
                                    <Card title={`Upcoming (${matches.length})`} bodyStyle={{ padding: 0 }}>
                                        <div className="table_nowrap">
                                            <Table
                                                dataSource={matches}
                                                columns={columns}
                                                className="match-table table-striped-rows"
                                                tableLayout="auto"
                                                scroll={{ x: 400 }}
                                                size={defaultSize}
                                                pagination={false}
                                                onRow={(record, rowIndex) => {
                                                    return {
                                                        onClick: (event) => {}, // click row
                                                        onDoubleClick: (event) => {}, // double click row
                                                        onContextMenu: (event) => {}, // right button click row
                                                        onMouseEnter: (event) => {}, // mouse enter row
                                                        onMouseLeave: (event) => {}, // mouse leave row
                                                        className: getClassName(record),
                                                    };
                                                }}
                                                onHeaderRow={(column) => {
                                                    return {
                                                        onClick: () => {}, // click header row
                                                    };
                                                }}
                                            />
                                        </div>
                                    </Card>
                                );
                            }}
                        </Query>
                    </Col>
                    <Col xs={24}>
                        <Query
                            query={GET_LEAGUE_MATCHES_BY_STATUS}
                            fetchPolicy="cache-and-network"
                            notifyOnNetworkStatusChange={true}
                            variables={{ league_id: league.id, status: 'COMPLETED' }}
                            onCompleted={(data) => {
                                // console.log(data);
                            }}
                        >
                            {({ loading, error, data }) => {
                                if (loading) return <CircularLoader />;
                                if (error) return <div style={styles.container}>Error: {error.message}</div>;

                                const matches =
                                    data.matches &&
                                    data.matches.map((item, index) => {
                                        return {
                                            ...item,
                                            key: index,
                                            player1: item.match_players && item.match_players[0],
                                            player2: item._match_players && item.match_players[1],
                                            venue: item.venue && item.venue.name,
                                        };
                                    });
                                return (
                                    <Card title={`Completed (${matches.length})`} bodyStyle={{ padding: 0 }}>
                                        <div className="table_nowrap">
                                            <Table
                                                dataSource={matches}
                                                columns={columns}
                                                className="match-table table-striped-rows"
                                                tableLayout="auto"
                                                scroll={{ x: 400 }}
                                                size={defaultSize}
                                                pagination={false}
                                                onRow={(record, rowIndex) => {
                                                    return {
                                                        onClick: (event) => {}, // click row
                                                        onDoubleClick: (event) => {}, // double click row
                                                        onContextMenu: (event) => {}, // right button click row
                                                        onMouseEnter: (event) => {}, // mouse enter row
                                                        onMouseLeave: (event) => {}, // mouse leave row
                                                        className: getClassName(record),
                                                    };
                                                }}
                                                onHeaderRow={(column) => {
                                                    return {
                                                        onClick: () => {}, // click header row
                                                    };
                                                }}
                                            />
                                        </div>
                                    </Card>
                                );
                            }}
                        </Query>
                    </Col>
                </Row>
            </SectionContent>
        </SectionWrapper>
    );
}

export default LMSchedule;
