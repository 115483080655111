import { message } from 'antd';
import { GET_TOURNAMENT_STATUS, GET_TOURNAMENT_PROGRESS, GET_TOURNAMENT_QUERY } from '../screens/digitalpool/tournaments/data/queries';
import { UPDATE_TOURNAMENT_STATUS, UPDATE_TOURNAMENT } from '../screens/digitalpool/tournaments/data/mutations';

export const DigitalPoolAPI = (props) => {
    const { tournament } = props;
    const key = 'updatable';
    return {
        updateTournamentStatus: (status) => {
            return props.client
                .mutate({
                    mutation: UPDATE_TOURNAMENT_STATUS,
                    variables: {
                        id: tournament.id,
                        changes: {
                            status: status,
                            progress: '0',
                        },
                    },
                    notifyOnNetworkStatusChange: true,
                    awaitRefetchQueries: true,
                    refetchQueries: [
                        {
                            query: GET_TOURNAMENT_STATUS,
                            variables: { id: tournament.id },
                        },
                    ],
                })
                .then((res) => {
                    return res && res.data;
                })
                .catch((e) => {
                    console.error(e);
                    message.error({ content: JSON.stringify(e), key, duration: 2 });
                    return e;
                });
        },

        updateTournamentProgress: (progress) => {
            return props.client
                .mutate({
                    mutation: UPDATE_TOURNAMENT,
                    variables: {
                        id: tournament.id,
                        changes: {
                            progress: progress.toString(),
                        },
                    },
                    notifyOnNetworkStatusChange: true,
                    awaitRefetchQueries: true,
                    refetchQueries: [
                        {
                            query: GET_TOURNAMENT_PROGRESS,
                            variables: { id: tournament.id },
                        },
                    ],
                })
                .then((res) => {
                    console.log(res);
                    return res && res.data;
                })
                .catch((e) => {
                    console.error(e);
                    message.error({ content: JSON.stringify(e), key, duration: 2 });
                    return e;
                });
        },

        updateTournament: (changes) => {
            return props.client
                .mutate({
                    mutation: UPDATE_TOURNAMENT,
                    variables: {
                        id: tournament.id,
                        changes: changes,
                    },
                    notifyOnNetworkStatusChange: true,
                    awaitRefetchQueries: true,
                    refetchQueries: [
                        {
                            query: GET_TOURNAMENT_QUERY,
                            variables: { id: tournament.id },
                        },
                    ],
                })
                .then((res) => {
                    console.log(res);
                    return res && res.data;
                })
                .catch((e) => {
                    console.error(e);
                    message.error({ content: JSON.stringify(e), key, duration: 2 });
                    return e;
                });
        },
    };
};
