import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { message, Button, Modal, Typography, Tabs, Upload, Icon } from 'antd';
import { Formik, ErrorMessage } from 'formik';
import crypto from 'crypto';
import { Form, FormItem, Input, Radio, InputNumber, Select, Checkbox, DatePicker, SubmitButton, FormikDebug } from 'formik-antd';
import { functions } from '../../firebase';
import { connectFunctionsEmulator, httpsCallable } from 'firebase/functions';
import { CHECK_USER_LEAGUE_INVITE, GET_USER, GET_NOTIFICATIONS } from './data/queries';
import { CREATE_LEAGUE_INVITE_MUTATION, CREATE_LEAGUE_INVITE_NOTIFICATION_MUTATION } from './data/mutations';

const { Title, Text } = Typography;
const { TabPane } = Tabs;
const { TextArea } = Input;

function InvitePlayerModal(props) {
    const { modalVisible, onModalOk, onModalCancel, client } = props;
    const [loading, setLoading] = useState(false);
    const [fileList, setFileList] = useState();
    const [file, setFile] = useState();

    const formRef = useRef();

    const styles = {
        tab: {
            padding: 20,
        },
    };

    const uploadProps = {
        onChange: handleFile,
        beforeUpload: (file) => {
            return false;
        },
    };

    function handleFile(info) {
        let localFileList = info.fileList;
        localFileList = localFileList.slice(-1);
        setFileList(localFileList);
        setFile(info.file);
    }

    function generateInviteKey() {
        var key = [
            1,
            2,
            3,
            4,
            5,
            6,
            7,
            8,
            9,
            0,
            'a',
            'b',
            'c',
            'd',
            'e',
            'f',
            'g',
            'h',
            'i',
            'j',
            'k',
            'l',
            'm',
            'n',
            'o',
            'p',
            'q',
            'r',
            's',
            't',
            'u',
            'v',
            'w',
            'x',
            'y',
            'z',
        ];
        var length = key.length;

        var appendStr = '';
        for (let i = 0; i < 99; i++) {
            let rand = Math.floor(Math.random() * length);
            appendStr += key[rand];
        }

        return appendStr;
    }

    async function handleSinglePlayerSubmit(values) {
        const key = 'updatable';
        try {
            if (window.location.hostname.indexOf('localhost') > -1) {
                connectFunctionsEmulator(functions, 'localhost', 5001);
            }
            const sendInvite = httpsCallable(functions, 'tournamentPlayerInvite');
            message.loading({ content: `Sending invite...`, key, duration: 0 });

            const queryCheckUser = await client.query({
                query: CHECK_USER_LEAGUE_INVITE,
                variables: {
                    email: values.email,
                },
            });

            const checkUser = queryCheckUser.data && queryCheckUser.data.league_invite_keys.length && queryCheckUser.data.league_invite_keys[0];

            if (checkUser.email === values.email && checkUser.slug === props.league.slug) {
                message.error(`Error: User already invited to league ${props.league.slug} with email ${values.email}`, 10);
                return;
            }

            var inviteKey = checkUser.key ? checkUser.key : generateInviteKey();

            const user = await client.query({
                query: GET_USER,
                variables: {
                    email: values.email,
                },
            });

            let isUser = user.data.users.length ? true : false;

            const result = await sendInvite({
                to_email: values.email,
                to_name: values.name,
                from_name: props.authState.user.displayName,
                from_message: values.message,
                from_league_name: props.league.name,
                from_league_slug: props.league.slug,
                redirect_url: `/leagues/${props.league.slug}`,
                invite_key: inviteKey,
                is_user: isUser,
            });

            const insert = await client.mutate({
                mutation: CREATE_LEAGUE_INVITE_MUTATION,
                variables: {
                    objects: {
                        email: values.email,
                        key: inviteKey,
                        slug: props.league.slug,
                        league_id: props.league.id,
                    },
                },
            });

            if (isUser) {
                const notify = await client.mutate({
                    mutation: CREATE_LEAGUE_INVITE_NOTIFICATION_MUTATION,
                    variables: {
                        objects: {
                            name: 'League Invite',
                            action: 'JOIN_LEAGUE',
                            type: 'REDIRECT_LINK',
                            title: 'League Invite',
                            description: `Click to join the ${props.league.name}`,
                            user_id: user.data.users[0].id,
                            data: {
                                redirect_url: `/leagues/${props.league.slug}`,
                                league_id: props.league.id,
                                key: inviteKey,
                                slug: props.league.slug,
                            },
                        },
                    },
                });
            }

            setLoading(false);
            message.success({
                content: `Invitation sent to ${values.email}`,
                key,
                duration: 2,
            });
            onModalOk();
        } catch (err) {
            setLoading(false);
            console.log(err);
            message.error({ content: `There was an error ${JSON.stringify(err)}`, key, duration: 2 });
        }
    }
    // Error: {"graphQLErrors":[{"extensions":{"path":"$.selectionSet.insert_notifications.args.objects","code":"constraint-violation"},"message":"Not-NULL violation. null value in column \"name\" violates not-null constraint"}],"networkError":null,"message":"GraphQL error: Not-NULL violation. null value in column \"name\" violates not-null constraint"}

    return (
        <Modal
            title={<Text style={{ fontWeight: 700 }}>Invite Player to join {props.league.name}</Text>}
            // getContainer={() => document.querySelector('.tables')}
            visible={modalVisible}
            onOk={onModalOk}
            onCancel={onModalCancel}
            // enableReinitialize
            centered
            transitionName="fade"
            bodyStyle={{ maxHeight: 500, overflowY: 'auto', padding: '10px 20px' }}
            // transitionName="none"
            maskTransitionName="none"
            destroyOnClose={true}
            footer={[
                <Button
                    key="cancel"
                    onClick={() => {
                        onModalCancel();
                    }}
                >
                    Cancel
                </Button>,
                <Button
                    key="submit"
                    type="primary"
                    loading={loading}
                    onClick={() => {
                        formRef.current.handleSubmit();
                        onModalOk();
                    }}
                >
                    Invite
                </Button>,
            ]}
        >
            <React.Fragment>
                <Formik
                    ref={formRef}
                    enableReinitialize
                    // initialValues={initialValues}
                    // validationSchema={CreateTableSchema}
                    onSubmit={(values, actions) => {
                        // actions.setSubmitting(false);
                        // actions.resetForm();
                        // handleSubmit(values, createTable, updateTable);
                        handleSinglePlayerSubmit(values);
                    }}
                    render={({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                        <Form onSubmit={handleSubmit}>
                            <FormItem name="name">
                                <Input name="name" placeholder="Name" size="large" autoFocus />
                            </FormItem>
                            <FormItem name="email">
                                <Input name="email" placeholder="Email" size="large" />
                            </FormItem>
                            <FormItem name="message">
                                <TextArea name="message" placeholder="Message" rows="5" size="large" />
                            </FormItem>
                        </Form>
                    )}
                />
            </React.Fragment>
        </Modal>
    );
}

export default withRouter(InvitePlayerModal);
