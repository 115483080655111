import React from 'react';
import { Layout, Icon } from 'antd';
import facebookIconColor from '../assets/img/karl-boyes/facebook-icon-color.png';
import instagramIconColor from '../assets/img/karl-boyes/instagram-icon-color.png';

const { Header, Content, Footer } = Layout;

export default function SectionFooter(props) {
    const { theme } = props;

    return (
        <Footer className={`footer-${(theme && theme.name) || 'light'}`}>
            <span>
                ©{new Date().getFullYear()} DigitalPool, Inc . All Rights Reserved. -{' '}
                <a href="mailto:contact@digitalpool.com" target="_blank" rel="noopener noreferrer">
                    contact@digitalpool.com
                </a>
            </span>
            <span
                style={{
                    marginLeft: 5,
                    fontSize: 12,
                    textAlign: 'center',
                    color: theme && theme.name === 'dark' ? 'rgba(255,255,255,0.5)' : 'rgba(0,0,0,0.5)',
                }}
            >
                - v{process.env.REACT_APP_VERSION}
            </span>
            <span>
                <a href="https://www.facebook.com/digitalpoolcom" target="_blank" rel="noopener noreferrer" style={{ marginLeft: 10, marginRight: 5 }}>
                    <img src={facebookIconColor} alt="Facebook" height={18} />
                </a>
                <a href="https://www.instagram.com/digitalpoolcom" target="_blank" rel="noopener noreferrer">
                    <img src={instagramIconColor} alt="Instagram" height={20} />
                </a>
            </span>
        </Footer>
    );
}
