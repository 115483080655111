import React, { useState, useEffect, useRef } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { message, Comment, Avatar, Button, List } from 'antd';
import { Formik, insert, setIn } from 'formik';
import { Form, FormItem, Input, SubmitButton, Radio, Checkbox } from 'formik-antd';
import moment from 'moment';
import * as Yup from 'yup';
import { UserOutlined } from '@ant-design/icons';
import { GET_POST_COMMENTS } from '../data/queries';
import { CREATE_POST_COMMENT_MUTATION, UPDATE_POST_COMMENT_MUTATION } from '../data/mutations';
import { useMutation, useQuery } from '@apollo/react-hooks';
import EmojiPicker from './EmojiPicker';

const { TextArea } = Input;

function FeedCommentForm(props) {
    const { authState, user, post, comment, parentId } = props;
    const [comments, setComments] = useState([]);
    const [submitting, setSubmitting] = useState(false);
    const [loading, setLoading] = useState(false);
    const [text, setText] = useState('');
    const [createPostComment] = useMutation(CREATE_POST_COMMENT_MUTATION);
    const formRef = useRef();

    function handleSubmit(values, actions) {
        console.log({ values, actions });

        if (values.message) {
            setSubmitting(true);
            message.info('Adding comment...');

            createPostComment({
                variables: {
                    objects: [
                        {
                            message: values.message,
                            // slug: slug,
                            user_id: authState && authState.user && authState.user.id,
                            post_id: post.id,
                            ...(parentId && { parent_id: parentId }),
                        },
                    ],
                },
                notifyOnNetworkStatusChange: true,
                awaitRefetchQueries: true,
                refetchQueries: [
                    {
                        query: GET_POST_COMMENTS,
                        variables: { post_id: post.id },
                    },
                ],
            })
                .then((res) => {
                    actions.setSubmitting(false);
                    actions.resetForm();
                    console.log(res);
                    setSubmitting(false);
                    // const result = res && res.data && res.data.insert_posts && res.data.insert_posts.returning && res.data.insert_posts.returning[0];
                    message.success('Comment added');
                })
                .catch((e) => {
                    setSubmitting(false);
                    actions.setSubmitting(false);
                    message.error((e && e.message) || JSON.stringify(e));
                    console.log(e);
                });
        } else {
            message.error('No message found.');
        }
    }

    // function handleChange(e) {
    //     setValue(e.target.value);
    // }

    function handleFormFieldUpdates(event) {
        const caret = event.target.selectionStart;
        const element = event.target;
        window.requestAnimationFrame(() => {
            element.selectionStart = caret;
            element.selectionEnd = caret;
        });

        setText(event.target.value);
    }

    const schema = Yup.object().shape({
        // message: Yup.string().required('Message is a required field'),
    });

    return (
        <div className="feed-comment">
            {/* {comments.length > 0 && <CommentList comments={comments} />} */}
            <Formik
                ref={formRef}
                onSubmit={(data, actions) => {
                    handleSubmit(data, actions);
                }}
                validationSchema={schema}
                enableReinitialize
                initialValues={{
                    message: '',
                }}
                render={({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                    <Form onSubmit={handleSubmit} layout="vertical">
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            {(props.authState.user.avatar || props.authState.user.photoURL) && (
                                <div style={{ marginRight: 15 }}>
                                    <Avatar
                                        size={30}
                                        src={props.authState && props.authState.user && (props.authState.user.avatar || props.authState.user.photoURL)}
                                    >
                                        <UserOutlined
                                            style={{
                                                fontSize: 40,
                                                lineHeight: '60px',
                                                color: '#000',
                                            }}
                                        />
                                    </Avatar>
                                </div>
                            )}

                            <FormItem name="message" style={{ width: '100%' }}>
                                <Input
                                    name="message"
                                    size="large"
                                    placeholder="Add your comment here..."
                                    onChange={(event) => {
                                        handleFormFieldUpdates(event);
                                    }}
                                    addonAfter={
                                        <EmojiPicker
                                            text={text}
                                            buttonType="text"
                                            buttonSize="small"
                                            onSetText={(text) => {
                                                formRef.current.setValues({
                                                    message: text,
                                                });
                                                setText(text);
                                            }}
                                        />
                                    }
                                />
                            </FormItem>

                            <SubmitButton type="primary" ghost size="large" loading={submitting} style={{ marginLeft: 10 }}>
                                <i className="icon ion-md-send" style={{ fontSize: 20 }} />
                            </SubmitButton>
                        </div>
                    </Form>
                )}
            />
        </div>
    );
}

export default withRouter(FeedCommentForm);
