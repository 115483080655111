import React, { useState, useEffect, useRef } from 'react';
import { Table } from 'antd';
import Card from '../../../components/Card';
import ZeroState from '../../../components/ZeroState';

export default function ChallengesTable(props) {
    const dataSource = [
        // {
        // 	key: '1',
        // 	players: 'Sky Woodward vs. Dennis Orcollo',
        // 	race_to: '150',
        // 	score: '150-148',
        // 	match_type: 'action',
        // 	location: 'Snookered',
        // 	table_size: '9 Foot',
        // 	start_time: 'April 12, 2021'
        // }
    ];

    const columns = [
        {
            title: 'Players',
            dataIndex: 'players',
            key: 'players',
        },

        {
            title: 'Score',
            dataIndex: 'score',
            key: 'score',
        },
        {
            title: 'Race to',
            dataIndex: 'race_to',
            key: 'race_to',
        },
        {
            title: 'Match type',
            dataIndex: 'match_type',
            key: 'match_type',
        },
        {
            title: 'Table size',
            dataIndex: 'table_size',
            key: 'table_size',
        },
        {
            title: 'Location',
            dataIndex: 'location',
            key: 'location',
        },
        {
            title: 'Date',
            dataIndex: 'start_time',
            key: 'start_time',
        },
    ];

    return (
        <Card>
            <ZeroState message="Coming soon!" {...props} />
        </Card>
    );

    // return <Table dataSource={dataSource} columns={columns} pagination={false} />;
}
