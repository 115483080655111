import React, { useState, useEffect } from 'react';
import { withRouter, Route } from 'react-router-dom';
import {
    message,
    Descriptions,
    Tabs,
    Col,
    Layout,
    Row,
    Statistic,
    Progress,
    Skeleton,
    Tag,
    List,
    Avatar,
    Form,
    Icon,
    Input,
    Pagination,
    Button,
    Modal,
    Empty,
    Typography,
    Select,
} from 'antd';
import SectionHeader from '../../../components/SectionHeader';
import CircularLoader from '../../../components/CircularLoader';
import HeaderImage from '../../../components/HeaderImage';
import BreadcrumbContainer from '../../../components/BreadcrumbContainer';
import { GET_EVENT_QUERY, GET_USER_CREATED_EVENTS_QUERY, GET_EVENT_PLAYERS_QUERY } from '../data/queries';
import { DELETE_EVENT_MUTATION } from '../data/mutations';
import { Query } from '@apollo/client/react/components';
import useBreakpoint from 'use-breakpoint';
import { defaultBreakpoints } from '../../../utils/defaultBreakpoints';
import * as routes from '../../../config/routes';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import EventOverview from './EventOverview';
import EventScores from './EventScores';
import EventStandings from './EventStandings';
import EventTables from './EventTables';
import _ from 'lodash';
import ShareModal from './ShareModal';
import moment from 'moment-timezone';
import momentDurationFormatSetup from 'moment-duration-format';
momentDurationFormatSetup(moment);

const { TabPane } = Tabs;
const { Title, Text } = Typography;

function EventDetail(props) {
    const { authState, history, match, theme } = props;
    const [searchValue, setSearchValue] = useState();
    const [current, setCurrent] = useState('overview');
    const { breakpoint, maxWidth, minWidth } = useBreakpoint(defaultBreakpoints);
    const [shareModalVisible, setShareModalVisible] = useState(false);
    const urlPath = window.location.pathname;
    const params = props.match.params;
    const slug = match.params.slug;
    const splitPath = urlPath.split('/');

    const tabs = ['overview', 'scores', 'standings', 'tables'];

    useEffect(() => {
        let pathMatch = _.includes(tabs, splitPath[splitPath.length - 1])
            ? splitPath[splitPath.length - 1]
            : _.includes(tabs, splitPath[splitPath.length - 2])
            ? splitPath[splitPath.length - 2]
            : 'overview';

        setCurrent(pathMatch);
    }, [urlPath, tabs, splitPath, params]);

    const styles = {
        headerImg: {
            background: `linear-gradient(-90deg, #002eaf, #008dd0) 100% 100% no-repeat`,
            // background: `linear-gradient(0deg, rgba(0,0,0,0.2), rgba(0,0,0, 0.2)), url(${blueBg})`,
            // backgroundAttachment: 'fixed',
            backgroundRepeat: 'no-repeat',
            backgroundSize: '100%',
            // backgroundPosition: '0px -400px'

            // backgroundPosition: '0px -40px'
        },
        button: {
            marginTop: 10,
        },
        header: {
            paddingTop: breakpoint === 'mobile' ? 5 : 10,
            paddingLeft: breakpoint === 'mobile' ? 10 : 40,
            paddingRight: breakpoint === 'mobile' ? 0 : 0,
            paddingBottom: breakpoint === 'mobile' ? 0 : 10,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
    };

    function createMarkup(markup) {
        return { __html: markup };
    }

    const handleTabClick = (tab) => {
        setCurrent(tab);
        props.history.push(`/events/${slug}/${tab}`);
    };

    const showShareModal = () => {
        setShareModalVisible(true);
    };

    const handleShareModalOk = () => {
        setShareModalVisible(false);
    };

    const handleShareModalCancel = () => {
        setShareModalVisible(false);
    };

    return (
        <Query
            query={GET_EVENT_QUERY}
            fetchPolicy="cache-and-network"
            notifyOnNetworkStatusChange={true}
            variables={{ eventSlug: slug }}
            onCompleted={async (data) => {
                // console.log(data);
            }}
        >
            {({ loading, error, data }) => {
                if (loading) return <CircularLoader />;
                if (error) return <div style={styles.container}>Error: {error.message}</div>;
                if (data && !data.events) {
                    return <div>Event not found.</div>;
                }
                if (data && data.events.length === 0) {
                    return <div>Event not found.</div>;
                }
                const event = data.events[0];
                const tournaments = event.tournaments;
                const matches = event.matches;

                console.log(event);

                let duration;
                if (event) {
                    if (event.start_date_time && event.end_date_time) {
                        var start = moment(event.start_date_time);
                        var end = moment(event.end_date_time);
                        duration = moment.duration(end.diff(start, 'milliseconds')).format('h [hrs] m [min]');
                    } else {
                        duration = moment.duration(moment.utc().diff(event.start_date_time, 'milliseconds')).format('h [hours] m [minutes]');
                    }
                }

                return (
                    <React.Fragment>
                        <Helmet>
                            <title>{event.name}</title>
                            <meta name="description" content={event.description} />
                            <meta name="twitter:card" content="summary_large_image" />
                            <meta name="twitter:title" content={event.name || 'DigitalPool - Bridging the gap between player and technology'} />
                            <meta name="twitter:description" content={event.description} />
                            <meta name="twitter:image" content={event.flyer || 'https://digitalpool.com/images/fb-image.png'} />
                            <meta property="og:title" content={event.name || 'DigitalPool - Bridging the gap between player and technology'} />
                            <meta property="og:description" content={event.description} />
                            <meta property="og:image" content={event.flyer || 'https://digitalpool.com/images/fb-image.png'} />
                            <meta property="og:url" content={`https://www.digitalpool.com/events/${event.slug}`} />
                            <meta property="og:site_name" content="digitalpool.com" />
                            <meta property="og:locale" content="en_US" />
                            <meta property="og:type" content="website" />
                            <meta property="fb:app_id" content="1697957547011275" />
                        </Helmet>

                        <div style={styles.headerImg}>
                            {/* <img src={poolHall} alt="Pool hall" /> */}
                            {/* <HeaderImage /> */}
                            <SectionHeader
                                title={
                                    <Text style={breakpoint === 'mobile' ? { fontSize: 16, color: '#fff', maxWidth: 300 } : { fontSize: 20, color: '#fff' }}>
                                        {event && event.name.toUpperCase()}
                                    </Text>
                                }
                                titleStyle={{
                                    color: '#fff',
                                    textTransform: 'uppercase',
                                    margin: 0,
                                    display: 'flex',
                                    letterSpacing: breakpoint === 'mobile' ? 0 : 6,
                                    paddingRight: 10,
                                }}
                                backButton={
                                    <div
                                        style={{
                                            paddingLeft: 10,
                                            paddingBottom: 10,
                                            paddingTop: 10,
                                            paddingRight: 0,
                                        }}
                                    >
                                        <Icon type="arrow-left" onClick={() => history.push(`/events`)} style={{ color: '#fff' }} />
                                    </div>
                                }
                                style={styles.header}
                                // theme="tournamentBuilder"
                            >
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                    {((authState && authState.user && authState.user.role === 'admin') ||
                                        (authState && authState.user && authState.user.id === event.owner_id)) && (
                                        <Button ghost onClick={() => history.push(`/events/${event.slug}/edit`)}>
                                            <Icon type="edit" /> Edit Event
                                        </Button>
                                    )}
                                    <Button
                                        ghost
                                        onClick={() => {
                                            showShareModal();
                                        }}
                                    >
                                        <Icon type="share-alt" />
                                    </Button>
                                </div>
                            </SectionHeader>

                            <Tabs
                                defaultActiveKey={current}
                                activeKey={current}
                                animated={{ inkBar: true, tabPane: false }}
                                // renderTabBar={() => <ScrollableInkTabBar />}
                                // renderTabContent={() => <TabContent />}
                                onTabClick={handleTabClick}
                                className="tabs-bg"
                                tabPosition="top"
                                tabBarStyle={{
                                    textTransform: 'uppercase',
                                    paddingLeft: 24,
                                    paddingRight: 24,
                                    paddingTop: 0,
                                    // backgroundColor: '#132339',
                                    boxShadow: 'rgba(0, 0, 0, 0.2) 0px 1px 15px 0px',
                                    color: '#fff',
                                    zIndex: 1,
                                    position: 'relative',
                                    borderBottom: 'none',
                                    borderTop: '1px solid rgba(255,255,255,0.1)',
                                }}
                            >
                                <TabPane tab="Overview" key="overview">
                                    <Route
                                        path={routes.DIGITALPOOL.EVENT_DETAIL}
                                        render={(matchProps) => (
                                            <EventOverview {...matchProps} {...props} event={event} tournaments={tournaments} matches={matches} />
                                        )}
                                    />
                                </TabPane>
                                <TabPane tab="Live scores" key="scores">
                                    <Route
                                        path={routes.DIGITALPOOL.EVENT_DETAIL_SCORES}
                                        render={(matchProps) => (
                                            <EventScores {...matchProps} {...props} event={event} tournaments={tournaments} matches={matches} />
                                        )}
                                    />
                                </TabPane>
                                <TabPane tab="Standings" key="standings">
                                    <Route
                                        path={routes.DIGITALPOOL.EVENT_DETAIL_STANDINGS}
                                        render={(matchProps) => (
                                            <EventStandings {...matchProps} {...props} event={event} tournaments={tournaments} matches={matches} />
                                        )}
                                    />
                                </TabPane>
                                <TabPane tab="Tables" key="tables">
                                    <Route
                                        path={routes.DIGITALPOOL.EVENT_DETAIL_TABLES}
                                        render={(matchProps) => (
                                            <EventTables {...matchProps} {...props} event={event} tournaments={tournaments} matches={matches} />
                                        )}
                                    />
                                </TabPane>
                            </Tabs>

                            <ShareModal event={event} modalVisible={shareModalVisible} onOk={handleShareModalOk} onCancel={handleShareModalCancel} />
                        </div>
                    </React.Fragment>
                );
            }}
        </Query>
    );
}

export default withRouter(EventDetail);
