import React, { useState } from 'react';
import { Row, Col, Icon, Typography, Button, Tabs } from 'antd';
import { Link } from 'react-router-dom';
import { Query } from '@apollo/client/react/components';
import { GET_USER_GHOST_EVENTS_QUERY } from './data/queries';
import Card from '../../components/Card';
import SectionHeader from '../../components/SectionHeader';
import SectionContent from '../../components/SectionContent';
import SectionWrapper from '../../components/SectionWrapper';
import MainPlayerTable from './MainPlayerTable';
import CreatedGhostEventsTable from './components/GEMain/CreatedGhostEventsTable';

const { Text } = Typography;
const { TabPane } = Tabs;

const styles = {
    row: {
        display: 'flex',
        flexGrow: 1,
    },
};

export default function GGMain(props) {
    const { authState, history } = props;

    return (
        <SectionWrapper>
            <SectionHeader
                title={props.title}
                titleStyle={{
                    color: '#fff',
                    textTransform: 'uppercase',
                    margin: 0,
                    display: 'flex',
                }}
                height={116}
                subtitle="Ghost Games Here"
                subtitleSuffix={true}
                subtitleStyle={{ color: '#fff' }}
                theme="leagueManager"
            >
                <Link to="/ghost-games-manager/new">
                    <Button size="large" type="ghost" ghost>
                        Create Ghost Game
                        <Icon type="arrow-right" />
                    </Button>
                </Link>
            </SectionHeader>
            <SectionContent padding="20px 40px">
                <Tabs defaultActiveKey="your_created_ghost_games">
                    <TabPane tab="Your Created Games" key="your_created_ghost_games">
                        <Row gutter={16} style={styles.row}>
                            <Col xs={24} sm={24} md={24} lg={12}>
                                <Card title="Start a ghost game">
                                    <div style={{ marginBottom: 16 }}>
                                        <Text>Create Ghost Game</Text>
                                    </div>
                                    <Link to="/ghost-games-manager/new">
                                        <Button type="primary" size="large">
                                            Create Ghost Game
                                        </Button>
                                    </Link>
                                </Card>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={12}>
                                <Card title="Paid">
                                    <Link to="/ghost-games-manager/new">
                                        <Button type="primary" size="large">
                                            Create Ghost Game
                                        </Button>
                                    </Link>
                                </Card>
                            </Col>
                        </Row>
                        <Row gutter={16} style={styles.row}>
                            <Col xs={24} sm={24} md={24} lg={12}>
                                <Card title="Created Ghost Games">
                                    <CreatedGhostEventsTable history={history} userId={authState.user.id} />
                                </Card>
                            </Col>
                        </Row>
                    </TabPane>
                    <TabPane tab="Your Games" key="your_ghost_games">
                        <Row gutter={16} style={styles.row}>
                            <Col xs={24} sm={24} md={24} lg={12}>
                                <Card title="Your Ghost Games">Test</Card>
                            </Col>
                        </Row>
                    </TabPane>
                </Tabs>
            </SectionContent>
        </SectionWrapper>
    );
}
