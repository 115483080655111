import React, { useRef } from 'react';
import { message, Typography, Button, Icon, List } from 'antd';
import {
    INCREMENT_PLAYER_BALLS_POCKETED_MUTATION,
    INCREMENT_OPPONENT_BALLS_POCKETED_MUTATION,
    DECREMENT_PLAYER_BALLS_POCKETED_MUTATION,
    DECREMENT_OPPONENT_BALLS_POCKETED_MUTATION,
    SET_CHALLENGER_BALLS_POCKETED_MUTATION,
    INCREMENT_PLAYER_GAME_BALLS_POCKETED_MUTATION,
    INCREMENT_OPPONENT_GAME_BALLS_POCKETED_MUTATION,
    DECREMENT_PLAYER_GAME_BALLS_POCKETED_MUTATION,
    DECREMENT_OPPONENT_GAME_BALLS_POCKETED_MUTATION,
    SET_CHALLENGER_GAME_BALLS_POCKETED_MUTATION,
    INCREMENT_PLAYER_ERRORS_MUTATION,
    INCREMENT_OPPONENT_ERRORS_MUTATION,
    DECREMENT_PLAYER_ERRORS_MUTATION,
    DECREMENT_OPPONENT_ERRORS_MUTATION,
    SET_CHALLENGER_ERRORS_MUTATION,
    INCREMENT_PLAYER_GAME_ERRORS_MUTATION,
    INCREMENT_OPPONENT_GAME_ERRORS_MUTATION,
    DECREMENT_PLAYER_GAME_ERRORS_MUTATION,
    DECREMENT_OPPONENT_GAME_ERRORS_MUTATION,
    SET_CHALLENGER_GAME_ERRORS_MUTATION,
    INCREMENT_PLAYER_BREAK_RUNS_MUTATION,
    INCREMENT_OPPONENT_BREAK_RUNS_MUTATION,
    DECREMENT_PLAYER_BREAK_RUNS_MUTATION,
    DECREMENT_OPPONENT_BREAK_RUNS_MUTATION,
    SET_CHALLENGER_BREAK_RUNS_MUTATION,
    INCREMENT_PLAYER_GAME_BREAK_RUNS_MUTATION,
    INCREMENT_OPPONENT_GAME_BREAK_RUNS_MUTATION,
    DECREMENT_PLAYER_GAME_BREAK_RUNS_MUTATION,
    DECREMENT_OPPONENT_GAME_BREAK_RUNS_MUTATION,
    SET_CHALLENGER_GAME_BREAK_RUNS_MUTATION,
    INCREMENT_PLAYER_TABLE_RUNS_MUTATION,
    INCREMENT_OPPONENT_TABLE_RUNS_MUTATION,
    DECREMENT_PLAYER_TABLE_RUNS_MUTATION,
    DECREMENT_OPPONENT_TABLE_RUNS_MUTATION,
    SET_CHALLENGER_TABLE_RUNS_MUTATION,
    INCREMENT_PLAYER_GAME_TABLE_RUNS_MUTATION,
    INCREMENT_OPPONENT_GAME_TABLE_RUNS_MUTATION,
    DECREMENT_PLAYER_GAME_TABLE_RUNS_MUTATION,
    DECREMENT_OPPONENT_GAME_TABLE_RUNS_MUTATION,
    SET_CHALLENGER_GAME_TABLE_RUNS_MUTATION,
    INCREMENT_PLAYER_HIGH_RUN_MUTATION,
    INCREMENT_OPPONENT_HIGH_RUN_MUTATION,
    DECREMENT_PLAYER_HIGH_RUN_MUTATION,
    DECREMENT_OPPONENT_HIGH_RUN_MUTATION,
    SET_CHALLENGER_HIGH_RUN_MUTATION,
    INCREMENT_PLAYER_GAME_HIGH_RUN_MUTATION,
    INCREMENT_OPPONENT_GAME_HIGH_RUN_MUTATION,
    DECREMENT_PLAYER_GAME_HIGH_RUN_MUTATION,
    DECREMENT_OPPONENT_GAME_HIGH_RUN_MUTATION,
    SET_CHALLENGER_GAME_HIGH_RUN_MUTATION,
    INCREMENT_PLAYER_HIGH_BREAK_MUTATION,
    INCREMENT_OPPONENT_HIGH_BREAK_MUTATION,
    DECREMENT_PLAYER_HIGH_BREAK_MUTATION,
    DECREMENT_OPPONENT_HIGH_BREAK_MUTATION,
    SET_CHALLENGER_HIGH_BREAK_MUTATION,
    INCREMENT_PLAYER_GAME_HIGH_BREAK_MUTATION,
    INCREMENT_OPPONENT_GAME_HIGH_BREAK_MUTATION,
    DECREMENT_PLAYER_GAME_HIGH_BREAK_MUTATION,
    DECREMENT_OPPONENT_GAME_HIGH_BREAK_MUTATION,
    SET_CHALLENGER_GAME_HIGH_BREAK_MUTATION,
    UPDATE_LEAGUE_MATCH_GAME,
    UPDATE_LEAGUE_MATCH_STATS,
} from './data/mutations';
import { Form, FormItem, Input } from 'formik-antd';
import { Formik, ErrorMessage } from 'formik';
import { useMutation, useQuery } from '@apollo/react-hooks';
import _ from 'lodash';

const { Text } = Typography;

export default function TPAControls(props) {
    const { layout, currentGame, matchData, matchID } = props;
    const [updateMatchGame] = useMutation(UPDATE_LEAGUE_MATCH_GAME);
    const [updateMatchStats] = useMutation(UPDATE_LEAGUE_MATCH_STATS);

    const [incrementPlayerBallsPocketed] = useMutation(INCREMENT_PLAYER_BALLS_POCKETED_MUTATION);
    const [incrementOpponentBallsPocketed] = useMutation(INCREMENT_OPPONENT_BALLS_POCKETED_MUTATION);
    const [decrementPlayerBallsPocketed] = useMutation(DECREMENT_PLAYER_BALLS_POCKETED_MUTATION);
    const [decrementOpponentBallsPocketed] = useMutation(DECREMENT_OPPONENT_BALLS_POCKETED_MUTATION);
    const [setChallengerBallsPocketed] = useMutation(SET_CHALLENGER_BALLS_POCKETED_MUTATION);

    const [incrementPlayerGameBallsPocketed] = useMutation(INCREMENT_PLAYER_GAME_BALLS_POCKETED_MUTATION);
    const [incrementOpponentGameBallsPocketed] = useMutation(INCREMENT_OPPONENT_GAME_BALLS_POCKETED_MUTATION);
    const [decrementPlayerGameBallsPocketed] = useMutation(DECREMENT_PLAYER_GAME_BALLS_POCKETED_MUTATION);
    const [decrementOpponentGameBallsPocketed] = useMutation(DECREMENT_OPPONENT_GAME_BALLS_POCKETED_MUTATION);
    const [setChallengerGameBallsPocketed] = useMutation(SET_CHALLENGER_GAME_BALLS_POCKETED_MUTATION);

    const [incrementPlayerErrors] = useMutation(INCREMENT_PLAYER_ERRORS_MUTATION);
    const [incrementOpponentErrors] = useMutation(INCREMENT_OPPONENT_ERRORS_MUTATION);
    const [decrementPlayerErrors] = useMutation(DECREMENT_PLAYER_ERRORS_MUTATION);
    const [decrementOpponentErrors] = useMutation(DECREMENT_OPPONENT_ERRORS_MUTATION);
    const [setChallengerErrors] = useMutation(SET_CHALLENGER_ERRORS_MUTATION);

    const [incrementPlayerGameErrors] = useMutation(INCREMENT_PLAYER_GAME_ERRORS_MUTATION);
    const [incrementOpponentGameErrors] = useMutation(INCREMENT_OPPONENT_GAME_ERRORS_MUTATION);
    const [decrementPlayerGameErrors] = useMutation(DECREMENT_PLAYER_GAME_ERRORS_MUTATION);
    const [decrementOpponentGameErrors] = useMutation(DECREMENT_OPPONENT_GAME_ERRORS_MUTATION);
    const [setChallengerGameErrors] = useMutation(SET_CHALLENGER_GAME_ERRORS_MUTATION);

    const [incrementPlayerBreakRuns] = useMutation(INCREMENT_PLAYER_BREAK_RUNS_MUTATION);
    const [incrementOpponentBreakRuns] = useMutation(INCREMENT_OPPONENT_BREAK_RUNS_MUTATION);
    const [decrementPlayerBreakRuns] = useMutation(DECREMENT_PLAYER_BREAK_RUNS_MUTATION);
    const [decrementOpponentBreakRuns] = useMutation(DECREMENT_OPPONENT_BREAK_RUNS_MUTATION);
    const [setChallengerBreakRuns] = useMutation(SET_CHALLENGER_BREAK_RUNS_MUTATION);

    const [incrementPlayerGameBreakRuns] = useMutation(INCREMENT_PLAYER_GAME_BREAK_RUNS_MUTATION);
    const [incrementOpponentGameBreakRuns] = useMutation(INCREMENT_OPPONENT_GAME_BREAK_RUNS_MUTATION);
    const [decrementPlayerGameBreakRuns] = useMutation(DECREMENT_PLAYER_GAME_BREAK_RUNS_MUTATION);
    const [decrementOpponentGameBreakRuns] = useMutation(DECREMENT_OPPONENT_GAME_BREAK_RUNS_MUTATION);
    const [setChallengerGameBreakRuns] = useMutation(SET_CHALLENGER_GAME_BREAK_RUNS_MUTATION);

    const [incrementPlayerTableRuns] = useMutation(INCREMENT_PLAYER_TABLE_RUNS_MUTATION);
    const [incrementOpponentTableRuns] = useMutation(INCREMENT_OPPONENT_TABLE_RUNS_MUTATION);
    const [decrementPlayerTableRuns] = useMutation(DECREMENT_PLAYER_TABLE_RUNS_MUTATION);
    const [decrementOpponentTableRuns] = useMutation(DECREMENT_OPPONENT_TABLE_RUNS_MUTATION);
    const [setChallengerTableRuns] = useMutation(SET_CHALLENGER_TABLE_RUNS_MUTATION);

    const [incrementPlayerGameTableRuns] = useMutation(INCREMENT_PLAYER_GAME_TABLE_RUNS_MUTATION);
    const [incrementOpponentGameTableRuns] = useMutation(INCREMENT_OPPONENT_GAME_TABLE_RUNS_MUTATION);
    const [decrementPlayerGameTableRuns] = useMutation(DECREMENT_PLAYER_GAME_TABLE_RUNS_MUTATION);
    const [decrementOpponentGameTableRuns] = useMutation(DECREMENT_OPPONENT_GAME_TABLE_RUNS_MUTATION);
    const [setChallengerGameTableRuns] = useMutation(SET_CHALLENGER_GAME_TABLE_RUNS_MUTATION);

    const [incrementPlayerHighRun] = useMutation(INCREMENT_PLAYER_HIGH_RUN_MUTATION);
    const [incrementOpponentHighRun] = useMutation(INCREMENT_OPPONENT_HIGH_RUN_MUTATION);
    const [decrementPlayerHighRun] = useMutation(DECREMENT_PLAYER_HIGH_RUN_MUTATION);
    const [decrementOpponentHighRun] = useMutation(DECREMENT_OPPONENT_HIGH_RUN_MUTATION);
    const [setChallengerHighRun] = useMutation(SET_CHALLENGER_HIGH_RUN_MUTATION);

    const [incrementPlayerGameHighRun] = useMutation(INCREMENT_PLAYER_GAME_HIGH_RUN_MUTATION);
    const [incrementOpponentGameHighRun] = useMutation(INCREMENT_OPPONENT_GAME_HIGH_RUN_MUTATION);
    const [decrementPlayerGameHighRun] = useMutation(DECREMENT_PLAYER_GAME_HIGH_RUN_MUTATION);
    const [decrementOpponentGameHighRun] = useMutation(DECREMENT_OPPONENT_GAME_HIGH_RUN_MUTATION);
    const [setChallengerGameHighRun] = useMutation(SET_CHALLENGER_GAME_HIGH_RUN_MUTATION);

    const [incrementPlayerHighBreak] = useMutation(INCREMENT_PLAYER_HIGH_BREAK_MUTATION);
    const [incrementOpponentHighBreak] = useMutation(INCREMENT_OPPONENT_HIGH_BREAK_MUTATION);
    const [decrementPlayerHighBreak] = useMutation(DECREMENT_PLAYER_HIGH_BREAK_MUTATION);
    const [decrementOpponentHighBreak] = useMutation(DECREMENT_OPPONENT_HIGH_BREAK_MUTATION);
    const [setChallengerHighBreak] = useMutation(SET_CHALLENGER_HIGH_BREAK_MUTATION);

    const [incrementPlayerGameHighBreak] = useMutation(INCREMENT_PLAYER_GAME_HIGH_BREAK_MUTATION);
    const [incrementOpponentGameHighBreak] = useMutation(INCREMENT_OPPONENT_GAME_HIGH_BREAK_MUTATION);
    const [decrementPlayerGameHighBreak] = useMutation(DECREMENT_PLAYER_GAME_HIGH_BREAK_MUTATION);
    const [decrementOpponentGameHighBreak] = useMutation(DECREMENT_OPPONENT_GAME_HIGH_BREAK_MUTATION);
    const [setChallengerGameHighBreak] = useMutation(SET_CHALLENGER_GAME_HIGH_BREAK_MUTATION);
    const formRef = useRef();

    let ballsPocketed;
    let unforcedErrors;
    let breakRuns;
    let tableRuns;
    let highRun;
    let highBreak;

    if (layout === 'left') {
        ballsPocketed = (currentGame && currentGame.player_balls_pocketed) || 0;
        unforcedErrors = (currentGame && currentGame.player_errors) || 0;
        breakRuns = (currentGame && currentGame.player_break_runs) || 0;
        tableRuns = (currentGame && currentGame.player_table_runs) || 0;
        highRun = (currentGame && currentGame.player_high_run) || 0;
        highBreak = (currentGame && currentGame.player_high_break) || 0;
    } else {
        ballsPocketed = (currentGame && currentGame.opponent_balls_pocketed) || 0;
        unforcedErrors = (currentGame && currentGame.opponent_errors) || 0;
        breakRuns = (currentGame && currentGame.opponent_break_runs) || 0;
        tableRuns = (currentGame && currentGame.opponent_table_runs) || 0;
        highRun = (currentGame && currentGame.opponent_high_run) || 0;
        highBreak = (currentGame && currentGame.opponent_high_break) || 0;
    }

    function setValues(values) {
        let formData = {};
        Object.entries(values).forEach(([key, val], i) => {
            formData[key] = parseInt(val);
        });

        updateMatchGame({
            variables: {
                id: currentGame.id,
                changes: formData,
            },
        })
            .then((data) => {
                console.log(data);
                updateStats(values);
            })
            .catch((error) => {
                console.log(error);
                message.info('There was an error', error);
            });
    }

    function calculateMatchStats(games) {
        return {
            player_games: _.sumBy(games, 'player_games'),
            opponent_games: _.sumBy(games, 'opponent_games'),
            player_points: _.sumBy(games, 'player_points'),
            opponent_points: _.sumBy(games, 'opponent_points'),
            player_balls_pocketed: _.sumBy(games, 'player_balls_pocketed'),
            opponent_balls_pocketed: _.sumBy(games, 'opponent_balls_pocketed'),
            player_errors: _.sumBy(games, 'player_errors'),
            opponent_errors: _.sumBy(games, 'opponent_errors'),
            player_high_break: _.sumBy(games, 'player_high_break'),
            opponent_high_break: _.sumBy(games, 'opponent_high_break'),
        };
    }

    function updateStats(data) {
        let formData = {};
        Object.entries(data).forEach(([key, val], i) => {
            formData[key] = parseInt(val);
        });
        // console.log(formData);
        // console.log(matchData);
        // console.log(currentGame);

        const updatedGame = _.merge(currentGame, formData);
        const updatedMatchGames = matchData.match_games.map((game) => {
            if (game.id === updatedGame.id) {
                return updatedGame;
            } else {
                return game;
            }
        });
        // console.log(updatedMatchFrames);
        const matchStats = calculateMatchStats(updatedMatchGames);

        updateMatchStats({
            variables: {
                id: matchData.id,
                changes: matchStats,
            },
        })
            .then((data) => {
                // console.log(data);
                console.log('matchStats', matchStats);
                // message.success('Match stats updated');
            })
            .catch((error) => {
                console.log(error);
                // setLoading(false);
                message.error('There was an error', error);
            });
    }

    function increaseBallsPocketed(challenger) {
        if (challenger === 'challenger1') {
            incrementPlayerBallsPocketed({
                variables: {
                    id: matchID,
                    // id: currentGame.id,
                    // match_id: matchID
                },
            })
                .then((data) => {
                    console.log(data);
                })
                .catch((error) => {
                    console.log(error);
                    message.info('There was an error', error);
                });

            incrementPlayerGameBallsPocketed({
                variables: {
                    id: currentGame.id,
                    match_id: matchID,
                },
            })
                .then((data) => {
                    console.log(data);
                })
                .catch((error) => {
                    console.log(error);
                    message.info('There was an error', error);
                });
        } else if (challenger === 'challenger2') {
            incrementOpponentBallsPocketed({
                variables: {
                    id: matchID,
                },
            })
                .then((data) => {
                    console.log(data);
                })
                .catch((error) => {
                    console.log(error);
                    message.info('There was an error', error);
                });
            incrementOpponentGameBallsPocketed({
                variables: {
                    id: currentGame.id,
                    match_id: matchID,
                },
            })
                .then((data) => {
                    console.log(data);
                })
                .catch((error) => {
                    console.log(error);
                    message.info('There was an error', error);
                });
        }
    }

    function decreaseBallsPocketed(challenger) {
        if (challenger === 'challenger1') {
            decrementPlayerBallsPocketed({
                variables: {
                    id: matchID,
                },
            })
                .then((data) => {
                    console.log(data);
                })
                .catch((error) => {
                    console.log(error);
                    message.info('There was an error', error);
                });

            decrementPlayerGameBallsPocketed({
                variables: {
                    id: currentGame.id,
                    match_id: matchID,
                },
            })
                .then((data) => {
                    console.log(data);
                })
                .catch((error) => {
                    console.log(error);
                    message.info('There was an error', error);
                });
        } else if (challenger === 'challenger2') {
            decrementOpponentBallsPocketed({
                variables: {
                    id: matchID,
                },
            })
                .then((data) => {
                    console.log(data);
                })
                .catch((error) => {
                    console.log(error);
                    message.info('There was an error', error);
                });

            decrementOpponentGameBallsPocketed({
                variables: {
                    id: currentGame.id,
                    match_id: matchID,
                },
            })
                .then((data) => {
                    console.log(data);
                })
                .catch((error) => {
                    console.log(error);
                    message.info('There was an error', error);
                });
        }
    }

    function setBallsPocketed(challenger, value) {
        if (challenger === 'challenger1') {
            setChallengerBallsPocketed({
                variables: {
                    id: matchID,
                    changes: {
                        player_balls_pocketed: value || 0,
                    },
                },
            })
                .then((data) => {
                    console.log(data);
                })
                .catch((error) => {
                    console.log(error);
                    message.info('There was an error', error);
                });

            setChallengerGameBallsPocketed({
                variables: {
                    id: currentGame.id,
                    match_id: matchID,
                    changes: {
                        player_balls_pocketed: value || 0,
                    },
                },
            })
                .then((data) => {
                    console.log(data);
                })
                .catch((error) => {
                    console.log(error);
                    message.info('There was an error', error);
                });
        } else {
            setChallengerBallsPocketed({
                variables: {
                    id: matchID,
                    changes: {
                        opponent_balls_pocketed: value || 0,
                    },
                },
            })
                .then((data) => {
                    console.log(data);
                })
                .catch((error) => {
                    console.log(error);
                    message.info('There was an error', error);
                });

            setChallengerGameBallsPocketed({
                variables: {
                    id: currentGame.id,
                    match_id: matchID,
                    changes: {
                        opponent_balls_pocketed: value || 0,
                    },
                },
            })
                .then((data) => {
                    console.log(data);
                })
                .catch((error) => {
                    console.log(error);
                    message.info('There was an error', error);
                });
        }
    }

    function increaseErrors(challenger) {
        if (challenger === 'challenger1') {
            incrementPlayerErrors({
                variables: {
                    id: matchID,
                },
            })
                .then((data) => {
                    console.log(data);
                })
                .catch((error) => {
                    console.log(error);
                    message.info('There was an error', error);
                });

            incrementPlayerGameErrors({
                variables: {
                    id: currentGame.id,
                    match_id: matchID,
                },
            })
                .then((data) => {
                    console.log(data);
                })
                .catch((error) => {
                    console.log(error);
                    message.info('There was an error', error);
                });
        } else if (challenger === 'challenger2') {
            incrementOpponentErrors({
                variables: {
                    id: matchID,
                },
            })
                .then((data) => {
                    console.log(data);
                })
                .catch((error) => {
                    console.log(error);
                    message.info('There was an error', error);
                });

            incrementOpponentGameErrors({
                variables: {
                    id: currentGame.id,
                    match_id: matchID,
                },
            })
                .then((data) => {
                    console.log(data);
                })
                .catch((error) => {
                    console.log(error);
                    message.info('There was an error', error);
                });
        }
    }

    function decreaseErrors(challenger) {
        if (challenger === 'challenger1') {
            decrementPlayerErrors({
                variables: {
                    id: matchID,
                },
            })
                .then((data) => {
                    console.log(data);
                })
                .catch((error) => {
                    console.log(error);
                    message.info('There was an error', error);
                });

            decrementPlayerGameErrors({
                variables: {
                    id: currentGame.id,
                    match_id: matchID,
                },
            })
                .then((data) => {
                    console.log(data);
                })
                .catch((error) => {
                    console.log(error);
                    message.info('There was an error', error);
                });
        } else if (challenger === 'challenger2') {
            decrementOpponentErrors({
                variables: {
                    id: matchID,
                },
            })
                .then((data) => {
                    console.log(data);
                })
                .catch((error) => {
                    console.log(error);
                    message.info('There was an error', error);
                });

            decrementOpponentGameErrors({
                variables: {
                    id: currentGame.id,
                    match_id: matchID,
                },
            })
                .then((data) => {
                    console.log(data);
                })
                .catch((error) => {
                    console.log(error);
                    message.info('There was an error', error);
                });
        }
    }

    function setErrors(challenger, value) {
        if (challenger === 'challenger1') {
            setChallengerErrors({
                variables: {
                    id: matchID,
                    changes: {
                        player_errors: value || 0,
                    },
                },
            })
                .then((data) => {
                    console.log(data);
                })
                .catch((error) => {
                    console.log(error);
                    message.info('There was an error', error);
                });

            setChallengerGameErrors({
                variables: {
                    id: currentGame.id,
                    match_id: matchID,
                    changes: {
                        player_errors: value || 0,
                    },
                },
            })
                .then((data) => {
                    console.log(data);
                })
                .catch((error) => {
                    console.log(error);
                    message.info('There was an error', error);
                });
        } else {
            setChallengerErrors({
                variables: {
                    id: matchID,
                    changes: {
                        opponent_errors: value || 0,
                    },
                },
            })
                .then((data) => {
                    console.log(data);
                })
                .catch((error) => {
                    console.log(error);
                    message.info('There was an error', error);
                });

            setChallengerGameErrors({
                variables: {
                    id: currentGame.id,
                    match_id: matchID,
                    changes: {
                        opponent_errors: value || 0,
                    },
                },
            })
                .then((data) => {
                    console.log(data);
                })
                .catch((error) => {
                    console.log(error);
                    message.info('There was an error', error);
                });
        }
    }

    function increaseBreakRuns(challenger) {
        if (challenger === 'challenger1') {
            incrementPlayerBreakRuns({
                variables: {
                    id: matchID,
                },
            })
                .then((data) => {
                    console.log(data);
                })
                .catch((error) => {
                    console.log(error);
                    message.info('There was an error', error);
                });

            incrementPlayerGameBreakRuns({
                variables: {
                    id: currentGame.id,
                    match_id: matchID,
                },
            })
                .then((data) => {
                    console.log(data);
                })
                .catch((error) => {
                    console.log(error);
                    message.info('There was an error', error);
                });
        } else if (challenger === 'challenger2') {
            incrementOpponentBreakRuns({
                variables: {
                    id: matchID,
                },
            })
                .then((data) => {
                    console.log(data);
                })
                .catch((error) => {
                    console.log(error);
                    message.info('There was an error', error);
                });

            incrementOpponentGameBreakRuns({
                variables: {
                    id: currentGame.id,
                    match_id: matchID,
                },
            })
                .then((data) => {
                    console.log(data);
                })
                .catch((error) => {
                    console.log(error);
                    message.info('There was an error', error);
                });
        }
    }

    function decreaseBreakRuns(challenger) {
        if (challenger === 'challenger1') {
            decrementPlayerBreakRuns({
                variables: {
                    id: matchID,
                },
            })
                .then((data) => {
                    console.log(data);
                })
                .catch((error) => {
                    console.log(error);
                    message.info('There was an error', error);
                });

            decrementPlayerGameBreakRuns({
                variables: {
                    id: currentGame.id,
                    match_id: matchID,
                },
            })
                .then((data) => {
                    console.log(data);
                })
                .catch((error) => {
                    console.log(error);
                    message.info('There was an error', error);
                });
        } else if (challenger === 'challenger2') {
            decrementOpponentBreakRuns({
                variables: {
                    id: matchID,
                },
            })
                .then((data) => {
                    console.log(data);
                })
                .catch((error) => {
                    console.log(error);
                    message.info('There was an error', error);
                });

            decrementOpponentGameBreakRuns({
                variables: {
                    id: currentGame.id,
                    match_id: matchID,
                },
            })
                .then((data) => {
                    console.log(data);
                })
                .catch((error) => {
                    console.log(error);
                    message.info('There was an error', error);
                });
        }
    }

    function setBreakRuns(challenger, value) {
        if (challenger === 'challenger1') {
            setChallengerBreakRuns({
                variables: {
                    id: matchID,
                    changes: {
                        player_break_runs: value || 0,
                    },
                },
            })
                .then((data) => {
                    console.log(data);
                })
                .catch((error) => {
                    console.log(error);
                    message.info('There was an error', error);
                });

            setChallengerGameBreakRuns({
                variables: {
                    id: currentGame.id,
                    match_id: matchID,
                    changes: {
                        player_break_runs: value || 0,
                    },
                },
            })
                .then((data) => {
                    console.log(data);
                })
                .catch((error) => {
                    console.log(error);
                    message.info('There was an error', error);
                });
        } else {
            setChallengerBreakRuns({
                variables: {
                    id: matchID,
                    changes: {
                        opponent_break_runs: value || 0,
                    },
                },
            })
                .then((data) => {
                    console.log(data);
                })
                .catch((error) => {
                    console.log(error);
                    message.info('There was an error', error);
                });

            setChallengerGameBreakRuns({
                variables: {
                    id: currentGame.id,
                    match_id: matchID,
                    changes: {
                        opponent_break_runs: value || 0,
                    },
                },
            })
                .then((data) => {
                    console.log(data);
                })
                .catch((error) => {
                    console.log(error);
                    message.info('There was an error', error);
                });
        }
    }

    function increaseTableRuns(challenger) {
        if (challenger === 'challenger1') {
            incrementPlayerTableRuns({
                variables: {
                    id: matchID,
                },
            })
                .then((data) => {
                    console.log(data);
                })
                .catch((error) => {
                    console.log(error);
                    message.info('There was an error', error);
                });

            incrementPlayerGameTableRuns({
                variables: {
                    id: currentGame.id,
                    match_id: matchID,
                },
            })
                .then((data) => {
                    console.log(data);
                })
                .catch((error) => {
                    console.log(error);
                    message.info('There was an error', error);
                });
        } else if (challenger === 'challenger2') {
            incrementOpponentTableRuns({
                variables: {
                    id: matchID,
                },
            })
                .then((data) => {
                    console.log(data);
                })
                .catch((error) => {
                    console.log(error);
                    message.info('There was an error', error);
                });

            incrementOpponentGameTableRuns({
                variables: {
                    id: currentGame.id,
                    match_id: matchID,
                },
            })
                .then((data) => {
                    console.log(data);
                })
                .catch((error) => {
                    console.log(error);
                    message.info('There was an error', error);
                });
        }
    }

    function decreaseTableRuns(challenger) {
        if (challenger === 'challenger1') {
            decrementPlayerTableRuns({
                variables: {
                    id: matchID,
                },
            })
                .then((data) => {
                    console.log(data);
                })
                .catch((error) => {
                    console.log(error);
                    message.info('There was an error', error);
                });

            decrementPlayerGameTableRuns({
                variables: {
                    id: currentGame.id,
                    match_id: matchID,
                },
            })
                .then((data) => {
                    console.log(data);
                })
                .catch((error) => {
                    console.log(error);
                    message.info('There was an error', error);
                });
        } else if (challenger === 'challenger2') {
            decrementOpponentTableRuns({
                variables: {
                    id: matchID,
                },
            })
                .then((data) => {
                    console.log(data);
                })
                .catch((error) => {
                    console.log(error);
                    message.info('There was an error', error);
                });

            decrementOpponentGameTableRuns({
                variables: {
                    id: currentGame.id,
                    match_id: matchID,
                },
            })
                .then((data) => {
                    console.log(data);
                })
                .catch((error) => {
                    console.log(error);
                    message.info('There was an error', error);
                });
        }
    }

    function setTableRuns(challenger, value) {
        if (challenger === 'challenger1') {
            setChallengerTableRuns({
                variables: {
                    id: matchID,
                    changes: {
                        player_table_runs: value || 0,
                    },
                },
            })
                .then((data) => {
                    console.log(data);
                })
                .catch((error) => {
                    console.log(error);
                    message.info('There was an error', error);
                });

            setChallengerGameTableRuns({
                variables: {
                    id: currentGame.id,
                    match_id: matchID,
                    changes: {
                        player_table_runs: value || 0,
                    },
                },
            })
                .then((data) => {
                    console.log(data);
                })
                .catch((error) => {
                    console.log(error);
                    message.info('There was an error', error);
                });
        } else {
            setChallengerTableRuns({
                variables: {
                    id: matchID,
                    changes: {
                        opponent_table_runs: value || 0,
                    },
                },
            })
                .then((data) => {
                    console.log(data);
                })
                .catch((error) => {
                    console.log(error);
                    message.info('There was an error', error);
                });

            setChallengerGameTableRuns({
                variables: {
                    id: currentGame.id,
                    match_id: matchID,
                    changes: {
                        opponent_table_runs: value || 0,
                    },
                },
            })
                .then((data) => {
                    console.log(data);
                })
                .catch((error) => {
                    console.log(error);
                    message.info('There was an error', error);
                });
        }
    }

    function increaseHighRun(challenger) {
        if (challenger === 'challenger1') {
            incrementPlayerHighRun({
                variables: {
                    id: matchID,
                },
            })
                .then((data) => {
                    console.log(data);
                })
                .catch((error) => {
                    console.log(error);
                    message.info('There was an error', error);
                });

            incrementPlayerGameHighRun({
                variables: {
                    id: currentGame.id,
                    match_id: matchID,
                },
            })
                .then((data) => {
                    console.log(data);
                })
                .catch((error) => {
                    console.log(error);
                    message.info('There was an error', error);
                });
        } else if (challenger === 'challenger2') {
            incrementOpponentHighRun({
                variables: {
                    id: matchID,
                },
            })
                .then((data) => {
                    console.log(data);
                })
                .catch((error) => {
                    console.log(error);
                    message.info('There was an error', error);
                });

            incrementOpponentGameHighRun({
                variables: {
                    id: currentGame.id,
                    match_id: matchID,
                },
            })
                .then((data) => {
                    console.log(data);
                })
                .catch((error) => {
                    console.log(error);
                    message.info('There was an error', error);
                });
        }
    }

    function decreaseHighRun(challenger) {
        if (challenger === 'challenger1') {
            decrementPlayerHighRun({
                variables: {
                    id: matchID,
                },
            })
                .then((data) => {
                    console.log(data);
                })
                .catch((error) => {
                    console.log(error);
                    message.info('There was an error', error);
                });

            decrementPlayerGameHighRun({
                variables: {
                    id: currentGame.id,
                    match_id: matchID,
                },
            })
                .then((data) => {
                    console.log(data);
                })
                .catch((error) => {
                    console.log(error);
                    message.info('There was an error', error);
                });
        } else if (challenger === 'challenger2') {
            decrementOpponentHighRun({
                variables: {
                    id: matchID,
                },
            })
                .then((data) => {
                    console.log(data);
                })
                .catch((error) => {
                    console.log(error);
                    message.info('There was an error', error);
                });

            decrementOpponentGameHighRun({
                variables: {
                    id: currentGame.id,
                    match_id: matchID,
                },
            })
                .then((data) => {
                    console.log(data);
                })
                .catch((error) => {
                    console.log(error);
                    message.info('There was an error', error);
                });
        }
    }

    function setHighRun(challenger, value) {
        if (challenger === 'challenger1') {
            setChallengerHighRun({
                variables: {
                    id: matchID,
                    changes: {
                        player_high_run: value || 0,
                    },
                },
            })
                .then((data) => {
                    console.log(data);
                })
                .catch((error) => {
                    console.log(error);
                    message.info('There was an error', error);
                });

            setChallengerGameHighRun({
                variables: {
                    id: currentGame.id,
                    match_id: matchID,
                    changes: {
                        player_high_run: value || 0,
                    },
                },
            })
                .then((data) => {
                    console.log(data);
                })
                .catch((error) => {
                    console.log(error);
                    message.info('There was an error', error);
                });
        } else {
            setChallengerHighRun({
                variables: {
                    id: matchID,
                    changes: {
                        opponent_high_run: value || 0,
                    },
                },
            })
                .then((data) => {
                    console.log(data);
                })
                .catch((error) => {
                    console.log(error);
                    message.info('There was an error', error);
                });

            setChallengerGameHighRun({
                variables: {
                    id: currentGame.id,
                    match_id: matchID,
                    changes: {
                        opponent_high_run: value || 0,
                    },
                },
            })
                .then((data) => {
                    console.log(data);
                })
                .catch((error) => {
                    console.log(error);
                    message.info('There was an error', error);
                });
        }
    }

    function increaseHighBreak(challenger) {
        if (challenger === 'challenger1') {
            incrementPlayerHighBreak({
                variables: {
                    id: matchID,
                },
            })
                .then((data) => {
                    console.log(data);
                })
                .catch((error) => {
                    console.log(error);
                    message.info('There was an error', error);
                });

            incrementPlayerGameHighBreak({
                variables: {
                    id: currentGame.id,
                    match_id: matchID,
                },
            })
                .then((data) => {
                    console.log(data);
                })
                .catch((error) => {
                    console.log(error);
                    message.info('There was an error', error);
                });
        } else if (challenger === 'challenger2') {
            incrementOpponentHighBreak({
                variables: {
                    id: matchID,
                },
            })
                .then((data) => {
                    console.log(data);
                })
                .catch((error) => {
                    console.log(error);
                    message.info('There was an error', error);
                });

            incrementOpponentGameHighBreak({
                variables: {
                    id: currentGame.id,
                    match_id: matchID,
                },
            })
                .then((data) => {
                    console.log(data);
                })
                .catch((error) => {
                    console.log(error);
                    message.info('There was an error', error);
                });
        }
    }

    function decreaseHighBreak(challenger) {
        if (challenger === 'challenger1') {
            decrementPlayerHighBreak({
                variables: {
                    id: matchID,
                },
            })
                .then((data) => {
                    console.log(data);
                })
                .catch((error) => {
                    console.log(error);
                    message.info('There was an error', error);
                });

            decrementPlayerGameHighBreak({
                variables: {
                    id: currentGame.id,
                    match_id: matchID,
                },
            })
                .then((data) => {
                    console.log(data);
                })
                .catch((error) => {
                    console.log(error);
                    message.info('There was an error', error);
                });
        } else if (challenger === 'challenger2') {
            decrementOpponentHighBreak({
                variables: {
                    id: matchID,
                },
            })
                .then((data) => {
                    console.log(data);
                })
                .catch((error) => {
                    console.log(error);
                    message.info('There was an error', error);
                });

            decrementOpponentGameHighBreak({
                variables: {
                    id: currentGame.id,
                    match_id: matchID,
                },
            })
                .then((data) => {
                    console.log(data);
                })
                .catch((error) => {
                    console.log(error);
                    message.info('There was an error', error);
                });
        }
    }

    function setHighBreak(challenger, value) {
        if (challenger === 'challenger1') {
            setChallengerHighBreak({
                variables: {
                    id: matchID,
                    changes: {
                        player_high_break: value || 0,
                    },
                },
            })
                .then((data) => {
                    console.log(data);
                })
                .catch((error) => {
                    console.log(error);
                    message.info('There was an error', error);
                });

            setChallengerGameHighBreak({
                variables: {
                    id: currentGame.id,
                    match_id: matchID,
                    changes: {
                        player_high_break: value || 0,
                    },
                },
            })
                .then((data) => {
                    console.log(data);
                })
                .catch((error) => {
                    console.log(error);
                    message.info('There was an error', error);
                });
        } else {
            setChallengerHighBreak({
                variables: {
                    id: matchID,
                    changes: {
                        opponent_high_break: value || 0,
                    },
                },
            })
                .then((data) => {
                    console.log(data);
                })
                .catch((error) => {
                    console.log(error);
                    message.info('There was an error', error);
                });

            setChallengerGameHighBreak({
                variables: {
                    id: currentGame.id,
                    match_id: matchID,
                    changes: {
                        opponent_high_break: value || 0,
                    },
                },
            })
                .then((data) => {
                    console.log(data);
                })
                .catch((error) => {
                    console.log(error);
                    message.info('There was an error', error);
                });
        }
    }

    function onSetChallenger1Value(event, type) {
        console.log('changed', event.target.value);
        const value = event.target.value;
        const challenger = 'challenger1';
        if (type === 'balls pocketed') {
            setBallsPocketed(challenger, value);
        } else if (type === 'unforced errors') {
            setErrors(challenger, value);
        } else if (type === 'break and runs') {
            setBreakRuns(challenger, value);
        } else if (type === 'table runs') {
            setTableRuns(challenger, value);
        } else if (type === 'high run') {
            setHighRun(challenger, value);
        } else if (type === 'high break') {
            setHighBreak(challenger, value);
        }
    }

    function onSetChallenger2Value(event, type) {
        console.log('changed', event.target.value);
        const value = event.target.value;
        const challenger = 'challenger2';
        if (type === 'balls pocketed') {
            setBallsPocketed(challenger, value);
        } else if (type === 'unforced errors') {
            setErrors(challenger, value);
        } else if (type === 'break and runs') {
            setBreakRuns(challenger, value);
        } else if (type === 'table runs') {
            setTableRuns(challenger, value);
        } else if (type === 'high run') {
            setHighRun(challenger, value);
        } else if (type === 'high break') {
            setHighBreak(challenger, value);
        }
    }

    function handleFocus(event) {
        event.target.select();
    }

    function buttons(item) {
        const currentItem = item.toLowerCase();

        return (
            <div style={layout === 'left' ? { marginLeft: 20 } : { marginRight: 20 }}>
                <Button
                    type="primary"
                    onClick={() => {
                        let challenger;
                        if (layout === 'left') {
                            challenger = 'challenger1';
                        } else {
                            challenger = 'challenger2';
                        }
                        if (currentItem === 'balls pocketed') {
                            increaseBallsPocketed(challenger);
                        } else if (currentItem === 'unforced errors') {
                            increaseErrors(challenger);
                        } else if (currentItem === 'break and runs') {
                            increaseBreakRuns(challenger);
                        } else if (currentItem === 'table runs') {
                            increaseTableRuns(challenger);
                        } else if (currentItem === 'high run') {
                            increaseHighRun(challenger);
                        } else if (currentItem === 'high break') {
                            increaseHighBreak(challenger);
                        }
                    }}
                    disabled={currentGame && currentGame.status !== 'IN_PROGRESS' ? true : false}
                >
                    <Icon type="plus" />
                </Button>
                <Button
                    type="danger"
                    onClick={() => {
                        let challenger;
                        if (layout === 'left') {
                            challenger = 'challenger1';
                        } else {
                            challenger = 'challenger2';
                        }
                        if (currentItem === 'balls pocketed') {
                            decreaseBallsPocketed(challenger);
                        } else if (currentItem === 'unforced errors') {
                            decreaseErrors(challenger);
                        } else if (currentItem === 'break and runs') {
                            decreaseBreakRuns(challenger);
                        } else if (currentItem === 'table runs') {
                            decreaseTableRuns(challenger);
                        } else if (currentItem === 'high run') {
                            decreaseHighRun(challenger);
                        } else if (currentItem === 'high break') {
                            decreaseHighBreak(challenger);
                        }
                    }}
                    disabled={currentGame && currentGame.status !== 'IN_PROGRESS' ? true : false}
                    style={{ marginLeft: 10 }}
                >
                    <Icon type="minus" />
                </Button>
            </div>
        );
    }

    function inputFields(item) {
        const currentItem = item.toLowerCase();
        const currentKey =
            currentItem === 'balls pocketed'
                ? 'balls_pocketed'
                : currentItem === 'unforced errors'
                ? 'errors'
                : currentItem === 'break and runs'
                ? 'break_runs'
                : currentItem === 'table runs'
                ? 'table_runs'
                : currentItem === 'high run'
                ? 'high_run'
                : currentItem === 'high break'
                ? 'high_break'
                : 0;
        // const current =
        // 	currentItem === 'balls pocketed'
        // 		? ballsPocketed
        // 		: currentItem === 'unforced errors'
        // 			? unforcedErrors
        // 			: currentItem === 'break and runs'
        // 				? breakRuns
        // 				: currentItem === 'table runs'
        // 					? tableRuns
        // 					: currentItem === 'high run' ? highRun : currentItem === 'high break' ? highBreak : 0;

        return (
            <List.Item.Meta
                title={
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        {layout === 'left' ? (
                            <React.Fragment>
                                <Text style={{ fontSize: 20 }}>{item}</Text>
                                <FormItem name={`player_${currentKey}`}>
                                    <Input
                                        min={0}
                                        onFocus={handleFocus}
                                        name={`player_${currentKey}`}
                                        onPressEnter={() => {
                                            formRef.current.handleSubmit();
                                        }}
                                        onBlur={() => {
                                            formRef.current.handleSubmit();
                                        }}
                                        // defaultValue={current}
                                        // value={current}
                                        // onChange={(e) => {
                                        // 	onSetChallenger1Value(e, currentItem);
                                        // }}
                                        disabled={currentGame && currentGame.status !== 'IN_PROGRESS' ? true : false}
                                        style={{
                                            width: 40,
                                            backgroundColor: 'transparent',
                                            fontSize: 20,
                                            padding: 0,
                                            fontWeight: 700,
                                            height: 34,
                                            marginLeft: 20,
                                            textAlign: 'center',
                                            // border: 'none'
                                        }}
                                    />
                                </FormItem>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <FormItem name={`opponent_${currentKey}`}>
                                    <Input
                                        min={0}
                                        onFocus={handleFocus}
                                        name={`opponent_${currentKey}`}
                                        onPressEnter={() => {
                                            formRef.current.handleSubmit();
                                        }}
                                        onBlur={() => {
                                            formRef.current.handleSubmit();
                                        }}
                                        disabled={currentGame && currentGame.status !== 'IN_PROGRESS' ? true : false}
                                        // defaultValue={current}
                                        // value={current}
                                        // onChange={(e) => {
                                        // 	onSetChallenger2Value(e, currentItem);
                                        // }}
                                        style={{
                                            width: 40,
                                            backgroundColor: 'transparent',
                                            fontSize: 20,
                                            padding: 0,
                                            fontWeight: 700,
                                            height: 34,
                                            textAlign: 'center',
                                            // border: 'none'
                                        }}
                                    />
                                </FormItem>

                                <Text style={{ fontSize: 20 }}>{item}</Text>
                            </React.Fragment>
                        )}
                    </div>
                }
            />
        );
    }

    return (
        <div style={{ marginTop: 10, borderRadius: 10 }}>
            <Formik
                ref={formRef}
                onSubmit={(data, actions) => {
                    setValues(data);
                }}
                // validationSchema={schema}
                enableReinitialize
                initialValues={
                    currentGame
                        ? {
                              player_balls_pocketed: currentGame.player_balls_pocketed !== null ? currentGame.player_balls_pocketed : 0,
                              opponent_balls_pocketed: currentGame.opponent_balls_pocketed !== null ? currentGame.opponent_balls_pocketed : 0,
                              player_errors: currentGame.player_errors !== null ? currentGame.player_errors : 0,
                              opponent_errors: currentGame.opponent_errors !== null ? currentGame.opponent_errors : 0,
                              player_high_break: currentGame.player_high_break !== null ? currentGame.player_high_break : 0,
                              opponent_high_break: currentGame.opponent_high_break !== null ? currentGame.opponent_high_break : 0,
                          }
                        : {
                              player_balls_pocketed: 0,
                              opponent_balls_pocketed: 0,
                              player_errors: 0,
                              opponent_errors: 0,
                              player_high_break: 0,
                              opponent_high_break: 0,
                          }
                }
                render={({ errors, touched, values }) => (
                    <Form
                        layout="vertical"
                        style={{ maxWidth: 600 }}
                        onSubmit={(e) => {
                            e.preventDefault();
                            console.log(values);
                            // handleSubmit(values);
                        }}
                    >
                        <List
                            itemLayout="horizontal"
                            dataSource={
                                matchData.game_type === 'SNOOKER'
                                    ? ['Balls pocketed', 'Unforced errors', 'High Break']
                                    : ['Balls pocketed', 'Unforced errors', 'Break and Runs', 'Table Runs', 'High Run', 'High Break']
                            }
                            renderItem={(item) => (
                                <List.Item
                                    extra={layout === 'left' ? buttons(item) : inputFields(item)}
                                    style={{
                                        padding: '2px 0px',
                                        // borderBottom: '1px solid #eee'
                                    }}
                                >
                                    {layout === 'left' ? inputFields(item) : buttons(item)}
                                </List.Item>
                            )}
                        />
                    </Form>
                )}
            />
        </div>
    );
}
