import React, { useState, useEffect } from 'react';
import { GET_MATCH_BREAK_AND_RUN_PACKAGES } from './data/subscriptions';
import CircularLoader from '../../../components/CircularLoader';
import { Query, Subscription } from '@apollo/client/react/components';

const styles = {};

export default function StatBreakAndRunPackages(props) {
    const { matchId } = props;

    return (
        <tr>
            <Subscription
                subscription={GET_MATCH_BREAK_AND_RUN_PACKAGES}
                fetchPolicy="cache-and-network"
                notifyOnNetworkStatusChange={true}
                variables={{ match_id: matchId }}
                onSubscriptionData={async (data) => {
                    // console.log(data);
                }}
            >
                {({ loading, error, data }) => {
                    if (loading) return <React.Fragment></React.Fragment>;
                    if (error) return <React.Fragment></React.Fragment>;

                    const packages = data && data.vw_break_and_run_streaks;
                    const playerPackages = packages
                        ? packages.filter((player) => {
                              return player.name === 'player';
                          }).length
                        : 0;

                    const opponentPackages = packages
                        ? packages.filter((player) => {
                              return player.name === 'opponent';
                          }).length
                        : 0;

                    return (
                        <React.Fragment>
                            <td align="right" width="60px"></td>
                            <td align="right">
                                <div
                                    style={{
                                        fontSize: 18,
                                    }}
                                >
                                    <b>{playerPackages}</b>
                                </div>
                            </td>
                            <td align="center" width="350px">
                                Break And Run Packages
                            </td>
                            <td align="left">
                                <div
                                    style={{
                                        fontSize: 18,
                                    }}
                                >
                                    <b>{opponentPackages}</b>
                                </div>
                            </td>
                            <td align="left" width="60px"></td>
                        </React.Fragment>
                    );
                }}
            </Subscription>
        </tr>
    );
}
