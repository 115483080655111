import React from 'react';
import { withRouter } from 'react-router-dom';
import { PageHeader, Steps, Typography, Button, Empty } from 'antd';
import Card from '../../../components/Card';
import SectionContent from '../../../components/SectionContent';
import LMVenueCreateEditForm from './LMVenueCreateEditForm';
import CircularLoader from '../../../components/CircularLoader';
import { Query } from '@apollo/client/react/components';
import { GET_LEAGUE_VENUE_QUERY } from '../data/queries';
import _ from 'lodash';
const { Text } = Typography;

const { Step } = Steps;

const styles = {
    cardHeader: {
        paddingTop: 5,
        paddingBottom: 5,
        background: 'linear-gradient(0deg, rgb(241, 244, 246), rgb(255, 255, 255))',
        // boxShadow: 'rgba(0, 0, 0, 0.05) 0px 2px 8px 0px',
        borderRadius: '10px 10px 0px 0px',
    },
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
};

function LMVenueCreate(props) {
    console.log(props);
    return (
        <React.Fragment>
            {props.match.params.venue ? (
                <PageHeader
                    onBack={() => props.history.push(`/league-manager/${props.data.slug}/venues/${props.match.params.venue}`)}
                    title={`EDIT ${_.startCase(props.match.params.venue.replace('-', ' ')).toUpperCase()}`}
                    style={{
                        background: props.theme === 'dark' ? '#000' : 'linear-gradient(180deg, #fff, #f0f2f5)',
                        borderBottom: '1px solid #e0e3ea',
                        paddingTop: 24,
                        paddingLeft: 40,
                        paddingRight: 24,
                        paddingBottom: 24,
                        display: 'flex',
                        alignItems: 'center',
                    }}
                />
            ) : (
                <PageHeader
                    onBack={() => props.history.push(`/league-manager/${props.data.slug}/venues`)}
                    title="CREATE VENUE"
                    style={{
                        background: props.theme === 'dark' ? '#000' : 'linear-gradient(180deg, #fff, #f0f2f5)',
                        borderBottom: '1px solid #e0e3ea',
                        paddingTop: 24,
                        paddingLeft: 40,
                        paddingRight: 24,
                        paddingBottom: 24,
                        display: 'flex',
                        alignItems: 'center',
                    }}
                />
            )}
            <SectionContent>
                <Card title="Venue Information" headStyle={styles.cardHeader}>
                    {props.match.params.venue ? (
                        <Query
                            query={GET_LEAGUE_VENUE_QUERY}
                            fetchPolicy="cache-and-network"
                            notifyOnNetworkStatusChange={true}
                            variables={{ leagueId: props.league.id, venueSlug: props.match.params.venue }}
                            onCompleted={async (data) => {
                                console.log(data);
                            }}
                        >
                            {({ loading, error, data }) => {
                                if (loading)
                                    return (
                                        <div style={styles.container}>
                                            <CircularLoader />
                                        </div>
                                    );
                                if (error) return <div style={styles.container}>Error: {error.message}</div>;
                                if (!loading && data && !data.venues) {
                                    return (
                                        <Empty
                                            image="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original"
                                            imageStyle={{
                                                height: 100,
                                            }}
                                            description={<Text style={{ fontSize: 18 }}>You have not added any venues yet.</Text>}
                                            style={{ margin: 10 }}
                                        >
                                            <Button>Create New Venue</Button>
                                        </Empty>
                                    );
                                }

                                return (
                                    <React.Fragment>
                                        <LMVenueCreateEditForm {...props} venue={data.venues[0]} />
                                    </React.Fragment>
                                );
                            }}
                        </Query>
                    ) : (
                        <LMVenueCreateEditForm {...props} />
                    )}
                </Card>
            </SectionContent>
        </React.Fragment>
    );
}

export default withRouter(LMVenueCreate);
