import { selector } from 'recoil';
import { 
        rowSelectAtom,
        removeButtonDisabledAtom, 
        removeButtonLoadingAtom,
        createGhostGameFormPublicRadio,
        createGhostGameFormInviteRadio,
        createGhostGameFormRebuyRadio,
        createGhostGameInviteInputList,
        createStep,
        completedStepAtom
    } 
    from './atoms';

export const playerTableSelector = selector({ // should probably separate from remove player selector
    key: 'playerTableSelector',
    get: ({get}) => {
        const selectedRows = get(rowSelectAtom);

        return {
            selectedRows,
            countSelectedRows: selectedRows.length
        }
    }
})

export const removePlayerButtonSelector = selector({
    key: 'removePlayerSelector',
    get: ({get}) => {
        const buttonLoading = get(removeButtonLoadingAtom);
        const buttonDisabled = get(removeButtonDisabledAtom);

        return {
            buttonLoading: buttonLoading,
            buttonDisabled: buttonDisabled
        }
    }
});

export const settingsFormSelector = selector({
    key: 'settingsFormSelector',
    get: ({get}) => {
        return {
            step: get(createStep),
            publicRadio: get(createGhostGameFormPublicRadio),
            rebuyRadio: get(createGhostGameFormRebuyRadio),
            completedStep: get(completedStepAtom)
        }
    }
})

export const createGhostGameInviteInputListSelector = selector({
    key: 'createGhostGameInviteInputListSelector',
    get: ({get}) => {
        const inputs = get(createGhostGameInviteInputList);

        return {
            count: inputs.length,
            inputs: inputs
        }
    }
});