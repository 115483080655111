import React, { useState } from 'react';
import { Typography } from 'antd';
import { FormItem, Select } from 'formik-antd';
import moment from 'moment-timezone';
import momentDurationFormatSetup from 'moment-duration-format';
momentDurationFormatSetup(moment);

const { Text } = Typography;
const { Option, OptGroup } = Select;

export default function RoundTableSelect(props) {
    const { name, tables, match, onOk } = props;

    function handleSelectTableChange(id) {
        onOk(id);
    }

    return (
        <FormItem name={name}>
            <Select
                name={name}
                defaultValue={match && match.scheduled_table_id}
                // style={{ minWidth: 300 }}
                onChange={handleSelectTableChange}
                placeholder="Select table assignment"
                size="large"
                allowClear={match && match.scheduled_table_id ? false : true}
            >
                <OptGroup label="TABLES">
                    <Option key={`${name}-none`} value={undefined}>
                        None
                    </Option>
                    {tables
                        .filter((item, index) => {
                            return item.status !== 'CLOSED';
                        })
                        .sort((a, b) => {
                            return a.label.localeCompare(b.label, undefined, {
                                numeric: true,
                                sensitivity: 'base',
                            });
                        })
                        .sort((a, b) => {
                            return (b.status === 'OPEN') - (a.status === 'OPEN');
                        })
                        .map((item, index) => (
                            <Option key={item.id} value={item.id}>
                                {item.label} {item.is_streaming_table ? '(Streaming Table)' : ''}
                                {item.is_featured_table ? '(Featured Table)' : ''}
                            </Option>
                        ))}
                </OptGroup>
            </Select>
        </FormItem>
    );
}
